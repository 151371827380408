import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { Row, Col, Input, Select, Popover, Checkbox, Space, InputNumber, notification } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import CurrencyInput from 'react-currency-input-field';
import { BsUsbC, BsX } from 'react-icons/bs';

import '../../assets/styles/common.css';
import {
  handelIncreaseTime,
  handelDecreaseTime,
  secondsToHms,
  setTimetoSec,
  checkInputWithColor,
  checkShowHideIcon,
  handleShowHide,
  handleCollpse,
  handleKeyPress,
  kouteiCalculateSelectedArr,
} from '../common/Common.js';
import { DataType, MaterialSurfaceBillingUnit, ServiceClass, WorkType, WorkTypeGroup } from '../common/enums';
import { amtEmptyStr, timeHmsStr } from '../common/Constant';

const IQ3KouteiCalculate_Yosetsu = forwardRef((props, ref) => {
  const [isYousetsudetail, setIYousetsudetail] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [totalAmt, setTotalAmt] = useState('');
  const [totalTime, setTotalTime] = useState('');
  const [totalSagyoAmt, setTotalSagyoAmt] = useState('');
  const [totalSagyoTime, setTotalSagyoTime] = useState('');
  const [totalDandoriAmt, setTotalDandoriAmt] = useState('');
  const [totalDandoriTime, setTotalDandoriTime] = useState('');

  const [totalSum, setTotalSum] = useState('');
  const [totalTimeSum, setTotalTimeSum] = useState('');
  const [sagyoAmtSum, setSagyoAmtSum] = useState('');
  const [sagyoTimeSum, setSagyoTimeSum] = useState('');
  const [dandoriAmtSum, setDandoriAmtSum] = useState('');
  const [dandoriTimeSum, setDandoriTimeSum] = useState('');
  const [processArr, setProcessArr] = useState([]);
  const [editedData, setEditedData] = useState([]);

  const [subYousetsusArr, setSubYousetsuArr] = useState([]);
  const [yousetsusArr, setYousetsuArr] = useState([]);
  const [selectedDataFlg, setSelectedDataFlg] = useState(0);
  const [updateEditedData, setUpdateEditedData] = useState([]);
  const [weldingAmt, setWeldingAmt] = useState(0);
  const [deviceMst, setDeviceMst] = useState([]);
  const [processMst, setProcessMst] = useState([]);
  const [patternInfo, setPatternInfo] = useState([]);

  const JPYs = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'JPY',
  });

  const handleChangeCheckData = (e, i) => {
    /* setSelectedDataFlg(e);
    props?.sendSelectedDataFlg(e); */
    const data = { ...updateEditedData };
    data.dataType = e;
    setUpdateEditedData(data);
    setWeldingAmt(e == DataType.Data ? data.totalDataPrice : data.totalEditPrice);
    props?.updateIQ3KouteiCal(data, WorkType.SmWelding);
  };

  const calculateTotals = (editDataArr) => {
    let sagyoAmtSum = 0;
    let dandoriAmtSum = 0;

    let sagyoTimeSec = 0;
    let dandoriTimeSec = 0;

    editDataArr?.forEach((element) => {
      sagyoAmtSum += element.sagyoAmt;
      dandoriAmtSum += element.dandoriAmt;
      sagyoTimeSec += element.sagyoTime;
      dandoriTimeSec += element.dandoriTime;
    });

    // sagyoAmtSum += parseInt(editDataArr[0].handelingAmt);
    // sagyoTimeSec += setTimetoSec(editDataArr[0].handelingTime);

    // let totalSum = Math.floor(sagyoAmtSum + dandoriAmtSum + editDataArr[0].kensaAmt);
    let totalSum = sagyoAmtSum + dandoriAmtSum;
    let sagyoTimeSum = sagyoTimeSec;
    let dandoriTimeSum = dandoriTimeSec;
    // let totalTimeSum = secondsToHms(sagyoTimeSec + dandoriTimeSec + setTimetoSec(editedKensaTime));
    let totalTimeSum = sagyoTimeSec + dandoriTimeSec;

    let totals = [];
    let i = 0;
    totals.push({
      id: i++,
      sagyoAmtSum: sagyoAmtSum,
      dandoriAmtSum: dandoriAmtSum,
      sagyoTimeSec: sagyoTimeSec,
      dandoriTimeSec: dandoriTimeSec,
      totalSum: totalSum,
      sagyoTimeSum: sagyoTimeSum,
      dandoriTimeSum: dandoriTimeSum,
      totalTimeSum: totalTimeSum,
    });
    return totals;
  };

  const contentDandori = (
    <div>
      <p>
        段取金額=アルゴン段取金額＋ボルト段取金額
        <br /> {totalDandoriAmt} =
        {(() => {
          const subArr = [];
          for (let j = 0; j < editedData?.length; j++) {
            subArr.push(editedData[j]?.dandoriAmt);
          }
          return subArr.join('+');
        })()}
      </p>
      <p>
        段取時間=アルゴン段取時間＋ボルト段取時間
        <br /> {totalDandoriTime} =
        {(() => {
          const subArr = [];
          for (let j = 0; j < editedData?.length; j++) {
            subArr.push(editedData[j]?.dandoriTime);
          }
          return subArr.join('+');
        })()}
      </p>
    </div>
  );

  const contentSagyo = (
    <div>
      <p>
        作業金額=アルゴン作業金額＋ボルト作業金額
        <br /> {totalSagyoAmt} =
        {(() => {
          const subArr = [];
          for (let j = 0; j < editedData?.length; j++) {
            subArr.push(editedData[j]?.sagyoAmt);
          }
          return subArr.join('+');
        })()}
      </p>
      <p>
        作業時間=アルゴン作業時間＋ボルト作業時間
        <br /> {totalSagyoTime} =
        {(() => {
          const subArr = [];
          for (let j = 0; j < editedData?.length; j++) {
            subArr.push(editedData[j]?.sagyoTime);
          }
          return subArr.join('+');
        })()}
      </p>
    </div>
  );

  const contentSub = (
    <div>
      <p>
        作業金額=チャージ金額 x 作業時間
        <br /> ¥233.3334 = ¥4,200(/h) x 200(秒)
      </p>
      <p>
        作業時間 = 加工長 / 加工速度 x 溶接難易度係数 x 製品サイズ係数 x 生産個数係数 <br />
        200(秒) = 200(mm) / 1(mm/秒) x 1 x 1 x 1
      </p>
      <p>
        引用パラメータ
        <br />
        加工長：200mm
        <br /> 加工速度：60mm/分 = 1mm/秒 <br />
        溶接難易度係数：1 <br />
        製品サイズ係数：1 <br />
        生産個数係数：1
      </p>
    </div>
  );

  useEffect(() => {
    let editData = [];
    let totals = [];
    let toBEdit = [];
    let processMst = [];
    let deviceMst = [];

    let parameters = props?.selectedEstimateData?.estimateProducts?.calcParameters?.parameters;
    if (parameters?.process?.length > 0) {
      processMst = parameters?.process
        ? parameters?.process?.filter(
            (item) => item?.class === ServiceClass.SheetMetal && item.workType === WorkType.SmWelding
          )[0]
        : [];
      setProcessMst(processMst ? processMst : []);
      if (parameters?.device?.length > 0) {
        deviceMst = parameters?.device?.filter((item) => processMst?.id == item?.processId);
      }
    }

    let patternInfo = props?.selectedIQ3DataDetail?.processSelect
      ? props?.selectedIQ3DataDetail?.processSelect?.details?.filter((item) => item.processId == processMst?.id)
      : [];
    setPatternInfo([]);
    if (patternInfo == undefined) return;
    if (patternInfo?.length > 0 && patternInfo[0]?.isUsedForCalc == false) return;

    deviceMst =
      deviceMst && patternInfo
        ? deviceMst?.filter(
            (item) => item.processId == patternInfo[0]?.processId && item.id == patternInfo[0]?.deviceId
          )[0]
        : []; //テスト用ID;
    setDeviceMst(deviceMst ? deviceMst : []);
    if (deviceMst == undefined) return;
    setPatternInfo(patternInfo);

    let weldingAdditions = props?.selectedIQ3DataDetail?.additions?.filter(
      (item) => item.processId == processMst?.id
    )[0];
    // 溶接工程入力情報取得
    let weldingProcessInput = props?.selectedIQ3DataDetail?.processInput?.filter(
      (item) => item.processId == processMst?.id
    )[0];

    let detailItems = [];
    let editDetailItems = [];
    //溶接情報設定
    for (let j = 0; j < weldingProcessInput?.details?.[0]?.weldingList?.length; j++) {
      let weldingList = weldingProcessInput?.details?.[0]?.weldingList[j];
      let processTypeNm = processMst?.details?.processDetailTypes?.filter(
        (item) => item.id == weldingList.yousetsuType
      )?.[0];
      detailItems.push({
        id: j + 1,
        processName: '溶接処理(No' + (j + 1) + ')',
        processTypeId: processTypeNm?.id,
        processType: processTypeNm?.name,
        dandoriAmt: 0,
        dandoriTime: 0,
        sagyoAmt: 0,
        sagyoTime: 0,
        senchou: weldingList.senchou, //溶接加工長
        karitsukeCnt: weldingList.karitsukeCnt, //仮付
        senchouKaisu: weldingList.senchouKaisu, //溶接加工長回数
        karitsukeKaisu: weldingList.karitsukeKaisu, //仮付回数
        daimeter: 0, // 径
        number: 0, // 加工数
        count: 0, // 回数
        weldType: 0, // 0:溶接, 1:サブ溶接
        formula: '',
      });
    }

    //サブ溶接情報設定
    for (let j = 0; j < weldingProcessInput?.details?.[0]?.subWeldingList?.length; j++) {
      let subWeldingList = weldingProcessInput?.details?.[0]?.subWeldingList[j];
      let processTypeNm = processMst?.details?.processDetailTypes?.filter(
        (item) => item.id == subWeldingList.subWeldingType
      )?.[0];
      let detailItemsId = detailItems?.length == 0 ? 0 : detailItems?.length;
      detailItems.push({
        id: detailItemsId + 1,
        processName: '溶接処理(No' + (j + 1) + ')',
        processTypeId: processTypeNm?.id,
        processType: processTypeNm?.name,
        dandoriAmt: 0,
        dandoriTime: 0,
        sagyoAmt: 0,
        sagyoTime: 0,
        senchou: 0, //溶接加工長
        karitsukeCnt: 0, //仮付
        senchouKaisu: 0, //溶接加工長回数
        karitsukeKaisu: 0, //仮付回数
        daimeter: subWeldingList.daimeter, // 径
        number: subWeldingList.number, // 加工数
        count: subWeldingList.count, // 回数
        weldType: 1, // 0:溶接, 1:サブ溶接
        formula: '',
      });
    }

    editDetailItems = [];
    let weldingAddition = {
      no: processMst?.id,
      processId: processMst?.id,
      dataType: weldingAdditions?.dataType ? weldingAdditions?.dataType : DataType.Data,
      dataItems: detailItems,
      editItems: weldingAdditions?.editItems ? weldingAdditions?.editItems : editDetailItems,
      totalDandori: {
        dataItemsAmt: 0,
        dataItemsTime: 0,
        editItemsAmt: 0,
        editItemsTime: 0,
      },
      totalSagyo: {
        dataItemsAmt: 0,
        dataItemsTime: 0,
        editItemsAmt: 0,
        editItemsTime: 0,
      },
      totalDataPrice: 0,
      totalDataTime: 0,
      totalEditPrice: 0,
      totalEditTime: 0,
    };
    weldingAdditions = weldingAddition;

    if (weldingAdditions?.dataItems) {
      let orgData = JSON.parse(JSON.stringify(weldingAdditions?.dataItems));
      if (deviceMst != undefined) {
        let totalDandori = 0;
        let totalSagyo = 0;
        let totalSagyoTm = 0;
        let totalDandoriTm = 0;
        let dataItemsByProcessInput = [];
        // 数量
        let partCnt = props?.selectedEstimateData?.estimateProducts?.quantity;
        // 板厚
        let thickness = props?.selectedIQ3DataDetail?.thickness;
        // 重量
        let weight = props?.selectedIQ3DataDetail?.weight;
        // 面積
        let area = props?.selectedIQ3DataDetail?.totalSurfaceArea;
        //材質
        let materialTypeIq3Id = props?.selectedIQ3DataDetail?.materialTypeIq3Id;

        //材質区分
        let materialTypeId = parameters?.materialTypeIq3?.filter((item) => item.id == materialTypeIq3Id)?.[0]
          ?.materialTypeId;

        // 選択された部品の板厚により難易度係数を取得
        let diffCoef = getDiffCoef(deviceMst, thickness, materialTypeId);
        // サイズ係数
        let sizeCoef = getSizeCoeff(deviceMst, weight, area);

        //溶接情報設定
        let mainWeldAdditions = orgData?.filter((item) => item.weldType == 0);
        for (let i = 0; i < weldingProcessInput?.details?.[0]?.weldingList?.length; i++) {
          //工程入力情報
          let weldingProcessInputInfo = weldingProcessInput?.details?.[0]?.weldingList[i];
          let dataItemByProcessInput = mainWeldAdditions[i];

          /** 段取金額/時間 */
          // 基本段取時間＊サイズ係数
          let dandoriTimeSec = calDandoriTime(deviceMst, weight, area, weldingProcessInputInfo?.yousetsuType);
          let dandoriTime = dandoriTimeSec;
          dataItemByProcessInput.dandoriTime = dandoriTime;
          // 段取時間の合計
          totalDandoriTm += dandoriTime;

          // 段取金額設定
          //段階時間
          let mSetTimeMin = dandoriTimeSec / 60;
          //装置の段階時間チャージ
          let deviceCharges = Object.entries(deviceMst.details?.charges)?.filter(
            ([key, data]) => parseInt(key) === weldingProcessInputInfo?.yousetsuType
          )?.[0];
          let mSetHRate = 0;
          deviceCharges?.map((item, index) => {
            if (index > 0) {
              mSetHRate = item?.prep;
            }
          });
          let dandoriAmt = (mSetTimeMin * mSetHRate) / 60.0; //段階時間＊時間チャージ
          dataItemByProcessInput.dandoriAmt = dandoriAmt;
          // 段取金額の合計
          totalDandori += dandoriAmt;

          // 作業時間（溶接時間＋仮付時間）

          // 加工長
          let sagyoLen = weldingProcessInputInfo?.senchou;
          // 溶接線長により該当種類の加工速度を取得
          let sagyoSpeed = getSagyoSpeed(deviceMst, sagyoLen, weldingProcessInputInfo?.yousetsuType);

          // 溶接時間（加工長*回数/加工速度＊難易度係数＊サイズ係数）
          let totalSagyoLen = sagyoLen * weldingProcessInputInfo?.senchouKaisu;
          let sagyoTimeSec = Number.isFinite(totalSagyoLen / sagyoSpeed)
            ? (totalSagyoLen / sagyoSpeed) * diffCoef * sizeCoef
            : 0;
          // 仮付時間
          let tempAttachTimeSecParm = getTempAttachTime(deviceMst, thickness, weldingProcessInputInfo?.yousetsuType);
          // 仮付時間（仮付時間＊数量＊難易度係数＊サイズ係数）＊仮付回数
          let tempAttachTimeSec =
            tempAttachTimeSecParm * partCnt * diffCoef * sizeCoef * weldingProcessInputInfo?.karitsukeKaisu;
          // 作業時間（溶接時間＋仮付時間）
          dataItemByProcessInput.sagyoTime = sagyoTimeSec + tempAttachTimeSec;
          totalSagyoTm += dataItemByProcessInput.sagyoTime;

          // 作業金額
          let mSagyoTimeMin = dataItemByProcessInput.sagyoTime / 60;
          //装置の作業時間チャージ
          let mSagyoHRate = 0;
          deviceCharges?.map((item, index) => {
            if (index > 0) {
              mSetHRate = item?.work;
            }
          });
          let sagyoAmt = (mSagyoTimeMin * mSagyoHRate) / 60.0; //作業時間＊時間チャージ
          dataItemByProcessInput.sagyoAmt = sagyoAmt;
          // 作業金額の合計
          totalSagyo += sagyoAmt;
          dataItemsByProcessInput.push(dataItemByProcessInput);
        }

        //サブ溶接情報設定
        let subWeldAdditions = orgData?.filter((item) => item.weldType == 1);
        for (let i = 0; i < weldingProcessInput?.details?.[0]?.subWeldingList?.length; i++) {
          //工程入力情報
          let weldingProcessInputInfo = weldingProcessInput?.details?.[0]?.subWeldingList[i];
          let dataItemByProcessInput = subWeldAdditions[i];

          /** 段取金額/時間 */
          // 基本段取時間＊サイズ係数
          let dandoriTimeSec = calDandoriTime(deviceMst, weight, area, weldingProcessInputInfo?.subWeldingType);
          let dandoriTime = dandoriTimeSec;
          dataItemByProcessInput.dandoriTime = dandoriTime;
          // 段取時間の合計
          totalDandoriTm += dandoriTime;

          // 段取金額設定
          //段階時間
          let mSetTimeMin = dandoriTimeSec / 60;
          //装置の段階時間チャージ
          let deviceCharges = Object.entries(deviceMst.details?.charges)?.filter(
            ([key, data]) => parseInt(key) === weldingProcessInputInfo?.subWeldingType
          )?.[0];
          let mSetHRate = 0;
          deviceCharges?.map((item, index) => {
            if (index > 0) {
              mSetHRate = item?.prep;
            }
          });
          let dandoriAmt = (mSetTimeMin * mSetHRate) / 60.0; //段階時間＊時間チャージ
          dataItemByProcessInput.dandoriAmt = dandoriAmt;
          // 段取金額の合計
          totalDandori += dandoriAmt;

          // 作業時間（サブ溶接時間＊数量＊難易度係数＊サイズ係数）
          // 作業時間（サブ溶接時間）
          let subWeldTm = getSubWebTm(
            deviceMst,
            thickness,
            weldingProcessInputInfo?.subWeldingGroupType,
            weldingProcessInputInfo?.subWeldingType
          );
          // 作業時間（サブ溶接時間＊数量＊難易度係数＊サイズ係数）
          dataItemByProcessInput.sagyoTime = subWeldTm * partCnt * diffCoef * sizeCoef;

          // 作業金額
          let mSagyoTimeMin = dataItemByProcessInput.sagyoTime / 60;
          //装置の作業時間チャージ
          let mSagyoHRate = 0;
          deviceCharges?.map((item, index) => {
            if (index > 0) {
              mSetHRate = item?.work;
            }
          });
          let sagyoAmt = (mSagyoTimeMin * mSagyoHRate) / 60.0; //作業時間＊時間チャージ
          dataItemByProcessInput.sagyoAmt = sagyoAmt;
          // 作業金額の合計
          totalSagyo += sagyoAmt;
          dataItemsByProcessInput.push(dataItemByProcessInput);
        }

        // 段取金額の合計
        setDandoriAmtSum(totalDandori);
        weldingAdditions.totalDandori.dataItemsAmt = totalDandori;
        // 段取時間の合計
        setDandoriTimeSum(totalDandoriTm);
        weldingAdditions.totalDandori.dataItemsTime = totalDandoriTm;
        // 作業金額の合計
        setSagyoAmtSum(totalSagyo);
        weldingAdditions.totalSagyo.dataItemsAmt = totalSagyo;

        setSagyoTimeSum(totalSagyoTm);
        weldingAdditions.totalSagyo.dataItemsTime = totalSagyoTm;

        /** 　合計時間　*/
        let totalTimeSum = totalDandoriTm;
        setTotalTimeSum(totalTimeSum);
        weldingAdditions.totalDataTime = totalTimeSum;

        /** 　合計金額　*/
        let totalSum = totalDandori + totalSagyo;
        setTotalSum(totalSum);
        weldingAdditions.totalDataPrice = totalSum;

        orgData = dataItemsByProcessInput;
        setProcessArr(dataItemsByProcessInput);

        if (weldingAdditions.dataType == DataType.Edit) {
          toBEdit = JSON.parse(JSON.stringify(weldingAdditions?.editItems));
        } else {
          toBEdit = JSON.parse(JSON.stringify(orgData));
        }
      }
    }
    if (toBEdit?.length > 0) {
      editData = toBEdit;
      weldingAdditions.editItems = editData;
      if (editData?.length > 0) {
        setEditedData(editData);
        totals = calculateTotals(editData);

        setTotalAmt(totals[0]?.totalSum);
        weldingAdditions.totalEditPrice = totals[0]?.totalSum;
        weldingAdditions.totalDandori.editItemsAmt = totals[0]?.totalSum;

        setTotalTime(totals[0]?.totalTimeSum);
        weldingAdditions.totalEditTime = totals[0]?.totalTimeSum;
        weldingAdditions.totalDandori.editItemsTime = totals[0]?.totalTimeSum;

        setTotalSagyoAmt(totals[0]?.sagyoAmtSum);
        setTotalSagyoTime(totals[0]?.sagyoTimeSum);

        setTotalDandoriAmt(totals[0]?.dandoriAmtSum);
        setTotalDandoriTime(totals[0]?.dandoriTimeSum);
      }
      setUpdateEditedData(weldingAdditions);
    }
    if (weldingAdditions?.dataType == DataType.Data) {
      setWeldingAmt(weldingAdditions.totalDataPrice);
    } else {
      setWeldingAmt(weldingAdditions.totalEditPrice);
    }
    props?.updateIQ3KouteiCal(weldingAdditions, WorkType.SmWelding);
  }, [props?.activeTabKey]);

  const yousetsu = () => {
    if (isYousetsudetail == true) {
      setIYousetsudetail(false);
    } else {
      setIYousetsudetail(true);
    }
  };

  useEffect(() => {
    setEditMode(props?.editMode != undefined ? props?.editMode : false);
  }, [props?.editMode]);

  const calDandoriTime = (deviceMst, weight, area, weldTypeId) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    // 段取時間設定

    // 重量
    let childPartsCoeffWeightThMaster = deviceMst?.details?.childPartsCoeffWeightThMaster?.filter(
      (item) => item.value == weight || item.value > weight
    );
    // 面積
    let childPartsCoeffAreaThMaster = deviceMst?.details?.childPartsCoeffAreaThMaster?.filter(
      (item) => item.value == area || item.value > area
    );
    let childPartsCoeffs =
      childPartsCoeffWeightThMaster?.length > 0 && childPartsCoeffAreaThMaster?.length > 0
        ? deviceMst?.details?.childPartsCoeff?.filter((item) => item.weightId == childPartsCoeffWeightThMaster[0]?.no)
        : deviceMst?.details?.childPartsCoeff[0];

    // サイズ係数
    let partsCoeff =
      childPartsCoeffs?.length > 0
        ? childPartsCoeffs?.[0]?.areaCoeffList?.filter((item) => item.no == childPartsCoeffAreaThMaster?.[0]?.no)?.[0]
            ?.value
        : childPartsCoeffs?.[0]?.areaCoeffList?.[0]?.value;

    //　基本段取時間＊サイズ係数
    let deviceDandori = Object.entries(deviceMst.details?.prepSecs)?.filter(
      ([key, data]) => parseInt(key) === weldTypeId
    )?.[0];
    let deviceDandoriTm = 0;
    deviceDandori?.map((item, index) => {
      if (index > 0) {
        deviceDandoriTm = item;
      }
    });

    let dandoriTimeSec = deviceDandoriTm * partsCoeff;
    return dandoriTimeSec;
  };

  const calDandoriAmt = (dandoriTimeSec, deviceMst) => {
    //段階時間
    let mSetTimeMin = dandoriTimeSec / 60;
    //装置の段階時間チャージ
    // let mSetHRate = deviceMst.details?.charges.prep;
    let mSetHRate = 10000;
    //段階時間＊時間チャージ
    let dandoriAmt = (mSetTimeMin * mSetHRate) / 60.0;
    return dandoriAmt;
  };

  const calSagyoTime = (deviceMst, weight, area, processCount) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    // 作業時間
    // 重量
    let prodSizeWeightThMaster = deviceMst?.details?.prodSizeWeightThMaster?.filter(
      (item) => item.value == weight || item.value > weight
    );
    // 面積
    let prodSizeAreaThMaster = deviceMst?.details?.prodSizeAreaThMaster?.filter(
      (item) => item.value == area || item.value > area
    );
    let prodSizeItems =
      prodSizeWeightThMaster?.length > 0 && prodSizeAreaThMaster?.length > 0
        ? deviceMst?.details?.prodSizeItems?.filter((item) => item.weightId == prodSizeWeightThMaster[0]?.no)
        : deviceMst?.details?.prodSizeItems[0];

    // サイズ係数
    let partsCoeff =
      prodSizeItems?.length > 0
        ? prodSizeItems?.[0]?.areaCoeffList?.filter((item) => item.no == prodSizeAreaThMaster?.[0]?.no)?.[0]?.value
        : prodSizeItems?.[0]?.areaCoeffList?.[0]?.value;

    //　基本段取時間＊検査数＊サイズ係数
    let dandoriTimeSec = processCount * partsCoeff;
    return dandoriTimeSec;
  };

  const calSagyoAmt = (cSagyoTimeKonpoSec, deviceMst) => {
    // 作業金額
    // 作業時間
    let mWorkHTime = cSagyoTimeKonpoSec;
    let mWorkHTimeMin = mWorkHTime / 60;
    //装置の作業時間チャージ
    // let mWorkHRate = deviceMst.details?.charges.work;
    let mWorkHRate = 10000;
    //　加工時間＊時間チャージ
    let cSagyoAmtKonpo = (mWorkHTimeMin * mWorkHRate) / 60.0;
    return cSagyoAmtKonpo;
  };

  const getSagyoSpeed = (deviceMst, sencho, weldTypeId) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    // 加工速度
    // 溶接線長
    let workSpeedThMaster = deviceMst?.details?.workSpeedThMaster?.filter(
      (item) => item.value == sencho || item.value > sencho
    )?.[0];

    let workSpeedThItems = Object.entries(deviceMst.details?.workSpeedThItems)?.filter(
      ([key, data]) => parseInt(key) === workSpeedThMaster?.no
    )?.[0];

    // 加工速度
    let childPartsCoeff = 0;
    workSpeedThItems?.map((item, index) => {
      if (index > 0) {
        childPartsCoeff = item?.filter((item) => parseInt(item.no) == weldTypeId)?.[0]?.value;
      }
    });
    return childPartsCoeff;
  };

  const getDiffCoef = (deviceMst, thickness, materialTypeId) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    // 溶接難易度
    let weldDifficultyCoeffThMaster = deviceMst?.details?.weldDifficultyCoeffThMaster?.filter(
      (item) => item.value == thickness || item.value > thickness
    )?.[0];

    let weldDifficultyCoeffThItems = Object.entries(deviceMst.details?.weldDifficultyCoeffThItems)?.filter(
      ([key, data]) => parseInt(key) === weldDifficultyCoeffThMaster?.no
    )?.[0];

    // 溶接難易度
    let childPartsCoeff = 0;
    weldDifficultyCoeffThItems?.map((item, index) => {
      if (index > 0) {
        childPartsCoeff = item?.filter((item) => parseInt(item.no) == materialTypeId)?.[0]?.value;
      }
    });
    return childPartsCoeff;
  };

  const getSizeCoeff = (deviceMst, weight, area) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    // 段取時間設定

    // 重量
    let prodSizeWeightThMaster = deviceMst?.details?.prodSizeWeightThMaster?.filter(
      (item) => item.value == weight || item.value > weight
    );
    // 面積
    let prodSizeAreaThMaster = deviceMst?.details?.prodSizeAreaThMaster?.filter(
      (item) => item.value == area || item.value > area
    );
    let childPartsCoeffs =
      prodSizeWeightThMaster?.length > 0 && prodSizeAreaThMaster?.length > 0
        ? deviceMst?.details?.prodSizeItems?.filter((item) => item.weightId == prodSizeWeightThMaster[0]?.no)
        : deviceMst?.details?.prodSizeItems[0];

    // サイズ係数
    let partsCoeff =
      childPartsCoeffs?.length > 0
        ? childPartsCoeffs?.[0]?.areaCoeffList?.filter((item) => item.no == prodSizeAreaThMaster?.[0]?.no)?.[0]?.value
        : childPartsCoeffs?.[0]?.areaCoeffList?.[0]?.value;

    return partsCoeff;
  };

  const getTempAttachTime = (deviceMst, thickness, weldTypeId) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;

    let tempInstThMaster = deviceMst?.details?.tempInstThMaster?.filter(
      (item) => item.value == thickness || item.value > thickness
    )?.[0];

    let tempInstThItems = Object.entries(deviceMst.details?.tempInstThItems)?.filter(
      ([key, data]) => parseInt(key) === tempInstThMaster?.no
    )?.[0];

    // 仮付け時間
    let tempAttachTime = 0;
    tempInstThItems?.map((item, index) => {
      if (index > 0) {
        tempAttachTime = item?.filter((item) => parseInt(item.no) == weldTypeId)?.[0]?.value;
      }
    });
    return tempAttachTime;
  };

  const getSubWebTm = (deviceMst, thickness, weldTypeId, weldTypeGroupId) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    let tempInstThMaster = [];
    let tempInstThItems = [];
    if (weldTypeGroupId == WorkTypeGroup.SmInstThick) {
      tempInstThMaster = deviceMst?.details?.instThickThMaster?.filter(
        (item) => item.value == thickness || item.value > thickness
      )?.[0];

      tempInstThItems = Object.entries(deviceMst.details?.instThickThItems)?.filter(
        ([key, data]) => parseInt(key) === tempInstThMaster?.no
      )?.[0];
    } else {
      tempInstThMaster = deviceMst?.details?.instSizeThMaster?.filter(
        (item) => item.value == thickness || item.value > thickness
      )?.[0];

      tempInstThItems = Object.entries(deviceMst.details?.instSizeThItems)?.filter(
        ([key, data]) => parseInt(key) === tempInstThMaster?.no
      )?.[0];
    }

    // 仮付け時間
    let tempAttachTime = 0;
    tempInstThItems?.map((item, index) => {
      if (index > 0) {
        tempAttachTime = item?.filter((item) => parseInt(item.no) == weldTypeId)?.[0]?.value;
      }
    });
    return tempAttachTime;
  };

  const getYousetsuTypes = () => {
    let subYousetsus = [];
    let yousetsus = [];
    const kouteiMasterData = JSON.parse(localStorage.getItem('kouteiMasterData'))?.forEach((e) => {
      if (e.processType === '溶接' && e.type === 'iQ3') {
        e.processDetail?.forEach((processDetail) => {
          if (
            processDetail.processDetailTitle === '取付種類（板厚判断）' ||
            processDetail.processDetailTitle === '取付種類（サイズ判断）'
          ) {
            processDetail.processDetailValue?.forEach((detailVal) => {
              subYousetsus.push(detailVal.detailType);
            });
          } else if (processDetail.processDetailTitle === '溶接種類') {
            processDetail.processDetailValue?.forEach((detailVal) => {
              yousetsus.push(detailVal.detailType);
            });
          }
        });
      }
    });
    setSubYousetsuArr(subYousetsus);
    setYousetsuArr(yousetsus);
  };

  const subpartDetail = (i) => {
    let showHide = document.getElementById('yosetsuProcessIQ3' + i).style.display;
    if (showHide == 'block') {
      document.getElementById('yosetsuProcessIQ3' + i).style.display = 'none';
      document.getElementById('arrowDownYosetsuIQ3' + i).style.display = 'block';
      document.getElementById('arrowUpYosetsuIQ3' + i).style.display = 'none';
    } else {
      document.getElementById('yosetsuProcessIQ3' + i).style.display = 'block';
      document.getElementById('arrowDownYosetsuIQ3' + i).style.display = 'none';
      document.getElementById('arrowUpYosetsuIQ3' + i).style.display = 'block';
    }
  };

  const setEditModeChange = (mode) => {
    setEditMode(mode);
  };
  useImperativeHandle(ref, () => ({
    setEditModeChange: setEditModeChange,
  }));

  const settingNewDandoriAmtInArr = (value, index, objId, timeType, timeAct) => {
    let newState = [...editedData];
    let oldValue = editedData[index][objId];
    if (value === undefined) {
      value = 0;
    }
    newState[index][objId] = Number(value);
    updateEditedData.editItems[index][objId] = newState[index][objId];

    /* if (objId !== 'handelingTime' && objId !== 'kensaTime') {
      oldValue = editedData[index][objId];
      if (value === undefined) {
        value = 0;
      } 
    } else {
      if (objId === 'handelingTime') {
        oldValue = editedHandelingTime;
        setEditedHandelingTime(value);
      } else if (objId === 'kensaTime') {
        oldValue = editedKensaTime;
        setEditedHandelingTime(value);
      }
    } */

    if (objId === 'dandoriTime') {
      if (document.getElementById(index + 'YousetsuDandoriAmtCu').style.display !== undefined) {
        let showHideCu = document.getElementById(index + 'YousetsuDandoriAmtCu').style.display;
        if (showHideCu === 'block') {
          checkInputWithColor(false, '', index + 'dandoriAmt', newState[index].dandoriAmt, 'red');
        } else {
          if (timeAct === 'input') {
            let timeType = setTimetoSec(value) - setTimetoSec(oldValue);
            newState[index].dandoriAmt = changeAmtwithTime(newState[index].dandoriAmt, timeType, timeAct, value, objId);
            updateEditedData.editItems[index].dandoriAmt = newState[index].dandoriAmt;
          } else {
            newState[index].dandoriAmt = changeAmtwithTime(newState[index].dandoriAmt, timeType, timeAct, value, objId);
            updateEditedData.editItems[index].dandoriAmt = newState[index].dandoriAmt;
          }
          // checkInputWithColor(false, '', index + 'dandoriAmt', newState[index].dandoriAmt, 'green');
        }
      }
    } else if (objId === 'sagyoTime') {
      checkInputWithColor(false, '', index + 'yosetsuSagyoAmt', newState[index].sagyoAmt, 'green');
      if (document.getElementById(index + 'YosetsuSagyoAmtCu').style.display !== undefined) {
        let showHideCu = document.getElementById(index + 'YosetsuSagyoAmtCu').style.display;
        if (showHideCu === 'block') {
          checkInputWithColor(false, '', index + 'yosetsuSagyoAmt', newState[index].sagyoAmt, 'red');
        } else {
          if (timeAct === 'input') {
            let timeType = setTimetoSec(value) - setTimetoSec(oldValue);
            newState[index].sagyoAmt = changeAmtwithTime(newState[index].sagyoAmt, timeType, timeAct, value, objId);
            updateEditedData.editItems[index].sagyoAmt = newState[index].sagyoAmt;
          } else {
            newState[index].sagyoAmt = changeAmtwithTime(newState[index].sagyoAmt, timeType, timeAct, value, objId);
            updateEditedData.editItems[index].sagyoAmt = newState[index].sagyoAmt;
          }
          checkInputWithColor(false, '', index + 'yosetsuSagyoAmt', newState[index].sagyoAmt, 'green');
        }
      }
    }

    let newSagyoSum = 0;
    let newDandoriSum = 0;
    let newSum = 0;
    let newSubSum = 0;
    let newSagyoTimeSec = 0;
    let newDandoriTimeSec = 0;

    newState?.forEach((element) => {
      newSagyoSum += parseFloat(element.sagyoAmt);
      newDandoriSum += parseFloat(element.dandoriAmt);
      newSagyoTimeSec += element.sagyoTime;
      newDandoriTimeSec += element.dandoriTime;
    });

    /* newSagyoSum += parseFloat(newState[0].handelingAmt);
    newSagyoTimeSec += setTimetoSec(newState[0].handelingTime); */
    newSum = (parseFloat(newSagyoSum) + parseFloat(newDandoriSum)).toFixed(0);

    let newSagyoTimeSum = newSagyoTimeSec;
    let newDandoriTimeSum = newDandoriTimeSec;
    let newTotalTimeSum = newSagyoTimeSec + newDandoriTimeSec;

    /* checkInputWithColor(false,'',index + 'subTotal', newSubSum, 'green');
    checkInputWithColor(false,'',index + 'subTotalTime', newSubTimeSum, 'green'); */
    checkInputWithColor(false, '', 'totalSagyoAmt', newSagyoSum, 'green');
    checkInputWithColor(false, '', 'totalDandoriAmt', newDandoriSum, 'green');
    checkInputWithColor(false, '', 'totalAmt', newSum, 'green');
    checkInputWithColor(false, '', 'totalSagyoTime', newSagyoTimeSum, 'green');
    checkInputWithColor(false, '', 'totalDandoriTime', newDandoriTimeSum, 'green');
    checkInputWithColor(false, '', 'totalTime', newTotalTimeSum, 'green');
    setTotalSagyoAmt(newSagyoSum);
    setTotalDandoriAmt(newDandoriSum);
    setTotalAmt(newSum);
    setTotalSagyoTime(newSagyoTimeSum);
    setTotalDandoriTime(newDandoriTimeSum);
    setTotalTime(newTotalTimeSum);
    setEditedData(newState);

    updateEditedData.totalEditPrice = newSum;
    updateEditedData.totalEditTime = newTotalTimeSum;
    updateEditedData.totalDandori.editItemsAmt = newDandoriSum;
    updateEditedData.totalDandori.editItemsTime = newDandoriTimeSum;
    updateEditedData.totalSagyo.editItemsAmt = newSagyoSum;
    updateEditedData.totalSagyo.editItemsTime = newSagyoTimeSum;
    if (updateEditedData.dataType == DataType.Data) {
      setWeldingAmt(updateEditedData.totalDataPrice);
    } else {
      setWeldingAmt(updateEditedData.totalEditPrice);
    }
    props?.updateIQ3KouteiCal(updateEditedData, WorkType.SmWelding);
  };

  const checkStr = (val) => {
    let intAmt;
    if (val === '') {
      intAmt = 0;
    } else if (typeof val === 'string') {
      intAmt = parseFloat(val.charAt(0) == '¥' ? val.replace('¥', '') : val);
    } else intAmt = val;
    return intAmt;
  };

  const changeAmtwithTime = (amt, typeTime, action, timeVal, processType) => {
    let editedAmt;
    let totalSec = Number(timeVal);
    let intAmt = checkStr(amt);
    //let deviceMst = [];

    if (processType === 'dandoriTime') {
      // 段取金額
      //　段取時間＊時間チャージ
      editedAmt = calDandoriAmt(totalSec, deviceMst);
    } else if (processType === 'sagyoTime') {
      // 作業金額
      //　加工時間＊時間チャージ
      editedAmt = calSagyoAmt(totalSec, deviceMst);
    }

    return editedAmt;
  };

  return (
    <div className="kouteiCalculateDiv">
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row " onClick={yousetsu}>
        <Col span={4} className="oyabuhin-col">
          <div style={{ display: 'flex' }}>
            {isYousetsudetail ? (
              <CaretUpOutlined style={{ fontSize: 17, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 17, marginTop: 2 }} />
            )}
            <label style={{ marginLeft: 5 }}>{processMst?.name}</label>
          </div>
        </Col>
        <Col span={6} className="oyabuhin-col">
          <div>{weldingAmt ? JPYs.format(Math.floor(weldingAmt)) : amtEmptyStr}</div>
        </Col>
        <Col span={13} style={{ border: 'none' }} className="oyabuhin-col">
          <div>
            <label style={{ marginLeft: 5 }}>{deviceMst?.name}</label>
          </div>
        </Col>
      </Row>
      <div style={{ display: isYousetsudetail ? 'block' : 'none' }}>
        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col">
            工程詳細
          </Col>
          <Col span={6} className="oyabuhin-detail-col">
            <Checkbox
              key={kouteiCalculateSelectedArr[0].key}
              value={kouteiCalculateSelectedArr[0].value}
              checked={kouteiCalculateSelectedArr[0].key == updateEditedData.dataType}
              disabled={!props?.editMode}
              style={{ marginRight: 6 }}
              onChange={(e) => props?.editMode && handleChangeCheckData(kouteiCalculateSelectedArr[0].key)}
            ></Checkbox>
            <label>{kouteiCalculateSelectedArr[0].value}</label>
          </Col>
          <Col span={8} className="oyabuhin-detail-col">
            <Checkbox
              key={kouteiCalculateSelectedArr[1].key}
              value={kouteiCalculateSelectedArr[1].value}
              disabled={!props?.editMode}
              onChange={(e) => props?.editMode && handleChangeCheckData(kouteiCalculateSelectedArr[1].key)}
              checked={kouteiCalculateSelectedArr[1].key == updateEditedData.dataType}
              style={{ marginRight: 6 }}
            ></Checkbox>
            <label>{kouteiCalculateSelectedArr[1].value}</label>
          </Col>

          <Col span={1} className="oyabuhin-detail-col">
            単位
          </Col>
          <Col span={5} className="formula-column">
            計算式
          </Col>
        </Row>
        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col">
            {processMst?.name}
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalAmt">{totalSum ? JPYs.format(Math.floor(totalSum)) : amtEmptyStr}</label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalTime">{totalTimeSum ? secondsToHms(totalTimeSum) : timeHmsStr}</label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalAmtIn" className="resetColor">
              {totalAmt ? JPYs.format(Math.floor(totalAmt)) : amtEmptyStr}
            </label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalTimeIn" className="resetColor">
              {totalTime ? secondsToHms(totalTime) : timeHmsStr}
            </label>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={5} className="formula-column"></Col>
        </Row>
        {/* 段取金額/時間 */}
        <Row
          style={{ color: 'black' }}
          className="oyabuhin-detail oyabuhin-row"
          onClick={(e) => handleCollpse('YousetsuDandori')}
        >
          <Col span={4} className="oyabuhin-detail-col">
            <div style={{ display: 'flex' }}>
              <CaretUpOutlined id={'arrowUpYousetsuDandori'} style={{ fontSize: 17, marginTop: 2, display: 'none' }} />

              <CaretDownOutlined id={'arrowDownYousetsuDandori'} style={{ fontSize: 17, marginTop: 2 }} />
              <label style={{ marginLeft: 5 }}> 段取金額/時間</label>
            </div>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalDandoriAmt">{dandoriAmtSum ? JPYs.format(Math.floor(dandoriAmtSum)) : amtEmptyStr}</label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalDandoriTime">{dandoriTimeSum ? secondsToHms(dandoriTimeSum) : timeHmsStr}</label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalDandoriAmtIn" className="resetColor">
              {totalDandoriAmt ? JPYs.format(Math.floor(totalDandoriAmt)) : amtEmptyStr}
            </label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalDandoriTimeIn" className="resetColor">
              {totalDandoriTime ? secondsToHms(totalDandoriTime) : timeHmsStr}
            </label>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={5} className="formula-column">
            <Popover content={contentDandori}>段取金額の総合計</Popover>
          </Col>
        </Row>
        <div id={'divYousetsuDandori'} style={{ display: 'none' }}>
          {(() => {
            const arr = [];
            for (let j = 0; j < processArr?.length; j++) {
              arr.push(
                <div>
                  {/* ○○_標準 */}
                  <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                    <Col span={4} className="oyabuhin-detail-col">
                      <div style={{ display: 'flex' }}>
                        <span style={{ marginLeft: '20px' }}>{processArr[j]?.processType}</span>
                      </div>
                    </Col>
                    <Col span={3} className="oyabuhin-detail-col">
                      <label id={j + 'YousetsuDandoriAmt'}>
                        {processArr[j]?.dandoriAmt && processArr[j]?.dandoriAmt > 0
                          ? JPYs.format(Math.floor(processArr[j]?.dandoriAmt))
                          : amtEmptyStr}
                      </label>
                    </Col>
                    <Col span={3} className="oyabuhin-detail-col">
                      {
                        <label id={j + 'YousetsuDndoriTime'}>
                          {processArr[j]?.dandoriTime ? secondsToHms(processArr[j]?.dandoriTime) : timeHmsStr}
                        </label>
                      }
                    </Col>

                    <Col span={4} className="input-col">
                      <CurrencyInput
                        id={j + 'YousetsuDandoriAmtIn'}
                        value={editedData[j]?.dandoriAmt ? Math.floor(editedData[j]?.dandoriAmt) : 0}
                        defaultValue={0}
                        style={{
                          border: 'none',
                          color:
                            processArr[j]?.dandoriAmt === editedData[j]?.dandoriAmt
                              ? 'black'
                              : checkShowHideIcon(j + 'YousetsuDandoriAmtCu') === 'none'
                              ? '#4cc12f'
                              : 'red',
                        }}
                        onValueChange={(e) => {
                          //checkRegex(e.target.value);
                          if (editedData[j]?.dandoriAmt != e) {
                            settingNewDandoriAmtInArr(e, j, 'dandoriAmt', '', '');
                            checkInputWithColor(false, '', j + 'YousetsuDandoriAmt', e, 'amt');
                          }
                        }}
                        className={
                          editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'
                        }
                        prefix="¥"
                        decimalsLimit={100}
                      />
                      <div id={j + 'YousetsuDandoriAmtCu'} style={{ display: 'none' }}>
                        <div className="currencyIcon">
                          <BsUsbC style={{ fontSize: '25' }} />
                          <BsX style={{ margin: '-8px 0 0 16px' }} />
                          <span class="tooltiptext">
                            金額に変更がありましたので、以降時間と連携して自動計算されない。
                          </span>
                        </div>
                      </div>
                    </Col>

                    <Col span={4} className="input-col">
                      <Row style={{ width: '100%' }}>
                        <Col className="timer-input-col">
                          <Input
                            value={editedData[j]?.dandoriTime ? secondsToHms(editedData[j]?.dandoriTime) : timeHmsStr}
                            id={j + 'YosetsuDandoriTimeIn'}
                            style={{
                              width: '100%',
                              color:
                                processArr[j]?.dandoriTime === editedData[j]?.dandoriTime
                                  ? handleShowHide(
                                      parseFloat(editedData[j]?.dandoriAmt),
                                      editedData[j]?.dandoriAmt,
                                      j + 'YosetsuDandoriAmtCu'
                                    )
                                  : 'red',
                            }}
                            className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                            onChange={(e) => {
                              settingNewDandoriAmtInArr(e.target.value, j, 'YousetsudandoriAmt', 'input', 'input');
                            }}
                          ></Input>
                        </Col>
                        <Col className="timer-button-col">
                          {editMode ? (
                            <>
                              <CaretUpOutlined
                                style={{ fontSize: 17 + 'px' }}
                                onClick={() => {
                                  let editedTime = handelIncreaseTime(
                                    editedData[j]?.dandoriTime,
                                    '10min',
                                    j + 'YosetsuDandoriTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', '10min', 'inc');
                                }}
                              />
                              <CaretDownOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelDecreaseTime(
                                    editedData[j]?.dandoriTime,
                                    '10min',
                                    j + 'YosetsuDandoriTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', '10min', 'dec');
                                }}
                              />
                              <CaretUpOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelIncreaseTime(
                                    editedData[j]?.dandoriTime,
                                    'min',
                                    j + 'YosetsuDandoriTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'min', 'inc');
                                }}
                              />
                              <CaretDownOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelDecreaseTime(
                                    editedData[j]?.dandoriTime,
                                    'min',
                                    j + 'YosetsuDandoriTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'min', 'dec');
                                }}
                              />
                              <CaretUpOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelIncreaseTime(
                                    editedData[j]?.dandoriTime,
                                    'sec',
                                    j + 'YosetsuDandoriTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'sec', 'inc');
                                }}
                              />
                              <CaretDownOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelDecreaseTime(
                                    editedData[j]?.dandoriTime,
                                    'sec',
                                    j + 'YosetsuDandoriTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'sec', 'dec');
                                }}
                              />
                            </>
                          ) : (
                            <></>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col span={1} className="oyabuhin-detail-col"></Col>
                    <Col span={5} className="formula-column"></Col>
                  </Row>
                </div>
              );
            }
            return arr;
          })()}
        </div>
        {/*  作業金額/時間 */}
        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col" onClick={(e) => handleCollpse('YousetsuSagyoTotal')}>
            <div style={{ display: 'flex' }}>
              <CaretUpOutlined
                id={'arrowUpYousetsuSagyoTotal'}
                style={{ fontSize: 17, marginTop: 2, display: 'none' }}
              />

              <CaretDownOutlined id={'arrowDownYousetsuSagyoTotal'} style={{ fontSize: 17, marginTop: 2 }} />

              <label style={{ marginLeft: 5 }}> 作業金額/時間</label>
            </div>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalSagyoAmt">{sagyoAmtSum ? JPYs.format(Math.floor(sagyoAmtSum)) : amtEmptyStr}</label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalSagyoTime">{sagyoTimeSum ? secondsToHms(sagyoTimeSum) : timeHmsStr}</label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalSagyoAmtIn" className="resetColor">
              {totalSagyoAmt ? JPYs.format(Math.floor(totalSagyoAmt)) : amtEmptyStr}
            </label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalSagyoTimeIn" className="resetColor">
              {totalSagyoTime ? secondsToHms(totalSagyoTime) : timeHmsStr}
            </label>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={5} className="formula-column">
            <Popover content={contentSagyo}>作業金額の総合計</Popover>
          </Col>
        </Row>
        <div id={'divYousetsuSagyoTotal'} style={{ display: 'none' }}>
          {(() => {
            const arr = [];

            for (let i = 0; i < editedData?.length; i++) {
              arr.push(
                <div>
                  <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                    <Col span={4} className="oyabuhin-detail-col">
                      <div style={{ display: 'flex', marginLeft: '20px' }}>
                        <CaretUpOutlined
                          id={'arrowUpYosetsuIQ3' + i}
                          style={{ fontSize: 17, marginTop: 2 }}
                          onClick={(e) => subpartDetail(i)}
                        />
                        <CaretDownOutlined
                          id={'arrowDownYosetsuIQ3' + i}
                          style={{ fontSize: 17, marginTop: 2, display: 'none' }}
                          onClick={(e) => subpartDetail(i)}
                        />
                        <span style={{ marginLeft: '5px' }}>{processArr[i]?.processType}</span>
                      </div>
                    </Col>
                    <Col span={3} className="oyabuhin-detail-col">
                      {/* ¥ */}
                      <label id={i + 'yosetsuSagyoAmt'}>
                        {processArr[i]?.sagyoAmt ? JPYs.format(Math.floor(processArr[i]?.sagyoAmt)) : amtEmptyStr}
                      </label>
                    </Col>
                    <Col span={3} className="oyabuhin-detail-col">
                      <label id={i + 'yosetsuSagyoTime'}>
                        {processArr[i]?.sagyoTime ? secondsToHms(processArr[i]?.sagyoTime) : timeHmsStr}
                      </label>
                    </Col>
                    <Col span={4} className="input-col">
                      <CurrencyInput
                        id={i + 'yosetsuSagyoAmtIn'}
                        value={editedData[i]?.sagyoAmt ? Math.floor(editedData[i]?.sagyoAmt) : 0}
                        defaultValue={0}
                        onValueChange={(e) => {
                          if (editedData[i]?.sagyoAmt != e) {
                            settingNewDandoriAmtInArr(e, i, 'sagyoAmt', '', '');
                            checkInputWithColor(false, '', i + 'YosetsuSagyoAmtCu', e, 'amt');
                          }
                        }}
                        style={{ border: 'none' }}
                        className={
                          editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'
                        }
                        prefix="¥"
                        decimalsLimit={100}
                      />

                      <div id={i + 'YosetsuSagyoAmtCu'} style={{ display: 'none' }} className="currencyReset">
                        <div className="currencyIcon">
                          <BsUsbC style={{ fontSize: '25' }} />
                          <BsX style={{ margin: '-8px 0 0 16px' }} />
                          <span class="tooltiptext">
                            金額に変更がありましたので、以降時間と連携して自動計算されない。
                          </span>
                        </div>
                      </div>
                    </Col>
                    <Col span={4} className="input-col">
                      <Row style={{ width: '100%' }}>
                        <Col className="timer-input-col">
                          <Input
                            value={editedData[i]?.sagyoTime ? secondsToHms(editedData[i]?.sagyoTime) : timeHmsStr}
                            id={i + 'yosetsuSagyoTimeIn'}
                            style={{ width: '100%' }}
                            className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                            onChange={(e) => {
                              settingNewDandoriAmtInArr(e.target.value, i, 'sagyoTime', 'input', 'input');
                            }}
                          ></Input>
                        </Col>
                        <Col className="timer-button-col">
                          {editMode ? (
                            <>
                              <CaretUpOutlined
                                style={{ fontSize: 17 + 'px' }}
                                onClick={() => {
                                  let editedTime = handelIncreaseTime(
                                    editedData[i]?.sagyoTime,
                                    '10min',
                                    i + 'yosetsuSagyoTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', '10min', 'inc');
                                }}
                              />
                              <CaretDownOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelDecreaseTime(
                                    editedData[i]?.sagyoTime,
                                    '10min',
                                    i + 'yosetsuSagyoTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', '10min', 'dec');
                                }}
                              />
                              <CaretUpOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelIncreaseTime(
                                    editedData[i]?.sagyoTime,
                                    'min',
                                    i + 'yosetsuSagyoTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', 'min', 'inc');
                                }}
                              />
                              <CaretDownOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelDecreaseTime(
                                    editedData[i]?.sagyoTime,
                                    'min',
                                    i + 'yosetsuSagyoTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', 'min', 'dec');
                                }}
                              />
                              <CaretUpOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelIncreaseTime(
                                    editedData[i]?.sagyoTime,
                                    'sec',
                                    i + 'yosetsuSagyoTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', 'sec', 'inc');
                                }}
                              />
                              <CaretDownOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelDecreaseTime(
                                    editedData[i]?.sagyoTime,
                                    'sec',
                                    i + 'yosetsuSagyoTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', 'sec', 'dec');
                                }}
                              />
                            </>
                          ) : (
                            <></>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col span={1} className="oyabuhin-detail-col"></Col>
                    <Col span={5} className="formula-column"></Col>
                  </Row>
                  <div id={'yosetsuProcessIQ3' + i} style={{ display: 'none' }}>
                    <div>
                      {/* 作業金額/時間 */}
                      {processArr[i]?.weldType === 0 ? (
                        <div>
                          {/* 溶接加工長/加工回数 */}
                          <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                            <Col span={4} className="oyabuhin-detail-col">
                              <span className="ddl_2_noImg" style={{ marginLeft: '45px' }}>
                                溶接加工長/加工回数
                              </span>
                            </Col>

                            <Col span={3} className="oyabuhin-detail-col">
                              <label id="senchou">{processArr[i]?.senchou}</label>
                            </Col>

                            <Col span={3} className="oyabuhin-detail-col">
                              <label id={i + 'senchouKaisu'}>{processArr[i]?.senchouKaisu}</label>
                            </Col>

                            <Col span={4} className="input-col">
                              <Input
                                value={editedData[i]?.senchou}
                                id={i + 'senchouIn'}
                                min={0}
                                className={editMode ? 'input-editable' : 'input-non-editable '}
                                controls={false}
                                style={{
                                  width: '45%',
                                  border: 'none',
                                  color:
                                    parseFloat(editedData[i]?.senchou) === processArr[i]?.senchou ? 'black' : 'red',
                                }}
                                onKeyPress={(e) => {
                                  handleKeyPress(e);
                                }}
                                onChange={(e) => settingNewDandoriAmtInArr(e.target.value, i, 'senchou', '', '')}
                              />
                            </Col>

                            <Col span={4} className="input-col">
                              <Input
                                value={editedData[i]?.senchouKaisu}
                                id={i + 'senchouKaisuIn'}
                                min={0}
                                className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                                controls={false}
                                style={{
                                  width: '45%',
                                  border: 'none',
                                  color:
                                    parseFloat(editedData[i]?.senchouKaisu) === processArr[i]?.senchouKaisu
                                      ? 'black'
                                      : 'red',
                                }}
                                onKeyPress={(e) => {
                                  handleKeyPress(e);
                                }}
                                onChange={(e) => settingNewDandoriAmtInArr(e.target.value, i, 'senchouKaisu', '', '')}
                              />
                            </Col>
                            <Col span={1} className="oyabuhin-detail-col"></Col>
                            <Col span={4} className="formula-column"></Col>
                          </Row>

                          {/* 仮付箇所/加工回数 */}
                          <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                            <Col span={4} className="oyabuhin-detail-col">
                              <span className="ddl_2_noImg" style={{ marginLeft: '45px' }}>
                                仮付箇所/加工回数
                              </span>
                            </Col>
                            <Col span={3} className="oyabuhin-detail-col">
                              <label id={i + 'karitsukeCnt'}>{processArr[i]?.karitsukeCnt}</label>
                            </Col>
                            <Col span={3} className="oyabuhin-detail-col">
                              <label id={i + 'karitsukeKaisu'}>{processArr[i]?.karitsukeKaisu}</label>
                            </Col>

                            <Col span={4} className="input-col">
                              <Input
                                value={editedData[i]?.karitsukeKaisu}
                                id={i + 'karitsukeKaisuIn'}
                                min={0}
                                className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                                controls={false}
                                style={{
                                  width: '45%',
                                  border: 'none',
                                  color:
                                    parseFloat(editedData[i]?.karitsukeKaisu) === processArr[i]?.karitsukeKaisu
                                      ? 'black'
                                      : 'red',
                                }}
                                onKeyPress={handleKeyPress}
                                onChange={(e) => settingNewDandoriAmtInArr(e.target.value, i, 'karitsukeKaisu', '', '')}
                              />
                            </Col>

                            <Col span={4} className="input-col">
                              <Input
                                value={editedData[i]?.karitsukeCnt}
                                id={i + 'karitsukeCntIn'}
                                min={0}
                                className={editMode ? 'input-editable' : 'input-non-editable '}
                                controls={false}
                                style={{
                                  width: '45%',
                                  border: 'none',
                                  color:
                                    parseFloat(editedData[i]?.karitsukeCnt) === processArr[i]?.karitsukeCnt
                                      ? 'black'
                                      : 'red',
                                }}
                                onKeyPress={handleKeyPress}
                                onChange={(e) => settingNewDandoriAmtInArr(e.target.value, i, 'karitsukeCnt', '', '')}
                              />
                            </Col>
                            <Col span={1} className="oyabuhin-detail-col"></Col>
                            <Col span={4} className="formula-column"></Col>
                          </Row>
                        </div>
                      ) : (
                        <div>
                          {/*  加工数/回数 */}
                          <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                            <Col span={4} className="oyabuhin-detail-col">
                              <span className="ddl_2_noImg" style={{ marginLeft: '45px' }}>
                                加工数/回数
                              </span>
                            </Col>

                            <Col span={3} className="oyabuhin-detail-col">
                              <label id={i + 'number'}>{processArr[i]?.number}</label>
                            </Col>
                            <Col span={3} className="oyabuhin-detail-col">
                              <label id={i + 'count'}>{processArr[i]?.count}</label>
                            </Col>
                            <Col span={4} className="input-col">
                              <Input
                                value={editedData[i]?.number}
                                id={i + 'numberIn'}
                                min={0}
                                className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                                controls={false}
                                style={{
                                  width: '45%',
                                  border: 'none',
                                  color: parseFloat(editedData[i]?.number) === processArr[i]?.number ? 'black' : 'red',
                                }}
                                onKeyPress={handleKeyPress}
                                onChange={(e) => settingNewDandoriAmtInArr(e.target.value, i, 'number', '', '')}
                              />
                            </Col>

                            <Col span={4} className="input-col">
                              <Input
                                value={editedData[i]?.count}
                                id={i + 'countIn'}
                                min={0}
                                className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                                controls={false}
                                style={{
                                  width: '45%',
                                  border: 'none',
                                  color: parseFloat(editedData[i]?.count) === processArr[i]?.count ? 'black' : 'red',
                                }}
                                onKeyPress={handleKeyPress}
                                onChange={(e) => settingNewDandoriAmtInArr(e.target.value, i, 'count', '', '')}
                              />
                            </Col>
                            <Col span={1} className="oyabuhin-detail-col"></Col>
                            <Col span={5} className="formula-column"></Col>
                          </Row>
                          {/*  径(mm) */}
                          <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                            <Col span={4} className="oyabuhin-detail-col">
                              <span className="ddl_2_noImg" style={{ marginLeft: '45px' }}>
                                径(mm)
                              </span>
                            </Col>

                            <Col span={3} className="oyabuhin-detail-col">
                              <label id="proDiameter">{processArr[i]?.daimeter}</label>
                            </Col>
                            <Col span={3} className="oyabuhin-detail-col"></Col>
                            <Col span={4} className="input-col">
                              <label id="proDiameter">{processArr[i]?.daimeter}</label>
                            </Col>
                            <Col span={4} className="input-col"></Col>
                            <Col span={1} className="oyabuhin-detail-col"></Col>
                            <Col span={5} className="formula-column"></Col>
                          </Row>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            }
            return arr;
          })()}
        </div>
      </div>
    </div>
  );
});

export default IQ3KouteiCalculate_Yosetsu;
