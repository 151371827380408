/**
 * クラス名：マイン見積
 * 説明：親部品見積・子部品見積纏めJSファイルや共通操作ファイルである。
 * 作成者：ナンス
 * 作成日：2023/03/01
 * バージョン：1.0
 */
import {
  Form,
  Button,
  Row,
  Input,
  Col,
  Modal,
  Image,
  Tooltip,
  Space,
  Tabs,
  Menu,
  Checkbox,
  Steps,
  Select,
  Empty,
} from 'antd';
import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { CloseOutlined, CaretUpOutlined, CaretDownOutlined, CloseSquareFilled } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Table as RTable } from 'react-bootstrap';

import '../../assets/styles/common.css';
import close from '../../assets/images/close.png';
import save from '../../assets/images/save.png';
import pc_save from '../../assets/images/pc_save.png';
import pmx_import from '../../assets/images/pmx_import.png';
import logo from '../../assets/images/logo.ico';
import export_report from '../../assets/images/export_report.png';
import edit_info from '../../assets/images/edit_info.png';
import IQ3Estimate from '../iq3/IQ3Estimate';
import ParentEstimate from '../parent/ParentEstimate';
import EstimateBasicInfo from '../parent/EstimateBasicInfo';
import ParentKouteiSentaku from '../parent/ParentKouteiSentaku';
import IQ3KouteiSentaku from '../iq3/IQ3KouteiSentaku';
//import { formatDateString, getTenjikaiSampleData, todayTime, updateEstimateInfoByReCal } from '../common/Common';
import {
  formatDateString,
  getTenjikaiSampleData,
  todayTime,
  initAdditionalItemCost,
  initAdditionalItemLabel,
  initAdditionalItemMemo,
  initAdditionalItemDate,
  toDecimal,
  iq3EstimateInitInfo,
  padWithLeadingZeros,
  updateEstimateInfoByReCal,
  convertServerExcelToHtml,
  formatPMXDate,
} from '../common/Common';
import {
  commonTaiochuModal,
  confirmScreenChangeContent,
  confirmScreenChangeTitle,
  commonModal,
  commonFooter,
  confirmDisplayDataSaveContent,
  confirmOverwriteSaveContent,
  confirmSaveContent,
  confirmDiscardContent,
  confirmScreenChangeWarning,
} from '../common/CommonModal';
import confirm_icon from '../../assets/images/confirm_icon.png';
//import { createEstimateProductInfo, updateEstimateProductInfo } from '../common/CommonAPI';
import {
  createEstimateProductInfo,
  getCompanyInfo,
  getEstimateIq3Info,
  getExcelDatabyUseType,
  getExistUserSetting,
  getMaterialIQ3Info,
  getMaterialSurfaceIQ3Info,
  getMaterialTypeIQ3Info,
  getProcessPatternInfo,
  getPurchaseCategoryInfo,
  getPurchaseInfo,
  updateEstimateProductInfo,
  logOutSubmit,
  getClientInfo,
  getEstimateStatusData,
  getDeviceInfo,
  getProcessInfo,
  getCardInfo,
  getCardDetailImg,
} from '../common/CommonAPI';
import export_csv_icon from '../../assets/images/export_csv_icon.png';
import PMX_CloudFileDialog_Tab_Parent from './PMX_CloudFileDialog_Tab_Parent';
import PMX_CloudFileDialog_Tab_IQ3 from './PMX_CloudFileDialog_Tab_IQ3';
import parent_call_detail from '../../assets/images/parent_call_detail.png';
import ParentList from '../parent/ParentList';
/* import { logOutSubmit, getClientInfo, getEstimateStatusData, getDeviceInfo, getProcessInfo } from '../common/CommonAPI'; */

import {
  ParamCalType,
  ClientType,
  WorkType,
  DataType,
  ServiceClass,
  SurfaceProtectionType,
  EstimateCreationType,
  PrintTypes,
  CreatePMXData,
  WorkTypeGroup,
} from './enums';
import { BlankTypes, SubWeldTypes, Types, byQuantities, testData1, testData2 } from './Constant';
import { async } from 'q';

dayjs.extend(customParseFormat);
const iconWidth = 32;

const MainEstimate = React.forwardRef((props, ref) => {
  const [activePartMenu, setActivePartMenu] = useState('');
  // PMX工程選択ダイアログ表示
  const [isPMXProcessPatternOpen, setIsPMXProcessPatternOpen] = useState(false);
  const [isNext, setIsNext] = useState(false);
  const [isPMXComplete, setIsPMXComplete] = useState(false);
  const [selectedEstimateData, setSelectedEstimateData] = useState([]);
  const [selectedEstimateDataTemp, setSelectedEstimateDataTemp] = useState([]);
  const [selectediq3Data, setSelectediq3Data] = useState([]);
  const [estimateBasicInfo, setEstimateBasicInfo] = useState([]);
  const [confirmScreenChangeModalOpen, setConfirmScreenChangeModalOpen] = useState(false);
  const [confirmDisplayDataSaveModalOpen, setConfirmDisplayDataSaveModalOpen] = useState(false);
  // 開発中ポップアップ
  const [openCommonTaiochuPopup, setOpenCommonTaiochuPopup] = useState(false);
  const [editMode, setEditMode] = useState(false);
  // const [clickAddNewModal, setClickAddNewModal] = useState(false);
  const [clickPMX, setClickPMX] = useState(false);
  const [pMXFileName, setPMXFileName] = useState('zerofour-02.STEP.SB-E_WELD_v2023-1-7.PMX');
  const [clickMenu, setClickMenu] = useState('');
  // 帳票出力
  const [isReportOutputOpen, setIsReportOutputOpen] = useState(false);
  const [isListOutputOpen, setIsListOutputOpen] = useState(false);
  const [childTitle, setChildTitle] = useState('');
  // pc保存
  const [isEstimateDataSaveConfirm, setIsEstimateDataSaveConfirm] = useState(false);
  const [isLoadPMXProcess, setIsLoadPMXProcess] = useState(false);
  const [isCreatePMX, setIsCreatePMX] = useState();
  const [currentStep, setCurrentStep] = useState(0);
  const [pmxProcessType, setPmxProcessType] = useState('');
  const [patternList, setPatternList] = useState([]);
  const [parentPatternNo, setParentPatternNo] = useState(1);
  const [childPatternNo, setChildPatternNo] = useState(1);
  const [currentPMXMenu, setCurrentPMXMenu] = useState(false);
  const [iq3PatternList, setIQ3PatternList] = useState([]);
  const [pmxTabKey, setPMXTabKey] = useState('1');
  const [isPMXCloudFileDialogOpen, setIsPMXCloudFileDialogOpen] = useState(false);
  const [selectedParentDatas, setSelectedParentDatas] = useState([]);
  const [selectedIQ3Datas, setSelectedIQ3Datas] = useState([]);
  const [parentListDisplay, setParentListDisplay] = useState(false);
  const [selectedParentAddEstimate, setSelectedParentAddEstimate] = useState([]);

  const [checkPMXOrNot, setCheckPMXOrNot] = useState(false);
  // 別名保存・上書き保存
  const saveMenuChildItems = [
    { key: 'otherNmSave', label: '別名保存' },
    { key: 'overwrite', label: '上書き保存' },
  ];
  // 新規保存
  const [createConfirm, setCreateConfirm] = useState(false);
  // 破棄
  const [discardConfirm, setDiscardConfirm] = useState(false);
  const [selectedSaveMenuKey, setSelectedSaveMenuKey] = useState('');
  // 別名保存の入力項目
  const [inputDrawingNo, setInputDrawingNo] = useState('');
  const [parentEstimateName, setParentEstimateName] = useState('');
  const [isSaveByOtherName, setIsSaveByOtherName] = useState(false);
  // 上書き保存
  const [isSaveByOverwrite, setIsSaveByOverwrite] = useState(false);
  const [saveDiscardConfirmPopup, setSaveDiscardConfirmPopup] = useState(false);
  const [navigateTabName, setNavigateTabName] = useState('');
  const [yobidashiClk, setYobidashiClk] = useState(false);
  const [calClk, setCalClk] = useState(false);
  const [saveClk, setSaveClk] = useState(false);
  const [pcInClk, setPcInClk] = useState(false);
  const [pcOutClk, setPcOutClk] = useState(false);
  const [reportClk, setReportClk] = useState(false);
  const [csvClk, setCsvClk] = useState(false);
  const [remarkClk, setRemarkClk] = useState(false);
  const [updateParentPatternList, setUpdateParentPatternList] = useState([]);
  const [updateIQ3PatternList, setUpdateIQ3PatternList] = useState([]);
  const [pmxSelectedEstimateData, setPMXSelectedEstimateData] = useState([]);

  //帳票出力
  const [excelIndividualFormats, setExcelIndividualFormats] = useState([]);
  const [selectedExeclIndividualFormat, setSelectedExeclIndividualFormat] = useState();

  // PMX取り込み
  const [selectedPMXEstimateData, setSelectedPMXEstimateData] = useState([]);
  const [selectedPMXEstimateDataTemp, setSelectedPMXEstimateDataTemp] = useState([]);
  const [selectedCardData, setSelectedCardData] = useState([]);

  // カード情報
  const [cardInfo, setCardInfo] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();
  const tenjikaiSampleData = getTenjikaiSampleData();
  const listData = tenjikaiSampleData.parentList;

  // file dialog
  const inputFile = useRef();
  const iQ3EstimateRef = useRef();
  const parentEstimateRef = useRef();
  const estimateBasicInfoRef = useRef();
  const inputFile_pcInput = useRef(null);
  const inputFile_pcOutput = useRef(null);
  const pmxCloudParentRef = useRef();
  const pmxCloudIQ3Ref = useRef();
  const iQ3KouteiSentakuRef = useRef();
  const parentKouteiSentakuRef = useRef();

  // useEffect(() => {
  //   let calEstimateData = updateEstimateInfoByReCal(props.selectedEstimateData);
  //   //親部品の基本情報更新
  //   parentEstimateRef?.current?.getParentEstimateDetailRef()?.current?.updateParentEstimateDetail(calEstimateData);
  //   updateEstimateInfo(calEstimateData);
  //   setSelectedEstimateData(calEstimateData);
  // }, [props.selectedEstimateData]);

  useEffect(() => {
    //破棄用見積情報保持
    setSelectedEstimateDataTemp(JSON.parse(JSON.stringify(props.selectedEstimateData)));
  }, []);

  useEffect(() => {
    setActivePartMenu(props.activeTab);
    if (location.pathname === '/home/:estimate.iq3') {
      setActivePartMenu(2);
    }
    setSelectedEstimateData(props.selectedEstimateData);
  }, [props.selectedEstimateData]);

  useEffect(() => {
    setActivePartMenu(props.activeTab);
  }, [props.activeTab]);

  useEffect(() => {
    let data = props.selectedEstimateData;
  }, [activePartMenu]);

  //編集モード切替により呼び出す
  useEffect(() => {
    setEditMode(props.editMode);
  }, [props.editMode]);

  const openPMXFileDialog = async (e, type, clickCon) => {
    setIsCreatePMX(clickCon);
    setIsLoadPMXProcess(false);
    setPmxProcessType(type);
    if (currentPMXMenu == 'local') {
      setPmxProcessType(type);
      inputFile.current.click();
    } else {
      // const tenjikaiSampleData = getTenjikaiSampleData();
      // const pmxList = tenjikaiSampleData.parentList_PMX_1;
      // setPMXSelectedEstimateData(pmxList[0]);
      // setPMXTabKey('1');
      if (clickCon === CreatePMXData.SaveAndNew) {
        if (selectedEstimateData?.estimateProducts?.id === 0) {
          estimateNewDataSaveEvent();
        } else {
          saveByOverwriteOk();
        }
      }
      setIsPMXCloudFileDialogOpen(true);

      let cardData = await getCardInfo();
      const updatedData = cardData?.map((item) => ({
        ...item,
        modified: formatPMXDate(item.modified),
      }));
      setCardInfo(updatedData);
    }
  };

  const openFileDialog_pcInput = () => {
    setPcInClk(true);
    if (editMode) {
      setConfirmScreenChangeModalOpen(true);
    } else {
      setConfirmDisplayDataSaveModalOpen(true);
    }
  };

  const openFileDialog_pcOutput = () => {
    setPcOutClk(true);
    if (editMode) {
      setConfirmScreenChangeModalOpen(true);
    } else {
      setConfirmDisplayDataSaveModalOpen(true);
    }
  };

  const openPMXProcessPatternDialog = (e) => {
    if (currentPMXMenu == 'local') {
      let targetFileNm = e.target.files[0].name;
      setPMXFileName(targetFileNm);
    }
    setIsPMXProcessPatternOpen(true);
    //親部品工程パターンリスト
    let kouteiPatternList =
      localStorage.getItem('parent_processPattern') != undefined
        ? JSON.parse(localStorage.getItem('parent_processPattern'))
        : [];
    setPatternList(kouteiPatternList);
    //板金品工程パターンリスト
    let iq3kouteiPatternList =
      localStorage.getItem('iq3_processPattern') != undefined
        ? JSON.parse(localStorage.getItem('iq3_processPattern'))
        : [];
    setIQ3PatternList(iq3kouteiPatternList);
    setCurrentStep(1);
  };

  const openPMXProcessPatternModal = (res, estimateData, selectedCardData) => {
    setIsPMXProcessPatternOpen(res);

    // ＊＊＊
    let parameters = estimateData?.estimateProducts?.calcParameters?.parameters;
    // 工程マスタデータ
    const patternLst = parameters?.processPattern;
    let parentKouteiPatternList = patternLst?.filter((item) => item.class === ServiceClass.Parent);
    let iq3KouteiPatternList = patternLst?.filter((item) => item.class === ServiceClass.SheetMetal);

    // ***
    //親部品工程パターンリスト
    let kouteiPatternList =
      localStorage.getItem('parent_processPattern') != undefined
        ? JSON.parse(localStorage.getItem('parent_processPattern'))
        : [];
    setPatternList(parentKouteiPatternList);
    //板金品工程パターンリスト

    let iq3kouteiPatternList =
      localStorage.getItem('iq3_processPattern') != undefined
        ? JSON.parse(localStorage.getItem('iq3_processPattern'))
        : [];
    setIQ3PatternList(iq3KouteiPatternList);
    setChildPatternNo(iq3KouteiPatternList?.[0]?.id);
    setParentPatternNo(parentKouteiPatternList?.[0]?.id);
    setCurrentStep(1);
    setSelectedPMXEstimateData(estimateData);
    setSelectedCardData(selectedCardData);
  };

  // PMXメニューイベント
  const pmxMenuChangeEvent = async (e) => {
    setClickPMX(true);
    setCurrentPMXMenu(e.key);
    if (editMode) {
      setConfirmScreenChangeModalOpen(true);
    } else {
      if (e.key === 'cloud') {
        setIsLoadPMXProcess(true);
      }
    }
  };

  const pmxTabChange = (key) => {
    setPMXTabKey(key);
    if (key == '1') {
    } else if (key == '2') {
    }
  };

  const getSelectedParentRows = (selectedRows) => {
    setSelectedParentDatas(selectedRows);
  };
  const getSelectedIQ3Rows = (selectedRows) => {
    setSelectedIQ3Datas(selectedRows);
  };

  const pmxLocalProcessCancel = () => {
    setIsLoadPMXProcess(false);
    setIsPMXCloudFileDialogOpen(false);
  };

  const fetchIndividualData = async () => {
    try {
      const calcParameters = props?.selectedEstimateData?.estimateProducts?.calcParameters;
      const companySetting = calcParameters?.settings?.companySettings;
      if (companySetting !== undefined) {
        // Check if companySetting is not null or undefined
        const formats = getExcelDatabyUseType(companySetting?.excelFormats);
        const excelIndividualformats = formats[PrintTypes.individual];
        if (excelIndividualformats !== undefined && excelIndividualformats?.length > 0) {
          const selectedExelIndividualFormat = formats[0]?.find(
            (format) => format.id === companySetting?.printOutputs?.[PrintTypes.individual]?.formatsId
          );
          setExcelIndividualFormats(formats[PrintTypes.individual]);

          if (selectedExelIndividualFormat !== undefined) {
            setSelectedExeclIndividualFormat(selectedExelIndividualFormat);
          } else {
            setSelectedExeclIndividualFormat(formats[0][0]);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const openDialogOK = (e) => {
    e.target.value = null;
    // let targetFileNm = e.target.files[0].name;
    // setPMXFileName(targetFileNm);
    // setIsPMXProcessPatternOpen(true);
  };

  const importPMXFile = () => {
    let costPrice = 0.0;
    let totalCostPrice = 0.0;
    let estimatedAmount = 0.0;
    let totalEstimatedAmount = 0.0;
    let costProfit = 0.0;
    let estimateProfit = 0.0;
    let materialCost = 0.0;
    let processCost = 0.0;
    let purchaseCost = 0.0;
    setIsNext(false);
    setIsPMXProcessPatternOpen(false);
    setClickPMX(false);
    const tenjikaiSampleData = getTenjikaiSampleData();
    let listData = [];
    if (pMXFileName?.includes('zerofour-02')) {
      listData = tenjikaiSampleData.parentList_PMX_1;
    } else {
      listData = tenjikaiSampleData.parentList_PMX_2;
    }
    let estimateDataProps = currentPMXMenu == 'local' ? listData[0] : pmxSelectedEstimateData;
    setSelectedEstimateData(estimateDataProps);
    setSelectediq3Data(estimateDataProps.iq3DataLst);
    if (estimateDataProps != null) {
      costPrice = parseFloat(estimateDataProps.costPrice);
      totalCostPrice = parseFloat(estimateDataProps.totalCostPrice);
      estimatedAmount = parseFloat(estimateDataProps.estimatedAmount);
      totalEstimatedAmount = parseFloat(estimateDataProps.totalEstimatedAmount);
      costProfit = parseFloat(estimateDataProps.costProfit);
      estimateProfit = parseFloat(estimateDataProps.estimateProfit);
      materialCost = parseFloat(estimateDataProps.materialCost);
      processCost = parseFloat(estimateDataProps.processCost);
      purchaseCost = parseFloat(estimateDataProps.purchaseCost);
    }
    const estimateBasicInfo = {
      estimateImgNo: estimateDataProps.imgNo,
      estimateNm: estimateDataProps.name,
      customerNm: estimateDataProps.customerNm,
      weight: estimateDataProps.childPartTotalWeight, // 確認
      surfaceArea: estimateDataProps.surfaceArea, // 確認
      volume:
        estimateDataProps.volume != undefined
          ? estimateDataProps.volume.toLocaleString(navigator.language, {
              minimumFractionDigits: 0,
            })
          : 0, // 確認
      creator: estimateDataProps.creator,
      estimatedDate: estimateDataProps.createdDate,
      deliveryDate: estimateDataProps.dateLst != undefined ? estimateDataProps.dateLst[2].data : '',
      partCnt: estimateDataProps.lotNum,

      costPrice: costPrice.toLocaleString(navigator.language, {
        minimumFractionDigits: 0,
      }),
      totalCostPrice: totalCostPrice.toLocaleString(navigator.language, {
        minimumFractionDigits: 0,
      }),
      estimatedAmount: estimatedAmount.toLocaleString(navigator.language, {
        minimumFractionDigits: 0,
      }),
      totalEstimatedAmount: totalEstimatedAmount.toLocaleString(navigator.language, {
        minimumFractionDigits: 0,
      }),
      costProfit: costProfit.toLocaleString(navigator.language, {
        minimumFractionDigits: 0,
      }),
      estimateProfit:
        estimateProfit != undefined
          ? estimateProfit.toLocaleString(navigator.language, {
              minimumFractionDigits: 0,
            })
          : 0,
      profitRate: estimateDataProps.rate,

      materialCost: materialCost.toLocaleString(navigator.language, {
        minimumFractionDigits: 0,
      }),
      processCost: processCost.toLocaleString(navigator.language, {
        minimumFractionDigits: 0,
      }),
      purchaseCost: purchaseCost.toLocaleString(navigator.language, {
        minimumFractionDigits: 0,
      }),

      customerCoefficient: estimateDataProps.customerCoefficient,
      managementCoefficient: estimateDataProps.managementCoefficient,
      materialLossCoefficient: estimateDataProps.materialLossCoefficient,
      orderForm: estimateDataProps.orderCoefficient,
      setupCoefficient: estimateDataProps.setupCoefficient,
      surfaceAreaCoefficient: estimateDataProps.surfaceAreaCoefficient,
      purchaseCoefficient: estimateDataProps.purchaseCoefficient,
      deliveryCoefficient: estimateDataProps.deliveryCoefficient,
      comment: estimateDataProps.comment,
      hiyou: estimateDataProps.hiyou,
    };
    setEstimateBasicInfo(estimateBasicInfo);
    updateEstimateInfo(estimateBasicInfo);

    //　空欄パターン情報更新
    localStorage.setItem('parent_processPattern', JSON.stringify(updateParentPatternList));
    localStorage.setItem('iq3_processPattern', JSON.stringify(updateIQ3PatternList));
  };

  const updateEstimateInfo = (estimateInfo) => {
    setSelectedEstimateData(estimateInfo);
    estimateBasicInfoRef.current.updateEstimateInfo(estimateInfo);
  };

  const updateParentEstimateInfo = (estimateInfo) => {
    parentEstimateRef.current.updateEstimateInfo(estimateInfo);
  };

  const getCurrentIQ3 = () => {
    let currentIq3Data = iQ3EstimateRef?.current?.getEstimateIq3Info();
    return currentIq3Data;
  };

  const getParentQuantity = () => {
    return parentEstimateRef?.current?.getParentQuantity(); //入力した数量情報を取得
  };

  // 開発中ポップアップ
  const closeCommonTaiochuPopup = () => {
    setOpenCommonTaiochuPopup(false);
  };
  // 呼出
  const commonTaiochuPopupOpen = () => {
    setOpenCommonTaiochuPopup(true);
  };

  const discardConfirmOk = () => {
    setConfirmScreenChangeModalOpen(false);
    setConfirmDisplayDataSaveModalOpen(false);
    setEditMode(false);
    props.updateEstimateEditMode(false);

    if (clickPMX) {
      setClickPMX(false);
      setIsLoadPMXProcess(true);
    } else if (yobidashiClk) {
      setParentListDisplay(true);
      setYobidashiClk(false);
    } else if (calClk) {
      setIsEstimateDataSaveConfirm(true);
      setChildTitle('確認');
      setCalClk(false);
    } else if (saveClk) {
      // setIsEstimateDataSaveConfirm(true);
      if (selectedSaveMenuKey === saveMenuChildItems[0].key) {
        setIsSaveByOtherName(true);
        setInputDrawingNo(formatDateString(todayTime()));
        setParentEstimateName(formatDateString(todayTime()));
      } else {
        setIsSaveByOverwrite(true);
      }
      setChildTitle('確認');
      setSaveClk(false);
      parentEstimateRef?.current?.discardConfirmOk();
    } else if (pcInClk) {
      inputFile_pcInput.current.click();
      setPcInClk(false);
    } else if (pcOutClk) {
      inputFile_pcOutput.current.click();
      setPcOutClk(false);
    } else if (reportClk) {
      setIsReportOutputOpen(true);
      setReportClk(false);
    } else if (csvClk) {
      setIsReportOutputOpen(true);
      setCsvClk(false);
    } else if (remarkClk) {
    } else {
    }

    if (clickMenu === '1') {
      setActivePartMenu('1');
      navigate(':estimate.parent');
    } else if (clickMenu === '2') {
      setActivePartMenu('2');
      navigate(':estimate.iq3');
    }
  };

  const discardConfirmCancel = () => {
    setConfirmScreenChangeModalOpen(false);
    setConfirmDisplayDataSaveModalOpen(false);
    setClickPMX(false);
    // setClickAddNewModal(false);
  };
  const updateMainEstimateEditMode = (mode) => {
    setEditMode(mode);
    props.updateEstimateEditMode(mode);
  };

  const partMenuChange = (e) => {
    setClickMenu(e);
    if (editMode) {
      setConfirmScreenChangeModalOpen(true);
    } else {
      setConfirmScreenChangeModalOpen(false);
      // setActivePartMenu(e);
      if (e === '1') {
        setNavigateTabName('parent');

        navigate(':estimate.parent');
        setActivePartMenu(e);
        // setSaveDiscardConfirmPopup(true);
      } else if (e === '2') {
        setNavigateTabName('sheetMetal');
        // setSaveDiscardConfirmPopup(true);
        setActivePartMenu(e);
        navigate(':estimate.iq3');
      }
    }
  };

  // 帳票出力
  const reportOutputChangeEvent = (e) => {
    // if (e.key == 'listReport') {
    //   setReportClk(true);
    //   setChildTitle('リスト帳票');
    // } else if (e.key == 'parentReport') {
    //   setIsReportOutputOpen(true);
    //   setChildTitle('親部品帳票');
    // } else if (e.key == 'pcSave') {
    //   setPcInClk(true);
    // } else if (e.key == 'pcLoad') {
    //   setPcOutClk(true);
    // } else if (e.key == 'csvReport') {
    //   setCsvClk(true);
    //   setChildTitle('CSV出力');
    // }
    setChildTitle('帳票');
    fetchIndividualData();
    setIsReportOutputOpen(true);
    // if (editMode) {
    //   setConfirmScreenChangeModalOpen(true);
    // } else {
    //   setConfirmDisplayDataSaveModalOpen(true);
    // }
  };

  // 再計算
  const calEstimateDataEvent = (e) => {
    setCalClk(true);
    if (editMode) {
      setConfirmScreenChangeModalOpen(true);
    } else {
      setConfirmDisplayDataSaveModalOpen(true);
    }
  };

  const estimateNewDataSaveEvent = () => {
    setNavigateTabName('');
    //setCreateConfirm(true);
    setCreateConfirm(props?.userSettingInfo?.[0]?.massages?.editOk);
    if (!props?.userSettingInfo?.[0]?.massages?.editOk) {
      createOk();
    }
  };
  // 別名保存・上書き保存
  const estimateDataSaveEvent = async (e) => {
    setSaveClk(true);
    setSelectedSaveMenuKey(e.key);
    if (editMode) {
      setConfirmScreenChangeModalOpen(true);
      return;
    }
    if (e.key === saveMenuChildItems[0].key) {
      setIsSaveByOtherName(true);
      /* setIsSaveByOtherName(props?.userSettingInfo?.[0]?.massages?.editOk);
      if (!props?.userSettingInfo?.[0]?.massages?.editOk) {
        saveByOtherNameOk();
      } */
      setInputDrawingNo(formatDateString(todayTime()));
      setParentEstimateName(formatDateString(todayTime()));
    } else {
      //setIsSaveByOverwrite(true);
      setIsSaveByOverwrite(props?.userSettingInfo?.[0]?.massages?.editOk);
      if (!props?.userSettingInfo?.[0]?.massages?.editOk) {
        saveByOverwriteOk();
      }
    }

    // setIsEstimateDataSaveConfirm(true);
    setChildTitle('確認');
  };

  const childModalTitle = (
    <div
      style={{
        width: 1490,
        backgroundColor: '#005fab',
        // marginLeft: isPMXCloudFileDialogOpen ? -10 : -24,
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>PMX取り込み</p>
    </div>
  );
  const updateOk = () => {
    setIsPMXProcessPatternOpen(false);
    setClickPMX(false);
    setIsPMXCloudFileDialogOpen(false);
  };

  const updateCancel = () => {
    setIsPMXProcessPatternOpen(false);
    setClickPMX(false);
    setIsPMXCloudFileDialogOpen(false);
  };
  const cancelPMXImport = () => {
    setIsPMXProcessPatternOpen(false);
    setClickPMX(false);
  };
  const nextProcessPattern = () => {
    setIsNext(true);
    setCurrentStep(2);
  };

  const completePMXData = async () => {
    let processMst = selectedPMXEstimateData?.estimateProducts?.calcParameters?.parameters?.process;
    let bendingTypes = [];
    let weldingTypes = [];
    let subWeldingTypes = [];
    const bendingDetails = processMst?.filter((i) => i.workType === WorkType.SmBending);
    if (bendingDetails?.length > 0) {
      bendingTypes = bendingDetails[0]?.details?.processDetailTypes;
    }
    const weldingDetails = processMst?.filter((i) => i.workType === WorkType.SmWelding);
    if (weldingDetails?.length > 0) {
      weldingTypes = weldingDetails[0].details?.processDetailTypes?.filter((i) => i.group === WorkTypeGroup.SmWelding);

      subWeldingTypes = weldingDetails[0].details?.processDetailTypes?.filter(
        (i) => i.group === WorkTypeGroup.SmInstSize || i.group === WorkTypeGroup.SmInstThick
      );
    }

    for (let i = 0; i < selectedPMXEstimateData?.esIq3Info?.length; i++) {
      let cardMainDataDetails = selectedCardData?.details?.[i];
      let cardDetails = cardMainDataDetails?.processingInfo ? JSON.parse(cardMainDataDetails?.processingInfo) : {};

      // 基本情報設定
      let iq3PmxImg = await getCardDetailImg(cardMainDataDetails?.id);
      if (iq3PmxImg) {
        selectedPMXEstimateData.esIq3Info[i].imageId = URL.createObjectURL(iq3PmxImg);
      } else {
        selectedPMXEstimateData.esIq3Info[i].imageId = '';
      }
      selectedPMXEstimateData.esIq3Info[i].partCount = cardMainDataDetails?.quantity;
      selectedPMXEstimateData.esIq3Info[i].drawingNo = cardMainDataDetails?.drawingNo;
      selectedPMXEstimateData.esIq3Info[i].name = cardMainDataDetails?.name;
      let pmxMaterialNm = cardMainDataDetails?.material;
      // 材料リスト
      let materialList = selectedPMXEstimateData?.estimateProducts?.calcParameters?.parameters?.materialIq3;
      let materialSurfaceLst =
        selectedPMXEstimateData?.estimateProducts?.calcParameters?.parameters?.materialSurfaceIq3;
      let materialNm = materialList?.filter((item) => item.name === pmxMaterialNm);
      let gravity = 0.0;
      // 材料情報設定
      if (materialNm?.length > 0) {
        selectedPMXEstimateData.esIq3Info[i].materialIq3Id = materialNm?.[0]?.id;
        selectedPMXEstimateData.esIq3Info[i].materialTypeIq3Id = materialNm?.[0]?.materialTypeIq3Id;
        selectedPMXEstimateData.esIq3Info[i].thickness = materialNm?.[0]?.info.thick;
        gravity = materialNm?.[0]?.info?.gravity;
        let materialSurface = materialSurfaceLst?.filter(
          (item) => item.materialTypeIq3Id === materialNm?.[0]?.materialTypeIq3Id
        );
        selectedPMXEstimateData.esIq3Info[i].materialSurfaceIq3Id =
          materialSurface?.length > 0 ? materialSurface[0]?.id : materialSurfaceLst?.[0]?.id;
        selectedPMXEstimateData.esIq3Info[i].materialSurfaceName =
          materialSurface?.length > 0 ? materialSurface[0]?.name : materialSurfaceLst?.[0]?.name;
      }
      //　面積・重量設定
      //重量(kg) = 板厚(mm) X Xサイズ(mm) X Yサイズ(mm) ÷ 1000000 X 比重(g/㎤)
      let sizeX = cardDetails?.boundaryBoxSize?.width;
      let sizeY = cardDetails?.boundaryBoxSize?.height;
      let weight = (selectedPMXEstimateData.esIq3Info[i].thickness * sizeX * sizeY) / (1000000 * gravity);
      if (sizeX && sizeY && weight) {
        selectedPMXEstimateData.esIq3Info[i].partSizeX = sizeX;
        selectedPMXEstimateData.esIq3Info[i].partSizeY = sizeY;
        selectedPMXEstimateData.esIq3Info[i].weight = Number.isFinite(weight) ? weight : 0;
        selectedPMXEstimateData.esIq3Info[i].totalSurfaceArea = cardDetails?.area;
      }

      //板金子部品より親部品の重量と面積データ更新
      if (selectedPMXEstimateData?.esIq3Info[i]?.weight) {
        selectedPMXEstimateData.estimateProducts.totalWeight += selectedPMXEstimateData?.esIq3Info[i]?.weight;
      }
      if (selectedPMXEstimateData?.esIq3Info[i]?.totalSurfaceArea) {
        selectedPMXEstimateData.estimateProducts.totalSurfaceArea +=
          selectedPMXEstimateData?.esIq3Info[i]?.totalSurfaceArea;
      }

      //工程入力情報設定
      let updateProcessInputs = [];
      let processInputs = JSON.parse(JSON.stringify(selectedPMXEstimateData?.esIq3Info[i]?.processInput));
      for (let j = 0; j < processInputs?.length; j++) {
        let curWorkType = processMst?.filter((item) => item.id == processInputs[j].processId)?.[0]?.workType;

        if (curWorkType === WorkType.SmProgram) {
          let programProcessInput = processInputs[j];

          programProcessInput.details[0].elements = cardDetails?.elements;

          updateProcessInputs.push(programProcessInput);
        } else if (
          curWorkType === WorkType.SmBlankCombi ||
          curWorkType === WorkType.SmBlankLaser ||
          curWorkType === WorkType.SmBlankPunch
        ) {
          let blankProcessInput = processInputs[j];
          blankProcessInput.details[0].dimensionData.outerDia = cardDetails?.contourLength?.outerLength;
          blankProcessInput.details[0].dimensionData.innerDia = cardDetails?.contourLength?.innerLength;
          blankProcessInput.details[0].dimensionData.airFeedDistance = cardDetails?.airDistance;

          let qtyLen = blankProcessInput.details[0].quantityList?.length;
          if (cardDetails?.contourLength?.holes?.length > 0) {
            blankProcessInput.details[0].quantityList.shift();
            for (let k = 0; k < cardDetails?.contourLength?.holes?.length; k++) {
              let typeId = Types?.filter((item) => item.value == 'その他')?.[0]?.id;
              let holdType = BlankTypes?.filter((item) => item.value === cardDetails?.contourLength?.holes[k].name)?.[0]
                ?.label;
              let blanktypes = Types?.filter((item) => item.value == holdType)?.[0];
              if (blanktypes) {
                typeId = blanktypes?.id;
              }
              const hold = cardDetails?.contourLength?.holes?.[k];
              const newListRow = {
                id: k + 1 + qtyLen,
                types: typeId,
                diameter: hold?.r * 2,
                xsize: hold?.xl,
                ysize: hold?.yl,
                perimeter: hold?.length,
                count: hold?.number,
              };
              blankProcessInput.details[0].quantityList?.push(newListRow);
            }
          }
          updateProcessInputs.push(blankProcessInput);
        } else if (curWorkType === WorkType.SmBending) {
          let bendProcessInput = processInputs[j];
          const maxBendLength = cardDetails?.bendInfo
            ? cardDetails?.bendInfo?.reduce((max, item) => {
                return item.width > max ? item.width : max;
              }, -Infinity)
            : 0;
          bendProcessInput.details[0].bendingData.maxBendLength = maxBendLength ? maxBendLength : 0;

          let bendLen = bendProcessInput.details[0].bendingList?.length;
          if (cardDetails?.bendInfo?.length > 0) {
            bendProcessInput?.details[0]?.bendingList?.shift();
            for (let k = 0; k < cardDetails?.bendInfo?.length; k++) {
              const bend = cardDetails?.bendInfo[k];
              let typeId = bendingTypes?.[0]?.id;

              const result = bendingTypes?.find((benItem) => {
                const character = benItem.name?.split('_')[1];

                return character === bend?.name;
              });
              if (result) {
                typeId = result?.id;
              }

              const newListRow = {
                id: k + 1 + bendLen,
                types: typeId,
                bendLength: bend?.width,
                lines: bend?.amount,
                bendCount: bend?.amount,
              };
              bendProcessInput?.details[0]?.bendingList?.push(newListRow);
            }
          }

          updateProcessInputs.push(bendProcessInput);
        } else if (curWorkType === WorkType.SmWelding) {
          let weldProcessInput = processInputs?.[j];

          let weldLen = weldProcessInput?.details[0].weldingList?.length;
          if (cardDetails?.weldInfo?.length > 0) {
            weldProcessInput?.details[0]?.weldingList?.shift();
            for (let k = 0; k < cardDetails?.weldInfo?.length; k++) {
              const weld = cardDetails?.weldInfo?.[k];
              let typeId = weldingTypes?.[0]?.id;

              // const result = weldingTypes?.find((weldItem) => {
              //   return weldItem.name === weld.weldType;
              // });
              // if (result) {
              //   typeId = result.id;
              // }

              const result = weldingTypes?.find((weldItem) => {
                return weldItem.id === weld?.weldType;
              });
              if (result) {
                typeId = result?.id;
              }

              const newListRow = {
                id: k + 1 + weldLen,
                yousetsuType: typeId,
                senchou: weld?.length,
                senchouKaisu: 0,
                senchouYousetsushiage: 0,
                total1: 0,

                karitsukeCnt: weld?.number,
                karitsukeKaisu: 0,
                karitsukeYousetsushiage: 0,
                total2: 0,
              };
              weldProcessInput?.details[0]?.weldingList?.push(newListRow);
            }
          }
          // サプ溶接種類
          let subWeldLen = weldProcessInput?.details[0]?.subWeldingList?.length;
          if (cardDetails?.subWeldInfo?.length > 0) {
            weldProcessInput?.details[0]?.subWeldingList?.shift();
            for (let k = 0; k < cardDetails?.subWeldInfo?.length; k++) {
              const subWeld = cardDetails?.subWeldInfo?.[k];
              let typeId = subWeldingTypes?.[0]?.id;
              let typeGroup = subWeldingTypes?.[0]?.group;
              // ***
              let subWeldType = SubWeldTypes?.filter(
                (itemTyp) => itemTyp.value === cardDetails?.subWeldInfo?.[k]?.name
              )?.[0]?.label;
              let result = subWeldingTypes?.filter((subWeldItem) => subWeldItem.name == subWeldType)?.[0];

              if (result) {
                typeId = result?.id;
                typeGroup = result?.group;
              }

              const newListRow = {
                id: k + 1 + subWeldLen,
                subWeldingType: typeId,
                daimeter: subWeld?.xl,
                number: subWeld?.number,
                count: 0,
                yousetsushiage: 1,
                total: subWeld?.xl * subWeld?.number * 0,
                subWeldingGroupType: typeGroup,
              };
              weldProcessInput.details[0].subWeldingList.push(newListRow);
            }
          }

          updateProcessInputs.push(weldProcessInput);
        } else if (curWorkType === WorkType.SmSecondaryWork) {
          let secWorkProcessInput = processInputs[j];
          updateProcessInputs.push(secWorkProcessInput);
        } else if (curWorkType === WorkType.SmDeburringAuto) {
          let autoDeburProcessInput = processInputs[j];
          autoDeburProcessInput.details[0].area = cardDetails?.area ? cardDetails?.area : 0;
          if (weight) {
            autoDeburProcessInput.details[0].weight = Number.isFinite(weight) ? weight : 0;
          }
          updateProcessInputs.push(autoDeburProcessInput);
        } else if (curWorkType === WorkType.SmDeburringManual) {
          let manualDeburProcessInput = processInputs[j];
          updateProcessInputs.push(manualDeburProcessInput);
        } else if (curWorkType === WorkType.SmSurface) {
          let surfaceProcessInput = processInputs[j];
          if (surfaceProcessInput?.details) {
            surfaceProcessInput.details[0].areaUnitPrice = cardDetails?.area;
            surfaceProcessInput.details[0].weightUnitPrice = Number.isFinite(weight) ? weight : 0;
          }

          updateProcessInputs.push(surfaceProcessInput);
        } else if (curWorkType === WorkType.SmShearing) {
          let shearingProcessInput = processInputs[j];
          shearingProcessInput.details[0].numOfCuts = 0;
          updateProcessInputs.push(shearingProcessInput);
        } else if (curWorkType === WorkType.SmInspection) {
          let inspectionProcessInput = processInputs[j];
          updateProcessInputs.push(inspectionProcessInput);
        }
      }
      //updateProcessInputs
      selectedPMXEstimateData.esIq3Info[i].processInput = updateProcessInputs;
    }
    setIsPMXComplete(true);
    setCheckPMXOrNot(true);
    if (isCreatePMX === CreatePMXData.Update) {
      if (props.selectedEstimateData.esIq3Info?.length > 0) {
        const listData = selectedPMXEstimateData?.esIq3Info;
        const updatedInfoArray = listData?.map((item, index) => ({
          ...item,
          no: props.selectedEstimateData?.esIq3Info?.length + 1 + index,
        }));
        props.selectedEstimateData.esIq3Info = props.selectedEstimateData?.esIq3Info?.concat(updatedInfoArray);
        props.updateSelectedEstimateData(props.selectedEstimateData);
      } else {
        props.selectedEstimateData.esIq3Info = props.selectedEstimateData?.esIq3Info?.concat(
          selectedPMXEstimateData.esIq3Info
        );
        props.updateSelectedEstimateData(props.selectedEstimateData);
      }
    } else {
      props.updateSelectedEstimateData(selectedPMXEstimateData);
    }

    setIsPMXProcessPatternOpen(false);
  };

  const backProcessPattern = () => {
    setIsNext(false);
    setCurrentStep(1);
  };

  const nextIQ3FileLoad = () => {
    if (pmxTabKey == '1') {
      setPMXTabKey('2');
    }
  };
  const cloudPMXFileLoadOk = () => {
    setIsPMXCloudFileDialogOpen(false);
    openPMXProcessPatternDialog();
  };
  const cloudPMXFileLoadCancel = () => {
    setIsPMXCloudFileDialogOpen(false);
  };
  const onChangeProcessPatternInfo = (type, key) => {
    // const pmxCloudSelectedData = pmxCloudParentRef?.current?.getSelectedParent();
    const data = { ...selectedPMXEstimateData };

    // const data = { ...pmxSelectedEstimateData };
    if (type == 0) {
      setParentPatternNo(key);
      const selectedPatternInfo = patternList?.filter((item) => item.id == key);
      // data.esIq3Info[0].processSelect = selectedPatternInfo[0];
      // setSelectedPMXEstimateData(data);
      data.estimateProducts.processSelect = selectedPatternInfo[0];

      setSelectedPMXEstimateData(data);

      // data.kouteiSentakuPatternNo = key;
      // setSelectedEstimateData(data);
    } else {
      setChildPatternNo(key);
      const selectedPatternInfo = iq3PatternList?.filter((item) => item.id == key);
      // data.esIq3Info[0].processSelect = selectedPatternInfo[0];
      const updatedLists = data?.esIq3Info?.map((item) => ({
        ...item,
        processSelect: selectedPatternInfo[0],
      }));
      data.esIq3Info = updatedLists;

      setSelectedPMXEstimateData(data);
      // props.updateSelectedEstimateData(selectedPMXEstimateData);

      // data.iq3DataLst[0].iq3KouteiSentakuPatternNo = key;
      // setSelectedEstimateData(data);
    }

    // setPatternNo(id);
    // let patternList = props.listData;
    // const selectedPatternInfo = patternList?.filter((item) => item.id == id);
    // setSelectedPatternInfo(selectedPatternInfo[0]);
    // if (selectedPatternInfo?.length > 0) {
    //   if (props.isParameter) {
    //     // 選択したパターンキーより一覧の選択行を変更
    //     props.updateSelectedData(selectedPatternInfo[0]);
    //   } else {
    //     // 選択したパターンキーより親部品工程選択・子部品工程選択の選択パターン番号を変更
    //     props.updateKouteiSentakuInfo(selectedPatternInfo[0], patternList);
    //   }
    // }
  };
  const reportOutputOk = async () => {
    let estimateProductId = props.selectedEstimateData?.estimateProducts?.id;
    let iq3Infos = await getEstimateIq3Info(estimateProductId);

    convertServerExcelToHtml(selectedExeclIndividualFormat, props.selectedEstimateData, iq3Infos);
    setIsReportOutputOpen(false);
    setIsEstimateDataSaveConfirm(false);
    setIsListOutputOpen(false);
  };
  const reportOutputCancel = () => {
    setIsReportOutputOpen(false);
    setIsEstimateDataSaveConfirm(false);
    setIsListOutputOpen(false);
  };

  useImperativeHandle(
    ref,
    () => ({
      getSelectedEstimateData: () => {
        return selectedEstimateData;
      },
      getParentEstimateData: getParentEstimate,
      getEstimateIq3Data: getEstimateIq3,
      callFormValidation: formValidation,
      updateEstimateInfo: updateEstimateInfo,
      getParentEstimateRef: () => {
        return parentEstimateRef;
      },
      getPMXEstimateInof: () => {
        return selectedPMXEstimateData;
      },
    }),
    [selectedEstimateData, editMode]
  );

  const formValidation = () => {
    let validation = parentEstimateRef?.current?.kihonFormValidation();
    if (validation) {
      validation = iQ3EstimateRef?.current?.kihonFormValidation();
    }
    return validation;
  };

  const getParentEstimate = () => {
    let currentEstimateData = parentEstimateRef?.current?.getParentEstimateInfo();
    let iq3EstimateData = iQ3EstimateRef?.current?.getIQ3EstimateDetailInfo();
    if (iq3EstimateData) currentEstimateData.esIq3Info = iq3EstimateData?.esIq3Info;
    // currentEstimateData.estimateProducts.calcParameters.parameters = oldParameters;
    return currentEstimateData;
  };

  const getEstimateIq3 = () => {
    let currentEstimateIq3Data = iQ3EstimateRef?.current?.getEstimateIq3Info();
    return currentEstimateIq3Data;
  };

  //　追加呼出ボタンの押下
  const displayAddParentInfo = () => {
    setYobidashiClk(true);
    if (editMode) {
      setConfirmScreenChangeModalOpen(true);
    } else {
      setConfirmDisplayDataSaveModalOpen(true);
    }
  };

  const selectParentInfoOk = () => {
    setParentListDisplay(false);
    let selectedParent =
      selectedParentAddEstimate !== undefined && selectedParentAddEstimate.iq3DataLst?.length > 0
        ? selectedParentAddEstimate
        : listData[0];
    const addIQ3Lst = selectedParent.iq3DataLst;
    var lastRecord =
      selectedEstimateData.iq3DataLst?.length > 0
        ? selectedEstimateData.iq3DataLst[selectedEstimateData.iq3DataLst?.length - 1]
        : [];
    let key = lastRecord && lastRecord?.key ? lastRecord?.key + 1 : 0;
    let no = lastRecord && lastRecord?.no ? lastRecord?.no + 1 : 1;
    const addNewIQ3Lst = [];
    for (let i = 0; i < addIQ3Lst?.length; i++) {
      var iq3Data = { ...addIQ3Lst[i] };
      iq3Data.key = key;
      iq3Data.no = no;
      addNewIQ3Lst.push(iq3Data);
      key++;
      no++;
    }
    setSelectediq3Data([...selectediq3Data, ...addNewIQ3Lst]);
  };

  const selectParentInfoCancel = () => {
    setParentListDisplay(false);
  };

  const getSelectedEstimateData = (estimateData, iq3RowKey, estimateEditMode) => {
    setSelectedParentAddEstimate(estimateData);
  };

  const updateSelectedPatternNo = (id) => {
    setChildPatternNo(id);
  };
  const updateSelectedParentPatternNo = (id) => {
    setParentPatternNo(id);
  };
  const updatePatternList = (selectedProcessPattern, list, type) => {
    const data = { ...pmxSelectedEstimateData };

    if (type === 'iQ3') {
      data.iq3DataLst[0].iq3KouteiSentakuPatternNo[0].id = selectedProcessPattern.id;
      setPMXSelectedEstimateData(data);
      setUpdateIQ3PatternList(list);
    } else {
      data.kouteiSentakuPatternNo = selectedProcessPattern.id;
      setPMXSelectedEstimateData(data);
      setUpdateParentPatternList(list);
    }
  };

  const tabItems = [
    {
      key: '1',
      label: (
        <Button
          key="parent-tab"
          type="primary"
          className={activePartMenu == '1' ? 'estimate-tab-btn-active first-btn' : 'estimate-tab-btn first-btn'}
        >
          親部品
        </Button>
      ),
      // children: (
      //   <ParentEstimate
      //     editMode={editMode}
      //     ref={parentEstimateRef}
      //     selectedEstimateData={selectedEstimateData}
      //     updateMainEstimateEditMode={updateMainEstimateEditMode}
      //     activePartMenu={activePartMenu}
      //     updateEstimateInfo={updateEstimateInfo}
      //     processMasterData={processMasterInfo}
      //     deviceMasterData={deviceMasterInfo}
      //     companyInfo={companyInfo}
      //     clientInfo={clientInfo}
      //     staffList={staffList}
      //     estimateStatusInfo={estimateStatusInfo}
      //     purchasesData={purchasesData}
      //     purchaseCategoryInfo={purchaseCategoryInfo}
      //     selectedEstimateSampleData={props.selectedEstimateSampleData}
      //     userSettingData={userSettingData}
      //     isReCal={isReCal}
      //     updateEstimateInfoByReCal={updateEstimateInfoByReCal}
      //     newMode={props.newMode}
      //     reCalEstimate={reCalEstimate}
      //   />
      // ),
    },
    {
      key: '2',
      label: (
        <Button
          key="iq3-tab"
          type="primary"
          className={activePartMenu == '2' ? 'estimate-tab-btn-active last-btn' : 'estimate-tab-btn last-btn'}
        >
          iQ3板金
        </Button>
      ),
      // children: (
      //   <IQ3Estimate
      //     editMode={editMode}
      //     ref={iQ3EstimateRef}
      //     updateEstimateInfo={updateEstimateInfo}
      //     iq3Data={selectediq3Data}
      //     selectedEstimateData={selectedEstimateData}
      //     updateMainEstimateEditMode={updateMainEstimateEditMode}
      //     getNewIQ3EstimateInfo={getNewIQ3EstimateInfo}
      //     // targetRowKey={props.iq3TargetRowKey == undefined ? 0 : props.iq3TargetRowKey}
      //     activePartMenu={activePartMenu}
      //     processPatternLst={processPatternLst}
      //     materialLst={materialLst}
      //     materialTypeLst={materialTypeLst}
      //     materialSurfaceLst={materialSurfaceLst}
      //     processMasterData={processMasterInfo}
      //     deviceMasterData={deviceMasterInfo}
      //     clientInfo={clientInfo}
      //   />
      // ),
    },
    // {
    //   key: '3',
    //   label: `iQ5形鋼`,
    //   children: (
    //     <IQ3Estimate
    //       editMode={editMode}
    //       ref={iQ3EstimateRef}
    //       updateEstimateInfo={updateEstimateInfo}
    //       iq3Data={selectediq3Data}
    //       selectedEstimateData={selectedEstimateData}
    //       childEditMode={updateMainEstimateEditMode}
    //       targetRowKey={props.iq3TargetRowKey == undefined ? 0 : props.iq3TargetRowKey}
    //     />
    //   ),
    // },
    // ,
    // {
    //   key: '4',
    //   label: `iQ7機械`,
    //   children: (
    //     <IQ3Estimate
    //       editMode={editMode}
    //       ref={iQ3EstimateRef}
    //       updateEstimateInfo={updateEstimateInfo}
    //       iq3Data={selectediq3Data}
    //       selectedEstimateData={selectedEstimateData}
    //       childEditMode={updateMainEstimateEditMode}
    //       targetRowKey={props.iq3TargetRowKey == undefined ? 0 : props.iq3TargetRowKey}
    //     />
    //   ),
    // },
  ];

  // 保存ボタン
  const saveMenuItems = [
    {
      label: <Image preview={false} width={33} src={save} style={{ marginRight: '6px' }}></Image>,
      key: 'save',
      children: [
        {
          label: saveMenuChildItems[0]?.label,
          key: saveMenuChildItems[0]?.key,
        },
        {
          label: saveMenuChildItems[1]?.label,
          key: saveMenuChildItems[1]?.key,
        },
      ],
    },
  ];

  const reportOutputMenuitems = [
    {
      label: <Image preview={false} width={30} src={export_report} style={{ marginLeft: '25px' }}></Image>,
      key: 'reportOutput',
      children: [
        {
          label: 'リスト帳票',
          key: 'listReport',
        },
        {
          label: '親部品帳票',
          key: 'parentReport',
        },
      ],
    },
  ];
  const listOutputMenuitems = [
    {
      label: (
        <Image
          preview={false}
          width={iconWidth}
          src={export_csv_icon}
          style={{ marginLeft: '30px', textDecoration: 'none' }}
        ></Image>
      ),
      key: 'listOutput',
      children: [
        {
          label: 'CSV出力(表示)',
          key: 'csvReport',
        },
        {
          label: 'CSV出力(全て)',
          key: 'csvAllReport',
        },
        {
          label: 'iQ見積アナライザ',
          key: 'iQReport',
        },
        {
          label: '明細出力',
          key: 'detailsReport',
        },
      ],
    },
  ];

  const pmxMenuItems = [
    {
      label: (
        <Image preview={false} width={28} src={pmx_import} style={{ marginLeft: '20px', marginTop: '0px' }}></Image>
      ),
      key: 'save',
      children: [
        {
          label: 'クラウド',
          key: 'cloud',
        },
        // {
        //   label: 'ローカル',
        //   key: 'local',
        // },
      ],
    },
  ];

  const pcSaveMenuitems = [
    {
      label: <Image preview={false} width={28} src={pc_save} style={{ marginLeft: '10px' }}></Image>,
      key: 'pcSave',
      children: [
        {
          label: 'PC保存',
          key: 'pcSave',
        },
        {
          label: 'PC呼出',
          key: 'pcLoad',
        },
      ],
    },
  ];

  const reportModalTitle = (
    <div
      style={{
        width: 600,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
        display: 'flex',
      }}
    >
      <Image
        preview={false}
        width={20}
        src={confirm_icon}
        style={{ marginTop: 9, marginLeft: 7, display: isEstimateDataSaveConfirm ? '' : 'none' }}
      ></Image>
      <p style={{ marginLeft: 7, paddingTop: 8, color: 'white' }}>{childTitle}</p>
    </div>
  );

  const pmxConfirmModalTitle = (
    <div
      style={{
        width: 300,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
        display: 'flex',
      }}
    >
      <Image preview={false} width={20} src={logo} style={{ marginTop: 9, marginLeft: 7 }}></Image>
      <p style={{ marginLeft: 10, paddingTop: 8, color: 'white' }}>PMX取り込み</p>
    </div>
  );

  const commonModalTitle = (
    <div
      style={{
        width: 520,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{childTitle}</p>
    </div>
  );

  /* 確認メッセージのタイトル */
  const confirmModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>確認</p>
    </div>
  );

  // 帳票出力
  const reportParentList = (
    <>
      <Row style={{ marginTop: 10 }}>
        <p style={{ fontSize: '13.5px', fontWeight: '500', margin: 0 }}>
          {isListOutputOpen
            ? 'CSV出力するフォーマットを選択してください'
            : '帳票出力するフォーマットを選択してください'}
        </p>
      </Row>
      <Row style={{ marginTop: 20 }}>
        <Col span={7}>
          <p style={{ fontSize: '13.5px', fontWeight: '500', margin: 0 }}>出力フォーマット：</p>
        </Col>
        <Col span={17}>
          {excelIndividualFormats !== undefined && excelIndividualFormats?.length > 0 ? (
            <Select
              style={{ width: 300, fontSize: 11 }}
              value={selectedExeclIndividualFormat?.id}
              onChange={(e) => {
                const selectedData = excelIndividualFormats?.find((item) => item?.id === e);
                if (selectedData !== undefined) {
                  setSelectedExeclIndividualFormat(selectedData);
                }
              }}
            >
              {excelIndividualFormats?.map((item) => (
                <Select.Option key={item?.id} value={item?.id}>
                  {item?.fileName}
                </Select.Option>
              ))}
            </Select>
          ) : (
            <Select style={{ width: 300, fontSize: 11 }} />
          )}
        </Col>
      </Row>
      {/* <Row style={{ marginTop: 10 }}>
        <Col span={7}>
          <p style={{ fontSize: '13.5px', fontWeight: '500', margin: 0 }}>出力元ファイル：</p>
        </Col>
        <Col span={17}>
          <Input className="input-editable" style={{ width: 300 }} />
          <Upload>
            <Button style={{ fontSize: '13.5px', marginLeft: '5px' }}>参照</Button>
          </Upload>
        </Col>
      </Row> */}
      <Row style={{ marginTop: '17px' }}>
        <Col span={24} style={{ textAlign: 'right' }} offset={0}>
          <Button onClick={reportOutputOk} className="mainButton" id="update">
            OK
          </Button>

          <Button onClick={reportOutputCancel} className="cancelButton" style={{ marginLeft: 10 }} id="discard">
            キャンセル
          </Button>
        </Col>
      </Row>
    </>
  );

  // PC保存
  const estimateDataSaveContent = (
    <>
      <Row style={{ marginTop: 0 }}>
        <p style={{ fontSize: '13.5px', fontWeight: '500', margin: 0 }}>
          パラメータが更新されています。最新を使用する項目を選択して下さい
        </p>
      </Row>
      <Row style={{ marginTop: '0px' }}>
        <Col span={2}>
          <Checkbox checked></Checkbox>
        </Col>
        <Col span={22}>
          <p style={{ fontSize: '13.5px', fontWeight: '500', margin: 0 }}>材料費</p>
        </Col>
      </Row>
      <Row style={{ marginTop: '0px' }}>
        <Col span={2}>
          <Checkbox></Checkbox>
        </Col>
        <Col span={22}>
          <p style={{ fontSize: '13.5px', fontWeight: '500', margin: 0 }}>装置のチャージ金額/係数</p>
        </Col>
      </Row>
      <Row style={{ marginTop: '0px' }}>
        <Col span={2}>
          <Checkbox checked></Checkbox>
        </Col>
        <Col span={22}>
          <p style={{ fontSize: '13.5px', fontWeight: '500', margin: 0 }}>購入品</p>
        </Col>
      </Row>
      <Row style={{ marginTop: '10px' }}>
        <Col span={24} style={{ textAlign: 'right' }} offset={0}>
          <Button onClick={reportOutputOk} className="mainButton" id="update">
            再計算
          </Button>

          <Button onClick={reportOutputCancel} className="cancelButton" style={{ marginLeft: 10 }} id="discard">
            キャンセル
          </Button>
        </Col>
      </Row>
    </>
  );

  // PMX取り込みコンテンツ
  const pmxConfirmContent = (
    <>
      <Row style={{ marginTop: 10 }}>
        <a
          onClick={(e) => openPMXFileDialog(e, '編集中見積り保存して、新規見積', CreatePMXData.SaveAndNew)}
          style={{ textDecoration: 'underline', color: '#1063aa', fontWeight: 'bold', textAlign: 'right' }}
        >
          編集中見積り保存して、新規見積
        </a>
      </Row>
      <Row style={{ marginTop: 7 }}>
        <a
          onClick={(e) => openPMXFileDialog(e, '新規見積', CreatePMXData.New)}
          style={{ textDecoration: 'underline', color: '#1063aa', fontWeight: 'bold', textAlign: 'right' }}
        >
          新規見積
        </a>
      </Row>
      <Row style={{ marginTop: 7 }}>
        <a
          onClick={(e) => openPMXFileDialog(e, '編集中見積に追加', CreatePMXData.Update)}
          style={{ textDecoration: 'underline', color: '#1063aa', fontWeight: 'bold', textAlign: 'right' }}
        >
          編集中見積に追加
        </a>
      </Row>
      <Row
        justify="end"
        style={{
          marginBottom: 0,
          marginTop: 15,
        }}
      >
        <Button className="cancelButton" onClick={pmxLocalProcessCancel}>
          キャンセル
        </Button>
      </Row>
    </>
  );

  // 別名保存
  const saveByOtherNameInfoContent = (
    <>
      <p style={{ fontSize: '13.5px', height: 10, borderBottom: 10 }}>
        表示中の見積データを別名で保存します。必要な項目を編集してください。
      </p>
      <RTable className="propertiesTbl">
        <thead>
          <tr>
            <td className="detail-tbl-val-title">
              <label className="detail-tbl-val-title-label">図番</label>
            </td>
            <td colSpan={6} style={{ verticalAlign: 'middle' }}>
              <Input
                style={{ padding: 5, marginLeft: 5, width: '98.2%' }}
                className="input-editable "
                // ref={ref}
                name="inputDrawingNo"
                id="inputDrawingNo"
                value={inputDrawingNo}
                type="text"
                onChange={(e) => {
                  setInputDrawingNo(e.target.value);
                }}
              />
            </td>
          </tr>
          <tr>
            <td className="detail-tbl-val-title">
              <label className="detail-tbl-val-title-label">名称</label>
            </td>
            <td colSpan={6} style={{ verticalAlign: 'middle' }}>
              <Input
                style={{ padding: 5, marginLeft: 5, width: '98.2%' }}
                className="input-editable "
                name="parentEstimateName"
                id="parentEstimateName"
                value={parentEstimateName}
                type="text"
                onChange={(e) => {
                  setParentEstimateName(e.target.value);
                }}
              />
            </td>
          </tr>
        </thead>
      </RTable>
    </>
  );

  // 別名保存入力ボックスのOKボタン押下
  const saveByOtherNameOk = async () => {
    setIsSaveByOtherName(false);
    // if (activePartMenu === '1') {
    if (!formValidation()) {
      return;
    }
    let currentEstimateData = parentEstimateRef?.current?.getParentEstimateInfo();
    let iq3EstimateData = iQ3EstimateRef?.current?.getIQ3EstimateDetailInfo();
    if (iq3EstimateData) currentEstimateData.esIq3Info = iq3EstimateData?.esIq3Info;
    const copyData = JSON.parse(JSON.stringify(currentEstimateData));

    copyData.estimateProducts = {
      ...copyData.estimateProducts,
      id: 0,
      inputDrawingNo: inputDrawingNo,
      name: parentEstimateName,
    };
    copyData.epCustomCosts = copyData?.epCustomCosts?.map((cost) => {
      return { ...cost, id: 0 };
    });
    copyData.epCustomDates = copyData?.epCustomDates?.map((date) => {
      return { ...date, id: 0 };
    });
    copyData.epCustomLabels = copyData?.epCustomLabels?.map((label) => {
      return { ...label, id: 0 };
    });
    copyData.epCustomMemos = copyData?.epCustomMemos?.map((memo) => {
      return { ...memo, id: 0 };
    });

    copyData.esIq3Info = copyData?.esIq3Info?.map((item) => ({ ...item, id: 0 }));

    let createdData = await createEstimateProductInfo(copyData);
    // } else {
    // }

    setSaveDiscardConfirmPopup(false);
    // if (navigateTabName === 'sheetMetal') {
    //   navigate(':estimate.iq3');
    //   setActivePartMenu('2');
    // } else if (navigateTabName === 'parent') {
    //   navigate(':estimate.parent');
    //   setActivePartMenu('1');
    // }
  };

  // 別名保存入力ボックスのCancelボタン押下
  const saveByOtherNameCancel = () => {
    setIsSaveByOtherName(false);
  };

  // 上書き保存のOKボタン押下
  const saveByOverwriteOk = async () => {
    setIsSaveByOverwrite(false);
    // if (activePartMenu === '1') {
    if (!formValidation()) {
      return;
    }
    let currentEstimateData = parentEstimateRef?.current?.getParentEstimateInfo();
    let iq3EstimateData = iQ3EstimateRef?.current?.getIQ3EstimateDetailInfo();
    if (iq3EstimateData) currentEstimateData.esIq3Info = iq3EstimateData?.esIq3Info;

    let updatedData = await updateEstimateProductInfo(currentEstimateData);
    // await getEstiamteIQ3Data(updatedData?.estimateProducts?.id);
    // } else {
    // }
    setSaveDiscardConfirmPopup(false);
    props.updateNewMode(false);
    // if (navigateTabName === 'sheetMetal') {
    //   navigate(':estimate.iq3');
    //   setActivePartMenu('2');
    // } else if (navigateTabName === 'parent') {
    //   navigate(':estimate.parent');
    //   setActivePartMenu('1');
    // }
  };

  // 上書き保存のCancelボタン押下
  const saveByOverwriteCancel = () => {
    setIsSaveByOverwrite(false);
  };
  //新規保存
  const createOk = async () => {
    setCreateConfirm(false);
    // if (activePartMenu == '1') {
    // 親部品見積のデータ保存
    let parentValidation = parentEstimateRef?.current?.kihonFormValidation();
    if (!parentValidation) return;
    let iq3Validation = iQ3EstimateRef?.current?.kihonFormValidation();
    if (!iq3Validation) return;
    let currentEstimateData = parentEstimateRef?.current?.getParentEstimateInfo();

    let iq3EstimateData = iQ3EstimateRef?.current?.getIQ3EstimateDetailInfo();
    if (iq3EstimateData) currentEstimateData.esIq3Info = iq3EstimateData?.esIq3Info;

    let createdData = await createEstimateProductInfo(currentEstimateData);
    if (createdData) {
      props.selectedEstimateData.estimateProducts.id = createdData?.estimateProducts?.id;
    }
    // await getEstiamteIQ3Data(createdData?.estimateProducts?.id);
    setSelectedEstimateData(createdData);
    setCreateConfirm(false);
    setSaveDiscardConfirmPopup(false);
    props.updateNewMode(false);
    // if (navigateTabName === 'sheetMetal') {
    //   navigate(':estimate.iq3');
    //   setActivePartMenu('2');
    // }
    // } else {
    //   // 板金の見積データ保存
    //   if (navigateTabName === 'parent') {
    //     navigate(':estimate.parent');
    //     setActivePartMenu('1');
    //   }
    // }
  };
  // 新規保存のCancelボタン押下
  const createCancel = () => {
    setCreateConfirm(false);
  };
  const discardOk = () => {
    const data = { ...selectedEstimateDataTemp };
    setSelectedEstimateData(data);
    props.updateSelectedEstimateData(data);
    setDiscardConfirm(false);
    setSaveDiscardConfirmPopup(false);
    if (navigateTabName === 'sheetMetal') {
      navigate(':estimate.iq3');
      setActivePartMenu('2');
    } else if (navigateTabName === 'parent') {
      navigate(':estimate.parent');
      setActivePartMenu('1');
    }
  };

  const discardCancel = () => {
    setDiscardConfirm(false);
  };
  const pmxTabItems = [
    {
      key: '1',
      label: `親部品`,
      children: <PMX_CloudFileDialog_Tab_Parent selectedParentRows={getSelectedParentRows} ref={pmxCloudParentRef} />,
    },
    {
      key: '2',
      label: `板金`,
      children: <PMX_CloudFileDialog_Tab_IQ3 selectedIQ3Rows={getSelectedIQ3Rows} ref={pmxCloudIQ3Ref} />,
    },
  ];

  const parentListModalTitle = (
    <div
      style={{
        width: 1670,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 33,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 5, paddingLeft: 18, color: 'white' }}>追加呼出</p>
    </div>
  );
  const estimateDataDiscardEvent = () => {
    //setDiscardConfirm(true);

    let editCancel =
      props?.userSettingInfo?.[0]?.massages?.editCancel == undefined
        ? true
        : props?.userSettingInfo?.[0]?.massages?.editCancel;
    setDiscardConfirm(editCancel);
    if (!editCancel) {
      discardOk();
    }
  };
  const closeOk = () => {
    setSaveDiscardConfirmPopup(false);
  };
  const saveEstimateDataByOtherName = () => {
    setIsSaveByOtherName(true);
    setInputDrawingNo(formatDateString(todayTime()));
    setParentEstimateName(formatDateString(todayTime()));
  };
  const cancelOk = () => {
    setSaveDiscardConfirmPopup(false);
  };
  const saveDiscardConfirmContent = (
    <>
      <Row>
        <p style={{ fontSize: '13.5px' }}>
          見積データが保存されていません。
          <br />
          そのまま画面を切り替えると編集データが削除されます。
          <br /> 保存しますか？
        </p>
      </Row>
      <Row align="middle">
        <Col span={8}>
          {selectedEstimateData?.estimateProducts?.id === 0 ? (
            <>
              <Button className="mainButton" onClick={createOk}>
                保存
              </Button>
            </>
          ) : (
            <>
              <Space size={'middle'}>
                <Button className="mainButton" onClick={saveEstimateDataByOtherName}>
                  別名保存
                </Button>
                <Button className="mainButton" onClick={saveByOverwriteOk}>
                  上書保存
                </Button>
              </Space>
            </>
          )}
        </Col>

        <Col span={8} offset={7}>
          <Space size={'middle'}>
            <Button className="discardButton" style={{ textAlign: 'center' }} onClick={discardOk}>
              破棄
            </Button>
            <Button className="cancelButton" onClick={cancelOk}>
              キャンセル
            </Button>
          </Space>
        </Col>
      </Row>
    </>
  );
  return (
    <div style={{ overflow: 'hidden' }}>
      <Form>
        {/** 共通操作 */}
        <div
          style={{
            borderBottom: 'solid',
            borderBottomWidth: '1px',
            borderBottomColor: '#212529',
            backgroundColor: '#fafafc',
          }}
        >
          <Row style={{ marginLeft: '10px' }}>
            <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', width: '60%' }}>
              <Row style={{ marginTop: '1px', marginLeft: '0' }}>
                <Col span={9}>
                  <Space size="middle">
                    <Tooltip title="子部品追加呼出" placement="top" overlayClassName="tooltip-text">
                      <a onClick={displayAddParentInfo}>
                        <Image
                          preview={false}
                          width={30}
                          src={parent_call_detail}
                          style={{ marginLeft: '5px' }}
                        ></Image>
                      </a>
                    </Tooltip>

                    {/* <Tooltip title="再計算" placement="top" overlayClassName="tooltip-text">
                      <a onClick={calEstimateDataEvent}>
                        <Image
                          preview={false}
                          width={iconWidth}
                          src={calculator}
                          style={{ marginLeft: '5px', marginTop: '2px' }}
                        ></Image>
                      </a>
                    </Tooltip> */}

                    {/*ファーストリリースで不要*/}
                    {/* <Tooltip title="PC保存・PC呼出" placement="top" overlayClassName="tooltip-text">
                      <Menu
                        onClick={reportOutputChangeEvent}
                        mode="horizontal"
                        items={pcSaveMenuitems}
                        style={{
                          marginLeft: '-19px',
                          marginTop: '-24px',
                          height: 0,
                          color: 'white',
                        }}
                      />
                      <input
                        type="file"
                        id="file"
                        ref={inputFile_pcOutput}
                        style={{ display: 'none' }}
                        accept=".*"
                        onChange={(e) => openDialogOK(e)}
                        onClick={(e) => (e.target.value = null)}
                      />
                      <input
                        type="file"
                        id="file"
                        ref={inputFile_pcInput}
                        style={{ display: 'none' }}
                        accept=".*"
                        onChange={(e) => openDialogOK(e)}
                        onClick={(e) => (e.target.value = null)}
                      />
                    </Tooltip> */}

                    <Tooltip title="PMX取込" placement="top" overlayClassName="tooltip-text">
                      <Menu
                        onClick={pmxMenuChangeEvent}
                        mode="horizontal"
                        items={pmxMenuItems}
                        style={{
                          marginLeft: '-25px',
                          marginTop: '-24px',
                          height: 0,
                          color: 'white',
                        }}
                      />
                      <input
                        type="file"
                        id="file"
                        ref={inputFile}
                        style={{ display: 'none' }}
                        accept=".PMX"
                        onChange={(e) => openPMXProcessPatternDialog(e)}
                        onClick={(e) => (e.target.value = null)}
                      />
                    </Tooltip>

                    <Tooltip title="帳票" placement="top" overlayClassName="tooltip-text">
                      <a onClick={reportOutputChangeEvent}>
                        <Image
                          preview={false}
                          width={iconWidth}
                          src={export_report}
                          style={{ marginLeft: '25px', marginTop: '2px' }}
                        ></Image>
                      </a>
                    </Tooltip>

                    {/* <Tooltip title="帳票" placement="top" overlayClassName="tooltip-text">
                      <Menu
                        onClick={reportOutputChangeEvent}
                        mode="horizontal"
                        items={reportOutputMenuitems}
                        style={{
                          marginLeft: '-15px',
                          marginTop: '-24px',
                          height: 0,
                          color: 'white',
                        }}
                      />
                    </Tooltip> */}
                    {/*ファーストリリースで不要*/}
                    {/* <Tooltip title="CSV出力" placement="top" overlayClassName="tooltip-text">
                      <Menu
                        onClick={reportOutputChangeEvent}
                        mode="horizontal"
                        items={listOutputMenuitems}
                        style={{
                          marginLeft: '-19px',
                          marginTop: '-24px',
                          height: 0,
                          color: 'white',
                          textDecoration: 'none',
                        }}
                      />
                    </Tooltip>
                    <Tooltip title="備考価格" placement="top" overlayClassName="tooltip-text">
                      <a onClick={commonTaiochuPopupOpen}>
                        <Image
                          preview={false}
                          width={iconWidth}
                          src={reference_price}
                          style={{ marginLeft: '30px', marginTop: '0px' }}
                        ></Image>
                      </a>
                    </Tooltip> */}
                  </Space>
                </Col>
              </Row>
            </div>
            <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center', width: '39%' }}>
              <Row align="middle">
                {/* <Col span={8}> */}
                <Space size="middle">
                  {selectedEstimateData?.estimateProducts?.id === 0 ||
                  selectedEstimateData?.estimateProducts == undefined ? (
                    <>
                      <Tooltip title="保存" placement="top" overlayClassName="tooltip-text">
                        <a onClick={estimateNewDataSaveEvent}>
                          <Image preview={false} width={33} src={save} style={{ marginRight: '5px' }}></Image>
                        </a>
                      </Tooltip>
                    </>
                  ) : (
                    <>
                      <Tooltip title="保存" placement="top" overlayClassName="tooltip-text">
                        <Menu
                          onClick={(e) => estimateDataSaveEvent(e, 'update')}
                          mode="horizontal"
                          items={saveMenuItems}
                          style={{
                            marginLeft: '-17px',
                            marginTop: '-24px',
                            marginRight: '5px',
                            height: 0,
                            color: 'white',
                          }}
                        />
                      </Tooltip>
                    </>
                  )}
                  {/* </Col> */}
                  {/* <Col span={7}> */}
                  {selectedEstimateData?.estimateProducts?.id !== 0 ? (
                    <Input
                      className="editInfoTle"
                      value={'編集'}
                      type="text"
                      prefix={
                        <Image
                          preview={false}
                          width={13}
                          src={edit_info}
                          style={{
                            transform: 'rotate(270deg)',
                            marginRight: '5px',
                          }}
                        />
                      }
                    />
                  ) : (
                    <Input className="editInfoTle" value={'新規'} type="text" />
                  )}
                  <Tooltip title="破棄" placement="top" overlayClassName="tooltip-text">
                    <a onClick={estimateDataDiscardEvent}>
                      <Image preview={false} width={22} src={close}></Image>
                    </a>
                  </Tooltip>
                </Space>
              </Row>
            </div>
          </Row>
        </div>
        {/** 親部品・板金子部品・形鋼子部品 */}
        <div>
          <Row>
            <Col span={19} style={{ borderRight: '1px solid #212529' }}>
              <Tabs
                className="estimate-tab"
                type="card"
                defaultActiveKey="1"
                activeKey={activePartMenu}
                items={tabItems}
                onChange={partMenuChange}
              />
              <div style={{ display: activePartMenu == '1' ? 'block' : 'none', marginTop: 7 }}>
                <ParentEstimate
                  editMode={editMode}
                  ref={parentEstimateRef}
                  // selectedEstimateData={isPMXComplete ? selectedPMXEstimateData : props.selectedEstimateData}
                  selectedEstimateData={props.selectedEstimateData}
                  // selectedTestEstimateData={isPMXComplete ? selectedPMXEstimateData : props.selectedEstimateData}
                  updateMainEstimateEditMode={updateMainEstimateEditMode}
                  activePartMenu={activePartMenu}
                  updateEstimateInfo={updateEstimateInfo}
                  isReCal={props.isReCal}
                  updateEstimateInfoByReCal={updateEstimateInfoByReCal}
                  newMode={props.newMode}
                  getParentQuantity={getParentQuantity}
                />
              </div>
              <div style={{ display: activePartMenu == '2' ? 'block' : 'none', marginTop: 7 }}>
                <IQ3Estimate
                  type={checkPMXOrNot}
                  editMode={editMode}
                  ref={iQ3EstimateRef}
                  updateEstimateInfo={updateEstimateInfo}
                  // iq3Data={selectediq3Data}
                  // selectedEstimateData={isPMXComplete ? selectedPMXEstimateData : props.selectedEstimateData}
                  selectedEstimateData={props.selectedEstimateData}
                  updateMainEstimateEditMode={updateMainEstimateEditMode}
                  // targetRowKey={props.iq3TargetRowKey == undefined ? 0 : props.iq3TargetRowKey}
                  activePartMenu={activePartMenu}
                  userSettingInfo={props.userSettingInfo}
                  getParentQuantity={getParentQuantity}
                />
              </div>
            </Col>
            <Col span={5}>
              {/* 見積基本情報 */}
              <EstimateBasicInfo
                estimateBasicInfo={props.selectedEstimateData}
                ref={estimateBasicInfoRef}
                getCurrentIQ3={getCurrentIQ3}
                settingParametersByReCal={props.settingParametersByReCal}
                activePartMenu={activePartMenu}
              />
            </Col>
          </Row>
        </div>
      </Form>
      {/* PMX用工程選択画面　*/}
      <Modal
        maskClosable={false}
        title={childModalTitle}
        open={isPMXProcessPatternOpen}
        onOk={updateOk}
        centered={true}
        onCancel={updateCancel}
        width={1490}
        className="updateModalStyle"
        bodyStyle={{ marginTop: '0px' }}
        // style={{ marginTop: '-15px' }}
        closeIcon={<CloseOutlined style={{ color: 'white' }} />}
        footer={[
          <Button key="back" onClick={backProcessPattern} className="cancelButton">
            {'< 戻る(B)'}
          </Button>,
          <Button key="next" type="primary" onClick={nextProcessPattern} className="cancelButton">
            {'次へ(N) >'}
          </Button>,
          <Button key="ok" onClick={completePMXData} className="mainButton">
            {'完了(F)'}
          </Button>,
          <Button key="cancel" onClick={cancelPMXImport} className="cancelButton">
            {'キャンセル'}
          </Button>,
        ]}
      >
        <Steps
          type="navigation"
          size="small"
          className="site-navigation-steps"
          current={currentStep}
          items={[
            {
              title: '',
            },
            {
              title: '親部品工程選択',
            },
            {
              title: '板金工程選択',
            },
            {
              title: '読込',
            },
            {
              title: '',
            },
          ]}
        />
        <Row style={{ marginBottom: '20px' }}>
          <Col span={14}>
            <Row style={{ marginTop: 0 }}>
              <p style={{ fontSize: '13.5px', fontWeight: '500', margin: 0 }}>
                【指定した編集中の見積データに対しての動作】
              </p>
            </Row>
            <Row style={{ marginTop: '10px' }}>
              <Col span={24}>
                <ul class="pmxType">
                  <li>{pmxProcessType}</li>
                </ul>
                {/* <Radio.Group value={pmxProcessType}>
                  <Space direction="vertical">
                    <Radio value={0}>編集中の見積を保存して新規見積を作成する</Radio>
                    <Radio value={1}>新規見積を作成する</Radio>
                    <Radio value={2}>編集中の見積に追加する</Radio>
                  </Space>
                </Radio.Group> */}
              </Col>
            </Row>
          </Col>
          <Col span={10}>
            <Row style={{ height: 42, width: '98%' }}>
              <Col span={8} className="parent-pdetail-tbl-val-title" style={{ padding: 5 }}>
                親部品工程パターン
              </Col>
              <Col
                span={16}
                style={{
                  verticalAlign: 'middle',
                  padding: 5,
                  borderTop: '1px solid rgb(181, 178, 174)',
                  borderRight: '1px solid rgb(181, 178, 174)',
                }}
              >
                <Select
                  style={{ width: '90%', marginLeft: 20 }}
                  id="pattern"
                  name="pattern"
                  // value={parentPatternNo}
                  value={parentPatternNo != 0 ? parentPatternNo : Empty}
                  onChange={(e) => onChangeProcessPatternInfo(0, e)}
                  className={'input-editable'}
                  options={patternList?.map((item) => ({
                    key: item.id,
                    value: item.id,
                    label: item.name === '' ? Empty : item.name,
                  }))}
                />
              </Col>
            </Row>
            <Row style={{ height: 42, width: '98%', borderTop: '1px solid rgb(181, 178, 174)' }}>
              <Col span={8} className="parent-pdetail-tbl-val-title" style={{ padding: 5 }}>
                板金工程パターン
              </Col>
              <Col
                span={16}
                style={{
                  verticalAlign: 'middle',
                  padding: 5,
                  borderBottom: '1px solid rgb(181, 178, 174)',
                  borderRight: '1px solid rgb(181, 178, 174)',
                }}
              >
                <Select
                  style={{ width: '90%', marginLeft: 20 }}
                  id="childPatternNo"
                  name="childPatternNo"
                  // value={childPatternNo}
                  value={childPatternNo != 0 ? childPatternNo : Empty}
                  onChange={(e) => onChangeProcessPatternInfo(1, e)}
                  className={'input-editable'}
                  options={iq3PatternList?.map((item) => ({
                    key: item.id,
                    value: item.id,
                    label: item.name === '' ? Empty : item.name,
                  }))}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <div className="overflow-auto kouteical timecharge" style={{ height: '60vh', overflow: 'hidden' }}>
          {isNext == false ? (
            <ParentKouteiSentaku
              type={'pmx'}
              editMode={true}
              patternNo={parentPatternNo}
              updateSelectedParentPatternNo={updateSelectedParentPatternNo}
              // selectedDataDetail={selectedEstimateData}
              selectedDataDetail={selectedPMXEstimateData}
              pmxMode={true}
              ref={parentKouteiSentakuRef}
              updatePatternList={updatePatternList}
            />
          ) : (
            <IQ3KouteiSentaku
              type={'pmx'}
              patternNo={childPatternNo}
              updateSelectedPatternNo={updateSelectedPatternNo}
              editMode={true}
              selectedDataDetail={selectedPMXEstimateData}
              selectedCardData={selectedCardData}
              // selectedEstimateData={selectedPMXEstimateData}
              selectedIQ3DataDetail={
                selectediq3Data != undefined && selectediq3Data?.length > 0 ? selectediq3Data[0] : []
              }
              pmxMode={true}
              iQ3DataLst={selectediq3Data}
              ref={iQ3KouteiSentakuRef}
              updatePatternList={updatePatternList}
              // updatedSelectedData={updatedSelectedData}
            />
          )}
        </div>
      </Modal>

      {/* PMX読込クラウド画面　*/}
      {/* <Modal
        maskClosable={false}
        title={childModalTitle}
        open={isPMXCloudFileDialogOpen}
        onOk={updateOk}
        onCancel={updateCancel}
        width={1490}
        className="pmxCloudFileModal"
        // bodyStyle={{ height: 750, marginTop: '0px' }}
        style={{ marginTop: '-15px' }}
        closeIcon={<CloseOutlined style={{ color: 'white' }} />}
        footer={[
          // <Button key="back" onClick={nextIQ3FileLoad} className="mainButton">
          //   {'続けて選択'}
          // </Button>,
          <Button key="next" type="primary" onClick={cloudPMXFileLoadOk} className="mainButton">
            {'OK'}
          </Button>,
          <Button key="cancel" onClick={cloudPMXFileLoadCancel} className="cancelButton">
            {'キャンセル'}
          </Button>,
        ]}
      >
         <Tabs
          className="pmx-cloud-file-tab"
          type="card"
          defaultActiveKey="1"
          activeKey={pmxTabKey}
          items={pmxTabItems}
          onChange={pmxTabChange}
        /> 
        <PMX_CloudFileDialog_Tab_Parent
          selectedParentRows={getSelectedParentRows}
          ref={pmxCloudParentRef}
          cardData={cardInfo}
        />
      </Modal> */}
      {isPMXCloudFileDialogOpen ? (
        <PMX_CloudFileDialog_Tab_Parent
          pmxModal={isPMXCloudFileDialogOpen}
          selectedParentRows={getSelectedParentRows}
          ref={pmxCloudParentRef}
          cardData={cardInfo}
          pmxProcessPattern={openPMXProcessPatternModal}
        />
      ) : (
        ''
      )}
      {/** 開発中ポップアップ */}
      {openCommonTaiochuPopup ? commonTaiochuModal(openCommonTaiochuPopup, closeCommonTaiochuPopup) : ''}
      {/** 画面切替警告メッセージ */}
      {confirmScreenChangeModalOpen
        ? commonModal(
            confirmScreenChangeModalOpen,
            confirmScreenChangeTitle,
            commonFooter(discardConfirmOk, discardConfirmCancel),
            null,
            400,
            discardConfirmOk,
            discardConfirmCancel,
            confirmScreenChangeContent,
            null,
            null,
            false
          )
        : ''}
      {/* 帳票出力 */}
      {isReportOutputOpen
        ? commonModal(
            isReportOutputOpen,
            reportModalTitle,
            null,
            { height: 120 },
            600,
            reportOutputOk,
            reportOutputCancel,
            reportParentList,
            'updateModalStyle',
            { top: 50 },
            false
          )
        : ''}
      {/* PC保存確認 */}
      {isEstimateDataSaveConfirm
        ? commonModal(
            isEstimateDataSaveConfirm,
            reportModalTitle,
            null,
            { height: 120 },
            550,
            reportOutputOk,
            reportOutputCancel,
            estimateDataSaveContent,
            'updateModalStyle',
            { top: 50 },
            false
          )
        : ''}
      {/** 閲覧モードでもこのデータを保存していない場合は破棄さえてしまうため、メッセージ表示 */}
      {confirmDisplayDataSaveModalOpen
        ? commonModal(
            confirmDisplayDataSaveModalOpen,
            confirmScreenChangeTitle,
            null,
            null,
            400,
            discardConfirmOk,
            discardConfirmCancel,
            confirmDisplayDataSaveContent(discardConfirmOk, discardConfirmCancel),
            null,
            null,
            false
          )
        : ''}
      {/* PMX取り込み確認 */}
      {isLoadPMXProcess
        ? commonModal(
            isLoadPMXProcess,
            pmxConfirmModalTitle,
            null,
            { height: 120 },
            300,
            null,
            pmxLocalProcessCancel,
            pmxConfirmContent,
            'updateModalStyle',
            { left: -300, top: 100 },
            false
          )
        : ''}
      {/* 親部品リストダイアログ */}
      {parentListDisplay ? (
        <Modal
          maskClosable={false}
          open={parentListDisplay}
          title={parentListModalTitle}
          footer={null}
          bodyStyle={{ height: '71.7vh', marginTop: -5, marginLeft: -23 }}
          width={1670}
          onOk={selectParentInfoOk}
          onCancel={selectParentInfoCancel}
          closeIcon={<CloseOutlined style={{ color: 'white' }} />}
          className={null}
          style={{ top: 110, left: 50 }}
          centered={false}
        >
          <div style={{ borderBottom: '1px solid #6c757d', width: '101.2%' }}>
            <ParentList
              getSelectedEstimateData={getSelectedEstimateData}
              editMode={false}
              parentListClick={false}
              isFromiQ3={true}
              isFromParent={true}
            />
          </div>
          <Row
            justify="end"
            style={{
              marginBottom: 0,
              marginTop: 7,
            }}
          >
            <Button className="mainButton" onClick={selectParentInfoOk} style={{ marginRight: 5 }}>
              追加
            </Button>
            <Button className="cancelButton" onClick={selectParentInfoCancel}>
              閉じる
            </Button>
          </Row>
        </Modal>
      ) : (
        ''
      )}

      {/* 別名保存_Modal */}
      {isSaveByOtherName
        ? commonModal(
            isSaveByOtherName,
            commonModalTitle,
            commonFooter(saveByOtherNameOk, saveByOtherNameCancel),
            null,
            520,
            saveByOtherNameOk,
            saveByOtherNameCancel,
            saveByOtherNameInfoContent,
            null,
            null,
            false
          )
        : ''}

      {/* 上書き保存_Modal */}
      {isSaveByOverwrite
        ? commonModal(
            isSaveByOverwrite,
            confirmModalTitle,
            commonFooter(saveByOverwriteOk, saveByOverwriteCancel),
            null,
            400,
            saveByOverwriteOk,
            saveByOverwriteCancel,
            confirmOverwriteSaveContent,
            null,
            null,
            null,
            false
          )
        : ''}
      {createConfirm
        ? commonModal(
            createConfirm,
            confirmModalTitle,
            commonFooter(createOk, createCancel),
            null,
            400,
            createOk,
            createCancel,
            confirmSaveContent,
            null,
            null,
            null,
            false
          )
        : ''}

      {discardConfirm
        ? commonModal(
            discardConfirm,
            confirmModalTitle,
            commonFooter(discardOk, discardCancel),
            null,
            400,
            discardOk,
            discardCancel,
            confirmDiscardContent,
            null,
            null,
            null,
            false
          )
        : ''}
      {saveDiscardConfirmPopup
        ? commonModal(
            saveDiscardConfirmPopup,
            confirmScreenChangeWarning,
            null,
            null,
            500,
            discardConfirmOk,
            closeOk,
            saveDiscardConfirmContent,
            null,
            null,
            false
          )
        : ''}
    </div>
  );
});

export default MainEstimate;
