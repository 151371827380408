import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { Row, Col, Input, Select, Popover, Checkbox, Space, InputNumber, notification } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import CurrencyInput from 'react-currency-input-field';
import { BsUsbC, BsX } from 'react-icons/bs';

import '../../assets/styles/common.css';
import {
  handelIncreaseTime,
  handelDecreaseTime,
  checkInput,
  secondsToHms,
  setTimetoSec,
  checkInputWithColor,
  checkShowHideIcon,
  handleShowHide,
  handleCollpse,
  handleKeyPress,
  kouteiCalculateSelectedArr,
} from '../common/Common.js';
import { DataType, MaterialSurfaceBillingUnit, ServiceClass, WorkType, WorkTypeGroup } from '../common/enums';
import { amtEmptyStr, timeHmsStr } from '../common/Constant';

const IQ3KouteiCalculate_2jikakou = forwardRef((props, ref) => {
  const [isNijikakoudetail, setNijikakoudetail] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [totalAmt, setTotalAmt] = useState('');
  const [totalTime, setTotalTime] = useState('');
  const [totalSagyoAmt, setTotalSagyoAmt] = useState('');
  const [totalSagyoTime, setTotalSagyoTime] = useState('');
  const [totalDandoriAmt, setTotalDandoriAmt] = useState('');
  const [totalDandoriTime, setTotalDandoriTime] = useState('');

  const [totalSum, setTotalSum] = useState('');
  const [totalTimeSum, setTotalTimeSum] = useState('');
  const [sagyoAmtSum, setSagyoAmtSum] = useState('');
  const [sagyoTimeSum, setSagyoTimeSum] = useState('');
  const [dandoriAmtSum, setDandoriAmtSum] = useState('');
  const [dandoriTimeSum, setDandoriTimeSum] = useState('');

  const [processArr, setProcessArr] = useState([]);
  const [editedData, setEditedData] = useState([]);
  const [oldEditedData, setOldEditedData] = useState([]);

  const [editedHandelingAmt, setEditedHandelingAmt] = useState();
  const [editedHandelingTime, setEditedHandelingTime] = useState();
  const [editedKensaAmt, setEditedKensaAmt] = useState();
  const [editedKensaTime, setEditedKensaTime] = useState();

  const [subNijikakousArr, setSubNijikakouArr] = useState([]);
  const [nijikakousArr, setNijikakouArr] = useState([]);
  const [selectedDataFlg, setSelectedDataFlg] = useState(0);
  const [updateEditedData, setUpdateEditedData] = useState([]);
  const [secondaryWorkAmt, setSecondaryWorkAmt] = useState(0);
  const [deviceMst, setDeviceMst] = useState([]);
  const [processMst, setProcessMst] = useState([]);
  const [patternInfo, setPatternInfo] = useState([]);

  const JPYs = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'JPY',
  });

  const handleChangeCheckData = (e, i) => {
    /* setSelectedDataFlg(e);
    props?.sendSelectedDataFlg(e); */
    const data = { ...updateEditedData };
    data.dataType = e;
    setUpdateEditedData(data);
    setSecondaryWorkAmt(e == DataType.Data ? data.totalDataPrice : data.totalEditPrice);
    props?.updateIQ3KouteiCal(data, WorkType.SmSecondaryWork);
  };

  const settingEditData = (arr) => {
    let editDataArr = [];

    for (let i = 0; i < arr[0].dandoriKakouAmt?.length; i++) {
      let subArr = arr[0].dandoriKakouAmt;
      editDataArr.push({
        id: i,
        dandoriAmt: subArr[i].dandoriAmt,
        dandoriTime: subArr[i].dandoriTime,
        sagyoAmt: subArr[i].sagyoAmt,
        sagyoTime: subArr[i].sagyoTime,
        processType: subArr[i].processType,
        diameter: subArr[i].diameter, //径
        workTimeSize: subArr[i].workTimeSize, //1つ当たりの加工時間
        processCnt: subArr[i].count,
        blankFlag: subArr[i].blankFlag,
      });
    }
    return editDataArr;
  };

  const calculateTotals = (editDataArr) => {
    let sagyoAmtSum = 0;
    let dandoriAmtSum = 0;

    let sagyoTimeSec = 0;
    let dandoriTimeSec = 0;

    editDataArr?.forEach((element) => {
      sagyoAmtSum += element.sagyoAmt;
      dandoriAmtSum += element.dandoriAmt;
      sagyoTimeSec += element.sagyoTime;
      dandoriTimeSec += element.dandoriTime;
    });

    // sagyoAmtSum += parseInt(editDataArr[0].handelingAmt);
    // sagyoTimeSec += setTimetoSec(editDataArr[0].handelingTime);

    // let totalSum = Math.floor(sagyoAmtSum + dandoriAmtSum + editDataArr[0].kensaAmt);
    let totalSum = sagyoAmtSum + dandoriAmtSum;
    let sagyoTimeSum = sagyoTimeSec;
    let dandoriTimeSum = dandoriTimeSec;
    // let totalTimeSum = secondsToHms(sagyoTimeSec + dandoriTimeSec + setTimetoSec(editedKensaTime));
    let totalTimeSum = sagyoTimeSec + dandoriTimeSec;

    let totals = [];
    let i = 0;
    totals.push({
      id: i++,
      sagyoAmtSum: sagyoAmtSum,
      dandoriAmtSum: dandoriAmtSum,
      sagyoTimeSec: sagyoTimeSec,
      dandoriTimeSec: dandoriTimeSec,
      totalSum: totalSum,
      sagyoTimeSum: sagyoTimeSum,
      dandoriTimeSum: dandoriTimeSum,
      totalTimeSum: totalTimeSum,
    });
    return totals;
  };

  const contentDandori = (
    <div>
      {/* <p>
        段取金額=アルゴン段取金額＋ボルト段取金額
        <br /> {totalDandoriAmt} =
        {(() => {
          const subArr = [];
          for (let j = 0; j < editedData?.length; j++) {
            subArr.push(editedData[j]?.dandoriAmt);
          }
          return subArr.join('+');
        })()}
      </p>
      <p>
        段取時間=アルゴン段取時間＋ボルト段取時間
        <br /> {totalDandoriTime} =
        {(() => {
          const subArr = [];
          for (let j = 0; j < editedData?.length; j++) {
            subArr.push(editedData[j]?.dandoriTime);
          }
          return subArr.join('+');
        })()}
      </p> */}
    </div>
  );

  const contentSagyo = (
    <div>
      {/* <p>
        作業金額=アルゴン作業金額＋ボルト作業金額
        <br /> {totalSagyoAmt} =
        {(() => {
          const subArr = [];
          for (let j = 0; j < editedData?.length; j++) {
            subArr.push(editedData[j]?.sagyoAmt);
          }
          return subArr.join('+');
        })()}
      </p>
      <p>
        作業時間=アルゴン作業時間＋ボルト作業時間
        <br /> {totalSagyoTime} =
        {(() => {
          const subArr = [];
          for (let j = 0; j < editedData?.length; j++) {
            subArr.push(editedData[j]?.sagyoTime);
          }
          return subArr.join('+');
        })()}
      </p> */}
    </div>
  );

  const contentSub = (
    <div>
      {/* <p>
        作業金額=チャージ金額 x 作業時間
        <br /> ¥233.3334 = ¥4,200(/h) x 200(秒)
      </p>
      <p>
        作業時間 = 加工長 / 加工速度 x 溶接難易度係数 x 製品サイズ係数 x 生産個数係数 <br />
        200(秒) = 200(mm) / 1(mm/秒) x 1 x 1 x 1
      </p>
      <p>
        引用パラメータ
        <br />
        加工長：200mm
        <br /> 加工速度：60mm/分 = 1mm/秒 <br />
        溶接難易度係数：1 <br />
        製品サイズ係数：1 <br />
        生産個数係数：1
      </p> */}
    </div>
  );

  /* "input[data-type='currency']".on({
    keyup: function () {
      alert('test');
    },
  }); */

  const uniquifyArr = (arr) => {
    const uniqueProcessTypes = new Set();
    let uniqueData = arr?.filter((item) => {
      if (!uniqueProcessTypes.has(item.processType)) {
        uniqueProcessTypes.add(item.processType);
        return true;
      }
      return false;
    });
    return uniqueData;
  };

  useEffect(() => {
    let editData = [];
    let totals = [];
    let processMst = [];
    let deviceMst = []; //テスト用ID;
    let toBEdit = [];

    let parameters = props?.selectedEstimateData?.estimateProducts?.calcParameters?.parameters;
    // 数量
    let partCnt = props?.selectedEstimateData?.estimateProducts?.quantity;
    if (parameters?.process?.length > 0) {
      processMst = parameters?.process
        ? parameters?.process?.filter(
            (item) => item?.class === ServiceClass.SheetMetal && item.workType === WorkType.SmSecondaryWork
          )[0]
        : [];
      setProcessMst(processMst ? processMst : []);
      if (parameters?.device?.length > 0) {
        deviceMst = parameters?.device?.filter((item) => processMst?.id == item?.processId);
      }
    }

    let patternInfo = props?.selectedIQ3DataDetail?.processSelect
      ? props?.selectedIQ3DataDetail?.processSelect?.details?.filter((item) => item.processId == processMst?.id)
      : [];
    setPatternInfo([]);
    if (patternInfo == undefined) return;
    if (patternInfo?.length > 0 && patternInfo[0]?.isUsedForCalc == false) return;

    deviceMst =
      deviceMst && patternInfo
        ? deviceMst?.filter(
            (item) => item.processId == patternInfo[0]?.processId && item.id == patternInfo[0]?.deviceId
          )[0]
        : []; //テスト用ID;
    setDeviceMst(deviceMst ? deviceMst : []);
    if (deviceMst == undefined) return;
    setPatternInfo(patternInfo);

    let secondaryWorkAdditions = props?.selectedIQ3DataDetail?.additions?.filter(
      (item) => item.processId == processMst?.id
    )[0];
    // 2次加工工程入力情報取得
    let secondaryWorkProcessInput = props?.selectedIQ3DataDetail?.processInput?.filter(
      (item) => item.processId == processMst?.id
    )[0];

    let detailItems = [];
    let editDetailItems = [];
    //2次加工種類（サイズ判断）設定
    let sizeProcessInputs = secondaryWorkProcessInput?.details?.filter(
      (item) => item.workTypeGroup == WorkTypeGroup.SmSecondaryWorkSize
    );
    for (let j = 0; j < sizeProcessInputs?.length; j++) {
      let sizeProcessInput = sizeProcessInputs[j];
      let processTypeNm = processMst?.details?.processDetailTypes?.filter(
        (item) => item.id == sizeProcessInput.types && item.group == sizeProcessInput.workTypeGroup
      )?.[0];
      detailItems.push({
        id: j + 1,
        processName: '2次加工処理(No' + (j + 1) + ')',
        processTypeId: processTypeNm?.id,
        processType: processTypeNm?.name,
        dandoriAmt: 0,
        dandoriTime: 0,
        sagyoAmt: 0,
        sagyoTime: 0,
        workTimeSize: 0, //1つ当たりの加工時間
        diameter: sizeProcessInput.diameter, //径
        processCnt: sizeProcessInput.count, // 数
        blankFlag: sizeProcessInput.blankFlag, // ブランクフラグ
        secondaryWorkType: sizeProcessInput.workTypeGroup, // 2次加工種類（サイズ判断）/ 2次加工種類（ミリメートル判断）
        partCnt: partCnt,
        formula: '',
      });
    }

    //2次加工種類（ミリメートル判断）設定
    let millimeterProcessInputs = secondaryWorkProcessInput?.details?.filter(
      (item) => item.workTypeGroup == WorkTypeGroup.SmSecondaryWorkMillimeter
    );
    for (let j = 0; j < millimeterProcessInputs?.length; j++) {
      let millimeterProcessInput = millimeterProcessInputs[j];
      let processTypeNm = processMst?.details?.processDetailTypes?.filter(
        (item) => item.id == millimeterProcessInput.types && item.group == millimeterProcessInput.workTypeGroup
      )?.[0];
      let detailItemsId = detailItems?.length == 0 ? 0 : detailItems?.length;
      detailItems.push({
        id: detailItemsId + j + 1,
        processName: '2次加工処理(No' + (j + 1) + ')',
        processTypeId: processTypeNm?.id,
        processType: processTypeNm?.name + '(M' + millimeterProcessInput.diameter + ')',
        dandoriAmt: 0,
        dandoriTime: 0,
        sagyoAmt: 0,
        sagyoTime: 0,
        workTimeSize: 0, //1つ当たりの加工時間
        diameter: millimeterProcessInput.diameter, //径
        processCnt: millimeterProcessInput.count, // 数
        blankFlag: millimeterProcessInput.blankFlag, // ブランクフラグ
        secondaryWorkType: millimeterProcessInput.workTypeGroup, // 2次加工種類（サイズ判断）/ 2次加工種類（ミリメートル判断）
        partCnt: partCnt,
        formula: '',
      });
    }

    editDetailItems = [];
    let secondaryWorkAddition = {
      no: processMst?.id,
      processId: processMst?.id,
      dataType: secondaryWorkAdditions?.dataType ? secondaryWorkAdditions?.dataType : DataType.Data,
      dataItems: detailItems,
      editItems: secondaryWorkAdditions?.editItems ? secondaryWorkAdditions?.editItems : editDetailItems,
      totalDandori: {
        dataItemsAmt: 0,
        dataItemsTime: 0,
        editItemsAmt: 0,
        editItemsTime: 0,
      },
      totalSagyo: {
        dataItemsAmt: 0,
        dataItemsTime: 0,
        editItemsAmt: 0,
        editItemsTime: 0,
      },
      totalDataPrice: 0,
      totalDataTime: 0,
      totalEditPrice: 0,
      totalEditTime: 0,
    };
    secondaryWorkAdditions = secondaryWorkAddition;

    if (secondaryWorkAdditions?.dataItems) {
      let orgData = JSON.parse(JSON.stringify(secondaryWorkAdditions?.dataItems));
      if (deviceMst != undefined) {
        let totalDandori = 0;
        let totalSagyo = 0;
        let totalSagyoTm = 0;
        let totalDandoriTm = 0;
        let dataItemsByProcessInput = [];

        // 板厚
        let thickness = props?.selectedIQ3DataDetail?.thickness;
        // 重量
        let weight = props?.selectedIQ3DataDetail?.weight;
        // 面積
        let area = props?.selectedIQ3DataDetail?.totalSurfaceArea;
        //材質
        let materialTypeIq3Id = props?.selectedIQ3DataDetail?.materialTypeIq3Id;

        //材質区分
        let materialTypeId = parameters?.materialTypeIq3?.filter((item) => item.id == materialTypeIq3Id)?.[0]
          ?.materialTypeId;

        // サイズ係数
        let sizeCoef = getSizeCoeff(deviceMst, weight, area);

        let dandoriSameTypeArr = [];

        let mainSecondaryWorkAdditions = orgData?.filter(
          (item) => item.secondaryWorkType == WorkTypeGroup.SmSecondaryWorkSize
        );
        let mainSecondaryWorkProcessInput = secondaryWorkProcessInput?.details?.filter(
          (item) => item.workTypeGroup == WorkTypeGroup.SmSecondaryWorkSize
        );
        for (let i = 0; i < mainSecondaryWorkProcessInput?.length; i++) {
          //工程入力情報
          let secondaryWorkProcessInputInfo = mainSecondaryWorkProcessInput[i];

          if (mainSecondaryWorkAdditions?.length == 0) continue;
          let dataItemByProcessInput = mainSecondaryWorkAdditions[i];

          let processTypeNm = processMst?.details?.processDetailTypes?.filter(
            (item) =>
              item.id == secondaryWorkProcessInputInfo.types &&
              item.group == secondaryWorkProcessInputInfo?.workTypeGroup
          )?.[0];
          dataItemByProcessInput.processType = processTypeNm?.name;

          let deviceCharges = Object.entries(deviceMst?.details?.charges)?.filter(
            ([key, data]) => parseInt(key) === secondaryWorkProcessInputInfo?.types
          )?.[0];
          /** 段取金額/時間 */
          // 基本段取時間＊サイズ係数
          let isSameType = dandoriSameTypeArr?.filter((item) => item == secondaryWorkProcessInputInfo?.types);
          if (isSameType?.length == 0 || dandoriSameTypeArr?.length == 0) {
            let dandoriTimeSec = calDandoriTime(deviceMst, weight, area, secondaryWorkProcessInputInfo?.types);
            let dandoriTime = dandoriTimeSec;
            dataItemByProcessInput.dandoriTime = dandoriTime;
            // 段取時間の合計
            totalDandoriTm += dandoriTime;

            // 段取金額設定
            //段階時間
            let mSetTimeMin = dandoriTimeSec / 60;
            //装置の段階時間チャージ

            let mSetHRate = 0;
            deviceCharges?.map((item, index) => {
              if (index > 0) {
                mSetHRate = item?.prep;
              }
            });
            let dandoriAmt = (mSetTimeMin * mSetHRate) / 60.0; //段階時間＊時間チャージ

            dataItemByProcessInput.dandoriAmt = dandoriAmt;
            // 段取金額の合計
            totalDandori += dandoriAmt;
          } else {
            dataItemByProcessInput.dandoriTime = 0;
            dataItemByProcessInput.dandoriAmt = 0;
          }

          // 作業時間（{(1つ当たりの加工時間	+	(加工補正時間	*	加工補正係数)｝	*	サイズ係数）
          // 1つ当たりの加工時間
          let sagyoTimePerSec = getSagyoTime(
            deviceMst,
            secondaryWorkProcessInputInfo?.diameter,
            secondaryWorkProcessInputInfo?.types,
            secondaryWorkProcessInputInfo?.workTypeGroup
          );
          // 加工補正時間
          let modifyTimeQtyThMaster = deviceMst?.details?.modifyTimeQtyThMaster?.filter(
            (item) =>
              item.value == secondaryWorkProcessInputInfo?.count || item.value > secondaryWorkProcessInputInfo?.count
          )?.[0];
          let hoseTime = deviceMst?.details?.modifyTimeItems?.filter(
            (item) => item.no == modifyTimeQtyThMaster?.no
          )?.[0]?.value;
          // 加工補正係数
          let hoseCoeff = getHoseCoeff(deviceMst, weight, area);
          // 作業時間（{(1つ当たりの加工時間	+	(加工補正時間	*	加工補正係数)｝	*	サイズ係数）
          let sagyoTimeSec = sagyoTimePerSec + hoseCoeff * hoseTime * sizeCoef;
          dataItemByProcessInput.sagyoTime = sagyoTimeSec;
          dataItemByProcessInput.workTimeSize = sagyoTimePerSec;
          totalSagyoTm += sagyoTimeSec;
          // 作業金額
          let mSagyoTimeMin = sagyoTimeSec / 60;
          //装置の作業時間チャージ
          let mSagyoHRate = 0;
          deviceCharges?.map((item, index) => {
            if (index > 0) {
              mSagyoHRate = item?.work;
            }
          });
          let sagyoAmt = (mSagyoTimeMin * mSagyoHRate) / 60.0; //作業時間＊時間チャージ
          dataItemByProcessInput.sagyoAmt = sagyoAmt;

          // 作業金額の合計
          totalSagyo += sagyoAmt;
          dataItemsByProcessInput.push(dataItemByProcessInput);
          dandoriSameTypeArr.push(secondaryWorkProcessInputInfo?.types);
        }

        let millimeterSecondaryWorkAdditions = orgData?.filter(
          (item) => item.secondaryWorkType == WorkTypeGroup.SmSecondaryWorkMillimeter
        );
        let millimeterSecondaryProcessType = secondaryWorkProcessInput?.details?.filter(
          (item) => item.workTypeGroup == WorkTypeGroup.SmSecondaryWorkMillimeter
        );
        for (let i = 0; i < millimeterSecondaryProcessType?.length; i++) {
          //工程入力情報
          let secondaryWorkProcessInputInfo = millimeterSecondaryProcessType[i];
          if (millimeterSecondaryWorkAdditions?.length == 0) continue;
          let dataItemByProcessInput = millimeterSecondaryWorkAdditions[i];

          let processTypeNm = processMst?.details?.processDetailTypes?.filter(
            (item) =>
              item.id == secondaryWorkProcessInputInfo.types &&
              item.group == secondaryWorkProcessInputInfo?.workTypeGroup
          )?.[0];
          dataItemByProcessInput.processType = processTypeNm?.name;

          let deviceCharges = Object.entries(deviceMst?.details?.charges)?.filter(
            ([key, data]) => parseInt(key) === secondaryWorkProcessInputInfo?.types
          )?.[0];
          /** 段取金額/時間 */
          // 基本段取時間＊サイズ係数
          let isSameType = dandoriSameTypeArr?.filter((item) => item == secondaryWorkProcessInputInfo?.types);
          if (isSameType?.length == 0 || dandoriSameTypeArr?.length == 0) {
            let dandoriTimeSec = calDandoriTime(deviceMst, weight, area, secondaryWorkProcessInputInfo?.types);
            let dandoriTime = dandoriTimeSec;
            dataItemByProcessInput.dandoriTime = dandoriTime;
            // 段取時間の合計
            totalDandoriTm += dandoriTime;

            // 段取金額設定
            //段階時間
            let mSetTimeMin = dandoriTimeSec / 60;
            //装置の段階時間チャージ

            let mSetHRate = 0;
            deviceCharges?.map((item, index) => {
              if (index > 0) {
                mSetHRate = item?.prep;
              }
            });
            let dandoriAmt = (mSetTimeMin * mSetHRate) / 60.0; //段階時間＊時間チャージ

            dataItemByProcessInput.dandoriAmt = dandoriAmt;
            // 段取金額の合計
            totalDandori += dandoriAmt;
          } else {
            dataItemByProcessInput.dandoriTime = 0;
            dataItemByProcessInput.dandoriAmt = 0;
          }

          // 作業時間（{(1つ当たりの加工時間	+	(加工補正時間	*	加工補正係数)｝	*	サイズ係数）
          // 1つ当たりの加工時間
          let sagyoTimePerSec = getSagyoTime(
            deviceMst,
            secondaryWorkProcessInputInfo?.diameter,
            secondaryWorkProcessInputInfo?.types,
            secondaryWorkProcessInputInfo?.workTypeGroup
          );
          // 加工補正時間
          let modifyTimeQtyThMaster = deviceMst?.details?.modifyTimeQtyThMaster?.filter(
            (item) =>
              item.value == secondaryWorkProcessInputInfo?.count || item.value > secondaryWorkProcessInputInfo?.count
          )?.[0];
          let hoseTime = deviceMst?.details?.modifyTimeItems?.filter(
            (item) => item.no == modifyTimeQtyThMaster?.no
          )?.[0]?.value;
          // 加工補正係数
          let hoseCoeff = getHoseCoeff(deviceMst, weight, area);
          // 作業時間（{(1つ当たりの加工時間	+	(加工補正時間	*	加工補正係数)｝	*	サイズ係数）
          let sagyoTimeSec = sagyoTimePerSec + hoseCoeff * hoseTime * sizeCoef;
          dataItemByProcessInput.sagyoTime = sagyoTimeSec;
          dataItemByProcessInput.workTimeSize = sagyoTimePerSec;

          // 作業金額
          let mSagyoTimeMin = sagyoTimeSec / 60;
          //装置の作業時間チャージ
          let mSagyoHRate = 0;
          deviceCharges?.map((item, index) => {
            if (index > 0) {
              mSagyoHRate = item?.work;
            }
          });
          let sagyoAmt = (mSagyoTimeMin * mSagyoHRate) / 60.0; //作業時間＊時間チャージ
          dataItemByProcessInput.sagyoAmt = sagyoAmt;

          // 作業金額の合計
          totalSagyo += sagyoAmt;
          dataItemsByProcessInput.push(dataItemByProcessInput);
          dandoriSameTypeArr.push(secondaryWorkProcessInputInfo?.types);
        }
        // 段取金額の合計
        setDandoriAmtSum(totalDandori);
        secondaryWorkAdditions.totalDandori.dataItemsAmt = totalDandori;
        // 段取時間の合計
        setDandoriTimeSum(totalDandoriTm);
        secondaryWorkAdditions.totalDandori.dataItemsTime = totalDandoriTm;
        // 作業金額の合計
        setSagyoAmtSum(totalSagyo);
        secondaryWorkAdditions.totalSagyo.dataItemsAmt = totalSagyo;
        setSagyoTimeSum(totalSagyoTm);
        secondaryWorkAdditions.totalSagyo.dataItemsTime = totalSagyoTm;

        /** 　合計時間　*/
        let totalTimeSum = totalDandoriTm + totalSagyoTm;
        setTotalTimeSum(totalTimeSum);
        secondaryWorkAdditions.totalDataTime = totalTimeSum;

        /** 　合計金額　*/
        let totalSum = totalDandori + totalSagyo;
        setTotalSum(totalSum);
        secondaryWorkAdditions.totalDataPrice = totalSum;

        orgData = dataItemsByProcessInput;
        setProcessArr(dataItemsByProcessInput);

        if (secondaryWorkAdditions.dataType == DataType.Edit) {
          toBEdit = JSON.parse(JSON.stringify(secondaryWorkAdditions?.editItems));
        } else {
          toBEdit = JSON.parse(JSON.stringify(orgData));
        }
      }
    }
    if (toBEdit?.length > 0) {
      editData = toBEdit;
      secondaryWorkAdditions.editItems = editData;
      if (editData?.length > 0) {
        setEditedData(editData);
        totals = calculateTotals(editData);

        setTotalAmt(totals[0]?.totalSum);
        secondaryWorkAdditions.totalEditPrice = totals[0]?.totalSum;
        secondaryWorkAdditions.totalDandori.editItemsAmt = totals[0]?.totalSum;

        setTotalTime(totals[0]?.totalTimeSum);
        secondaryWorkAdditions.totalEditTime = totals[0]?.totalTimeSum;
        secondaryWorkAdditions.totalDandori.editItemsTime = totals[0]?.totalTimeSum;

        setTotalSagyoAmt(totals[0]?.sagyoAmtSum);
        setTotalSagyoTime(totals[0]?.sagyoTimeSum);

        setTotalDandoriAmt(totals[0]?.dandoriAmtSum);
        setTotalDandoriTime(totals[0]?.dandoriTimeSum);
      }
      setUpdateEditedData(secondaryWorkAdditions);
    }
    if (secondaryWorkAdditions?.dataType == DataType.Data) {
      setSecondaryWorkAmt(secondaryWorkAdditions.totalDataPrice);
    } else {
      setSecondaryWorkAmt(secondaryWorkAdditions.totalEditPrice);
    }
    props?.updateIQ3KouteiCal(secondaryWorkAdditions, WorkType.SmSurface);
  }, [props?.activeTabKey]);

  useEffect(() => {
    setEditMode(props?.editMode != undefined ? props?.editMode : false);
  }, [props?.editMode]);

  const nijikakou = () => {
    if (isNijikakoudetail == true) {
      setNijikakoudetail(false);
    } else {
      setNijikakoudetail(true);
    }
  };

  const getSizeCoeff = (deviceMst, weight, area) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    // 段取時間設定

    // 重量
    let prodSizeWeightThMaster = deviceMst?.details?.prodSizeWeightThMaster?.filter(
      (item) => item.value == weight || item.value > weight
    );
    // 面積
    let prodSizeAreaThMaster = deviceMst?.details?.prodSizeAreaThMaster?.filter(
      (item) => item.value == area || item.value > area
    );
    let childPartsCoeffs =
      prodSizeWeightThMaster?.length > 0 && prodSizeAreaThMaster?.length > 0
        ? deviceMst?.details?.prodSizeItems?.filter((item) => item.weightId == prodSizeWeightThMaster[0]?.no)
        : deviceMst?.details?.prodSizeItems[0];

    // サイズ係数
    let partsCoeff =
      childPartsCoeffs?.length > 0
        ? childPartsCoeffs?.[0]?.areaCoeffList?.filter((item) => item.no == prodSizeAreaThMaster?.[0].no)?.[0].value
        : childPartsCoeffs?.[0]?.areaCoeffList?.[0]?.value;

    return partsCoeff;
  };

  const getHoseCoeff = (deviceMst, weight, area) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    // 段取時間設定

    // 重量
    let mtWeightThMaster = deviceMst?.details?.mtWeightThMaster?.filter(
      (item) => item.value == weight || item.value > weight
    );
    // 面積
    let mtAreaThMaster = deviceMst?.details?.mtAreaThMaster?.filter((item) => item.value == area || item.value > area);
    let childPartsCoeffs =
      mtWeightThMaster?.length > 0 && mtAreaThMaster?.length > 0
        ? deviceMst?.details?.prodSizeItems?.filter((item) => item.weightId == mtWeightThMaster[0]?.no)
        : deviceMst?.details?.prodSizeItems[0];

    // 補正係数
    let partsCoeff =
      childPartsCoeffs?.length > 0
        ? childPartsCoeffs?.[0]?.areaCoeffList?.filter((item) => item.no == mtAreaThMaster?.[0].no)?.[0].value
        : childPartsCoeffs?.[0]?.areaCoeffList?.[0]?.value;

    return partsCoeff;
  };

  const calDandoriTime = (deviceMst, weight, area, weldTypeId) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    // 段取時間設定

    // 重量
    let childPartsCoeffWeightThMaster = deviceMst?.details?.childPartsCoeffWeightThMaster?.filter(
      (item) => item.value == weight || item.value > weight
    );
    // 面積
    let childPartsCoeffAreaThMaster = deviceMst?.details?.childPartsCoeffAreaThMaster?.filter(
      (item) => item.value == area || item.value > area
    );
    let childPartsCoeffs =
      childPartsCoeffWeightThMaster?.length > 0 && childPartsCoeffAreaThMaster?.length > 0
        ? deviceMst?.details?.childPartsCoeff?.filter((item) => item.weightId == childPartsCoeffWeightThMaster[0]?.no)
        : deviceMst?.details?.childPartsCoeff[0];

    // サイズ係数
    let partsCoeff =
      childPartsCoeffs?.length > 0
        ? childPartsCoeffs?.[0]?.areaCoeffList?.filter((item) => item.no == childPartsCoeffAreaThMaster?.[0].no)?.[0]
            .value
        : childPartsCoeffs?.[0]?.areaCoeffList?.[0]?.value;

    //　基本段取時間＊サイズ係数
    let deviceDandori = Object.entries(deviceMst?.details?.prepSecs)?.filter(
      ([key, data]) => parseInt(key) === weldTypeId
    )?.[0];
    let deviceDandoriTm = 0;
    deviceDandori?.map((item, index) => {
      if (index > 0) {
        deviceDandoriTm = item;
      }
    });

    let dandoriTimeSec = deviceDandoriTm * partsCoeff;
    return dandoriTimeSec;
  };

  const calDandoriAmt = (dandoriTimeSec, deviceMst) => {
    //段階時間
    let mSetTimeMin = dandoriTimeSec / 60;
    //装置の段階時間チャージ
    // let mSetHRate = deviceMst?.details?.charges.prep;
    let mSetHRate = 10000;
    //段階時間＊時間チャージ
    let dandoriAmt = Math.floor((mSetTimeMin * mSetHRate) / 60.0);
    return dandoriAmt;
  };

  const getSagyoTime = (deviceMst, sizeMm, typeId, groupTypeId) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    let thMaster = [];
    let thItems = [];
    let workTime = 0;

    if (groupTypeId === WorkTypeGroup.SmSecondaryWorkSize) {
      thMaster = deviceMst?.details?.sizeThMaster?.filter((item) => item.value == sizeMm || item.value > sizeMm)?.[0];

      let workTimeSizeThItems = Object.entries(deviceMst?.details?.workTimeSizeThItems)?.filter(
        ([key, data]) => parseInt(key) === typeId
      )?.[0];

      workTimeSizeThItems?.map((item, index) => {
        if (index > 0) {
          workTime = item?.filter((item) => parseInt(item.no) == thMaster?.no)?.[0].value;
        }
      });
    } else {
      thMaster = deviceMst?.details?.mmThMaster?.filter((item) => item.value == sizeMm || item.value > sizeMm)?.[0];

      let workTimeSizeHoleThItems = Object.entries(deviceMst?.details?.workTimeSizeHoleThItems)?.filter(
        ([key, data]) => parseInt(key) === typeId
      )?.[0];

      workTimeSizeHoleThItems?.map((item, index) => {
        if (index > 0) {
          workTime = item?.filter((item) => parseInt(item.no) == thMaster?.no)?.[0].value;
        }
      });
    }

    return workTime;
  };

  const calSagyoAmt = (cSagyoTimeKonpoSec, deviceMst) => {
    // 作業金額
    // 作業時間
    let mWorkHTime = cSagyoTimeKonpoSec;
    let mWorkHTimeMin = mWorkHTime / 60;
    //装置の作業時間チャージ
    // let mWorkHRate = deviceMst?.details?.charges.work;
    let mWorkHRate = 10000;
    //　加工時間＊時間チャージ
    let cSagyoAmtKonpo = Math.floor((mWorkHTimeMin * mWorkHRate) / 60.0);
    return cSagyoAmtKonpo;
  };

  useEffect(() => {
    getNijikakouTypes();
  }, []);

  const getNijikakouTypes = () => {
    let subNijikakous = [];
    let nijikakous = [];
    const kouteiMasterData = JSON.parse(localStorage.getItem('kouteiMasterData'))?.forEach((e) => {
      if (e.processType === '溶接' && e.type === 'iQ3') {
        e.processDetail?.forEach((processDetail) => {
          if (
            processDetail.processDetailTitle === '取付種類（板厚判断）' ||
            processDetail.processDetailTitle === '取付種類（サイズ判断）'
          ) {
            processDetail.processDetailValue?.forEach((detailVal) => {
              subNijikakous.push(detailVal.detailType);
            });
          } else if (processDetail.processDetailTitle === '溶接種類') {
            processDetail.processDetailValue?.forEach((detailVal) => {
              nijikakous.push(detailVal.detailType);
            });
          }
        });
      }
    });
    setSubNijikakouArr(subNijikakous);
    setNijikakouArr(nijikakous);
  };

  const subpartDetail = (i) => {
    let showHide = document.getElementById('nijikakouProcessIQ3' + i).style.display;
    if (showHide == 'block') {
      document.getElementById('nijikakouProcessIQ3' + i).style.display = 'none';
      document.getElementById('arrowDownNijikakouIQ3' + i).style.display = 'block';
      document.getElementById('arrowUpNijikakouIQ3' + i).style.display = 'none';
    } else {
      document.getElementById('nijikakouProcessIQ3' + i).style.display = 'block';
      document.getElementById('arrowDownNijikakouIQ3' + i).style.display = 'none';
      document.getElementById('arrowUpNijikakouIQ3' + i).style.display = 'block';
    }
  };

  const setEditModeChange = (mode) => {
    setEditMode(mode);
  };
  useImperativeHandle(ref, () => ({
    setEditModeChange: setEditModeChange,
  }));

  const settingNewDandoriAmtInArr = (value, index, objId, timeType, timeAct) => {
    let newState = [...editedData];
    if (objId !== 'dandoriAmt' && objId !== 'dandoriTime' && objId !== 'handelingAmt' && objId !== 'handelingTime') {
      setOldEditedData(JSON.parse(JSON.stringify(editedData)));
    }
    let newStateUnique = [...editedData];
    let oldValue = editedData[index][objId];
    if (objId !== 'dandoriTime' && objId !== 'dandoriAmt') {
      oldValue = editedData[index][objId];
      newState[index][objId] = Number(value);
      updateEditedData.editItems[index][objId] = newState[index][objId];
    }

    if (objId === 'dandoriTime' || objId === 'dandoriAmt') {
      oldValue = newStateUnique[index][objId];
      newStateUnique[index][objId] = value;
      updateEditedData.editItems[index][objId] = value;
      if (objId === 'dandoriTime') {
        if (document.getElementById(index + 'NijikakouDandoriAmtCu').style.display !== undefined) {
          let showHideCu = document.getElementById(index + 'NijikakouDandoriAmtCu').style.display;
          if (showHideCu === 'block') {
            // checkInputWithColor(false, '', index + 'nijikakouDandoriAmt', newStateUnique[index].dandoriAmt, 'red');
          } else {
            if (timeAct === 'input') {
              let timeType = value - oldValue;
              newStateUnique[index].dandoriAmt = changeAmtwithTime(
                newStateUnique[index].dandoriAmt,
                timeType,
                timeAct,
                value,
                objId
              );
              updateEditedData.editItems[index].dandoriAmt = newStateUnique[index].dandoriAmt;
            } else {
              newStateUnique[index].dandoriAmt = changeAmtwithTime(
                newStateUnique[index].dandoriAmt,
                timeType,
                timeAct,
                value,
                objId
              );
              updateEditedData.editItems[index].dandoriAmt = newStateUnique[index].dandoriAmt;
            }
            /* checkInputWithColor(false, '', index + 'dandoriAmt', newStateUnique[index].dandoriAmt, 'green'); */
          }
        }
      }
      /* newState = newState?.map((item) => {
        const matchingItem = newStateUnique?.find((el) => el.processType === item.processType);
        return matchingItem
          ? { ...item, dandoriAmt: matchingItem.dandoriAmt, dandoriTime: matchingItem.dandoriTime }
          : item;
      }); */
    } else if (objId === 'sagyoTime') {
      checkInputWithColor(false, '', index + 'nijikakouSagyoAmt', newState[index].sagyoAmt, 'green');

      if (document.getElementById(index + 'nijikakouSagyoAmtCu').style.display !== undefined) {
        let showHideCu = document.getElementById(index + 'nijikakouSagyoAmtCu').style.display;
        if (showHideCu === 'block') {
          checkInputWithColor(false, '', index + 'nijikakouSagyoAmt', newState[index].sagyoAmt, 'red');
        } else {
          if (timeAct === 'input') {
            let timeType = value - oldValue;
            newState[index].sagyoAmt = changeAmtwithTime(newState[index].sagyoAmt, timeType, timeAct, value, objId);
            updateEditedData.editItems[index].sagyoAmt = newState[index].sagyoAmt;
          } else {
            newState[index].sagyoAmt = changeAmtwithTime(newState[index].sagyoAmt, timeType, timeAct, value, objId);
            updateEditedData.editItems[index].sagyoAmt = newState[index].sagyoAmt;
          }
          /* checkInputWithColor(false, '', index + 'nijikakouSagyoAmt', newState[index].sagyoAmt, 'green'); */
        }
      }
    } else if (objId === 'partCnt' || objId === 'workTimeSize') {
      // 作業時間（(1つ当たりの加工時間	*	個数	*	曲げ時間係数)	*	サイズ係数）
      // 1つ当たりの加工時間
      let sagyoTimePerSec = objId === 'workTimeSize' ? value : newState[index].workTimeSize; //300;
      // 個数
      let partCnt = objId === 'partCnt' ? value : newState[index].partCnt;

      // サイズ係数
      let sizeCoef = 1.0;
      // 曲げ_加工時間（(1つ当たりの加工時間	*	個数	*	曲げ時間係数)	*	サイズ係数）
      let sagyoTimeSec = sagyoTimePerSec * partCnt * sizeCoef;
      // 作業時間（溶接時間＋仮付時間）
      newState[index].sagyoTime = sagyoTimeSec;
      updateEditedData.editItems[index].sagyoTime = sagyoTimeSec;

      // 作業金額
      let mSagyoTimeMin = newState[index].sagyoTime / 60;
      //装置の作業時間チャージ
      // let mSetHRate = deviceMst?.details?.charges[processType].prep;
      let mSagyoHRate = 10000;
      if (document.getElementById(index + 'nijikakouSagyoAmtCu').style.display !== undefined) {
        let showHideCu = document.getElementById(index + 'nijikakouSagyoAmtCu').style.display;
        if (showHideCu === 'none') {
          let sagyoAmt = Math.floor((mSagyoTimeMin * mSagyoHRate) / 60.0); //作業時間＊時間チャージ
          newState[index].sagyoAmt = sagyoAmt;
          updateEditedData.editItems[index].sagyoAmt = sagyoAmt;
        }
      }
    }

    let newSagyoSum = 0;
    let newDandoriSum = 0;
    let newSum = 0;
    let newSubSum = 0;
    let newSagyoTimeSec = 0;
    let newDandoriTimeSec = 0;

    newState?.forEach((element) => {
      newSagyoSum += parseFloat(element.sagyoAmt);
      newSagyoTimeSec += element.sagyoTime;
    });

    newStateUnique?.forEach((element) => {
      newDandoriSum += parseFloat(element.dandoriAmt);
      newDandoriTimeSec += element.dandoriTime;
    });

    /* newSagyoSum += parseFloat(newState[0].handelingAmt);
    newSagyoTimeSec += newState[0].handelingTime); */
    newSum = (parseFloat(newSagyoSum) + parseFloat(newDandoriSum)) /* + parseFloat(editedKensaAmt) */
      .toFixed(0);

    let newSagyoTimeSum = newSagyoTimeSec;
    let newDandoriTimeSum = newDandoriTimeSec;
    let newTotalTimeSum = newSagyoTimeSec + newDandoriTimeSec; /* + editedKensaTime */

    /* checkInputWithColor(false,'',index + 'subTotal', newSubSum, 'green');
    checkInputWithColor(false,'',index + 'subTotalTime', newSubTimeSum, 'green'); */
    checkInputWithColor(false, '', 'totalSagyoAmt', newSagyoSum, 'green');
    checkInputWithColor(false, '', 'totalDandoriAmt', newDandoriSum, 'green');
    checkInputWithColor(false, '', 'totalAmt', newSum, 'green');
    checkInputWithColor(false, '', 'totalSagyoTime', newSagyoTimeSum, 'green');
    checkInputWithColor(false, '', 'totalDandoriTime', newDandoriTimeSum, 'green');
    checkInputWithColor(false, '', 'totalTime', newTotalTimeSum, 'green');
    setTotalSagyoAmt(newSagyoSum);
    setTotalDandoriAmt(newDandoriSum);
    setTotalAmt(newSum);
    setTotalSagyoTime(newSagyoTimeSum);
    setTotalDandoriTime(newDandoriTimeSum);
    setTotalTime(newTotalTimeSum);
    setEditedHandelingAmt(newState[0].handelingAmt);
    setEditedHandelingTime(newState[0].handelingTime);
    setEditedKensaAmt(newState[0].kensaAmt);
    setEditedKensaTime(newState[0].kensaTime);
    setEditedData(newState);
    updateEditedData.totalEditPrice = newSum;
    updateEditedData.totalEditTime = newTotalTimeSum;
    updateEditedData.totalDandori.editItemsAmt = newDandoriSum;
    updateEditedData.totalDandori.editItemsTime = newDandoriTimeSum;
    updateEditedData.totalSagyo.editItemsAmt = newSagyoSum;
    updateEditedData.totalSagyo.editItemsTime = newSagyoTimeSum;
    if (updateEditedData.dataType == DataType.Data) {
      setSecondaryWorkAmt(updateEditedData.totalDataPrice);
    } else {
      setSecondaryWorkAmt(updateEditedData.totalEditPrice);
    }
    props?.updateIQ3KouteiCal(updateEditedData, WorkType.SmSecondaryWork);
  };

  const checkStr = (val) => {
    let intAmt;
    if (val === '') {
      intAmt = 0;
    } else if (typeof val === 'string') {
      intAmt = parseFloat(val.charAt(0) == '¥' ? val.replace('¥', '') : val);
    } else intAmt = val;
    return intAmt;
  };

  const changeAmtwithTime = (amt, typeTime, action, timeVal, processType) => {
    let editedAmt;
    let totalSec = Number(timeVal);
    let intAmt = checkStr(amt);
    let deviceMst = [];

    if (processType === 'dandoriTime') {
      // 段取金額
      //　段取時間＊時間チャージ
      editedAmt = calDandoriAmt(totalSec, deviceMst);
    } else if (processType === 'sagyoTime') {
      // 作業金額
      //　加工時間＊時間チャージ
      editedAmt = calSagyoAmt(totalSec, deviceMst);
    } else if (processType === 'handelingTime') {
      // マテハン金額
      //　マテリアルハンドリング時間＊時間チャージ
      editedAmt = calSagyoAmt(totalSec, deviceMst);
    }
    return editedAmt;
  };

  const handleProvinceChange = (value) => {};

  const checkRegex = (value) => {
    var patt = /^\¥?[0-9]+\.?[0-9]*$/;
    return patt.test(value);
  };

  const disableTimer = (id) => {
    document.getElementById(id).style.color = 'grey';
    document.getElementById(id).style.pointerEvents = 'none';
  };

  return (
    <div className="kouteiCalculateDiv">
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row " onClick={nijikakou}>
        <Col span={4} className="oyabuhin-col">
          <div style={{ display: 'flex' }}>
            {isNijikakoudetail ? (
              <CaretUpOutlined style={{ fontSize: 17, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 17, marginTop: 2 }} />
            )}
            <label style={{ marginLeft: 5 }}>{processMst?.name}</label>
          </div>
        </Col>
        <Col span={6} className="oyabuhin-col">
          <div>{secondaryWorkAmt ? JPYs.format(Math.floor(secondaryWorkAmt)) : amtEmptyStr}</div>
        </Col>
        <Col span={13} style={{ border: 'none' }} className="oyabuhin-col">
          <div>
            <label style={{ marginLeft: 5 }}>{deviceMst?.name}</label>
          </div>
        </Col>
      </Row>
      <div style={{ display: isNijikakoudetail ? 'block' : 'none' }}>
        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col">
            工程詳細
          </Col>
          <Col span={6} className="oyabuhin-detail-col">
            <Checkbox
              key={kouteiCalculateSelectedArr[0].key}
              value={kouteiCalculateSelectedArr[0].value}
              checked={kouteiCalculateSelectedArr[0].key == updateEditedData.dataType}
              disabled={!props?.editMode}
              style={{ marginRight: 6 }}
              onChange={(e) => props?.editMode && handleChangeCheckData(kouteiCalculateSelectedArr[0].key)}
            ></Checkbox>
            <label>{kouteiCalculateSelectedArr[0].value}</label>
          </Col>
          <Col span={8} className="oyabuhin-detail-col">
            <Checkbox
              key={kouteiCalculateSelectedArr[1].key}
              value={kouteiCalculateSelectedArr[1].value}
              disabled={!props?.editMode}
              onChange={(e) => props?.editMode && handleChangeCheckData(kouteiCalculateSelectedArr[1].key)}
              checked={kouteiCalculateSelectedArr[1].key == updateEditedData.dataType}
              style={{ marginRight: 6 }}
            ></Checkbox>
            <label>{kouteiCalculateSelectedArr[1].value}</label>
          </Col>

          <Col span={1} className="oyabuhin-detail-col">
            単位
          </Col>
          <Col span={5} className="formula-column">
            計算式
          </Col>
        </Row>
        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col">
            2次加工
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalAmt">{totalSum ? JPYs.format(Math.floor(totalSum)) : amtEmptyStr}</label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalTime">{totalTimeSum ? secondsToHms(totalTimeSum) : timeHmsStr}</label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalAmtIn" className="resetColor">
              {totalAmt ? JPYs.format(Math.floor(totalAmt)) : amtEmptyStr}
            </label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalTimeIn" className="resetColor">
              {totalTime ? secondsToHms(totalTime) : timeHmsStr}
            </label>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={5} className="formula-column"></Col>
        </Row>
        {/* 段取金額/時間 */}
        <Row
          style={{ color: 'black' }}
          className="oyabuhin-detail oyabuhin-row"
          onClick={(e) => handleCollpse('NijikakouDandori')}
        >
          <Col span={4} className="oyabuhin-detail-col">
            <div style={{ display: 'flex' }}>
              <CaretUpOutlined id={'arrowUpNijikakouDandori'} style={{ fontSize: 17, marginTop: 2, display: 'none' }} />

              <CaretDownOutlined id={'arrowDownNijikakouDandori'} style={{ fontSize: 17, marginTop: 2 }} />
              <label style={{ marginLeft: 5 }}> 段取金額/時間</label>
            </div>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalDandoriAmt">{dandoriAmtSum ? JPYs.format(Math.floor(dandoriAmtSum)) : amtEmptyStr}</label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalDandoriTime">{dandoriTimeSum ? secondsToHms(dandoriTimeSum) : timeHmsStr}</label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalDandoriAmtIn" className="resetColor">
              {totalDandoriAmt ? JPYs.format(Math.floor(totalDandoriAmt)) : amtEmptyStr}
            </label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalDandoriTimeIn" className="resetColor">
              {totalDandoriTime ? secondsToHms(totalDandoriTime) : timeHmsStr}
            </label>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={5} className="formula-column">
            <Popover content={contentDandori}>段取金額の総合計</Popover>
          </Col>
        </Row>
        <div id={'divNijikakouDandori'} style={{ display: 'none' }}>
          {(() => {
            const arr = [];
            const dandoriSameTypeArr = [];
            for (let j = 0; j < processArr?.length; j++) {
              //同じ種類の場合、計算や表示しない。
              let sameType = dandoriSameTypeArr?.filter((item) => item == processArr[j]?.processTypeId);
              if (sameType?.length == 0 || dandoriSameTypeArr?.length == 0) {
                arr.push(
                  <div>
                    {/* ○○_標準 */}
                    <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                      <Col span={4} className="oyabuhin-detail-col">
                        <div style={{ display: 'flex' }}>
                          <span style={{ marginLeft: '20px' }}>{processArr[j]?.processType}</span>
                        </div>
                      </Col>
                      <Col span={3} className="oyabuhin-detail-col">
                        <label id={j + 'NijikakouDandoriAmt'}>
                          {processArr[j]?.dandoriAmt && processArr[j]?.dandoriAmt > 0
                            ? JPYs.format(Math.floor(processArr[j]?.dandoriAmt))
                            : amtEmptyStr}
                        </label>
                      </Col>
                      <Col span={3} className="oyabuhin-detail-col">
                        {
                          <label id={j + 'NijikakouDndoriTime'}>
                            {processArr[j]?.dandoriTime ? secondsToHms(processArr[j]?.dandoriTime) : timeHmsStr}
                          </label>
                        }
                      </Col>

                      <Col span={4} className="input-col">
                        <CurrencyInput
                          id={j + 'NijikakouDandoriAmtIn'}
                          value={editedData[j]?.dandoriAmt ? Math.floor(editedData[j]?.dandoriAmt) : 0}
                          defaultValue={0}
                          style={{
                            border: 'none',
                            color:
                              processArr[j]?.dandoriAmt === editedData[j]?.dandoriAmt
                                ? 'black'
                                : checkShowHideIcon(j + 'NijikakouDandoriAmtCu') === 'none'
                                ? '#4cc12f'
                                : 'red',
                          }}
                          onValueChange={(e) => {
                            //checkRegex(e.target.value);
                            if (editedData[j]?.dandoriAmt != e) {
                              settingNewDandoriAmtInArr(e, j, 'dandoriAmt', '', '');
                              checkInputWithColor(false, '', j + 'NijikakouDandoriAmt', e, 'amt');
                            }
                          }}
                          className={
                            editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'
                          }
                          prefix="¥"
                          decimalsLimit={100}
                        />
                        <div id={j + 'NijikakouDandoriAmtCu'} style={{ display: 'none' }}>
                          <div className="currencyIcon">
                            <BsUsbC style={{ fontSize: '25' }} />
                            <BsX style={{ margin: '-8px 0 0 16px' }} />
                            <span class="tooltiptext">
                              金額に変更がありましたので、以降時間と連携して自動計算されない。
                            </span>
                          </div>
                        </div>
                      </Col>

                      <Col span={4} className="input-col">
                        <Row style={{ width: '100%' }}>
                          <Col className="timer-input-col">
                            <Input
                              value={editedData[j]?.dandoriTime ? secondsToHms(editedData[j]?.dandoriTime) : timeHmsStr}
                              id={j + 'NijikakouDandoriTimeIn'}
                              style={{
                                width: '100%',
                                color:
                                  processArr[j]?.dandoriTime === editedData[j]?.dandoriTime
                                    ? handleShowHide(
                                        parseFloat(editedData[j]?.dandoriAmt),
                                        editedData[j]?.dandoriAmt,
                                        j + 'NijikakouDandoriAmtCu'
                                      )
                                    : 'red',
                              }}
                              className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                              onChange={(e) => {
                                settingNewDandoriAmtInArr(e.target.value, j, 'dandoriTime', 'input', 'input');
                              }}
                            ></Input>
                          </Col>
                          <Col className="timer-button-col">
                            {editMode ? (
                              <>
                                <CaretUpOutlined
                                  style={{ fontSize: 17 + 'px' }}
                                  onClick={() => {
                                    let editedTime = handelIncreaseTime(
                                      editedData[j]?.dandoriTime,
                                      '10min',
                                      j + 'NijikakouDandoriTime'
                                    );
                                    settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', '10min', 'inc');
                                  }}
                                />
                                <CaretDownOutlined
                                  style={{ fontSize: 17 + 'px', color: '#000' }}
                                  onClick={() => {
                                    let editedTime = handelDecreaseTime(
                                      editedData[j]?.dandoriTime,
                                      '10min',
                                      j + 'NijikakouDandoriTime'
                                    );
                                    settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', '10min', 'dec');
                                  }}
                                />
                                <CaretUpOutlined
                                  style={{ fontSize: 17 + 'px', color: '#000' }}
                                  onClick={() => {
                                    let editedTime = handelIncreaseTime(
                                      editedData[j]?.dandoriTime,
                                      'min',
                                      j + 'NijikakouDandoriTime'
                                    );
                                    settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'min', 'inc');
                                  }}
                                />
                                <CaretDownOutlined
                                  style={{ fontSize: 17 + 'px', color: '#000' }}
                                  onClick={() => {
                                    let editedTime = handelDecreaseTime(
                                      editedData[j]?.dandoriTime,
                                      'min',
                                      j + 'NijikakouDandoriTime'
                                    );
                                    settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'min', 'dec');
                                  }}
                                />
                                <CaretUpOutlined
                                  style={{ fontSize: 17 + 'px', color: '#000' }}
                                  onClick={() => {
                                    let editedTime = handelIncreaseTime(
                                      editedData[j]?.dandoriTime,
                                      'sec',
                                      j + 'NijikakouDandoriTime'
                                    );
                                    settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'sec', 'inc');
                                  }}
                                />
                                <CaretDownOutlined
                                  style={{ fontSize: 17 + 'px', color: '#000' }}
                                  onClick={() => {
                                    let editedTime = handelDecreaseTime(
                                      editedData[j]?.dandoriTime,
                                      'sec',
                                      j + 'NijikakouDandoriTime'
                                    );
                                    settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'sec', 'dec');
                                  }}
                                />
                              </>
                            ) : (
                              <></>
                            )}
                          </Col>
                        </Row>
                      </Col>
                      <Col span={1} className="oyabuhin-detail-col"></Col>
                      <Col span={5} className="formula-column"></Col>
                    </Row>
                  </div>
                );
                dandoriSameTypeArr.push(processArr[j]?.processTypeId);
              }
            }
            return arr;
          })()}
        </div>
        {/*  作業金額/時間 */}
        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col" onClick={(e) => handleCollpse('NijikakouSagyoTotal')}>
            <div style={{ display: 'flex' }}>
              <CaretUpOutlined
                id={'arrowUpNijikakouSagyoTotal'}
                style={{ fontSize: 17, marginTop: 2, display: 'none' }}
              />
              <CaretDownOutlined id={'arrowDownNijikakouSagyoTotal'} style={{ fontSize: 17, marginTop: 2 }} />
              <label style={{ marginLeft: 5 }}> 加工金額/時間</label>
            </div>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalSagyoAmt">{sagyoAmtSum ? JPYs.format(Math.floor(sagyoAmtSum)) : amtEmptyStr}</label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalSagyoTime">{sagyoTimeSum ? secondsToHms(sagyoTimeSum) : timeHmsStr}</label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalSagyoAmtIn" className="resetColor">
              {totalSagyoAmt ? JPYs.format(Math.floor(totalSagyoAmt)) : amtEmptyStr}
            </label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalSagyoTimeIn" className="resetColor">
              {totalSagyoTime ? secondsToHms(totalSagyoTime) : timeHmsStr}
            </label>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={5} className="formula-column">
            <Popover content={contentSagyo}>作業金額の総合計</Popover>
          </Col>
        </Row>
        <div id={'divNijikakouSagyoTotal'} style={{ display: 'none' }}>
          {(() => {
            const arr = [];

            for (let i = 0; i < processArr?.length; i++) {
              arr.push(
                <div>
                  <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                    <Col span={4} className="oyabuhin-detail-col" onClick={(e) => subpartDetail(i)}>
                      <div style={{ display: 'flex', marginLeft: '5px' }}>
                        <CaretUpOutlined id={'arrowUpNijikakouIQ3' + i} style={{ fontSize: 17, marginTop: 2 }} />
                        <CaretDownOutlined
                          id={'arrowDownNijikakouIQ3' + i}
                          style={{ fontSize: 17, marginTop: 2, display: 'none' }}
                        />
                        <span style={{ marginLeft: '5px' }}>
                          {processArr[i]?.processType + '(M' + processArr[i]?.diameter + ')'}
                        </span>
                      </div>
                    </Col>
                    <Col span={3} className="oyabuhin-detail-col" onClick={(e) => subpartDetail(i)}>
                      {/* ¥ */}
                      <label id={i + 'nijikakouSagyoAmt'}>
                        {processArr[i]?.sagyoAmt ? JPYs.format(Math.floor(processArr[i]?.sagyoAmt)) : amtEmptyStr}
                      </label>
                    </Col>
                    <Col span={3} className="oyabuhin-detail-col" onClick={(e) => subpartDetail(i)}>
                      <label id={i + 'nijikakouSagyoTime'}>
                        {processArr[i]?.sagyoTime ? secondsToHms(processArr[i]?.sagyoTime) : timeHmsStr}
                      </label>
                    </Col>
                    <Col span={4} className="input-col">
                      <CurrencyInput
                        id={i + 'nijikakouSagyoAmtIn'}
                        value={editedData[i]?.sagyoAmt ? Math.floor(editedData[i]?.sagyoAmt) : 0}
                        defaultValue={0}
                        onValueChange={(e) => {
                          if (editedData[i]?.sagyoAmt != e) {
                            settingNewDandoriAmtInArr(e, i, 'sagyoAmt', '', '');
                            checkInputWithColor(false, '', i + 'nijikakouSagyoAmt', e, 'amt');
                          }
                        }}
                        style={{
                          border: 'none',
                          color:
                            processArr[i]?.sagyoAmt === editedData[i]?.sagyoAmt
                              ? 'black'
                              : checkShowHideIcon(i + 'nijikakouSagyoAmtCu') === 'none'
                              ? '#4cc12f'
                              : 'red',
                        }}
                        className={
                          editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'
                        }
                        prefix="¥"
                        decimalsLimit={100}
                      />

                      <div id={i + 'nijikakouSagyoAmtCu'} style={{ display: 'none' }} className="currencyReset">
                        <div className="currencyIcon">
                          <BsUsbC style={{ fontSize: '25' }} />
                          <BsX style={{ margin: '-8px 0 0 16px' }} />
                          <span class="tooltiptext">
                            金額に変更がありましたので、以降時間と連携して自動計算されない。
                          </span>
                        </div>
                      </div>
                    </Col>
                    <Col span={4} className="input-col">
                      <Row style={{ width: '100%' }}>
                        <Col className="timer-input-col">
                          <Input
                            value={editedData[i]?.sagyoTime ? secondsToHms(editedData[i]?.sagyoTime) : timeHmsStr}
                            id={i + 'nijikakouSagyoTimeIn'}
                            style={{
                              width: '100%',
                              color:
                                processArr[i]?.sagyoTime === editedData[i]?.sagyoTime
                                  ? 'black'
                                  : oldEditedData[i]?.partCnt === editedData[i]?.partCnt &&
                                    oldEditedData[i]?.workTimeSize === editedData[i]?.workTimeSize
                                  ? 'red'
                                  : '#4cc12f',
                            }}
                            className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                            onChange={(e) => {
                              settingNewDandoriAmtInArr(e.target.value, i, 'sagyoTime', 'input', 'input');
                            }}
                          ></Input>
                        </Col>
                        <Col className="timer-button-col">
                          {editMode ? (
                            <>
                              <CaretUpOutlined
                                style={{ fontSize: 17 + 'px' }}
                                onClick={() => {
                                  let editedTime = handelIncreaseTime(
                                    editedData[i]?.sagyoTime,
                                    '10min',
                                    i + 'nijikakouSagyoTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', '10min', 'inc');
                                }}
                              />
                              <CaretDownOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelDecreaseTime(
                                    editedData[i]?.sagyoTime,
                                    '10min',
                                    i + 'nijikakouSagyoTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', '10min', 'dec');
                                }}
                              />
                              <CaretUpOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelIncreaseTime(
                                    editedData[i]?.sagyoTime,
                                    'min',
                                    i + 'nijikakouSagyoTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', 'min', 'inc');
                                }}
                              />
                              <CaretDownOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelDecreaseTime(
                                    editedData[i]?.sagyoTime,
                                    'min',
                                    i + 'nijikakouSagyoTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', 'min', 'dec');
                                }}
                              />
                              <CaretUpOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelIncreaseTime(
                                    editedData[i]?.sagyoTime,
                                    'sec',
                                    i + 'nijikakouSagyoTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', 'sec', 'inc');
                                }}
                              />
                              <CaretDownOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelDecreaseTime(
                                    editedData[i]?.sagyoTime,
                                    'sec',
                                    i + 'nijikakouSagyoTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', 'sec', 'dec');
                                }}
                              />
                            </>
                          ) : (
                            <></>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col span={1} className="oyabuhin-detail-col" onClick={(e) => subpartDetail(i)}></Col>
                    <Col span={5} className="formula-column" onClick={(e) => subpartDetail(i)}></Col>
                  </Row>
                  <div id={'nijikakouProcessIQ3' + i} style={{ display: 'none' }}>
                    <div>
                      {/* 作業金額/時間 */}

                      <div>
                        {/* 加工種類/仮付箇所 */}
                        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                          <Col span={4} className="oyabuhin-detail-col">
                            <span className="ddl_2_noImg">数量/1つ当たりの加工時間</span>
                          </Col>
                          <Col span={3} className="oyabuhin-detail-col">
                            <label id="nijiAmount">{processArr[i]?.partCnt}</label>
                          </Col>
                          <Col span={3} className="oyabuhin-detail-col">
                            <label id={i + 'nijiTimeForOne'}>
                              {processArr[i]?.workTimeSize ? secondsToHms(processArr[i]?.workTimeSize) : timeHmsStr}
                            </label>
                          </Col>

                          <Col span={4} className="input-col">
                            <Input
                              value={editedData[i]?.partCnt}
                              id={i + 'nijiAmountIn'}
                              min={0}
                              className={editMode ? 'input-editable' : 'input-non-editable '}
                              controls={false}
                              style={{
                                width: '45%',
                                border: 'none',
                                color: parseFloat(editedData[i]?.partCnt) === processArr[i]?.partCnt ? 'black' : 'red',
                              }}
                              onKeyPress={handleKeyPress}
                              onChange={(e) => settingNewDandoriAmtInArr(e.target.value, i, 'partCnt', '', '')}
                            />
                          </Col>

                          <Col span={4} className="input-col">
                            <Row style={{ width: '100%' }}>
                              <Col className="timer-input-col">
                                <Input
                                  value={
                                    editedData[i]?.workTimeSize ? secondsToHms(editedData[i]?.workTimeSize) : timeHmsStr
                                  }
                                  id={i + 'nijiTimeForOneIn'}
                                  min={0}
                                  className={editMode ? 'input-editable' : 'input-non-editable '}
                                  controls={false}
                                  style={{
                                    width: '100%',
                                    border: 'none',
                                    color:
                                      editedData[i]?.workTimeSize === processArr[i]?.workTimeSize ? 'black' : 'red',
                                  }}
                                  //onKeyPress={handleKeyPress}
                                  onChange={(e) => settingNewDandoriAmtInArr(e.target.value, i, 'workTimeSize', '', '')}
                                />
                              </Col>
                              <Col className="timer-button-col">
                                {editMode ? (
                                  <>
                                    <CaretUpOutlined
                                      style={{ fontSize: 17 + 'px' }}
                                      onClick={() => {
                                        let editedTime = handelIncreaseTime(
                                          editedData[i]?.workTimeSize,
                                          '10min',
                                          'workTimeSize'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, i, 'workTimeSize', '10min', 'inc');
                                      }}
                                    />
                                    <CaretDownOutlined
                                      style={{ fontSize: 17 + 'px', color: '#000' }}
                                      onClick={() => {
                                        let editedTime = handelDecreaseTime(
                                          editedData[i]?.workTimeSize,
                                          '10min',
                                          'workTimeSize'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, i, 'workTimeSize', '10min', 'dec');
                                      }}
                                    />
                                    <CaretUpOutlined
                                      style={{ fontSize: 17 + 'px', color: '#000' }}
                                      onClick={() => {
                                        let editedTime = handelIncreaseTime(
                                          editedData[i]?.workTimeSize,
                                          'min',
                                          'workTimeSize'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, i, 'workTimeSize', 'min', 'inc');
                                      }}
                                    />
                                    <CaretDownOutlined
                                      style={{ fontSize: 17 + 'px', color: '#000' }}
                                      onClick={() => {
                                        let editedTime = handelDecreaseTime(
                                          editedData[i]?.workTimeSize,
                                          'min',
                                          'workTimeSize'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, i, 'workTimeSize', 'min', 'dec');
                                      }}
                                    />
                                    <CaretUpOutlined
                                      style={{ fontSize: 17 + 'px', color: '#000' }}
                                      onClick={() => {
                                        let editedTime = handelIncreaseTime(
                                          editedData[i]?.workTimeSize,
                                          'sec',
                                          'workTimeSize'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, i, 'workTimeSize', 'sec', 'inc');
                                      }}
                                    />
                                    <CaretDownOutlined
                                      style={{ fontSize: 17 + 'px', color: '#000' }}
                                      onClick={() => {
                                        let editedTime = handelDecreaseTime(
                                          editedData[i]?.workTimeSize,
                                          'sec',
                                          'workTimeSize'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, i, 'workTimeSize', 'sec', 'dec');
                                      }}
                                    />
                                  </>
                                ) : (
                                  <></>
                                )}
                              </Col>
                            </Row>
                          </Col>
                          <Col span={1} className="oyabuhin-detail-col"></Col>
                          <Col span={4} className="formula-column"></Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
            return arr;
          })()}
        </div>
      </div>
    </div>
  );
});

export default IQ3KouteiCalculate_2jikakou;
