/**
 * クラス名：板金の装置設定のガス係数画面
 * 作成者：チュー
 * 作成日：2023/06/28
 * バージョン：1.0
 */

import React, { useEffect, forwardRef, useState, useRef } from 'react';

import { Row, Col, Input, Button, Space, Select, Popover } from 'antd';
import { Table } from 'react-bootstrap';
import { PlusCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { isEmpty } from '@rc-component/mini-decimal/es/numberUtil';

import { commonModal } from '../../../common/CommonModal';
import {
  deleteListColData,
  updateListColData,
  sortListData,
  handleKeyPress,
  updateList,
  sortListByMasterData,
  getInputBorderStyle,
} from '../../../common/Common';

const Device_Blank_GasKeisuu = forwardRef((props, ref) => {
  const [editMode, setEditMode] = useState(false);

  const [isGasType, setIsGasType] = useState(false);

  // 種類
  const [gasTypeTemp, setGasTypeTemp] = useState([]);
  const [gasTypeData, setGasTypeData] = useState([]);
  const [gasKeisuuListData, setGasKeisuuListData] = useState([]);

  const [isItaatsu, setIsItaatsu] = useState(false);

  // 板厚
  const [itaatsuTemp, setItaatsuTemp] = useState([]);
  const [itaatsuData, setItaatsuData] = useState([]);
  const [gasTypeList, setGasTypeList] = useState([]);
  const [materialType, setMaterialType] = useState([]);

  const [filterKeys, setFilterKeys] = useState([]);

  const [errIndexVal, setErrIndexVal] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const inputRef = useRef([]);

  useEffect(() => {
    setErrIndexVal([]);
    setErrorMessage('');

    setEditMode(props.editMode);

    if (props?.detailsData?.gasCoeffThMaster) {
      setGasTypeData(props?.detailsData?.gasCoeffThMaster);
      setGasTypeTemp(props?.detailsData?.gasCoeffThMaster);
    }
    if (props?.detailsData?.gasCoeffListItems) {
      setGasKeisuuListData(props?.detailsData?.gasCoeffListItems);
    }
    if (props?.detailsData?.gasCoeffThickThMaster) {
      setItaatsuData(props?.detailsData?.gasCoeffThickThMaster);
      setItaatsuTemp(props?.detailsData?.gasCoeffThickThMaster);
    }
    if (props?.detailsData?.gasCoeffThItems) {
      setGasTypeList(props?.detailsData?.gasCoeffThItems);
    }
    setMaterialType(props.materialTypes);
    const usedItems = props.materialTypes?.filter((item) => item.info.isUsed);

    // Extract the id values from the filtered items
    const usedIds = usedItems?.map((item) => item.id);
    setFilterKeys(usedIds);
    // setJointList(props.jointItaatsuList);
    // setKakousokudoItaatsuList(props.kakouSokudoItaatsuList);
    // setPiercingProcessList(props.piercingProcessList);
    // setPunchProcessItaatsuListData(props.punchProcessItaatsuListData);
    // setListData(props.blankKakousokudolistData);
  }, [
    props.detailsData,
    // props.processName,
    props.editMode,
    props.materialTypes,
    // props.gasTypeData,
    // props.gasKeisuuList,
    // props.itaatsuData,
    // props.kakouSokudoItaatsuList,
    // props.gasTypeList,
    // props.jointItaatsuList,
    // props.piercingProcessList,
    // props.punchProcessItaatsuListData,
    // props.blankKakousokudolistData,
  ]);

  const addGasTypeModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>種類</p>
    </div>
  );

  const addData = () => {};

  const closeAddModal = () => {
    if (isGasType === true) {
      setIsGasType(false);
    } else if (isItaatsu === true) {
      setIsItaatsu(false);
    }
  };
  const addGasType = (id) => {
    const copyData = gasTypeTemp.slice(); // Create a copy of the original array

    const insertIndex = gasTypeTemp?.findIndex((item) => item.no === id); // Find the index of the item with id 2

    if (insertIndex !== -1) {
      const data = {
        no: gasTypeTemp?.length + 1,
        name: '',
        isOutOfRange: false,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setGasTypeTemp(copyData);
    }
  };
  const deleteGasType = (id, e) => {
    if (gasTypeTemp?.filter((item) => item.isOutOfRange === false)?.length > 1) {
      const updatedData = gasTypeTemp?.map((row) => {
        if (row.no === id) {
          return { ...row, isOutOfRange: true };
        }
        return row;
      });
      setGasTypeTemp(updatedData);
    }
  };

  const handleGasTypeChange = (event, id, field) => {
    const updatedData = gasTypeTemp?.map((row) => {
      if (row.no === id) {
        return { ...row, [field]: event.target.value };
      }
      return row;
    });
    setGasTypeTemp(updatedData);
  };

  const cancelMasterData = () => {
    if (isGasType === true) {
      setGasTypeTemp(gasTypeData);
      setIsGasType(false);
    } else {
      setErrIndexVal([]);
      setErrorMessage('');
      setItaatsuTemp(itaatsuData);
      setIsItaatsu(false);
    }
  };
  const addGasTypeMaster = () => {
    setIsGasType(false);

    const deletedId = [];

    gasTypeData?.forEach((item1, index) => {
      const found = gasTypeTemp?.find((item2) => item2.no === item1.no && item2.isOutOfRange === true);
      if (found) {
        deletedId.push(item1.no);
      }
    });

    const newId = gasTypeTemp
      ?.filter((item) => !item.isOutOfRange && !gasTypeData.some((data2Item) => data2Item.no === item.no))
      ?.map((item) => item.no);

    const deletedData = deleteListColData(gasKeisuuListData, deletedId);

    const updatedData = updateListColData(deletedData, newId);

    const sortedList = sortListData(updatedData, gasTypeTemp);

    setGasTypeData(gasTypeTemp);

    setGasKeisuuListData(sortedList);
    props.updatedGasType(gasTypeTemp);
    props.updateGasKeisuuList(sortedList);
  };

  const addContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table>
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>値</label>
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {gasTypeTemp
                  ?.filter((subitem) => subitem.isOutOfRange === false)
                  ?.map((item, index) => (
                    <tr key={index} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        <Input
                          value={item.name}
                          onChange={(e) => handleGasTypeChange(e, item.no, 'name')}
                          style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addGasType(item.no)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteGasType(item.no, e)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button className="mainButton" id="update" onClick={addGasTypeMaster}>
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} id="discard" onClick={cancelMasterData}>
          キャンセル
        </Button>
      </Row>
    </>
  );

  const gasTypeModal = () => {
    setIsGasType(true);
  };
  const gasKeisuuListDataDataChange = (event, no) => {
    // const copyData = [...gasKeisuuListData];
    // const updatedData = JSON.parse(JSON.stringify(copyData));
    // updatedData[rowIndex].lstData[colIndex].value = event.target.value;

    // setGasKeisuuListData(updatedData);

    // props.updateGasKeisuuList(updatedData);
    gasKeisuuListData
      ?.filter((item) => item.no === no)
      ?.map((list) => {
        list.value = event.target.value;
      });
    setGasKeisuuListData(gasKeisuuListData);
    props.updateGasKeisuuList(gasKeisuuListData);
  };

  const addItaatsuModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>板厚</p>
    </div>
  );

  const addItaatsu = (id, index) => {
    inputRef.current[index].focus();
    const copyData = itaatsuTemp.slice(); // Create a copy of the original array

    const insertIndex = itaatsuTemp?.findIndex((item) => item.no === id); // Find the index of the item with id 2

    if (insertIndex !== -1) {
      const data = {
        no: itaatsuTemp?.length + 1,
        value: 0,
        isOutOfRange: false,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setItaatsuTemp(copyData);
    }
  };
  const deleteItaatsu = (id, e, index) => {
    // 更新するエラーインデックスを得る
    const updatedErrorIndexes = errIndexVal?.filter((item) => item !== index);

    const finalErrorIndexes = updatedErrorIndexes?.map((item) => (item > index ? item - 1 : item));
    setErrIndexVal(finalErrorIndexes);

    if (itaatsuTemp?.filter((item) => item.isOutOfRange === false)?.length > 2) {
      const updatedData = itaatsuTemp?.map((row) => {
        if (row.no === id) {
          return { ...row, isOutOfRange: true };
        }
        return row;
      });
      setItaatsuTemp(updatedData);
      const filterData = updatedData?.filter((i) => !i.isOutOfRange);
      if (index + 1 !== filterData?.length) {
        inputRef.current[index].focus();
      }
    }
  };
  // 板厚マスタデータ更新
  const handleItaatsuChange = (event, id, field) => {
    const updatedData = itaatsuTemp?.map((row) => {
      if (row.no === id) {
        return { ...row, [field]: event.target.value };
      }
      return row;
    });
    setItaatsuTemp(updatedData);
  };

  const updateListData = (deletedData, newId) => {
    const list = [
      { key: 1, name: '丸穴径', svalue: '', mvalue: '', lvalue: '' },
      { key: 2, name: '加工周長', svalue: '', mvalue: '', lvalue: '' },
      { key: 3, name: '速度', svalue: '', mvalue: '', lvalue: '' },
    ];
    const updatedData = deletedData?.map((item) => {
      newId?.forEach((key) => {
        // Add new objects to lstData
        item.lstData.push({
          key: key,
          value: '',
          list: list,
        });
      });

      return item;
    });
    return updatedData;
  };
  // 板厚マスタデータ追加
  const addItaatsuMaster = () => {
    setIsItaatsu(false);

    const deletedId = [];

    itaatsuData?.forEach((item1, index) => {
      const found = itaatsuTemp?.find((item2) => item2.no === item1.no && item2.isOutOfRange === true);
      if (found) {
        deletedId.push(item1.no);
      }
    });

    const newId = itaatsuTemp
      ?.filter((item) => !item.isOutOfRange && !itaatsuData.some((data2Item) => data2Item.no === item.no))
      ?.map((item) => item.no);

    const updatedList = updateList(gasTypeList, deletedId, newId);
    const sortedList = sortListByMasterData(updatedList, itaatsuTemp);

    setItaatsuData(itaatsuTemp);
    props.updateGasCoeffThickThMaster(itaatsuTemp);

    setGasTypeList(sortedList);
    props.updateGasCoeffThItems(sortedList);
  };
  // 板厚マスタデータの入力チェック
  const isValueOrderCorrect = (no) => {
    const temp = itaatsuTemp?.filter((i) => i.isOutOfRange === false);

    const insertIndex = temp?.findIndex((item) => item.no === no);

    if (insertIndex > 0) {
      const prevValue = parseFloat(temp[insertIndex - 1].value);
      const currentValue = parseFloat(temp[insertIndex].value);
      const nextValue = parseFloat(temp[insertIndex + 1].value);
      if (currentValue > prevValue && currentValue < nextValue) {
        let temp = [insertIndex - 1, insertIndex, insertIndex + 1];

        setErrIndexVal(errIndexVal?.filter((item) => item !== insertIndex && item != insertIndex - 1));

        setErrorMessage('前のしきい値より大きい値を入力してください！');
      } else {
        setErrIndexVal([...errIndexVal, insertIndex]);

        if (currentValue <= prevValue) {
          setErrorMessage('前のしきい値より大きい値を入力してください！');
        } else if (currentValue >= nextValue) {
          setErrorMessage('次のしきい値より小さい値を入力してください！');
        }
      }
    } else {
      const currentValue = parseFloat(temp[insertIndex].value);
      const nextValue = parseFloat(temp[insertIndex + 1].value);

      if (currentValue < nextValue) {
        setErrIndexVal(errIndexVal?.filter((item) => item !== insertIndex));
      } else {
        setErrIndexVal([...errIndexVal, insertIndex]);
        setErrorMessage('次のしきい値より小さい値を入力してください！');
      }
    }
  };
  const addItaatsuContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>しきい値</label>（≦）
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {itaatsuTemp
                  ?.filter((subitem) => subitem.isOutOfRange === false)
                  ?.map((item, index) => (
                    <tr key={index} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        {errIndexVal?.includes(index) ? (
                          <Popover
                            content={<div style={{ padding: 7 }}>{errorMessage}</div>}
                            placement="left"
                            visible={true}
                          ></Popover>
                        ) : (
                          ''
                        )}
                        <Input
                          value={item.value}
                          onChange={(e) => handleItaatsuChange(e, item.no, 'value')}
                          onBlur={() => isValueOrderCorrect(item.no)}
                          className={errIndexVal?.includes(index) ? 'red-border' : ''}
                          style={{
                            ...getInputBorderStyle(index, itaatsuTemp),
                            pointerEvents: editMode ? 'auto' : 'none',
                          }}
                          ref={(ref) => (inputRef.current[index] = ref)}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addItaatsu(item.no, index + 1)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteItaatsu(item.no, e, index)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button
          className={isEmpty(errIndexVal) ? 'mainButton' : 'disabledButton'}
          id="update"
          onClick={addItaatsuMaster}
        >
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} id="discard" onClick={cancelMasterData}>
          キャンセル
        </Button>
      </Row>
    </>
  );

  const itaatsuModal = () => {
    setIsItaatsu(true);
  };
  const gasTypeListDataChange = (event, key, no) => {
    const newList = { ...gasTypeList };
    const itemIndex = newList[key]?.findIndex((item) => item.no === no);

    if (itemIndex !== -1) {
      newList[key][itemIndex].value = event;
      setGasTypeList(newList);
      props.updateGasCoeffThItems(newList);
    }
  };

  const checkValue = (val) => {
    const selectedDataObject = gasTypeData?.filter((i) => i.isOutOfRange === false)?.find((item) => item.no === val);
    if (selectedDataObject) {
      return selectedDataObject.no;
    } else {
      return 1;
    }
  };
  return (
    <>
      <div style={{ marginLeft: 10, marginRight: 10 }}>
        <Row className="table-container">
          <Table
            style={{
              width:
                gasTypeData?.filter((i) => i.isOutOfRange === false)?.length > 8
                  ? '100%'
                  : gasTypeData?.filter((i) => i.isOutOfRange === false)?.length * 98 + 196 + 'px',
            }}
          >
            <tr style={{ color: 'white', textAlign: 'center', height: 40 }}>
              <td style={{ width: '196px' }} className="tbl-header fixedCol">
                <Row justify="center" className="tbl-header">
                  <Space>
                    <label>係数</label>
                    <label>/</label>
                    {editMode ? (
                      <>
                        <Button className="cancleButton" onClick={gasTypeModal} style={{ pointerEvents: 'auto' }}>
                          種類
                        </Button>
                      </>
                    ) : (
                      <>
                        <label>種類</label>
                      </>
                    )}
                  </Space>
                </Row>
              </td>

              {gasTypeData
                ?.filter((subitem) => subitem.isOutOfRange === false)
                ?.map((item, index) => (
                  <td key={index} style={{ backgroundColor: '#1063aa', width: '98px' }}>
                    {item.name}
                  </td>
                ))}
            </tr>

            <tr style={{ textAlign: 'center' }}>
              <td style={{ width: '196px' }} className="fixedOneRowCol">
                ガス係数
              </td>
              {gasKeisuuListData?.map((row, index) => (
                <td key={index} style={{ width: '98px' }}>
                  <Input
                    value={row.value}
                    onChange={(e) => gasKeisuuListDataDataChange(e, row.no)}
                    onKeyPress={(e) => {
                      handleKeyPress(e, false);
                    }}
                    style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                    className={editMode ? 'input-editable ' : 'input-non-editable'}
                  />
                </td>
              ))}
            </tr>
          </Table>
        </Row>
        <Row className="mt-2 table-container">
          <Table
            style={{
              width:
                itaatsuData?.filter((i) => i.isOutOfRange === false)?.length > 8
                  ? '100%'
                  : itaatsuData?.filter((i) => i.isOutOfRange === false)?.length * 98 + 196 + 'px',
            }}
          >
            <tr style={{ color: 'white', textAlign: 'center', height: 40 }}>
              <td style={{ width: '196px' }} className="tbl-header fixedCol">
                <Row justify="center" className="tbl-header">
                  <Space>
                    <label>材質区分</label>
                    <label>/</label>
                    {editMode ? (
                      <>
                        <Button className="cancleButton" onClick={itaatsuModal} style={{ pointerEvents: 'auto' }}>
                          板厚
                        </Button>
                      </>
                    ) : (
                      <>
                        <label>板厚</label>
                      </>
                    )}
                  </Space>
                </Row>
              </td>

              {itaatsuData
                ?.filter((subitem) => subitem.isOutOfRange === false)
                ?.map((item, index) => (
                  <td key={index} style={{ backgroundColor: '#1063aa', width: '98px' }}>
                    ～{item.value}t
                  </td>
                ))}
            </tr>

            {Object.keys(gasTypeList)
              ?.filter((listkey) => filterKeys?.includes(parseInt(listkey)))
              ?.map((key) => (
                <tr key={key} style={{ textAlign: 'center' }}>
                  <td style={{ color: 'white', width: '196px' }} className="tbl-header fixedCol">
                    {materialType?.find((item) => item.id.toString() === key)?.name}
                  </td>
                  {gasTypeList[key]?.map((item, index) => (
                    <td key={index} style={{ verticalAlign: 'middle', textAlign: 'center', width: 210 }}>
                      <Select
                        placeholder="選択"
                        value={checkValue(item.value)}
                        style={{ fontSize: 11, width: '95%', pointerEvents: editMode ? 'auto' : 'none' }}
                        onChange={(e) => gasTypeListDataChange(e, key, item.no)}
                      >
                        {gasTypeData
                          ?.filter((subitem) => subitem.isOutOfRange === false)
                          ?.map((item, index) => (
                            <Select.Option key={index} value={item.no}>
                              {item.name}
                            </Select.Option>
                          ))}
                      </Select>
                    </td>
                  ))}
                </tr>
              ))}
          </Table>
        </Row>
      </div>

      {/* 種類 */}
      {isGasType
        ? commonModal(
            isGasType,
            addGasTypeModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addContent,
            null,
            null,
            true
          )
        : ''}
      {/* 板厚 */}
      {isItaatsu
        ? commonModal(
            isItaatsu,
            addItaatsuModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addItaatsuContent,
            null,
            null,
            true
          )
        : ''}
    </>
  );
});

export default Device_Blank_GasKeisuu;
