/**
 * クラス名：ユーザー設定画面
 * 作成者：チュー
 * 作成日：2023/07/21
 * バージョン：1.0
 */
import { Input, Layout, Menu, Row, Col, Select, Button, Typography, Checkbox } from 'antd';
import React, { Component, useState, useRef, useEffect } from 'react';
import dayjs from 'dayjs';

// import moment from 'moment-timezone';
import CompanySetting from './CompanySetting';
import { getStaffInfo } from '../common/CommonAPI';
import { getAccessToken, updateAccessToken } from '../common/Common';
import { ErrorMessage } from '../common/Message';
import {
  authorizeError,
  error,
  confirmModalTitle,
  commonModal,
  commonFooter,
  confirmUpdateContent,
  complete,
} from '../common/CommonModal';

import { StatusCodes } from 'http-status-codes';
import { initShowDisps, languages, UserRoleToString } from '../common/enums';
const { Sider, Content } = Layout;
const contentStyle = {
  backgroundColor: 'white',
  fontSize: '13px',
};

const siderStyle = {
  color: '#fff',
  backgroundColor: '#e5e5e5',
  width: '350px',
};
const userSetting = ['ユーザー情報', '言語とタイムゾーン', 'メッセージ表示'];
const adminSetting = ['言語とタイムゾーン', /*  '環境', '初期名称', */ '出力フォーマット', '帳票' /* , 'CSV出力' */];

const timeZone = [
  { id: 1, value: '会社の設定を使用：(GMT+0:900) Asia/Tokyo', label: '会社の設定を使用：(GMT+0:900) Asia/Tokyo' },
];
const displayFlag = [
  { id: 1, value: true, label: '表示する' },
  { id: 2, value: false, label: '表示しない' },
];

const UserSetting = () => {
  const [selectedItem, setSelectedItem] = useState('0');
  const [selectedAdminItem, setSelectedAdminItem] = useState();
  const [userRole, setUserRole] = useState('user');
  /*const [userTimeZone, setUserTimeZone] = useState('会社の設定を使用：(GMT+0:900) Asia/Tokyo'); */
  const [personInChargeData, setPersonInChargeData] = useState([]);
  const [userSettingObj, setUserSettingObj] = useState([]);
  const [userSettingBefore, setUserSettingBefore] = useState([]);
  const [isExist, setIsExist] = useState(false);
  const [saveConfirm, setSaveConfirm] = useState(false);
  const [manager, setManager] = useState('');
  const [screen, setScreen] = useState('');
  const [userLanguage, setUserLanguage] = useState();
  const [updateMessageShowHide, setUpdateMessageShowHide] = useState();
  const [discardMessageShowHide, setDiscardMessageShowHide] = useState();
  const [pageTransitionConfirm, setPageTransitionConfirm] = useState();
  const [childDeleteConfirm, setChildDeleteConfirm] = useState();
  const [mitsumoriDeleteConfirm, setMitsumoriDeleteConfirm] = useState();

  // const timeZones = moment.tz.names();
  const [userId, setUserId] = useState('');
  const [userType, setUserType] = useState('');

  async function getPersonInChargeData() {
    let personInChargeData = [];
    personInChargeData = await getStaffInfo();
    let personInChargeFilterData = personInChargeData?.filter((item) => item.info.isUsed === true);
    return personInChargeFilterData;
  }

  const currentDateTime = dayjs().format('YYYY-MM-DD HH:mm:ss.SSSSSZ');

  useEffect(() => {
    async function fetchData() {
      var userInfo =
        window.localStorage.getItem('iQxWeb_LoginUser') != undefined
          ? JSON.parse(decodeURI(window.localStorage.getItem('iQxWeb_LoginUser')))
          : [];

      setUserId(userInfo.mail);
      const data = await getPersonInChargeData();
      setPersonInChargeData(data);
      setManager(data[0]?.id);
      setScreen(initShowDisps[0]);
      setUserLanguage(1);
      setUpdateMessageShowHide(true);
      setDiscardMessageShowHide(true);
      setPageTransitionConfirm(true);
      setChildDeleteConfirm(true);
      setMitsumoriDeleteConfirm(true);
      setUserType(UserRoleToString[userInfo.role]);
      var existedData = await getExistUserSetting();

      if (existedData?.length > 0 && existedData != []) {
        setUserSettingObj(existedData[0]);
        setUserSettingBefore(existedData[0]);
        setIsExist(true);
      } else {
        setUserSettingObj({
          created: currentDateTime,
          modified: currentDateTime,
          users: { nickName: data[0]?.name, staffId: data[0]?.id, initShowDisp: 0 },
          languages: { language: 0 },
          massages: { editOk: true, editCancel: true, moveInEdit: true, deleteEst: true, deletePart: true },
          info: { isUsed: true, sortNum: 0, remarks: '' },
        });
        setUserSettingBefore({
          created: currentDateTime,
          modified: currentDateTime,
          users: { nickName: data[0]?.name, staffId: data[0]?.id, initShowDisp: 0 },
          languages: { language: 0 },
          massages: { editOk: true, editCancel: true, moveInEdit: true, deleteEst: true, deletePart: true },
          info: { isUsed: true, sortNum: 0, remarks: '' },
        });
        setIsExist(false);
      }
    }
    fetchData();
  }, [selectedItem]);

  const getExistUserSetting = async () => {
    let existUserSetting = [];
    try {
      const Url = process.env.REACT_APP_BACKEND_API_URL + 'UserSetting';
      const otherParam = {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + getAccessToken(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          return await response.json();
        })
        .then((data) => {
          if (data && data?.updateToken) {
            // セクションにトークン情報を更新する
            updateAccessToken(data?.updateToken);
          }
          if (data && data.statusCode === StatusCodes.OK) {
            existUserSetting = data.data;
          } else if (
            data &&
            (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)
          ) {
            authorizeError(ErrorMessage().E006);
            return;
          } else if (data && data.statusCode !== StatusCodes.OK) {
            error(ErrorMessage()[data?.messageCode]);
            return;
          }
        })
        .catch((e) => {
          authorizeError(ErrorMessage().E010);
          return;
        });
    } catch (e) {
      error(e.message);
      return;
    }
    return existUserSetting;
  };

  async function addUserSetting(userSetting) {
    let ret = [];
    try {
      const Url = process.env.REACT_APP_BACKEND_API_URL + 'UserSetting';
      const otherParam = {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + getAccessToken(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
        body: JSON.stringify({
          userSettingType: selectedItem,
          Info: userSetting.users,
          Langs: userSetting.languages,
          Msgs: userSetting.massages,
          SettingInfo: userSetting.info,
        }),
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          return await response.json();
        })
        .then((data) => {
          if (data && data?.updateToken) {
            // セクションにトークン情報を更新する
            updateAccessToken(data?.updateToken);
          }
          if (data && data.statusCode === StatusCodes.OK) {
            ret = data.data[0];
            complete('データ保存は正常に完了しました。');
            setUserSettingBefore(JSON.parse(JSON.stringify(userSettingObj)));
          } else if (
            data &&
            (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)
          ) {
            authorizeError(ErrorMessage()[data?.messageCode]);
            return;
          } else if (data && data.statusCode !== StatusCodes.OK) {
            error(ErrorMessage()[data?.messageCode]);
            return;
          }
        })
        .catch((e) => {
          authorizeError(ErrorMessage().E010);
          return;
        });
    } catch (e) {
      error(e.message);
      return;
    }
    return ret;
  }

  async function updateUserSetting(userSetting) {
    let ret = [];
    try {
      const Url = process.env.REACT_APP_BACKEND_API_URL + 'UserSetting';
      const otherParam = {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + getAccessToken(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
        body: JSON.stringify({
          userSettingType: selectedItem,
          Info: userSetting.users,
          Langs: userSetting.languages,
          Msgs: userSetting.massages,
          SettingInfo: userSetting.info,
        }),
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          return await response.json();
        })
        .then((data) => {
          if (data && data?.updateToken) {
            // セクションにトークン情報を更新する
            updateAccessToken(data?.updateToken);
          }
          if (data && data.statusCode === StatusCodes.OK) {
            ret = data.data[0];
            complete('データ保存は正常に完了しました。');
            setUserSettingBefore(JSON.parse(JSON.stringify(userSettingObj)));
          } else if (
            data &&
            (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)
          ) {
            authorizeError(ErrorMessage()[data?.messageCode]);
            return;
          } else if (data && data.statusCode !== StatusCodes.OK) {
            error(ErrorMessage()[data?.messageCode]);
            return;
          }
        })
        .catch((e) => {
          authorizeError(ErrorMessage().E010);
          return;
        });
    } catch (e) {
      error(e.message);
      return;
    }
    return ret;
  }

  const updConfirmOk = async (e) => {
    /* let userSetting;
    switch (selectedItem) {
      case '0':
        userSetting = {
          users: userSettingObj.users,
          languages: {},
          massages: {},
          info: {},
        };
        break;
      case '1':
        userSetting = {
          users: {},
          languages: userSettingObj.languages,
          massages: {},
          info: {},
        };
        break;
      case '2':
        userSetting = {
          users: {},
          languages: {},
          massages: userSettingObj.massages,
          info: {},
        };
        break;
      default:
      // Default code when selectedItem doesn't match any case
    } */

    if (isExist) {
      await updateUserSetting(userSettingObj);
    } else {
      await addUserSetting(userSettingObj);
      setIsExist(true);
    }
    setSaveConfirm(false);
  };

  const updConfirmCancel = () => {
    setSaveConfirm(false);
  };

  const userInformationContent = (
    <>
      <Row className="mt-4">
        <label style={{ fontWeight: 800, fontSize: '13.5px' }}>ユーザー情報</label>
      </Row>
      <div style={{ marginLeft: '1.5rem' }} className="settingContent">
        <Row className="mt-2">
          <label>ユーザーID</label>
        </Row>
        <Row>
          <Col span={8}>
            <Input value={userId} disabled></Input>
          </Col>
        </Row>
        <Row className="mt-4">
          <label>担当者</label>
        </Row>
        <Row>
          <Col span={8}>
            <Select
              style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
              value={/* manager */ userSettingObj?.users?.staffId !== 0 ? userSettingObj?.users?.staffId : manager}
              onChange={(e) => {
                setUserSettingObj((prevData) => ({
                  ...prevData,
                  users: {
                    ...prevData?.users,
                    staffId: e,
                    nickName: personInChargeData?.find((item) => item.id === e)?.name || '',
                  },
                }));
                setManager(e);
              }}
            >
              {personInChargeData !== undefined &&
                personInChargeData?.map((item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
            </Select>
          </Col>
        </Row>
        <Row className="mt-4">
          <label>権限</label>
        </Row>
        <Row>
          <Col span={8}>
            <Input value={userType} disabled></Input>
          </Col>
        </Row>
        <Row className="mt-4">
          <label>ログイン後の表示画面</label>
        </Row>
        <Row>
          <Col span={8}>
            <Select
              style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
              value={initShowDisps[userSettingObj?.users?.initShowDisp]}
              onChange={(e) => {
                setUserSettingObj((prevData) => ({
                  ...prevData,
                  users: { ...prevData?.users, initShowDisp: e },
                }));
                setScreen(e);
              }}
            >
              {/* {screens?.map((item) => (
                <Select.Option value={item.id}>{item.label}</Select.Option>
              ))} */}
              {Object.entries(initShowDisps)?.map(([key, value]) => (
                <Select.Option key={key} value={key}>
                  {value}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row style={{ marginTop: '4rem' }}>
          <Col span={8} style={{ textAlign: 'right' }}>
            <Button
              className="mainButton"
              onClick={() => {
                setSaveConfirm(userSettingObj?.massages?.editOk);
                if (!userSettingBefore.massages.editOk) {
                  updConfirmOk();
                }
              }}
            >
              保存
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );

  const userLanguageSetting = (
    <>
      <Row className="mt-4">
        <label style={{ fontWeight: 800, fontSize: '13.5px' }}>言語とタイムゾーンの設定</label>
      </Row>
      <div style={{ marginLeft: '1.5rem' }} className="settingContent">
        <Row className="mt-2">
          <label>言語</label>
        </Row>
        <Row>
          <Col span={8}>
            <Select
              style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
              value={userSettingObj?.languages?.language}
              onChange={(e) => {
                setUserSettingObj((prevData) => ({
                  ...prevData,
                  languages: {
                    ...prevData?.languages,
                    language: e,
                  },
                }));
                setUserLanguage(e);
              }}
            >
              {Object.entries(languages)?.map(([key, value]) => (
                <Select.Option key={value} value={value}>
                  {key}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        {/*  <Row className="mt-4">
          <label>タイムゾーン</label>
        </Row>
        <Row>
          <Col span={8}>
            <Select
              style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
              value={userTimeZone}
              onChange={(e) => setUserTimeZone(e)}
            >
              {timeZone?.map((item) => (
                <Select.Option value={item.value}>{item.label}</Select.Option>
              ))}
              {timeZones?.map((item, index) => (
              <Select.Option value={index}>{item}</Select.Option>
            ))}
            </Select>
          </Col>
        </Row> */}
        <Row style={{ marginTop: '4rem' }}>
          <Col span={8} style={{ textAlign: 'right' }}>
            <Button
              className="mainButton"
              onClick={() => {
                setSaveConfirm(userSettingBefore.massages.editOk);
                if (!userSettingBefore.massages.editOk) {
                  updConfirmOk();
                }
              }}
            >
              保存
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );

  const displaySetting = (
    <>
      <Row className="mt-4">
        <label style={{ fontWeight: 800, fontSize: '13.5px' }}>メッセージ表示の設定</label>
      </Row>
      <div style={{ marginLeft: '1.5rem' }} className="settingContent">
        <Row className="mt-2">
          <label>編集更新確認メッセージ</label>
        </Row>
        <Row>
          <Col span={8}>
            <Select
              style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
              value={userSettingObj?.massages?.editOk}
              onChange={(e) => {
                setUserSettingObj((prevData) => ({
                  ...prevData,
                  massages: {
                    ...prevData?.massages,
                    editOk: e,
                  },
                }));
                setUpdateMessageShowHide(e);
              }}
            >
              {displayFlag?.map((item, index) => (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
            <Typography.Text type="secondary" style={{ fontSize: '11px', marginLeft: '5px' }}>
              ※表示しないを選択した場合、更新ボタンを押すとぞのまま更新されます。
            </Typography.Text>
          </Col>
        </Row>
        <Row className="mt-4">
          <label>編集破棄確認メッセージ</label>
        </Row>
        <Row>
          <Col span={8}>
            <Select
              style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
              value={userSettingObj?.massages?.editCancel}
              onChange={(e) => {
                setUserSettingObj((prevData) => ({
                  ...prevData,
                  massages: {
                    ...prevData?.massages,
                    editCancel: e,
                  },
                }));
                setDiscardMessageShowHide(e);
              }}
            >
              {displayFlag?.map((item, index) => (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
            <Typography.Text type="secondary" style={{ fontSize: '11px', marginLeft: '5px' }}>
              ※表示しないを選択した場合、破棄ボタンを押すとぞのまま破棄されます。
            </Typography.Text>
          </Col>
        </Row>
        <Row className="mt-4">
          <label>編集モード時の画面遷移確認メッセージ</label>
        </Row>
        <Row>
          <Col span={8}>
            <Select
              style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
              value={userSettingObj?.massages?.moveInEdit}
              onChange={(e) => {
                setUserSettingObj((prevData) => ({
                  ...prevData,
                  massages: {
                    ...prevData?.massages,
                    moveInEdit: e,
                  },
                }));
                setPageTransitionConfirm(e);
              }}
            >
              {displayFlag?.map((item, index) => (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={24}>
            <Typography.Text type="secondary" style={{ fontSize: '11px', marginLeft: '5px' }}>
              ※表示しないを選択した場合、画面遷移の際に編集データは自動的に破棄されます。
            </Typography.Text>
          </Col>
        </Row>
        <Row className="mt-4">
          <label>見積データ削除確認メッセージ</label>
        </Row>
        <Row>
          <Col span={8}>
            <Select
              style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
              value={userSettingObj?.massages?.deleteEst}
              onChange={(e) => {
                setUserSettingObj((prevData) => ({
                  ...prevData,
                  massages: {
                    ...prevData?.massages,
                    deleteEst: e,
                  },
                }));
                setMitsumoriDeleteConfirm(e);
              }}
            >
              {displayFlag?.map((item, index) => (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row className="mt-4">
          <label>子部品データ削除確認メッセージ</label>
        </Row>
        <Row>
          <Col span={8}>
            <Select
              style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
              value={userSettingObj?.massages?.deletePart}
              onChange={(e) => {
                setUserSettingObj((prevData) => ({
                  ...prevData,
                  massages: {
                    ...prevData?.massages,
                    deletePart: e,
                  },
                }));
                setChildDeleteConfirm(e);
              }}
            >
              {displayFlag?.map((item, index) => (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>

        <Row style={{ marginTop: '4rem' }}>
          <Col span={8} style={{ textAlign: 'right' }}>
            <Button
              className="mainButton"
              onClick={() => {
                setSaveConfirm(userSettingBefore.massages.editOk);
                if (!userSettingBefore.massages.editOk) {
                  updConfirmOk();
                }
                //saveInfo();
              }}
            >
              保存
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );

  const handleMenuClick = (e, role) => {
    if (role === 'user') {
      setUserRole('user');
      setSelectedItem(e.key);
      setSelectedAdminItem();
    } else if (role === 'admin') {
      setUserRole('admin');
      setSelectedAdminItem(e.key);
      setSelectedItem();
    }
  };

  return (
    <>
      <Layout className="setting">
        <Sider style={siderStyle}>
          <label style={{ marginLeft: 25, fontWeight: '400', color: 'black', marginTop: '1.5rem' }}>ユーザー設定</label>
          <Menu
            mode="vertical"
            selectedKeys={[selectedItem]}
            // defaultSelectedKeys={[selectedItem]}
            onClick={(e) => handleMenuClick(e, 'user')}
            style={{ backgroundColor: '#e5e5e5', marginLeft: 25, fontSize: '13px' }}
          >
            {userSetting?.map((item, index) => (
              <Menu.Item key={index} style={{ textAlign: 'left', width: '240px' }}>
                {item}
              </Menu.Item>
            ))}
          </Menu>

          <label style={{ marginLeft: 25, fontWeight: '400', color: 'black', marginTop: 20 }}>会社(管理者)設定</label>
          <Menu
            mode="vertical"
            selectedKeys={[selectedAdminItem]}
            style={{ backgroundColor: '#e5e5e5', marginLeft: 25, fontSize: '13px' }}
          >
            {adminSetting?.map((item, index) => (
              <Menu.Item
                key={index}
                style={{ textAlign: 'left', width: '240px' }}
                onClick={(e) => handleMenuClick(e, 'admin')}
              >
                {item}
              </Menu.Item>
            ))}
          </Menu>
        </Sider>
        <Layout>
          <Content style={contentStyle}>
            <div style={{ marginLeft: 25 }}>
              {userRole === 'user' ? (
                <>
                  {selectedItem === '0'
                    ? userInformationContent
                    : selectedItem === '1'
                    ? userLanguageSetting
                    : displaySetting}
                </>
              ) : (
                <>
                  <CompanySetting selectedItem={selectedAdminItem} />
                </>
              )}

              {/* {userInformationContent} */}
            </div>
          </Content>
        </Layout>
      </Layout>

      {/** 新規追加破棄確認ポップアップ */}
      {saveConfirm &&
        commonModal(
          saveConfirm,
          confirmModalTitle,
          commonFooter(updConfirmOk, updConfirmCancel),
          null,
          400,
          updConfirmOk,
          updConfirmCancel,
          confirmUpdateContent,
          null,
          null,
          false
        )}
    </>
  );
};

export default UserSetting;
