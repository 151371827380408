/**
 * クラス名：材料サイズ詳細画面
 * 説明：iQ3板金に使用の材料サイズ詳細画面です。
 * 作成者：テッ
 * 作成日：2023/06/01
 * バージョン：1.0
 */

import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { Button, Radio, Image, Input } from 'antd';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table } from 'react-bootstrap';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import checkedImg from '../../../../assets/images/checked.png';
import {
  confirmDiscardContent,
  confirmUpdateContent,
  confirmModalTitle,
  commonModal,
  commonFooter,
  error,
} from '../../../common/CommonModal';
import { formValidatorMode, formatDate, isDecimal } from '../../../common/Common';
import { ErrorMessage } from '../../../common/Message';

dayjs.extend(customParseFormat);
const RadioGroup = Radio.Group;
const { TextArea } = Input;

const MaterialSizeDetail = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    discardConfirmOk: discardConfirmOk,
    discardConfirmCancel: discardConfirmCancel,
    formValidation: formValidation,
  }));

  const editMode = props.editMode;

  const [selectedRowData, setSelectedRowData] = useState([]);
  const [originData, setOriginData] = useState([]);

  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [updateConfirm, setUpdateConfirm] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [updDateFlg, setUpdDateFlg] = useState(false);

  const [isNoData, setIsNoData] = useState(false);

  const inputItemRef = {};

  useEffect(() => {
    if (props.selectedData && Object.keys(props.selectedData)?.length > 0) {
      let selectedData = JSON.parse(JSON.stringify({ ...props.selectedData }));
      setOriginData({ ...props.selectedData });
      setSelectedRowData(selectedData);
      /* setUpdDate(selectedData.modified); */
      setIsNoData(false);
    } else {
      setIsNoData(true);
      setSelectedRowData([]);
    }
  }, [props.selectedData]);

  useEffect(() => {
    typeof selectedRowData === 'object' ? setOriginData({ ...selectedRowData }) : setOriginData([...selectedRowData]);
    if (props.editMode) {
      document.getElementById('edit').style.display = 'none';
      document.getElementById('update').style.display = 'block';
      document.getElementById('discard').style.display = 'block';
    } else {
      document.getElementById('edit').style.display = 'block';
      document.getElementById('update').style.display = 'none';
      document.getElementById('discard').style.display = 'none';
    }
  }, [props.editMode]);

  const editDetailInput = (objKey, value) => {
    const isValueChanged = String(originData[objKey]) !== String(value);
    setUpdDateFlg(isValueChanged);
    setSelectedRowData((prevData) => {
      var updatedData = { ...prevData, info: { ...prevData.info } };
      Object.keys(updatedData?.info)?.filter((v) => v === objKey)?.length > 0
        ? (updatedData.info[objKey] = value)
        : (updatedData[objKey] = value);
      return updatedData;
    });
  };

  const editData = () => {
    props.updateEditMode(true);
    document.getElementById('update').style.display = 'block';
    document.getElementById('discard').style.display = 'block';
    document.getElementById('edit').style.display = 'none';
  };

  const updateData = () => {
    /* setUpdateConfirm(true);
    setIsUpdate(true); */
    setUpdateConfirm(props.showEdtConfirmOK);
    if (!props.showEdtConfirmOK) {
      updConfirmOk();
    }
    setIsUpdate(true);
  };

  const discardData = () => {
    /*  setCancelConfirm(true); */
    setCancelConfirm(props.showEdtConfirmCancle);
    if (!props.showEdtConfirmCancle) {
      discardConfirmOk();
    }
  };

  // 画面の入力チェック
  const formValidation = (inputItems, procMode) => {
    let ret = true;
    let message = [];
    // 名称
    let input = inputItems['name']?.input;
    input?.classList?.remove('input-error');
    if (input?.value?.length <= 0 && procMode !== formValidatorMode.BulkEdit) {
      // 必須
      message.push(<li>{ErrorMessage('名称').E007}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    // サイズX
    input = inputItems['sizeX']?.input;
    input?.classList?.remove('input-error');
    if (input?.value?.length <= 0 && procMode !== formValidatorMode.BulkEdit) {
      // 必須
      message.push(<li>{ErrorMessage('サイズX').E007}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    if (input?.value?.length > 0 && !isDecimal(input?.value)) {
      message.push(<li>{ErrorMessage('サイズX').E014}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    // サイズY
    input = inputItems['sizeY']?.input;
    input?.classList?.remove('input-error');
    if (input?.value?.length <= 0 && procMode !== formValidatorMode.BulkEdit) {
      // 必須
      message.push(<li>{ErrorMessage('サイズY').E007}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    if (input?.value?.length > 0 && !isDecimal(input?.value)) {
      message.push(<li>{ErrorMessage('サイズY').E014}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    if (message?.length > 0) error(<ul>{message}</ul>);

    return ret;
  };

  const updConfirmOk = (e) => {
    if (isUpdate) {
      setUpdateConfirm(false);
      // 画面の入力チェック
      if (!formValidation(inputItemRef, formValidatorMode.Edit)) {
        return;
      }
      props.updateEditMode(false);
      document.getElementById('update').style.display = 'none';
      document.getElementById('discard').style.display = 'none';
      document.getElementById('edit').style.display = 'block';
      let updateData = { ...selectedRowData };

      if (updDateFlg) {
        updateData.modified = dayjs(dayjs()).format('YYYY-MM-DD');
      }
      setSelectedRowData(updateData);
      props.submitData(updateData);
    }
  };

  const updConfirmCancel = () => {
    setUpdateConfirm(false);
  };

  const discardConfirmOk = () => {
    props.updateEditMode(false);
    document.getElementById('update').style.display = 'none';
    document.getElementById('discard').style.display = 'none';
    document.getElementById('edit').style.display = 'block';
    setSelectedRowData({ ...props.selectedData });
    setCancelConfirm(false);
  };

  const discardConfirmCancel = () => {
    setCancelConfirm(false);
  };

  return (
    <>
      <div className="d-flex" style={{ marginTop: 9, marginRight: 10 }}>
        <div>
          <Button
            className="mainButton"
            style={{ marginLeft: 10, display: 'none', fontSize: '13.5px' }}
            id="update"
            onClick={updateData}
          >
            更新
          </Button>
        </div>
        <div>
          <Button
            className="cancelButton"
            style={{ marginLeft: 10, display: 'none', fontSize: '13.5px' }}
            id="discard"
            onClick={discardData}
          >
            破棄
          </Button>
        </div>
        <div className="ms-auto">
          <Button className={isNoData ? 'disabledButton' : 'mainButton'} id="edit" onClick={editData}>
            編集
          </Button>
        </div>
      </div>

      <div className="overflow-auto materialClass" style={{ height: 760, marginTop: 10 }}>
        <div id="showData">
          <Table id="param_detail_tbl" className="propertiesTbl">
            <thead>
              {/* <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">No</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <label className="param_detail-title-label">{props.itemNo}</label>
                </td>
              </tr> */}
              {/* 名称 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">名称</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <Input
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    name="name"
                    id="name"
                    value={selectedRowData?.name}
                    type="text"
                    ref={(ref) => {
                      inputItemRef['name'] = ref;
                    }}
                    maxLength={50}
                    onChange={(e) => editDetailInput('name', e.target.value)}
                  />
                </td>
              </tr>
              {/* サイズX */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">サイズX</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <Input
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    name="sizeX"
                    id="sizeX"
                    value={selectedRowData?.info?.sizeX}
                    type="text"
                    ref={(ref) => {
                      inputItemRef['sizeX'] = ref;
                    }}
                    maxLength={Number.MAX_SAFE_INTEGER}
                    onChange={(e) => editDetailInput('sizeX', e.target.value)}
                  />
                </td>
              </tr>
              {/* サイズY */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">サイズY</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <Input
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    name="sizeY"
                    id="sizeY"
                    value={selectedRowData?.info?.sizeY}
                    type="text"
                    ref={(ref) => {
                      inputItemRef['sizeY'] = ref;
                    }}
                    maxLength={Number.MAX_SAFE_INTEGER}
                    onChange={(e) => editDetailInput('sizeY', e.target.value)}
                  />
                </td>
              </tr>
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">作成日</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <label style={{ fontSize: '13px', paddingLeft: 10 }}>
                    {selectedRowData?.created ? formatDate(selectedRowData?.created) : ''}
                  </label>
                </td>
              </tr>

              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">更新日</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <label style={{ fontSize: '13px', paddingLeft: 10 }}>
                    {selectedRowData?.modified ? formatDate(selectedRowData?.modified) : ''}
                  </label>
                </td>
              </tr>
              {/* 更新者 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">更新者</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <label className="param_detail-title-label">{selectedRowData?.modifier}</label>
                </td>
              </tr>
              {/* 備考 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">備考</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  <TextArea
                    type="text"
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    data-name="name"
                    name="remarks"
                    id="remarks"
                    style={{ margin: 5, padding: 5, width: '98.3%' }}
                    value={selectedRowData?.info?.remarks}
                    onChange={(e) => editDetailInput('remarks', e.target.value)}
                    maxLength={2048}
                  />
                </td>
              </tr>
              {/* 表示 */}
              <tr className="detail-tbl-row">
                <td className="param-detail-tbl-val-title">
                  <label className="param_detail-title-label">表示</label>
                </td>
                <td style={{ verticalAlign: 'middle' }}>
                  {editMode ? (
                    <RadioGroup
                      name="isUsed"
                      //defaultValue={selectedRowData.isUsed}
                      className="radioCustomerGpEdit"
                      onChange={(e) => {
                        editDetailInput('isUsed', e.target.value);
                      }}
                      value={selectedRowData?.info?.isUsed}
                    >
                      <Radio value={true}>オン</Radio>
                      <Radio value={false}>オフ</Radio>
                    </RadioGroup>
                  ) : (
                    selectedRowData?.info?.isUsed && (
                      <Image preview={false} width={13} src={checkedImg} id="imgOutsourcingFg" />
                    )
                  )}
                </td>
              </tr>
            </thead>
          </Table>
          <div>
            {/* 更新確認_Modal */}
            {updateConfirm
              ? commonModal(
                  updateConfirm,
                  confirmModalTitle,
                  commonFooter(updConfirmOk, updConfirmCancel),
                  null,
                  400,
                  updConfirmOk,
                  updConfirmCancel,
                  confirmUpdateContent,
                  null,
                  null,
                  false
                )
              : ''}
            {/* 破棄確認_Modal */}
            {cancelConfirm
              ? commonModal(
                  cancelConfirm,
                  confirmModalTitle,
                  commonFooter(discardConfirmOk, discardConfirmCancel),
                  null,
                  400,
                  discardConfirmOk,
                  discardConfirmCancel,
                  confirmDiscardContent,
                  null,
                  null,
                  false
                )
              : ''}
          </div>
        </div>
      </div>
    </>
  );
});

export default MaterialSizeDetail;
