/**
 * クラス名：材料名称一覧画面
 * 説明：iQ3板金に使用の材料名称一覧ファイルです。
 * 作成者：テッ
 * 作成日：2023/06/01
 * バージョン：1.0
 */

import { Table, Form, Button, Row, Input, Col, Modal, Select, Space, Image, Radio, InputNumber } from 'antd';
import { Table as RTable } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input-field';
import { CSVLink, CSVDownload } from 'react-csv';
import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { CloseOutlined, SyncOutlined } from '@ant-design/icons';
import SplitPane, { Pane } from 'split-pane-react';
import 'split-pane-react/esm/themes/default.css';
import { StatusCodes } from 'http-status-codes';

import '../../../../assets/styles/common.css';
import checked from '../../../../assets/images/checked.png';
import {
  confirmHakiContent,
  confirmModalTitle,
  commonModal,
  commonFooter,
  confirmDiscardContent,
  modalTitle,
  confirmDeleteContent,
  confirmCreateContent,
  commonActionFooter,
  confirmUpdateContent,
  authorizeError,
  error,
  commonDeleteFooter,
} from '../../../common/CommonModal';
import {
  JPYs,
  getZaishittsuNameDDL,
  dataStateList,
  actionArr,
  getMaterialNameStorage,
  ResizableTitle,
  formatDate,
  getMaterialTypeIQ3List,
  defaultValue,
  getAccessToken,
  updateAccessToken,
  formValidatorMode,
  handleKeyPress,
} from '../../../common/Common.js';
import MaterialNameDetail from './MaterialNameDetail';
import { getMaterialIQ3Info } from '../../../common/CommonAPI';
import { ErrorMessage } from '../../../common/Message';

dayjs.extend(customParseFormat);

const plusMinus = [
  {
    label: '+',
    key: '1',
  },
  {
    label: '-',
    key: '2',
  },
];

const RadioGroup = Radio.Group;
const { TextArea } = Input;

const MaterialNameList = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    setSplitPaneSize: setSplitPaneSize,
    showAddNewModal: showAddNewModal,
    showXMLInputModal: showXMLInputModal,
    showGroupEditModal: showGroupEditModal,
    showDeleteModal: showDeleteModal,
    showCSVInputModal: showCSVInputModal,
    getEditMode: editMode,
    discardChange: actionArr,
    setDiscardChangeMode: setDiscardOKAction,
    updateEditMode: setEditMode,
    setEditModeCancelConfirm: setEditModeCancelConfirm,
    discardConfirmOk: discardConfirmOk,
  }));

  const detailRef = useRef();
  const navigate = useNavigate();

  const [paramSelected, setParamSelected] = useState('材料名称＊（名称毎比重、材料粗利係数）');
  const [paramType, setParamType] = useState('iq3');
  const [materialNameData, setMaterialNameData] = useState([]);
  const [tempMaterialClass, setTempMaterialClass] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [materialTypeIQ3List, setMaterialTypeIQ3List] = useState([]);

  const [editMode, setEditMode] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [checkedRowKeys, setCheckedRowKeys] = useState([]);
  const [changeRecordKey, setChangeRecordKey] = useState();
  const [dataState, setDataState] = useState();
  const [nameKeyword, setNameKeyWord] = useState('');
  const [modelKeyword, setModelKeyWord] = useState('');
  const [hasCheckData, setHasCheckData] = useState(true);
  const [showFlag, setShowFlag] = useState(1);
  const [filterKey, setFilterKey] = useState();
  const [isFilter, setIsFilter] = useState(true);
  const [chkData, setChkData] = useState(0);
  const [visibleGroupEditModal, setVisibleGroupEditModal] = useState(false);
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [visibleCSVInput, setVisibleCSVInput] = useState(false);
  const [visibleCSVOutput, setVisibleCSVOutput] = useState(false);
  const [visibleXMLInput, setVisibleXMLInput] = useState(false);
  const [visibleAddNewModal, setVisibleAddNewModal] = useState(false);
  const [childTitle, setChildTitle] = useState('');

  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [updateConfirm, setUpdateConfirm] = useState(false);
  const [editModeCancelConfirm, setEditModeCancelConfirm] = useState(false);
  const [discardOKAction, setDiscardOKAction] = useState(actionArr[0]);
  const [unitPrice, setUnitPrice] = useState(10);
  const [updateCancelConfirm, setUpdateCancelConfirm] = useState(false);
  const [plusMinusAct, setPlusMinusAct] = useState('+');
  const [isAdd, setIsAdd] = useState(false);
  const [isGpUpd, setIsGpUpd] = useState(false);
  const [tmpFilteredData, setTmpFilteredData] = useState();
  const [zairyoName, setZairyoName] = useState();
  const [materialTypeIq3IdState, setMaterialTypeIq3IdState] = useState();
  const [thickness, setThickness] = useState();

  // Content Resize
  const [sizes, setSizes] = useState(['60%']);

  //　テーブル列Resizable用変数
  // 一覧用変数
  const [detailMaterialNameWidth, setDetailMaterialNameWidth] = useState(150);
  const [materialNameWidth, setMaterialNameWidth] = useState(150);
  const [pricePerWeightWidth, setPricePerWeightWidth] = useState(100);
  const [remarkWidth, setRemarkWidth] = useState(200);
  // 一括編集用変数
  const [detailMaterialNameWidthEdit, setDetailMaterialNameWidthEdit] = useState(150);
  const [materialNameWidthEdit, setMaterialNameWidthEdit] = useState(150);
  const [pricePerWeightWidthEdit, setPricePerWeightWidthEdit] = useState(100);
  const [remarkWidthEdit, setRemarkWidthEdit] = useState(200);
  // 一括編集選択行
  const [chkrowscount, setChkRowsCount] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemNo, setItemNo] = useState(1);
  const [itemNoTmp, setItemNoTmp] = useState(0);

  const [fromThickness, setFromThickness] = useState('');
  const [toThickness, setToThickness] = useState('');

  const [unitPriceKey, setUnitPriceKey] = useState('');

  const [showEdtConfirmOK, setShowEdtConfirmOK] = useState();
  const [showEdtConfirmCancle, setShowEdtConfirmCancle] = useState();

  const inputItemRef = {};

  const releaseDate = '2023-10-01';

  useEffect(() => {
    getMaterialIQ3Data();
  }, []);

  useEffect(() => {
    if (props.showConfirmMsgs !== undefined) {
      setShowEdtConfirmOK(props.showConfirmMsgs?.editOk);
      setShowEdtConfirmCancle(props.showConfirmMsgs?.editCancel);
    } else {
      setShowEdtConfirmOK(true);
      setShowEdtConfirmCancle(true);
    }
  }, [props.showConfirmMsgs]);

  // 板金子部品材料マスタ情報を取得する
  const getMaterialIQ3Data = async () => {
    let materialNameData = await getMaterialIQ3Info();
    setMaterialNameData(materialNameData);
    setFilteredData(materialNameData);
    setTempMaterialClass(materialNameData?.[0]);
    setSelectedRowKeys(materialNameData?.[0]?.id);
    setSelectedData(materialNameData?.[0]);
    props.setParam(paramType, paramSelected);
    setShowFlag(1);
    setIsFilter(true);
    let materialTypeIQ3List = await getMaterialTypeIQ3List();
    setMaterialTypeIQ3List(materialTypeIQ3List);
  };

  const columns = [
    {
      key: 'No',
      title: 'No',
      dataIndex: 'No',
      width: 50,
      sorter: (a, b) => a.no - b.no,
      sortOrder: 'ascend',
      render: (id, record, index) => {
        return (currentPage - 1) * 25 + (index + 1);
      },
      className: 'cm-a-right',
    },
    {
      key: 'code',
      title: 'コード',
      dataIndex: 'code',
      width: 120,
    },
    {
      key: 'name',
      title: '材料名',
      dataIndex: 'name',
      width: visibleGroupEditModal ? detailMaterialNameWidthEdit : detailMaterialNameWidth,
      onHeaderCell: (column) => ({
        width: column.width,
        onResize: (e, { size }) => {
          if (visibleGroupEditModal) {
            setDetailMaterialNameWidthEdit(size.width);
          } else {
            setDetailMaterialNameWidth(size.width);
          }
        },
      }),
    },
    {
      key: 'materialName',
      title: '材質名称',
      dataIndex: 'materialTypeIq3Id',
      width: visibleGroupEditModal ? materialNameWidthEdit : materialNameWidth,
      onHeaderCell: (column) => ({
        width: column.width,
        onResize: (e, { size }) => {
          if (visibleGroupEditModal) {
            setMaterialNameWidthEdit(size.width);
          } else {
            setMaterialNameWidth(size.width);
          }
        },
      }),
      render: (value) => getMaterilTypeIQ3Name(value),
    },
    {
      key: 'thick',
      title: '板厚',
      dataIndex: 'info',
      width: 80,
      className: 'cm-a-right',
      render: (info) => info.thick,
    },
    {
      key: 'gravity',
      title: '比重',
      dataIndex: 'info',
      width: 80,
      className: 'cm-a-right',
      render: (info) => info.gravity,
    },
    {
      key: 'unitPrice',
      title: '重量単価',
      dataIndex: 'info',
      className: 'cm-a-right',
      render: (text, record) => {
        return JPYs.format(record.info.unitPrice);
      },
      width: visibleGroupEditModal ? pricePerWeightWidthEdit : pricePerWeightWidth,
      onHeaderCell: (column) => ({
        width: column.width,
        onResize: (e, { size }) => {
          if (visibleGroupEditModal) {
            setPricePerWeightWidthEdit(size.width);
          } else {
            setPricePerWeightWidth(size.width);
          }
        },
      }),
    },
    {
      key: 'created',
      title: '作成日',
      dataIndex: 'created',
      width: 100,
      render: (created) => formatDate(created),
    },
    {
      key: 'modified',
      title: '更新日',
      dataIndex: 'modified',
      width: 100,
      render: (modified) => formatDate(modified),
    },
    {
      key: 'modifier',
      title: '更新者',
      dataIndex: 'modifier',
      width: 150,
    },
    {
      key: 'remarks',
      title: '備考',
      dataIndex: 'info',
      width: visibleGroupEditModal ? remarkWidthEdit : remarkWidth,
      onHeaderCell: (column) => ({
        width: column.width,
        onResize: (e, { size }) => {
          if (visibleGroupEditModal) {
            setRemarkWidthEdit(size.width);
          } else {
            setRemarkWidth(size.width);
          }
        },
      }),
      render: (info) => info.remarks,
    },
    {
      key: 'isUsed',
      title: '表示',
      dataIndex: 'info',
      width: 95,
      render: (text, record) => {
        return record.info.isUsed && <Image preview={false} width={13} src={checked} style={{ marginLeft: '3px' }} />;
      },
      className: 'cm-a-center',
    },
  ];

  // テーブル幅Resizable
  const components = {
    header: {
      cell: ResizableTitle,
    },
  };

  const tableColumns = columns?.map((item) => ({
    ...item,
  }));

  // 板金子部品材質名をIDで取得する
  const getMaterilTypeIQ3Name = (id) => {
    return materialTypeIQ3List?.filter((item) => item.value === id)?.[0]?.label;
  };

  // 入力ボックスは金額タイプの場合、他の項目CSSを参照にして利用
  const getAntInputBoxClassName = (id, mode = true) => {
    let classList = document.getElementById(id)?.classList;
    let className = '';
    if (classList?.length > 0)
      className =
        ' ' +
        Object.values(classList)?.filter(
          (v) => v !== 'ant-input' && v !== 'input-editable' && v !== 'input-non-editable'
        );
    if (mode) {
      return 'ant-input input-editable' + className;
    } else {
      return 'input-non-editable';
    }
  };

  const addModalContent = (
    <div id="addNewMaterialName" style={{ marginTop: 8 }}>
      <RTable id="param_detail_tbl" className="propertiesTbl">
        <thead>
          {/* <tr>
            <td className="param-detail-tbl-val-title">No</td>
            <td style={{ verticalAlign: 'middle', height: 28, padding: 3 }}>
              <label> {tempMaterialClass.key + 1} </label>
            </td>
          </tr> */}
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">コード</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <Input
                type="text"
                data-name="code"
                className={'input-editable'}
                value={tempMaterialClass?.code}
                id="code"
                name="code"
                onChange={(e) => {
                  setTempMaterialClass({ ...tempMaterialClass, code: e.target.value });
                }}
                ref={(ref) => {
                  inputItemRef['code'] = ref;
                }}
                maxLength={50}
              />
            </td>
          </tr>

          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">材質名称</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <Select
                className={'search-select'}
                style={{ width: '98.2%' }}
                onChange={(e) => {
                  /* const data = { ...tempMaterialClass };
                  //data['materialTypeIq3Id'] = e;
                  if (tempMaterialClass.info.thick !== '' && zairyoName === '') {
                    // data['detailMaterialName'] = e + '_' + data.info.thick;
                    setZairyoName(e + '_' + data.info.thick);
                  } */
                  setTempMaterialClass((prevData) => ({
                    ...prevData,
                    materialTypeIq3Id: e,
                    /* info: {
                      ...prevData.info,
                      gravity: materialTypeIQ3List?.filter((item) => item.value === e)?.[0]?.gravity,
                    }, */
                  }));
                  setMaterialTypeIq3IdState(e);
                }}
                id="materialTypeIq3Id"
                name="materialTypeIq3Id"
                defaultValue=""
              >
                <Select.Option id="defaultValue" value="">
                  {defaultValue.materialTypeIq3}
                </Select.Option>
                {materialTypeIQ3List?.map((item, index) => (
                  <Select.Option key={index} id={'materialClassName' + item.value} value={item.value}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </td>
          </tr>
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">板厚</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <Input
                id="thick"
                name="thick"
                type="text"
                data-name="name"
                className={'input-editable'}
                value={tempMaterialClass?.info?.thick}
                onChange={(e) => {
                  setTempMaterialClass((prevData) => ({
                    ...prevData,
                    info: { ...prevData?.info, thick: e.target.value },
                  }));
                  setThickness(e.target.value);
                }}
                ref={(ref) => {
                  inputItemRef['thick'] = ref;
                }}
                maxLength={Number.MAX_SAFE_INTEGER}
              />
            </td>
          </tr>
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">材料名</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <Input
                type="text"
                data-name="name"
                className={'input-editable'}
                value={tempMaterialClass?.name === '' ? zairyoName : tempMaterialClass?.name}
                id="name"
                name="name"
                onChange={(e) => {
                  setZairyoName(e.target.value);
                  setTempMaterialClass({
                    ...tempMaterialClass,
                    name: e.target.value,
                  });
                }}
                ref={(ref) => {
                  inputItemRef['name'] = ref;
                }}
                maxLength={50}
              />
            </td>
          </tr>
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">比重</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <Input
                id="gravity"
                name="gravity"
                type="text"
                data-name="name"
                className={'input-editable'}
                value={tempMaterialClass?.info?.gravity}
                onChange={(e) => {
                  setTempMaterialClass((prevData) => ({
                    ...prevData,
                    info: { ...prevData.info, gravity: e.target.value },
                  }));
                }}
                ref={(ref) => {
                  inputItemRef['gravity'] = ref;
                }}
                maxLength={Number.MAX_SAFE_INTEGER}
              />
            </td>
          </tr>
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">重量単価</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <CurrencyInput
                id="unitPrice"
                name="unitPrice"
                type="text"
                data-name="name"
                className={getAntInputBoxClassName('name')}
                value={tempMaterialClass?.info?.unitPrice}
                defaultValue={0}
                onValueChange={(e) => {
                  setTempMaterialClass((prevData) => ({
                    ...prevData,
                    info: { ...prevData.info, unitPrice: e },
                  }));
                }}
                prefix="¥"
                decimalsLimit={100}
                ref={(ref) => {
                  inputItemRef['unitPrice'] = ref;
                }}
                maxLength={Number.MAX_SAFE_INTEGER}
              />
            </td>
          </tr>
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">作成日</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <label className="param_detail-title-label">
                {tempMaterialClass?.created ? formatDate(tempMaterialClass?.created) : ''}
              </label>
            </td>
          </tr>
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">更新日</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <label className="param_detail-title-label">
                {tempMaterialClass?.modified ? formatDate(tempMaterialClass?.modified) : ''}
              </label>
            </td>
          </tr>
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">備考</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <TextArea
                type="text"
                data-name="name"
                className={'input-editable'}
                style={{ margin: 5, padding: 5, width: '98.3%' }}
                value={tempMaterialClass?.info?.remarks}
                onChange={(e) => {
                  setTempMaterialClass((prevData) => ({
                    ...prevData,
                    info: { ...prevData.info, remarks: e.target.value },
                  }));
                }}
                maxLength={2048}
              />
            </td>
          </tr>
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">表示</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <RadioGroup
                name="isUsed"
                defaultValue={true}
                className="radioCustomerGpEdit"
                onChange={(e) => {
                  setTempMaterialClass((prevData) => ({
                    ...prevData,
                    info: { ...prevData.info, isUsed: e.target.value },
                  }));
                }}
                value={tempMaterialClass?.info?.isUsed}
              >
                <Radio value={true}>オン</Radio>
                <Radio value={false}>オフ</Radio>
              </RadioGroup>
            </td>
          </tr>
        </thead>
      </RTable>
    </div>
  );

  const onSelectedRowKeysChange = (checked, record, index) => {
    let selectedRowKeys = [...checked];
    setCheckedRowKeys(selectedRowKeys);
    setChkRowsCount(selectedRowKeys?.length);
  };

  const rowSelection = {
    onChange: onSelectedRowKeysChange,
  };

  const groupEditOK = (e) => {
    setIsAdd(true);
    setUpdateCancelConfirm(true);
  };

  const groupEditCancel = (e) => {
    setIsAdd(false);
    setUpdateCancelConfirm(true);
  };

  const gpEditModalContent = (
    <Form>
      <Row>
        <Col span="13">
          <p style={{ fontSize: '13.5px', marginBottom: 6, marginTop: 0, fontWeight: '700' }}>
            {chkrowscount}件を選択中
          </p>
          <Table
            showSorterTooltip={false}
            components={components}
            dataSource={filteredData}
            columns={tableColumns}
            scroll={{ y: 480, x: '50vw' }}
            pagination={{
              position: ['bottomLeft'],
              total: (record) => record?.length,
              showTotal: (total, range) => `全${total}件中${range[0]}件〜${range[1]}件を表示`,
              defaultPageSize: 25,
              defaultCurrent: 1,
              pageSize: 25,
              current: currentPage,
              onChange: (page) => {
                setCurrentPage(page);
              },
            }}
            className="groupEditTable"
            rowSelection={rowSelection}
            rowKey={(record) => record.id}
          />
        </Col>
        <Col span="11">
          <div
            style={{
              height: 749,
              marginTop: 28,
              fontSize: '13.5px',
              overflowY: 'auto',
              whiteSpace: 'nowrap',
            }}
          >
            <RTable id="param_detail_tbl" className="propertiesTbl">
              <thead>
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">材質名称</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <Select
                      className={'search-select'}
                      style={{ width: '50%' }}
                      onChange={(e) => {
                        setTempMaterialClass({ ...tempMaterialClass, materialTypeIq3Id: e });
                      }}
                      id="materialTypeIq3Id"
                      name="materialTypeIq3Id"
                      defaultValue=""
                    >
                      <Select.Option id="defaultValue" value="">
                        {defaultValue.materialTypeIq3}
                      </Select.Option>
                      {materialTypeIQ3List?.map((item, index) => (
                        <Select.Option key={index} id={'materialRef' + item.value} value={item.value}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </td>
                </tr>
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">板厚</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <Input
                      id="thick"
                      name="thick"
                      type="text"
                      data-name="thick"
                      className={'input-editable'}
                      value={tempMaterialClass?.info?.thick}
                      onChange={(e) => {
                        setTempMaterialClass((prevData) => ({
                          ...prevData,
                          info: { ...prevData.info, thick: e.target.value },
                        }));
                      }}
                      ref={(ref) => {
                        inputItemRef['thick'] = ref;
                      }}
                      maxLength={Number.MAX_SAFE_INTEGER}
                    />
                  </td>
                </tr>
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">重量単価</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <CurrencyInput
                      type="text"
                      data-name="name"
                      className={getAntInputBoxClassName('remarks')}
                      value={tempMaterialClass?.info?.unitPrice}
                      onValueChange={(e) => {
                        setTempMaterialClass((prevData) => ({
                          ...prevData,
                          info: { ...prevData.info, unitPrice: e },
                        }));
                      }}
                      prefix="¥"
                      decimalsLimit={100}
                      ref={(ref) => {
                        inputItemRef['unitPrice'] = ref;
                      }}
                      maxLength={Number.MAX_SAFE_INTEGER}
                    />
                  </td>
                </tr>
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">備考</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <TextArea
                      type="text"
                      data-name="name"
                      className={'input-editable'}
                      style={{ margin: 5, padding: 5, width: '98.3%' }}
                      value={tempMaterialClass?.info?.remarks}
                      onChange={(e) => {
                        setTempMaterialClass((prevData) => ({
                          ...prevData,
                          info: { ...prevData.info, remarks: e.target.value },
                        }));
                      }}
                      maxLength={2048}
                    />
                  </td>
                </tr>
                <tr className="detail-tbl-row">
                  <td className="param-detail-tbl-val-title">
                    <label className="param_detail-title-label">表示</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <RadioGroup
                      name="isUsed"
                      defaultValue={true}
                      className="radioCustomerGpEdit"
                      onChange={(e) => {
                        setTempMaterialClass((prevData) => ({
                          ...prevData,
                          info: { ...prevData.info, isUsed: e.target.value },
                        }));
                      }}
                      value={tempMaterialClass?.info?.isUsed}
                    >
                      <Radio value={true}>オン</Radio>
                      <Radio value={false}>オフ</Radio>
                    </RadioGroup>
                  </td>
                </tr>
              </thead>
            </RTable>
          </div>
        </Col>
      </Row>
      <Row
        justify="end"
        style={{
          marginBottom: 0,
          marginTop: -40,
        }}
      >
        <Button style={{ marginRight: 5 }} className="mainButton" onClick={groupEditOK}>
          更新
        </Button>
        <Button className="cancelButton" onClick={groupEditCancel}>
          破棄
        </Button>
      </Row>
    </Form>
  );

  const onRowSelect = (record, index) => {
    setChangeRecordKey(record.id);
    //setItemNo((currentPage - 1) * 25 + (index + 1));
    if (editMode) {
      setDiscardOKAction(actionArr[0]);
      setItemNoTmp((currentPage - 1) * 25 + (index + 1));
      let moveInEdit = props.showConfirmMsgs.moveInEdit == undefined ? true : props.showConfirmMsgs.moveInEdit;
      setEditModeCancelConfirm(moveInEdit);
      if (!moveInEdit) {
        setEditMode(false);
        document.getElementById('update').style.display = 'none';
        document.getElementById('discard').style.display = 'none';
        document.getElementById('edit').style.display = 'block';
        setSelectedRowKeys(record.id);
        setSelectedData(record);
        setItemNo((currentPage - 1) * 25 + (index + 1));
        navigate(':iq3.materialName');
      }
    } else {
      setSelectedRowKeys(record.id);
      setSelectedData(record);
      setItemNo((currentPage - 1) * 25 + (index + 1));
      navigate(':iq3.materialName');
    }
  };

  const handleOk = (e) => {
    setUpdateConfirm(false);
    setVisibleGroupEditModal(false);
    setVisibleDeleteModal(false);
    setVisibleCSVOutput(false);
    setVisibleAddNewModal(false);
  };

  const handleCancel = (e) => {
    setCancelConfirm(false);
    setVisibleGroupEditModal(false);
    setVisibleDeleteModal(false);
    setVisibleCSVOutput(false);
    setVisibleAddNewModal(false);
    setVisibleCSVInput(false);
    setVisibleXMLInput(false);
    setUpdateCancelConfirm(false);
  };

  const gpEditCheck = (newObj, oldObj, objKey) => {
    if (newObj[objKey] === '') {
      return oldObj[objKey];
    } else if (newObj.info[objKey] === '') {
      return oldObj.info[objKey];
    } else {
      setIsGpUpd(newObj[objKey] === oldObj[objKey] || newObj.info[objKey] === oldObj.info[objKey]);
      return Object.keys(newObj?.info)?.filter((v) => v === objKey)?.length > 0 ? newObj.info[objKey] : newObj[objKey];
    }
  };

  const updConfirmOk = async (e) => {
    let stateData = [];
    stateData = JSON.parse(JSON.stringify(materialNameData));
    setUpdateCancelConfirm(false);
    if (visibleAddNewModal) {
      // 画面の入力チェック
      if (!detailRef.current.formValidation(inputItemRef, formValidatorMode.Create, false)) {
        return;
      }
      let createdData = await createMaterialIq3Data(tempMaterialClass);
      stateData.push(createdData);
      setTmpFilteredData(stateData);
      setMaterialNameData(stateData);
      resetAddModal();
      //onRowSelect(tempMaterialClass);
      setSelectedRowKeys(createdData?.id);
      setSelectedData(createdData);
      setItemNo(createdData?.id);
      setCurrentPage(Math.ceil(stateData?.length / 25));
      setVisibleAddNewModal(false);
    } else if (visibleGroupEditModal) {
      // 画面の入力チェック
      if (!detailRef.current.formValidation(inputItemRef, formValidatorMode.BulkEdit, false)) {
        return;
      }
      if (checkedRowKeys?.length > 0) {
        const updatedData = stateData?.map((obj) => {
          if (checkedRowKeys?.includes(obj?.id)) {
            // gpEditHistory(obj);
            return {
              ...obj,
              materialTypeIq3Id: gpEditCheck(tempMaterialClass, obj, 'materialTypeIq3Id'),
              info: {
                ...obj.info,
                isUsed: gpEditCheck(tempMaterialClass, obj, 'isUsed'),
                thick: gpEditCheck(tempMaterialClass, obj, 'thick'),
                unitPrice: gpEditCheck(tempMaterialClass, obj, 'unitPrice'),
                remarks: gpEditCheck(tempMaterialClass, obj, 'remarks'),
              },
              modified: isGpUpd ? obj.modified : dayjs().format('YYYY-MM-DD'),
            };
          }
          return obj;
        });
        for (var i = 0; i <= updatedData?.length - 1; i++) {
          updatedData[i] = await updateMaterialIq3Data(updatedData[i]);
        }
        setTmpFilteredData(updatedData);
        setMaterialNameData(updatedData);
      }
      setVisibleGroupEditModal(false);
    }
    setIsFilter(true);
  };

  // 板金子部品材料マスタ情報をDBに新規登録する
  async function createMaterialIq3Data(createData) {
    let ret = [];
    try {
      const Url = process.env.REACT_APP_BACKEND_API_URL + 'MaterialIq3';
      const otherParam = {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + getAccessToken(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
        body: JSON.stringify({
          code: createData.code,
          name: createData.name,
          materialTypeIq3Id: createData.materialTypeIq3Id,
          info: createData.info,
        }),
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          return await response.json();
        })
        .then((data) => {
          if (data && data?.updateToken) {
            // セクションにトークン情報を更新する
            updateAccessToken(data?.updateToken);
          }
          if (data && data.statusCode === StatusCodes.OK) {
            ret = data.data[0];
          } else if (
            data &&
            (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)
          ) {
            authorizeError(ErrorMessage()[data?.messageCode]);
            return;
          } else if (data && data.statusCode !== StatusCodes.OK) {
            error(ErrorMessage()[data?.messageCode]);
            return;
          }
        })
        .catch((e) => {
          authorizeError(ErrorMessage().E010);
          return;
        });
    } catch (e) {
      error(e.message);
      return;
    }
    return ret;
  }

  const callMethod = (methodName, param) => {
    const method = eval(methodName);
    if (typeof method === 'function') {
      method(param);
    }
  };

  const handleGpEdit = () => {
    setTempMaterialClass({
      code: '',
      name: '',
      materialTypeIq3Id: '',
      priceHistories: [{ unitPrice: 0, modified: releaseDate }],
      info: { isUsed: '', thick: '', gravity: '', unitPrice: '', remarks: '' },
      created: releaseDate,
      modified: releaseDate,
    });
    setVisibleGroupEditModal(true);
    setChkRowsCount(0);
  };

  // 編集中破壊確認ポップアップのOKボタン押下
  const discardConfirmOk = () => {
    setEditModeCancelConfirm(false);
    detailRef.current.discardConfirmOk();
  };

  // 編集中破壊確認ポップアップのCancelボタン押下
  const discardConfirmCancel = () => {
    setEditModeCancelConfirm(false);
    detailRef.current.discardConfirmCancel();
    /* setEditModeCancelConfirm(showEdtConfirmCancle);
    if (!showEdtConfirmCancle) {
      detailRef.current.discardConfirmCancel();
    } */
  };

  const updCancelOK = () => {
    setUpdateCancelConfirm(false);
    setVisibleAddNewModal(false);
    setVisibleGroupEditModal(false);
  };

  const updCancelCancel = () => {
    setUpdateCancelConfirm(false);
    if (visibleAddNewModal) {
      setVisibleAddNewModal(true);
    } else if (visibleGroupEditModal) {
      setVisibleGroupEditModal(true);
    }
  };

  const updConfirmCancel = () => {
    setUpdateCancelConfirm(false);
  };

  const handleOkXMLInput = (e) => {
    setVisibleXMLInput(false);
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = '.xml';
    input.onchange = (_) => {
      let files = Array.from(input.files);
    };
    input.click();
  };

  const showGroupEditModal = () => {
    if (editMode) {
      setDiscardOKAction(actionArr[2]);
      updateEditMode(false);
      setEditModeCancelConfirm(true);
    } else {
      handleGpEdit();
    }
    /* setVisibleGroupEditModal(true); */
  };

  const showDeleteModal = () => {
    if (editMode) {
      let moveInEdit = props.showConfirmMsgs.moveInEdit == undefined ? true : props.showConfirmMsgs.moveInEdit;
      setEditModeCancelConfirm(moveInEdit);
      if (!moveInEdit) {
        setEditMode(false);
        document.getElementById('update').style.display = 'none';
        document.getElementById('discard').style.display = 'none';
        document.getElementById('edit').style.display = 'block';
      }
      setVisibleDeleteModal(true);
    } else {
      setVisibleDeleteModal(true);
    }
  };

  const deleteOK = async () => {
    setVisibleDeleteModal(false);
    let deletedData = await deleteMaterialIq3Data(selectedData.id);

    let data = [...filteredData];
    data = data?.filter((item) => item.id != deletedData?.id);

    setMaterialNameData(data);
    setTmpFilteredData(data);
    setFilteredData(data);
    setSelectedRowKeys(data?.length > 0 ? data[0].id : -1);
    setSelectedData(data?.length > 0 ? data[0] : []);
    setIsFilter(true);
  };

  // 板金子部品材料マスタ情報をDBに削除する
  async function deleteMaterialIq3Data(id) {
    let ret = [];
    try {
      const Url = process.env.REACT_APP_BACKEND_API_URL + 'MaterialIq3/' + id;
      const otherParam = {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + getAccessToken(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          return await response.json();
        })
        .then((data) => {
          if (data && data?.updateToken) {
            // セクションにトークン情報を更新する
            updateAccessToken(data?.updateToken);
          }
          if (data && data.statusCode === StatusCodes.OK) {
            ret = data.data[0];
          } else if (
            data &&
            (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)
          ) {
            authorizeError(ErrorMessage()[data?.messageCode]);
            return;
          } else if (data && data.statusCode !== StatusCodes.OK) {
            error(ErrorMessage()[data?.messageCode]);
            return;
          }
        })
        .catch((e) => {
          authorizeError(ErrorMessage().E010);
          return;
        });
    } catch (e) {
      error(e.message);
      return;
    }
    return ret;
  }

  const deleteCancel = () => {
    setVisibleDeleteModal(false);
  };

  const showCSVInputModal = () => {
    setVisibleCSVInput(false);
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = '.csv';
    input.onchange = (_) => {
      let files = Array.from(input.files);
    };
    input.click();
  };

  const showXMLInputModal = () => {
    setVisibleXMLInput(true);
  };

  const showAddNewModal = () => {
    if (editMode) {
      setDiscardOKAction(actionArr[3]);
      updateEditMode(false);
      let moveInEdit = props.showConfirmMsgs.moveInEdit == undefined ? true : props.showConfirmMsgs.moveInEdit;
      setEditModeCancelConfirm(moveInEdit);
      if (!moveInEdit) {
        setEditMode(false);
        document.getElementById('update').style.display = 'none';
        document.getElementById('discard').style.display = 'none';
        document.getElementById('edit').style.display = 'block';
        // handleAddFormSubmit();
      }
      handleAddFormSubmit();
    } else {
      handleAddFormSubmit();
    }
  };

  const handleAddFormSubmit = () => {
    setTempMaterialClass({
      code: '',
      name: '',
      materialTypeIq3Id: '',
      priceHistories: [
        {
          unitPrice: 0,
          modified: dayjs().format('YYYY-MM-DD'),
        },
      ],
      info: { isUsed: true, thick: '', gravity: '', unitPrice: 0, remarks: '' },
      created: dayjs().format('YYYY-MM-DD'),
      modified: dayjs().format('YYYY-MM-DD'),
    });
    setZairyoName('');
    setVisibleAddNewModal(true);
    setChildTitle('材料名称');
  };

  const addOK = () => {
    setIsAdd(true);
    setUpdateCancelConfirm(true);
  };

  const addCancel = () => {
    setIsAdd(false);
    // setUpdateCancelConfirm(true);
    setUpdateCancelConfirm(showEdtConfirmCancle);
    if (!showEdtConfirmCancle) {
      updCancelOK();
    }
    /* setVisibleAddNewModal(false); */
  };

  const resetAddModal = () => {
    setTempMaterialClass({
      code: '',
      name: '',
      materialTypeIq3Id: '',
      priceHistories: [
        {
          unitPrice: 0,
          modified: dayjs().format('YYYY-MM-DD'),
        },
      ],
      info: { isUsed: true, thick: '', gravity: '', unitPrice: 0, remarks: '' },
      created: dayjs().format('YYYY-MM-DD'),
      modified: dayjs().format('YYYY-MM-DD'),
    });
    setZairyoName('');
  };

  // フラグ、キーワードに変更された場合、
  useEffect(() => {
    // 絞り込み、
    getDataByFilter();
  }, [fromThickness, toThickness, unitPriceKey, dataState, showFlag, tmpFilteredData, materialNameData]);

  useEffect(() => {
    if (
      tempMaterialClass !== undefined &&
      tempMaterialClass?.materialTypeIq3Id !== '' &&
      tempMaterialClass?.info?.thick !== ''
    )
      var typeName = materialTypeIQ3List?.find((item) => item.value === tempMaterialClass?.materialTypeIq3Id)?.label;
    if (typeName !== undefined && tempMaterialClass?.info?.thick !== undefined) {
      var zairyo = typeName + '_' + tempMaterialClass?.info?.thick;
      setZairyoName(zairyo);
      setTempMaterialClass({
        ...tempMaterialClass,
        name: zairyo,
      });
    } else setZairyoName('');
  }, [materialTypeIq3IdState, thickness]);

  // 編集中破棄確認ポップアップに「OK」又は「キャンセル」を押下
  useEffect(() => {
    if (!editMode && !editModeCancelConfirm) {
      if (discardOKAction.key === 0 && changeRecordKey !== undefined) {
        setSelectedRowKeys(changeRecordKey);
        setSelectedData(filteredData?.find((item) => item.id === changeRecordKey));
        setItemNo(itemNoTmp);
      } else if (discardOKAction.key === 0 && changeRecordKey === undefined) {
        return;
      } else {
        const action = actionArr?.find((item) => item.key === discardOKAction.key);
        if ([1, 4, 5]?.includes(discardOKAction.key)) {
          callMethod(action.methodName, filterKey);
        } else {
          callMethod(action.methodName);
        }
      }
    }
  }, [editModeCancelConfirm]);

  const beforeSearch = (e, itemName) => {
    {
      let action;
      switch (itemName) {
        //searchByKeyword
        case 'thick':
        case 'unitPrice':
          action = actionArr[1];
          break;
        //材質Id
        case 'materialTypeIq3':
          action = actionArr[4];
          break;
        //表示.非表示
        case 'showFlg':
          action = actionArr[5];
          break;
      }
      setFilterKey(e);
      setDiscardOKAction(action);
      callMethod(action.methodName, e);
    }
  };

  // 材質名称で検索した場合、
  const searchByDataState = (value) => {
    dataState === value ? getDataByFilter() : setDataState(value);
  };

  // 表示.非表示で検索した場合、
  const searchByShowFlg = (value) => {
    showFlag === value ? getDataByFilter() : setShowFlag(value);
  };

  // キーワードで検索した場合、
  const searchByKeyword = (e) => {
    switch (e.target.name) {
      case 'toThickness':
        toThickness === e.target.value ? getDataByFilter() : setToThickness(e.target.value);
        break;
      case 'fromThickness':
        fromThickness === e.target.value ? getDataByFilter() : setFromThickness(e.target.value);
        break;
      case 'unitPrice':
        // let formattedValue = formatCurrencyInput(e.target.value);
        unitPriceKey === e.target.value ? getDataByFilter() : setUnitPriceKey(e.target.value);
        break;
    }
  };

  // 絞り込み、
  const getDataByFilter = () => {
    if (materialNameData?.length <= 0) {
      return;
    }
    let nKeyword = nameKeyword;
    let mKeyword = modelKeyword;
    let upperRange = toThickness;
    let lowerRange = fromThickness;
    let unitPriceKeyword = unitPriceKey;
    let currentData = [...materialNameData];
    let materialFilterData = currentData;
    let flag;

    // 名称のキーワードで絞り込み
    if (unitPriceKeyword != '') {
      materialFilterData = materialFilterData?.filter(
        (item) => parseFloat(item.info.unitPrice) === parseFloat(unitPriceKeyword)
      );
    }

    if (lowerRange != '' || upperRange != '') {
      lowerRange === '' && (lowerRange = 0);
      upperRange === '' &&
        (upperRange = Math.max.apply(
          null,
          materialFilterData?.map(function (o) {
            return o?.info.thick;
          })
        ));
      materialFilterData = materialFilterData?.filter(function (obj) {
        let objThickness = parseFloat(obj?.info.thick);
        return objThickness >= parseFloat(lowerRange) && objThickness <= parseFloat(upperRange);
      });
    }

    //  無効フラグで絞り込み
    if (showFlag === 1 || showFlag === 2) {
      flag = showFlag === 1 ? true : false;
      materialFilterData = materialFilterData?.filter((item) => item.info.isUsed === flag);
    }

    //参考価格用材質で絞り込み
    if (dataState !== undefined && dataState !== '全て') {
      materialFilterData = materialFilterData?.filter((item) => item.materialTypeIq3Id === dataState);
    }

    setFilteredData(materialFilterData);
  };

  document.body.style.overflowY = 'hidden';

  const reportModalTitle = (
    <div
      style={{
        width: 520,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{childTitle}</p>
    </div>
  );

  const setSplitPaneSize = (splitSize) => {
    setSizes(splitSize);
  };

  // 更新確認のOKを押下で、一覧画面のデータを反映する
  const submitData = async (updateData) => {
    var tmpData = [...materialNameData];
    var updatedInfo = [];
    updatedInfo = await updateMaterialIq3Data(updateData);

    var index = tmpData?.findIndex((item) => item?.id === updatedInfo?.id);
    tmpData[index] = updatedInfo;
    setMaterialNameData(tmpData);
    setTmpFilteredData(tmpData);
    setSelectedRowKeys(updatedInfo?.id);
    setSelectedData(updatedInfo);
    setIsFilter(false);
  };

  // 板金子部品材料マスタ情報をDBに更新する
  async function updateMaterialIq3Data(updateData) {
    let ret = [];
    try {
      const Url = process.env.REACT_APP_BACKEND_API_URL + 'MaterialIq3';
      const otherParam = {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + getAccessToken(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
        body: JSON.stringify({
          id: updateData.id,
          code: updateData.code,
          name: updateData.name,
          materialTypeIq3Id: updateData.materialTypeIq3Id,
          info: updateData.info,
        }),
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          return await response.json();
        })
        .then((data) => {
          if (data && data?.updateToken) {
            // セクションにトークン情報を更新する
            updateAccessToken(data?.updateToken);
          }
          if (data && data.statusCode === StatusCodes.OK) {
            ret = data.data[0];
          } else if (
            data &&
            (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)
          ) {
            authorizeError(ErrorMessage()[data?.messageCode]);
            return;
          } else if (data && data.statusCode !== StatusCodes.OK) {
            error(ErrorMessage()[data?.messageCode]);
            return;
          }
        })
        .catch((e) => {
          authorizeError(ErrorMessage().E010);
          return;
        });
    } catch (e) {
      error(e.message);
      return;
    }
    return ret;
  }

  // 重量単価更新の押下
  const handleChangeUnitPrice = async () => {
    let nameData = [...materialNameData];
    for (var i = 0; i <= nameData?.length - 1; i++) {
      let updatedPrice = 0;
      if (plusMinusAct === '+') {
        updatedPrice = parseFloat(nameData[i]?.info.unitPrice) + unitPrice;
      } else if (plusMinusAct === '-') {
        updatedPrice = parseFloat(nameData[i]?.info.unitPrice) - unitPrice;
        if (updatedPrice < 0) {
          updatedPrice = 0;
        }
      }
      nameData[i].info.unitPrice = updatedPrice;
      nameData[i] = await updateMaterialIq3Data(nameData[i]);
    }
    setFilteredData(nameData);
    setTmpFilteredData(nameData);
    setMaterialNameData(nameData);
    setIsFilter(true);
  };

  // 編集モードを変更
  const updateEditMode = (mode) => {
    setEditMode(mode);
    props.updateMainParameterEditMode(mode);
  };

  return (
    <>
      {/* 一覧とプロパティ表示 */}
      <div
        style={{
          height: '90.8vh',
          marginLeft: 10,
        }}
        className="materialName"
      >
        <SplitPane split="vertical" sizes={sizes} onChange={setSizes} resizerSize={1}>
          <div style={{ marginTop: 7 }}>
            {/*  クイック検索 */}
            <>
              <Space size="middle" className="filterSpace">
                <Select
                  style={{ width: 170 }}
                  onChange={(e) => beforeSearch(e, 'materialTypeIq3')}
                  id="materialRefDDL"
                  name="materialRefDDL"
                  value={dataState}
                  defaultValue={'全て'}
                >
                  <Select.Option id="allOption" value={'全て'}>
                    {'全ての材質名称'}
                  </Select.Option>
                  {materialTypeIQ3List?.map((item, index) => (
                    <Select.Option key={index} id={'materialClassName' + item.value} value={item.value}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>

                <div
                  className="thicknessFilter"
                  size="middle"
                  style={{ gap: 0, display: 'flex', alignItems: 'center' }}
                >
                  <label>板厚：</label>
                  <div className="containerStyle" style={{ border: 0 }}>
                    <Input
                      className="filter-input-range input-editable search-select-input"
                      onPressEnter={(e) => beforeSearch(e, 'thick')}
                      onBlur={(e) => beforeSearch(e, 'thick')}
                      onKeyPress={(e) => {
                        handleKeyPress(e, true);
                      }}
                      style={{ width: 90 }}
                      allowClear
                      onChange={(e) => {
                        if (e.target.value === '') {
                          beforeSearch(e, 'thick');
                        }
                      }}
                      name="fromThickness"
                      id="fromThickness"
                    ></Input>
                    <label>~</label>
                    <Input
                      className="filter-input-range input-editable search-select-input"
                      onPressEnter={(e) => beforeSearch(e, 'thick')}
                      onBlur={(e) => beforeSearch(e, 'thick')}
                      onKeyPress={(e) => {
                        handleKeyPress(e, true);
                      }}
                      style={{ width: 90 }}
                      allowClear
                      onChange={(e) => {
                        if (e.target.value === '') {
                          beforeSearch(e, 'thick');
                        }
                      }}
                      name="toThickness"
                      id="toThickness"
                    ></Input>
                  </div>
                </div>

                <Input
                  className="input-editable search-select-input unitPrice"
                  placeholder="重量単価"
                  allowClear
                  type="number"
                  // pattern="[0-9]*"
                  onPressEnter={(e) => beforeSearch(e, 'unitPrice')}
                  onBlur={(e) => beforeSearch(e, 'unitPrice')}
                  onKeyPress={(e) => {
                    handleKeyPress(e, false);
                  }}
                  onChange={(e) => {
                    if (e.target.value === '') {
                      beforeSearch(e, 'unitPrice');
                    }
                  }}
                  name="unitPrice"
                  id="unitPrice"
                />

                <Select
                  style={{ width: 90 }}
                  onChange={(e) => beforeSearch(e, 'showFlg')}
                  placeholder="表示・非表示"
                  id="showFlgDrop"
                  name="showFlgDrop"
                  value={showFlag}
                >
                  {dataStateList?.map((item, index) => (
                    <Select.Option key={index} id={'showFlg' + item.value} value={item.value}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Space>
              <Space className="unitPriceAction" size="middle" style={{ gap: 0, position: 'absolute', right: 0 }}>
                <label>重量単価更新：</label>
                <div className="containerStyle">
                  <Select
                    defaultValue={'+'}
                    id="plusMinusDDL"
                    name="plusMinusDDL"
                    className={plusMinusAct === '-' ? 'plusMinusDDL minus' : 'plusMinusDDL'}
                    value={plusMinusAct}
                    onChange={(e) => {
                      setPlusMinusAct(e);
                    }}
                  >
                    {plusMinus?.map((item, index) => (
                      <Select.Option key={index} id={'plusMinus' + item.value} value={item.label}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                  <CurrencyInput
                    name="unitPrice"
                    id="unitPrice"
                    style={{ width: 80, border: 'none', textAlign: 'center' }}
                    value={unitPrice}
                    type="text"
                    prefix="¥"
                    decimalsLimit={100}
                    className="currencyInput"
                    onValueChange={(e) =>
                      setUnitPrice(() => {
                        let value = e;
                        let formattedValue = value;

                        if (e == '' || e === undefined) {
                          formattedValue = 0;
                        } else {
                          if (value.startsWith('¥', 0)) {
                            formattedValue = formattedValue.replace('¥', '');
                          }
                        }
                        return parseFloat(formattedValue);
                      })
                    }
                  />
                </div>
                <SyncOutlined className="submitUnitPrice" style={{ margin: '0 5' }} onClick={handleChangeUnitPrice} />
              </Space>
              {/* 一覧アリア */}
              <Row style={{ marginTop: 10 }}>
                <Table
                  showSorterTooltip={false}
                  columns={hasCheckData ? tableColumns : chkData}
                  components={components}
                  dataSource={filteredData}
                  scroll={{ y: 590, x: '10vw' }}
                  rowClassName={(record) => (record.id == selectedRowKeys ? 'active-row' : 'data-row')}
                  rowKey={(record) => record.id}
                  pagination={{
                    position: ['bottomLeft'],
                    total: (record) => record?.length,
                    showTotal: (total, range) => `全${total}件中${range[0]}件〜${range[1]}件を表示`,
                    defaultPageSize: 25,
                    defaultCurrent: 1,
                    pageSize: 25,
                    current: currentPage,
                    onChange: (page) => {
                      setCurrentPage(page);
                    },
                  }}
                  className="param_tbListShow"
                  onRow={(record, index) => {
                    return {
                      onClick: (event) => {
                        onRowSelect(record, index);
                      },
                    };
                  }}
                />
              </Row>
            </>
          </div>
          <div>
            {/* <Outlet /> */}
            <Routes>
              <Route
                exact
                path=":iq3.materialName"
                element={
                  <MaterialNameDetail
                    ref={detailRef}
                    editMode={editMode}
                    selectedData={materialNameData?.find((element) => element.id === selectedRowKeys)}
                    itemNo={itemNo}
                    submitData={submitData}
                    materialTypeIQ3List={materialTypeIQ3List}
                    getMaterilTypeIQ3Name={getMaterilTypeIQ3Name}
                    updateEditMode={updateEditMode}
                    getAntInputBoxClassName={getAntInputBoxClassName}
                    showEdtConfirmOK={showEdtConfirmOK}
                    showEdtConfirmCancle={showEdtConfirmCancle}
                  />
                }
              />
            </Routes>
          </div>
        </SplitPane>
      </div>

      {/* 一括編集　Modal */}
      {visibleGroupEditModal &&
        commonModal(
          visibleGroupEditModal,
          modalTitle('材料名称（選択したデータをまとめて編集）', 1790, -24),
          null,
          null,
          1790,
          handleOk,
          handleCancel,
          gpEditModalContent,
          'gpEditModal',
          null,
          true
        )}

      {/* 削除_Modal */}
      {visibleDeleteModal &&
        commonModal(
          visibleDeleteModal,
          confirmModalTitle,
          commonDeleteFooter(deleteOK, deleteCancel),
          null,
          400,
          handleOk,
          handleCancel,
          confirmDeleteContent,
          null,
          null,
          false
        )}

      {/* XML呼出 */}
      <Modal
        //title="確認"
        title={reportModalTitle}
        open={visibleXMLInput}
        onOk={handleOkXMLInput}
        onCancel={handleCancel}
        footer={[
          <Button key="file" type="primary" onClick={handleOkXMLInput} className="btn-ok-cancel">
            OK
          </Button>,
          <Button key="back" onClick={handleCancel} className="btn-ok-cancel">
            Cancel
          </Button>,
        ]}
        closeIcon={<CloseOutlined style={{ color: 'white' }} />}
      >
        <p>
          装置情報（時間チャージ、段取設定、加工設定）を初期パラメータ作成情報から取り込みます。
          <br></br>装置情報は、呼出情報で更新されます。
        </p>
      </Modal>

      {/** 編集中破棄確認ポップアップ */}
      {editModeCancelConfirm
        ? commonModal(
            editModeCancelConfirm,
            confirmModalTitle,
            commonFooter(discardConfirmOk, discardConfirmCancel),
            null,
            400,
            discardConfirmOk,
            discardConfirmCancel,
            confirmHakiContent,
            null,
            null,
            false
          )
        : ''}

      {/* 新規追加Modal */}
      {visibleAddNewModal
        ? commonModal(
            visibleAddNewModal,
            modalTitle('材料名称（新規追加）', 800, -24),
            commonActionFooter(addOK, addCancel, '登録', '破棄'),
            null,
            800,
            handleOk,
            handleCancel,
            addModalContent,
            'addNewMaterialClassModal',
            null,
            false
          )
        : ''}

      {/** 新規追加破棄確認ポップアップ */}
      {updateCancelConfirm &&
        commonModal(
          updateCancelConfirm,
          confirmModalTitle,
          commonFooter(isAdd ? updConfirmOk : updCancelOK, isAdd ? updConfirmCancel : updCancelCancel),
          null,
          400,
          updCancelOK,
          updCancelCancel,
          isAdd ? (visibleGroupEditModal ? confirmUpdateContent : confirmCreateContent) : confirmDiscardContent,
          null,
          null,
          false
        )}
    </>
  );
});

export default MaterialNameList;
