import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { Row, Select, Input } from 'antd';
import { PlusCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { WorkType } from '../common/enums';
const types = [
  { id: 1, value: 'V曲げ', label: 'V曲げ' },
  { id: 2, value: 'R曲げ', label: 'R曲げ' },
  { id: 3, value: 'FR曲げ', label: 'FR曲げ' },
  { id: 4, value: '3R曲げ', label: '3R曲げ' },
  { id: 5, value: 'HM曲げ', label: 'HM曲げ' },
  { id: 6, value: 'Z曲げ', label: 'Z曲げ' },
  { id: 7, value: 'AMA曲げ', label: 'AMA曲げ' },
  { id: 8, value: '特殊曲げ1', label: '特殊曲げ1' },
  { id: 9, value: '特殊曲げ2', label: '特殊曲げ2' },
  { id: 10, value: '特殊曲げ3', label: '特殊曲げ3' },
];

const IQ3KouteiInput_Bending = forwardRef((props, ref) => {
  const [bendingData, setBendingData] = useState({});
  const [bendingList, setBendingList] = useState([]);
  const [maxBendLen, setMaxBendLen] = useState(0);
  const [maxBendAutoExt, setMaxBendAutoExt] = useState(0);
  const [types, setTypes] = useState([]);
  useEffect(() => {
    const processes = props.processMaster;
    const bendingDetails = processes?.filter((i) => i.workType === WorkType.SmBending);
    if (bendingDetails?.length > 0) {
      let types = bendingDetails?.[0].details?.processDetailTypes;
      setTypes(types);
    }

    let data = props.bendingInfo;
    if (data != undefined) {
      setBendingData(data.details?.[0]?.bendingData);
      // setMaxBendLen(data.details[0].bendingData.maxBendLength);
      // setMaxBendAutoExt(data.details[0].bendingData.maxBendAutoExtract);
      setBendingList(data.details?.[0]?.bendingList);
      // let maxBend = getMaxBendLength(data.details[0].bendingList);

      // const newBendingData = { ...data.details[0].bendingData };

      // newBendingData['maxBendLength'] = parseFloat(maxBend) || 0;
      // newBendingData['maxBendAutoExtract'] = parseFloat(maxBend) || 0;

      // data.details[0].bendingData = newBendingData;
      // setBendingData(newBendingData);
    }
  }, [props.bendingInfo]);

  const getMaxBendLength = (list) => {
    const maxBendLength = list
      ? list?.reduce((max, item) => {
          return item.bendLength > max ? item.bendLength : max;
        }, -Infinity)
      : -Infinity;
    const newBendingData = { ...props.bendingInfo?.details?.[0]?.bendingData };

    newBendingData['maxBendLength'] = parseFloat(maxBendLength) || 0;
    newBendingData['maxBendAutoExtract'] = parseFloat(maxBendLength) || 0;

    props.bendingInfo.details[0].bendingData = newBendingData;
    setBendingData(newBendingData);
    // return maxBendLength;
  };

  useImperativeHandle(ref, () => ({
    setEditModeChange: setEditModeChange,
  }));

  const addBending = (id) => {
    const copyData = bendingList?.slice(); // Create a copy of the original array

    const insertIndex = bendingList?.findIndex((item) => item.id === id); // Find the clicked index of the item
    const largestId = bendingList?.reduce((maxId, item) => {
      return item.id > maxId ? item.id : maxId;
    }, 0);

    if (insertIndex !== -1) {
      const data = {
        id: largestId + 1,
        types: copyData[insertIndex]?.types,
        bendLength: copyData[insertIndex]?.bendLength,
        lines: copyData[insertIndex]?.lines,
        bendCount: copyData[insertIndex]?.bendCount,
        // num: copyData[insertIndex].num,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setBendingList(copyData);
      const newBendingInfo = { ...props.bendingInfo };
      newBendingInfo.details[0].bendingList = copyData;
      props.updateBending(newBendingInfo);
    }
  };
  const deleteBending = (id) => {
    if (bendingList?.length > 1) {
      const updatedData = bendingList?.filter((item) => item.id !== id);

      setBendingList(updatedData);
      const newBendingInfo = { ...props.bendingInfo };
      newBendingInfo.details[0].bendingList = updatedData;
      props.updateBending(newBendingInfo);
    }
  };
  const changeDetails = (event, id, types) => {
    const temp = JSON.parse(JSON.stringify(bendingList));
    let updatedData = [];
    if (types === 'types') {
      updatedData = temp?.map((row) => {
        if (row.id === id) {
          return { ...row, [types]: event };
        }
        return row;
      });
    } else {
      updatedData = temp?.map((row) => {
        if (row.id === id) {
          return { ...row, [types]: event.target.value };
        }
        return row;
      });
    }
    setBendingList(updatedData);
    getMaxBendLength(updatedData);
    const newBendingInfo = { ...props.bendingInfo };
    newBendingInfo.details[0].bendingList = updatedData;
    props.updateBending(newBendingInfo);
  };

  const changeValue = (e) => {
    const { name, value } = e.target;
    const newBendingInfo = { ...props.bendingInfo };

    const newBendingData = { ...newBendingInfo.details[0].bendingData };

    newBendingData[name] = parseFloat(value) || 0;

    newBendingInfo.details[0].bendingData = newBendingData;
    setBendingData(newBendingData);
    props.updateBending(newBendingInfo);
  };
  return (
    <>
      <Row className="mt-1 registerModal">
        <Table className="kouteiinput" style={{ width: '22%' }}>
          <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
            <tr style={{ textAlign: 'center' }}>
              <th style={{ width: '22%' }} className="tbl-header">
                最大曲げ線長(mm)
              </th>
              {/* <th style={{ width: '22%' }} className="tbl-header">
                自動抽出(最大曲げ線長)
              </th> */}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Input
                  // className={editMode ? 'input-non-editable ' : 'input-non-editable '}
                  className="input-editable"
                  name="maxBendLength"
                  value={bendingData.maxBendLength}
                  onChange={changeValue}
                  style={{ height: 32, textAlign: 'center' }}
                ></Input>
              </td>
              {/* <td>
                <Input
                  // className={editMode ? 'input-non-editable ' : 'input-non-editable '}
                  className="input-non-editable"
                  name="maxBendAutoExtract"
                  value={bendingData.maxBendAutoExtract}
                  // onChange={(e) => changeValue(e, 'maxBendAutoExtract')}
                  // onChange={changeValue}
                  style={{ height: 32, textAlign: 'center' }}
                ></Input>
              </td> */}
            </tr>
            {/* </>
            ))} */}
          </tbody>
        </Table>
      </Row>
      <Row className="mt-2 registerModal">
        <Table className="kouteiinput" style={{ width: '60.5%' }}>
          <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
            <tr style={{ textAlign: 'center' }}>
              <th style={{ width: '3%' }} className="tbl-header">
                No
              </th>
              <th style={{ width: '19%' }} className="tbl-header">
                種類.形状
              </th>
              <th style={{ width: '11.5%' }} className="tbl-header">
                曲げ長さ(mm)
              </th>
              <th style={{ width: '11%' }} className="tbl-header">
                曲げ回数
              </th>
              <th style={{ width: '11%' }} className="tbl-header">
                FR曲げ回数
              </th>
            </tr>
          </thead>
          <tbody>
            {bendingList?.map((i, index) => (
              <>
                <tr>
                  <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{index + 1}</td>
                  <td style={{ verticalAlign: 'middle' }}>
                    {/* {editMode ? (
                      <> */}
                    <Select
                      defaultValue={1}
                      value={i.types}
                      onChange={(e) => changeDetails(e, i.id, 'types')}
                      size="middle"
                      style={{ width: '100%' }}
                    >
                      {types?.map((option) => (
                        <Select.Option value={option.id}>{option.name}</Select.Option>
                      ))}
                    </Select>
                  </td>

                  <td>
                    <Input
                      className="input-editable"
                      value={i.bendLength}
                      onChange={(e) => changeDetails(e, i.id, 'bendLength')}
                      style={{ height: 32, textAlign: 'center' }}
                    ></Input>
                  </td>
                  <td>
                    <Input
                      className="input-editable"
                      value={i.lines}
                      onChange={(e) => changeDetails(e, i.id, 'lines')}
                      style={{ height: 32, textAlign: 'center' }}
                    ></Input>
                  </td>
                  <td>
                    {i.types === 3 ? (
                      <>
                        <Input
                          // className={editMode ? 'input-editable ' : 'input-non-editable '}
                          className="input-editable"
                          value={i.bendCount}
                          onChange={(e) => changeDetails(e, i.id, 'bendCount')}
                          style={{ height: 32, textAlign: 'center' }}
                        ></Input>
                      </>
                    ) : (
                      <></>
                    )}
                  </td>

                  {/* {editMode ? (
                    <> */}
                  <td style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '6%' }}>
                    <Row justify="center">
                      <PlusCircleFilled
                        className="add-remove-icon"
                        style={{ marginLeft: 10, marginRight: 10 }}
                        onClick={() => addBending(i.id)}
                      />
                      <CloseCircleFilled className="add-remove-icon" onClick={() => deleteBending(i.id)} />
                    </Row>
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        </Table>
      </Row>
    </>
  );
});

export default IQ3KouteiInput_Bending;
