/**
 * クラス名：板金子部品見積材料詳細
 * 説明：板板金子部品見積材料詳細ファイルである。
 * 作成者：ケイ
 * 作成日：2023/06/29
 * バージョン：1.0
 */
import React, { useImperativeHandle, forwardRef, useEffect, useState, useRef } from 'react';
import { Table, Row, Col, Select, Button, Input, Popover, Image } from 'antd';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table as RTable } from 'react-bootstrap';
import {
  RightCircleFilled,
  LeftCircleFilled,
  CaretUpOutlined,
  CaretDownOutlined,
  UpCircleFilled,
  DownCircleOutlined,
} from '@ant-design/icons';

import pattern1 from '../../assets/images/pattern1.png';
import pattern2 from '../../assets/images/pattern2.png';
import bottomToTop from '../../assets/images/bottomToTop.png';
import rotateY from '../../assets/images/rotateY.png';
import leftToRight from '../../assets/images/leftToRight.png';
import rotateX from '../../assets/images/rotateX.png';
import leftRightFit from '../../assets/images/leftRightFit.png';
import topBottomFit from '../../assets/images/topBottomFit.png';
import pattern_img from '../../assets/images/pattern_img_open.png';
import {
  JPYs,
  Percent,
  draw,
  getMaterialDetailPatternName,
  getMaterialNameById,
  getMaterialSurfaceById,
  getMaterialTypeById,
  handleKeyPress,
  toDecimal,
} from '../common/Common';
import {
  commonModal,
  commonFooter,
  confirmModalTitle,
  confirmUpdateContent,
  confirmDiscardContent,
} from '../common/CommonModal';
import IQ3MaterialDetail_SheetDetailPattern from './IQ3MaterialDetail_SheetDetailPattern';
import { MaterialSurfaceBillingUnit, PatternType, ProcessType, ServiceClass, WorkType } from '../common/enums';

const IQ3MaterialDetail = forwardRef((props, ref) => {
  let zoom = 1;
  const [editMode, setEditMode] = useState(false);
  const [detailData, setDetailData] = useState();
  const [tempDetailData, setTempDetailData] = useState();
  const [materialSizeData, setMaterialSizeData] = useState([]);
  const [materialDetailSizeData, setMaterialDetailSizeData] = useState([]);
  const [materialDetailPatternData, setMaterialDetailPatternData] = useState([]);

  // 材料マスタ情報
  const [materialListData, setMaterialListData] = useState([]);
  // 材質マスタ情報
  const [materialTypeListData, setMaterialTypeListData] = useState([]);
  // 材料表面保護マスタ情報
  const [materialSurfaceListData, setMaterialSurfaceListData] = useState([]);

  const [activePattern, setActivePattern] = useState(PatternType.Pattern1);

  // 情報項目
  const [partName, setPartName] = useState('');
  const [quantity, setQuantity] = useState('');
  const [partCount, setPartCount] = useState('');
  const [xSize, setXSize] = useState('');
  const [ySize, setYSize] = useState('');
  const [materialId, setMaterialId] = useState(0);
  const [materialTypeId, setMaterialTypeId] = useState(0);
  const [thickness, setThickness] = useState('');
  const [surfaceId, setSurfaceId] = useState(0);
  const [weight, setWeight] = useState('');
  const [surfaceArea, setSurfaceArea] = useState('');
  const [xGrabAllowance, setXGrabAllowance] = useState('');
  const [yGrabAllowance, setYGrabAllowance] = useState('');
  const [xWidthOfPier, setXWidthOfPier] = useState('');
  const [yWidthOfPier, setYWidthOfPier] = useState('');
  const [xMachiningRange, setXMachiningRange] = useState('');
  const [yMachiningRange, setYMachiningRange] = useState('');

  // 計算結果項目
  const [summarizeTotalAmount, setSummarizeTotalAmount] = useState('');
  const [summarizeYield, setSummarizeYield] = useState('');
  const [summarizeNumOfMaterial, setSummarizeNumOfMaterial] = useState('');
  const [summarizePrice, setSummarizePrice] = useState('');
  const [summarizeSurface, setSummarizeSurface] = useState('');
  const [summarizeWeight, setSummarizeWeight] = useState('');
  const [summarizeMaterialPrice, setSummarizeMaterialPrice] = useState('');
  const [summarizeCostRate, setSummarizeCostRate] = useState('');
  const [summarizeScrapAmount, setSummarizeScrapAmount] = useState('');

  // パターン1項目
  const [pattern1TotalAmount, setPattern1TotalAmount] = useState(0);
  const [pattern1Yield, setPattern1Yield] = useState(0);
  const [pattern1NumOfMaterial, setPattern1NumOfMaterial] = useState(0);
  const [pattern1XSize, setPattern1XSize] = useState(0);
  const [pattern1YSize, setPattern1YSize] = useState(0);
  const [pattern1MaterialSizeId, setPattern1MaterialSizeId] = useState(0);

  // パターン2項目
  const [pattern2TotalAmount, setPattern2TotalAmount] = useState(0);
  const [pattern2Yield, setPattern2Yield] = useState(0);
  const [pattern2NumOfMaterial, setPattern2NumOfMaterial] = useState(0);
  const [pattern2XSize, setPattern2XSize] = useState(0);
  const [pattern2YSize, setPattern2YSize] = useState(0);
  const [pattern2MaterialSizeId, setPattern2MaterialSizeId] = useState(0);

  const [selectedRowNo, setSelectedRowNo] = useState();
  const [selectedMaterialXsize, setSelectedMaterialXsize] = useState();
  const [selectedMaterialYsize, setSelectedMaterialYsize] = useState();

  const [isPatternDetail, setIsPatternDetail] = useState(false);
  const [childTitle, setChildTitle] = useState('');

  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [updateConfirm, setUpdateConfirm] = useState(false);

  const [isCalResult, setIsCalResult] = useState(true);
  const [isPattern1, setIsPattern1] = useState(true);
  const [isPattern1Img, setIsPattern1Img] = useState(false);
  const [isPattern2, setIsPattern2] = useState(true);
  const [isPattern2Img, setIsPattern2Img] = useState(false);

  const [materialCnt, setMaterialCnt] = useState(1);
  const [materialCntPattern2, setMaterialCntPattern2] = useState(1);

  const [materialOtherSize, setMaterialOtherSize] = useState(false);

  // パターン1詳細項目
  const [detailP1TotalAmount, setDetailP1TotalAmount] = useState(0);
  const [detailP1Yield, setDetailP1Yield] = useState(0);
  const [detailP1NumOfMaterial, setDetailP1NumOfMaterial] = useState(0);
  const [detailP1XSize, setDetailP1XSize] = useState(0);
  const [detailP1YSize, setDetailP1YSize] = useState(0);
  const [detailP1MaterialSizeId, setDetailP1MaterialSizeId] = useState(0);

  // パターン2詳細項目
  const [detailP2TotalAmount, setDetailP2TotalAmount] = useState(0);
  const [detailP2Yield, setDetailP2Yield] = useState(0);
  const [detailP2NumOfMaterial, setDetailP2NumOfMaterial] = useState(0);
  const [detailP2XSize, setDetailP2XSize] = useState(0);
  const [detailP2YSize, setDetailP2YSize] = useState(0);
  const [detailP2MaterialSizeId, setDetailP2MaterialSizeId] = useState(0);

  // 詳細項目
  const [detailMaterialId, setDetailMaterialId] = useState(0);
  const [detailMaterialTypeId, setDetailMaterialTypeId] = useState(0);
  const [detailThickness, setDetailThickness] = useState('');
  const [detailSurfaceId, setDetailSurfaceId] = useState(0);
  const [detailXGrabAllowance, setDetailXGrabAllowance] = useState('');
  const [detailYGrabAllowance, setDetailYGrabAllowance] = useState('');
  const [detailXWidthOfPier, setDetailXWidthOfPier] = useState('');
  const [detailYWidthOfPier, setDetailYWidthOfPier] = useState('');

  // パーツ情報
  const [mPartInfo, setMPartInfo] = useState([]);
  // パターン計算パーツ情報
  const [patternPartInfo, setPatternPartInfo] = useState([]);
  // パターン計算配置情報
  const [patternSettingInfo, setPatternSettingInfo] = useState([]);
  // パターン計算材料情報
  const [patternMaterialInfo, setPatternMaterialInfo] = useState([]);

  // 見積もり情報
  const [selectedEstimateData, setSelectedEstimateData] = useState([]);

  const [ySetting, setYSetting] = useState(true);

  const [yRotate, setYRotate] = useState(false);

  const [rightFit, setRightFit] = useState(false);

  const [topFit, setTopFit] = useState(false);

  const [minXsize, setMinXsize] = useState(0);

  const [minYsize, setMinYsize] = useState(0);

  const maxXYSize = 99999.99;

  const ZOOM_SPEED = 0.1;

  let toDrawInfo = {
    partInfo: {},
    settingInfo: {},
    materialInfo: {},
  };

  useEffect(() => {
    const childPartDetail = props.selectedIQ3DataDetail;
    if (childPartDetail === undefined) return;

    setEditMode(props.editMode != undefined ? props.editMode : false);

    setMPartInfo(props.selectedIQ3DataDetail);

    // 初期又は材料情報に変更された、タイミングのみ再計算する
    if (props?.isReCalMaterial || props?.selectedIQ3DataDetail?.materialDetailPatterns?.length === 0) {
      let calculatedIq3Data = calculateMaterialDetailSize({ ...childPartDetail }); // 材料リストの計算
      let materialDetailSizeLists = calculatedIq3Data?.materialDetailSizes;
      let materialDetailPatternLists = calculatedIq3Data?.materialDetailPatterns;
      setDetailData({ ...calculatedIq3Data });
      setMaterialDetailInfo(calculatedIq3Data); // 材料詳細情報
      setMaterialDetailSizeData(materialDetailSizeLists); // 材料リスト
      setMaterialDetailPatternData(materialDetailPatternLists); // 材料詳細パターン情報

      // 一番合計歩留まりが良い材料サイズ情報
      let maxYieldRecord = {};
      if (materialDetailSizeLists?.length > 0) {
        maxYieldRecord = materialDetailSizeLists?.reduce((prev, current) =>
          prev && prev.yield > current.yield ? prev : current
        );
      }
      const rowNo = maxYieldRecord?.no
        ? maxYieldRecord?.no
        : materialDetailSizeLists?.length > 0
        ? materialDetailSizeLists?.[0]?.no
        : 0;
      setSelectedRowNo(rowNo);
      calMaterialXYSize(maxYieldRecord.materialSizeIq3Id);
      displayMaterialDetailInfo(maxYieldRecord, materialDetailPatternLists);
    } else if (childPartDetail?.id !== 0) {
      // 更新モードの場合、
      let iq3Data = { ...childPartDetail }; // 材料リストの計算
      let materialDetailSizeLists = iq3Data?.materialDetailSizes;
      let materialDetailPatternLists = iq3Data?.materialDetailPatterns;
      setDetailData({ ...iq3Data });
      setMaterialDetailInfo(iq3Data); // 材料詳細情報
      setMaterialDetailSizeData(materialDetailSizeLists); // 材料リスト
      setMaterialDetailPatternData(materialDetailPatternLists); // 材料詳細パターン情報
      let selectedRow = materialDetailSizeLists?.[0];
      setSelectedRowNo(selectedRow?.no);
      calMaterialXYSize(selectedRow?.materialSizeIq3Id);
      displayMaterialDetailInfo(selectedRow, materialDetailPatternLists);
    }
  }, [props.selectedIQ3DataDetail, props.editMode, props.getParentQuantity]);

  // 親部品の見積もり情報
  useEffect(() => {
    const selectedEstimateData = props.selectedEstimateData;
    if (selectedEstimateData === undefined) return;
    setSelectedEstimateData(selectedEstimateData);
    let materialSizeData = getMaterialSizeData(selectedEstimateData); // 材料サイズ情報
    setMaterialSizeData(materialSizeData);
  }, [props.selectedEstimateData]);

  // 材質、材料、材料表面情報
  useEffect(() => {
    setMaterialListData(props.materialLst);
    setMaterialTypeListData(props.materialTypeLst);
    setMaterialSurfaceListData(props.materialSurfaceLst);
  }, [props.materialLst, props.materialTypeLst, props.materialSurfaceLst]);

  // 材質、材料名称、板厚、表面保護、掴み代X、掴み代Y、桟幅X、桟幅Yを変更した場合、
  useEffect(() => {
    if (detailData === undefined) return;
    let calculatedIq3Data = calculateMaterialDetailSize(detailData); // 材料リストの計算
    setMaterialDetailSizeData(calculatedIq3Data?.materialDetailSizes); // 材料リスト
    setMaterialDetailPatternData(calculatedIq3Data?.materialDetailPatterns); // 材料詳細パターン情報
    let selectedSizeData = calculatedIq3Data?.materialDetailSizes?.find((item) => item.no === selectedRowNo);
    displayMaterialDetailInfo(selectedSizeData, calculatedIq3Data?.materialDetailPatterns);
  }, [xGrabAllowance, yGrabAllowance, xWidthOfPier, yWidthOfPier, materialTypeId, materialId, thickness, surfaceId]);

  // 詳細画面の材質、材料名称、板厚、表面保護、掴み代X、掴み代Y、桟幅X、桟幅Yを変更した場合、
  useEffect(() => {
    // if (tempDetailData === undefined || topFit || rightFit) return;
    if (tempDetailData === undefined) return;
    let calculatedIq3Data = calculateMaterialDetailSize(tempDetailData, true); // 材料リストの計算
    let selectedSizeData = calculatedIq3Data?.materialDetailSizes?.find(
      (item) => item.materialSizeIq3Id === detailP1MaterialSizeId
    );
    displayMaterialDetailInfo(selectedSizeData, calculatedIq3Data?.materialDetailPatterns, true);
  }, [
    detailXGrabAllowance,
    detailYGrabAllowance,
    detailXWidthOfPier,
    detailYWidthOfPier,
    detailMaterialTypeId,
    detailMaterialId,
    detailThickness,
    detailSurfaceId,
    detailP1XSize,
    detailP1YSize,
    detailP2XSize,
    detailP2YSize,
    ySetting,
  ]);

  // 詳細画面のYFit、XFit、rotate、ySettingを押下した場合、
  useEffect(() => {
    // if (tempDetailData === undefined || (!topFit && !rightFit)) return;
    if (tempDetailData === undefined) return;
    let calculatedIq3Data = calculateMaterialDetailSize(tempDetailData, true, true); // 材料リストの計算
    let selectedSizeData = calculatedIq3Data?.materialDetailSizes?.find(
      (item) => item.materialSizeIq3Id === detailP1MaterialSizeId
    );
    let patternDetailData = calculatedIq3Data?.materialDetailPatterns;
    let pattern1Info = getPatternInfo(patternDetailData, selectedSizeData.materialSizeIq3Id, PatternType.Pattern1);
    let pattern2Info = getPatternInfo(patternDetailData, selectedSizeData.materialSizeIq3Id, PatternType.Pattern2);
    setPattern1Info(pattern1Info, true); // パターン１詳細情報
    setPattern2Info(pattern2Info, true); // パターン２詳細情報
    if (activePattern === PatternType.Pattern1) {
      setMinXsize(pattern1Info.sizeX);
      setMinYsize(pattern1Info.sizeY);
    } else {
      setMinXsize(pattern2Info.sizeX);
      setMinYsize(pattern2Info.sizeY);
    }
  }, [rightFit, topFit, yRotate]);

  useImperativeHandle(
    ref,
    () => ({
      getSelectedEstimateData: () => {
        return selectedEstimateData;
      },
      getIQ3MaterialDetailInfo: () => {
        return detailData;
      },
      getQuantity: () => {
        return quantity;
      },
    }),
    [detailData]
  );

  const columns = [
    {
      id: '1',
      title: 'No',
      dataIndex: 'no',
      width: 50 + 'px',
      // render: (id, record, index) => index + 1,
    },
    {
      id: '2',
      title: '材料サイズ',
      dataIndex: 'materialSizeIq3Id',
      width: 100 + 'px',
      className: 'cm-a-center',
      render: (value) => materialSizeData?.filter((item) => item.id === value)?.[0]?.name,
    },
    {
      id: '3',
      title: '材料枚数',
      dataIndex: 'materialQuantity',
      width: 100 + 'px',
      className: 'cm-a-right',
    },
    {
      id: '4',
      title: '金額',
      dataIndex: 'price',
      width: 100 + 'px',
      className: 'cm-a-right',
      render: (value) => JPYs.format(value),
    },
    {
      id: '5',
      title: '歩留まり',
      dataIndex: 'yield',
      width: 100 + 'px',
      className: 'cm-a-right',
      render: (value) => Percent.format(value / 100),
    },
    {
      id: '6',
      title: 'パターン1',
      dataIndex: 'pattern1Rate',
      width: 100 + 'px',
      className: 'cm-a-right',
      render: (value) => (value === 0 ? '-' : Percent.format(value / 100)),
    },
    {
      id: '7',
      title: '配置数',
      dataIndex: 'pattern1Quantity',
      width: 100 + 'px',
      className: 'cm-a-right',
      render: (value) => (value === 0 ? '-' : value),
    },
    {
      id: '8',
      title: 'パターン2',
      dataIndex: 'pattern2Rate',
      width: 100 + 'px',
      className: 'cm-a-right',
      render: (value) => (value === 0 ? '-' : Percent.format(value / 100)),
    },
    {
      id: '9',
      title: '配置数',
      dataIndex: 'pattern2Quantity',
      width: 100 + 'px',
      className: 'cm-a-right',
      render: (value) => (value === 0 ? '-' : value),
    },
    {
      id: '10',
      title: '最大歩留まり',
      dataIndex: 'maxYield',
      width: 100 + 'px',
      className: 'cm-a-right',
      render: (value) => Percent.format(value / 100),
    },
    {
      id: '11',
      title: '最大加工数',
      dataIndex: 'maxQuantity',
      width: 100 + 'px',
      className: 'cm-a-right',
    },
    {
      id: '12',
      title: '',
      dataIndex: 'no',
      hidden: true,
    },
  ]?.filter((item) => !item.hidden);

  // 材料詳細画面に値を変更した場合、
  const onChangeMaterialDetatilInfo = () => {
    if (tempDetailData === undefined) return;
    let calculatedIq3Data = calculateMaterialDetailSize(tempDetailData, true); // 材料リストの計算
    let selectedSizeData = calculatedIq3Data?.materialDetailSizes?.find(
      (item) => item.materialSizeIq3Id === detailP1MaterialSizeId
    );
    displayMaterialDetailInfo(selectedSizeData, calculatedIq3Data?.materialDetailPatterns, true);
  };

  // 材料サイズマスタ情報を得る
  const getMaterialSizeData = (selectedEstimateData) => {
    let materialSizeData = [];
    if (selectedEstimateData) {
      const estimateParameters = selectedEstimateData?.estimateProducts?.calcParameters?.parameters;
      materialSizeData = estimateParameters?.materialSizeIq3;
      materialSizeData = materialSizeData
        ?.filter((item) => item.info.isUsed)
        ?.map((item) => {
          return { id: item.id, name: item.name, sizeX: item.info.sizeX, sizeY: item.info.sizeY };
        });
    }
    return materialSizeData;
  };

  // 情報の値
  const setMaterialDetailInfo = (selectedIq3Info) => {
    setPartName(selectedIq3Info?.name);
    setQuantity(props?.getParentQuantity());
    setPartCount(selectedIq3Info?.partCount);
    setXSize(Number(selectedIq3Info?.partSizeX));
    setYSize(Number(selectedIq3Info?.partSizeY));
    setMaterialId(selectedIq3Info?.materialIq3Id);
    setMaterialTypeId(selectedIq3Info?.materialTypeIq3Id);
    setThickness(selectedIq3Info?.thickness);
    setSurfaceId(selectedIq3Info?.materialSurfaceIq3Id);
    setWeight(selectedIq3Info?.weight);
    setSurfaceArea(toDecimal(selectedIq3Info?.totalSurfaceArea, 2));
    getCalculateInfo(selectedIq3Info);
  };

  // 計算に利用する情報
  const getCalculateInfo = (selectedIq3Info) => {
    // ブランクの場合
    let blankProcess = getProcessRecord(
      ServiceClass.SheetMetal,
      [WorkType.SmBlankLaser, WorkType.SmBlankPunch, WorkType.SmBlankCombi], // レーザー、パンチ、複合機
      props?.processMasterData
    );
    // 板金工程選択にブランクを使用している場合、
    let usedBlankProcess = selectedIq3Info?.processSelect?.details?.find(
      (selectItem) =>
        selectItem.isUsedForCalc && blankProcess?.findIndex((item) => item.id === selectItem.processId) > -1
    );
    // 使用している装置の情報を取得する
    let usedDeviceInfo = props?.deviceMasterData?.find((device) => device.id === usedBlankProcess?.deviceId);
    let grabbingX;
    let grabbingY;
    let pierWidthX;
    let pierWidthY;
    let worlRangeX;
    let worlRangeY;
    // 装置がある場合、
    if (usedDeviceInfo) {
      // 装置から材料配置情報
      let materialArrange = usedDeviceInfo?.details?.materialArrange;
      grabbingX = materialArrange?.graspAllowanceX;
      grabbingY = materialArrange?.graspAllowanceY;
      pierWidthX = materialArrange?.xWidth;
      pierWidthY = materialArrange?.yWidth;
      worlRangeX = materialArrange?.workRangeX;
      worlRangeY = materialArrange?.workRangeY;
    } else {
      grabbingX = selectedIq3Info?.grabbingX;
      grabbingY = selectedIq3Info?.grabbingY;
      pierWidthX = selectedIq3Info?.pierWidthX;
      pierWidthY = selectedIq3Info?.pierWidthY;
      worlRangeX = selectedIq3Info?.worlRangeX;
      worlRangeY = selectedIq3Info?.worlRangeY;
    }
    // 掴み代X、掴み代Y（ブランクの場合、装置から材料配置情報を取得する。）
    setXGrabAllowance(toDecimal(grabbingX));
    setYGrabAllowance(toDecimal(grabbingY));
    // 桟幅X、桟幅Y（ブランクの場合、装置から材料配置情報を取得する。）
    setXWidthOfPier(toDecimal(pierWidthX));
    setYWidthOfPier(toDecimal(pierWidthY));
    // 加工範囲X、加工範囲Y（ブランクの場合、装置から材料配置情報を取得する。）
    setXMachiningRange(worlRangeX);
    setYMachiningRange(worlRangeY);

    // 最小Xサイズ計算（部品サイズ　＋　掴み代　＋　桟幅）
    setMinXsize(Number(selectedIq3Info?.partSizeX) + grabbingX + pierWidthX);
    // 最小Yサイズ計算（部品サイズ　＋　掴み代　＋　桟幅）
    setMinYsize(Number(selectedIq3Info?.partSizeY) + grabbingY + pierWidthY);
  };

  // 計算結果の値
  const setSummarizeInfo = (totalInfo) => {
    // setSummarizeTotalAmount(JPYs.format(totalInfo.price));
    let totalPrice = totalInfo.price;
    setSummarizeTotalAmount(totalPrice);
    setSummarizeYield(Percent.format(totalInfo.yield / 100));
    setSummarizeNumOfMaterial(totalInfo.materialQuantity);

    // 材料パラメータ情報
    let materialInfo = materialListData?.find((item) => item.id === materialId);
    setSummarizePrice(JPYs.format(materialInfo ? materialInfo.info.unitPrice : 0));

    // 材料表面パラメータ情報
    let surfaceInfo = materialSurfaceListData?.find((item) => item.id === surfaceId);
    if (surfaceInfo?.info?.unit === MaterialSurfaceBillingUnit.SquareMeter) {
      setSummarizeSurface(JPYs.format(surfaceInfo ? surfaceInfo.info.price : 0));
      setSummarizeWeight(JPYs.format(0));
    } else {
      setSummarizeSurface(JPYs.format(0));
      setSummarizeWeight(JPYs.format(surfaceInfo ? surfaceInfo.info.price : 0));
    }
    let unitPrice = totalInfo?.price / Number(detailData?.partCount * props.getParentQuantity()); //　材料原価/員数＊数量
    setSummarizeMaterialPrice(unitPrice); //　材料原価/員数＊数量
    if (detailData?.materialCostUnitPrice !== 0 && detailData?.costUnitPrice !== 0) {
      setSummarizeCostRate(Percent.format((detailData?.materialCostUnitPrice / detailData?.costUnitPrice) * 100)); // 材料原価/原価*100
    } else {
      setSummarizeCostRate(Percent.format(0 * 100));
    }
    // setSummarizeScrapAmount(JPYs.format(500));
    props?.getMaterialPrice(totalPrice, unitPrice);
  };

  // パターン1の値
  const setPattern1Info = (pattern1Info, isDetail = false) => {
    if (pattern1Info === undefined) return;
    if (isDetail) {
      setDetailP1TotalAmount(JPYs.format(Number.isFinite(pattern1Info?.totalPrice) ? pattern1Info?.totalPrice : 0));
      setDetailP1Yield(Percent.format(pattern1Info?.yield / 100));
      setDetailP1NumOfMaterial(Number.isInteger(pattern1Info?.materialQuantity) ? pattern1Info?.materialQuantity : 0);
      setDetailP1MaterialSizeId(pattern1Info?.materialSizeIq3Id);
      setDetailP1XSize(pattern1Info?.sizeX);
      setDetailP1YSize(pattern1Info?.sizeY);
    } else {
      setPattern1TotalAmount(JPYs.format(Number.isFinite(pattern1Info?.totalPrice) ? pattern1Info?.totalPrice : 0));
      setPattern1Yield(Percent.format(pattern1Info?.yield / 100));
      setPattern1NumOfMaterial(Number.isInteger(pattern1Info?.materialQuantity) ? pattern1Info?.materialQuantity : 0);
      setPattern1MaterialSizeId(pattern1Info?.materialSizeIq3Id);
      setPattern1XSize(pattern1Info?.sizeX);
      setPattern1YSize(pattern1Info?.sizeY);
    }
  };

  // パターン2の値
  const setPattern2Info = (pattern2Info, isDetail = false) => {
    if (pattern2Info === undefined) return;
    if (isDetail) {
      setDetailP2TotalAmount(JPYs.format(Number.isFinite(pattern2Info?.totalPrice) ? pattern2Info?.totalPrice : 0));
      setDetailP2Yield(Percent.format(pattern2Info?.yield / 100));
      setDetailP2NumOfMaterial(Number.isInteger(pattern2Info?.materialQuantity) ? pattern2Info?.materialQuantity : 0);
      setDetailP2MaterialSizeId(pattern2Info?.materialSizeIq3Id);
      setDetailP2XSize(pattern2Info?.sizeX);
      setDetailP2YSize(pattern2Info?.sizeY);
    } else {
      setPattern2TotalAmount(JPYs.format(Number.isFinite(pattern2Info?.totalPrice) ? pattern2Info?.totalPrice : 0));
      setPattern2Yield(Percent.format(pattern2Info?.yield / 100));
      setPattern2NumOfMaterial(Number.isInteger(pattern2Info?.materialQuantity) ? pattern2Info?.materialQuantity : 0);
      setPattern2MaterialSizeId(pattern2Info?.materialSizeIq3Id);
      setPattern2XSize(pattern2Info?.sizeX);
      setPattern2YSize(pattern2Info?.sizeY);
    }
  };

  // ヘッダーの値
  const setDetailHeaderInfo = () => {
    setDetailMaterialTypeId(materialTypeId);
    setDetailMaterialId(materialId);
    setDetailThickness(thickness);
    setDetailSurfaceId(surfaceId);
    setDetailXGrabAllowance(xGrabAllowance);
    setDetailYGrabAllowance(yGrabAllowance);
    setDetailXWidthOfPier(xWidthOfPier);
    setDetailYWidthOfPier(yWidthOfPier);
  };

  // パターン1の詳細情報
  const callPattern1Detail = () => {
    setTempDetailData(JSON.parse(JSON.stringify({ ...detailData })));
    setIsPatternDetail(true);
    setChildTitle('詳細');
    setActivePattern(PatternType.Pattern1);
    let selectedMaterialSize = materialDetailSizeData?.find(
      (item) =>
        item.materialSizeIq3Id ===
        (activePattern === PatternType.Pattern1 ? pattern1MaterialSizeId : pattern2MaterialSizeId)
    );
    // 材料詳細の画像設定情報を取得する
    setYRotate(selectedMaterialSize?.setting?.yRotate);
    setYSetting(selectedMaterialSize?.setting?.ySetting);
    setRightFit(selectedMaterialSize?.setting?.rightFit);
    setTopFit(selectedMaterialSize?.setting?.topFit);

    setDetailHeaderInfo();
    let pattern1Info = getPatternInfo(materialDetailPatternData, pattern1MaterialSizeId, PatternType.Pattern1);
    setPattern1Info(pattern1Info, true);
    setPattern2Info(getPatternInfo(materialDetailPatternData, pattern2MaterialSizeId, PatternType.Pattern2), true);
    // 選択中材料のXサイズ
    setSelectedMaterialXsize(pattern1Info?.sizeX);
    // 選択中材料のYサイズ
    setSelectedMaterialYsize(pattern1Info?.sizeY);
    settingCalPatternInfo(
      PatternType.Pattern1,
      pattern1MaterialSizeId,
      pattern1Info?.sizeX,
      pattern1Info?.sizeY,
      selectedMaterialSize?.setting?.yRotate,
      xWidthOfPier,
      yWidthOfPier,
      xGrabAllowance,
      yGrabAllowance,
      true
    );
  };

  // パターン2の詳細情報
  const callPattern2Detail = () => {
    setTempDetailData(JSON.parse(JSON.stringify({ ...detailData })));
    setIsPatternDetail(true);
    setChildTitle('詳細');
    setActivePattern(PatternType.Pattern2);
    let selectedMaterialSize = materialDetailSizeData?.find(
      (item) =>
        item.materialSizeIq3Id ===
        (activePattern === PatternType.Pattern1 ? pattern1MaterialSizeId : pattern2MaterialSizeId)
    );
    // 材料詳細の画像設定情報を取得する
    setYRotate(selectedMaterialSize?.setting?.yRotate);
    setYSetting(selectedMaterialSize?.setting?.ySetting);
    setRightFit(selectedMaterialSize?.setting?.rightFit);
    setTopFit(selectedMaterialSize?.setting?.topFit);

    setDetailHeaderInfo();
    let pattern1Info = getPatternInfo(materialDetailPatternData, pattern1MaterialSizeId, PatternType.Pattern1);
    setPattern1Info(pattern1Info, true);
    let pattern2Info = getPatternInfo(materialDetailPatternData, pattern2MaterialSizeId, PatternType.Pattern2);
    setPattern2Info(pattern2Info, true);
    // 選択中材料のXサイズ
    setSelectedMaterialXsize(pattern1Info?.sizeX);
    // 選択中材料のYサイズ
    setSelectedMaterialYsize(pattern1Info?.sizeY);
    settingCalPatternInfo(
      PatternType.Pattern2,
      pattern2MaterialSizeId,
      pattern1Info?.sizeX,
      pattern1Info?.sizeY,
      selectedMaterialSize?.setting?.yRotate,
      xWidthOfPier,
      yWidthOfPier,
      xGrabAllowance,
      yGrabAllowance,
      true
    );
  };

  // 材料リストの計算
  const calculateMaterialDetailSize = (selectedIq3Info, isDetail = false, isAutoFree = false) => {
    let materialPatternLists = [];
    let materialDetailSizes = [];
    if (isDetail) {
      materialDetailSizes = selectedIq3Info?.materialDetailSizes?.filter(
        (item) => item.materialSizeIq3Id === detailP1MaterialSizeId
      );
    } else {
      materialDetailSizes = selectedIq3Info?.materialDetailSizes;
    }
    // freeの場合、
    if (materialDetailSizes?.length === 0 && detailP1MaterialSizeId === getMaterialSizeFreeId()) {
      materialDetailSizes = materialSizeData
        ?.filter((item) => item.name === 'Free')
        ?.map((item) => {
          let data = {
            no: 0,
            materialSizeIq3Id: item.id,
            sizeName: item.name,
            materialQuantity: 0,
            price: 0,
            yield: 0,
            pattern1Rate: 0,
            pattern1Quantity: 0,
            pattern2Rate: 0,
            pattern2Quantity: 0,
            maxYield: 0,
            maxQuantity: 0,
            setting: { ySetting: true, yRotate: false, topFit: false, rightFit: false },
          };
          return data;
        });
    }
    materialDetailSizes?.map((sizeInfo) => {
      let info = { ...sizeInfo };
      let xSize = 0;
      let ySize = 0;
      let xPart = 0;
      let yPart = 0;
      let totalNoOfMaterial = 0;
      let materialPrice = 0; // 合計金額
      let pattern1Price = 0; // パターン１金額
      let pattern2Price = 0; // パターン２金額
      let yiedlRate = 0;
      let pattern1Rate = 0;
      let pattern2Rate = 0;

      let totalPlacement = 0; // 合計配置数
      let pattern1Placement = 0; // パターン１配置数
      let pattern2Placement = 0; // パターン２配置数
      let pattern1NoOfMaterial = 0; // パターン１枚数
      let pattern2NoOfMaterial = 0; // パターン２枚数

      // 材料パラメータ情報
      let materialInfo = materialListData?.find((item) => item.id === selectedIq3Info.materialIq3Id);

      let materialX = '';
      let materialY = '';
      // 材料枚数の計算
      if (isDetail) {
        // Freeの場合、
        if (isAutoFree) {
          // Fit押下でFreeになる場合、
          materialX = selectedMaterialXsize;
          materialY = selectedMaterialYsize;
        } else {
          // 手入力でFreeになる場合、
          // materialX = activePattern === PatternType.Pattern1 ? detailP1XSize : detailP2XSize;
          // materialY = activePattern === PatternType.Pattern1 ? detailP1YSize : detailP2YSize;
          materialX = detailP1XSize;
          materialY = detailP1YSize;
        }
        settingCalPatternInfo(
          activePattern,
          info.materialSizeIq3Id,
          info.sizeName === 'Free' ? materialX : '',
          info.sizeName === 'Free' ? materialY : '',
          yRotate,
          detailXWidthOfPier,
          detailYWidthOfPier,
          detailXGrabAllowance,
          detailYGrabAllowance,
          isDetail
        );
      } else {
        settingCalPatternInfo(
          activePattern,
          info.materialSizeIq3Id,
          '',
          '',
          yRotate,
          xWidthOfPier,
          yWidthOfPier,
          xGrabAllowance,
          yGrabAllowance,
          isDetail
        );
      }
      xSize = Number(toDrawInfo?.materialInfo?.mSheetX);
      ySize = Number(toDrawInfo?.materialInfo?.mSheetY);
      xPart = Number(toDrawInfo?.partInfo?.mPartX);
      yPart = Number(toDrawInfo?.partInfo?.mPartY);
      // 材料・掴み代・部品矩形・桟幅の配置
      let partCntInfo = draw(
        toDrawInfo?.partInfo,
        toDrawInfo?.settingInfo,
        toDrawInfo?.materialInfo,
        true,
        ySetting,
        rightFit,
        topFit,
        ''
      );
      if (partCntInfo === undefined) return;

      let p1MaterialYSize = ySize;
      let p1MaterialXSize = xSize;
      let p2MaterialYSize = ySize;
      let p2MaterialXSize = xSize;
      // Y方向をFixの場合、
      if (topFit) {
        // 製品の矩形Y
        let partY = toDrawInfo?.partInfo.mPartY + toDrawInfo?.settingInfo.mMarginY;
        p1MaterialYSize = partY * partCntInfo?.p1PartCntY + toDrawInfo?.settingInfo.mClampdzY;
        p2MaterialYSize = partY * partCntInfo?.p2PartCntY + toDrawInfo?.settingInfo.mClampdzY;
      }
      // 右方向をFixの場合、
      if (rightFit) {
        // 製品の矩形X
        let partX = toDrawInfo?.partInfo.mPartX + toDrawInfo?.settingInfo.mMarginX;
        p1MaterialXSize = partX * partCntInfo?.p1PartCntX + toDrawInfo?.settingInfo.mClampdzX;
        p2MaterialXSize = partX * partCntInfo?.p2PartCntX + toDrawInfo?.settingInfo.mClampdzX;
      }

      // 最大加工数
      let maxNoOfPlace = partCntInfo.maxNoOfPlace < 0 ? 0 : partCntInfo.maxNoOfPlace;
      let maxYieldRate = 0;

      // 最大数
      let maxPlacement = Number(selectedIq3Info.partCount * props.getParentQuantity()); //　員数＊数量

      // パターン１枚数
      pattern1NoOfMaterial = partCntInfo.p1NoOfMaterial;
      // パターン１配置数
      pattern1Placement = partCntInfo.p1NoOfPlace * pattern1NoOfMaterial;

      let isExistPattern2 = true;
      if (maxPlacement == pattern1Placement * pattern1NoOfMaterial && maxPlacement == pattern1NoOfMaterial) {
        isExistPattern2 = false;
      } else if (maxPlacement <= pattern1Placement * pattern1NoOfMaterial && pattern1NoOfMaterial == 1) {
        isExistPattern2 = false;
      }

      if (isExistPattern2) {
        pattern2NoOfMaterial = info.sizeName === 'Other' ? 0 : 1;
        totalNoOfMaterial = pattern1NoOfMaterial + pattern2NoOfMaterial;
      } // パターン1の材料枚数＋パターン2の材料枚数
      else {
        totalNoOfMaterial = pattern1NoOfMaterial;
        pattern2NoOfMaterial = 0;
      }

      // 材料費の計算=材料X(mm)*材料Y(mm)*板厚(mm)*比重(kg/c㎥)/1000000*重量単価(¥/kg)*材料枚数
      pattern1Price =
        p1MaterialXSize *
        p1MaterialYSize *
        materialInfo?.info?.thick *
        (materialInfo?.info?.gravity / 1000000) *
        materialInfo?.info?.unitPrice *
        pattern1NoOfMaterial;

      // 材料費の計算=材料X(mm)*材料Y(mm)*板厚(mm)*比重(kg/c㎥)/1000000*重量単価(¥/kg)*材料枚数
      pattern2Price =
        p2MaterialXSize *
        p2MaterialYSize *
        materialInfo?.info?.thick *
        (materialInfo?.info?.gravity / 1000000) *
        materialInfo?.info?.unitPrice *
        pattern2NoOfMaterial;

      // 合計金額
      materialPrice = pattern1Price + pattern2Price;

      if (isExistPattern2) {
        pattern2Placement = info.sizeName === 'Other' ? 0 : partCntInfo.p2NoOfPlace;
      }
      totalPlacement = pattern1Placement + pattern2Placement; // パターン1の配置数＋パターン2の配置数

      // Otherの材料サイズの計算
      if (info.sizeName === 'Other') {
        // 歩留の計算=矩形X*矩形Y/材料X*材料Y*100
        yiedlRate = ((xPart * yPart) / (p1MaterialXSize * p1MaterialYSize)) * 100;
        pattern1Rate = yiedlRate;
        maxYieldRate = yiedlRate;
        pattern2Rate = 0;
      } else {
        // 材料X*材料Y*材料枚数
        let p1MaterialSize = p1MaterialXSize * p1MaterialYSize * pattern1NoOfMaterial;
        let p2MaterialSize = p2MaterialXSize * p2MaterialYSize * pattern2NoOfMaterial;
        // 矩形X*矩形Y*配置数/材料X*材料Y*材料枚数*100
        yiedlRate = ((xPart * yPart * totalPlacement) / (p1MaterialSize + p2MaterialSize)) * 100;
        pattern1Rate = ((xPart * yPart * pattern1Placement) / p1MaterialSize) * 100;
        if (isExistPattern2) {
          pattern2Rate = ((xPart * yPart * pattern2Placement) / p2MaterialSize) * 100;
        } else {
          pattern2Rate = 0;
        }
        maxYieldRate = ((xPart * yPart * maxNoOfPlace) / (xSize * ySize * totalNoOfMaterial)) * 100;
      }
      if (sizeInfo) {
        sizeInfo.materialQuantity = Number.isInteger(totalNoOfMaterial) ? totalNoOfMaterial : 0;
        sizeInfo.price = Number.isFinite(materialPrice) ? materialPrice : 0;
        sizeInfo.yield = yiedlRate;
        sizeInfo.pattern1Rate = pattern1Rate;
        sizeInfo.pattern1Quantity = pattern1Placement;
        sizeInfo.pattern2Rate = pattern2Rate;
        sizeInfo.pattern2Quantity = pattern2Placement;
        sizeInfo.maxYield = maxYieldRate;
        sizeInfo.maxQuantity = maxNoOfPlace;
        if (sizeInfo.setting) {
          sizeInfo.setting.ySetting = ySetting;
          sizeInfo.setting.yRotate = yRotate;
          sizeInfo.setting.topFit = topFit;
          sizeInfo.setting.rightFit = rightFit;
        }
      }

      // 材料詳細パターン情報
      new Array(2).fill(null)?.map((_, index) => {
        let materialQuantity = index === 0 ? pattern1NoOfMaterial : pattern2NoOfMaterial;
        let totalPrice = index === 0 ? pattern1Price : pattern2Price;
        let rate = index === 0 ? pattern1Rate : pattern2Rate;
        materialPatternLists?.push({
          patternType: index === 0 ? PatternType.Pattern1 : PatternType.Pattern2,
          totalPrice: totalPrice ? totalPrice : 0,
          yield: rate ? rate : 0,
          materialQuantity: materialQuantity ? materialQuantity : 0,
          materialSizeIq3Id: info?.materialSizeIq3Id,
          sizeName: info?.sizeName,
          sizeX: rightFit ? (index === 0 ? p1MaterialXSize : p2MaterialXSize) : xSize,
          sizeY: topFit ? (index === 0 ? p1MaterialYSize : p2MaterialYSize) : ySize,
          // sizeX: xSize,
          // sizeY: ySize,
        });
      });
    });
    if (isDetail) {
      materialDetailSizes?.map((item) => {
        let index = selectedIq3Info.materialDetailSizes?.findIndex(
          (list) => list.materialSizeIq3Id === item.materialSizeIq3Id
        );
        if (index > -1) {
          selectedIq3Info.materialDetailSizes[index] = item;
        } else {
          // Freeサイズの場合、サイズ一覧の先頭行に追加する
          const index = 0;
          selectedIq3Info.materialDetailSizes = [
            ...selectedIq3Info.materialDetailSizes.slice(0, index),
            item,
            ...selectedIq3Info.materialDetailSizes.slice(index),
          ];
        }
      }); // 材料サイズリスト情報

      materialPatternLists?.map((item) => {
        let index = selectedIq3Info.materialDetailPatterns?.findIndex(
          (list) => list.patternType === item.patternType && list.materialSizeIq3Id === item.materialSizeIq3Id
        );
        if (index > -1) {
          selectedIq3Info.materialDetailPatterns[index] = item;
        } else {
          // Freeサイズの場合、パターン詳細情報に追加する
          selectedIq3Info.materialDetailPatterns.push(item);
        }
      }); // 材料詳細パターン情報
    } else {
      selectedIq3Info.materialDetailPatterns = materialPatternLists; // 材料詳細パターン情報
    }

    return selectedIq3Info;
  };

  const settingCalPatternInfo = (
    activePatternInfo,
    selectedMaterialSizeId,
    materialX,
    materialY,
    yRotate,
    mMarginX,
    mMarginY,
    mClampdzX,
    mClampdzY,
    isDetail = false
  ) => {
    /** 部品情報 */
    let partInfo = {};
    // if (mPartInfo) {
    if (props.selectedIQ3DataDetail) {
      let mPartInfo = props.selectedIQ3DataDetail;
      partInfo = {
        mPartX: yRotate ? Number(mPartInfo.partSizeY) : Number(mPartInfo.partSizeX),
        mPartY: yRotate ? Number(mPartInfo.partSizeX) : Number(mPartInfo.partSizeY),
        partCnt: Number(mPartInfo.partCount),
        suryou: Number(props.getParentQuantity()),
        totalProcessSuryou: Number(mPartInfo.partCount * props.getParentQuantity()), //　員数＊数量
      };
      setPatternPartInfo(partInfo);
      setXSize(partInfo.mPartX);
      setYSize(partInfo.mPartY);
      if (isDetail) {
        setMinXsize(partInfo.mPartX + Number(detailXGrabAllowance) + Number(detailXWidthOfPier));
        setMinYsize(partInfo.mPartY + Number(detailYGrabAllowance) + Number(detailYWidthOfPier));
      } else {
        setMinXsize(partInfo.mPartX + Number(xGrabAllowance) + Number(xWidthOfPier));
        setMinYsize(partInfo.mPartY + Number(yGrabAllowance) + Number(yWidthOfPier));
      }
    }

    /** 材料情報 */
    let materialInfo = {};
    let sheetX = 0;
    let sheetY = 0;
    if (materialX && materialY) {
      sheetX = materialX;
      sheetY = materialY;
    } else {
      let selectedMaterialSize = selectedMaterialSizeId;
      let materialSizes = materialSizeData ? materialSizeData?.filter((item) => item.id === selectedMaterialSize) : [];
      if (materialSizes?.length > 0) {
        //材料サイズをOtherに変更した場合、部品矩形サイズ＋桟幅＋掴み代で決定
        if (materialSizes[0]?.sizeX == 0 || materialSizes[0]?.sizeY == 0 || materialSizes[0]?.name === 'Other') {
          setMaterialOtherSize(true);
          // /材料サイズX計算
          sheetX = Number(partInfo.mPartX) + Number(mMarginX) + Number(mClampdzX);
          // /材料サイズY計算
          sheetY = Number(partInfo.mPartY) + Number(mMarginY) + Number(mClampdzY);
        } else if (materialSizes[0]?.name === 'Free') {
          //Freeに変更前のサイズを設定
          // もし、変更前のサイズがＯｔｈｅｒの場合、部品矩形サイズ＋桟幅＋掴み代で決定
          if (materialOtherSize) {
            // /材料サイズX計算
            sheetX = Number(partInfo.mPartX) + Number(mMarginX) + Number(mClampdzX);
            // /材料サイズY計算
            sheetY = Number(partInfo.mPartY) + Number(mMarginY) + Number(mClampdzY);
          } else {
            sheetX = patternMaterialInfo.mSheetX;
            sheetY = patternMaterialInfo.mSheetY;
          }
        } else {
          setMaterialOtherSize(false);
          sheetX = Number(materialSizes[0]?.sizeX);
          sheetY = Number(materialSizes[0]?.sizeY);
        }
      }
    }
    materialInfo = {
      mSheetX: sheetX,
      mSheetY: sheetY,
    };
    setPatternMaterialInfo(materialInfo);

    if (activePatternInfo == PatternType.Pattern1) {
      if (isDetail) {
        setDetailP1MaterialSizeId(selectedMaterialSizeId);
        setDetailP1XSize(materialInfo.mSheetX);
        setDetailP1YSize(materialInfo.mSheetY);
      } else {
        setPattern1MaterialSizeId(selectedMaterialSizeId);
        setPattern1XSize(materialInfo.mSheetX);
        setPattern1YSize(materialInfo.mSheetY);
      }
    } else {
      if (isDetail) {
        setDetailP2MaterialSizeId(selectedMaterialSizeId);
        setDetailP2XSize(materialInfo.mSheetX);
        setDetailP2YSize(materialInfo.mSheetY);
      } else {
        setPattern2MaterialSizeId(selectedMaterialSizeId);
        setPattern2XSize(materialInfo.mSheetX);
        setPattern2YSize(materialInfo.mSheetY);
      }
    }

    toDrawInfo.partInfo = partInfo;
    if (isDetail) {
      toDrawInfo.settingInfo = {
        mClampdzX: Number(detailXGrabAllowance),
        mClampdzY: Number(detailYGrabAllowance),
        mMarginX: Number(detailXWidthOfPier),
        mMarginY: Number(detailYWidthOfPier),
      };
    } else {
      toDrawInfo.settingInfo = {
        mClampdzX: Number(xGrabAllowance),
        mClampdzY: Number(yGrabAllowance),
        mMarginX: Number(xWidthOfPier),
        mMarginY: Number(yWidthOfPier),
      };
    }
    toDrawInfo.materialInfo = materialInfo;
  };

  // スクラップ金額の詳細
  const scrapAmount = (
    <div>
      <p>
        スクラップ金額
        <br /> {JPYs.format(500)}
      </p>
    </div>
  );

  // idで名称を取得する
  const getNameById = (id, dataList) => {
    if (id && dataList) {
      let data = dataList?.filter((item) => item.id === id);
      return data?.length > 0 ? data[0]?.name : '';
    }
    return '';
  };

  const getAntInputBoxClassName = (id, mode = true) => {
    let classList = document.getElementById(id)?.classList;
    let className = '';
    if (classList?.length > 0)
      className =
        ' ' +
        Object.values(classList)?.filter(
          (v) => v !== 'ant-input' && v !== 'input-editable' && v !== 'input-non-editable'
        );
    if (mode) {
      return 'ant-input input-editable' + className;
    } else {
      return 'input-non-editable';
    }
  };

  // パターン詳細情報を更新
  const patternUpdateOK = () => {
    setUpdateConfirm(true);
  };

  // パターン詳細情報をキャンセル
  const patternUpdateCancel = (e) => {
    setCancelConfirm(true);
  };

  // 確認ポップアップのOKボタン押下
  const confirmOk = async (e) => {
    if (isPatternDetail) {
      setUpdateConfirm(false);
      setIsPatternDetail(false);
      setDetailData(tempDetailData);
      setMaterialDetailInfo(tempDetailData); // 材料詳細情報
      setMaterialDetailSizeData(tempDetailData?.materialDetailSizes); // 材料リスト
      setMaterialDetailPatternData(tempDetailData?.materialDetailPatterns); // 材料詳細パターン情報
      let selectedRow = tempDetailData?.materialDetailSizes?.find(
        (item) => item.materialSizeIq3Id === detailP1MaterialSizeId
      );
      setSelectedRowNo(selectedRow?.no);
      calMaterialXYSize(selectedRow.materialSizeIq3Id);
      displayMaterialDetailInfo(selectedRow, tempDetailData?.materialDetailPatterns);
    }
  };

  // 確認ポップアップのCancelボタン押下
  const confirmCancel = () => {
    setUpdateConfirm(false);
  };

  // 破壊確認ポップアップのOKボタン押下
  const cancelConfirmOK = () => {
    if (isPatternDetail) {
      setIsPatternDetail(false);
    }
    setCancelConfirm(false);
  };

  // 破壊確認ポップアップのCancelボタン押下
  const cancelConfirmCancel = () => {
    setCancelConfirm(false);
  };

  // パターン詳細情報の切り替え
  const changePattern = () => {
    if (activePattern === PatternType.Pattern1) {
      setActivePattern(PatternType.Pattern2);
      if (topFit || rightFit) {
        setMinXsize(detailP2XSize);
        setMinYsize(detailP2YSize);
      }
    } else {
      setActivePattern(PatternType.Pattern1);
      if (topFit || rightFit) {
        setMinXsize(detailP1XSize);
        setMinYsize(detailP1YSize);
      }
    }
  };

  // 計算結果パネル開く・閉じる制御
  const openCalResultPanel = () => {
    if (isCalResult == true) {
      setIsCalResult(false);
    } else {
      setIsCalResult(true);
    }
  };

  // パターン１パネル開く・閉じる制御
  const openPattern1Panel = () => {
    if (isPattern1 == true) {
      setIsPattern1(false);
    } else {
      setIsPattern1(true);
    }
  };
  const openPattern1ImgPanel = () => {
    if (isPattern1Img == true) {
      setIsPattern1Img(false);
    } else {
      setIsPattern1Img(true);
    }
  };

  // パターン２計算結果パネル開く・閉じる制御
  const openPattern2Panel = () => {
    if (isPattern2 == true) {
      setIsPattern2(false);
    } else {
      setIsPattern2(true);
    }
  };
  const openPattern2ImgPanel = () => {
    if (isPattern2Img == true) {
      setIsPattern2Img(false);
    } else {
      setIsPattern2Img(true);
    }
  };

  // 材質を変更した時、材料、板厚、表面保護の情報をフィルターする
  const onChangeMaterialType = (materialTypeId, isDetail = false) => {
    isDetail ? setDetailMaterialTypeId(materialTypeId) : setMaterialTypeId(materialTypeId);
    // 材料情報
    let materialList = materialListData?.filter(
      (item) => item.info.isUsed && item.materialTypeIq3Id === materialTypeId
    );
    let materialId = 0;
    let materialSurfaceId = 0;
    if (materialList?.length > 0) {
      materialId = materialList[0]?.id;
    }
    let materialName = getMaterialNameById(materialId, materialListData);
    if (isDetail) {
      setDetailThickness(materialId);
      setDetailMaterialId(materialId);
      tempDetailData['thickness'] = materialId;
      tempDetailData['materialIq3Id'] = materialId;
      tempDetailData['materialName'] = materialName;
    } else {
      setThickness(materialId); // 板厚
      setMaterialId(materialId);
      detailData['thickness'] = materialId;
      detailData['materialIq3Id'] = materialId;
      detailData['materialName'] = materialName;
    }

    // 材料表面情報
    let materialSurfaceList = materialSurfaceListData?.filter(
      (item) => item.info.isUsed && item.materialTypeIq3Id === materialTypeId
    );
    if (materialSurfaceList?.length > 0) {
      materialSurfaceId = materialSurfaceList[0]?.id;
    }
    let surfaceName = getMaterialSurfaceById(materialSurfaceId, materialSurfaceListData);
    if (isDetail) {
      setDetailSurfaceId(materialSurfaceId);
      tempDetailData['materialSurfaceIq3Id'] = materialSurfaceId;
      tempDetailData['materialSurfaceName'] = surfaceName;
    } else {
      setSurfaceId(materialSurfaceId);
      detailData['materialSurfaceIq3Id'] = materialSurfaceId;
      detailData['materialSurfaceName'] = surfaceName;
    }
  };

  // 工程マスタのレコードIDを取得する
  const getProcessRecord = (classId, processTypes, mstInfo) => {
    // 選択された、種別と工程より工程マスタレコードIDを得る
    let mstRecordInfo = [...mstInfo]?.filter(
      (item) => item?.class === classId && processTypes?.findIndex((workType) => workType === item?.workType) > -1
    );
    return mstRecordInfo;
  };

  // 入力項目情報
  const inputFormItem = (
    <>
      <div style={{ width: '100%' }}>
        <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row">
          <Col span={24} className="deli-col">
            <div className="cm-a-center">
              <label style={{ marginLeft: 0 }}>入力項目(共通)</label>
            </div>
          </Col>
        </Row>
        <div>
          <Row>
            <Col span={24}>
              <RTable className="iq3material-detail">
                <thead>
                  <tr>
                    <th width={'18%'}>材質</th>
                    <th width={'18%'}>材料名称</th>
                    <th width={'18%'}>板厚</th>
                    <th width={'18%'}>表面保護</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {/* 材質 */}
                    <td>
                      {editMode ? (
                        <>
                          <Select
                            value={detailMaterialTypeId ? detailMaterialTypeId : ''}
                            onChange={(e) => {
                              onChangeMaterialType(e, true);
                              let name = getMaterialTypeById(e, materialTypeListData);
                              onUpdateDetail('materialTypeName', name, tempDetailData);
                              onUpdateDetail('materialTypeIq3Id', e, tempDetailData);
                            }}
                            style={{ pointerEvents: editMode ? 'auto' : 'none', width: '100%' }}
                          >
                            {materialTypeListData
                              ?.filter(
                                (item) =>
                                  materialListData?.findIndex((mItem) => mItem.materialTypeIq3Id === item.id) > -1
                              )
                              ?.map((item, index) => (
                                <Select.Option key={index} value={item.id}>
                                  {item.name}
                                </Select.Option>
                              ))}
                          </Select>
                        </>
                      ) : (
                        <>{getNameById(detailMaterialTypeId, materialTypeListData)}</>
                      )}
                    </td>
                    {/* 材料名称 */}
                    <td>
                      {editMode ? (
                        <>
                          <Select
                            value={detailMaterialId ? detailMaterialId : ''}
                            onChange={(e) => {
                              setDetailMaterialId(e);
                              setDetailThickness(e);
                              let name = getMaterialNameById(e, materialListData);
                              onUpdateDetail('materialName', name, tempDetailData);
                              onUpdateDetail('materialIq3Id', e, tempDetailData);
                              onUpdateDetail('thickness', e, tempDetailData);
                            }}
                            style={{ pointerEvents: editMode ? 'auto' : 'none', width: '100%' }}
                          >
                            {materialListData
                              ?.filter((item) => item.info.isUsed && item.materialTypeIq3Id === detailMaterialTypeId)
                              ?.map((item, index) => (
                                <Select.Option key={index} value={item.id}>
                                  {item.name}
                                </Select.Option>
                              ))}
                          </Select>
                        </>
                      ) : (
                        <>{getNameById(detailMaterialId, materialListData)}</>
                      )}
                    </td>
                    {/* 板厚 */}
                    <td>
                      {editMode ? (
                        <>
                          <Select
                            value={detailThickness ? detailThickness : ''}
                            onChange={(e) => {
                              setDetailThickness(e);
                              setDetailMaterialId(e);
                              let name = getMaterialNameById(e, materialListData);
                              onUpdateDetail('thickness', e, tempDetailData);
                              onUpdateDetail('materialName', name, tempDetailData);
                              onUpdateDetail('materialIq3Id', e, tempDetailData);
                            }}
                            style={{ pointerEvents: editMode ? 'auto' : 'none', width: '100%' }}
                          >
                            {materialListData
                              ?.filter((item) => item.info.isUsed && item.materialTypeIq3Id === detailMaterialTypeId)
                              ?.map((item, index) => (
                                <Select.Option key={index} value={item.id}>
                                  {item.info.thick}
                                </Select.Option>
                              ))}
                          </Select>
                        </>
                      ) : (
                        <>{getNameById(detailThickness, materialListData)}</>
                      )}
                    </td>
                    {/* 表面保護 */}
                    <td>
                      {editMode ? (
                        <>
                          <Select
                            value={detailSurfaceId ? detailSurfaceId : ''}
                            onChange={(e) => {
                              setDetailSurfaceId(e);
                              let name = getMaterialSurfaceById(e, materialSurfaceListData);
                              onUpdateDetail('materialSurfaceName', name, tempDetailData);
                              onUpdateDetail('materialSurfaceIq3Id', e, tempDetailData);
                            }}
                            style={{ pointerEvents: editMode ? 'auto' : 'none', width: '100%' }}
                          >
                            {materialSurfaceListData
                              ?.filter((item) => item.info.isUsed && item.materialTypeIq3Id === detailMaterialTypeId)
                              ?.map((item, index) => (
                                <Select.Option key={index} value={item.id}>
                                  {item.name}
                                </Select.Option>
                              ))}
                          </Select>
                        </>
                      ) : (
                        <>{getNameById(detailSurfaceId, materialSurfaceListData)}</>
                      )}
                    </td>
                  </tr>
                </tbody>
                <thead>
                  <tr>
                    <th width={'18%'}>掴み代X</th>
                    <th width={'18%'}>掴み代Y</th>
                    <th width={'18%'}>桟幅X</th>
                    <th width={'18%'}>桟幅Y</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {/* 掴み代X */}
                    <td>
                      <Input
                        name="detailXGrabAllowance"
                        id="detailXGrabAllowance"
                        value={detailXGrabAllowance}
                        onChange={(e) => {
                          let xGrabAllowanceVal =
                            Number(e.target.value) > selectedMaterialXsize
                              ? Number(detailXGrabAllowance)
                              : Number(e.target.value);
                          setDetailXGrabAllowance(xGrabAllowanceVal);
                          onUpdateDetail('grabbingX', xGrabAllowanceVal, tempDetailData);
                          //　最小Xサイズ計算（部品サイズ　＋　掴み代　＋　桟幅）
                          setMinXsize(xSize + Number(xGrabAllowanceVal) + Number(detailXWidthOfPier));
                        }}
                        className={'input-editable'}
                        style={{ pointerEvents: editMode ? 'auto' : 'none', textAlign: 'center' }}
                      />
                    </td>
                    {/* 掴み代Y */}
                    <td>
                      <Input
                        name="detailYGrabAllowance"
                        id="detailYGrabAllowance"
                        value={detailYGrabAllowance}
                        onChange={(e) => {
                          let yGrabAllowanceVal =
                            Number(e.target.value) > selectedMaterialYsize
                              ? Number(detailYGrabAllowance)
                              : Number(e.target.value);
                          setDetailYGrabAllowance(e.target.value);
                          onUpdateDetail('grabbingY', e.target.value, tempDetailData);
                          // 最小Yサイズ計算（部品サイズ　＋　掴み代　＋　桟幅）
                          setMinYsize(Number(ySize) + Number(yGrabAllowanceVal) + Number(detailYWidthOfPier));
                        }}
                        className={'input-editable'}
                        style={{ pointerEvents: editMode ? 'auto' : 'none', textAlign: 'center' }}
                      />
                    </td>
                    {/* 桟幅X */}
                    <td>
                      <Input
                        name="detailXWidthOfPier"
                        id="detailXWidthOfPier"
                        value={detailXWidthOfPier}
                        onChange={(e) => {
                          let xWidthOfPierVal =
                            Number(e.target.value) > selectedMaterialXsize
                              ? Number(detailXWidthOfPier)
                              : Number(e.target.value);
                          setDetailXWidthOfPier(xWidthOfPierVal);
                          onUpdateDetail('pierWidthX', xWidthOfPierVal, tempDetailData);
                          //　最小Xサイズ計算（部品サイズ　＋　掴み代　＋　桟幅）
                          setMinXsize(xSize + Number(detailXGrabAllowance) + Number(xWidthOfPierVal));
                        }}
                        className={'input-editable'}
                        style={{ pointerEvents: editMode ? 'auto' : 'none', textAlign: 'center' }}
                      />
                    </td>
                    {/* 桟幅Y */}
                    <td>
                      <Input
                        name="detailYWidthOfPier"
                        id="detailYWidthOfPier"
                        value={detailYWidthOfPier}
                        onChange={(e) => {
                          let yWidthOfPierVal =
                            Number(e.target.value) > selectedMaterialYsize
                              ? Number(detailYWidthOfPier)
                              : Number(e.target.value);
                          setDetailYWidthOfPier(yWidthOfPierVal);
                          onUpdateDetail('pierWidthY', yWidthOfPierVal, tempDetailData);
                          //　最小Yサイズ計算（部品サイズ　＋　掴み代　＋　桟幅）
                          setMinYsize(ySize + Number(detailYGrabAllowance) + Number(yWidthOfPierVal));
                        }}
                        className={'input-editable'}
                        style={{ pointerEvents: editMode ? 'auto' : 'none', textAlign: 'center' }}
                      />
                    </td>
                  </tr>
                </tbody>
              </RTable>
            </Col>
          </Row>
        </div>
        <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row">
          <Col span={8} className="deli-col">
            <div style={{ display: activePattern === PatternType.Pattern1 ? 'none' : 'block' }}>
              <LeftCircleFilled onClick={changePattern} style={{ fontSize: 20, verticalAlign: 'text-top' }} />
              &nbsp;
              <label>
                {getMaterialDetailPatternName(PatternType.Pattern1) +
                  '(' +
                  detailP1Yield +
                  ' * ' +
                  detailP1NumOfMaterial +
                  ')'}
              </label>
            </div>
          </Col>
          <Col span={8} className="deli-col">
            <div className="cm-a-center">
              <label>{getMaterialDetailPatternName(activePattern)}</label>
            </div>
          </Col>
          <Col span={8} className="deli-col">
            <div style={{ display: activePattern === PatternType.Pattern2 ? 'none' : 'block' }} className="cm-a-right">
              <label>
                {getMaterialDetailPatternName(PatternType.Pattern2) +
                  '(' +
                  detailP2Yield +
                  ' * ' +
                  detailP2NumOfMaterial +
                  ')'}
              </label>
              &nbsp;
              <RightCircleFilled onClick={changePattern} style={{ fontSize: 20, verticalAlign: 'text-top' }} />
            </div>
          </Col>
        </Row>
        <div>
          <Row>
            <Col span={24}>
              <RTable className="iq3material-detail">
                <thead>
                  <tr>
                    <th width={'33.33%'}>材料原価</th>
                    <th width={'33.33%'}>歩留率</th>
                    <th width={'33.33%'}>材料枚数</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {/* 合計金額 */}
                    <td>{activePattern === PatternType.Pattern1 ? detailP1TotalAmount : detailP2TotalAmount}</td>
                    {/* 歩留まり */}
                    <td>{activePattern === PatternType.Pattern1 ? detailP1Yield : detailP2Yield}</td>
                    {/* 材料枚数 */}
                    <td>{activePattern === PatternType.Pattern1 ? detailP1NumOfMaterial : detailP2NumOfMaterial}</td>
                  </tr>
                </tbody>
                <thead>
                  <tr>
                    <th width={'33.33%'}>材料サイズ</th>
                    <th width={'33.33%'}>Xサイズ</th>
                    <th width={'33.33%'}>Yサイズ</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {/* 材料サイズ */}
                    {/* <td>
                      <Select
                        value={activePattern === PatternType.Pattern1 ? detailP1MaterialSizeId : detailP2MaterialSizeId}
                        onChange={(e) => {
                          setTopFit(false);
                          setRightFit(false);
                          let tempMaterialDetailPattern = tempDetailData?.materialDetailPatterns;
                          if (activePattern === PatternType.Pattern1) {
                            setDetailP1MaterialSizeId(e);
                            setDetailP2MaterialSizeId(e);
                            setPattern1Info(getPatternInfo(tempMaterialDetailPattern, e, PatternType.Pattern1), true);
                            setPattern2Info(getPatternInfo(tempMaterialDetailPattern, e, PatternType.Pattern2), true);
                            // settingCalPatternInfo(
                            //   PatternType.Pattern1,
                            //   e,
                            //   '',
                            //   '',
                            //   yRotate,
                            //   xWidthOfPier,
                            //   yWidthOfPier,
                            //   xGrabAllowance,
                            //   yGrabAllowance
                            // );
                          } else {
                            setDetailP1MaterialSizeId(e);
                            setDetailP2MaterialSizeId(e);
                            changePattern();
                            setPattern1Info(getPatternInfo(tempMaterialDetailPattern, e, PatternType.Pattern1), true);
                            setPattern2Info(getPatternInfo(tempMaterialDetailPattern, e, PatternType.Pattern2), true);
                            // settingCalPatternInfo(
                            //   PatternType.Pattern2,
                            //   e,
                            //   '',
                            //   '',
                            //   yRotate,
                            //   xWidthOfPier,
                            //   yWidthOfPier,
                            //   xGrabAllowance,
                            //   yGrabAllowance
                            // );
                          }
                          // let detailSizeInfo = materialDetailSizeData?.find((item) => item.materialSizeIq3Id === e);
                          // setSelectedRowNo(detailSizeInfo?.no);
                          // displayMaterialDetailInfo(detailSizeInfo, materialDetailPatternData);
                        }}
                        style={{ pointerEvents: editMode ? 'auto' : 'none', width: '100%' }}
                      >
                        {materialSizeData?.map((item, index) => (
                          <Select.Option key={index} value={item.id}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </td> */}
                    {/* 材料サイズ */}
                    <td>
                      {getNameById(
                        activePattern === PatternType.Pattern1 ? detailP1MaterialSizeId : detailP2MaterialSizeId,
                        materialSizeData
                      )}
                    </td>
                    {/* Xサイズ */}
                    <td>
                      <Input
                        name={activePattern === PatternType.Pattern1 ? 'pattern1XSize' : 'pattern2XSize'}
                        id="xSize"
                        className={'input-editable '}
                        value={activePattern === PatternType.Pattern1 ? detailP1XSize : detailP2XSize}
                        onChange={(e) => {
                          let xVal = Number(e.target.value);
                          let freeId = getMaterialSizeFreeId();
                          setDetailP1MaterialSizeId(freeId);
                          setDetailP2MaterialSizeId(freeId);
                          // 入力値が最小値より、大きいの場合、
                          if (xVal > minXsize) {
                            if (topFit) {
                              setTopFit(false);
                              updateSetting(freeId, 'topFit', false, tempDetailData);
                            }
                            if (rightFit) {
                              setRightFit(false);
                              updateSetting(freeId, 'rightFit', false, tempDetailData);
                            }
                          }
                          if (topFit || rightFit) {
                            updateXYSize(freeId, 'sizeX', xVal, tempDetailData, activePattern);
                            activePattern === PatternType.Pattern1 ? setDetailP1XSize(xVal) : setDetailP2XSize(xVal);
                          } else {
                            updateXYSize(freeId, 'sizeX', xVal, tempDetailData, PatternType.Pattern1);
                            updateXYSize(freeId, 'sizeX', xVal, tempDetailData, PatternType.Pattern2);
                            setDetailP1XSize(xVal);
                            setDetailP2XSize(xVal);
                          }
                        }}
                        onBlur={(e) => {
                          let xVal = Number(e.target.value);
                          if (xVal > maxXYSize || xVal < minXsize) {
                            xVal = minXsize;
                          }
                          let freeId = getMaterialSizeFreeId();
                          if (topFit || rightFit) {
                            updateXYSize(freeId, 'sizeX', xVal, tempDetailData, activePattern);
                            activePattern === PatternType.Pattern1 ? setDetailP1XSize(xVal) : setDetailP2XSize(xVal);
                          } else {
                            updateXYSize(freeId, 'sizeX', xVal, tempDetailData, PatternType.Pattern1);
                            updateXYSize(freeId, 'sizeX', xVal, tempDetailData, PatternType.Pattern2);
                            setDetailP1XSize(xVal);
                            setDetailP2XSize(xVal);
                          }
                        }}
                        style={{ pointerEvents: editMode ? 'auto' : 'none', textAlign: 'center' }}
                      />
                    </td>
                    {/* Yサイズ */}
                    <td>
                      <Input
                        name={activePattern === PatternType.Pattern1 ? 'pattern1YSize' : 'pattern2YSize'}
                        id="xSize"
                        className={'input-editable '}
                        value={activePattern === PatternType.Pattern1 ? detailP1YSize : detailP2YSize}
                        onChange={(e) => {
                          let yVal = Number(e.target.value);
                          let freeId = getMaterialSizeFreeId();
                          setDetailP1MaterialSizeId(freeId);
                          setDetailP2MaterialSizeId(freeId);
                          // 入力値が最小値より、大きいの場合、
                          if (yVal > minYsize) {
                            if (topFit) {
                              setTopFit(false);
                              updateSetting(freeId, 'topFit', false, tempDetailData);
                            }
                            if (rightFit) {
                              setRightFit(false);
                              updateSetting(freeId, 'rightFit', false, tempDetailData);
                            }
                          }
                          if (topFit || rightFit) {
                            updateXYSize(freeId, 'sizeY', yVal, tempDetailData, activePattern);
                            activePattern === PatternType.Pattern1 ? setDetailP1YSize(yVal) : setDetailP2YSize(yVal);
                          } else {
                            updateXYSize(freeId, 'sizeY', yVal, tempDetailData, PatternType.Pattern1);
                            updateXYSize(freeId, 'sizeY', yVal, tempDetailData, PatternType.Pattern2);
                            setDetailP1YSize(yVal);
                            setDetailP2YSize(yVal);
                          }
                        }}
                        onBlur={(e) => {
                          let yVal = Number(e.target.value);
                          if (yVal > maxXYSize || yVal < minYsize) {
                            yVal = minYsize;
                          }
                          let freeId = getMaterialSizeFreeId();
                          if (topFit || rightFit) {
                            updateXYSize(freeId, 'sizeY', yVal, tempDetailData, activePattern);
                            activePattern === PatternType.Pattern1 ? setDetailP1YSize(yVal) : setDetailP2YSize(yVal);
                          } else {
                            updateXYSize(freeId, 'sizeY', yVal, tempDetailData, PatternType.Pattern1);
                            updateXYSize(freeId, 'sizeY', yVal, tempDetailData, PatternType.Pattern2);
                            setDetailP1YSize(yVal);
                            setDetailP2YSize(yVal);
                          }
                        }}
                        style={{ pointerEvents: editMode ? 'auto' : 'none', textAlign: 'center' }}
                      />
                    </td>
                  </tr>
                </tbody>
              </RTable>
            </Col>
          </Row>
        </div>
        <Row>
          <Col span={3} className="deli-col" style={{ display: 'grid' }}>
            <Image
              preview={false}
              width={50}
              src={ySetting ? bottomToTop : leftToRight}
              style={{ marginLeft: '5px', marginTop: '15px' }}
              onClick={(e) => {
                let id = activePattern === PatternType.Pattern1 ? detailP1MaterialSizeId : detailP2MaterialSizeId;
                if (ySetting) {
                  setYSetting(false);
                  updateSetting(id, 'ySetting', false, tempDetailData);
                } else {
                  setYSetting(true);
                  updateSetting(id, 'ySetting', true, tempDetailData);
                }
              }}
            />
            <Image
              preview={false}
              width={50}
              src={yRotate ? rotateY : rotateX}
              style={{ marginLeft: '5px' }}
              onClick={(e) => {
                let id = activePattern === PatternType.Pattern1 ? detailP1MaterialSizeId : detailP2MaterialSizeId;
                if (yRotate) {
                  setYRotate(false);
                  updateSetting(id, 'yRotate', false, tempDetailData);
                } else {
                  setYRotate(true);
                  updateSetting(id, 'yRotate', true, tempDetailData);
                }
                if (topFit) {
                  setTopFit(false);
                  updateSetting(id, 'topFit', false, tempDetailData);
                }
                if (rightFit) {
                  setRightFit(false);
                  updateSetting(id, 'rightFit', false, tempDetailData);
                }
              }}
            />
            <Image
              preview={false}
              width={50}
              src={leftRightFit}
              style={{ marginLeft: '5px' }}
              onClick={(e) => {
                setRightFit(true);
                let freeId = getMaterialSizeFreeId();
                setDetailP1MaterialSizeId(freeId);
                setDetailP2MaterialSizeId(freeId);
                updateSetting(freeId, 'rightFit', true, tempDetailData);
              }}
            />
            <Image
              preview={false}
              width={50}
              src={topBottomFit}
              style={{ marginLeft: '5px' }}
              onClick={(e) => {
                setTopFit(true);
                let freeId = getMaterialSizeFreeId();
                setDetailP1MaterialSizeId(freeId);
                setDetailP2MaterialSizeId(freeId);
                updateSetting(freeId, 'topFit', true, tempDetailData);
              }}
            />
          </Col>
          <Col span={21} className="deli-col">
            <div className="canvasDiv">
              <div
                class="canvasDivOnWheel"
                onWheel={(e) => {
                  const zoomElement = document.querySelector('.canvasDivOnWheel');
                  if (e.deltaY < 0) {
                    zoomElement.style.transform = `scale(${(zoom += ZOOM_SPEED)})`;
                  } else {
                    zoomElement.style.transform = `scale(${(zoom -= ZOOM_SPEED)})`;
                  }
                }}
              >
                {/* パターン１イメージ */}
                <div style={{ padding: '14px 20px 0px 20px' }}>
                  <IQ3MaterialDetail_SheetDetailPattern
                    isPatternDetail={isPatternDetail}
                    selectedMaterialSize={selectedRowNo}
                    materialSizeData={materialSizeData}
                    partInfo={patternPartInfo}
                    xGrabAllowance={xGrabAllowance}
                    yGrabAllowance={yGrabAllowance}
                    xWidthOfPier={xWidthOfPier}
                    yWidthOfPier={yWidthOfPier}
                    materialInfo={patternMaterialInfo}
                    suryou={props.quantity}
                    isPattern2={activePattern === PatternType.Pattern2 ? true : false}
                    ySetting={ySetting}
                    rightFit={rightFit}
                    topFit={topFit}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );

  // パターン詳細情報の入力項目
  const patternContent = (
    <>
      <Row style={{ padding: '0px 15px 0px 15px' }}>
        {/** プロパティレイアウト */}
        <Col span={24}>
          <div
            style={{
              height: 670,
              fontSize: '13.5px',
              // overflowY: 'auto',
              whiteSpace: 'nowrap',
            }}
          >
            {inputFormItem}
          </div>
        </Col>
      </Row>
      <Row
        justify="end"
        style={{
          marginBottom: 0,
          marginTop: 20,
        }}
      >
        <Button className="mainButton" onClick={patternUpdateOK} style={{ marginRight: 5 }}>
          OK
        </Button>
        <Button className="cancelButton" onClick={patternUpdateCancel}>
          キャンセル
        </Button>
      </Row>
    </>
  );

  // パターン詳細情報のタイトル
  const patternTitle = (
    <div
      style={{
        width: 650,
        backgroundColor: '#005fab',
        marginLeft: -10,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{childTitle}</p>
    </div>
  );

  // 一覧画面の行を選択した場合、
  const onRowSelect = (record) => {
    setSelectedRowNo(record.no);
    calMaterialXYSize(record.materialSizeIq3Id);
    displayMaterialDetailInfo(record, materialDetailPatternData);
  };

  // 選択中の材料サイズ「X,Y」値を最大値として設定
  const calMaterialXYSize = (selectedId) => {
    let mstMaterialSizes = [];
    if (materialSizeData?.length === 0) {
      const selectedEstimateData = props.selectedEstimateData;
      mstMaterialSizes = getMaterialSizeData(selectedEstimateData); // 材料サイズ情報
    } else {
      mstMaterialSizes = materialSizeData;
    }
    // 選択された材料サイズ設定
    let materialSizes = mstMaterialSizes ? mstMaterialSizes?.filter((item) => item.id === selectedId) : [];
    let sheetX = 0;
    let sheetY = 0;
    if (materialSizes?.length > 0) {
      if (materialSizes[0]?.sizeX == 0 || materialSizes[0]?.name === 'Other') {
        sheetX = Number(patternPartInfo.mPartX) + Number(xWidthOfPier) + Number(xGrabAllowance);
      } else {
        sheetX = Number(materialSizes[0]?.sizeX);
      }
      if (materialSizes[0]?.sizeY == 0 || materialSizes[0]?.name === 'Other') {
        sheetY = Number(patternPartInfo.mPartY) + Number(yWidthOfPier) + Number(yGrabAllowance);
      } else {
        sheetY = Number(materialSizes[0]?.sizeY);
      }
    }
    setSelectedMaterialXsize(sheetX);
    setSelectedMaterialYsize(sheetY);
  };

  // パターン詳細情報を得る
  const getPatternInfo = (patternList, sizeIq3Id, patternNo) => {
    return patternList?.find((item) => item.patternType === patternNo && item.materialSizeIq3Id === sizeIq3Id);
  };

  // 材料サイズFreeのIDを得る
  const getMaterialSizeFreeId = () => {
    let id = materialSizeData?.find((item) => item.name === 'Free')?.id;
    return id ? id : 0;
  };

  // 値を変更した場合、
  const onUpdateDetail = (name, value, data) => {
    data[name] = value;
  };

  // XY値を変更した場合、
  const updateXYSize = (id, name, value, data, patternNo) => {
    data?.materialDetailPatterns?.map((item) => {
      if (item.materialSizeIq3Id === id && item.patternType === patternNo) {
        item[name] = value;
      }
    });
  };

  // 表示設定（ySetting、yRotate、topFit、rightFit）値を変更した場合、
  const updateSetting = (id, name, value, data) => {
    data?.materialDetailSizes?.map((item) => {
      if (item.materialSizeIq3Id === id && item?.setting) item.setting[name] = value;
    });
  };

  // パターン１詳細情報、パターン２詳細情報、計算結果情報
  const displayMaterialDetailInfo = (selectedSizeData, patternDetailData, isDetail = false) => {
    if (selectedSizeData === undefined) return;
    setPattern1Info(
      getPatternInfo(patternDetailData, selectedSizeData.materialSizeIq3Id, PatternType.Pattern1),
      isDetail
    ); // パターン１詳細情報
    setPattern2Info(
      getPatternInfo(patternDetailData, selectedSizeData.materialSizeIq3Id, PatternType.Pattern2),
      isDetail
    ); // パターン２詳細情報
    setSummarizeInfo(selectedSizeData); // 計算結果情報
  };

  return (
    <>
      <div style={{ display: 'flex', float: 'left', width: '100%', paddingTop: 5 }}>
        {/* 左 */}
        <div style={{ width: '60%' }}>
          <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row">
            <Col span={24} className="deli-col">
              <div className="cm-a-center">
                <label style={{ marginLeft: 0 }}>情報</label>
              </div>
            </Col>
          </Row>
          <div>
            <Row>
              <Col span={24}>
                <RTable className="iq3material-detail">
                  <thead>
                    <tr>
                      <th colSpan={3} width={'55%'}>
                        子部品名称
                      </th>
                      <th width={'15%'}>子部品製作数</th>
                      <th width={'15%'}>Xサイズ</th>
                      <th width={'15%'}>Yサイズ</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {/* 部品名 */}
                      <td colSpan={3}>{partName}</td>
                      {/* 製造個数(員数) */}
                      <td>{quantity + '(' + partCount + ')'}</td>
                      {/* Xサイズ */}
                      <td>{xSize}</td>
                      {/* Yサイズ */}
                      <td>{ySize}</td>
                    </tr>
                  </tbody>
                  <thead>
                    <tr>
                      <th width={'18%'}>材質</th>
                      <th width={'18%'}>材料名称</th>
                      <th width={'18%'}>板厚</th>
                      <th width={'15%'}>表面保護</th>
                      <th width={'15%'}>重量(kg)</th>
                      <th width={'15%'}>表面積(c㎡)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {/* 材質 */}
                      <td>
                        {editMode ? (
                          <>
                            <Select
                              value={materialTypeId ? materialTypeId : ''}
                              onChange={(e) => {
                                onChangeMaterialType(e);
                                let name = getMaterialTypeById(e, materialTypeListData);
                                onUpdateDetail('materialTypeName', name, detailData);
                                onUpdateDetail('materialTypeIq3Id', e, detailData);
                              }}
                              style={{ pointerEvents: editMode ? 'auto' : 'none', width: '100%' }}
                            >
                              {materialTypeListData
                                ?.filter(
                                  (item) =>
                                    materialListData?.findIndex((mItem) => mItem.materialTypeIq3Id === item.id) > -1
                                )
                                ?.map((item, index) => (
                                  <Select.Option key={index} value={item.id}>
                                    {item.name}
                                  </Select.Option>
                                ))}
                            </Select>
                          </>
                        ) : (
                          <>{getNameById(materialTypeId, materialTypeListData)}</>
                        )}
                      </td>
                      {/* 材料名称 */}
                      <td>
                        {editMode ? (
                          <>
                            <Select
                              value={materialId ? materialId : ''}
                              onChange={(e) => {
                                setMaterialId(e);
                                setThickness(e);
                                let name = getMaterialNameById(e, materialListData);
                                onUpdateDetail('materialName', name, detailData);
                                onUpdateDetail('materialIq3Id', e, detailData);
                                onUpdateDetail('thickness', e, detailData);
                              }}
                              style={{ pointerEvents: editMode ? 'auto' : 'none', width: '100%' }}
                            >
                              {materialListData
                                ?.filter((item) => item.info.isUsed && item.materialTypeIq3Id === materialTypeId)
                                ?.map((item, index) => (
                                  <Select.Option key={index} value={item.id}>
                                    {item.name}
                                  </Select.Option>
                                ))}
                            </Select>
                          </>
                        ) : (
                          <>{getNameById(materialId, materialListData)}</>
                        )}
                      </td>
                      {/* 板厚 */}
                      <td>
                        {editMode ? (
                          <>
                            <Select
                              value={thickness ? thickness : ''}
                              onChange={(e) => {
                                setThickness(e);
                                setMaterialId(e);
                                let name = getMaterialNameById(e, materialListData);
                                onUpdateDetail('thickness', e, detailData);
                                onUpdateDetail('materialName', name, detailData);
                                onUpdateDetail('materialIq3Id', e, detailData);
                              }}
                              style={{ pointerEvents: editMode ? 'auto' : 'none', width: '100%' }}
                            >
                              {materialListData
                                ?.filter((item) => item.info.isUsed && item.materialTypeIq3Id === materialTypeId)
                                ?.map((item, index) => (
                                  <Select.Option key={index} value={item.id}>
                                    {item.info.thick}
                                  </Select.Option>
                                ))}
                            </Select>
                          </>
                        ) : (
                          <>{getNameById(thickness, materialListData)}</>
                        )}
                      </td>
                      {/* 表面保護 */}
                      <td>
                        {editMode ? (
                          <>
                            <Select
                              value={surfaceId ? surfaceId : ''}
                              name="surface"
                              id="surface"
                              onChange={(e) => {
                                setSurfaceId(e);
                                let name = getMaterialSurfaceById(e, materialSurfaceListData);
                                onUpdateDetail('materialSurfaceName', name, detailData);
                                onUpdateDetail('materialSurfaceIq3Id', e, detailData);
                              }}
                              style={{ pointerEvents: editMode ? 'auto' : 'none', width: '100%' }}
                            >
                              {materialSurfaceListData
                                ?.filter((item) => item.info.isUsed && item.materialTypeIq3Id === materialTypeId)
                                ?.map((item, index) => (
                                  <Select.Option key={index} value={item.id}>
                                    {item.name}
                                  </Select.Option>
                                ))}
                            </Select>
                          </>
                        ) : (
                          <>{getNameById(surfaceId, materialSurfaceListData)}</>
                        )}
                      </td>
                      {/* 重量(kg) */}
                      <td>{weight}</td>
                      {/* 表面積(c㎡) */}
                      <td>{surfaceArea}</td>
                    </tr>
                  </tbody>
                  <thead>
                    <tr>
                      <th width={'18%'}>掴み代X</th>
                      <th width={'18%'}>掴み代Y</th>
                      <th width={'18%'}>桟幅X</th>
                      <th width={'15%'}>桟幅Y</th>
                      <th width={'15%'}>加工範囲 X</th>
                      <th width={'15%'}>加工範囲 Y</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {/* 掴み代X */}

                      <td>
                        <Input
                          name="xGrabAllowance"
                          id="xGrabAllowance"
                          value={xGrabAllowance}
                          onKeyPress={(e) => {
                            handleKeyPress(e, false);
                          }}
                          onChange={(e) => {
                            let xGrabAllowanceVal =
                              Number(e.target.value) > selectedMaterialXsize
                                ? Number(xGrabAllowance)
                                : Number(e.target.value);
                            setXGrabAllowance(xGrabAllowanceVal);
                            onUpdateDetail('grabbingX', xGrabAllowanceVal, detailData);
                            //　最小Xサイズ計算（部品サイズ　＋　掴み代　＋　桟幅）
                            setMinXsize(xSize + Number(xGrabAllowanceVal) + Number(xWidthOfPier));
                          }}
                          className={'input-editable'}
                          style={{ pointerEvents: editMode ? 'auto' : 'none', textAlign: 'center' }}
                        />
                      </td>
                      {/* 掴み代Y */}

                      <td>
                        <Input
                          name="yGrabAllowance"
                          id="yGrabAllowance"
                          onKeyPress={(e) => {
                            handleKeyPress(e, false);
                          }}
                          value={yGrabAllowance}
                          onChange={(e) => {
                            let yGrabAllowanceVal =
                              Number(e.target.value) > selectedMaterialYsize
                                ? Number(yGrabAllowance)
                                : Number(e.target.value);
                            setYGrabAllowance(yGrabAllowanceVal);
                            onUpdateDetail('grabbingY', e.target.value, detailData);
                            // 最小Yサイズ計算（部品サイズ　＋　掴み代　＋　桟幅）
                            setMinYsize(Number(ySize) + Number(yGrabAllowanceVal) + Number(yWidthOfPier));
                          }}
                          className={'input-editable'}
                          style={{ pointerEvents: editMode ? 'auto' : 'none', textAlign: 'center' }}
                        />
                      </td>
                      {/* 桟幅X */}

                      <td>
                        <Input
                          name="xWidthOfPier"
                          id="xWidthOfPier"
                          onKeyPress={(e) => {
                            handleKeyPress(e, false);
                          }}
                          value={xWidthOfPier}
                          onChange={(e) => {
                            let xWidthOfPierVal =
                              Number(e.target.value) > selectedMaterialXsize
                                ? Number(xWidthOfPier)
                                : Number(e.target.value);
                            setXWidthOfPier(xWidthOfPierVal);
                            onUpdateDetail('pierWidthX', xWidthOfPierVal, detailData);
                            //　最小Xサイズ計算（部品サイズ　＋　掴み代　＋　桟幅）
                            setMinXsize(xSize + Number(xGrabAllowance) + Number(xWidthOfPierVal));
                          }}
                          className={'input-editable'}
                          style={{ pointerEvents: editMode ? 'auto' : 'none', textAlign: 'center' }}
                        />
                      </td>
                      {/* 桟幅Y */}

                      <td>
                        <Input
                          name="yWidthOfPier"
                          id="yWidthOfPier"
                          onKeyPress={(e) => {
                            handleKeyPress(e, false);
                          }}
                          value={yWidthOfPier}
                          onChange={(e) => {
                            let yWidthOfPierVal =
                              Number(e.target.value) > selectedMaterialYsize
                                ? Number(yWidthOfPier)
                                : Number(e.target.value);
                            setYWidthOfPier(yWidthOfPierVal);
                            onUpdateDetail('pierWidthY', yWidthOfPierVal, detailData);
                            //　最小Yサイズ計算（部品サイズ　＋　掴み代　＋　桟幅）
                            setMinYsize(xSize + Number(yGrabAllowance) + Number(yWidthOfPierVal));
                          }}
                          className={'input-editable'}
                          style={{ pointerEvents: editMode ? 'auto' : 'none', textAlign: 'center' }}
                        />
                      </td>
                      {/* 加工範囲 X */}
                      <td>{xMachiningRange}</td>
                      {/* 加工範囲 Y */}
                      <td>{yMachiningRange}</td>
                    </tr>
                  </tbody>
                </RTable>
              </Col>
            </Row>
          </div>
          <Row>
            <Col span={24}>
              <div className="cm-a-center">
                <Table
                  columns={columns}
                  rowKey={(record) => record.no}
                  dataSource={materialDetailSizeData}
                  scroll={{ y: 350, x: '10vw' }}
                  rowClassName={(record) => (record.no == selectedRowNo ? 'active-row' : 'data-row')}
                  className="iq3material-detail-list-table"
                  pagination={false}
                  onRow={(record, index) => {
                    return {
                      onClick: (event) => {
                        onRowSelect(record);
                      },
                    };
                  }}
                />
              </div>
            </Col>
          </Row>
        </div>
        {/* 右 */}
        <div style={{ marginLeft: '10px', width: '40%' }}>
          {/* 計算結果 */}
          <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={openCalResultPanel}>
            <Col span={24} className="deli-col">
              <div className="cm-a-center" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <label style={{ marginLeft: 0 }}>計算結果</label>
                {isCalResult ? (
                  <CaretUpOutlined style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }} />
                ) : (
                  <CaretDownOutlined style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }} />
                )}
              </div>
            </Col>
          </Row>
          <div style={{ display: isCalResult ? 'block' : 'none' }}>
            <Row>
              <Col span={24}>
                <RTable className="iq3material-detail">
                  <thead>
                    <tr>
                      <th width={'33.33%'}>材料原価</th>
                      <th width={'33.33%'}>歩留率</th>
                      <th width={'33.33%'}>材料枚数</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {/* 合計金額 */}
                      <td>{JPYs.format(summarizeTotalAmount)}</td>
                      {/* 歩留まり */}
                      <td>{summarizeYield}</td>
                      {/* 材料枚数 */}
                      <td>{summarizeNumOfMaterial}</td>
                    </tr>
                  </tbody>
                  <thead>
                    <tr>
                      <th width={'33.33%'}>重量単価</th>
                      <th width={'33.33%'}>表面加算</th>
                      <th width={'33.33%'}>重量加算</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {/* 重量単価 */}
                      <td>{summarizePrice}</td>
                      {/* 表面加算 */}
                      <td>{summarizeSurface}</td>
                      {/* 重量加算 */}
                      <td>{summarizeWeight}</td>
                    </tr>
                  </tbody>
                  <thead>
                    <tr>
                      <th width={'33.33%'}>材料単価(1部品当たり)</th>
                      <th width={'33.33%'}>原価率(1部品当たり)</th>
                      <th width={'33.33%'} className="formula-column">
                        {/* <Popover content={scrapAmount}>スクラップ金額</Popover> */}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {/* 材料単価(1部品当たり) */}
                      <td>{JPYs.format(summarizeMaterialPrice)}</td>
                      {/* 原価率(1部品当たり) */}
                      <td>{summarizeCostRate}</td>
                      {/* スクラップ金額 */}
                      {/* <td>{summarizeScrapAmount}</td> */}
                    </tr>
                  </tbody>
                </RTable>
              </Col>
            </Row>
          </div>

          {/* パターン1 */}
          <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row">
            <Col span={24} className="deli-col">
              <div className="cm-a-center" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {editMode ? (
                  <Button className={'pattern-btn'} id="pattern1Btn" onClick={callPattern1Detail}>
                    {getMaterialDetailPatternName(PatternType.Pattern1)}
                  </Button>
                ) : (
                  <label style={{ marginLeft: 0 }}>{getMaterialDetailPatternName(PatternType.Pattern1)}</label>
                )}
                {isPattern1 ? (
                  <>
                    <CaretUpOutlined
                      style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }}
                      onClick={openPattern1Panel}
                    />
                    {isPattern1Img ? (
                      <UpCircleFilled
                        style={{ fontSize: 15, marginTop: 0, marginLeft: 10 }}
                        onClick={openPattern1ImgPanel}
                      />
                    ) : (
                      <DownCircleOutlined
                        style={{ fontSize: 15, marginTop: 0, marginLeft: 10 }}
                        onClick={openPattern1ImgPanel}
                      />
                    )}
                  </>
                ) : (
                  <CaretDownOutlined
                    style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }}
                    onClick={openPattern1Panel}
                  />
                )}
              </div>
            </Col>
          </Row>
          <div style={{ display: isPattern1 ? 'block' : 'none', marginBottom: 12 }}>
            <Row>
              <Col span={24} style={{ height: '153px' }}>
                <RTable className="iq3material-detail">
                  <thead>
                    <tr>
                      <th width={'33.33%'}>合計金額</th>
                      <th width={'33.33%'}>歩留まり</th>
                      <th width={'33.33%'}>材料枚数</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {/* 合計金額 */}
                      <td>{pattern1TotalAmount}</td>
                      {/* 歩留まり */}
                      <td>{pattern1Yield}</td>
                      {/* 材料枚数 */}
                      <td>{pattern1NumOfMaterial}</td>
                    </tr>
                  </tbody>
                  <thead>
                    <tr>
                      <th width={'33.33%'}>材料サイズ</th>
                      <th width={'33.33%'}>Xサイズ</th>
                      <th width={'33.33%'}>Yサイズ</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {/* 材料サイズ */}
                      <td>{getNameById(pattern1MaterialSizeId, materialSizeData)}</td>
                      {/* Xサイズ */}
                      <td>{pattern1XSize}</td>
                      {/* Yサイズ */}
                      <td>{pattern1YSize}</td>
                    </tr>
                  </tbody>
                </RTable>
              </Col>
            </Row>
          </div>
          <div
            style={{
              display: isPattern1 && isPattern1Img ? 'block' : 'none',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              marginBottom: 12,
            }}
          >
            <Row>
              <Col span={24} className="deli-col">
                <Image
                  width={'85%'}
                  style={{ border: '1px solid rgb(165, 165, 165)' }}
                  preview={false}
                  src={pattern1}
                />
              </Col>
            </Row>
          </div>

          {/* パターン2 */}
          <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row">
            <Col span={24} className="deli-col">
              <div className="cm-a-center" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {editMode ? (
                  <Button className={'pattern-btn'} id="pattern1Btn" onClick={callPattern2Detail}>
                    {getMaterialDetailPatternName(PatternType.Pattern2)}
                  </Button>
                ) : (
                  <label style={{ marginLeft: 0 }}>{getMaterialDetailPatternName(PatternType.Pattern2)}</label>
                )}
                {isPattern2 ? (
                  <>
                    <CaretUpOutlined
                      style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }}
                      onClick={openPattern2Panel}
                    />
                    {isPattern2Img ? (
                      <UpCircleFilled
                        style={{ fontSize: 15, marginTop: 0, marginLeft: 10 }}
                        onClick={openPattern2ImgPanel}
                      />
                    ) : (
                      <DownCircleOutlined
                        style={{ fontSize: 15, marginTop: 0, marginLeft: 10 }}
                        onClick={openPattern2ImgPanel}
                      />
                    )}
                  </>
                ) : (
                  <CaretDownOutlined
                    style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }}
                    onClick={openPattern2Panel}
                  />
                )}
              </div>
            </Col>
          </Row>
          <div style={{ display: isPattern2 ? 'block' : 'none', marginBottom: 12 }}>
            <Row>
              <Col span={24} style={{ height: '153px' }}>
                <RTable className="iq3material-detail">
                  <thead>
                    <tr>
                      <th width={'33.33%'}>合計金額</th>
                      <th width={'33.33%'}>歩留まり</th>
                      <th width={'33.33%'}>材料枚数</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {/* 合計金額 */}
                      <td>{pattern2TotalAmount}</td>
                      {/* 歩留まり */}
                      <td>{pattern2Yield}</td>
                      {/* 材料枚数 */}
                      <td>{pattern2NumOfMaterial}</td>
                    </tr>
                  </tbody>
                  <thead>
                    <tr>
                      <th width={'33.33%'}>材料サイズ</th>
                      <th width={'33.33%'}>Xサイズ</th>
                      <th width={'33.33%'}>Yサイズ</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {/* 材料サイズ */}
                      <td>{getNameById(pattern2MaterialSizeId, materialSizeData)}</td>
                      {/* Xサイズ */}
                      <td>{pattern2XSize}</td>
                      {/* Yサイズ */}
                      <td>{pattern2YSize}</td>
                    </tr>
                  </tbody>
                </RTable>
              </Col>
            </Row>
          </div>
          <div
            style={{
              display: isPattern2 && isPattern2Img ? 'block' : 'none',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              marginBottom: 12,
            }}
          >
            <Row>
              <Col span={24} className="deli-col">
                <Image
                  width={'85%'}
                  style={{ border: '1px solid rgb(165, 165, 165)', marginLeft: '3px' }}
                  preview={false}
                  src={pattern2}
                />
              </Col>
            </Row>
          </div>
        </div>
      </div>

      {/* パターン詳細情報 */}
      {isPatternDetail
        ? commonModal(
            isPatternDetail,
            patternTitle,
            null,
            null,
            650,
            patternUpdateOK,
            patternUpdateCancel,
            patternContent,
            'iq3material-pattern-detail',
            null,
            true
          )
        : ''}

      {/* 更新確認 */}
      {updateConfirm
        ? commonModal(
            updateConfirm,
            confirmModalTitle,
            commonFooter(confirmOk, confirmCancel),
            null,
            400,
            confirmOk,
            confirmCancel,
            confirmUpdateContent,
            null,
            null,
            false
          )
        : ''}

      {/** 破棄の確認メッセージ */}
      {cancelConfirm
        ? commonModal(
            cancelConfirm,
            confirmModalTitle,
            commonFooter(cancelConfirmOK, cancelConfirmCancel),
            null,
            400,
            cancelConfirmOK,
            cancelConfirmCancel,
            confirmDiscardContent,
            null,
            null,
            false
          )
        : ''}
    </>
  );
});

export default IQ3MaterialDetail;
