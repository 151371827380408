/**
 * クラス名：装置設定一覧画面
 * 作成者：チュー
 * 作成日：2023/06/01
 * バージョン：1.0
 */
import { Col, Input, Row, Select, Table, Button, Checkbox } from 'antd';
import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import SplitPane from 'split-pane-react';
import 'split-pane-react/esm/themes/default.css';
import { Table as RTable } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input-field';
import { CaretDownOutlined, CaretUpOutlined, SearchOutlined } from '@ant-design/icons';
import { StatusCodes } from 'http-status-codes';

import {
  souchiHandleIncreaseTime,
  souchiHandleDecreaseTime,
  JPYs,
  getKubunNameDDL,
  actionArr,
  createShiageFlagData,
  createJikanListData,
  updateJikanList,
  createJikanSizeListData,
  updateJikanSizeListData,
  updateProductSizeJikanList,
  createKeisuuListData,
  createSizeListData,
  createGasListData,
  createKakouSokudoListData,
  processTypes,
  getAccessToken,
  updateAccessToken,
  secondsToHms,
  setTimetoSec,
} from '../../../common/Common.js';
import DevicesDetails from './DevicesDetails';
import {
  confirmCreateContent,
  confirmHakiContent,
  confirmDiscardContent,
  confirmModalTitle,
  commonModal,
  commonFooter,
  confirmDeleteContent,
  authorizeError,
  error,
  commonDeleteFooter,
} from '../../../common/CommonModal';
import { Customer } from '../../../common/Constant';
import { ClientType, ServiceClass, WorkType } from '../../../common/enums.js';
import SearchAndGetCustomerData from '../../../common/SearchAndGetCustomerData.js';
import { createData } from './deviceDetailsModel/Weld_Detail.js';
import { createListData } from '../../../common/Common.js';
import { getClientInfo, getDeviceInfo, getProcessInfo, getMaterialTypeInfo } from '../../../common/CommonAPI.js';

import { ErrorMessage } from '../../../common/Message.js';
// 溶接線長
const weldLineMasterData = [
  { id: 1, max: '0.80', displayFlag: true },
  { id: 2, max: '1.00', displayFlag: true },
  { id: 3, max: '1.20', displayFlag: true },
  { id: 4, max: '1.60', displayFlag: true },
  { id: 5, max: '2.30', displayFlag: true },
  { id: 6, max: '3.20', displayFlag: true },
  { id: 7, max: '4.50', displayFlag: true },
  { id: 8, max: '6.00', displayFlag: true },
  { id: 8, max: '99999', displayFlag: true },
];
// 曲げ個数
const mageKosuuData = [
  { id: 1, val: 1, displayFlag: true },
  { id: 2, val: 2, displayFlag: true },
];
// iq3板厚
const iq3ItaatsuData = [
  { id: 1, max: '0.80', displayFlag: true },
  { id: 2, max: '1.00', displayFlag: true },
  { id: 3, max: '1.20', displayFlag: true },
  { id: 4, max: '1.60', displayFlag: true },
  { id: 5, max: '2.30', displayFlag: true },
  { id: 6, max: '3.20', displayFlag: true },
  { id: 7, max: '4.50', displayFlag: true },
  { id: 8, max: '6.00', displayFlag: true },
  { id: 8, max: '99999', displayFlag: true },
];
// iq3ガス種類
const gasTypeData = [
  { id: 1, val: '酸素', displayFlag: true },
  { id: 2, val: '窒素', displayFlag: true },
  { id: 3, val: 'Easy', displayFlag: true },
  { id: 4, val: 'Air', displayFlag: true },
  { id: 5, val: 'その他', displayFlag: true },
];
const retainedMoldData = [
  {
    id: 1,
    val: '5.0 x 5.0',
    displayFlag: true,
  },
  {
    id: 2,
    val: '10.0 x 5.0',
    displayFlag: true,
  },
  {
    id: 7,
    val: '20.0 x 5.0',
    displayFlag: true,
  },
  {
    id: 6,
    val: '30.0 x 5.0',
    displayFlag: true,
  },
  {
    id: 5,
    val: '40.0 x 5.0',
    displayFlag: true,
  },
  {
    id: 4,
    val: '50.0 x 5.0',
    displayFlag: true,
  },
  {
    id: 3,
    val: '80.0 x 5.0',
    displayFlag: true,
  },
  {
    id: 8,
    val: '110.0 x 5.0',
    displayFlag: true,
  },
];
// ジョイントサイズ
const jointSizeData = [
  {
    id: 1,
    max: '100',
    displayFlag: true,
  },
  {
    id: 6,
    val: '',
    displayFlag: true,
    max: '400',
  },
  {
    id: 5,
    val: '',
    displayFlag: true,
    max: '800',
  },
  {
    id: 4,
    val: '',
    displayFlag: true,
    max: '1600',
  },
  {
    id: 3,
    val: '',
    displayFlag: true,
    max: '10000',
  },
  {
    id: 2,
    max: 99999,
    displayFlag: true,
  },
];
// iq3曲げ数
const mageSuuData = [
  { id: 1, val: 1, displayFlag: true },
  { id: 2, val: 2, displayFlag: true },
];
// 板厚
const itaatsuMasterData = [
  { id: 1, max: '0.80', displayFlag: true },
  { id: 2, max: '1.00', displayFlag: true },
  { id: 3, max: '1.20', displayFlag: true },
  { id: 4, max: '1.60', displayFlag: true },
  { id: 5, max: '2.30', displayFlag: true },
  { id: 6, max: '3.20', displayFlag: true },
  { id: 7, max: '4.50', displayFlag: true },
  { id: 8, max: '6.00', displayFlag: true },
  { id: 8, max: '99999', displayFlag: true },
];
// パンチ条件
const punchData = [
  { key: 1, name: '1パンチ丸穴径', val: 0 },
  { key: 2, name: '1パンチ矩形周長', val: 0 },
  { key: 3, name: '二ブリングピッチ', val: 0 },
];
// 材料配置

// 板厚(溶接難易度)
const yousetsuNanidoItaatsuMasterData = [
  { id: 1, max: '0.80', displayFlag: true },
  { id: 2, max: '1.00', displayFlag: true },
  { id: 3, max: '1.20', displayFlag: true },
  { id: 4, max: '1.60', displayFlag: true },
  { id: 5, max: '2.30', displayFlag: true },
  { id: 6, max: '3.20', displayFlag: true },
  { id: 7, max: '4.50', displayFlag: true },
  { id: 8, max: '6.00', displayFlag: true },
  { id: 8, max: '99999', displayFlag: true },
];
// 板厚(仮付き時間)
const karitsukiJikanItaatsuMasterData = [
  { id: 1, max: '0.80', displayFlag: true },
  { id: 2, max: '1.00', displayFlag: true },
  { id: 3, max: '1.20', displayFlag: true },
  { id: 4, max: '1.60', displayFlag: true },
  { id: 5, max: '2.30', displayFlag: true },
  { id: 6, max: '3.20', displayFlag: true },
  { id: 7, max: '4.50', displayFlag: true },
  { id: 8, max: '6.00', displayFlag: true },
  { id: 8, max: '99999', displayFlag: true },
];
// 製品重量係数
const weightMasterData = [
  { id: 1, max: 5, displayFlag: true },
  { id: 2, max: 10, displayFlag: true },
  { id: 3, max: 20, displayFlag: true },
  { id: 4, max: 30, displayFlag: true },
  { id: 5, max: 50, displayFlag: true },
  { id: 6, max: 999, displayFlag: true },
];
const taisekiMasterData = [
  { id: 1, max: '100.00', displayFlag: true },
  { id: 2, max: '400.00', displayFlag: true },
  { id: 3, max: '800.00', displayFlag: true },
  { id: 4, max: '1200.00', displayFlag: true },
  { id: 5, max: '1600.00', displayFlag: true },
  { id: 6, max: '2400.00', displayFlag: true },
  { id: 7, max: '10000.00', displayFlag: true },
  { id: 8, max: '99999.99', displayFlag: true },
];
const areaMasterData = [
  { id: 1, max: '100.00', displayFlag: true },
  { id: 2, max: '400.00', displayFlag: true },
  { id: 3, max: '800.00', displayFlag: true },
  { id: 4, max: '1200.00', displayFlag: true },
  { id: 5, max: '1600.00', displayFlag: true },
  { id: 6, max: '2400.00', displayFlag: true },
  { id: 7, max: '10000.00', displayFlag: true },
  { id: 8, max: '99999.99', displayFlag: true },
];
const iq3areaMasterData = [
  { id: 1, max: '100.00', displayFlag: true },
  { id: 2, max: '400.00', displayFlag: true },
  { id: 3, max: '800.00', displayFlag: true },
  { id: 4, max: '1200.00', displayFlag: true },
  { id: 5, max: '1600.00', displayFlag: true },
  { id: 6, max: '2400.00', displayFlag: true },
  { id: 7, max: '10000.00', displayFlag: true },
  { id: 8, max: '99999.99', displayFlag: true },
];
// 加工長
const kakouchouMasterData = [
  { id: 1, max: 30, displayFlag: true },
  { id: 2, max: 99999, displayFlag: true },
];
const iq3SizeMasterData = [
  { id: 1, max: 4, displayFlag: true },
  { id: 2, max: 6, displayFlag: true },
  { id: 3, max: 10, displayFlag: true },
  { id: 4, max: 999, displayFlag: true },
];
const iq3mmMasterData = [
  { id: 1, max: 5, displayFlag: true },
  { id: 2, max: 10, displayFlag: true },
  { id: 3, max: 20, displayFlag: true },
  { id: 4, max: 30, displayFlag: true },
  { id: 5, max: 999, displayFlag: true },
];
const lengthMasterData = [
  { id: 1, max: '100.00', displayFlag: true },
  { id: 2, max: '400.00', displayFlag: true },
  { id: 3, max: '800.00', displayFlag: true },
  { id: 4, max: '1200.00', displayFlag: true },
  { id: 5, max: '1600.00', displayFlag: true },
  { id: 6, max: '2400.00', displayFlag: true },
  { id: 7, max: '10000.00', displayFlag: true },
  { id: 8, max: '99999.99', displayFlag: true },
];
// 取付時間のサイズ
const sizeMasterData = [
  { id: 1, max: 4, displayFlag: true },
  { id: 2, max: 6, displayFlag: true },
  { id: 3, max: 8, displayFlag: true },
  { id: 4, max: 10, displayFlag: true },
  { id: 5, max: 12, displayFlag: true },
  { id: 6, max: 999, displayFlag: true },
];
// 生産個数係数
const quantityMasterData = [
  { id: 1, val: 1, displayFlag: true },
  { id: 2, val: 2, displayFlag: true },
  { id: 3, val: 3, displayFlag: true },
];
// 数量区分
const suuryouKubunMasterData = [
  { id: 1, max: 10, displayFlag: true },
  { id: 2, max: 25, displayFlag: true },
  { id: 3, max: 50, displayFlag: true },
  { id: 4, max: 100, displayFlag: true },
  { id: 5, max: 150, displayFlag: true },
  { id: 6, max: 200, displayFlag: true },
  { id: 7, max: 250, displayFlag: true },
  { id: 8, max: 99999, displayFlag: true },
];
// 要素数
const yousosuuMasterData = [
  { id: 1, max: 10, displayFlag: true },
  { id: 2, max: 25, displayFlag: true },
  { id: 3, max: 50, displayFlag: true },
  { id: 4, max: 100, displayFlag: true },
  { id: 5, max: 150, displayFlag: true },
  { id: 6, max: 200, displayFlag: true },
  { id: 7, max: 250, displayFlag: true },
  { id: 8, max: 99999, displayFlag: true },
];
// 名称
const meishouMasterData = [
  { id: 1, name: '極粗級', displayFlag: true },
  { id: 2, name: '粗級', displayFlag: true },
  { id: 3, name: '中級', displayFlag: true },
  { id: 4, name: '精級', displayFlag: true },
];
// iq3生産個数
const iq3SeisanKosuuMasterData = [
  { id: 1, val: 1, displayFlag: true },
  { id: 2, val: 2, displayFlag: true },
  { id: 3, val: 5, displayFlag: true },
  { id: 4, val: 10, displayFlag: true },
  { id: 5, val: 50, displayFlag: true },
  { id: 6, val: 100, displayFlag: true },
  { id: 7, val: 250, displayFlag: true },
  { id: 8, val: 251, displayFlag: true },
];
// iq3本数
const iq3honsuuMasterData = [
  { id: 1, val: 3, displayFlag: true },
  { id: 2, val: 5, displayFlag: true },
  { id: 3, val: 8, displayFlag: true },
  { id: 4, val: 10, displayFlag: true },
  { id: 5, val: 11, displayFlag: true },
];

// 数量区分
const keisuu = ['係数'];
// 数量
const suuryouTypes = ['子部品点数', '子部品種類数'];
// デフォルト値
const defaultCharge = 4200;
// 時間チャージ
// const jikanchaji = [
//   {
//     id: 1,
//     val: defaultCharge,
//   },
//   {
//     id: 2,
//     val: defaultCharge,
//   },
//   {
//     id: 3,
//     val: defaultCharge,
//   },
// ];

// 子部品数係数（検査と梱包）
// const kobuhinData = [
//   {
//     key: 1,
//     name: '子部品点数',
//     lstData: [
//       {
//         key: 1,
//         name: 'iQ3',
//         val: '',
//       },
//       {
//         key: 2,
//         name: 'iQ5',
//         val: '',
//       },
//       {
//         key: 3,
//         name: 'iQ7',
//         val: '',
//       },
//     ],
//   },
//   {
//     key: 2,
//     name: '子部品種類数',
//     lstData: [
//       {
//         key: 1,
//         name: 'iQ3',
//         val: '',
//       },
//       {
//         key: 2,
//         name: 'iQ5',
//         val: '',
//       },
//       {
//         key: 3,
//         name: 'iQ7',
//         val: '',
//       },
//     ],
//   },
// ];

// デフォルトページの表示件数
const defaultPageSize = 25;

// ReactEnvの情報
const envProperties = process.env;

const DevicesList = forwardRef((props, ref) => {
  //   Content Resize
  const [sizes, setSizes] = useState(['45%']);

  // const [tblColumns, setTblColumns] = useState([]);
  const navigate = useNavigate();
  // 編集モード
  const [editMode, setEditMode] = useState(false);
  const [editModeCancelConfirm, setEditModeCancelConfirm] = useState(false);
  const [clickedAction, setClickedAction] = useState(0);

  const [currentRecordKey, setCurrentRecordKey] = useState(0);
  const [currentRecord, setCurrentRecord] = useState([]);

  // 新規modal
  const [isNew, setIsNew] = useState(false);
  const [isUpdateData, setIsUpdateData] = useState(false);
  const [isDiscardData, setIsDiscardData] = useState(false);

  // 削除Modal
  const [isDelete, setIsDelete] = useState(false);

  // 新規作成
  const [category, setCategory] = useState(processTypes.standard);

  const [kouteiType, setKouteiType] = useState('');

  const [kouteiId, setKouteiId] = useState('');
  const [kouteiCode, setKouteiCode] = useState('');
  const [kouteiName, setKouteiName] = useState('');
  const [kakouHouhou, setKakouHouhou] = useState([]);
  const [kakouId, setKakouId] = useState();
  const [kakouName, setKakouName] = useState([]);
  const [kakouCode, setKakouCode] = useState([]);
  const [souchiCode, setSouchiCode] = useState('');
  const [souchiName, setSouchiName] = useState('');
  // 段取時間チャージ金額
  const [prep, setPrep] = useState(0);
  // 作業時間チャージ金額
  const [work, setWork] = useState(0);

  // 追加工程種類
  const [addProcessTypeId, setAddProcessTypeId] = useState('');
  // const [tsuikaKouteiCode, setTsuikaKouteiCode] = useState('');
  const [addProcessTypes, setAddProcessTypes] = useState([]);

  // 初期金額(手入力時)
  const [initPrice, setInitPrice] = useState(0);

  const [selectedData, setSelectedData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [initialTblItems, setInitialTableItems] = useState([]); // 初期表示に表示する項目
  const [initialTblItemsBk, setInitialTableItemsBk] = useState([]); // 初期表示に表示する項目
  const [tmpFilteredData, setTmpFilteredData] = useState();

  // 検索
  const [process, setProcess] = useState('');

  // 委託工程
  const [isCommission, setIsCommission] = useState(false);
  const [selectDisabled, setSelectDisabled] = useState(true);

  // 手入力フラグ
  const [isManualInput, setIsManualInput] = useState(false);
  const [inputDisabled, setInputDisabled] = useState(true);

  const [customerModal, setCustomerModal] = useState(false);

  // 複写
  const [copyDeviceList, setCopyDeviceList] = useState([]);
  const [copyDeviceId, setCopyDeviceId] = useState('');

  // 取引先情報
  const [customerId, setCustomerId] = useState(0);
  const [customerName, setCustomerName] = useState('');
  const [customerCode, setCustomerCode] = useState('');

  // 備考
  const [remarks, setRemarks] = useState('');

  const [flag, setFlag] = useState(false);

  const [weldLineMaster, setWeldLineMaster] = useState([]);
  const [areaMaster, setAreaMaster] = useState([]);
  const [weightMaster, setWeightMaster] = useState([]);
  const [volumeMaster, setVolumeMaster] = useState([]);

  const [suuryouKubunMaster, setSuuryouKubunMaster] = useState([]);
  // 要素数
  const [yousosuuMaster, setYousosuuMaster] = useState([]);

  // 溶接難易度
  const [yousetsuNanidoItaatsuMaster, setYousetsuNanidoItaatsuMaster] = useState([]);
  // 仮付き時間
  const [karitsukiJikanItaatsuMaster, setKaritsukiJikanItaatsuMaster] = useState([]);

  // 取付き時間
  const [toritsukiJikanItaatsuMaster, setToritsukiJikanItaatsuMaster] = useState([]);
  const [toritsukiJikanSizeMaster, setToritsukiJikanSizeMaster] = useState([]);

  // 加工速度（板厚）
  const [kakouSokudoItaatsuMaster, setKakouSokudoItaatsuMaster] = useState([]);

  // 名称
  const [meishouMaster, setMeishouMasterData] = useState([]);

  // 個数
  const [kosuuMaster, setKosuuMaster] = useState([]);

  // 段取時間
  const [prepBasicTime, setPrepBasicTime] = useState(0);
  // 作業時間(1箇所当たり)
  const [workBasicTime, setWorkBasicTime] = useState(0);

  const [kouteiMaster, setKouteiMaster] = useState([]);

  const [yousetsuSenChouMasterData, setYousetsuSenChouMasterData] = useState([]);
  const [yousetsuToritsukiSizeMasterData, setYousetsuToritsukiSizeMasterData] = useState([]);
  const [yousetsuToritsukiItaatsuMasterData, setYousetsuToritsukiItaatsuMasterData] = useState([]);
  const [yousetsuTypeMasterData, setYousetsuTypeMasterData] = useState([]);

  // 材質区分
  const [zaishitsuKubunn, setZaishitsuKubunn] = useState([]);
  const [customers, setCustomers] = useState([]);

  // 絞込
  const [koteimei, setKoteimei] = useState();
  const [device, setDevice] = useState('');
  const [nameKeyword, setNameKeyWord] = useState('');

  // iq3
  const [iq3Itaatsu, setIQ3Itaatsu] = useState([]);
  // ガス種類
  const [gasType, setGasType] = useState([]);

  const [retainedMold, setRetrainedMold] = useState([]);
  // ジョイントサイズ
  const [jointSize, setJointSize] = useState([]);
  // 曲げ時間係数
  const [lengthMaster, setLengthMaster] = useState([]);
  // 曲げ数
  const [mageSuuMaster, setMageSuuMaster] = useState([]);
  // 曲げ個数
  const [mageKosuuMaster, setMageKosuuMaster] = useState([]);
  // 面積
  const [iq3AreaMaster, setIQ3AreaMaster] = useState([]);
  // 加工長
  const [iq3Kakouchou, setIQ3Kakouchou] = useState([]);
  // 生産個数
  const [seisanKosuuMaster, setSeisanKosuuMaster] = useState([]);
  // 本数
  const [honsuuMaster, setHonsuuMaster] = useState([]);
  // サイズ
  const [iq3SizeMaster, setIQ3SizeMaster] = useState([]);
  const [mmMaster, setMmMaster] = useState([]);
  const [itaatsu1Master, setItaatsu1Master] = useState([]);
  // ２次加工の種類
  const [secondProcessTypes, setSecondProcessTypes] = useState([]);

  // 取引先情報リスト
  const [clientInfos, setClientInfos] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);

  const [showEdtConfirmOK, setShowEdtConfirmOK] = useState();
  const [showEdtConfirmCancle, setShowEdtConfirmCancle] = useState();

  const handleDropdown = () => {
    setFlag(true);
  };

  const location = useLocation();
  const [path, setPath] = useState('');

  const currentRoute = location.pathname;

  const inputItemRef = {};

  useEffect(() => {
    getDeviceMstData();
  }, [currentRoute]);

  useEffect(() => {
    // 絞り込み、
    getDataByFilter();
  }, [nameKeyword, koteimei, tmpFilteredData]);

  useEffect(() => {
    if (props.showConfirmMsgs !== undefined) {
      setShowEdtConfirmOK(props.showConfirmMsgs?.editOk);
      setShowEdtConfirmCancle(props.showConfirmMsgs?.editCancel);
    } else {
      setShowEdtConfirmOK(true);
      setShowEdtConfirmCancle(true);
    }
  }, [props.showConfirmMsgs]);

  // 装置マスタ情報を取得する
  const getDeviceMstData = async () => {
    // let processMstData = await getProcessMstData(); // 工程マスタ情報を取得する
    // await getProcessMstData();
    await getMaterialTypeData();
    await getClientInfoAPI();
    let deviceMstData = await getDeviceInfo(); // 装置マスタ情報を取得する

    let deviceLst = [];
    // let kosuu = [];
    // // 個数
    // kosuu =
    //   localStorage.getItem('kosuuMasterData') != undefined ? JSON.parse(localStorage.getItem('kosuuMasterData')) : [];
    // const kosuuMaster1 = kosuu?.filter((subitem) => subitem.displayFlag === true);

    // setKosuuMaster(kosuuMaster1);

    // setKouteiMaster(processMstData);

    if (currentRoute?.includes('parent')) {
      setPath(':parent.device');
      navigate(':parent.device');

      const parentKouteiMst = await getProcessMstData(ServiceClass.Parent);
      setKouteiMaster(parentKouteiMst);
      deviceLst = deviceMstData?.filter(
        (item) => parentKouteiMst?.findIndex((process) => process?.id === item?.processId) > -1
      );

      // deviceLst =
      //   localStorage.getItem('parent_devicesMaster') != undefined
      //     ? JSON.parse(localStorage.getItem('parent_devicesMaster'))
      //     : [];
      // deviceLst = deviceLst?.filter((item) => item.type === '親部品');
      // 個数
      // if (deviceLst?.length > 0) {
      //   const kosuuUpdList = updateList2(
      //     deviceLst[deviceLst?.length - 1].kosuuMasterData,
      //     kosuuMaster1,
      //     deviceLst[deviceLst?.length - 1].kosuuLstData
      //   );

      //   deviceLst[deviceLst?.length - 1]['kosuuLstData'] = kosuuUpdList;
      //   deviceLst[deviceLst?.length - 1]['kosuuMasterData'] = kosuuMaster1;
      // }

      // setSelectedData(deviceLst?.length > 0 ? deviceLst[0] : []);
      // setSelectedRowKeys(deviceLst?.length > 0 ? deviceLst[0]?.id : 0);
      // setInitialTableItems(deviceLst);
      // setInitialTableItemsBk(deviceLst);

      // 取付Masterデータ（板厚とサイズ）
      // const yousetsuTypeMasterData = result
      //   ?.filter((i) => i.processType === '溶接')[0]
      //   .processDetail[0].processDetailValue?.map((i) => {
      //     if (i.displayFlg === true) {
      //       return i.detailType;
      //     }
      //   });

      // setYousetsuTypeMasterData(yousetsuTypeMasterData);
      // 取付Masterデータ（板厚とサイズ）
      // const yousetsuToritsukiItaatsuMasterData = result
      //   ?.filter((i) => i.processType === '溶接')[0]
      //   .processDetail[1].processDetailValue?.map((i) => {
      //     if (i.displayFlg === true) {
      //       return i.detailType;
      //     }
      //   });
      // setYousetsuToritsukiItaatsuMasterData(yousetsuToritsukiItaatsuMasterData);
      // const yousetsuToritsukiSizeMasterData = result
      //   ?.filter((i) => i.processType === '溶接')[0]
      //   .processDetail[2].processDetailValue?.map((i) => {
      //     if (i.displayFlg === true) {
      //       return i.detailType;
      //     }
      //   });
      // setYousetsuToritsukiSizeMasterData(yousetsuToritsukiSizeMasterData);
      // 溶接線長
      // const yousetsuSenChouMasterData = result
      //   ?.filter((i) => i.processType === '溶接仕上')[0]
      //   .processDetail[0].processDetailValue?.map((i) => {
      //     if (i.displayFlg === true) {
      //       return i.detailType;
      //     }
      //   });
      // setYousetsuSenChouMasterData(yousetsuSenChouMasterData);

      // const data = getKubunNameDDL();

      // const zaishitsu = data?.map((i) => {
      //   return i.label;
      // });
      // setZaishitsuKubunn(zaishitsu);
    } else {
      navigate(':iq3.device');
      const iq3KouteiMst = await getProcessMstData(ServiceClass.SheetMetal);
      setKouteiMaster(iq3KouteiMst);
      // const result = deviceMstData?.filter((i) => i.type === 'iQ3');
      // setKouteiMaster(result);
      // deviceLst =
      //   localStorage.getItem('iq3_devicesMaster') != undefined
      //     ? JSON.parse(localStorage.getItem('iq3_devicesMaster'))
      //     : [];

      // deviceLst = deviceLst?.filter((item) => item.type === 'iQ3');

      deviceLst = deviceMstData?.filter(
        (item) => iq3KouteiMst?.findIndex((process) => process?.id === item?.processId) > -1
      );

      // setSelectedData(deviceLst?.length > 0 ? deviceLst[0] : []);
      // setSelectedRowKeys(deviceLst?.length > 0 ? deviceLst[0]?.id : 0);
      // setInitialTableItems(deviceLst);
      // setInitialTableItemsBk(deviceLst);

      // setMageKosuuMaster(mageKosuuData);
      // setIQ3AreaMaster(iq3areaMasterData);
      // setSeisanKosuuMaster(iq3SeisanKosuuMasterData);
      // setHonsuuMaster(iq3honsuuMasterData);
      // setIQ3SizeMaster(iq3SizeMasterData);
      // setMmMaster(iq3mmMasterData);
      // setItaatsu1Master(yousetsuNanidoItaatsuMasterData);
      // setIQ3Kakouchou(kakouchouMasterData);
      // const data = getKubunNameDDL();
      // const zaishitsu = data?.map((i) => {
      //   return i.label;
      // });
      // setZaishitsuKubunn(zaishitsu);
      // 取付Masterデータ（板厚とサイズ）
      // const yousetsuTypeMasterData = result
      //   ?.filter((i) => i.processType === '溶接')[0]
      //   .processDetail[0].processDetailValue?.map((i) => {
      //     if (i.displayFlg === true) {
      //       return i.detailType;
      //     }
      //   });
      // setYousetsuTypeMasterData(yousetsuTypeMasterData);
      // 取付Masterデータ（板厚とサイズ）
      // const yousetsuToritsukiItaatsuMasterData = result
      //   ?.filter((i) => i.processType === '溶接')[0]
      //   .processDetail[1].processDetailValue?.map((i) => {
      //     if (i.displayFlg === true) {
      //       return i.detailType;
      //     }
      //   });
      // setYousetsuToritsukiItaatsuMasterData(yousetsuToritsukiItaatsuMasterData);
      // const yousetsuToritsukiSizeMasterData = result
      //   ?.filter((i) => i.processType === '溶接')[0]
      //   .processDetail[2].processDetailValue?.map((i) => {
      //     if (i.displayFlg === true) {
      //       return i.detailType;
      //     }
      //   });
      // setYousetsuToritsukiSizeMasterData(yousetsuToritsukiSizeMasterData);
      // 溶接線長
      // const yousetsuSenChouMasterData = result
      //   ?.filter((i) => i.processType === '溶接仕上')[0]
      //   .processDetail[0].processDetailValue?.map((i) => {
      //     if (i.displayFlg === true) {
      //       return i.detailType;
      //     }
      //   });
      // setYousetsuSenChouMasterData(yousetsuSenChouMasterData);

      setPath(':iq3.device');
    }

    setSelectedData(deviceLst?.length > 0 ? deviceLst[0] : []);
    setSelectedRowKeys(deviceLst?.length > 0 ? deviceLst[0]?.id : 0);
    setInitialTableItems(deviceLst);
    setInitialTableItemsBk(deviceLst);

    // setTblColumns(devicesColumns);

    setWeldLineMaster(weldLineMasterData);

    // 製品重量係数
    setAreaMaster(areaMasterData);
    setWeightMaster(weightMasterData);
    setVolumeMaster(taisekiMasterData); //体積

    setMeishouMasterData(meishouMasterData);
    setSuuryouKubunMaster(suuryouKubunMasterData);
    setYousosuuMaster(yousosuuMasterData);
    setYousetsuNanidoItaatsuMaster(yousetsuNanidoItaatsuMasterData);
    setKaritsukiJikanItaatsuMaster(karitsukiJikanItaatsuMasterData);
    setToritsukiJikanItaatsuMaster(karitsukiJikanItaatsuMasterData);

    setToritsukiJikanSizeMaster(sizeMasterData);
    setKakouSokudoItaatsuMaster(itaatsuMasterData);

    // iq3
    setIQ3Itaatsu(iq3ItaatsuData);
    setGasType(gasTypeData);
    setRetrainedMold(retainedMoldData);
    setJointSize(jointSizeData);
    setLengthMaster(lengthMasterData);
    setMageSuuMaster(mageSuuData);
    setCurrentPage(1);
  };

  // 工程マスタ情報を取得する
  const getProcessMstData = async (classId) => {
    let processMstData = await getProcessInfo();
    processMstData = processMstData?.filter((process) => process?.class === classId);
    return processMstData;
  };

  // 工程マスタレコード情報をIDで取得する
  const getProcessMstRecordById = (id) => {
    return [...kouteiMaster]?.filter((record) => record?.id === id)?.[0];
  };
  // 工程マスタレコード情報をWorkTypeで取得する
  const getProcessMstRecordByWorkType = () => {
    return [...kouteiMaster]?.filter((record) => record?.workType === 10600)?.[0];
  };

  // 材質区分マスタ情報を取得する
  const getMaterialTypeData = async () => {
    const materialTypeData = await getMaterialTypeInfo();
    setZaishitsuKubunn(materialTypeData);
    // getMaterialTypeInfo
  };

  const getClientInfoAPI = async () => {
    const result = await getClientInfo();
    setCustomers(result);
  };
  // テーブルColumns
  const columns = [
    {
      key: 'No',
      title: 'No',
      dataIndex: 'no',
      sortOrder: 'ascend',
      width: '55px',
      className: 'cm-a-right',
      render: (id, record, index) => {
        return (currentPage - 1) * defaultPageSize + (index + 1);
      },
    },
    {
      key: 'processType',
      title: '種別',
      dataIndex: 'processId',
      width: '65px',
      render: (processId) =>
        getProcessMstRecordById(processId)?.isAdditional ? processTypes.additional : processTypes.standard,
    },
    {
      key: 'processcode',
      title: 'コード',
      dataIndex: 'processId',
      width: '85px',
      render: (processId) => getProcessMstRecordById(processId)?.code,
    },
    {
      key: 'processName',
      title: '工程名',
      dataIndex: 'processId',
      width: '160px',
      render: (processId) => getProcessMstRecordById(processId)?.name,
    },
    {
      key: 'deviceCode',
      title: '装置コード',
      dataIndex: 'code',
      width: '85px',
    },
    {
      key: 'deviceName',
      title: '装置名',
      dataIndex: 'name',
      width: '225px',
    },
    {
      key: 'prepPrice',
      title: '段取チャージ',
      dataIndex: 'details',
      width: '120px',
      className: 'cm-a-right',
      render: (details, record) => (
        <label>
          {getProcessMstRecordById(record?.processId)?.isAdditional && details?.charges?.prep >= 0
            ? JPYs.format(details?.charges?.prep)
            : '-'}
        </label>
      ),
    },
    {
      key: 'workPrice',
      title: '加工チャージ',
      dataIndex: 'details',
      width: '120px',
      className: 'cm-a-right',
      render: (details, record) => (
        <label>
          {getProcessMstRecordById(record?.processId)?.isAdditional && details?.charges?.work >= 0
            ? JPYs.format(details?.charges?.work)
            : '-'}
        </label>
      ),
    },
    {
      key: 'isMultiChargePrice',
      title: 'チャージ金額複数有',
      dataIndex: 'processId',
      width: '135px',
      className: 'cm-a-center',
      render: (processId) =>
        getProcessMstRecordById(processId)?.isAdditional ? (
          '-'
        ) : (
          <a onClick={handleDropdown} style={{ textDecoration: 'underline', color: '#1063aa' }}>
            {'有'}
          </a>
        ),
    },
    {
      key: 'remarks',
      title: '備考',
      dataIndex: 'info',
      render: (text, record, index) => <span>{record?.info?.remarks}</span>,
      width: 250,
    },
  ];

  const tableColumns = columns?.map((item) => ({
    ...item,
  }));

  const setSplitPaneSize = (splitSize) => {
    setSizes(splitSize);
  };

  // 編集モードを変更
  const updateEditMode = (mode) => {
    setEditMode(mode);
  };

  const showAddNewModal = async () => {
    setClickedAction(2);
    if (editMode) {
      // setEditModeCancelConfirm(true);
      //setEditModeCancelConfirm(showEdtConfirmCancle);
      let moveInEdit = props.showConfirmMsgs.moveInEdit == undefined ? true : props.showConfirmMsgs.moveInEdit;
      setEditModeCancelConfirm(moveInEdit);
      if (!moveInEdit) {
        setEditMode(false);
        setIsNew(true);
        setKouteiType('');
        setKouteiCode('');
        setSouchiCode('');
        setSouchiName('');
        setCopyDeviceList([]);
        setCopyDeviceId('');
        setCategory(processTypes.standard);
        setAddProcessTypeId('');
        setIsManualInput(false);
        setIsCommission(false);
        setSelectDisabled(true);
        setInputDisabled(true);
        setPrepBasicTime(0);
        setWorkBasicTime(0);
        setPrep(0);
        setWork(0);
      }
    } else {
      let result = await getClientInfo(); // 取引先情報を得る
      setClientInfos(result);
      setIsNew(true);
      setKakouCode('');
      setKakouHouhou([]);
      setKakouId();
      setKakouName('');
      setKouteiType('');
      setKouteiCode('');
      setSouchiCode('');
      setSouchiName('');
      setCopyDeviceList([]);
      setCopyDeviceId('');
      setCategory(processTypes.standard);
      setAddProcessTypeId('');
      setIsManualInput(false);
      setIsCommission(false);
      setSelectDisabled(true);
      setInputDisabled(true);
      setInitPrice(0);
      setPrepBasicTime(0);
      setWorkBasicTime(0);
      setPrep(0);
      setWork(0);
      setKouteiName('');
      setKouteiId('');
    }
  };

  const showDeleteModal = () => {
    setClickedAction(3);
    if (editMode) {
      let moveInEdit = props.showConfirmMsgs.moveInEdit == undefined ? true : props.showConfirmMsgs.moveInEdit;
      setEditModeCancelConfirm(moveInEdit);
      if (!moveInEdit) {
        discardConfirmOk();
      }
    } else {
      if (selectedData?.length != 0) {
        setIsDelete(true);
      } else {
        alert('データを選択してください');
      }
    }
  };

  const deleteModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>確認</p>
    </div>
  );

  const deleteOk = async () => {
    setIsDelete(false);
    if (!selectedData?.id) return;
    let deletedData = await deleteDeviceData(selectedData?.id);

    let updatedData = [...initialTblItemsBk];
    updatedData = updatedData?.filter((item) => item.id !== deletedData?.id);

    // for (let i = 0; i < updatedData?.length; i++) {
    //   updatedData[i].deviceNo = i + 1;
    // }

    // setInitialTableItems(updatedData);
    setTmpFilteredData(updatedData?.length === 0 ? [] : updatedData);
    setInitialTableItemsBk(updatedData?.length === 0 ? [] : updatedData);
    if (updatedData?.length === 0) {
      setInitialTableItems([]);
    }
    // setSelectedRowKeys(updatedData?.length > 0 ? updatedData[updatedData?.length - 1].deviceNo : 0);
    // setSelectedData(updatedData?.length > 0 ? updatedData[updatedData?.length - 1] : []);
    setSelectedRowKeys(updatedData?.length > 0 ? updatedData[0]?.id : 0);
    setSelectedData(updatedData?.length > 0 ? updatedData[0] : []);
    // if (currentRoute?.includes('parent')) {
    //   localStorage.setItem('parent_devicesMaster', JSON.stringify(updatedData));
    // } else {
    //   localStorage.setItem('iq3_devicesMaster', JSON.stringify(updatedData));
    // }
    setCurrentPage(1);
  };

  // 装置マスタ情報をDBに削除する
  async function deleteDeviceData(id) {
    let ret = [];
    try {
      const Url = envProperties.REACT_APP_BACKEND_API_URL + 'Device/' + id;
      const otherParam = {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + getAccessToken(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': envProperties.REACT_APP_API_KEY_AUTH,
        },
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          return await response.json();
        })
        .then((data) => {
          if (data && data?.updateToken) {
            // セクションにトークン情報を更新する
            updateAccessToken(data?.updateToken);
          }
          if (data && data.statusCode === StatusCodes.OK) {
            ret = data.data[0];
          } else if (
            data &&
            (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)
          ) {
            authorizeError(ErrorMessage()[data?.messageCode]);
            return;
          } else if (data && data.statusCode !== StatusCodes.OK) {
            error(ErrorMessage()[data?.messageCode]);
            return;
          }
        })
        .catch((e) => {
          authorizeError(ErrorMessage().E010);
          return;
        });
    } catch (e) {
      error(e.message);
      return;
    }
    return ret;
  }

  const deleteCancel = () => {
    setIsDelete(false);
  };

  useImperativeHandle(ref, () => ({
    setSplitPaneSize: setSplitPaneSize,
    showAddNewModal: showAddNewModal,
    showDeleteModal: showDeleteModal,
  }));

  // 新規作成一覧のヘッダー
  const addNewModalTitle = (
    <div
      style={{
        width: 600,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>新規作成</p>
    </div>
  );

  // 新規作成のOKボタン押下
  const addData = () => {
    setIsUpdateData(true);

    /* setIsUpdateData(showEdtConfirmCancle);
    if (!showEdtConfirmCancle) {
      updateDataOk
    } */
  };

  // 新規作成のCancelボタン押下
  const cancelData = () => {
    // setIsDiscardData(true);

    setIsDiscardData(showEdtConfirmCancle);
    if (!showEdtConfirmCancle) {
      discardOk();
    }
  };

  const createDetailsListData = (type, masterData, kubunTypes, itaatsuMasterData) => {
    const data = createJikanListData(kubunTypes, itaatsuMasterData);

    const result = type?.map((materialType, key) => {
      const filteredData2 = masterData
        ?.filter((subitem) => subitem.displayFlag === true)
        ?.map((item) => {
          return {
            key: item.id,
            value: '00:00:00',
            list: data,
          };
        });

      return {
        key: key + 1,
        materialType,
        lstData: filteredData2,
        isUsed: false,
      };
    });
    return result;
  };

  const createList = (masterData) => {
    const filteredData2 = masterData
      ?.filter((subitem) => subitem.displayFlag === true)
      ?.map((item) => {
        return {
          key: item.id,
          val: '',
        };
      });

    return filteredData2;
  };
  const createKosuuList = (masterData) => {
    let temp = masterData?.filter((i) => i.displayFlag === true);
    const lastObject = temp[temp?.length - 1];
    let valOfLastObject = lastObject.val + 1;
    let addData = { id: temp?.length + 1, val: valOfLastObject, displayFlag: true };

    const data = [...masterData, addData];

    const filteredData2 = data
      ?.filter((subitem) => subitem.displayFlag === true)
      ?.map((item) => {
        return {
          key: item.id,
          val: '',
        };
      });

    return filteredData2;
  };

  // 登録確認メッセージのOKボタン押下
  const updateDataOk = async () => {
    let createdData = {};
    // setInitialTableItems(initialTblItemsBk); //filtertesting
    setIsUpdateData(false);
    if (isNew == true) {
      // 画面の入力チェック
      if (!formValidation(inputItemRef)) {
        return;
      }
      setIsNew(false);
      const kouteiData = kouteiMaster?.filter((i) => i.id === kouteiId)?.[0];
      if (kouteiData.isAdditional) {
        let createData = {
          processId: kouteiId,
          code: souchiCode,
          name: souchiName,
          details: {
            charges: {
              prep: prep === 0 ? 4200 : prep,
              work: work === 0 ? 4200 : work,
            },
            clientId: customerId,
            initPrice: initPrice,
            addProcessTypeId: addProcessTypeId,
            prepBasicTime: prepBasicTime,
            workBasicTime: workBasicTime,
            isCommission: isCommission,
            isManualInput: isManualInput,
          },
          info: { remarks: remarks },
        };
        createdData = await createDeviceData(createData);
      } else if (copyDeviceId) {
        let orgDeviceData = [...initialTblItemsBk];
        let copyRow = orgDeviceData?.filter((item) => item.id === copyDeviceId)?.[0];
        let copyData = { ...copyRow };
        copyData.processId = kouteiId;
        copyData.name = souchiName;
        copyData.code = souchiCode;
        let createData = {
          processId: copyData.processId,
          code: copyData.code,
          name: copyData.name,
          details: copyData.details,
        };
        createdData = await createDeviceData(createData);

        // const data = {
        //   key: initialTblItemsBk?.length + 1,
        //   type: copyRow.type,
        //   deviceNo: initialTblItemsBk?.length + 1,
        //   deviceTypes: category, //種別
        //   code: code, // コード
        //   processName: kouteiName, //工程名
        //   processType: copyRow.processType, // 工程タイプ
        //   deviceCode: souchiCode, // 装置コード
        //   deviceName: souchiName, // 装置名
        //   setupCharge: category === processTypes.standard ? '-' : setupData, // 段取チャージ
        //   processCharge: category === processTypes.standard ? '-' : workData, // 加工チャージ
        //   multiCharge: category === processTypes.standard ? '有' : '', //チャージ金額複数有
        //   remarks: copyRow.remarks, // 備考
        //   processDetails: copyRow.processDetails, // チャージ金額

        //   kihonDantoriTime: copyRow.kihonDantoriTime,

        //   processTimeByUnit: copyRow.processTimeByUnit,

        //   meishouDetails: copyRow.meishouDetails,
        //   jikanChajji: copyRow.jikanChajji,

        //   kouteiType: tsuikaKouteiType,
        //   kouteiCode: tsuikaKouteiCode,

        //   yousetsuSenchouMasterData: copyRow.yousetsuSenchouMasterData,
        //   karitsukiJikanItaatsuMasterData: copyRow.karitsukiJikanItaatsuMasterData,
        //   toritsukiJikanItaatsuMasterData: copyRow.karitsukiJikanItaatsuMasterData,

        //   yousetsuNanidoItaatsuMasterData: copyRow.yousetsuNanidoItaatsuMasterData,

        //   areaMasterData: copyRow.areaMasterData,
        //   weightMasterData: copyRow.weightMasterData,
        //   volumeMasterData: copyRow.volumeMasterData,
        //   kosuuMasterData: copyRow.kosuuMasterData,

        //   yousetsuShiageSenchouMasterData: copyRow.yousetsuShiageSenchouMasterData,
        //   yousetsuShiageSenchouLstData: copyRow.yousetsuShiageSenchouLstData,
        //   yousetsuSenchouLstData: copyRow.yousetsuSenchouLstData,
        //   karitsukiJikanItaatsuLstData: copyRow.karitsukiJikanItaatsuLstData,
        //   toritsukiJikanSizeMasterData: copyRow.toritsukiJikanSizeMasterData,
        //   toritsukiJikanSizeLstData: copyRow.toritsukiJikanSizeLstData,
        //   toritsukiJikanItaatsuLstData: copyRow.toritsukiJikanItaatsuLstData,
        //   yousetsuNanidoItaatsuLstData: copyRow.yousetsuNanidoItaatsuLstData,
        //   tapWeldingItaatsuLstData: copyRow.tapWeldingItaatsuLstData,
        //   pitchItaatsuLstData: copyRow.pitchItaatsuLstData,
        //   sizeListData: copyRow.sizeListData,
        //   kosuuLstData: copyRow.kosuuLstData,
        //   dantoriSizeListData: copyRow.dantoriSizeListData,
        //   kobuhinKeisuuMasterData: copyRow.kobuhinKeisuuMasterData,
        //   dantoriSetteiSuuryouLstData: copyRow.dantoriSetteiSuuryouLstData,

        //   // kobuhinKeisuuLstData: copyRow.kobuhinKeisuuLstData,

        //   customerName: customerName,
        //   customerCode: customerCode,
        //   inputFlag: flagChecked,
        //   itakuFlag: checkboxChecked,
        //   setupTime: dantoriTime,
        //   workTime: workTime,
        //   initialAmount: initialAmount,

        //   yousosuuMasterData: copyRow.yousosuuMasterData,
        //   sagyouJikanKeisuuLstData: copyRow.sagyouJikanKeisuuLstData,
        //   shokiDankaiJikanLstData: copyRow.shokiDankaiJikanLstData,
        //   kensaJikanKeisuuLstData: copyRow.kensaJikanKeisuuLstData,

        //   // iQ3
        //   dantoriFlag: copyRow.dantoriFlag,
        //   iq3SetupAmount: copyRow.iq3SetupAmount,
        //   iq3WorkAmount: copyRow.iq3WorkAmount,
        //   iq3ProcessInspection: copyRow.iq3ProcessInspection,
        //   secondProcessAmount: copyRow.secondProcessAmount,
        //   jointBarashiAmount: copyRow.jointBarashiAmount,
        //   kakouType: copyRow.kakouType,
        //   kakouMethods: copyRow.kakouMethods,
        //   kakouCode: copyRow.kakouCode,
        //   iq3ItaatsuMasterData: copyRow.iq3ItaatsuMasterData,
        //   iq3BendingTimeLstData: copyRow.iq3BendingTimeLstData,
        //   // 曲げ時間係数
        //   lengthMasterData: copyRow?.lengthMasterData,
        //   bendingTimeFactorLstData: copyRow.bendingTimeFactorLstData, //段取設定のサイズリスト
        //   iq3BendingkosuuLstData: copyRow.iq3BendingkosuuLstData,
        //   iq3BendingHonsuuLstData: copyRow.iq3BendingHonsuuLstData,
        //   // 曲げ数
        //   mageSuuMasterData: copyRow.mageSuuMasterData,
        //   mageSuuLstData: copyRow.mageSuuLstData,
        //   // 曲げ個数
        //   mageKosuuMasterData: copyRow.mageKosuuMasterData,
        //   mageKosuuLstData: copyRow.mageKosuuLstData,
        //   // dantoriSizeLstData: copyRow.dantoriSizeLstData,
        //   // 曲長さ別段取時間
        //   iq3MagechouDantoriJikanListData: copyRow.iq3MagechouDantoriJikanListData,
        //   // iq3面積
        //   iq3AreaMasterData: copyRow.iq3AreaMasterData,
        //   iq3KakouJikanListData: copyRow.iq3KakouJikanListData,
        //   blankKakousokudoListData: copyRow.blankKakousokudoListData,
        //   // 生産個数
        //   seisanKosuuMasterData: copyRow.seisanKosuuMasterData,
        //   seisanKosuuLstData: copyRow.seisanKosuuLstData,
        //   processInspectionKosuuLstData: copyRow.processInspectionKosuuLstData,
        //   honsuuMasterData: copyRow.honsuuMasterData,
        //   honsuuLstData: copyRow.honsuuLstData,
        //   // 二次加工の加工時間
        //   iq3SizeMasterData: copyRow.iq3SizeMasterData,
        //   iq3MmMasterData: copyRow.iq3MmMasterData,
        //   iq3KaninyuryokuListData: copyRow.iq3KaninyuryokuListData,
        //   iq3KaninyuryokuAnaListData: copyRow.iq3KaninyuryokuAnaListData,
        //   itaatsu1MasterData: copyRow.itaatsu1MasterData,
        //   zaishitsuKubunn: copyRow.zaishitsuKubunn,
        //   // iq3製品重量係数
        //   iq3ProductSizeLstData: copyRow.iq3ProductSizeLstData,
        //   correctionTimeFactorLstData: copyRow.correctionTimeFactorLstData,
        //   // マテリアルハンドリング時間
        //   materialHandlingTime: copyRow.materialHandlingTime,
        //   // 同一サイズ、同一形状をパターン化する
        //   programSameSizePatternValue: copyRow.programSameSizePatternValue,
        //   gasTypeLstData: copyRow.gasTypeLstData,
        //   processInspectionTime: copyRow.processInspectionTime,

        //   punchConditionList: copyRow.punchConditionList,
        //   drillingTime: copyRow.drillingTime,
        //   forwardSpeed: copyRow.forwardSpeed,
        //   rollerSpeed: copyRow.rollerSpeed,
        //   piercingProcessItaatsuLstData: copyRow.piercingProcessItaatsuLstData,
        //   punchProcessItaatsuLstData: copyRow.punchProcessItaatsuLstData,
        //   okuriSokudoVal: copyRow.okuriSokudoVal,
        //   kegakiSokudoVal: copyRow.kegakiSokudoVal,
        //   blankKakousokudoItaatsuList: copyRow.blankKakousokudoItaatsuList,
        //   gasTypeMasterData: copyRow.gasTypeMasterData,
        //   gasKeissuuLstData: copyRow.gasKeissuuLstData,
        //   extractConditionList: copyRow.extractConditionList,
        //   jointSizeListData: copyRow.jointSizeListData,
        //   jointSizeMasterData: copyRow.jointSizeMasterData,
        //   jointItaatsuLstData: copyRow.jointItaatsuLstData,
        //   bendingCorrectionTimeFactorLstData: copyRow.bendingCorrectionTimeFactorLstData,
        //   retrainedMoldMasterData: copyRow.retrainedMoldMasterData,
        //   kakosokudo_bari: copyRow.kakosokudo_bari,
        //   naikei_bari: copyRow.naikei_bari,
        //   naikei_min: copyRow.naikei_min,
        //   naikei_max: copyRow.naikei_max,
        //   ana_min: copyRow.ana_min,
        //   ana_max: copyRow.ana_max,
        //   shirringflag: copyRow.shirringflag,
        //   shirringTime: copyRow.shirringTime,
        //   iq3ShudouBariKakouSokudoListData: copyRow.iq3ShudouBariKakouSokudoListData,
        //   kouteinaiKensaAmount: copyRow.kouteinaiKensaAmount,
        //   nanidoKeisuuLstData: copyRow.nanidoKeisuuLstData,
        //   yousetsuTypeMasterData: copyRow.yousetsuTypeMasterData,
        // };

        // setInitialTableItems([...initialTblItems, data]);
        // setInitialTableItemsBk([...initialTblItemsBk, createdData]);
        // setTmpFilteredData([...initialTblItemsBk, createdData]);
        // setSelectedData(createdData);
        // setCurrentPage(Math.ceil([...initialTblItemsBk, createdData]?.length / 25));
        // if (currentRoute?.includes('parent')) {
        //   localStorage.setItem('parent_devicesMaster', JSON.stringify([...initialTblItemsBk, data]));
        // } else {
        //   localStorage.setItem('iq3_devicesMaster', JSON.stringify([...initialTblItemsBk, data]));
        // }
      } else {
        let createData = {
          processId: kouteiId,
          code: souchiCode,
          name: souchiName,
        };
        createdData = await createDeviceData(createData);

        // let hyoumenshoriDetails = [];
        // hyoumenshoriDetails = kouteiData[0].processDetail;
        // const type = location.pathname?.includes('parent') ? '親部品' : 'iQ3';
        // const getData = kouteiMaster?.filter((i) => i.processType === kouteiType);
        // let result = [];
        // if (getData[0].processDetail?.length > 0) {
        //   result = getData[0].processDetail[0].processDetailValue?.map((i) => {
        //     if (i.displayFlg === true) {
        //       return i.detailType;
        //     }
        //   });
        // }
        // setSecondProcessTypes(result);

        // if (category === processTypes.standard) {
        //   const data = {
        //     key: initialTblItemsBk?.length + 1,
        //     type: location.pathname?.includes('parent') ? '親部品' : 'iQ3',
        //     deviceNo: initialTblItemsBk?.length + 1,
        //     deviceTypes: category,
        //     code: code,
        //     processName: kouteiName,
        //     processType: kouteiType,
        //     deviceCode: souchiCode,
        //     deviceName: souchiName,
        //     setupCharge: '-',
        //     processCharge: '-',
        //     multiCharge: '有',
        //     remarks: '',

        //     // チャージ金額
        //     processDetails: hyoumenshoriDetails?.map((detail) => ({
        //       ...detail,
        //       processDetailValue: detail.processDetailValue?.map(({ key, detailType, displayFlg }) => ({
        //         key,
        //         detailType,
        //         displayFlg,
        //         dantori: defaultCharge,
        //         kakou: defaultCharge,
        //         dantoriTime: '00:00:00',
        //         dantoriSurface: defaultCharge,
        //         dantoriFlag: false,
        //         kakouFlag: false,
        //       })),
        //     })),

        //     meishouDetails: meishouMaster,
        //     kihonDantoriTime: '00:00:00',
        //     processTimeByUnit: '00:00:00',
        //     dantoriFlag: false,
        //     // jikanChajji: jikanchaji,
        //     // kobuhinSuu: kobuhinData,
        //     pitchItaatsuLstData: createListData(zaishitsuKubunn, yousetsuNanidoItaatsuMaster), //点付、仮付ピッチのリストデータ

        //     tapWeldingItaatsuLstData: createListData(zaishitsuKubunn, yousetsuNanidoItaatsuMaster), //点付、仮付ピッチのリストデータ

        //     yousetsuNanidoItaatsuMasterData: yousetsuNanidoItaatsuMaster,
        //     yousetsuNanidoItaatsuLstData: createListData(zaishitsuKubunn, yousetsuNanidoItaatsuMaster),

        //     karitsukiJikanItaatsuMasterData: karitsukiJikanItaatsuMaster,
        //     karitsukiJikanItaatsuLstData: createJikanListData(yousetsuTypeMasterData, karitsukiJikanItaatsuMaster),

        //     toritsukiJikanItaatsuMasterData: karitsukiJikanItaatsuMaster,
        //     toritsukiJikanItaatsuLstData: createJikanListData(
        //       yousetsuToritsukiItaatsuMasterData,
        //       karitsukiJikanItaatsuMaster
        //     ),

        //     toritsukiJikanSizeMasterData: toritsukiJikanSizeMaster,
        //     toritsukiJikanSizeLstData: createJikanListData(yousetsuToritsukiSizeMasterData, toritsukiJikanSizeMaster),

        //     // 親部品溶接の加工速度(mm/min)
        //     yousetsuSenchouMasterData: weldLineMaster,
        //     yousetsuSenchouLstData: createListData(yousetsuTypeMasterData, weldLineMaster),

        //     yousetsuShiageSenchouMasterData: weldLineMaster,
        //     yousetsuShiageSenchouLstData: createListData(yousetsuSenChouMasterData, weldLineMaster),

        //     // 親部品溶接の段取設定
        //     kobuhinKeisuuMasterData: suuryouKubunMaster,
        //     // kobuhinKeisuuLstData: createListData(keisuu, suuryouKubunMaster),
        //     dantoriSetteiSuuryouLstData: createListData(suuryouTypes, suuryouKubunMaster), //段取設定の数量

        //     // 要素数(プログラム)
        //     yousosuuMasterData: yousosuuMaster,
        //     sagyouJikanKeisuuLstData: createKeisuuListData(['作業時間係数'], yousosuuMaster),
        //     shokiDankaiJikanLstData: createJikanListData(['初期段階時間'], yousosuuMaster),
        //     kensaJikanKeisuuLstData: createKeisuuListData(['検査時間係数'], yousosuuMaster),
        //     // meishouMaster
        //     meishouMasterData: meishouMaster,
        //     meishouLstData: createList(meishouMaster),
        //     // 製品重量係数
        //     areaMasterData: areaMaster,
        //     weightMasterData: weightMaster,
        //     // sizeListData: createSizeListData(weightMaster, areaMaster),
        //     sizeListData: createKeisuuListData(['重量係数'], kosuuMaster),
        //     volumeMasterData: volumeMaster,
        //     // dantoriSizeListData: createSizeListData(weightMaster, volumeMaster), //段取設定のサイズリスト
        //     dantoriSizeListData: createKeisuuListData(['重量係数'], weightMaster),
        //     // 個数
        //     kosuuMasterData: kosuuMaster,
        //     kosuuLstData: createKeisuuListData(['生産個数係数'], kosuuMaster),
        //     iq3DantoriJikanKosuuLstData: createKeisuuListData(['加算係数'], kosuuMaster), // 段取時間（生産個数）

        //     // iq3データ
        //     iq3SetupAmount: defaultCharge,
        //     iq3WorkAmount: defaultCharge,
        //     iq3ProcessInspection: defaultCharge,
        //     secondProcessAmount: defaultCharge,
        //     jointBarashiAmount: defaultCharge,
        //     kakouType: kakouName,
        //     kakouCode: kakouCode,
        //     kakouMethods: kakouHouhou,
        //     iq3ItaatsuMasterData: iq3Itaatsu,
        //     gasTypeMasterData: gasType,
        //     retrainedMoldMasterData: retainedMold,
        //     jointSizeMasterData: jointSize,
        //     iq3BendingTimeLstData: createJikanListData(result, iq3Itaatsu),
        //     // 曲げ時間係数
        //     lengthMasterData: lengthMaster,
        //     bendingTimeFactorLstData: createSizeListData(weightMaster, lengthMaster), //段取設定のサイズリスト
        //     bendingCorrectionTimeFactorLstData: createSizeListData(weightMaster, lengthMaster),
        //     correctionTimeFactorLstData: createSizeListData(weightMaster, iq3AreaMaster),
        //     iq3BendingkosuuLstData: createJikanListData(['補正時間'], kosuuMaster),
        //     iq3BendingHonsuuLstData: createJikanListData(['補正時間'], honsuuMaster),
        //     // 曲げ数
        //     mageSuuMasterData: mageSuuMaster,
        //     mageSuuLstData: createJikanListData(result, iq3Itaatsu),
        //     // 曲げ個数
        //     mageKosuuMasterData: mageKosuuMaster,
        //     mageKosuuLstData: createKeisuuListData(['加算係数'], mageKosuuMaster),
        //     // dantoriSizeLstData: createSizeListData(weightMaster, lengthMaster),
        //     // 曲長段取時間
        //     iq3MagechouDantoriJikanListData: createListData(['曲長段取時間'], lengthMaster),
        //     // iq3面積
        //     iq3AreaMasterData: iq3AreaMaster,
        //     iq3KakouJikanListData: createJikanListData(['加工時間'], iq3AreaMaster),
        //     // 生産個数
        //     seisanKosuuMasterData: seisanKosuuMaster,
        //     seisanKosuuLstData: createKeisuuListData(['段取個数係数'], seisanKosuuMaster),
        //     // 工程内検査の生産個数
        //     processInspectionKosuuLstData: createKeisuuListData(['検査時間係数'], seisanKosuuMaster),
        //     // 本数
        //     honsuuMasterData: honsuuMaster,
        //     honsuuLstData: createListData(['時間'], honsuuMaster),
        //     // 二次加工の加工時間
        //     iq3SizeMasterData: iq3SizeMaster,
        //     iq3MmMasterData: mmMaster,
        //     iq3KaninyuryokuListData: createDetailsListData(
        //       result?.filter((item) => !item?.includes('穴')),
        //       iq3SizeMaster,
        //       zaishitsuKubunn,
        //       itaatsu1Master
        //     ),
        //     iq3KaninyuryokuAnaListData: createDetailsListData(
        //       result?.filter((item) => item?.includes('穴')),
        //       mmMaster,
        //       zaishitsuKubunn,
        //       itaatsu1Master
        //     ),

        //     itaatsu1MasterData: itaatsu1Master,
        //     zaishitsuKubunn: zaishitsuKubunn,
        //     // iq3サイズ係数
        //     iq3ProductSizeLstData: createSizeListData(weightMaster, iq3AreaMaster),

        //     // マテリアルハンドリング時間
        //     materialHandlingTime: createJikanSizeListData(weightMaster, iq3AreaMaster),

        //     // 同一サイズ、同一形状をパターン化する
        //     programSameSizePatternValue: 5,
        //     // ガス種類
        //     gasTypeLstData: createGasListData(zaishitsuKubunn, iq3Itaatsu),
        //     jointItaatsuLstData: createListData(zaishitsuKubunn, iq3Itaatsu),
        //     blankKakousokudoItaatsuList: createListData(['内加工', '外加工'], iq3Itaatsu),
        //     piercingProcessItaatsuLstData: createListData(zaishitsuKubunn, iq3Itaatsu),
        //     punchProcessItaatsuLstData: createListData(zaishitsuKubunn, iq3Itaatsu),
        //     blankKakousokudoListData: createKakouSokudoListData(zaishitsuKubunn, iq3Itaatsu),
        //     gasKeissuuLstData: createKeisuuListData(['ガス係数'], gasType),
        //     // 難易度係数
        //     nanidoKeisuuLstData: createKeisuuListData(['難易度係数'], iq3Itaatsu),

        //     // 外形追抜条件
        //     extractConditionList: createListData(['加工辺長(mm)'], retainedMold),
        //     jointSizeListData: createKeisuuListData(['ジョイント個数'], jointSize),

        //     // 手動バリ取りの加工速度
        //     iq3KakouchouMasterData: iq3Kakouchou,
        //     // iq3ShudouBariKakouSokudoListData: createSizeListData(iq3Itaatsu, iq3Kakouchou),
        //     iq3ShudouBariKakouSokudoListData: createSizeListData(iq3Kakouchou, iq3Itaatsu),

        //     // 工程内検査の基本段取時間
        //     processInspectionTime: '00:00:00',
        //     workTime: workTime,
        //     // 材料配置
        //     pierWidth1: 0,
        //     pierWidth2: 0,
        //     grabAllowance1: 0,
        //     grabAllowance2: 0,
        //     processRange1: 0,
        //     processRange2: 0,
        //     pickupTime: 0,
        //     // パンチ条件
        //     punchConditionList: punchData,
        //     // 2工程穴加工時間
        //     drillingTime: '00:00:00',
        //     forwardSpeed: '',
        //     rollerSpeed: '',
        //     okuriSokudoVal: 0,
        //     kegakiSokudoVal: 0,
        //     kakosokudo_bari: 0,
        //     naikei_bari: 0,
        //     naikei_min: 0,
        //     naikei_max: 0,
        //     ana_min: 0,
        //     ana_max: 0,
        //     shirringflag: false,
        //     shirringTime: '00:00:00',
        //     kouteinaiKensaAmount: defaultCharge,
        //     // 溶接種類
        //     yousetsuTypeMasterData: yousetsuTypeMasterData,
        //     shiageFlagListData: createShiageFlagData(['難易度係数'], yousetsuTypeMasterData),
        //   };

        //   // setInitialTableItems([...initialTblItems, data]);
        //   setInitialTableItemsBk([...initialTblItemsBk, data]);
        //   setTmpFilteredData([...initialTblItemsBk, data]);
        //   setCurrentPage(Math.ceil([...initialTblItemsBk, data]?.length / 25));

        //   setSelectedData(data);
        //   // if (currentRoute?.includes('parent')) {
        //   //   localStorage.setItem('parent_devicesMaster', JSON.stringify([...initialTblItemsBk, data]));
        //   // } else {
        //   //   localStorage.setItem('iq3_devicesMaster', JSON.stringify([...initialTblItemsBk, data]));
        //   // }
        // } else {
        //   const data = {
        //     key: initialTblItemsBk?.length + 1,
        //     type: location.pathname?.includes('parent') ? '親部品' : 'iQ3',
        //     deviceNo: initialTblItemsBk?.length + 1,
        //     deviceTypes: category,
        //     code: code,
        //     processName: kouteiName,
        //     processType: kouteiType,
        //     deviceCode: souchiCode,
        //     deviceName: souchiName,
        //     setupCharge: setupData,
        //     processCharge: workData,
        //     iq3SetupAmount: setupData,
        //     iq3WorkAmount: workData,
        //     multiCharge: '',
        //     remarks: remarks,
        //     tsuikaKouteiTypes: tsuikaKouteiTypes,

        //     customerName: customerName,
        //     customerCode: customerCode,
        //     inputFlag: flagChecked,
        //     itakuFlag: checkboxChecked,
        //     setupTime: dantoriTime,
        //     workTime: workTime,

        //     // 生産個数
        //     seisanKosuuMasterData: seisanKosuuMaster,
        //     seisanKosuuLstData: createListData(['段取個数係数'], seisanKosuuMaster),
        //     // チャージ金額
        //     processDetails: hyoumenshoriDetails?.map((detail) => ({
        //       ...detail,
        //       processDetailValue: detail.processDetailValue?.map(({ key, detailType, displayFlg }) => ({
        //         key,
        //         detailType,
        //         displayFlg,
        //       })),
        //     })),

        //     kihonDantoriTime: '00:00:00',
        //     processTimeByUnit: '00:00:00',
        //     // jikanChajji: jikanchaji,
        //     // kobuhinSuu: kobuhinData,
        //     kouteiType: tsuikaKouteiType,
        //     kouteiCode: tsuikaKouteiCode,
        //     initialAmount: initialAmount,
        //     areaMasterData: areaMaster,
        //     weightMasterData: weightMaster,
        //     iq3AreaMasterData: iq3AreaMaster,
        //     correctionTimeFactorLstData: createSizeListData(weightMaster, iq3AreaMaster),
        //     materialHandlingTime: createJikanSizeListData(weightMaster, iq3AreaMaster),
        //     // iq3サイズ係数
        //     iq3ProductSizeLstData: createSizeListData(weightMaster, iq3AreaMaster),
        //     // 曲げ時間係数
        //     lengthMasterData: lengthMaster,
        //     bendingTimeFactorLstData: createSizeListData(weightMaster, lengthMaster),
        //     // 個数
        //     kosuuMasterData: kosuuMaster,
        //     kosuuLstData: createListData(['生産個数係数'], kosuuMaster),
        //     // 工程内検査の生産個数
        //     processInspectionKosuuLstData: createListData(['検査時間係数'], seisanKosuuMaster),
        //     // 工程内検査の基本段取時間
        //     processInspectionTime: '00:00:00',
        //     // dantoriSizeLstData: createSizeListData(weightMaster, lengthMaster),
        //     kobuhinKeisuuMasterData: suuryouKubunMaster,
        //     dantoriSetteiSuuryouLstData: createListData(suuryouTypes, suuryouKubunMaster),
        //     dantoriSizeListData: createListData(['重量係数'], weightMaster),
        //     sizeListData: createListData(['重量係数'], kosuuMaster),
        //   };

        //   // setInitialTableItems([...initialTblItems, data]);
        //   setInitialTableItemsBk([...initialTblItemsBk, data]);
        //   setTmpFilteredData([...initialTblItemsBk, data]);
        //   setCurrentPage(Math.ceil([...initialTblItemsBk, data]?.length / 25));
        //   setSelectedData(data);
        //   // if (currentRoute?.includes('parent')) {
        //   //   localStorage.setItem('parent_devices', JSON.stringify(data));
        //   // } else if (currentRoute?.includes('iq3')) {
        //   //   localStorage.setItem('parent_devicesMaster', JSON.stringify(data));
        //   // }
        //   // if (currentRoute?.includes('parent')) {
        //   //   localStorage.setItem('parent_devicesMaster', JSON.stringify([...initialTblItemsBk, data]));
        //   // } else {
        //   //   localStorage.setItem('iq3_devicesMaster', JSON.stringify([...initialTblItemsBk, data]));
        //   // }
        // }
      }

      setInitialTableItemsBk([...initialTblItemsBk, createdData]);
      setTmpFilteredData([...initialTblItemsBk, createdData]);
      setSelectedData(createdData);
      setCurrentPage(Math.ceil([...initialTblItemsBk, createdData]?.length / 25));
      setSelectedRowKeys(createdData?.id);
      // navigate(':iq3.device');
      // navigate(path);
    }
  };

  // 装置マスタ情報をDBに新規登録する
  async function createDeviceData(createData) {
    let ret = [];
    try {
      const Url = envProperties.REACT_APP_BACKEND_API_URL + 'Device';
      const otherParam = {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + getAccessToken(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': envProperties.REACT_APP_API_KEY_AUTH,
        },
        body: JSON.stringify(createData),
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          return await response.json();
        })
        .then((data) => {
          if (data && data?.updateToken) {
            // セクションにトークン情報を更新する
            updateAccessToken(data?.updateToken);
          }
          if (data && data.statusCode === StatusCodes.OK) {
            ret = data.data[0];
          } else if (
            data &&
            (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)
          ) {
            authorizeError(ErrorMessage()[data?.messageCode]);
            return;
          } else if (data && data.statusCode !== StatusCodes.OK) {
            error(ErrorMessage()[data?.messageCode]);
            return;
          }
        })
        .catch((e) => {
          authorizeError(ErrorMessage().E010);
          return;
        });
    } catch (e) {
      error(e.message);
      return;
    }
    return ret;
  }

  const updateDataCancel = () => {
    if (isNew == true) {
      setIsNew(true);
    }
    setIsUpdateData(false);
  };
  const discardOk = () => {
    if (isNew == true) {
      setIsNew(false);
    }
    setIsDiscardData(false);
  };
  const discardCancel = () => {
    if (isNew == true) {
      setIsNew(true);
    }
    setIsDiscardData(false);
  };
  const closeAddModal = () => {
    setIsNew(false);
  };
  const closeCustomerModal = () => {
    setCustomerModal(false);
  };
  // masterデータにより、リスト更新
  const updateList = (masterData, updatedMasterData, listData) => {
    // 削除したid取得
    const deletedId = [];
    masterData?.forEach((item1, index) => {
      const found = updatedMasterData?.find((item2) => item2.id === item1.id && item2.displayFlag === false);
      if (found) {
        deletedId.push(index + 1);
      }
    });
    // 新規追加したid取得
    const newId = updatedMasterData
      ?.filter((item) => item.displayFlag && !masterData.some((data2Item) => data2Item.id === item.id))
      ?.map((item) => item.id);
    // リスト更新
    const deleteLstData = listData?.map((item) => {
      return {
        ...item,
        lstData: item.lstData?.filter((it) => !deletedId?.includes(it.key)),
      };
    });
    const updatedData = deleteLstData?.map((item) => {
      newId?.forEach((key) => {
        // Add new objects to lstData
        item.lstData.push({
          key: key,
          value: '',
        });
      });

      return item;
    });
    return updatedData;
  };

  const updateList2 = (masterData, updatedMasterData, listData) => {
    // 削除したid取得
    const deletedId = [];
    masterData?.forEach((item1, index) => {
      const found = updatedMasterData?.find((item2) => item2.id === item1.id && item2.displayFlag === false);
      if (found) {
        deletedId.push(item1.id);
      }
    });
    // 新規追加したid取得
    const newId = updatedMasterData
      ?.filter((item) => item.displayFlag && !masterData.some((data2Item) => data2Item.id === item.id))
      ?.map((item) => item.id);
    // リスト更新
    const deleteLstData = listData?.map((item) => {
      return {
        ...item,
        lstData: item.lstData?.filter((it) => !deletedId?.includes(it.key)),
      };
    });
    const updatedData = deleteLstData?.map((item) => {
      newId?.forEach((key) => {
        // Add new objects to lstData
        item.lstData.push({
          key: key,
          value: '',
        });
      });

      return item;
    });
    const sortedList = updatedData?.map((item) => {
      const sortedLstData = [...item.lstData];
      sortedLstData.sort((a, b) => {
        const aIndex = updatedMasterData?.findIndex((obj) => obj.id === a.key);
        const bIndex = updatedMasterData?.findIndex((obj) => obj.id === b.key);
        return aIndex - bIndex;
      });

      return {
        key: item.key,
        materialType: item.materialType,
        lstData: sortedLstData,
        isUsed: item.isUsed,
      };
    });
    return sortedList;
  };

  // const updateList3 = (masterData, updatedMasterData, listData) => {
  //   const list = [
  //     { key: 1, name: '丸穴径', svalue: '', mvalue: '', lvalue: '' },
  //     { key: 2, name: '加工周長', svalue: '', mvalue: '', lvalue: '' },
  //     { key: 3, name: '速度', svalue: '', mvalue: '', lvalue: '' },
  //   ];
  //   // 削除したid取得
  //   const deletedId = [];
  //   masterData?.forEach((item1, index) => {
  //     const found = updatedMasterData?.find((item2) => item2.id === item1.id && item2.displayFlag === false);
  //     if (found) {
  //       deletedId.push(item1.id);
  //     }
  //   });
  //   // 新規追加したid取得
  //   const newId = updatedMasterData
  //     ?.filter((item) => item.displayFlag && !masterData.some((data2Item) => data2Item.id === item.id))
  //     ?.map((item) => item.id);
  //   // リスト更新
  //   const deleteLstData = listData?.map((item) => {
  //     return {
  //       ...item,
  //       lstData: item.lstData?.filter((it) => !deletedId?.includes(it.key)),
  //     };
  //   });
  //   const updatedData = deleteLstData?.map((item) => {
  //     newId?.forEach((key) => {
  //       // Add new objects to lstData
  //       item.lstData.push({
  //         key: key,
  //         value: '',
  //         list: list,
  //       });
  //     });

  //     return item;
  //   });
  //   const sortedList = updatedData?.map((item) => {
  //     const sortedLstData = [...item.lstData];
  //     sortedLstData.sort((a, b) => {
  //       const aIndex = updatedMasterData?.findIndex((obj) => obj.id === a.key);
  //       const bIndex = updatedMasterData?.findIndex((obj) => obj.id === b.key);
  //       return aIndex - bIndex;
  //     });

  //     return {
  //       key: item.key,
  //       materialType: item.materialType,
  //       lstData: sortedLstData,
  //     };
  //   });
  //   return sortedList;
  // };
  const deleteListData = (list, deletedId) => {
    const deletedData = list?.map((item) => {
      return {
        ...item,
        lstData: item.lstData?.filter((it) => !deletedId?.includes(it.key)),
      };
    });
    return deletedData;
  };
  const updateListData = (deletedData, newId, detailsData) => {
    const updatedData = deletedData?.map((item) => {
      newId?.forEach((key) => {
        // Add new objects to lstData
        item.lstData.push({
          key: key,
          value: '00:00:00',
          list: detailsData,
        });
      });

      return item;
    });
    return updatedData;
  };
  const sortListData = (list, masterData) => {
    const sortedList = list?.map((item) => {
      const sortedLstData = [...item.lstData];
      sortedLstData.sort((a, b) => {
        const aIndex = masterData?.findIndex((obj) => obj.id === a.key);
        const bIndex = masterData?.findIndex((obj) => obj.id === b.key);
        return aIndex - bIndex;
      });

      return {
        key: item.key,
        materialType: item.materialType,
        lstData: sortedLstData,
        isUsed: item.isUsed,
      };
    });
    return sortedList;
  };
  const deleteListData2 = (data, deletedId) => {
    const updatedData = [...data];

    updatedData?.forEach((item) => {
      item.lstData?.forEach((subitem) => {
        subitem.list?.forEach((listItem) => {
          if (listItem.lstData) {
            listItem.lstData = listItem.lstData?.filter((lstItem) => !deletedId?.includes(lstItem.key));
          }
        });
      });
    });

    return updatedData;
  };
  const updateListData2 = (deletedData, newId) => {
    const updatedData = JSON.parse(JSON.stringify(deletedData)); // Create a deep copy of the data array

    updatedData?.forEach((item) => {
      item.lstData?.forEach((i) => {
        i.list?.forEach((j) => {
          if (j.lstData) {
            newId?.forEach((key) => {
              // Add new objects to lstData
              j.lstData.push({
                key: key,
                value: '00:00:00',
              });
            });
          }
        });
      });
    });

    return updatedData;
  };
  const sortListData2 = (list, masterData) => {
    const sortedList = JSON.parse(JSON.stringify(list));

    sortedList?.forEach((item) => {
      item.lstData?.forEach((i) => {
        i.list?.forEach((j) => {
          if (j.lstData) {
            j.lstData.sort((a, b) => {
              const aIndex = masterData?.findIndex((obj) => obj.id === a.key);
              const bIndex = masterData?.findIndex((obj) => obj.id === b.key);
              return aIndex - bIndex;
            });
          }
        });
      });
    });

    return sortedList;
  };
  // ２次加工
  const updateItaatsuInsideKaniList = (itaatsu, itaatsuTemp, list) => {
    const deletedId = [];

    itaatsu?.forEach((item1, index) => {
      const found = itaatsuTemp?.find((item2) => item2.id === item1.id && item2.displayFlag === false);
      if (found) {
        deletedId.push(item1.id);
      }
    });

    const newId = itaatsuTemp
      ?.filter((item) => item.displayFlag && !itaatsu.some((data2Item) => data2Item.id === item.id))
      ?.map((item) => item.id);

    const deletedData = deleteListData2(list, deletedId);

    const updatedData = updateListData2(deletedData, newId);

    const sortedList = sortListData2(updatedData, itaatsuTemp);
    return sortedList;
  };

  const updateKaniSizeList = (
    zaishitsu,
    masterData,
    updatedMasterData,
    itaatsuMasterData,
    updItaatsuMasterData,
    listData
  ) => {
    const deletedId = [];

    masterData?.forEach((item1, index) => {
      const found = updatedMasterData?.find((item2) => item2.id === item1.id && item2.displayFlag === false);
      if (found) {
        deletedId.push(item1.id);
      }
    });

    const newId = updatedMasterData
      ?.filter((item) => item.displayFlag && !masterData.some((data2Item) => data2Item.id === item.id))
      ?.map((item) => item.id);

    const deletedData = deleteListData(listData, deletedId);

    // let detailsData = createListData(zaishitsu, itaatsuMasterData);
    let detailsData = zaishitsu?.map((materialType, key) => {
      const filteredData2 = itaatsuMasterData
        ?.filter((subitem) => subitem.displayFlag === true)
        ?.map((item) => {
          return {
            key: item.id,
            value: '',
          };
        });

      return {
        key: key + 1,
        materialType,
        lstData: filteredData2,
        isUsed: false,
      };
    });
    const updatedData = updateListData(deletedData, newId, detailsData);

    const sortedList = sortListData(updatedData, updatedMasterData);
    const updatedListData = updateItaatsuInsideKaniList(itaatsuMasterData, updItaatsuMasterData, sortedList);
    return updatedListData;
  };
  // const updateKaniList = (sizeMasterData, updSizeMasterData, itaatsuMasterData, updItaatsuMasterData, listData) => {
  //   const sizeupdated = updateList2(sizeMasterData, updSizeMasterData, listData);
  //   return sizeupdated;
  // };
  const updateProductSizeList = (masterData, updatedMasterData, listData) => {
    const deletedId = [];

    masterData?.forEach((item1, index) => {
      const found = updatedMasterData?.find((item2) => item2.id === item1.id && item2.displayFlag === false);
      if (found) {
        deletedId.push(index + 1);
      }
    });

    const newId = updatedMasterData
      ?.filter((item) => item.displayFlag && !masterData.some((data2Item) => data2Item.id === item.id))
      ?.map((item) => item.id);

    const deleteData = listData?.filter((it) => !deletedId?.includes(it.key));

    const result = deleteData?.map((item) => {
      const list = updatedMasterData?.find((listItem) => listItem.id === item.key);

      return {
        ...item,
        max: list ? list.max : item.max,
        lstData: item.lstData?.map((lstItem) => {
          const newListData = {
            key: lstItem.key,
            value: lstItem.value,
          };

          return newListData;
        }),
      };
    });

    newId?.forEach((id) => {
      const list = updatedMasterData?.find((item) => item.id === id);

      if (list) {
        const newObj = {
          key: id,
          max: list.max,
          displayFlag: true,
          lstData: result[0].lstData?.map((lstItem) => {
            return {
              key: lstItem.key,
              value: '',
            };
          }),
        };

        result.push(newObj);
      }
    });
    return result;
  };

  // iq3製品重量係数
  const updateProductSizeList2 = (masterData, updatedMasterData, listData) => {
    const deletedId = [];

    masterData?.forEach((item1, index) => {
      const found = updatedMasterData?.find((item2) => item2.id === item1.id && item2.displayFlag === false);
      if (found) {
        deletedId.push(item1.id);
      }
    });

    const newId = updatedMasterData
      ?.filter((item) => item.displayFlag && !masterData?.some((data2Item) => data2Item.id === item.id))
      ?.map((item) => item.id);

    const deleteData = listData?.filter((it) => !deletedId?.includes(it.key));

    const result = deleteData?.map((item) => {
      const list = updatedMasterData?.find((listItem) => listItem.id === item.key);

      return {
        ...item,
        max: list ? list.max : item.max,
        lstData: item.lstData?.map((lstItem) => {
          const newListData = {
            key: lstItem.key,
            value: lstItem.value,
          };

          return newListData;
        }),
      };
    });

    newId?.forEach((id) => {
      const list = updatedMasterData?.find((item) => item.id === id);

      if (list) {
        const newObj = {
          key: id,
          max: list.max,
          displayFlag: true,
          lstData: result[0]?.lstData?.map((lstItem) => {
            return {
              key: lstItem.key,
              value: '',
            };
          }),
        };

        result?.push(newObj);
      }
    });
    result?.sort((a, b) => {
      const aIndex = updatedMasterData?.findIndex((obj) => obj.id === a.key);
      const bIndex = updatedMasterData?.findIndex((obj) => obj.id === b.key);
      return aIndex - bIndex;
    });
    return result;
  };

  const updateMeishouList = (masterData, updatedMasterData, listData) => {
    const deletedId = [];

    masterData?.forEach((item1, index) => {
      const found = updatedMasterData?.find((item2) => item2.id === item1.id && item2.displayFlag === false);
      if (found) {
        deletedId.push(index + 1);
      }
    });

    const newId = updatedMasterData
      ?.filter((item) => item.displayFlag && !masterData?.some((data2Item) => data2Item.id === item.id))
      ?.map((item) => item.id);

    const updatedData = listData?.filter((it) => !deletedId?.includes(it.key));

    const addNewData = newId?.map((item) => ({
      key: item,
      value: '',
    }));

    updatedData?.push(...addNewData);
    return updatedData;
  };

  const updateKosuuList = (masterData, updatedMasterData, listData) => {
    const lastArrObj = updatedMasterData[updatedMasterData?.length - 1];
    const lastArrObjId = lastArrObj.id;
    const lastArrObjList = listData[listData?.length - 1];
    const lastArrObjVal = lastArrObjList.val;
    // change last key value
    const updatedList = [
      ...listData.slice(0, listData?.length - 1),
      { ...listData[listData?.length - 1], key: lastArrObjId + 1, val: lastArrObjVal },
    ];

    const deletedId = [];

    masterData?.forEach((item1, index) => {
      const found = updatedMasterData?.find((item2) => item2.id === item1.id && item2.displayFlag === false);
      if (found) {
        deletedId.push(index + 1);
      }
    });

    const newId = updatedMasterData
      ?.filter((item) => item.displayFlag && !masterData.some((data2Item) => data2Item.id === item.id))
      ?.map((item) => item.id);

    const updatedData = updatedList?.filter((it) => !deletedId?.includes(it.key));

    const addNewData = newId?.map((item) => ({
      key: item,
      val: '',
    }));

    updatedData.push(...addNewData);
    const sortedData = updatedData.sort((a, b) => a.key - b.key);
    return sortedData;
  };

  const onRowSelect = (record) => {
    // const getData = kouteiMaster?.filter((i) => i.processType === record.processType);
    // let result = [];
    // if (getData[0].processDetail?.length > 0) {
    //   result = getData[0].processDetail[0].processDetailValue?.map((i) => {
    //     if (i.displayFlg === true) {
    //       return i.detailType;
    //     }
    //   });
    // }

    setCurrentRecordKey(record?.id);
    setCurrentRecord(record);
    setClickedAction(1);
    if (editMode) {
      //setEditModeCancelConfirm(true);
      let moveInEdit = props.showConfirmMsgs.moveInEdit == undefined ? true : props.showConfirmMsgs.moveInEdit;
      setEditModeCancelConfirm(moveInEdit);
      if (!moveInEdit) {
        setSelectedRowKeys(record?.id);
        setSelectedData(record);
      }
    } else {
      // const dantoriSizeUpdList = updateList2(record.weightMasterData, weightMaster, record.dantoriSizeListData);
      // record['dantoriSizeListData'] = dantoriSizeUpdList;
      // record['weightMasterData'] = weightMaster;

      // if (record.deviceTypes === '追加') {
      setSelectedRowKeys(record?.id);
      setSelectedData(record);
      // navigate(':parent.device');
      // navigate(path);
      // } else {
      // update 係数区分 master data

      // record['meishouDetails'] = meishouMaster;
      // record['suuryouDetails'] = suuryouKubunMaster;

      // 数量区分　子部品係数
      // const suuryouKubunUpdList = updateList2(
      //   record.kobuhinKeisuuMasterData,
      //   suuryouKubunMaster,
      //   record.kobuhinKeisuuLstData
      // );

      // 段取設定の数量
      // const danntorisetteisuuryouKubunUpdList = updateList2(
      //   record.kobuhinKeisuuMasterData,
      //   suuryouKubunMaster,
      //   record.dantoriSetteiSuuryouLstData
      // );

      // record['dantoriSetteiSuuryouLstData'] = danntorisetteisuuryouKubunUpdList;
      // record['kobuhinKeisuuMasterData'] = suuryouKubunMaster;
      // record['kobuhinKeisuuLstData'] = suuryouKubunUpdList;

      // if (record.processName === '組立' || record.processName === '検査' || record.processName === '梱包') {
      //   const meishouUpdList = updateMeishouList(record.meishouMasterData, meishouMaster, record.meishouLstData);
      //   record['meishouMasterData'] = meishouMaster;
      //   record['meishouLstData'] = meishouUpdList;
      // }
      // if (record.processName === '溶接') {
      //   // 溶接線長
      //   const yousetsuSenchouUpdList = updateList2(
      //     record.yousetsuSenchouMasterData,
      //     weldLineMaster,
      //     record.yousetsuSenchouLstData
      //   );
      //   record['yousetsuSenchouMasterData'] = weldLineMaster;
      //   record['yousetsuSenchouLstData'] = yousetsuSenchouUpdList;
      // }

      // if (record.processName === '溶接仕上') {
      //   // 溶接仕上線長
      //   const yousetsuShiageSenchouLstData = updateList2(
      //     record.yousetsuSenchouMasterData,
      //     weldLineMaster,
      //     record.yousetsuShiageSenchouLstData
      //   );
      //   record['yousetsuSenchouMasterData'] = weldLineMaster;
      //   record['yousetsuShiageSenchouLstData'] = yousetsuShiageSenchouLstData;
      // }

      // // Masterdataによって、リストデータ再更新
      // const pitchUpdList = updateList2(
      //   record.yousetsuNanidoItaatsuMasterData,
      //   yousetsuNanidoItaatsuMaster,
      //   record.pitchItaatsuLstData
      // );
      // const tapWeldingUpdList = updateList2(
      //   record.yousetsuNanidoItaatsuMasterData,
      //   yousetsuNanidoItaatsuMaster,
      //   record.tapWeldingItaatsuLstData
      // );

      // const yousetsuNanidoUpdList = updateList2(
      //   record.yousetsuNanidoItaatsuMasterData,
      //   yousetsuNanidoItaatsuMaster,
      //   record.yousetsuNanidoItaatsuLstData
      // );

      // const karitsukiJikanUpdList = updateJikanList(
      //   record.karitsukiJikanItaatsuMasterData,
      //   karitsukiJikanItaatsuMaster,
      //   record.karitsukiJikanItaatsuLstData
      // );

      // const toritsukiJikanItaatsuUpdList = updateJikanList(
      //   record.karitsukiJikanItaatsuMasterData,
      //   karitsukiJikanItaatsuMaster,
      //   record.toritsukiJikanItaatsuLstData
      // );
      // const toritsukiJikanSizeUpdList = updateJikanList(
      //   record.toritsukiJikanSizeMasterData,
      //   toritsukiJikanSizeMaster,
      //   record.toritsukiJikanSizeLstData
      // );

      // // 加工速度
      // const yousetsuShiageSenchouUpdList = updateList2(
      //   record.karitsukiJikanItaatsuMasterData,
      //   karitsukiJikanItaatsuMaster,
      //   record.yousetsuShiageSenchouLstData
      // );

      // // 個数
      // const kosuuUpdList = updateList2(record.kosuuMasterData, kosuuMaster, record.kosuuLstData);

      // // 製品重量係数
      // // const addColList = updateList2(record.areaMasterData, areaMaster, record.sizeListData);
      // // const productSizeUpdList = updateProductSizeList(record.weightMasterData, weightMaster, addColList);
      // const productSizeUpdList = updateList2(record.kosuuMasterData, kosuuMaster, record.sizeListData);
      // // 段取サイズ
      // // const dantoriTaisekiUpdList = updateList2(record.volumeMasterData, volumeMaster, record.dantoriSizeListData);
      // // const dantoriSizeUpdList = updateProductSizeList(record.weightMasterData, weightMaster, dantoriTaisekiUpdList);
      // const dantoriSizeUpdList = updateList2(record.weightMasterData, weightMaster, record.dantoriSizeListData);
      // // iq3製品重量係数
      // // 個数
      // const seihinkosuuUpdList = updateJikanList(record.kosuuMasterData, kosuuMaster, record.iq3BendingkosuuLstData);
      // const seihinHonsuuUpdList = updateJikanList(
      //   record.honsuuMasterData,
      //   honsuuMaster,
      //   record.iq3BendingHonsuuLstData
      // );
      // // iq3生産個数
      // const seisanKosuUpdList = updateList2(
      //   record.seisanKosuuMasterData,
      //   seisanKosuuMaster,
      //   record.seisanKosuuLstData
      // );
      // // 工程内検査の生産個数

      // const processInspectionKosuUpdList = updateList2(
      //   record.seisanKosuuMasterData,
      //   seisanKosuuMaster,
      //   record.processInspectionKosuuLstData
      // );
      // // 本数
      // const honnsuuUpdList = updateList2(record.honsuuMasterData, honsuuMaster, record.honsuuLstData);
      // // ２次加工
      // // iq3KaninyuryokuListData: createDetailsListData(result, iq3SizeMaster, zaishitsuKubunn, itaatsu1Master),
      // const iq3KaninyuryokuUpdList = updateKaniSizeList(
      //   zaishitsuKubunn,
      //   record.iq3SizeMasterData,
      //   iq3SizeMaster,
      //   record.itaatsu1MasterData,
      //   itaatsu1Master,
      //   record.iq3KaninyuryokuListData
      // );
      // const iq3KaninyuryokuAnaUpdList = updateKaniSizeList(
      //   zaishitsuKubunn,
      //   record.iq3MmMasterData,
      //   mmMaster,
      //   record.itaatsu1MasterData,
      //   itaatsu1Master,
      //   record.iq3KaninyuryokuAnaListData
      // );
      // // iq3製品重量係数
      // const iq3ProductSizeAreaUpdList = updateList2(
      //   record.iq3AreaMasterData,
      //   iq3AreaMaster,
      //   record.iq3ProductSizeLstData
      // );
      // const iq3ProductSizeUpdList = updateProductSizeList2(
      //   record.weightMasterData,
      //   weightMaster,
      //   iq3ProductSizeAreaUpdList
      // );
      // // 補正時間係数
      // const correctionTimeFactorLst = updateList2(
      //   record.iq3AreaMasterData,
      //   iq3AreaMaster,
      //   record.correctionTimeFactorLstData
      // );
      // const correctionTimeFactorUpdList = updateProductSizeList2(
      //   record.weightMasterData,
      //   weightMaster,
      //   correctionTimeFactorLst
      // );

      // // 曲げ補正時間係数
      // const bendingTimeFactorUpdlengthList = updateList2(
      //   record?.lengthMasterData,
      //   lengthMaster,
      //   record.bendingTimeFactorLstData
      // );
      // const bendingTimeFactorUpdList = updateProductSizeList2(
      //   record.weightMasterData,
      //   weightMaster,
      //   bendingTimeFactorUpdlengthList
      // );
      // // 曲げ時間係数
      // const bendTimeFactorUpdlengthList = updateList2(
      //   record?.lengthMasterData,
      //   lengthMaster,
      //   record.bendingCorrectionTimeFactorLstData
      // );
      // const bendTimeFactorUpdList = updateProductSizeList2(
      //   record.weightMasterData,
      //   weightMaster,
      //   bendTimeFactorUpdlengthList
      // );
      // // マテリアルハンドリング時間
      // const materialHandlingLstData = updateJikanSizeListData(
      //   record.iq3AreaMasterData,
      //   iq3AreaMaster,
      //   record.materialHandlingTime
      // );
      // const materialHandlingUpdLst = updateProductSizeJikanList(
      //   record.weightMasterData,
      //   weightMaster,
      //   materialHandlingLstData
      // );
      // // ガス種類
      // const gasTypeUpdList = updateList2(record.iq3ItaatsuMasterData, iq3Itaatsu, record.gasTypeLstData);
      // // ブランク加工速度
      // const blankKakousokudoUpdList = updateList3(
      //   record.iq3ItaatsuMasterData,
      //   iq3Itaatsu,
      //   record.blankKakousokudoListData
      // );
      // // ジョイント
      // const jointItaatsuUpdList = updateList2(record.iq3ItaatsuMasterData, iq3Itaatsu, record.jointItaatsuLstData);
      // // 加工速度係数
      // const kakouSokudoItaatsuUpdList = updateList2(
      //   record.iq3ItaatsuMasterData,
      //   iq3Itaatsu,
      //   record.blankKakousokudoItaatsuList
      // );
      // // 1ピアス加工時間
      // const piercingProcessItaatsuUpdList = updateList2(
      //   record.iq3ItaatsuMasterData,
      //   iq3Itaatsu,
      //   record.piercingProcessItaatsuLstData
      // );

      // // 難易度係数
      // const nanidoKeisuuUpdList = updateList2(record.iq3ItaatsuMasterData, iq3Itaatsu, record.nanidoKeisuuLstData);

      // // 1パンチ加工時間
      // const punchProcessItaatsuUpdList = updateList2(
      //   record.iq3ItaatsuMasterData,
      //   iq3Itaatsu,
      //   record.punchProcessItaatsuLstData
      // );
      // // ガス係数
      // const gasKeisuuUpdList = updateList2(record.gasTypeMasterData, gasType, record.gasKeissuuLstData);
      // // ジョイントサイズ
      // const jointSizeUpdList = updateList2(record.jointSizeMasterData, jointSize, record.jointSizeListData);

      // const extractConditionUpdList = updateList2(
      //   record.retrainedMoldMasterData,
      //   retainedMold,
      //   record.extractConditionList
      // );

      // // 手動バリの加工速度
      // const shudouBariKakousokudoLengthUpdList = updateList2(
      //   record.iq3ItaatsuMasterData,
      //   iq3Itaatsu,
      //   record.iq3ShudouBariKakouSokudoListData
      // );
      // const shudouBariKakousokudoUpdList = updateProductSizeList2(
      //   record.iq3KakouchouMasterData,
      //   iq3Kakouchou,
      //   shudouBariKakousokudoLengthUpdList
      // );
      // // 要素数リスト更新
      // const sagyouJikanKeisuuUpdList = updateList2(
      //   record.yousosuuMasterData,
      //   yousosuuMaster,
      //   record.sagyouJikanKeisuuLstData
      // );
      // const shokiDankaiJikanUpdLst = updateJikanList(
      //   record.yousosuuMasterData,
      //   yousosuuMaster,
      //   record.shokiDankaiJikanLstData
      // );
      // const kensaJikanKeisuuUpdList = updateList2(
      //   record.yousosuuMasterData,
      //   yousosuuMaster,
      //   record.kensaJikanKeisuuLstData
      // );
      // const mageSuuUpdList = updateJikanList(record.iq3ItaatsuMasterData, iq3Itaatsu, record.mageSuuLstData);
      // const kakouJikanUpdList = updateJikanList(
      //   record.iq3AreaMasterData,
      //   iq3AreaMaster,
      //   record.iq3KakouJikanListData
      // );
      // // ベンディングの作業加工時間
      // const iq3BendingTimeUpdList = updateJikanList(
      //   record.iq3ItaatsuMasterData,
      //   iq3Itaatsu,
      //   record.iq3BendingTimeLstData
      // );
      // record['iq3KakouJikanListData'] = kakouJikanUpdList;
      // record['iq3BendingTimeLstData'] = iq3BendingTimeUpdList;
      // record['mageSuuLstData'] = mageSuuUpdList;
      // record['shokiDankaiJikanLstData'] = shokiDankaiJikanUpdLst;
      // record['sagyouJikanKeisuuLstData'] = sagyouJikanKeisuuUpdList;
      // record['kensaJikanKeisuuLstData'] = kensaJikanKeisuuUpdList;
      // record['iq3ShudouBariKakouSokudoListData'] = shudouBariKakousokudoUpdList;
      // record['iq3KakouchouMasterData'] = iq3Kakouchou;
      // record['processInspectionKosuuLstData'] = processInspectionKosuUpdList;
      // record['blankKakousokudoListData'] = blankKakousokudoUpdList;
      // record['jointSizeListData'] = jointSizeUpdList;
      // record['gasKeissuuLstData'] = gasKeisuuUpdList;
      // record['extractConditionList'] = extractConditionUpdList;
      // record['piercingProcessItaatsuLstData'] = piercingProcessItaatsuUpdList;
      // record['nanidoKeisuuLstData'] = nanidoKeisuuUpdList;
      // record['punchProcessItaatsuLstData'] = punchProcessItaatsuUpdList;
      // record['jointItaatsuLstData'] = jointItaatsuUpdList;
      // record['gasTypeLstData'] = gasTypeUpdList;
      // record['blankKakousokudoItaatsuList'] = kakouSokudoItaatsuUpdList;
      // record['correctionTimeFactorLstData'] = correctionTimeFactorUpdList;
      // record['bendingTimeFactorLstData'] = bendingTimeFactorUpdList;
      // record['bendingCorrectionTimeFactorLstData'] = bendTimeFactorUpdList;
      // record['iq3ProductSizeLstData'] = iq3ProductSizeUpdList;
      // record['seisanKosuuMasterData'] = seisanKosuuMaster;
      // record['honsuuMasterData'] = honsuuMaster;
      // record['iq3SizeMasterData'] = iq3SizeMaster;
      // record['itaatsu1MasterData'] = itaatsu1Master;
      // record['iq3KaninyuryokuListData'] = iq3KaninyuryokuUpdList;
      // record['iq3KaninyuryokuAnaListData'] = iq3KaninyuryokuAnaUpdList;
      // record['pitchItaatsuLstData'] = pitchUpdList;

      // record['tapWeldingItaatsuLstData'] = tapWeldingUpdList;

      // record['yousetsuNanidoItaatsuMasterData'] = yousetsuNanidoItaatsuMaster;
      // record['yousetsuNanidoItaatsuLstData'] = yousetsuNanidoUpdList;

      // record['karitsukiJikanItaatsuMasterData'] = karitsukiJikanItaatsuMaster;
      // record['karitsukiJikanItaatsuLstData'] = karitsukiJikanUpdList;

      // record['toritsukiJikanItaatsuMasterData'] = karitsukiJikanItaatsuMaster;
      // record['toritsukiJikanItaatsuLstData'] = toritsukiJikanItaatsuUpdList;

      // record['toritsukiJikanSizeMasterData'] = toritsukiJikanSizeMaster;
      // record['toritsukiJikanSizeLstData'] = toritsukiJikanSizeUpdList;

      // record['yousetsuShiageSenchouMasterData'] = kakouSokudoItaatsuMaster;
      // record['yousetsuShiageSenchouLstData'] = yousetsuShiageSenchouUpdList;

      // record['areaMasterData'] = areaMaster;
      // record['weightMasterData'] = weightMaster;
      // record['lengthMasterData'] = lengthMaster;
      // record['volumeMasterData'] = volumeMaster;
      // record['sizeListData'] = productSizeUpdList;
      // record['dantoriSizeListData'] = dantoriSizeUpdList;

      // record['kosuuMasterData'] = kosuuMaster;
      // record['kosuuLstData'] = kosuuUpdList;

      // record['iq3BendingkosuuLstData'] = seihinkosuuUpdList;
      // record['iq3BendingHonsuuLstData'] = seihinHonsuuUpdList;
      // record['seisanKosuuLstData'] = seisanKosuUpdList;
      // record['honsuuLstData'] = honnsuuUpdList;
      // record['iq3AreaMasterData'] = iq3AreaMaster;
      // record['materialHandlingTime'] = materialHandlingUpdLst;
      // record['iq3ItaatsuMasterData'] = iq3Itaatsu;
      // record['jointSizeMasterData'] = jointSize;
      // record['yousosuuMasterData'] = yousosuuMaster;
      // record['iq3MmMasterData'] = mmMaster;

      // setSelectedRowKeys(record?.id);
      // setSelectedData(record);
      // navigate(':parent.device');
      // navigate(path);
      // }
    }
  };

  const discardConfirmOk = () => {
    setEditModeCancelConfirm(false);
    setEditMode(false);
    if (clickedAction == 1) {
      // selected row change
      currentRecord['meishouDetails'] = meishouMaster;
      setSelectedRowKeys(currentRecordKey);
      setSelectedData(currentRecord);
      // navigate(':iq3.device');
      // navigate(path);
    } else if (clickedAction === 2) {
      setIsNew(true);
      setKouteiType('');
      setKouteiCode('');
      setSouchiCode('');
      setSouchiName('');
      setCopyDeviceList([]);
      setCopyDeviceId('');
      setCategory(processTypes.standard);
      setAddProcessTypeId('');
      setIsManualInput(false);
      setIsCommission(false);
      setSelectDisabled(true);
      setInputDisabled(true);
      setPrepBasicTime(0);
      setWorkBasicTime(0);
      setPrep(0);
      setWork(0);
    } else if (clickedAction === 3) {
      if (selectedData?.length != 0) {
        setIsDelete(true);
      } else {
        alert('データを選択してください');
      }
    }
  };

  const discardConfirmCancel = () => {
    setEditMode(true);
    setEditModeCancelConfirm(false);
  };

  const handleSelectVal = (e) => {
    setProcess(e);
    if (e != 0) {
      let deviceData = initialTblItemsBk;
      const deviceFilter = deviceData?.filter((item) => {
        return item.processName === e && item.deviceName?.includes(device);
      });

      setInitialTableItems(deviceFilter);
    } else {
      setInitialTableItems(initialTblItemsBk);
    }
  };

  const handleInputVal = (e) => {
    setDevice(e.target.value);
  };

  const searchByDeviceName = (e) => {
    let deviceData = initialTblItemsBk;

    const deviceFilter = deviceData?.filter((item) => {
      return item.deviceName?.includes(e.target.value);
    });

    setInitialTableItems(deviceFilter);
  };

  // 取引先情報一覧のOKボタン押下
  const getCustomerData = (selectedCustomerData) => {
    setCustomerId(selectedCustomerData.id);
    setCustomerCode(selectedCustomerData.code);
    setCustomerName(selectedCustomerData.name);
    setCustomerModal(false);
  };

  // 工程コードを選択した場合、
  const changeKouteiType = (e) => {
    setKakouId('');
    setKakouName('');
    setKakouHouhou([]);
    setKakouCode('');
    setAddProcessTypeId('');
    const koutei = kouteiMaster?.filter((i) => i.id === e);

    // カスタムの場合、
    if (koutei[0].workType === WorkType.PaCustom || koutei[0].workType === WorkType.SmCustom) {
      const data = koutei
        ?.filter((item) => item.id === e)?.[0]
        ?.details?.processDetailTypes?.map((i) => {
          if (i.isUsed) {
            return i;
          }
        });
      // 追加工程種類リスト
      setAddProcessTypes(data);
    }

    // バリ取、ブランクの場合、
    if (
      koutei[0].workType === WorkType.SmDeburringAuto ||
      koutei[0].workType === WorkType.SmDeburringManual ||
      koutei[0].workType === WorkType.SmBlankLaser ||
      koutei[0].workType === WorkType.SmBlankPunch ||
      koutei[0].workType === WorkType.SmBlankCombi
    ) {
      const data = koutei?.[0].details?.processDetailTypes?.map((i) => {
        if (i.isUsed) {
          return i;
        }
      });
      setKakouHouhou(data);
    }

    setCopyDeviceList([]);
    setCopyDeviceId('');
    setCustomerId(0);
    setCustomerCode('');
    setCustomerName('');
    setPrepBasicTime(0);
    setWorkBasicTime(0);
    setPrep(0);
    setWork(0);

    const option = kouteiMaster?.find((option) => option.id === e);

    if (initialTblItems?.length > 0) {
      const deviceFilter = initialTblItemsBk?.filter((item) => {
        return item.processId === option.id;
      });
      setCopyDeviceList(deviceFilter);
    }

    setKouteiType(option.workType);
    setCategory(option.isAdditional ? processTypes.additional : processTypes.standard);
    // 標準の場合、
    if (!option.isAdditional) {
      setSelectDisabled(true);
      setIsCommission(false);
    }
    setKouteiCode(option.code);
    setKouteiName(option.name);
    setKouteiId(option.id);
  };

  const changeKakouhouhou = (e) => {
    const option = kakouHouhou?.find((option) => option.id === e);
    setKakouId(option.id);
    setKakouName(option.name);
    setKakouCode(option.code);
  };

  // 委託工程を変更した場合、
  const changeIsCommission = (e) => {
    const isChecked = e.target.checked;
    setIsCommission(isChecked);
    setSelectDisabled(!isChecked);
  };

  // 手入力フラグを変更した場合、
  const changeIsManualInput = (e) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setPrep(initPrice);
      setWork(initPrice);
      setWorkBasicTime(0);
      setPrepBasicTime(0);
    }
    setIsManualInput(isChecked);
    setInputDisabled(!isChecked);
  };

  // 取引先の検索ボタン押下
  const searchCustomer = () => {
    setCustomerModal(true);
    // setCustomerCol(result);
  };

  // コピー元装置を変更した場合、
  const changeCopyDeviceId = (e) => {
    if (category === processTypes.additional && e) {
      const copy = initialTblItemsBk?.filter((item) => item.id === e)[0];

      // 初期金額(手入力時)
      setInitPrice(copy?.details?.initPrice);

      // 取引先情報
      setCustomerId(0);
      setCustomerName('');
      setCustomerCode('');
      if (copy?.details?.clientId) {
        let info = clientInfos?.filter((item) => item.id === copy?.details?.clientId);
        setCustomerId(info?.[0]?.id);
        setCustomerName(info?.[0]?.name);
        setCustomerCode(info?.[0]?.code);
      }
      // 手入力フラグ
      setIsManualInput(copy?.details?.isManualInput);
      setInputDisabled(!copy?.details?.isManualInput);

      // 委託工程
      setIsCommission(copy?.details?.isCommission);
      setSelectDisabled(!copy?.details?.isCommission);

      // 追加工程種類
      setAddProcessTypeId(copy?.details?.addProcessTypeId);

      // 段取時間
      setPrepBasicTime(copy?.details?.prepBasicTime);
      // 作業時間(1箇所当たり)
      setWorkBasicTime(copy?.details?.workBasicTime);
      // 段取時間チャージ金額
      setPrep(copy?.details?.charges?.prep);
      // 作業時間チャージ金額
      setWork(copy?.details?.charges?.work);
      // 備考
      setRemarks(copy?.info?.remarks);
    }
    setCopyDeviceId(e);
  };

  // 追加工程種類を変更した場合、
  const changeAddProcessType = (e) => {
    const code = addProcessTypes?.filter((i) => i.id === e);
    setAddProcessTypeId(e);
    // setTsuikaKouteiCode(code?.[0]?.code);
  };

  // 金額の項目を入力した場合、
  const changeAmount = (name, e) => {
    // if (e.startsWith('¥', 0)) {
    //   e = e.replace('¥', '');
    // }
    // if (e.indexOf(',') > 0) {
    //   e = e.replace(/,/g, '');
    // }
    if (name === 'dantori') {
      setPrep(e);
    } else if (name === 'kakou') {
      setWork(e);
    } else {
      setInitPrice(e);
      // 手入力フラグはオンの場合、段取と加工を自動入力
      if (isManualInput) {
        setPrep(e);
        setWork(e);
      }
    }
  };

  // 新規作成一覧の入力項目
  const addNewModalContent = (
    <>
      <Row>
        <RTable className="propertiesTbl">
          <thead>
            {/* 工程名/コード */}
            <tr>
              <td className="device-detail-tbl-val-title">
                <label className="detail-tbl-val-title-label">工程名/コード</label>
              </td>
              <td style={{ verticalAlign: 'middle', width: '40%' }}>
                <Select
                  id="koutei"
                  name="koutei"
                  placeholder="選択"
                  value={kouteiId}
                  style={{ fontSize: 11, width: '97%', marginLeft: 5 }}
                  onChange={changeKouteiType}
                >
                  {kouteiMaster?.map((item, index) => (
                    <Select.Option key={index} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </td>
              <td style={{ verticalAlign: 'middle' }}>
                <Input
                  type="text"
                  value={kouteiCode}
                  style={{ padding: 5, marginLeft: 5, width: '93%', pointerEvents: 'none' }}
                  className={'input-editable'}
                ></Input>
              </td>
            </tr>
            {/* 加工方法/コード */}
            {/* <tr>
              <td className="device-detail-tbl-val-title">
                <label className="detail-tbl-val-title-label">加工方法/コード</label>
              </td>
              <td style={{ verticalAlign: 'middle', width: '40%' }}>
                <Select
                  placeholder="選択"
                  // disabled
                  value={kakouId}
                  style={{
                    fontSize: 11,
                    width: '97%',
                    marginLeft: 5,
                    pointerEvents:
                      // バリ取 || ブランク
                      kouteiType === WorkType.SmDeburringAuto ||
                      kouteiType === WorkType.SmDeburringManual ||
                      kouteiType === WorkType.SmBlankLaser ||
                      kouteiType === WorkType.SmBlankPunch ||
                      kouteiType === WorkType.SmBlankCombi
                        ? 'auto'
                        : 'none',
                  }}
                  onChange={changeKakouhouhou}
                >
                  {kakouHouhou?.map((item) => (
                    <Select.Option value={item.id}>{item.name}</Select.Option>
                  ))}
                </Select>
              </td>
              <td style={{ verticalAlign: 'middle' }}>
                <Input
                  value={kakouCode}
                  type="text"
                  style={{ padding: 5, marginLeft: 5, width: '93%', pointerEvents: 'none' }}
                  className={'input-editable'}
                ></Input>
              </td>
            </tr> */}
            {/* 装置名/コード */}
            <tr>
              <td className="device-detail-tbl-val-title">
                <label className="detail-tbl-val-title-label">装置名/コード</label>
              </td>
              <td style={{ verticalAlign: 'middle' }}>
                <Input
                  type="text"
                  value={souchiName}
                  onChange={(e) => setSouchiName(e.target.value)}
                  style={{ padding: 5, marginLeft: 5, width: '97%' }}
                  className={'input-editable'}
                  ref={(ref) => {
                    inputItemRef['souchiName'] = ref;
                  }}
                  maxLength={50}
                ></Input>
              </td>
              <td style={{ verticalAlign: 'middle' }}>
                <Input
                  type="text"
                  value={souchiCode}
                  onChange={(e) => setSouchiCode(e.target.value)}
                  style={{ padding: 5, marginLeft: 5, width: '93%' }}
                  className={'input-editable'}
                  ref={(ref) => {
                    inputItemRef['souchiCode'] = ref;
                  }}
                  maxLength={50}
                ></Input>
              </td>
            </tr>
          </thead>
        </RTable>
      </Row>
      <Row justify="start" align="middle">
        <Col span={7} style={{ textAlign: 'end' }}>
          コピー元装置：
        </Col>
        <Col span={12}>
          <Select
            placeholder="選択"
            value={copyDeviceId}
            style={{ fontSize: 11, width: '97%', marginLeft: 5 }}
            onChange={changeCopyDeviceId}
          >
            {copyDeviceList?.map((option, index) => (
              <Select.Option key={index} value={option.id}>
                {option.name}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Row className="mt-3">
        <RTable className="propertiesTbl tsuikakoutei">
          <thead>
            {/* 追加工程種類 */}
            <tr className={category === processTypes.standard ? 'souchi' : ''}>
              <td className="device-detail-tbl-tsuika">
                <label className="detail-tbl-val-title-label">追加工程種類</label>
              </td>
              <td style={{ verticalAlign: 'middle' }} colSpan={2}>
                <Select
                  placeholder="選択"
                  value={addProcessTypeId}
                  id="addProcessType"
                  name="addProcessType"
                  onChange={(e) => changeAddProcessType(e)}
                  style={{ fontSize: 11, width: '97%', marginLeft: 5 }}
                >
                  {addProcessTypes?.map((item, index) => (
                    <Select.Option key={index} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </td>
            </tr>
            <tr className={`${category === processTypes.standard ? 'souchi' : ''} ${inputDisabled ? '' : 'souchi'}`}>
              <td className="device-detail-tbl-tsuika">
                <label className="detail-tbl-val-title-label">段取時間</label>
              </td>
              <td style={{ verticalAlign: 'middle' }} colSpan={2}>
                <Row style={{ marginLeft: 5 }} align="middle">
                  <Col span={5}>
                    {category === processTypes.standard ? (
                      <>
                        <label style={{ marginLeft: 5 }}>{secondsToHms(prepBasicTime)}</label>
                      </>
                    ) : (
                      <>
                        <Input
                          value={secondsToHms(prepBasicTime)}
                          onChange={(e) => {
                            setPrepBasicTime(setTimetoSec(e.target.value));
                          }}
                        ></Input>
                      </>
                    )}
                  </Col>
                  <Col span={12}>
                    <CaretUpOutlined
                      style={{ fontSize: 17 + 'px', marginLeft: 5 }}
                      onClick={() => {
                        let editedTime = souchiHandleIncreaseTime(secondsToHms(prepBasicTime), '10min');
                        setPrepBasicTime(setTimetoSec(editedTime));
                      }}
                    />
                    <CaretDownOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let editedTime = souchiHandleDecreaseTime(secondsToHms(prepBasicTime), '10min');
                        setPrepBasicTime(setTimetoSec(editedTime));
                      }}
                    />
                    <CaretUpOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let editedTime = souchiHandleIncreaseTime(secondsToHms(prepBasicTime), 'min');
                        setPrepBasicTime(setTimetoSec(editedTime));
                      }}
                    />
                    <CaretDownOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let editedTime = souchiHandleDecreaseTime(secondsToHms(prepBasicTime), 'min');
                        setPrepBasicTime(setTimetoSec(editedTime));
                      }}
                    />
                    <CaretUpOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let editedTime = souchiHandleIncreaseTime(secondsToHms(prepBasicTime), 'sec');
                        setPrepBasicTime(setTimetoSec(editedTime));
                      }}
                    />
                    <CaretDownOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let editedTime = souchiHandleDecreaseTime(secondsToHms(prepBasicTime), 'sec');
                        setPrepBasicTime(setTimetoSec(editedTime));
                      }}
                    />
                  </Col>
                </Row>
              </td>
            </tr>
            <tr className={`${category === processTypes.standard ? 'souchi' : ''} ${inputDisabled ? '' : 'souchi'}`}>
              <td className="device-detail-tbl-tsuika">
                <label className="detail-tbl-val-title-label">段取時間チャージ金額</label>
              </td>
              <td style={{ verticalAlign: 'middle' }} colSpan={2}>
                <Row align="middle" style={{ marginLeft: 5 }}>
                  {category === processTypes.standard ? (
                    <>
                      <label style={{ marginLeft: 5 }}>{JPYs.format(prep)}</label>
                      <label style={{ marginLeft: 5 }}>（/h）</label>
                    </>
                  ) : (
                    <>
                      <Col span={5}>
                        {/* <Input
                          value={JPYs.format(prep)}
                          defaultValue={0}
                          onChange={(e) => changeAmount('dantori', e.target.value)}
                          ref={(ref) => {
                            inputItemRef['prep'] = ref;
                          }}
                          maxLength={Number.MAX_SAFE_INTEGER}
                        /> */}
                        <CurrencyInput
                          value={prep}
                          style={{
                            padding: 5,
                            // marginLeft: 5,
                            width: '97%',
                            pointerEvents: inputDisabled ? 'auto' : 'none',
                          }}
                          className={'input-editable chargeAmount_currency'}
                          onValueChange={(event) => changeAmount('dantori', event)}
                          maxLength={Number.MAX_SAFE_INTEGER}
                          prefix="¥"
                          decimalsLimit={100}
                          defaultValue={0}
                          ref={(ref) => {
                            inputItemRef['prep'] = ref;
                          }}
                        />
                      </Col>
                      <Col span={12}>
                        <label style={{ marginLeft: 5 }}>（/h）</label>
                      </Col>
                    </>
                  )}
                </Row>
              </td>
            </tr>
            <tr className={`${category === processTypes.standard ? 'souchi' : ''} ${inputDisabled ? '' : 'souchi'}`}>
              <td className="device-detail-tbl-tsuika">
                <label className="detail-tbl-val-title-label">作業時間(1箇所当たり)</label>
              </td>
              <td style={{ verticalAlign: 'middle' }} colSpan={2}>
                <Row style={{ marginLeft: 5 }} align="middle">
                  <Col span={5}>
                    {category === processTypes.standard ? (
                      <>
                        <label style={{ marginLeft: 5 }}>{secondsToHms(workBasicTime)}</label>
                      </>
                    ) : (
                      <>
                        <Input
                          value={secondsToHms(workBasicTime)}
                          onChange={(e) => {
                            setWorkBasicTime(setTimetoSec(e.target.value));
                          }}
                        ></Input>
                      </>
                    )}
                  </Col>
                  <Col span={12}>
                    <CaretUpOutlined
                      style={{ fontSize: 17 + 'px', marginLeft: 5 }}
                      onClick={() => {
                        let editedTime = souchiHandleIncreaseTime(secondsToHms(workBasicTime), '10min');
                        setWorkBasicTime(setTimetoSec(editedTime));
                      }}
                    />
                    <CaretDownOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let editedTime = souchiHandleDecreaseTime(secondsToHms(workBasicTime), '10min');
                        setWorkBasicTime(setTimetoSec(editedTime));
                      }}
                    />
                    <CaretUpOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let editedTime = souchiHandleIncreaseTime(secondsToHms(workBasicTime), 'min');
                        setWorkBasicTime(setTimetoSec(editedTime));
                      }}
                    />
                    <CaretDownOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let editedTime = souchiHandleDecreaseTime(secondsToHms(workBasicTime), 'min');
                        setWorkBasicTime(setTimetoSec(editedTime));
                      }}
                    />
                    <CaretUpOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let editedTime = souchiHandleIncreaseTime(secondsToHms(workBasicTime), 'sec');
                        setWorkBasicTime(setTimetoSec(editedTime));
                      }}
                    />
                    <CaretDownOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let editedTime = souchiHandleDecreaseTime(secondsToHms(workBasicTime), 'sec');
                        setWorkBasicTime(setTimetoSec(editedTime));
                      }}
                    />
                  </Col>
                </Row>
              </td>
            </tr>
            <tr className={`${category === processTypes.standard ? 'souchi' : ''} ${inputDisabled ? '' : 'souchi'}`}>
              <td className="device-detail-tbl-tsuika">
                <label className="detail-tbl-val-title-label">作業時間チャージ金額</label>
              </td>
              <td style={{ verticalAlign: 'middle' }} colSpan={2}>
                <Row align="middle" style={{ marginLeft: 5 }}>
                  {category === processTypes.standard ? (
                    <>
                      <label style={{ marginLeft: 5 }}>{JPYs.format(work)}</label>
                      <label style={{ marginLeft: 5 }}>（/h）</label>
                    </>
                  ) : (
                    <>
                      <Col span={5}>
                        {/* <Input
                          value={JPYs.format(work)}
                          defaultValue={0}
                          onChange={(e) => changeAmount('kakou', e.target.value)}
                          ref={(ref) => {
                            inputItemRef['work'] = ref;
                          }}
                          maxLength={Number.MAX_SAFE_INTEGER}
                        /> */}
                        <CurrencyInput
                          value={work}
                          style={{
                            padding: 5,
                            // marginLeft: 5,
                            width: '97%',
                            pointerEvents: inputDisabled ? 'auto' : 'none',
                          }}
                          className={'input-editable chargeAmount_currency'}
                          onValueChange={(event) => changeAmount('kakou', event)}
                          maxLength={Number.MAX_SAFE_INTEGER}
                          prefix="¥"
                          decimalsLimit={100}
                          defaultValue={0}
                          ref={(ref) => {
                            inputItemRef['work'] = ref;
                          }}
                        />
                      </Col>
                      <Col span={12}>
                        <label style={{ marginLeft: 5 }}>（/h）</label>
                      </Col>
                    </>
                  )}
                </Row>
              </td>
            </tr>
            <tr className={category === processTypes.standard ? 'souchi' : ''}>
              <td className="device-detail-tbl-tsuika">
                <label className="detail-tbl-val-title-label">委託工程</label>
              </td>
              <td style={{ verticalAlign: 'middle' }} colSpan={2}>
                <Checkbox style={{ marginLeft: 5 }} onChange={changeIsCommission} checked={isCommission}></Checkbox>
              </td>
            </tr>
            <tr className={`${category === processTypes.standard ? 'souchi' : ''} ${selectDisabled ? 'souchi' : ''}`}>
              <td className="device-detail-tbl-tsuika">
                <label className="detail-tbl-val-title-label">取引先</label>
              </td>
              <td
                style={{
                  verticalAlign: 'middle',
                }}
                colSpan={2}
              >
                <Row>
                  <Col span={22}>
                    {category == processTypes.standard ? (
                      ''
                    ) : (
                      <>
                        <label style={{ marginLeft: 5 }}> {customerName}</label>
                      </>
                    )}
                  </Col>
                  <Col span={2}>
                    <SearchOutlined
                      style={{ fontSize: '18px', pointerEvents: selectDisabled ? 'none' : 'auto' }}
                      onClick={searchCustomer}
                    />
                  </Col>
                </Row>
              </td>
            </tr>
            <tr className={`${category === processTypes.standard ? 'souchi' : ''} ${selectDisabled ? 'souchi' : ''}`}>
              <td className="device-detail-tbl-tsuika">
                <label className="detail-tbl-val-title-label">取引先コード</label>
              </td>
              <td style={{ verticalAlign: 'middle' }} colSpan={2}>
                {category == processTypes.standard ? (
                  ''
                ) : (
                  <>
                    <label style={{ marginLeft: 5 }}> {customerCode}</label>
                  </>
                )}
              </td>
            </tr>
            <tr className={category === processTypes.standard ? 'souchi' : ''}>
              <td className="device-detail-tbl-tsuika">
                <label className="detail-tbl-val-title-label">手入力フラグ</label>
              </td>
              <td style={{ verticalAlign: 'middle' }} colSpan={2}>
                <Checkbox style={{ marginLeft: 5 }} onChange={changeIsManualInput} checked={isManualInput}></Checkbox>
              </td>
            </tr>
            <tr className={`${category === processTypes.standard ? 'souchi' : ''} ${inputDisabled ? 'souchi' : ''}`}>
              <td className="device-detail-tbl-tsuika">
                <label className="detail-tbl-val-title-label">初期金額(手入力時)</label>
              </td>
              <td style={{ verticalAlign: 'middle' }} colSpan={2}>
                {category === processTypes.standard ? (
                  ''
                ) : (
                  <>
                    {/* <Input
                      type="text"
                      value={JPYs.format(initPrice)}
                      onChange={(e) => changeAmount('initPrice', e.target.value)}
                      style={{
                        padding: 5,
                        marginLeft: 5,
                        width: '97%',
                        pointerEvents: inputDisabled ? 'none' : 'auto',
                      }}
                      className="input-editable"
                      ref={(ref) => {
                        inputItemRef['initPrice'] = ref;
                      }}
                      maxLength={Number.MAX_SAFE_INTEGER}
                    ></Input> */}
                    <CurrencyInput
                      value={initPrice}
                      style={{
                        padding: 5,
                        marginLeft: 5,
                        width: '97%',
                        pointerEvents: inputDisabled ? 'none' : 'auto',
                      }}
                      className={'input-editable chargeAmount_currency'}
                      onValueChange={(event) => changeAmount('initPrice', event)}
                      maxLength={Number.MAX_SAFE_INTEGER}
                      prefix="¥"
                      decimalsLimit={100}
                      defaultValue={0}
                      ref={(ref) => {
                        inputItemRef['initPrice'] = ref;
                      }}
                    />
                  </>
                )}
              </td>
            </tr>
            <tr className={category === processTypes.standard ? 'souchi' : ''}>
              <td className="device-detail-tbl-tsuika">
                <label className="detail-tbl-val-title-label">備考</label>
              </td>
              <td style={{ verticalAlign: 'middle' }} colSpan={2}>
                {category === processTypes.standard ? (
                  ''
                ) : (
                  <>
                    <Input
                      type="text"
                      value={remarks}
                      onChange={(e) => setRemarks(e.target.value)}
                      style={{ padding: 5, marginLeft: 5, width: '97%' }}
                      className={'input-editable'}
                      ref={(ref) => {
                        inputItemRef['remarks'] = ref;
                      }}
                      maxLength={2048}
                    ></Input>
                  </>
                )}
              </td>
            </tr>
          </thead>
        </RTable>
      </Row>
      <Row justify="end">
        <Button onClick={addData} className="mainButton" id="update">
          登録
        </Button>
        <Button onClick={cancelData} className="cancelButton" style={{ marginLeft: 10 }} id="discard">
          破棄
        </Button>
      </Row>
    </>
  );

  // 画面の入力チェック
  const formValidation = (inputItems) => {
    let ret = true;
    let message = [];
    let input;

    // 工程名
    input = document.getElementsByName('koutei')?.[0];
    input?.childNodes?.[0]?.classList?.remove('input-error');
    if (input?.innerText?.length === 0) {
      // 必須
      message.push(<li>{ErrorMessage('工程名').E017}</li>);
      input?.childNodes?.[0]?.classList.add('input-error');
      ret = false;
    }

    // 装置名
    input = inputItems['souchiName']?.input;
    input?.classList?.remove('input-error');

    if (input?.value?.length <= 0) {
      // 必須
      message.push(<li>{ErrorMessage('装置名').E007}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    // 装置コード
    input = inputItems['souchiCode']?.input;
    input?.classList?.remove('input-error');

    if (input?.value?.length <= 0) {
      // 必須
      message.push(<li>{ErrorMessage('装置コード').E007}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    // 種別は追加の場合、
    if (kouteiMaster?.filter((i) => i.id === kouteiId)?.[0]?.isAdditional) {
      // 追加工程種類
      input = document.getElementsByName('addProcessType')?.[0];
      input?.childNodes?.[0]?.classList?.remove('input-error');
      if (input?.innerText?.length <= 0) {
        // 必須
        message.push(<li>{ErrorMessage('追加工程種類').E017}</li>);
        input?.childNodes?.[0]?.classList.add('input-error');
        ret = false;
      }

      // 段取時間チャージ金額
      input = inputItems['prep'];
      input?.classList?.remove('input-error');
      if (input?.value?.length <= 0) {
        // 必須
        message.push(<li>{ErrorMessage('段取時間チャージ金額').E007}</li>);
        input.classList.add('input-error');
        ret = false;
      }

      // 作業時間チャージ金額
      input = inputItems['work'];
      input?.classList?.remove('input-error');
      if (input?.value?.length <= 0) {
        // 必須
        message.push(<li>{ErrorMessage('作業時間チャージ金額').E007}</li>);
        input.classList.add('input-error');
        ret = false;
      }

      // 初期金額(手入力時)
      input = inputItems['initPrice'];
      input?.classList?.remove('input-error');
      if (isManualInput && input?.value?.length <= 0) {
        // 必須
        message.push(<li>{ErrorMessage('初期金額(手入力時)').E007}</li>);
        input.classList.add('input-error');
        ret = false;
      } else {
        input.value = 0;
      }
    }

    if (message?.length > 0) error(<ul>{message}</ul>);

    return ret;
  };

  const updateFlag = (result) => {
    setFlag(result);
  };

  // 更新確認のOKを押下で、一覧画面のデータを反映する
  const updatedDetailsData = async (result) => {
    if (result != null) {
      const data = [...initialTblItemsBk];

      var updatedInfo = [];
      updatedInfo = await updateDeviceData(result);

      const index = data?.findIndex((item) => item.id === updatedInfo.id);
      data[index] = result;

      setInitialTableItemsBk(data);
      setSelectedData(result);
      setTmpFilteredData(data);
      setSelectedRowKeys(result?.id);
      // if (result.deviceTypes != '追加') {
      //   setYousetsuNanidoItaatsuMaster(result.yousetsuNanidoItaatsuMasterData);
      //   setKaritsukiJikanItaatsuMaster(result.karitsukiJikanItaatsuMasterData);
      //   setToritsukiJikanItaatsuMaster(result.karitsukiJikanItaatsuMasterData);
      //   setToritsukiJikanSizeMaster(result.toritsukiJikanSizeMasterData);
      //   setWeldLineMaster(result.yousetsuSenchouMasterData);
      //   setKakouSokudoItaatsuMaster(result.yousetsuShiageSenchouMasterData);

      //   setVolumeMaster(result.volumeMasterData);
      //   setKosuuMaster(result.kosuuMasterData);
      //   localStorage.setItem('kosuuMasterData', JSON.stringify(result.kosuuMasterData));
      //   setMeishouMasterData(result.meishouDetails);
      //   setIQ3Itaatsu(result.iq3ItaatsuMasterData);
      //   setIQ3SizeMaster(result.iq3SizeMasterData);
      //   setItaatsu1Master(result.itaatsu1MasterData);

      //   setGasType(result.gasTypeMasterData);
      //   setJointSize(result.jointSizeMasterData);
      //   setHonsuuMaster(result.honsuuMasterData);
      //   setRetrainedMold(result.retrainedMoldMasterData);
      //   setIQ3Kakouchou(result.iq3KakouchouMasterData);
      //   setYousosuuMaster(result.yousosuuMasterData);
      // }
      // setAreaMaster(result.areaMasterData);
      // setWeightMaster(result.weightMasterData);
      // setIQ3AreaMaster(result.iq3AreaMasterData);
      // setLengthMaster(result?.lengthMasterData);
      // setSeisanKosuuMaster(result.seisanKosuuMasterData);
      // setSuuryouKubunMaster(result.kobuhinKeisuuMasterData);
      // if (currentRoute?.includes('parent')) {
      //   localStorage.setItem('parent_devicesMaster', JSON.stringify(data));
      // } else {
      //   localStorage.setItem('iq3_devicesMaster', JSON.stringify(data));
      // }
    }
  };

  // 装置マスタ情報をDBに更新する
  async function updateDeviceData(updateData) {
    let ret = [];
    try {
      const Url = envProperties.REACT_APP_BACKEND_API_URL + 'Device';
      const otherParam = {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + getAccessToken(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': envProperties.REACT_APP_API_KEY_AUTH,
        },
        body: JSON.stringify({
          id: updateData.id,
          processId: updateData.processId,
          code: updateData.code,
          name: updateData.name,
          details: updateData.details,
          info: updateData.info,
        }),
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          return await response.json();
        })
        .then((data) => {
          if (data && data?.updateToken) {
            // セクションにトークン情報を更新する
            updateAccessToken(data?.updateToken);
          }
          if (data && data.statusCode === StatusCodes.OK) {
            ret = data.data[0];
          } else if (
            data &&
            (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)
          ) {
            authorizeError(ErrorMessage()[data?.messageCode]);
            return;
          } else if (data && data.statusCode !== StatusCodes.OK) {
            error(ErrorMessage()[data?.messageCode]);
            return;
          }
        })
        .catch((e) => {
          authorizeError(ErrorMessage().E010);
          return;
        });
    } catch (e) {
      error(e.message);
      return;
    }
    return ret;
  }

  const beforeSearch = (e) => {
    {
      let action;
      switch (typeof e) {
        case 'object':
          action = actionArr[1];
          break;
        case 'string':
          action = actionArr[4];
          break;
      }
      callMethod(action.methodName, e);
    }
  };
  const callMethod = (methodName, param) => {
    const method = eval(methodName);
    if (typeof method === 'function') {
      method(param);
    }
  };
  const searchByKeyword = (e) => {
    nameKeyword === e.target.value ? getDataByFilter() : setNameKeyWord(e.target.value);
  };
  const searchByDataState = (value) => {
    koteimei === value ? getDataByFilter() : setKoteimei(value);
  };
  // 絞り込み、
  const getDataByFilter = () => {
    if (initialTblItemsBk?.length <= 0) {
      return;
    }
    let nKeyword = nameKeyword;
    let currentData = initialTblItemsBk;
    let souchiFIlterData = currentData;

    if (nKeyword != '') {
      souchiFIlterData = souchiFIlterData?.filter((item) => item.name?.includes(nKeyword));
    }

    if (koteimei !== undefined && koteimei !== '全て') {
      souchiFIlterData = souchiFIlterData?.filter(
        (item) => getProcessMstRecordById(item?.processId)?.name === koteimei
      );
    }

    setInitialTableItems(souchiFIlterData);
    // setCurrentPage(1);
  };

  return (
    <>
      <div
        style={{
          height: 870,
          marginLeft: 10,
        }}
      >
        <SplitPane split="vertical" sizes={sizes} onChange={setSizes} resizerSize={1}>
          <div>
            <Row className="mt-2" align="middle">
              <Col span={2} style={{ textAlign: 'center' }}>
                <label>工程名：</label>
              </Col>
              <Col span={6}>
                <Select
                  className="yousetsu-select search-select-input"
                  // className="yousetsu-select"
                  placeholder="選択"
                  style={{ fontSize: 11, width: 205 }}
                  // onChange={handleSelectVal}
                  onChange={beforeSearch}
                  value={koteimei}
                  defaultValue={'全て'}
                >
                  <Select.Option value={'全て'}>全て</Select.Option>

                  {kouteiMaster?.map((item, index) => (
                    <Select.Option key={index} value={item.name}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
              <Col span={7}>
                <Input
                  style={{ marginLeft: 20, width: 205 }}
                  // className="input-editable"
                  className="input-editable search-select-input"
                  placeholder="装置名"
                  allowClear
                  onPressEnter={beforeSearch}
                  onBlur={beforeSearch}
                  onChange={(e) => {
                    if (e.target.value === '') {
                      beforeSearch(e);
                    }
                  }}
                  name="name"
                  id="name"
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col span={24}>
                <Table
                  className="tblCustomerList"
                  columns={tableColumns}
                  scroll={{ y: 590, x: '10vw' }}
                  dataSource={initialTblItems}
                  rowKey={(record) => record?.id}
                  rowClassName={(record) => (record?.id == selectedRowKeys ? 'active-row' : 'data-row')}
                  pagination={{
                    position: ['bottomLeft'],
                    total: (record) => record?.length,
                    showTotal: (total, range) => `全${total}件中${range[0]}件〜${range[1]}件を表示`,
                    // defaultPageSize: 25, // 本番値確認必要
                    defaultPageSize: defaultPageSize,
                    defaultCurrent: 1,
                    pageSize: defaultPageSize,
                    current: currentPage,
                    onChange: (page) => {
                      setCurrentPage(page);
                    },
                  }}
                  onRow={(record, index) => {
                    return {
                      onClick: (event) => {
                        onRowSelect(record);
                      },
                    };
                  }}
                ></Table>
              </Col>
            </Row>
          </div>
          <div>
            <Routes>
              <Route
                exact
                path=":iq3.device"
                element={
                  <DevicesDetails
                    flag={flag}
                    updatedFlag={updateFlag}
                    editMode={editMode}
                    selectedData={selectedData}
                    updatedDetails={updatedDetailsData}
                    getProcessMstRecordById={getProcessMstRecordById}
                    getMaterialTypes={zaishitsuKubunn}
                    updateEditMode={updateEditMode}
                    getProcessMstRecordByWorkType={getProcessMstRecordByWorkType}
                    getCustomers={customers}
                    showEdtConfirmOK={showEdtConfirmOK}
                    showEdtConfirmCancle={showEdtConfirmCancle}
                  />
                }
              />
            </Routes>
          </div>
        </SplitPane>
      </div>
      {/* 新規作成 */}
      {isNew
        ? commonModal(
            isNew,
            addNewModalTitle,
            null,
            null,
            600,
            addData,
            closeAddModal,
            addNewModalContent,
            null,
            null,
            true
          )
        : ''}

      {/* 取引先検索 */}

      {customerModal ? (
        <SearchAndGetCustomerData
          customerModal={customerModal}
          selectedCustomerData={getCustomerData}
          title={Customer}
          clientType={ClientType.Contractor}
        />
      ) : (
        ''
      )}
      {/** 更新の確認メッセージ */}
      {isUpdateData
        ? commonModal(
            isUpdateData,
            confirmModalTitle,
            commonFooter(updateDataOk, updateDataCancel),
            null,
            400,
            updateDataOk,
            closeAddModal,
            confirmCreateContent,
            null,
            null,
            false
          )
        : ''}
      {/** 破棄の確認メッセージ */}
      {isDiscardData
        ? commonModal(
            isDiscardData,
            confirmModalTitle,
            commonFooter(discardOk, discardCancel),
            null,
            400,
            discardOk,
            discardCancel,
            confirmDiscardContent,
            null,
            null,
            false
          )
        : ''}

      {/** 編集中破棄確認ポップアップ */}
      {editModeCancelConfirm
        ? commonModal(
            editModeCancelConfirm,
            confirmModalTitle,
            commonFooter(discardConfirmOk, discardConfirmCancel),
            null,
            400,
            discardConfirmOk,
            discardConfirmCancel,
            confirmHakiContent,
            null,
            null,
            false
          )
        : ''}

      {isDelete
        ? commonModal(
            isDelete,
            deleteModalTitle,
            commonDeleteFooter(deleteOk, deleteCancel),
            null,
            400,
            deleteOk,
            deleteCancel,
            confirmDeleteContent,
            null,
            null,
            false
          )
        : ''}
    </>
  );
});

export default DevicesList;
