import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Table } from 'react-bootstrap';
import { Row, Input } from 'antd';

const IQ3KouteiInput_Kensa = forwardRef((props, ref) => {
  // 編集モード
  // const editMode = props.editMode;
  const [numOfKensa, setNumOfKensa] = useState('');

  useEffect(() => {
    let data = props.inspectionInfo;
    if (data != undefined) {
      setNumOfKensa(data?.details?.[0]?.numOfCuts);
    }
  }, [props.inspectionInfo]);

  useImperativeHandle(ref, () => ({
    setEditModeChange: setEditModeChange,
  }));

  return (
    <>
      <Row className="mt-2 registerModal">
        <Table className="kouteiinput " style={{ width: '22%' }}>
          <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
            <tr style={{ textAlign: 'center' }}>
              <th style={{ width: '2%' }} className="tbl-header">
                No.
              </th>
              <th style={{ width: '20%' }} className="tbl-header">
                検査箇所
              </th>
            </tr>
          </thead>
          <tbody>
            {/* {numOfKensa === '' ? (
              <></>
            ) : (
              <> */}
            <tr>
              <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>1</td>
              <td style={{ verticalAlign: 'middle', textAlign: 'center', verticalAlign: 'middle' }}>
                {/* {editMode ? (
                      <> */}
                <Input
                  // className={editMode ? 'input-editable ' : 'input-non-editable '}
                  className="input-editable"
                  value={numOfKensa}
                  onChange={(e) => {
                    setNumOfKensa(e.target.value);
                    props.inspectionInfo.details[0].numOfCuts = e.target.value;
                    props.updateInspection(props.inspectionInfo);
                  }}
                  style={{ height: 32, textAlign: 'center' }}
                ></Input>
                {/* </>
                    ) : (
                      <>{numOfKensa}</>
                    )} */}
              </td>
            </tr>
            {/* </>
            )} */}
          </tbody>
        </Table>
      </Row>
    </>
  );
});

export default IQ3KouteiInput_Kensa;
