/**
 * クラス名：装置設定のチャージ金額画面
 * 作成者：チュー
 * 作成日：2023/06/01
 * バージョン：1.0
 */
import React, { Component, useEffect, forwardRef, useState } from 'react';
import { Row, Col, Checkbox, Button } from 'antd';
import { Table } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input-field';
import { MaterialSurfaceBillingUnit, ServiceClass, WorkType } from '../../../common/enums';
import { getProcessDetailTypesInfo, handleKeyPress, processTypes } from '../../../common/Common';

const ChargeAmount = forwardRef((props, ref) => {
  const [chargesDetails, setChargesDetails] = useState();
  const [category, setCategory] = useState();
  const [deviceType, setDeviceType] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [jikanCharge, setJikanCharge] = useState([]);
  const [type, setType] = useState('');

  // 追加工程の段取金額と作業金額
  const [tsuikaSetupAmount, setTsuikaSetupAmount] = useState('');
  const [tsuikaWorkAmount, setTsuikaWorkAmount] = useState('');
  // iq3
  const [setupAmount, setSetupAmount] = useState('');
  const [workAmount, setWorkAmount] = useState('');
  const [iq3ProcessInspection, setIq3ProcessInspection] = useState('');
  const [secondProcessAmount, setSecondProcessAmount] = useState('');
  const [jointBarashiAmount, setJointBarashiAmount] = useState('');
  const [processMethod, setProcessMethod] = useState('');

  const [processMstRecord, setProcessMstRecord] = useState();
  const [groupDisplayList, setGroupDisplayList] = useState([]);

  // 工程内検査
  const [kensaCharge, setKensaCharge] = useState('');
  useEffect(() => {
    setEditMode(props.editMode);
    if (props.detailsData != undefined) {
      setChargesDetails(props.detailsData?.charges);
      setCategory(props.processType);
    }
    if (props?.processMstRecord) {
      setProcessMstRecord(props.processMstRecord);
    }
    // チャージ金額の値がある場合、
    if (props?.detailsData?.charges && props?.processMstRecord) {
      // 工程種別の詳細情報を得る
      const groupDisplayList = getProcessDetailTypesInfo(props?.detailsData?.charges, props?.processMstRecord);

      setGroupDisplayList(groupDisplayList);
    }
    setJikanCharge(props.chajiDetails);
    // iq3
    setSetupAmount(props.iq3SetupAmount);
    setWorkAmount(props.iq3WorkAmount);

    setIq3ProcessInspection(props.iq3ProcessInspection);
    setSecondProcessAmount(props.secondProcessAmount);
    setJointBarashiAmount(props.jointBarashiAmount);
    setProcessMethod(props.processMethod);
    setDeviceType(props.deviceType);
    setType(props.types);
    setKensaCharge(props.kensaAmount);
  }, [
    props.detailsData,
    props.processType,
    props.editMode,
    props.processMstRecord,
    props.chajiDetails,
    props.iq3SetupAmount,
    props.iq3WorkAmount,
    props.iq3ProcessInspection,
    props.secondProcessAmount,
    props.jointBarashiAmount,
    props.processMethod,
    props.deviceType,
    props.types,
    props.kensaAmount,
  ]);

  // チャージ金額の値を入力された場合、
  const changeInputValue = (itemId, value, types) => {
    // if (types === 'dandori') {
    // const updatedData = chargesDetails?.map((item) => {
    //   if (item.key === itemId) {
    //     const updatedSubItems = item.processDetailValue?.map((subItem) => {
    //       if (subItem.key === subItemId) {
    //         return { ...subItem, dantori: value };
    //       }
    //       return subItem;
    //     });

    //     return { ...item, processDetailValue: updatedSubItems };
    //   }
    //   return item;
    // });

    //   setChargesDetails(updatedData);
    //   props.updatedAmount(updatedData);
    // } else if (types === 'kakou') {
    // const updatedData = chargesDetails?.map((item) => {
    //   if (item.key === itemId) {
    //     const updatedSubItems = item.processDetailValue?.map((subItem) => {
    //       if (subItem.key === subItemId) {
    //         return { ...subItem, kakou: value };
    //       }
    //       return subItem;
    //     });
    //     return { ...item, processDetailValue: updatedSubItems };
    //   }
    //   return item;
    // });
    // setChargesDetails(updatedData);
    // props.updatedAmount(updatedData);
    // } else {
    Object.entries(chargesDetails)?.map(([key, item]) => {
      if (parseInt(key) === itemId) {
        if (types === 'dandori') {
          item.prep = value;
        } else if (types === 'kakou') {
          item.work = value;
        } else if (types === 'manseki') {
          item.squareMeter = value;
        } else if (types === 'jyuuryou') {
          item.weight = value;
        } else if (types === 'dantoriSurface') {
          item.prep = value;
        } else if (types === 'mansekiChecked' && value) {
          item.unit = MaterialSurfaceBillingUnit.SquareMeter;
        } else {
          item.unit = MaterialSurfaceBillingUnit.Weight;
        }
      }
    });
    setChargesDetails(chargesDetails);
    props.updatedAmount(chargesDetails);
    // }
  };

  // 組立、検査、梱包の場合、加工と段取を入力された時
  const changeJikanCharge = (id, e) => {
    // const val = JSON.parse(JSON.stringify(jikanCharge));
    // val[id - 1]['val'] = e;
    // setJikanCharge(val);
    // props.updatedCharge(val);
    chargesDetails[id] = e;
    setChargesDetails(chargesDetails);
    props.updatedAmount(chargesDetails);
  };

  // カスタムの場合、段取と加工を入力された時
  const changeAmount = (id, e) => {
    chargesDetails[id] = e;
    setChargesDetails(chargesDetails);
    props.updatedAmount(chargesDetails);
    // if (name === 'dantori') {
    //   setSetupAmount(e);
    //   props.updatediq3SetupAmount(e);
    // } else if (name === 'kakou') {
    //   setWorkAmount(e);
    //   props.updatediq3WorkAmount(e);
    // } else if (name === 'kensa') {
    //   setIq3ProcessInspection(e);
    //   props.updatediq3ProcessInspection(e);
    // } else if (name === 'niji') {
    //   setSecondProcessAmount(e);
    //   props.updatedsecondProcessAmount(e);
    // } else if (name === 'joint') {
    //   setJointBarashiAmount(e);
    //   props.updatedjointBarashiAmount(e);
    // } else if (name === 'kouteinaikensa') {
    //   setKensaCharge(e);
    //   props.updateKensaAmount(e);
    // }
  };

  // チャージ金額の詳細情報を得る
  const getChargesDetailValues = (id) => {
    var detailValue = {};
    detailValue = Object.entries(chargesDetails)?.filter(([key, data]) => parseInt(key) === id)?.[0];
    return detailValue;
  };

  return (
    <>
      <div className="registerModal" style={{ marginLeft: 10, marginRight: 10 }}>
        {/* category=>溶接、溶接仕上、表面処理、2次加工 */}
        {category === WorkType.PaWelding ||
        category === WorkType.SmWelding ||
        category === WorkType.PaWeldFinish ||
        category === WorkType.SmWeldFinish ||
        category === WorkType.PaSurface ||
        category === WorkType.SmSurface ||
        category === WorkType.SmSecondaryWork ? (
          <>
            {Object.entries(groupDisplayList)?.map(([key, groupItem]) => (
              <Row key={key} className="chargeAmount">
                {groupItem?.map((subItem, index) =>
                  subItem ? (
                    <Col
                      key={index}
                      style={{
                        width: category === WorkType.PaSurface || category === WorkType.SmSurface ? '33%' : '20%',
                      }}
                    >
                      <Table>
                        <thead>
                          <tr style={{ textAlign: 'center' }}>
                            <th
                              className="tbl-header"
                              colSpan={category === WorkType.PaSurface || category === WorkType.SmSurface ? 3 : 2}
                            >
                              {subItem?.name}
                            </th>
                          </tr>
                        </thead>
                        {getChargesDetailValues(subItem?.id)?.map((item, index) => {
                          if (parseInt(item) === subItem?.id) return;
                          return (
                            <tbody key={index}>
                              <tr style={{ textAlign: 'center' }}>
                                {/* 表面処理の場合、 */}
                                {category === WorkType.PaSurface || category === WorkType.SmSurface ? (
                                  <td className="charge_title" style={{ width: '30.3%' }}>
                                    <label>段取(￥/h)</label>
                                  </td>
                                ) : (
                                  ''
                                )}
                                <td className="charge_title" style={{ width: '30.3%' }}>
                                  {/* category=>溶接、溶接仕上、2次加工 */}
                                  {category == WorkType.PaWelding ||
                                  category === WorkType.SmWelding ||
                                  category == WorkType.PaWeldFinish ||
                                  category == WorkType.SmWeldFinish ||
                                  category === WorkType.SmSecondaryWork ? (
                                    <>
                                      <label>段取</label>
                                    </>
                                  ) : (
                                    <Row>
                                      <Col span={5}>
                                        <Checkbox
                                          style={{ marginLeft: 3, pointerEvents: editMode ? 'auto' : 'none' }}
                                          type="checkbox"
                                          checked={item?.unit === MaterialSurfaceBillingUnit.Weight}
                                          onChange={(e) =>
                                            changeInputValue(subItem?.id, e.target.checked, 'jyuuryouChecked')
                                          }
                                        ></Checkbox>
                                      </Col>
                                      <Col span={19}>
                                        <label>重量(￥/kg)</label>
                                      </Col>
                                    </Row>
                                  )}
                                </td>
                                <td className="charge_title" style={{ width: '30.3%' }}>
                                  {/* category=>溶接、溶接仕上、2次加工 */}
                                  {category === WorkType.PaWelding ||
                                  category === WorkType.SmWelding ||
                                  category === WorkType.PaWeldFinish ||
                                  category === WorkType.SmWeldFinish ||
                                  category === WorkType.SmSecondaryWork ? (
                                    <>
                                      <label>加工</label>
                                    </>
                                  ) : (
                                    <Row>
                                      <Col span={5}>
                                        <Checkbox
                                          style={{ marginLeft: 3, pointerEvents: editMode ? 'auto' : 'none' }}
                                          type="checkbox"
                                          checked={item?.unit === MaterialSurfaceBillingUnit.SquareMeter}
                                          onChange={(e) =>
                                            changeInputValue(subItem?.id, e.target.checked, 'mansekiChecked')
                                          }
                                        ></Checkbox>
                                      </Col>
                                      <Col span={19}>
                                        <label>
                                          面積(￥/m<sup>2</sup>)
                                        </label>
                                      </Col>
                                    </Row>
                                  )}
                                </td>
                              </tr>
                              <tr style={{ textAlign: 'center' }}>
                                {/* 表面処理の場合、 */}
                                {category === WorkType.PaSurface || category === WorkType.SmSurface ? (
                                  <td>
                                    <CurrencyInput
                                      value={item.prep}
                                      style={{ padding: 3, width: '98.2%', height: 24, textAlign: 'center' }}
                                      className={
                                        editMode ? 'input-editable chargeAmount_currency' : 'input-non-editable'
                                      }
                                      onValueChange={(event) => changeInputValue(subItem?.id, event, 'dantoriSurface')}
                                      maxLength={Number.MAX_SAFE_INTEGER}
                                      prefix="¥"
                                      decimalsLimit={100}
                                      defaultValue={0}
                                    />
                                  </td>
                                ) : (
                                  ''
                                )}

                                <td>
                                  {/* category=>溶接、溶接仕上、2次加工、表面処理 */}
                                  <CurrencyInput
                                    value={
                                      category === WorkType.PaWelding ||
                                      category === WorkType.SmWelding ||
                                      category === WorkType.PaWeldFinish ||
                                      category === WorkType.SmWeldFinish ||
                                      category === WorkType.SmSecondaryWork
                                        ? item.prep
                                        : item.weight
                                    }
                                    style={{ padding: 3, width: '98.2%', height: 24, textAlign: 'center' }}
                                    className={editMode ? 'input-editable chargeAmount_currency' : 'input-non-editable'}
                                    onValueChange={(event) =>
                                      changeInputValue(
                                        subItem?.id,
                                        event,
                                        category === WorkType.PaWelding ||
                                          category === WorkType.SmWelding ||
                                          category === WorkType.PaWeldFinish ||
                                          category === WorkType.SmWeldFinish ||
                                          category === WorkType.SmSecondaryWork
                                          ? 'dandori'
                                          : 'jyuuryou'
                                      )
                                    }
                                    maxLength={Number.MAX_SAFE_INTEGER}
                                    prefix="¥"
                                    decimalsLimit={100}
                                    defaultValue={0}
                                  />
                                </td>
                                <td>
                                  {/* category=>溶接、溶接仕上、2次加工、表面処理 */}
                                  <CurrencyInput
                                    value={
                                      category === WorkType.PaWelding ||
                                      category === WorkType.SmWelding ||
                                      category === WorkType.PaWeldFinish ||
                                      category === WorkType.SmWeldFinish ||
                                      category === WorkType.SmSecondaryWork
                                        ? item.work
                                        : item.squareMeter
                                    }
                                    style={{ padding: 3, width: '98.2%', height: 24, textAlign: 'center' }}
                                    className={editMode ? 'input-editable chargeAmount_currency' : 'input-non-editable'}
                                    onValueChange={(event) =>
                                      changeInputValue(
                                        subItem?.id,
                                        event,
                                        category === WorkType.PaWelding ||
                                          category === WorkType.SmWelding ||
                                          category === WorkType.PaWeldFinish ||
                                          category === WorkType.SmWeldFinish ||
                                          category === WorkType.SmSecondaryWork
                                          ? 'kakou'
                                          : 'manseki'
                                      )
                                    }
                                    maxLength={Number.MAX_SAFE_INTEGER}
                                    prefix="¥"
                                    decimalsLimit={100}
                                    defaultValue={0}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          );
                        })}
                      </Table>
                    </Col>
                  ) : (
                    <></>
                  )
                )}
              </Row>
            ))}
          </>
        ) : // 組立
        category === WorkType.PaAssenble ||
          // 検査と親部品の場合、
          (category === WorkType.PaInspection && type === ServiceClass.Parent) ||
          // 梱包
          category === WorkType.PaPacking ? (
          // 組立、検査、梱包の場合、
          <>
            <Row className="chargeAmount">
              <Table style={{ width: '40%' }}>
                <thead>
                  <tr style={{ textAlign: 'center' }}>
                    {/* <th className="tbl-header" colSpan={2}>
                      加工
                    </th> */}
                    <th className="tbl-header">段取</th>
                    <th className="tbl-header">加工</th>
                  </tr>
                </thead>
                <tbody>
                  {/* <tr style={{ textAlign: 'center' }}>
                    <td style={{ width: '20%' }} className="charge_title">
                      <Checkbox style={{ marginRight: 8 }}></Checkbox>
                      <label>時間チャージ</label>
                    </td>
                    <td style={{ width: '20%' }} className="charge_title">
                      <Checkbox style={{ marginRight: 8 }}></Checkbox>
                      <label>重量チャージ</label>
                    </td>
                    <td style={{ width: '20%' }} className="charge_title">
                      <label>時間チャージ</label>
                    </td>
                  </tr> */}
                  {/* <tr style={{ textAlign: 'center' }}>
                    {jikanCharge?.map((i) => {
                      const { id, val } = i;
                      return (
                        <>
                          <td>
                            <CurrencyInput
                              style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                              value={val}
                              id={id}
                              className={editMode ? 'input-editable chargeAmount_currency' : 'input-non-editable'}
                              onValueChange={(event) => changeJikanCharge(id, event)}
                              maxLength={Number.MAX_SAFE_INTEGER}
                              prefix="¥"
                              decimalsLimit={100}
                              defaultValue={0}
                            />
                          </td>
                        </>
                      );
                    })}
                  </tr> */}
                  <tr style={{ textAlign: 'center' }}>
                    <td>
                      <CurrencyInput
                        style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                        value={chargesDetails?.prep}
                        id={'prep'}
                        className={editMode ? 'input-editable chargeAmount_currency' : 'input-non-editable'}
                        onValueChange={(event) => changeJikanCharge('prep', event)}
                        maxLength={Number.MAX_SAFE_INTEGER}
                        prefix="¥"
                        decimalsLimit={100}
                        defaultValue={0}
                      />
                    </td>
                    <td>
                      <CurrencyInput
                        style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                        value={chargesDetails?.work}
                        id={'work'}
                        className={editMode ? 'input-editable chargeAmount_currency' : 'input-non-editable'}
                        onValueChange={(event) => changeJikanCharge('work', event)}
                        maxLength={Number.MAX_SAFE_INTEGER}
                        prefix="¥"
                        decimalsLimit={100}
                        defaultValue={0}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Row>
          </>
        ) : category === WorkType.SmProgram ? (
          // プログラム
          <>
            <Row className="chargeAmount">
              {/* <Table style={{ width: '30%' }}> */}
              <Table style={{ width: '40%' }}>
                <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                  <tr style={{ textAlign: 'center' }}>
                    <th style={{ width: '20%' }} className="tbl-header">
                      段取
                    </th>
                    <th style={{ width: '20%' }} className="tbl-header">
                      作業
                    </th>
                    {/* <th style={{ width: '10%' }} className="tbl-header">
                      <label>工程内検査</label>
                    </th> */}
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ textAlign: 'center' }}>
                    <td>
                      <CurrencyInput
                        style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                        // value={setupAmount}
                        value={chargesDetails?.prep}
                        className={editMode ? 'input-editable chargeAmount_currency' : 'input-non-editable'}
                        onValueChange={(e) => changeAmount('prep', e)}
                        onKeyPress={(e) => {
                          handleKeyPress(e, false);
                        }}
                        maxLength={Number.MAX_SAFE_INTEGER}
                        prefix="¥"
                        decimalsLimit={100}
                        defaultValue={0}
                      />
                    </td>
                    <td>
                      <CurrencyInput
                        style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                        // value={workAmount}
                        value={chargesDetails?.work}
                        className={editMode ? 'input-editable chargeAmount_currency' : 'input-non-editable'}
                        onValueChange={(e) => changeAmount('work', e)}
                        onKeyPress={(e) => {
                          handleKeyPress(e, false);
                        }}
                        maxLength={Number.MAX_SAFE_INTEGER}
                        prefix="¥"
                        decimalsLimit={100}
                        defaultValue={0}
                      />
                    </td>
                    {/* <td>
                      <CurrencyInput
                        value={kensaCharge}
                        style={{ padding: 3, width: '98.2%', height: 24, textAlign: 'center' }}
                        className={editMode ? 'input-editable chargeAmount_currency' : 'input-non-editable'}
                        maxLength={Number.MAX_SAFE_INTEGER}
                        onValueChange={(e) => changeAmount('kouteinaikensa', e)}
                        prefix="¥"
                        decimalsLimit={100}
                        defaultValue={0}
                      />
                    </td> */}
                  </tr>
                </tbody>
              </Table>
            </Row>
          </>
        ) : category === WorkType.SmBending ||
          category === WorkType.SmDeburringAuto ||
          category === WorkType.SmDeburringManual ||
          category === WorkType.SmShearing ? (
          // ベンディング、シャーリング、バリ取り(自動・手動)
          <>
            <Row className="chargeAmount">
              <Table
                style={{
                  width:
                    // ベンディング、シャーリング、バリ取り(自動・手動)
                    category === WorkType.SmBending ||
                    category === WorkType.SmDeburringAuto ||
                    category === WorkType.SmDeburringManual ||
                    category === WorkType.SmShearing
                      ? '40%'
                      : '100%',
                }}
              >
                <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                  <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                    <th style={{ width: '20%' }} className="tbl-header">
                      <label>段取</label>
                    </th>

                    <th style={{ width: '20%' }} className="tbl-header">
                      <label>加工</label>{' '}
                    </th>
                    {/* ブランク */}
                    {category === WorkType.SmBlankLaser ||
                    category === WorkType.SmBlankPunch ||
                    category === WorkType.SmBlankCombi ? (
                      <>
                        <th style={{ width: '20%' }} className="tbl-header">
                          <label>2工程穴</label>{' '}
                        </th>
                        <th style={{ width: '20%' }} className="tbl-header">
                          <label>ジョイントバラシ</label>{' '}
                        </th>
                      </>
                    ) : (
                      <></>
                    )}
                    {/* <th style={{ width: '20%' }} className="tbl-header">
                      <label>工程内検査</label>{' '}
                    </th> */}
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ textAlign: 'center' }}>
                    <td>
                      {/* 段取 */}
                      <CurrencyInput
                        style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                        value={chargesDetails?.prep}
                        className={editMode ? 'input-editable chargeAmount_currency' : 'input-non-editable'}
                        onValueChange={(e) => changeAmount('prep', e)}
                        maxLength={Number.MAX_SAFE_INTEGER}
                        prefix="¥"
                        decimalsLimit={100}
                        defaultValue={0}
                      />
                    </td>
                    <td>
                      {/* 加工 */}
                      <CurrencyInput
                        style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                        value={chargesDetails?.work}
                        className={editMode ? 'input-editable chargeAmount_currency' : 'input-non-editable'}
                        onValueChange={(e) => changeAmount('work', e)}
                        maxLength={Number.MAX_SAFE_INTEGER}
                        prefix="¥"
                        decimalsLimit={100}
                        defaultValue={0}
                      />
                    </td>
                    {/* ブランク */}
                    {category === WorkType.SmBlankLaser ||
                    category === WorkType.SmBlankPunch ||
                    category === WorkType.SmBlankCombi ? (
                      <>
                        <td>
                          <CurrencyInput
                            style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                            className={editMode ? 'input-editable chargeAmount_currency' : 'input-non-editable'}
                            value={secondProcessAmount}
                            onValueChange={(e) => changeAmount('niji', e)}
                            maxLength={Number.MAX_SAFE_INTEGER}
                            prefix="¥"
                            decimalsLimit={100}
                            defaultValue={0}
                          />
                        </td>
                        <td>
                          <CurrencyInput
                            style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                            className={editMode ? 'input-editable chargeAmount_currency' : 'input-non-editable'}
                            value={jointBarashiAmount}
                            onValueChange={(e) => changeAmount('joint', e)}
                            maxLength={Number.MAX_SAFE_INTEGER}
                            prefix="¥"
                            decimalsLimit={100}
                            defaultValue={0}
                          />
                        </td>
                      </>
                    ) : (
                      <></>
                    )}
                    {/* 工程内検査 */}
                    {/* <td>
                      <CurrencyInput
                        style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                        className={editMode ? 'input-editable chargeAmount_currency' : 'input-non-editable'}
                        value={iq3ProcessInspection}
                        onValueChange={(e) => changeAmount('kensa', e)}
                        maxLength={Number.MAX_SAFE_INTEGER}
                        prefix="¥"
                        decimalsLimit={100}
                        defaultValue={0}
                      />
                    </td> */}
                  </tr>
                </tbody>
              </Table>
            </Row>
          </>
        ) : category === WorkType.SmBlankLaser ||
          category === WorkType.SmBlankPunch ||
          category === WorkType.SmBlankCombi ? (
          // ブランク
          <>
            <Row className="chargeAmount">
              <Table style={{ width: category === WorkType.SmBlankCombi ? '90%' : '75%' }}>
                <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                  <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                    <th style={{ width: '15%', verticalAlign: 'middle' }} className="tbl-header">
                      <label>段取</label>
                    </th>
                    {category === WorkType.SmBlankCombi ? (
                      // 複合機
                      <>
                        <th style={{ width: '15%' }} className="tbl-header">
                          <label>パンチ加工 </label>{' '}
                        </th>
                        <th style={{ width: '15%' }} className="tbl-header">
                          <label>レーザー加工</label>{' '}
                        </th>
                      </>
                    ) : (
                      <>
                        <th style={{ width: '15%' }} className="tbl-header">
                          <label> 加工</label>
                        </th>
                      </>
                    )}

                    <th style={{ width: '15%' }} className="tbl-header">
                      <label>2工程穴</label>
                    </th>
                    <th style={{ width: '15%', padding: 0 }} className="tbl-header">
                      <label>ジョイントバラシ</label>
                    </th>

                    {/* <th style={{ width: '15%' }} className="tbl-header">
                      <label>工程内検査</label>
                    </th> */}
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ textAlign: 'center' }}>
                    <td>
                      {/* 段取 */}
                      <CurrencyInput
                        style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                        value={chargesDetails?.prep}
                        className={editMode ? 'input-editable chargeAmount_currency' : 'input-non-editable'}
                        onValueChange={(e) => changeAmount('prep', e)}
                        maxLength={Number.MAX_SAFE_INTEGER}
                        prefix="¥"
                        decimalsLimit={100}
                        defaultValue={0}
                      />
                    </td>
                    {category === WorkType.SmBlankCombi ? (
                      // 複合機
                      <>
                        <td>
                          {/* パンチ加工 */}
                          <CurrencyInput
                            style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                            value={chargesDetails?.punchWork}
                            className={editMode ? 'input-editable chargeAmount_currency' : 'input-non-editable'}
                            onValueChange={(e) => changeAmount('punchWork', e)}
                            maxLength={Number.MAX_SAFE_INTEGER}
                            prefix="¥"
                            decimalsLimit={100}
                            defaultValue={0}
                          />
                        </td>
                        <td>
                          {/* レーザー加工 */}
                          <CurrencyInput
                            style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                            value={chargesDetails?.laserWork}
                            className={editMode ? 'input-editable chargeAmount_currency' : 'input-non-editable'}
                            onValueChange={(e) => changeAmount('laserWork', e)}
                            maxLength={Number.MAX_SAFE_INTEGER}
                            prefix="¥"
                            decimalsLimit={100}
                            defaultValue={0}
                          />
                        </td>
                      </>
                    ) : (
                      <>
                        <td>
                          {/* 加工 */}
                          <CurrencyInput
                            style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                            value={chargesDetails?.work}
                            className={editMode ? 'input-editable chargeAmount_currency' : 'input-non-editable'}
                            onValueChange={(e) => changeAmount('work', e)}
                            maxLength={Number.MAX_SAFE_INTEGER}
                            prefix="¥"
                            decimalsLimit={100}
                            defaultValue={0}
                          />
                        </td>
                      </>
                    )}

                    <td>
                      {/* 2工程穴 */}
                      <CurrencyInput
                        style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                        className={editMode ? 'input-editable chargeAmount_currency' : 'input-non-editable'}
                        value={chargesDetails?.twoStepHolework}
                        onValueChange={(e) => changeAmount('twoStepHolework', e)}
                        maxLength={Number.MAX_SAFE_INTEGER}
                        prefix="¥"
                        decimalsLimit={100}
                        defaultValue={0}
                      />
                    </td>
                    <td>
                      {/* ジョイントバラシ */}
                      <CurrencyInput
                        style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                        className={editMode ? 'input-editable chargeAmount_currency' : 'input-non-editable'}
                        value={chargesDetails?.jointSeparate}
                        onValueChange={(e) => changeAmount('jointSeparate', e)}
                        maxLength={Number.MAX_SAFE_INTEGER}
                        prefix="¥"
                        decimalsLimit={100}
                        defaultValue={0}
                      />
                    </td>

                    {/* <td>
                      <CurrencyInput
                        style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                        className={editMode ? 'input-editable chargeAmount_currency' : 'input-non-editable'}
                        value={iq3ProcessInspection}
                        onValueChange={(e) => changeAmount('kensa', e)}
                        maxLength={Number.MAX_SAFE_INTEGER}
                        prefix="¥"
                        decimalsLimit={100}
                        defaultValue={0}
                      />
                    </td> */}
                  </tr>
                </tbody>
              </Table>
            </Row>
          </>
        ) : (
          <></>
        )}
        {/* 追加 */}
        {deviceType === processTypes.additional ||
        // 検査とiQ3
        (category === WorkType.SmInspection && type === ServiceClass.SheetMetal) ? (
          <>
            <Row className="chargeAmount">
              <Col style={{ width: '40%' }}>
                <Table>
                  <tbody>
                    <tr style={{ textAlign: 'center' }}>
                      <td className="tbl-header">
                        <label>段取</label>
                      </td>
                      <td className="tbl-header">
                        <label>加工</label>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {/* <Input
                                  style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                                  value={JPYs.format(setupAmount)}
                                  className={editMode ? 'input-editable ' : 'input-non-editable'}
                                  onChange={(e) => changeAmount('dantori', e.target.value)}
                                /> */}
                        <CurrencyInput
                          style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                          value={chargesDetails?.prep}
                          className={editMode ? 'input-editable chargeAmount_currency' : 'input-non-editable'}
                          onValueChange={(e) => changeAmount('prep', e)}
                          maxLength={Number.MAX_SAFE_INTEGER}
                          prefix="¥"
                          decimalsLimit={100}
                          defaultValue={0}
                        />
                      </td>
                      <td>
                        <CurrencyInput
                          style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                          value={chargesDetails?.work}
                          className={editMode ? 'input-editable chargeAmount_currency' : 'input-non-editable'}
                          onValueChange={(e) => changeAmount('work', e)}
                          maxLength={Number.MAX_SAFE_INTEGER}
                          prefix="¥"
                          decimalsLimit={100}
                          defaultValue={0}
                        />

                        {/* <Input
                                  style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                                  value={JPYs.format(workAmount)}
                                  className={editMode ? 'input-editable ' : 'input-non-editable'}
                                  onChange={(e) => changeAmount('kakou', e.target.value)}
                                /> */}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </>
        ) : (
          <></>
        )}
        {/* 溶接、溶接仕上 */}
        {/* {(category === WorkType.SmWelding ||
          category === WorkType.SmWeldFinish ||
          category === '2次加工' ||
          category === '表面処理') &&
       
        type === ServiceClass.SheetMetal ? (
          <>
            <Row className="chargeAmount">
              <Col style={{ width: '20%' }}>
                <Table>
                  <thead>
                    <tr style={{ textAlign: 'center' }}>
                      <th className="tbl-header">工程内検査</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr style={{ textAlign: 'center' }}>
                      <td>
                        <CurrencyInput
                          value={kensaCharge}
                          style={{ padding: 3, width: '98.2%', height: 24, textAlign: 'center' }}
                          className={editMode ? 'input-editable chargeAmount_currency' : 'input-non-editable'}
                          maxLength={Number.MAX_SAFE_INTEGER}
                          onValueChange={(e) => changeAmount('kouteinaikensa', e)}
                          prefix="¥"
                          decimalsLimit={100}
                          defaultValue={0}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </>
        ) : (
          <></>
        )} */}
      </div>
    </>
  );
});

export default ChargeAmount;
