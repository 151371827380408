import React, { useState, useEffect, forwardRef, useRef } from 'react';
import { Row, Col, Input, Button, Space, Select, Popover } from 'antd';
import { PlusCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { Table } from 'react-bootstrap';
import { isEmpty } from '@rc-component/mini-decimal/es/numberUtil';

import { commonModal } from '../../../common/CommonModal';
import { sameSizePatternValue } from '../../../common/Constant';
import {
  deleteListColData,
  updateListColData,
  sortListData,
  handleKeyPress,
  getInputBorderStyle,
  toDecimal,
} from '../../../common/Common';

const Device_Sagyoukeisuu = forwardRef((props, ref) => {
  const [yousosuu, setYousosuu] = useState([]);
  const [yousosuuTemp, setYousosuuTemp] = useState([]);
  const [yousosuuLst, setYousosuuLst] = useState([]);
  const [shokiDankaiJikanLst, setShokiDankaiJikanLst] = useState([]);
  const [kensaJikanKeisuuList, setKensaJikanKeisuuList] = useState([]);

  const [editMode, setEditMode] = useState(false);
  const [isYousosuu, setIsYousosuu] = useState(false);
  const [programSameSizePatternValue, setProgramSameSizePatternValue] = useState();

  const [errIndexVal, setErrIndexVal] = useState([]);

  const [errorMessage, setErrorMessage] = useState('');
  const inputRef = useRef([]);
  useEffect(() => {
    setErrIndexVal([]);
    setErrorMessage('');

    setEditMode(props.editMode);

    if (props?.detailsData?.otherElementCoeffThMaster) setYousosuu(props?.detailsData?.otherElementCoeffThMaster);
    if (props?.detailsData?.otherElementCoeffThMaster) setYousosuuTemp(props?.detailsData?.otherElementCoeffThMaster);
    if (props?.detailsData?.workTimeElementCoeffThItems)
      setYousosuuLst(props?.detailsData?.workTimeElementCoeffThItems);
    // 同一形状・同一サイズの上限
    if (props?.detailsData?.shapeGrouping !== undefined)
      setProgramSameSizePatternValue(props?.detailsData?.shapeGrouping);
    setShokiDankaiJikanLst(props.shokidankaiYousosuuList);
    setKensaJikanKeisuuList(props.kensaJikanKeisuuList);
  }, [
    props.yousosuuMasterData,
    props.editMode,
    props.processType,
    props.detailsData,
    props.sagyouKeisuuYousosuuList,
    props.shokidankaiYousosuuList,
    props.kensaJikanKeisuuList,
    props.programSameSizePatternValue,
  ]);

  // 要素数ボタン押下
  const openYousosuuModal = () => {
    setErrIndexVal([]);
    setErrorMessage('');
    setIsYousosuu(true);
  };

  const addData = () => {
    setIsUpdateData(true);
  };

  // マスタモデルの「X」ボタン押下
  const closeAddModal = () => {
    if (isYousosuu === true) {
      setIsYousosuu(false);
    }
  };

  // 要素数マスタのOKボタン押下
  const okYousosuuMasterData = () => {
    const deletedId = [];

    yousosuu?.forEach((item1, index) => {
      const found = yousosuuTemp?.find((item2) => item2.no === item1.no && item2.isOutOfRange);
      if (found) {
        deletedId.push(item1.no);
      }
    });

    const newId = yousosuuTemp
      ?.filter((item) => !item.isOutOfRange && !yousosuu.some((data2Item) => data2Item.no === item.no))
      ?.map((item) => item.no);

    const deleteYousosuuList = deleteListColData(yousosuuLst, deletedId);
    // const deleteShokiDankaiJikanList = deleteListColData(shokiDankaiJikanLst, deletedId);
    // const deleteSagyouJikanKeisuuList = deleteListColData(kensaJikanKeisuuList, deletedId);

    const updatedData = updateListColData(deleteYousosuuList, newId);
    // const updateShokiDankaiJikanList = updateListColData(deleteShokiDankaiJikanList, newId);
    // const updateSagyouJikanKeisuuList = updateListColData(deleteSagyouJikanKeisuuList, newId);

    const sortedList = sortListData(updatedData, yousosuuTemp);
    // const sortedShokiDankiaiJikanList = sortListData(updateShokiDankaiJikanList, yousosuuTemp);
    // const sortedSagyouJikanKeisuuList = sortListData(updateSagyouJikanKeisuuList, yousosuuTemp);

    setIsYousosuu(false);
    setYousosuuTemp(yousosuuTemp);

    setYousosuuLst(sortedList);
    props.updatedYousosuuMasterData(yousosuuTemp);

    props.updatedYousosuuList(sortedList);

    // setShokiDankaiJikanLst(sortedShokiDankiaiJikanList);
    // props.updatedShokidankaiYousosuuList(sortedShokiDankiaiJikanList);

    // setKensaJikanKeisuuList(sortedSagyouJikanKeisuuList);
    // props.updateKensaJikanKeisuuList(sortedSagyouJikanKeisuuList);
  };

  // 要素数マスタのOキャンセルボタン押下
  const cancelMasterData = () => {
    setYousosuuTemp(yousosuu);
    setIsYousosuu(false);
  };

  // 要素数マスタの値を入力された場合、
  const handleYousosuuChange = (event, no, field) => {
    const temp = JSON.parse(JSON.stringify(yousosuuTemp));
    const updatedData = temp?.map((row) => {
      if (row.no === no) {
        return { ...row, [field]: event.target.value };
      }
      return row;
    });
    setYousosuuTemp(updatedData);
  };

  // 要素数マスタの行追加ボタン押下
  const addYousosuu = (no, index) => {
    inputRef.current[index].focus();
    const copyData = yousosuuTemp.slice(); // Create a copy of the original array

    const insertIndex = yousosuuTemp?.findIndex((item) => item.no === no); // Find the clicked index of the item

    if (insertIndex !== -1) {
      const data = {
        no: yousosuuTemp?.length + 1,
        value: 0,
        isOutOfRange: false,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setYousosuuTemp(copyData);
    }
  };

  // 要素数マスタの行削除ボタン押下
  const deleteYousosuu = (no, e, index) => {
    // 更新するエラーインデックスを得る
    const updatedErrorIndexes = errIndexVal?.filter((item) => item !== index);

    const finalErrorIndexes = updatedErrorIndexes?.map((item) => (item > index ? item - 1 : item));
    setErrIndexVal(finalErrorIndexes);
    const listTemp = JSON.parse(JSON.stringify(yousosuuTemp));
    if (listTemp?.filter((item) => !item.isOutOfRange)?.length > 2) {
      const updatedData = listTemp?.map((row) => {
        if (row.no === no) {
          return { ...row, isOutOfRange: true };
        }
        return row;
      });
      setYousosuuTemp(updatedData);
      const filterData = updatedData?.filter((i) => !i.isOutOfRange);
      if (index + 1 !== filterData?.length) {
        inputRef.current[index].focus();
      }
    }
  };

  // 要素数マスタのヘッダー
  const addYousosuuModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>要素数</p>
    </div>
  );
  const isValueOrderCorrect = (no) => {
    const temp = yousosuuTemp?.filter((i) => i.isOutOfRange === false);

    const insertIndex = temp?.findIndex((item) => item.no === no);

    if (insertIndex > 0) {
      const prevValue = parseFloat(temp[insertIndex - 1].value);
      const currentValue = parseFloat(temp[insertIndex].value);
      const nextValue = parseFloat(temp[insertIndex + 1].value);
      if (currentValue > prevValue && currentValue < nextValue) {
        let temp = [insertIndex - 1, insertIndex, insertIndex + 1];

        setErrIndexVal(errIndexVal?.filter((item) => item !== insertIndex && item != insertIndex - 1));

        setErrorMessage('前のしきい値より大きい値を入力してください！');
      } else {
        setErrIndexVal([...errIndexVal, insertIndex]);

        if (currentValue <= prevValue) {
          setErrorMessage('前のしきい値より大きい値を入力してください！');
        } else if (currentValue >= nextValue) {
          setErrorMessage('次のしきい値より小さい値を入力してください！');
        }
      }
    } else {
      const currentValue = parseFloat(temp[insertIndex].value);
      const nextValue = parseFloat(temp[insertIndex + 1].value);

      if (currentValue < nextValue) {
        setErrIndexVal(errIndexVal?.filter((item) => item !== insertIndex));
      } else {
        setErrIndexVal([...errIndexVal, insertIndex]);
        setErrorMessage('次のしきい値より小さい値を入力してください！');
      }
    }
  };
  // 要素数マスタ一覧
  const addYousosuuContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>しきい値</label>（≦）
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {yousosuuTemp
                  ?.filter((subitem) => !subitem.isOutOfRange)
                  ?.map((item, index) => (
                    <tr key={index} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        {errIndexVal?.includes(index) ? (
                          <Popover
                            content={<div style={{ padding: 7 }}>{errorMessage}</div>}
                            placement="left"
                            visible={true}
                          ></Popover>
                        ) : (
                          ''
                        )}
                        <Input
                          value={item.value}
                          onChange={(e) => handleYousosuuChange(e, item.no, 'value')}
                          onBlur={() => isValueOrderCorrect(item.no)}
                          className={errIndexVal?.includes(index) ? 'red-border' : ''}
                          style={{
                            ...getInputBorderStyle(index, yousosuuTemp),
                            pointerEvents: editMode ? 'auto' : 'none',
                          }}
                          id={'kosuu' + index + 1}
                          ref={(ref) => (inputRef.current[index] = ref)}
                          onKeyPress={(e) => {
                            handleKeyPress(e, false);
                          }}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={(e) => addYousosuu(item.no, index + 1)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteYousosuu(item.no, e, index)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button
          className={isEmpty(errIndexVal) ? 'mainButton' : 'disabledButton'}
          id="update"
          onClick={okYousosuuMasterData}
        >
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} onClick={cancelMasterData}>
          キャンセル
        </Button>
      </Row>
    </>
  );

  // 要素数のリスト値を変更した場合、
  const changeYousosuu = (event, no) => {
    // const copyData = [...yousosuuLst];
    // const updatedData = JSON.parse(JSON.stringify(copyData));
    // updatedData[rowIndex].lstData[colIndex].value = event.target.value;
    yousosuuLst
      ?.filter((item) => item.no === no)
      ?.map((list) => {
        list.value = event.target.value;
      });
    setYousosuuLst(yousosuuLst);
    props.updatedYousosuuList(yousosuuLst);
  };

  // 同一形状・サイズの上限を変更した場合、
  const changeLimit = (e) => {
    setProgramSameSizePatternValue(e);
    props.updatedProgramSameSizePatternValue(e);
  };

  return (
    <>
      <div className="table-container" style={{ marginLeft: 10, marginRight: 10 }}>
        <Table
          style={{
            width:
              yousosuu?.filter((i) => !i.isOutOfRange)?.length > 7
                ? '100%'
                : yousosuu?.filter((i) => !i.isOutOfRange)?.length * 98 + 196 + 'px',
          }}
        >
          <tr style={{ color: 'white', textAlign: 'center', verticalAlign: 'middle', height: 40 }}>
            <td style={{ width: '196px' }} className="tbl-header fixedCol">
              <Row justify="center" align="middle" className="tbl-header">
                {editMode ? (
                  <>
                    <Button className="cancleButton" onClick={openYousosuuModal} style={{ pointerEvents: 'auto' }}>
                      要素数
                    </Button>
                  </>
                ) : (
                  <>
                    <label>要素数</label>
                  </>
                )}
              </Row>
            </td>

            {yousosuu
              ?.filter((subitem) => !subitem.isOutOfRange)
              ?.map((item, index) => (
                <td key={index} style={{ backgroundColor: '#1063aa', width: '98px' }}>
                  ～{item.value}
                </td>
              ))}
          </tr>

          <tr style={{ textAlign: 'center' }}>
            <td style={{ width: '196px' }} className="fixedOneRowCol">
              {/* {row.materialType} */}
              {'作業時間係数'}
            </td>
            {yousosuuLst
              ?.filter((item) => !item.isOutOfRange)
              ?.map((row, index) => (
                <td key={index} style={{ width: '98px' }}>
                  <Input
                    value={editMode ? row.value : toDecimal(row.value, 2)}
                    onChange={(e) => changeYousosuu(e, row.no)}
                    onKeyPress={(e) => {
                      handleKeyPress(e, false);
                    }}
                    style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                    className={editMode ? 'input-editable ' : 'input-non-editable'}
                  />
                </td>
              ))}
          </tr>
        </Table>
      </div>
      {/*同一形状・サイズの上限*/}
      <Row style={{ marginLeft: 10, marginRight: 10 }} className="mt-2">
        <Table style={{ width: '20%' }}>
          <tr style={{ textAlign: 'center', height: 40, verticalAlign: 'middle' }}>
            <th className="tbl-header">同一形状・サイズの上限</th>
          </tr>
          <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
            <td style={{ backgroundColor: '#ffff !important', verticalAlign: 'middle' }}>
              <Input
                value={programSameSizePatternValue}
                onChange={(e) => {
                  setProgramSameSizePatternValue(e.target.value);
                  props.updatedProgramSameSizePatternValue(e.target.value);
                }}
                onKeyPress={(e) => {
                  handleKeyPress(e, false);
                }}
                className={editMode ? 'input-editable ' : 'input-non-editable'}
                style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
              ></Input>
              {/* <Select
                style={{ width: '98.2%', pointerEvents: editMode ? 'auto' : 'none', padding: 3, marginBottom: 4 }}
                className="yousetsu-select"
                value={programSameSizePatternValue}
                onChange={(e) => {
                  changeLimit(e);
                }}
              >
                {sameSizePatternValue?.map((item, index) => (
                  <Select.Option key={index} value={item.value}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select> */}
            </td>
          </tr>
        </Table>
      </Row>
      {/* 要素数 */}
      {isYousosuu
        ? commonModal(
            isYousosuu,
            addYousosuuModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addYousosuuContent,
            null,
            null,
            true
          )
        : ''}
    </>
  );
});

export default Device_Sagyoukeisuu;
