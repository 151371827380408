import React, { useImperativeHandle, forwardRef, useEffect, useRef, useState } from 'react';
import { Input, Select, Image, DatePicker, Checkbox, Button } from 'antd';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import CurrencyInput from 'react-currency-input-field';
import NumericInput from 'react-numeric-input';

import search from '../../assets/images/search.png';
import SearchAndGetCustomerData from '../common/SearchAndGetCustomerData';
import { Customer, amtEmptyStr } from '../common/Constant';
import { ClientType } from '../common/enums';
import {
  increaseDecreaseKeisu,
  handleKeyPress,
  toDecimal,
  getStaffList,
  todayTime,
  today,
  formatDate,
  JPYs,
  getNameByRecordId,
  updateEstimateInfoByReCal,
} from '../common/Common';
import {
  confirmUpdateContent,
  confirmDiscardContent,
  confirmModalTitle,
  commonModal,
  commonFooter,
  commonDeleteFooter,
  error,
} from '../common/CommonModal';
import { getStaffInfo } from '../common/CommonAPI';
import { ErrorMessage } from '../common/Message';

const plusMinus = [
  {
    label: '+',
    key: '1',
  },
  {
    label: '-',
    key: '2',
  },
];

const ParentEstimateDetail = forwardRef((props, ref) => {
  const location = useLocation();
  const [estimateId, setEstimateId] = useState(0);
  const [drawingNo, setDrawingNo] = useState('');
  const [name, setName] = useState('');
  const [clientId, setClientId] = useState(0);
  const [clientName, setClientName] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [coeffOrdersId, setCoeffOrdersId] = useState(0);
  const [coeffOrdersName, setCoeffOrdersName] = useState('');
  const [coeffDeliveryDatesId, setCoeffDeliveryDatesId] = useState(0);
  const [coeffDeliveryDatesName, setCoeffDeliveryDatesName] = useState('');
  const [coeffPurchasesId, setCoeffPurchasesId] = useState(0);
  const [coeffPurchasesName, setCoeffPurchasesName] = useState('');
  const [coeffManagementsId, setCoeffManagementsId] = useState(0);
  const [coeffManagementsName, setCoeffManagementsName] = useState('');
  const [totalWeight, setTotalWeight] = useState(0);
  const [totalSurfaceArea, setTotalSurfaceArea] = useState(0);
  const [createrId, setCreaterId] = useState(0);
  const [creater, setCreater] = useState('');
  const [modifierId, setModifierId] = useState(0);
  const [modifier, setModifier] = useState('');
  const [created, setCreated] = useState('');
  const [modified, setModified] = useState('');
  const [sizeX, setSizeX] = useState('');
  const [sizeY, setSizeY] = useState('');
  const [sizeZ, setSizeZ] = useState('');
  const [estimateNo, setEstimateNo] = useState('');
  const [estimateStatusId, setEstimateStatusId] = useState(0);
  const [estimateStatus, setEstimateStatus] = useState('');
  const [comment, setComment] = useState('');

  const [customCostLst, setCustomCostLst] = useState([]);
  const [customLabelLst, setCustomLabelLst] = useState([]);
  const [customMemoLst, setCustomMemoLst] = useState([]);
  const [customDateLst, setCustomDateLst] = useState([]);

  const [estimator, setEstimator] = useState('');

  const [editMode, setEditMode] = useState(true);

  const [shippingMethod, setShippingMethod] = useState('');
  const [shippingArea, setShippingArea] = useState('');

  const [materialLossCoefficient, setMaterialLossCoefficient] = useState(0);
  const [setupCoefficient, setSetupCoefficient] = useState(0);
  const [surfaceAreaCoefficient, setSurfaceAreaCoefficient] = useState(0);

  const [customerCoefficient, setCustomerCoefficient] = useState(0);
  const [orderCoefficient, setOrderCoefficient] = useState(0);
  const [deliveryCoefficient, setDeliveryCoefficient] = useState(0);
  const [purchaseCoefficient, setPurchaseCoefficient] = useState(0);
  const [managementCoefficient, setManagementCoefficient] = useState(0);

  const [labelOpen, setLabelOpen] = useState(true);
  const [lableSmallOpen, setLableSmallOpen] = useState(true);
  const [customizeOpen, setCustomizeOpen] = useState(true);
  const [dateOpen, setdateOpen] = useState(true);

  const { TextArea } = Input;

  const [parentEstimateInfo, setParentEstimateInfo] = useState([]);

  const [customerModal, setCustomerModal] = useState(false);

  const [tankaPriotiryChecked, setTankaPriotiryChecked] = useState(true);

  const [isAdjustPrice, setIsAdjustPrice] = useState(false);
  const [estimatedAmount, setEstimatedAmount] = useState(0);
  const [totalCostPrice, setTotalCostPrice] = useState(0);
  const [costProfit, setCostProfit] = useState(0);
  const [rate, setRate] = useState(0);
  const [adjustAmount, setAdjustAmount] = useState(0);
  const [totalAmountByAdjust, setTotalAmountByAdjust] = useState(0);
  const [adjustAmountLabel, setAdjustAmountLabel] = useState(0);
  const [plusMinusAct, setPlusMinusAct] = useState('+');
  const [plusMinusActLabel, setPlusMinusActLabel] = useState('+');

  const [orderCoeffs, setOrderCoeffs] = useState([]);
  const [deliveryDateCoeffs, setDeliveryDateCoeffs] = useState([]);
  const [purchaseCoeffs, setPurchaseCoeffs] = useState([]);
  const [mangCoeffs, setMangCoeffs] = useState([]);

  // ログインしているユーザーの会社情報
  const [companyInfo, setCompanyInfo] = useState([]);
  // 担当者情報リスト
  const [staffList, setStaffList] = useState([]);
  // 案件状態情報
  const [estimateStatusInfo, setEstimateStatusInfo] = useState([]);

  // ユーザー設定情報
  const [userSettingInfo, setUserSettingInfo] = useState([]);

  const [paramCostLst, setParamCostLst] = useState([]); // 追加項目の費用マスタ情報
  const [paramLabelLst, setParamLabelLst] = useState([]); // 追加項目のラベルマスタ情報
  const [paramMemoLst, setParamMemoLst] = useState([]); // 追加項目のメモマスタ情報
  const [paramDateLst, setParamDateLst] = useState([]); // 追加項目の日付マスタ情報

  // useEffect(() => {
  //   initFormData(props.selectedDataDetail);
  //   let parameters = props.selectedDataDetail?.estimateProducts?.calcParameters?.parameters;
  //   // let settings = props.selectedDataDetail?.estimateProducts?.calcParameters?.parameters;
  //   initCompanyInfo(parameters?.company);
  //   // initAdditionalInfo(parameters?.company);
  //   // 担当者情報リスト
  //   setStaffList(parameters?.staff);
  //   // 案件状態情報
  //   setEstimateStatusInfo(parameters?.estimateStatus);
  //   // setUserSettingInfo(settings?.userSetting);
  // }, []);

  useEffect(() => {
    if (props?.activePartMenu == '1') {
      initFormData(props.selectedDataDetail);
      let parameters = props.selectedDataDetail?.estimateProducts?.calcParameters?.parameters;
      // let settings = props.selectedDataDetail?.estimateProducts?.calcParameters?.parameters;
      initCompanyInfo(parameters?.company);
      // initAdditionalInfo(parameters?.company);
      // 担当者情報リスト
      setStaffList(parameters?.staff);
      // 案件状態情報
      setEstimateStatusInfo(parameters?.estimateStatus);
      // setUserSettingInfo(settings?.userSetting);
    }
  }, [props.selectedDataDetail, props.activePartMenu]);

  const updateParentEstimateDetail = (updateEstimate) => {
    initFormData(updateEstimate);
    let parameters = updateEstimate?.estimateProducts?.calcParameters?.parameters;
    // let settings = props.selectedDataDetail?.estimateProducts?.calcParameters?.parameters;
    initCompanyInfo(parameters?.company);
    // initAdditionalInfo(parameters?.company);
    // 担当者情報リスト
    setStaffList(parameters?.staff);
    // 案件状態情報
    setEstimateStatusInfo(parameters?.estimateStatus);
    // setUserSettingInfo(settings?.userSetting);
  };

  useImperativeHandle(
    ref,
    () => ({
      updatePartData: updatePartData,
      setEditModeChange: setEditModeChange,
      getParentEstimateDetail: getParentEstimateDetail,
      // getParentEstimateDetail: () => {
      //   return parentEstimateInfo;
      // },
      formValidation: formValidation,
      updateParentEstimateDetail: updateParentEstimateDetail,
      getParentQuantity: getQuantity,
    }),
    [parentEstimateInfo]
  );

  const getParentEstimateDetail = (mainParentEstimate) => {
    if (mainParentEstimate == undefined || mainParentEstimate?.length == 0) return;
    let parentEstimate = mainParentEstimate;
    parentEstimate.estimateProducts.drawingNo = parentEstimateInfo?.estimateProducts?.drawingNo;
    parentEstimate.estimateProducts.name = parentEstimateInfo?.estimateProducts?.name;
    parentEstimate.estimateProducts.clientId = parentEstimateInfo?.estimateProducts?.clientId;
    parentEstimate.estimateProducts.clientName = parentEstimateInfo?.estimateProducts?.clientName;
    parentEstimate.estimateProducts.quantity = parentEstimateInfo?.estimateProducts?.quantity;
    parentEstimate.estimateProducts.coeffOrdersId = parentEstimateInfo?.estimateProducts?.coeffOrdersId;
    parentEstimate.estimateProducts.coeffOrdersName = parentEstimateInfo?.estimateProducts?.coeffOrdersName;
    parentEstimate.estimateProducts.coeffDeliveryDatesId = parentEstimateInfo?.estimateProducts?.coeffDeliveryDatesId;
    parentEstimate.estimateProducts.coeffDeliveryDatesName =
      parentEstimateInfo?.estimateProducts?.coeffDeliveryDatesName;
    parentEstimate.estimateProducts.coeffPurchasesId = parentEstimateInfo?.estimateProducts?.coeffPurchasesId;
    parentEstimate.estimateProducts.coeffPurchasesName = parentEstimateInfo?.estimateProducts?.coeffPurchasesName;
    parentEstimate.estimateProducts.coeffManagementsId = parentEstimateInfo?.estimateProducts?.coeffManagementsId;
    parentEstimate.estimateProducts.coeffManagementsName = parentEstimateInfo?.estimateProducts?.coeffManagementsName;
    parentEstimate.estimateProducts.totalWeight = parentEstimateInfo?.estimateProducts?.totalWeight;
    parentEstimate.estimateProducts.totalSurfaceArea = parentEstimateInfo?.estimateProducts?.totalSurfaceArea;
    parentEstimate.estimateProducts.createrId = parentEstimateInfo?.estimateProducts?.createrId;
    parentEstimate.estimateProducts.creater = parentEstimateInfo?.estimateProducts?.creater;
    parentEstimate.estimateProducts.modifierId = parentEstimateInfo?.estimateProducts?.modifierId;
    parentEstimate.estimateProducts.modifier = parentEstimateInfo?.estimateProducts?.modifier;
    parentEstimate.estimateProducts.sizeX = parentEstimateInfo?.estimateProducts?.sizeX;
    parentEstimate.estimateProducts.sizeY = parentEstimateInfo?.estimateProducts?.sizeY;
    parentEstimate.estimateProducts.sizeZ = parentEstimateInfo?.estimateProducts?.sizeZ;
    parentEstimate.estimateProducts.estimateNo = parentEstimateInfo?.estimateProducts?.estimateNo;
    parentEstimate.estimateProducts.estimateStatusId = parentEstimateInfo?.estimateProducts?.estimateStatusId;
    parentEstimate.estimateProducts.estimateStatus = parentEstimateInfo?.estimateProducts?.estimateStatus;
    parentEstimate.estimateProducts.comment = parentEstimateInfo?.estimateProducts?.comment;
    parentEstimate.estimateProducts.otherProduct.adjustment =
      parentEstimateInfo?.estimateProducts?.otherProduct?.adjustment;
    parentEstimate.estimateProducts.otherProduct.adjustmentMethod =
      parentEstimateInfo?.estimateProducts?.otherProduct?.adjustmentMethod;
    parentEstimate.estimateProducts.otherProduct.coeffClient =
      parentEstimateInfo?.estimateProducts?.otherProduct?.coeffClient;
    parentEstimate.estimateProducts.otherProduct.coeffOrders =
      parentEstimateInfo?.estimateProducts?.otherProduct?.coeffOrders;
    parentEstimate.estimateProducts.otherProduct.coeffDeliveryDates =
      parentEstimateInfo?.estimateProducts?.otherProduct?.coeffDeliveryDates;
    parentEstimate.estimateProducts.otherProduct.coeffPurchases =
      parentEstimateInfo?.estimateProducts?.otherProduct?.coeffPurchases;
    parentEstimate.estimateProducts.otherProduct.coeffManagements =
      parentEstimateInfo?.estimateProducts?.otherProduct?.coeffManagements;
    parentEstimate.estimateProducts.materialLossCoefficient =
      parentEstimateInfo?.estimateProducts?.materialLossCoefficient;
    parentEstimate.estimateProducts.setupCoefficient = parentEstimateInfo?.estimateProducts?.setupCoefficient
      ? parentEstimateInfo?.estimateProducts?.setupCoefficient
      : 0;
    parentEstimate.estimateProducts.surfaceAreaCoefficient = parentEstimateInfo?.estimateProducts
      ?.surfaceAreaCoefficient
      ? parentEstimateInfo?.estimateProducts?.surfaceAreaCoefficient
      : 0;
    // parentEstimate.estimateProducts.shippingMethod = parentEstimateInfo?.estimateProducts?.shippingMethod
    //   ? parentEstimateInfo?.estimateProducts?.shippingMethod
    //   : 0;
    // parentEstimate.estimateProducts.shippingArea = parentEstimateInfo?.estimateProducts?.shippingArea
    //   ? parentEstimateInfo?.estimateProducts?.shippingArea
    //   : '';
    parentEstimate.epCustomCosts = parentEstimateInfo?.epCustomCosts;
    parentEstimate.epCustomLabels = parentEstimateInfo?.epCustomLabels;
    parentEstimate.epCustomMemos = parentEstimateInfo?.epCustomMemos;
    parentEstimate.epCustomDates = parentEstimateInfo?.epCustomDates;
    return parentEstimate;
  };

  // 初期化設定
  const initFormData = (selectedDataDetail) => {
    // if (selectedDataDetail?.length === 0) return;
    // let partDetail = selectedDataDetail != undefined ? selectedDataDetail : [];
    let partDetail = selectedDataDetail != undefined ? JSON.parse(JSON.stringify(selectedDataDetail)) : [];
    setParentEstimateInfo(partDetail);
    let basicInfo = partDetail.estimateProducts;
    if (basicInfo == undefined) return;
    setDrawingNo(basicInfo.drawingNo);
    setName(basicInfo.name);
    setClientId(basicInfo.clientId);
    setClientName(basicInfo.clientName);
    setQuantity(Number(basicInfo.quantity));
    setCoeffOrdersId(basicInfo.coeffOrdersId);
    setCoeffOrdersName(basicInfo.coeffOrdersName);
    setCoeffDeliveryDatesId(basicInfo.coeffDeliveryDatesId);
    setCoeffDeliveryDatesName(basicInfo.coeffDeliveryDatesName);
    setCoeffPurchasesId(basicInfo.coeffPurchasesId);
    setCoeffPurchasesName(basicInfo.coeffPurchasesName);
    setCoeffManagementsId(basicInfo.coeffManagementsId);
    setCoeffManagementsName(basicInfo.coeffManagementsName);
    setTotalWeight(toDecimal(basicInfo.totalWeight, 2));
    setTotalSurfaceArea(toDecimal(basicInfo.totalSurfaceArea, 2));
    setCreaterId(basicInfo.createrId);
    setCreater(basicInfo.creater);
    setModifierId(basicInfo.modifierId);
    setModifier(basicInfo.modifier);
    setSizeX(toDecimal(basicInfo.sizeX, 2));
    setSizeY(toDecimal(basicInfo.sizeY, 2));
    setSizeZ(toDecimal(basicInfo.sizeZ, 2));
    setEstimateNo(basicInfo.estimateNo);
    setEstimateStatusId(basicInfo.estimateStatusId);
    setEstimateStatus(basicInfo.estimateStatus);
    setComment(basicInfo.comment);

    setCustomerCoefficient(basicInfo?.otherProduct?.coeffClient);
    setOrderCoefficient(basicInfo?.otherProduct?.coeffOrders);
    setDeliveryCoefficient(basicInfo?.otherProduct?.coeffDeliveryDates);
    setPurchaseCoefficient(basicInfo?.otherProduct?.coeffPurchases);
    setManagementCoefficient(basicInfo?.otherProduct?.coeffManagements);

    setMaterialLossCoefficient(basicInfo.materialLossCoefficient);
    setSetupCoefficient(basicInfo.setupCoefficient);
    setSurfaceAreaCoefficient(basicInfo.surfaceAreaCoefficient);
    // setShippingMethod(basicInfo.shippingMethod);
    // setShippingArea(basicInfo.shippingArea);

    setEstimator(basicInfo.estimator);
    if (basicInfo.id !== 0) {
      setEstimateId(basicInfo.id);
    }
    setCreated(dayjs(basicInfo.created).format('YYYY-MM-DD'));
    setModified(dayjs(basicInfo.modified).format('YYYY-MM-DD'));

    setEstimatedAmount(basicInfo.estimatedAmount);
    setTotalCostPrice(basicInfo.totalCostPrice);

    setAdjustAmount(basicInfo?.otherProduct?.adjustment);
    setAdjustAmountLabel(basicInfo?.otherProduct?.adjustment);
    setPlusMinusAct(basicInfo?.otherProduct?.adjustmentMethod);
    setPlusMinusActLabel(
      basicInfo?.otherProduct?.adjustmentMethod == '' ? '+' : basicInfo?.otherProduct?.adjustmentMethod
    );
    let totalAdjustAmount = basicInfo.estimatedAmount;
    if (basicInfo?.otherProduct?.adjustmentMethod === '-') {
      totalAdjustAmount = totalAdjustAmount - basicInfo?.otherProduct?.adjustment;
    } else if (basicInfo?.otherProduct?.adjustmentMethod === '+') {
      totalAdjustAmount = totalAdjustAmount + basicInfo?.otherProduct?.adjustment;
    }
    setTotalAmountByAdjust(totalAdjustAmount);

    let costProfit = Math.round(totalAdjustAmount - basicInfo.totalCostPrice);
    setCostProfit(costProfit ? costProfit : 0);
    let rate = Math.round((costProfit / totalAdjustAmount) * 100);
    setRate(rate ? rate : 0);

    const drawingNo = basicInfo.drawingNo == undefined ? '' : basicInfo.drawingNo;
    // if (imgNo == '' || imgNo == '初期値') {
    if (basicInfo.id === 0) {
      setTankaPriotiryChecked(false);
    } else {
      const costLst = partDetail.epCustomCosts == undefined ? [] : partDetail.epCustomCosts;
      if (costLst?.length > 0) {
        const isAllUsedTanka = partDetail.epCustomCosts?.filter((item) => item.isUseUnit == false);
        if (isAllUsedTanka?.length == 0) {
          setTankaPriotiryChecked(true);
        } else {
          setTankaPriotiryChecked(false);
        }
      }
    }
    setCustomCostLst(partDetail?.epCustomCosts);
    setCustomLabelLst(partDetail?.epCustomLabels);
    setCustomMemoLst(partDetail?.epCustomMemos);
    setCustomDateLst(partDetail?.epCustomDates);
  };

  // 会社情報の初期化設定
  const initCompanyInfo = (companyInfo) => {
    let orderCoeffs = [];
    let deliDateCoeffs = [];
    let purCoeffs = [];
    let mngCoeffs = [];
    if (companyInfo?.length > 0) {
      setCompanyInfo(companyInfo);
      orderCoeffs = companyInfo[0].coeffOrders;
      deliDateCoeffs = companyInfo[0].coeffDeliveryDates;
      purCoeffs = companyInfo[0].coeffPurchases;
      mngCoeffs = companyInfo[0].coeffManagements;
    }
    setPurchaseCoeffs(purCoeffs);
    setMangCoeffs(mngCoeffs);
    setOrderCoeffs(orderCoeffs);
    setDeliveryDateCoeffs(deliDateCoeffs);
  };

  // 追加項目情報の設定
  const initAdditionalInfo = (companyInfo) => {
    if (companyInfo?.length > 0) {
      setParamCostLst(companyInfo[0]?.additionalItemCosts);
      setParamLabelLst(companyInfo[0]?.additionalItemLabels);
      setParamMemoLst(companyInfo[0]?.additionalItemMemos);
      setParamDateLst(companyInfo[0]?.additionalItemDates);
    }
  };

  // 基本情報・お客様検索ボタン押下
  const getCustomerName = () => {
    setCustomerModal(true);
  };

  // 画面の入力チェック
  const formValidation = () => {
    let ret = true;
    let message = [];
    let input;
    // 日付
    // customDateLst?.map((date) => {
    //   input = document.getElementsByName('date' + date?.additionalItemId)?.[0];
    //   input?.classList?.remove('input-error');
    //   if (input?.value?.length === 0) {
    //     // 必須
    //     message.push(<li>{ErrorMessage('基本情報の' + date?.additionalItemName).E017}</li>);
    //     input?.classList.add('input-error');
    //     ret = false;
    //   }
    // });
    if (message?.length > 0) error(<ul>{message}</ul>);
    return ret;
  };

  // 入力した数量情報を得る
  const getQuantity = () => {
    return parentEstimateInfo?.estimateProducts?.quantity;
  };

  const materialNmHandleChange = (value) => {
    setCoeffOrdersId(value);
  };
  const thicknessHandleChange = (value) => {
    setThickness(value);
  };
  const updatePartData = (value) => {};
  const setEditModeChange = (mode) => {
    setEditMode(mode);
  };

  const labelOpenDetail = (e, isShow) => {
    setLabelOpen(isShow);
  };
  const labelSmallOpenDetail = (e, isShow) => {
    setLableSmallOpen(isShow);
  };
  const customizeOpenDetail = (e, isShow) => {
    setCustomizeOpen(isShow);
  };
  const dateOpenDetail = (e, isShow) => {
    setdateOpen(isShow);
  };
  const onValChange = (name, value) => {
    const data = parentEstimateInfo;
    data.estimateProducts[name] = value;
    setParentEstimateInfo(data);
  };

  // 費用・日付・メモ・ラベルに入力した場合、
  const onArrValChange = (i, e, name, type) => {
    if (type == 'cost') {
      const data = [...customCostLst];
      data[i][name] = e;
      if (name === 'unitPrice') {
        data[i]['totalPrice'] = e;
      }
      setParentEstimateInfo((prevData) => {
        return { ...prevData, epCustomCosts: data };
      });
    } else if (type == 'date') {
      const data = [...customDateLst];
      data[i][name] = e;
      setParentEstimateInfo((prevData) => {
        return { ...prevData, epCustomDates: data };
      });
    } else if (type == 'label') {
      const data = [...customLabelLst];
      data[i][name] = e.target.value;
      setParentEstimateInfo((prevData) => {
        return { ...prevData, epCustomLabels: data };
      });
    } else if (type == 'memo') {
      const data = [...customMemoLst];
      data[i][name] = e.target.value;
      setParentEstimateInfo((prevData) => {
        return { ...prevData, epCustomMemos: data };
      });
    }
  };

  // 作成日を変更した場合、
  const onChangeCreatedDate = (name, dateString) => {
    setCreated(dateString);
    const data = parentEstimateInfo;
    data.estimateProducts['created'] = dateString;
    setParentEstimateInfo(data);
  };

  // 更新日を変更した場合、
  const onChangeModifiedDate = (name, dateString) => {
    setModified(dateString);
    const data = parentEstimateInfo;
    data.estimateProducts['modified'] = dateString;
    setParentEstimateInfo(data);
  };

  // お客様一覧のOKボタン押下
  const selectedCustomerData = (selectedCustomerData) => {
    let id = selectedCustomerData.id;
    let name = selectedCustomerData.name;
    let coeff = selectedCustomerData?.coeffClients?.find((item) => item.clientType === ClientType.Customer)?.coeff;
    setClientId(id);
    setClientName(name);
    setCustomerCoefficient(coeff);
    const data = parentEstimateInfo;
    data.estimateProducts['clientId'] = id;
    data.estimateProducts['clientName'] = name;
    data.estimateProducts['otherProduct']['coeffClient'] = coeff;
    setParentEstimateInfo(data);
    setCustomerModal(false);
  };

  const settingTankaPriority = (e) => {
    setTankaPriotiryChecked(e.target.checked);
    const data = [...customCostLst];
    for (let i = 0; i < data?.length; i++) {
      data[i].isUseUnit = e.target.checked;
    }
    setCustomCostLst(data);
  };

  const adjustPrice = () => {
    initAdjustPrice();
    setIsAdjustPrice(true);
  };

  const initAdjustPrice = () => {
    //サンプルデータ用
    setEstimatedAmount(parentEstimateInfo.estimateProducts.estimateTotalPrice);
    setTotalCostPrice(parentEstimateInfo.estimateProducts.costTotalPrice);
    setCostProfit(parentEstimateInfo.estimateProducts.grossTotalPrice);
    setRate(parentEstimateInfo.estimateProducts.grossRate);
    setAdjustAmount(parentEstimateInfo.estimateProducts.otherProduct.adjustment);
    setPlusMinusAct(parentEstimateInfo.estimateProducts.otherProduct.adjustmentMethod);
    // 最終見積金額
    let totalAmountByAdjustCal =
      parentEstimateInfo.estimateProducts.otherProduct.adjustmentMethod === '-'
        ? parentEstimateInfo.estimateProducts.estimateTotalPrice -
          parentEstimateInfo.estimateProducts.otherProduct.adjustment
        : parentEstimateInfo.estimateProducts.estimateTotalPrice +
          parentEstimateInfo.estimateProducts.otherProduct.adjustment;
    setTotalAmountByAdjust(totalAmountByAdjustCal);
    // 粗利
    let profitAmount =
      totalAmountByAdjustCal > 0 && parentEstimateInfo.estimateProducts.costTotalPrice > 0
        ? totalAmountByAdjustCal - parentEstimateInfo.estimateProducts.costTotalPrice
        : 0;
    setCostProfit(Math.round(profitAmount));
    //粗利率
    let profitRate = profitAmount > 0 && totalAmountByAdjustCal > 0 ? (profitAmount / totalAmountByAdjustCal) * 100 : 0;
    setRate(Math.round(profitRate));
  };

  const adjustPriceOK = () => {
    let adjustMethod = plusMinusAct == undefined ? '+' : plusMinusAct;
    setIsAdjustPrice(false);
    setPlusMinusActLabel(adjustMethod);
    setAdjustAmountLabel(adjustAmount);
    const data = parentEstimateInfo;
    data.estimateProducts.otherProduct.adjustment = adjustAmount;
    data.estimateProducts.otherProduct.adjustmentMethod = adjustMethod;
    setParentEstimateInfo(data);
  };

  const adjustPriceCancel = () => {
    setIsAdjustPrice(false);
  };

  const calculateEstimateAmountByAdjust = (
    profitInput,
    profitRateInput,
    adjustAmountInput,
    totalAdjustAmountInput,
    adjustMethod
  ) => {
    let profitRate = rate;
    let totalAmountByAdjustCal = totalAmountByAdjust;
    let profitAmount = costProfit;
    let adjustAmountCal = 0;

    // 粗利入力の場合
    if (profitInput !== '') {
      //粗利率
      profitRate = (profitInput / estimatedAmount) * 100;
      setRate(Math.round(profitRate));
      // 最終見積金額
      totalAmountByAdjustCal = profitInput + totalCostPrice;
      //調整額
      adjustAmountCal = totalAmountByAdjustCal - estimatedAmount;
      setAdjustAmount(Math.abs(adjustAmountCal));
      setPlusMinusAct(adjustAmountCal > 0 ? '+' : '-');
    } // 粗利率入力の場合
    else if (profitRateInput !== '') {
      let discount = profitRateInput / 100;
      // 粗利
      profitAmount = Math.round(estimatedAmount * discount);
      setCostProfit(profitAmount);
      // 最終見積金額
      totalAmountByAdjustCal = profitAmount + totalCostPrice;
      //調整額
      adjustAmountCal = totalAmountByAdjustCal - estimatedAmount;
      setAdjustAmount(Math.abs(adjustAmountCal));
      setPlusMinusAct(adjustAmountCal > 0 ? '+' : '-');
    } // 調整金額入力の場合
    else if (adjustAmountInput !== '') {
      // 最終見積金額
      totalAmountByAdjustCal =
        plusMinusAct === '-' ? estimatedAmount - adjustAmountInput : estimatedAmount + adjustAmountInput;
      // 粗利
      profitAmount = totalAmountByAdjustCal > 0 ? totalAmountByAdjustCal - totalCostPrice : 0;
      setCostProfit(Math.round(profitAmount));
      //粗利率
      profitRate = (profitAmount / totalAmountByAdjustCal) * 100;
      setRate(Math.round(profitRate));
    } // 最終見積金額入力の場合
    else if (totalAdjustAmountInput !== '') {
      // 最終見積金額
      totalAmountByAdjustCal = totalAdjustAmountInput;
      // 粗利
      profitAmount = totalAmountByAdjustCal > 0 ? totalAmountByAdjustCal - totalCostPrice : 0;
      setCostProfit(Math.round(profitAmount));
      //粗利率
      profitRate = (profitAmount / totalAmountByAdjustCal) * 100;
      setRate(Math.round(profitRate));
      //調整額
      adjustAmountCal = totalAmountByAdjustCal - estimatedAmount;
      setAdjustAmount(Math.abs(adjustAmountCal));
      setPlusMinusAct(adjustAmountCal > 0 ? '+' : '-');
    } else if (adjustMethod !== '') {
      // 調整方法入力の場合
      // 最終見積金額
      totalAmountByAdjustCal =
        adjustMethod === '-' ? estimatedAmount - parseInt(adjustAmount) : estimatedAmount + parseInt(adjustAmount);
      // 粗利
      profitAmount = totalAmountByAdjustCal > 0 ? totalAmountByAdjustCal - totalCostPrice : 0;
      setCostProfit(Math.round(profitAmount));
      //粗利率
      profitRate = (profitAmount / totalAmountByAdjustCal) * 100;
      setRate(Math.round(profitRate));
    }
    // 最終見積金額
    setTotalAmountByAdjust(Math.round(totalAmountByAdjustCal));
  };

  const resetAdjustAmount = () => {
    initAdjustPrice();
  };

  const adjustPriceTitle = (
    <div
      style={{
        width: 420,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>価格調整</p>
    </div>
  );

  // 価格調整
  const adjustPriceContent = (
    <div id="addNewCustomer" style={{ marginTop: 10, marginBottom: 20 }}>
      <table style={{ width: '87%' }}>
        <thead>
          <tr>
            <td className="parent-detail-adjustPrice-title ">見積合計</td>
            <td style={{ verticalAlign: 'middle', height: 28, padding: 3 }}>
              <CurrencyInput
                name="estimatedAmount"
                id="estimatedAmount"
                value={estimatedAmount ? JPYs.format(Math.floor(estimatedAmount)) : 0}
                defaultValue={0}
                style={{ padding: 3, textAlign: 'right', width: '100%' }}
                className="input-non-editable"
                prefix="¥"
                decimalsLimit={100}
              />
            </td>
          </tr>
          <tr>
            <td className="parent-detail-adjustPrice-title ">原価合計</td>
            <td style={{ verticalAlign: 'middle', height: 28, padding: 3 }}>
              <CurrencyInput
                name="totalCostPrice"
                id="totalCostPrice"
                value={totalCostPrice ? JPYs.format(Math.floor(totalCostPrice)) : 0}
                defaultValue={0}
                style={{ padding: 3, textAlign: 'right', width: '100%' }}
                className="input-non-editable"
                prefix="¥"
                decimalsLimit={100}
              />
            </td>
          </tr>
          <tr>
            <td className="parent-detail-adjustPrice-title ">粗利</td>
            <td style={{ verticalAlign: 'middle', height: 28, padding: 3 }}>
              <CurrencyInput
                name="costProfit"
                id="costProfit"
                value={costProfit > 0 ? JPYs.format(Math.floor(costProfit)) : 0}
                onValueChange={(e) => {
                  if (costProfit != e) {
                    setCostProfit(e);
                    calculateEstimateAmountByAdjust(parseInt(e), '', '', '', '');
                  }
                }}
                style={{ padding: 3, textAlign: 'right' }}
                defaultValue={0}
                prefix="¥"
                decimalsLimit={100}
                className="currencyInput"
              />
            </td>
          </tr>
          <tr>
            <td className="parent-detail-adjustPrice-title ">粗利率</td>
            <td style={{ verticalAlign: 'middle', height: 28, padding: 3 }}>
              <Input
                name="rate"
                id="rate"
                value={rate ? JPYs.format(Math.floor(rate)) : 0}
                // onChange={(e) => setRate(e.target.value)}
                onChange={(e) => {
                  if (rate != e.target.value) {
                    setRate(e.target.value);
                    calculateEstimateAmountByAdjust('', parseInt(e.target.value), '', '', '');
                  }
                }}
                style={{ padding: 3, textAlign: 'right' }}
                defaultValue={0}
                suffix="%"
                className="input-editable percent-input "
              />
            </td>
          </tr>
          <tr>
            <td className="parent-detail-adjustPrice-title " style={{ color: 'red' }}>
              調整額
            </td>
            <td style={{ verticalAlign: 'middle', height: 36, padding: 3, display: 'flex' }}>
              <Select
                defaultValue={'+'}
                id="adjustMethod"
                name="adjustMethod"
                className={
                  plusMinusAct === '-'
                    ? 'plusMinusDDL plusMinusDDL-parent-estimate minus'
                    : 'plusMinusDDL plusMinusDDL-parent-estimate'
                }
                value={plusMinusAct}
                onChange={(e) => {
                  if (plusMinusAct != e) {
                    setPlusMinusAct(e);
                    calculateEstimateAmountByAdjust('', '', '', '', e);
                  }
                }}
              >
                {plusMinus?.map((item, index) => (
                  <Select.Option key={index} id={'plusMinus' + item.value} value={item.label}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
              <CurrencyInput
                name="adjustAmount"
                id="adjustAmount"
                value={adjustAmount ? JPYs.format(Math.floor(adjustAmount)) : 0}
                onValueChange={(e) => {
                  if (adjustAmount != e) {
                    setAdjustAmount(Number(e));
                    calculateEstimateAmountByAdjust('', '', parseInt(e), '', '');
                  }
                }}
                style={{ padding: 3, color: 'red', textAlign: 'right', marginLeft: 5 }}
                defaultValue={0}
                prefix="¥"
                decimalsLimit={100}
                className="currencyInput"
              />
            </td>
          </tr>
          <tr>
            <td className="parent-detail-adjustPrice-title" style={{ fontWeight: 'bold' }}>
              最終見積合計
            </td>
            <td style={{ verticalAlign: 'middle', height: 28, padding: 3 }}>
              <CurrencyInput
                name="totalAmountByAdjust"
                id="totalAmountByAdjust"
                value={totalAmountByAdjust ? JPYs.format(Math.floor(totalAmountByAdjust)) : 0}
                onValueChange={(e) => {
                  if (totalAmountByAdjust != e) {
                    setTotalAmountByAdjust(e);
                    calculateEstimateAmountByAdjust('', '', '', parseInt(e), '');
                  }
                }}
                style={{ padding: 3, textAlign: 'right', fontWeight: 'bold' }}
                defaultValue={0}
                prefix="¥"
                decimalsLimit={100}
                className="currencyInput"
              />
            </td>
          </tr>
          <tr style={{ marginTop: 10 }}>
            <td></td>
            <td style={{ textAlign: 'right' }}>
              <a
                onClick={resetAdjustAmount}
                style={{ textDecoration: 'underline', color: '#1063aa', fontWeight: 'bold', textAlign: 'right' }}
              >
                リセット
              </a>
            </td>
          </tr>
        </thead>
      </table>
    </div>
  );

  const getNameById = (id, list) => {
    let name = '';
    if (list != '') {
      let record = list?.filter((record) => record.id === id);
      name = record?.length > 0 ? record[0].name : '';
    }
    return name;
  };

  return (
    <>
      {/* 部品プロパティアリア */}
      <div
        style={{
          // margin: '2px',
          display: 'flex',
        }}
      >
        <div style={{ float: 'left', width: '55%' }}>
          <Table className="propertiesTbl">
            <thead>
              {/** 図番 */}
              <tr>
                <td colSpan={2} style={{ width: '23%' }} className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">図番</label>
                </td>
                <td colSpan={10} style={{ width: '80%' }} className="detail-tbl-val">
                  <Input
                    style={{ padding: 5, marginLeft: 5, width: '98%' }}
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    //
                    name="drawingNo"
                    id="drawingNo"
                    value={drawingNo}
                    type="text"
                    onChange={(e) => {
                      setDrawingNo(e.target.value);
                      const data = parentEstimateInfo;
                      data.estimateProducts[e.target.name] = e.target.value;
                      setParentEstimateInfo(data);
                    }}
                  />
                </td>
              </tr>
              {/** 名称 */}
              <tr>
                <td colSpan={2} style={{ width: '23%' }} className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">名称</label>
                </td>
                <td colSpan={10} style={{ width: '80%' }} className="detail-tbl-val">
                  <Input
                    style={{ padding: 5, marginLeft: 5, width: '98%' }}
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    name="name"
                    id="name"
                    value={name}
                    type="text"
                    onChange={(e) => {
                      setName(e.target.value);
                      const data = parentEstimateInfo;
                      data.estimateProducts[e.target.name] = e.target.value;
                      setParentEstimateInfo(data);
                    }}
                  />
                </td>
              </tr>
              {/** お客様名／お客様係数 */}
              <tr>
                <td colSpan={2} style={{ width: '23%' }} className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">お客様名／お客様係数</label>
                </td>
                <td colSpan={7} style={{ width: '50%' }} className="detail-tbl-val">
                  <Input
                    style={{ padding: 5, marginLeft: 5, width: '98%' }}
                    // className={editMode ? 'input-editable' : 'input-non-editable'}
                    className={'input-non-editable'}
                    name="clientName"
                    id="clientName"
                    type="text"
                    onChange={(e) => {
                      setClientName(e.target.value);
                      const data = parentEstimateInfo;
                      data.estimateProducts[e.target.name] = e.target.value;
                      setParentEstimateInfo(data);
                    }}
                    value={clientName}
                    // suffix={
                    //   editMode ? (
                    //     <a
                    //       onClick={() => {
                    //         getCustomerName();
                    //       }}
                    //     >
                    //       <Image preview={false} width={20} height={20} src={search} style={{ marginBottom: '5px' }} />
                    //     </a>
                    //   ) : (
                    //     ''
                    //   )
                    // }
                  />
                </td>
                <td className="detail-tbl-val">
                  {editMode ? (
                    <a
                      onClick={() => {
                        getCustomerName();
                      }}
                    >
                      <Image preview={false} width={20} height={20} src={search} style={{ marginBottom: '5px' }} />
                    </a>
                  ) : (
                    ''
                  )}
                </td>
                <td colSpan={2} style={{ borderLeft: '0.1px solid #a5a5a5' }} className="detail-tbl-val">
                  <div style={{ display: 'flex' }}>
                    <Input
                      style={{ padding: 5, marginLeft: 5, width: '94%' }}
                      className={'input-non-editable'}
                      name="customerCoefficient"
                      id="customerCoefficient"
                      type="text"
                      value={toDecimal(customerCoefficient, 2)}
                    />
                  </div>
                </td>
              </tr>
              {/** 数量 */}
              <tr>
                <td colSpan={2} style={{ width: '23%' }} className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">数量</label>
                </td>
                <td colSpan={4} style={{ width: '27.5%' }} className="detail-tbl-val">
                  <Input
                    style={{ padding: 5, marginLeft: 5, width: '94%' }}
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    name="quantity"
                    id="quantity"
                    value={quantity}
                    type="text"
                    onKeyPress={(e) => {
                      handleKeyPress(e, true);
                    }}
                    onChange={(e) => {
                      let quantity = Number(e.target.value);
                      setQuantity(quantity);
                      const data = parentEstimateInfo;
                      data.estimateProducts[e.target.name] = quantity;
                      setParentEstimateInfo(data);
                    }}
                  />
                </td>
                <td colSpan={2} style={{ width: '26%' }} className="detail-tbl-val-title">
                  {editMode ? (
                    <Button onClick={adjustPrice} className="cancleButton" style={{ width: '90px', marginLeft: 10 }}>
                      価格調整
                    </Button>
                  ) : (
                    <label className="detail-tbl-val-title-label">価格調整</label>
                  )}
                </td>
                <td colSpan={4} style={{ width: '25%' }} className="detail-tbl-val">
                  <CurrencyInput
                    style={{ padding: 5, marginLeft: 5, width: '94%' }}
                    className={'input-non-editable'}
                    name="adjustAmountLabel"
                    id="adjustAmountLabel"
                    type="text"
                    value={adjustAmountLabel}
                    prefix={plusMinusActLabel + ' ¥'}
                    decimalsLimit={100}
                  />
                </td>
              </tr>
              {/** 受注形態 */}
              <tr>
                <td colSpan={2} style={{ width: '23%' }} className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">受注形態</label>
                </td>
                <td colSpan={4} style={{ width: '27.5%' }} className="detail-tbl-val">
                  {editMode ? (
                    <Select
                      className="iq3-detail-select"
                      value={coeffOrdersId}
                      onChange={(e) => {
                        setCoeffOrdersId(e);
                        const data = parentEstimateInfo;
                        // data['coeffOrdersId'] = e;
                        let record = orderCoeffs?.filter((record) => record.id === e);
                        setOrderCoefficient(record?.[0].coeff);
                        setCoeffOrdersName(record?.[0].name);
                        data.estimateProducts['coeffOrdersName'] = record?.[0].name;
                        data.estimateProducts['coeffOrdersId'] = record?.[0].id;
                        data.estimateProducts['otherProduct']['coeffOrders'] = record?.[0].coeff;
                        setParentEstimateInfo(data);
                      }}
                      style={{ marginLeft: 5, width: '94%' }}
                    >
                      {orderCoeffs?.map((option, index) => (
                        <Select.Option key={index} value={option.id}>
                          {option.name}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : (
                    <label style={{ fontSize: '13px', paddingLeft: 10 }}>
                      {getNameById(coeffOrdersId, orderCoeffs)}
                    </label>
                  )}
                </td>
                <td colSpan={2} style={{ width: '25%' }} className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">受注係数</label>
                </td>
                <td colSpan={4} style={{ width: '25%' }} className="detail-tbl-val">
                  {' '}
                  <div style={{ display: 'flex' }}>
                    <Input
                      style={{ padding: 5, marginLeft: 5, width: '94%' }}
                      className={'input-non-editable'}
                      name="orderCoefficient"
                      id="orderCoefficient"
                      type="text"
                      value={toDecimal(orderCoefficient, 2)}
                    />
                  </div>
                </td>
              </tr>
              {/** 納期形態 */}
              <tr>
                <td colSpan={2} style={{ width: '23%' }} className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">納期形態</label>
                </td>
                <td colSpan={4} style={{ width: '27.5%' }} className="detail-tbl-val">
                  {editMode ? (
                    <Select
                      className="iq3-detail-select"
                      value={coeffDeliveryDatesId}
                      onChange={(e) => {
                        setCoeffDeliveryDatesId(e);
                        const data = parentEstimateInfo;
                        let record = deliveryDateCoeffs?.filter((record) => record.id === e);
                        setDeliveryCoefficient(record[0].coeff);
                        setCoeffDeliveryDatesName(record?.[0].name);
                        data.estimateProducts['coeffDeliveryDatesName'] = record?.[0].name;
                        data.estimateProducts['coeffDeliveryDatesId'] = record?.[0].id;
                        data.estimateProducts['otherProduct']['coeffDeliveryDates'] = record?.[0].coeff;
                        setParentEstimateInfo(data);
                      }}
                      style={{ marginLeft: 5, width: '94%' }}
                    >
                      {deliveryDateCoeffs?.map((option, index) => (
                        <Select.Option key={index} value={option.id}>
                          {option.name}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : (
                    <label style={{ fontSize: '13px', paddingLeft: 10 }}>
                      {getNameById(coeffDeliveryDatesId, deliveryDateCoeffs)}
                    </label>
                  )}
                </td>
                <td colSpan={2} style={{ width: '25%' }} className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">納期係数</label>
                </td>
                <td colSpan={4} style={{ width: '25%' }} className="detail-tbl-val">
                  {' '}
                  <div style={{ display: 'flex' }}>
                    <Input
                      style={{ padding: 5, marginLeft: 5, width: '94%' }}
                      className={'input-non-editable'}
                      name="deliveryCoefficient"
                      id="deliveryCoefficient"
                      type="text"
                      value={toDecimal(deliveryCoefficient, 2)}
                    />
                  </div>
                </td>
              </tr>
              {/** 購入品 */}
              <tr>
                <td colSpan={2} style={{ width: '23%' }} className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">購入品</label>
                </td>
                <td colSpan={4} style={{ width: '27.5%' }} className="detail-tbl-val">
                  <div style={{ display: 'flex' }}>
                    {editMode ? (
                      <Select
                        className={
                          editMode ? 'iq3-detail-select input-editable' : 'iq3-detail-select input-non-editable'
                        }
                        value={coeffPurchasesId}
                        onChange={(e) => {
                          setCoeffPurchasesId(e);
                          const data = parentEstimateInfo;
                          let record = purchaseCoeffs?.filter((record) => record.id === e);
                          setPurchaseCoefficient(record[0].coeff);
                          setCoeffPurchasesName(record?.[0].name);
                          data.estimateProducts['coeffPurchasesName'] = record?.[0].name;
                          data.estimateProducts['coeffPurchasesId'] = record?.[0].id;
                          data.estimateProducts['otherProduct']['coeffPurchases'] = record?.[0].coeff;
                          setParentEstimateInfo(data);
                        }}
                        style={{ marginLeft: 5, width: '94%' }}
                      >
                        {purchaseCoeffs?.map((option, index) => (
                          <Select.Option key={index} value={option.id}>
                            {option.name}
                          </Select.Option>
                        ))}
                      </Select>
                    ) : (
                      <label style={{ fontSize: '13px', paddingLeft: 10 }}>
                        {getNameById(coeffPurchasesId, purchaseCoeffs)}
                      </label>
                    )}
                  </div>
                </td>
                <td colSpan={2} style={{ width: '25%' }} className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">購入品係数</label>
                </td>
                <td colSpan={4} style={{ width: '25%' }} className="detail-tbl-val">
                  {' '}
                  <div style={{ display: 'flex' }}>
                    <Input
                      style={{ padding: 5, marginLeft: 5, width: '94%' }}
                      className={'input-non-editable'}
                      name="purchaseCoefficient"
                      id="purchaseCoefficient"
                      type="text"
                      value={toDecimal(purchaseCoefficient, 2)}
                    />
                  </div>
                </td>
              </tr>
              {/** 管理方法 */}
              <tr>
                <td colSpan={2} style={{ width: '23%' }} className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">管理方法</label>
                </td>
                <td colSpan={4} style={{ width: '27.5%' }} className="detail-tbl-val">
                  <div style={{ display: 'flex' }}>
                    {editMode ? (
                      <Select
                        className={
                          editMode ? 'iq3-detail-select input-editable' : 'iq3-detail-select input-non-editable'
                        }
                        value={coeffManagementsId}
                        onChange={(e) => {
                          setCoeffManagementsId(e);
                          const data = parentEstimateInfo;
                          let record = mangCoeffs?.filter((record) => record.id === e);
                          setManagementCoefficient(record[0].coeff);
                          setCoeffManagementsName(record?.[0].name);
                          data.estimateProducts['coeffManagementsName'] = record?.[0].name;
                          data.estimateProducts['coeffManagementsId'] = record?.[0].id;
                          data.estimateProducts['otherProduct']['coeffManagements'] = record?.[0].coeff;
                          setParentEstimateInfo(data);
                        }}
                        style={{ marginLeft: 5, width: '94%' }}
                      >
                        {mangCoeffs?.map((option, index) => (
                          <Select.Option key={index} value={option.id}>
                            {option.name}
                          </Select.Option>
                        ))}
                      </Select>
                    ) : (
                      <label style={{ fontSize: '13px', paddingLeft: 10 }}>
                        {getNameById(coeffManagementsId, mangCoeffs)}
                      </label>
                    )}
                  </div>
                </td>
                <td colSpan={2} style={{ width: '25%' }} className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">管理係数</label>
                </td>
                <td colSpan={4} style={{ width: '25%' }} className="detail-tbl-val">
                  {' '}
                  <div style={{ display: 'flex' }}>
                    <Input
                      style={{ padding: 5, marginLeft: 5, width: '94%' }}
                      className={'input-non-editable'}
                      name="managementCoefficient"
                      id="managementCoefficient"
                      type="text"
                      value={toDecimal(managementCoefficient, 2)}
                    />
                  </div>
                </td>
              </tr>
              {/** 配送方法 */}
              {/* <tr>
                <td colSpan={2} style={{ width: '23%' }} className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">配送方法</label>
                </td>
                <td colSpan={4} style={{ width: '27.5%' }} className="detail-tbl-val">
                  {editMode ? (
                    <Select
                      className="iq3-detail-select"
                      value={shippingMethod}
                      onChange={(e) => {
                        setShippingMethod(e);
                        const data = parentEstimateInfo;
                        data['shippingMethod'] = e;
                        setParentEstimateInfo(data);
                      }}
                      style={{ marginLeft: 5, width: '94%' }}
                    >
                      {deadLineSelect?.map((option) => (
                        <Select.Option value={option.value}>{option.label}</Select.Option>
                      ))}
                    </Select>
                  ) : (
                    <label style={{ fontSize: '13px', paddingLeft: 10 }}>{shippingMethod}</label>
                  )}
                </td>
                <td colSpan={2} style={{ width: '25%' }} className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">配送エリア</label>
                </td>
                <td colSpan={4} style={{ width: '25%' }} className="detail-tbl-val">
                  {editMode ? (
                    <Select
                      className="iq3-detail-select"
                      value={shippingArea}
                      onChange={(e) => {
                        setShippingArea(e);
                        y;
                        const data = parentEstimateInfo;
                        data['shippingArea'] = e;
                        setParentEstimateInfo(data);
                      }}
                      style={{ marginLeft: 5, width: '94%' }}
                    >
                      {deadLineSelect?.map((option) => (
                        <Select.Option value={option.value}>{option.label}</Select.Option>
                      ))}
                    </Select>
                  ) : (
                    <label style={{ fontSize: '13px', paddingLeft: 10 }}>{shippingArea}</label>
                  )}
                </td>
              </tr> */}
              {/** 総重量・面積 */}
              <tr>
                <td colSpan={2} style={{ width: '23%' }} className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">総重量（kg）</label>
                </td>
                <td colSpan={4} style={{ width: '27.5%' }} className="detail-tbl-val">
                  <label style={{ marginLeft: '5px' }}>{totalWeight}</label>

                  {/* <Input
                    style={{ padding: 5, marginLeft: 5, width: '94%' }}
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    name="totalWeight"
                    id="totalWeight"
                    value={totalWeight}
                    type="text"
                    onChange={(e) => {
                      setTotalWeight(e.target.value);
                      const data = parentEstimateInfo;
                      data.estimateProducts[e.target.name] = e.target.value;
                      setParentEstimateInfo(data);
                    }}
                    onKeyPress={(e) => {
                      handleKeyPress(e, false);
                    }}
                  /> */}
                </td>
                <td colSpan={2} style={{ width: '25%' }} className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">
                    総表面積（cm<sup>2</sup>）
                  </label>
                </td>
                <td colSpan={4} style={{ width: '25%' }} className="detail-tbl-val">
                  <label style={{ marginLeft: '5px' }}>{totalSurfaceArea}</label>

                  {/* <Input
                      style={{ padding: 5, marginLeft: 5, width: '94%' }}
                      className={editMode ? 'input-editable' : 'input-non-editable'}
                      name="totalSurfaceArea"
                      id="totalSurfaceArea"
                      type="text"
                      onChange={(e) => {
                        setTotalSurfaceArea(e.target.value);
                        const data = parentEstimateInfo;
                        data.estimateProducts[e.target.name] = e.target.value;
                        setParentEstimateInfo(data);
                      }}
                      // disabled
                      value={totalSurfaceArea}
                      onKeyPress={(e) => {
                        handleKeyPress(e, false);
                      }}
                    /> */}
                  {/* {editMode ? (
                      <div style={{ height: 18, width: '13%', display: 'flex' }}>
                        <CaretUpOutlined style={{ fontSize: 16, marginTop: 7 }} />
                        <CaretDownOutlined style={{ fontSize: 16, marginTop: 7 }} />
                      </div>
                    ) : (
                      ''
                    )} */}
                </td>
              </tr>
              {/** 作成者・最終更新者 */}
              <tr>
                <td colSpan={2} style={{ width: '23%' }} className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">作成者</label>
                </td>
                <td colSpan={4} style={{ width: '27.5%' }} className="detail-tbl-val">
                  {editMode ? (
                    <Select
                      className="iq3-detail-select"
                      value={createrId}
                      onChange={(e) => {
                        let createrName = getNameByRecordId(e, staffList);
                        setCreaterId(e);
                        setCreater(createrName);
                        const data = parentEstimateInfo;
                        data.estimateProducts['creater'] = createrName;
                        data.estimateProducts['createrId'] = e;
                        setParentEstimateInfo(data);
                      }}
                      style={{ marginLeft: 5, width: '94%' }}
                    >
                      {staffList?.map((option, index) => (
                        <Select.Option key={index} value={option.id}>
                          {option.name}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : (
                    <label style={{ fontSize: '13px', paddingLeft: 10 }}>
                      {getNameByRecordId(createrId, staffList)}
                    </label>
                  )}
                </td>
                <td colSpan={2} style={{ width: '25%' }} className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">最終更新者</label>
                </td>
                <td colSpan={4} style={{ width: '25%' }} className="detail-tbl-val">
                  {' '}
                  <div style={{ display: 'flex' }}>
                    {editMode ? (
                      <Select
                        className="iq3-detail-select"
                        value={modifierId}
                        onChange={(e) => {
                          let modifierName = getNameByRecordId(e, staffList);
                          setModifierId(e);
                          setModifier(modifierName);
                          const data = parentEstimateInfo;
                          data.estimateProducts['modifier'] = modifierName;
                          data.estimateProducts['modifierId'] = e;
                          setParentEstimateInfo(data);
                        }}
                        style={{ marginLeft: 5, width: '94%' }}
                      >
                        {staffList?.map((option, index) => (
                          <Select.Option key={index} value={option.id}>
                            {option.name}
                          </Select.Option>
                        ))}
                      </Select>
                    ) : (
                      <label style={{ fontSize: '13px', paddingLeft: 10 }}>
                        {getNameByRecordId(modifierId, staffList)}
                      </label>
                    )}
                  </div>
                </td>
              </tr>
              {/** 作成日・更新日 */}
              <tr>
                <td colSpan={2} style={{ width: '23%' }} className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">作成日</label>
                </td>
                <td colSpan={4} style={{ width: '27.5%' }} className="detail-tbl-val">
                  {editMode ? (
                    <DatePicker
                      style={{ padding: 5, marginLeft: 5, width: '94%' }}
                      className={editMode ? 'input-editable' : 'input-non-editable'}
                      onChange={onChangeCreatedDate}
                      value={dayjs(created, 'YYYY-MM-DD')}
                    />
                  ) : (
                    <label style={{ fontSize: '13px', paddingLeft: 10 }}>{formatDate(created)}</label>
                  )}
                </td>
                <td colSpan={2} style={{ width: '25%' }} className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">更新日</label>
                </td>
                <td colSpan={4} style={{ width: '25%' }} className="detail-tbl-val">
                  {' '}
                  <div style={{ display: 'flex' }}>
                    {editMode ? (
                      <DatePicker
                        style={{ padding: 5, marginLeft: 5, width: '94%' }}
                        className={editMode ? 'input-editable' : 'input-non-editable'}
                        onChange={onChangeModifiedDate}
                        value={dayjs(modified, 'YYYY-MM-DD')}
                      />
                    ) : (
                      <label style={{ fontSize: '13px', paddingLeft: 10 }}>{formatDate(modified)}</label>
                    )}
                  </div>
                </td>
              </tr>
              {/** XYZサイズ（mm） */}
              <tr>
                <td colSpan={2} style={{ width: '23%' }} className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">XYZサイズ（mm）</label>
                </td>
                <td colSpan={4} style={{ width: '27.5%' }} className="detail-tbl-val">
                  <Input
                    style={{ padding: 5, marginLeft: 5, width: '94%' }}
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    name="sizeX"
                    id="sizeX"
                    value={sizeX}
                    type="text"
                    onChange={(e) => {
                      setSizeX(e.target.value);
                      const data = parentEstimateInfo;
                      data.estimateProducts[e.target.name] = e.target.value;
                      setParentEstimateInfo(data);
                    }}
                    placeholder="Xサイズ"
                    onKeyPress={(e) => {
                      handleKeyPress(e, false);
                    }}
                  />
                </td>
                <td colSpan={2} style={{ width: '25%', border: '0.1px solid #a5a5a5' }} className="detail-tbl-val">
                  <Input
                    style={{ padding: 5, marginLeft: 5, width: '94%' }}
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    name="sizeY"
                    id="sizeY"
                    value={sizeY}
                    type="text"
                    onChange={(e) => {
                      setSizeY(e.target.value);
                      const data = parentEstimateInfo;
                      data.estimateProducts[e.target.name] = e.target.value;
                      setParentEstimateInfo(data);
                    }}
                    placeholder="Yサイズ"
                    onKeyPress={(e) => {
                      handleKeyPress(e, false);
                    }}
                  />
                </td>
                <td colSpan={4} style={{ width: '25%' }} className="detail-tbl-val">
                  <Input
                    style={{ padding: 5, marginLeft: 5, width: '94%' }}
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    name="sizeZ"
                    id="sizeZ"
                    value={sizeZ}
                    type="text"
                    onChange={(e) => {
                      setSizeZ(e.target.value);
                      const data = parentEstimateInfo;
                      data.estimateProducts[e.target.name] = e.target.value;
                      setParentEstimateInfo(data);
                    }}
                    placeholder="Zサイズ"
                    onKeyPress={(e) => {
                      handleKeyPress(e, false);
                    }}
                  />
                </td>
              </tr>
              {/** 見積番号・案件状態 */}
              <tr>
                <td colSpan={2} style={{ width: '23%' }} className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">見積番号</label>
                </td>
                <td colSpan={4} style={{ width: '80%' }} className="detail-tbl-val">
                  <Input
                    style={{ padding: 5, marginLeft: 5, width: '94%' }}
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    name="estimateNo"
                    id="estimateNo"
                    value={estimateNo}
                    type="text"
                    onChange={(e) => {
                      setEstimateNo(e.target.value);
                      const data = parentEstimateInfo;
                      data.estimateProducts[e.target.name] = e.target.value;
                      setParentEstimateInfo(data);
                    }}
                  />
                </td>
                <td colSpan={2} style={{ width: '25%' }} className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">案件状態</label>
                </td>
                <td colSpan={4} style={{ width: '25%' }} className="detail-tbl-val">
                  {' '}
                  <div style={{ display: 'flex' }}>
                    {editMode ? (
                      <Select
                        className="iq3-detail-select"
                        value={estimateStatusId}
                        onChange={(e) => {
                          setEstimateStatusId(e);
                          let estStatusName = getNameByRecordId(e, estimateStatusInfo);
                          setEstimateStatus(estStatusName);
                          const data = parentEstimateInfo;
                          data.estimateProducts['estimateStatusId'] = e;
                          data.estimateProducts['estimateStatus'] = estStatusName;
                          setParentEstimateInfo(data);
                        }}
                        style={{ marginLeft: 5, width: '94%' }}
                      >
                        {estimateStatusInfo?.map((option, index) => (
                          <Select.Option key={index} value={option.id}>
                            {option.name}
                          </Select.Option>
                        ))}
                      </Select>
                    ) : (
                      <label style={{ fontSize: '13px', paddingLeft: 10 }}>
                        {getNameByRecordId(estimateStatusId, estimateStatusInfo)}
                      </label>
                    )}
                  </div>
                </td>
              </tr>
              {/** コメント */}
              <tr style={{ height: '110px' }}>
                <td colSpan={2} style={{ width: '23%' }} className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">コメント</label>
                </td>
                <td colSpan={10} style={{ width: '80%', height: '133px' }} className="detail-tbl-val">
                  <TextArea
                    style={{ padding: 8, marginLeft: 5, width: '98%', verticalAlign: 'Top', fontSize: '13px' }}
                    className={editMode ? 'input-editable' : 'input-non-editable'}
                    name="comment"
                    id="comment"
                    value={comment}
                    type="text"
                    rows={5}
                    onChange={(e) => {
                      setComment(e.target.value);
                      const data = parentEstimateInfo;
                      data.estimateProducts[e.target.name] = e.target.value;
                      setParentEstimateInfo(data);
                    }}
                  />
                </td>
              </tr>
            </thead>
          </Table>
        </div>
        <div style={{ float: 'right', width: '45%', marginLeft: '15px', overflowY: 'auto', height: '80.5vh' }}>
          <Table className="propertiesTbl">
            <thead>
              {/* 費用 */}
              <tr>
                <td colSpan={12} className="detail-tbl-val-title">
                  <div style={{ display: 'flex' }}>
                    {customizeOpen ? (
                      <CaretUpOutlined
                        style={{ fontSize: 18, marginLeft: 5 }}
                        onClick={(e) => customizeOpenDetail(e, false)}
                      />
                    ) : (
                      <CaretDownOutlined
                        style={{ fontSize: 18, marginLeft: 5 }}
                        onClick={(e) => customizeOpenDetail(e, true)}
                      />
                    )}
                    <label style={{ marginLeft: 5 }}>費用</label>
                  </div>
                </td>
              </tr>
              <tr style={{ display: customCostLst?.length > 0 && customizeOpen ? '' : 'none' }}>
                <td
                  colSpan={3}
                  className="detail-tbl-val-title"
                  style={{ textAlign: 'center', backgroundColor: '#d9d9d99e', color: 'black' }}
                >
                  <label style={{ marginLeft: 5 }}>名称</label>
                </td>
                <td
                  colSpan={3}
                  className="detail-tbl-val-title"
                  style={{
                    textAlign: 'center',
                    backgroundColor: '#d9d9d99e',
                    color: 'black',
                    borderLeft: '0.1px dashed #a5a5a5',
                  }}
                >
                  <label style={{ marginLeft: 5 }}>単価</label>
                </td>
                <td
                  colSpan={3}
                  className="detail-tbl-val-title"
                  style={{
                    textAlign: 'center',
                    backgroundColor: '#d9d9d99e',
                    color: 'black',
                    borderLeft: '0.1px dashed #a5a5a5',
                  }}
                >
                  <label style={{ marginLeft: 5 }}>単価優先</label>
                  <Checkbox
                    style={{ marginLeft: '5px' }}
                    disabled={editMode ? false : true}
                    onChange={(e) => settingTankaPriority(e)}
                    checked={tankaPriotiryChecked}
                  />
                </td>
                <td
                  colSpan={3}
                  className="detail-tbl-val-title"
                  style={{
                    textAlign: 'center',
                    backgroundColor: '#d9d9d99e',
                    color: 'black',
                    borderLeft: '0.1px dashed #a5a5a5',
                  }}
                >
                  <label style={{ marginLeft: 5 }}>合計</label>
                </td>
              </tr>
              {customCostLst != undefined
                ? customCostLst?.map((item, index) => {
                    const { additionalItemName, unitPrice, isUseUnit, totalPrice } = item;
                    // 追加項目の費用マスタに存在する場合、表示

                    return (
                      <tr key={index} style={{ display: customizeOpen ? '' : 'none' }}>
                        <td colSpan={3} style={{ width: '23%' }} className="detail-tbl-val">
                          <Input
                            style={{ padding: 5, marginLeft: 5, width: '91.7%', textAlign: 'center', border: 'none' }}
                            // className={editMode ? 'input-editable' : 'input-non-editable'}
                            className={'input-non-editable'}
                            name="additionalItemName"
                            id="additionalItemName"
                            value={additionalItemName}
                            type="text"
                          />
                        </td>
                        <td
                          colSpan={3}
                          style={{ width: '80%', borderLeft: '0.1px dashed #a5a5a5' }}
                          className="detail-tbl-val"
                        >
                          {editMode ? (
                            <CurrencyInput
                              name="unitPrice"
                              id="unitPrice"
                              value={unitPrice}
                              defaultValue={0}
                              style={{ padding: 3, width: '91.7%' }}
                              className={
                                editMode ? 'currencyInputCost input-editable' : 'currencyInputCost input-non-editable'
                              }
                              prefix="¥"
                              decimalsLimit={100}
                              onValueChange={(e) => onArrValChange(index, e, 'unitPrice', 'cost')}
                            />
                          ) : (
                            <label style={{ fontSize: '13px', paddingLeft: 10 }}>
                              {unitPrice ? JPYs.format(unitPrice) : JPYs.format(0)}
                            </label>
                          )}
                        </td>
                        <td
                          colSpan={3}
                          style={{ width: '23%', textAlign: 'center', borderLeft: '0.1px dashed #a5a5a5' }}
                          className="detail-tbl-val"
                        >
                          <Checkbox
                            id={index}
                            disabled={editMode ? false : true}
                            checked={isUseUnit}
                            onChange={(e) => {
                              const data = [...customCostLst];
                              data[index].isUseUnit = e.target.checked;
                              const isAllUsedTanka = data?.filter((item) => item.isUseUnit == false);
                              if (isAllUsedTanka?.length == 0) {
                                setTankaPriotiryChecked(true);
                              } else {
                                setTankaPriotiryChecked(false);
                              }
                              setCustomCostLst(data);
                            }}
                          />
                        </td>
                        <td
                          colSpan={3}
                          style={{ width: '80%', borderLeft: '0.1px dashed #a5a5a5' }}
                          className="detail-tbl-val"
                        >
                          <Input
                            style={{ padding: 5, marginLeft: 5, width: '91.7%', textAlign: 'center' }}
                            className={'input-non-editable'}
                            name="totalPrice"
                            id="labelTotalPrice"
                            value={totalPrice ? JPYs.format(totalPrice) : JPYs.format(0)}
                            type="text"
                            onChange={(e) => onArrValChange(index, e, e.target.name, 'cost')}
                            // prefix={<label style={{ fontSize: '13px' }}>¥</label>}
                          />
                        </td>
                      </tr>
                    );
                  })
                : ''}
              {/* 日付 */}
              <tr>
                <td colSpan={12} className="detail-tbl-val-title">
                  <div style={{ display: 'flex' }}>
                    {dateOpen ? (
                      <CaretUpOutlined
                        style={{ fontSize: 18, marginLeft: 5 }}
                        onClick={(e) => dateOpenDetail(e, false)}
                      />
                    ) : (
                      <CaretDownOutlined
                        style={{ fontSize: 18, marginLeft: 5 }}
                        onClick={(e) => dateOpenDetail(e, true)}
                      />
                    )}
                    <label style={{ marginLeft: 5 }}>日付</label>
                  </div>
                </td>
              </tr>
              {customDateLst != undefined
                ? customDateLst?.map((item, index) => {
                    const { additionalItemId, additionalItemName, date } = item;
                    // 追加項目の費用マスタに存在する場合、表示
                    return (
                      <tr key={index} style={{ display: dateOpen ? '' : 'none' }}>
                        <td colSpan={2} style={{ width: '23%' }} className="detail-tbl-val">
                          <Input
                            style={{ padding: 5, marginLeft: 5, width: '91.7%', border: 'none' }}
                            className={'input-non-editable'}
                            name={'labelDate' + additionalItemId}
                            id={'labelDate' + additionalItemId}
                            value={additionalItemName}
                            type="text"
                          />
                        </td>
                        <td
                          colSpan={10}
                          style={{ width: '80%', borderLeft: '0.1px dashed #a5a5a5' }}
                          className="detail-tbl-val"
                        >
                          {editMode ? (
                            <DatePicker
                              name={'date' + additionalItemId}
                              id={'date' + additionalItemId}
                              style={{ padding: 5, marginLeft: 5, width: '29.7%' }}
                              className={editMode ? 'input-editable' : 'input-non-editable'}
                              onChange={(e, dateString) => onArrValChange(index, dateString, 'date', 'date')}
                              value={date ? dayjs(date, 'YYYY-MM-DD') : ''}
                            />
                          ) : (
                            <label style={{ fontSize: '13px', paddingLeft: 10 }}>{date ? formatDate(date) : ''}</label>
                          )}
                        </td>
                      </tr>
                    );
                  })
                : ''}
              {/* ラベル */}
              <tr>
                <td colSpan={12} className="detail-tbl-val-title">
                  <div style={{ display: 'flex' }}>
                    {lableSmallOpen ? (
                      <CaretUpOutlined
                        style={{ fontSize: 18, marginLeft: 5 }}
                        onClick={(e) => labelSmallOpenDetail(e, false)}
                      />
                    ) : (
                      <CaretDownOutlined
                        style={{ fontSize: 18, marginLeft: 5 }}
                        onClick={(e) => labelSmallOpenDetail(e, true)}
                      />
                    )}
                    <label style={{ marginLeft: 5 }}>ラベル</label>
                  </div>
                </td>
              </tr>
              {customLabelLst != undefined
                ? customLabelLst?.map((item, index) => {
                    const { additionalItemName, label } = item;
                    // 追加項目の費用マスタに存在する場合、表示
                    return (
                      <tr key={index} style={{ display: lableSmallOpen ? '' : 'none' }}>
                        <td colSpan={2} style={{ width: '23%' }} className="detail-tbl-val">
                          <Input
                            style={{ padding: 5, marginLeft: 5, width: '91.7%', border: 'none' }}
                            className={'input-non-editable'}
                            name="additionalItemName"
                            id="labelAdditionalItemName"
                            value={additionalItemName}
                            type="text"
                          />
                        </td>
                        <td
                          colSpan={10}
                          style={{ width: '80%', borderLeft: '0.1px dashed #a5a5a5' }}
                          className="detail-tbl-val"
                        >
                          <Input
                            style={{ padding: 5, marginLeft: 5, width: '30%' }}
                            className={editMode ? 'input-editable' : 'input-non-editable'}
                            name="label"
                            id="labelLabel"
                            value={label}
                            type="text"
                            onChange={(e) => onArrValChange(index, e, e.target.name, 'label')}
                          />
                        </td>
                      </tr>
                    );
                  })
                : ''}

              {/** メモ */}
              <tr>
                <td colSpan={12} className="detail-tbl-val-title">
                  <div style={{ display: 'flex' }}>
                    {labelOpen ? (
                      <CaretUpOutlined
                        style={{ fontSize: 18, marginLeft: 5 }}
                        onClick={(e) => labelOpenDetail(e, false)}
                      />
                    ) : (
                      <CaretDownOutlined
                        style={{ fontSize: 18, marginLeft: 5 }}
                        onClick={(e) => labelOpenDetail(e, true)}
                      />
                    )}
                    <label style={{ marginLeft: 5 }}>メモ</label>
                  </div>
                </td>
              </tr>
              {customMemoLst != undefined
                ? customMemoLst?.map((item, index) => {
                    const { additionalItemName, memo } = item;
                    // 追加項目の費用マスタに存在する場合、表示
                    return (
                      <tr key={index} style={{ display: labelOpen ? '' : 'none' }}>
                        <td colSpan={2} style={{ width: '23%' }} className="detail-tbl-val">
                          <Input
                            style={{ padding: 5, marginLeft: 5, width: '91.7%', border: 'none' }}
                            className={'input-non-editable'}
                            name="additionalItemName"
                            id="labelAdditionalItemName"
                            value={additionalItemName}
                            type="text"
                          />
                        </td>
                        <td
                          colSpan={10}
                          style={{
                            width: '80%',
                            borderLeft: '0.1px dashed #a5a5a5',
                          }}
                          className="detail-tbl-val"
                        >
                          <Input
                            style={{ padding: 5, marginLeft: 5, width: '98%' }}
                            className={editMode ? 'input-editable' : 'input-non-editable'}
                            name="memo"
                            id="labelMemo"
                            value={memo}
                            type="text"
                            onChange={(e) => onArrValChange(index, e, e.target.name, 'memo')}
                          />
                        </td>
                      </tr>
                    );
                  })
                : ''}
            </thead>
          </Table>
        </div>
      </div>

      {customerModal ? (
        <SearchAndGetCustomerData
          customerModal={customerModal}
          selectedCustomerData={selectedCustomerData}
          title={Customer}
          clientType={ClientType.Customer}
          selectedEstimateData={props.selectedDataDetail?.estimateProducts}
        />
      ) : (
        ''
      )}
      {/* 価格調整 */}
      {isAdjustPrice
        ? commonModal(
            isAdjustPrice,
            adjustPriceTitle,
            commonFooter(adjustPriceOK, adjustPriceCancel),
            null,
            420,
            adjustPriceOK,
            adjustPriceCancel,
            adjustPriceContent,
            null,
            null,
            false
          )
        : ''}
    </>
  );
});

export default ParentEstimateDetail;
