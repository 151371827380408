/**
 * クラス名：板金子部品見積材料詳細
 * 説明：板板金子部品見積材料詳細ファイルである。
 * 作成者：ナンス
 * 作成日：2023/06/29
 * バージョン：1.0
 */
import React, { useImperativeHandle, forwardRef, useEffect, useState, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { draw } from '../common/Common';

const IQ3MaterialDetail_SheetDetailPattern = forwardRef((props, ref) => {
  const [canvas, setCanvas] = useState('');
  const [canvasContext, setCanvasContext] = useState('leftToRight');

  /** 部品情報 */
  // パーツ情報
  const [mPartInfo, setMPartInfo] = useState([]);

  /** 配置情報 */
  // Scale
  let ca1Scale = 1;
  let ca2Scale = 1;

  useEffect(() => {
    /** 配置情報 */
    let settingInfo = {
      mClampdzX: Number(props.xGrabAllowance),
      mClampdzY: Number(props.yGrabAllowance),
      mMarginX: Number(props.xWidthOfPier),
      mMarginY: Number(props.yWidthOfPier),
    };

    /** 配置するCanvasの情報 */
    const canvas = document.getElementById('canvas');
    setCanvas(canvas);
    canvas.style.width = '100%';
    canvas.style.height = '100%';
    canvas.width = canvas.offsetWidth;
    canvas.height = canvas.offsetHeight;
    const canvasContext = canvas.getContext('2d');
    setCanvasContext(canvasContext);
    if (canvasContext !== null) {
      // 材料・掴み代・部品矩形・桟幅の配置
      let partCntInfo = draw(
        props.partInfo,
        settingInfo,
        props.materialInfo,
        props.isPattern2,
        props.ySetting,
        props.rightFit,
        props.topFit,
        document.getElementById('canvas')
      );

      // 部品XYサイズ配置
      // 製品の矩形X
      let partX = props.partInfo.mPartX + settingInfo.mMarginX;
      let partY = props.partInfo.mPartY + settingInfo.mMarginY;
      let fillTextX = 'X=' + partX * partCntInfo?.partCntX + '(' + partCntInfo?.partCntX + ')';
      let fillTextY = 'Y=' + partY * partCntInfo?.partCntY + '(' + partCntInfo?.partCntY + ')';
      canvasContext.beginPath();
      const { backgroundColor = 'black', color = 'black' } = { backgroundColor: 'PowderBlue', color: 'black' };
      // 開始X
      let startX = props.materialInfo.mSheetX * ca1Scale;
      let startY = props.materialInfo.mSheetY * ca1Scale;
      canvasContext.fillStyle = backgroundColor;
      canvasContext.fillRect(startX - 90, startY - 13, 100, 12);
      canvasContext.fillStyle = color;
      canvasContext.font = 'bold';
      canvasContext.fillText(fillTextX, startX - 88, startY - 3, 200);
      // 開始Y
      canvasContext.fillStyle = backgroundColor;
      canvasContext.fillRect(0, 0, 100, 12);
      canvasContext.fillStyle = color;
      canvasContext.font = 'bold';
      canvasContext.fillText(fillTextY, 2, 10, 200);
    }
  }, [props.isPatternDetail, props.suryou, props.partInfo, props.settingInfo, props.materialInfo, props.isPattern2]);

  return (
    <>
      <canvas width="480" height="240" id="canvas"></canvas>
    </>
  );
});

export default IQ3MaterialDetail_SheetDetailPattern;
