import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Table } from 'react-bootstrap';
import { Row, Select, Input } from 'antd';
import { PlusCircleFilled, CloseCircleFilled } from '@ant-design/icons';

import { getNameById } from '../common/Common';

const types = [
  { id: 1, value: '丸', label: '丸' },
  { id: 2, value: '角', label: '角' },
  { id: 3, value: '長角', label: '長角' },
  { id: 4, value: '長丸', label: '長丸' },
  { id: 5, value: 'その他', label: 'その他' },
];
const IQ3KouteiInput_Blank = forwardRef((props, ref) => {
  // const [editMode, setEditMode] = useState(false);

  const [plankValues, setPlankValues] = useState([]);
  const [firstplankValues, setFirstPlankValues] = useState([]);

  const [dimensionData, setDimensionData] = useState({});
  const [quantityList, setQuantityList] = useState([]);
  const [blankData, setBlankData] = useState([]);

  useEffect(() => {
    let data = props.blankInfo;
    setBlankData(data);
    if (data != undefined || data?.length > 0) {
      setDimensionData(data.details[0]?.dimensionData);
      setQuantityList(data.details[0]?.quantityList);
    }
    // setBlankData(props.blankInfo);
  }, [props.blankInfo]);

  useImperativeHandle(ref, () => ({
    setEditModeChange: setEditModeChange,
  }));

  const getInnerDia = (lists) => {
    let totalCount = lists?.reduce((total, item) => {
      const countValue = parseFloat(item.perimeter);
      return total + countValue;
    }, 0);
    props.blankInfo.details[0].dimensionData['innerDia'] = totalCount;
    props.blankInfo.details[0].dimensionData['innerAutoExtract'] = totalCount;
  };
  const changeDetails = (event, id, types, plankObj) => {
    const temp = JSON.parse(JSON.stringify(quantityList));
    let updatedData = [];
    if (types === 'types') {
      updatedData = temp?.map((row) => {
        if (row.id === id) {
          row[types] = event;
          if (event === 1 || event === 2) {
            row['xsize'] = 0;
            row['ysize'] = 0;
          } else if (event === 3 || event === 4) {
            row['diameter'] = 0;
          } else {
            row['xsize'] = 0;
            row['ysize'] = 0;
            row['diameter'] = 0;
          }
          row['perimeter'] = calculatePrimeter(row);
          /* return { ...row, [types]: event }; */
        }
        return row;
      });
      // setQuantityList(updatedData);
    } else {
      updatedData = temp?.map((row) => {
        if (row.id === id) {
          row[types] = event.target.value;
          row['perimeter'] = calculatePrimeter(row);
          //return { ...row, [types]: event.target.value };
        }
        return row;
      });
      // setQuantityList(updatedData);
    }
    setQuantityList(updatedData);
    getInnerDia(updatedData);
    const newBlankInfo = { ...props.blankInfo };
    newBlankInfo.details[0].quantityList = updatedData;
    props.updateBlank(newBlankInfo);
  };

  const calculatePrimeter = (plankObj) => {
    let perimeter = 0;
    switch (plankObj.types) {
      case 1:
        if (plankObj.diameter !== undefined && plankObj.diameter !== null)
          // perimeter = 2 * Number(plankObj.diameter) * Number(plankObj.count);
          perimeter = Number(plankObj.diameter) * Math.PI;
        break;
      case 2:
        if (plankObj.diameter !== undefined && plankObj.diameter !== null) perimeter = 4 * Number(plankObj.diameter);
        break;
      case 3:
        if (
          plankObj.xsize !== undefined &&
          plankObj.xsize !== null &&
          plankObj.ysize !== undefined &&
          plankObj.ysize !== null
        )
          perimeter = 2 * (Number(plankObj.xsize) + Number(plankObj.ysize));
        break;
      case 4:
        if (
          plankObj.xsize !== undefined &&
          plankObj.xsize !== null &&
          plankObj.ysize !== undefined &&
          plankObj.ysize !== null
        )
          // perimeter =
          //   Number(plankObj.xsize) * 2 - Number(plankObj.ysize) + Number(plankObj.ysize) * Number(plankObj.count);
          perimeter =
            Number(plankObj.xsize) -
            Number(plankObj.ysize) +
            (Number(plankObj.xsize) - Number(plankObj.ysize)) +
            Number(plankObj.ysize) * Math.PI +
            Number(plankObj.ysize) * Math.PI;
        break;
      default:
        perimeter = plankObj.perimeter;
        break;
    }
    return perimeter;
  };

  const addRow = (id) => {
    const copyData = quantityList?.slice(); // Create a copy of the original array

    const insertIndex = quantityList?.findIndex((item) => item.id === id); // Find the clicked index of the item
    const largestId = quantityList?.reduce((maxId, item) => {
      return item.id > maxId ? item.id : maxId;
    }, 0);

    if (insertIndex !== -1) {
      const data = {
        id: largestId + 1,
        types: quantityList[insertIndex].types,
        diameter: quantityList[insertIndex].diameter === null ? 0 : quantityList[insertIndex].diameter,
        xsize: quantityList[insertIndex].xsize === null ? 0 : quantityList[insertIndex].xsize,
        ysize: quantityList[insertIndex].ysize === null ? 0 : quantityList[insertIndex].ysize,
        perimeter: quantityList[insertIndex].perimeter === null ? 0 : quantityList[insertIndex].perimeter,
        count: quantityList[insertIndex].count === null ? 1 : quantityList[insertIndex].count,
        blankFlag: quantityList[insertIndex].blankFlag,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setQuantityList(copyData);
      getInnerDia(copyData);
      const newBlankInfo = { ...props.blankInfo };
      newBlankInfo.details[0].quantityList = copyData;
      props.updateBlank(newBlankInfo);
    }
  };
  const deleteRow = (id) => {
    if (quantityList?.length > 1) {
      const updatedData = quantityList?.filter((item) => item.id !== id);

      setQuantityList(updatedData);
      getInnerDia(updatedData);
      const newBlankInfo = { ...props.blankInfo };
      newBlankInfo.details[0].quantityList = updatedData;
      props.updateBlank(newBlankInfo);
    }
  };

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setDimensionData({
  //     ...dimensionData,
  //     [name]: parseFloat(value) || 0,
  //   });
  // };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const newBlankInfo = { ...props.blankInfo };

    const newDimensionData = { ...newBlankInfo.details[0].dimensionData };

    newDimensionData[name] = parseFloat(value) || 0;

    newBlankInfo.details[0].dimensionData = newDimensionData;
    setDimensionData(newDimensionData);
    props.updateBlank(newBlankInfo);
  };
  return (
    <>
      <Row className="mt-1 registerModal">
        <Table className="kouteiinput " style={{ width: '66%' }}>
          <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
            <tr style={{ textAlign: 'center' }}>
              <th style={{ width: '11%' }} className="tbl-header">
                外径周長(mm)
              </th>
              {/* <th style={{ width: '11%', padding: 0, verticalAlign: 'middle' }} className="tbl-header">
                自動抽出(外径周長)
              </th> */}
              <th style={{ width: '11%' }} className="tbl-header">
                内径周長(mm)
              </th>
              {/* <th style={{ width: '11%', padding: 0, verticalAlign: 'middle' }} className="tbl-header">
                自動抽出(内径周長)
              </th> */}
              <th style={{ width: '11%' }} className="tbl-header">
                空送距離(mm)
              </th>
              {/* <th style={{ width: '11%', padding: 0, verticalAlign: 'middle' }} className="tbl-header">
                自動抽出(空送距離)
              </th> */}
            </tr>
          </thead>
          <tbody>
            {/* {firstplankValues?.map((i) => (
              <> */}
            <tr>
              <td>
                <Input
                  className="input-editable"
                  value={dimensionData.outerDia}
                  name="outerDia"
                  onChange={handleInputChange}
                  // onChange={(e) => changeValue(e, 'val1')}
                  style={{ height: 32, textAlign: 'center' }}
                ></Input>
              </td>
              {/* <td>
                <Input
                  // className={editMode ? 'input-non-editable ' : 'input-non-editable '}
                  className="input-non-editable"
                  value={dimensionData.outerAutoExtract}
                  name="outerAutoExtract"
                  // onChange={handleInputChange}
                  // onChange={(e) => changeValue(e, 'val2')}
                  style={{ height: 32, textAlign: 'center' }}
                ></Input>
              </td> */}
              <td>
                <Input
                  value={dimensionData.innerDia}
                  name="innerDia"
                  onChange={handleInputChange}
                  // onChange={(e) => changeValue(e, 'val3')}
                  // className={editMode ? 'input-editable ' : 'input-non-editable '}
                  className="input-editable"
                  style={{ height: 32, textAlign: 'center' }}
                ></Input>
              </td>
              {/* <td>
                <Input
                  value={dimensionData.innerAutoExtract}
                  name="innerAutoExtract"
                  // onChange={handleInputChange}
                  // onChange={(e) => changeValue(e, 'val4')}
                  // className={editMode ? 'input-non-editable ' : 'input-non-editable '}
                  className="input-non-editable"
                  style={{ height: 32, textAlign: 'center' }}
                ></Input>
              </td> */}
              <td>
                <Input
                  // className={editMode ? 'input-editable ' : 'input-non-editable '}
                  className="input-editable"
                  value={dimensionData.airFeedDistance}
                  name="airFeedDistance"
                  onChange={handleInputChange}
                  // onChange={(e) => changeValue(e, 'val5')}
                  style={{ height: 32, textAlign: 'center' }}
                ></Input>
              </td>
              {/* <td>
                <Input
                  // className={editMode ? 'input-non-editable ' : 'input-non-editable '}
                  className="input-non-editable"
                  value={dimensionData.airFeedAutoExtract}
                  name="airFeedAutoExtract"
                  // onChange={handleInputChange}
                  style={{ height: 32, textAlign: 'center' }}
                ></Input>
              </td> */}
            </tr>
            {/* </>
            ))} */}
          </tbody>
        </Table>
      </Row>
      <Row className="mt-2 registerModal">
        <Table className="kouteiinput " style={{ width: '82.9%' }}>
          <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
            <tr style={{ textAlign: 'center' }}>
              <th style={{ width: '3%' }} className="tbl-header">
                No.
              </th>
              <th style={{ width: '18.5%' }} className="tbl-header">
                種類.形状
              </th>
              <th style={{ width: '11%' }} className="tbl-header">
                径/辺長(mm)
              </th>
              <th style={{ width: '11%' }} className="tbl-header">
                Xサイズ(mm)
              </th>
              <th style={{ width: '11%' }} className="tbl-header">
                Yサイズ(mm)
              </th>
              <th style={{ width: '11%' }} className="tbl-header">
                周長(mm)
              </th>
              <th style={{ width: '11%' }} className="tbl-header">
                数
              </th>
            </tr>
          </thead>
          <tbody>
            {quantityList?.map((i, index) => (
              <tr key={index}>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{index + 1}</td>
                <td style={{ verticalAlign: 'middle' }}>
                  {/* {editMode ? (
                    <> */}
                  <Select
                    defaultValue={1}
                    size="middle"
                    value={i.types}
                    onChange={(e) => changeDetails(e, i.id, 'types')}
                    style={{ width: '100%' }}
                  >
                    {types?.map((option, index) => (
                      <Select.Option key={index} value={option.id}>
                        {option.label}
                      </Select.Option>
                    ))}
                  </Select>
                </td>
                <td>
                  <Input
                    // className={editMode ? 'input-editable ' : 'input-non-editable '}
                    className="input-editable"
                    value={i.diameter}
                    onChange={(e) => changeDetails(e, i.id, 'diameter', i)}
                    style={{
                      height: 32,
                      textAlign: 'center',
                      borderColor: i.types == 3 || i.types == 4 || i.types == 5 ? 'transparent' : '#a5a5a5',
                      /*  backgroundColor: i.types == 3 || i.types == 4 || i.types == 5 ? '#d9d9d9' : 'white', */
                      pointerEvents: i.types == 3 || i.types == 4 || i.types == 5 ? 'none' : 'auto',
                    }}
                  ></Input>
                </td>
                <td>
                  <Input
                    // className={editMode ? 'input-editable ' : 'input-non-editable '}
                    className="input-editable"
                    value={i.xsize}
                    onChange={(e) => changeDetails(e, i.id, 'xsize')}
                    style={{
                      height: 32,
                      textAlign: 'center',
                      borderColor: i.types == 1 || i.types == 2 || i.types == 5 ? 'transparent' : '#a5a5a5',

                      /* backgroundColor: i.types == 1 || i.types == 2 ? '#d9d9d9' : 'white', */
                      pointerEvents: i.types == 1 || i.types == 2 || i.types == 5 ? 'none' : 'auto',
                    }}
                  ></Input>
                </td>
                <td>
                  <Input
                    // className={editMode ? 'input-editable ' : 'input-non-editable '}
                    className="input-editable"
                    value={i.ysize}
                    onChange={(e) => changeDetails(e, i.id, 'ysize')}
                    style={{
                      height: 32,
                      textAlign: 'center',
                      borderColor: i.types == 1 || i.types == 2 || i.types == 5 ? 'transparent' : '#a5a5a5',
                      /*  backgroundColor: i.types == 1 || i.types == 2 ? '#d9d9d9' : 'white', */
                      pointerEvents: i.types == 1 || i.types == 2 || i.types == 5 ? 'none' : 'auto',
                    }}
                  ></Input>
                </td>
                <td>
                  <Input
                    // className={editMode ? 'input-editable ' : 'input-non-editable '}
                    className="input-editable"
                    value={i.perimeter}
                    onChange={(e) => changeDetails(e, i.id, 'perimeter')}
                    style={{
                      height: 32,
                      textAlign: 'center',
                      borderColor: i.types < 5 ? 'transparent' : '#a5a5a5',
                      /*  backgroundColor: i.types < 5 ? '#d9d9d9' : 'white', */
                      pointerEvents: i.types < 5 ? 'none' : 'auto',
                    }}
                  ></Input>
                </td>
                <td>
                  <Input
                    // className={editMode ? 'input-editable ' : 'input-non-editable '}
                    className="input-editable"
                    value={i.count}
                    onChange={(e) => changeDetails(e, i.id, 'count')}
                    style={{ height: 32, textAlign: 'center' }}
                  ></Input>
                </td>
                {/* {editMode ? (
                  <> */}
                <td style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '6%' }}>
                  <Row justify="center">
                    <PlusCircleFilled
                      className="add-remove-icon"
                      onClick={() => addRow(i.id)}
                      style={{ marginLeft: 5, marginRight: 5 }}
                    />
                    <CloseCircleFilled
                      className="add-remove-icon"
                      onClick={() => deleteRow(i.id)}
                      // style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                    />
                  </Row>
                </td>
                {/* </>
                ) : (
                  <>
                    {' '}
                    <td style={{ border: 'none', width: '6%' }}></td>
                  </>
                )} */}
              </tr>
            ))}
          </tbody>
        </Table>
      </Row>
    </>
  );
});

export default IQ3KouteiInput_Blank;
