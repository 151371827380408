import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';

import { Row, Col, Input, Select, Space, Button, Modal } from 'antd';
import { PlusCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { Table } from 'react-bootstrap';
import '../../assets/styles/common.css';
import ParentKouteiInput_ChildPartInfoSetting from './ParentKouteiInput_ChildPartInfoSetting';
import { getPrepCoeffTypeName, handleKeyPress, secondsToHms } from '../common/Common';

let kensaData = [];
let iQ3Data = [];
const iQ5Data = [];
const iQ7Data = [];
const purchaseCnt = 10;
iQ3Data.push({
  key: 1,
  parentName: `iQ3板金`,
  childType: 'iQ3',
  imgNm: 'Product-20230320 105141',
  childName: `Test-1`,
  itemNum: 2,
  quality: 'SPCC',
  thick: 1.6,
  area: 10,
  weight: 3,
});
iQ5Data.push({
  key: 1,
  parentName: `iQ5形鋼`,
  childType: 'iQ5',
  imgNm: 'Product-20230320 105142',
  childName: `Test-2`,
  itemNum: 2,
  quality: 'SPCC',
  thick: 1.6,
  area: 10,
  weight: 3,
});
iQ7Data.push({
  key: 1,
  parentName: `iQ7機械`,
  childType: 'iQ7',
  imgNm: 'Product-20230320 105143',
  childName: `Test-3`,
  itemNum: 2,
  quality: 'SPCC',
  thick: 1.6,
  area: 10,
  weight: 3,
});
const childParts = [
  {
    key: 1,
    childKey: 1,
    parentName: `iQ3板金`,
    childType: 'iQ3',
    imgNm: 'Product-20230320 105141',
    childName: `Test-1`,
    itemNum: 2,
    quality: 'SPCC',
    thick: 1.6,
    area: 10,
    weight: 3,
    size: `200x100`,
    materialname: '丸バィフ',
    details: '25.0t-280.0',
  },
  {
    key: 2,
    childKey: 1,
    parentName: `iQ5形鋼`,
    childType: 'iQ5',
    imgNm: 'Product-20230320 105142',
    childName: `Test-1`,
    itemNum: 2,
    quality: 'SPCC',
    thick: 1.6,
    area: 10,
    weight: 3,
    size: `200x100`,
    materialname: '丸バィフ',
    details: '25.0t-280.0',
  },
  {
    key: 3,
    childKey: 1,
    parentName: `iQ7機械`,
    childType: 'iQ7',
    imgNm: 'Product-20230320 105143',
    childName: `Test-1`,
    itemNum: 2,
    quality: 'SPCC',
    thick: 1.6,
    area: 10,
    weight: 3,
    size: `200x100`,
    materialname: '丸バィフ',
    details: '25.0t-280.0',
  },
];

kensaData.push({
  key: 1,
  kensaType: '子部品点数',
  childPartNm: ['板金子部品', '形鋼子部品', '機械加工子部品'],
  childParts: childParts,
  iq3: iQ3Data?.length,
  iq5: iQ5Data?.length,
  iq7: iQ7Data?.length,
  purchaseCnt: purchaseCnt,
  sum: 30,
  totalCnt: parseInt(iQ3Data?.length) + parseInt(iQ5Data?.length) + parseInt(iQ7Data?.length) + parseInt(purchaseCnt),
  selectedChildPartCnt: parseInt(iQ3Data?.length) + parseInt(iQ5Data?.length) + parseInt(iQ7Data?.length),
});

const kensaTypes = [
  { id: 1, value: '子部品点数', label: '子部品点数' },
  { id: 2, value: '重量', label: '重量' },
];

const ParentKouteiInput_Kensa = forwardRef((props, ref) => {
  const [kensaDataLst, setKensaDataLst] = useState([]);
  const [curKensaData, setCurKensaData] = useState([]);
  const [curDataIndex, setCurDataIndex] = useState(0);
  // const [editMode, setEditMode] = useState(false);
  // add new
  const [addModal, setAddModal] = useState(false);

  const [totalChildParts, setTotalChildParts] = useState(0);

  // 編集モード
  const editMode = props.editMode;

  const [calculateType, setCalculateType] = useState();
  const [childPartsCount, setChildPartsCount] = useState();

  const [inspectionWorkBasicTime, setInspectionWorkBasicTime] = useState(0);

  useEffect(() => {
    if (props.kouteiSentakuProcessesId?.length > 0) {
      const inspectionItem = props.kouteiSentakuProcessesId?.find((item) => item.inspectionTime);

      // Check if inspectionItem is found and get its value
      const inspectionTime = inspectionItem ? inspectionItem.inspectionTime : 0;

      setInspectionWorkBasicTime(inspectionTime);
    }

    const kensaDetailsData = props?.kouteiInputInfo?.inspectionProcessInputInfo;
    if (kensaDetailsData) {
      setCalculateType(kensaDetailsData.calcType);
      setChildPartsCount(kensaDetailsData.smPartsCount);
      setKensaDataLst(kensaDetailsData);
    }
    setTotalChildParts(60);
  }, [props.kensaData, props.kouteiInputInfo, props.kouteiSentakuProcessesId]);

  const addKensa = () => {
    if (kensaDataLst?.length > 0) {
      //
      const data = {
        key: kensaDataLst.slice(-1)[0].key + 1,
        kensaType: '子部品点数',
        childPartNm: ['板金子部品', '形鋼子部品', '機械加工子部品'],
        childParts: [],
        iq3: 0,
        iq5: 0,
        iq7: 0,
        purchaseCnt: purchaseCnt,
        totalCnt: 10,
        sum: 0,
        selectedChildPartCnt: 0,
        types: 0,
        weightVal: 0,
        bankin1: 0,
        bankin2: kensaDataLst[0].bankin2,
        katakou1: kensaDataLst[0].katakou1,
        katakou2: kensaDataLst[0].katakou2,
      };
      let addArr = [...kensaDataLst, data];
      setKensaDataLst(addArr);
    }
  };
  const deleteKensa = (e, index) => {
    if (kensaDataLst?.length > 1) {
      setKensaDataLst(
        kensaDataLst?.filter((j) => {
          return ![e]?.includes(j.key);
        })
      );
    }
  };
  const onKensaValUpdate = (i, event) => {
    const { name, value } = event.target;
    const data = [...kensaDataLst];
    data[i][name] = value;
    setKensaDataLst(data);
  };
  const onKumitateValSelectUpdate = (i, event, name) => {
    const data = [...kensaDataLst];
    data[i][name] = event;
    setKensaDataLst(data);
  };
  const showModal = (index, item) => {
    setCurKensaData(item);
    setCurDataIndex(index);
    setAddModal(true);
  };
  const updateChildPart = (selectedChildParts) => {
    const iQ3Data = selectedChildParts?.filter((j) => {
      return j.childType == 'iQ3';
    });
    const iQ5Data = selectedChildParts?.filter((j) => {
      return j.childType == 'iQ5';
    });
    const iQ7Data = selectedChildParts?.filter((j) => {
      return j.childType == 'iQ7';
    });
    let sum = selectedChildParts?.reduce(function (prev, current) {
      return prev + +current.area;
    }, 0);

    const updatedChildPart = {
      key: curKensaData.key,
      kensaType: curKensaData.kensaType,
      childPartNm: curKensaData.childPartNm,
      childParts: selectedChildParts,
      iq3: iQ3Data?.length,
      iq5: iQ5Data?.length,
      iq7: iQ7Data?.length,
      sum: sum,
      purchaseCnt: curKensaData.purchaseCnt,
      totalCnt:
        parseInt(iQ3Data?.length) + parseInt(iQ5Data?.length) + parseInt(iQ7Data?.length) + parseInt(purchaseCnt),
      selectedChildPartCnt: parseInt(iQ3Data?.length) + parseInt(iQ5Data?.length) + parseInt(iQ7Data?.length),
    };
    const data = [...kensaDataLst];
    data[curDataIndex] = updatedChildPart;
    setKensaDataLst(data);
    setAddModal(false);
  };
  const cancelChildPart = () => {
    setAddModal(false);
  };

  const kensaComponent = (
    <>
      <tr>
        <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>1</td>
        <td style={{ verticalAlign: 'middle' }}>{getPrepCoeffTypeName(calculateType)}</td>

        <td style={{ verticalAlign: 'middle' }}>
          <Input
            name="types"
            value={childPartsCount}
            style={{ textAlign: 'right' }}
            className={editMode ? 'input-editable ' : 'input-non-editable '}
            onChange={(e) => {
              setChildPartsCount(e.target.value);
              kensaDataLst.smPartsCount = e.target.value;
              kensaDataLst.total = e.target.value * inspectionWorkBasicTime;
              props.updatedKensaProcessInput(kensaDataLst);
            }}
            onKeyPress={(e) => {
              handleKeyPress(e, false);
            }}
          ></Input>
        </td>
        <td style={{ verticalAlign: 'middle', width: '9.5%', textAlign: 'center' }}>
          {secondsToHms(inspectionWorkBasicTime * childPartsCount)}
        </td>
      </tr>
    </>
  );
  return (
    <>
      {/* 組立 */}
      <Row className="mt-1 registerModal" align="middle">
        <Table className="kouteiinput" style={{ width: '42%' }}>
          <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
            <tr style={{ textAlign: 'center' }}>
              <th style={{ width: '3%' }} className="tbl-header">
                No
              </th>
              <th style={{ width: '13%' }} className="tbl-header">
                計算方法
              </th>
              <th style={{ width: '13%' }} className="tbl-header">
                点数
              </th>
              <th style={{ width: '13%' }} className="tbl-header">
                合計時間
              </th>
              {/* <th colSpan={3} style={{ width: '19%' }} className="tbl-header">
                詳細
              </th> */}
            </tr>
          </thead>
          <tbody>{kensaComponent}</tbody>
        </Table>
      </Row>
      {/* 詳細 */}
      {addModal ? (
        <ParentKouteiInput_ChildPartInfoSetting
          editMode={editMode}
          onUpdate={updateChildPart}
          onCancel={cancelChildPart}
          curData={curKensaData}
          shoriNm={'検査' + curKensaData.key}
        />
      ) : (
        ''
      )}
    </>
  );
});

export default ParentKouteiInput_Kensa;
