import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';

import { Row, Col, Input, Select, Popover, Checkbox, Space, InputNumber, notification } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import '../../assets/styles/common.css';
import {
  handelIncreaseTime,
  handelDecreaseTime,
  checkInput,
  secondsToHms,
  setTimetoSec,
  checkInputWithColor,
  isNumber,
  checkShowHideIcon,
  JPYs,
  handleShowHide,
  handleKeyPress,
  kouteiCalculateSelectedArr,
  handleCollpse,
} from '../common/Common.js';
import CurrencyInput from 'react-currency-input-field';
import { BsUsbC, BsX } from 'react-icons/bs';
import { WorkType, DataType } from '../common/enums';
import { amtEmptyStr, timeHmsStr } from '../common/Constant';

//員数
// const partCnt = 4;

const ParentKouteiCalculate_Konpo = forwardRef((props, ref) => {
  const [isYousetsudetail, setIYousetsudetail] = useState(false);
  const [totalAmt, setTotalAmt] = useState('');
  const [totalTime, setTotalTime] = useState('');
  const [totalSagyoAmt, setTotalSagyoAmt] = useState('');
  const [totalSagyoTime, setTotalSagyoTime] = useState('');
  const [totalDandoriAmt, setTotalDandoriAmt] = useState('');
  const [totalDandoriTime, setTotalDandoriTime] = useState('');

  const [totalSum, setTotalSum] = useState('');
  const [totalTimeSum, setTotalTimeSum] = useState('');
  const [sagyoAmtSum, setSagyoAmtSum] = useState('');
  const [sagyoTimeSum, setSagyoTimeSum] = useState('');
  const [dandoriAmtSum, setDandoriAmtSum] = useState('');
  const [dandoriTimeSum, setDandoriTimeSum] = useState('');
  const [subTotalAmt, setSubTotalAmt] = useState([]);
  const [subTotalTime, setSubTotalTime] = useState([]);
  const [totalChild, setTotalChild] = useState([]);
  const [editData, setEditData] = useState([]);
  const [orgData, setOrgData] = useState([]);
  const [selectedDataFlg, setSelectedDataFlg] = useState(0);

  const [deviceMst, setDeviceMst] = useState([]);
  const [processMst, setProcessMst] = useState([]);
  const [patternInfo, setPatternInfo] = useState([]);
  const [oldEditedData, setOldEditedData] = useState([]);
  const [updateEditedData, setUpdateEditedData] = useState([]);
  const [konpoAmt, setKonpoAmt] = useState(0);

  const contentDandori = (
    <div>
      {/* <p>
        段取金額=アルゴン段取金額＋ボルト段取金額
        <br /> ¥2780 = 0 + ¥2780
      </p>
      <p>
        段取時間=アルゴン段取時間＋ボルト段取時間
        <br />
        00:45:40 = 00:00:00 + 00:01:00
      </p> */}
    </div>
  );

  const contentSagyo = (
    <div>
      {/* <p>
        作業金額=アルゴン作業金額＋ボルト作業金額
        <br /> ¥2780 = 0 + ¥2780
      </p>
      <p>
        作業時間=アルゴン作業時間＋ボルト作業時間
        <br />
        00:45:40 = 00:00:00 + 00:01:00
      </p> */}
    </div>
  );

  const contentSub = (
    <div>
      <p>
        作業金額=チャージ金額 x 作業時間
        <br /> ¥233.3334 = ¥4,200(/h) x 200(秒)
      </p>
      <p>
        作業時間 = 加工長 / 加工速度 x 梱包難易度係数 x 製品サイズ係数 x 生産個数係数 <br />
        200(秒) = 200(mm) / 1(mm/秒) x 1 x 1 x 1
      </p>
      <p>
        引用パラメータ
        <br />
        加工長：200mm
        <br /> 加工速度：60mm/分 = 1mm/秒 <br />
        梱包難易度係数：1 <br />
        製品サイズ係数：1 <br />
        生産個数係数：1
      </p>
    </div>
  );

  const settingEditData = (arr) => {
    let sagyoAmt = 0;
    let sagyoTimeSec = 0;
    let childSumArr = [];
    let dandoriAmtSum = 0;
    let dandoriTimeSumSec = 0;

    if (arr[0] == undefined) return;

    dandoriAmtSum = arr[0].dandoriAmt;
    dandoriTimeSumSec = arr[0].dandoriTime;

    for (let i = 0; i < arr?.length; i++) {
      sagyoAmt += arr[i].sagyoAmt;
      sagyoTimeSec += arr[i].sagyoTime;

      childSumArr.push({
        id: i,
        processName: arr[i].processName,
        dandoriAmt: arr[i].dandoriAmt,
        dandoriTime: arr[i].dandoriTime,
        dandoriTimeSec: arr[i].dandoriTime,
        sagyoAmt: arr[i].sagyoAmt,
        sagyoTime: arr[i].sagyoTime,
        sagyoTimeSec: arr[i].sagyoTime,
        processCount: arr[i].processCount,
        processTime: arr[i].processTime,
      });
    }
    return childSumArr;
  };

  const calculateTotals = (editDataArr) => {
    let sagyoTimeSumSec = 0;
    let sagyoAmtSum = 0;
    editDataArr?.forEach((element) => {
      sagyoAmtSum += element.sagyoAmt;
      sagyoTimeSumSec += element.sagyoTimeSec;
    });

    let totalSum = sagyoAmtSum + editDataArr[0].dandoriAmt;
    let totalTimeSum = sagyoTimeSumSec + editDataArr[0].dandoriTimeSec;
    let sagyoTimeSum = sagyoTimeSumSec;

    let totals = [];
    let i = 0;
    totals.push({
      id: i++,
      sagyoAmtSum: sagyoAmtSum,
      sagyoTimeSum: sagyoTimeSum,
      sagyoTimeSumSec: sagyoTimeSumSec,
      totalSum: totalSum,
      totalTimeSum: totalTimeSum,
    });
    return totals;
  };

  const handleChangeCheckData = (e, i) => {
    const editedData = { ...updateEditedData };
    editedData.dataType = e;
    setUpdateEditedData(editedData);
    setKonpoAmt(e == DataType.Data ? editedData.totalDataPrice : editedData.totalEditPrice);
    props?.updateKouteiCal(editedData, WorkType.PaPacking);
  };

  useEffect(() => {
    let editData = [];
    let totals = [];
    let parameters = props?.selectedDataDetail?.estimateProducts?.calcParameters?.parameters;
    let processMst = parameters?.process
      ? parameters.process?.filter((item) => item.workType === WorkType.PaPacking)[0]
      : [];
    let konpoAdditions = props?.selectedDataDetail?.estimateProducts?.additions?.filter(
      (item) => item.processId == processMst?.id
    )[0];
    let patternInfo = props?.selectedDataDetail?.estimateProducts?.processSelect
      ? props?.selectedDataDetail?.estimateProducts?.processSelect?.details?.filter(
          (item) => item.processId == processMst?.id
        )
      : [];
    setPatternInfo([]);
    if (patternInfo == undefined) return;
    if (patternInfo?.length > 0 && patternInfo[0]?.isUsedForCalc == false) return;
    let deviceMst =
      parameters?.device && patternInfo
        ? parameters?.device?.filter(
            (item) => item.processId == patternInfo[0]?.processId && item.id == patternInfo[0]?.deviceId
          )[0]
        : []; //テスト用ID;
    setProcessMst(processMst ? processMst : []);
    setDeviceMst(deviceMst ? deviceMst : []);
    setPatternInfo(patternInfo);
    // 新規の場合
    if (konpoAdditions == undefined) {
      let konpoAddition = {};
      let detailItems = [];
      let editDetailItems = [];
      let processCount = 0;
      let processName = '基本合計梱包金額/時間';
      let processTime = 0;

      // 基本合計組立情報
      detailItems.push({
        id: 1,
        processName: processName,
        dandoriAmt: 0,
        dandoriTime: 0,
        sagyoAmt: 0,
        sagyoTime: 0,
        processCount: processCount,
        processTime: processTime,
        kihon: true,
        formula: '',
      });

      let id = 1;
      if (props?.selectedDataDetail?.estimateProducts?.additions?.length > 0) {
        let lastRecord = props?.selectedDataDetail?.estimateProducts?.additions?.sort((a, b) => b.no - a.no)?.[0];
        id = lastRecord?.no + 1;
      }
      konpoAddition = {
        no: id,
        processId: processMst.id,
        dataType: DataType.Data,
        dataItems: detailItems,
        editItems: editDetailItems,
        totalDandori: {
          dataItemsAmt: 0,
          dataItemsTime: 0,
          editItemsAmt: 0,
          editItemsTime: 0,
        },
        totalSagyo: {
          dataItemsAmt: 0,
          dataItemsTime: 0,
          editItemsAmt: 0,
          editItemsTime: 0,
        },
        totalDataPrice: 0,
        totalDataTime: 0,
        totalEditPrice: 0,
        totalEditTime: 0,
      };
      konpoAdditions = konpoAddition;
    }

    if (konpoAdditions?.dataItems) {
      let orgData = JSON.parse(JSON.stringify(konpoAdditions?.dataItems));
      // konpoAdditions.dataItems = JSON.parse(JSON.stringify(props?.konpoData));
      setOrgData(JSON.parse(JSON.stringify(konpoAdditions?.dataItems)));
      if (deviceMst != undefined && orgData?.length > 0) {
        let packingProcessInput = props?.selectedDataDetail?.estimateProducts?.processInput?.packingProcessInputInfo;
        //部品数
        let partCnt = parseInt(packingProcessInput?.smPartsCount);
        orgData[0].processCount = partCnt;
        konpoAdditions.dataItems[0].processCount = partCnt;
        // 基本情報の総重量
        let weight = props?.selectedDataDetail?.estimateProducts?.totalWeight;

        /** 段取金額/時間 */
        // 段取時間設定
        //　基本段取時間＊重量係数＊部品点数
        let dandoriTimeSec = calDandoriTime(partCnt, deviceMst, weight);
        let dandoriTimeHms = dandoriTimeSec;
        orgData[0].dandoriTime = dandoriTimeHms;
        konpoAdditions.dataItems[0].dandoriTime = dandoriTimeSec;
        // 段取時間の合計
        setDandoriTimeSum(dandoriTimeHms);

        // 段取金額設定
        //段階時間＊時間チャージ
        let dandoriAmt = calDandoriAmt(dandoriTimeSec, deviceMst);
        orgData[0].dandoriAmt = dandoriAmt;
        // 段取金額の合計
        setDandoriAmtSum(dandoriAmt);
        konpoAdditions.dataItems[0].dandoriAmt = dandoriAmt;

        // 1部品当たりの作業時間
        let mWorkHTime = deviceMst?.details?.workBasicTime;
        orgData[0].processTime = mWorkHTime;
        konpoAdditions.dataItems[0].processTime = mWorkHTime;

        /** 作業金額/時間 */
        // 作業時間
        // 1部品当たりの作業時間＊部品数＊重量係数
        let cSagyoTimeKonpoSec = calSagyoTime(partCnt, mWorkHTime, deviceMst, weight);
        let cSagyoTimeKonpo = cSagyoTimeKonpoSec;
        orgData[0].sagyoTime = cSagyoTimeKonpo;
        konpoAdditions.dataItems[0].sagyoTime = cSagyoTimeKonpoSec;
        setSagyoTimeSum(cSagyoTimeKonpo);

        // 作業金額
        //　作業時間＊時間チャージ
        let cSagyoAmtKonpo = calSagyoAmt(cSagyoTimeKonpoSec, deviceMst);
        orgData[0].sagyoAmt = cSagyoAmtKonpo;
        konpoAdditions.dataItems[0].sagyoAmt = cSagyoAmtKonpo;
        setSagyoAmtSum(cSagyoAmtKonpo);

        let totalSum = dandoriAmt + cSagyoAmtKonpo;
        setTotalSum(totalSum);
        konpoAdditions.totalDataPrice = totalSum;
        konpoAdditions.totalDandori.dataItemsAmt = dandoriAmt;
        konpoAdditions.totalSagyo.dataItemsAmt = cSagyoAmtKonpo;

        let totalTimeSec = dandoriTimeSec + cSagyoTimeKonpoSec;
        setTotalTimeSum(totalTimeSec);
        konpoAdditions.totalDataTime = totalTimeSec;
        konpoAdditions.totalDandori.dataItemsTime = dandoriTimeSec;
        konpoAdditions.totalSagyo.dataItemsTime = cSagyoTimeKonpoSec;

        setOrgData(orgData);
        if (konpoAdditions?.dataType == DataType.Edit) {
          editData = settingEditData(JSON.parse(JSON.stringify(konpoAdditions?.editItems)));
        } else {
          editData = settingEditData(JSON.parse(JSON.stringify(orgData)));
        }
        if (editData != undefined && editData?.length > 0) {
          konpoAdditions.editItems = editData;
          setEditData(editData);
          setOldEditedData(editData);
          totals = calculateTotals(editData);
          // 編集
          setTotalAmt(totals[0]?.totalSum);
          konpoAdditions.totalEditPrice = totals[0]?.totalSum;
          konpoAdditions.totalDandori.editItemsAmt = editData[0].dandoriAmt;
          konpoAdditions.totalSagyo.editItemsAmt = totals[0]?.sagyoAmtSum;

          setTotalTime(totals[0]?.totalTimeSum);
          konpoAdditions.totalEditTime = totals[0]?.totalTimeSum;
          konpoAdditions.totalDandori.editItemsTime = editData[0].dandoriTime;
          konpoAdditions.totalSagyo.editItemsTime = totals[0]?.sagyoTimeSum;

          setTotalSagyoAmt(totals[0]?.sagyoAmtSum);
          setTotalSagyoTime(totals[0]?.sagyoTimeSum);
        }
        setKonpoAmt(
          konpoAdditions?.dataType == DataType.Data ? konpoAdditions.totalDataPrice : konpoAdditions.totalEditPrice
        );
        setUpdateEditedData(konpoAdditions);
        props?.updateKouteiCal(konpoAdditions, WorkType.PaPacking);
      }
    }
  }, [props?.activeTabKey]);

  const Konpo = () => {
    if (isYousetsudetail == true) {
      setIYousetsudetail(false);
    } else {
      setIYousetsudetail(true);
    }
  };

  const subpartKonpoDetail = (i) => {
    let showHide = document.getElementById('KonpoProcess' + i).style.display;

    if (showHide == 'block') {
      document.getElementById('KonpoProcess' + i).style.display = 'none';
      document.getElementById('arrowDownKonpo' + i).style.display = 'block';
      document.getElementById('arrowUpKonpo' + i).style.display = 'none';
    } else {
      document.getElementById('KonpoProcess' + i).style.display = 'block';
      document.getElementById('arrowDownKonpo' + i).style.display = 'none';
      document.getElementById('arrowUpKonpo' + i).style.display = 'block';
    }
  };

  const childDetail = (i) => {
    let showHide = document.getElementById('childPartKonpo' + i).style.display;
    if (showHide == 'block') {
      document.getElementById('childPartKonpo' + i).style.display = 'none';
      document.getElementById('arrowUpChildKonpo' + i).style.display = 'none';
      document.getElementById('arrowDownChildKonpo' + i).style.display = 'block';
    } else {
      document.getElementById('childPartKonpo' + i).style.display = 'block';
      document.getElementById('arrowUpChildKonpo' + i).style.display = 'block';
      document.getElementById('arrowDownChildKonpo' + i).style.display = 'none';
    }
  };

  const setKonpoDisplay = (display) => {
    setIYousetsudetail(display);
  };

  useImperativeHandle(ref, () => ({
    getKouteiCalInfoPacking: () => {
      return updateEditedData;
    },
    setKonpoDisplay: setKonpoDisplay,
  }));

  const settingNewDandoriAmtInArr = (value, indexj, objId, timeType, timeAct) => {
    let newState = [...editData];

    setOldEditedData(JSON.parse(JSON.stringify(newState)));
    let oldValue;
    let oldChild = JSON.parse(JSON.stringify(newState[indexj]));
    let sagyoAmtSum = 0;
    let dandoriAmtSum = 0;
    let dandoriAmt = 0;
    let sagyoAmt = 0;
    let sagyoTimeSec = 0;
    let dandoriTimeSec = 0;
    let sagyoTimeSumSec = 0;
    let dandoriTimeSumSec = 0;
    let totalChild = 0;
    let childSumArrState = [];
    let childPartArr = newState;
    let cSagyoAmtKonpoSum = 0;
    let cDadoriAmtSum = 0;
    let csagyoTimeSec = 0;
    let cDandoriTimeSec = 0;
    if (value === undefined) {
      value = 0;
    }

    let childPart = editData[indexj];
    if (indexj === '') {
      oldValue = JSON.parse(JSON.stringify(editData[indexj][objId]));
      newState[objId] = value;
    } else {
      oldValue = childPart[objId];
      if (timeType === '') {
        childPart[objId] = parseFloat(value);
      } else {
        childPart[objId] = value;
      }
      newState = childPart;
    }
    // 基本情報の総重量
    let weight = props?.selectedDataDetail?.estimateProducts?.totalWeight;
    if (objId === 'sagyoTime') {
      if (document.getElementById(indexj + 'cSagyoAmtKonpoCu').style.display !== undefined) {
        let showHideCu = document.getElementById(indexj + 'cSagyoAmtKonpoCu').style.display;
        if (showHideCu === 'block') {
          checkInputWithColor(true, '', indexj + 'cSagyoAmtKonpo', childPart[indexj].sagyoAmt, 'red');
        } else {
          if (timeAct === 'input') {
            let oldValue = oldChild[indexj];
            let timeType = value - oldValue.sagyoTime;
            childPart.sagyoAmt = changeAmtwithTime(newState.sagyoAmt, timeType, timeAct, value);
          } else {
            newState.sagyoAmt = changeAmtwithTime(newState.sagyoAmt, timeType, timeAct, value);
          }
          newState = childPart;
          updateEditedData.editItems[indexj].sagyoAmt = newState.sagyoAmt;
          /* checkInputWithColor(false,'',indexi + 'cSagyoAmtKonpo', newState[indexi].sagyoAmt, 'green'); */
          checkInputWithColor(true, '', indexj + 'cSagyoAmtKonpo', childPart.sagyoAmt, 'green');
        }
      }
    } else if (objId === 'processCount') {
      /** 段取金額/時間 */
      // 1部品当たりの作業時間＊部品数＊重量係数
      // 段取時間設定
      let dandoriTimeSec = calDandoriTime(value, deviceMst, weight);
      let dandoriTimeHms = dandoriTimeSec;
      childPartArr[0].dandoriTime = dandoriTimeHms;
      updateEditedData.editItems[indexj].dandoriTime = dandoriTimeSec;
      // 段取金額設定
      //段階時間＊時間チャージ
      let dandoriAmt = calDandoriAmt(dandoriTimeSec, deviceMst);
      childPartArr[0].dandoriAmt = dandoriAmt;
      updateEditedData.editItems[indexj].dandoriAmt = dandoriAmt;

      // 作業時間
      // 1部品当たりの作業時間＊部品数＊重量係数
      let cSagyoTimeKonpoSec = calSagyoTime(value, deviceMst?.details?.workBasicTime, deviceMst, weight);
      childPartArr[0].sagyoTime = cSagyoTimeKonpoSec;
      updateEditedData.editItems[indexj].sagyoTime = cSagyoTimeKonpoSec;
      // 作業金額
      //　加工時間＊時間チャージ
      childPartArr[0].sagyoAmt = calSagyoAmt(cSagyoTimeKonpoSec, deviceMst);
      updateEditedData.editItems[indexj].sagyoAmt = childPartArr[0].sagyoAmt;
    } else if (objId === 'processTime') {
      // 作業時間
      let workBasicTimeIn = value;
      // 1部品当たりの作業時間＊部品数＊重量係数
      let cSagyoTimeKonpoSec = calSagyoTime(childPartArr[0].processCount, workBasicTimeIn, deviceMst, weight);
      childPartArr[0].sagyoTime = cSagyoTimeKonpoSec;
      updateEditedData.editItems[indexj].sagyoTime = cSagyoTimeKonpoSec;
      // 作業金額
      //　加工時間＊時間チャージ
      childPartArr[0].sagyoAmt = calSagyoAmt(cSagyoTimeKonpoSec, deviceMst);
      updateEditedData.editItems[indexj].sagyoAmt = childPartArr[0].sagyoAmt;
    } else if (objId === 'dandoriAmt') {
      newState.dandoriAmt = value;
      updateEditedData.editItems[indexj].dandoriAmt = value;
    } else if (objId === 'dandoriTime') {
      newState.dandoriTime = value;
      // 段取金額設定
      //段階時間＊時間チャージ
      let dandoriAmt = calDandoriAmt(value, deviceMst);
      newState.dandoriAmt = dandoriAmt;
      setTotalDandoriAmt(dandoriAmt);
      updateEditedData.editItems[indexj].dandoriAmt = value;
    }

    // データ更新
    dandoriAmt = newState.dandoriAmt;
    dandoriTimeSec = newState.dandoriTime;
    for (let j = 0; j < childPartArr?.length; j++) {
      childSumArrState.push({
        id: j,
        dandoriAmt: dandoriAmt,
        dandoriTimeSec: dandoriTimeSec,
        dandoriTime: dandoriTimeSec,
        sagyoAmt: +childPartArr[j].sagyoAmt,
        sagyoTime: +childPartArr[j].sagyoTime,
        sagyoTimeSec: +childPartArr[j].sagyoTime,
        processCount: childPartArr[j].processCount,
        processTime: childPartArr[j].processTime,
        kihon: childPartArr[j].kihon,
      });
    }

    childSumArrState?.forEach((element) => {
      sagyoAmtSum += element.sagyoAmt;
      sagyoTimeSumSec += element.sagyoTimeSec;
    });

    let totalSum = Number(sagyoAmtSum) + Number(dandoriAmt);
    let totalTimeSumSec = sagyoTimeSumSec + dandoriTimeSec;
    let sagyoTimeSum = sagyoTimeSumSec;

    setTotalSagyoAmt(sagyoAmtSum);
    setTotalSagyoTime(sagyoTimeSum);
    setTotalAmt(totalSum);
    setTotalTime(totalTimeSumSec);
    updateEditedData.totalEditPrice = totalSum;
    updateEditedData.totalEditTime = totalTimeSumSec;
    updateEditedData.totalDandori.editItemsAmt = dandoriAmt;
    updateEditedData.totalDandori.editItemsTime = dandoriTimeSec;
    updateEditedData.totalSagyo.editItemsAmt = sagyoAmtSum;
    updateEditedData.totalSagyo.editItemsTime = sagyoTimeSumSec;

    setEditData(childSumArrState);
    if (updateEditedData.dataType == DataType.Data) {
      setKonpoAmt(updateEditedData.totalDataPrice);
    } else {
      setKonpoAmt(updateEditedData.totalEditPrice);
    }
    props?.updateKouteiCal(updateEditedData, WorkType.PaPacking);
  };

  const calSagyoTime = (partCntIn, workBasicTime, deviceMst, weight) => {
    // 作業時間
    // 重量係数
    let prepCoeffThWeights = deviceMst?.details?.prepCoeffThWeightMaster?.filter(
      (item) => item.value == weight || item.value > weight
    );
    let prepCoeffThWeightNo =
      prepCoeffThWeights?.length > 0 ? prepCoeffThWeights[0]?.no : deviceMst?.details?.prepCoeffThWeightMaster[0].no;
    let prepCoeffThWeight =
      prepCoeffThWeightNo == 0
        ? 0
        : deviceMst?.details?.prepCoeffThWeightItems?.filter((item) => item.no == prepCoeffThWeightNo)[0]?.value;
    // 1部品当たりの作業時間＊部品数＊重量係数
    let cSagyoTimeKonpoSec = workBasicTime * partCntIn * prepCoeffThWeight;
    return cSagyoTimeKonpoSec;
  };

  const calSagyoAmt = (cSagyoTimeKonpoSec, deviceMst) => {
    // 作業金額
    // 1部品当たりの作業時間
    let mWorkHTime = cSagyoTimeKonpoSec;
    let mWorkHTimeMin = mWorkHTime / 60;
    //装置の作業時間チャージ
    let mWorkHRate = deviceMst?.details?.charges.work;
    //　加工時間＊時間チャージ
    let cSagyoAmtKonpo = (mWorkHTimeMin * mWorkHRate) / 60.0;
    return cSagyoAmtKonpo;
  };

  const calDandoriTime = (partCnt, deviceMst, weight) => {
    if (partCnt == 0) return 0;
    // 段取時間設定
    // 重量係数
    let mBendCoeffMsts = deviceMst?.details?.prepCoeffThWeightMaster?.filter(
      (item) => item.value == weight || item.value > weight
    );
    let mBendCoeffMstNo =
      mBendCoeffMsts?.length > 0 ? mBendCoeffMsts[0]?.no : deviceMst?.details?.prepCoeffThWeightMaster[0]?.no;
    let mBendCoeff =
      mBendCoeffMstNo == 0
        ? 0
        : deviceMst?.details?.prepCoeffThWeightItems?.filter((item) => item.no == mBendCoeffMstNo)[0]?.value;
    // 部品点数
    let prepThQtyMasters = deviceMst?.details?.prepThQtyMaster?.filter(
      (item) => item.value == partCnt || item.value > partCnt
    );
    let buhinCntNo = prepThQtyMasters?.length > 0 ? prepThQtyMasters[0]?.no : deviceMst?.details?.prepThQtyMaster[0].no;
    let buhinCnt =
      buhinCntNo == 0
        ? 0
        : deviceMst?.details?.prepCoeffThChildQtyItems?.filter((item) => item.no == buhinCntNo)[0]?.value;
    //　基本段取時間＊重量係数＊部品点数
    let dandoriTimeSec = deviceMst?.details?.prepBasicTime * mBendCoeff * buhinCnt;
    return dandoriTimeSec;
  };

  const calDandoriAmt = (dandoriTimeSec, deviceMst) => {
    //段階時間
    let mSetTimeMin = dandoriTimeSec / 60;
    //装置の段階時間チャージ
    let mSetHRate = deviceMst?.details?.charges.prep;
    //段階時間＊時間チャージ
    let dandoriAmt = (mSetTimeMin * mSetHRate) / 60.0;
    return dandoriAmt;
  };

  const checkStr = (val) => {
    let intAmt;
    if (val === '') {
      intAmt = 0;
    } else if (typeof val === 'string') {
      intAmt = parseFloat(val.charAt(0) == '¥' ? val.replace('¥', '') : val);
    } else intAmt = val;
    return intAmt;
  };

  const changeAmtwithTime = (amt, typeTime, action, timeVal) => {
    let editedAmt;
    let totalSec = Number(timeVal);
    let intAmt = checkStr(amt);

    if (action === 'input') {
      editedAmt = intAmt + typeTime;
    } else {
      // 作業金額
      //　加工時間＊時間チャージ
      editedAmt = calSagyoAmt(totalSec, deviceMst);
    }
    return editedAmt;
  };

  return (
    <div className="kouteiCalculateDiv" style={{ display: patternInfo?.length > 0 ? 'block' : 'none' }}>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row " onClick={Konpo}>
        <Col span={4} className="oyabuhin-col">
          <div style={{ display: 'flex' }}>
            {isYousetsudetail ? (
              <CaretUpOutlined style={{ fontSize: 17, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 17, marginTop: 2 }} />
            )}
            <label style={{ marginLeft: 5 }}>梱包</label>
          </div>
        </Col>
        <Col span={6} className="oyabuhin-col">
          <div>{konpoAmt ? JPYs.format(Math.floor(konpoAmt)) : amtEmptyStr}</div>
        </Col>
        <Col span={13} style={{ border: 'none' }} className="oyabuhin-col">
          <div>
            <label style={{ marginLeft: 5 }}>{deviceMst?.name}</label>
          </div>
        </Col>
      </Row>
      <div style={{ display: isYousetsudetail ? 'block' : 'none' }}>
        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col">
            工程詳細
          </Col>
          <Col span={6} className="oyabuhin-detail-col">
            <Checkbox
              key={kouteiCalculateSelectedArr[0].key}
              value={kouteiCalculateSelectedArr[0].value}
              checked={kouteiCalculateSelectedArr[0].key == updateEditedData.dataType}
              disabled={!props?.editMode}
              style={{ marginRight: 6 }}
              onChange={(e) => props?.editMode && handleChangeCheckData(kouteiCalculateSelectedArr[0].key)}
            ></Checkbox>
            <label>{kouteiCalculateSelectedArr[0].value}</label>
          </Col>
          <Col span={8} className="oyabuhin-detail-col">
            <Checkbox
              key={kouteiCalculateSelectedArr[1].key}
              value={kouteiCalculateSelectedArr[1].value}
              disabled={!props?.editMode}
              onChange={(e) => props?.editMode && handleChangeCheckData(kouteiCalculateSelectedArr[1].key)}
              checked={kouteiCalculateSelectedArr[1].key == updateEditedData.dataType}
              style={{ marginRight: 6 }}
            ></Checkbox>
            <label>{kouteiCalculateSelectedArr[1].value}</label>
          </Col>
          <Col span={1} className="oyabuhin-detail-col">
            単位
          </Col>
          <Col span={5} className="formula-column">
            計算式
          </Col>
        </Row>

        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col">
            梱包
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="konpoTotalAmt">{totalSum ? JPYs.format(Math.floor(totalSum)) : amtEmptyStr}</label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="konpoTotalTime">{totalTimeSum ? secondsToHms(totalTimeSum) : timeHmsStr}</label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label
              id="konpoTotalAmtIn"
              className="resetColor"
              style={{ color: Math.floor(totalSum) === Math.floor(totalAmt) ? 'black' : '#4cc12f' }}
            >
              {totalAmt ? JPYs.format(Math.floor(totalAmt)) : amtEmptyStr}
            </label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label
              id="konpoTotalTimeIn"
              className="resetColor"
              style={{ color: totalTimeSum === totalTime ? 'black' : '#4cc12f' }}
            >
              {totalTime ? secondsToHms(totalTime) : timeHmsStr}
            </label>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={5} className="formula-column"></Col>
        </Row>

        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col">
            段取金額/時間
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="konpoTotalDandoriAmt">
              {orgData[0]?.dandoriAmt ? JPYs.format(Math.floor(orgData[0]?.dandoriAmt)) : amtEmptyStr}
            </label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="konpoTotalDandoriTime">
              {orgData[0]?.dandoriTime ? secondsToHms(orgData[0]?.dandoriTime) : timeHmsStr}
            </label>
          </Col>
          <Col span={4} className="input-col">
            <CurrencyInput
              id={'KonpoTotalDandoriAmtIn'}
              value={editData[0]?.dandoriAmt ? Math.floor(editData[0]?.dandoriAmt) : 0}
              defaultValue={0}
              onValueChange={(e) => {
                if (totalDandoriAmt != e) {
                  settingNewDandoriAmtInArr(e, 0, 'dandoriAmt', '', '');
                }
              }}
              style={{
                border: 'none',
                color:
                  Math.floor(orgData[0]?.dandoriAmt) === Math.floor(editData[0]?.dandoriAmt)
                    ? 'black'
                    : checkShowHideIcon('cDandoriAmtCu') === 'none'
                    ? '#4cc12f'
                    : 'red',
              }}
              className={
                props?.editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'
              }
              prefix="¥"
              decimalsLimit={100}
            />
            <div id={'cDandoriAmtCu'} style={{ display: 'none' }} className="currencyReset">
              <div className="currencyIcon">
                <BsUsbC style={{ fontSize: '25' }} />
                <BsX style={{ margin: '-8px 0 0 16px' }} />
                <span class="tooltiptext">金額に変更がありましたので、以降時間と連携して自動計算されない。</span>
              </div>
            </div>
          </Col>
          <Col span={4} className="input-col">
            <Row style={{ width: '100%' }}>
              <Col className="timer-input-col">
                <Input
                  value={editData[0]?.dandoriTime ? secondsToHms(editData[0]?.dandoriTime) : timeHmsStr}
                  id={'KonpoTotalDandoriTimeIn'}
                  style={{
                    width: '100%',
                    color:
                      orgData[0]?.dandoriTime === editData[0]?.dandoriTime
                        ? 'black'
                        : oldEditedData[0]?.processCount === editData[0]?.processCount
                        ? 'red'
                        : '#4cc12f',
                  }}
                  className={props?.editMode ? 'input-editable' : 'input-non-editable'}
                  onChange={(e) => {
                    //disableTimer('test');
                    settingNewDandoriAmtInArr(e.target.value, 0, 'dandoriTime', 'input', 'input');
                  }}
                ></Input>
              </Col>
              <Col className="timer-button-col">
                {props?.editMode ? (
                  <>
                    <CaretUpOutlined
                      style={{ fontSize: 17 + 'px' }}
                      onClick={() => {
                        let editedTime = handelIncreaseTime(editData[0]?.dandoriTime, '10min', 'cDandoroTimeKonpo');
                        settingNewDandoriAmtInArr(editedTime, 0, 'dandoriTime', '10min', 'inc');
                      }}
                    />
                    <CaretDownOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let editedTime = handelDecreaseTime(editData[0]?.dandoriTime, '10min', 'cDandoroTimeKonpo');
                        settingNewDandoriAmtInArr(editedTime, 0, 'dandoriTime', '10min', 'dec');
                      }}
                    />
                    <CaretUpOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let editedTime = handelIncreaseTime(editData[0]?.dandoriTime, 'min', 'cDandoroTimeKonpo');
                        settingNewDandoriAmtInArr(editedTime, 0, 'dandoriTime', 'min', 'inc');
                      }}
                    />
                    <CaretDownOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let editedTime = handelDecreaseTime(editData[0]?.dandoriTime, 'min', 'cDandoroTimeKonpo');
                        settingNewDandoriAmtInArr(editedTime, 0, 'dandoriTime', 'min', 'dec');
                      }}
                    />
                    <CaretUpOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let editedTime = handelIncreaseTime(editData[0]?.dandoriTime, 'sec', 'cDandoroTimeKonpo');
                        settingNewDandoriAmtInArr(editedTime, 0, 'dandoriTime', 'sec', 'inc');
                      }}
                    />
                    <CaretDownOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let editedTime = handelDecreaseTime(editData[0]?.dandoriTime, 'sec', 'cDandoroTimeKonpo');
                        settingNewDandoriAmtInArr(editedTime, 0, 'dandoriTime', 'sec', 'dec');
                      }}
                    />
                  </>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={5} className="formula-column">
            <Popover content={contentDandori}>段取金額の総合計</Popover>
          </Col>
        </Row>

        <Row
          style={{ color: 'black' }}
          className="oyabuhin-detail oyabuhin-row"
          onClick={(e) => handleCollpse('KonpoSagyo')}
        >
          <Col span={4} className="oyabuhin-detail-col">
            <div style={{ display: 'flex', overflow: 'hidden' }}>
              <CaretUpOutlined id={'arrowUpKonpoSagyo'} style={{ fontSize: 17, marginTop: 2, display: 'none' }} />
              <CaretDownOutlined id={'arrowDownKonpoSagyo'} style={{ fontSize: 17, marginTop: 2 }} />
              <span className="kouteiCal-proc-title">作業金額/時間</span>
            </div>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="konpoTotalSagyoAmt">{sagyoAmtSum ? JPYs.format(Math.floor(sagyoAmtSum)) : amtEmptyStr}</label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="konpoTotalSagyoTime">{sagyoTimeSum ? secondsToHms(sagyoTimeSum) : timeHmsStr}</label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label
              id="konpoTotalSagyoAmtIn"
              className="resetColor"
              style={{ color: totalSagyoAmt === sagyoAmtSum ? 'black' : '#4cc12f' }}
            >
              {totalSagyoAmt ? JPYs.format(Math.floor(totalSagyoAmt)) : amtEmptyStr}
            </label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label
              id="konpoTotalSagyoTimeIn"
              className="resetColor"
              style={{ color: totalSagyoTime === sagyoTimeSum ? 'black' : '#4cc12f' }}
            >
              {totalSagyoTime ? secondsToHms(totalSagyoTime) : timeHmsStr}
            </label>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={5} className="formula-column">
            <Popover content={contentSagyo}>作業金額の総合計</Popover>
          </Col>
        </Row>
        <div id={'divKonpoSagyo'} style={{ display: 'none' }}>
          {(() => {
            const subArr = [];
            for (let j = 0; j < editData?.length; j++) {
              <div>
                {(() => {
                  subArr.push(
                    <div>
                      <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                        <Col span={4} className="oyabuhin-detail-col" onClick={(e) => childDetail(j)}>
                          <div style={{ display: 'flex', overflow: 'hidden', marginLeft: '20px' }}>
                            <CaretUpOutlined
                              id={'arrowUpChildKonpo' + j}
                              style={{ fontSize: 17, marginTop: 2, display: 'none' }}
                            />
                            <CaretDownOutlined id={'arrowDownChildKonpo' + j} style={{ fontSize: 17, marginTop: 2 }} />
                            <span className="kouteiCal-proc-title" title={orgData[j]?.processName}>
                              {orgData[j]?.processName}
                            </span>
                          </div>
                        </Col>
                        <Col span={3} className="oyabuhin-detail-col" onClick={(e) => childDetail(j)}>
                          <label id={j + 'cSagyoAmtKonpo'}>
                            {orgData[j]?.sagyoAmt ? JPYs.format(Math.floor(orgData[j]?.sagyoAmt)) : amtEmptyStr}
                          </label>
                        </Col>
                        <Col span={3} className="oyabuhin-detail-col" onClick={(e) => childDetail(j)}>
                          <label id={'sagyoTime' + j}>
                            {orgData[j]?.sagyoTime ? secondsToHms(orgData[j]?.sagyoTime) : timeHmsStr}
                          </label>
                        </Col>
                        <Col span={4} className="input-col">
                          <CurrencyInput
                            id={j + 'cSagyoAmtKonpoIn'}
                            value={editData[j]?.sagyoAmt ? Math.floor(editData[j]?.sagyoAmt) : 0}
                            defaultValue={0}
                            onValueChange={(e) => {
                              if (editData[j]?.sagyoAmt != e) {
                                settingNewDandoriAmtInArr(e, j, 'sagyoAmt', '', '');
                                checkInputWithColor(false, '', j + 'cSagyoAmtKonpo', e, 'amt');
                              }
                            }}
                            style={{
                              border: 'none',
                              color:
                                Math.floor(orgData[j]?.sagyoAmt) === Math.floor(editData[j]?.sagyoAmt)
                                  ? 'black'
                                  : checkShowHideIcon(j + 'cSagyoAmtKonpoCu') === 'none'
                                  ? '#4cc12f'
                                  : 'red',
                            }}
                            className={
                              props?.editMode
                                ? 'input-editable currency resetColor'
                                : 'input-non-editable currency resetColor'
                            }
                            prefix="¥"
                            decimalsLimit={100}
                          />
                          <div id={j + 'cSagyoAmtKonpoCu'} style={{ display: 'none' }} className="currencyReset">
                            <div className="currencyIcon">
                              <BsUsbC style={{ fontSize: '25' }} />
                              <BsX style={{ margin: '-8px 0 0 16px' }} />
                              <span class="tooltiptext">
                                金額に変更がありましたので、以降時間と連携して自動計算されない。
                              </span>
                            </div>
                          </div>
                        </Col>
                        <Col span={4} className="input-col">
                          <Row style={{ width: '100%' }}>
                            <Col className="timer-input-col">
                              <Input
                                value={editData[j]?.sagyoTime ? secondsToHms(editData[j]?.sagyoTime) : timeHmsStr}
                                id={'sagyoTime' + j + 'In'}
                                style={{
                                  width: '100%',
                                  color:
                                    orgData[j]?.sagyoTime === editData[j]?.sagyoTime
                                      ? handleShowHide(
                                          Math.floor(orgData[j]?.sagyoAmt),
                                          Math.floor(editData[j]?.sagyoAmt),
                                          j + 'cSagyoAmtKonpoCu'
                                        )
                                      : oldEditedData[j]?.processCount === editData[j]?.processCount &&
                                        oldEditedData[j]?.processTime === editData[j]?.processTime
                                      ? 'red'
                                      : '#4cc12f',
                                }}
                                className={
                                  props?.editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'
                                }
                                onChange={(e) => {
                                  //disableTimer('test');

                                  settingNewDandoriAmtInArr(e.target.value, j, 'sagyoTime', 'input', 'input');
                                }}
                              ></Input>
                            </Col>
                            <Col className="timer-button-col">
                              {props?.editMode ? (
                                <>
                                  <CaretUpOutlined
                                    style={{ fontSize: 17 + 'px' }}
                                    onClick={() => {
                                      let editedTime = handelIncreaseTime(
                                        editData[j]?.sagyoTime,
                                        '10min',
                                        'sagyoTime' + j
                                      );
                                      settingNewDandoriAmtInArr(editedTime, j, 'sagyoTime', '10min', 'inc');
                                    }}
                                  />
                                  <CaretDownOutlined
                                    style={{ fontSize: 17 + 'px', color: '#000' }}
                                    onClick={() => {
                                      let editedTime = handelDecreaseTime(
                                        editData[j]?.sagyoTime,
                                        '10min',
                                        'sagyoTime' + j
                                      );
                                      settingNewDandoriAmtInArr(editedTime, j, 'sagyoTime', '10min', 'dec');
                                    }}
                                  />
                                  <CaretUpOutlined
                                    style={{ fontSize: 17 + 'px', color: '#000' }}
                                    onClick={() => {
                                      let editedTime = handelIncreaseTime(
                                        editData[j]?.sagyoTime,
                                        'min',
                                        'sagyoTime' + j
                                      );
                                      settingNewDandoriAmtInArr(editedTime, j, 'sagyoTime', 'min', 'inc');
                                    }}
                                  />
                                  <CaretDownOutlined
                                    style={{ fontSize: 17 + 'px', color: '#000' }}
                                    onClick={() => {
                                      let editedTime = handelDecreaseTime(
                                        editData[j]?.sagyoTime,
                                        'min',
                                        'sagyoTime' + j
                                      );
                                      settingNewDandoriAmtInArr(editedTime, j, 'sagyoTime', 'min', 'dec');
                                    }}
                                  />
                                  <CaretUpOutlined
                                    style={{ fontSize: 17 + 'px', color: '#000' }}
                                    onClick={() => {
                                      let editedTime = handelIncreaseTime(
                                        editData[j]?.sagyoTime,
                                        'sec',
                                        'sagyoTime' + j
                                      );
                                      settingNewDandoriAmtInArr(editedTime, j, 'sagyoTime', 'sec', 'inc');
                                    }}
                                  />
                                  <CaretDownOutlined
                                    style={{ fontSize: 17 + 'px', color: '#000' }}
                                    onClick={() => {
                                      let editedTime = handelDecreaseTime(
                                        editData[j]?.sagyoTime,
                                        'sec',
                                        'sagyoTime' + j
                                      );
                                      settingNewDandoriAmtInArr(editedTime, j, 'sagyoTime', 'sec', 'dec');
                                    }}
                                  />
                                </>
                              ) : (
                                <></>
                              )}
                            </Col>
                          </Row>
                        </Col>
                        <Col span={1} className="oyabuhin-detail-col" onClick={(e) => childDetail(j)}></Col>
                        <Col span={5} className="formula-column" onClick={(e) => childDetail(j)}>
                          {/* <Popover content={contentSub}>{editData[j]?.formula}</Popover> */}
                        </Col>
                      </Row>
                      <div id={'childPartKonpo' + j} style={{ display: 'none' }}>
                        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                          <Col span={4} className="oyabuhin-detail-col">
                            <span className="ddl_2_noImg kouteiCal-proc-title" style={{ paddingLeft: '10px' }}>
                              {orgData[j]?.kihon ? '部品数' : '個数'}/1つ当たりの梱包時間
                            </span>
                          </Col>
                          <Col span={3} className="oyabuhin-detail-col">
                            <label id="processCount">{orgData[j]?.processCount}</label>
                          </Col>
                          <Col span={3} className="oyabuhin-detail-col">
                            <label id="processTime">
                              {orgData[j]?.processTime ? secondsToHms(orgData[j]?.processTime) : timeHmsStr}
                            </label>
                          </Col>
                          <Col span={4} className="input-col">
                            <Input
                              value={editData[j]?.processCount}
                              id="processCountIn"
                              min={0}
                              className={props?.editMode ? 'input-editable' : 'input-non-editable '}
                              controls={false}
                              style={{
                                width: '45%',
                                border: 'none',
                                color: orgData[j]?.processCount === editData[j]?.processCount ? 'black' : 'red',
                              }}
                              onKeyPress={(e) => {
                                handleKeyPress(e, true);
                              }}
                              onChange={(e) => {
                                settingNewDandoriAmtInArr(
                                  e.target.value ? parseInt(e.target.value) : 0,
                                  j,
                                  'processCount',
                                  '',
                                  ''
                                );
                              }}
                            />
                          </Col>
                          <Col span={4} className="input-col">
                            <Row style={{ width: '100%' }}>
                              <Col className="timer-input-col">
                                <Input
                                  value={editData[j]?.processTime ? secondsToHms(editData[j]?.processTime) : timeHmsStr}
                                  id={j + 'processTimeIn'}
                                  style={{
                                    width: '100%',
                                    color: orgData[j]?.processTime === editData[j]?.processTime ? 'black' : 'red',
                                  }}
                                  className={
                                    props?.editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'
                                  }
                                  onChange={(e) => {
                                    settingNewDandoriAmtInArr(e.target.value, j, 'processTime', 'input', 'input');
                                  }}
                                ></Input>
                              </Col>
                              <Col className="timer-button-col">
                                {props?.editMode ? (
                                  <>
                                    <CaretUpOutlined
                                      style={{ fontSize: 17 + 'px' }}
                                      onClick={() => {
                                        let editedTime = handelIncreaseTime(
                                          editData[j]?.processTime,
                                          '10min',
                                          j + 'processTime'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, j, 'processTime', '10min', 'inc');
                                      }}
                                    />
                                    <CaretDownOutlined
                                      style={{ fontSize: 17 + 'px', color: '#000' }}
                                      onClick={() => {
                                        let editedTime = handelDecreaseTime(
                                          editData[j]?.processTime,
                                          '10min',
                                          j + 'processTime'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, j, 'processTime', '10min', 'dec');
                                      }}
                                    />
                                    <CaretUpOutlined
                                      style={{ fontSize: 17 + 'px' }}
                                      onClick={() => {
                                        let editedTime = handelIncreaseTime(
                                          editData[j]?.processTime,
                                          'min',
                                          j + 'processTime'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, j, 'processTime', 'min', 'inc');
                                      }}
                                    />
                                    <CaretDownOutlined
                                      style={{ fontSize: 17 + 'px', color: '#000' }}
                                      onClick={() => {
                                        let editedTime = handelDecreaseTime(
                                          editData[j]?.processTime,
                                          'min',
                                          j + 'processTime'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, j, 'processTime', 'min', 'dec');
                                      }}
                                    />
                                    <CaretUpOutlined
                                      style={{ fontSize: 17 + 'px' }}
                                      onClick={() => {
                                        let editedTime = handelIncreaseTime(
                                          editData[j]?.processTime,
                                          'sec',
                                          j + 'processTime'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, j, 'processTime', 'sec', 'inc');
                                      }}
                                    />
                                    <CaretDownOutlined
                                      style={{ fontSize: 17 + 'px', color: '#000' }}
                                      onClick={() => {
                                        let editedTime = handelDecreaseTime(
                                          editData[j]?.processTime,
                                          'sec',
                                          j + 'processTime'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, j, 'processTime', 'sec', 'dec');
                                      }}
                                    />
                                  </>
                                ) : (
                                  <></>
                                )}
                              </Col>
                            </Row>
                          </Col>

                          <Col span={1} className="oyabuhin-detail-col"></Col>
                          <Col span={4} className="formula-column"></Col>
                        </Row>
                      </div>
                    </div>
                  );
                  return subArr;
                })()}
              </div>;
            }
            return subArr;
          })()}
        </div>
      </div>
    </div>
  );
});

export default ParentKouteiCalculate_Konpo;
