/**
 * クラス名：会社設定画面
 * 作成者：チュー
 * 作成日：2023/07/24
 * バージョン：1.0
 */
import { Input, Row, Col, Select, Button, Typography, Checkbox } from 'antd';
import React, { Component, useState, useRef, forwardRef, useEffect } from 'react';
import dayjs from 'dayjs';
import axios from 'axios';
// import FilePicker from 'react-file-picker';
// import FileReaderInput from 'react-file-reader';
import {
  CaretUpOutlined,
  CaretDownOutlined,
  PlusCircleFilled,
  CloseCircleFilled,
  EditOutlined,
} from '@ant-design/icons';
import { Table } from 'react-bootstrap';
import { getAccessToken, updateAccessToken } from '../common/Common';
import { ErrorMessage } from '../common/Message';
import {
  authorizeError,
  error,
  confirmModalTitle,
  commonModal,
  commonFooter,
  confirmUpdateContent,
  complete,
} from '../common/CommonModal';
import { getExistCompanySetting, getExcelDatabyUseType, getExistUserSetting } from '../common/CommonAPI';

import { StatusCodes } from 'http-status-codes';
import { CurrencySymbols, PrintTypes, RoundUpMethods, languages, timeZones } from '../common/enums';

/* const languages = [
  { id: 1, value: '日本語', label: '日本語' },
  { id: 2, value: '英語', label: '英語' },
];
const timeZone = [{ id: 1, value: '(GMT+0:900) Asia/Tokyo', label: '(GMT+0:900) Asia/Tokyo' }]; */

const timeZoneTxt = { 0: '(GMT+0:900) Asia/Tokyo' };

const mitsumori = [{ id: 1, value: '切上げ', label: '切上げ' }];
const currencySymbol = [{ id: 1, value: '￥', label: '￥' }];
const excelFilesData = [
  { key: 1, title: '見積', useFor: '個別帳票', fileName: '見積フォーマット.xlsx', note: '' },
  { key: 2, title: '親部品リスト見積', useFor: 'リスト帳票', fileName: '親部品リスト見積.xlsx', note: '' },
];
const csvFilesData = [
  { key: 1, title: '生産管理連携', useFor: '個別出力', examples: '図面、品番、名称、金額、工程1、工程2、' },
  { key: 2, title: '親部品リスト', useFor: 'リスト出力', examples: '図面、品番、名称、金額、工程1、工程2、' },
];
const outputOptions = [
  { id: 1, value: '個別帳票', label: '個別帳票' },
  { id: 2, value: 'リスト帳票', label: 'リスト帳票' },
];
const filesOption = [
  { id: 1, value: '見積フォーマット.xlsx', label: '見積フォーマット.xlsx', fileId: '見積フォーマット' },
  { id: 2, value: '親部品リスト見積.xlsx', label: '親部品リスト見積.xlsx', fileId: '親部品リスト見積' },
  { id: 3, value: '見積_最新.xlsx', label: '見積_最新.xlsx', fileId: '見積_最新' },
];
const dates = [
  { id: 1, value: '年月日', label: '年月日' },
  { id: 2, value: '年', label: '年' },
  { id: 3, value: '月', label: '月' },
  { id: 4, value: '日', label: '日' },
];
const namingConditions = [
  // { id: 1, value: '材料名', label: '材料名' },
  // { id: 2, value: '材質名', label: '材質名' },
  // { id: 3, value: '材料表面処理', label: '材料表面処理' },
  // { id: 4, value: '板厚', label: '板厚' },
  // { id: 5, value: '材料サイズX', label: '材料サイズX' },
  // { id: 6, value: '材料サイズY', label: '材料サイズY' },
  // { id: 7, value: '部品サイズX', label: '部品サイズX' },
  // { id: 8, value: '部品サイズY', label: '部品サイズY' },
  // { id: 9, value: '親部品図番', label: '親部品図番' },
  // { id: 10, value: '親部品名', label: '親部品名' },
  { id: 1, value: 1, label: '材料名' },
  { id: 2, value: 2, label: '材質名' },
  { id: 3, value: 3, label: '材料表面処理' },
  { id: 4, value: 4, label: '板厚' },
  { id: 5, value: 5, label: '材料サイズX' },
  { id: 6, value: 6, label: '材料サイズY' },
  { id: 7, value: 7, label: '部品サイズX' },
  { id: 8, value: 8, label: '部品サイズY' },
  { id: 9, value: 9, label: '親部品図番' },
  { id: 10, value: 10, label: '親部品名' },
];
const times = [
  { id: 1, value: '時分秒', label: '時分秒' },
  { id: 2, value: '時', label: '時' },
  { id: 3, value: '分', label: '分' },
  { id: 4, value: '秒', label: '秒' },
];

const curSymbol = {
  0: '¥',
};

const riyoushin = {
  0: '個別帳票',
  1: 'リスト帳票',
};

const CompanySetting = forwardRef((props, ref) => {
  const [selectedAdminItem, setSelectedAdminItem] = useState();
  const workFileInputRef = useRef(null);
  const pcFileInputRef = useRef(null);
  const excelFileRef = useRef(null);
  const reportOutputFolderRef = useRef(null);
  const [fileName, setFileName] = useState('');
  const [pcFileName, setPCFileName] = useState('');
  const [saveZumen, setSaveZumen] = useState(true);
  const [saveImage, setSaveImage] = useState(true);
  const [calculationOnSave, setCalculationOnSave] = useState(false);
  const [saveWithSameName, setSaveWithSameName] = useState(true);
  const [addMultiChildParts, setAddMultiChildParts] = useState(true);
  const [showConfirmDialogue, setShowConfirmDialogue] = useState(true);
  const [individualExcelQuickOutput, setIndividualExcelQuickOutput] = useState(true);
  const [individualAutoExcelExport, setIndividualAutoExcelExport] = useState(true);
  const [individualFormatId, setIndividualFormatId] = useState();
  const [listExcelQuickOutput, setListExcelQuickOutput] = useState(true);
  const [listFormatId, setListFormatId] = useState();
  const [listAutoExcelExport, setListAutoExcelExport] = useState(true);
  // CSV出力
  const [individualCSVQuickOutput, setIndividualCSVQuickOutput] = useState(true);
  const [individualAutoCSVExport, setIndividualAutoCSVExport] = useState(true);
  const [listCSVQuickOutput, setListCSVQuickOutput] = useState(true);
  const [listAutoCSVExport, setListAutoCSVExport] = useState(true);

  const [mitsumoriVal, setMitsumoriVal] = useState('切上げ');
  const [currency, setCurrency] = useState('￥');

  const [excelFiles, setExcelFiles] = useState('');

  const [dragging, setDragging] = useState(false);
  const [files, setFiles] = useState([]);

  const [excelFilesList, setExcelFilesList] = useState([]);

  const [csvFilesList, setCSVFilesList] = useState(csvFilesData);

  const [CSVSettingModal, setCSVSettingModal] = useState(false);

  const [adminLanguage, setAdminLanguage] = useState('日本語');
  const [adminTimeZone, setAdminTimeZone] = useState('(GMT+0:900) Asia/Tokyo');

  const [companySettingObj, setCompanySettingObj] = useState([]);
  const [isExist, setIsExist] = useState(false);
  const [saveConfirm, setSaveConfirm] = useState(false);
  const [uploadedFileLst, setUploadedFileLst] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadComplete, setUploadComplete] = useState(false);

  //   見積金額切上げ桁数
  const [estimatedAmount, setEstimatedAmount] = useState(0);
  //   小数点以下桁数
  const [decimalPlaces, setDecimalPlaces] = useState(0);
  /* const [excelFilesList, setExcelFilesList] = useState([]); */

  const [naming1, setNaming1] = useState(5);
  const [naming2, setNaming2] = useState(6);
  const [naming3, setNaming3] = useState(7);
  const [naming4, setNaming4] = useState(8);

  const [partNaming1, setPartNaming1] = useState(5);
  const [partNaming2, setPartNaming2] = useState(6);
  const [partNaming3, setPartNaming3] = useState(7);
  const [partNaming4, setPartNaming4] = useState(8);

  const [mitsumoriNaming1, setMitsumoriNaming1] = useState(5);
  const [mitsumoriNaming2, setMitsumoriNaming2] = useState(6);
  const [mitsumoriNaming3, setMitsumoriNaming3] = useState(7);
  const [mitsumoriNaming4, setMitsumoriNaming4] = useState(8);

  const [childNaming1, setChildNaming1] = useState(5);
  const [childNaming2, setChildNaming2] = useState(6);
  const [childNaming3, setChildNaming3] = useState(7);
  const [childNaming4, setChildNaming4] = useState(8);

  const [childPartNaming1, setChildPartNaming1] = useState(5);
  const [childPartNaming2, setChildPartNaming2] = useState(6);
  const [childPartNaming3, setChildPartNaming3] = useState(7);
  const [childPartNaming4, setChildPartNaming4] = useState(8);

  const [userSettingInfo, setUserSettingInfo] = useState([]);

  const currentDateTime = dayjs().format('YYYY-MM-DD HH:mm:ss.SSSSSZ');

  const getFileList = async () => {
    var transformedFilesOption = [];
    try {
      const apiUrl = process.env.REACT_APP_BACKEND_API_URL + 'CompanySetting/getFileList';
      const accessToken = getAccessToken();
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
      });

      if (response.data && response.status === StatusCodes.OK) {
        var uploadedFiles = response.data;
        if (uploadedFiles?.length > 0) {
          transformedFilesOption = uploadedFiles?.map((fileName, index) => ({
            id: index + 1,
            value: uploadedFiles[index],
            label: uploadedFiles[index],
            fileId: uploadedFiles[index].replace(/\.[^/.]+$/, ''), // Removes file extension
          }));
        } else {
          transformedFilesOption.push(
            uploadedFiles?.map((fileName, index) => ({
              id: index + 1,
              value: '',
              label: '',
              fileId: '',
            }))
          );
        }
        return transformedFilesOption;
      } else if (
        response.data &&
        (response.status === StatusCodes.UNAUTHORIZED || response.status === StatusCodes.CONFLICT)
      ) {
        authorizeError(ErrorMessage().E006);
        throw new Error('Unauthorized or Conflict'); // Throw an error to indicate unauthorized or conflict
      } else if (response.data && response.status !== StatusCodes.OK) {
        error(ErrorMessage()[response.data?.messageCode]);
        throw new Error('Request failed with an error status'); // Throw an error for other error statuses
      }
    } catch (internal_err) {
      error(internal_err.message);
      throw internal_err; // Re-throw the error to propagate it to the caller
    }
  };

  useEffect(() => {
    async function fetchData() {
      setSelectedAdminItem(props.selectedItem);
      var existedData = await getExistCompanySetting();
      var uploadedFiles = await getFileList();
      setUploadedFileLst(uploadedFiles);
      let userSettingInfo = await getExistUserSetting();
      if (userSettingInfo !== undefined && userSettingInfo?.length !== 0) {
        setUserSettingInfo(userSettingInfo[0]);
      }
      if (existedData?.length > 0 && existedData != []) {
        setIndividualExcelQuickOutput(existedData[0]?.printOutputs[PrintTypes.individual]?.isUseQuickOutput);
        setListExcelQuickOutput(existedData[0]?.printOutputs[PrintTypes.list]?.isUseQuickOutput);

        setExcelFilesList(
          existedData[0]?.excelFormats?.length == 0 || existedData[0]?.excelFormats == undefined
            ? [{ id: 0, title: '', useType: 0, fileName: uploadedFiles[0].value, fileId: uploadedFiles[0].fileId }]
            : existedData[0]?.excelFormats
        );

        if (existedData[0]?.excelFormats?.length > 0) {
          var excelData = getExcelDatabyUseType(existedData[0]?.excelFormats);
          let teset =
            existedData[0]?.printOutputs[0]?.formatsId == 0
              ? excelData[0]?.length > 0
                ? excelData[0][0].fileName
                : ''
              : existedData[0]?.printOutputs[0]?.formatsId;
          setIndividualFormatId(teset);

          setListFormatId(
            existedData[0]?.printOutputs[1]?.formatsId == 0
              ? excelData[1]?.length > 0
                ? excelData[1][0].fileName
                : ''
              : existedData[0].printOutputs[1].formatsId
          );
          /* var excelData = getExcelDatabyUseType(existedData[0]?.excelFormats);
          existedData[0].printOutputs[0].formatsId = excelData[0]?.length > 0 && existedData[0].printOutputs[0].formatsId ==0  ? excelData[0][0].id : 0;
          existedData[0].printOutputs[1].formatsId =
            excelData[1]?.length > 0 && existedData[0].printOutputs[0].formatsId == 0 ? excelData[1][0].id : 0; */
        } else {
          setListFormatId('');
          setIndividualFormatId('');
          existedData[0].printOutputs[0].formatsId = 0;
          existedData[0].printOutputs[1].formatsId = 0;
        }
        setCompanySettingObj(existedData[0]);
        setIsExist(true);
      } else {
        setExcelFilesList([{ id: 0, title: '', useType: 0, fileName: uploadedFiles[0].value, fileId: '' }]);
        setCompanySettingObj({
          created: currentDateTime,
          modified: currentDateTime,
          languages: { language: 0, timeZone: 0 },
          environments: {
            workFolderPath: '',
            localSavePath: '',
            isUseSameDrawingNo: true,
            isBacthPartsAdd: true,
            isEachConfirmSameProcess: true,
            roundUpDigits: 1,
            roundUpMethod: 0,
            decimalPlaces: 1,
            currencySymbol: 0,
          },
          initNames: { initNameType: 0, select1: 0, select2: 0, select3: 0, select4: 0 },
          excelFormats: { id: 0, title: '', useType: 0, fileName: '', fileId: '' },
          csvFormats: { id: 0, title: '', useType: 0, fileName: '', fileId: '' },
          printOutputs: [
            {
              printType: PrintTypes.individual,
              isUseQuickOutput: true,
              formatsId: 0,
            },
            {
              printType: PrintTypes.list,
              isUseQuickOutput: true,
              formatsId: 0,
            },
          ],
          csvOutputs: { printType: 0, isUseQuickOutput: true, formatsId: 0 },
          info: { isUsed: true, sortNum: 0, remarks: '' },
        });
        setListExcelQuickOutput(true);
        setIndividualExcelQuickOutput(true);
        setListFormatId('');
        setIndividualFormatId('');
        setIsExist(false);
      }
    }
    fetchData();
  }, [props.selectedItem]);

  useEffect(() => {
    if (uploadedFileLst[0] !== undefined && uploadedFileLst[0]?.value != '' && uploadedFileLst?.length == 1) {
      setExcelFilesList([{ id: 0, title: '', useType: 0, fileName: uploadedFileLst[0]?.value, fileId: '' }]);
    }
  }, [uploadedFileLst]);

  const handleDragEnter = (event) => {
    event.preventDefault();
    setDragging(true);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'copy'; // Shows a "copy" cursor when dragging over the component
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleFileUpload = async (selectedFile) => {
    if (!selectedFile) {
      alert('Please select a file to upload.');
      return;
    }

    try {
      const formData = new FormData();
      formData.append('file', selectedFile);

      const apiUrl = `${process.env.REACT_APP_BACKEND_API_URL}CompanySetting/uploadFile`;

      // Add authorization header with bearer token
      const accessToken = getAccessToken();
      const headers = {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${accessToken}`,
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      };

      const response = await axios.post(apiUrl, formData, { headers });

      if (response && response.status === StatusCodes.OK) {
        complete('ファイルのアップロードは正常に完了しました。');
        var uploadFiles = await getFileList();
        setUploadedFileLst(uploadFiles);
        //setExcelFilesList([{ id: 0, title: '', useType: 0, fileName: uploadFiles[0].value, fileId: '' }]);
      } else if (
        response &&
        (response.status === StatusCodes.UNAUTHORIZED || response.status === StatusCodes.CONFLICT)
      ) {
        authorizeError(ErrorMessage().E006);
        return;
      } else if (response && response.status !== StatusCodes.OK) {
        error(ErrorMessage()[response?.messageCode]);
        return;
      }
    } catch (internal_error) {
      error(internal_error.message);
      /* console.error('Error uploading file:', error.response ? error.response.data : error.message); */
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setDragging(false);

    const droppedFiles = Array.from(event.dataTransfer.files);
    const excelFiles = droppedFiles?.filter(
      (file) => file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' // for .xlsx files
    );

    if (excelFiles?.length > 0) {
      setFiles(excelFiles);
      handleFileUpload(excelFiles[0]);
    } else {
    }
  };

  const browseWorkFiles = () => {
    workFileInputRef.current.click();
  };
  const browsePCFiles = () => {
    pcFileInputRef.current.click();
  };
  const browseExcelFiles = () => {
    excelFileRef.current.click();
  };

  const browseIndividualFile = () => {
    reportOutputFolderRef.current.click();
  };

  const handleWorkFileChange = (event) => {
    const selectedFile = event.target.files[0];

    setFileName(selectedFile.webkitRelativePath);
  };
  const handlePCFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      // setPCFileName(selectedFile.name);
      setPCFileName(selectedFile.webkitRelativePath);
    } else {
      setPCFileName('');
    }
  };

  const handleExcelFileChange = (event) => {
    /* const selectedFile = event.target.files[0];
    if (selectedFile) {
      setExcelFiles(selectedFile.name);
      setSelectedFile(selectedFile);
    } else {
      setExcelFiles('');
    } */
    handleFileUpload(event.target.files[0]);
  };

  const adminLanguageSetting = (
    <>
      <Row className="mt-4">
        <label style={{ fontWeight: 800, fontSize: '13.5px' }}>言語とタイムゾーンの設定</label>
      </Row>
      <div style={{ marginLeft: '1.5rem' }} className="settingContent">
        <Row className="mt-2">
          <label>言語</label>
        </Row>
        <Row>
          <Col span={8}>
            <Select
              style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
              value={companySettingObj?.languages?.language}
              onChange={(e) => {
                setCompanySettingObj((prevData) => ({
                  ...prevData,
                  languages: {
                    ...prevData?.languages,
                    language: e,
                  },
                }));
              }}
            >
              {Object.entries(languages)?.map(([key, value]) => (
                <Select.Option key={value} value={value}>
                  {key}
                </Select.Option>
              ))}
            </Select>
            <Typography.Text type="secondary" style={{ fontSize: '11px', marginLeft: '5px' }}>
              ユーザー設定がされている場合、ユーザー設定が優先されます。
            </Typography.Text>
          </Col>
        </Row>
        <Row className="mt-4">
          <label>タイムゾーン</label>
        </Row>
        <Row>
          <Col span={8}>
            　{' '}
            <Select
              style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
              value={companySettingObj?.languages?.timeZone}
              onChange={(e) => {
                setCompanySettingObj((prevData) => ({
                  ...prevData,
                  languages: {
                    ...prevData?.languages,
                    timeZone: e,
                  },
                }));
              }}
            >
              {Object.entries(timeZones)?.map(([key, value]) => (
                <Select.Option key={value} value={value}>
                  {key === 'JP' ? '(GMT+0:900) Asia/Tokyo' : ''}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row style={{ marginTop: '4rem' }}>
          <Col span={8} style={{ textAlign: 'right' }}>
            <Button
              className="mainButton"
              onClick={() => {
                //setSaveConfirm(true);

                setSaveConfirm(userSettingInfo?.massages?.editOk);
                if (!userSettingInfo?.massages?.editOk) {
                  updConfirmOk();
                }
              }}
            >
              保存
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );

  const increaseEstimatedAmount = (lblName) => {
    if (lblName === 'estimate') {
      //setEstimatedAmount((prevCount) => prevCount + 1);
      setCompanySettingObj((prevData) => ({
        ...prevData,
        environments: {
          ...prevData?.environments,
          roundUpDigits: prevData?.environments?.roundUpDigits + 1,
        },
      }));
    } else {
      //setDecimalPlaces((prevCount) => prevCount + 1);
      setCompanySettingObj((prevData) => ({
        ...prevData,
        environments: {
          ...prevData?.environments,
          decimalPlaces: prevData?.environments?.decimalPlaces + 1,
        },
      }));
    }
  };
  const decreaseEstimatedAmount = (lblName) => {
    if (lblName === 'estimate') {
      // setEstimatedAmount((prevCount) => (prevCount > 0 ? prevCount - 1 : 0));

      setCompanySettingObj((prevData) => ({
        ...prevData,
        environments: {
          ...prevData?.environments,
          roundUpDigits: prevData?.environments?.roundUpDigits > 0 ? prevData?.environments?.roundUpDigits - 1 : 0,
        },
      }));
    } else {
      // setDecimalPlaces((prevCount) => (prevCount > 0 ? prevCount - 1 : 0));
      setCompanySettingObj((prevData) => ({
        ...prevData,
        environments: {
          ...prevData?.environments,
          decimalPlaces: prevData?.environments?.decimalPlaces > 0 ? prevData?.environments?.decimalPlaces - 1 : 0,
        },
      }));
    }
  };
  const enviromentSetting = (
    <>
      <Row className="mt-4">
        <label style={{ fontWeight: 800, fontSize: '13.5px' }}>環境の設定</label>
      </Row>

      <div style={{ marginLeft: '1.5rem' }} className="settingContent">
        {/* <Row className="mt-2">
          <label>ワークフォルダ</label>
        </Row>
        <Row align="middle">
          <Col span={6}>
            <Input value={fileName}></Input>
          </Col>
          <Col span={1}>
            <input
              type="file"
              ref={workFileInputRef}
              style={{ display: 'none' }}
              onChange={handleWorkFileChange}
              directory=""
              webkitdirectory=""
              // webkitRelativePath
            />
            <Button className="referenceButton" onClick={browseWorkFiles} style={{ marginLeft: 3 }}>
              参照
            </Button>
          </Col>
        </Row>

        <Row className="mt-4">
          <label>PC保存フォルダ</label>
        </Row>
        <Row align="middle">
          <Col span={6}>
            <Input value={pcFileName}></Input>
          </Col>
          <Col span={1}>
            <input
              type="file"
              ref={pcFileInputRef}
              directory=""
              webkitdirectory=""
              style={{ display: 'none' }}
              onChange={handlePCFileChange}
            />
            <Button className="referenceButton" onClick={browsePCFiles} style={{ marginLeft: 3 }}>
              参照
            </Button>
          </Col>
        </Row>
        <Row className="mt-4">保存時の設定</Row>
        <Row className="mt-2">
          <Checkbox checked={saveZumen} onChange={(e) => setSaveZumen(e.target.checked)}>
            下敷き図面を保存する
          </Checkbox>
        </Row>
        <Row className="mt-1">
          <Checkbox checked={saveImage} onChange={(e) => setSaveImage(e.target.checked)}>
            イメージを保存する
          </Checkbox>
        </Row>
        <Row className="mt-1">
          <Checkbox checked={calculationOnSave} onChange={(e) => setCalculationOnSave(e.target.checked)}>
            保存時に数量別計算を実行する
          </Checkbox>
        </Row>
        <Row className="mt-1">
          <Checkbox checked={saveWithSameName} onChange={(e) => setSaveWithSameName(e.target.checked)}>
            親図番の同一名の保存を許可する
          </Checkbox>
        </Row>
        <Row className="mt-4">子部品追加の設定</Row>
        <Row className="mt-2">
          <Checkbox checked={addMultiChildParts} onChange={(e) => setAddMultiChildParts(e.target.checked)}>
            子部品追加時に個数を指定して複数同時追加を行う
          </Checkbox>
        </Row>
        <Row className="mt-4">工程選択の設定</Row>
        <Row className="mt-2">
          <Checkbox checked={showConfirmDialogue} onChange={(e) => setShowConfirmDialogue(e.target.checked)}>
            工程選択を行ったときに他の子部品も同じ変更を行うか確認ウィンドウを表示する
          </Checkbox>
        </Row> */}

        <Row className="mt-4">金額表示の設定</Row>
        <Row align="middle" className="mt-2">
          <Col span={2}>見積金額切上げ桁数</Col>
          <Col span={2} style={{ textAlign: 'end' }}>
            <Row>
              <Col span={18}>
                <Input
                  value={companySettingObj?.environments?.roundUpDigits /* estimatedAmount */}
                  style={{ width: '60%' }}
                ></Input>
              </Col>
              <Col span={4}>
                <Row>
                  <CaretUpOutlined
                    style={{ color: '#1063aa', fontSize: 16 }}
                    onClick={(e) => increaseEstimatedAmount('estimate')}
                  />
                </Row>
                <Row>
                  <CaretDownOutlined
                    style={{ color: '#1063aa', fontSize: 16 }}
                    onClick={(e) => decreaseEstimatedAmount('estimate')}
                  />
                </Row>
              </Col>
            </Row>
          </Col>

          <Col span={3}>
            <Select
              style={{ fontSize: 11, width: '100%' }}
              value={companySettingObj?.environments?.roundUpMethod}
              onChange={(e) => {
                setCompanySettingObj((prevData) => ({
                  ...prevData,
                  environments: {
                    ...prevData?.environments,
                    roundUpMethod: e,
                  },
                }));
                /* setMitsumoriDeleteConfirm(e); */
              }}
            >
              {Object.entries(RoundUpMethods)?.map(([key, value]) => (
                <Select.Option key={value} value={value}>
                  {key}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row align="middle" className="mt-2">
          <Col span={2}>小数点以下桁数</Col>
          <Col span={2} style={{ textAlign: 'end' }}>
            <Row>
              <Col span={18}>
                <Input value={companySettingObj?.environments?.decimalPlaces} style={{ width: '60%' }}></Input>
              </Col>
              <Col span={4}>
                <Row>
                  <CaretUpOutlined
                    style={{ color: '#1063aa', fontSize: 16 }}
                    onClick={(e) => increaseEstimatedAmount('decimal')}
                  />
                </Row>
                <Row>
                  <CaretDownOutlined
                    style={{ color: '#1063aa', fontSize: 16 }}
                    onClick={(e) => decreaseEstimatedAmount('decimal')}
                  />
                </Row>
              </Col>
            </Row>
          </Col>
          {/* <Col span={1}>
            
          </Col> */}
        </Row>
        <Row className="mt-2" align="middle">
          <Col span={2}>通貨記号</Col>
          <Col span={5}>
            <Select
              style={{ fontSize: 11, width: '88%', marginLeft: '2.5rem' }}
              value={companySettingObj?.environments?.currencySymbol}
              onChange={(e) => {
                setCompanySettingObj((prevData) => ({
                  ...prevData,
                  environments: {
                    ...prevData?.environments,
                    currencySymbol: e,
                  },
                }));
                /* setMitsumoriDeleteConfirm(e); */
              }}
            >
              {/* {currencySymbol?.map((item) => (
                <Select.Option value={item.value}>{item.label}</Select.Option>
              ))} */}

              {Object.entries(CurrencySymbols)?.map(([key, value]) => (
                <Select.Option key={value} value={value}>
                  {curSymbol[value]}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row style={{ marginTop: '4rem' }}>
          <Col span={7} style={{ textAlign: 'right' }}>
            <Button
              className="mainButton"
              onClick={() => {
                setSaveConfirm(userSettingInfo?.massages?.editOk);
                if (!userSettingInfo?.massages?.editOk) {
                  updConfirmOk();
                }
              }}
            >
              保存
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
  const editCSVSetting = () => {
    setCSVSettingModal(true);
  };
  const handleIncrease = (e, key, name) => {
    if (name === 'excel') {
      // const data= { key: 1, title: '', useFor: '', fileName: '', note: '' }
      const copyData = excelFilesList.slice(); // Create a copy of the original array

      const insertIndex = excelFilesList?.findIndex((item, index) => index === key);
      //const insertIndex = excelFilesList?.map((item,index)=> index ===key);
      const largestId = excelFilesList?.reduce((maxId, item) => {
        return item.key > maxId ? item.key : maxId;
      }, 0);

      if (insertIndex !== -1) {
        const data = {
          id: 0,
          title: '',
          useType: PrintTypes.individual,
          fileName: uploadedFileLst[0].value,
          fileId: '',
        };
        copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
        setExcelFilesList(copyData);
      }
    } else {
      const copyData = csvFilesList.slice(); // Create a copy of the original array

      const insertIndex = csvFilesList?.findIndex((item) => item.key === key); // Find the clicked index of the item
      const largestId = csvFilesList?.reduce((maxId, item) => {
        return item.key > maxId ? item.key : maxId;
      }, 0);

      if (insertIndex !== -1) {
        const data = {
          key: largestId + 1,
          title: '',
          useFor: '',
          examples: '',
        };
        copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
        setCSVFilesList(copyData);
      }
    }
  };
  const handleDecrease = (e, key, name) => {
    if (name === 'excel') {
      if (excelFilesList?.length > 1) {
        const updatedData = excelFilesList?.filter((item, index) => index !== key);
        setExcelFilesList(updatedData);
      }
    } else {
      if (csvFilesList?.length > 1) {
        const updatedData = csvFilesList?.filter((item, index) => index !== key);
        setCSVFilesList(updatedData);
      }
    }
  };
  const exportSetting = (
    <>
      <Row className="mt-4">
        <label style={{ fontWeight: 800, fontSize: '13.5px' }}>出力フォーマットの設定</label>
      </Row>
      <div style={{ marginLeft: '1.5rem' }} className="settingContent">
        <Row className="mt-2">
          <label>Excelフォーマット管理</label>
        </Row>
        <div
          className="col-md-10"
          onDragEnter={handleDragEnter}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          style={{ border: `1px dashed ${dragging ? 'blue' : 'black'}`, padding: '20px', marginTop: '0.5em' }}
        >
          <Row justify="center" align="middle">
            <label>ファイルをドラック＆ドロップかクリックでアップロード　または　</label>{' '}
            <input
              type="file"
              accept=".xlsx"
              ref={excelFileRef}
              style={{ display: 'none' }}
              onChange={handleExcelFileChange}
            />
            <Button onClick={browseExcelFiles} style={{ backgroundColor: '#005fab', color: 'white' }}>
              ファイルを選択
            </Button>
          </Row>
        </div>
        {/*  <div className="overflow-auto" style={{ height: '100px' }}>
          {files?.length > 0 ? (
            <>
              <Row>
                <ul>
                  {files?.map((file, index) => (
                    <li key={index}>{file.name}</li>
                  ))}
                </ul>
              </Row>
              <Row justify="center">
                <Col span={11}>
                  <Button
                    className="mainButton"
                    onClick={() => {
                      handleFileUpload();
                    }}
                  >
                    保存
                  </Button>
                </Col>
              </Row>
            </>
          ) : excelFiles?.length > 0 ? (
            <>
              <Row></Row>
              <Row justify="center">
                <Col span={11}>
                  <Button
                    className="mainButton"
                    onClick={() => {
                      handleFileUpload();
                    }}
                  >
                    保存
                  </Button>
                </Col>
              </Row>
            </>
          ) : (
            <></>
          )}
        </div> */}
        <div
          className="registerModal mt-4 chargeAmount overflow-auto"
          style={{ height: 245, overflowY: 'scroll', width: '66%' }}
        >
          <Table>
            <thead>
              <tr style={{ height: '42px', verticalAlign: 'middle' }}>
                <td className="tbl-header fixedHeader" style={{ width: '15%' }}>
                  タイトル
                </td>
                <td className="tbl-header fixedHeader" style={{ width: '15%' }}>
                  利用シーン
                </td>
                <td className="tbl-header fixedHeader" style={{ width: '15%' }}>
                  ファイル名
                </td>
                {/*  <td className="tbl-header fixedHeader" style={{ width: '15%' }}></td> */}
                <td style={{ border: 'none', backgroundColor: 'white' }} className="fixedHeader"></td>
              </tr>
            </thead>
            <tbody>
              {excelFilesList?.map((item, index) => (
                <tr key={index} style={{ textAlign: 'center', verticalAlign: 'middle', height: '40px' }}>
                  <td>
                    <Input
                      value={item.title}
                      /*  onChange={(e) => {
                        if (excelFilesList?.length > 0 || excelFilesList != undefined)
                          setExcelFilesList((prevData) => ({
                            ...prevData[index],
                            title: e.target.value,
                          }));
                      }} */
                      onChange={(e) => {
                        setExcelFilesList((prevData) => {
                          const newData = [...prevData];
                          newData[index] = {
                            ...newData[index],
                            title: e.target.value,
                            fileId: e.target.value,
                          };
                          return newData;
                        });
                      }}
                    ></Input>
                  </td>
                  <td>
                    <Select
                      style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
                      value={item.useType}
                      onChange={(e) => {
                        setExcelFilesList((prevData) => {
                          const newData = [...prevData];
                          newData[index] = {
                            ...newData[index],
                            useType: e,
                          };
                          return newData;
                        });
                      }}
                    >
                      {Object.entries(PrintTypes)?.map(([key, value]) => (
                        <Select.Option key={value} value={value}>
                          {riyoushin[value]}
                        </Select.Option>
                      ))}
                    </Select>
                  </td>
                  <td>
                    <Select
                      style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
                      value={item.fileName}
                      onChange={(e) => {
                        setExcelFilesList((prevData) => {
                          const newData = [...prevData];
                          newData[index] = {
                            ...newData[index],
                            fileName: e,
                            fileId: e,
                          };
                          return newData;
                        });
                      }}
                    >
                      {uploadedFileLst?.map((item, index) => (
                        <Select.Option key={index} value={item.value}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </td>
                  {/* <td>{item.note}</td> */}
                  <td style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '6%' }}>
                    <Row justify="center">
                      <PlusCircleFilled
                        className="add-remove-icon"
                        style={{ marginLeft: 5, marginRight: 5 }}
                        onClick={(e) => handleIncrease(e, index, 'excel')}
                      />
                      <CloseCircleFilled
                        className="add-remove-icon"
                        onClick={(e) => handleDecrease(e, index, 'excel')}
                      />
                    </Row>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        {/* <Row className="mt-4">
          <label>CSVフォーマット管理</label>
        </Row>
        <div
          className="registerModal mt-2 chargeAmount overflow-auto"
          style={{ height: 245, overflowY: 'scroll', width: '66%' }}
        >
          <Table>
            <thead>
              <tr style={{ height: '42px', verticalAlign: 'middle' }}>
                <td className="tbl-header fixedHeader" style={{ width: '15%' }}>
                  タイトル
                </td>
                <td className="tbl-header fixedHeader" style={{ width: '15%' }}>
                  利用シーン
                </td>
                <td className="tbl-header fixedHeader" style={{ width: '30%' }}>
                  出力例
                </td>
                <td style={{ border: 'none', backgroundColor: 'white' }} className="fixedHeader"></td>
              </tr>
            </thead>
            <tbody>
              {csvFilesList?.map((item, index) => (
                <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  <td>
                    <Input value={item.title}></Input>
                  </td>
                  <td>
                    <Select style={{ fontSize: 11, width: '100%', textAlign: 'left' }} value={item.useFor}>
                      {outputOptions?.map((item) => (
                        <Select.Option value={item.value}>{item.label}</Select.Option>
                      ))}
                    </Select>
                  </td>
                  <td style={{ textAlign: 'left', verticalAlign: 'middle' }}>
                    <Row>
                      <Col span={22}>{item.examples}</Col>
                      <Col span={2}>
                        <EditOutlined
                          style={{ color: '#1063aa', fontSize: 20, stroke: 'white' }}
                          onClick={editCSVSetting}
                        />
                      </Col>
                    </Row>
                  </td>
                  <td style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '6%' }}>
                    <Row justify="center">
                      <PlusCircleFilled
                        className="add-remove-icon"
                        style={{ marginLeft: 5, marginRight: 5 }}
                        onClick={(e) => handleIncrease(e, item.key, 'csv')}
                      />
                      <CloseCircleFilled
                        className="add-remove-icon"
                        onClick={(e) => handleDecrease(e, item.key, 'csv')}
                      />
                    </Row>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div> */}
        <Row style={{ marginTop: '4rem' }}>
          <Col span={8} style={{ textAlign: 'right' }}>
            <Button
              className={uploadedFileLst[0] == undefined || uploadedFileLst[0]?.length == 0 ? '' : 'mainButton'}
              onClick={() => {
                setSaveConfirm(userSettingInfo?.massages?.editOk);
                if (!userSettingInfo?.massages?.editOk) {
                  updConfirmOk();
                }
              }}
              disabled={uploadedFileLst[0] == undefined || uploadedFileLst[0]?.length == 0}
            >
              保存
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
  const reportSetting = (
    <>
      <Row className="mt-4">
        <label style={{ fontWeight: 800, fontSize: '13.5px' }}>帳票の設定</label>
      </Row>
      <div style={{ marginLeft: '1.5rem' }} className="settingContent">
        <Row className="mt-2">個別帳票</Row>
        <Row className="mt-1">
          <Checkbox
            checked={individualExcelQuickOutput}
            onChange={(e) => {
              if (companySettingObj?.printOutputs?.length > 0) {
                setCompanySettingObj((prevData) => ({
                  ...prevData,
                  printOutputs: prevData?.printOutputs?.map((output, index) => {
                    if (output.printType === PrintTypes.individual) {
                      // Update the second object while leaving others unchanged
                      return {
                        ...output,
                        isUseQuickOutput: e.target.checked,
                      };
                    }
                    return output;
                  }),
                }));
              }
              setListExcelQuickOutput(e.target.checked);
            }}
          >
            Quick出力を有効にする
          </Checkbox>
        </Row>
        {/* <Row>
          <Checkbox
            checked={individualAutoExcelExport}
            onChange={(e) => setIndividualAutoExcelExport(e.target.checked)}
          >
            帳票出力後、Excelファイルを自動で開く
          </Checkbox>
        </Row> */}
        {/* <Row className="mt-4">
          <label>出力フォルダ</label>
        </Row>
        <Row align="middle">
          <Col span={6}>
            <Input value={fileName}></Input>
          </Col>
          <Col span={1}>
            <input
              type="file"
              ref={reportOutputFolderRef}
              style={{ display: 'none' }}
              onChange={handleWorkFileChange}
            />
            <Button className="referenceButton" onClick={browseIndividualFile} style={{ marginLeft: 3 }}>
              参照
            </Button>
          </Col>
        </Row> */}
        <Row className="mt-4">
          <label>出力ファイル</label>
        </Row>
        <Row>
          <Col span={6}>
            <Select
              style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
              value={individualFormatId}
              onChange={(e) => {
                if (companySettingObj?.printOutputs?.length > 0) {
                  setCompanySettingObj((prevData) => ({
                    ...prevData,
                    printOutputs: prevData?.printOutputs?.map((output, index) => {
                      if (output.printType === PrintTypes.individual) {
                        // Update the second object while leaving others unchanged
                        return {
                          ...output,
                          formatsId: e,
                        };
                      }
                      return output; // Leave other objects unchanged
                    }),
                  }));
                }
                setIndividualFormatId(e);
              }}
            >
              {excelFilesList[0]?.id !== 0 ? (
                excelFilesList?.map((item) => {
                  if (item.useType === 0) {
                    return (
                      <Select.Option key={item.id} value={item.id}>
                        {item?.fileName}
                      </Select.Option>
                    );
                  }
                  return null; // Return null for items that don't meet the condition
                })
              ) : (
                <Select.Option></Select.Option>
              )}
            </Select>
          </Col>
        </Row>
        {/* list  */}
        <Row style={{ marginTop: '2.5rem' }}>リスト帳票</Row>
        <Row className="mt-1">
          <Checkbox
            checked={listExcelQuickOutput}
            onChange={(e) => {
              if (companySettingObj?.printOutputs?.length > 0) {
                setCompanySettingObj((prevData) => ({
                  ...prevData,
                  printOutputs: prevData?.printOutputs?.map((output, index) => {
                    if (output.printType === PrintTypes.list) {
                      // Update the second object while leaving others unchanged
                      return {
                        ...output,
                        isUseQuickOutput: e.target.checked,
                      };
                    }
                    return output;
                  }),
                }));
              }
              setListExcelQuickOutput(e.target.checked);
            }}
          >
            Quick出力を有効にする
          </Checkbox>
        </Row>
        {/* <Row>
          <Checkbox checked={listAutoExcelExport} onChange={(e) => setListAutoExcelExport(e.target.checked)}>
            帳票出力後、Excelファイルを自動で開く
          </Checkbox>
        </Row> */}
        {/* <Row className="mt-4">
          <label>出力フォルダ</label>
        </Row>
        <Row align="middle">
          <Col span={6}>
            <Input value={fileName}></Input>
          </Col>
          <Col span={1}>
            <input
              type="file"
              ref={reportOutputFolderRef}
              style={{ display: 'none' }}
              onChange={handleWorkFileChange}
            />
            <Button className="referenceButton" onClick={browseIndividualFile} style={{ marginLeft: 3 }}>
              参照
            </Button>
          </Col>
        </Row> */}
        <Row className="mt-4">
          <label>出力ファイル</label>
        </Row>
        <Row>
          <Col span={6}>
            <Select
              style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
              // value={excelFilesList[0]?.id != 0 ? listFormatId : ''}
              value={listFormatId}
              onChange={(e) => {
                if (companySettingObj?.printOutputs?.length > 0) {
                  setCompanySettingObj((prevData) => ({
                    ...prevData,
                    printOutputs: prevData?.printOutputs?.map((output, index) => {
                      if (output.printType === PrintTypes.list) {
                        // Update the second object while leaving others unchanged
                        return {
                          ...output,
                          formatsId: e,
                        };
                      }
                      return output;
                    }),
                  }));
                }
                setListFormatId(e);
              }}
            >
              {excelFilesList[0]?.id !== 0 ? (
                excelFilesList?.map((item) => {
                  if (item?.useType === 1) {
                    return (
                      <Select.Option key={item.id} value={item.id}>
                        {item?.fileName}
                      </Select.Option>
                    );
                  }
                  return null; // Return null for items that don't meet the condition
                })
              ) : (
                <Select.Option></Select.Option>
              )}
              {/* {formatLst != [] || formatLst != undefined ? (
                formatLst?.map((item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item?.fileName}
                  </Select.Option>
                ))
              ) : (
                
              )} */}
            </Select>
          </Col>
        </Row>
        <Row style={{ marginTop: '4rem' }}>
          <Col span={6} style={{ textAlign: 'right' }}>
            <Button
              className={excelFilesList[0]?.id == 0 ? '' : 'mainButton'}
              onClick={() => {
                setSaveConfirm(true);
              }}
              disabled={excelFilesList[0]?.id == 0}
            >
              保存
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
  const csvExportSetting = (
    <>
      <Row className="mt-4">
        <label style={{ fontWeight: 800, fontSize: '13.5px' }}>CSV出力の設定</label>
      </Row>
      <div style={{ marginLeft: '1.5rem' }} className="settingContent">
        <Row className="mt-2">個別帳票</Row>
        <Row className="mt-1">
          <Checkbox checked={individualCSVQuickOutput} onChange={(e) => setIndividualCSVQuickOutput(e.target.checked)}>
            Quick出力を有効にする
          </Checkbox>
        </Row>
        {/* <Row className="mt-1">
          <Checkbox checked={individualAutoCSVExport} onChange={(e) => setIndividualAutoCSVExport(e.target.checked)}>
            帳票出力後、CSVファイルを自動で開く
          </Checkbox>
        </Row>
        <Row className="mt-4">
          <label>出力フォルダ</label>
        </Row>
        <Row align="middle">
          <Col span={6}>
            <Input value={fileName}></Input>
          </Col>
          <Col span={1}>
            <input
              type="file"
              ref={reportOutputFolderRef}
              style={{ display: 'none' }}
              onChange={handleWorkFileChange}
            />
            <Button className="referenceButton" onClick={browseIndividualFile} style={{ marginLeft: 3 }}>
              参照
            </Button>
          </Col>
        </Row> */}
        <Row className="mt-4">
          <label>出力形式</label>
        </Row>
        <Row>
          <Col span={6}>
            <Select style={{ width: '100%' }} value={1}>
              <Select.Option value={1}>生産管理連携_最新</Select.Option>
            </Select>
          </Col>
        </Row>
        {/* list  */}
        <Row style={{ marginTop: '2.5rem' }}>リスト帳票</Row>
        <Row className="mt-1">
          <Checkbox checked={listCSVQuickOutput} onChange={(e) => setListCSVQuickOutput(e.target.checked)}>
            Quick出力を有効にする
          </Checkbox>
        </Row>
        {/* <Row className="mt-1">
          <Checkbox checked={listAutoCSVExport} onChange={(e) => setListAutoCSVExport(e.target.checked)}>
            帳票出力後、CSVファイルを自動で開く
          </Checkbox>
        </Row>
        <Row className="mt-4">
          <label>出力フォルダ</label>
        </Row>
        <Row align="middle">
          <Col span={6}>
            <Input value={fileName}></Input>
          </Col>
          <Col span={1}>
            <input
              type="file"
              ref={reportOutputFolderRef}
              style={{ display: 'none' }}
              onChange={handleWorkFileChange}
            />
            <Button className="referenceButton" onClick={browseIndividualFile} style={{ marginLeft: 3 }}>
              参照
            </Button>
          </Col>
        </Row> */}
        <Row className="mt-4">
          <label>出力ファイル</label>
        </Row>
        <Row>
          <Col span={6}>
            <Select style={{ width: '100%' }} value={1}>
              <Select.Option value={1}>親備品リス</Select.Option>
            </Select>
          </Col>
        </Row>
        <Row style={{ marginTop: '4rem' }}>
          <Col span={6} style={{ textAlign: 'right' }}>
            <Button className="mainButton">保存</Button>
          </Col>
        </Row>
      </div>
    </>
  );
  const defaultNameSetting = (
    <>
      <Row className="mt-4">
        <label style={{ fontWeight: 800, fontSize: '13.5px' }}>親部品の初期名称の設定</label>
      </Row>
      <div style={{ marginLeft: '1.5rem' }} className="settingContent">
        <Row className="mt-2">
          <label>図番</label>
        </Row>
        <Row className="mt-1">
          <Col span={2}>
            <Input></Input>
          </Col>
          <Col span={1} style={{ textAlign: 'center' }}>
            <Input style={{ width: '80%' }}></Input>
          </Col>
          <Col span={2}>
            <Select
              style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
              value={naming1}
              onChange={(e) => setNaming1(e)}
            >
              {namingConditions?.map((item, index) => (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={1} style={{ textAlign: 'center' }}>
            <Input style={{ width: '80%' }}></Input>
          </Col>
          <Col span={2}>
            <Select
              style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
              value={naming2}
              onChange={(e) => setNaming2(e)}
            >
              {namingConditions?.map((item, index) => (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={1} style={{ textAlign: 'center' }}>
            <Input style={{ width: '80%' }}></Input>
          </Col>
          <Col span={2}>
            <Select
              style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
              value={naming3}
              onChange={(e) => setNaming3(e)}
            >
              {namingConditions?.map((item, index) => (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={1} style={{ textAlign: 'center' }}>
            <Input style={{ width: '80%' }}></Input>
          </Col>
          <Col span={2}>
            <Select
              style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
              value={naming4}
              onChange={(e) => setNaming4(e)}
            >
              {namingConditions?.map((item, index) => (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row className="mt-4">
          <label>部品名</label>
        </Row>
        <Row className="mt-1">
          <Col span={2}>
            <Input></Input>
          </Col>
          <Col span={1} style={{ textAlign: 'center' }}>
            <Input style={{ width: '80%' }}></Input>
          </Col>
          <Col span={2}>
            <Select
              style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
              value={partNaming1}
              onChange={(e) => setPartNaming1(e)}
            >
              {namingConditions?.map((item, index) => (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={1} style={{ textAlign: 'center' }}>
            <Input style={{ width: '80%' }}></Input>
          </Col>
          <Col span={2}>
            <Select
              style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
              value={partNaming2}
              onChange={(e) => setPartNaming2(e)}
            >
              {namingConditions?.map((item, index) => (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={1} style={{ textAlign: 'center' }}>
            <Input style={{ width: '80%' }}></Input>
          </Col>
          <Col span={2}>
            <Select
              style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
              value={partNaming3}
              onChange={(e) => setPartNaming3(e)}
            >
              {namingConditions?.map((item, index) => (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={1} style={{ textAlign: 'center' }}>
            <Input style={{ width: '80%' }}></Input>
          </Col>
          <Col span={2}>
            <Select
              style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
              value={partNaming4}
              onChange={(e) => setPartNaming4(e)}
            >
              {namingConditions?.map((item, index) => (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row className="mt-4">
          <label>見積番号</label>
        </Row>
        <Row className="mt-1">
          <Col span={2}>
            <Input></Input>
          </Col>
          <Col span={1} style={{ textAlign: 'center' }}>
            <Input style={{ width: '80%' }}></Input>
          </Col>
          <Col span={2}>
            <Select
              style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
              value={mitsumoriNaming1}
              onChange={(e) => setMitsumoriNaming1(e)}
            >
              {namingConditions?.map((item, index) => (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={1} style={{ textAlign: 'center' }}>
            <Input style={{ width: '80%' }}></Input>
          </Col>
          <Col span={2}>
            <Select
              style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
              value={mitsumoriNaming2}
              onChange={(e) => setMitsumoriNaming2(e)}
            >
              {namingConditions?.map((item, index) => (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={1} style={{ textAlign: 'center' }}>
            <Input style={{ width: '80%' }}></Input>
          </Col>
          <Col span={2}>
            <Select
              style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
              value={mitsumoriNaming3}
              onChange={(e) => setMitsumoriNaming3(e)}
            >
              {namingConditions?.map((item, index) => (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={1} style={{ textAlign: 'center' }}>
            <Input style={{ width: '80%' }}></Input>
          </Col>
          <Col span={2}>
            <Select
              style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
              value={mitsumoriNaming4}
              onChange={(e) => setMitsumoriNaming4(e)}
            >
              {namingConditions?.map((item, index) => (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
      </div>

      <Row style={{ marginTop: '2.5rem' }}>
        <label style={{ fontWeight: 800, fontSize: '13.5px' }}>子部品の初期名称の設定</label>
      </Row>
      <div style={{ marginLeft: '1.5rem' }} className="settingContent">
        <Row className="mt-2">
          <label>図番</label>
        </Row>
        <Row className="mt-1">
          <Col span={2}>
            <Input></Input>
          </Col>
          <Col span={1} style={{ textAlign: 'center' }}>
            <Input style={{ width: '80%' }}></Input>
          </Col>
          <Col span={2}>
            <Select
              style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
              value={childNaming1}
              onChange={(e) => setChildNaming1(e)}
            >
              {namingConditions?.map((item, index) => (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={1} style={{ textAlign: 'center' }}>
            <Input style={{ width: '80%' }}></Input>
          </Col>
          <Col span={2}>
            <Select
              style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
              value={childNaming2}
              onChange={(e) => setChildNaming2(e)}
            >
              {namingConditions?.map((item, index) => (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={1} style={{ textAlign: 'center' }}>
            <Input style={{ width: '80%' }}></Input>
          </Col>
          <Col span={2}>
            <Select
              style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
              value={childNaming3}
              onChange={(e) => setChildNaming3(e)}
            >
              {namingConditions?.map((item, index) => (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={1} style={{ textAlign: 'center' }}>
            <Input style={{ width: '80%' }}></Input>
          </Col>
          <Col span={2}>
            <Select
              style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
              value={childNaming4}
              onChange={(e) => setChildNaming4(e)}
            >
              {namingConditions?.map((item, index) => (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row className="mt-4">
          <label>部品名</label>
        </Row>
        <Row className="mt-1">
          <Col span={2}>
            <Input></Input>
          </Col>
          <Col span={1} style={{ textAlign: 'center' }}>
            <Input style={{ width: '80%' }}></Input>
          </Col>
          <Col span={2}>
            <Select
              style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
              value={childPartNaming1}
              onChange={(e) => setChildPartNaming1(e)}
            >
              {namingConditions?.map((item, index) => (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={1} style={{ textAlign: 'center' }}>
            <Input style={{ width: '80%' }}></Input>
          </Col>
          <Col span={2}>
            <Select
              style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
              value={childPartNaming2}
              onChange={(e) => setChildPartNaming2(e)}
            >
              {namingConditions?.map((item, index) => (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={1} style={{ textAlign: 'center' }}>
            <Input style={{ width: '80%' }}></Input>
          </Col>
          <Col span={2}>
            <Select
              style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
              value={childNaming3}
              onChange={(e) => setChildPartNaming3(e)}
            >
              {namingConditions?.map((item, index) => (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={1} style={{ textAlign: 'center' }}>
            <Input style={{ width: '80%' }}></Input>
          </Col>
          <Col span={2}>
            <Select
              style={{ fontSize: 11, width: '100%', textAlign: 'left' }}
              value={childNaming4}
              onChange={(e) => setChildNaming4(e)}
            >
              {namingConditions?.map((item, index) => (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>

        <Row style={{ marginTop: '4rem' }}>
          <Col span={14} style={{ textAlign: 'right' }}>
            <Button className="mainButton">保存</Button>
          </Col>
        </Row>
      </div>
    </>
  );

  async function addCompanySetting(companySetting) {
    let ret = [];
    try {
      const Url = process.env.REACT_APP_BACKEND_API_URL + 'CompanySetting';
      const otherParam = {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + getAccessToken(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
        body: JSON.stringify({
          companySettingType: selectedAdminItem,
          Langs: companySetting.languages,
          Envs: companySetting.environments,
          InitNames: companySetting.initNames,
          ExcelFormats: companySetting.excelFormats,
          CSVFormats: companySetting.csvFormats,
          PrintOutputs: companySetting.printOutputs,
          CSVOutputs: companySetting.csvOutputs,
          SettingInfo: companySetting.info,
          downloadFormatId: 0,
        }),
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          return await response.json();
        })
        .then((data) => {
          if (data && data?.updateToken) {
            // セクションにトークン情報を更新する
            updateAccessToken(data?.updateToken);
          }
          if (data && data.statusCode === StatusCodes.OK) {
            ret = data.data[0];
            complete('データ保存は正常に完了しました。');
          } else if (
            data &&
            (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)
          ) {
            authorizeError(ErrorMessage()[data?.messageCode]);
            return;
          } else if (data && data.statusCode !== StatusCodes.OK) {
            if (data?.messageCode == 'E020' || data?.messageCode == 'E007') {
              let errorMsg = '';
              if (data?.messageCode === 'E020') {
                errorMsg = ErrorMessage('ファイル名「' + data.detailMessage + '」')[data?.messageCode];
              } else if (data?.messageCode === 'E007') {
                errorMsg =
                  '出力ファイルを選択してください。選択物がない場合、出力フォーマットにフォーマットをさきに設定してください。';
              }

              error(errorMsg);
              return;
            } else {
              error(ErrorMessage()[data?.messageCode]);
              return;
            }
          }
        })
        .catch((e) => {
          authorizeError(ErrorMessage().E010);
          return;
        });
    } catch (e) {
      error(e.message);
      return;
    }
    ret?.length > 0 && ret != undefined ? setIsExist(true) : setIsExist(false);
    return ret;
  }

  async function updateCompanySetting(companySetting) {
    let ret = [];
    try {
      const Url = process.env.REACT_APP_BACKEND_API_URL + 'CompanySetting';
      const otherParam = {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + getAccessToken(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
        body: JSON.stringify({
          companySettingType: selectedAdminItem,
          Langs: companySetting.languages,
          Envs: companySetting.environments,
          InitNames: companySetting.initNames,
          ExcelFormats: companySetting.excelFormats,
          CSVFormats: companySetting.csvFormats,
          PrintOutputs: companySetting.printOutputs,
          CSVOutputs: companySetting.csvOutputs,
          SettingInfo: companySetting.info,
        }),
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          return await response.json();
        })
        .then((data) => {
          if (data && data?.updateToken) {
            // セクションにトークン情報を更新する
            updateAccessToken(data?.updateToken);
          }
          if (data && data.statusCode === StatusCodes.OK) {
            ret = data.data[0];
            complete('データ保存は正常に完了しました。');
          } else if (
            data &&
            (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)
          ) {
            authorizeError(ErrorMessage()[data?.messageCode]);
            return;
          } else if (data && data.statusCode !== StatusCodes.OK) {
            if (data?.messageCode == 'E020' || data?.messageCode == 'E007') {
              let errorMsg = '';
              if (data?.messageCode === 'E020') {
                errorMsg = ErrorMessage('ファイル名「' + data.detailMessage + '」')[data?.messageCode];
              } else if (data?.messageCode === 'E007') {
                errorMsg =
                  '出力ファイルを選択してください。選択物がない場合、出力フォーマットにフォーマットをさきに設定してください。';
              }
              error(errorMsg);
              return;
            } else {
              error(ErrorMessage()[data?.messageCode]);
              return;
            }
          }
        })
        .catch((e) => {
          authorizeError(ErrorMessage().E010);
          return;
        });
    } catch (e) {
      error(e.message);
      return;
    }
    return ret;
  }

  const updConfirmOk = async (e) => {
    companySettingObj.excelFormats = excelFilesList;
    if (isExist) {
      await updateCompanySetting(companySettingObj);
    } else {
      await addCompanySetting(companySettingObj);
    }
    setSaveConfirm(false);
  };

  const updConfirmCancel = () => {
    setSaveConfirm(false);
  };

  return (
    <>
      <div>
        {selectedAdminItem === '0' ? adminLanguageSetting : selectedAdminItem === '1' ? exportSetting : reportSetting}
      </div>
      {/* {CSVSettingModal ? <CSVSetting csvModal={CSVSettingModal} /> : ''} */}

      {/** 新規追加破棄確認ポップアップ */}
      {saveConfirm &&
        commonModal(
          saveConfirm,
          confirmModalTitle,
          commonFooter(updConfirmOk, updConfirmCancel),
          null,
          400,
          updConfirmOk,
          updConfirmCancel,
          confirmUpdateContent,
          null,
          null,
          false
        )}
    </>
  );
});

export default CompanySetting;
