/**
 * クラス名：共通API
 * 説明：システム全体共通使うAPIへの要求関数を記載するファイルである。
 * 作成者：ナンス
 * 作成日：2023/06/21
 * バージョン：1.0
 */
import axios from 'axios';
import { StatusCodes } from 'http-status-codes';

import { error, authorizeError, complete } from './CommonModal';
import { ErrorMessage } from './Message';
import { updateAccessToken, getAccessToken } from './Common';

export const loginPath = process.env.REACT_APP_FRONTEND_URL;

//　ログアウト処理
export const logOutSubmit = async () => {
  let result = true;
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'Auth/Logout';
    const token = getAccessToken();
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };

    await fetch(Url, otherParam)
      .then((response) => {
        if (response?.status === StatusCodes.UNAUTHORIZED) {
          authorizeError(ErrorMessage().E006);
          return;
        } else {
          return response;
        }
      })
      .then((data) => {
        if (data && data?.status === StatusCodes.OK) {
          // localStorage.clear(); // セクション情報をクリック
          localStorage?.removeItem('iQxWeb_LoginUser');
          localStorage?.removeItem('iQxWeb_AccessToken');
          // window.location.replace(loginPath); // ログインへ遷移
        } else if (data && data?.status !== StatusCodes.OK) {
          error(data);
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return result;
};

// ログインユーザーの情報を取得する
export const getCurrentUserInfo = async () => {
  let ret = '';
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'Auth/CurrentUserInfo';
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        if (response?.status === StatusCodes.UNAUTHORIZED) {
          authorizeError(ErrorMessage().E006);
          return;
        } else {
          return await response?.json();
        }
      })
      .then((data) => {
        if (data) {
          // セクションにログインユーザー情報を保持する
          // Access value associated with the key
          localStorage.setItem('iQxWeb_LoginUser', encodeURI(JSON.stringify(data)));
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          ret = ErrorMessage().E004;
        }
      })
      .catch((error) => {
        ret = ErrorMessage().E010;
      });
  } catch (error) {
    ret = error.message;
  }
  return ret;
};

// お客様情報の取得
export const getClientInfo = async () => {
  let clientsData = [];
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'Client';
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };
    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          clientsData = data?.data;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage()[data?.messageCode]);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          error(ErrorMessage()[data?.messageCode]);
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return clientsData;
};

// 担当者情報をDBから取得する
export const getStaffInfo = async () => {
  let personInChargeData = [];
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'Staff';
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          personInChargeData = data?.data;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage()[data?.messageCode]);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          error(ErrorMessage()[data?.messageCode]);
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return personInChargeData;
};

// 案件状態情報をDBから取得する
export const getEstimateStatusData = async () => {
  let projectStatusData = [];
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'EstimateStatus';
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          projectStatusData = data?.data;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage()[data?.messageCode]);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          error(ErrorMessage()[data?.messageCode]);
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return projectStatusData;
};

// 購入品情報をDBから取得する
export const getPurchaseInfo = async () => {
  let purchaseData = [];

  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'Purchase';
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })

      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          purchaseData = data?.data;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          error(ErrorMessage()[data?.messageCode]);
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return purchaseData;
};

// 購入品種別情報をDBから取得する
export const getPurchaseCategoryInfo = async () => {
  let purchaseCategoryData = [];
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'PurchaseCategory';
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          purchaseCategoryData = data?.data;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          error(ErrorMessage()[data?.messageCode]);
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return purchaseCategoryData;
};

// 購入品種別より所属している購入品があるかどうかのチェック
export const isExistPurchaseInfo = async (id) => {
  let retMessage = '';
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'PurchaseCategory/CheckPurchase/' + id;
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)) {
          authorizeError(ErrorMessage()[data?.messageCode]);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          // 購入品がある場合、
          retMessage = ErrorMessage()[data?.messageCode];
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return retMessage;
};

// 材質区分マスタ情報をDBから取得する
export const getMaterialTypeInfo = async () => {
  let materialTypeData = [];
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'MaterialType';
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          materialTypeData = data?.data;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          error(ErrorMessage()[data?.messageCode]);
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return materialTypeData;
};

// 板金子部品材質マスタ情報をDBから取得する
export const getMaterialTypeIQ3Info = async () => {
  let materialTypeIq3Data = [];
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'MaterialTypeIq3';
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          materialTypeIq3Data = data?.data;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          error(ErrorMessage()[data?.messageCode]);
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return materialTypeIq3Data;
};

// 板金子部品材料サイズマスタ情報をDBから取得する
export const getMaterialSizeIQ3Info = async () => {
  let materialSizeIq3Data = [];
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'MaterialSizeIq3';
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          materialSizeIq3Data = data?.data;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          error(ErrorMessage()[data?.messageCode]);
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return materialSizeIq3Data;
};

// 材料表面保護マスタ情報をDBから取得する
export const getMaterialSurfaceIQ3Info = async () => {
  let materialSurfaceIq3Data = [];
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'MaterialSurfaceIq3';
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          materialSurfaceIq3Data = data?.data;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          error(ErrorMessage()[data?.messageCode]);
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return materialSurfaceIq3Data;
};

// 板金子部品材料マスタ情報をDBから取得する
export const getMaterialIQ3Info = async () => {
  let materialIq3Data = [];
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'MaterialIq3';
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          materialIq3Data = data?.data;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          error(ErrorMessage()[data?.messageCode]);
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return materialIq3Data;
};

// 工程マスタ情報をDBから取得する
export const getProcessInfo = async () => {
  let processData = [];
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'Process';
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          processData = data?.data;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          error(ErrorMessage()[data?.messageCode]);
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return processData;
};

// 購入品引当マスタ情報をDBから取得する
export const getPurchaseAllocationInfo = async () => {
  let purchaseAllocationData = [];
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'PurchaseAllocation';
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          purchaseAllocationData = data?.data;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          error(ErrorMessage()[data?.messageCode]);
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return purchaseAllocationData;
};

// 装置マスタ情報をDBから取得する
export const getDeviceInfo = async () => {
  let devicesData = [];
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'Device';
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          devicesData = data?.data;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          error(ErrorMessage()[data?.messageCode]);
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return devicesData;
};

// お客様情報の取得
export const getCompanyInfo = async () => {
  let companysData = [];
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'Company';
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };
    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          companysData = data?.data;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage()[data?.messageCode]);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          error(ErrorMessage()[data?.messageCode]);
          return companysData;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return companysData;
      });
  } catch (e) {
    error(e.message);
    return companysData;
  }
  return companysData;
};

// 工程パターンマスタ情報をDBから取得する
export const getProcessPatternInfo = async () => {
  let processPatternData = [];
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'ProcessPattern';
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };
    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }

        if (data && data?.statusCode === StatusCodes.OK) {
          processPatternData = data?.data;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          error(ErrorMessage()[data?.messageCode]);
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return processPatternData;
};

// 見積情報をDBから取得する
export const getEstimateProductInfo = async () => {
  let esProductData = [];
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'EstimateProduct';
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          esProductData = data?.data;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          error(ErrorMessage()[data?.messageCode]);
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return esProductData;
};

// 親部品見積情報をDBに新規登録する
export const createEstimateProductInfo = async (createEstimateData) => {
  let ret = [];
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'EstimateProduct';
    const otherParam = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        esProductInfo: createEstimateData.estimateProducts,
        esIq3Info: createEstimateData.esIq3Info,
        epCustomCostInfo: createEstimateData.epCustomCosts,
        epCustomLabelInfo: createEstimateData.epCustomLabels,
        epCustomMemoInfo: createEstimateData.epCustomMemos,
        epCustomDateInfo: createEstimateData.epCustomDates,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          ret = data?.data[0];
          complete('データ保存は正常に完了しました。');
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage()[data?.messageCode]);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            error('データ保存に失敗しました。');
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return ret;
};

// 親部品見積情報をDBに更新する
export const updateEstimateProductInfo = async (updateEstimateData) => {
  let ret = [];
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'EstimateProduct';
    const otherParam = {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        esProductInfo: updateEstimateData.estimateProducts,
        esIq3Info: updateEstimateData.esIq3Info,
        epCustomCostInfo: updateEstimateData.epCustomCosts,
        epCustomLabelInfo: updateEstimateData.epCustomLabels,
        epCustomMemoInfo: updateEstimateData.epCustomMemos,
        epCustomDateInfo: updateEstimateData.epCustomDates,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          ret = data?.data[0];
          complete('データ更新は正常に完了しました。');
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage()[data?.messageCode]);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          if (data?.messageCode) {
            error(ErrorMessage()[data?.messageCode]);
          } else {
            error('データ更新に失敗しました。');
          }
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return ret;
};

// 工程パターンマスタ情報をDBに新規登録する
export const createProcessPatternData = async (createData) => {
  let ret = [];
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'ProcessPattern';
    const otherParam = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify(createData),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          ret = data?.data[0];
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage()[data?.messageCode]);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          error(ErrorMessage()[data?.messageCode]);
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return ret;
};

// 工程パターンマスタ情報をDBに更新する
export const updateProcessPatternData = async (updateData) => {
  let ret = [];
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'ProcessPattern';
    const otherParam = {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        id: updateData.id,
        name: updateData.name,
        details: updateData.details,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          ret = data?.data[0];
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage()[data?.messageCode]);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          error(ErrorMessage()[data?.messageCode]);
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return ret;
};

// ユーザー情報の取得
export const getUserInfo = async () => {
  let userData = [];
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'User';
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };
    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          userData = data?.data;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage()[data?.messageCode]);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          error(ErrorMessage()[data?.messageCode]);
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return userData;
};

// ユーザー設定の情報を取得する
export const getExistUserSetting = async () => {
  let existUserSetting = [];
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'UserSetting';
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          existUserSetting = data?.data;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          error(ErrorMessage()[data?.messageCode]);
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return existUserSetting;
};

export const getFileList = async () => {
  var transformedFilesOption = [];
  try {
    const apiUrl = `${process.env.REACT_APP_BACKEND_API_URL}CompanySetting/getFileList`;
    const accessToken = getAccessToken();
    const response = await axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    });

    if (response?.data && response?.status === StatusCodes.OK) {
      var uploadedFiles = response?.data;
      if (uploadedFiles?.length > 0) {
        transformedFilesOption = uploadedFiles?.map((fileName, index) => ({
          id: index + 1,
          value: uploadedFiles[index],
          label: uploadedFiles[index],
          fileId: uploadedFiles[index]?.replace(/\.[^/.]+$/, ''), // Removes file extension
        }));
      } else {
        transformedFilesOption.push(
          uploadedFiles?.map((fileName, index) => ({
            id: index + 1,
            value: '',
            label: '',
            fileId: '',
          }))
        );
      }
      return transformedFilesOption;
    } else if (
      response?.data &&
      (response?.status === StatusCodes.UNAUTHORIZED || response?.status === StatusCodes.CONFLICT)
    ) {
      authorizeError(ErrorMessage().E006);
      throw new Error('Unauthorized or Conflict'); // Throw an error to indicate unauthorized or conflict
    } else if (response?.data && response?.status !== StatusCodes.OK) {
      error(ErrorMessage()[response?.data?.messageCode]);
      throw new Error('Request failed with an error status'); // Throw an error for other error statuses
    }
  } catch (internal_err) {
    error(internal_err.message);
    throw internal_err; // Re-throw the error to propagate it to the caller
  }
};

//設定済み会社データ取得
export const getExistCompanySetting = async () => {
  let existCompanySetting = [];
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'CompanySetting';
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };
    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          existCompanySetting = data?.data;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          error(ErrorMessage()[data?.messageCode]);
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return existCompanySetting;
};

//個別帳票とリスト帳票分別
export const getExcelDatabyUseType = (existData) => {
  if (existData?.length > 0) {
    const dataByUseType0 = [];
    const dataByUseType1 = [];

    existData?.forEach((item) => {
      if (item.useType === 0) {
        dataByUseType0.push(item);
      } else if (item.useType === 1) {
        dataByUseType1.push(item);
      }
    });

    return {
      0: dataByUseType0,
      1: dataByUseType1,
    };
  }

  return {
    useType0: [],
    useType1: [],
  };
};

//Severからformatファイル取得
export const getS3FileContent = async (fileId) => {
  let stream;
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'CompanySetting/getS3FileContent';
    const otherParam = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        companySettingType: '0',
        Langs: {},
        Envs: {},
        InitNames: {},
        ExcelFormats: [],
        CSVFormats: {},
        PrintOutputs: [],
        CSVOutputs: {},
        settingInfo: { isUsed: true, remarks: 'testput', sortNum: 0 },
        downloadFormatId: fileId,
      }),
    };

    const response = await fetch(Url, otherParam);

    if (!response?.ok) {
      // Handle HTTP error responses
      if (response?.status === StatusCodes.UNAUTHORIZED || response?.status === StatusCodes.CONFLICT) {
        authorizeError(ErrorMessage().E006);
      } else {
        error(ErrorMessage()[response?.messageCode]);
      }
      return;
    }

    const arrayBuffer = await response?.arrayBuffer();
    stream = new Uint8Array(arrayBuffer);

    if (response && response?.updateToken) {
      updateAccessToken(response?.updateToken);
    }
  } catch (e) {
    error(e.message);
    return;
  }
  return stream;
};

// 見積情報の新旧パラを判断する
export const getIsEstimateNewParam = async (estimateData) => {
  let isNewParam = false;
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'EstimateProduct/GetIsEstimateNewParam';
    const otherParam = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
      body: JSON.stringify({
        esProductInfo: estimateData.estimateProducts,
        esIq3Info: estimateData.esIq3Info,
        epCustomCostInfo: estimateData.epCustomCosts,
        epCustomLabelInfo: estimateData.epCustomLabels,
        epCustomMemoInfo: estimateData.epCustomMemos,
        epCustomDateInfo: estimateData.epCustomDates,
      }),
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          isNewParam = data?.isNewParam;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          error(ErrorMessage()[data?.messageCode]);
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return isNewParam;
};

// 板金部品の見積もり情報をDBから取得する
export const getEstimateIq3Info = async (estimateProductId) => {
  let esIq3Data = [];
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'EstimateIq3/' + estimateProductId;
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          esIq3Data = data?.data;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          error(ErrorMessage()[data?.messageCode]);
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return esIq3Data;
};

// お客様情報の取得
export const GetParametersByCompId = async () => {
  let parameters = [];
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'EstimateProduct/GetParametersByCompId';
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };
    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          parameters = data;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage()[data?.messageCode]);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          error(ErrorMessage()[data?.messageCode]);
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return parameters;
};

// Card情報をDBから取得する
export const getCardInfo = async () => {
  let cardData = [];
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'Card';
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };

    await fetch(Url, otherParam)
      .then(async (response) => {
        return await response?.json();
      })
      .then((data) => {
        if (data && data?.updateToken) {
          // セクションにトークン情報を更新する
          updateAccessToken(data?.updateToken);
        }
        if (data && data?.statusCode === StatusCodes.OK) {
          cardData = data?.data;
        } else if (
          data &&
          (data?.statusCode === StatusCodes.UNAUTHORIZED || data?.statusCode === StatusCodes.CONFLICT)
        ) {
          authorizeError(ErrorMessage().E006);
          return;
        } else if (data && data?.statusCode !== StatusCodes.OK) {
          error(ErrorMessage()[data?.messageCode]);
          return;
        }
      })
      .catch((e) => {
        authorizeError(ErrorMessage().E010);
        return;
      });
  } catch (e) {
    error(e.message);
    return;
  }
  return cardData;
};

// Card画像を取得する
export const getCardDetailImg = async (cadDataDetailId) => {
  let imgData;
  try {
    const Url = process.env.REACT_APP_BACKEND_API_URL + 'Cad2d/CadData/Thumb/' + cadDataDetailId;
    const otherParam = {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + getAccessToken(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
      },
    };

    const response = await fetch(Url, otherParam);

    if (!response?.ok) {
      // Handle HTTP error responses
      if (response?.status === StatusCodes.UNAUTHORIZED || response?.status === StatusCodes.CONFLICT) {
        authorizeError(ErrorMessage().E006);
      } else {
        // error(ErrorMessage()[response?.messageCode]);
      }
      return;
    }

    imgData = response?.blob();

    if (response && response?.updateToken) {
      updateAccessToken(response?.updateToken);
    }
  } catch (e) {
    error(e.message);
    return;
  }
  return imgData;
};
