import React, { useImperativeHandle, forwardRef, useState, useEffect, useRef } from 'react';
import {
  Table,
  Form,
  Button,
  Row,
  Input,
  Col,
  Modal,
  Select,
  Checkbox,
  Divider,
  Space,
  Image,
  Tooltip,
  Menu,
  Upload,
  Radio,
  Collapse,
  Tabs,
  Card,
  Carousel,
} from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import SplitPane, { Pane } from 'split-pane-react';
import { CloseOutlined, CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { Route, Outlet, Routes, useLocation, useNavigate } from 'react-router-dom';

// Content resize
import '../../assets/styles/common.css';
import 'split-pane-react/esm/themes/default.css';
import WithRouter from '../../components/WithRouter';
import calculator from '../../assets/images/calculator.png';
import confirm_icon from '../../assets/images/confirm_icon.png';
import reload from '../../assets/images/reload_icon.png';
import { formatDate, toDecimal, JPYs } from '../common/Common';
import { ParamCalType } from '../../views/common/enums.js';
import { amtEmptyStr } from '../common/Constant';
import { commonModal, confirmDisplayOldNewParamContent } from '../common/CommonModal';
import { getIsEstimateNewParam } from '../common/CommonAPI';

const { Panel } = Collapse;
dayjs.extend(customParseFormat);
const dateFormat = 'YYYY/MM/DD';
const iconWidth = 30;
const now = new Date();
const date = `${now.getFullYear()}/${now.getMonth() + 1}/${now.getDate()}`;
const { Search } = Input;
const materialNm = ['02_SPHC（黒皮）', '03_SPHC（酸洗）'];
const thickness = ['9.0', '0.5'];
const data = [];

const EstimateBasicInfo = forwardRef((props, ref) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const [estimateBasicInfo, setEstimateBasicInfo] = useState([]);
  const [costPrice, setCostPrice] = useState([]);
  const [costPriceTotal, setCostPriceTotal] = useState([]);
  const [isReCalOldNewParam, setIsReCalOldNewParam] = useState(false);
  const { TextArea } = Input;

  useEffect(() => {
    if (estimateBasicInfo == undefined || estimateBasicInfo?.length == 0) {
      setEstimateBasicInfo(props.estimateBasicInfo == undefined ? [] : props.estimateBasicInfo?.estimateProducts);
      //費用合計
      let costPrice = 0;
      let costPriceTotal = 0;
      let epCustomCosts = props.estimateBasicInfo?.epCustomCosts;
      let quantity = props.estimateBasicInfo?.estimateProducts?.quantity;
      for (let i = 0; i < epCustomCosts?.length; i++) {
        if (epCustomCosts[i].isUseUnit) {
          costPrice += epCustomCosts[i].unitPrice;
        } else {
          costPrice += epCustomCosts[i].totalPrice;
        }
        costPriceTotal += costPrice * quantity;
      }
      setCostPrice(costPrice);
      setCostPriceTotal(costPriceTotal);
    }
  }, [props.estimateBasicInfo, props.activePartMenu]);

  const updateEstimateInfo = (estimateInfo) => {
    //費用合計
    let costPrice = 0;
    let costPriceTotal = 0;
    let epCustomCosts = estimateInfo?.epCustomCosts;
    let quantity = estimateInfo?.estimateProducts?.quantity;
    for (let i = 0; i < epCustomCosts?.length; i++) {
      if (epCustomCosts[i].isUseUnit) {
        costPrice += epCustomCosts[i].unitPrice;
      } else {
        costPrice += epCustomCosts[i].totalPrice;
      }
      costPriceTotal += costPrice * quantity;
    }
    setCostPrice(costPrice);
    setCostPriceTotal(costPriceTotal);
    setEstimateBasicInfo(estimateInfo?.estimateProducts);
  };

  const onChange = () => {};

  useImperativeHandle(ref, () => ({
    updateEstimateInfo: updateEstimateInfo,
  }));

  // 再計算ボタン押下
  const onClickReCalEstimate = async (e) => {
    //新規作成の場合
    if (estimateBasicInfo?.id == 0) {
      props.settingParametersByReCal(ParamCalType.NewParam);
    } else {
      let isNewParam = await getIsEstimateNewParam(props.estimateBasicInfo);
      // 現在のパラメータ情報に更新がある場合
      if (isNewParam) {
        setIsReCalOldNewParam(true);
      } else {
        props.settingParametersByReCal(ParamCalType.OldParam);
      }
    }
  };

  // 再計算しない
  const discardConfirmCancel = () => {
    setIsReCalOldNewParam(false);
  };

  // 新パラで再計算
  const calculateWithNewParam = () => {
    setIsReCalOldNewParam(false);
    props.settingParametersByReCal(ParamCalType.NewParam);
  };

  // 旧パラで再計算
  const calculateWithOldParam = () => {
    setIsReCalOldNewParam(false);
    props.settingParametersByReCal(ParamCalType.OldParam);
  };

  /* 画面切替警告メッセージのタイトル */
  const confirmScreenChangeTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
        display: 'flex',
      }}
    >
      <Image preview={false} width={20} src={confirm_icon} style={{ marginTop: 9, marginLeft: 7 }}></Image>
      <p style={{ paddingTop: 8, paddingLeft: 10, color: 'white', margin: 0 }}>確認</p>
    </div>
  );

  return (
    <>
      {/* 見積基本情報 */}
      <div
        style={{
          backgroundColor: '#fafafc',
          padding: 5,
        }}
      >
        {/** 基本情報 */}
        <Card hoverable={false}>
          <Row align="middle">
            <Col span={5}>
              <Tooltip title="再計算" placement="top" overlayClassName="tooltip-text">
                <a onClick={onClickReCalEstimate}>
                  <Image preview={false} src={reload} width={47} style={{ marginLeft: '7px', marginTop: 0 }}></Image>
                </a>
              </Tooltip>
            </Col>
            <Col span={19}>
              <p style={{ margin: 0, fontSize: '17px', paddingLeft: 50 }}>
                {estimateBasicInfo == undefined ? '' : estimateBasicInfo?.drawingNo}
              </p>
              <p style={{ margin: 0, fontSize: '16px', paddingLeft: 53 }}>
                {estimateBasicInfo == undefined ? '' : estimateBasicInfo?.name}
              </p>
            </Col>
          </Row>
          {/* <Row>
            <Col span={24} style={{ textAlign: 'center' }}>
              <p style={{ margin: 0, fontSize: '16px' }}>
                {estimateBasicInfo == undefined ? '' : estimateBasicInfo?.estimateNm}
              </p>
            </Col>
          </Row> */}
          <Row
            style={{
              background: '#364d79',
              marginTop: '5px',
              borderRadius: '3px',
              padding: '3px',
            }}
          >
            <Col span={24} style={{ textAlign: 'center' }}>
              <p style={{ margin: 0, fontSize: '12px' }}>{estimateBasicInfo?.clientName}</p>
            </Col>
          </Row>
          {/** 総重量*/}
          <Row style={{ marginTop: '5px' }}>
            <Col span={8}>
              <div style={{ background: '#364d79', borderRadius: '3px', padding: '3px' }}>
                <Row>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '12px' }}>総重量</p>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '12px' }}>
                      {estimateBasicInfo?.totalWeight != undefined
                        ? Math.floor(estimateBasicInfo?.totalWeight).toFixed(2) + 'kg'
                        : '0.00kg'}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col span={8}>
              <div style={{ background: '#364d79', borderRadius: '3px', padding: '3px', marginLeft: '5px' }}>
                <Row>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '12px' }}>総表面積</p>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '12px' }}>
                      {estimateBasicInfo?.totalSurfaceArea != undefined
                        ? Math.floor(estimateBasicInfo?.totalSurfaceArea).toFixed(2) + 'c㎡'
                        : '0.00c㎡'}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col span={8}>
              <div style={{ background: '#364d79', borderRadius: '3px', padding: '3px', marginLeft: '5px' }}>
                <Row>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '12px' }}>体積</p>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '12px' }}>
                      {estimateBasicInfo?.volume != undefined
                        ? Math.floor(estimateBasicInfo?.volume).toFixed(2) + 'c㎡'
                        : '0.00c㎡'}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          {/** 作成者*/}
          <Row style={{ marginTop: '5px' }}>
            <Col span={8}>
              <div style={{ background: '#364d79', borderRadius: '3px', padding: '3px' }}>
                <Row>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '12px' }}>作成者</p>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '12px' }}>{estimateBasicInfo?.creater}</p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col span={8}>
              <div style={{ background: '#364d79', borderRadius: '3px', padding: '3px', marginLeft: '5px' }}>
                <Row>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '12px' }}>作成日</p>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '12px' }}>{formatDate(estimateBasicInfo?.created)}</p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col span={8}>
              <div style={{ background: '#364d79', borderRadius: '3px', padding: '3px', marginLeft: '5px' }}>
                <Row>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '12px' }}>最終更新日</p>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '12px' }}>{formatDate(estimateBasicInfo?.modified)}</p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Card>
        {/** 見積／原価／粗利・粗利率 */}
        <Card hoverable={false} style={{ marginTop: 3 }}>
          <Row>
            <Col span={24} style={{ textAlign: 'center' }}>
              <p style={{ margin: 0, fontSize: '16px', fontWeight: '600' }}>
                {estimateBasicInfo?.quantity == undefined ? ' 数量　0' : ' 数量　' + estimateBasicInfo?.quantity}
              </p>
            </Col>
          </Row>
          <Row style={{ marginTop: '5px' }}>
            <Col span={12}>
              <div style={{ background: '#364d79', borderRadius: '3px', padding: '5px' }}>
                <Row style={{ marginTop: '3px' }}>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '12px' }}>原価</p>
                  </Col>
                </Row>
                <Row style={{ marginTop: '5px' }}>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '18px', fontWeight: '600' }}>
                      {estimateBasicInfo?.costUnitPrice == undefined
                        ? amtEmptyStr
                        : JPYs.format(Math.floor(estimateBasicInfo?.costUnitPrice))}
                    </p>
                  </Col>
                </Row>
                <Row style={{ marginTop: '5px' }}>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '18px', fontWeight: '600' }}>
                      {estimateBasicInfo?.costTotalPrice == undefined
                        ? amtEmptyStr
                        : JPYs.format(Math.floor(estimateBasicInfo?.costTotalPrice))}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col span={12}>
              <div style={{ background: '#364d79', borderRadius: '3px', padding: '5px', marginLeft: '10px' }}>
                <Row style={{ marginTop: '3px' }}>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '12px' }}>見積</p>
                  </Col>
                </Row>
                <Row style={{ marginTop: '5px' }}>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '18px', fontWeight: '600' }}>
                      {estimateBasicInfo?.estimateUnitPrice == undefined
                        ? amtEmptyStr
                        : JPYs.format(Math.floor(estimateBasicInfo?.estimateUnitPrice))}
                    </p>
                  </Col>
                </Row>
                <Row style={{ marginTop: '5px' }}>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '18px', fontWeight: '600' }}>
                      {estimateBasicInfo?.estimateTotalPrice == undefined
                        ? amtEmptyStr
                        : JPYs.format(Math.floor(estimateBasicInfo?.estimateTotalPrice))}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: '5px' }}>
            <Col span={12}>
              <div style={{ background: '#364d79', borderRadius: '3px', padding: '5px' }}>
                <Row style={{ marginTop: '3px' }}>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '12px' }}>粗利</p>
                  </Col>
                </Row>
                <Row style={{ marginTop: '5px' }}>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '18px', fontWeight: '600' }}>
                      {estimateBasicInfo?.grossUnitPrice == undefined
                        ? amtEmptyStr
                        : JPYs.format(Math.floor(estimateBasicInfo?.grossUnitPrice))}
                    </p>
                  </Col>
                </Row>
                <Row style={{ marginTop: '5px' }}>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '18px', fontWeight: '600' }}>
                      {estimateBasicInfo?.grossTotalPrice == undefined
                        ? amtEmptyStr
                        : JPYs.format(Math.floor(estimateBasicInfo?.grossTotalPrice))}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col span={12}>
              <div
                style={{
                  height: '98px',
                  background: '#364d79',
                  borderRadius: '3px',
                  padding: '5px',
                  marginLeft: '10px',
                }}
              >
                <Row style={{ marginTop: '3px' }}>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '12px' }}>粗利率</p>
                  </Col>
                </Row>
                <Row style={{ marginTop: '7px' }}>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <p style={{ margin: 0, fontSize: '25px', fontWeight: '600', color: 'white' }}>
                      {estimateBasicInfo?.grossRate != undefined ? estimateBasicInfo?.grossRate + '%' : '0.00%'}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Card>
        {/** 原価詳細 */}
        <Card hoverable={false} style={{ marginTop: 3 }}>
          <Carousel afterChange={onChange} style={{ color: 'white' }}>
            <div>
              <Row>
                <Col span={7}>
                  <div style={{ background: '#364d79', borderRadius: '3px', padding: '10px' }}>
                    <Row>
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <p style={{ margin: 0, fontSize: '12px' }}>材料原価</p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <p style={{ margin: 0, fontSize: '12px' }}>
                          {estimateBasicInfo?.materialCostUnitPrice == undefined
                            ? amtEmptyStr
                            : JPYs.format(Math.floor(estimateBasicInfo?.materialCostUnitPrice)) + '-'}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <p style={{ margin: 0, fontSize: '12px' }}>
                          {estimateBasicInfo?.materialCostTotalPrice == undefined
                            ? amtEmptyStr
                            : JPYs.format(Math.floor(estimateBasicInfo?.materialCostTotalPrice)) + '-'}
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col span={7}>
                  <div style={{ background: '#364d79', borderRadius: '3px', padding: '10px', marginLeft: '5px' }}>
                    <Row>
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <p style={{ margin: 0, fontSize: '12px' }}>製造原価</p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <p style={{ margin: 0, fontSize: '12px' }}>
                          {estimateBasicInfo?.manufacturingCostTotalPrice == undefined
                            ? amtEmptyStr
                            : JPYs.format(Math.floor(estimateBasicInfo?.manufacturingCostTotalPrice)) + '-'}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <p style={{ margin: 0, fontSize: '12px' }}>
                          {estimateBasicInfo?.manufacturingCostTotalPrice == undefined
                            ? amtEmptyStr
                            : JPYs.format(
                                Math.floor(estimateBasicInfo?.manufacturingCostTotalPrice * estimateBasicInfo?.quantity)
                              ) + '-'}
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col span={7}>
                  <div style={{ background: '#364d79', borderRadius: '3px', padding: '10px', marginLeft: '5px' }}>
                    <Row>
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <p style={{ margin: 0, fontSize: '12px' }}>購入品</p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <p style={{ margin: 0, fontSize: '12px' }}>
                          {estimateBasicInfo?.purchaseUnitPrice == undefined
                            ? amtEmptyStr
                            : JPYs.format(Math.floor(estimateBasicInfo?.purchaseUnitPrice)) + '-'}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <p style={{ margin: 0, fontSize: '12px' }}>
                          {estimateBasicInfo?.purchaseTotalPrice == undefined
                            ? amtEmptyStr
                            : JPYs.format(Math.floor(estimateBasicInfo?.purchaseTotalPrice)) + '-'}
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col span={7}>
                  <div style={{ background: '#364d79', borderRadius: '3px', padding: '10px' }}>
                    <Row>
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <p style={{ margin: 0, fontSize: '12px' }}>費用</p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <p style={{ margin: 0, fontSize: '12px' }}>
                          {costPrice == undefined ? amtEmptyStr : JPYs.format(Math.floor(costPrice)) + '-'}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <p style={{ margin: 0, fontSize: '12px' }}>
                          {costPriceTotal == undefined ? amtEmptyStr : JPYs.format(Math.floor(costPriceTotal)) + '-'}
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col span={7}>
                  <div style={{ background: '#364d79', borderRadius: '3px', padding: '10px', marginLeft: '5px' }}>
                    <Row>
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <p style={{ margin: 0, fontSize: '12px' }}>管理費</p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <p style={{ margin: 0, fontSize: '12px' }}>
                          {estimateBasicInfo?.managementFeeTotalPrice == undefined
                            ? amtEmptyStr
                            : JPYs.format(
                                Math.floor(estimateBasicInfo?.managementFeeTotalPrice / estimateBasicInfo?.quantity)
                              ) + '-'}
                        </p>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <p style={{ margin: 0, fontSize: '12px' }}>
                          {estimateBasicInfo?.managementFeeTotalPrice == undefined
                            ? amtEmptyStr
                            : JPYs.format(Math.floor(estimateBasicInfo?.managementFeeTotalPrice)) + '-'}
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col span={7}></Col>
              </Row>
            </div>
          </Carousel>
        </Card>
        {/** 係数 */}
        <Card hoverable={false} style={{ marginTop: 3 }}>
          <Row>
            <Col span={12}>
              <div style={{ background: '#364d79', borderRadius: '3px', padding: '3px' }}>
                <Row>
                  <Col span={14}>
                    <p style={{ margin: 0, fontSize: '12px', paddingTop: '4px', paddingLeft: '10px' }}>取引先係数</p>
                  </Col>
                  <Col span={10} style={{ textAlign: 'right' }}>
                    <p style={{ margin: 0, fontSize: '16px', color: 'white', fontWeight: '600', paddingRight: '10px' }}>
                      {toDecimal(estimateBasicInfo?.otherProduct?.coeffClient, 2)}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col span={12}>
              <div
                style={{
                  background: '#364d79',
                  borderRadius: '3px',
                  padding: '3px',
                  marginLeft: '5px',
                }}
              >
                <Row>
                  <Col span={14}>
                    <p style={{ margin: 0, fontSize: '12px', paddingTop: '4px', paddingLeft: '10px' }}>受注形態</p>
                  </Col>
                  <Col span={10} style={{ textAlign: 'right' }}>
                    <p style={{ margin: 0, fontSize: '16px', color: 'white', fontWeight: '600', paddingRight: '10px' }}>
                      {toDecimal(estimateBasicInfo?.otherProduct?.coeffOrders, 2)}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: '5px' }}>
            <Col span={12}>
              <div style={{ background: '#364d79', borderRadius: '3px', padding: '3px' }}>
                <Row>
                  <Col span={14}>
                    <p style={{ margin: 0, fontSize: '12px', paddingTop: '4px', paddingLeft: '10px' }}>管理費係数</p>
                  </Col>
                  <Col span={10} style={{ textAlign: 'right' }}>
                    <p style={{ margin: 0, fontSize: '16px', color: 'white', fontWeight: '600', paddingRight: '10px' }}>
                      {toDecimal(estimateBasicInfo?.otherProduct?.coeffManagements, 2)}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col span={12}>
              <div
                style={{
                  background: '#364d79',
                  borderRadius: '3px',
                  padding: '3px',
                  marginLeft: '5px',
                }}
              >
                <Row>
                  <Col span={14}>
                    <p style={{ margin: 0, fontSize: '12px', paddingTop: '4px', paddingLeft: '10px' }}>納期係数</p>
                  </Col>
                  <Col span={10} style={{ textAlign: 'right' }}>
                    <p style={{ margin: 0, fontSize: '16px', color: 'white', fontWeight: '600', paddingRight: '10px' }}>
                      {toDecimal(estimateBasicInfo?.otherProduct?.coeffDeliveryDates, 2)}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: '5px' }}>
            <Col span={24}>
              <div style={{ width: '50%', background: '#364d79', borderRadius: '3px', padding: '3px' }}>
                <Row>
                  <Col span={14}>
                    <p style={{ margin: 0, fontSize: '12px', paddingTop: '4px', paddingLeft: '10px' }}>購入品係数</p>
                  </Col>
                  <Col span={10} style={{ textAlign: 'right' }}>
                    <p style={{ margin: 0, fontSize: '16px', color: 'white', fontWeight: '600', paddingRight: '10px' }}>
                      {toDecimal(estimateBasicInfo?.otherProduct?.coeffPurchases, 2)}
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Card>
        {/** コメント */}
        <Card hoverable={false} style={{ marginTop: 3 }}>
          <Row>
            <Col span={24}>
              <p style={{ margin: 0, fontSize: '12px' }}>コメント</p>
            </Col>
          </Row>
          <Row style={{ marginTop: '5px' }}>
            <Col span={24}>
              {/* <p style={{ margin: 0, fontSize: '12px' }}>{estimateBasicInfo?.comment}</p> */}
              <TextArea
                style={{
                  verticalAlign: 'Top',
                  fontSize: '12px',
                  height: '140px',
                  background: '#364d79',
                  borderRadius: '5px',
                  padding: '10px',
                  color: 'white',
                }}
                className={'input-non-editable'}
                name="comment"
                id="comment"
                value={estimateBasicInfo?.comment}
                type="text"
                rows={5}
              />
            </Col>
          </Row>
        </Card>

        {/** 新旧パラで計算のメッセージ表示 */}
        {isReCalOldNewParam
          ? commonModal(
              isReCalOldNewParam,
              confirmScreenChangeTitle,
              null,
              null,
              400,
              calculateWithNewParam,
              discardConfirmCancel,
              confirmDisplayOldNewParamContent(calculateWithNewParam, calculateWithOldParam, discardConfirmCancel),
              null,
              null,
              false
            )
          : ''}
      </div>
    </>
  );
});

export default EstimateBasicInfo;
