import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef } from 'react';

import { patternPage, updateEstimateInfoByReCal } from '../common/Common';
import ProcessPatternForm from '../parameter/parent/processPattern/ProcessPatternForm';
import { getDeviceInfo, getProcessInfo, getProcessPatternInfo } from '../common/CommonAPI';
import { ProcessDetailGroup, ServiceClass, WorkType } from '../common/enums';

const ParentKouteiSentaku = forwardRef((props, ref) => {
  const [listData, setListData] = useState([]);
  const [processSelectedData, setProcessSelectedData] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [kouteiSentakuPatternNo, setKouteiSentakuPatternNo] = useState(0);
  const [kouteiSentakuPatternList, setKouteiSentakuPatternList] = useState([]);
  const [deviceMasterInfo, setDeviceMasterInfo] = useState([]);
  const [processMasterInfo, setProcessMasterInfo] = useState([]);
  const [clientInfo, setClientInfo] = useState([]);

  const patternformRef = useRef();
  const currentRoute = location.pathname;

  useEffect(() => {
    getProcesssPatternInfo();
  }, [props.activeTabKey, props.selectedDataDetail]);

  useEffect(() => {
    setEditMode(props.editMode != undefined ? props.editMode : false);
  }, [props.editMode]);

  const getProcesssPatternInfo = () => {
    let kouteiPatternList = [];
    let parameters = props.selectedDataDetail?.estimateProducts?.calcParameters?.parameters;
    // 工程マスタデータ
    const patternLst = parameters?.processPattern;
    kouteiPatternList = patternLst?.filter((item) => item.class === ServiceClass.Parent);

    setListData(kouteiPatternList);
    setKouteiSentakuPatternList(kouteiPatternList);
    let processSelect = props?.selectedDataDetail?.estimateProducts?.processSelect;
    setProcessSelectedData(processSelect);
    setKouteiSentakuPatternNo(processSelect?.id);
    setDeviceMasterInfo(parameters?.device);
    setProcessMasterInfo(parameters?.process);
    setClientInfo(parameters?.client);
  };

  const updateKouteiSentakuInfo = (selectedProcessPattern, list) => {
    setKouteiSentakuPatternNo(selectedProcessPattern.id);
    setProcessSelectedData(selectedProcessPattern);
    setKouteiSentakuPatternList(list);
    props.selectedDataDetail.estimateProducts.processSelect = selectedProcessPattern;
    if (props?.type === 'pmx') {
      props.updateSelectedParentPatternNo(selectedProcessPattern?.id);
    }

    // props.updatePatternList(updatePatternList)
  };

  useImperativeHandle(
    ref,
    () => ({
      getKouteiSentakuPatternNo: () => {
        return kouteiSentakuPatternNo;
      },
      getProcessSelectedData: () => {
        return processSelectedData;
      },
      getKouteiSentakuPatternList: () => {
        return kouteiSentakuPatternList;
      },
    }),
    [kouteiSentakuPatternNo, processSelectedData, kouteiSentakuPatternList]
  );

  return (
    <>
      <ProcessPatternForm
        ref={patternformRef}
        selectedData={processSelectedData}
        listData={listData}
        editMode={editMode}
        page={patternPage[0]}
        isParameter={false}
        updateKouteiSentakuInfo={updateKouteiSentakuInfo}
        kouteiSentakuEditMode={editMode}
        pmxMode={props.pmxMode}
        processMasterData={processMasterInfo}
        deviceMasterData={deviceMasterInfo}
        clientMasterData={clientInfo}
      />
    </>
  );
});

export default ParentKouteiSentaku;
