/**
 * クラス名：装置設定詳細画面
 * 作成者：チュー
 * 作成日：2023/06/01
 * バージョン：1.0
 */

import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { Form, Col, Input, Row, Button, Checkbox, Space } from 'antd';
import { Table as RTable } from 'react-bootstrap';
import {
  CaretUpOutlined,
  CaretDownOutlined,
  SearchOutlined,
  DownSquareFilled,
  UpSquareFilled,
} from '@ant-design/icons';
import CurrencyInput from 'react-currency-input-field';

import ChargeAmount from './ChargeAmount';
import SetupSetting from './SetupSetting';
import MachineSpeed from './MachineSpeed';
import KaritsukiTime from './KaritsukiTime';
import TapWelding from './TapWelding';
import ProductSize from './ProductSize';
import Quantity from './Quantity';
import YousetsuDifficulty from './YousetsuDifficulty';
import Pitch from './Pitch';
import ChildPart from './ChildPart';
import WorkAccuracy from './WorkAccuracy';
import ToritsukiJikan_Itaatsu from './ToritsukiJikan_Itaatsu';
import ToritsukiJikan_Size from './ToritsukiJikan_Size';
import Device_Sagyoukeisuu from '../../iq3/devices/Device_Sagyoukeisuu';
import Device_DantoriJikan from '../../iq3/devices/Device_DantoriJikan';

import {
  souchiHandleIncreaseTime,
  souchiHandleDecreaseTime,
  JPYs,
  WorkTypes,
  processTypes,
  ServiceClasses,
  secondsToHms,
  setTimetoSec,
  handleKeyPress,
} from '../../../common/Common';
import { Customer } from '../../../common/Constant';
import { ClientType, ServiceClass, WorkType } from '../../../common/enums.js';
import SearchAndGetCustomerData from '../../../common/SearchAndGetCustomerData.js';
import {
  confirmModalTitle,
  confirmDiscardContent,
  confirmUpdateContent,
  commonModal,
  commonFooter,
} from '../../../common/CommonModal';
import Device_Bending from '../../iq3/devices/Device_Bending';
import Device_BendingTimeFactor from '../../iq3/devices/Device_BendingTimeFactor';
import Device_BendingCorrectionTime from '../../iq3/devices/Device_BendingCorrectionTime';
import Device_SetupSetting from '../../iq3/devices/Device_SetupSetting';
import Device_ProcessType from '../../iq3/devices/Device_ProcessType';
import Device_KakouSokudo from '../../iq3/devices/Device_KakouSokudo';
import Device_KakouJikan from '../../iq3/devices/Device_KakouJikan';
import Device_ManualBari from '../../iq3/devices/Device_ManualBari';
import Device_ProductSize from '../../iq3/devices/Device_ProductSize';
import Device_BendingCorrectionTimeFactor from '../../iq3/devices/Device_BendingCorrectionTimeFactor';
import Device_ProcessInspection from '../../iq3/devices/Device_ProcessInspection';
import Device_MaterialHandlingTime from '../../iq3/devices/Device_MaterialHandlingTime';
import Device_MaterialArrangement from '../../iq3/devices/Device_MaterialArrangement';
import Device_Blank_DrillingTime from '../../iq3/devices/Device_Blank_DrillingTime';
import Device_Blank_GasType from '../../iq3/devices/Device_Blank_GasType';
import Device_Blank_Joint from '../../iq3/devices/Device_Blank_Joint';
import Device_Blank_KakouSokudoKeisuu from '../../iq3/devices/Device_Blank_KakouSokudoKeisuu';
import Device_Blank_PiercingProcessTime from '../../iq3/devices/Device_Blank_PiercingProcessTime';
import Device_Blank_GasKeisuu from '../../iq3/devices/Device_Blank_GasKeisuu';
import Device_Blank_Kakousokudo from '../../iq3/devices/Device_Blank_Kakousokudo';
import Device_Blank_PunchCondition from '../../iq3/devices/Device_Blank_PunchCondition';
import Device_Blank_OkuriSokudo from '../../iq3/devices/Device_Blank_OkuriSokudo';
import Device_Blank_RollerSpeed from '../../iq3/devices/Device_Blank_RollerSpeed';
import Device_Blank_ExtractCondition from '../../iq3/devices/Device_Blank_ExtractCondition';
import Device_Blank_PunchProcessTime from '../../iq3/devices/Device_Blank_PunchProcessTime';
import ChargeDetails from './ChargeDetails';
import Device_YousetsuShiage_DifficultyFactor from '../../iq3/devices/Device_YousetsuShiage_DifficultyFactor';
import Device_ShiageFlag from '../../iq3/devices/Device_ShiageFlag';
import { getClientInfo } from '../../../common/CommonAPI';

const DevicesDetails = forwardRef((props, ref) => {
  const [isBaritoriShurui, setIsBaritoriShurui] = useState(false);
  const [isCharge, setIsCharge] = useState(false);
  const [isDantoriJikan, setIsDantoriJikan] = useState(false);
  const [isSetupSetting, setIsSetupSetting] = useState(false);
  const [isKouteiNaikensa, setIsKouteiNaikensa] = useState(false);
  const [isMagekakouJikan, setIsMagekakouJikan] = useState(false);
  const [isArrangeMaterial, setIsArrangeMaterial] = useState(false);
  const [isPunch, setIsPunch] = useState(false);
  const [isPiercingProcess, setIsPiercingProcess] = useState(false);
  const [isPunchProcess, setIsPunchProcess] = useState(false);
  const [isMageHoseiJikan, setIsMageHoseiJikan] = useState(false);
  const [isMageHoseiJikanFactor, setIsMageHoseiJikanFactor] = useState(false);
  const [isProcessSizeFactor, setIsProcessSizeFactor] = useState(false);
  const [isBendingTimeFactor, setIsBendingTimeFactor] = useState(false);
  const [isProcessSpeed, setIsProcessSpeed] = useState(false);
  const [isSagyouSokudo, setIsSagyouSokudo] = useState(false);
  const [isSagyouKeisuu, setIsSagyouKeisuu] = useState(false);
  const [isiq3DantoriJikan, setIsiq3DantoriJikan] = useState(false);
  const [isiq3KakouJikan, setIsiq3KakouJikan] = useState(false);
  const [isBlankKakouSokudo, setIsBlankKakouSokudo] = useState(false);
  const [isBlankKakouSokudoKeisuu, setIsBlankKakouSokudoKeisuu] = useState(false);
  const [isBlankGasuKeisuu, setIsBlankGasuKeisuu] = useState(false);
  const [isExtractCondition, setIsExtractCondition] = useState(false);
  const [isBlankGasuType, setIsBlankGasuType] = useState(false);
  const [isBlankDrillingTime, setIsBlankDrillingTime] = useState(false);
  const [isOkuriSokudo, setIsOkuriSokudo] = useState(false);
  const [isRollerKakouSokudo, setIsRollerKakouSokudo] = useState(false);
  const [isBlankJoint, setIsBlankJoint] = useState(false);
  const [isiq3KakouSokudo, setIsiq3KakouSokudo] = useState(false);
  const [isiq3ManualBari, setIsiq3ManualBari] = useState(false);
  const [isProcessTime, setIsProcessTime] = useState(false);
  const [isAttach, setIsAttach] = useState(false);
  const [isToritsukiJikanItaatsu, setIsToritsukiJikanItaatsu] = useState(false);
  const [isToritsukiJikanSize, setIsToritsukiJikanSize] = useState(false);
  const [isWelding, setIsWelding] = useState(false);
  const [isDeviceDetails, setIsDeviceDetails] = useState(false);
  const [isTapWelding, setIsTapWelding] = useState(false);
  const [isPitch, setIsPitch] = useState(false);
  const [isProductSize, setIsProductSize] = useState(false);
  const [isProductQuantity, setIsProductQuantity] = useState(false);
  const [isChildPart, setIsChildPart] = useState(false);
  const [isDifficultyFactor, setIsDifficultyFactor] = useState(false);
  const [isShiageFlag, setIsShiageFlag] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [updateConfirm, setUpdateConfirm] = useState(false);
  const [deviceType, setDeviceType] = useState(processTypes.standard);
  const [dantoriCharge, setDantoriChange] = useState('');
  const [detailsProcess, setDetailProcess] = useState([]);

  const [detailsData, setDetailsData] = useState([]);
  const initialValues = [8, 11, 13, 16, 17];
  // データ表示
  const [code, setCode] = useState('');
  const [processName, setProcessName] = useState('');
  const [workType, setWorkType] = useState();
  const [deviceCode, setDeviceCode] = useState('');
  const [deviceName, setDeviceName] = useState('');
  const [remarks, setRemarks] = useState('');
  // 追加工程種類情報
  const [kouteiName, setKouteiName] = useState('');
  const [kouteiCode, setKouteiCode] = useState('');
  const [customerId, setCustomerId] = useState();
  const [customerName, setCustomerName] = useState('');
  const [customerCode, setCustomerCode] = useState('');
  const [iniAmount, setIniAmount] = useState('');
  const [koubuhinnKeisuu, setKobuhinKeisuu] = useState('');
  const [tsuikaKouteiTypes, setTsuikaKouteiTypes] = useState([]);

  // 追加工程の段取金額と作業金額
  const [setupAmount, setSetupAmount] = useState('');
  const [workAmount, setWorkAmount] = useState('');
  // 段取時間
  const [dantoriTime, setDantoriTime] = useState('00:00:00');
  const [workTime, setWorkTime] = useState(0);
  const [sagyouTime, setSagyouTime] = useState(0); // 作業時間（x要素数）

  const [area, setArea] = useState([]);
  const [weight, setWeight] = useState([]);
  const [volume, setVolume] = useState([]);
  const [sizesDetailsList, setSizeDetailsList] = useState([]);
  const [dantoriSizeDetailsList, setDantoriSizeDetailsList] = useState([]);

  const [kihonDantori, setKihonDantori] = useState('');
  // 段取を個別に持つ
  const [dantoriFlag, setDantoriFlag] = useState('');
  const [selectedDeviceData, setSelectedDeviceData] = useState([]);
  // 名称
  const [meishou, setMeishou] = useState([]);
  const [meishouList, setMeishouList] = useState([]);
  // 数量区分
  const [suuryouData, setSuuryouData] = useState([]);
  const [suuryouList, setSuuryouList] = useState([]);
  const [dantoriSetteiSuuryouList, setdantoriSetteiSuuryouList] = useState([]);

  // 要素数
  const [yousosuuData, setYousosuuData] = useState([]);
  const [sagyouKeisuuYousosuuList, setSagyouKeisuuYousosuuList] = useState([]);
  const [shokiDankaiJikanList, setShokiDankaiJikanList] = useState([]);
  const [kensaJikanKeisuuList, setKensaJikanKeisuuList] = useState([]);

  const [jikanCharge, setJikanChaji] = useState([]);
  const [customerModal, setCustomerModal] = useState(false);

  const [initialCustomerData, setInitialCustomerData] = useState([]); // 初期表示に表示する項目
  const [initialCustomerDataBk, setInitialCustomerDataBk] = useState([]);
  const [selectedCustomerData, setSelectedCustomerData] = useState([]);
  const [selectedCustomerRowKeys, setSelectedCustomerRowKeys] = useState([]);

  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [selectDisabled, setSelectDisabled] = useState(true);

  const [flagChecked, setFlagChecked] = useState(false);
  const [inputDisabled, setInputDisabled] = useState(true);

  const [pitchItaatsuList, setPitchItaatsuList] = useState([]);

  const [tapWeldItaatsuList, setTapWeldItaatsuList] = useState([]);

  const [yousetsuNanidoItaatsuMasterData, setYousetsuNanidoItaatsuMasterData] = useState([]);
  const [yousetsuNanidoItaatsuList, setYousetsuNanidoItaatsuList] = useState([]);

  const [karitsukiTimeItaatsuMasterData, setKaritsukiTimeItaatsuMasterData] = useState([]);
  const [karitsukiTimeItaatsuList, setKaritsukiTimeItaatsuList] = useState([]);

  // 取付時間（サイズ、板厚）
  const [toritsukiTimeItaatsuMasterData, setToritsukiTimeItaatsuMasterData] = useState([]);
  const [toritsukiTimeItaatsuList, setToritsukiTimeItaatsuList] = useState([]);
  const [toritsukiTimeSizeMasterData, setToritsukiTimeSizeMasterData] = useState([]);
  const [toritsukiTimeSizeList, setToritsukiTimeSizeList] = useState([]);

  // 溶接線長
  const [detailsWeldLine, setWeldLine] = useState([]);
  const [yousetsuWeldLineList, setYousetsuWeldLineList] = useState([]);

  // 加工速度（溶接仕上線長）
  const [kakouSokudoSenchouMasterData, setKakouSokudoSenchouMasterData] = useState([]);
  const [kakouSokudoSenchouList, setKakouSokudoSenchouList] = useState([]);

  // 個数
  const [kosuu, setKosuu] = useState([]);
  const [kosuuList, setKousuuList] = useState([]);
  const [iq3KosuuList, setIQ3KosuuList] = useState([]);

  // iq3
  const [iq3SetupAmount, setiq3SetupAmount] = useState('');
  const [iq3WorkAmount, setiq3WorkAmount] = useState('');
  const [iq3ProcessInspection, setIQ3ProcessInspection] = useState('');
  const [secondProcessAmount, setSecondProcessAmount] = useState('');
  const [jointBarashiAmount, setJointBarashiAmount] = useState('');
  const [iq3ItaatsuMaster, setIQ3ItaatsuMaster] = useState([]);
  const [jointSizeMaster, setJointSizeMaster] = useState([]);
  const [iq3BendingTimeListData, setIQ3BendingTimeListData] = useState([]);
  const [gasTypeMaster, setGasTypeMaster] = useState([]);
  // 保有金型
  const [retainedMoldMaster, setRetainedMoldMaster] = useState([]);
  // ジョイントサイズ
  const [jointSizeList, setJointSizeList] = useState([]);
  // ガス種類
  const [gasTypeListData, setGasTypeListData] = useState([]);
  // ジョイント
  const [jointItaatsuListData, setJointItaatsuListData] = useState([]);
  // 加工速度係数
  const [blankKakouSokudoItaatsuList, setBlankKakouSokudoItaatsuList] = useState([]);
  // ガス係数
  const [gasKeisuuList, setGasKeisuuList] = useState([]);
  const [extractConditionList, setExtractConditionList] = useState([]);
  // 1ピアス加工時間
  const [piercingProcessItaatsuListData, setPiercingProcessItaatsuListData] = useState([]);
  // 難易度係数
  const [nanidoKeisuuList, setNanidoKeisuuList] = useState([]);
  // 1パンチ加工時間
  const [punchProcessItaatsuListData, setPunchProcessItaatsuListData] = useState([]);

  // 曲げ時間係数
  const [length, setLength] = useState([]);
  const [bendingTimeFactorListData, setBendingTimeFactorListData] = useState([]);
  const [bendingCorrectionTimeFactorListData, setBendingCorrectionTimeFactorListData] = useState([]);
  // 補正時間係数
  const [correctionTimeFactorListData, setCorrectionTimeFactorListData] = useState([]);
  const [iq3BendingHonsuuList, setiQ3BendingHonsuuList] = useState([]);
  const [iq3BendingKosuuList, setIQ3BendingKosuuList] = useState([]);
  // 曲げ数
  const [iq3MageSuuMaster, setIQ3MageSuuMaster] = useState([]);
  const [iq3MageSuuListData, setIQ3MageSuuListData] = useState([]);
  // 曲げ個数
  const [iq3MageKosuuMaster, setIQ3MageKosuuMaster] = useState([]);
  const [iq3MageKosuuListData, setIQ3MageKosuuListData] = useState([]);
  // 子部品サイズ係数リスト
  // const [dantoriJuuryouMagechouList, setDantoriJuuryouMagechouList] = useState([]);
  // 曲げ長さ別段取時間
  const [magechouDantoriJikanListData, setMagechouDantoriJikanListData] = useState([]);
  // 面積
  const [iq3AreaMaster, setIQ3AreaMaster] = useState([]);
  const [iq3KakouJikanListData, setIQ3KakouJikanListData] = useState([]);
  // ブランク加工速度
  const [blankKakousokudolistData, setBlankKakousokudolistData] = useState([]);

  const [types, setTypes] = useState('');
  // 加工方法
  const [processMethod, setProcessMethod] = useState('');
  const [processMethods, setProcessMethods] = useState([]);
  const [processCode, setProcessCode] = useState([]);
  // 生産個数
  const [seisanKosuu, setSeisanKosuu] = useState([]);
  const [seisanKosuuList, setSeisanKosuuList] = useState([]);
  // 工程内検査の生産個数
  const [processInspectionKosuuList, setProcessInspectionKosuuList] = useState([]);

  // 本数
  const [honSuu, setHonsuu] = useState([]);
  const [honSuuList, setHonSuuList] = useState([]);
  // サイズ
  const [iq3SizeMaster, setIQ3SizeMaster] = useState([]);
  const [mmMaster, setMmMaster] = useState([]);
  const [iq3KaniListData, setIQ3KaniListData] = useState([]);
  const [iq3KaniAnaListData, setIQ3KaniAnaListData] = useState([]);
  const [itaatsu1, setItaatsu1] = useState([]);
  const [zaishitsu, setZaishitsu] = useState([]);
  // 製品重量係数
  const [iq3ProductSizeListData, setiq3ProductSizeListData] = useState([]);

  // 一部品あたり作業時間
  const [processTimeByUnit, setProcessTimeByUnit] = useState('');

  // マテリアルハンドリング時間
  const [materialHandlingTimeList, setMaterialHandlingTimeList] = useState([]);
  const [isMaterialHandling, setIsMaterialHandling] = useState(false);

  // 同一サイズ、同一形状をパターン化する
  const [programSameSizePatternValue, setProgramSameSizePatternValue] = useState([]);

  // 工程内検査
  const [processInspectionTime, setProcessInspectionTime] = useState('');

  // パンチ条件
  const [punchConditionList, setPunchConditionList] = useState('');

  const [drillingTime, setDrillingTime] = useState('');
  // 送り速度
  // const [forwardSpeed, setForwardSpeed] = useState('');
  // ローラー/ホイール/ケガキ金型　加工速度
  const [rollerSpeed, setRollerSpeed] = useState('');

  // 送り速度
  const [okuriSokudoVal, setOkuriSokudoVal] = useState('');
  // ケガキ速度
  const [kegakiSokudoVal, setKegakiSokudoVal] = useState('');

  const [kakosokudo_bari, setkakosokudo_bari] = useState('');
  const [naikei_bari, setNaikeibari] = useState('');
  const [naikei_min, setNaikeiMin] = useState('');
  const [naikei_max, setNaikeiMax] = useState('');
  const [ana_min, setAnaMin] = useState('');
  const [ana_max, setAnaMax] = useState('');

  // One by one
  const [shirringflag, setShirringFlag] = useState(false);

  const [cutTime, setCutTime] = useState('00:00:00');

  // const [deviceTypes, setDeviceTypes] = useState('');

  // 手動バリ取りの加工速度
  const [iq3kakouchou, setIQ3Kakouchou] = useState([]);
  const [shudobariKakousokudoList, setShudoubariKakousokudoList] = useState([]);
  // チャージ金額の工程内検査
  const [kensaAmount, setKensaAmount] = useState('');

  // チャージ金額詳細
  const [isDetails, setIsDetails] = useState(false);
  // 溶接種類
  const [yousetsuTypes, setYousetsuTypes] = useState(false);
  const [shiageFlagList, setShiageFlagList] = useState([]);

  const [processMstRecord, setProcessMstRecord] = useState();

  // 工程マスタの種類リスト
  const [processTypesList, setProcessTypesList] = useState([]);

  // 材質区分データ取得
  const [materialTypes, setMaterialTypes] = useState([]);

  useEffect(() => {
    let selectedRowData = JSON.parse(JSON.stringify(props.selectedData));
    setEditMode(props.editMode != undefined ? props.editMode : false);
    setSelectedDeviceData(selectedRowData);
    setDetailsData(props.selectedData);
    let yousetsuMstRecord = props?.getProcessMstRecordByWorkType();
    // setYousetsuTypes(
    //   yousetsuMstRecord?.details.processDetailTypes?.filter((item) => item.group === 110 && item.isUsed === true)
    // );
    setYousetsuTypes(yousetsuMstRecord);

    let processMstRecord = props?.getProcessMstRecordById(selectedRowData?.processId);

    if (processMstRecord) setProcessMstRecord(processMstRecord);
    if (selectedRowData?.length != 0) {
      setMaterialTypes(props?.getMaterialTypes);

      // setDeviceTypes(selectedRowData.deviceTypes);
      // setkakosokudo_bari(selectedRowData.kakosokudo_bari);
      // setNaikeibari(selectedRowData.naikei_bari);
      // setNaikeiMin(selectedRowData.naikei_min);
      // setNaikeiMax(selectedRowData.naikei_max);
      // setAnaMin(selectedRowData.ana_min);
      // setAnaMin(selectedRowData.ana_min);
      // setAnaMax(selectedRowData.ana_max);
      // setShirringFlag(selectedRowData.shirringflag);
      // setCutTime(selectedRowData.shirringTime);
      // iq3
      // setIQ3BendingTimeListData(selectedRowData.iq3BendingTimeLstData);
      // setGasTypeListData(selectedRowData.gasTypeLstData);
      // setJointSizeList(selectedRowData.jointSizeListData);
      // setJointItaatsuListData(selectedRowData.jointItaatsuLstData);
      // setBlankKakouSokudoItaatsuList(selectedRowData.blankKakousokudoItaatsuList);
      // setPiercingProcessItaatsuListData(selectedRowData.piercingProcessItaatsuLstData);
      // setNanidoKeisuuList(selectedRowData.nanidoKeisuuLstData);
      // setPunchProcessItaatsuListData(selectedRowData.punchProcessItaatsuLstData);
      // setGasKeisuuList(selectedRowData.gasKeissuuLstData);
      // setExtractConditionList(selectedRowData.extractConditionList);
      // setIQ3ItaatsuMaster(selectedRowData.iq3ItaatsuMasterData);
      // setGasTypeMaster(selectedRowData.gasTypeMasterData);
      // setRetainedMoldMaster(selectedRowData.retrainedMoldMasterData);
      // setJointSizeMaster(selectedRowData.jointSizeMasterData);

      // 委託装置詳細
      setFlagChecked(selectedRowData?.details.isManualInput);
      setInputDisabled(!selectedRowData?.details.isManualInput);
      setCheckboxChecked(selectedRowData?.details.isCommission);
      // setCustomerName(selectedRowData?.details.clientName);
      // setCustomerCode(selectedRowData?.details.clientCode);
      if (selectedRowData?.details.clientId) {
        const customers = props?.getCustomers?.filter((item) => item.id === selectedRowData?.details.clientId);

        if (customers?.length > 0) {
          setCustomerCode(customers[0].code); // Access the code from the first item in the filtered array
          setCustomerName(customers[0].name);
        }
      } else {
        setCustomerCode('');
        setCustomerName('');
      }

      setIniAmount(selectedRowData?.details.initPrice);
      setSelectDisabled(!selectedRowData?.details.isCommission);

      // setKosuu(selectedRowData.kosuuMasterData);
      // setKousuuList(selectedRowData.kosuuLstData);

      // 装置の詳細情報
      setDetailProcess(selectedRowData?.details);

      // 作業時間（x要素数）
      setSagyouTime(selectedRowData?.details?.workBasicTime);

      // setDantoriChange(selectedRowData.processCharge);
      setDeviceType(processMstRecord?.isAdditional ? processTypes.additional : processTypes.standard);

      setCode(processMstRecord?.code);
      setProcessName(processMstRecord?.name);
      setWorkType(processMstRecord?.workType);
      setProcessTypesList(processMstRecord?.details.processDetailTypes);

      setDeviceCode(selectedRowData?.code);
      setDeviceName(selectedRowData?.name);
      setRemarks(selectedRowData?.info?.remarks);

      setTypes(processMstRecord?.class);

      // 追加工程種類情報
      let kouteiInfo = getAdditionalProcessInfo(processMstRecord, selectedRowData?.details?.addProcessTypeId);
      setKouteiName(kouteiInfo?.name);
      setKouteiCode(kouteiInfo?.code);

      // let worktime = secondsToHms(selectedRowData?.details.workBasicTime);

      // 1部品あたりの作業時間
      setWorkTime(selectedRowData?.details.workBasicTime);
      // 溶接線長
      setWeldLine(selectedRowData?.details.workSpeedThMaster);
      // setYousetsuWeldLineList(selectedRowData?.details.workSpeedThItems);
      setKakouSokudoSenchouList(selectedRowData?.details.workSpeedThItems);

      // 加工速度（板厚）
      // setKakouSokudoSenchouMasterData(selectedRowData.yousetsuShiageSenchouMasterData);

      // setArea(selectedRowData.areaMasterData);
      // setWeight(selectedRowData.weightMasterData);
      // setSizeDetailsList(selectedRowData.sizeListData);
      // setVolume(selectedRowData.volumeMasterData);
      // setDantoriSizeDetailsList(selectedRowData.dantoriSizeListData);

      // setKihonDantori(selectedRowData.kihonDantoriTime);
      // setDantoriFlag(selectedRowData.dantoriFlag);
      // setProcessInspectionTime(selectedRowData.processInspectionTime);

      // 1検査時間
      setProcessTimeByUnit(selectedRowData?.details.inspectionWorkTime);

      // 名称
      // setMeishou(selectedRowData.meishouDetails);
      // setMeishouList(selectedRowData.meishouLstData);
      // 数量区分
      // setSuuryouData(selectedRowData.kobuhinKeisuuMasterData);
      // setSuuryouList(selectedRowData.kobuhinKeisuuLstData);
      // setdantoriSetteiSuuryouList(selectedRowData.dantoriSetteiSuuryouLstData);

      // 要素数
      // setYousosuuData(selectedRowData.yousosuuMasterData);
      // setSagyouKeisuuYousosuuList(selectedRowData.sagyouJikanKeisuuLstData);
      // setShokiDankaiJikanList(selectedRowData.shokiDankaiJikanLstData);
      // setKensaJikanKeisuuList(selectedRowData.kensaJikanKeisuuLstData);

      // setJikanChaji(selectedRowData.jikanChajji);

      // setInitialCustomerData([]);
      // setInitialCustomerDataBk([]);

      // setKobuhinKeisuu(selectedRowData.kobuhinSuu);
      // setPitchItaatsuList(selectedRowData.pitchItaatsuLstData);
      // setTapWeldItaatsuList(selectedRowData.tapWeldingItaatsuLstData);

      // setYousetsuNanidoItaatsuMasterData(selectedRowData.yousetsuNanidoItaatsuMasterData);
      // setYousetsuNanidoItaatsuList(selectedRowData.yousetsuNanidoItaatsuLstData);

      // setKaritsukiTimeItaatsuMasterData(selectedRowData.karitsukiJikanItaatsuMasterData);
      // setKaritsukiTimeItaatsuList(selectedRowData.karitsukiJikanItaatsuLstData);

      // setToritsukiTimeItaatsuList(selectedRowData.toritsukiJikanItaatsuLstData); //別々テーブル
      // setToritsukiTimeItaatsuMasterData(selectedRowData.karitsukiJikanItaatsuMasterData);

      // setToritsukiTimeSizeMasterData(selectedRowData.toritsukiJikanSizeMasterData);
      // setToritsukiTimeSizeList(selectedRowData.toritsukiJikanSizeLstData);

      // setSetupAmount(selectedRowData.setupCharge);
      // setWorkAmount(selectedRowData.processCharge);
      // setDantoriTime(selectedRowData.setupTime);
      // setWorkTime(selectedRowData.workTime);
      // setTsuikaKouteiTypes(selectedRowData.tsuikaKouteiTypes);

      // setiq3SetupAmount(selectedRowData.iq3SetupAmount);
      // setiq3WorkAmount(selectedRowData.iq3WorkAmount);
      // setIQ3ProcessInspection(selectedRowData.iq3ProcessInspection);
      // setSecondProcessAmount(selectedRowData.secondProcessAmount);
      // setJointBarashiAmount(selectedRowData.jointBarashiAmount);
      // setIQ3KosuuList(selectedRowData.iq3DantoriJikanKosuuLstData);
      // setBendingTimeFactorListData(selectedRowData.bendingTimeFactorLstData);
      // setBendingCorrectionTimeFactorListData(selectedRowData.bendingCorrectionTimeFactorLstData);
      // setCorrectionTimeFactorListData(selectedRowData.correctionTimeFactorLstData);
      // setiq3ProductSizeListData(selectedRowData.iq3ProductSizeLstData);
      // setLength(selectedRowData?.lengthMasterData);
      // setiQ3BendingHonsuuList(selectedRowData.iq3BendingHonsuuLstData);
      // setIQ3BendingKosuuList(selectedRowData.iq3BendingkosuuLstData);
      // setIQ3MageSuuMaster(selectedRowData.mageSuuMasterData);
      // setIQ3MageSuuListData(selectedRowData.mageSuuLstData);
      // setIQ3MageKosuuMaster(selectedRowData.mageKosuuMasterData);
      // setIQ3MageKosuuListData(selectedRowData.mageKosuuLstData);

      // setDantoriJuuryouMagechouList(selectedRowData.dantoriSizeLstData);
      // setMagechouDantoriJikanListData(selectedRowData.iq3MagechouDantoriJikanListData);
      // setIQ3AreaMaster(selectedRowData.iq3AreaMasterData);
      // setIQ3KakouJikanListData(selectedRowData.iq3KakouJikanListData);
      // setBlankKakousokudolistData(selectedRowData.blankKakousokudoListData);
      // setProcessMethod(selectedRowData.kakouType);
      // setProcessMethods(selectedRowData.kakouMethods);
      // setProcessCode(selectedRowData.kakouCode);
      // setSeisanKosuu(selectedRowData.seisanKosuuMasterData);
      // setSeisanKosuuList(selectedRowData.seisanKosuuLstData);
      // setProcessInspectionKosuuList(selectedRowData.processInspectionKosuuLstData);
      // setHonsuu(selectedRowData.honsuuMasterData);
      // setHonSuuList(selectedRowData.honsuuLstData);
      // setIQ3SizeMaster(selectedRowData.iq3SizeMasterData);
      // setMmMaster(selectedRowData.iq3MmMasterData);

      // setIQ3KaniListData(selectedRowData.iq3KaninyuryokuListData);
      // setIQ3KaniAnaListData(selectedRowData.iq3KaninyuryokuAnaListData);
      // setItaatsu1(selectedRowData.itaatsu1MasterData);

      // setZaishitsu(selectedRowData.zaishitsuKubunn);
      // setMaterialHandlingTimeList(selectedRowData.materialHandlingTime);
      // setProgramSameSizePatternValue(selectedRowData.programSameSizePatternValue);

      // setPunchConditionList(selectedRowData.punchConditionList);
      // setDrillingTime(selectedRowData.drillingTime);
      // setForwardSpeed(selectedRowData.forwardSpeed);
      // setRollerSpeed(selectedRowData.rollerSpeed);
      // setOkuriSokudoVal(selectedRowData.okuriSokudoVal);
      // setKegakiSokudoVal(selectedRowData.kegakiSokudoVal);
      // setIQ3Kakouchou(selectedRowData.iq3KakouchouMasterData);
      // setShudoubariKakousokudoList(selectedRowData.iq3ShudouBariKakouSokudoListData);
      // setKensaAmount(selectedRowData.kouteinaiKensaAmount);

      // setShiageFlagList(selectedRowData.shiageFlagListData);
    } else {
      setProcessName('');
      setCode('');
      setDeviceCode('');
      setDeviceName('');
      setRemarks('');
    }
  }, [props.selectedData, props.editMode, props.getMaterialTypes]);

  useEffect(() => {
    setIsCharge(props.flag);
  }, [, props.flag]);

  // const getClientInfoAPI = async (clientId) => {
  //   let result = await getClientInfo();
  //   const filterdData = result?.filter((item) => item.id === clientId);
  //   return filterdData;
  // };
  useImperativeHandle(ref, () => ({}));

  // 工程種別名を得る
  const getWorkTypeName = (value) => {
    return WorkTypes?.filter((item) => item?.value === value)?.[0]?.label;
  };

  // 追加工程種類名を得る
  const getAdditionalProcessInfo = (masterInfoRecord, id) => {
    return masterInfoRecord?.details?.processDetailTypes?.filter((item) => item.id === id)?.[0];
  };

  const chargeAmount = () => {
    if (isCharge == true) {
      setIsCharge(false);
      props.updatedFlag(false);
    } else {
      setIsCharge(true);
      props.updatedFlag(true);
    }
  };
  const baritoriShurui = () => {
    if (isBaritoriShurui == true) {
      setIsBaritoriShurui(false);
    } else {
      setIsBaritoriShurui(true);
    }
  };
  const dantoriJikan = () => {
    if (isDantoriJikan === true) {
      setIsDantoriJikan(false);
    } else {
      setIsDantoriJikan(true);
    }
  };
  const setupSetting = () => {
    if (isSetupSetting == true) {
      setIsSetupSetting(false);
    } else {
      setIsSetupSetting(true);
    }
  };
  const kouteiNaikensa = () => {
    if (isKouteiNaikensa == true) {
      setIsKouteiNaikensa(false);
    } else {
      setIsKouteiNaikensa(true);
    }
  };
  const magekakouJikan = () => {
    if (isMagekakouJikan === true) {
      setIsMagekakouJikan(false);
    } else {
      setIsMagekakouJikan(true);
    }
  };
  const arrangeMaterial = () => {
    if (isArrangeMaterial === true) {
      setIsArrangeMaterial(false);
    } else {
      setIsArrangeMaterial(true);
    }
  };
  const punch = () => {
    if (isPunch === true) {
      setIsPunch(false);
    } else {
      setIsPunch(true);
    }
  };
  const piercingProcess = () => {
    if (isPiercingProcess === true) {
      setIsPiercingProcess(false);
    } else {
      setIsPiercingProcess(true);
    }
  };
  const punchProcess = () => {
    if (isPunchProcess === true) {
      setIsPunchProcess(false);
    } else {
      setIsPunchProcess(true);
    }
  };
  const mageHoseiJikan = () => {
    if (isMageHoseiJikan === true) {
      setIsMageHoseiJikan(false);
    } else {
      setIsMageHoseiJikan(true);
    }
  };
  const mageHoseiJikanFactor = () => {
    if (isMageHoseiJikanFactor === true) {
      setIsMageHoseiJikanFactor(false);
    } else {
      setIsMageHoseiJikanFactor(true);
    }
  };
  const processSizeFactor = () => {
    if (isProcessSizeFactor === true) {
      setIsProcessSizeFactor(false);
    } else {
      setIsProcessSizeFactor(true);
    }
  };
  const bendingTimeFactor = () => {
    if (isBendingTimeFactor === true) {
      setIsBendingTimeFactor(false);
    } else {
      setIsBendingTimeFactor(true);
    }
  };
  const processSpeed = () => {
    if (isProcessSpeed == true) {
      setIsProcessSpeed(false);
    } else {
      setIsProcessSpeed(true);
    }
  };
  const sagyouSokudo = () => {
    if (isSagyouSokudo == true) {
      setIsSagyouSokudo(false);
    } else {
      setIsSagyouSokudo(true);
    }
  };
  const sagyouKeisuu = () => {
    if (isSagyouKeisuu == true) {
      setIsSagyouKeisuu(false);
    } else {
      setIsSagyouKeisuu(true);
    }
  };
  const iq3DantoriJikan = () => {
    if (isiq3DantoriJikan === true) {
      setIsiq3DantoriJikan(false);
    } else {
      setIsiq3DantoriJikan(true);
    }
  };
  const iq3KakouJikan = () => {
    if (isiq3KakouJikan === true) {
      setIsiq3KakouJikan(false);
    } else {
      setIsiq3KakouJikan(true);
    }
  };
  const blankKakouSokudo = () => {
    if (isBlankKakouSokudo === true) {
      setIsBlankKakouSokudo(false);
    } else {
      setIsBlankKakouSokudo(true);
    }
  };
  const blankKakouSokudoKeisuu = () => {
    if (isBlankKakouSokudoKeisuu === true) {
      setIsBlankKakouSokudoKeisuu(false);
    } else {
      setIsBlankKakouSokudoKeisuu(true);
    }
  };
  const blankGasuKeisuu = () => {
    if (isBlankGasuKeisuu === true) {
      setIsBlankGasuKeisuu(false);
    } else {
      setIsBlankGasuKeisuu(true);
    }
  };
  const extractCondition = () => {
    if (isExtractCondition === true) {
      setIsExtractCondition(false);
    } else {
      setIsExtractCondition(true);
    }
  };
  const blankGasuType = () => {
    if (isBlankGasuType === true) {
      setIsBlankGasuType(false);
    } else {
      setIsBlankGasuType(true);
    }
  };
  const blankDrillingTime = () => {
    if (isBlankDrillingTime === true) {
      setIsBlankDrillingTime(false);
    } else {
      setIsBlankDrillingTime(true);
    }
  };
  const okuriSokudo = () => {
    if (isOkuriSokudo === true) {
      setIsOkuriSokudo(false);
    } else {
      setIsOkuriSokudo(true);
    }
  };
  const rollerKakouSokudo = () => {
    if (isRollerKakouSokudo === true) {
      setIsRollerKakouSokudo(false);
    } else {
      setIsRollerKakouSokudo(true);
    }
  };
  const blankJoint = () => {
    if (isBlankJoint === true) {
      setIsBlankJoint(false);
    } else {
      setIsBlankJoint(true);
    }
  };
  const iq3KakouSokudo = () => {
    if (isiq3KakouSokudo === true) {
      setIsiq3KakouSokudo(false);
    } else {
      setIsiq3KakouSokudo(true);
    }
  };
  const iq3ManualBari = () => {
    if (isiq3ManualBari === true) {
      setIsiq3ManualBari(false);
    } else {
      setIsiq3ManualBari(true);
    }
  };
  const processTime = () => {
    if (isProcessTime == true) {
      setIsProcessTime(false);
    } else {
      setIsProcessTime(true);
    }
  };
  const attach = () => {
    if (isAttach == true) {
      setIsAttach(false);
    } else {
      setIsAttach(true);
    }
  };

  const toritsukiJikanItaatsu = () => {
    if (isToritsukiJikanItaatsu === true) {
      setIsToritsukiJikanItaatsu(false);
    } else {
      setIsToritsukiJikanItaatsu(true);
    }
  };
  const toritsukiJikanSize = () => {
    if (isToritsukiJikanSize === true) {
      setIsToritsukiJikanSize(false);
    } else {
      setIsToritsukiJikanSize(true);
    }
  };
  const welding = () => {
    if (isWelding == true) {
      setIsWelding(false);
    } else {
      setIsWelding(true);
    }
  };
  const deviceDetails = () => {
    if (isDeviceDetails === true) {
      setIsDeviceDetails(false);
    } else {
      setIsDeviceDetails(true);
    }
  };
  const tapWelding = () => {
    if (isTapWelding == true) {
      setIsTapWelding(false);
    } else {
      setIsTapWelding(true);
    }
  };
  const pitch = () => {
    if (isPitch == true) {
      setIsPitch(false);
    } else {
      setIsPitch(true);
    }
  };
  const productSize = () => {
    if (isProductSize == true) {
      setIsProductSize(false);
    } else {
      setIsProductSize(true);
    }
  };
  const productQuantity = () => {
    if (isProductQuantity == true) {
      setIsProductQuantity(false);
    } else {
      setIsProductQuantity(true);
    }
  };
  const childPart = () => {
    if (isChildPart == true) {
      setIsChildPart(false);
    } else {
      setIsChildPart(true);
    }
  };
  const difficultyFactor = () => {
    if (isDifficultyFactor == true) {
      setIsDifficultyFactor(false);
    } else {
      setIsDifficultyFactor(true);
    }
  };
  const shiageFlag = () => {
    if (isShiageFlag == true) {
      setIsShiageFlag(false);
    } else {
      setIsShiageFlag(true);
    }
  };
  const materialHandling = () => {
    if (isMaterialHandling == true) {
      setIsMaterialHandling(false);
    } else {
      setIsMaterialHandling(true);
    }
  };
  const editData = () => {
    setEditMode(true);
    props.updatedFlag(isCharge);
    props.updateEditMode(true);
  };
  const updateData = () => {
    //  setUpdateConfirm(true);
    setUpdateConfirm(props.showEdtConfirmOK);
    if (!props.showEdtConfirmOK) {
      updConfirmOk();
    }
  };

  const discardData = () => {
    // setCancelConfirm(true);

    setCancelConfirm(props.showEdtConfirmCancle);
    if (!props.showEdtConfirmCancle) {
      discardConfirmOk();
    }
  };

  const updConfirmOk = (e) => {
    setEditMode(false);
    props.updateEditMode(false);
    setUpdateConfirm(false);
    selectedDeviceData.name = deviceName;
    selectedDeviceData.code = deviceCode;
    selectedDeviceData.info.remarks = remarks;
    selectedDeviceData.details = detailsProcess;
    // プログラムの場合、
    if (workType === WorkType.SmProgram) {
      selectedDeviceData.details.workBasicTime = sagyouTime; // 作業時間（x要素数）
    } else {
      selectedDeviceData.details.workBasicTime = workTime;
    }
    selectedDeviceData.details.isCommission = checkboxChecked;

    selectedDeviceData.details.clientId = customerId;
    selectedDeviceData.details.isManualInput = flagChecked;
    selectedDeviceData.details.initPrice = iniAmount;
    selectedDeviceData.details.inspectionWorkTime = processTimeByUnit; // 1検査時間

    props.updatedDetails(selectedDeviceData);
  };

  const updConfirmCancel = () => {
    setUpdateConfirm(false);
  };

  const discardConfirmOk = () => {
    props.updateEditMode(false);
    setCancelConfirm(false);
    setEditMode(false);
    setDeviceName(detailsData['name']);
    setDeviceCode(detailsData['code']);
    setRemarks(detailsData.info['remarks']);
    setDetailProcess(detailsData['details']);
  };

  const discardConfirmCancel = () => {
    setCancelConfirm(false);
    props.updateEditMode(true);
  };

  const updateShiageFlagList = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, difficultyCoeff: result }));
  };

  //　チャージ金額の値を入力した場合、
  const updatedChargeAmount = (updDetails) => {
    setDetailProcess((prevData) => ({ ...prevData, charges: updDetails }));
  };

  const updatedCharge = (result) => {
    setJikanChaji(result);
  };
  // 送り速度(mm/min)
  const updateForwardSpeed = (result) => {
    // setForwardSpeed(result);
    setDetailProcess((prevData) => ({ ...prevData, feedSpeed: result }));
  };
  // ローラー/ホイール/ケガキ金型　加工速度(mm/sec)加工速度(mm/分)
  const updateRollerSpeed = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, workSpeed: result }));
  };
  const updateDrillingTime = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, twoStepHoleworkTime: result }));
  };
  // パンチ条件データ更新
  const updatePunchList = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, punches: result }));
  };
  // 材料配置データ更新
  const updateMaterialArrangement = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, materialArrange: result }));
  };
  // 1ピアス加工時間の板厚マスタ値とリスト更新
  const updatePiercingTimeThickThMaster = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, piercingTimeThickThMaster: result }));
  };
  const updatePiercingTimeThItems = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, piercingTimeThItems: result }));
  };
  const updateProcessMethod = (result) => {
    setProcessMethod(result);
  };
  const updateSetupAmount = (result) => {
    setiq3SetupAmount(result);
  };
  const updateWorkAmount = (result) => {
    setiq3WorkAmount(result);
  };
  const updateiq3ProcessInspection = (result) => {
    setIQ3ProcessInspection(result);
  };
  const updatesecondProcessAmount = (result) => {
    setSecondProcessAmount(result);
  };
  const updatejointBarashiAmount = (result) => {
    setJointBarashiAmount(result);
  };
  const updateKensaAmount = (result) => {
    setKensaAmount(result);
  };

  // 段取設定の値を入力された場合、
  const updatedDantori = (updDetails) => {
    setDetailProcess((prevData) => ({ ...prevData, prepSecs: updDetails }));
  };

  // 数量のマスタ値を入力された場合、
  const updateSuuryouMstData = (updDetails) => {
    // setSuuryouData(result);
    setDetailProcess((prevData) => ({ ...prevData, prepThQtyMaster: updDetails }));
  };

  // 子部品点数の値を入力された場合、
  const updatedSuuryouChildList = (updDetails) => {
    setDetailProcess((prevData) => ({ ...prevData, prepCoeffThChildQtyItems: updDetails }));
  };

  // 子部品種類数の値を入力された場合、
  const updatedSuuryouChildTypeList = (updDetails) => {
    setDetailProcess((prevData) => ({ ...prevData, prepCoeffThChildTypeQtyItems: updDetails }));
  };

  // 子部品点数又子部品種類数のチェックボタンを変更された場合、
  const updatedPreparationCoeffType = (updDetails) => {
    setDetailProcess((prevData) => ({ ...prevData, prepCoeffType: updDetails }));
  };

  // 重量マスタ値を入力された場合、
  const updatedWeightMstData = (updDetails) => {
    setDetailProcess((prevData) => ({ ...prevData, prepCoeffThWeightMaster: updDetails }));
  };

  // 重量リスト値を入力された場合、
  const updatedWeightListData = (updDetails) => {
    setDetailProcess((prevData) => ({ ...prevData, prepCoeffThWeightItems: updDetails }));
  };

  const updatedantoriSetteiSuuryouList = (result) => {
    setdantoriSetteiSuuryouList(result);
  };

  // 基本段取時間を入力された場合、
  const updatedBasicTime = (updDetails) => {
    // setKihonDantori(result);
    setDetailProcess((prevData) => ({ ...prevData, prepBasicTime: updDetails }));
  };

  // 段取按分しないを変更された場合、
  const updateApportionSelect = (updDetails) => {
    // setDantoriFlag(result);
    setDetailProcess((prevData) => ({ ...prevData, apportionSelect: updDetails }));
  };

  const updateDantoriFlag = (result) => {
    setDantoriFlag(result);
  };
  // 加工速度の溶接線長マスタ値を入力された場合、
  const updatedYousetsuSenchouData = (result) => {
    // setWeldLine(result);
    setDetailProcess((prevData) => ({ ...prevData, workSpeedThMaster: result }));
  };

  const updateYousetsuSenchouList = (result) => {
    setYousetsuWeldLineList(result);
  };

  const updateKakousokudoSenchouList = (result) => {
    // setKakouSokudoSenchouList(result);
    setDetailProcess((prevData) => ({ ...prevData, workSpeedThItems: result }));
  };
  // 仮付き時間の板厚マスタ値を入力された場合、
  const upadateKaritsukiItaatsuData = (result) => {
    // setKaritsukiTimeItaatsuMasterData(result);
    setDetailProcess((prevData) => ({ ...prevData, tempInstThMaster: result }));
  };
  // 取付時間(板厚判断)の板厚マスタ値を入力された場合、
  const updateInstThickThMaster = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, instThickThMaster: result }));
  };
  // 仮付き時間の板厚マスタ値により、リスト更新
  const updateKaritsukiTimeList = (result) => {
    // setKaritsukiTimeItaatsuList(result);
    setDetailProcess((prevData) => ({ ...prevData, tempInstThItems: result }));
  };
  // 取付時間(板厚判断)の板厚マスタ値により、リスト更新
  const updateInstThickThItems = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, instThickThItems: result }));
  };

  const updateSpotPitchThMaster = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, spotPitchThMaster: result }));
  };
  const updateSpotPitchThItems = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, spotPitchThItems: result }));
  };
  // 溶接仕上の難易度係数更新
  const updateDiffFactorCoeff = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, diffFactorCoeff: result }));
  };

  // 曲げ補正時間の本数マスタ値更新
  const updateModifyTimeQtyThMaster = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, modifyTimeQtyThMaster: result }));
  };
  const updateModifyTimeItems = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, modifyTimeItems: result }));
  };
  // 曲げ補正時間のkgとmmマスタ値更新
  const updateModifyCoeffLenThMaster = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, modifyCoeffLenThMaster: result }));
  };
  const updateModifyCoeffWeightThMaster = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, modifyCoeffWeightThMaster: result }));
  };
  const updateMtWeightThMaster = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, mtWeightThMaster: result }));
  };
  const updateModifyCoeffItems = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, modifyCoeffItems: result }));
  };
  // 加工補正時間係数
  const updatedworkCorrectionTimeListData = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, mtTimeCoeffItems: result }));
  };
  const updateMtAreaThMaster = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, mtAreaThMaster: result }));
  };
  // 手動バリ取対象サイズの内径最小丸穴径(mm)の値更新
  const updateRoundHoleDia = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, roundHoleDia: result }));
  };
  // 手動バリ取対象サイズの内径最小矩形周長(mm)の値更新
  const updateRectangleDia = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, rectangleDia: result }));
  };
  const updatedLists = (result) => {
    setYousetsuNanidoItaatsuList(result);
  };

  const updateProductSizeArea = (result) => {
    setArea(result);
  };

  //
  const updateKosuu = (result) => {
    setKosuu(result);
  };

  // 生産個数マスタの値を変更した場合、
  const updateSeisanKosuu = (result) => {
    // setSeisanKosuu(result);
    setDetailProcess((prevData) => ({ ...prevData, prepQtyCoeffThMaster: result }));
  };

  // 本数マスタ値を更新
  const updateHonsuu = (result) => {
    // setHonsuu(result);
    if (workType === WorkType.SmBending) {
      setDetailProcess((prevData) => ({ ...prevData, programCreateTimeCountfThMaster: result }));
    } else {
      setDetailProcess((prevData) => ({ ...prevData, prepMoldQtyCoeffThMaster: result }));
    }
  };

  // 生産個数リストの値を変更した場合、
  const updateiq3SeisanKosuuList = (result) => {
    // setSeisanKosuuList(result);
    setDetailProcess((prevData) => ({ ...prevData, prepQtyCoeffItems: result }));
  };

  const updateProcessInspectionKosuuList = (result) => {
    setProcessInspectionKosuuList(result);
  };

  // 本数リスト値を更新
  const updateHonsuuList = (result) => {
    // setHonSuuList(result);
    if (workType === WorkType.SmBending) {
      setDetailProcess((prevData) => ({ ...prevData, programCreateTimeItems: result }));
    } else {
      setDetailProcess((prevData) => ({ ...prevData, prepMoldQtyCoeffItems: result }));
    }
  };

  // 基本段取時間
  const updateDantoriTime = (result) => {
    // setKihonDantori(result);
    setDetailProcess((prevData) => ({ ...prevData, prepBasicTime: result }));
  };

  const updateProcessInspectionTime = (result) => {
    setProcessInspectionTime(result);
  };
  const updateMageKosuu = (result) => {
    setIQ3MageKosuuMaster(result);
  };
  const updateMageKosuList = (result) => {
    setIQ3MageKosuuListData(result);
  };
  const updateiq3BendingHonsuuList = (result) => {
    setiQ3BendingHonsuuList(result);
  };
  const updateIQ3BendingKosuuList = (result) => {
    setIQ3BendingKosuuList(result);
  };
  const updateiq3KosuuList = (result) => {
    setSeisanKosuuList(result);
  };

  // 生産個数係数の個数マスタ値を入力された場合、
  const updateProductionQtyCoeffMst = (updDetails) => {
    // setKousuuList(result);
    setDetailProcess((prevData) => ({ ...prevData, productionQtyCoeffThMaster: updDetails }));
  };

  // 生産個数係数を入力された場合、
  const updateProductionQtyCoeffList = (updDetails) => {
    // setKousuuList(result);
    setDetailProcess((prevData) => ({ ...prevData, productionQtyCoeffThItems: updDetails }));
  };

  const updatename = (result) => {
    setMeishou(result);
  };
  const updateMeishouList = (result) => {
    setMeishouList(result);
  };

  // 重量マスタの値を変更した場合、
  const updateProductSizeWeight = (result) => {
    // setWeight(result);
    setDetailProcess((prevData) => ({ ...prevData, childPartsCoeffWeightThMaster: result }));
  };

  // 曲げ時間係数のkgとmmマスタ値更新
  const updateBendTimeCoeffLenThMaster = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, bendTimeCoeffLenThMaster: result }));
  };

  const updateBendTimeCoeffWeightThMaster = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, bendTimeCoeffWeightThMaster: result }));
  };

  const updateBendingCorrectionTimeFactorListData = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, bendTimeCoeffItems: result }));
  };

  // マテリアルハンドリング時間の重量と面積マスタ値更新
  const updateMhWeightThMaster = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, mhWeightThMaster: result }));
  };
  const updateMhAreaThMaster = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, mhAreaThMaster: result }));
  };

  // マテリアルハンドリング時間リスト更新
  const updateMhTimeItems = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, mhTimeItems: result }));
  };

  // 面積マスタの値を変更した場合、
  const updateBendingTimeArea = (result) => {
    // setIQ3AreaMaster(result);
    setDetailProcess((prevData) => ({ ...prevData, childPartsCoeffAreaThMaster: result }));
  };

  // const updateDantoriJuuryoMagechouListData = (result) => {
  //   setDantoriJuuryouMagechouList(result);
  // };

  // 曲長リスト値を変更
  const updateMagechouDantoriJikanList = (result) => {
    // setMagechouDantoriJikanListData(result);
    setDetailProcess((prevData) => ({ ...prevData, prepBendTimeItems: result }));
  };

  const updateBendingTimeFactorListData = (result) => {
    setBendingTimeFactorListData(result);
  };

  // サイズ係数のリスト値を変更した場合、
  const updatecorrectionTimeFactorListData = (result) => {
    // setCorrectionTimeFactorListData(result);
    setDetailProcess((prevData) => ({ ...prevData, childPartsCoeff: result }));
  };
  // 1パンチ加工時間マスタ更新
  const updatePunchWorkTimeThickThMaster = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, punchWorkTimeThickThMaster: result }));
  };
  const updatePunchWorkTimeItems = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, punchWorkTimeItems: result }));
  };
  // サイズ係数の面積マスタ更新
  const updateProdSizeAreaThMaster = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, prodSizeAreaThMaster: result }));
  };

  // サイズ係数の重量マスタ更新
  const updateProdSizeWeightThMaster = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, prodSizeWeightThMaster: result }));
  };

  // サイズ係数の重量面積リスト情報を更新
  const updateProdSizeItems = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, prodSizeItems: result }));
  };

  const updateIQ3ProductSizeListData = (result) => {
    setiq3ProductSizeListData(result);
  };

  // 曲長マスタの値を更新
  const updateBendingTimeLength = (result) => {
    // setLength(result);
    setDetailProcess((prevData) => ({ ...prevData, prepBendTimeLengthMaster: result }));
  };

  const updateIQ3AreaMaster = (result) => {
    setIQ3AreaMaster(result);
  };
  // 自動バリの加工時間のcm２マスタ値更新
  const updateAreaThMaster = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, areaThMaster: result }));
  };
  const updateWorkTimeThItems = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, workTimeThItems: result }));
  };

  const updateIQ3KakouJikanList = (result) => {
    setIQ3KakouJikanListData(result);
  };

  // サイズマスタの更新
  const updatediq3SizeMaster = (result) => {
    // setIQ3SizeMaster(result);
    setDetailProcess((prevData) => ({ ...prevData, sizeThMaster: result }));
  };

  // mmマスタの更新
  const updateSizeMmMaster = (result) => {
    // setMmMaster(result);
    setDetailProcess((prevData) => ({ ...prevData, mmThMaster: result }));
  };

  // サイズ簡易入力リストの更新
  const updatediq3KaniListData = (result) => {
    // setIQ3KaniListData(result);
    setDetailProcess((prevData) => ({ ...prevData, workTimeSizeThItems: result }));
  };

  // mm簡易入力リストの更新
  const updateiq3KaniAnaListData = (result) => {
    // [setIQ3KaniAnaListData(result)];
    setDetailProcess((prevData) => ({ ...prevData, workTimeSizeHoleThItems: result }));
  };

  // サイズ板厚マスタの更新
  const updateSizeItaatsu = (result) => {
    // setItaatsu1(result);
    setDetailProcess((prevData) => ({ ...prevData, sizeThicknessThMaster: result }));
  };

  // mm板厚マスタの更新
  const updateMmItaatsu = (result) => {
    // setItaatsu1(result);
    setDetailProcess((prevData) => ({ ...prevData, mmThicknessThMaster: result }));
  };

  // 1カット時間
  const updateCutTime = (result) => {
    // setCutTime(result);
    setDetailProcess((prevData) => ({ ...prevData, cutTime: result }));
  };

  // one by one
  const updateShirringFlag = (result) => {
    // setShirringFlag(result);
    setDetailProcess((prevData) => ({ ...prevData, isOneCut: result }));
  };

  const updateVolume = (result) => {
    setVolume(result);
  };

  const updateDantoriSizeList = (result) => {
    setDantoriSizeDetailsList(result);
  };

  // 重量係数の個数マスタ値を入力された場合、
  const updateProductWeightCoeffMst = (updDetails) => {
    setDetailProcess((prevData) => ({ ...prevData, prodWeightCoeffThMaster: updDetails }));
  };

  // 重量係数の値を入力された場合、
  const updateProductWeightCoeffList = (updDetails) => {
    // setSizeDetailsList(result);
    setDetailProcess((prevData) => ({ ...prevData, prodWeightCoeffThItems: updDetails }));
  };

  const updateSuuryouDetails = (result) => {
    setSuuryouData(result);
  };

  const updateMagesuuData = (result) => {
    setIQ3MageSuuMaster(result);
  };

  // 曲げ種類リストの値を更新、
  const updateMagesuuList = (result) => {
    // setIQ3MageSuuListData(result);
    setDetailProcess((prevData) => ({ ...prevData, basicTimeThickItems: result }));
  };

  // 板厚マスタの値を更新
  const updateItaatsuMstData = (result) => {
    // setIQ3ItaatsuMaster(result);
    setDetailProcess((prevData) => ({ ...prevData, basicTimeThickThMaster: result }));
  };

  // 曲げ種類の板厚マスタを更新
  const updateIQ3Itaatsu = (result) => {
    // setIQ3ItaatsuMaster(result);
    setDetailProcess((prevData) => ({ ...prevData, workTimeThickThMaster: result }));
  };

  const updatedBlankKakousokudolistData = (result) => {
    setBlankKakousokudolistData(result);
  };
  const updateNaikeibari = (result) => {
    setNaikeibari(result);
  };
  const updateKakousokudobari = (result) => {
    setkakosokudo_bari(result);
  };
  // 手動バリの加工速度(mm/min)のマスタ値とリスト更新
  const updateWorkSpeedThickThMaster = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, workSpeedThickThMaster: result }));
  };
  const updateWorkSpeedLenThMaster = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, workSpeedLenThMaster: result }));
  };
  const updateWorkSpeedItems = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, workSpeedItems: result }));
  };
  const updateShudobariKakousokudoList = (result) => {
    setShudoubariKakousokudoList(result);
  };
  const updatedWorkSpeedItems = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, workSpeedItems: result }));
  };
  // const updateNaikeiMin = (result) => {
  //   setNaikeiMin(result);
  // };
  // const updateNaikeiMax = (result) => {
  //   setNaikeiMax(result);
  // };
  // const updateAnaMax = (result) => {
  //   setAnaMax(result);
  // };
  // const updateAnaMin = (result) => {
  //   setAnaMin(result);
  // };
  // ガス係数のガス種類と板厚マスタ値更新
  const updateGasCoeffThMaster = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, gasCoeffThMaster: result }));
  };
  const updateGasCoeffListItems = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, gasCoeffListItems: result }));
  };
  const updateGasCoeffThickThMaster = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, gasCoeffThickThMaster: result }));
  };
  const updateGasCoeffThItems = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, gasCoeffThItems: result }));
  };
  // 外形追抜条件の保有金型マスタ値更新
  const updatedRetainedMold = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, odOvertakeMoldMaster: result }));
  };
  const updateExtractConditionList = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, odOvertakeItems: result }));
  };

  // 曲げ種類の板厚リスト値を更新
  const updateBendingTimeList = (result) => {
    // setIQ3BendingTimeListData(result);
    setDetailProcess((prevData) => ({ ...prevData, workTimeThickItems: result }));
  };

  const updateGasTypeList = (result) => {
    setGasTypeListData(result);
  };

  const updatePiercingProcessList = (result) => {
    setPiercingProcessItaatsuListData(result);
  };

  const updatePunchProcessList = (result) => {
    setPunchProcessItaatsuListData(result);
  };
  // ジョイントバラシのcm2マスタ値更新
  const updateJointQtySizeThMaster = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, jointQtySizeThMaster: result }));
  };
  const updateJointQtyItems = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, jointQtyItems: result }));
  };
  // ジョイントバラシの板厚マスタ値更新
  const updateJointSeparateThickThMaster = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, jointSeparateThickThMaster: result }));
  };
  const updateJointSeparateItems = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, jointSeparateItems: result }));
  };

  // 加工速度係数のマスタ値とリスト更新
  const updateWorkSpeedCoeffThickThMaster = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, workSpeedCoeffThickThMaster: result }));
  };
  const updateWorkSpeedCoeffInternalWorkItems = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, workSpeedCoeffInternalWorkItems: result }));
  };
  const updateWorkSpeedCoeffExternalWorkItems = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, workSpeedCoeffExternalWorkItems: result }));
  };
  const updateJointItaatsuList = (result) => {
    setJointItaatsuListData(result);
  };
  const updateblankKakouSokudoItaatsuList = (result) => {
    setBlankKakouSokudoItaatsuList(result);
  };
  const updateOkuriSokudoVal = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, feedSpeed: result }));
  };
  const updateKegakiSokudoVal = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, scribingSpeed: result }));
  };

  // const updateYousosuu = (result) => {
  //   setYousosuuData(result);
  // };

  // 要素数マスタを変更した場合、
  const updateYousosuuMaster = (result) => {
    // setYousosuuData(result);
    setDetailProcess((prevData) => ({ ...prevData, prepElementThMaster: result }));
  };

  // 初期段取時間を変更した場合、
  const updateshokiDankaiJikanListData = (result) => {
    // setShokiDankaiJikanList(result);
    setDetailProcess((prevData) => ({ ...prevData, prepElementThItems: result }));
  };

  // 同一形状・サイズの上限を変更した場合、
  const updateProgramSameSizePatternValue = (result) => {
    // setProgramSameSizePatternValue(result);
    setDetailProcess((prevData) => ({ ...prevData, shapeGrouping: result }));
  };

  // const updateSuuryouListData = (result) => {
  //   setSuuryouList(result);
  // };

  const updateDantoriProductSizeWeight = (result) => {
    setWeight(result);
  };
  const updatKobuhinnKeisuu = (result) => {
    setKobuhinKeisuu(result);
  };

  const updateTapWeldItaatsuLst = (result) => {
    setTapWeldItaatsuList(result);
  };
  const updateInstSizeThMaster = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, instSizeThMaster: result }));
  };
  const updateInstSizeThItems = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, instSizeThItems: result }));
  };

  // 溶接難易度の板厚マスタデータ更新
  const updateWeldDifficultyCoeffThMaster = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, weldDifficultyCoeffThMaster: result }));
  };
  const updatedWeldDifficultyCoeffThItems = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, weldDifficultyCoeffThItems: result }));
  };
  // タップ溶接の板厚マスタデータ更新
  const updateTapRateThMaster = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, tapRateThMaster: result }));
  };
  const updateTapRateThItems = (result) => {
    setDetailProcess((prevData) => ({ ...prevData, tapRateThItems: result }));
  };
  const updateToritsukiItaatsuList = (result) => {
    setToritsukiTimeItaatsuList(result);
  };

  // 1検査時間を変更した場合、
  const changeTime = (e) => {
    setProcessTimeByUnit(setTimetoSec(e));
  };

  // マテリアルハンドリング時間更新
  const updateMaterialHandlingList = (result) => {
    setMaterialHandlingTimeList(result);
  };

  // 作業時間係数の要素数リストを変更した場合、
  const updateYousosuuListData = (result) => {
    // setSagyouKeisuuYousosuuList(result);
    setDetailProcess((prevData) => ({ ...prevData, workTimeElementCoeffThItems: result }));
  };

  // 作業時間係数の要素数マスタを変更した場合、
  const updateYousosuu = (result) => {
    // setYousosuuData(result);
    setDetailProcess((prevData) => ({ ...prevData, otherElementCoeffThMaster: result }));
  };

  const updateKensaJikanKeisuuList = (result) => {
    setKensaJikanKeisuuList(result);
  };

  // チャージ金額詳細
  const chargeDetailsModal = (event) => {
    event.stopPropagation();
    setIsDetails(true);
  };

  // バリ取種類
  const baritoriTypesContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={baritoriShurui}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>{processName === 'バリ取' ? 'バリ取' : 'ブランク'}種類</label>
            {isBaritoriShurui ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isBaritoriShurui ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <Device_ProcessType
              processMethods={processMethods}
              processMethod={processMethod}
              editMode={editMode}
              updatedProcessMethod={updateProcessMethod}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // チャージ金額
  const chargeAmountContent = (
    <>
      {/* <Row align="middle" className="oyabuhin oyabuhin-row" onClick={chargeAmount}> */}
      <Row align="middle" style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={chargeAmount}>
        <Col span={20} className="deli-col">
          <div style={{ verticalAlign: 'middle' }}>
            <label style={{ marginRight: 5 }}>チャージ金額(/h)</label>
            {isCharge ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}

            {/* <Button className="detailsButton" onClick={chargeDetailsModal} style={{ marginTop: -2 }}>
              詳細
            </Button> */}
          </div>
        </Col>
      </Row>
      <div style={{ display: isCharge ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            {/* チャージ金額 */}
            <ChargeAmount
              deviceType={deviceType}
              types={types}
              // setupAmount={setupAmount}
              // updateSetupAmount={updateSetupAmount}
              // workAmount ={workAmount}
              // updateWorkAmount={updateWorkAmount}
              detailsData={detailsProcess}
              processMstRecord={processMstRecord}
              // chajiDetails={jikanCharge}
              processType={workType}
              processMethod={processMethod}
              editMode={editMode}
              updatedAmount={updatedChargeAmount}
              // updatedCharge={updatedCharge}
              // iq3SetupAmount={iq3SetupAmount}
              // updatediq3SetupAmount={updateSetupAmount}
              // iq3WorkAmount={iq3WorkAmount}
              // updatediq3WorkAmount={updateWorkAmount}
              // iq3ProcessInspection={iq3ProcessInspection} //工程内検査
              // updatediq3ProcessInspection={updateiq3ProcessInspection}
              // secondProcessAmount={secondProcessAmount} //２次加工
              // updatedsecondProcessAmount={updatesecondProcessAmount}
              // jointBarashiAmount={jointBarashiAmount}
              // updatedjointBarashiAmount={updatejointBarashiAmount}
              // チャージ金額の工程内検査
              // kensaAmount={kensaAmount}
              // updateKensaAmount={updateKensaAmount}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );

  // 段取設定
  const dantoriSettingContent = (
    <>
      <Row align="middle" style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={setupSetting}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>段取設定</label>
            {isSetupSetting ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isSetupSetting ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            {
              // プログラム
              workType === WorkType.SmProgram ||
              // ブランク
              workType === WorkType.SmBlankLaser ||
              workType === WorkType.SmBlankPunch ||
              workType === WorkType.SmBlankCombi ||
              // ベンディング
              workType === WorkType.SmBending ||
              // バリ取（自動・手動）
              workType === WorkType.SmDeburringAuto ||
              workType === WorkType.SmDeburringManual ||
              // 2次加工
              workType === WorkType.SmSecondaryWork ||
              // シャーリング
              workType === WorkType.SmShearing ||
              // 表面処理とiQ3
              (workType === WorkType.SmSurface && types === ServiceClass.SheetMetal) ||
              // 溶接とiQ3
              (workType === WorkType.SmWelding && types === ServiceClass.SheetMetal) ||
              // 溶接仕上とiQ3
              (workType === WorkType.SmWeldFinish && types === ServiceClass.SheetMetal) ||
              // 検査とiQ3
              (workType === WorkType.SmInspection && types === ServiceClass.SheetMetal) ||
              // カスタムとiQ3
              (workType === WorkType.SmCustom && types === ServiceClass.SheetMetal) ? (
                <>
                  <Device_SetupSetting
                    processType={workType}
                    detailsData={detailsProcess}
                    processMstRecord={processMstRecord}
                    processMethod={processMethod}
                    editMode={editMode}
                    // mageSuuData={iq3MageSuuMaster}
                    // updatedMagesuuData={updateMagesuuData}
                    itaatsuData={iq3ItaatsuMaster}
                    updatedItaatsuData={updateItaatsuMstData}
                    mageSuuLst={iq3MageSuuListData}
                    updatedMagesuuList={updateMagesuuList}
                    // 要素数
                    yousosuuMasterData={yousosuuData}
                    updatedYousosuuMasterData={updateYousosuuMaster}
                    // shokidankaiYousosuuList={shokiDankaiJikanList}
                    updatedYousosuuList={updateshokiDankaiJikanListData}
                    // 要素数により、作業係数のリスト更新
                    sagyouKeisuuYousosuuList={sagyouKeisuuYousosuuList}
                    updatedSagyouYousosuuList={updateYousosuuListData}
                    kensaJikanKeisuuList={kensaJikanKeisuuList}
                    updateKensaJikanKeisuuList={updateKensaJikanKeisuuList}
                    // 曲げ個数
                    mageKosuu={iq3MageKosuuMaster}
                    updatedMageKosuu={updateMageKosuu}
                    mageKosuuList={iq3MageKosuuListData}
                    updatedMageKosuuList={updateMageKosuList}
                    // 重量と曲げ長
                    length={length}
                    updatedLength={updateBendingTimeLength}
                    weight={weight}
                    updatedWeight={updateProductSizeWeight}
                    // dantoriJuuryouMagechouList={dantoriJuuryouMagechouList}
                    // updatedDantoriJuuryouMagechouList={updateDantoriJuuryoMagechouListData}
                    magechouDantoriJikanList={magechouDantoriJikanListData}
                    updatedmagechouDantoriJikanListData={updateMagechouDantoriJikanList}
                    // 二次加工

                    updatedDantoriSetting={updatedDantori} //段取 秒更新データ
                    // 生産個数
                    kosuu={seisanKosuu}
                    updatedKosuu={updateSeisanKosuu}
                    updatedKosuuList={updateiq3SeisanKosuuList}
                    kosuuList={seisanKosuuList}
                    processInspectionKosuuListosuuList={processInspectionKosuuList}
                    updatedProcessInspectionKosuuList={updateProcessInspectionKosuuList}
                    // 本数
                    honSuu={honSuu}
                    updateHonsuu={updateHonsuu}
                    honSuuList={honSuuList}
                    updateHonsuuList={updateHonsuuList}
                    // 基本段取時間
                    dantoriTime={kihonDantori}
                    updatedDantoriTime={updateDantoriTime}
                    // 段取を個別に持つ
                    dantoriFlag={dantoriFlag}
                    updateDantoriFlag={updateDantoriFlag}
                    area={iq3AreaMaster}
                    updatedArea={updateBendingTimeArea}
                    // 重量と面積
                    sizeKeisuuListData={correctionTimeFactorListData}
                    updatedsizeKeisuuListData={updatecorrectionTimeFactorListData}
                    // マテリアルハンドリング時間
                    materialHandlingTimeList={materialHandlingTimeList}
                    updateMaterialHandlingList={updateMaterialHandlingList}
                    // 製品重量係数
                    iq3SeihinSizeLst={iq3ProductSizeListData}
                    updatediq3ProductSizesList={updateIQ3ProductSizeListData}
                  />
                </>
              ) : (
                <>
                  {/* 段取設定 */}
                  <SetupSetting
                    editMode={editMode}
                    processType={workType}
                    detailsData={detailsProcess}
                    processMstRecord={processMstRecord}
                    updatedDantoriSetting={updatedDantori} //段取　秒更新データ
                    // kihonDantoriTime={kihonDantori} //基本段取時間
                    // updatedKihonDantoriTime={updatedTime}
                    // suuryouData={suuryouData} //数量Master
                    updatedSuuryouMstData={updateSuuryouMstData} // 個数マスタ値
                    updatedSuuryouChildList={updatedSuuryouChildList} // 子部品点数リスト値
                    updatedSuuryouChildTypeList={updatedSuuryouChildTypeList} // 子部品種類数リスト値
                    updatedPrepCoeffType={updatedPreparationCoeffType} // 子部品点数又子部品種類数のチェックボタン値
                    // dantoriSetteiSuuryouList={dantoriSetteiSuuryouList}
                    // updateddantoriSetteiSuuryouList={updatedantoriSetteiSuuryouList}
                    // 子部品数量
                    // kobuhinsuuryouList={suuryouList}
                    // updatedSuuryouList={updateSuuryouListData}
                    // 重量と体積
                    updatedWeightMstData={updatedWeightMstData} // 重量マスタ値
                    updatedWeightListData={updatedWeightListData} // 重量リスト値
                    updatedBasicTime={updatedBasicTime} //基本段取時間
                    // weight={weight}
                    // updatedWeight={updateDantoriProductSizeWeight}
                    // dantoriSizeList={dantoriSizeDetailsList}
                    // updatedDantoriSizeList={updateDantoriSizeList}
                    // sizeList={sizesDetailsList}
                    // updatedSizesList={updateProductSizeList}
                    // 段取を個別に持つ
                    // dantoriFlag={dantoriFlag}
                    updateDantoriFlag={updateApportionSelect} // 段取按分しない
                  />
                </>
              )
            }
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // 工程内検査
  const kouteiNaiKensaContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={kouteiNaikensa}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>工程内検査</label>
            {isKouteiNaikensa ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isKouteiNaikensa ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <Device_ProcessInspection
              processName={getWorkTypeName(workType)}
              editMode={editMode}
              // 生産個数
              kosuu={seisanKosuu}
              updatedKosuu={updateSeisanKosuu}
              kosuuList={processInspectionKosuuList}
              updatedKosuuList={updateProcessInspectionKosuuList}
              // 段取設定の生産個数
              dantorikosuuList={seisanKosuuList}
              updatedDantorisKosuuList={updateiq3SeisanKosuuList}
              // 基本段取時間
              inspectionTime={processInspectionTime}
              updatedDantoriTime={updateProcessInspectionTime}
              // 要素数
              yousosuuMasterData={yousosuuData}
              updatedYousosuuMasterData={updateYousosuu}
              kensaJikanKeisuuList={kensaJikanKeisuuList}
              updateKensaJikanKeisuuList={updateKensaJikanKeisuuList}
              // 要素数により、リスト更新
              sagyouKeisuuYousosuuList={sagyouKeisuuYousosuuList}
              updatedYousosuuList={updateYousosuuListData}
              // 要素数により、リスト更新
              shokidankaiYousosuuList={shokiDankaiJikanList}
              updatedShokidankaiYousosuuList={updateshokiDankaiJikanListData}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // マテリアルハンドリング時間
  const materialHandlingContent = (
    <>
      <Row align="middle" style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={materialHandling}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>マテリアルハンドリング時間</label>
            {isMaterialHandling ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isMaterialHandling ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <Device_MaterialHandlingTime
              detailsData={detailsProcess}
              processMstRecord={processMstRecord}
              processName={getWorkTypeName(workType)}
              editMode={editMode}
              // mageSuuData={iq3MageSuuMaster}
              // updatedMagesuuData={updateMagesuuData}
              // mageSuuLst={iq3MageSuuListData}
              // updatedMagesuuList={updateMagesuuList}
              // 重量と面積
              // length={length}
              // updatedLength={updateBendingTimeLength}
              // weight={weight}
              updatedWeight={updateMhWeightThMaster}
              // area={iq3AreaMaster}
              updatedArea={updateMhAreaThMaster}
              // dantoriJuuryouMagechouList={dantoriJuuryouMagechouList}
              // materialHandlingTimeList={materialHandlingTimeList}
              updateMaterialHandlingList={updateMhTimeItems}
              // 段取設定の曲げ係数
              // correctionTimeFactorListData={correctionTimeFactorListData}
              // updatedcorrectionTimeFactorListData={updatecorrectionTimeFactorListData}
              // 製品重量係数
              // iq3SeihinSizeLst={iq3ProductSizeListData}
              // updatediq3ProductSizesList={updateIQ3ProductSizeListData}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );

  // 作業時間
  const sagyouJikanContent = (
    <>
      {/* 作業時間 */}
      <Row align="middle" style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={processTime}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>作業時間</label>
            {isProcessTime ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isProcessTime ? 'block' : 'none' }}>
        <Row style={{ marginLeft: 10 }} className="chargeAmount">
          <Col style={{ width: '20%' }}>
            <RTable>
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th className="tbl-header">
                    {deviceType === processTypes.additional ? '1箇所あたりの作業時間' : '1部品あたりの作業時間'}{' '}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ textAlign: 'center' }}>
                  <td style={{ verticalAlign: 'middle' }}>
                    <Row style={{ marginLeft: 5 }} align="middle">
                      {!editMode ? (
                        <>
                          <Col span={24}>
                            <label>{secondsToHms(workTime)}</label>
                          </Col>
                        </>
                      ) : (
                        <>
                          <Col span={10}>
                            <Input
                              value={secondsToHms(workTime)}
                              onChange={(e) => {
                                setWorkTime(setTimetoSec(e.target.value));
                              }}
                            ></Input>
                          </Col>
                          <Col span={14}>
                            <CaretUpOutlined
                              style={{
                                fontSize: 16 + 'px',
                                marginLeft: 3,
                                pointerEvents: editMode ? 'auto' : 'none',
                              }}
                              onClick={() => {
                                let editedTime = souchiHandleIncreaseTime(secondsToHms(workTime), '10min');
                                setWorkTime(setTimetoSec(editedTime));
                              }}
                            />
                            <CaretDownOutlined
                              style={{
                                fontSize: 17 + 'px',
                                color: '#000',
                                pointerEvents: editMode ? 'auto' : 'none',
                              }}
                              onClick={() => {
                                let editedTime = souchiHandleDecreaseTime(secondsToHms(workTime), '10min');
                                setWorkTime(setTimetoSec(editedTime));
                              }}
                            />
                            <CaretUpOutlined
                              style={{
                                fontSize: 17 + 'px',
                                color: '#000',
                                pointerEvents: editMode ? 'auto' : 'none',
                              }}
                              onClick={() => {
                                let editedTime = souchiHandleIncreaseTime(secondsToHms(workTime), 'min');
                                setWorkTime(setTimetoSec(editedTime));
                              }}
                            />
                            <CaretDownOutlined
                              style={{
                                fontSize: 17 + 'px',
                                color: '#000',
                                pointerEvents: editMode ? 'auto' : 'none',
                              }}
                              onClick={() => {
                                let editedTime = souchiHandleDecreaseTime(secondsToHms(workTime), 'min');
                                setWorkTime(setTimetoSec(editedTime));
                              }}
                            />
                            <CaretUpOutlined
                              style={{
                                fontSize: 17 + 'px',
                                color: '#000',
                                pointerEvents: editMode ? 'auto' : 'none',
                              }}
                              onClick={() => {
                                let editedTime = souchiHandleIncreaseTime(secondsToHms(workTime), 'sec');
                                setWorkTime(setTimetoSec(editedTime));
                              }}
                            />
                            <CaretDownOutlined
                              style={{
                                fontSize: 17 + 'px',
                                color: '#000',
                                pointerEvents: editMode ? 'auto' : 'none',
                              }}
                              onClick={() => {
                                let editedTime = souchiHandleDecreaseTime(secondsToHms(workTime), 'sec');
                                setWorkTime(setTimetoSec(editedTime));
                              }}
                            />
                          </Col>
                        </>
                      )}
                    </Row>
                  </td>
                </tr>
              </tbody>
            </RTable>
          </Col>
        </Row>
      </div>
    </>
  );
  // 曲げ加工時間
  const bendingTimeContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={magekakouJikan}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>作業・加工時間</label>
            {isMagekakouJikan ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isMagekakouJikan ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <Device_Bending
              editMode={editMode}
              detailsData={detailsProcess}
              processMstRecord={processMstRecord}
              itaatsuData={iq3ItaatsuMaster}
              updatedItaatsuData={updateIQ3Itaatsu}
              itaatsuLst={iq3BendingTimeListData}
              updatedItaatsuList={updateBendingTimeList}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // 材料配置
  const materialArrangementContent = (
    <>
      <Row align="middle" style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={arrangeMaterial}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>材料配置</label>
            {isArrangeMaterial ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isArrangeMaterial ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <Device_MaterialArrangement
              editMode={editMode}
              detailsData={detailsProcess}
              updateMaterialArrangement={updateMaterialArrangement}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // パンチ条件
  const punchConditionContent = (
    <>
      <Row align="middle" style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={punch}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>パンチ条件</label>
            {isPunch ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isPunch ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <Device_Blank_PunchCondition
              editMode={editMode}
              detailsData={detailsProcess}
              updateList={updatePunchList}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // 1ピアス加工時間
  const piercingProcessTimeContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={piercingProcess}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>1ピアス加工時間</label>
            {isPiercingProcess ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isPiercingProcess ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <Device_Blank_PiercingProcessTime
              editMode={editMode}
              detailsData={detailsProcess}
              materialTypes={materialTypes}
              // itaatsuData={iq3ItaatsuMaster}
              updatePiercingTimeThickThMaster={updatePiercingTimeThickThMaster}
              // piercingProcessList={piercingProcessItaatsuListData}
              updatePiercingTimeThItems={updatePiercingTimeThItems}
              // // ガス種類
              // gasTypeList={gasTypeListData}
              // updatedGasTypeList={updateGasTypeList}
              // // ジョイント
              // jointItaatsuList={jointItaatsuListData}
              // updatedJointItaatsuList={updateJointItaatsuList}
              // // 加工速度係数
              // kakouSokudoItaatsuList={blankKakouSokudoItaatsuList}
              // updateblankKakouSokudoItaatsuList={updateblankKakouSokudoItaatsuList}
              // // 1パンチ加工時間
              // punchProcessItaatsuListData={punchProcessItaatsuListData}
              // updatePunchProcessList={updatePunchProcessList}
              // // 加工速度
              // blankKakousokudolistData={blankKakousokudolistData}
              // updatedBlankKakousokudolistData={updatedBlankKakousokudolistData}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // 1パンチ加工時間
  const punchProcessTimeContent = (
    <>
      <Row align="middle" style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={punchProcess}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>1パンチ加工時間</label>
            {isPunchProcess ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isPunchProcess ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <Device_Blank_PunchProcessTime
              editMode={editMode}
              // itaatsuData={iq3ItaatsuMaster}
              // updatedItaatsuData={updateIQ3Itaatsu}
              // // 1パンチ加工時間
              // punchProcessItaatsuListData={punchProcessItaatsuListData}
              // updatePunchProcessList={updatePunchProcessList}

              detailsData={detailsProcess}
              materialTypes={materialTypes}
              updatedItaatsu={updatePunchWorkTimeThickThMaster}
              updatePunchProcessList={updatePunchWorkTimeItems}
              // // 1ピアス加工時間
              // piercingProcessList={piercingProcessItaatsuListData}
              // updatePiercingProcessList={updatePiercingProcessList}
              // // ガス種類
              // gasTypeList={gasTypeListData}
              // updatedGasTypeList={updateGasTypeList}
              // // ジョイント
              // jointItaatsuList={jointItaatsuListData}
              // updatedJointItaatsuList={updateJointItaatsuList}
              // // 加工速度係数
              // kakouSokudoItaatsuList={blankKakouSokudoItaatsuList}
              // updateblankKakouSokudoItaatsuList={updateblankKakouSokudoItaatsuList}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // 曲げ時間係数
  const bendingTimeFactorContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={bendingTimeFactor}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>曲げ時間係数</label>
            {isBendingTimeFactor ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isBendingTimeFactor ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <Device_BendingTimeFactor
              detailsData={detailsProcess}
              processName={workType}
              editMode={editMode}
              // length={length}
              updatedLength={updateBendTimeCoeffLenThMaster}
              // weight={weight}
              updatedWeight={updateBendTimeCoeffWeightThMaster}
              // 曲げ時間係数リスト
              // bendingTimeFactorLst={bendingCorrectionTimeFactorListData}
              updatedSizesList={updateBendingCorrectionTimeFactorListData}
              // 曲げ補正時間係数
              // bendingCorrectionTimeFactorLst={bendingTimeFactorListData}
              // updatedbendingCorrectionTimeFactorLst={updateBendingTimeFactorListData}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // 曲げ補正時間
  const bendingCorrectionTimeContent = (
    <>
      <Row align="middle" style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={mageHoseiJikan}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>
              {/* {workType === WorkType.SmBending ? '曲げ補正時間' : '加工補正時間'} */}加工補正時間
            </label>
            {isMageHoseiJikan ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isMageHoseiJikan ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <Device_BendingCorrectionTime
              editMode={editMode}
              detailsData={detailsProcess}
              processName={workType}
              // honSuu={honSuu}
              updateHonsuu={updateModifyTimeQtyThMaster}
              // honsuuList={iq3BendingHonsuuList}
              updateHonsuuList={updateModifyTimeItems}
              // updatedSeisanKosuuList={updateKosuuList}
              //追加
              // processName={getWorkTypeName(workType)}
              // length={length}
              updatedLength={updateModifyCoeffLenThMaster}
              // weight={weight}
              updatedWeight={updateModifyCoeffWeightThMaster}
              updatedWorkWeight={updateMtWeightThMaster}
              // 曲げ補正リスト
              // bendingTimeFactorLst={bendingTimeFactorListData}
              updatedSizesList={updateModifyCoeffItems}
              // 補正リスト
              // area={iq3AreaMaster}
              updatedArea={updateMtAreaThMaster}
              // correctionTimeFactorListData={correctionTimeFactorListData}
              updatedworkCorrectionTimeListData={updatedworkCorrectionTimeListData}
              // 製品重量係数
              iq3SeihinSizeLst={iq3ProductSizeListData}
              updatediq3ProductSizesList={updateIQ3ProductSizeListData}
              // マテリアルハンドリング時間
              materialHandlingTimeList={materialHandlingTimeList}
              updateMaterialHandlingList={updateMaterialHandlingList}
              listData={iq3KakouJikanListData}
              updatedIQ3KakouJikanList={updateIQ3KakouJikanList}
              kosuu={kosuu}
              updatedKosuu={updateKosuu}
              iq3BendingKosuuList={iq3BendingKosuuList}
              updateIQ3BendingKosuuList={updateIQ3BendingKosuuList}
              seisanKosuuList={kosuuList}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // 曲げ補正時間係数
  const bendingCorrectionTimeFactorContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={mageHoseiJikanFactor}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>
              {/* 2次加工、バリ取 */}
              {workType === WorkType.SmSecondaryWork ||
              workType === WorkType.SmDeburringAuto ||
              workType === WorkType.SmDeburringManual
                ? '補正時間係数'
                : '曲げ補正時間係数'}
            </label>
            {isMageHoseiJikanFactor ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isMageHoseiJikanFactor ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <Device_BendingCorrectionTimeFactor
              processName={getWorkTypeName(workType)}
              editMode={editMode}
              area={iq3AreaMaster}
              updatedArea={updateBendingTimeArea}
              length={length}
              updatedLength={updateBendingTimeLength}
              weight={weight}
              updatedWeight={updateProductSizeWeight}
              // 曲げ補正リスト
              bendingTimeFactorLst={bendingTimeFactorListData}
              updatedSizesList={updateBendingTimeFactorListData}
              // 補正リスト
              correctionTimeFactorListData={correctionTimeFactorListData}
              updatedcorrectionTimeFactorListData={updatecorrectionTimeFactorListData}
              // 製品重量係数
              iq3SeihinSizeLst={iq3ProductSizeListData}
              updatediq3ProductSizesList={updateIQ3ProductSizeListData}
              // マテリアルハンドリング時間
              materialHandlingTimeList={materialHandlingTimeList}
              updateMaterialHandlingList={updateMaterialHandlingList}
              listData={iq3KakouJikanListData}
              updatedIQ3KakouJikanList={updateIQ3KakouJikanList}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // 製品重量係数
  const productSizeFactorContent = (
    <>
      <Row align="middle" style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={processSizeFactor}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>サイズ係数</label>
            {isProcessSizeFactor ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isProcessSizeFactor ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <Device_ProductSize
              detailsData={detailsProcess}
              editMode={editMode}
              // area={iq3AreaMaster}
              updatedArea={updateProdSizeAreaThMaster}
              // weight={weight}
              updatedWeight={updateProdSizeWeightThMaster}
              // iq3SeihinSizeLst={iq3ProductSizeListData}
              // updatediq3ProductSizesList={updateIQ3ProductSizeListData}
              updatediq3ProductSizesList={updateProdSizeItems}
              // 補正時間係数
              bendingTimeFactorLst={bendingTimeFactorListData}
              updatedSizesList={updateBendingTimeFactorListData}
              // bendingTimeFactorLst={correctionTimeFactorListData}
              // updatedSizesList={updatecorrectionTimeFactorListData}
              // 段取設定の曲げ係数
              correctionTimeFactorListData={correctionTimeFactorListData}
              updatedcorrectionTimeFactorListData={updatecorrectionTimeFactorListData}
              // マテリアルハンドリング時間
              materialHandlingTimeList={materialHandlingTimeList}
              updateMaterialHandlingList={updateMaterialHandlingList}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // 加工速度
  const machineSpeedContent = (
    <>
      <Row align="middle" style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={processSpeed}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>加工速度(mm/min)</label>
            {isProcessSpeed ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isProcessSpeed ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <MachineSpeed
              detailsData={detailsProcess}
              processMstRecord={processMstRecord}
              // 工程タイプ
              processName={workType}
              // 工程マスタ種類
              processTypes={processTypesList}
              editMode={editMode}
              //　線長マスター更新
              detailsWeldLine={detailsWeldLine}
              updatedYousetsuSenchou={updatedYousetsuSenchouData}
              //溶接
              weldLineLst={yousetsuWeldLineList}
              updatedYousetsuSenchouList={updateYousetsuSenchouList}
              // 溶接仕上
              // senchouList={kakouSokudoSenchouList}
              updatedSenchouList={updateKakousokudoSenchouList}
              // detailsSenchou={detailsWeldLine}
              // updatedItaatsu={upadateKaritsukiItaatsuData}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // 作業速度
  const sagyouSokudoContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={sagyouSokudo}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>加工設定</label>
            {isSagyouSokudo ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isSagyouSokudo ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <Row style={{ marginLeft: 10 }} className="chargeAmount">
              <Col style={{ width: '20%' }}>
                <RTable>
                  <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                    <tr style={{ textAlign: 'center' }}>
                      <th className="tbl-header">作業時間（x要素数）</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>
                        <Row align="middle">
                          {!editMode ? (
                            <>
                              <Col span={24}>
                                <label>{secondsToHms(sagyouTime)}</label>
                              </Col>
                            </>
                          ) : (
                            <>
                              <Col span={10}>
                                <Input
                                  value={secondsToHms(sagyouTime)}
                                  onChange={(e) => {
                                    setSagyouTime(e.target.value);
                                  }}
                                  onKeyPress={(e) => {
                                    handleKeyPress(e, false);
                                  }}
                                ></Input>
                              </Col>
                              <Col span={14}>
                                <CaretUpOutlined
                                  style={{
                                    fontSize: 17 + 'px',
                                    marginLeft: 3,
                                    pointerEvents: editMode ? 'auto' : 'none',
                                  }}
                                  onClick={() => {
                                    let editedTime = souchiHandleIncreaseTime(secondsToHms(sagyouTime), '10min');
                                    setSagyouTime(setTimetoSec(editedTime));
                                  }}
                                />
                                <CaretDownOutlined
                                  style={{
                                    fontSize: 17 + 'px',
                                    color: '#000',
                                    pointerEvents: editMode ? 'auto' : 'none',
                                  }}
                                  onClick={() => {
                                    let editedTime = souchiHandleDecreaseTime(secondsToHms(sagyouTime), '10min');
                                    setSagyouTime(setTimetoSec(editedTime));
                                  }}
                                />
                                <CaretUpOutlined
                                  style={{
                                    fontSize: 17 + 'px',
                                    color: '#000',
                                    pointerEvents: editMode ? 'auto' : 'none',
                                  }}
                                  onClick={() => {
                                    let editedTime = souchiHandleIncreaseTime(secondsToHms(sagyouTime), 'min');
                                    setSagyouTime(setTimetoSec(editedTime));
                                  }}
                                />
                                <CaretDownOutlined
                                  style={{
                                    fontSize: 17 + 'px',
                                    color: '#000',
                                    pointerEvents: editMode ? 'auto' : 'none',
                                  }}
                                  onClick={() => {
                                    let editedTime = souchiHandleDecreaseTime(secondsToHms(sagyouTime), 'min');
                                    setSagyouTime(setTimetoSec(editedTime));
                                  }}
                                />
                                <CaretUpOutlined
                                  style={{
                                    fontSize: 17 + 'px',
                                    color: '#000',
                                    pointerEvents: editMode ? 'auto' : 'none',
                                  }}
                                  onClick={() => {
                                    let editedTime = souchiHandleIncreaseTime(secondsToHms(sagyouTime), 'sec');
                                    setSagyouTime(setTimetoSec(editedTime));
                                  }}
                                />
                                <CaretDownOutlined
                                  style={{
                                    fontSize: 17 + 'px',
                                    color: '#000',
                                    pointerEvents: editMode ? 'auto' : 'none',
                                  }}
                                  onClick={() => {
                                    let editedTime = souchiHandleDecreaseTime(secondsToHms(sagyouTime), 'sec');
                                    setSagyouTime(setTimetoSec(editedTime));
                                  }}
                                />
                              </Col>
                            </>
                          )}
                        </Row>
                      </td>
                    </tr>
                  </tbody>
                </RTable>
              </Col>
            </Row>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // 作業係数など
  const sagyouKeisuuContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={sagyouKeisuu}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>係数・その他 </label>
            {isSagyouKeisuu ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isSagyouKeisuu ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <Device_Sagyoukeisuu
              editMode={editMode}
              processType={workType}
              detailsData={detailsProcess}
              yousosuuMasterData={yousosuuData}
              updatedYousosuuMasterData={updateYousosuu}
              sagyouKeisuuYousosuuList={sagyouKeisuuYousosuuList}
              updatedYousosuuList={updateYousosuuListData}
              // 要素数により、リスト更新
              shokidankaiYousosuuList={shokiDankaiJikanList}
              updatedShokidankaiYousosuuList={updateshokiDankaiJikanListData}
              kensaJikanKeisuuList={kensaJikanKeisuuList}
              updateKensaJikanKeisuuList={updateKensaJikanKeisuuList}
              // 同一サイズ、同一形状をパターン化する
              programSameSizePatternValue={programSameSizePatternValue}
              updatedProgramSameSizePatternValue={updateProgramSameSizePatternValue}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // iq3段取時間
  const iq3DantoriJikanContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={iq3DantoriJikan}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>段取時間</label>
            {isiq3DantoriJikan ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
            <label style={{ marginLeft: 10 }}>(分)</label>
          </div>
        </Col>
      </Row>
      <div style={{ display: isiq3DantoriJikan ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <Device_DantoriJikan
              editMode={editMode}
              kosuu={seisanKosuu}
              updatedKosuu={updateSeisanKosuu}
              kosuuList={seisanKosuuList}
              updatedKosuuList={updateiq3KosuuList}
              // 基本段取時間
              dantoriTime={kihonDantori}
              updatedDantoriTime={updateDantoriTime}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // iq3作業・加工時間
  const iq3KakouJikanContent = (
    <>
      <Row align="middle" style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={iq3KakouJikan}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>{workType === WorkType.SmShearing ? '作業・加工時間' : '加工時間'}</label>
            {isiq3KakouJikan ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isiq3KakouJikan ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <Device_KakouJikan
              detailsData={detailsProcess}
              editMode={editMode}
              processType={workType}
              processMstRecord={processMstRecord}
              materialTypes={materialTypes}
              // area={iq3AreaMaster}
              updatedArea={updateAreaThMaster}
              // listData={iq3KakouJikanListData}
              updatedIQ3KakouJikanList={updateWorkTimeThItems}
              // processName={getWorkTypeName(workType)}
              area={iq3AreaMaster}
              // updatedArea={updateIQ3AreaMaster}
              listData={iq3KakouJikanListData}
              // updatedIQ3KakouJikanList={updateIQ3KakouJikanList}
              // 補正リスト
              // correctionTimeFactorListData={correctionTimeFactorListData}
              // updatedcorrectionTimeFactorListData={updatecorrectionTimeFactorListData}
              // 二次加工
              iq3SizeMaster={iq3SizeMaster}
              updatediq3SizeMaster={updatediq3SizeMaster}
              iq3KaniListData={iq3KaniListData}
              updatediq3KaniListData={updatediq3KaniListData}
              iq3KaniAnaListData={iq3KaniAnaListData}
              mmMaster={mmMaster}
              updateSizeMmMaster={updateSizeMmMaster}
              updateiq3KaniAnaListData={updateiq3KaniAnaListData}
              itaatsu1={itaatsu1}
              updatedSizeItaatsu={updateSizeItaatsu}
              updatedMmItaatsu={updateMmItaatsu}
              // zaishitsu={zaishitsu}
              shirringflag={shirringflag}
              updateShirringFlag={updateShirringFlag}
              updateCutTime={updateCutTime}
              cutTime={cutTime}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // ブランク加工速度
  const BlankKakouSokudoContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={blankKakouSokudo}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>加工速度(mm/min)</label>
            {isBlankKakouSokudo ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isBlankKakouSokudo ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <Device_Blank_Kakousokudo
              editMode={editMode}
              processName={workType}
              detailsData={detailsProcess}
              materialTypes={materialTypes}
              // itaatsu={iq3ItaatsuMaster}
              updatedItaatsu={updateWorkSpeedThickThMaster}
              // blankKakousokudolistData={blankKakousokudolistData}
              updatedBlankKakousokudolistData={updatedWorkSpeedItems}
              // // 1パンチ加工時間
              // punchProcessItaatsuListData={punchProcessItaatsuListData}
              // updatePunchProcessList={updatePunchProcessList}
              // // 1ピアス加工時間
              // piercingProcessList={piercingProcessItaatsuListData}
              // updatePiercingProcessList={updatePiercingProcessList}
              // // ガス種類
              // gasTypeList={gasTypeListData}
              // updatedGasTypeList={updateGasTypeList}
              // // ジョイント
              // jointItaatsuList={jointItaatsuListData}
              // updatedJointItaatsuList={updateJointItaatsuList}
              // // 加工速度係数
              // kakouSokudoItaatsuList={blankKakouSokudoItaatsuList}
              // updateblankKakouSokudoItaatsuList={updateblankKakouSokudoItaatsuList}
              okuriSokudoVal={okuriSokudoVal}
              updateOkuriSokudoVal={updateOkuriSokudoVal}
              kegakiSokudoVal={kegakiSokudoVal}
              updateKegakiSokudoVal={updateKegakiSokudoVal}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // ブランク加工速度係数
  const BlankKakouSokudoKeisuuContent = (
    <>
      <Row align="middle" style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={blankKakouSokudoKeisuu}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>加工速度係数</label>
            {isBlankKakouSokudoKeisuu ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isBlankKakouSokudoKeisuu ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <Device_Blank_KakouSokudoKeisuu
              editMode={editMode}
              detailsData={detailsProcess}
              // itaatsuData={iq3ItaatsuMaster}
              updatedItaatsuData={updateWorkSpeedCoeffThickThMaster}
              // kakouSokudoItaatsuList={blankKakouSokudoItaatsuList}
              updateWorkSpeedCoeffInternalWorkItems={updateWorkSpeedCoeffInternalWorkItems}
              updateWorkSpeedCoeffExternalWorkItems={updateWorkSpeedCoeffExternalWorkItems}
              // // ジョイント
              // jointItaatsuList={jointItaatsuListData}
              // updatedJointItaatsuList={updateJointItaatsuList}
              // // ガス種類
              // gasTypeList={gasTypeListData}
              // updatedGasTypeList={updateGasTypeList}
              // // 1ピアス加工時間
              // piercingProcessList={piercingProcessItaatsuListData}
              // updatePiercingProcessList={updatePiercingProcessList}
              // // 1パンチ加工時間
              // punchProcessItaatsuListData={punchProcessItaatsuListData}
              // updatePunchProcessList={updatePunchProcessList}
              // // 加工速度
              // blankKakousokudolistData={blankKakousokudolistData}
              // updatedBlankKakousokudolistData={updatedBlankKakousokudolistData}
              // // 手動バリの加工速度
              // shudobariKakousokudoList={shudobariKakousokudoList}
              // updateShudobariKakousokudoList={updateShudobariKakousokudoList}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // ブランクガス係数
  const BlankGasuKeisuuContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={blankGasuKeisuu}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>ガス係数</label>
            {isBlankGasuKeisuu ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isBlankGasuKeisuu ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <Device_Blank_GasKeisuu
              editMode={editMode}
              detailsData={detailsProcess}
              materialTypes={materialTypes}
              // gasTypeData={gasTypeMaster}
              updatedGasType={updateGasCoeffThMaster}
              // gasKeisuuList={gasKeisuuList}
              updateGasKeisuuList={updateGasCoeffListItems}
              // ガス種類
              // itaatsuData={iq3ItaatsuMaster}
              updateGasCoeffThickThMaster={updateGasCoeffThickThMaster}
              // gasTypeList={gasTypeListData}
              updateGasCoeffThItems={updateGasCoeffThItems}
              // // ジョイント
              // jointItaatsuList={jointItaatsuListData}
              // updatedJointItaatsuList={updateJointItaatsuList}
              // // 加工速度係数
              // kakouSokudoItaatsuList={blankKakouSokudoItaatsuList}
              // updateblankKakouSokudoItaatsuList={updateblankKakouSokudoItaatsuList}
              // // 1ピアス加工時間
              // piercingProcessList={piercingProcessItaatsuListData}
              // updatePiercingProcessList={updatePiercingProcessList}
              // // 1パンチ加工時間
              // punchProcessItaatsuListData={punchProcessItaatsuListData}
              // updatePunchProcessList={updatePunchProcessList}
              // // 加工速度
              // blankKakousokudolistData={blankKakousokudolistData}
              // updatedBlankKakousokudolistData={updatedBlankKakousokudolistData}
            />
            {/* <Device_Blank_GasType
              editMode={editMode}
              itaatsuData={iq3ItaatsuMaster}
              updatedItaatsuData={updateIQ3Itaatsu}
              gasTypeList={gasTypeListData}
              updatedGasTypeList={updateGasTypeList}
              // ジョイント
              jointItaatsuList={jointItaatsuListData}
              updatedJointItaatsuList={updateJointItaatsuList}
              // 加工速度係数
              kakouSokudoItaatsuList={blankKakouSokudoItaatsuList}
              updateblankKakouSokudoItaatsuList={updateblankKakouSokudoItaatsuList}
              // 1ピアス加工時間
              piercingProcessList={piercingProcessItaatsuListData}
              updatePiercingProcessList={updatePiercingProcessList}
              // 1パンチ加工時間
              punchProcessItaatsuListData={punchProcessItaatsuListData}
              updatePunchProcessList={updatePunchProcessList}
              // 加工速度
              blankKakousokudolistData={blankKakousokudolistData}
              updatedBlankKakousokudolistData={updatedBlankKakousokudolistData}
            /> */}
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // ブランク外形追抜条件
  const extractConditionContent = (
    <>
      <Row align="middle" style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={extractCondition}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>外形追抜条件</label>
            {isExtractCondition ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isExtractCondition ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <Device_Blank_ExtractCondition
              editMode={editMode}
              detailsData={detailsProcess}
              // retainedMold={retainedMoldMaster}
              updatedRetainedMold={updatedRetainedMold}
              // extractConditionList={extractConditionList}
              updateExtractConditionList={updateExtractConditionList}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // ブランクガス種類
  const BlankGasuTypeContent = (
    <>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={blankGasuType}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>ガス種類</label>
            {isBlankGasuType ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isBlankGasuType ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <Device_Blank_GasType
              editMode={editMode}
              itaatsuData={iq3ItaatsuMaster}
              updatedItaatsuData={updateIQ3Itaatsu}
              gasTypeList={gasTypeListData}
              updatedGasTypeList={updateGasTypeList}
              // ジョイント
              jointItaatsuList={jointItaatsuListData}
              updatedJointItaatsuList={updateJointItaatsuList}
              // 加工速度係数
              kakouSokudoItaatsuList={blankKakouSokudoItaatsuList}
              updateblankKakouSokudoItaatsuList={updateblankKakouSokudoItaatsuList}
              // 1ピアス加工時間
              piercingProcessList={piercingProcessItaatsuListData}
              updatePiercingProcessList={updatePiercingProcessList}
              // 1パンチ加工時間
              punchProcessItaatsuListData={punchProcessItaatsuListData}
              updatePunchProcessList={updatePunchProcessList}
              // 加工速度
              blankKakousokudolistData={blankKakousokudolistData}
              updatedBlankKakousokudolistData={updatedBlankKakousokudolistData}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // ブランク2工程穴加工時間
  const BlankDrillingTimeContent = (
    <>
      <Row align="middle" style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={blankDrillingTime}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>2工程穴加工時間</label>
            {isBlankDrillingTime ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isBlankDrillingTime ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <Device_Blank_DrillingTime
              editMode={editMode}
              // drillingTime={drillingTime}
              detailsData={detailsProcess}
              updateDrillingTime={updateDrillingTime}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // ブランク送り速度
  const okuriSokudoContent = (
    <>
      <Row align="middle" style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={okuriSokudo}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>送り速度(mm/min)</label>
            {isOkuriSokudo ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isOkuriSokudo ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <Device_Blank_OkuriSokudo
              editMode={editMode}
              // forwardSpeed={forwardSpeed}
              detailsData={detailsProcess}
              updateForwardSpeed={updateForwardSpeed}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // ローラー/ホイール/ケガキ金型　加工速度
  const rollerKakouSokudoContent = (
    <>
      <Row align="middle" style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={rollerKakouSokudo}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>ローラー/ホイール/ケガキ金型　加工速度(mm/sec)</label>
            {isRollerKakouSokudo ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isRollerKakouSokudo ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <Device_Blank_RollerSpeed
              editMode={editMode}
              // rollerSpeed={rollerSpeed}
              detailsData={detailsProcess}
              updateRollerSpeed={updateRollerSpeed}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // ブランクジョイント
  const BlankJointContent = (
    <>
      <Row align="middle" style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={blankJoint}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>ジョイントバラシ</label>
            {isBlankJoint ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isBlankJoint ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <Device_Blank_Joint
              editMode={editMode}
              materialTypes={materialTypes}
              detailsData={detailsProcess}
              // itaatsuData={iq3ItaatsuMaster}
              updatedItaatsuData={updateJointSeparateThickThMaster}
              // jointItaatsuList={jointItaatsuListData}
              updatedJointItaatsuList={updateJointSeparateItems}
              // サイズc
              // jointSizeMaster={jointSizeMaster}
              updateJointQtySizeThMaster={updateJointQtySizeThMaster}
              // jointSizeList={jointSizeList}
              updateJointQtyItems={updateJointQtyItems}
              // // ガス種類
              // gasTypeList={gasTypeListData}
              // updatedGasTypeList={updateGasTypeList}
              // // 加工速度係数
              // kakouSokudoItaatsuList={blankKakouSokudoItaatsuList}
              // updateblankKakouSokudoItaatsuList={updateblankKakouSokudoItaatsuList}
              // // 1ピアス加工時間
              // piercingProcessList={piercingProcessItaatsuListData}
              // updatePiercingProcessList={updatePiercingProcessList}

              // // 加工速度
              // blankKakousokudolistData={blankKakousokudolistData}
              // updatedBlankKakousokudolistData={updatedBlankKakousokudolistData}
              // // 1パンチ加工時間
              // punchProcessItaatsuListData={punchProcessItaatsuListData}
              // updatePunchProcessList={updatePunchProcessList}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // iq3加工速度
  const iq3KakousokudoContent = (
    <>
      <Row align="middle" style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={iq3KakouSokudo}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>加工速度(mm/min)</label>
            {isiq3KakouSokudo ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isiq3KakouSokudo ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <Device_KakouSokudo
              editMode={editMode}
              detailsData={detailsProcess}
              // 手動バリの加工速度
              // itaatsuData={iq3ItaatsuMaster}
              updatedItaatsuData={updateWorkSpeedThickThMaster}
              // iq3kakouchou={iq3kakouchou}
              updateWorkSpeedLenThMaster={updateWorkSpeedLenThMaster}
              // shudobariKakousokudoList={shudobariKakousokudoList}
              updateShudobariKakousokudoList={updateWorkSpeedItems}
              // 加工速度係数
              kakouSokudoItaatsuList={blankKakouSokudoItaatsuList}
              updateblankKakouSokudoItaatsuList={updateblankKakouSokudoItaatsuList}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // iq3手動バリ取対象サイズ
  const iq3ManualBariContent = (
    <>
      <Row align="middle" style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={iq3ManualBari}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>手動バリ取対象サイズ</label>
            {isiq3ManualBari ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isiq3ManualBari ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <Device_ManualBari
              editMode={editMode}
              detailsData={detailsProcess}
              updateRoundHoleDia={updateRoundHoleDia}
              updateRectangleDia={updateRectangleDia}
              // naikei_min={naikei_min}
              // updateNaikeiMin={updateNaikeiMin}
              // naikei_max={naikei_max}
              // updateNaikeiMax={updateNaikeiMax}
              // ana_max={ana_max}
              // updateAnaMax={updateAnaMax}
              // ana_min={ana_min}
              // updateAnaMin={updateAnaMin}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // 仮付時間
  const karitsukiTimeContent = (
    <>
      <Row align="middle" style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={attach}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>仮付時間</label>
            {isAttach ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isAttach ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <KaritsukiTime
              editMode={editMode}
              // 工程マスタ種類
              processTypes={processTypesList}
              detailsData={detailsProcess}
              processMstRecord={processMstRecord}
              // detailsItaatsu={karitsukiTimeItaatsuMasterData}
              updatedItaatsu={upadateKaritsukiItaatsuData}
              // listData={karitsukiTimeItaatsuList}
              updatedListData={updateKaritsukiTimeList}
              // 板厚データにより、取付き時間の板厚リスト更新
              // itaatsuList={toritsukiTimeItaatsuList}
              // updatedItaatsuList={updateToritsukiItaatsuList}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );

  // 取付＿板厚
  const toritsukiTimeItaatsuContent = (
    <>
      <Row align="middle" style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={toritsukiJikanItaatsu}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>取付時間(板厚判断)</label>
            {isToritsukiJikanItaatsu ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isToritsukiJikanItaatsu ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <ToritsukiJikan_Itaatsu
              editMode={editMode}
              // 工程マスタ種類
              processTypes={processTypesList}
              detailsData={detailsProcess}
              processMstRecord={processMstRecord}
              updatedItaatsu={updateInstThickThMaster}
              updatedListData={updateInstThickThItems}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // 取付＿サイズ
  const toritsukiTimeSizeContent = (
    <>
      <Row align="middle" style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={toritsukiJikanSize}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>取付時間(サイズ判断)</label>
            {isToritsukiJikanSize ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isToritsukiJikanSize ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <ToritsukiJikan_Size
              editMode={editMode}
              processTypes={processTypesList}
              detailsData={detailsProcess}
              processMstRecord={processMstRecord}
              updatedSize={updateInstSizeThMaster}
              updatedSizeList={updateInstSizeThItems}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // 溶接難易度
  const yousetsuDifficultyContent = (
    <>
      <Row align="middle" style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={welding}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>溶接難易度</label>
            {isWelding ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isWelding ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <YousetsuDifficulty
              editMode={editMode}
              updatedItaatsu={updateWeldDifficultyCoeffThMaster}
              updatedListData={updatedWeldDifficultyCoeffThItems}
              detailsData={detailsProcess}
              materialTypes={materialTypes}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // タップ溶接
  const tappuYousetsuContent = (
    <>
      <Row align="middle" style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={tapWelding}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>タップ溶接(%/辺)</label>
            {isTapWelding ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isTapWelding ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <TapWelding
              editMode={editMode}
              detailsData={detailsProcess}
              materialTypes={materialTypes}
              updatedItaatsu={updateTapRateThMaster}
              updatedItaatsuList={updateTapRateThItems}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // 点付、仮付ピッチ
  const pitchContent = (
    <>
      <Row align="middle" style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={pitch}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>点付、仮付ピッチ(mm/辺)</label>
            {isPitch ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isPitch ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <Pitch
              editMode={editMode}
              updatedItaatsu={updateSpotPitchThMaster}
              updatedItaatsuList={updateSpotPitchThItems}
              detailsData={detailsProcess}
              // 材質区分データ
              materialTypes={materialTypes}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );

  // 製品重量係数
  const productSizeContent = (
    <>
      <Row align="middle" style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={productSize}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>重量係数</label>
            {isProductSize ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isProductSize ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            {/* 重量係数 */}
            <ProductSize
              detailsData={detailsProcess}
              editMode={editMode}
              // kosuu={kosuu}
              updatedMstJuuryouData={updateProductWeightCoeffMst}
              updatedJuuryouList={updateProductWeightCoeffList}
              // weight={weight}
              // updatedWeight={updateProductSizeWeight}
              // sizeList={sizesDetailsList}
              // updatedSizesList={updateKosuuList}
              // dantoriSizeList={dantoriSizeDetailsList}
              // updatedDantoriSizeList={updateDantoriSizeList}
              // 生産個数係数リスト更新
              // kosuuList={kosuuList}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // 生産個数係数
  const quantityContent = (
    <>
      <Row align="middle" style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={productQuantity}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>生産個数係数</label>
            {isProductQuantity ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isProductQuantity ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <Quantity
              detailsData={detailsProcess}
              editMode={editMode}
              // kosuu={kosuu}
              updatedKosuu={updateProductionQtyCoeffMst}
              // kosuuList={kosuuList}
              updatedKosuuList={updateProductionQtyCoeffList}
              // ２次加工の補正時間
              // jikankosuuList={iq3BendingHonsuuList}
              // updatedJikanKosuuList={updateiq3BendingHonsuuList}
              // 重量係数
              // sizeList={sizesDetailsList}
              // updatedSizesList={updateProductSizeList}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );

  // 作業・加工時間
  const childPartContent = (
    <>
      <Row align="middle" style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={childPart}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>作業・加工時間</label>
            {isChildPart ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isChildPart ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <Row className="chargeAmount" style={{ marginLeft: 10, marginRight: 10 }}>
              <RTable style={{ width: '20%' }}>
                <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                  <tr style={{ textAlign: 'center' }}>
                    <th className="tbl-header">1検査時間 </th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ textAlign: 'center', height: 35 }}>
                    <td>
                      <Row style={{ textAlign: 'center' }} align="middle">
                        {!editMode ? (
                          <>
                            <Col span={24}>
                              <label>{secondsToHms(processTimeByUnit)}</label>
                            </Col>
                          </>
                        ) : (
                          <>
                            <Col span={10}>
                              <Input
                                value={secondsToHms(processTimeByUnit)}
                                onChange={(event) => changeTime(event.target.value)}
                              ></Input>
                            </Col>
                            <Col span={14}>
                              <CaretUpOutlined
                                style={{
                                  fontSize: 17 + 'px',
                                  marginLeft: 3,
                                  pointerEvents: 'auto',
                                }}
                                onClick={() => {
                                  let editedTime = souchiHandleIncreaseTime(secondsToHms(processTimeByUnit), '10min');
                                  changeTime(editedTime);
                                }}
                              />
                              <CaretDownOutlined
                                style={{
                                  fontSize: 17 + 'px',
                                  color: '#000',
                                  pointerEvents: 'auto',
                                }}
                                onClick={() => {
                                  let editedTime = souchiHandleDecreaseTime(secondsToHms(processTimeByUnit), '10min');
                                  changeTime(editedTime);
                                }}
                              />
                              <CaretUpOutlined
                                style={{
                                  fontSize: 17 + 'px',
                                  color: '#000',
                                  pointerEvents: 'auto',
                                }}
                                onClick={() => {
                                  let editedTime = souchiHandleIncreaseTime(secondsToHms(processTimeByUnit), 'min');
                                  changeTime(editedTime);
                                }}
                              />
                              <CaretDownOutlined
                                style={{
                                  fontSize: 17 + 'px',
                                  color: '#000',
                                  pointerEvents: 'auto',
                                }}
                                onClick={() => {
                                  let editedTime = souchiHandleDecreaseTime(secondsToHms(processTimeByUnit), 'min');
                                  changeTime(editedTime);
                                }}
                              />
                              <CaretUpOutlined
                                style={{
                                  fontSize: 17 + 'px',
                                  color: '#000',
                                  pointerEvents: 'auto',
                                }}
                                onClick={() => {
                                  let editedTime = souchiHandleIncreaseTime(secondsToHms(processTimeByUnit), 'sec');
                                  changeTime(editedTime);
                                }}
                              />
                              <CaretDownOutlined
                                style={{
                                  fontSize: 17 + 'px',
                                  color: '#000',
                                  pointerEvents: 'auto',
                                }}
                                onClick={() => {
                                  let editedTime = souchiHandleDecreaseTime(secondsToHms(processTimeByUnit), 'sec');
                                  changeTime(editedTime);
                                }}
                              />
                            </Col>
                          </>
                        )}
                      </Row>
                    </td>
                  </tr>
                </tbody>
              </RTable>
            </Row>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
  // 難易度係数
  const difficultyFactorContent = (
    <>
      <Row align="middle" style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={difficultyFactor}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>難易度係数</label>
            {isDifficultyFactor ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isDifficultyFactor ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <Device_YousetsuShiage_DifficultyFactor
              detailsData={detailsProcess}
              materialTypes={materialTypes}
              editMode={editMode}
              updateDiffFactorCoeff={updateDiffFactorCoeff}
            />
          </>
        ) : (
          <></>
        )}{' '}
      </div>
    </>
  );
  // 仕上有無
  const shiageFlagContent = (
    <>
      <Row align="middle" style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={shiageFlag}>
        <Col span={20} className="deli-col">
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>仕上有無</label>
            {isShiageFlag ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
          </div>
        </Col>
      </Row>
      <div style={{ display: isShiageFlag ? 'block' : 'none' }}>
        {selectedDeviceData?.length != 0 ? (
          <>
            <Device_ShiageFlag
              detailsData={detailsProcess}
              processMstRecord={processMstRecord}
              yousetsuTypes={yousetsuTypes}
              editMode={editMode}
              // shiageFlagList={shiageFlagList}
              updateShiageFlagList={updateShiageFlagList}
            />
          </>
        ) : (
          <></>
        )}{' '}
      </div>
    </>
  );

  // 工程種類により、Propertiesの情報を判断する
  const content =
    // 溶接の場合、
    workType === WorkType.PaWelding || workType === WorkType.SmWelding ? (
      <>
        <div>
          {chargeAmountContent}
          {dantoriSettingContent}
          {/* {types === '親部品' ? '' : materialHandlingContent} */}
          {machineSpeedContent}
          {karitsukiTimeContent}
          {toritsukiTimeItaatsuContent}
          {toritsukiTimeSizeContent}
          {/* {tappuYousetsuContent}
          {pitchContent} */}
          {yousetsuDifficultyContent}
          {/* iQ3 */}
          {types === ServiceClass.SheetMetal ? (
            <>
              {/* {materialHandlingContent} */}
              {productSizeFactorContent}
              {quantityContent}
              {/* {kouteiNaiKensaContent} */}
            </>
          ) : (
            <>
              {productSizeContent}
              {quantityContent}
            </>
          )}
        </div>
      </>
    ) : workType === WorkType.PaWeldFinish || workType === WorkType.SmWeldFinish ? (
      // 溶接仕上の場合、
      <div>
        {chargeAmountContent}
        {dantoriSettingContent}
        {/* iQ3 */}
        {types === ServiceClass.SheetMetal ? (
          <>
            {machineSpeedContent}
            {difficultyFactorContent}
            {shiageFlagContent}
            {/*  {materialHandlingContent} */}
            {productSizeFactorContent}
            {quantityContent}
            {/* {kouteiNaiKensaContent} */}
          </>
        ) : (
          <>
            {machineSpeedContent}
            {productSizeContent}
            {quantityContent}
          </>
        )}
      </div>
    ) : workType === WorkType.PaSurface || workType === WorkType.SmSurface ? (
      // 表面処理の場合、
      <>
        <div>
          {/* チャージ金額 */}
          {chargeAmountContent}
          {/* 段取設定 */}
          {dantoriSettingContent}
          {/*  {types === ServiceClass.SheetMetal ? materialHandlingContent : ''} */}
          {/* 製品重量係数 */}
          {productSizeContent}
          {/* 生産個数係数 */}
          {quantityContent}
          {/* iQ3 */}
          {/* {types === ServiceClass.SheetMetal ? kouteiNaiKensaContent : ''} */}
        </div>
      </>
    ) : workType === WorkType.PaAssenble ||
      workType === WorkType.PaInspection ||
      workType === WorkType.PaPacking ||
      workType === WorkType.SmInspection ? (
      // 組立、検査、梱包の場合、
      <div>
        {chargeAmountContent}
        {dantoriSettingContent}
        {/* 親部品 */}
        {types === ServiceClass.Parent &&
        // 組立、検査、梱包の場合、
        (workType === WorkType.PaAssenble || workType === WorkType.PaPacking || workType === WorkType.PaInspection)
          ? sagyouJikanContent
          : childPartContent}
        {/* {childPartContent} */}
        {/* 検査とiQ3 */}
        {/*  {types === ServiceClass.SheetMetal && workType === WorkType.SmInspection ? materialHandlingContent : ''} */}
        {/* 検査とiQ3 */}
        {types === ServiceClass.SheetMetal && workType === WorkType.SmInspection
          ? productSizeFactorContent
          : productSizeContent}
        {/* {difficultyFactorContent} */}
        {quantityContent}
      </div>
    ) : workType === WorkType.SmProgram ? (
      // プログラム
      <>
        <div>
          {chargeAmountContent}
          {dantoriSettingContent}
          {sagyouSokudoContent}
          {sagyouKeisuuContent}
          {/* {kouteiNaiKensaContent} */}
        </div>
      </>
    ) : workType === WorkType.SmShearing ? (
      // シャーリング
      <>
        <div>
          {chargeAmountContent}
          {dantoriSettingContent}
          {iq3KakouJikanContent}
          {/*  {materialHandlingContent} */}
          {productSizeFactorContent}
          {quantityContent}
          {/* {kouteiNaiKensaContent} */}
        </div>
      </>
    ) : workType === WorkType.SmBending ? (
      // ベンディング
      <div>
        {chargeAmountContent}
        {dantoriSettingContent}
        {bendingTimeContent}
        {bendingTimeFactorContent}
        {bendingCorrectionTimeContent}
        {/* {bendingCorrectionTimeFactorContent} */}
        {/* {materialHandlingContent} */}
        {productSizeFactorContent}
        {quantityContent}
        {/* {kouteiNaiKensaContent} */}
      </div>
    ) : workType === WorkType.SmSecondaryWork ? (
      // 2次加工
      <>
        <div>
          {chargeAmountContent}
          {dantoriSettingContent}
          {iq3KakouJikanContent}
          {bendingCorrectionTimeContent}
          {/* {bendingCorrectionTimeFactorContent} */}
          {/*  {materialHandlingContent} */}
          {productSizeFactorContent}
          {quantityContent}
          {/* {kouteiNaiKensaContent} */}
        </div>
      </>
    ) : workType === WorkType.SmDeburringAuto ? (
      // 自動バリ
      <div>
        {chargeAmountContent}
        {dantoriSettingContent}
        {iq3KakouJikanContent}
        {bendingCorrectionTimeContent}
        {/*  {materialHandlingContent} */}
        {productSizeFactorContent}
        {quantityContent}
        {/* {kouteiNaiKensaContent} */}
      </div>
    ) : workType === WorkType.SmDeburringManual ? (
      <div>
        {chargeAmountContent}
        {dantoriSettingContent}
        {iq3KakousokudoContent}
        {BlankKakouSokudoKeisuuContent}
        {bendingCorrectionTimeContent}
        {iq3ManualBariContent}
        {/*  {materialHandlingContent} */}
        {productSizeFactorContent}
        {quantityContent}
        {/* {kouteiNaiKensaContent} */}
      </div>
    ) : workType === WorkType.SmBlankPunch ? (
      // ブランク（パンチ）
      <>
        {/* {baritoriTypesContent} */}
        {chargeAmountContent}
        {dantoriSettingContent}
        {materialArrangementContent}
        {punchConditionContent}
        {punchProcessTimeContent}
        {okuriSokudoContent}
        {rollerKakouSokudoContent}
        {extractConditionContent}
        {productSizeFactorContent}
        {quantityContent}
        {BlankDrillingTimeContent}
        {BlankJointContent}
        {/* {kouteiNaiKensaContent} */}
      </>
    ) : workType === WorkType.SmBlankCombi ? (
      // ブランク（複合機）
      <>
        {/* {baritoriTypesContent} */}
        {chargeAmountContent}
        {dantoriSettingContent}
        {materialArrangementContent}
        {punchConditionContent}
        {punchProcessTimeContent}
        {piercingProcessTimeContent}
        {BlankKakouSokudoContent}
        {BlankKakouSokudoKeisuuContent}
        {BlankGasuKeisuuContent}
        {productSizeFactorContent}
        {quantityContent}
        {BlankDrillingTimeContent}
        {BlankJointContent}
        {/* {kouteiNaiKensaContent} */}
      </>
    ) : workType === WorkType.SmBlankLaser ? (
      // ブランク（レーザー）
      <>
        {/* {baritoriTypesContent} */}
        {chargeAmountContent}
        {dantoriSettingContent}
        {materialArrangementContent}
        {piercingProcessTimeContent}
        {BlankKakouSokudoContent}
        {BlankKakouSokudoKeisuuContent}
        {BlankGasuKeisuuContent}
        {productSizeFactorContent}
        {quantityContent}
        {BlankDrillingTimeContent}
        {BlankJointContent}
        {/* {kouteiNaiKensaContent} */}
      </>
    ) : (
      <div>
        {chargeAmountContent}
        {dantoriSettingContent}
        {/* {machineSpeedContent}
        {karitsukiTimeContent}

        {toritsukiTimeItaatsuContent}
        {toritsukiTimeSizeContent}
        {yousetsuDifficultyContent}
        {tappuYousetsuContent}
        {pitchContent} */}
        {productSizeContent}
        {quantityContent}
      </div>
    );

  const searchCustomer = () => {
    setCustomerModal(true);
  };
  const getCustomerData = (selectedCustomerData) => {
    setCustomerId(selectedCustomerData.id);
    setCustomerCode(selectedCustomerData.code);
    setCustomerName(selectedCustomerData.name);
    setCustomerModal(false);
  };

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setCheckboxChecked(isChecked);
    setSelectDisabled(!isChecked);
  };
  const flagChange = (e) => {
    const isChecked = e.target.checked;
    setFlagChecked(isChecked);
    setInputDisabled(!isChecked);
  };
  const changeAmount = (name, e) => {
    // const regex = /^[0-9,¥]+$/;
    // if (regex.test(e)) {
    //   if (e.startsWith('¥', 0)) {
    //     e = e.replace('¥', '');
    //   }
    //   if (e.indexOf(',') > 0) {
    //     e = e.replace(/,/g, '');
    //   }
    // } else {
    //   e = 0;
    // }
    // if (name === 'dantori') {
    //   setSetupAmount(e);
    // } else if (name === 'kakou') {
    //   setWorkAmount(e);
    // } else {
    //   setIniAmount(e);
    // }
    setIniAmount(e);
  };
  const closeAll = () => {
    props.updatedFlag(false);
    setIsCharge(false);
    setIsSetupSetting(false);
    setIsProcessSpeed(false);
    setIsAttach(false);
    setIsToritsukiJikanItaatsu(false);
    setIsToritsukiJikanSize(false);
    setIsDifficultyFactor(false);
    setIsTapWelding(false);
    setIsPitch(false);
    setIsProductSize(false);
    setIsProductQuantity(false);
    setIsChildPart(false);
    setIsDifficultyFactor(false);
    setIsDantoriJikan(false);
    setIsDeviceDetails(false);
    setIsProcessTime(false);
    setIsWelding(false);
    setIsSagyouSokudo(false);
    setIsSagyouKeisuu(false);
    setIsiq3DantoriJikan(false);
    setIsiq3KakouJikan(false);
    setIsBendingTimeFactor(false);
    setIsMagekakouJikan(false);
    setIsMageHoseiJikanFactor(false);
    setIsMageHoseiJikan(false);
    setIsBaritoriShurui(false);
    setIsiq3KakouSokudo(false);
    setIsiq3ManualBari(false);
    setIsProcessSizeFactor(false);
    setIsKouteiNaikensa(false);
    setIsMaterialHandling(false);
    setIsArrangeMaterial(false);
    setIsPiercingProcess(false);
    setIsBlankKakouSokudo(false);
    setIsBlankKakouSokudoKeisuu(false);
    setIsBlankGasuKeisuu(false);
    setIsBlankGasuType(false);
    setIsBlankJoint(false);
    setIsBlankDrillingTime(false);
    setIsPunch(false);
    setIsOkuriSokudo(false);
    setIsRollerKakouSokudo(false);
    setIsExtractCondition(false);
    setIsPunchProcess(false);
    setIsShiageFlag(false);
  };
  const openAll = () => {
    props.updatedFlag(true);
    setIsCharge(true);
    setIsSetupSetting(true);
    setIsProcessSpeed(true);
    setIsAttach(true);
    setIsToritsukiJikanItaatsu(true);
    setIsToritsukiJikanSize(true);
    setIsDifficultyFactor(true);
    setIsTapWelding(true);
    setIsPitch(true);
    setIsProductSize(true);
    setIsProductQuantity(true);
    setIsChildPart(true);
    setIsDifficultyFactor(true);
    setIsDantoriJikan(true);
    setIsDeviceDetails(true);
    setIsProcessTime(true);
    setIsWelding(true);
    setIsSagyouSokudo(true);
    setIsSagyouKeisuu(true);
    setIsiq3DantoriJikan(true);
    setIsiq3KakouJikan(true);
    setIsBendingTimeFactor(true);
    setIsMagekakouJikan(true);
    setIsMageHoseiJikanFactor(true);
    setIsMageHoseiJikan(true);
    setIsBaritoriShurui(true);
    setIsiq3KakouSokudo(true);
    setIsiq3ManualBari(true);
    setIsProcessSizeFactor(true);
    setIsKouteiNaikensa(true);
    setIsMaterialHandling(true);
    setIsArrangeMaterial(true);
    setIsPiercingProcess(true);
    setIsBlankKakouSokudo(true);
    setIsBlankKakouSokudoKeisuu(true);
    setIsBlankGasuKeisuu(true);
    setIsBlankGasuType(true);
    setIsBlankJoint(true);
    setIsBlankDrillingTime(true);
    setIsPunch(true);
    setIsOkuriSokudo(true);
    setIsRollerKakouSokudo(true);
    setIsExtractCondition(true);
    setIsPunchProcess(true);
    setIsShiageFlag(true);
  };

  const addDetailsModalTitle = (
    <div
      style={{
        width: 1030,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>チャージ金額詳細</p>
    </div>
  );
  const closeModal = (result) => {
    setIsDetails(result);
  };
  const closeAddModal = () => {
    setIsDetails(false);
  };
  const addData = () => {};
  const addContent = (
    <>
      <ChargeDetails editMode={editMode} closeModal={closeModal} />
    </>
  );
  return (
    <>
      <Form>
        <div className="d-flex" style={{ marginTop: 7 }}>
          <div>
            <Button
              style={{
                marginLeft: 10,
                display: editMode ? 'block' : 'none',
              }}
              className="mainButton"
              id="update"
              onClick={updateData}
            >
              更新
            </Button>
          </div>
          <div>
            <Button
              style={{
                marginLeft: 10,
                display: editMode ? 'block' : 'none',
              }}
              className="cancelButton"
              id="discard"
              onClick={discardData}
            >
              破棄
            </Button>
          </div>
          <div className="ms-auto">
            <Button
              style={{
                marginRight: 10,
                display: 'none',
                display: editMode ? 'none' : 'block',
                pointerEvents: selectedDeviceData?.length != 0 ? 'auto' : 'none',
              }}
              className="mainButton"
              id="edit"
              onClick={editData}
            >
              編集
            </Button>
          </div>
        </div>
        <div className="overflow-auto kouteical timecharge registerModal" style={{ height: '77.1vh', marginTop: 22 }}>
          <div className="upperDeviceTbl">
            <Row className=" chargeAmount ">
              {/* workType：カスタム、ブランクの場合、 */}
              <Col
                style={{
                  width: workType === WorkType.PaCustom || workType === WorkType.SmCustom ? '100%' : '75%',
                }}
              >
                <RTable>
                  <thead className="table-light">
                    <tr style={{ textAlign: 'center' }}>
                      <th style={{ width: '10%' }}>コード</th>
                      <th style={{ width: '15%' }}>工程名</th>
                      {/* workType：カスタム、ブランクの場合、 */}
                      {workType === WorkType.PaCustom || workType === WorkType.SmCustom ? (
                        <>
                          <th style={{ width: '10%' }}>コード</th>
                          <th style={{ width: '15%' }}>工程名</th>
                        </>
                      ) : (
                        <></>
                      )}
                      <th style={{ width: '10%' }}>装置コード</th>
                      <th style={{ width: '20%' }}>装置名</th>
                      <th style={{ width: '20%' }}>備考</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr style={{ textAlign: 'center' }}>
                      <td style={{ verticalAlign: 'middle' }}>{code}</td>
                      <td style={{ verticalAlign: 'middle' }}>{processName}</td>
                      {/* workType：ブランクの場合、 */}
                      {workType === WorkType.PaCustom || workType === WorkType.SmCustom ? (
                        // workType：カスタムの場合、
                        <>
                          <td style={{ verticalAlign: 'middle' }}>{kouteiCode}</td>
                          <td style={{ verticalAlign: 'middle' }}>{kouteiName}</td>
                        </>
                      ) : (
                        <></>
                      )}
                      <td style={{ verticalAlign: 'middle' }}>
                        <Input
                          style={{ padding: 3, width: '98.2%', textAlign: 'center', height: 26 }}
                          className={editMode ? 'input-editable ' : 'input-non-editable '}
                          name="deviceCode"
                          id="deviceCode"
                          value={deviceCode}
                          type="text"
                          onChange={(e) => {
                            setDeviceCode(e.target.value);
                          }}
                        />
                      </td>
                      <td style={{ verticalAlign: 'middle' }}>
                        <Input
                          style={{ padding: 3, width: '98.2%', textAlign: 'center', height: 26 }}
                          className={editMode ? 'input-editable ' : 'input-non-editable '}
                          name="deviceName"
                          id="deviceName"
                          value={deviceName}
                          type="text"
                          onChange={(e) => {
                            setDeviceName(e.target.value);
                          }}
                        />
                      </td>
                      <td style={{ verticalAlign: 'middle' }}>
                        <Input
                          style={{ padding: 3, width: '98.2%', textAlign: 'center', height: 26 }}
                          className={editMode ? 'input-editable ' : 'input-non-editable '}
                          value={remarks}
                          type="text"
                          onChange={(e) => {
                            setRemarks(e.target.value);
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </RTable>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col>
                <Space>
                  <DownSquareFilled style={{ fontSize: 25, color: '#1063aa' }} onClick={openAll} />
                  <UpSquareFilled style={{ fontSize: 25, color: '#1063aa' }} onClick={closeAll} />
                </Space>
              </Col>
            </Row>
          </div>
          <Row>
            <Col span={24}>
              {/* 標準の場合、 */}
              {selectedDeviceData?.length != 0 ? (
                <>
                  {deviceType === processTypes.standard ? (
                    <>{content}</>
                  ) : (
                    // 追加の場合、
                    <>
                      {/* チャージ金額 */}
                      {chargeAmountContent}
                      {/* 段取時間 */}
                      {dantoriSettingContent}
                      {sagyouJikanContent}
                      {/* 親部品 */}
                      {types === ServiceClass.Parent ? (
                        productSizeContent
                      ) : (
                        <>
                          {/*  {materialHandlingContent} */}
                          {productSizeFactorContent}
                        </>
                      )}
                      {quantityContent}
                      {/* 委託装置詳細 */}
                      <Row
                        align="middle"
                        style={{ color: 'black' }}
                        className="oyabuhin oyabuhin-row"
                        onClick={deviceDetails}
                      >
                        <Col span={20} className="deli-col">
                          <div style={{ display: 'flex' }}>
                            <label style={{ marginRight: 5 }}>委託装置詳細</label>
                            {isDeviceDetails ? (
                              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
                            ) : (
                              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
                            )}
                          </div>
                        </Col>
                      </Row>
                      <div style={{ display: isDeviceDetails ? 'block' : 'none' }}>
                        <Row style={{ marginLeft: 10 }} className="chargeAmount">
                          <Col style={{ width: '70%' }}>
                            <RTable className="tsuikakoutei">
                              <thead>
                                <tr>
                                  <td className="device-detail-tbl-tsuika">
                                    <label className="detail-tbl-val-title-label">委託工程</label>
                                  </td>
                                  <td style={{ verticalAlign: 'middle' }} colSpan={2}>
                                    <Checkbox
                                      style={{ marginLeft: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                                      onChange={handleCheckboxChange}
                                      checked={checkboxChecked}
                                    ></Checkbox>
                                  </td>
                                </tr>
                                <tr className={selectDisabled ? 'souchi' : ''}>
                                  <td className="device-detail-tbl-tsuika">
                                    <label className="detail-tbl-val-title-label">取引先(委託先)</label>
                                  </td>
                                  <td style={{ verticalAlign: 'middle' }} colSpan={2}>
                                    <Row style={{ marginLeft: 10 }}>
                                      <Col span={16}>{customerName}</Col>
                                      <Col span={7} style={{ textAlign: 'end' }}>
                                        <SearchOutlined
                                          style={{
                                            fontSize: '18px',
                                            pointerEvents: selectDisabled || !editMode ? 'none' : 'auto',
                                          }}
                                          onClick={searchCustomer}
                                        />
                                      </Col>
                                    </Row>
                                  </td>
                                </tr>
                                <tr className={selectDisabled ? 'souchi' : ''}>
                                  <td className="device-detail-tbl-tsuika">
                                    <label className="detail-tbl-val-title-label">取引先コード</label>
                                  </td>
                                  <td style={{ verticalAlign: 'middle' }} colSpan={2}>
                                    <label style={{ marginLeft: 10 }}>{customerCode}</label>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="device-detail-tbl-tsuika">
                                    <label className="detail-tbl-val-title-label">手入力フラグ</label>
                                  </td>
                                  <td style={{ verticalAlign: 'middle' }} colSpan={2}>
                                    <Checkbox
                                      style={{ marginLeft: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                                      onChange={flagChange}
                                      checked={flagChecked}
                                    ></Checkbox>
                                  </td>
                                </tr>
                                <tr className={inputDisabled ? 'souchi' : ''}>
                                  <td className="device-detail-tbl-tsuika">
                                    <label className="detail-tbl-val-title-label">初期金額(手入力時)</label>
                                  </td>
                                  <td style={{ verticalAlign: 'middle' }} colSpan={2}>
                                    <CurrencyInput
                                      name="iniAmount"
                                      id="iniAmount"
                                      value={iniAmount}
                                      defaultValue={0}
                                      style={{
                                        padding: 3,
                                        width: '97%',
                                        pointerEvents: inputDisabled || !editMode ? 'none' : 'auto',
                                      }}
                                      className={
                                        editMode
                                          ? 'currencyInputCost input-editable'
                                          : 'currencyInputCost input-non-editable'
                                      }
                                      prefix="¥"
                                      decimalsLimit={100}
                                      onValueChange={(e) => changeAmount('iniAmount', e)}
                                    />
                                    {/* <Input
                                  value={JPYs.format(iniAmount)}
                                  onChange={(e) => changeAmount('iniAmount', e.target.value)}
                                  type="text"
                                  style={{
                                    padding: 5,
                                    marginLeft: 5,
                                    width: '97%',
                                    pointerEvents: inputDisabled || !editMode ? 'none' : 'auto',
                                  }}
                                  className="input-editable"
                                ></Input> */}
                                  </td>
                                </tr>
                              </thead>
                            </RTable>
                          </Col>
                        </Row>
                      </div>
                      {/* 親部品 */}
                      {/* {types === ServiceClass.Parent ? '' : kouteiNaiKensaContent} */}
                    </>
                  )}
                </>
              ) : (
                <>
                  {chargeAmountContent}
                  {dantoriSettingContent}

                  {productSizeContent}
                  {quantityContent}
                </>
              )}
            </Col>
          </Row>
        </div>
      </Form>
      {/** 更新の確認メッセージ */}
      {updateConfirm
        ? commonModal(
            updateConfirm,
            confirmModalTitle,
            commonFooter(updConfirmOk, updConfirmCancel),
            null,
            400,
            updConfirmOk,
            updConfirmCancel,
            confirmUpdateContent,
            null,
            null,
            false
          )
        : ''}
      {/** 破棄の確認メッセージ */}
      {cancelConfirm
        ? commonModal(
            cancelConfirm,
            confirmModalTitle,
            commonFooter(discardConfirmOk, discardConfirmCancel),
            null,
            400,
            discardConfirmOk,
            discardConfirmCancel,
            confirmDiscardContent,
            null,
            null,
            false
          )
        : ''}

      {/* チャージ金額詳細 */}
      {isDetails
        ? commonModal(
            isDetails,
            addDetailsModalTitle,
            null,
            null,
            1030,
            addData,
            closeAddModal,
            addContent,
            null,
            null,
            true
          )
        : ''}
      {customerModal ? (
        <SearchAndGetCustomerData
          customerModal={customerModal}
          selectedCustomerData={getCustomerData}
          title={Customer}
          clientType={ClientType.Contractor}
        />
      ) : (
        ''
      )}
    </>
  );
});

export default DevicesDetails;
