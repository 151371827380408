/**
 * クラス名：iq3の装置設定の曲げ時間係数画面
 * 作成者：チュー
 * 作成日：2023/06/21
 * バージョン：1.0
 */

import React, { useState, useEffect, forwardRef, useRef } from 'react';
import { Row, Col, Input, Button, Space, Popover } from 'antd';
import { PlusCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { Table } from 'react-bootstrap';

import { commonModal } from '../../../common/CommonModal';
import { isEmpty } from '@rc-component/mini-decimal/es/numberUtil';
import {
  getInputBorderStyle,
  handleKeyPress,
  sortListByMasterData,
  updateList,
  updateSizeList,
} from '../../../common/Common';
const Device_BendingTimeFactor = forwardRef((props, ref) => {
  const [editMode, setEditMode] = useState(false);
  const [isLength, setIsLength] = useState(false);
  const [isArea, setIsArea] = useState(false);
  const [isWeight, setIsWeight] = useState(false);
  const [category, setCategory] = useState('');

  const [weightData, setWeightData] = useState([]);
  const [lengthData, setLengthData] = useState([]);
  const [detailsLengthTemp, setDetailsLengthTemp] = useState([]);
  const [detailsWeightTemp, setDetailsWeightTemp] = useState([]);
  // 曲げ時間係数リスト
  const [detailsList, setDetailsList] = useState([]);
  // 補正補正時間係数リスト
  // const [correctionTimeFactorList, setcorrectionTimeFactorList] = useState([]);

  // 製品サイズ係数
  // const [seihinSizeList, setSeihinSizeList] = useState([]);

  const [errIndexVal, setErrIndexVal] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const inputRef = useRef([]);
  useEffect(() => {
    setErrIndexVal([]);
    setErrorMessage('');
    setEditMode(props.editMode);
    setWeightData(props?.detailsData?.bendTimeCoeffWeightThMaster);
    setDetailsWeightTemp(props?.detailsData?.bendTimeCoeffWeightThMaster);
    setLengthData(props?.detailsData?.bendTimeCoeffLenThMaster);
    setDetailsLengthTemp(props?.detailsData?.bendTimeCoeffLenThMaster);

    setDetailsList(props?.detailsData?.bendTimeCoeffItems);

    setCategory(props.processName);
    // setcorrectionTimeFactorList(props.bendingCorrectionTimeFactorLst);
  }, [
    props.detailsData,
    props.editMode,
    props.processName,
    // props.bendingCorrectionTimeFactorLst,
  ]);
  const addLength = () => {
    setIsLength(true);
  };
  const addWeight = () => {
    setIsWeight(true);
  };

  const addLengthModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>曲長</p>
    </div>
  );
  const addWeightModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>重量</p>
    </div>
  );

  const addData = () => {
    setIsUpdateData(true);
  };

  const addLengthRow = (id, index) => {
    inputRef.current[index].focus();
    const copyData = detailsLengthTemp.slice(); // Create a copy of the original array

    const insertIndex = detailsLengthTemp?.findIndex((item) => item.no === id); // Find the index of the item with id 2

    if (insertIndex !== -1) {
      const data = {
        no: detailsLengthTemp?.length + 1,
        value: 0,
        isOutOfRange: false,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setDetailsLengthTemp(copyData);
    }
  };
  const deleteLength = (id, e, index) => {
    // 更新するエラーインデックスを得る
    const updatedErrorIndexes = errIndexVal?.filter((item) => item !== index);

    const finalErrorIndexes = updatedErrorIndexes?.map((item) => (item > index ? item - 1 : item));
    setErrIndexVal(finalErrorIndexes);

    if (detailsLengthTemp?.filter((item) => item.isOutOfRange === false)?.length > 2) {
      const updatedData = detailsLengthTemp?.map((row) => {
        if (row.no === id) {
          return { ...row, isOutOfRange: true };
        }
        return row;
      });
      setDetailsLengthTemp(updatedData);
      const filterData = updatedData?.filter((i) => !i.isOutOfRange);
      if (index + 1 !== filterData?.length) {
        inputRef.current[index].focus();
      }
    }
  };
  const deleteWeight = (id, e, index) => {
    // 更新するエラーインデックスを得る
    const updatedErrorIndexes = errIndexVal?.filter((item) => item !== index);

    const finalErrorIndexes = updatedErrorIndexes?.map((item) => (item > index ? item - 1 : item));
    setErrIndexVal(finalErrorIndexes);

    if (detailsWeightTemp?.filter((item) => item.isOutOfRange === false)?.length > 2) {
      const updatedData = detailsWeightTemp?.map((row) => {
        if (row.no === id) {
          return { ...row, isOutOfRange: true };
        }
        return row;
      });
      setDetailsWeightTemp(updatedData);
      const filterData = updatedData?.filter((i) => !i.isOutOfRange);
      if (index + 1 !== filterData?.length) {
        inputRef.current[index].focus();
      }
    }
  };
  const addWeightRow = (id, index) => {
    inputRef.current[index].focus();
    const copyData = detailsWeightTemp.slice(); // Create a copy of the original array

    const insertIndex = detailsWeightTemp?.findIndex((item) => item.no === id); // Find the index of the item with id 2

    if (insertIndex !== -1) {
      const data = {
        no: detailsWeightTemp?.length + 1,
        value: 0,
        isOutOfRange: false,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setDetailsWeightTemp(copyData);
    }
  };
  const closeAddModal = () => {
    if (isLength === true) {
      setIsLength(false);
    } else if (isWeight === true) {
      setIsWeight(false);
    } else if (isArea === true) {
      setIsArea(false);
    }
  };
  const handleLengthChange = (event, id, field) => {
    const updatedData = detailsLengthTemp?.map((row) => {
      if (row.no === id) {
        return { ...row, [field]: event.target.value };
      }
      return row;
    });
    setDetailsLengthTemp(updatedData);
  };
  const handleWeightChange = (event, id, field) => {
    const updatedData = detailsWeightTemp?.map((row) => {
      if (row.no === id) {
        return { ...row, [field]: event.target.value };
      }
      return row;
    });
    setDetailsWeightTemp(updatedData);
  };
  const cancelLengthMaster = () => {
    setErrIndexVal([]);
    setErrorMessage('');
    setDetailsLengthTemp(lengthData);
    setIsLength(false);
  };
  const addLengthMaster = () => {
    const deletedId = [];

    lengthData?.forEach((item1, index) => {
      const found = detailsLengthTemp?.find((item2) => item2.no === item1.no && item2.isOutOfRange === true);
      if (found) {
        deletedId.push(item1.no);
      }
    });

    const newId = detailsLengthTemp
      ?.filter((item) => !item.isOutOfRange && !lengthData.some((data2Item) => data2Item.no === item.no))
      ?.map((item) => item.no);

    // const deleteData = detailsList?.map((item) => {
    //   return {
    //     ...item,
    //     lstData: item.lstData?.filter((it) => !deletedId?.includes(it.key)),
    //   };
    // });
    // const deleteData2 = correctionTimeFactorList?.map((item) => {
    //   return {
    //     ...item,
    //     lstData: item.lstData?.filter((it) => !deletedId?.includes(it.key)),
    //   };
    // });

    const updatedData = updateList(detailsList, deletedId, newId);
    // const updatedData2 = deleteData2?.map((item) => {
    //   newId?.forEach((key) => {
    //     // Add new objects to lstData
    //     item.lstData.push({
    //       key: key,
    //       value: '',
    //     });
    //   });

    //   return item;
    // });
    const sortedList = sortListByMasterData(updatedData, detailsLengthTemp);
    // const sortedList2 = updatedData2?.map((item) => {
    //   const sortedLstData = [...item.lstData];
    //   sortedLstData.sort((a, b) => {
    //     const aIndex = detailsLengthTemp?.findIndex((obj) => obj.id === a.key);
    //     const bIndex = detailsLengthTemp?.findIndex((obj) => obj.id === b.key);
    //     return aIndex - bIndex;
    //   });

    //   return {
    //     key: item.key,
    //     max: item.max,
    //     displayFlag: item.displayFlag,
    //     lstData: sortedLstData,
    //   };
    // });
    setIsLength(false);
    setDetailsLengthTemp(detailsLengthTemp);
    props.updatedLength(detailsLengthTemp);

    setDetailsList(sortedList);
    props.updatedSizesList(sortedList);

    // setcorrectionTimeFactorList(sortedList2);
    // props.updatedbendingCorrectionTimeFactorLst(sortedList2);
  };
  const cancelWeightMaster = () => {
    setErrIndexVal([]);
    setErrorMessage('');
    setDetailsWeightTemp(weightData);
    setIsWeight(false);
  };
  const addWeightMaster = () => {
    const deletedId = [];

    weightData?.forEach((item1, index) => {
      const found = detailsWeightTemp?.find((item2) => item2.no === item1.no && item2.isOutOfRange === true);
      if (found) {
        deletedId.push(item1.no);
      }
    });

    const newId = detailsWeightTemp
      ?.filter((item) => !item.isOutOfRange && !weightData.some((data2Item) => data2Item.no === item.no))
      ?.map((item) => item.no);

    const updatedList = updateSizeList(detailsList, deletedId, newId);
    // const deletedData = detailsList?.filter((it) => !deletedId?.includes(it.key));
    // const deletedData2 = correctionTimeFactorList?.filter((it) => !deletedId?.includes(it.key));
    // const result = deletedData?.map((item) => {
    //   const list = detailsWeightTemp?.find((listItem) => listItem.id === item.key);

    //   return {
    //     ...item,
    //     max: list ? list.max : item.max,
    //     lstData: item.lstData?.map((lstItem) => {
    //       const newListData = {
    //         key: lstItem.key,
    //         value: lstItem.value,
    //       };

    //       return newListData;
    //     }),
    //   };
    // });
    // const result2 = deletedData2?.map((item) => {
    //   const list = detailsWeightTemp?.find((listItem) => listItem.id === item.key);

    //   return {
    //     ...item,
    //     max: list ? list.max : item.max,
    //     lstData: item.lstData?.map((lstItem) => {
    //       const newListData = {
    //         key: lstItem.key,
    //         value: lstItem.value,
    //       };

    //       return newListData;
    //     }),
    //   };
    // });

    // newId?.forEach((id) => {
    //   const list = detailsWeightTemp?.find((item) => item.id === id);

    //   if (list) {
    //     const newObj = {
    //       key: id,
    //       max: list.max,
    //       displayFlag: true,
    //       lstData: result[0].lstData?.map((lstItem) => {
    //         return {
    //           key: lstItem.key,
    //           value: '',
    //         };
    //       }),
    //     };
    //     const newObj2 = {
    //       key: id,
    //       max: list.max,
    //       displayFlag: true,
    //       lstData: result2[0].lstData?.map((lstItem) => {
    //         return {
    //           key: lstItem.key,
    //           value: '',
    //         };
    //       }),
    //     };

    //     result.push(newObj);
    //     result2.push(newObj2);
    //   }
    // });
    // result.sort((a, b) => {
    //   const aIndex = detailsWeightTemp?.findIndex((obj) => obj.id === a.key);
    //   const bIndex = detailsWeightTemp?.findIndex((obj) => obj.id === b.key);
    //   return aIndex - bIndex;
    // });
    // result2.sort((a, b) => {
    //   const aIndex = detailsWeightTemp?.findIndex((obj) => obj.id === a.key);
    //   const bIndex = detailsWeightTemp?.findIndex((obj) => obj.id === b.key);
    //   return aIndex - bIndex;
    // });

    setIsWeight(false);
    setWeightData(detailsWeightTemp);
    props.updatedWeight(detailsWeightTemp);
    setDetailsList(updatedList);
    props.updatedSizesList(updatedList);
    // setcorrectionTimeFactorList(result2);
    // props.updatedbendingCorrectionTimeFactorLst(result2);
  };
  const isValueOrderCorrect = (no, tblName) => {
    let temp = [];
    if (tblName === 'length') {
      temp = detailsLengthTemp?.filter((i) => i.isOutOfRange === false);
    } else {
      temp = detailsWeightTemp?.filter((i) => i.isOutOfRange === false);
    }

    const insertIndex = temp?.findIndex((item) => item.no === no);

    if (insertIndex > 0) {
      const prevValue = parseFloat(temp[insertIndex - 1].value);
      const currentValue = parseFloat(temp[insertIndex].value);
      const nextValue = parseFloat(temp[insertIndex + 1].value);
      if (currentValue > prevValue && currentValue < nextValue) {
        let temp = [insertIndex - 1, insertIndex, insertIndex + 1];

        setErrIndexVal(errIndexVal?.filter((item) => item !== insertIndex && item != insertIndex - 1));

        setErrorMessage('前のしきい値より大きい値を入力してください！');
      } else {
        setErrIndexVal([...errIndexVal, insertIndex]);

        if (currentValue <= prevValue) {
          setErrorMessage('前のしきい値より大きい値を入力してください！');
        } else if (currentValue >= nextValue) {
          setErrorMessage('次のしきい値より小さい値を入力してください！');
        }
      }
    } else {
      const currentValue = parseFloat(temp[insertIndex].value);
      const nextValue = parseFloat(temp[insertIndex + 1].value);

      if (currentValue < nextValue) {
        setErrIndexVal(errIndexVal?.filter((item) => item !== insertIndex));
      } else {
        setErrIndexVal([...errIndexVal, insertIndex]);
        setErrorMessage('次のしきい値より小さい値を入力してください！');
      }
    }
  };
  const addLengthContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>しきい値</label>（≦）
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {detailsLengthTemp
                  ?.filter((subitem) => subitem.isOutOfRange === false)
                  ?.map((item, index) => (
                    <tr key={index} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        {errIndexVal?.includes(index) ? (
                          <Popover
                            content={<div style={{ padding: 7 }}>{errorMessage}</div>}
                            placement="left"
                            visible={true}
                          ></Popover>
                        ) : (
                          ''
                        )}
                        <Input
                          value={item.value}
                          onChange={(e) => handleLengthChange(e, item.no, 'value')}
                          onBlur={() => isValueOrderCorrect(item.no, 'length')}
                          className={errIndexVal?.includes(index) ? 'red-border' : ''}
                          style={{
                            ...getInputBorderStyle(index, detailsLengthTemp),
                            pointerEvents: editMode ? 'auto' : 'none',
                          }}
                          onKeyPress={(e) => {
                            handleKeyPress(e, false);
                          }}
                          ref={(ref) => (inputRef.current[index] = ref)}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addLengthRow(item.no, index + 1)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteLength(item.no, e, index)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button
          className={isEmpty(errIndexVal) ? 'mainButton' : 'disabledButton'}
          id="update"
          onClick={addLengthMaster}
        >
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} onClick={cancelLengthMaster}>
          キャンセル
        </Button>
      </Row>
    </>
  );
  const addWeightContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>しきい値</label>（≦）
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {detailsWeightTemp
                  ?.filter((i) => !i.isOutOfRange)
                  ?.map((item, index) => (
                    <tr key={index} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        {errIndexVal?.includes(index) ? (
                          <Popover
                            content={<div style={{ padding: 7 }}>{errorMessage}</div>}
                            placement="left"
                            visible={true}
                          ></Popover>
                        ) : (
                          ''
                        )}
                        <Input
                          value={item.value}
                          onChange={(e) => handleWeightChange(e, item.no, 'value')}
                          onBlur={() => isValueOrderCorrect(item.no, 'weight')}
                          className={errIndexVal?.includes(index) ? 'red-border' : ''}
                          style={{
                            ...getInputBorderStyle(index, detailsWeightTemp),
                            pointerEvents: editMode ? 'auto' : 'none',
                          }}
                          onKeyPress={(e) => {
                            handleKeyPress(e, false);
                          }}
                          ref={(ref) => (inputRef.current[index] = ref)}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addWeightRow(item.no, index + 1)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteWeight(item.no, e, index)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button
          className={isEmpty(errIndexVal) ? 'mainButton' : 'disabledButton'}
          id="update"
          onClick={addWeightMaster}
        >
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} onClick={cancelWeightMaster}>
          キャンセル
        </Button>
      </Row>
    </>
  );
  const listDataChange = (event, key, no) => {
    //  const copyData = [...detailsList];
    //   const updatedData = JSON.parse(JSON.stringify(copyData));
    //   updatedData[rowIndex].lstData[colIndex].value = event.target.value;
    //   setDetailsList(updatedData);
    //   props.updatedSizesList(updatedData);
    const newList = { ...detailsList };
    const itemIndex = newList[key]?.findIndex((item) => item.no === no);

    if (itemIndex !== -1) {
      newList[key][itemIndex].value = event.target.value;
      setDetailsList(newList);
      props.updatedSizesList(newList);
    }
  };
  return (
    <>
      <div className="table-container" style={{ marginLeft: 10, marginRight: 10 }}>
        <Table
          style={{
            width:
              lengthData?.filter((i) => !i.isOutOfRange)?.length > 8
                ? '100%'
                : lengthData?.filter((i) => !i.isOutOfRange)?.length * 98 + 196 + 'px',
          }}
        >
          <tr style={{ color: 'white', textAlign: 'center', height: 40 }}>
            <td style={{ width: '196px' }} className="fixedCol">
              <Row justify="center" className="tbl-header">
                <Space>
                  {editMode ? (
                    <>
                      <Button className="cancleButton" onClick={addWeight} style={{ pointerEvents: 'auto' }}>
                        kg
                      </Button>
                      /
                      <Button className="cancleButton" onClick={addLength} style={{ pointerEvents: 'auto' }}>
                        mm
                      </Button>
                    </>
                  ) : (
                    <>
                      <label>kg / mm</label>
                    </>
                  )}
                </Space>
              </Row>
            </td>

            {lengthData
              ?.filter((i) => !i.isOutOfRange)
              ?.map((item, index) => (
                <td key={index} style={{ backgroundColor: '#1063aa', width: '98px' }}>
                  ～{item.value}
                </td>
              ))}
          </tr>

          {/* {detailsList?.map((row, rowIndex) => (
            <tr style={{ textAlign: 'center' }}>
              <td style={{ backgroundColor: '#1063aa', color: 'white', width: '196px' }} className="fixedCol">
                ～{row.max}
              </td>

              {row.lstData?.map((col, colIndex) => (
                <td style={{ width: '98px' }}>
                  <Input
                    value={col.value}
                    onChange={(e) => listDataChange(e, rowIndex, colIndex, 'magechou')}
                    style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                    className={editMode ? 'input-editable ' : 'input-non-editable'}
                  />
                </td>
              ))}
            </tr>
          ))} */}
          {Object.keys(detailsList)?.map((key) => (
            <tr key={`${key}`} style={{ textAlign: 'center' }}>
              <td style={{ color: 'white', width: '196px' }} className="tbl-header fixedCol">
                {/* {key} */}
                {weightData?.find((item) => item.no === parseInt(key))?.value}
              </td>
              {detailsList[key]?.map((item, index) => (
                <td key={index} style={{ verticalAlign: 'middle', textAlign: 'center', width: 210 }}>
                  <Input
                    value={item.value}
                    onChange={(e) => listDataChange(e, key, item.no)}
                    style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                    className={editMode ? 'input-editable ' : 'input-non-editable'}
                  />
                </td>
              ))}
            </tr>
          ))}
        </Table>
      </div>

      {/* 曲長 */}
      {isLength
        ? commonModal(
            isLength,
            addLengthModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addLengthContent,
            null,
            null,
            true
          )
        : ''}
      {/* 重量 */}
      {isWeight
        ? commonModal(
            isWeight,
            addWeightModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addWeightContent,
            null,
            null,
            true
          )
        : ''}
    </>
  );
});

export default Device_BendingTimeFactor;
