import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';

import { Row, Col, Table as AntTable, Space } from 'antd';
import {
  CaretUpOutlined,
  CaretDownFilled,
  CaretDownOutlined,
  DownSquareFilled,
  UpSquareFilled,
} from '@ant-design/icons';
import { Table } from 'react-bootstrap';
import '../../assets/styles/common.css';
import ParentKouteiInput_Yousetsu from './ParentKouteiInput_Yousetsu';
import ParentKouteiInput_Kumitate from './ParentKouteiInput_Kumitate';
import ParentKouteiInput_Hyoumenshori from './ParentKouteiInput_Hyoumenshori';
import ParentKouteiInput_Kensa from './ParentKouteiInput_Kensa';
import ParentKouteiInput_Konpou from './ParentKouteiInput_Konpou';
import { commonTaiochuModal, confirmHakiModal } from '../common/CommonModal';
import IQ3KouteiInput_Hyoumenshori from '../iq3/IQ3KouteiInput_Hyoumenshori';
import {
  getEstimateProductInfo,
  getProcessInfo,
  getProcessInputInfo,
  getPurchaseCategoryInfo,
  getPurchaseInfo,
} from '../common/CommonAPI';
import { async } from 'q';
import { WorkType } from '../common/enums';
import { updateEstimateInfoByReCal } from '../common/Common';

const options = ['北海道', '北東北', '南東北', '関東', '信越', '北陸', '関西', '中国', '四国', '九州', '沖縄'];
const platingoptions = ['ユニクロメッキ', 'クロメート', 'クロームメッキ', '亜鉛メッキ', 'メッキ01', 'メッキ02'];
const paintingoptions = ['メラミン塗装', 'アクリル塗装', 'ウレタン塗装', '粉体塗装'];
const paintingoptions2 = ['カチオン塗装', 'プライマー', '塗装01', '塗装02'];
const columns = [
  {
    id: '1',
    title: '子部品種類',
    dataIndex: 'parentname',
  },
  {
    id: '2',
    title: '子部品名',
    dataIndex: 'childname',
  },
  {
    id: '3',
    title: '面積',
    dataIndex: 'surface',
  },
  {
    id: '4',
    title: '重量(kg)',
    dataIndex: 'weight',
  },
  {
    id: '5',
    title: '材質',
    dataIndex: 'quality',
  },
  {
    id: '6',
    title: '板厚',
    dataIndex: 'thick',
  },
];

const childParts = [
  {
    key: 1,
    childKey: 1,
    parentName: `iQ3板金`,
    childType: 'iQ3',
    imgNm: 'Product-20230320 105141',
    childName: `Test-1`,
    itemNum: 2,
    quality: 'SPCC',
    thick: 1.6,
    area: 10,
    weight: 3,
    size: `200x100`,
    materialname: '丸バィフ',
    details: '25.0t-280.0',
  },
  {
    key: 2,
    childKey: 1,
    parentName: `iQ5形鋼`,
    childType: 'iQ5',
    imgNm: 'Product-20230320 105142',
    childName: `Test-1`,
    itemNum: 2,
    quality: 'SPCC',
    thick: 1.6,
    area: 10,
    weight: 3,
    size: `200x100`,
    materialname: '丸バィフ',
    details: '25.0t-280.0',
  },
  {
    key: 3,
    childKey: 1,
    parentName: `iQ7機械`,
    childType: 'iQ7',
    imgNm: 'Product-20230320 105143',
    childName: `Test-1`,
    itemNum: 2,
    quality: 'SPCC',
    thick: 1.6,
    area: 10,
    weight: 3,
    size: `200x100`,
    materialname: '丸バィフ',
    details: '25.0t-280.0',
  },
];
const surfaceData = [];

for (let i = 1; i < 10; i++) {
  surfaceData.push({
    key: i,
    parentname: `iQ3板金`,
    childname: `Test-${i}`,
    surface: '100',
    weight: 10,
    quality: 'SPCC',
    thick: 1.6,
  });
}
const yousetsu1Data1 = [
  {
    key: 1,
    yousetsuType: 'アルゴン',
    senchou: 0,
    senchouKaisu: 0,
    senchouYousetsushiage: 'on',
    total1: 0,

    karitsukeCnt: 0,
    karitsukeKaisu: 0,
    karitsukeYousetsushiage: 'on',
    total2: 0,
  },
];

const yousetsuCondition = ['zerofour-02'];
const KouteiInput = forwardRef((props, ref) => {
  const [value1, setValue1] = useState();
  const [platingValue, setPlatingValue] = useState();
  const [paintingValue, setPaintingValue] = useState();
  const [paintingValue2, setPaintingValue2] = useState();
  const [isDelivery, setIsDelivery] = useState(false);
  const [isWelding, setIsWelding] = useState(false);
  const [isHyoumenShori, setIsHyoumenShori] = useState(false);
  const [isAssembly, setIsAssembly] = useState(false);
  const [isPacking, setIsPacking] = useState(false);
  const [isSurvey, setIsSurvey] = useState(false);

  const [editMode, setEditMode] = useState(false);

  const [tblSurface, setTblSurface] = useState(0);
  const [version, setVersion] = useState('iq3');

  // add new
  const [addModal, setAddModal] = useState(false);
  const [pmx, setPmx] = useState(yousetsuCondition[0]);

  const [yousetsuTbl1, setYouSetsuTbl1] = useState([]);
  const [hyoumenshoriTable, setHyoumenshoriTable] = useState([]);
  const [kumitateTbl, setKumitateTbl] = useState([]);
  const [kensaTbl, setKensaTbl] = useState([]);
  const [konpouTbl, setKonpouTbl] = useState([]);

  // 開発中ポップアップ
  const [openCommonTaiochuPopup, setOpenCommonTaiochuPopup] = useState(false);

  const [kouteiInputInfo, setKouteiInputInfo] = useState({});
  const [kouteiInputInfoTemp, setKouteiInputInfoTemp] = useState({});

  // 溶接
  const yousetsu = useRef();

  // 組立
  const kumitate = useRef();

  // 表面処理
  const hyoumenshori = useRef();

  // 検査
  const kensa = useRef();

  // 梱包
  const konpou = useRef();

  const [purchasesData, setPurchasesData] = useState([]);
  const [purchaseCategoryInfo, setPurchaseCategoryInfo] = useState([]);
  const [surfaceData, setSurfaceData] = useState([]);

  const [displaySurface, setDisplaySurface] = useState(false);
  const [displayAssenble, setDisplayAssenble] = useState(false);
  const [displayInspection, setDisplayInspection] = useState(false);
  const [displayPacking, setDisplayPacking] = useState(false);
  const [kouteiSentakuProcessesId, setKouteiSentakuProcessesId] = useState([]);

  useEffect(() => {
    let parameters = props.selectedDataDetail?.estimateProducts?.calcParameters?.parameters;
    if (parameters?.purchaseCategory) {
      setPurchaseCategoryInfo(parameters?.purchaseCategory);
    }
    const surfaceDetails = parameters?.process?.filter((i) => i.workType === WorkType.PaSurface);
    setSurfaceData(surfaceDetails);

    let surfaceId = surfaceDetails?.[0]?.id;
    let assenbleId = parameters?.process?.filter((i) => i.workType === WorkType.PaAssenble)[0]?.id;

    let inspectionId = parameters?.process?.filter((i) => i.workType === WorkType.PaInspection)[0]?.id;
    let packingId = parameters?.process?.filter((i) => i.workType === WorkType.PaPacking)[0]?.id;
    setEditMode(props.editMode != undefined ? props.editMode : false);
    setTblSurface(surfaceData);
    setKouteiInputInfoTemp(props.selectedDataDetail.estimateProducts?.processInput);
    // // setYouSetsuTbl1(yousetsu1Data1);//親部品工程から溶接工程を削除対応

    //親部品リストから呼び出した場合
    if (props?.selectedDataDetail?.estimateProducts != undefined) {
      let surface = props.selectedDataDetail.estimateProducts.processSelect.details?.filter(
        (i) => i.processId === surfaceId
      );
      let surfaceDataFromSelect = [];
      if (surface != undefined) {
        setDisplaySurface(surface[0]?.isUsedForCalc);
        surfaceDataFromSelect = surface[0]?.multiprocesses?.filter(
          (i) => i.processDetailGroup != 0 && i.processDetailTypeId != 0
        );
      }

      // const surfaceDataFromSelect = surface[0]?.multiprocesses?.filter(
      //   (i) => i.processDetailGroup != 0 && i.processDetailTypeId != 0
      // );

      let assenble = props.selectedDataDetail.estimateProducts.processSelect.details?.filter(
        (i) => i.processId === assenbleId
      );
      if (assenble != undefined) {
        setDisplayAssenble(assenble[0]?.isUsedForCalc);
      }

      let inspection = props.selectedDataDetail.estimateProducts.processSelect.details?.filter(
        (i) => i.processId === inspectionId
      );
      if (inspection != undefined) {
        setDisplayInspection(inspection[0]?.isUsedForCalc);
      }

      let packing = props.selectedDataDetail.estimateProducts.processSelect.details?.filter(
        (i) => i.processId === packingId
      );
      if (packing != undefined) {
        setDisplayPacking(packing[0]?.isUsedForCalc);
      }

      if (parameters?.device?.length > 0) {
        let assenbleWorkBasicTime = 0;
        let inspectionWorkBasicTime = 0;
        let packingWorkBasicTime = 0;
        if (assenble != undefined) {
          assenbleWorkBasicTime = parameters?.device?.filter((i) => i.id === assenble[0]?.deviceId)[0]?.details
            .workBasicTime;
        }
        if (inspection != undefined) {
          inspectionWorkBasicTime = parameters?.device?.filter((i) => i.id === inspection[0]?.deviceId)[0]?.details
            .workBasicTime;
        }
        if (packing != undefined) {
          packingWorkBasicTime = parameters?.device?.filter((i) => i.id === packing[0]?.deviceId)[0]?.details
            .workBasicTime;
        }
        let result = [
          { assenbleTime: assenbleWorkBasicTime },
          { inspectionTime: inspectionWorkBasicTime },
          { packingTime: packingWorkBasicTime },
        ];
        setKouteiSentakuProcessesId(result);
      }
      setKouteiInputInfoTemp(props.selectedDataDetail.estimateProducts.processInput);

      let weight = props?.selectedDataDetail?.estimateProducts?.totalWeight;
      let area = props?.selectedDataDetail?.estimateProducts?.totalSurfaceArea;
      // if (props?.selectedDataDetail?.estimateProducts?.id === 0) {
      const data = props.selectedDataDetail.estimateProducts.processInput.surfaceProcessInputInfo;

      // if (surfaceDataFromSelect?.length > 0) {
      //   const createSurfaceData = surfaceDataFromSelect?.map((item) => ({
      //     // ...item,
      //     no: item.processNo,
      //     calcType: 1,
      //     processDetailWorkTypeGroup: item.processDetailGroup,
      //     processDetailTypeNo: item.processDetailTypeId,
      //     areaUnitPrice: 0,
      //     weightUnitPrice: 0,
      //     weightCount: 1,
      //     areaCount: 1,
      //     areaAutoExtract: area,
      //     weightAutoExtract: weight,
      //     totalArea: 0 * 1,
      //     totalWeight: 0 * 1,
      //   }));
      //   props.selectedDataDetail.estimateProducts.processInput.surfaceProcessInputInfo = createSurfaceData;
      //   setKouteiInputInfo(props.selectedDataDetail.estimateProducts.processInput);
      // } else {
      const result = surfaceDataFromSelect?.map((item1) => {
        const matchingData2 = data?.find((item2) => item2.no === item1.processNo);
        if (matchingData2) {
          return {
            ...matchingData2,
            processDetailWorkTypeGroup: item1.processDetailGroup,
            processDetailTypeNo: item1.processDetailTypeId,
          };
        } else {
          return {
            no: item1.processNo,
            calcType: 1,
            processDetailWorkTypeGroup: item1.processDetailGroup,
            processDetailTypeNo: item1.processDetailTypeId,
            areaUnitPrice: 0,
            weightUnitPrice: 0,
            weightCount: 1,
            areaCount: 1,
            areaAutoExtract: area,
            weightAutoExtract: weight,
            totalArea: 0 * 1,
            totalWeight: 0 * 1,
          };
        }
      });

      props.selectedDataDetail.estimateProducts.processInput.surfaceProcessInputInfo = result;
      setKouteiInputInfo(props.selectedDataDetail.estimateProducts.processInput);
      // }
      // } else {
      //   const data = props.selectedDataDetail.estimateProducts.processInput.surfaceProcessInputInfo;

      //   const result = surfaceDataFromSelect?.map((item1) => {
      //     const matchingData2 = data?.find((item2) => item2.no === item1.processNo);
      //     if (matchingData2) {
      //       return {
      //         ...matchingData2,
      //         processDetailWorkTypeGroup: item1.processDetailGroup,
      //         processDetailTypeNo: item1.processDetailTypeId,
      //       };
      //     } else {
      //       return {
      //         no: item1.processNo,
      //         calcType: 1,
      //         processDetailWorkTypeGroup: item1.processDetailGroup,
      //         processDetailTypeNo: item1.processDetailTypeId,
      //         areaUnitPrice: area,
      //         weightUnitPrice: weight,
      //         weightCount: 1,
      //         areaCount: 1,
      //         areaAutoExtract: area,
      //         weightAutoExtract: weight,
      //         totalArea: area * 1,
      //         totalWeight: weight * 1,
      //       };
      //     }
      //   });
      //   props.selectedDataDetail.estimateProducts.processInput.surfaceProcessInputInfo = result;
      //   setKouteiInputInfo(props.selectedDataDetail.estimateProducts.processInput);
      // }

      setPurchasesData(props.selectedDataDetail.estimateProducts.purchases);
    }
  }, [props.activeTabKey]);

  // useEffect(() => {
  //   if (props.activeTabKey == '3') {
  //     let calEstimateInfo = updateEstimateInfoByReCal(props.selectedDataDetail);
  //     props.selectedDataDetail.estimateProducts = calEstimateInfo?.estimateProducts;
  //     props.selectedDataDetail.epCustomCosts = calEstimateInfo?.epCustomCosts;
  //     props.selectedDataDetail.epCustomLabels = calEstimateInfo?.epCustomLabels;
  //     props.selectedDataDetail.epCustomMemos = calEstimateInfo?.epCustomMemos;
  //     props.selectedDataDetail.epCustomDates = calEstimateInfo?.epCustomDates;
  //     props.updateEstimateInfo(calEstimateInfo);
  //   }
  // }, [props.activeTabKey]);

  const openAll = () => {
    setIsWelding(true);
    setIsHyoumenShori(true);
    setIsAssembly(true);
    setIsSurvey(true);
    setIsPacking(true);
  };
  const closeAll = () => {
    setIsWelding(false);
    setIsHyoumenShori(false);
    setIsAssembly(false);
    setIsSurvey(false);
    setIsPacking(false);
  };
  const onChange1 = (e) => {
    setValue1(e.target.value);
  };
  const onChangePlating = (e) => {
    setPlatingValue(e.target.value);
  };
  const onChangePainting = (e) => {
    setPaintingValue(e.target.value);
  };
  const onChangePainting2 = (e) => {
    setPaintingValue2(e.target.value);
  };
  const delivery = () => {
    if (isDelivery == true) {
      setIsDelivery(false);
    } else {
      setIsDelivery(true);
    }
  };
  const welding = () => {
    if (isWelding == true) {
      setIsWelding(false);
    } else {
      setIsWelding(true);
    }
  };
  const hyoumenShori = () => {
    if (isHyoumenShori == true) {
      setIsHyoumenShori(false);
    } else {
      setIsHyoumenShori(true);
    }
  };
  const assembly = () => {
    if (isAssembly == true) {
      setIsAssembly(false);
    } else {
      setIsAssembly(true);
    }
  };
  const packing = () => {
    if (isPacking == true) {
      setIsPacking(false);
    } else {
      setIsPacking(true);
    }
  };
  const survey = () => {
    if (isSurvey == true) {
      setIsSurvey(false);
    } else {
      setIsSurvey(true);
    }
  };
  const setEditModeChange = (mode) => {
    setEditMode(mode);
    yousetsu.current.setEditModeChange(mode);
    kumitate.current.setEditModeChange(mode);
    hyoumenshori.current.setEditModeChange(mode);
    kensa.current.setEditModeChange(mode);
    konpou.current.setEditModeChange(mode);
  };
  const onRdoChange = (e) => {
    setVersion(e.target.value);
  };
  useImperativeHandle(
    ref,
    () => ({
      setEditModeChange: setEditModeChange,
      getKouteiInputInfo: () => {
        return kouteiInputInfo;
      },
    }),
    [kouteiInputInfo]
  );
  // 開発中ポップアップ
  const closeCommonTaiochuPopup = () => {
    setOpenCommonTaiochuPopup(false);
  };
  // 呼出
  const commonTaiochuPopupOpen = () => {
    setOpenCommonTaiochuPopup(true);
  };
  const surfaceTreatementTbl1 = (
    <>
      <AntTable
        bordered
        rowSelection
        columns={columns}
        rowKey={(record) => record.key}
        dataSource={tblSurface}
        scroll={{ y: 146, x: '10vw' }}
        pagination={false}
      />
    </>
  );
  const surfaceTreatementTbl2 = (
    <>
      <AntTable
        bordered
        rowSelection
        columns={columns}
        rowKey={(record) => record.key}
        dataSource={tblSurface}
        scroll={{ y: 146, x: '10vw' }}
        pagination={false}
      />
    </>
  );
  const updatedSurfaceProcessInput = (result) => {
    if (props?.selectedDataDetail?.estimateProducts != undefined) {
      props.selectedDataDetail.estimateProducts.processInput.surfaceProcessInputInfo = result;
    }
  };
  const updatedKumitateProcessInput = (result) => {
    if (props?.selectedDataDetail?.estimateProducts != undefined) {
      props.selectedDataDetail.estimateProducts.processInput.assenbleProcessInputInfo = result;
    }
  };
  const updatedKensaProcessInput = (result) => {
    if (props?.selectedDataDetail?.estimateProducts != undefined) {
      props.selectedDataDetail.estimateProducts.processInput.inspectionProcessInputInfo = result;
    }
  };
  const updatedKonpouProcessInput = (result) => {
    // const updatedData = { ...kouteiInputInfo, packingProcessInputInfo: result };

    // Update the state
    // setKouteiInputInfo(updatedData);

    if (props?.selectedDataDetail?.estimateProducts != undefined) {
      props.selectedDataDetail.estimateProducts.processInput.packingProcessInputInfo = result;
    }
  };
  return (
    <>
      {!displaySurface && !displayAssenble && !displayInspection && !displayPacking ? (
        <></>
      ) : (
        <>
          <Row className="mb-1">
            <Col style={{ paddingLeft: 2 }}>
              <Space size="small">
                <CaretUpOutlined
                  style={{ color: 'white', backgroundColor: '#1063aa', fontSize: 18 }}
                  onClick={closeAll}
                />

                <CaretDownFilled
                  style={{ color: 'white', backgroundColor: '#1063aa', fontSize: 18 }}
                  onClick={openAll}
                />
              </Space>
            </Col>
          </Row>
        </>
      )}
      <div className="overflow-auto kouteical timecharge" style={{ height: '78vh' }}>
        {/* 溶接 */}
        {/* <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={welding}>
          <Col span={20} className="deli-col">
            <div style={{ display: 'flex' }}>
              {isWelding ? (
                <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
              ) : (
                <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
              )}
              <label style={{ marginLeft: 5 }}>溶接</label>
            </div>
          </Col>
        </Row>
        <div style={{ display: isWelding ? 'block' : 'none', margin: '5px' }}>
          <ParentKouteiInput_Yousetsu
            editMode={props.editMode}
            yousetsu1Datas={yousetsuTbl1}
            commonTaiochuPopupOpen={commonTaiochuPopupOpen}
          />
        </div> */}

        {/* 表面処理 */}
        {displaySurface ? (
          <>
            <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={hyoumenShori}>
              <Col span={20} className="deli-col">
                <div style={{ display: 'flex' }}>
                  {isHyoumenShori ? (
                    <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
                  ) : (
                    <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
                  )}
                  <label style={{ marginLeft: 5 }}>表面処理</label>
                </div>
              </Col>
            </Row>
            <div style={{ display: isHyoumenShori ? 'block' : 'none', margin: '5px' }}>
              <IQ3KouteiInput_Hyoumenshori
                // editMode={props.editMode}
                type={'親部品'}
                // hyoumenShoriVal={hyoumenshoriTable}
                kouteiInputInfo={kouteiInputInfo}
                surfaceData={surfaceData}
                updatedSurfaceProcessInput={updatedSurfaceProcessInput}
              />
            </div>
          </>
        ) : (
          <></>
        )}
        {/* 組立 */}
        {displayAssenble ? (
          <>
            <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={assembly}>
              <Col span={20} className="deli-col">
                <div style={{ display: 'flex' }}>
                  {isAssembly ? (
                    <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
                  ) : (
                    <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
                  )}
                  <label style={{ marginLeft: 5 }}>組立</label>
                </div>
              </Col>
            </Row>
            <div style={{ display: isAssembly ? 'block' : 'none', margin: '5px' }}>
              <ParentKouteiInput_Kumitate
                editMode={props.editMode}
                kouteiInputInfo={kouteiInputInfo}
                kumitateDatas={kumitateTbl}
                purchasesData={purchasesData}
                purchaseCategoryInfo={purchaseCategoryInfo}
                commonTaiochuPopupOpen={commonTaiochuPopupOpen}
                updatedKumitateProcessInput={updatedKumitateProcessInput}
                kouteiSentakuProcessesId={kouteiSentakuProcessesId}
              />
            </div>
          </>
        ) : (
          <></>
        )}
        {/* 検査　 */}
        {displayInspection ? (
          <>
            <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={survey}>
              <Col span={20} className="deli-col">
                <div style={{ display: 'flex' }}>
                  {isSurvey ? (
                    <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
                  ) : (
                    <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
                  )}
                  <label style={{ marginLeft: 5 }}>検査</label>
                </div>
              </Col>
            </Row>
            <div style={{ display: isSurvey ? 'block' : 'none', margin: '5px' }}>
              <ParentKouteiInput_Kensa
                editMode={props.editMode}
                kouteiInputInfo={kouteiInputInfo}
                kensaData={kensaTbl}
                commonTaiochuPopupOpen={commonTaiochuPopupOpen}
                updatedKensaProcessInput={updatedKensaProcessInput}
                deviceMasterData={props.deviceMasterData}
                kouteiSentakuProcessesId={kouteiSentakuProcessesId}
              />
            </div>
          </>
        ) : (
          <></>
        )}
        {/* 梱包 */}
        {displayPacking ? (
          <>
            <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={packing}>
              <Col span={20} className="deli-col">
                <div style={{ display: 'flex' }}>
                  {isPacking ? (
                    <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
                  ) : (
                    <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
                  )}
                  <label style={{ marginLeft: 5 }}>梱包</label>
                </div>
              </Col>
            </Row>
            <div style={{ display: isPacking ? 'block' : 'none', margin: '5px' }}>
              <ParentKouteiInput_Konpou
                editMode={props.editMode}
                kensaData={konpouTbl}
                kouteiInputInfo={kouteiInputInfo}
                commonTaiochuPopupOpen={commonTaiochuPopupOpen}
                updatedKonpouProcessInput={updatedKonpouProcessInput}
                kouteiSentakuProcessesId={kouteiSentakuProcessesId}
              />
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
      {/** 開発中ポップアップ */}
      {openCommonTaiochuPopup ? commonTaiochuModal(openCommonTaiochuPopup, closeCommonTaiochuPopup) : ''}
    </>
  );
});

export default KouteiInput;
