/**
 * クラス名：システム全体の共通ファイル
 * 説明：システム全体に共通使っているファイルである。
 * 作成者：ナンス
 * 作成日：2023/03/01
 * バージョン：1.0
 */
import { useEffect, useState, Input, Row, Table } from 'react';
import dayjs from 'dayjs';
import { Button, Input as antdInput, Checkbox } from 'antd';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import { StatusCodes } from 'http-status-codes';
import { Resizable } from 'react-resizable';
import * as XLSX from 'xlsx';

import {
  parentList,
  parentList_PMX_1,
  parentList_PMX_2,
  processInputKensaData,
  processInputKonpouData,
  processInputKumitateData,
  processInputSurfaceData,
} from '../common/Constant';
import '../../assets/styles/common.css';
import { commonModal, error } from '../common/CommonModal';
import { ErrorMessage } from '../common/Message';
import {
  ClientType,
  MaterialCategory,
  MaterialSurfaceBillingUnit,
  WorkType,
  ServiceClass,
  PrepCoeffType,
  ProcessDetailGroup,
  ProcessType,
  EstimateCreationType,
  DataType,
  PatternType,
  WorkTypeGroup,
} from './enums';
import { element } from 'prop-types';
import {
  getMaterialTypeIQ3Info,
  getMaterialTypeInfo,
  getClientInfo,
  getCompanyInfo,
  getStaffInfo,
  getS3FileContent,
} from './CommonAPI';

export const secondsToHms = (totalIncSec) => {
  if (totalIncSec > 0) {
    var h = Math.floor(totalIncSec / 3600).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    var m = Math.floor((totalIncSec % 3600) / 60).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    var s = Math.floor((totalIncSec % 3600) % 60).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    return h + ':' + m + ':' + s;
  } else {
    return '00:00:00';
  }
};

export const setTimetoSec = (timeStr) => {
  if (timeStr == undefined || timeStr == '') return 0;
  var splitTime = timeStr.split(':');
  var seconds = +splitTime[0] * 60 * 60 + +splitTime[1] * 60 + +splitTime[2];
  return seconds;
};

export const handelDecreaseTime = (time, timeType, idLbl) => {
  let totalSec = time;
  let totalIncSec;
  if (timeType == '10min' && totalSec >= 600) {
    totalIncSec = totalSec - 600;
  } else if (timeType == '10min' && totalSec < 600) {
    totalIncSec = totalSec;
  } else if (timeType == 'min' && totalSec >= 60) {
    totalIncSec = totalSec - 60;
  } /* else if (timeType == 'min' && totalSec < 60) {
    totalIncSec = totalSec;
  }  */ else if (timeType == 'sec') {
    totalIncSec = totalSec - 10;
  }
  // let timeStr = secondsToHms(totalIncSec);
  // checkInput(idLbl, timeStr);
  return totalIncSec;
};

export const handelIncreaseTime = (time, timeType, idLbl) => {
  let totalSec = time;
  let totalIncSec;
  if (timeType == '10min') {
    totalIncSec = totalSec + 600;
  } else if (timeType == 'min') {
    totalIncSec = totalSec + 60;
  } else if (timeType == 'sec') {
    totalIncSec = totalSec + 10;
  }
  return totalIncSec;
};

export const checkInput = (id, input) => {
  let originalText = document.getElementById(id).textContent;
  var regexp = /^¥\d+(?:\.\d+)?$/;

  if (originalText !== input) {
    document.getElementById(id + 'In').style.color = 'red';
  } else {
    document.getElementById(id + 'In').style.color = '#000000';
  }
};

function getTextBeforeAmount(inputString) {
  const regex = /(.*?)(?:Amount|Amt)/i;
  const matches = inputString.match(regex);
  if (matches && matches?.length > 1) {
    return matches[1];
  }
  return '';
}

export const handleShowHide = (val1, val2, showHideId) => {
  if (val1 === val2 && document.getElementById(showHideId) !== null) {
    document.getElementById(showHideId).style.display = 'none';
  }
  return 'black';
};

export const checkInputWithColor = (kumitateflg, idI, id, input, color) => {
  let originalText = document.getElementById(id).textContent;
  let originalTextNumber = 0;
  if (input === undefined) {
    input = 0;
  }
  if (typeof input === 'string' && !input.startsWith(':', 2)) {
    if (input.startsWith('¥', 0)) {
      input = input.replace('¥', '');
    }
    if (input.indexOf(',') > 0) {
      input = input.replace(',', '');
    }
    input = Number(input);
  }
  if (originalText.startsWith('¥', 0)) {
    originalText = originalText.replace('¥', '');
    if (originalText.indexOf(',') > 0) {
      originalText = originalText.replace(',', '');
    }
    originalTextNumber = Number(originalText);
  } else if (originalText.startsWith(':', 2)) {
    originalTextNumber = originalText;
  } else {
    originalTextNumber = Number(originalText);
  }
  var regexp = /^¥\d+(?:\.\d+)?$/;
  if (originalTextNumber != input && color === 'red') {
    document.getElementById(id + 'In').style.color = '#F4031E';
  } else if (originalTextNumber != input && color === 'green') {
    document.getElementById(id + 'In').style.color = '#4cc12f';
  } else if (originalTextNumber != input && color === 'amt') {
    document.getElementById(id + 'In').style.color = '#F4031E';
    let siblingEle = document.getElementById(id + 'In').nextSibling;
    if (siblingEle !== null) {
      siblingEle.firstChild.classList?.remove('reset');
      document.getElementById(id + 'Cu').style.display = 'block';
    }
  } else if (originalText != input && color === 'time') {
    document.getElementById(id + 'In').style.color = '#4cc12f';
  } else {
    let idColor = 'black';
    document.getElementById(id + 'In').style.color = idColor;
    let beforeAmt = getTextBeforeAmount(id);
    if (beforeAmt !== '') {
      let timeId = beforeAmt + 'TimeIn';
      if (kumitateflg) {
        let indexAmt = id.indexOf('Amt');
        timeId = indexAmt !== -1 && id.replace('Amt', 'Time') + id[0] + 'In';
        timeId = timeId.replace(id[0], idI);
      }
      if (
        idColor === 'black' &&
        (document.getElementById(timeId).style.color === 'black' || document.getElementById(timeId).style.color === '')
      ) {
        if (document.getElementById(id + 'Cu') !== null) document.getElementById(id + 'Cu').style.display = 'none';
      }
    }
  }
};

export const checkInputDDL = (id, input, color) => {
  let originalText = document.getElementById(id).textContent;
  if (originalText === undefined) {
    originalText = '';
  }
  if (input === undefined) {
    input = '';
  }
  let parent = document.getElementById(id + 'In').parentElement;
  if (originalText != input && color === 'red') {
    parent.nextSibling.style.color = '#F4031E';
    document.getElementById(id + 'In').style.color = '#F4031E';
  } else {
    parent.nextSibling.style.color = '#000000';
    document.getElementById(id + 'In').style.color = '#F4031E';
  }
};

export const checkDDLLbl = (id, input) => {
  let originalText;
  if (document.getElementById(id) != null) {
    originalText = document.getElementById(id).innerText;
  }

  if (originalText === undefined) {
    originalText = '';
  }
  if (input === undefined) {
    input = '';
  }
  let test;
  if (originalText != input) {
    test = true;
  } else {
    test = false;
  }
  return test;
};
export const totalEditData = (arr) => {
  var sum = 0;
  for (var i = 0; i < arr?.length; i++) {
    sum += isNaN(parseFloat(arr[i])) ? 0 : parseFloat(arr[i]);
  }
  return sum;
};

export const formatCurrency = (input, blur) => {
  // appends $ to value, validates decimal side
  // and puts cursor back in right position.

  // get input value
  var input_val = input;

  // don't validate empty input
  if (input_val === '') {
    return;
  }

  // original length
  var original_len = input_val?.length;

  // initial caret position
  //var caret_pos = input.prop('selectionStart');

  // check for decimal
  if (input_val.indexOf('.') >= 0) {
    // get position of first decimal
    // this prevents multiple decimals from
    // being entered
    //var decimal_pos = input_val.indexOf('.');

    // split number by decimal point
    var left_side = input_val.substring(0, decimal_pos);
    var right_side = input_val.substring(decimal_pos);

    // add commas to left side of number
    left_side = formatNumber(left_side);

    // validate right side
    right_side = formatNumber(right_side);

    // On blur make sure 2 numbers after decimal
    if (blur === 'blur') {
      right_side += '00';
    }

    // Limit decimal to only 2 digits
    right_side = right_side.substring(0, 2);

    // join number by .
    input_val = '$' + left_side + '.' + right_side;
  } else {
    // no decimal entered
    // add commas to number
    // remove all non-digits
    input_val = formatNumber(input_val);
    input_val = '$' + input_val;

    // final formatting
    if (blur === 'blur') {
      input_val += '.00';
    }
  }

  // send updated string to input
  input.val(input_val);

  // put caret back in the right position
  var updated_len = input_val?.length;
  caret_pos = updated_len - original_len + caret_pos;
  input[0]?.setSelectionRange(caret_pos, caret_pos);
};

export const formatNumber = (n) => {
  // format number 1000000 to 1,234,567
  return n.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const getInitDataForProcess = () => {
  const data = [];
  for (let i = 1; i < 10; i++) {
    // 標準工程
    const standardInfo = [];
    standardInfo.push({
      parentPart: [{ no: 1, checked: 1 }],
      program: [{ no: 1, checked: 1 }],
      blank: [{ no1: 1, no2: 2, checked: 1 }],
      secondary: [{ no: 1, checked: 1 }],
      bending: [{ no: 1, checked: 1 }],
      manual: [{ no: 1, checked: 1 }],
      automatic: [{ no: 1, checked: 1 }],
      bevel: [{ no: 1, checked: 1 }],
      faceProcess: [{ no: 1, checked: 1 }],
      shearing: [{ no: 1, checked: 1 }],
      welding: [{ no: 1, checked: 1 }],
      weldFinish: [{ no: 1, checked: 0, process1: 1, process2: 1, process3: 1, process4: 1 }],
      surface: [
        {
          no: 1,
          checked: 1,
          process1: [{ no: 2, type: 1 }],
          process2: [{ no: 3, type: 3 }],
          process3: [{ no: 5, type: 3 }],
          process4: [{ no: 1, type: 1 }],
        },
      ],
      assembly: [{ no: 1, checked: 1 }],
      packing: [{ no: 1, checked: 1 }],
      test: [{ no: 1, checked: 1 }],
      delivery: [{ no: 1, checked: 1 }],
      temporary: [{ no: 1, checked: 0 }],
      outSupplier: [
        { no: 1, checked: 1, companyId: 1 },
        { no: 2, checked: 0, companyId: 2 },
        { no: null, checked: 0, companyId: null },
        { no: 1, checked: 0, companyId: 1 },
      ],
      polishing: [{ no: 1, checked: 1 }],
    });
    // 追加工程
    const additionInfo = Array.from({
      length: 20,
    })?.map((_, i) => ({
      key: i.toString(),
      addinUsed: i % 4 === 0,
      addinName: `工程${i + 1}`,
      addinCode: `${i + 101}`,
      customerName: `取引先${i + 1}`,
      customerCode: `${i + 11}`,
      productNumber: `**********`,
      remarks: `**********`,
      // disabled: i % 4 === 0,
    }));

    // 一覧情報
    data.push({
      key: i,
      patternNo: `${i}`,
      patternName: `標準パターン ${i}`,
      standardInfo: standardInfo,
      additionInfo: additionInfo,
    });
  }
  return data;
};
export const getInitDataForProcess1 = () => {
  const data = [];
  for (let i = 1; i < 10; i++) {
    // 標準工程
    const standardInfo = [];
    standardInfo.push({
      parentPart: [{ no: 1, checked: 1 }],
      program: [{ no: 1, checked: 0 }],
      blank: [{ no1: 3, no2: null, checked: 1 }],
      secondary: [{ no: 1, checked: 1 }],
      bending: [{ no: 3, checked: 1 }],
      manual: [{ no: 1, checked: 1 }],
      automatic: [{ no: 1, checked: 1 }],
      bevel: [{ no: 1, checked: 0 }],
      faceProcess: [{ no: 1, checked: 0 }],
      shearing: [{ no: 1, checked: 0 }],
      welding: [{ no: 1, checked: 1 }],
      weldFinish: [{ no: 1, checked: 1, process1: 2, process2: 4, process3: 3, process4: 1 }],
      surface: [
        {
          no: 1,
          checked: 1,
          process1: [{ no: 5, type: 2 }],
          process2: [{ no: 5, type: 2 }],
          process3: [{ no: 1, type: 1 }],
          process4: [{ no: 1, type: 1 }],
        },
      ],
      assembly: [{ no: 1, checked: 1 }],
      packing: [{ no: 1, checked: 1 }],
      test: [{ no: 1, checked: 0 }],
      delivery: [{ no: 1, checked: 1 }],
      temporary: [{ no: 1, checked: 0 }],
      outSupplier: [
        { no: 1, checked: 1, companyId: 1 },
        { no: 2, checked: 0, companyId: 2 },
        { no: 1, checked: 0, companyId: null },
        { no: 1, checked: 0, companyId: 1 },
      ],
      polishing: [{ no: 1, checked: 0 }],
    });
    // 追加工程
    const additionInfo = Array.from({
      length: 20,
    })?.map((_, i) => ({
      key: i.toString(),
      addinUsed: i % 4 === 0,
      addinName: `工程${i + 1}`,
      addinCode: `${i + 101}`,
      customerName: `取引先${i + 1}`,
      customerCode: `${i + 11}`,
      productNumber: `**********`,
      remarks: `**********`,
      // disabled: i % 4 === 0,
    }));

    // 一覧情報
    data.push({
      key: i,
      patternNo: `${i}`,
      patternName: `標準パターン ${i}`,
      standardInfo: standardInfo,
      additionInfo: additionInfo,
    });
  }
  return data;
};

export const getInitDataForProcess2 = () => {
  const data = [];
  for (let i = 1; i < 10; i++) {
    // 標準工程
    const standardInfo = [];
    standardInfo.push({
      parentPart: [{ no: 1, checked: 1 }],
      program: [{ no: 1, checked: 1 }],
      blank: [{ no1: 3, no2: null, checked: 1 }],
      secondary: [{ no: 1, checked: 1 }],
      bending: [{ no: 3, checked: 1 }],
      manual: [{ no: 1, checked: 1 }],
      automatic: [{ no: 1, checked: 1 }],
      bevel: [{ no: 1, checked: 0 }],
      faceProcess: [{ no: 1, checked: 0 }],
      shearing: [{ no: 1, checked: 0 }],
      welding: [{ no: 1, checked: 1 }],
      weldFinish: [{ no: 1, checked: 1, process1: 2, process2: 4, process3: 3, process4: 1 }],
      surface: [
        {
          no: 1,
          checked: 1,
          process1: [{ no: 5, type: 2 }],
          process2: [{ no: 5, type: 2 }],
          process3: [{ no: 1, type: 1 }],
          process4: [{ no: 1, type: 1 }],
        },
      ],
      assembly: [{ no: 1, checked: 1 }],
      packing: [{ no: 1, checked: 1 }],
      test: [{ no: 1, checked: 0 }],
      delivery: [{ no: 1, checked: 1 }],
      temporary: [{ no: 1, checked: 0 }],
      outSupplier: [
        { no: 1, checked: 1, companyId: 1 },
        { no: 2, checked: 0, companyId: 2 },
        { no: 1, checked: 0, companyId: null },
        { no: 1, checked: 0, companyId: 1 },
      ],
      polishing: [{ no: 1, checked: 0 }],
    });
    // 追加工程
    const additionInfo = Array.from({
      length: 20,
    })?.map((_, i) => ({
      key: i.toString(),
      addinUsed: i % 4 === 0,
      addinName: `工程${i + 1}`,
      addinCode: `${i + 101}`,
      customerName: `取引先${i + 1}`,
      customerCode: `${i + 11}`,
      productNumber: `**********`,
      remarks: `**********`,
      // disabled: i % 4 === 0,
    }));

    // 一覧情報
    data.push({
      key: i,
      patternNo: `${i}`,
      patternName: `標準パターン ${i}`,
      standardInfo: standardInfo,
      additionInfo: additionInfo,
    });
  }
  return data;
};
export const getInitDataForProcess3 = () => {
  const data = [];
  for (let i = 1; i < 10; i++) {
    // 標準工程
    const standardInfo = [];
    standardInfo.push({
      parentPart: [{ no: 1, checked: 1 }],
      program: [{ no: 1, checked: 1 }],
      blank: [{ no1: 3, no2: null, checked: 1 }],
      secondary: [{ no: 1, checked: 1 }],
      bending: [{ no: 3, checked: 1 }],
      manual: [{ no: 1, checked: 1 }],
      automatic: [{ no: 1, checked: 1 }],
      bevel: [{ no: 1, checked: 0 }],
      faceProcess: [{ no: 1, checked: 0 }],
      shearing: [{ no: 1, checked: 0 }],
      welding: [{ no: 1, checked: 1 }],
      weldFinish: [{ no: 1, checked: 1, process1: 2, process2: 4, process3: 3, process4: 1 }],
      surface: [
        {
          no: 1,
          checked: 1,
          process1: [{ no: 5, type: 2 }],
          process2: [{ no: 5, type: 2 }],
          process3: [{ no: 1, type: 1 }],
          process4: [{ no: 1, type: 1 }],
        },
      ],
      assembly: [{ no: 1, checked: 1 }],
      packing: [{ no: 1, checked: 1 }],
      test: [{ no: 1, checked: 0 }],
      delivery: [{ no: 1, checked: 1 }],
      temporary: [{ no: 1, checked: 0 }],
      outSupplier: [
        { no: 1, checked: 1, companyId: 1 },
        { no: 2, checked: 0, companyId: 2 },
        { no: 1, checked: 0, companyId: null },
        { no: 1, checked: 0, companyId: 1 },
      ],
      polishing: [{ no: 1, checked: 0 }],
    });
    // 追加工程
    const additionInfo = Array.from({
      length: 20,
    })?.map((_, i) => ({
      key: i.toString(),
      addinUsed: i % 4 === 0,
      addinName: `工程${i + 1}`,
      addinCode: `${i + 101}`,
      customerName: `取引先${i + 1}`,
      customerCode: `${i + 11}`,
      productNumber: `**********`,
      remarks: `**********`,
      // disabled: i % 4 === 0,
    }));

    // 一覧情報
    data.push({
      key: i,
      patternNo: `${i}`,
      patternName: `標準パターン ${i}`,
      standardInfo: standardInfo,
      additionInfo: additionInfo,
    });
  }
  return data;
};
export const getInitDataForProcess4 = () => {
  const data = [];
  for (let i = 1; i < 10; i++) {
    // 標準工程
    const standardInfo = [];
    standardInfo.push({
      parentPart: [{ no: 1, checked: 1 }],
      program: [{ no: 1, checked: 1 }],
      blank: [{ no1: 3, no2: null, checked: 1 }],
      secondary: [{ no: 1, checked: 1 }],
      bending: [{ no: 3, checked: 1 }],
      manual: [{ no: 1, checked: 1 }],
      automatic: [{ no: 1, checked: 1 }],
      bevel: [{ no: 1, checked: 0 }],
      faceProcess: [{ no: 1, checked: 0 }],
      shearing: [{ no: 1, checked: 0 }],
      welding: [{ no: 1, checked: 1 }],
      weldFinish: [{ no: 1, checked: 1, process1: 2, process2: 4, process3: 3, process4: 1 }],
      surface: [
        {
          no: 1,
          checked: 1,
          process1: [{ no: 5, type: 2 }],
          process2: [{ no: 5, type: 2 }],
          process3: [{ no: 1, type: 1 }],
          process4: [{ no: 1, type: 1 }],
        },
      ],
      assembly: [{ no: 1, checked: 1 }],
      packing: [{ no: 1, checked: 1 }],
      test: [{ no: 1, checked: 0 }],
      delivery: [{ no: 1, checked: 1 }],
      temporary: [{ no: 1, checked: 0 }],
      outSupplier: [
        { no: 1, checked: 1, companyId: 1 },
        { no: 2, checked: 0, companyId: 2 },
        { no: 1, checked: 0, companyId: null },
        { no: 1, checked: 0, companyId: 1 },
      ],
      polishing: [{ no: 1, checked: 0 }],
    });
    // 追加工程
    const additionInfo = Array.from({
      length: 20,
    })?.map((_, i) => ({
      key: i.toString(),
      addinUsed: i % 4 === 0,
      addinName: `工程${i + 1}`,
      addinCode: `${i + 101}`,
      customerName: `取引先${i + 1}`,
      customerCode: `${i + 11}`,
      productNumber: `**********`,
      remarks: `**********`,
      // disabled: i % 4 === 0,
    }));

    // 一覧情報
    data.push({
      key: i,
      patternNo: `${i}`,
      patternName: `標準パターン ${i}`,
      standardInfo: standardInfo,
      additionInfo: additionInfo,
    });
  }
  return data;
};
export const getInitDataForProcess5 = () => {
  const data = [];
  for (let i = 1; i < 10; i++) {
    // 標準工程
    const standardInfo = [];
    standardInfo.push({
      parentPart: [{ no: 1, checked: 1 }],
      program: [{ no: 1, checked: 1 }],
      blank: [{ no1: 3, no2: null, checked: 1 }],
      secondary: [{ no: 1, checked: 1 }],
      bending: [{ no: 3, checked: 1 }],
      manual: [{ no: 1, checked: 1 }],
      automatic: [{ no: 1, checked: 1 }],
      bevel: [{ no: 1, checked: 0 }],
      faceProcess: [{ no: 1, checked: 0 }],
      shearing: [{ no: 1, checked: 0 }],
      welding: [{ no: 1, checked: 1 }],
      weldFinish: [{ no: 1, checked: 1, process1: 2, process2: 4, process3: 3, process4: 1 }],
      surface: [
        {
          no: 1,
          checked: 1,
          process1: [{ no: 5, type: 2 }],
          process2: [{ no: 5, type: 2 }],
          process3: [{ no: 1, type: 1 }],
          process4: [{ no: 1, type: 1 }],
        },
      ],
      assembly: [{ no: 1, checked: 1 }],
      packing: [{ no: 1, checked: 1 }],
      test: [{ no: 1, checked: 0 }],
      delivery: [{ no: 1, checked: 1 }],
      temporary: [{ no: 1, checked: 0 }],
      outSupplier: [
        { no: 1, checked: 1, companyId: 1 },
        { no: 2, checked: 0, companyId: 2 },
        { no: 1, checked: 0, companyId: null },
        { no: 1, checked: 0, companyId: 1 },
      ],
      polishing: [{ no: 1, checked: 0 }],
    });
    // 追加工程
    const additionInfo = Array.from({
      length: 20,
    })?.map((_, i) => ({
      key: i.toString(),
      addinUsed: i % 4 === 0,
      addinName: `工程${i + 1}`,
      addinCode: `${i + 101}`,
      customerName: `取引先${i + 1}`,
      customerCode: `${i + 11}`,
      productNumber: `**********`,
      remarks: `**********`,
      // disabled: i % 4 === 0,
    }));

    // 一覧情報
    data.push({
      key: i,
      patternNo: `${i}`,
      patternName: `標準パターン ${i}`,
      standardInfo: standardInfo,
      additionInfo: additionInfo,
    });
  }
  return data;
};

export const getInitDataForProcess_PMX_1 = () => {
  const data = [];
  for (let i = 1; i < 10; i++) {
    // 標準工程
    const standardInfo = [];
    standardInfo.push({
      parentPart: [{ no: 1, checked: 0 }],
      program: [{ no: 1, checked: 1 }],
      blank: [{ no1: 1, no2: 2, checked: 1 }],
      secondary: [{ no: 1, checked: 1 }],
      bending: [{ no: 1, checked: 1 }],
      manual: [{ no: 1, checked: 1 }],
      automatic: [{ no: 1, checked: 1 }],
      bevel: [{ no: 1, checked: 1 }],
      faceProcess: [{ no: 1, checked: 1 }],
      shearing: [{ no: 1, checked: 1 }],
      welding: [{ no: 1, checked: 1 }],
      weldFinish: [{ no: 1, checked: 0, process1: 1, process2: 1, process3: 1, process4: 1 }],
      surface: [
        {
          no: 1,
          checked: 1,
          process1: [{ no: 2, type: 1 }],
          process2: [{ no: 5, type: 3 }],
          process3: [{ no: 1, type: 1 }],
          process4: [{ no: 1, type: 1 }],
        },
      ],
      assembly: [{ no: 1, checked: 1 }],
      packing: [{ no: 1, checked: 1 }],
      test: [{ no: 1, checked: 1 }],
      delivery: [{ no: 1, checked: 1 }],
      temporary: [{ no: 1, checked: 0 }],
      outSupplier: [
        { no: 1, checked: 0, companyId: 1 },
        { no: 2, checked: 0, companyId: 2 },
        { no: null, checked: 0, companyId: null },
        { no: 1, checked: 0, companyId: 1 },
      ],
      polishing: [{ no: 1, checked: 0 }],
    });
    // 追加工程
    const additionInfo = Array.from({
      length: 20,
    })?.map((_, i) => ({
      key: i.toString(),
      addinUsed: i % 4 === 0,
      addinName: `工程${i + 1}`,
      addinCode: `${i + 101}`,
      customerName: `取引先${i + 1}`,
      customerCode: `${i + 11}`,
      productNumber: `**********`,
      remarks: `**********`,
      // disabled: i % 4 === 0,
    }));

    // 一覧情報
    data.push({
      key: i,
      patternNo: `${i}`,
      patternName: `標準パターン ${i}`,
      standardInfo: standardInfo,
      additionInfo: additionInfo,
    });
  }
  return data;
};
export const getInitDataForProcess_PMX_1_1 = () => {
  const data = [];
  for (let i = 1; i < 10; i++) {
    // 標準工程
    const standardInfo = [];
    standardInfo.push({
      parentPart: [{ no: 1, checked: 1 }],
      program: [{ no: 1, checked: 1 }],
      blank: [{ no1: 3, no2: null, checked: 1 }],
      secondary: [{ no: 1, checked: 1 }],
      bending: [{ no: 3, checked: 1 }],
      manual: [{ no: 1, checked: 1 }],
      automatic: [{ no: 1, checked: 1 }],
      bevel: [{ no: 1, checked: 0 }],
      faceProcess: [{ no: 1, checked: 0 }],
      shearing: [{ no: 1, checked: 0 }],
      welding: [{ no: 1, checked: 1 }],
      weldFinish: [{ no: 1, checked: 1, process1: 2, process2: 4, process3: 3, process4: 1 }],
      surface: [
        {
          no: 1,
          checked: 1,
          process1: [{ no: 5, type: 2 }],
          process2: [{ no: 5, type: 2 }],
          process3: [{ no: 1, type: 1 }],
          process4: [{ no: 1, type: 1 }],
        },
      ],
      assembly: [{ no: 1, checked: 1 }],
      packing: [{ no: 1, checked: 1 }],
      test: [{ no: 1, checked: 0 }],
      delivery: [{ no: 1, checked: 1 }],
      temporary: [{ no: 1, checked: 0 }],
      outSupplier: [
        { no: 1, checked: 1, companyId: 1 },
        { no: 2, checked: 0, companyId: 2 },
        { no: 1, checked: 0, companyId: null },
        { no: 1, checked: 0, companyId: 1 },
      ],
      polishing: [{ no: 1, checked: 0 }],
    });
    // 追加工程
    const additionInfo = Array.from({
      length: 20,
    })?.map((_, i) => ({
      key: i.toString(),
      addinUsed: i % 4 === 0,
      addinName: `工程${i + 1}`,
      addinCode: `${i + 101}`,
      customerName: `取引先${i + 1}`,
      customerCode: `${i + 11}`,
      productNumber: `**********`,
      remarks: `**********`,
      // disabled: i % 4 === 0,
    }));

    // 一覧情報
    data.push({
      key: i,
      patternNo: `${i}`,
      patternName: `標準パターン ${i}`,
      standardInfo: standardInfo,
      additionInfo: additionInfo,
    });
  }
  return data;
};
export const getInitDataForProcess_PMX_2 = () => {
  const data = [];
  for (let i = 1; i < 10; i++) {
    // 標準工程
    const standardInfo = [];
    standardInfo.push({
      parentPart: [{ no: 1, checked: 1 }],
      program: [{ no: 1, checked: 1 }],
      blank: [{ no1: 1, no2: 2, checked: 1 }],
      secondary: [{ no: 1, checked: 1 }],
      bending: [{ no: 1, checked: 1 }],
      manual: [{ no: 1, checked: 1 }],
      automatic: [{ no: 1, checked: 1 }],
      bevel: [{ no: 1, checked: 1 }],
      faceProcess: [{ no: 1, checked: 1 }],
      shearing: [{ no: 1, checked: 1 }],
      welding: [{ no: 1, checked: 1 }],
      weldFinish: [{ no: 1, checked: 1, process1: 1, process2: 1, process3: 1, process4: 1 }],
      surface: [
        {
          no: 1,
          checked: 0,
          process1: [{ no: 2, type: 1 }],
          process2: [{ no: 5, type: 3 }],
          process3: [{ no: 1, type: 1 }],
          process4: [{ no: 1, type: 1 }],
        },
      ],
      assembly: [{ no: 1, checked: 1 }],
      packing: [{ no: 1, checked: 1 }],
      test: [{ no: 1, checked: 1 }],
      delivery: [{ no: 1, checked: 1 }],
      temporary: [{ no: 1, checked: 0 }],
      outSupplier: [
        { no: 1, checked: 0, companyId: 1 },
        { no: 2, checked: 0, companyId: 2 },
        { no: null, checked: 0, companyId: null },
        { no: 1, checked: 0, companyId: 1 },
      ],
      polishing: [{ no: 1, checked: 1 }],
    });
    // 追加工程
    const additionInfo = Array.from({
      length: 20,
    })?.map((_, i) => ({
      key: i.toString(),
      addinUsed: i % 4 === 0,
      addinName: `工程${i + 1}`,
      addinCode: `${i + 101}`,
      customerName: `取引先${i + 1}`,
      customerCode: `${i + 11}`,
      productNumber: `**********`,
      remarks: `**********`,
      // disabled: i % 4 === 0,
    }));

    // 一覧情報
    data.push({
      key: i,
      patternNo: `${i}`,
      patternName: `標準パターン ${i}`,
      standardInfo: standardInfo,
      additionInfo: additionInfo,
    });
  }
  return data;
};
export const getInitDataForProcess_PMX_2_1 = () => {
  const data = [];
  for (let i = 1; i < 10; i++) {
    // 標準工程
    const standardInfo = [];
    standardInfo.push({
      parentPart: [{ no: 1, checked: 1 }],
      program: [{ no: 1, checked: 1 }],
      blank: [{ no1: 3, no2: null, checked: 1 }],
      secondary: [{ no: 1, checked: 1 }],
      bending: [{ no: 3, checked: 1 }],
      manual: [{ no: 1, checked: 1 }],
      automatic: [{ no: 1, checked: 1 }],
      bevel: [{ no: 1, checked: 0 }],
      faceProcess: [{ no: 1, checked: 0 }],
      shearing: [{ no: 1, checked: 0 }],
      welding: [{ no: 1, checked: 1 }],
      weldFinish: [{ no: 1, checked: 1, process1: 2, process2: 4, process3: 3, process4: 1 }],
      surface: [
        {
          no: 1,
          checked: 1,
          process1: [{ no: 5, type: 2 }],
          process2: [{ no: 5, type: 2 }],
          process3: [{ no: 1, type: 1 }],
          process4: [{ no: 1, type: 1 }],
        },
      ],
      assembly: [{ no: 1, checked: 1 }],
      packing: [{ no: 1, checked: 1 }],
      test: [{ no: 1, checked: 0 }],
      delivery: [{ no: 1, checked: 1 }],
      temporary: [{ no: 1, checked: 0 }],
      outSupplier: [
        { no: 1, checked: 1, companyId: 1 },
        { no: 2, checked: 0, companyId: 2 },
        { no: 1, checked: 0, companyId: null },
        { no: 1, checked: 0, companyId: 1 },
      ],
      polishing: [{ no: 1, checked: 0 }],
    });
    // 追加工程
    const additionInfo = Array.from({
      length: 20,
    })?.map((_, i) => ({
      key: i.toString(),
      addinUsed: i % 4 === 0,
      addinName: `工程${i + 1}`,
      addinCode: `${i + 101}`,
      customerName: `取引先${i + 1}`,
      customerCode: `${i + 11}`,
      productNumber: `**********`,
      remarks: `**********`,
      // disabled: i % 4 === 0,
    }));

    // 一覧情報
    data.push({
      key: i,
      patternNo: `${i}`,
      patternName: `標準パターン ${i}`,
      standardInfo: standardInfo,
      additionInfo: additionInfo,
    });
  }
  return data;
};

export const patternPage = [
  { mode: '1', page: '親部品' },
  { mode: '2', page: '板金' },
  { mode: '3', page: 'パラメータ' },
];

export const getTenjikaiSampleData = () => {
  // 親部品リスト
  // const parentList = [];
  // parentList.push(parentList);

  // サンプルデータ纏め
  const sampleData = {
    key: 1,
    parentList: parentList,
    parentList_PMX_1: parentList_PMX_1,
    parentList_PMX_2: parentList_PMX_2,
  };
  return sampleData;
};
export const isNumber = (char) => {
  return /^\d$/.test(char);
};

export const checkShowHideIcon = (id) => {
  let showHideCu = 'none';
  if (document.getElementById(id) !== null) {
    showHideCu = document.getElementById(id).style.display;
  }
  return showHideCu;
};

export const JPYs = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'JPY',
});

export const Percent = new Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumFractionDigits: 1,
});

export const kouteiCalculateSelectedArr = [
  { key: 0, value: 'データ' },
  { key: 1, value: '編集データ' },
];
export const dataStateList = [
  { value: 0, label: '全て' },
  { value: 1, label: '表示' },
  { value: 2, label: '非表示' },
];
export const flgStateList = [
  { value: 0, label: '全て' },
  { value: 1, label: 'オン' },
  { value: 2, label: 'オフ' },
];
export const parameterCostLst = [
  {
    key: 1,
    title: '諸経費',
    data: 0,
    dataIndex: 'costLst1',
    isUsedTanka: true,
  },
  {
    key: 2,
    title: '特別費',
    data: 0,
    dataIndex: 'costLst2',
    isUsedTanka: true,
  },
  {
    key: 3,
    title: '調査費​',
    data: 0,
    dataIndex: 'costLst3',
    isUsedTanka: true,
  },
  {
    key: 4,
    title: 'カスタマイズ費用',
    data: 0,
    dataIndex: 'costLst4',
    isUsedTanka: true,
  },
];
export const parameterDateLst = [
  {
    key: 1,
    title: '社内納期',
    data: dayjs().format('YYYY-MM-DD'),
    dataIndex: 'dateLst1',
  },
  {
    key: 2,
    title: '回答日',
    data: dayjs().format('YYYY-MM-DD'),
    dataIndex: 'dateLst2',
  },
  {
    key: 3,
    title: 'カスタマイズ日付',
    data: dayjs().format('YYYY-MM-DD'),
    dataIndex: 'dateLst3',
  },
];
export const parameterLabelLst = [
  {
    key: 1,
    title: '製造部管理ラベル',
    data: '',
    dataIndex: 'labelLst1',
  },
  {
    key: 2,
    title: '製造部管理ラベル',
    data: '',
    dataIndex: 'labelLst2',
  },
  {
    key: 3,
    title: 'カスタマイズラベル',
    data: '',
    dataIndex: 'labelLst3',
  },
];
export const parameterMemoLst = [
  {
    key: 1,
    title: '注意事項',
    data: '',
    dataIndex: 'memoLst1',
  },
  {
    key: 2,
    title: '見積備考',
    data: '',
    dataIndex: 'memoLst2',
  },
  {
    key: 3,
    title: 'コメント',
    data: '',
    dataIndex: 'memoLst3',
  },
  {
    key: 4,
    title: 'カスタマイズメモ',
    data: '',
    dataIndex: 'memoLst4',
  },
];

// export const getNewParentInfo = (key) => {
//   return {
//     key: key > 0 ? key - 1 : 0,
//     id: key,
//     customerCode: code,
//     customerNm: name,
//     imgNo: formatDateString(todayTime),
//     name: formatDateString(todayTime),
//     lotNum: 1,
//     grossProfit: 0,
//     rate: 0,
//     unitPrice: 0,
//     estimatedAmount: 0,
//     materialAmount: 0,
//     matufacturingAmount: 0,
//     setupAmount: 0,
//     processingAmount: 0,
//     purchaseCost: 0,
//     managementCost: 0,
//     isOrder: '単発',
//     deliveryType: '普通',
//     img: '',
//     allowGaichu: '受注',
//     sizeX: '0.00',
//     sizeY: '0.00',
//     sizeZ: '0.00',
//     childPartTotalWeight: 0,
//     iq3Weight: 0,
//     iq5Weight: 0,
//     iq7Weight: 0,
//     childPartTypeCnt: 0,
//     iq3TypeCnt: 0,
//     iq5TypeCnt: 0,
//     iq7TypeCnt: 0,
//     childPartTotalProduceCnt: 0,
//     iq3TotalProduceCnt: 0,
//     iq5TotalProduceCnt: 0,
//     iq7TotalProduceCnt: 0,
//     kumitate: 0,
//     inspection: 0,
//     packing: 0,
//     temporaryAttachment: 0,
//     outsourcing: 0,
//     polishing: 0,
//     comment: '',
//     estimateNo: '初期値',
//     estimator: '石田 浩太郎',
//     creator: '石田 浩太郎',
//     latestUpdator: '石田 浩太郎',
//     createdDate: 'YYYY-MM-DD',
//     updatedDate: 'YYYY-MM-DD',
//     costLst: parameterCostLst,
//     dateLst: parameterDateLst,
//     labelLst: parameterLabelLst,
//     memoLst: parameterMemoLst,
//     customerCoefficient: '1.00',
//     managementCoefficient: '1.00',
//     materialLossCoefficient: '1.00',
//     orderCoefficient: '1.00',
//     setupCoefficient: '1.00',
//     surfaceAreaCoefficient: '1.00',
//     purchaseCoefficient: '1.00',
//     deliveryCoefficient: '1.00',
//     orderCoefficient: '1.00',
//     shippingMethod: '普通',
//     shippingArea: '普通',
//     weight: 0,
//     surfaceArea: '0.00',
//     parentProcess: [],
//     iq3Process: [],
//     iq5Process: [],
//     iq3MaterialAmount: 0,
//     iq5MaterialAmount: 0,
//     iq3DataLst: [],
//     volume: 0,
//     costPrice: 0,
//     totalCostPrice: 0,
//     estimatedAmount: 0,
//     totalEstimatedAmount: 0,
//     costProfit: 0,
//     estimateProfit: 0,
//     materialCost: 0,
//     processCost: 0,
//     purchaseCost: 0,
//     hiyou: 0,
//     kouteiCalInfoData: [],
//     kouteiCalInfoEditData: [],
//   };
// };

// 現在の日付「YYYY/MM/DD」

export const today = new Date().toLocaleString('ja-JP', {
  timeZone: 'Asia/Tokyo',
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
});

// 現在の日付「YYYY/MM/DD hhmmss」
export const todayTime = () => {
  return new Date().toLocaleString('ja-JP', {
    timeZone: 'Asia/Tokyo',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    format: 'YYYYMMDD hhmmss',
  });
};

// YYYY/MM/DDへ変換
export function formatDate(date) {
  return new Date(date).toLocaleString('ja-JP', {
    timeZone: 'Asia/Tokyo',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
}

export function formatDateString(date, sep = '') {
  let dateString = date.replace(/[/:]+/g, '');
  return dateString;
}

/* export const zaishittsuNameDDL = [
  { value: 1, label: 'SPCC' },
  { value: 2, label: 'SPHC（黒皮）' },
  { value: 3, label: 'SPHC（酸洗）' },
  { value: 4, label: 'SECC（ボンデ）' },
  { value: 5, label: 'SS400' },
  { value: 6, label: 'ZAM' },
  { value: 7, label: 'SUS304/2B' },
  { value: 8, label: 'SUS304/NO1' },
  { value: 9, label: 'SUS304/#400' },
  { value: 10, label: 'SUS304/両面#400' },
  { value: 11, label: 'SUS304/HL' },
  { value: 12, label: 'SUS304/両面HL' },
  { value: 13, label: 'SUS304/BA' },
  { value: 14, label: 'SUS316' },
  { value: 15, label: 'SUS316L' },
  { value: 16, label: 'SUS430/2B' },
  { value: 17, label: 'SUS430/BA' },
  { value: 18, label: 'SUS430/#400' },
  { value: 19, label: 'SUS430/HL' },
  { value: 20, label: 'A1050' },
  { value: 21, label: 'A1100' },
  { value: 22, label: 'A5052' },
  { value: 23, label: 'C1100' },
]; */

export const getZaishittsuNameDDL = () => {
  let materialClassNameStorage = getMaterialClassNameStorage();
  const zaishittsuNameDDL = [];
  let count = 0;
  materialClassNameStorage?.forEach((item) => {
    if (item.displayFlag) {
      zaishittsuNameDDL.push({ value: count++, label: item.materialName });
    }
  });
  return zaishittsuNameDDL;
};

// 板金子部品材質マスタを選択ボックス情報にする
export const getMaterialTypeIQ3List = async () => {
  let materialTypesIq3 = await getMaterialTypeIQ3Info();
  const materialTypesIq3List = [];
  materialTypesIq3?.forEach((item) => {
    if (item.info.isUsed) {
      materialTypesIq3List.push({ value: item.id, label: item.name, gravity: item?.info?.gravity });
    }
  });
  return materialTypesIq3List;
};

export const MaterialCategories = [
  { value: MaterialCategory.Iron, label: '鉄' },
  { value: MaterialCategory.Stainless, label: 'ステンレス' },
  { value: MaterialCategory.Aluminum, label: 'アルミ' },
  { value: MaterialCategory.Copper, label: '銅' },
  { value: MaterialCategory.Zinc, label: '亜鉛' },
  { value: MaterialCategory.Other, label: 'その他' },
];

export const unitTypes = [
  { value: MaterialSurfaceBillingUnit.Weight, label: '重量' },
  { value: MaterialSurfaceBillingUnit.SquareMeter, label: '平米' },
];

export const ServiceClasses = [
  { value: ServiceClass.Parent, label: '親部品' },
  { value: ServiceClass.SheetMetal, label: '板金子部品' },
  { value: ServiceClass.Beams, label: '形鋼' },
  { value: ServiceClass.Machining, label: '機械加工' },
];

export const WorkTypes = [
  { value: WorkType.PaWelding, label: '溶接' },
  { value: WorkType.PaWeldFinish, label: '溶接仕上' },
  { value: WorkType.PaSurface, label: '表面処理' },
  { value: WorkType.PaAssenble, label: '組立' },
  { value: WorkType.PaInspection, label: '検査' },
  { value: WorkType.PaPacking, label: '梱包' },
  { value: WorkType.PaCustom, label: '追加工程（カスタム）' },
  { value: WorkType.SmProgram, label: 'プログラム' },
  { value: WorkType.SmBlankLaser, label: 'ブランク（レーザー）' },
  { value: WorkType.SmBlankPunch, label: 'ブランク（パンチ）' },
  { value: WorkType.SmBlankCombi, label: 'ブランク（複合機）' },
  { value: WorkType.SmSecondaryWork, label: '2次加工' },
  { value: WorkType.SmBending, label: 'ベンディング' },
  { value: WorkType.SmDeburringAuto, label: 'バリ取（自動）' },
  { value: WorkType.SmDeburringManual, label: 'バリ取（手動）' },
  { value: WorkType.SmWelding, label: '溶接' },
  { value: WorkType.SmWeldFinish, label: '溶接仕上' },
  { value: WorkType.SmSurface, label: '表面処理' },
  { value: WorkType.SmShearing, label: 'シャーリング' },
  { value: WorkType.SmInspection, label: '検査' },
  { value: WorkType.SmCustom, label: '追加工程（カスタム）' },
];

export const getKubunNameDDL = () => {
  let materialClassStorageData = getMaterialClassDataSample();
  const kubunNameDDL = [];
  let count = 0;
  materialClassStorageData?.forEach((item) => {
    if (item.displayFlag) {
      kubunNameDDL.push({ value: count++, label: item.classificationName });
    }
  });
  return kubunNameDDL;
};

// 材質区分名を選択ボックス情報にする
export const getMaterialTypeList = async () => {
  let materialTypes = await getMaterialTypeInfo();
  const materialTypesList = [];
  let count = 0;
  materialTypes?.forEach((item) => {
    if (item.info.isUsed) {
      materialTypesList.push({ value: item.id, label: item.name });
    }
  });
  return materialTypesList;
};

// 工程パターン画面のCarousel操作
const iconClassName_inactive = 'carousel-circleOutlined-inactive';
const iconClassName_active = 'carousel-circleOutlined-active';
let iconClassName_pre = iconClassName_active;
let iconClassName_next = iconClassName_active;
const slickActive = 'slick-active';

export const CarouselPrevArrow = (props) => {
  const { className, style, onClick, targetDivCnt, name } = props;

  const onChangePrevArrow = () => {
    let activeDiv = [];
    let activeDivIndex = 0;
    activeDiv = document.getElementById(name).getElementsByClassName(slickActive);
    if (activeDiv?.length > 1) activeDivIndex = activeDiv[activeDiv?.length - 1]?.innerText;
    let lastDivIndex = parseInt(activeDivIndex) - 1;
    if (activeDivIndex == 1) {
      // iconClassName_pre = iconClassName_inactive;
    } else if (activeDivIndex <= targetDivCnt) {
      // iconClassName_next = iconClassName_active;
      onClick();
      // if (lastDivIndex === 1) {
      //   iconClassName_pre = iconClassName_inactive;
      // } else {
      //   iconClassName_pre = iconClassName_active;
      // }
    }
  };

  return (
    <Button
      className={className}
      style={{ ...style }}
      onClick={onChangePrevArrow}
      icon={<LeftCircleOutlined id="leftCarouselCircle" className={iconClassName_pre} />}
    />
  );
};
export const CarouselNextArrow = (props) => {
  const { className, style, onClick, targetDivCnt, name } = props;

  const onChangeNextArrow = () => {
    let activeDiv = [];
    let activeDivIndex = 0;
    activeDiv = document.getElementById(name).getElementsByClassName(slickActive);
    if (activeDiv?.length > 1) activeDivIndex = activeDiv[activeDiv?.length - 1]?.innerText;
    let lastDivIndex = parseInt(activeDivIndex) + 1;

    if (activeDivIndex < targetDivCnt) {
      // iconClassName_pre = iconClassName_active;
      onClick();
      // if (lastDivIndex === targetDivCnt) {
      //   iconClassName_next = iconClassName_inactive;
      // } else {
      //   iconClassName_next = iconClassName_active;
      // }
    } else {
      // iconClassName_next = iconClassName_inactive;
    }
  };

  return (
    <Button
      className={className}
      style={{ ...style }}
      onClick={onChangeNextArrow}
      icon={<RightCircleOutlined id="rightCarouselCircle" className={iconClassName_next} />}
    />
  );
};

// export const initialCarouselBtn = () => {
//   let leftCircleList = document.getElementsByClassName('anticon-left-circle');
//   Object.keys(leftCircleList)?.map((anticonLeftCircle, j) => {
//     leftCircleList[anticonLeftCircle]?.classList?.remove(iconClassName_active);
//     leftCircleList[anticonLeftCircle]?.classList.add(iconClassName_inactive);
//   });
//   let rightCircleList = document.getElementsByClassName('anticon-right-circle');
//   Object.keys(rightCircleList)?.map((anticonRightCircle, j) => {
//     rightCircleList[anticonRightCircle]?.classList?.remove(iconClassName_inactive);
//     rightCircleList[anticonRightCircle]?.classList.add(iconClassName_active);
//   });
// };

export function groupBy(items) {
  return items?.reduce((acc, curr) => {
    if (curr.detailGroup) {
      const { detailGroup } = curr;
      const currentItems = acc[detailGroup];

      return {
        ...acc,
        [detailGroup]: currentItems ? [...currentItems, curr] : [curr],
      };
    }
    return acc;
  }, {});
}

// 装置の段取時間更新
export const souchiHandleIncreaseTime = (time, timeType) => {
  let totalSec = Number(setTimetoSec(time));
  let totalIncSec;
  if (timeType == '10min') {
    totalIncSec = totalSec + 600;
  } else if (timeType == 'min') {
    totalIncSec = totalSec + 60;
  } else if (timeType == 'sec') {
    totalIncSec = totalSec + 10;
  }
  let timeStr = secondsToHms(totalIncSec);

  return timeStr;
};
export const souchiHandleDecreaseTime = (time, timeType) => {
  let totalSec = Number(setTimetoSec(time));
  let totalIncSec;
  if (timeType == '10min' && totalSec >= 600) {
    totalIncSec = totalSec - 600;
  } else if (timeType == '10min' && totalSec < 600) {
    totalIncSec = totalSec;
  } else if (timeType == 'min' && totalSec >= 60) {
    totalIncSec = totalSec - 60;
  } /* else if (timeType == 'min' && totalSec < 60) {
    totalIncSec = totalSec;
  }  */ else if (timeType == 'sec') {
    totalIncSec = totalSec - 10;
  }
  let timeStr = secondsToHms(totalIncSec);

  return timeStr;
};

export const actionArr = [
  {
    key: 0,
    methodName: 'rowSelect',
  },
  {
    key: 1,
    methodName: 'searchByKeyword',
  },
  {
    key: 2,
    methodName: 'handleGpEdit',
  },
  {
    key: 3,
    methodName: 'handleAddFormSubmit',
  },
  {
    key: 4,
    methodName: 'searchByDataState',
  },
  {
    key: 5,
    methodName: 'searchByShowFlg',
  },
];

export const removeYen = (value) => {
  let noYen = String(value);
  if (value === '' || value === undefined) {
    noYen = '0';
  }
  if (value.startsWith('¥', 0)) {
    noYen = noYen.replace('¥', '');
  }
  noYen = noYen.replace(/,/g, '');
  return noYen;
};

export const { TextArea } = antdInput;

// メールフォーマットのチェック
export function isEmail(val) {
  // ####@####の形式
  let regEmail = /^[^\s]+@[^\s]+$/;
  return regEmail.test(val);
}

// 数字のチェック
export function isDigit(val) {
  // 整数値のみ
  let regDigit = /^[0-9]+$/;
  return regDigit.test(val);
}

// Tel又Faxのチェック
export function isTelAndFax(val) {
  // プラス、ハイフン、整数値のみ
  let regStr = /^[0-9+-]+$/;
  return regStr.test(val);
}

// 小数点のチェック
export function isDecimal(val) {
  // 小数点
  let regStr = /^\d*\.?\d*$/;
  return regStr.test(val);
}

// 入力項目のチェックを行う処理タイプ
export const formValidatorMode = {
  Create: 1, // 新規作成
  Edit: 2, // 編集
  BulkEdit: 3, // 一括編集
};

// セクションにトークン情報を更新する
export const updateAccessToken = (token) => {
  localStorage.setItem('iQxWeb_AccessToken', token);
};

const releaseDate = '2023-10-01';
export const getMaterialClassDataSample = () => {
  let count = 0;
  const materialClassDataSample = [
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classificationCode: (++count).toString().padStart(3, '0'),
      classificationName: 'SPHC',
      materialRefPrice: '鉄',
      created: releaseDate,
      modified: releaseDate,
      remark: '',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classificationCode: (++count).toString().padStart(3, '0'),
      classificationName: 'SPCC',
      materialRefPrice: '鉄',
      created: releaseDate,
      modified: releaseDate,
      remark: '',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classificationCode: (++count).toString().padStart(3, '0'),
      classificationName: 'SECC',
      materialRefPrice: '鉄',
      created: releaseDate,
      modified: releaseDate,
      remark: '',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classificationCode: (++count).toString().padStart(3, '0'),
      classificationName: 'SUS',
      materialRefPrice: 'ステンレス',
      created: releaseDate,
      modified: releaseDate,
      remark: '',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classificationCode: (++count).toString().padStart(3, '0'),
      classificationName: 'AL',
      materialRefPrice: 'アルミ',
      created: releaseDate,
      modified: releaseDate,
      remark: '',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classificationCode: (++count).toString().padStart(3, '0'),
      classificationName: 'CU',
      materialRefPrice: '銅',
      created: releaseDate,
      modified: releaseDate,
      remark: '',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: false,
      classificationCode: (++count).toString().padStart(3, '0'),
      classificationName: 'その他',
      materialRefPrice: '',
      created: releaseDate,
      modified: releaseDate,
      remark: '',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: false,
      classificationCode: (++count).toString().padStart(3, '0'),
      classificationName: 'その他',
      materialRefPrice: '',
      created: releaseDate,
      modified: releaseDate,
      remark: '',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: false,
      classificationCode: (++count).toString().padStart(3, '0'),
      classificationName: 'その他',
      materialRefPrice: '',
      created: releaseDate,
      modified: releaseDate,
      remark: '',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: false,
      classificationCode: (++count).toString().padStart(3, '0'),
      classificationName: 'その他',
      materialRefPrice: '',
      created: releaseDate,
      modified: releaseDate,
      remark: '',
    },
  ];
  if (
    localStorage.getItem('materialClassStorageData') === null ||
    localStorage.getItem('materialClassStorageData')?.length <= 0
  ) {
    localStorage.setItem('materialClassStorageData', JSON.stringify(materialClassDataSample));
  }
  return JSON.parse(localStorage.getItem('materialClassStorageData'));
};

export const getMaterialClassNameStorage = () => {
  let count = 0;

  const materialClassNameDataSample = [
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'SPCC',
      materialClassName: 'SPCC',
      gravity: 7.85,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: '冷延鋼材',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'SPHC（黒皮）',
      materialClassName: 'SPHC',
      gravity: 7.85,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: '熱延鋼材：黒皮',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'SPHC（酸洗）',
      materialClassName: 'SPHC',
      gravity: 7.85,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: '熱延鋼材：酸洗',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'SECC（ボンデ）',
      materialClassName: 'SECC',
      gravity: 7.9,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: 'ボンデ鋼材',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'SS400',
      materialClassName: 'SPHC',
      gravity: 7.85,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: '一般構造用圧延鋼材',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'ZAM',
      materialClassName: 'SECC',
      gravity: 7.9,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: '',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'SUS304/2B',
      materialClassName: 'SUS',
      gravity: 7.9,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: '',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'SUS304/NO1',
      materialClassName: 'SUS',
      gravity: 7.9,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: 'ステンレス鋼板',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'SUS304/#400',
      materialClassName: 'SUS',
      gravity: 7.9,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: 'ステンレス鋼板',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'SUS304/両面#400',
      materialClassName: 'SUS',
      gravity: 7.9,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: 'ステンレス鋼板',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'SUS304/HL',
      materialClassName: 'SUS',
      gravity: 7.9,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: 'ステンレス鋼板',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'SUS304/両面HL',
      materialClassName: 'SUS',
      gravity: 7.9,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: 'ステンレス鋼板',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'SUS304/BA',
      materialClassName: 'SUS',
      gravity: 7.9,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: 'ステンレス鋼板',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'SUS316',
      materialClassName: 'SUS',
      gravity: 7.9,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: 'ステンレス鋼板',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'SUS316L',
      materialClassName: 'SUS',
      gravity: 7.9,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: '',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'SUS430/2B',
      materialClassName: 'SUS',
      gravity: 7.9,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: 'ステンレス鋼板',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'SUS430/BA',
      materialClassName: 'SUS',
      gravity: 7.9,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: 'ステンレス鋼板',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'SUS430/#400',
      materialClassName: 'SUS',
      gravity: 7.9,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: '',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'SUS430/HL',
      materialClassName: 'SUS',
      gravity: 7.9,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: 'ステンレス鋼板',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'A1050',
      materialClassName: 'AL',
      gravity: 2.7,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: 'ステンレス鋼板',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'A1100',
      materialClassName: 'AL',
      gravity: 2.7,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: '',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'A5052',
      materialClassName: 'AL',
      gravity: 2.7,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: 'ステンレス鋼板',
    },
    {
      key: count,
      no: count + 1,
      displayFlag: true,
      classnameCode: (++count).toString().padStart(3, '0'),
      materialName: 'C1100',
      materialClassName: 'CU',
      gravity: 8.0,
      lossFactor: '',
      created: releaseDate,
      modified: releaseDate,
      remark: '',
    },
  ];
  if (
    localStorage.getItem('materialClassNameStorage') === null ||
    localStorage.getItem('materialClassNameStorage')?.length <= 0
  ) {
    localStorage.setItem('materialClassNameStorage', JSON.stringify(materialClassNameDataSample));
  }

  return JSON.parse(localStorage.getItem('materialClassNameStorage'));
};

export const getMaterialNameStorage = () => {
  let count = 0;

  const materialNameDataSample = [
    {
      key: count++,
      no: count,
      displayFlag: true,
      detailMaterialName: 'SPCC - 0.4',
      materialName: 'SPCC',
      thickness: 0.4,

      pricePerWeight: 120,
      created: releaseDate,
      modified: releaseDate,
      remark: '',
      updHistory: [{ updId: 0, updateDate: releaseDate, updPrice: 120 }],
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      detailMaterialName: 'SPCC-  0.51',
      materialName: 'SPCC',
      thickness: 0.5,

      pricePerWeight: 120,
      created: releaseDate,
      modified: releaseDate,
      remark: '',
      updHistory: [
        {
          updId: 0,
          updateDate: releaseDate,
          updPrice: 120,
        },
      ],
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      detailMaterialName: 'SPCC - 0.6',
      materialName: 'SPCC',
      thickness: 0.6,

      pricePerWeight: 120,
      created: releaseDate,
      modified: releaseDate,
      remark: '',
      updHistory: [
        {
          updId: 0,
          updateDate: releaseDate,
          updPrice: 120,
        },
      ],
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      detailMaterialName: 'SPCC - 0.7',
      materialName: 'SPCC',
      thickness: 0.7,

      pricePerWeight: 120,
      created: releaseDate,
      modified: releaseDate,
      remark: '',
      updHistory: [
        {
          updId: 0,
          updateDate: releaseDate,
          updPrice: 120,
        },
      ],
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      detailMaterialName: 'SPCC - 0.8',
      materialName: 'SPCC',
      thickness: 0.8,

      pricePerWeight: 120,
      created: releaseDate,
      modified: releaseDate,
      remark: '',
      updHistory: [
        {
          updId: 0,
          updateDate: releaseDate,
          updPrice: 120,
        },
      ],
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      detailMaterialName: 'SPCC - 0.9',
      materialName: 'SPCC',
      thickness: 0.9,

      pricePerWeight: 120,
      created: releaseDate,
      modified: releaseDate,
      remark: '',
      updHistory: [
        {
          updId: 0,
          updateDate: releaseDate,
          updPrice: 120,
        },
      ],
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      detailMaterialName: 'SPCC - 1.0',
      materialName: 'SPCC',
      thickness: 1,

      pricePerWeight: 120,
      created: releaseDate,
      modified: releaseDate,
      remark: '',
      updHistory: [
        {
          updId: 0,
          updateDate: releaseDate,
          updPrice: 120,
        },
      ],
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      detailMaterialName: 'SPCC - 1.2',
      materialName: 'SPCC',
      thickness: 1.2,

      pricePerWeight: 120,
      created: releaseDate,
      modified: releaseDate,
      remark: '',
      updHistory: [
        {
          updId: 0,
          updateDate: releaseDate,
          updPrice: 120,
        },
      ],
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      detailMaterialName: 'SPCC - 1.4',
      materialName: 'SPCC',
      thickness: 1.4,

      pricePerWeight: 120,
      created: releaseDate,
      modified: releaseDate,
      remark: '',
      updHistory: [
        {
          updId: 0,
          updateDate: releaseDate,
          updPrice: 120,
        },
      ],
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      detailMaterialName: 'SPCC - 1.6',
      materialName: 'SPCC',
      thickness: 1.6,

      pricePerWeight: 120,
      created: releaseDate,
      modified: releaseDate,
      remark: '',
      updHistory: [
        {
          updId: 0,
          updateDate: releaseDate,
          updPrice: 120,
        },
      ],
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      detailMaterialName: 'SPCC - 1.8',
      materialName: 'SPCC',
      thickness: 1.8,

      pricePerWeight: 120,
      created: releaseDate,
      modified: releaseDate,
      remark: '',
      updHistory: [
        {
          updId: 0,
          updateDate: releaseDate,
          updPrice: 120,
        },
      ],
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      detailMaterialName: 'SPCC - 2.0',
      materialName: 'SPCC',
      thickness: 2,

      pricePerWeight: 120,
      created: releaseDate,
      modified: releaseDate,
      remark: '',
      updHistory: [
        {
          updId: 0,
          updateDate: releaseDate,
          updPrice: 120,
        },
      ],
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      detailMaterialName: 'SPCC - 2.3',
      materialName: 'SPCC',
      thickness: 2.3,

      pricePerWeight: 120,
      created: releaseDate,
      modified: releaseDate,
      remark: '',
      updHistory: [
        {
          updId: 0,
          updateDate: releaseDate,
          updPrice: 120,
        },
      ],
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      detailMaterialName: 'SPCC - 2.5',
      materialName: 'SPCC',
      thickness: 2.5,

      pricePerWeight: 120,
      created: releaseDate,
      modified: releaseDate,
      remark: '',
      updHistory: [
        {
          updId: 0,
          updateDate: releaseDate,
          updPrice: 120,
        },
      ],
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      detailMaterialName: 'SPCC - 2.6',
      materialName: 'SPCC',
      thickness: 2.6,

      pricePerWeight: 120,
      created: releaseDate,
      modified: releaseDate,
      remark: '',
      updHistory: [
        {
          updId: 0,
          updateDate: releaseDate,
          updPrice: 120,
        },
      ],
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      detailMaterialName: 'SPCC - 2.8',
      materialName: 'SPCC',
      thickness: 2.8,

      pricePerWeight: 120,
      created: releaseDate,
      modified: releaseDate,
      remark: '',
      updHistory: [
        {
          updId: 0,
          updateDate: releaseDate,
          updPrice: 120,
        },
      ],
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      detailMaterialName: 'SPCC - 2.9',
      materialName: 'SPCC',
      thickness: 2.9,

      pricePerWeight: 120,
      created: releaseDate,
      modified: releaseDate,
      remark: '',
      updHistory: [
        {
          updId: 0,
          updateDate: releaseDate,
          updPrice: 120,
        },
      ],
    },
  ];
  if (
    localStorage.getItem('materialNameStorage') === null ||
    localStorage.getItem('materialNameStorage')?.length <= 0
  ) {
    localStorage.setItem('materialNameStorage', JSON.stringify(materialNameDataSample));
  }

  return JSON.parse(localStorage.getItem('materialNameStorage'));
};

export const getMaterialSizeStorage = () => {
  let count = 0;

  const materialSizeDataSample = [
    {
      key: count++,
      no: count,
      displayFlag: true,
      materialSizeName: 'Other',
      sizeX: 0,
      sizeY: 0,
      remark: '',
      created: releaseDate,
      modified: releaseDate,
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      materialSizeName: "3'x6'",
      sizeX: 1829,
      sizeY: 914,
      remark: '',
      created: releaseDate,
      modified: releaseDate,
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      materialSizeName: "4'x8'",
      sizeX: 2438,
      sizeY: 1219,
      remark: '',
      created: releaseDate,
      modified: releaseDate,
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      materialSizeName: "5'x10'",
      sizeX: 3048,
      sizeY: 1524,
      remark: '',
      created: releaseDate,
      modified: releaseDate,
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      materialSizeName: '1x2m',
      sizeX: 2000,
      sizeY: 1000,
      remark: '',
      created: releaseDate,
      modified: releaseDate,
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      materialSizeName: '2x4m',
      sizeX: 4000,
      sizeY: 2000,
      remark: '',
      created: releaseDate,
      modified: releaseDate,
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      materialSizeName: '1.25x2.5m',
      sizeX: 2500,
      sizeY: 1250,
      remark: '',
      created: releaseDate,
      modified: releaseDate,
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      materialSizeName: '小板',
      sizeX: 1000,
      sizeY: 365,
      remark: '',
      created: releaseDate,
      modified: releaseDate,
    },
    {
      key: count++,
      no: count,
      displayFlag: true,
      materialSizeName: 'free',
      sizeX: 0,
      sizeY: 0,
      remark: '',
      created: releaseDate,
      modified: releaseDate,
    },
  ];
  if (
    localStorage.getItem('materialSizeStorage') === null ||
    localStorage.getItem('materialSizeStorage')?.length <= 0
  ) {
    localStorage.setItem('materialSizeStorage', JSON.stringify(materialSizeDataSample));
  }

  return JSON.parse(localStorage.getItem('materialSizeStorage'));
};

export const getMaterialSurfaceStorage = () => {
  let count = 0;

  const materialSurfaceDataSample = [
    {
      key: 3,
      no: 4,
      displayFlag: true,
      materialName: '',
      surfaceName: 'なし',
      attributeType: '',
      additionalMethod: '',
      doubleLaser: true,
      processSpeed: '',
      additionalAmount: '',
      remark: '',
      created: '',
      modified: '',
    },
    {
      key: 0,
      no: 1,
      displayFlag: true,
      materialName: 'SPCC',
      surfaceName: '片面ビニール',
      attributeType: 'ビニール保護',
      additionalMethod: '重量',
      doubleLaser: true,
      processSpeed: 4000,
      additionalAmount: '',
      created: '2023-10-01',
      modified: '2023-07-10',
    },
    {
      key: 1,
      no: 2,
      displayFlag: true,
      materialName: 'SUS304/2B',
      surfaceName: '片面ビニール',
      attributeType: 'ビニール保護',
      additionalMethod: '重量',
      doubleLaser: true,
      processSpeed: 4000,
      additionalAmount: '',
      remark: '',
      created: '2023-07-10',
      modified: '2023-07-10',
    },
    {
      key: 2,
      no: 3,
      displayFlag: true,
      materialName: 'SUS304/2B',
      surfaceName: '両面ビニール',
      attributeType: 'ビニール保護',
      additionalMethod: '重量',
      doubleLaser: true,
      processSpeed: '2000',
      additionalAmount: '',
      remark: '',
      created: '2023-07-10',
      modified: '2023-07-10',
    },
  ];
  if (
    localStorage.getItem('materialSurfaceStorage') === null ||
    localStorage.getItem('materialSurfaceStorage')?.length <= 0
  ) {
    localStorage.setItem('materialSurfaceStorage', JSON.stringify(materialSurfaceDataSample));
  }

  return JSON.parse(localStorage.getItem('materialSurfaceStorage'));
};

// 小数点１へ変換する
export const toDecimal = (value, places = 1) => {
  if (!isDecimal(value)) return value;
  if (value?.length <= 0) value = 0;
  return parseFloat(value).toFixed(places);
};

// テーブル列の幅Resizable
export const ResizableTitle = (props) => {
  const { onResize, width, ...restProps } = props;

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

// 破壊ポップアップを表示させる処理タイプ
export const discardChange = [
  { no: 1, state: 'rowSelect' },
  { no: 2, state: 'search' },
  { no: 3, state: 'bulkEdit' },
  { no: 4, state: 'createNew' },
];

//　係数値下げ、上げ計算
export const increaseDecreaseKeisu = (value, increaseFlag) => {
  let targetValue = parseFloat(value);
  if (increaseFlag) {
    targetValue = targetValue + 0.01;
  } else {
    targetValue = targetValue - 0.01;
  }
  return targetValue.toFixed(2);
};

export const isCustomer = (value) => {
  if ((value & ClientType.Customer) === ClientType.Customer) return true;
  return false;
};

export const isSupplier = (value) => {
  if ((value & ClientType.Supplier) === ClientType.Supplier) return true;
  return false;
};

export const isContractor = (value) => {
  if ((value & ClientType.Contractor) === ClientType.Contractor) return true;
  return false;
};
// 装置設定
export const deleteListColData = (list, deletedId, isObjType = false, columnName = '') => {
  let deleteData;
  if (isObjType) {
    deleteData = Object.entries(list)?.map(([key, value]) => value?.filter((item) => !deletedId?.includes(item.no)));
  } else if (columnName !== '') {
    deleteData = list?.filter((item) => !deletedId?.includes(item[columnName]));
  } else {
    deleteData = list?.filter((item) => !deletedId?.includes(item.no));
  }
  return deleteData;
};

export const updateListColData = (list, newId, isObjType = false, customItem = {}, columnName = '') => {
  // const updatedData = list?.map((item) => {
  //   newId?.forEach((key) => {
  //     // Add new objects to lstData
  //     item.lstData.push({
  //       key: key,
  //       value: '',
  //     });
  //   });
  //   return item;
  // });
  // return updatedData;

  newId?.forEach((no) => {
    // Add new objects to lstData
    if (isObjType) {
      Object.entries(list)?.map(([key, value]) =>
        value.push({
          no: no,
          value: 0,
          isOutOfRange: false,
        })
      );
    } else if (Object.keys(customItem)?.length > 0) {
      if (columnName !== '') {
        customItem[columnName] = no;
      }
      list.push(customItem);
    } else {
      list.push({
        no: no,
        value: 0,
        isOutOfRange: false,
      });
    }
  });
  return list;
};

export const updateJikanListColData = (list, newId) => {
  const updatedData = list?.map((item) => {
    newId?.forEach((key) => {
      // Add new objects to lstData
      item.lstData.push({
        key: key,
        value: '00:00:00',
      });
    });

    return item;
  });
  return updatedData;
};

export const sortListData = (list, masterData, isObjType = false, columnName = '') => {
  // const sortedData = list?.map((item) => {
  //   const sortedLstData = [...item.lstData];
  //   sortedLstData.sort((a, b) => {
  //     const aIndex = masterData?.findIndex((obj) => obj.id === a.key);
  //     const bIndex = masterData?.findIndex((obj) => obj.id === b.key);
  //     return aIndex - bIndex;
  //   });

  //   return {
  //     key: item.key,
  //     materialType: item.materialType,
  //     lstData: sortedLstData,
  //   };
  // });

  // return sortedData;
  if (isObjType) {
    Object.entries(list)?.map(([key, value]) =>
      value.sort((a, b) => {
        const aIndex = masterData?.findIndex((obj) => obj.no === a.no);
        const bIndex = masterData?.findIndex((obj) => obj.no === b.no);
        return aIndex - bIndex;
      })
    );
  } else if (columnName !== '') {
    list.sort((a, b) => {
      const aIndex = masterData?.findIndex((obj) => obj.no === a[columnName]);
      const bIndex = masterData?.findIndex((obj) => obj.no === b[columnName]);
      return aIndex - bIndex;
    });
  } else {
    list.sort((a, b) => {
      const aIndex = masterData?.findIndex((obj) => obj.no === a.no);
      const bIndex = masterData?.findIndex((obj) => obj.no === b.no);
      return aIndex - bIndex;
    });
  }
  return list;
};

export const sortSizeListData = (list, masterData) => {
  const sortedList = list?.map((item) => {
    const sortedLstData = [...item.lstData];
    sortedLstData.sort((a, b) => {
      const aIndex = masterData?.findIndex((obj) => obj.id === a.key);
      const bIndex = masterData?.findIndex((obj) => obj.id === b.key);
      return aIndex - bIndex;
    });

    return {
      key: item.key,
      max: item.max,
      displayFlag: item.displayFlag,
      lstData: sortedLstData,
    };
  });
  return sortedList;
};

export const updateWeightListData = (listData, masterData) => {
  const result = listData?.map((item) => {
    const list = masterData?.find((listItem) => listItem.id === item.key);

    return {
      ...item,
      max: list ? list.max : item.max,
      lstData: item.lstData?.map((lstItem) => {
        const newListData = {
          key: lstItem.key,
          value: lstItem.value,
        };

        return newListData;
      }),
    };
  });
  return result;
};
export const sortWeightListData = (list, masterData) => {
  const result = list.sort((a, b) => {
    const aIndex = masterData?.findIndex((obj) => obj.id === a.key);
    const bIndex = masterData?.findIndex((obj) => obj.id === b.key);
    return aIndex - bIndex;
  });
  return result;
};
// 装置設定
export const createSizeListData = (weight, area) => {
  const result = weight?.map((weightItem) => {
    const lstData = area
      ?.filter((subitem) => subitem.displayFlag === true)
      ?.map((item) => {
        return {
          key: item.id,
          value: 0,
        };
      });

    return {
      key: weightItem.id,
      max: weightItem.max,
      displayFlag: weightItem.displayFlag,
      lstData: lstData,
    };
  });
  return result;
};
export const createListData = (type, masterData) => {
  const result = type?.map((materialType, key) => {
    const filteredData2 = masterData
      ?.filter((subitem) => subitem.displayFlag === true)
      ?.map((item) => {
        return {
          key: item.id,
          value: '0',
        };
      });

    return {
      key: key + 1,
      materialType,
      lstData: filteredData2,
      isUsed: false,
    };
  });
  return result;
};
export const createGasListData = (type, masterData) => {
  const result = type?.map((materialType, key) => {
    const filteredData2 = masterData
      ?.filter((subitem) => subitem.displayFlag === true)
      ?.map((item) => {
        return {
          key: item.id,
          value: '',
        };
      });

    return {
      key: key + 1,
      materialType,
      lstData: filteredData2,
      isUsed: false,
    };
  });
  return result;
};
export const createKeisuuListData = (type, masterData) => {
  const result = type?.map((materialType, key) => {
    const filteredData2 = masterData
      ?.filter((subitem) => subitem.displayFlag === true)
      ?.map((item) => {
        return {
          key: item.id,
          value: '1.00',
        };
      });

    return {
      key: key + 1,
      materialType,
      lstData: filteredData2,
      isUsed: false,
    };
  });
  return result;
};
export const createJikanListData = (type, masterData) => {
  const result = type?.map((materialType, key) => {
    const filteredData2 = masterData
      ?.filter((subitem) => subitem.displayFlag === true)
      ?.map((item) => {
        return {
          key: item.id,
          value: '00:00:00',
        };
      });

    return {
      key: key + 1,
      materialType,
      lstData: filteredData2,
      isUsed: false,
    };
  });
  return result;
};
export const updateJikanList = (masterData, updatedMasterData, listData) => {
  // 削除したid取得
  const deletedId = [];
  masterData?.forEach((item1, index) => {
    const found = updatedMasterData?.find((item2) => item2.id === item1.id && item2.displayFlag === false);
    if (found) {
      deletedId.push(item1.id);
    }
  });
  // 新規追加したid取得
  const newId = updatedMasterData
    ?.filter((item) => item.displayFlag && !masterData.some((data2Item) => data2Item.id === item.id))
    ?.map((item) => item.id);
  // リスト更新
  const deleteLstData = listData?.map((item) => {
    return {
      ...item,
      lstData: item.lstData?.filter((it) => !deletedId?.includes(it.key)),
    };
  });
  const updatedData = deleteLstData?.map((item) => {
    newId?.forEach((key) => {
      // Add new objects to lstData
      item.lstData.push({
        key: key,
        value: '00:00:00',
      });
    });

    return item;
  });
  const sortedList = updatedData?.map((item) => {
    const sortedLstData = [...item.lstData];
    sortedLstData.sort((a, b) => {
      const aIndex = updatedMasterData?.findIndex((obj) => obj.id === a.key);
      const bIndex = updatedMasterData?.findIndex((obj) => obj.id === b.key);
      return aIndex - bIndex;
    });

    return {
      key: item.key,
      materialType: item.materialType,
      lstData: sortedLstData,
      isUsed: item.isUsed,
    };
  });
  return sortedList;
};
export const createJikanSizeListData = (weight, area) => {
  const result = weight?.map((weightItem) => {
    const lstData = area
      ?.filter((subitem) => subitem.displayFlag === true)
      ?.map((item) => {
        return {
          key: item.id,
          value: '00:00:00',
        };
      });

    return {
      key: weightItem.id,
      max: weightItem.max,
      displayFlag: weightItem.displayFlag,
      lstData: lstData,
    };
  });
  return result;
};
export const updateJikanSizeListData = (masterData, updatedMasterData, listData) => {
  // 削除したid取得
  const deletedId = [];
  masterData?.forEach((item1, index) => {
    const found = updatedMasterData?.find((item2) => item2.id === item1.id && item2.displayFlag === false);
    if (found) {
      deletedId.push(item1.id);
    }
  });
  // 新規追加したid取得
  const newId = updatedMasterData
    ?.filter((item) => item.displayFlag && !masterData.some((data2Item) => data2Item.id === item.id))
    ?.map((item) => item.id);
  // リスト更新
  const deleteLstData = listData?.map((item) => {
    return {
      ...item,
      lstData: item.lstData?.filter((it) => !deletedId?.includes(it.key)),
    };
  });
  const updatedData = deleteLstData?.map((item) => {
    newId?.forEach((key) => {
      // Add new objects to lstData
      item.lstData.push({
        key: key,
        value: '00:00:00',
      });
    });

    return item;
  });
  const sortedList = updatedData?.map((item) => {
    const sortedLstData = [...item.lstData];
    sortedLstData.sort((a, b) => {
      const aIndex = updatedMasterData?.findIndex((obj) => obj.id === a.key);
      const bIndex = updatedMasterData?.findIndex((obj) => obj.id === b.key);
      return aIndex - bIndex;
    });

    return {
      key: item.key,
      materialType: item.materialType,
      lstData: sortedLstData,
      isUsed: item.isUsed,
    };
  });
  return sortedList;
};
export const updateProductSizeJikanList = (masterData, updatedMasterData, listData) => {
  const deletedId = [];

  masterData?.forEach((item1, index) => {
    const found = updatedMasterData?.find((item2) => item2.id === item1.id && item2.displayFlag === false);
    if (found) {
      deletedId.push(item1.id);
    }
  });

  const newId = updatedMasterData
    ?.filter((item) => item.displayFlag && !masterData?.some((data2Item) => data2Item.id === item.id))
    ?.map((item) => item.id);

  const deleteData = listData?.filter((it) => !deletedId?.includes(it.key));

  const result = deleteData?.map((item) => {
    const list = updatedMasterData?.find((listItem) => listItem.id === item.key);

    return {
      ...item,
      max: list ? list.max : item.max,
      lstData: item.lstData?.map((lstItem) => {
        const newListData = {
          key: lstItem.key,
          value: lstItem.value,
        };

        return newListData;
      }),
    };
  });

  newId?.forEach((id) => {
    const list = updatedMasterData?.find((item) => item.id === id);

    if (list) {
      const newObj = {
        key: id,
        max: list.max,
        displayFlag: true,
        lstData: result[0]?.lstData?.map((lstItem) => {
          return {
            key: lstItem.key,
            value: '00:00:00',
          };
        }),
      };

      result?.push(newObj);
    }
  });
  result?.sort((a, b) => {
    const aIndex = updatedMasterData?.findIndex((obj) => obj.id === a.key);
    const bIndex = updatedMasterData?.findIndex((obj) => obj.id === b.key);
    return aIndex - bIndex;
  });
  return result;
};
export const createShiageFlagData = (type, masterData) => {
  const result = type?.map((materialType, key) => {
    const filteredData2 = masterData?.map((item) => {
      return {
        name: item,
        flag: false,
      };
    });

    return {
      key: key + 1,
      materialType,
      lstData: filteredData2,
    };
  });
  return result;
};
export const createKakouSokudoListData = (type, masterData) => {
  const list = [
    { key: 1, name: '丸穴径', svalue: '', mvalue: '', lvalue: '' },
    { key: 2, name: '加工周長', svalue: '', mvalue: '', lvalue: '' },
    { key: 3, name: '速度', svalue: '', mvalue: '', lvalue: '' },
  ];
  const result = type?.map((materialType, key) => {
    const filteredData2 = masterData
      ?.filter((subitem) => subitem.displayFlag === true)
      ?.map((item) => {
        return {
          key: item.id,
          value: 0,
          list: list,
        };
      });

    return {
      key: key + 1,
      materialType,
      lstData: filteredData2,
    };
  });
  return result;
};

// リスト更新
export const updateList = (originalList, deleteNos, addNos, inputVal = {}) => {
  const updatedList = {};

  // Loop through the keys (ids) in the original list
  Object.keys(originalList)?.forEach((id) => {
    // 削除
    const updatedItems = originalList[id]?.filter((item) => !deleteNos?.includes(item.no));

    // 追加
    if (Object.keys(inputVal)?.length > 0) {
      addNos?.forEach((no) => {
        updatedItems.push({
          no,
          value: 0,
          isOutOfRange: false,
          [Object.keys(inputVal)[0]]: inputVal[Object.keys(inputVal)[0]],
        });
      });
    } else {
      addNos?.forEach((no) => {
        updatedItems.push({
          no,
          value: 0,
          isOutOfRange: false,
        });
      });
    }

    // Assign the updated items to the corresponding id
    updatedList[id] = updatedItems;
  });

  return updatedList;
};
export const sortListByMasterData = (originalList, masterData) => {
  const noToIndexMap = {};
  masterData?.forEach((item, index) => {
    noToIndexMap[item.no] = index;
  });

  const updatedList = {};

  Object.keys(originalList)?.forEach((id) => {
    // noのindexによって項目をソートする
    const sortedItems = originalList[id]?.sort((a, b) => {
      const indexA = noToIndexMap[a.no];
      const indexB = noToIndexMap[b.no];
      return indexA - indexB;
    });

    // ソートされたアイテムを対応する ID に割り当てる
    updatedList[id] = sortedItems;
  });

  return updatedList;
};
export const updateSizeList = (list, deletedNo, addedNo) => {
  const newList = { ...list };
  delete newList[deletedNo];
  addedNo?.forEach((key, index) => {
    newList[key] = list['1']?.map((item) => ({
      no: item.no,
      value: 0,
      isOutOfRange: false,
    }));
  });
  return newList;
};

export const getInputBorderStyle = (index, masterData) => {
  const item = masterData?.filter((item) => !item.isOutOfRange)[index];

  if (item.value === 0 && item.isOutOfRange === false) {
    return { border: '1px solid red' };
  } else {
    return { border: '' };
  }
};
// 装置設定_end
export const getProcessDetailNameList = (type) => {
  let kouteiMaster =
    window.localStorage.getItem('kouteiMasterData') != undefined
      ? JSON.parse(window.localStorage.getItem('kouteiMasterData'))
      : [];
  let processDetail = [];
  kouteiMaster?.forEach((element) => {
    if (element.type === type) {
      processDetail = element.processDetail;
    }
  });
  let detailTypes = [];
  processDetail?.forEach((element) => {
    let processDetailValue = element.processDetailValue;
    processDetailValue?.forEach((e) => {
      detailTypes.push(e.detailType);
    });
  });
  return detailTypes;
};

// 最新のセクションにトークン情報を取得する
export const getAccessToken = () => {
  return localStorage?.getItem('iQxWeb_AccessToken');
};

export const defaultValue = {
  materialType: '材質区分名を選択してください。',
  materialTypeIq3: '材質名称を選択してください。',
  unitType: '加算方法を選択してください。',
  service: '種別を選択してください。',
  process: '工程を選択してください。',
  processType: '加工を選択してください。',
};

export const materialTypeDefaultValue = '材質区分名を選択でください。';

// IDで材料名称を取得
export function getMaterialNameById(id, Lists) {
  let retValue = '';
  if (id && Lists) {
    let info = Lists?.filter((info) => info.id === id);
    if (info?.length > 0) {
      retValue = info[0]?.name;
    }
  }
  return retValue;
}

// IDで板厚を取得
export function getMaterialThickById(id, Lists) {
  let retValue = '';
  if (id && Lists) {
    let info = Lists?.filter((info) => info.id === id);
    if (info?.length > 0) {
      retValue = info[0]?.info.thick;
    }
  }
  return retValue;
}

// IDで材質名称を取得
export function getMaterialTypeById(id, Lists) {
  let retValue = '';
  if (id && Lists) {
    let info = Lists?.filter((info) => info.id === id && info.info.isUsed);
    if (info?.length > 0) {
      retValue = info[0]?.name;
    }
  }
  return retValue;
}

// IDで表面保護を取得
export function getMaterialSurfaceById(id, Lists) {
  let retValue = '';
  if (id && Lists) {
    let info = Lists?.filter((info) => info.id === id);
    if (info?.length > 0) {
      retValue = info[0]?.name;
    }
  }
  return retValue;
}

export const processTypes = {
  standard: '標準',
  additional: '追加',
};

// 工程種別の詳細情報を得る
export const getProcessDetailTypesInfo = (detailsData, processMstRecord) => {
  // 装置の詳細情報で、工程種別の詳細情報を得る
  let displayList = Object.keys(detailsData)?.map(
    (key) =>
      processMstRecord?.details?.processDetailTypes?.filter(
        (subitem) => subitem.id === parseInt(key) && subitem.isUsed === true
      )?.[0]
  );
  // 工程グループより、行分けて表示するようにする
  const groupDisplayList = displayList?.reduce((list, item) => {
    let groupId = item?.group;
    if (!list[groupId]) list[groupId] = [];
    list[groupId]?.push(item);
    return list;
  }, {});
  return groupDisplayList;
};

// 工程種別の詳細情報名を得る
export const getProcessDetailTypeName = (detailTypeId, processMstRecord) => {
  // 装置の詳細情報で、工程種別Idより名称を得る
  let name = processMstRecord?.details?.processDetailTypes?.filter(
    (subitem) => subitem.id === detailTypeId && subitem.isUsed
  )?.[0]?.name;
  return name;
};

/* export const handleKeyPress = (e, isNumber) => {
  const keyCode = e.which || e.keyCode;
  let isValidKey;
  if (isNumber) { */

export const formatCurrencyInput = (value) => {
  let formattedValue = String(value);
  if (value === '' || value === undefined) {
    formattedValue = 0;
  }
  if (value.startsWith('¥', 0)) {
    formattedValue = formattedValue.replace('¥', '');
  }
  if (/,/.test(formattedValue)) {
    formattedValue = formattedValue?.replace(/,/g, '');
  }
  return formattedValue;
};

export const handleKeyPress = (e, isNumeric) => {
  // const targetName = e.target.name;
  const keyCode = e.which || e.keyCode;
  let isValidKey;
  if (isNumeric) {
    isValidKey = keyCode >= 48 && keyCode <= 57;
  } else {
    //decimal入力チャック
    isValidKey = (keyCode >= 48 && keyCode <= 57) || keyCode === 46;
  }

  if (!isValidKey) {
    e.preventDefault();
  }
};

export const handleCollpse = (id) => {
  if (document.getElementById('div' + id) !== null) {
    let showHide = document.getElementById('div' + id)?.style.display;
    if (showHide == 'block') {
      document.getElementById('div' + id).style.display = 'none';
      document.getElementById('arrowUp' + id).style.display = 'none';
      document.getElementById('arrowDown' + id).style.display = 'block';
    } else {
      document.getElementById('div' + id).style.display = 'block';
      document.getElementById('arrowUp' + id).style.display = 'block';
      document.getElementById('arrowDown' + id).style.display = 'none';
    }
  }
};

// 溶接->段取設定の段取係数1の種類名
export const getPrepCoeffTypeName = (typeNo) => {
  let coeffTypeName = '';
  switch (typeNo) {
    case PrepCoeffType.ChildQty:
      coeffTypeName = '子部品点数';
      break;
    case PrepCoeffType.ChildTypeQty:
      coeffTypeName = '子部品種類数';
      break;
    default:
      break;
  }
  return coeffTypeName;
};

// 個数マスタのデフォルト値
export const defaultQuantiyData = [
  {
    no: 1,
    value: 0,
    isOutOfRange: false,
  },
  {
    no: 2,
    value: 0,
    isOutOfRange: false,
  },
];

// 重量マスタのデフォルト値
export const defaultWeightData = [
  {
    no: 1,
    value: 0,
    isOutOfRange: false,
  },
  {
    no: 2,
    value: 999,
    isOutOfRange: false,
  },
];

// 工程種別グループ名
export const getProcessDetailGroupName = (typeNo) => {
  let groupName = '';
  switch (typeNo) {
    case ProcessDetailGroup.General:
      groupName = '処理全般';
      break;
    case ProcessDetailGroup.Plating:
      groupName = 'メッキ';
      break;
    case ProcessDetailGroup.OneSide:
      groupName = '片面塗装';
      break;
    case ProcessDetailGroup.DoubleSide:
      groupName = '両面塗装';
      break;
    default:
      groupName = '無し';
      break;
  }
  return groupName;
};

// 工程種別グループ名
export const ProcessDetailGroups = [
  { value: ProcessDetailGroup.None, label: '無し' },
  { value: ProcessDetailGroup.General, label: '処理全般' },
  { value: ProcessDetailGroup.Plating, label: 'メッキ' },
  { value: ProcessDetailGroup.OneSide, label: '片面塗装' },
  { value: ProcessDetailGroup.DoubleSide, label: '両面塗装' },
];

// コードで名称を取得
export function getNameById(id, Lists) {
  let retValue = '';
  if (id) {
    let info = Lists?.filter((info) => info.id === id);
    if (info?.length > 0) {
      retValue = info[0]?.label;
    }
  }
  return retValue;
}

export function getNameByValue(value, Lists) {
  let retValue = '';
  if (value) {
    let info = Lists?.filter((info) => info.value === value);
    if (info?.length > 0) {
      retValue = info[0]?.label;
    }
  }
  return retValue;
}

// コードで名称を取得
export function getNameByRecordId(id, Lists) {
  let retValue = '';
  if (id) {
    let info = Lists?.filter((info) => info.id === id);
    if (info?.length > 0) {
      retValue = info[0]?.name;
    }
  }
  return retValue;
}

// 工程種別worktypeと名称リスト
export const kouteiMasterTypes = [
  {
    id: 0,
    workType: 100,
    typeName: '溶接',
  },
  {
    id: 1,
    workType: 200,
    typeName: '溶接仕上',
  },
  {
    id: 2,
    workType: 300,
    typeName: '表面処理',
  },
  {
    id: 3,
    workType: 400,
    typeName: '組立',
  },
  {
    id: 4,
    workType: 500,
    typeName: '検査',
  },
  {
    id: 5,
    workType: 600,
    typeName: '梱包',
  },
  {
    id: 6,
    workType: 700,
    typeName: 'カスタム',
  },
  {
    id: 7,
    workType: 10100,
    typeName: 'プログラム',
  },
  {
    id: 8,
    workType: 10210,
    typeName: 'ブランク',
  },
  {
    id: 9,
    workType: 10220,
    typeName: 'ブランク',
  },
  {
    id: 10,
    workType: 10230,
    typeName: 'ブランク',
  },
  {
    id: 11,
    workType: 10300,
    typeName: '2次加工',
  },
  {
    id: 12,
    workType: 10400,
    typeName: 'ベンディング',
  },
  {
    id: 13,
    workType: 10510,
    typeName: 'バリ取',
  },
  {
    id: 14,
    workType: 10520,
    typeName: 'バリ取',
  },
  {
    id: 15,
    workType: 10600,
    typeName: '溶接',
  },
  {
    id: 16,
    workType: 10700,
    typeName: '溶接仕上',
  },
  {
    id: 17,
    workType: 10800,
    typeName: '表面処理',
  },
  {
    id: 18,
    workType: 10900,
    typeName: 'シャーリング',
  },
  {
    id: 19,
    workType: 11000,
    typeName: '検査',
  },
  {
    id: 20,
    workType: 11100,
    typeName: 'カスタム',
  },
];

// 工程種別グループ名称とdefault row取得
export function getTitleForKey(key, workTypeObj) {
  const titleMap = {
    110: { detailName: '溶接種類' },
    120: { detailName: '取付種類（板厚判断）' },
    130: { detailName: '取付種類（サイズ判断）' },
    310: { detailName: '処理全般種類', defaultRow: 1 },
    320: { detailName: 'メッキ種類', defaultRow: 8 },
    330: { detailName: '塗装種類', defaultRow: 8 },
    10510: { detailName: '処理全般種類', defaultRow: 1 },
    10520: { detailName: 'メッキ種類', defaultRow: 8 },
    10530: { detailName: '塗装種類', defaultRow: 8 },
    10710: { detailName: '溶接種類' },
    10720: { detailName: '取付種類（板厚判断）', defaultRow: 5 },
    10730: { detailName: '取付種類（サイズ判断）', defaultRow: 5 },
    10310: { detailName: '2次加工種類（サイズ判断）', defaultRow: 6 },
    10320: { detailName: '2次加工種類（ミリメートル判断）', defaultRow: 2 },
  };
  let detailType = titleMap[key];

  if (!detailType) {
    //工程種別グループ 0の場合、
    if (key === '0') {
      switch (workTypeObj.workType) {
        case 400:
        case 500:
        case 600:
        case 11000:
          detailType = { detailName: '計算方法' };
          break;
        case 700:
        case 11100:
          detailType = { detailName: '追加工程種類' };
          break;
        case 10400:
          detailType = { detailName: '曲げ種類' };
          break;
        case 10700:
          detailType = { detailName: '溶接仕上種類', defaultRow: 3 };
          break;
        default:
          detailType = { detailName: workTypeObj.typeName + '種類' };
      }
    }
  }
  return detailType;
}
// 加工速度係数の加工種別
export const getProcessTypeName = (typeNo) => {
  let processTypeName = '';
  switch (typeNo) {
    case ProcessType.InternalWork:
      processTypeName = '内加工';
      break;
    case ProcessType.ExternalWork:
      processTypeName = '外加工';
      break;
    default:
      break;
  }
  return processTypeName;
};
// 指定データより、リスト情報を作成
export const getListItemByCustomData = (data) => {
  const list = data?.map((item) => ({ no: item.no, value: 0, isOutOfRange: false }));
  return list;
};

// 板金部品見積の初期値
export const iq3EstimateInitInfo = [
  {
    id: 0,
    created: dayjs().format(),
    modified: dayjs().format(),
    modifierId: 0,
    modifier: '',
    estimateProductId: 0,
    no: 0,
    creationType: EstimateCreationType.Auto,
    imageId: '',
    drawingNo: '',
    name: '',
    partCount: 1,
    surfaceProtection: 0,
    weight: 0,
    totalSurfaceArea: 0,
    partSizeX: 0,
    partSizeY: 0,
    materialIq3Id: 0,
    materialName: '',
    materialTypeIq3Id: 0,
    materialTypeName: '',
    materialSurfaceIq3Id: 0,
    materialSurfaceName: '',
    thickness: 0,
    grabbingX: 0,
    grabbingY: 0,
    pierWidthX: 0,
    pierWidthY: 0,
    worlRangeX: 0,
    worlRangeY: 0,
    yield: 0,
    materialQuantity: 0,
    weightUnitPrice: 0,
    surfaceAddPrice: 0,
    weightAddPrice: 0,
    scrapPrice: 0,
    costUnitPrice: 0,
    costTotalPrice: 0,
    materialCostUnitPrice: 0,
    materialCostTotalRate: 0,
    materialCostTotalPrice: 0,
    workCostUnitPrice: 0,
    workCostTotalPrice: 0,
    purchaseUnitPrice: 0,
    purchaseTotalPrice: 0,
    surfaceUnitPrice: 0,
    surfaceTotalPrice: 0,
    createrId: 0,
    creater: '',
    processSelect: {
      id: 0,
      name: '',
      details: [],
      modified: dayjs().format(),
    },
    processInput: [],
    additions: [],
    purchases: [],
    materialDetailPatterns: [],
    materialDetailSizes: [],
    calcParameters: {
      settings: {},
      parameters: {},
    },
    info: {
      isUsed: true,
      remarks: '',
      sortNum: 0,
    },
  },
];

// 親部品見積の初期値
export const estimateInitInfo = {
  estimateProducts: {
    id: 0,
    created: dayjs(today).format('YYYY-MM-DD'),
    modified: dayjs(today).format('YYYY-MM-DD'),
    modifierId: 0,
    modifier: '',
    drawingNo: '',
    name: '',
    clientId: 0,
    clientName: '',
    quantity: 1,
    coeffOrdersId: 0,
    coeffOrdersName: '',
    coeffDeliveryDatesId: 0,
    coeffDeliveryDatesName: '',
    coeffPurchasesId: 0,
    coeffPurchasesName: '',
    coeffManagementsId: 0,
    coeffManagementsName: '',
    estimateStatusId: 0,
    estimateStatus: '',
    productType: '',
    totalWeight: 0.0,
    totalSurfaceArea: 0.0,
    createrId: 0,
    creater: '',
    sizeX: 0,
    sizeY: 0,
    sizeZ: 0,
    estimateNo: '',
    comment: '',
    capacity: 0,
    totalWeightParts: 0,
    totalWeightSm: 0,
    totalWeightBm: 0,
    totalWeightMa: 0,
    partTypesParts: 0,
    partTypesSm: 0,
    partTypesBm: 0,
    partTypesMa: 0,
    totalQuantityParts: 0,
    totalQuantitySm: 0,
    totalQuantityBm: 0,
    totalQuantityMa: 0,
    costUnitPrice: 0,
    costTotalPrice: 0,
    estimateUnitPrice: 0,
    estimateTotalPrice: 0,
    grossUnitPrice: 0,
    grossTotalPrice: 0,
    grossRate: 0,
    materialCostUnitPrice: 0,
    materialCostTotalPrice: 0,
    workCostUnitPrice: 0,
    workCostTotalPrice: 0,
    purchaseUnitPrice: 0,
    purchaseTotalPrice: 0,
    manufacturingCostTotalPrice: 0,
    setupCostTotalPrice: 0,
    managementFeeTotalPrice: 0,
    otherProduct: {
      adjustment: 0,
      coeffClient: 0,
      coeffOrders: 0,
      coeffDeliveryDatesId: 0,
      coeffPurchases: 0,
      coeffManagements: 0,
    },
    processSelect: {
      id: 0,
      name: '',
      details: [],
      modified: dayjs().format(),
    },
    processInput: {
      // surfaceProcessInputInfo: processInputSurfaceData,
      surfaceProcessInputInfo: [],
      assenbleProcessInputInfo: processInputKumitateData,
      inspectionProcessInputInfo: processInputKensaData,
      packingProcessInputInfo: processInputKonpouData,
    },
    additions: [],
    purchases: [],
    attachments: {
      id: 0,
      name: '',
      extention: '',
      byte: 0,
      created: '2023-08-31T02:36:11.4145609Z',
      modified: '2023-08-31T02:36:11.4145609Z',
    },
    memo: '',
    byQuantities: [],
    calcParameters: {
      settings: {},
      parameters: {},
    },
    info: {
      isUsed: true,
      remarks: '',
      sortNum: 0,
    },
  },
  esIq3Info: [],
  epCustomCosts: [],
  epCustomDates: [],
  epCustomLabels: [],
  epCustomMemos: [],
};

// 担当者情報を選択ボックス情報にする
export const getStaffList = async () => {
  let staffs = await getStaffInfo();
  const staffLists = [];
  staffs?.forEach((item) => {
    if (item.info.isUsed) {
      staffLists.push({ value: item.id, label: item.name });
    }
  });
  return staffLists;
};

// 表示設定、表示件数リスト
export const pageSizeOptions = [
  { label: 100, value: 1 },
  { label: 200, value: 2 },
  { label: 300, value: 3 },
  { label: 500, value: 4 },
  { label: 1000, value: 5 },
];

// 追加項目・費用の初期値オブジェクト
export const initAdditionalItemCost = (id, code, name) => {
  return {
    additionalItemId: id,
    additionalItemCode: code,
    additionalItemName: name,
    unitPrice: 0,
    isUseUnit: false,
    totalPrice: 0,
  };
};

// 追加項目・ラベルの初期値オブジェクト
export const initAdditionalItemLabel = (id, code, name) => {
  return {
    additionalItemId: id,
    additionalItemCode: code,
    additionalItemName: name,
    label: '',
  };
};

// 追加項目・メモの初期値オブジェクト
export const initAdditionalItemMemo = (id, code, name) => {
  return {
    additionalItemId: id,
    additionalItemCode: code,
    additionalItemName: name,
    memo: '',
  };
};

// 追加項目・日付の初期値オブジェクト
export const initAdditionalItemDate = (id, code, name) => {
  return {
    additionalItemId: id,
    additionalItemCode: code,
    additionalItemName: name,
    date: dayjs(today).format('YYYY-MM-DD'),
  };
};
export const productionManageCSVFileHeaders = [
  {
    label: '見積No',
    key: 'estimateNo',
  },
  {
    label: '見積日',
    key: 'created',
  },
  {
    label: '得意先コード',
    key: 'clientId',
  },
  {
    label: '得意先枝番',
    key: 'customerBranchNo',
  },
  {
    label: '得意先',
    key: 'clientName',
  },
  {
    label: '納期コード',
    key: 'deliveryCode', // 納期係数のコード
  },
  {
    label: '納期',
    key: 'deliveryName', // 納期係数の名称
  },
  {
    label: '自社担当者ｺｰﾄﾞ',
    key: 'creater',
  },
  {
    label: '自社担当者',
    key: 'modifier',
  },
  {
    label: '見積合計金額',
    key: 'estimateTotalPrice',
  },
  {
    label: '合計原価',
    key: 'costTotalPrice',
  },
  {
    label: '合計粗利',
    key: 'grossTotalPrice',
  },
  {
    label: '合計購入品費',
    key: 'purchaseTotalPrice',
  },
  {
    label: '合計管理費',
    key: 'managementFeeTotalPrice',
  },
  {
    label: '単品フラグ',
    key: 'singleItemFlag',
  },
  {
    label: '枝番',
    key: 'branchNo',
  },
  {
    label: '品番',
    key: 'drawingNo',
  },
  {
    label: '品名',
    key: 'name',
  },
  {
    label: '形式',
    key: 'format',
  },
  {
    label: '材質コード',
    key: 'productCode',
  },
  {
    label: '材質',
    key: 'productName',
  },
  {
    label: '表面保護',
    key: 'surfaceProtectionName',
  },
  {
    label: '板厚',
    key: 'thickness',
  },
  {
    label: '材料名',
    key: 'materialName',
  },
  {
    label: '合計数量',
    key: 'quantity',
  },
  {
    label: '材料単価',
    key: 'materialCostUnitPrice',
  },
  {
    label: '工順',
    key: 'route',
  },
  {
    label: '工程ｺｰﾄﾞ',
    key: 'processCode',
  },
  {
    label: '工程名称',
    key: 'processName',
  },
  {
    label: '加工種類コード',
    key: 'workTypeGroupCode',
  },
  {
    label: '加工種類',
    key: 'workTypeGroupName',
  },
  {
    label: '単段取工数',
    key: 'singlePrepTime',
  },
  {
    label: '段取工数',
    key: 'prepTime',
  },
  {
    label: '単加工時間',
    key: 'singleWorkTime',
  },
  {
    label: '加工時間',
    key: 'workTime',
  },
  {
    label: '加工工数',
    key: 'totalWorkTime',
  },
  {
    label: '合計金額',
    key: 'totalCost',
  },
  {
    label: 'カスタム区分',
    key: 'ep_custom',
  },
  {
    label: 'カスタムコード',
    key: 'customCode',
  },
  {
    label: 'カスタム名称',
    key: 'customName',
  },
  {
    label: 'カスタムテキスト',
    key: 'customText',
  },
  {
    label: 'カスタム日付',
    key: 'customDate',
  },
  {
    label: '合計金額(費用分)',
    key: 'totalCostAmount',
  },
];

// 先頭に０を埋める ####0
export function padWithLeadingZeros(num, totalLength) {
  return String(num).padStart(totalLength, '0');
}

// 再計算
export function updateEstimateInfoByReCal(selectedDataDetail) {
  let updateKouteiCalInfo = [];
  let estimateProducts = selectedDataDetail.estimateProducts;

  if (
    estimateProducts == undefined ||
    estimateProducts?.additions?.length <= 0 ||
    estimateProducts?.calcParameters == undefined
  ) {
    return selectedDataDetail;
  }

  //パラメータ情報再設定
  let orderCoeffs = [];
  let deliDateCoeffs = [];
  let purCoeffs = [];
  let mngCoeffs = [];
  let costLst = [];
  let labelLst = [];
  let memoLst = [];
  let dateLst = [];
  let parameters = estimateProducts?.calcParameters?.parameters;
  let settings = estimateProducts?.calcParameters?.settings;
  if (parameters) {
    let client =
      parameters.client?.length > 0 ? parameters.client?.filter((item) => item.id == estimateProducts.clientId) : [];
    let companyInfo = parameters.company;
    let processInfo = parameters.process;
    let deviceMasterInfo = parameters.device;
    let processPattern = parameters.processPattern;
    let estimateStatus =
      parameters.estimateStatus?.length > 0
        ? parameters.estimateStatus?.filter((item) => item.id == estimateProducts?.estimateStatusId)
        : [];
    let purchaseAllocation = parameters.purchaseAllocation;
    let purchaseCategoryInfo = parameters.purchaseCategory;
    let purchasesData = parameters.purchase;
    let staffList = parameters.staff;
    let userSettingData = settings.userSetting;
    let companySettingData = settings.companySetting;

    if (companyInfo?.length > 0) {
      orderCoeffs = companyInfo[0]?.coeffOrders;
      deliDateCoeffs = companyInfo[0]?.coeffDeliveryDates;
      purCoeffs = companyInfo[0]?.coeffPurchases;
      mngCoeffs = companyInfo[0]?.coeffManagements;

      costLst = companyInfo[0]?.additionalItemCosts
        ?.filter((item) => item.isUsed && !item.isDeleted)
        ?.map((item) => {
          return initAdditionalItemCost(item.id, item.code, item.name);
        });

      labelLst = companyInfo[0]?.additionalItemLabels
        ?.filter((item) => item.isUsed && !item.isDeleted)
        ?.map((item) => {
          return initAdditionalItemLabel(item.id, item.code, item.name);
        });

      memoLst = companyInfo[0]?.additionalItemMemos
        ?.filter((item) => item.isUsed && !item.isDeleted)
        ?.map((item) => {
          return initAdditionalItemMemo(item.id, item.code, item.name);
        });

      dateLst = companyInfo[0]?.additionalItemDates
        ?.filter((item) => item.isUsed && !item.isDeleted)
        ?.map((item) => {
          return initAdditionalItemDate(item.id, item.code, item.name);
        });
    }

    estimateProducts.clientId = client ? client?.[0]?.id : '';
    estimateProducts.clientName = client ? client?.[0]?.name : '';
    estimateProducts.coeffOrdersId = orderCoeffs?.length > 0 ? orderCoeffs?.[0]?.id : undefined;
    estimateProducts.coeffOrdersName = orderCoeffs?.length > 0 ? orderCoeffs?.[0]?.name : '';
    estimateProducts.coeffDeliveryDatesId = deliDateCoeffs?.length > 0 ? deliDateCoeffs?.[0]?.id : undefined;
    estimateProducts.coeffDeliveryDatesName = deliDateCoeffs?.length > 0 ? deliDateCoeffs?.[0]?.name : '';
    estimateProducts.coeffPurchasesId = purCoeffs?.length > 0 ? purCoeffs?.[0]?.id : undefined;
    estimateProducts.coeffPurchasesName = purCoeffs?.length > 0 ? purCoeffs?.[0]?.name : '';
    estimateProducts.coeffManagementsId = mngCoeffs?.length > 0 ? mngCoeffs?.[0]?.id : undefined;
    estimateProducts.coeffManagementsName = mngCoeffs?.length > 0 ? mngCoeffs?.[0]?.name : '';
    estimateProducts.createrId =
      userSettingData?.length > 0 ? userSettingData?.[0]?.userId : staffList?.length > 0 ? staffList?.[0]?.id : '';
    // creater: staffList?.length > 0 ? staffList[0]?.label : '',
    estimateProducts.creater =
      userSettingData?.length > 0
        ? userSettingData?.[0]?.users?.nickName
        : staffList?.length > 0
        ? staffList?.[0]?.name
        : '';
    estimateProducts.estimateStatusId = estimateStatus?.length > 0 ? estimateStatus?.[0]?.id : '';
    estimateProducts.estimateStatus = estimateStatus?.length > 0 ? estimateStatus?.[0]?.name : '';
    estimateProducts.otherProduct.coeffClient = client
      ? toDecimal(client?.[0]?.coeffClients?.find((item) => item.clientType === ClientType.Customer)?.coeff)
      : 0.0;
    estimateProducts.otherProduct.coeffOrders = orderCoeffs?.length > 0 ? toDecimal(orderCoeffs?.[0]?.coeff) : 0.0;
    estimateProducts.otherProduct.coeffDeliveryDates =
      deliDateCoeffs?.length > 0 ? toDecimal(deliDateCoeffs?.[0]?.coeff) : 0.0;
    estimateProducts.otherProduct.coeffPurchases = purCoeffs?.length > 0 ? toDecimal(purCoeffs?.[0]?.coeff) : 0.0;
    estimateProducts.otherProduct.coeffManagements = mngCoeffs?.length > 0 ? toDecimal(mngCoeffs?.[0]?.coeff) : 0.0;

    //追加項目情報再設定
    selectedDataDetail.epCustomCosts = costLst;
    selectedDataDetail.epCustomDates = dateLst;
    selectedDataDetail.epCustomLabels = labelLst;
    selectedDataDetail.epCustomMemos = memoLst;
  }
  //再計算
  // 数量
  let quantity = selectedDataDetail?.estimateProducts?.quantity;
  let device = estimateProducts?.calcParameters?.parameters?.device;
  let productionQuantityCoeff = 0;
  //板金子部品の工程積算情報
  let esIq3Infos = selectedDataDetail?.esIq3Info;
  let iq3SetupCostTotalPrice = 0;
  let iq3WorkCostTotalPrice = 0;
  let iq3PurUnitPrice = 0;
  let iq3PurTotalPrice = 0;
  let iq3Weight = 0;
  let iq3Area = 0;

  for (let s = 0; s < esIq3Infos?.length; s++) {
    let partCnt = Number(esIq3Infos[s]?.partCount);
    iq3Weight += Number(esIq3Infos[s]?.weight);
    iq3Area += Number(esIq3Infos[s]?.totalSurfaceArea);
    let iq3ProcessPattern = esIq3Infos[s]?.processSelect;
    //各子部品の工程積算合計計算
    let iq3UpdateKouteiCalInfo = esIq3Infos[s]?.additions;
    if (iq3UpdateKouteiCalInfo) {
      // 計算した工程データの足し算
      iq3UpdateKouteiCalInfo?.forEach((iq3UpdatedKouteiContent) => {
        // 段取原価合計金額計算
        estimateProducts.setupCostTotalPrice +=
          iq3UpdatedKouteiContent.dataType == DataType.Data
            ? iq3UpdatedKouteiContent.totalDandori.dataItemsAmt
            : iq3UpdatedKouteiContent.totalDandori.editItemsAmt;

        iq3SetupCostTotalPrice +=
          iq3UpdatedKouteiContent.dataType == DataType.Data
            ? iq3UpdatedKouteiContent.totalDandori.dataItemsAmt
            : iq3UpdatedKouteiContent.totalDandori.editItemsAmt;

        // 加工原価合計金額計算
        estimateProducts.workCostTotalPrice +=
          iq3UpdatedKouteiContent.dataType == DataType.Data
            ? iq3UpdatedKouteiContent.totalSagyo.dataItemsAmt
            : iq3UpdatedKouteiContent.totalSagyo.editItemsAmt;

        iq3WorkCostTotalPrice +=
          iq3UpdatedKouteiContent.dataType == DataType.Data
            ? iq3UpdatedKouteiContent.totalSagyo.dataItemsAmt
            : iq3UpdatedKouteiContent.totalSagyo.editItemsAmt;

        // 生産個数係数取得
        if (
          iq3UpdatedKouteiContent.totalDandori.dataItemsAmt > 0 ||
          iq3UpdatedKouteiContent.totalDandori.editItemsAmt > 0
        ) {
          let patternInfoFilter = iq3ProcessPattern
            ? iq3ProcessPattern?.details?.filter(
                (item) => item.processId == iq3UpdatedKouteiContent?.processId && item.isUsedForCalc
              )[0]
            : [];
          let deviceMstFilter = device
            ? device?.filter(
                (item) => patternInfoFilter?.processId == item.processId && patternInfoFilter?.deviceId == item.id
              )
            : [];
          if (deviceMstFilter != undefined && deviceMstFilter?.length > 0) {
            // 生産個数係数
            let productionCoeffTh = deviceMstFilter[0]?.details?.productionQtyCoeffThMaster?.filter(
              (item) => item.value == quantity || item.value > quantity
            );
            if (productionCoeffTh) {
              let productionCoeffThNo =
                productionCoeffTh?.[0]?.length > 0
                  ? productionCoeffTh?.[0]?.no
                  : deviceMstFilter?.[0]?.details?.productionQtyCoeffThMaster?.[0]?.no;
              let productionCoeff =
                productionCoeffThNo == 0
                  ? 0
                  : deviceMstFilter?.[0]?.details?.productionQtyCoeffThItems?.filter(
                      (item) => item.no == productionCoeffThNo
                    )[0]?.value;
              productionQuantityCoeff += productionCoeff;
            }
          }
        }

        //表面処理費
        let curWorkType = parameters?.process?.filter((item) => item.id == iq3UpdatedKouteiContent?.processId)?.[0]
          ?.workType;
        if (curWorkType == WorkType.SmSurface) {
          let surfaceSetup =
            iq3UpdatedKouteiContent.dataType == DataType.Data
              ? iq3UpdatedKouteiContent.totalDandori.dataItemsAmt
              : iq3UpdatedKouteiContent.totalDandori.editItemsAmt;
          let surfaceWork =
            iq3UpdatedKouteiContent.dataType == DataType.Data
              ? iq3UpdatedKouteiContent.totalSagyo.dataItemsAmt
              : iq3UpdatedKouteiContent.totalSagyo.editItemsAmt;
          if (surfaceSetup && surfaceWork) {
            selectedDataDetail.esIq3Info[s].surfaceUnitPrice = surfaceSetup + surfaceWork;
            selectedDataDetail.esIq3Info[s].surfaceTotalPrice =
              selectedDataDetail.esIq3Info[s].surfaceUnitPrice * partCnt;
          }
        }
      });
      if (iq3SetupCostTotalPrice && iq3WorkCostTotalPrice) {
        selectedDataDetail.esIq3Info[s].workCostUnitPrice = iq3SetupCostTotalPrice + iq3WorkCostTotalPrice;
        selectedDataDetail.esIq3Info[s].workCostTotalPrice =
          selectedDataDetail.esIq3Info[s]?.workCostUnitPrice * partCnt;
      }
    }
    // 板金の購入品合計
    let iq3Purchase = esIq3Infos[s]?.purchases;
    for (let p = 0; p < iq3Purchase?.length; p++) {
      iq3PurUnitPrice += iq3Purchase[p]?.unitPrice;
      iq3PurTotalPrice += iq3Purchase[p]?.unitPrice * partCnt;
    }
    // 板金の購入品合計
    selectedDataDetail.esIq3Info[s].purchaseUnitPrice = iq3PurUnitPrice ? iq3PurUnitPrice : 0;
    selectedDataDetail.esIq3Info[s].purchaseTotalPrice = iq3PurTotalPrice ? iq3PurTotalPrice * quantity : 0;

    //板金の原価計算(材料原価＋製造原価＋購入品費)
    if (
      selectedDataDetail.esIq3Info[s]?.materialCostUnitPrice &&
      selectedDataDetail.esIq3Info[s]?.workCostUnitPrice &&
      selectedDataDetail.esIq3Info[s]?.purchaseUnitPrice
    ) {
      selectedDataDetail.esIq3Info[s].costUnitPrice =
        selectedDataDetail.esIq3Info[s]?.materialCostUnitPrice +
        selectedDataDetail.esIq3Info[s]?.workCostUnitPrice +
        selectedDataDetail.esIq3Info[s]?.purchaseUnitPrice;
    }

    if (selectedDataDetail.esIq3Info[s]?.costUnitPrice) {
      selectedDataDetail.esIq3Info[s].costTotalPrice =
        selectedDataDetail.esIq3Info[s]?.costUnitPrice * partCnt * quantity;
    }
  }

  //親部品の工程積算情報
  updateKouteiCalInfo = estimateProducts?.additions;
  let setupCoeff = 1;
  let updateKouteiInfos = [];
  if (updateKouteiCalInfo) {
    estimateProducts.setupCostTotalPrice = 0;
    estimateProducts.workCostTotalPrice = 0;

    // 製造原価計算(段取金額＊段取個数係数+作業/加工金額*生産個数係数)
    let processPattern = estimateProducts?.processSelect;
    //工程パターンに利用しない工程を削除
    updateKouteiCalInfo?.forEach((koutei) => {
      let updateKouteiInfo = {};
      updateKouteiInfo = processPattern?.details
        ? processPattern?.details?.filter(
            (item) =>
              item.processId === koutei.processId &&
              item.isUsedForCalc == true &&
              koutei.dataItems[0]?.processDetailTypeNo == item.multiprocesses[0]?.processDetailTypeId
          )
        : [];
      if (updateKouteiInfo?.length > 0) {
        updateKouteiInfos.push(koutei);
      }
    });

    // 親部品の計算した工程データの足し算
    updateKouteiInfos?.forEach((updatedKouteiContent) => {
      // 段取原価合計金額計算
      estimateProducts.setupCostTotalPrice +=
        updatedKouteiContent.dataType == DataType.Data
          ? updatedKouteiContent.totalDandori.dataItemsAmt
          : updatedKouteiContent.totalDandori.editItemsAmt;

      // 加工原価合計金額計算
      estimateProducts.workCostTotalPrice +=
        updatedKouteiContent.dataType == DataType.Data
          ? updatedKouteiContent.totalSagyo.dataItemsAmt
          : updatedKouteiContent.totalSagyo.editItemsAmt;

      // 生産個数係数取得
      if (updatedKouteiContent.totalDandori.dataItemsAmt > 0 || updatedKouteiContent.totalDandori.editItemsAmt > 0) {
        let patternInfoFilter = processPattern
          ? processPattern?.details?.filter(
              (item) => item.processId == updatedKouteiContent.processId && item.isUsedForCalc
            )[0]
          : [];
        let deviceMstFilter = device
          ? device?.filter(
              (item) => patternInfoFilter?.processId == item.processId && patternInfoFilter?.deviceId == item.id
            )
          : [];
        if (deviceMstFilter != undefined && deviceMstFilter?.length > 0) {
          // 生産個数係数
          let productionCoeffTh = deviceMstFilter[0]?.details?.productionQtyCoeffThMaster?.filter(
            (item) => item.value == quantity || item.value > quantity
          );
          if (productionCoeffTh) {
            let productionCoeffThNo =
              productionCoeffTh[0]?.length > 0
                ? productionCoeffTh[0]?.no
                : deviceMstFilter[0]?.details?.productionQtyCoeffThMaster?.[0]?.no;
            let productionCoeff =
              productionCoeffThNo == 0
                ? 0
                : deviceMstFilter[0]?.details?.productionQtyCoeffThItems?.filter(
                    (item) => item.no == productionCoeffThNo
                  )[0]?.value;

            productionQuantityCoeff += productionCoeff;
          }
        }
      }
    });
  }

  // 親部品の購入品合計
  let parentPurchase = estimateProducts?.purchases;
  let parPurUnitPrice = 0;
  let parPurTotalPrice = 0;
  for (let p = 0; p < parentPurchase?.length; p++) {
    parPurUnitPrice += parentPurchase[p]?.unitPrice;
    parPurTotalPrice += parentPurchase[p]?.totalPrice;
  }
  estimateProducts.purchaseUnitPrice = parPurUnitPrice ? parPurUnitPrice : 0;
  estimateProducts.purchaseTotalPrice = parPurTotalPrice ? parPurTotalPrice : 0;

  //板金子部品より重量と面積データ更新
  estimateProducts.totalWeight = iq3Weight;
  estimateProducts.totalSurfaceArea = iq3Area;
  //親部品表面処理に子部品の重量、面積更新

  // 製造原価設定
  if (estimateProducts?.setupCostTotalPrice && estimateProducts?.workCostTotalPrice) {
    estimateProducts.manufacturingCostTotalPrice =
      estimateProducts.setupCostTotalPrice * setupCoeff +
      estimateProducts?.workCostTotalPrice * productionQuantityCoeff;
  }

  //費用合計
  let costPrice = 0;
  let costPriceTotal = 0;
  let epCustomCosts = selectedDataDetail.epCustomCosts;
  for (let i = 0; i < epCustomCosts?.length; i++) {
    if (epCustomCosts[i]?.isUseUnit) {
      costPrice += epCustomCosts[i]?.unitPrice;
    } else {
      costPrice += epCustomCosts[i]?.totalPrice;
    }
    costPriceTotal += costPrice * quantity;
  }

  // 原価単価（材料原価＋製造原価＋購入品＋費用）
  let materialCostUnitPrice = estimateProducts?.materialCostUnitPrice ? estimateProducts?.materialCostUnitPrice : 0;
  let manufacturingCostTotalPrice = estimateProducts?.manufacturingCostTotalPrice
    ? estimateProducts?.manufacturingCostTotalPrice
    : 0;
  let purchaseUnitPrice = estimateProducts?.purchaseUnitPrice
    ? estimateProducts?.purchaseUnitPrice + iq3PurUnitPrice
    : 0;
  let genka = materialCostUnitPrice + manufacturingCostTotalPrice + purchaseUnitPrice + costPrice;
  if (genka) {
    estimateProducts.costUnitPrice = genka;
  } else {
    estimateProducts.costUnitPrice = 0;
  }

  // 合計原価（材料原価*数量+加工原価*数量+購入品費+追加項目(費用)）
  // 材料原価合計
  let totalMaterialGenka = materialCostUnitPrice ? materialCostUnitPrice * quantity : 0;
  estimateProducts.materialCostTotalPrice = totalMaterialGenka ? totalMaterialGenka : 0;
  // 製造原価合計((段取金額＊段取個数係数+作業/加工金額*生産個数係数)*数量)
  let totalProcessGenka = manufacturingCostTotalPrice ? manufacturingCostTotalPrice * quantity : 0;
  // 購入品費合計(購入品金額(数量別金額)*数量　※設定による（単価保持の場合のみ*数量）)
  let totalPurchaseCost = estimateProducts?.purchaseTotalPrice
    ? estimateProducts?.purchaseTotalPrice + iq3PurTotalPrice
    : 0;
  let totalGenka = totalMaterialGenka + totalProcessGenka + totalPurchaseCost + costPriceTotal;
  estimateProducts.costTotalPrice = totalGenka ? totalGenka : 0;

  // 材料見積(材料費*材料粗利係数)
  let materialEstimate = materialCostUnitPrice ? materialCostUnitPrice * 1.0 : 0;
  // 加工見積((加工原価*(取引先係数＋納期係数＋受注形態係数-2))*管理係数)
  let coeffsTotal =
    Number(estimateProducts?.otherProduct?.coeffClient) +
    Number(estimateProducts?.otherProduct?.coeffDeliveryDates) +
    Number(estimateProducts?.otherProduct?.coeffOrders);
  let processEstimate =
    coeffsTotal && estimateProducts?.otherProduct?.coeffManagements
      ? genka * (coeffsTotal - 2) * Number(estimateProducts?.otherProduct?.coeffManagements)
      : 0;
  // 購入品費見積(購入品費*"仕入先係数or購入品係数")
  let purchaseEstimate =
    purchaseUnitPrice && estimateProducts?.otherProduct?.coeffPurchases
      ? purchaseUnitPrice * Number(estimateProducts?.otherProduct?.coeffPurchases)
      : 0;
  // 見積(材料見積+加工見積+購入品費見積+追加項目(費用)見積)
  let estimate = materialEstimate + processEstimate + purchaseEstimate + costPrice;
  estimateProducts.estimateUnitPrice = estimate ? estimate : 0;

  // 材料見積合計(材料見積*数量)
  let totalMaterialEstimate = materialEstimate ? materialEstimate * quantity : 0;
  // 加工見積合計(加工見積*数量)
  let totalProcessEstimate = processEstimate ? processEstimate * quantity : 0;
  // 購入品費見積(購入品費*"仕入先係数or購入品係数")
  let totalPurchaseEstimate = purchaseEstimate ? purchaseEstimate * quantity : 0;
  // 合計見積(材料見積*数量+加工見積*数量+購入品費+追加項目(費用))
  let totalEstimate = totalMaterialEstimate + totalProcessEstimate + totalPurchaseEstimate + costPriceTotal;
  estimateProducts.estimateTotalPrice = totalEstimate ? totalEstimate : 0;

  // 粗利単価(見積-原価)
  estimateProducts.grossUnitPrice = estimate && genka ? Math.abs(estimate - genka) : 0;
  // 粗利合計(合計見積-合計原価)
  estimateProducts.grossTotalPrice = totalEstimate && totalGenka ? Math.abs(totalEstimate - totalGenka) : 0;

  // 粗利率(粗利/合計見積x100)
  if (estimateProducts.grossTotalPrice && totalEstimate) {
    estimateProducts.grossRate =
      estimateProducts.grossTotalPrice === 0 || totalEstimate === 0
        ? 0
        : ((estimateProducts.grossTotalPrice / totalEstimate) * 100).toFixed(2);
  }

  estimateProducts.materialLossCoefficient = estimateProducts.materialLossCoefficient
    ? estimateProducts.materialLossCoefficient
    : 0;

  estimateProducts.additions = updateKouteiInfos;
  selectedDataDetail.estimateProducts = estimateProducts;
  return selectedDataDetail;
}

//Excel 出力

//Serverからフォーマットファイルにあるcolumnを取得
//param (HTML型フォーマットテーブル)
function extractHeaderFromHtmlTable(tableHtml) {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = tableHtml;

  const table = tempDiv.querySelector('table');
  let tdContentArray;

  if (table) {
    const rows = table.querySelectorAll('tr');
    rows?.forEach((row) => {
      const cells = row.querySelectorAll('td');
      const cellContentArray = [];

      cells?.forEach((cell) => {
        const cellContent = cell.textContent.trim();
        cellContentArray.push(cellContent);
      });

      tdContentArray = cellContentArray;
    });
  } else {
    console.error('Table not found in HTML content.');
  }

  return tdContentArray;
}
//dataIndexによって情報取得
const extractColumnData = (record, column) => {
  const dbNameAttr = column.dbNameAttr;
  const dataIndex = column.dataIndex;
  const nestedDataIndex = dataIndex.split('.');
  let value = record;

  nestedDataIndex?.forEach((index) => {
    if (value) {
      value = value[index];
    }
  });

  if (value === undefined) {
    return 0;
  }

  if (column.render) {
    return column.render(value, record);
    /* return column.render(value); */
  }

  return value;
};

//フォーマットによって画面に表示しいてるdata取得
function extractDataByExcelColumns(data, excelColumnHeaders, columnList) {
  const arrayOfObjects = [];

  let initDisplayColumns = []; /* getDisplayColumns() */
  if (columnList) {
    initDisplayColumns = columnList;
  }
  data?.forEach((record, index) => {
    const rowData = {};
    excelColumnHeaders?.forEach((header) => {
      // defaultColumns?.forEach((column) => {
      const column = initDisplayColumns?.find((col) => col.title === header);
      if (column) {
        const columnNameT = column.id + column.title;
        const columnName = column.title;
        const columnData = extractColumnData(record, column);
        if (typeof columnData === 'object') {
          if (columnData.props?.children?.props !== undefined) {
            rowData[columnName] = columnData.props?.children?.props?.children;
          } else if (Array.isArray(columnData.props?.children)) {
            rowData[columnName] = columnData.props?.children?.[1];
          } else if (columnData.props?.children !== undefined) {
            rowData[columnName] = columnData.props?.children;
          } else if (columnData.props?.checked !== undefined) {
            rowData[columnName] = 'true' /* columnData.props?.checked */;
          }
        } else if (columnName == 'ID') {
          rowData[columnName] = data?.length - index;
        } else {
          rowData[columnName] = columnData;
        }
      } else {
        rowData[header] = null; // Handle columns not found in defaultColumns
      }
    });
    arrayOfObjects.push(rowData);
  });
  return arrayOfObjects;
}

//HTML Table作成
//param (テーブル column, テーブル内容)
function createHtmlTable(header, data) {
  // Create the table element
  let table = document.createElement('table');

  if (header !== '') {
    // Create the table header row
    let headerRow = document.createElement('tr');
    for (let columnHeader of header) {
      let th = document.createElement('th');
      th.textContent = columnHeader;
      headerRow.appendChild(th);
    }
    table.appendChild(headerRow);

    // Create table rows for each data entry
    for (let rowData of data) {
      let row = document.createElement('tr');
      for (let columnHeader of header) {
        let td = document.createElement('td');
        td.textContent = rowData[columnHeader];
        row.appendChild(td);
      }
      table.appendChild(row);
    }
  } else {
    for (let rowData of data) {
      let row = document.createElement('tr');
      for (let key in rowData) {
        let td = document.createElement('td');
        if (Object.hasOwnProperty.call(rowData, key)) {
          td.textContent = rowData[key];
        }
        row.appendChild(td);
      }
      table.appendChild(row);
    }
  }

  // Serialize the table to an HTML string
  let htmlString = table.outerHTML;

  return htmlString;
}

export function getNameByIdExcel(id, selectedData) {
  let retValue = '';
  if (id) {
    let info = selectedData.estimateProducts.calcParameters.parameters.purchaseCategory?.filter(
      (info) => info.id === id
    );
    if (info?.length > 0) {
      retValue = info[0].name;
    }
  }
  return retValue;
}

export const createAdditionalItemsTbl = (selectedData) => {
  const customFieldData = {
    '■ｶｽﾀﾑﾌｨｰﾙﾄﾞ[費用]': [],
    '■ｶｽﾀﾑﾌｨｰﾙﾄﾞ[ラベル]': [],
    '■ｶｽﾀﾑﾌｨｰﾙﾄﾞ[日付]': [],
    '■ｶｽﾀﾑﾌｨｰﾙﾄﾞ[メモ]': [],
  };

  // Populate the arrays with data
  customFieldData['■ｶｽﾀﾑﾌｨｰﾙﾄﾞ[費用]'] = selectedData.epCustomCosts?.map((item, index) => ({
    id: index + 1,
    additionalItemCode: item?.additionalItemCode,
    additionalItemName: item?.additionalItemName,
    unitPrice: item?.unitPrice,
    quantity: selectedData?.estimateProducts?.quantity,
    totalPrice: item?.totalPrice,
  }));

  customFieldData['■ｶｽﾀﾑﾌｨｰﾙﾄﾞ[ラベル]'] = selectedData.epCustomLabels?.map((item, index) => ({
    id: index + 1,
    additionalItemCode: item?.additionalItemCode,
    additionalItemName: item?.additionalItemName,
    label: item?.label,
  }));

  customFieldData['■ｶｽﾀﾑﾌｨｰﾙﾄﾞ[日付]'] = selectedData.epCustomDates?.map((item, index) => ({
    id: index + 1,
    additionalItemCode: item?.additionalItemCode,
    additionalItemName: item?.additionalItemName,
    date: item?.date,
  }));

  customFieldData['■ｶｽﾀﾑﾌｨｰﾙﾄﾞ[メモ]'] = selectedData.epCustomMemos?.map((item, index) => ({
    id: index + 1,
    additionalItemCode: item?.additionalItemCode,
    additionalItemName: item?.additionalItemName,
    memo: item?.memo,
  }));
  return customFieldData;
};

const createProcessDataTbl = (selectedData) => {
  const customFieldData = {};
  let calculateData = selectedData.estimateProducts.additions;
  let calInputData = selectedData.estimateProducts.processInput;
  let processMasterData = selectedData.estimateProducts.calcParameters.parameters.process;
  let calProcessMstData = [];
  calculateData?.forEach((calData, index1) => {
    let calProcess = processMasterData?.filter((record) => record?.id === calData.processId)?.[0];
    calProcessMstData.push(calProcess);
    let count = 1;
    calData.dataItems?.forEach((e, index) => {
      let totalAmt;
      let totalDandoriAmt;
      let totalSagyoAmt;
      let totalDandoriTime;
      let totalSagyoTime;
      if (calData.dataType == 0) {
        totalAmt = e?.dandoriAmt + e?.sagyoAmt;
      } else if (e.dataType == 1) {
        totalAmt = e?.dandoriAmt + e?.sagyoAmt;
      }
      let processInput = {};
      // Iterate through the processInput properties
      for (const prop in calInputData) {
        if (Object.hasOwnProperty.call(calInputData, prop)) {
          const items = calInputData[prop];

          // Check if the property is an array
          if (Array.isArray(items)) {
            const filteredItems = items?.filter(
              (item) =>
                item?.workType === calProcess?.workType &&
                e.processDetailTypeNo == item.processDetailTypeNo &&
                e.processDetailWorkTypeGroup == item.processDetailWorkTypeGroup &&
                e.id == item.no
            );
            processInput = filteredItems;
          }
        }
      }
      if (!customFieldData[calProcess?.workType]) {
        customFieldData[calProcess?.workType] = [];
      }
      customFieldData[calProcess?.workType]?.push({
        no: index1 + 1,
        classification: calProcess.class == 0 ? '親部品' : calProcess.class == 1 ? '板金' : '',
        type: calProcess.isAdditional ? '追加' : '標準',
        processCode: calProcess.code,
        processName: calProcess.name,
        processType: (() => {
          const matchType = kouteiMasterTypes?.find((type) => type.workType === calProcess.workType);
          return matchType ? matchType.typeName : null;
        })(),
        no2: count,
        detailCode: calProcess?.details?.processDetailTypes?.filter(
          (record) => record?.id === e?.processDetailTypeNo
        )?.[0]?.code,
        processDetailType: calProcess?.details?.processDetailTypes?.filter(
          (record) => record?.id === e?.processDetailTypeNo
        )?.[0]?.name,
        parentCount: 1,
        childCount: '',
        kaitsuu: selectedData?.estimateProducts?.quantity,
        menseki: processInput?.[0]?.totalArea,
        jyuuryou: processInput?.[0]?.totalWeight,
        estTotalAmt: totalAmt,
        estDandoriAmt: e.dandoriAmt,
        estWorkAmt: e.sagyoAmt,
        gankaTotalAmt: totalAmt,
        gankaDandoriAmt: e.dandoriAmt,
        gankaSagyoAmt: e.sagyoAmt,
        gankaDandoriTime: secondsToHms(e.dandoriTime),
        gankaSagyoTime: secondsToHms(e.sagyoTime),
      });
      count++;
    });
  });
  return customFieldData;
};

export const createProcessDataTbl2 = (selectedData) => {
  const customFieldData = {};
  let calculateData = selectedData.estimateProducts.additions;
  let calInputData = selectedData.estimateProducts.processInput;
  let processMasterData = selectedData.estimateProducts.calcParameters.parameters.process;
  let calProcessMstData = [];
  calculateData?.forEach((calData, index1) => {
    let calProcess = processMasterData?.filter((record) => record?.id === calData.processId)?.[0];
    calProcessMstData.push(calProcess);

    calData.dataItems?.forEach((e, index) => {
      let totalAmt;
      let totalDandoriAmt;
      let totalSagyoAmt;
      let totalDandoriTime;
      let totalSagyoTime;
      if (calData.dataType == 0) {
        totalAmt = e?.dandoriAmt + e?.sagyoAmt;
      } else if (e.dataType == 1) {
        totalAmt = e?.dandoriAmt + e?.sagyoAmt;
      }

      let processInput = {};
      // Iterate through the processInput properties
      for (const prop in calInputData) {
        if (Object.hasOwnProperty.call(calInputData, prop)) {
          const items = calInputData[prop];

          // Check if the property is an array
          if (Array.isArray(items)) {
            const filteredItems = items?.filter(
              (item) =>
                item?.workType === calProcess?.workType &&
                e.processDetailTypeNo == item.processDetailTypeNo &&
                e.processDetailWorkTypeGroup == item.processDetailWorkTypeGroup &&
                e.id == item.no
            );
            processInput = filteredItems;
          }
        }
      }

      const commonAttribute =
        calProcess.workType == WorkType.PaCustom
          ? 'Additional' + calProcess?.workType + calProcess?.name
          : calProcess?.workType;

      if (!customFieldData[commonAttribute]) {
        customFieldData[commonAttribute] = [];
      }
      customFieldData[commonAttribute]?.push({
        'No.': index1 + 1,
        分類: calProcess.class == 0 ? '親部品' : calProcess.class == 1 ? '板金' : '',
        種別: calProcess.isAdditional ? '追加' : '標準',
        工程ｺｰﾄﾞ: calProcess.code,
        工程名: calProcess.name,
        工程ﾀｲﾌﾟ: (() => {
          const matchType = kouteiMasterTypes?.find((type) => type.workType === calProcess.workType);
          return matchType ? matchType.typeName : null;
        })(),
        'No.': index + 1,
        種類ｺｰﾄﾞ: calProcess?.details?.processDetailTypes?.filter(
          (record) => record?.id === e?.processDetailTypeNo
        )?.[0]?.code,
        種類: calProcess?.details?.processDetailTypes?.filter((record) => record?.id === e?.processDetailTypeNo)?.[0]
          ?.name,
        親部品数量: 1,
        '個数(子部品点数)': '',
        回数: selectedData?.estimateProducts?.quantity,
        面積: processInput?.[0]?.totalArea,
        重量: processInput?.[0]?.totalWeight,
        見積合計金額: totalAmt,
        段取金額: e.dandoriAmt,
        加工金額: e.sagyoAmt,
        原価合計金額: totalAmt,
        段取金額: e.dandoriAmt,
        加工金額: e.sagyoAmt,
        段取時間: secondsToHms(e.dandoriTime),
        加工時間: secondsToHms(e.sagyoTime),
      });
    });
  });
  return customFieldData;
};

const getChildProcessCount = (selectedData, iq3Infos) => {
  let processMasterData = selectedData?.estimateProducts?.calcParameters?.parameters?.process;
  const isUsedForCalcArrayPerItem = iq3Infos?.map((item) =>
    item?.processSelect?.details?.filter((detail) => detail.isUsedForCalc)?.map((detail) => detail.processId)
  );
  const foundCountArr = [];
  isUsedForCalcArrayPerItem?.forEach((subArray) => {
    const subArrayCounts = subArray?.reduce((count, num) => {
      count[num] = (count[num] || 0) + 1;
      return count;
    }, {});

    for (const key in subArrayCounts) {
      foundCountArr[key] = (foundCountArr[key] || 0) + subArrayCounts[key];
    }
  });

  let dataSheet4 = [];
  Object.keys(foundCountArr)?.forEach((key, index) => {
    let useProcessMasterData = processMasterData?.filter((record) => record?.id === parseInt(key))?.[0];
    let matchType = kouteiMasterTypes?.find((type) => type.workType === useProcessMasterData.workType);
    let relatedType = matchType ? matchType.typeName : null;
    dataSheet4.push({
      no: index + 1,
      id: parseInt(key),
      classification: useProcessMasterData.class == 0 ? '親部品' : useProcessMasterData.class == 1 ? 'iQ3' : '',
      type: useProcessMasterData.isAdditional ? '追加' : '標準',
      processCode: useProcessMasterData.code,
      processName: useProcessMasterData.name,
      count: foundCountArr[key],
    });
  });

  return dataSheet4;
};

const getSheet5Data = (usedProcessList, iq3Infos) => {
  const sheet5Data = [];
  iq3Infos?.forEach((estData, index) => {
    let additionsData = estData.additions;
    const resultData = [];
    const sheet5Object = {
      no: index + 1,
      drawingNo: estData.drawingNo,
      name: estData.name,
      partCount: estData.partCount,
      prodNo: '',
      partSizeX: estData.partSizeX,
      partSizeY: estData.partSizeY,
      '(空白)': '',
      材料名称: '',
      材質: '',
      板厚: '',
      材料比重: '',
      表面保護属性: '',
      重量: '',
      表面積: '',
      合計金額: '',
      歩留: '',
      枚数: '',
      重量単価: '',
      表面加算: '',
      重量加算: '',
      材料単価: '',
      原価率: '',
      スクラップ: '',
      合計金額1: '',
      歩留1: '',
      枚数1: '',
      材料サイズ選択: '',
      サイズX: '',
      サイズY: '',
      合計金額2: '',
      歩留2: '',
      枚数2: '',
      材料サイズ選択1: '',
      サイズX1: '',
      サイズY1: '',
      '(空白)1': '',
      単価: '',
      合計: '',
      '(空白)2': '',
      材料単価1: '',
      加工単価: '',
      購入品単価: '',
      材料合計: '',
      加工合計: '',
      購入品合計: '',
      '(空白)3': '',
      単価1: '',
      合計1: '',
      '(空白)4': '',
      材料単価2: '',
      加工単価1: '',
      購入品単価1: '',
      材料合計1: '',
      加工合計1: '',
      購入品合計1: '',
      '(空白)5': '',
    };
    usedProcessList?.forEach((item) => {
      const id = item.id.id;
      const matchedData = additionsData?.find((addition) => addition.processId === id);

      if (matchedData) {
        const { dataType, totalDandori, totalSagyo } = matchedData;
        if (id == 15) {
          resultData[id] = {
            menseki: estData.totalSurfaceArea,
            jyuuryou: estData.weight,
            [`setupAmount${id}`]: dataType === 0 ? totalDandori.dataItemsAmt : totalDandori.editItemsAmt,
            [`setupTime${id}`]: dataType === 0 ? totalDandori.dataItemsTime : totalDandori.editItemsTime,
            [`processingAmount${id}`]: dataType === 0 ? totalSagyo.dataItemsAmt : totalSagyo.editItemsAmt,
            [`processingTime${id}`]: dataType === 0 ? totalSagyo.dataItemsTime : totalSagyo.editItemsTime,
            [`space${id}`]: '',
          };
        } else {
          resultData[id] = {
            [`setupAmount${id}`]: dataType === 0 ? totalDandori.dataItemsAmt : totalDandori.editItemsAmt,
            [`setupTime${id}`]: dataType === 0 ? totalDandori.dataItemsTime : totalDandori.editItemsTime,
            [`processingAmount${id}`]: dataType === 0 ? totalSagyo.dataItemsAmt : totalSagyo.editItemsAmt,
            [`processingTime${id}`]: dataType === 0 ? totalSagyo.dataItemsTime : totalSagyo.editItemsTime,
            [`space${id}`]: '',
          };
        }
      } else {
        if (id != 15) {
          resultData[id] = {
            [`setupAmount${id}`]: '',
            [`setupTime${id}`]: '',
            [`processingAmount${id}`]: '',
            [`processingTime${id}`]: '',
            [`space${id}`]: '',
          };
        } else {
          resultData[id] = {
            menseki: '',
            jyuuryou: '',
            [`setupAmount${id}`]: '',
            [`setupTime${id}`]: '',
            [`setupAmount${id}`]: '',
            [`processingTime${id}`]: '',
            [`space${id}`]: '',
          };
        }
      }
    });
    const mergedSheet5Object = [sheet5Object, ...resultData];
    const transformedObject = mergedSheet5Object?.reduce((result, item) => {
      for (const key in item) {
        result[key] = item[key];
      }
      return result;
    }, {});
    sheet5Data.push(transformedObject);
  });

  return sheet5Data;
};

const getSheet6Data = (selectedData, iq3Infos, usedProcessList) => {
  const sheet6Data = [];
  let calParam = selectedData?.estimateProducts?.calcParameters?.parameters;

  iq3Infos?.forEach((estData, index) => {
    sheet6Data[index] = {};
    let processSelect = estData.processSelect.details; //isUsedForCalcArrPerItem[index];
    let processInput = estData.processInput;

    const sheet6Object = [
      {
        no: index + 1,
        drawingNo: estData.drawingNo,
        name: estData.name,
        partCount: estData.partCount,
        prodNo: '',
        '(空白)': '',
      },
    ];
    sheet6Data[index]['partInfo'] = sheet6Object;
    usedProcessList?.forEach((item) => {
      sheet6Data[index][item.processName] = [];
      const id = item.id;
      let param = item.param;
      const matchedData = processSelect?.find((process) => process.processId === id);
      const matchedProcessInput = processInput?.find((process) => process.processId === id);
      let obj = {};
      if (matchedData.isUsedForCalc) {
        let matchedDevice = calParam?.device?.find((devic) => devic?.id === matchedData?.deviceId);
        let processMst = calParam?.process?.find((process) => process?.id === matchedData?.processId);
        let processCode = processMst.code;
        let deviceCode = matchedDevice === undefined ? '' : matchedDevice?.code;
        let deviceName = matchedDevice === undefined ? '' : matchedDevice?.name;

        obj.processCode = processCode;
        obj.deviceCode = deviceCode;
        obj.deviceName = deviceName;

        if (id === 10) {
          let element = processInput?.details?.[0]?.elements;
          obj.element = element;
          obj['(空白)'] = '';
          sheet6Data[index][item.processName]?.push(obj);
        } else if (id === 11 || id == 12 || id == 13) {
          obj.detailCode = processMst.code;
          obj.detailName = processMst.name;
          if (matchedProcessInput?.details !== undefined && matchedProcessInput?.details?.length > 0) {
            obj.innerDia = matchedProcessInput?.details?.[0]?.dimensionData?.innerDia;
            obj.outerDia = matchedProcessInput?.details?.[0]?.dimensionData?.outerDia;
            obj.airFeedDistance = matchedProcessInput?.details?.[0]?.dimensionData?.airFeedDistance;
            obj['(空白)'] = '';
          } else {
            obj.innerDia = '';
            obj.outerDia = '';
            obj.airFeedDistance = '';
            obj['(空白)'] = '';
          }
          sheet6Data[index][item.processName]?.push(obj);
        } else if (id === 14) {
          let bendingArr = [];
          if (matchedProcessInput?.details !== undefined && matchedProcessInput?.details?.length > 0) {
            matchedProcessInput?.details?.[0]?.bendingList?.forEach((bending) => {
              let matchedDetailType = processMst?.details?.processDetailTypes?.find(
                (detailType) => detailType.id === bending.types
              );
              let obj1 = {};
              obj1.processCode = processCode;
              obj1.deviceCode = deviceCode;
              obj1.deviceName = deviceName;
              obj1.detailCode = matchedDetailType?.code;
              obj1.detailName = matchedDetailType?.name;
              obj1.bendCount = bending?.bendCount;
              if (bending.type == 3) {
                obj1.bendCountFR = bending?.bendCountFR;
              } else {
                obj1.bendCountFR = '';
              }
              //bendingArr.push(obj);
              obj1['(空白)'] = '';
              sheet6Data[index][item.processName]?.push(obj1);
            });
          } else {
            obj.detailCode = '';
            obj.detailName = '';
            obj.bendCount = '';
            obj.bendCountFR = '';
            //bendingArr.push(obj);
            obj['(空白)'] = '';
            sheet6Data[index][item.processName]?.push(obj);
          }
          //sheet6Data[index][item.processName]?.push(bendingArr);
        } else if (id === 15) {
          if (matchedProcessInput?.details !== undefined && matchedProcessInput?.details?.length > 0) {
            obj.detailCode = processMst.code;
            obj.detailName = processMst.name;

            obj.area = matchedProcessInput?.details?.[0]?.area;
            obj.weight = matchedProcessInput?.details?.[0]?.weight;
            obj.outerLength = '';
            obj.innerLength = '';
            obj.count = matchedProcessInput?.details?.[0]?.count;
            obj['(空白)'] = '';

            sheet6Data[index][item.processName]?.push(obj);
          } else {
            obj.detailCode = '';
            obj.detailName = '';
            obj.area = '';
            obj.weight = '';
            obj.outerLength = '';
            obj.innerLength = '';
            obj.count = '';
            obj['(空白)'] = '';
            sheet6Data[index][item.processName]?.push(obj);
          }
        } else if (id === 16) {
          if (matchedProcessInput?.details !== undefined && matchedProcessInput?.details?.length > 0) {
            obj.detailCode = processMst.code;
            obj.detailName = processMst.name;

            obj.area = '';
            obj.weight = '';

            obj.outerLength = matchedProcessInput?.details?.[0]?.length;
            obj.outerCount = matchedProcessInput?.details?.[0]?.count;
            obj.innerLength = matchedProcessInput?.details?.[1]?.length;
            obj.innerCount = matchedProcessInput?.details?.[1]?.count;
            obj['(空白)'] = '';

            sheet6Data[index][item.processName]?.push(obj);
          } else {
            obj.detailCode = '';
            obj.detailName = '';
            obj.area = '';
            obj.weight = '';
            obj.outerLength = '';
            obj.innerLength = '';
            obj.outerCount = '';
            obj.innerCount = '';
            obj['(空白)'] = '';
            sheet6Data[index][item.processName]?.push(obj);
          }
        } else if (id === 17) {
          let hyomenArr = [];
          if (matchedProcessInput?.details !== undefined && matchedProcessInput?.details?.length > 0) {
            matchedProcessInput?.details?.forEach((hyomen) => {
              let matchedDetailType = processMst?.details?.processDetailTypes?.find(
                (detailType) => detailType.id === hyomen.processDetailWorkTypeGroup
              );
              let obj1 = {};
              obj1.processCode = processCode;
              obj1.deviceCode = deviceCode;
              obj1.deviceName = deviceName;
              obj1.detailCode = matchedDetailType?.code;
              obj1.detailName = matchedDetailType?.name;
              obj1.areaWeight = hyomen?.calcType == 1 ? '面積' : '重量';
              obj1.count = hyomen?.calcType == 1 ? hyomen?.areaCount : hyomen?.weightCount;
              obj1['(空白)'] = '';
              sheet6Data[index][item.processName]?.push(obj1);
            });
          } else {
            obj.detailCode = '';
            obj.detailName = '';
            obj.areaWeight = '';
            obj.count = '';
            obj['(空白)'] = '';
            sheet6Data[index][item.processName]?.push(obj);
          }
          //sheet6Data[index][item.processName]?.push(hyomenArr);
        } else if (id === 18) {
          let nijiArr = [];
          if (matchedProcessInput?.details !== undefined && matchedProcessInput?.details?.length > 0) {
            matchedProcessInput?.details?.forEach((niji) => {
              let matchedDetailType = processMst?.details?.processDetailTypes?.find(
                (detailType) => detailType.id === niji.types
              );
              let obj1 = {};
              obj1.processCode = processCode;
              obj1.deviceCode = deviceCode;
              obj1.deviceName = deviceName;
              obj1.detailCode = matchedDetailType?.code;
              obj1.detailName = matchedDetailType?.name;
              obj1.count = niji?.count;
              obj1.x = niji?.xsize;
              obj1.y = niji?.ysize;
              obj1.perimeter = niji?.perimeter;
              //nijiArr.push(obj);
              obj1['(空白)'] = '';
              sheet6Data[index][item.processName]?.push(obj1);
            });
          } else {
            obj.detailCode = '';
            obj.detailName = '';
            obj.count = '';
            obj.x = '';
            obj.y = '';
            obj.perimeter = '';
            //nijiArr.push(obj);
            obj['(空白)'] = '';
            sheet6Data[index][item.processName]?.push(obj);
          }
          // sheet6Data[index][item.processName]?.push(nijiArr);
        } else if (id === 19) {
          let yosetsuArr = [];
          if (matchedProcessInput?.details !== undefined && matchedProcessInput?.details?.length > 0) {
            matchedProcessInput?.details?.[0]?.weldingList?.forEach((weld) => {
              let matchedDetailType = processMst?.details?.processDetailTypes?.find(
                (detailType) => detailType.id === weld.yousetsuType
              );
              let obj1 = {};
              obj1.processCode = processCode;
              obj1.deviceCode = deviceCode;
              obj1.deviceName = deviceName;
              obj1.detailCode = matchedDetailType?.code;
              obj1.detailName = matchedDetailType?.name;
              obj1.count = '';
              obj1.type = '';
              obj1.senchou = String(weld?.senchou) + '/' + String(weld?.senchouKaisu);
              //yosetsuArr.push(obj);
              obj1['(空白)'] = '';
              sheet6Data[index][item.processName]?.push(obj1);
            });
            matchedProcessInput?.details?.[0]?.subWeldingList?.forEach((subWeld) => {
              let matchedDetailType = processMst?.details?.processDetailTypes?.find(
                (detailType) => detailType.id === subWeld.subWeldingType
              );
              let obj1 = {};
              obj1.processCode = processCode;
              obj1.deviceCode = deviceCode;
              obj1.deviceName = deviceName;
              obj1.detailCode = matchedDetailType?.code;
              obj1.detailName = matchedDetailType?.name;
              obj1.count = subWeld.count;
              obj1.type = matchedDetailType.name + '/' + subWeld.daimeter;
              obj1.senchou = '';
              // yosetsuArr.push(obj);
              obj['(空白)'] = '';
              sheet6Data[index][item.processName]?.push(obj1);
            });
          } else {
            obj.detailCode = '';
            obj.detailName = '';
            obj.count = '';
            obj.type = '';
            obj.senchou = '';
            //yosetsuArr.push(obj);
            obj['(空白)'] = '';
            sheet6Data[index][item.processName]?.push(obj);
          }
          // sheet6Data[index][item.processName]?.push(obj);
        } else if (id === 20) {
          let shiageArr = [];
          if (
            matchedProcessInput?.details?.[0]?.weldingList !== undefined &&
            matchedProcessInput?.details?.[0]?.weldingList?.length > 0
          ) {
            matchedProcessInput?.details?.[0]?.weldingList?.forEach((weld) => {
              if (weld.senchouYousetsushiage == 1) {
                let matchedDetailType = processMst?.details?.processDetailTypes?.find(
                  (detailType) => detailType.id === weld.yousetsuType
                );
                let obj1 = {};
                obj1.processCode = processCode;
                obj1.deviceCode = deviceCode;
                obj1.deviceName = deviceName;
                obj1.detailCode = matchedDetailType?.code;
                obj1.detailName = matchedDetailType?.name;
                obj1.count = '';
                obj1.type = '';
                obj1.senchou = weld?.senchou + '/' + weld?.senchouKaisu;
                obj1['(空白)'] = '';
                sheet6Data[index][item.processName]?.push(obj1);
              } else {
                obj.detailCode = '';
                obj.detailName = '';
                obj.count = '';
                obj.type = '';
                obj.senchou = '';
                obj['(空白)'] = '';
                sheet6Data[index][item.processName]?.push(obj);
              }
            });
          } else {
            obj.detailCode = '';
            obj.detailName = '';
            obj.count = '';
            obj.type = '';
            obj.senchou = '';
            obj['(空白)'] = '';
            sheet6Data[index][item.processName]?.push(obj);
          }
          //shiageArr.push(obj);
        } else if (id === 21 || id === 22) {
          if (matchedProcessInput?.details !== undefined && matchedProcessInput?.details?.length > 0) {
            let numOfCuts = matchedProcessInput?.details?.[0]?.numOfCuts;
            obj.numOfCuts = numOfCuts;
          } else {
            obj.numOfCuts = '';
          }
          obj['(空白)'] = '';
          sheet6Data[index][item.processName]?.push(obj);
        } else if (id === 23 || id === 24 || id === 25 || id === 26 || id === 27) {
          if (matchedData?.multiprocesses !== undefined && matchedData?.multiprocesses?.length > 0) {
            let matchedDetailType = processMst?.details?.processDetailTypes?.find(
              (detailType) => detailType.id === matchedData?.multiprocesses?.[0]?.processDetailTypeId
            );
            obj.detailCode = matchedDetailType?.code;
            obj.detailName = matchedDetailType?.name;
            obj.count = matchedData?.multiprocesses?.length;
          } else {
            obj.detailCode = '';
            obj.detailName = '';
            obj.count = '';
          }
          obj['(空白)'] = '';
          sheet6Data[index][item.processName]?.push(obj);
        }
      } else {
        obj.processCode = '';
        obj.deviceCode = '';
        obj.deviceName = '';
        obj.detailCode = '';
        obj.detailName = '';
        if (id === 10) {
          obj.element = '';
          obj['(空白)'] = '';
          sheet6Data[index][item.processName]?.push(obj);
        } else if (id === 11 || id == 12 || id == 13) {
          obj.innerDia = '';
          obj.outerDia = '';
          obj.airFeedDistance = '';
          obj['(空白)'] = '';
          sheet6Data[index][item.processName]?.push(obj);
        } else if (id === 14) {
          let bending = [];

          obj.bendCount = '';
          obj.bendCountFR = '';
          obj['(空白)'] = '';
          //bending.push(obj);

          sheet6Data[index][item.processName]?.push(obj);
        } else if (id === 15) {
          obj.area = '';
          obj.weight = '';
          obj.outerLength = '';
          obj.innerLength = '';
          obj.count = '';
          obj['(空白)'] = '';
          sheet6Data[index][item.processName]?.push(obj);
        } else if (id === 16) {
          obj.area = '';
          obj.weight = '';
          obj.outerLength = '';
          (obj.outerCount = ''), (obj.innerLength = '');
          obj.innerCount = '';
          obj['(空白)'] = '';
          sheet6Data[index][item.processName]?.push(obj);
        } else if (id === 17) {
          let hyomenArr = [];

          obj.areaWeight = '';
          obj.count = '';
          obj['(空白)'] = '';
          hyomenArr.push(obj);

          sheet6Data[index][item.processName]?.push(obj);
        } else if (id === 18) {
          let nijiArr = [];
          obj.count = '';
          obj.x = '';
          obj.y = '';
          obj.perimeter = '';
          obj['(空白)'] = '';
          // nijiArr.push(obj);

          sheet6Data[index][item.processName]?.push(obj);
        } else if (id === 19) {
          let yosetsuArr = [];
          obj.count = '';
          obj.type = '';
          obj.senchou = '';
          obj['(空白)'] = '';
          //  yosetsuArr.push(obj);
          sheet6Data[index][item.processName]?.push(obj);
        } else if (id === 20) {
          let shiageArr = [];
          obj.detailCode = '';
          obj.detailName = '';
          obj.count = '';
          obj.type = '';
          obj.senchou = '';
          obj['(空白)'] = '';
          // shiageArr.push(obj);
          sheet6Data[index][item.processName]?.push(obj);
        } else if (id === 21 || id === 22) {
          obj.numOfCuts = '';
          obj['(空白)'] = '';

          sheet6Data[index][item.processName]?.push(obj);
        } else if (id === 23 || id === 24 || id === 25 || id === 26 || id === 27) {
          obj.count = '';
          obj['(空白)'] = '';

          sheet6Data[index][item.processName]?.push(obj);
        }
      }
    });
  });

  return sheet6Data;
};

function incrementExcelColumn(column) {
  let numericValue = 0;
  for (let i = 0; i < column?.length; i++) {
    numericValue = numericValue * 26 + (column.charCodeAt(i) - 64);
  }
  numericValue++;
  let incrementedColumn = '';
  while (numericValue > 0) {
    const remainder = (numericValue - 1) % 26;
    incrementedColumn = String.fromCharCode(65 + remainder) + incrementedColumn;
    numericValue = Math.floor((numericValue - 1) / 26);
  }
  return incrementedColumn;
}

function calculateRowCounts(data) {
  const rowCountsArray = [];
  let addition = {};
  data?.forEach((item) => {
    const rowCounts = {};
    let largestRowCount = 0;
    for (const key in item) {
      addition[key] = addition[key] == undefined ? 0 : addition[key];
      if (Array.isArray(item[key]) && item[key][0]?.processCode !== '') {
        rowCounts[key] = item[key]?.length;
        addition[key] += item[key]?.length;
        largestRowCount = Math.max(largestRowCount, item[key]?.length);
      } else {
        rowCounts[key] = 0;
      }
    }
    rowCounts.largestRowCount = largestRowCount;
    rowCountsArray.push(rowCounts);
  });
  rowCountsArray['addition'] = addition;
  return rowCountsArray;
}

// const XLSX = require('xlsx');

export const individualExcelDataSheets = [
  (html, selectedData, iq3Infos) => {
    return html;
  },
  //sheet1
  (html, selectedData, iq3Infos) => {
    let estimateProducts = selectedData.estimateProducts;
    //費用合計
    let costPrice = 0;
    let costPriceTotal = 0;
    let epCustomCosts = selectedData.epCustomCosts;
    for (let i = 0; i < epCustomCosts?.length; i++) {
      if (epCustomCosts[i].isUseUnit) {
        costPrice += Number(epCustomCosts[i].unitPrice);
      } else {
        costPrice += Number(epCustomCosts[i].totalPrice);
      }
      costPriceTotal += costPrice * estimateProducts.quantity;
    }
    let updateKouteiCalInfo = estimateProducts?.additions;
    let processPattern = estimateProducts?.processSelect;
    let setupCostTotalTime = 0;
    let workCostTotalTime = 0;
    if (updateKouteiCalInfo) {
      //工程パターンに利用しない工程を削除
      let updateKouteiInfos = [];
      updateKouteiCalInfo?.forEach((koutei) => {
        let updateKouteiInfo = {};
        updateKouteiInfo = processPattern?.details
          ? processPattern?.details?.filter(
              (item) =>
                item.processId === koutei.processId &&
                item.isUsedForCalc == true &&
                koutei.dataItems[0]?.processDetailTypeNo == item.multiprocesses[0]?.processDetailTypeId
            )
          : [];
        if (updateKouteiInfo?.length > 0) {
          updateKouteiInfos.push(koutei);
        }
      });

      // 計算した工程データの足し算
      updateKouteiInfos?.forEach((updatedKouteiContent) => {
        setupCostTotalTime +=
          updatedKouteiContent.dataType == DataType.Data
            ? updatedKouteiContent.totalDandori.dataItemsTime
            : updatedKouteiContent.totalDandori.editItemsTime;

        workCostTotalTime +=
          updatedKouteiContent.dataType == DataType.Data
            ? updatedKouteiContent.totalSagyo.dataItemsTime
            : updatedKouteiContent.totalSagyo.editItemsTime;
        // }
      });
    }

    const fixedFileExcelDataSheet1 = [
      { key: 'id', value: estimateProducts.id },
      { key: 'お客様ｺｰﾄﾞ', value: estimateProducts.id },
      { key: 'お客様', value: estimateProducts.clientName },
      { key: '図番', value: estimateProducts.drawingNo },
      { key: '名称', value: estimateProducts.name },
      { key: '見積番号', value: estimateProducts.estimateNo },
      { key: '数量', value: estimateProducts.quantity },
      { key: '作成者', value: estimateProducts.creater },
      { key: '作成日', value: estimateProducts.created },
      { key: '更新者', value: estimateProducts.modifier },
      { key: '更新日', value: estimateProducts.modified },
      { key: 'コメント', value: estimateProducts.comment },
      { key: '粗利益', value: estimateProducts.grossTotalPrice },
      { key: '粗利率', value: estimateProducts.grossRate },
      { key: '価格調整額', value: estimateProducts.otherProduct?.adjustment },
      { key: '受注非受注', value: estimateProducts.estimateStatus },
      { key: 'サイズX', value: estimateProducts.sizeX },
      { key: 'サイズY', value: estimateProducts.sizeY },
      { key: 'サイズZ', value: estimateProducts.sizeZ },
      { key: '重量', value: estimateProducts.totalWeight },
      { key: '表面積', value: estimateProducts.totalSurfaceArea },
      { key: '体積', value: estimateProducts.sizeX + estimateProducts.sizeY + estimateProducts.sizeZ },
      { key: '板金重量', value: estimateProducts.totalWeight },
      { key: '形鋼重量', value: '' },
      { key: '板金子部品種類', value: '' },
      { key: '形鋼子部品種類', value: '' },
      { key: '板金子部品製作数', value: iq3Infos?.length },
      { key: '形鋼子部品製作数', value: '' },
      { key: '製造原価総額', value: 1 * estimateProducts.quantity },
      { key: '製造原価', value: estimateProducts.manufacturingCostTotalPrice },
      { key: '材料費', value: estimateProducts.materialCostUnitPrice },
      { key: '作業合計費用', value: estimateProducts.setupCostTotalPrice + estimateProducts.workCostTotalPrice },
      { key: '段取金額', value1: estimateProducts.setupCostTotalPrice, value2: secondsToHms(setupCostTotalTime) },
      { key: '加工金額', value1: estimateProducts.workCostTotalPrice, value2: secondsToHms(workCostTotalTime) },
      { key: '購入品費', value: estimateProducts.purchaseTotalPrice },
      { key: 'その他の費用', value: costPriceTotal },
      { key: '見積総額', value: estimateProducts.estimateTotalPrice },
      { key: '見積製造費', value: estimateProducts.estimateUnitPrice },
      { key: '材料費', value: estimateProducts.materialCostUnitPrice * 0.0 },
      { key: '作業費合計', value: '' },
      { key: '段取金額', value: '' },
      { key: '加工金額', value: '' },
      { key: '購入品費', value: '' },
      { key: 'その他費用', value: '' },
      {
        key: '受注ﾀｲﾌﾟ',
        value1: estimateProducts?.coeffOrdersName,
        value2: estimateProducts?.otherProduct?.coeffOrders,
      },
      {
        key: '納期ﾀｲﾌﾟ',
        value1: estimateProducts.coeffDeliveryDatesName,
        value2: estimateProducts?.otherProduct?.coeffDeliveryDates,
      },
      {
        key: '購入品ﾀｲﾌﾟ',
        value1: estimateProducts?.coeffPurchasesName,
        value2: estimateProducts?.otherProduct?.coeffPurchases,
      },
      {
        key: '管理費ﾀｲﾌﾟ',
        value1: estimateProducts?.coeffManagementsName,
        value2: estimateProducts?.otherProduct?.coeffManagements,
      },
      {
        key: '■親部品工程index',
        value: '',
      },
      { key: '材料ﾛｽ係数', value1: '', value2: '' },
      { key: '費用', value: selectedData?.epCustomCosts?.length },
      {
        key: 'ラベル',
        value: selectedData?.epCustomLabels?.length,
      },
      { key: 'メモ', value: selectedData?.epCustomMemos?.length },
      {
        key: '日付',
        value: selectedData?.epCustomDates?.length,
      },

      {
        key: '■ｶｽﾀﾑﾌｨｰﾙﾄﾞ[費用]',
        value: '',
      },
      {
        key: '■ｶｽﾀﾑﾌｨｰﾙﾄﾞ[ラベル]',
        value: '',
      },
      {
        key: '■ｶｽﾀﾑﾌｨｰﾙﾄﾞ[メモ]',
        value: '',
      },
      {
        key: '■ｶｽﾀﾑﾌｨｰﾙﾄﾞ[日付]',
        value: '',
      },
    ];

    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    const table = tempDiv.querySelector('table');
    let initDisplayColumns = []; //getDisplayColumns();

    if (table) {
      const rows = table.querySelectorAll('tr');
      let tblData = createAdditionalItemsTbl(selectedData);
      let largestRowCount = 0;
      let newRows = [];
      for (const key in tblData) {
        if (tblData.hasOwnProperty(key)) {
          const array = tblData[key];
          if (Array.isArray(array) && array?.length > largestRowCount) {
            largestRowCount = array?.length;
          }
        }
      }

      rows?.forEach((row) => {
        let cells = row.querySelectorAll('td');

        cells?.forEach((cell, index) => {
          const cellContent = cell.textContent.trim();
          const column = initDisplayColumns?.find((col) => col.title === cellContent);

          if (column !== undefined) {
            let nextCell = cell.nextSibling;
            const columnData = extractColumnData(selectedData, column);
            let extractedVal;

            if (typeof columnData === 'object') {
              if (columnData.props?.children?.props !== undefined) {
                extractedVal = columnData.props?.children?.props?.children;
              } else {
                extractedVal = columnData.props?.children?.[1];
              }
            } else if (cellContent == 'ID') {
              extractedVal = selectedRowKeys;
            } else {
              extractedVal = columnData;
            }

            if (nextCell.textContent == '') {
              nextCell.textContent = extractedVal;
            }
          }
          let matchedItem = fixedFileExcelDataSheet1?.find((item) => item.key === cellContent);

          if (matchedItem) {
            let nextCell = cell.nextSibling;
            let dataCell;
            if (
              cellContent == '■ｶｽﾀﾑﾌｨｰﾙﾄﾞ[費用]' ||
              cellContent == '■ｶｽﾀﾑﾌｨｰﾙﾄﾞ[ラベル]' ||
              cellContent == '■ｶｽﾀﾑﾌｨｰﾙﾄﾞ[メモ]' ||
              cellContent == '■ｶｽﾀﾑﾌｨｰﾙﾄﾞ[日付]'
            ) {
              dataCell = cell.id;
              let modifiedHtml = createHtmlTable('', tblData[cellContent]);
              const parts = dataCell.match(/(.*?)(\d+)/);
              const originalText = parts[1];
              const numberPart = parts[2];
              const targetNumber = (parseInt(numberPart) + 2).toString();
              const targetCell = originalText + targetNumber;
              const tbody = table.querySelector('tbody');
              if (rows?.length > 0) {
                const lastRow = rows[rows?.length - 1];
                if (newRows?.length <= 0) {
                  for (let i = 0; i < largestRowCount; i++) {
                    const newRow = lastRow.cloneNode(true);
                    let lastRowTds = newRow.querySelectorAll('td');
                    lastRowTds?.forEach((td) => {
                      let idParts = td.id.match(/(.*?)(\d+)/);
                      let idOriginalText = idParts[1];
                      let idNumberPart = idParts[2];
                      let idTargetNumber = (parseInt(idNumberPart) + i + 1).toString();
                      let idTargetCell = idOriginalText + idTargetNumber;
                      td.id = idTargetCell;
                      td.innerHTML = '';
                      td.innerText = '';
                      td.setAttribute('data-v', '');
                    });
                    newRows.push(newRow);
                    tbody.appendChild(newRow);
                  }
                }

                const tempDiv = document.createElement('div');
                tempDiv.innerHTML = modifiedHtml;

                // Query for all <tr> elements within the HTML element
                const tableRows = tempDiv.querySelectorAll('tr');

                for (let j = 0; j < tableRows?.length; j++) {
                  if (newRows?.length > 0) {
                    let childrenWithIds = newRows[j]?.querySelectorAll('[id]');
                    const tableCols = tableRows[j]?.querySelectorAll('td');
                    for (let i = 0; i < tableCols?.length; i++) {
                      let childParts = childrenWithIds[0]?.id.match(/(.*?)(\d+)/);
                      let childNumberPart = childParts[2];

                      const lastTargetChar = originalText.slice(-1);
                      const newLastTargetChar = String.fromCharCode(lastTargetChar.charCodeAt(0) + i);
                      let targetOrgPart = originalText.slice(0, -1) + newLastTargetChar;

                      let attributeValue = targetOrgPart + childNumberPart;
                      childrenWithIds?.forEach((child) => {
                        if (child.id == attributeValue) {
                          child.setAttribute('data-v', tableCols[i]?.textContent);
                        }
                      });
                    }
                  }
                }
              }
            } else if (cellContent == '■親部品工程index') {
              let dataCellMst = cell.id;
              let processMasterOya = (estimateProducts?.calcParameters?.parameters?.process || [])?.filter(
                (e) => e.class === 0
              );
              let processMstTblData = [];
              processMasterOya?.forEach((e, index) => {
                let obj = {
                  no: index + 1,
                  class: '親部品',
                  additional: e?.isAdditional ? '追加' : '標準',
                  code: e?.code,
                  name: e?.name,
                  typeCount: e?.details?.processDetailTypes?.length,
                };
                processMstTblData.push(obj);
              });
              let processMstTbl = createHtmlTable('', processMstTblData);
              const processMstDiv = document.createElement('div');
              processMstDiv.innerHTML = processMstTbl;
              const parts = dataCellMst.match(/(.*?)(\d+)/);
              const originalText = parts[1];
              const numberPart = parts[2];

              const tableRows = processMstDiv.querySelectorAll('tr');
              for (let j = 0; j < tableRows?.length; j++) {
                let targetNumber = (parseInt(numberPart) + j + 2).toString();
                let targetId = originalText + targetNumber;
                let targetRowTDs = rows[j + 3].querySelectorAll('td');
                let target = Array.from(targetRowTDs)?.filter((cell) => cell.getAttribute('id') === targetId);
                let targetCell = target[0];
                const tableCols = tableRows[j].querySelectorAll('td');
                for (let i = 0; i < tableCols?.length; i++) {
                  targetCell.setAttribute('data-v', tableCols[i].textContent);
                  targetCell.setAttribute('data-t', 's');
                  targetCell = targetCell.nextSibling;
                }
              }
            }
            if (matchedItem.value) {
              nextCell.textContent = matchedItem.value;
            } else if (matchedItem.value1 && matchedItem.value2) {
              let coeffVal = nextCell.nextSibling;
              nextCell.textContent = matchedItem.value1;
              if (coeffVal.textContent === '時間') {
                let timeVal = coeffVal.nextSibling;
                timeVal.textContent = matchedItem.value2;
              } else {
                coeffVal.textContent = matchedItem.value2;
              }
            }
          }
        });
      });
    }
    return tempDiv.innerHTML;
  },
  //sheet2
  (html, selectedData, iq3Infos) => {
    let processData = createProcessDataTbl(selectedData);
    let largestRowCount = 0;
    let modifiedHtml = {};
    for (const key in processData) {
      if (processData.hasOwnProperty(key)) {
        if (parseInt(key) !== WorkType.PaCustom) {
          const dataArray = processData[key];
          modifiedHtml[key] = createHtmlTable('', dataArray);
          const array = processData[key];
          if (Array.isArray(array) && array?.length > largestRowCount) {
            largestRowCount = array?.length;
          }
        }
      }
    }
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    let table = tempDiv.querySelector('table');
    const orgRows = tempDiv.querySelectorAll('tr');
    const firstTwoRows = [orgRows[0], orgRows[1]];
    if (table) {
      table.parentNode?.removeChild(table);
    }

    const newTable = document.createElement('table');
    const tbody1 = document.createElement('tbody');

    firstTwoRows?.forEach((row) => tbody1.appendChild(row.cloneNode(true)));

    newTable.appendChild(tbody1);

    tempDiv.appendChild(newTable);

    const rows = tempDiv.querySelectorAll('tr');

    let newRows = [];
    if (rows?.length > 0) {
      const lastRow = rows[rows?.length - 1];
      if (newRows?.length <= 0) {
        for (let i = 0; i < largestRowCount; i++) {
          const newRow = lastRow.cloneNode(true);
          let lastRowTds = newRow.querySelectorAll('td');
          lastRowTds?.forEach((td) => {
            let idParts = td.id.match(/(.*?)(\d+)/);
            let idOriginalText = idParts[1];
            let idNumberPart = idParts[2];
            let idTargetNumber = (parseInt(idNumberPart) + i + 1).toString();
            let idTargetCell = idOriginalText + idTargetNumber;
            td.id = idTargetCell;
            td.innerHTML = '';
            td.innerText = '';
            td.setAttribute('data-v', '');
          });
          newRows.push(newRow);
          tbody1.appendChild(newRow);
        }
      }
      let gotoNextTbl = 0;
      for (const key in modifiedHtml) {
        if (modifiedHtml.hasOwnProperty(key)) {
          if (parseInt(key) !== WorkType.PaCustom) {
            let tempDivModi = document.createElement('div');
            tempDivModi.innerHTML = modifiedHtml[key];
            const tableRows = tempDivModi.querySelectorAll('tr');
            const tableColsHeader = tableRows[0]?.querySelectorAll('td');
            let headerRow = rows?.[1]?.querySelectorAll('[id]');

            for (let j = 0; j < tableRows?.length; j++) {
              let tableCols = tableRows[j]?.querySelectorAll('td');
              if (newRows?.length > 0) {
                let childrenWithIds = newRows[j]?.querySelectorAll('[id]');
                for (let i = 0; i < tableColsHeader?.length; i++) {
                  let childParts = childrenWithIds[0]?.id.match(/(.*?)(\d+)/);
                  let childNumberPart = parseInt(childParts[2]);
                  let partsSheet2 = headerRow[gotoNextTbl + i]?.id.match(/(.*?)(\d+)/);
                  let orgTxt = partsSheet2[1];
                  let attributeValue = orgTxt + childNumberPart;
                  childrenWithIds?.forEach((child) => {
                    if (child.id == attributeValue) {
                      child.setAttribute('data-v', tableCols[i]?.textContent);
                    }
                  });
                }
              }
            }
            gotoNextTbl += tableColsHeader?.length + 2;
          }
        }
      }
    }
    return tempDiv.innerHTML;
  },
  //sheet3
  (html, selectedData, iq3Infos) => {
    let processData = createProcessDataTbl2(selectedData);
    let largestRowCount = 0;
    let modifiedHtml = {};
    const columnHeaders = [
      'No.',
      '分類',
      '種別',
      '工程ｺｰﾄﾞ',
      '工程名',
      '工程ﾀｲﾌﾟ',
      'No.',
      '種類ｺｰﾄﾞ',
      '種類',
      '親部品数量',
      '個数(子部品点数)',
      '回数',
      '面積',
      '重量',
      '見積合計金額',
      '段取金額',
      '加工金額',
      '原価合計金額',
      '段取金額',
      '加工金額',
      '合計時間',
      '段取時間',
      '加工時間',
      '(空白)',
    ];

    let additionCount = 0;
    for (const key in processData) {
      if (processData.hasOwnProperty(key)) {
        if (key?.includes('Additional700')) {
          const dataArray = processData[key];
          modifiedHtml[key] = createHtmlTable(columnHeaders, dataArray);
          const array = processData[key];
          if (Array.isArray(array) && array?.length > largestRowCount) {
            largestRowCount = array?.length + 1;
          }
          additionCount++;
        }
      }
    }
    let mainTblColsCount = columnHeaders?.length * additionCount;
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    let table = tempDiv.querySelector('table');
    const orgRows = tempDiv.querySelectorAll('tr');
    const firstTwoRows = [orgRows[0]];
    if (table) {
      table.parentNode?.removeChild(table);
    }
    const newTable = document.createElement('table');
    const tbody1 = document.createElement('tbody');

    firstTwoRows?.forEach((row) => tbody1.appendChild(row.cloneNode(true)));

    newTable.appendChild(tbody1);

    tempDiv.appendChild(newTable);

    const rows = tempDiv.querySelectorAll('tr');

    let newRows = [];
    if (rows?.length > 0) {
      if (newRows?.length <= 0) {
        for (let i = 0; i < largestRowCount; i++) {
          const startCharCode = 'A'.charCodeAt(0);
          const newRow = document.createElement('tr');
          for (let j = 0; j < mainTblColsCount + 2; j++) {
            const newCell = document.createElement('td');
            const charCode = startCharCode + j;
            const char = String.fromCharCode(charCode);
            let count = i + 1;
            newCell.id = `sjs-${char}` + count;
            newCell.innerHTML = '';
            newCell.innerText = '';
            newCell.setAttribute('data-v', '');
            newRow.appendChild(newCell);
          }
          newRows.push(newRow);
          tbody1.appendChild(newRow);
        }
      }

      let gotoNextTbl = 0;
      for (const key in modifiedHtml) {
        if (modifiedHtml.hasOwnProperty(key)) {
          if (key?.includes('Additional700')) {
            let tempDivModi = document.createElement('div');
            tempDivModi.innerHTML = modifiedHtml[key];
            const tableRows = tempDivModi.querySelectorAll('tr');
            let tableCols1 = tableRows[0]?.querySelectorAll('th');
            let headerRow = newRows?.[0]?.querySelectorAll('[id]');

            for (let j = 0; j < tableRows?.length; j++) {
              let tableCols =
                tableRows[j]?.querySelectorAll('th')?.length == 0
                  ? tableRows[j]?.querySelectorAll('td')
                  : tableRows[j]?.querySelectorAll('th');
              if (newRows?.length > 0) {
                let childrenWithIds = newRows[j]?.querySelectorAll('[id]');
                for (let i = 0; i < tableCols?.length; i++) {
                  let childParts = childrenWithIds[0]?.id.match(/(.*?)(\d+)/);
                  let childNumberPart = parseInt(childParts[2]);
                  let partsSheet2 = headerRow[gotoNextTbl + i]?.id.match(/(.*?)(\d+)/);
                  let orgTxt = partsSheet2[1];
                  let attributeValue = orgTxt + childNumberPart;
                  childrenWithIds?.forEach((child) => {
                    if (child.id == attributeValue) {
                      child.setAttribute('data-v', tableCols[i]?.textContent);
                    }
                  });
                }
              }
            }
            gotoNextTbl += tableCols1?.length + 2;
          }
        }
      }
    }
    return tempDiv.innerHTML;
  },
  //sheet4
  (html, selectedData, iq3Infos) => {
    let childUsedProcess = getChildProcessCount(selectedData, iq3Infos);
    let sheet4BodyContent = childUsedProcess?.map(({ id, ...rest }) => rest);
    let dataSheet4Tbl = createHtmlTable('', sheet4BodyContent);

    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;

    let table = tempDiv.querySelector('table');
    const orgRows = tempDiv.querySelectorAll('tr');
    const firstTwoRows = [orgRows[0], orgRows[1]];

    if (table) {
      table.parentNode?.removeChild(table);
    }
    const modifiedHtml =
      '<table>' + firstTwoRows[0]?.outerHTML + firstTwoRows[1]?.outerHTML + dataSheet4Tbl + '<table>';
    return modifiedHtml;
  },
  //sheet5
  (html, selectedData, iq3Infos) => {
    let defaultProcessParam = ['段取時間', '加工時間', '段取金額', '加工金額', '(空白)'];
    let bariProcessParam = ['面積', '重量', '段取時間', '加工時間', '段取金額', '加工金額', '(空白)'];
    let processMasterData = selectedData?.estimateProducts?.calcParameters?.parameters?.process;

    let iq3Proccesses = [];
    processMasterData
      ?.filter((item) => item.class === 1)
      ?.map((item) => iq3Proccesses.push({ id: item.id, workType: item.workType, name: item.name }));

    let processParams = [];

    iq3Proccesses?.forEach((id) => {
      let matchType = kouteiMasterTypes?.find((type) => type.workType === id.workType);
      let relatedType = matchType ? matchType.typeName : null;

      if (id.id === 15 || id.id === 16) {
        processParams.push({
          id: id,
          param: bariProcessParam,
          name: id.name,
        });
      } else {
        processParams.push({
          id: id,
          param: defaultProcessParam,
          name: id.name,
        });
      }
    });

    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;

    let table = tempDiv.querySelector('table');
    const orgRows = tempDiv.querySelectorAll('tr');
    const firstTwoRows = [orgRows[0], orgRows[1]];
    const headerTd = orgRows[1]?.querySelectorAll('td');
    let lastHeaderTd;
    if (headerTd !== undefined && headerTd?.length > 0) {
      lastHeaderTd = headerTd[headerTd?.length - 1];
    }
    let lastParts;
    let lastLetters;
    let lastNumber;
    if (lastHeaderTd.id !== undefined) {
      lastParts = lastHeaderTd.id.split('-');
      // Extract BE and the number separately
      const secondPart = lastParts[1];
      const match = secondPart.match(/(\D+)(\d+)/);
      lastLetters = match[1]; // This will be "BE"
      lastNumber = match[2]; // This will be "2"
    }
    const uniqueNames = new Set();
    let childUsedProcess = getChildProcessCount(selectedData, iq3Infos, selectedData);
    const usedProcessList = processParams?.filter((process1) => {
      return childUsedProcess.some((process2) => {
        if (process2.processName === process1.name && !uniqueNames.has(process1.name)) {
          uniqueNames.add(process1.name);
          return true;
        }
        return false;
      });
    });

    if (orgRows?.length > 0) {
      usedProcessList?.forEach((usedProc) => {
        for (let i = 0; i < usedProc.param?.length; i++) {
          lastLetters = incrementExcelColumn(lastLetters);
          let param = usedProc.param;
          const newCellProcName = document.createElement('td');
          const newCell = document.createElement('td');

          newCellProcName.id = `sjs-${lastLetters}` + 1;
          if (i == 0) {
            newCellProcName.innerHTML = '[' + usedProc.name + ']';
            newCellProcName.innerText = '[' + usedProc.name + ']';
            newCellProcName.setAttribute('data-v', '[' + usedProc.name + ']');
          }

          newCell.id = `sjs-${lastLetters}` + lastNumber;
          newCell.innerHTML = param[i];
          newCell.innerText = param[i];
          newCell.setAttribute('data-v', param[i]);

          orgRows[0]?.appendChild(newCellProcName);
          orgRows[1]?.appendChild(newCell);
        }
      });
    }

    const sheet5Data = getSheet5Data(usedProcessList, iq3Infos, selectedData);
    let dataSheet5Tbl = createHtmlTable('', sheet5Data);

    const modifiedHtml = '<table>' + orgRows[0]?.outerHTML + orgRows[1]?.outerHTML + dataSheet5Tbl + '<table>';
    return modifiedHtml;
  },
  //sheet6

  (html, selectedData, iq3Infos) => {
    let tblHeaderList = [
      {
        id: 10,
        name: 'プログラム',
        param: ['工程ｺｰﾄﾞ', '装置ｺｰﾄﾞ', '装置名', '要素数', '(空白)'],
      },
      {
        id: 11,
        name: 'レーザー',
        param: ['工程ｺｰﾄﾞ', '装置ｺｰﾄﾞ', '装置名', '種類ｺｰﾄﾞ', '種類名', '外形周長', '内径周長', '空走距離', '(空白)'],
      },
      {
        id: 12,
        name: 'パンチ',
        param: ['工程ｺｰﾄﾞ', '装置ｺｰﾄﾞ', '装置名', '種類ｺｰﾄﾞ', '種類名', '外形周長', '内径周長', '空走距離', '(空白)'],
      },
      {
        id: 13,
        name: '複合機',
        param: ['工程ｺｰﾄﾞ', '装置ｺｰﾄﾞ', '装置名', '種類ｺｰﾄﾞ', '種類名', '外形周長', '内径周長', '空走距離', '(空白)'],
      },
      {
        id: 14,
        name: 'ベンディング',
        param: ['工程ｺｰﾄﾞ', '装置ｺｰﾄﾞ', '装置名', '種類ｺｰﾄﾞ', '種類名', '曲線数', 'FR曲回数', '(空白)'],
      },
      {
        id: 15,
        name: '自動バリ取',
        param: [
          '工程ｺｰﾄﾞ',
          '装置ｺｰﾄﾞ',
          '装置名',
          '種類ｺｰﾄﾞ',
          '種類名',
          '面積',
          '重量',
          '外形周長',
          '内径周長',
          '回数',
          '(空白)',
        ],
      },
      {
        id: 16,
        name: '手動バリ取',
        param: [
          '工程ｺｰﾄﾞ',
          '装置ｺｰﾄﾞ',
          '装置名',
          '種類ｺｰﾄﾞ',
          '種類名',
          '面積',
          '重量',
          '外形周長',
          '外形回数',
          '内径周長',
          '内径回数',
          '(空白)',
        ],
      },
      {
        id: 17,
        name: '表面処理',
        param: ['工程ｺｰﾄﾞ', '装置ｺｰﾄﾞ', '装置名', '種類ｺｰﾄﾞ', '種類名', '面積/重量', '回数', '(空白)'],
      },
      {
        id: 18,
        name: '2次加工',
        param: ['工程ｺｰﾄﾞ', '装置ｺｰﾄﾞ', '装置名', '種類ｺｰﾄﾞ', '種類名', 'X', 'Y', '周長', '個数', '(空白)'],
      },
      {
        id: 19,
        name: '溶接',
        param: ['工程ｺｰﾄﾞ', '装置ｺｰﾄﾞ', '装置名', '種類ｺｰﾄﾞ', '種類名', '種類/径', '線長/個数', '回数', '(空白)'],
      },
      {
        id: 20,
        name: '溶接仕上',
        param: ['工程ｺｰﾄﾞ', '装置ｺｰﾄﾞ', '装置名', '種類ｺｰﾄﾞ', '種類名', '種類/径', '線長/個数', '回数', '(空白)'],
      },
      {
        id: 21,
        name: 'シャーリング',
        param: ['工程ｺｰﾄﾞ', '装置ｺｰﾄﾞ', '装置名', '種類ｺｰﾄﾞ', '種類名', 'ｶｯﾄ数', '(空白)'],
      },
      {
        id: 22,
        name: '検査',
        param: ['工程ｺｰﾄﾞ', '装置ｺｰﾄﾞ', '装置名', '種類ｺｰﾄﾞ', '種類名', '検査数', '(空白)'],
      },
      {
        id: 23,
        name: '追加工程①',
        param: ['工程ｺｰﾄﾞ', '装置ｺｰﾄﾞ', '装置名', '種類ｺｰﾄﾞ', '種類名', '数', '(空白)'],
      },
      {
        id: 24,
        name: '追加工程②',
        param: ['工程ｺｰﾄﾞ', '装置ｺｰﾄﾞ', '装置名', '種類ｺｰﾄﾞ', '種類名', '数', '(空白)'],
      },
      {
        id: 25,
        name: '追加工程③',
        param: ['工程ｺｰﾄﾞ', '装置ｺｰﾄﾞ', '装置名', '種類ｺｰﾄﾞ', '種類名', '数', '(空白)'],
      },
      {
        id: 26,
        name: '追加工程④',
        param: ['工程ｺｰﾄﾞ', '装置ｺｰﾄﾞ', '装置名', '種類ｺｰﾄﾞ', '種類名', '数', '(空白)'],
      },
      {
        id: 27,
        name: '追加工程⑤',
        param: ['工程ｺｰﾄﾞ', '装置ｺｰﾄﾞ', '装置名', '種類ｺｰﾄﾞ', '種類名', '数', '(空白)'],
      },
    ];
    let childUsedProcess = getChildProcessCount(selectedData, iq3Infos);
    const sheet6Data = getSheet6Data(selectedData, iq3Infos, childUsedProcess);
    let modifiedHtml = [];
    sheet6Data?.forEach((data, index) => {
      modifiedHtml[index] = [];
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          const dataArray = data[key];
          modifiedHtml[index][key] = createHtmlTable('', dataArray);
        }
      }
    });

    let rowCountArr = calculateRowCounts(sheet6Data);

    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;

    let table = tempDiv.querySelector('table');
    let tbody = table.querySelector('tbody');
    const orgRows = tempDiv.querySelectorAll('tr');
    const firstTwoRows = [orgRows[0], orgRows[1]];
    const headerTd = orgRows[1]?.querySelectorAll('td');
    let lastHeaderTd;
    if (headerTd !== undefined && headerTd?.length > 0) {
      lastHeaderTd = headerTd[headerTd?.length - 1];
    }
    let lastParts;
    let lastLetters;
    let lastNumber;
    if (lastHeaderTd.id !== undefined) {
      lastParts = lastHeaderTd.id.split('-');
      // Extract BE and the number separately
      const secondPart = lastParts[1];
      const match = secondPart.match(/(\D+)(\d+)/);
      lastLetters = match[1]; // This will be "BE"
      lastNumber = match[2]; // This will be "2"
    }
    const uniqueNames = new Set();
    const usedProcessList = tblHeaderList?.filter((process1) => {
      return childUsedProcess.some((process2) => {
        if (process2.processName === process1.name && !uniqueNames.has(process1.name)) {
          uniqueNames.add(process1.name);
          return true;
        }
        return false;
      });
    });

    if (orgRows?.length > 0) {
      usedProcessList?.forEach((usedProc) => {
        for (let i = 0; i < usedProc.param?.length; i++) {
          lastLetters = incrementExcelColumn(lastLetters);
          let param = usedProc.param;
          const newCellProcName = document.createElement('td');
          const newCell = document.createElement('td');

          newCellProcName.id = `sjs-${lastLetters}` + 1;
          if (i == 0) {
            newCellProcName.innerHTML = usedProc.name + '   ' + rowCountArr.addition[usedProc.name];
            newCellProcName.innerText = usedProc.name + '   ' + rowCountArr.addition[usedProc.name];
            newCellProcName.setAttribute('data-v', usedProc.name + '   ' + rowCountArr.addition[usedProc.name]);
          }

          newCell.id = `sjs-${lastLetters}` + lastNumber;
          newCell.innerHTML = param[i];
          newCell.innerText = param[i];
          newCell.setAttribute('data-v', param[i]);

          orgRows[0]?.appendChild(newCellProcName);
          orgRows[1]?.appendChild(newCell);
        }
      });
    }

    const colCounts = orgRows[1]?.querySelectorAll('td')?.length;

    let rowCounts = 2;
    rowCountArr?.forEach((cnt) => {
      rowCounts += cnt.largestRowCount + 1;
    });
    let mainTblColsCount = colCounts * rowCounts;

    if (rowCounts > 2) {
      const originalRow = orgRows[1];

      for (let i = 2; i <= rowCounts; i++) {
        const clonedRow = originalRow.cloneNode(true);
        let cloneTds = clonedRow.querySelectorAll('td');
        cloneTds?.forEach((td) => {
          let id = td.id;
          let updatedId = id.replace(/\d+/, i + 1);
          td.id = updatedId;
          td.innerHTML = '';
          td.innerText = '';
          td.setAttribute('data-v', '');
        });
        tbody.appendChild(clonedRow);
      }

      let newRows = tbody.querySelectorAll('tr');
      let gotoNextPart = 2;
      modifiedHtml?.forEach((modi, index) => {
        let gotoNextTbl = 0;
        for (const key in modi) {
          if (modi.hasOwnProperty(key)) {
            let tempDivModi = document.createElement('div');
            tempDivModi.innerHTML = modi[key];
            const tableRows = tempDivModi.querySelectorAll('tr');
            let tableCols1 = tableRows[0]?.querySelectorAll('td');
            let headerRow = newRows?.[0]?.querySelectorAll('[id]');

            for (let j = 0; j < tableRows?.length; j++) {
              let tableCols =
                tableRows[j]?.querySelectorAll('th')?.length == 0
                  ? tableRows[j]?.querySelectorAll('td')
                  : tableRows[j]?.querySelectorAll('th');
              /*  if (newRows?.length > 0) { */
              let childrenWithIds = newRows[j + gotoNextPart]?.querySelectorAll('[id]');
              for (let i = 0; i < tableCols?.length; i++) {
                let childParts = childrenWithIds[0]?.id.match(/(.*?)(\d+)/);
                let childNumberPart = parseInt(childParts[2]);
                let partsSheet2 = headerRow[gotoNextTbl + i]?.id.match(/(.*?)(\d+)/);
                let orgTxt = partsSheet2[1];
                let attributeValue = orgTxt + childNumberPart;
                childrenWithIds?.forEach((child) => {
                  if (child.id == attributeValue) {
                    child.setAttribute('data-v', tableCols[i].textContent);
                    child.setAttribute('data-t', 's');
                    child.innerHTML = tableCols[i].textContent;
                    child.innerText = tableCols[i].textContent;
                  }
                });
              }
            }
            gotoNextTbl += tableCols1?.length;
          }
        }
        gotoNextPart += rowCountArr[index]?.largestRowCount;
      });
    }
    return tempDiv.innerHTML;
  },
  //sheet7
  (html, selectedData, iq3Infos) => {
    let purchaseLst = selectedData.estimateProducts.purchases;

    let iq3PurchaseLst = iq3Infos?.map((item) => item?.purchases);
    const flattenedArray = [];

    for (const innerArray of iq3PurchaseLst) {
      flattenedArray.push(...innerArray);
    }

    flattenedArray?.forEach((e) => {
      purchaseLst.push(e);
    });

    const purchaseColumns = [
      {
        id: '1',
        title: '種別',
        dataIndex: 'purchaseCategoryId',
        width: 180 + 'px',
        render: (_, record) =>
          selectedData?.estimateProducts?.purchases?.length >= 1 ? (
            <label style={{ fontSize: '13px' }}>{getNameByIdExcel(record.purchaseCategoryId, selectedData)}</label>
          ) : null,
      },
      {
        id: '2',
        title: '品名',
        dataIndex: 'name',
        width: 200 + 'px',
      },
      {
        id: '3',
        title: '型番',
        dataIndex: 'model',
        width: 120 + 'px',
      },
      {
        id: '4',
        title: '単価',
        dataIndex: 'unitPrice',
        width: 100 + 'px',
        className: 'cm-a-right',

        render: (text, record) => (
          <label
            style={{
              color: record.unitPriceByQuantity > 0 ? '#A5A5A5' : '#4A4747',
              display: 'block',
            }}
          >
            {text}
          </label>
        ),
      },

      {
        id: '5',
        title: '数量別単価',
        dataIndex: 'unitPriceByQuantity',
        width: 100 + 'px',
        className: 'cm-a-right',
        // className: (record) => (record.unitPriceByQuantity > 0 ? 'cm-a-right' : 'test cm-a-right'),

        render: (text, record) => (
          <label
            style={{
              color: record.unitPriceByQuantity > 0 ? '#4A4747' : '#A5A5A5',
              display: 'block',
            }}
          >
            {text}
          </label>
        ),
      },
      {
        id: '6',
        title: '必要数',
        dataIndex: 'required',
        width: 80 + 'px',
        className: 'cm-a-right',
      },
      {
        id: '7',
        title: '金額',
        dataIndex: 'price',
        width: 100 + 'px',
        className: 'cm-a-right',
      },

      {
        id: '8',
        title: '合計必要数',
        dataIndex: 'totalRequired',
        width: 120 + 'px',
      },
      {
        id: '9',
        title: '合計金額',
        dataIndex: 'totalPrice',
        width: 100 + 'px',
        className: 'cm-a-right',
      },
      {
        id: '10',
        title: '単価保持',
        dataIndex: 'isKeepPrice',
        width: 120 + 'px',
        render: (_, record) =>
          purchaseLst?.length >= 1 ? (
            <Checkbox style={{ marginLeft: '5px' }} disabled={true} checked={record.isKeepPrice} />
          ) : null,
      },
      {
        id: '11',
        title: '取付時間',
        dataIndex: 'processTime',
        width: 120 + 'px',
        render: (record) => (
          <div style={{ textAlign: 'right' }}>
            <label style={{ fontSize: '13px' }}>{secondsToHms(record)}</label>
          </div>
        ),
      },
      {
        id: '12',
        title: '合計取付時間',
        dataIndex: 'totalProcessTime',
        width: 120 + 'px',
        render: (record) => (
          <div style={{ textAlign: 'right' }}>
            <label style={{ fontSize: '13px' }}>{secondsToHms(record)}</label>
          </div>
        ),
      },
      {
        id: '13',
        title: '仕入先コード',
        dataIndex: 'supplierCode',
        width: 150 + 'px',
      },
      {
        id: '14',
        title: '仕入先名',
        dataIndex: 'supplierName',
        width: 150 + 'px',
      },
      {
        id: '15',
        title: 'メーカー',
        dataIndex: 'makerName',
        width: 150 + 'px',
      },

      {
        id: '16',
        title: '備考',
        dataIndex: 'remarks',
        width: 200 + 'px',
      },
    ];
    const tbHeaders = extractHeaderFromHtmlTable(html);
    const tblContents = extractDataByExcelColumns(purchaseLst, tbHeaders, purchaseColumns);
    tblContents?.forEach((row, index) => {
      row['No.'] = index + 1;
    });
    const modifiedHtml = createHtmlTable(tbHeaders, tblContents);

    html = modifiedHtml;
    return html;
  },
];

export async function convertServerExcelToHtml(excelData, selectedEstimateData, iq3Infos) {
  try {
    /* let excelData;
      if (isListReport) {
        excelData = selectedExeclListFormat;
      } else if (isIndividualReport) {
        excelData = selectedExeclIndividualFormat;
      } */

    const s3Stream = await getS3FileContent(excelData?.id);
    const workbook = XLSX.read(s3Stream, { type: 'array' });
    let excelName;
    const sheetPromises = workbook.SheetNames?.map(async (sheetName, index) => {
      const sheet = workbook.Sheets[sheetName];
      let html = XLSX.utils.sheet_to_html(sheet);

      // Add additional content for List Report
      /* if (isListReport) {
          excelName = selectedExeclListFormat.title + '.xlsx';
          const tbHeaders = extractHeaderFromHtmlTable(html);
          const tblContents = extractDataByExcelColumns(initialTblItems, tbHeaders);
          const modifiedHtml = createHtmlTable(tbHeaders, tblContents);
          html = modifiedHtml;
        }

        // Add additional content for Individual Report
        if (isIndividualReport) { */
      excelName = excelData.title + '.xlsx';
      html = individualExcelDataSheets[index](html, selectedEstimateData, iq3Infos);
      /*  } */
      return { sheetName, html };
    });
    const htmlContents = await Promise.all(sheetPromises);
    if (htmlContents?.length > 0) {
      handleExportToExcel(htmlContents, excelName);
    }
  } catch (error) {
    console.error('Error converting Excel to HTML:', error);
  }
}

//Excel File出力
export const handleExportToExcel = (contents, title) => {
  const wb = XLSX.utils.book_new();

  contents?.forEach(({ sheetName, html }) => {
    const tempContainer = document.createElement('div');
    tempContainer.innerHTML = html;

    const table = tempContainer.querySelector('table');

    if (table) {
      const ws = XLSX.utils.table_to_sheet(tempContainer);
      XLSX.utils.book_append_sheet(wb, ws, sheetName);
    }
  });

  XLSX.writeFile(wb, title);
};

// 材料詳細パターン
const materialDetailPatterns = [
  { value: PatternType.Pattern1, label: 'パターン1' },
  { value: PatternType.Pattern2, label: 'パターン2' },
];

// 材料詳細パターン名
export const getMaterialDetailPatternName = (patternNo) => {
  if (patternNo === undefined) return '';
  return materialDetailPatterns?.find((pattern) => pattern.value === patternNo)?.label;
};

export const scaleSet = (id, sheetX, sheetY, canvas = '') => {
  // const canvas = document.getElementById('canvas');
  let scale = 1;
  let x = canvas.width;
  let y = canvas.height;
  if (x < 0 || y < 0) return 0;
  let a = x / y; // CanvasのScale
  let b = sheetX / sheetY; // 材料サイズScale
  if (a > b) scale = y / sheetY;
  else scale = x / sheetX;
  return scale;
};

// Scale
let ca1Scale = 1;
let ca2Scale = 1;
export const draw = (partInfo, settingInfo, materialInfo, isPattern2, ySetting, rightFit, topFit, canvas = '') => {
  let partCntInfo = {
    partCntX: 1,
    partCntY: 1,
    p1NoOfMaterial: 0,
    p1NoOfPlace: 0,
    p2NoOfPlace: 0,
    maxNoOfPlace: 0,
    p1PartCntX: 0,
    p1PartCntY: 0,
    p2PartCntX: 0,
    p2PartCntY: 0,
  };
  if (partInfo.mPartX <= 0 || partInfo.mPartY <= 0) return;
  if (materialInfo.mSheetX < 0 || materialInfo.mSheetY < 0) return;

  if (canvas !== '') {
    //　指定したCanvasに配置できるScaleを計算
    ca1Scale = scaleSet(0, materialInfo.mSheetX, materialInfo.mSheetY, canvas);
    if (ca1Scale == 0) return;
  }

  //　利用可能な材料サイズ（材料ー掴み代）配置
  let materialX = materialInfo.mSheetX - settingInfo.mClampdzX;
  let materialY = materialInfo.mSheetY - settingInfo.mClampdzY;
  drawRect(0, { X: 0, Y: 0 }, { X: materialInfo.mSheetX, Y: materialInfo.mSheetY }, 0, canvas);

  // 掴み配置
  //mClampdzX
  let mClampdzXDrawInfo = drawRect(0, { X: 0, Y: 0 }, { X: settingInfo.mClampdzX, Y: materialInfo.mSheetY }, 2, canvas);
  // mClampdzY
  drawRect(
    0,
    { X: 0, Y: materialInfo.mSheetY - settingInfo.mClampdzY },
    { X: materialInfo.mSheetX, Y: settingInfo.mClampdzY },
    2,
    canvas
  );

  // 1つの製品を配置する時に使用する矩形サイズを計算する
  // 製品の矩形X
  let settingPartX = partInfo.mPartX + settingInfo.mMarginX;
  let settingPartY = partInfo.mPartY + settingInfo.mMarginY;
  // 材料に配置できる総数を確認する(X方向)
  let settingPartCntX = Math.floor(materialX / settingPartX);
  let clearSettingPartCntX = settingPartCntX;
  // 材料に配置できる総数を確認する(Y方向)
  let settingPartCntY = Math.floor(materialY / settingPartY);
  settingPartCntY = settingPartCntY <= 0 ? 1 : settingPartCntY;
  let clearSettingPartCntY = settingPartCntY;

  // 材料に配置できる最大数を計算する（パターン１に配置する部品数）
  let settingMaxCnt = Math.floor(settingPartCntX * settingPartCntY);
  partCntInfo.maxNoOfPlace = settingMaxCnt; // 最大加工数

  // パターン校数
  let n = Math.floor(partInfo.totalProcessSuryou / settingMaxCnt);
  //材料校数が1より小さい場合、１校数で配置できるXY方向を再計算
  if (n < 1) {
    // Y方向
    settingPartCntY = Math.floor(partInfo.totalProcessSuryou / settingPartCntX) + 1;
    clearSettingPartCntY = settingPartCntY;
    settingMaxCnt = partInfo.totalProcessSuryou;
    n = 1;
  }
  partCntInfo.p1NoOfMaterial = n; // パターン1の材料枚数
  partCntInfo.p1NoOfPlace = settingMaxCnt; // パターン1の配置数
  partCntInfo.p1PartCntX = settingPartCntX; // パターン1のX配置数
  partCntInfo.p1PartCntY = settingPartCntY; // パターン1のY配置数

  // パターン２の場合、最大部品数 － パターン１に配置する部品数
  if (isPattern2) {
    // Totalcount ＞ Maxcount *nかつ n＝1の場合、パターン1の配置が1枚とパターン2の配置が必要となる
    if (partInfo.totalProcessSuryou > settingMaxCnt * n && n == 1) {
      settingMaxCnt = partInfo.totalProcessSuryou - settingMaxCnt;
      if (!ySetting) {
        settingPartCntY = Math.round(settingMaxCnt / settingPartCntX);
        if (settingMaxCnt < settingPartCntX) {
          settingPartCntX = settingMaxCnt;
        }
      } else {
        if (settingMaxCnt < settingPartCntY) {
          settingPartCntY = settingMaxCnt;
        }
      }
    } else if (partInfo.totalProcessSuryou > settingMaxCnt * n && n > 1) {
      // Totalcount ＞ Maxcount *nかつ n≠1の場合、パターン1の配置がｎ枚とパターン2の配置が必要となる
      settingMaxCnt = partInfo.totalProcessSuryou - settingMaxCnt * n;
      if (!ySetting) {
        settingPartCntY = Math.round(settingMaxCnt / settingPartCntX);
        if (settingMaxCnt < settingPartCntX) {
          settingPartCntX = settingMaxCnt;
        }
      } else {
        if (settingMaxCnt < settingPartCntY) {
          settingPartCntY = settingMaxCnt;
        }
      }
    } else if (partInfo.totalProcessSuryou == settingMaxCnt * n && partInfo.totalProcessSuryou == n) {
      settingPartCntY = 0;
    } else if (partInfo.totalProcessSuryou <= settingMaxCnt * n && n == 1) {
      settingPartCntY = 0;
    }
    clearSettingPartCntY = settingPartCntY;
  }

  let rectStartX = mClampdzXDrawInfo.widthX;
  let rectStartY = (materialInfo.mSheetY - settingInfo.mClampdzY) * ca1Scale;
  rectStartY = rectStartY - partInfo.mPartY * ca1Scale;
  let drawRectInfo = {};

  if (settingPartCntX > 0 && settingPartCntY > 0) {
    // 部品合計数X
    partCntInfo.partCntX = settingPartCntX;
    partCntInfo.p2PartCntX = settingPartCntX; // パターン2のX配置数

    // パターン１の場合、パターン２とleftToRight設定の場合
    if (!isPattern2 || (!ySetting && isPattern2)) {
      // 部品矩形配置Y
      let fillCnt = settingPartCntX;
      for (let y = 0; y < settingPartCntY; y++) {
        rectStartX = mClampdzXDrawInfo.widthX;
        if (y > 0) {
          rectStartY = drawRectInfo.widthY - settingInfo.mMarginY * ca1Scale;
          if (fillCnt == settingMaxCnt) {
            settingPartCntX = settingPartCntX;
            fillCnt = fillCnt;
          } else if (fillCnt + settingPartCntX > settingMaxCnt) {
            settingPartCntX = settingMaxCnt - fillCnt;
            fillCnt = fillCnt + settingPartCntX;
          }
        }
        // 部品矩形配置X
        for (let x = 0; x < settingPartCntX; x++) {
          if (x > 0) {
            rectStartX = drawRectInfo.widthX + settingInfo.mMarginX * ca1Scale;
          }
          drawRectInfo = drawRect(
            0,
            { X: rectStartX, Y: rectStartY },
            { X: partInfo.mPartX, Y: partInfo.mPartY },
            1,
            canvas
          );
        }
        if (fillCnt != settingMaxCnt) {
          fillCnt = settingPartCntX * (y + 1);
        }
      }
    } else {
      // パターン２とBottomToTop設定の場合
      let fillCnt = settingPartCntY;
      for (let max = 0; fillCnt < settingMaxCnt || max == 0; max++) {
        // 部品矩形配置X
        if (max > 0) {
          rectStartX = drawRectInfo.widthX + settingInfo.mMarginX * ca1Scale;
          rectStartY = (materialInfo.mSheetY - settingInfo.mClampdzY) * ca1Scale;
          rectStartY = rectStartY - partInfo.mPartY * ca1Scale;
          if (fillCnt == settingMaxCnt) {
            settingPartCntY = settingPartCntY;
            fillCnt = fillCnt;
          } else if (fillCnt + settingPartCntY > settingMaxCnt) {
            settingPartCntY = settingMaxCnt - fillCnt;
            fillCnt = fillCnt + settingPartCntY;
          }
        }
        // 部品矩形配置Y
        for (let y = 0; y < settingPartCntY; y++) {
          if (y > 0) {
            rectStartY = drawRectInfo.widthY - settingInfo.mMarginY * ca1Scale;
          }
          drawRectInfo = drawRect(
            0,
            { X: rectStartX, Y: rectStartY },
            { X: partInfo.mPartX, Y: partInfo.mPartY },
            1,
            canvas
          );
        }

        if (fillCnt != settingMaxCnt) {
          fillCnt = settingPartCntY * (max + 1);
        }
        settingPartCntX = max + 1;
        partCntInfo.partCntX = settingPartCntX;
        partCntInfo.p2PartCntX = settingPartCntX; // パターン2のX配置数
        clearSettingPartCntX = settingPartCntX;
      }
    }
    // 部品合計数X
    partCntInfo.partCntY = settingPartCntY;
    partCntInfo.p2PartCntY = settingPartCntY; // パターン2のY配置数

    if (canvas != '') {
      //　材料のFit配置
      // const canvas = canvas;
      const canvasContext = canvas.getContext('2d');
      canvasContext.beginPath();
      // 左Fit配置
      if (rightFit) {
        let startClearX = settingInfo.mClampdzX * ca1Scale + settingPartX * clearSettingPartCntX * ca1Scale;
        let clearX = materialInfo.mSheetX * ca1Scale - startClearX;
        canvasContext.clearRect(startClearX, 0, clearX, canvas.height);
      }
      // 上Fit配置
      if (topFit) {
        let clearY = settingInfo.mClampdzY * ca1Scale + settingPartY * clearSettingPartCntY * ca1Scale;
        clearY = materialInfo.mSheetY * ca1Scale - clearY;
        canvasContext.clearRect(0, 0, canvas.width, clearY);
      }
    }
  } else {
    // 部品合計数X
    partCntInfo.partCntX = 0;
    partCntInfo.partCntY = 0;
  }
  partCntInfo.p2NoOfPlace = settingMaxCnt; // パターン2の配置数
  return partCntInfo;
};

export const drawRect = (id, pt, sz, type, canvas = '') => {
  let canvasContext;
  if (canvas !== '') {
    // const canvas = canvas;
    canvasContext = canvas.getContext('2d');
    canvasContext.beginPath();
  }
  let p1 = { X: 0, Y: 0 };
  let p2 = { X: 0, Y: 0 };
  // パターン１計算Scale
  let scale = ca1Scale;
  // パターン２計算Scale
  if (id == 1) scale = ca2Scale;

  // 開始XY
  p1.X = pt.X * scale;
  p1.Y = pt.Y * scale;
  let startX = type === 1 ? pt.X : p1.X;
  let startY = type === 1 ? pt.Y : p1.Y;
  // 幅XY
  p2.X = sz.X * scale;
  p2.Y = sz.Y * scale;
  let widthX = p2.X;
  let widthY = p2.Y;

  // 材料サイズ色
  if (type == 0) {
    const { backgroundColor = 'black' } = { backgroundColor: 'LightSteelBlue' };
    if (canvas !== '') canvasContext.fillStyle = backgroundColor;
  } else if (type == 1) {
    // 各部品矩形色
    const { backgroundColor = 'black' } = { backgroundColor: 'Gray' };
    if (canvas !== '') canvasContext.fillStyle = backgroundColor;
  } else {
    // 掴み代色
    const { backgroundColor = 'black' } = { backgroundColor: 'LightGray' };
    if (canvas !== '') canvasContext.fillStyle = backgroundColor;
  }

  if (canvas !== '') canvasContext.fillRect(startX, startY, widthX, widthY);
  let drawWidthHeight = {
    widthX: startX + widthX,
    widthY: startY - widthY,
  };
  return drawWidthHeight;
};

export const formatPMXDate = (record) => {
  const date = new Date(record);
  const formattedDate = `${date.getFullYear()}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date
    .getDate()
    .toString()
    .padStart(2, '0')}`;
  const formattedTime = `${date.getHours().toString().padStart(2, '0')}:${date
    .getMinutes()
    .toString()
    .padStart(2, '0')}`;
  const updDate = formattedDate + ' ' + formattedTime;
  return updDate;
};

// 板金見積の工程入力データ作成
export const getDetails = (workType, param) => {
  // let parameters = props.selectedEstimateData?.estimateProducts?.calcParameters?.parameters;
  let parameters = param;

  let subWeldingType = 0;
  let subWeldingGroupType = 0;
  let secondaryWorkProcessDetailsId = 1;
  let secondaryWorkProcessDetailsGroup = 10310;
  const weldingDetails = parameters?.process?.filter((i) => i.workType === WorkType.SmWelding);
  const secondaryWorkDetails = parameters?.process?.filter((i) => i.workType === WorkType.SmSecondaryWork);
  if (secondaryWorkDetails?.length > 0) {
    secondaryWorkProcessDetailsId = secondaryWorkDetails[0]?.details?.processDetailTypes[0]?.id;
    secondaryWorkProcessDetailsGroup = secondaryWorkDetails[0]?.details?.processDetailTypes[0]?.group;
  }
  if (weldingDetails?.length > 0) {
    let subYousetsu = weldingDetails[0]?.details?.processDetailTypes?.filter(
      (i) => i.group === WorkTypeGroup.SmInstSize || i.group === WorkTypeGroup.SmInstThick
    );
    if (subYousetsu?.length > 0) {
      subWeldingType = subYousetsu[0]?.id;
      subWeldingGroupType = subYousetsu[0]?.group;
    }
  }
  if (workType === WorkType.SmProgram) {
    // return { elements: 0, elementsAuto: 0 };
    return [{ elements: 0, elementsAuto: 0 }];
  } else if (
    workType === WorkType.SmBlankCombi ||
    workType === WorkType.SmBlankLaser ||
    workType === WorkType.SmBlankPunch
  ) {
    return [
      {
        dimensionData: {
          outerDia: 0,
          outerAutoExtract: 0,
          innerDia: 0,
          innerAutoExtract: 0,
          airFeedDistance: 0,
          airFeedAutoExtract: 0,
        },
        quantityList: [{ id: 1, types: 1, diameter: 0, xsize: 0, ysize: 0, perimeter: 0, count: 1 }],
      },
    ];
  } else if (workType === WorkType.SmSecondaryWork) {
    return [
      {
        id: 1,
        types: secondaryWorkProcessDetailsId,
        diameter: 0,
        xsize: 0,
        ysize: 0,
        perimeter: 0,
        count: 1,
        blankFlag: false,
        workTypeGroup: secondaryWorkProcessDetailsGroup,
      },
    ];
  } else if (workType === WorkType.SmBending) {
    return [
      {
        bendingData: { maxBendLength: 0, maxBendAutoExtract: 0 },
        bendingList: [
          {
            id: 1,
            types: 1,
            bendLength: 0,
            lines: 1,
            bendCount: 1,
          },
        ],
      },
    ];
  } else if (workType === WorkType.SmDeburringAuto) {
    return [
      {
        id: 1,
        types: '自動',
        area: 0,
        areaAutoExtract: 0,
        weight: 0,
        weightAutoExtract: 0,
        count: 1,
      },
    ];
  } else if (workType === WorkType.SmDeburringManual) {
    return [
      { id: 1, types: '手動', target: '外形', length: 0, autoExtract: 0, count: 1 },
      { id: 2, types: '手動', target: '内形', length: 0, autoExtract: 0, count: 1 },
    ];
  } else if (workType === WorkType.SmWelding) {
    return [
      {
        weldingList: [
          {
            id: 1,
            yousetsuType: 1,
            senchou: 0,
            senchouKaisu: 0,
            senchouYousetsushiage: 0,
            total1: 0,

            karitsukeCnt: 0,
            karitsukeKaisu: 0,
            karitsukeYousetsushiage: 0,
            total2: 0,
          },
        ],
        subWeldingList: [
          {
            id: 1,
            subWeldingType: subWeldingType,
            daimeter: 1,
            number: 0,
            count: 0,
            yousetsushiage: 1,
            total: 0,
            subWeldingGroupType: subWeldingGroupType,
          },
        ],
      },
    ];
  } else if (workType === WorkType.SmSurface) {
    return [
      {
        no: 1,
        calcType: 1,
        workType: 300,
        areaCount: 0,
        totalArea: 0,
        totalWeight: 0,
        weightCount: 0,
        areaUnitPrice: 0,
        areaAutoExtract: 0,
        weightUnitPrice: 0,
        weightAutoExtract: 0,
        processDetailTypeNo: 0,
        processDetailWorkTypeGroup: 0,
      },
    ];
  } else if (workType === WorkType.SmShearing || workType === WorkType.SmInspection) {
    return [{ numOfCuts: 1 }];
  } else {
    return []; // Default empty details for other deviceId values
  }
};
