/**
 * クラス名：ホーム画面
 * 説明：ログイン後ホーム画面である。
 * 作成者：ナンス
 * 作成日：2023/03/01
 * バージョン：1.0
 */
import { Layout, Image, Button, Row, Col, Space, Input } from 'antd';
import React, { useState, useEffect, useRef } from 'react';
import { Table as RTable } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import Header from './Header';
import iQ3_create from '../assets/images/iQ3_create.png';
import iQ5_create from '../assets/images/iQ5_create.png';
import iQ7_create from '../assets/images/iQ7_create.png';
import logout from '../assets/images/logout.png';
import parameter from '../assets/images/parameter.png';
import oya_list from '../assets/images/oya_list.png';
import ParentList from '../views/parent/ParentList';
import MainParameter from '../views/parameter/MainParameter';
import MainEstimate from '../views/common/MainEstimate';
import new_icon from '../assets/images/new_icon.png';
import {
  confirmScreenChangeContent,
  confirmScreenChangeTitle,
  commonModal,
  commonFooter,
  confirmDisplayDataSaveContent,
  confirmScreenChangeWarning,
} from '../views/common/CommonModal';
import {
  todayTime,
  formatDateString,
  isCustomer,
  toDecimal,
  estimateInitInfo,
  getTenjikaiSampleData,
  initAdditionalItemCost,
  initAdditionalItemLabel,
  initAdditionalItemMemo,
  initAdditionalItemDate,
  updateEstimateInfoByReCal,
  iq3EstimateInitInfo,
} from '../views/common/Common';
import {
  logOutSubmit,
  createEstimateProductInfo,
  updateEstimateProductInfo,
  GetParametersByCompId,
  getExistUserSetting,
} from '../views/common/CommonAPI';
import UserSetting from '../views/setting/UserSetting';
import { ClientType, ParamCalType, ServiceClass, initDispRoutes, initShowDisps } from '../views/common/enums';
import { byQuantities } from '../views/common/Constant';

const { Content, Sider } = Layout;

const MainLayout = React.forwardRef((props, ref) => {
  const [isMenuClick, setIsMenuClick] = useState(false);
  const [paramType, setParamType] = useState('');
  const [paramSelected, setParamSelected] = useState('');
  const [partNm, setPartNm] = useState('parentList');
  const [selectedEstimateData, setSelectedEstimateData] = useState([]);
  // 画面切替警告メッセージポップアップ
  const [editModeCancelConfirm, setEditModeCancelConfirm] = useState(false);
  const [saveDiscardConfirmPopup, setSaveDiscardConfirmPopup] = useState(false);
  const [navigatePageName, setNavigatePageName] = useState('');
  // 切替する画面の情報
  const [event, setEvent] = useState('');
  const [pageName, setPageName] = useState('');
  const [partName, setPartName] = useState('');
  const [routeType, setRouteType] = useState('');
  const [estimateEditMode, setEstimateEditMode] = useState(false);
  const [parentListClick, setParentListClick] = useState(false);
  const [confirmDisplayDataSaveModalOpen, setConfirmDisplayDataSaveModalOpen] = useState(false);
  const [curPartNm, setCurPartNm] = useState([]);
  const [clientInfo, setClientInfo] = useState([]);
  const [orderOptions, setOrderOptions] = useState([]);
  const [companyInfo, setCompanyInfo] = useState([]);
  const [staffList, setStaffList] = useState([]);
  const [userInfo, setUserInfo] = useState({});
  const [processInfo, setProcessInfo] = useState([]);
  const [companySettingInfo, setCompanySettingInfo] = useState([]);
  const [userSettingInfo, setUserSettingInfo] = useState([]);
  const [purchaseCategoryInfo, setPurchaseCategoryInfo] = useState([]);
  const [newMode, setNewMode] = useState(false);
  const [initDisplayRun, setInitDisplayRun] = useState(false);

  const paramRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const mainEstimateRef = useRef();
  const parentListRef = useRef();
  const menuContent = useRef(null);

  const tenjikaiSampleData = getTenjikaiSampleData();

  // 別名保存の入力項目
  const [drawingNo, setDrawingNo] = useState('');
  const [parentEstimateName, setParentEstimateName] = useState('');
  const [isSaveByOtherName, setIsSaveByOtherName] = useState(false);

  // 活性、非活性タブ
  const [activeTab, setActiveTab] = useState('');

  const [switchExecuted, setSwitchExecuted] = useState(false);

  const [isFromLogin, setIsFromLogin] = useState(false);

  const fromLogin = location?.state?.fromLogin;

  //本JSの初期関数
  useEffect(() => {
    getBackendPrepareData([]);
  }, []);

  useEffect(() => {
    setIsFromLogin(fromLogin);
    if (fromLogin) {
      initDisplay();
    }
  }, [fromLogin]);

  const initDisplay = () => {
    switch (location.pathname) {
      case initDispRoutes[0]:
        setInitDisplayRun(true);
        navigate(location.pathname);
        return (
          <ParentList
            ref={parentListRef}
            updateEstimateEditMode={updateEstimateEditMode}
            getSelectedEstimateData={getSelectedEstimateData}
            editMode={estimateEditMode}
            parentListClick={parentListClick}
            orderOptions={orderOptions}
            companyInfo={companyInfo}
            clientInfo={clientInfo}
            userInfo={userInfo}
            staffInfo={staffList}
            processInfo={processInfo}
            companySettingInfo={companySettingInfo}
            purchaseCategoryInfo={purchaseCategoryInfo}
          />
        );
      case initDispRoutes[1]:
        setActiveTab('1');
        navigatePage('', ':estimate.parent', 'parent');
        break;
      case initDispRoutes[2]:
        setActiveTab('2');
        navigatePage('', ':estimate.iq3', 'iq3');
        break;

      default:
        return (
          <MainParameter
            paramType={paramType}
            paramNm={paramSelected}
            updateParamSelected={updateParamSelected}
            companySettingInfo={companySettingInfo}
            userSettingInfo={userSettingInfo}
            ref={paramRef}
          />
        );
    }
  };

  const getBackendPrepareData = async (currentEstimateData) => {
    let updateEstimateData = currentEstimateData;
    let newParameters = await GetParametersByCompId();
    if (newParameters) {
      getProjectStatusData(newParameters.estimateStatus);
      getLoginCompanyInfo(newParameters.company);
      getStaffListInfo(newParameters.staff);
      getItakusakiInfo(newParameters.client);
      getLoginUserInfo(newParameters.currentUserInfo);
      getProcessMstInfo(newParameters.process);
      getCompanySettingInfo(newParameters.companySettings);
      getUserSettingInfo(newParameters.userSettings);
      getPurchaseCategoryInfo(newParameters.purchaseCategory);
      if (updateEstimateData != undefined && updateEstimateData?.estimateProducts) {
        updateEstimateData.estimateProducts.calcParameters.parameters.company = newParameters.company;
        updateEstimateData.estimateProducts.calcParameters.parameters.staff = newParameters.staff;
        updateEstimateData.estimateProducts.calcParameters.parameters.client = newParameters.client;
        updateEstimateData.estimateProducts.calcParameters.parameters.estimateStatus = newParameters.estimateStatus;
        updateEstimateData.estimateProducts.calcParameters.parameters.process = newParameters.process;
        updateEstimateData.estimateProducts.calcParameters.parameters.device = newParameters.device;
        let processSelect = newParameters.processPattern?.filter(
          (item) => item.id === updateEstimateData.estimateProducts.processSelect[0]?.id
        );
        if (processSelect?.length > 0) updateEstimateData.estimateProducts.processSelect = processSelect[0];
        updateEstimateData.estimateProducts.calcParameters.parameters.purchase = newParameters.purchase;
        updateEstimateData.estimateProducts.calcParameters.parameters.purchaseCategory = newParameters.purchaseCategory;
        updateEstimateData.estimateProducts.calcParameters.parameters.purchaseAllocation =
          newParameters.purchaseAllocation;
        updateEstimateData.estimateProducts.calcParameters.settings.userSettings = newParameters.userSettings;
        updateEstimateData.estimateProducts.calcParameters.settings.companySettings = newParameters.companySettings;
        //板金パラメータ情報
        updateEstimateData.estimateProducts.calcParameters.parameters.materialIq3 = newParameters.materialIq3;
        updateEstimateData.estimateProducts.calcParameters.parameters.materialSizeIq3 = newParameters.materialSizeIq3;
        updateEstimateData.estimateProducts.calcParameters.parameters.materialSurfaceIq3 =
          newParameters.materialSurfaceIq3;
        updateEstimateData.estimateProducts.calcParameters.parameters.materialType = newParameters.materialType;
        updateEstimateData.estimateProducts.calcParameters.parameters.materialTypeIq3 = newParameters.materialTypeIq3;
        setSelectedEstimateData(updateEstimateData);
        let calEstimateData = updateEstimateInfoByReCal(updateEstimateData);
        //親部品の基本情報更新
        mainEstimateRef?.current
          ?.getParentEstimateRef()
          ?.current?.getParentEstimateDetailRef()
          ?.current?.updateParentEstimateDetail(calEstimateData);
        mainEstimateRef?.current?.updateEstimateInfo(calEstimateData);
        setSelectedEstimateData(calEstimateData);
      } else {
        setPartNm('parentList');
        setParentListClick(true);
      }
    }
  };

  const settingParametersByReCal = (mode) => {
    let updateEstimateData = mainEstimateRef?.current?.getParentEstimateData();
    if (updateEstimateData != undefined && updateEstimateData?.estimateProducts?.calcParameters) {
      if (mode == ParamCalType.NewParam) {
        getBackendPrepareData(updateEstimateData);
      } else {
        let oldParameters = updateEstimateData?.estimateProducts?.calcParameters?.parameters;
        if (oldParameters) {
          getProjectStatusData(oldParameters.estimateStatus);
          getLoginCompanyInfo(oldParameters.company);
          getStaffListInfo(oldParameters.staff);
          getItakusakiInfo(oldParameters.client);
          getProcessMstInfo(oldParameters.process);
          getCompanySettingInfo(oldParameters.settings?.companySettings);
          getUserSettingInfo(oldParameters.settings?.userSetting);
          getPurchaseCategoryInfo(oldParameters.purchaseCategory);
          if (updateEstimateData) {
            updateEstimateData.estimateProducts.calcParameters.parameters.company = oldParameters.company;
            updateEstimateData.estimateProducts.calcParameters.parameters.staff = oldParameters.staff;
            updateEstimateData.estimateProducts.calcParameters.parameters.client = oldParameters.client;
            updateEstimateData.estimateProducts.calcParameters.parameters.estimateStatus = oldParameters.estimateStatus;
            updateEstimateData.estimateProducts.calcParameters.parameters.process = oldParameters.process;
            updateEstimateData.estimateProducts.calcParameters.parameters.device = oldParameters.device;
            let processSelect = oldParameters.processPattern?.filter(
              (item) => item.id === updateEstimateData.estimateProducts.processSelect[0]?.id
            );
            if (processSelect?.length > 0) updateEstimateData.estimateProducts.processSelect = processSelect[0];
            updateEstimateData.estimateProducts.calcParameters.parameters.purchase = oldParameters.purchase;
            updateEstimateData.estimateProducts.calcParameters.parameters.purchaseCategory =
              oldParameters.purchaseCategory;
            updateEstimateData.estimateProducts.calcParameters.parameters.purchaseAllocation =
              oldParameters.purchaseAllocation;
            updateEstimateData.estimateProducts.calcParameters.settings.userSettings =
              oldParameters.settings?.userSettings;
            updateEstimateData.estimateProducts.calcParameters.settings.companySettings =
              oldParameters.settings?.companySettings;
            //板金パラメータ情報
            updateEstimateData.estimateProducts.calcParameters.parameters.materialIq3 = oldParameters.materialIq3;
            updateEstimateData.estimateProducts.calcParameters.parameters.materialSizeIq3 =
              oldParameters.materialSizeIq3;
            updateEstimateData.estimateProducts.calcParameters.parameters.materialSurfaceIq3 =
              oldParameters.materialSurfaceIq3;
            updateEstimateData.estimateProducts.calcParameters.parameters.materialType = oldParameters.materialType;
            updateEstimateData.estimateProducts.calcParameters.parameters.materialTypeIq3 =
              oldParameters.materialTypeIq3;
            let calEstimateData = updateEstimateInfoByReCal(updateEstimateData);
            //親部品の基本情報更新
            mainEstimateRef?.current
              ?.getParentEstimateRef()
              ?.current?.getParentEstimateDetailRef()
              ?.current?.updateParentEstimateDetail(calEstimateData);
            mainEstimateRef?.current?.updateEstimateInfo(calEstimateData);
            setSelectedEstimateData(calEstimateData);
          }
        }
      }
    }
  };

  const getItakusakiInfo = (result) => {
    result = result?.filter((record) => isCustomer(record.clientType) && record.info.isUsed);
    if (result) setClientInfo(result?.length > 0 ? result : []);
  };

  // 案件状態データ
  const getProjectStatusData = (result) => {
    setOrderOptions(result?.filter((item) => item.info.isUsed));
  };

  // ログインしているユーザーの会社情報を取得する
  const getLoginCompanyInfo = (result) => {
    setCompanyInfo(result);
  };

  // 担当者情報を取得する
  const getStaffListInfo = (result) => {
    setStaffList(result?.filter((item) => item.info.isUsed));
  };

  // ユーザー情報を取得する
  const getLoginUserInfo = (result) => {
    setUserInfo(result);
  };

  // 工程マスタの情報を取得する
  const getProcessMstInfo = (result) => {
    setProcessInfo(result);
  };

  const getCompanySettingInfo = (result) => {
    setCompanySettingInfo(result);
  };

  const getUserSettingInfo = async (result) => {
    if (result === '') {
      setUserSettingInfo(await getExistUserSetting());
    } else {
      setUserSettingInfo(result);
    }
  };

  const getPurchaseCategoryInfo = (result) => {
    setPurchaseCategoryInfo(result);
  };

  const updateParamSelected = (param) => {
    setParamType(param);
    let textMenu;
    switch (param) {
      case '共通':
        textMenu = '・共通';
        break;
      case 'parent':
        textMenu = '・親部品';
        break;
      case 'iq3':
        textMenu = '・iQ3';
        break;
    }
    if (param === '共通') {
      setParamType('common');
    }
    let menus = menuContent?.current?.menu?.list?.children;
    let ul = menuContent?.current?.menu?.list;
    if (ul.classList.contains('paramMenu')) {
      ul.classList?.remove('paramMenu');
      ul.classList.add('paramMenu-active');
    }
    [...menus]?.forEach((menu) => {
      if (menu.innerText?.includes(textMenu)) {
        menu.classList.add('ant-menu-submenu-active');
        menu.classList.add('ant-menu-submenu-selected');
      } else {
        menu.classList?.remove('ant-menu-submenu-active');
        menu.classList?.remove('ant-menu-submenu-selected');
      }
    });
  };

  //　ログアウトボタンの押下
  const handleClick = async (e) => {
    e.preventDefault();
    const result = await logOutSubmit();
    if (result) {
      navigate('/login');
    }
  };

  // 画面切り替えする
  const discardConfirmOk = () => {
    setEstimateEditMode(false);
    setEditModeCancelConfirm(false);
    if (routeType === 1) {
      navigatePage(event, pageName, partName);
    } else if (routeType === 2) {
      paramChangeEvent(event);
    } else {
      handleClick(event);
    }
  };

  // 画面切り替えしない
  const discardConfirmCancel = () => {
    setEditModeCancelConfirm(false);
  };

  // 編集中の場合、画面切替警告メッセージポップアップを表示
  const isEditingMode = (e, type, page, part) => {
    setEvent(e);
    setRouteType(type);
    setPageName(page);
    setPartName(part);
    if (estimateEditMode || paramRef?.current?.getCurrentEditMode()) {
      // alert('Hi');
      // setEditModeCancelConfirm(true);
      let edtModeCancel =
        userSettingInfo[0]?.massages?.moveInEdit === undefined ? true : userSettingInfo[0]?.massages?.moveInEdit;
      setEditModeCancelConfirm(edtModeCancel);
      if (!edtModeCancel) {
        return false;
      }
      return true;
    }
    return false;
  };

  // 見積画面から、画面切り替えの場合
  const changeScreenEvent = (e, page) => {
    getUserSettingInfo('');
    if (location.pathname === '/home/:estimate.parent' || location.pathname === '/home/:estimate.iq3') {
      if (selectedEstimateData?.estimateProducts?.id === 0) {
        let validation = mainEstimateRef?.current?.callFormValidation();
        if (!validation) return;
        setNavigatePageName(page);
        setSaveDiscardConfirmPopup(true);

        let parentEstimateData = mainEstimateRef?.current?.getParentEstimateData();
        setSelectedEstimateData(parentEstimateData);
      } else {
        setNavigatePageName(page);
        setSaveDiscardConfirmPopup(true);

        let parentEstimateData = mainEstimateRef?.current?.getParentEstimateData();
        setSelectedEstimateData(parentEstimateData);
      }
      // } else if (location.pathname === '/home/:estimate.iq3') {
      //   setNavigatePageName(page);
      //   setSaveDiscardConfirmPopup(true);
    } else {
      if (page === 'parentList') {
        setActiveTab('1');
        navigatePage(e, ':parentList', 'parentList');
      } else if (page === 'parent') {
        setActiveTab('1');
        navigatePage(e, ':estimate.parent', 'parent');
      } else if (page === 'sheetMetal') {
        setActiveTab('2');
        navigatePage(e, ':estimate.iq3', 'iq3');
      }
    }
  };

  const paramChangeEvent = (e) => {
    setParamSelected('customer');
    getUserSettingInfo('');
    navigate(':common.customer');
    setIsMenuClick(true);
  };

  const navigatePage = async (e, pageNm, partNm) => {
    setNewMode(true);
    setIsMenuClick(false);
    setParamType('');
    setParamSelected('');
    if (partNm === 'parent' || partNm === 'iq3') {
      setCurPartNm(partNm);
      goToCreateParentScreen(pageNm, partNm);
    } else if (partNm === 'parentList') {
      await getBackendPrepareData();
      setPartNm(partNm);
      navigate(pageNm);
    }
  };

  // サイドメニューの親部品作成ボタン押下で作成画面に遷移する
  const goToCreateParentScreen = async (pageNm, partNm) => {
    let newParameters = await GetParametersByCompId();
    let newParentInfo = getNewParentInfo(partNm, newParameters);
    setSelectedEstimateData(newParentInfo);
    setPartNm(curPartNm);
    navigate(pageNm);
    setNewMode(true);
  };

  const discardGoToCreateParentScreen = () => {
    setConfirmDisplayDataSaveModalOpen(false);
  };

  const updateNewMode = (mode) => {
    setNewMode(mode);
  };

  const getSelectedEstimateData = (estimateData, iq3RowKey, estimateEditMode) => {
    setSelectedEstimateData(estimateData);
    setActiveTab('1');
    setEstimateEditMode(estimateEditMode);
  };

  const updateEstimateEditMode = (mode) => {
    setEstimateEditMode(mode);
  };

  const updateSelectedEstimateData = (result) => {
    setSelectedEstimateData(result);
  };

  // 親部品見積新規作成の初期値を作成する
  const getNewParentInfo = (partNm, newParameters) => {
    let orderCoeffs = [];
    let deliDateCoeffs = [];
    let purCoeffs = [];
    let mngCoeffs = [];
    let costLst = [];
    let labelLst = [];
    let memoLst = [];
    let dateLst = [];
    let clientInfo = newParameters.client;
    let companyInfo = newParameters.company;
    let processInfo = newParameters.process;
    let deviceMasterInfo = newParameters.device;
    let processPattern = newParameters.processPattern;
    let estimateStatus = newParameters.estimateStatus;
    let purchaseAllocation = newParameters.purchaseAllocation;
    let purchaseCategoryInfo = newParameters.purchaseCategory;
    let purchasesData = newParameters.purchase;
    let staffList = newParameters.staff;
    let userSettingData = newParameters.userSettings;
    let companySettingData = newParameters.companySettings;
    //板金パラメータ情報
    let materialIq3 = newParameters.materialIq3;
    let materialSizeIq3 = newParameters.materialSizeIq3;
    let materialSurfaceIq3 = newParameters.materialSurfaceIq3;
    let materialType = newParameters.materialType;
    let materialTypeIq3 = newParameters.materialTypeIq3;

    let client = clientInfo?.length > 0 ? clientInfo[0] : undefined;
    if (companyInfo?.length > 0) {
      orderCoeffs = companyInfo[0].coeffOrders;
      deliDateCoeffs = companyInfo[0].coeffDeliveryDates;
      purCoeffs = companyInfo[0].coeffPurchases;
      mngCoeffs = companyInfo[0].coeffManagements;

      costLst = companyInfo[0]?.additionalItemCosts
        ?.filter((item) => item.isUsed && !item.isDeleted)
        ?.map((item) => {
          return initAdditionalItemCost(item.id, item.code, item.name);
        });

      labelLst = companyInfo[0]?.additionalItemLabels
        ?.filter((item) => item.isUsed && !item.isDeleted)
        ?.map((item) => {
          return initAdditionalItemLabel(item.id, item.code, item.name);
        });

      memoLst = companyInfo[0]?.additionalItemMemos
        ?.filter((item) => item.isUsed && !item.isDeleted)
        ?.map((item) => {
          return initAdditionalItemMemo(item.id, item.code, item.name);
        });

      dateLst = companyInfo[0]?.additionalItemDates
        ?.filter((item) => item.isUsed && !item.isDeleted)
        ?.map((item) => {
          return initAdditionalItemDate(item.id, item.code, item.name);
        });
    }
    let kouteiPatternList = processPattern?.filter(
      (item) => item.class === (partNm === 'parent' ? ServiceClass.Parent : ServiceClass.SheetMetal)
    );

    let parameters = {
      company: companyInfo,
      client: clientInfo,
      process: processInfo,
      device: deviceMasterInfo,
      processPattern: processPattern,
      estimateStatus: estimateStatus,
      purchaseAllocation: purchaseAllocation,
      purchaseCategory: purchaseCategoryInfo,
      purchase: purchasesData,
      staff: staffList,
      materialIq3: materialIq3,
      materialSizeIq3: materialSizeIq3,
      materialSurfaceIq3: materialSurfaceIq3,
      materialType: materialType,
      materialTypeIq3: materialTypeIq3,
    };

    let settings = {
      userSettings: userSettingData,
      companySettings: companySettingData,
    };

    return {
      ...estimateInitInfo,
      estimateProducts: {
        ...estimateInitInfo.estimateProducts,
        modifierId:
          userSettingData?.length > 0 ? userSettingData[0].userId : staffList?.length > 0 ? staffList[0].id : '',
        // modifier: staffList?.length > 0 ? staffList[0].label : '',
        modifier:
          userSettingData?.length > 0
            ? userSettingData[0].users.nickName
            : staffList?.length > 0
            ? staffList[0].name
            : '',
        drawingNo: formatDateString(todayTime()),
        name: formatDateString(todayTime()),
        clientId: client ? client?.id : 0,
        clientName: client ? client?.name : '',
        coeffOrdersId: orderCoeffs?.length > 0 ? orderCoeffs?.[0].id : undefined,
        coeffOrdersName: orderCoeffs?.length > 0 ? orderCoeffs?.[0].name : '',
        coeffDeliveryDatesId: deliDateCoeffs?.length > 0 ? deliDateCoeffs?.[0].id : undefined,
        coeffDeliveryDatesName: deliDateCoeffs?.length > 0 ? deliDateCoeffs?.[0].name : '',
        coeffPurchasesId: purCoeffs?.length > 0 ? purCoeffs?.[0].id : undefined,
        coeffPurchasesName: purCoeffs?.length > 0 ? purCoeffs?.[0].name : '',
        coeffManagementsId: mngCoeffs?.length > 0 ? mngCoeffs?.[0].id : undefined,
        coeffManagementsName: mngCoeffs?.length > 0 ? mngCoeffs?.[0].name : '',
        createrId:
          userSettingData?.length > 0 ? userSettingData[0].userId : staffList?.length > 0 ? staffList[0].id : '',
        // creater: staffList?.length > 0 ? staffList[0].label : '',
        creater:
          userSettingData?.length > 0
            ? userSettingData[0].users.nickName
            : staffList?.length > 0
            ? staffList[0].name
            : '',
        estimateNo: '初期値',
        estimateStatusId: estimateStatus?.length > 0 ? estimateStatus[0].id : '',
        estimateStatus: estimateStatus?.length > 0 ? estimateStatus[0].name : '',
        otherProduct: {
          adjustment: 0,
          adjustmentMethod: '+',
          coeffClient: client
            ? toDecimal(client?.coeffClients?.find((item) => item.clientType === ClientType.Customer)?.coeff)
            : 0.0,
          coeffOrders: orderCoeffs?.length > 0 ? toDecimal(orderCoeffs?.[0].coeff) : 0.0,
          coeffDeliveryDates: deliDateCoeffs?.length > 0 ? toDecimal(deliDateCoeffs?.[0].coeff) : 0.0,
          coeffPurchases: purCoeffs?.length > 0 ? toDecimal(purCoeffs?.[0].coeff) : 0.0,
          coeffManagements: mngCoeffs?.length > 0 ? toDecimal(mngCoeffs?.[0].coeff) : 0.0,
        },
        processSelect: {
          id: kouteiPatternList?.length > 0 ? kouteiPatternList[0]?.id : 0,
          name: kouteiPatternList?.length > 0 ? kouteiPatternList[0]?.name : '',
          details: kouteiPatternList?.length > 0 ? kouteiPatternList[0]?.details : [],
          modified: kouteiPatternList?.length > 0 ? kouteiPatternList[0]?.modified : dayjs().format(),
        },
        purchases: [],
        byQuantities: JSON.parse(JSON.stringify(byQuantities)),
        calcParameters: {
          settings: settings,
          parameters: parameters,
        },
      },
      epCustomCosts: costLst,
      epCustomDates: dateLst,
      epCustomLabels: labelLst,
      epCustomMemos: memoLst,
    };
  };

  const cancelOk = () => {
    setSaveDiscardConfirmPopup(false);
  };

  const closeOk = () => {
    setSaveDiscardConfirmPopup(false);
  };

  const saveEstimateData = async (e) => {
    if (location.pathname === '/home/:estimate.parent' || location.pathname === '/home/:estimate.iq3') {
      // 親部品&板金
      let parentEstimateData = mainEstimateRef?.current?.getParentEstimateData();
      let createdData = await createEstimateProductInfo(parentEstimateData);
      // } else if (location.pathname === '/home/:estimate.iq3') {
      //   // 板金
    }

    setSaveDiscardConfirmPopup(false);
    if (navigatePageName === 'parentList') {
      setActiveTab('1');
      navigatePage(e, ':parentList', 'parentList');
    } else if (navigatePageName === 'parent') {
      setActiveTab('1');
      navigatePage(e, ':estimate.parent', 'parent');
    } else if (navigatePageName === 'sheetMetal') {
      setActiveTab('2');
      navigatePage(e, ':estimate.iq3', 'iq3');
    }
  };

  const updateEstimateData = async (e) => {
    if (location.pathname === '/home/:estimate.parent' || location.pathname === '/home/:estimate.iq3') {
      let validation = mainEstimateRef?.current?.callFormValidation();
      if (!validation) return;
      // 親部品
      let parentEstimateData = mainEstimateRef?.current?.getParentEstimateData();
      let updatedData = await updateEstimateProductInfo(parentEstimateData);
    }
    //  else if (location.pathname === '/home/:estimate.iq3') {
    //   // 板金
    // }
    setSaveDiscardConfirmPopup(false);
    if (navigatePageName === 'parentList') {
      setActiveTab('1');
      navigatePage(e, ':parentList', 'parentList');
    } else if (navigatePageName === 'parent') {
      setActiveTab('1');
      navigatePage(e, ':estimate.parent', 'parent');
    } else if (navigatePageName === 'sheetMetal') {
      setActiveTab('2');
      navigatePage(e, ':estimate.iq3', 'iq3');
    }
  };

  const discardEstimateData = async (e) => {
    let oldEstimateData = { ...selectedEstimateData };
    setSelectedEstimateData(oldEstimateData);
    setSaveDiscardConfirmPopup(false);
    // navigatePage(e, ':parentList', 'parentList');
    if (navigatePageName === 'parentList') {
      setActiveTab('1');
      navigatePage(e, ':parentList', 'parentList');
    } else if (navigatePageName === 'parent') {
      setActiveTab('1');
      navigatePage(e, ':estimate.parent', 'parent');
    } else if (navigatePageName === 'sheetMetal') {
      setActiveTab('2');
      navigatePage(e, ':estimate.iq3', 'iq3');
    }
  };

  const saveEstimateDataByOtherName = () => {
    setIsSaveByOtherName(true);
    setDrawingNo(formatDateString(todayTime()));
    setParentEstimateName(formatDateString(todayTime()));
  };

  // 別名保存入力ボックスのOKボタン押下
  const saveByOtherNameOk = async (e) => {
    if (location.pathname === '/home/:estimate.parent' || location.pathname === '/home/:estimate.iq3') {
      // 親部品

      let validation = mainEstimateRef?.current?.callFormValidation();
      if (!validation) return;

      let parentEstimateData = mainEstimateRef?.current?.getParentEstimateData();
      const copyData = JSON.parse(JSON.stringify(parentEstimateData));

      copyData.estimateProducts = {
        ...copyData.estimateProducts,
        id: 0,
        drawingNo: drawingNo,
        name: parentEstimateName,
      };
      copyData.epCustomCosts = copyData?.epCustomCosts?.map((cost) => {
        return { ...cost, id: 0 };
      });
      copyData.epCustomDates = copyData?.epCustomDates?.map((date) => {
        return { ...date, id: 0 };
      });
      copyData.epCustomLabels = copyData?.epCustomLabels?.map((label) => {
        return { ...label, id: 0 };
      });
      copyData.epCustomMemos = copyData?.epCustomMemos?.map((memo) => {
        return { ...memo, id: 0 };
      });

      copyData.esIq3Info = copyData?.esIq3Info?.map((item) => ({ ...item, id: 0 }));

      let createdData = await createEstimateProductInfo(copyData);
    }
    // else if (location.pathname === '/home/:estimate.iq3') {
    //   // 板金
    // }
    setIsSaveByOtherName(false);
    setSaveDiscardConfirmPopup(false);
    if (navigatePageName === 'parentList') {
      setActiveTab('1');
      navigatePage(e, ':parentList', 'parentList');
    } else if (navigatePageName === 'parent') {
      setActiveTab('1');
      navigatePage(e, ':estimate.parent', 'parent');
    } else if (navigatePageName === 'sheetMetal') {
      setActiveTab('2');
      navigatePage(e, ':estimate.iq3', 'iq3');
    }
  };

  // 別名保存入力ボックスのCancelボタン押下
  const saveByOtherNameCancel = () => {
    setIsSaveByOtherName(false);
  };

  // 別名保存
  const saveByOtherNameInfoContent = (
    <>
      <p style={{ fontSize: '13.5px', height: 10, borderBottom: 10 }}>
        表示中の見積データを別名で保存します。必要な項目を編集してください。
      </p>
      <RTable className="propertiesTbl">
        <thead>
          <tr>
            <td className="detail-tbl-val-title">
              <label className="detail-tbl-val-title-label">図番</label>
            </td>
            <td colSpan={6} style={{ verticalAlign: 'middle' }}>
              <Input
                style={{ padding: 5, marginLeft: 5, width: '98.2%' }}
                className="input-editable "
                ref={ref}
                name="drawingNo"
                id="drawingNo"
                value={drawingNo}
                type="text"
                onChange={(e) => {
                  setDrawingNo(e.target.value);
                }}
              />
            </td>
          </tr>
          <tr>
            <td className="detail-tbl-val-title">
              <label className="detail-tbl-val-title-label">名称</label>
            </td>
            <td colSpan={6} style={{ verticalAlign: 'middle' }}>
              <Input
                style={{ padding: 5, marginLeft: 5, width: '98.2%' }}
                className="input-editable "
                name="parentEstimateName"
                id="parentEstimateName"
                value={parentEstimateName}
                type="text"
                onChange={(e) => {
                  setParentEstimateName(e.target.value);
                }}
              />
            </td>
          </tr>
        </thead>
      </RTable>
    </>
  );

  const saveDiscardConfirmContent = (
    <>
      <Row>
        <p style={{ fontSize: '13.5px' }}>
          見積データが保存されていません。
          <br />
          そのまま画面を切り替えると編集データが削除されます。
          <br /> 保存しますか？
        </p>
      </Row>
      <Row align="middle">
        <Col span={8}>
          {selectedEstimateData?.estimateProducts?.id === 0 ? (
            <>
              <Button className="mainButton" onClick={saveEstimateData}>
                保存
              </Button>
            </>
          ) : (
            <>
              <Space size={'middle'}>
                <Button className="mainButton" onClick={saveEstimateDataByOtherName}>
                  別名保存
                </Button>
                <Button className="mainButton" onClick={updateEstimateData}>
                  上書保存
                </Button>
              </Space>
            </>
          )}
        </Col>

        <Col span={8} offset={7}>
          <Space size={'middle'}>
            <Button className="discardButton" style={{ textAlign: 'center' }} onClick={discardEstimateData}>
              {/* <CloseOutlined /> */}
              破棄
            </Button>
            <Button className="cancelButton" onClick={cancelOk}>
              キャンセル
            </Button>
          </Space>
        </Col>
      </Row>
    </>
  );

  const commonModalTitle = (
    <div
      style={{
        width: 520,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>確認</p>
    </div>
  );

  const renderComponent = () => {
    if (location.pathname == '/home/:parentList') {
      return (
        <ParentList
          ref={parentListRef}
          updateEstimateEditMode={updateEstimateEditMode}
          getSelectedEstimateData={getSelectedEstimateData}
          editMode={estimateEditMode}
          parentListClick={parentListClick}
          orderOptions={orderOptions}
          companyInfo={companyInfo}
          clientInfo={clientInfo}
          userInfo={userInfo}
          staffInfo={staffList}
          processInfo={processInfo}
          companySettingInfo={companySettingInfo}
          userSettingInfo={userSettingInfo}
          purchaseCategoryInfo={purchaseCategoryInfo}
        />
      );
    } else if (location.pathname == '/home/:estimate.parent' || location.pathname == '/home/:estimate.iq3') {
      return (
        <MainEstimate
          ref={mainEstimateRef}
          updateEstimateEditMode={updateEstimateEditMode}
          selectedEstimateData={selectedEstimateData}
          updateSelectedEstimateData={updateSelectedEstimateData}
          companyInfo={companyInfo}
          staffList={staffList}
          estimateStatusInfo={orderOptions}
          clientInfo={clientInfo}
          editMode={estimateEditMode}
          activeTab={activeTab}
          newMode={newMode}
          updateNewMode={updateNewMode}
          settingParametersByReCal={settingParametersByReCal}
          userSettingInfo={userSettingInfo}
        />
      );
    } else if (location.pathname == '/home/:userSetting') {
      return <UserSetting />;
    } else {
      return (
        <MainParameter
          paramType={paramType}
          paramNm={paramSelected}
          updateParamSelected={updateParamSelected}
          companySettingInfo={companySettingInfo}
          userSettingInfo={userSettingInfo}
          ref={paramRef}
        />
      );
    }
  };

  return (
    <>
      <Layout>
        {/* ヘッダー */}
        <Header />
        <Layout
          style={{
            background: 'white',
            left: 0,
          }}
          hasSider
        >
          {/* サイドメニュ */}
          <Sider
            style={{
              background: 'none',
              backgroundColor: '#fafafc',
              left: 0,
              bottom: 0,
              marginLeft: '0px',
              marginTop: '1px',
              borderRight: '1px solid #212529',
            }}
            width={75}
          >
            <div style={{ paddingLeft: '5px', display: 'flex', flexDirection: 'column', height: '94.5vh' }}>
              <div style={{ paddingTop: '0px' }}>
                <a onClick={(e) => changeScreenEvent(e, 'parentList')}>
                  <Image preview={false} width={35} src={oya_list} style={{ marginLeft: '15px' }}></Image>
                  <p
                    style={{
                      color: location.pathname == '/home/:parentList' ? '#1677ff' : '#4a4747',
                      fontSize: '10.5px',
                      fontWeight: 'bold',
                    }}
                  >
                    親部品リスト
                  </p>
                </a>
              </div>

              <div>
                <a onClick={(e) => changeScreenEvent(e, 'parent')}>
                  <Image preview={false} width={33} src={new_icon} style={{ marginLeft: '15px' }}></Image>
                  <p
                    style={{
                      color: partNm == 'parent' && isMenuClick == false ? '#1677ff' : '#4a4747',
                      fontSize: '10.5px',
                      fontWeight: 'bold',
                      marginLeft: '15px',
                    }}
                  >
                    親部品
                  </p>
                </a>
              </div>

              <div>
                <a onClick={(e) => changeScreenEvent(e, 'sheetMetal')}>
                  <Image preview={false} width={33} src={iQ3_create} style={{ marginLeft: '15px' }}></Image>
                  <p
                    style={{
                      color: partNm == 'iq3' && isMenuClick == false ? '#1677ff' : '#4a4747',
                      fontSize: '10.5px',
                      fontWeight: 'bold',
                      marginLeft: '12px',
                    }}
                  >
                    iQ3板金
                  </p>
                </a>
              </div>

              {/* <div>
              <Image preview={false} width={33} src={iQ5_create} style={{ marginLeft: '15px' }}></Image>
              <p
                style={{
                  color: partNm == 'iq5' ? '#1677ff' : '#4a4747',
                  fontSize: '10.5px',
                  fontWeight: 'bold',
                  marginLeft: '12px',
                }}
              >
                iQ5形鋼
              </p>
            </div> */}

              {/* <div>
              <Image preview={false} width={33} src={iQ7_create} style={{ marginLeft: '15px' }}></Image>
              <p
                style={{
                  color: partNm == 'iq7' ? '#1677ff' : '#4a4747',
                  fontSize: '10.5px',
                  fontWeight: 'bold',
                  marginLeft: '12px',
                }}
              >
                iQ7機械
              </p>
            </div> */}

              <div style={{ height: '68.5vh' }}>
                <a onClick={(e) => (!isEditingMode(e, 2, '', '') ? paramChangeEvent(e) : '')}>
                  <Image preview={false} width={30} src={parameter} style={{ marginLeft: '15px' }}></Image>
                  <p style={{ color: '#4a4747', fontSize: '10.5px', fontWeight: 'bold', marginLeft: '5px' }}>
                    パラメータ
                  </p>
                </a>
              </div>

              <div>
                <a onClick={(e) => (!isEditingMode(e, 3, '', '') ? handleClick(e) : '')}>
                  <Image preview={false} width={26} src={logout} style={{ marginLeft: '15px' }}></Image>
                </a>
                <p
                  style={{ color: '#4a4747', fontSize: '10.5px', fontWeight: 'bold', marginTop: 2, marginLeft: '5px' }}
                >
                  ログアウト
                </p>
              </div>
            </div>
          </Sider>
          {/* コンテンツ */}
          <Layout style={{ background: 'white' }}>
            <Content style={{ overflow: 'initial' }}>{renderComponent()}</Content>
          </Layout>
        </Layout>
      </Layout>

      {/** 画面切替警告メッセージポップアップ */}
      {editModeCancelConfirm
        ? commonModal(
            editModeCancelConfirm,
            confirmScreenChangeTitle,
            commonFooter(discardConfirmOk, discardConfirmCancel),
            null,
            400,
            discardConfirmOk,
            discardConfirmCancel,
            confirmScreenChangeContent,
            null,
            null,
            false
          )
        : ''}
      {saveDiscardConfirmPopup
        ? commonModal(
            saveDiscardConfirmPopup,
            confirmScreenChangeWarning,
            null,
            null,
            500,
            discardConfirmOk,
            closeOk,
            saveDiscardConfirmContent,
            null,
            null,
            false
          )
        : ''}
      {/** 閲覧モードでもこのデータを保存していない場合は破棄さえてしまうため、メッセージ表示 */}
      {confirmDisplayDataSaveModalOpen
        ? commonModal(
            confirmDisplayDataSaveModalOpen,
            confirmScreenChangeTitle,
            null,
            null,
            400,
            goToCreateParentScreen,
            discardGoToCreateParentScreen,
            confirmDisplayDataSaveContent(goToCreateParentScreen, discardGoToCreateParentScreen),
            null,
            null,
            false
          )
        : ''}

      {/* 別名保存_Modal */}
      {isSaveByOtherName
        ? commonModal(
            isSaveByOtherName,
            commonModalTitle,
            commonFooter(saveByOtherNameOk, saveByOtherNameCancel),
            null,
            520,
            saveByOtherNameOk,
            saveByOtherNameCancel,
            saveByOtherNameInfoContent,
            null,
            null,
            false
          )
        : ''}
    </>
  );
});

export default MainLayout;
