/**
 * クラス名：クラウド用PMXファイル読み込むの親部品情報タブ
 * 説明：クラウドにあるPMXファイルを読み込んで子部品情報やファイル情報を表示・複数ファイル選択操作を行うJSである。
 * 作成者：ナンス
 * 作成日：2023/07/24
 * バージョン：1.0
 */
import { Button, Row, Input, Col, Image, Table, DatePicker, Tree, Space } from 'antd';
import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { CaretUpOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import '../../assets/styles/common.css';
import filter_clear_icon from '../../assets/images/filter_clear_icon.png';
import iq3Img_pmx1_1 from '../../assets/images/iq3Img_pmx1_1.png';
import iq3Img_pmx1_2 from '../../assets/images/iq3Img_pmx1_2.png';
import iq3Img_pmx1_3 from '../../assets/images/iq3Img_pmx1_3.png';
import no_image from '../../assets/images/no_image.jpg';
import {
  estimateInitInfo,
  formatDate,
  formatDateString,
  getDetails,
  initAdditionalItemCost,
  initAdditionalItemDate,
  initAdditionalItemLabel,
  initAdditionalItemMemo,
  iq3EstimateInitInfo,
  padWithLeadingZeros,
  toDecimal,
  todayTime,
} from './Common';
import { commonModal } from './CommonModal';
import { GetParametersByCompId } from './CommonAPI';
import { ClientType, DataType, EstimateCreationType, PatternType, ServiceClass, SurfaceProtectionType } from './enums';
import { byQuantities } from './Constant';

const PMX_CloudFileDialog_Tab_Parent = React.forwardRef((props, ref) => {
  const [cloudPMXFileLst, setCloudPMXFileLst] = useState([]);
  const [cloudPMXFileLstBK, setCloudPMXFileLstBK] = useState([]);
  const [cloudPMXIQ3FileLst, setCloudPMXIQ3FileLst] = useState([]);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRow, setSelectedRow] = useState();
  const [selectedData, setSelectedData] = useState([]);
  const [selectedDatas, setSelectedDatas] = useState([]);
  const [iq3SelectedRowKeys, setIQ3SelectedRowKeys] = useState([]);

  const [searchFileNm, setSearchFileNm] = useState('');
  const [searchUpdatedStart, setSearchUpdatedStart] = useState('');
  const [searchUpdatedEnd, setSearchUpdatedEnd] = useState('');

  const [isPMXCloudFileDialogOpen, setIsPMXCloudFileDialogOpen] = useState(props.pmxModal);

  useEffect(() => {
    setIsPMXCloudFileDialogOpen(props.pmxModal);
    if (props.cardData) {
      //親部品
      const pmxFileList = [
        {
          key: 0,
          parents: [
            {
              title: 'MRO-M08A-4110' + '(4)',
              key: '0-0',
              children: [
                {
                  title: 'MRO-M08A-4110-01',
                  key: '0-0-0',
                  type: '板金',
                  img: (
                    <Image
                      preview={true}
                      width={120}
                      height={77}
                      src={iq3Img_pmx1_1}
                      style={{ marginLeft: '0px' }}
                    ></Image>
                  ),
                  material: 'SPCC',
                  thickness: '2.1',
                  partCnt: 1,
                },
                {
                  title: 'MRO-M08A-4110-02',
                  key: '0-0-1',
                  type: '板金',
                  img: (
                    <Image
                      preview={true}
                      width={120}
                      height={77}
                      src={iq3Img_pmx1_2}
                      style={{ marginLeft: '0px' }}
                    ></Image>
                  ),
                  material: 'SPCH',
                  thickness: '2.2',
                  partCnt: 2,
                },
                {
                  title: 'MRO-M08A-4110-03',
                  key: '0-0-2',
                  type: '板金',
                  img: (
                    <Image
                      preview={true}
                      width={120}
                      height={77}
                      src={iq3Img_pmx1_3}
                      style={{ marginLeft: '0px' }}
                    ></Image>
                  ),
                  material: 'SPCC',
                  thickness: '2.0',
                  partCnt: 2,
                },
                {
                  title: 'MRO-M08A-4110-04',
                  key: '0-0-3',
                  type: 'その他',
                  img: (
                    <Image preview={true} width={120} height={77} src={no_image} style={{ marginLeft: '0px' }}></Image>
                  ),
                  material: 'SPCC',
                  thickness: '2.5',
                  partCnt: 10,
                },
              ],
            },
          ],
          updated: '2023/07/21 18:00',
        },
        {
          key: 1,
          parents: [
            {
              title: 'MRO-M08A-4111' + '(1)',
              key: '0-1',
              children: [
                {
                  title: 'MRO-M08A-4111-01',
                  key: '0-1-0',
                  type: '板金',
                  img: '',
                  material: 'SPCC',
                  thickness: '2.3',
                  partCnt: 1,
                },
              ],
            },
          ],
          updated: '2023/07/20 18:00',
        },
        {
          key: 2,
          parents: [
            {
              title: 'MRO-M08A-4112' + '(1)',
              key: '0-2',
              children: [
                {
                  title: 'MRO-M08A-4112-01',
                  key: '0-2-0',
                },
              ],
            },
          ],
          updated: '2023/07/23 18:00',
        },
        {
          key: 3,
          parents: [
            {
              title: 'MRO-M08A-4113' + '(1)',
              key: '0-3',
              children: [
                {
                  title: 'MRO-M08A-4113-01',
                  key: '0-3-0',
                },
              ],
            },
          ],
          updated: '2023/07/22 18:00',
        },
        {
          key: 4,
          parents: [
            {
              title: 'MRO-M08A-4114' + '(1)',
              key: '0-4',
              children: [
                {
                  title: 'MRO-M08A-4114-01',
                  key: '0-4-0',
                },
              ],
            },
          ],
          updated: '2023/07/24 18:00',
        },
      ];
      // setCloudPMXFileLst(pmxFileList);
      // setCloudPMXFileLstBK(pmxFileList);
      // setCloudPMXIQ3FileLst(pmxFileList[0].parents[0].children);
      // setSelectedData(pmxFileList[0]);
      // setSelectedRowKeys([0]);
      setCloudPMXFileLst(props.cardData);
      setCloudPMXFileLstBK(props.cardData);
      setSelectedData(props.cardData[0]);
      setSelectedRow(props.cardData[0]?.id);
      // setSelectedRowKeys([1]);
    }
  }, [props.cardData]);

  let checkedRow = [];
  const onRowCheckboxSelect = (checked, record) => {
    const checkedRow = [...checked];
    setSelectedRowKeys(checkedRow);
    // const selectedRow = record.slice(-1);
    // setSelectedData(selectedRow[0]);
    // // setCloudPMXIQ3FileLst(selectedRow[0]?.parents[0].children);
    // const data = [...selectedDatas];
    // data.push(record);
    // setSelectedDatas(data);
    // props.selectedParentRows(data);
  };
  const onRowSelect = (record) => {
    setSelectedRow(record.id);
    setSelectedData(record);
  };

  const rowSelection = {
    // selectedRowKeys,
    onChange: onRowCheckboxSelect,
  };

  const getFileNmByFilter = (value) => {
    let searchBarText = value;
    let lstData = cloudPMXFileLst?.length > 0 ? cloudPMXFileLst : cloudPMXFileLstBK;
    const filterData = lstData?.filter((item) => item.name?.indexOf(searchBarText) >= 0);

    if (searchBarText != '') {
      setCloudPMXFileLst(filterData);
      // setCloudPMXIQ3FileLst(filterData?.length > 0 ? filterData[0].parents[0].children : []);
    } else {
      setCloudPMXFileLst(cloudPMXFileLstBK);
      // setCloudPMXIQ3FileLst(cloudPMXFileLstBK?.length > 0 ? cloudPMXFileLstBK[0].parents[0].children : []);
    }
  };

  const getDateByFilter = (name, dateString) => {
    let lstData = [];

    lstData = cloudPMXFileLst?.length > 0 ? cloudPMXFileLst : cloudPMXFileLstBK;

    if (name == 0) {
      setSearchUpdatedStart(dateString);
      let filterData = [];
      if (searchUpdatedEnd == '') {
        filterData = lstData?.filter((item) => dateString <= dayjs(item.updated).format('YYYY-MM-DD'));
      } else {
        filterData = lstData?.filter(
          (item) =>
            dateString <= dayjs(item.updated).format('YYYY-MM-DD') &&
            dayjs(item.updated).format('YYYY-MM-DD') <= searchUpdatedEnd
        );
      }

      setCloudPMXFileLst(filterData);
      // setCloudPMXIQ3FileLst(filterData?.length > 0 ? filterData[0].parents[0].children : []);
    } else {
      setSearchUpdatedEnd(dateString);
      let filterData = [];
      if (searchUpdatedStart == '') {
        filterData = lstData?.filter((item) => dateString >= dayjs(item.updated).format('YYYY-MM-DD'));
      } else {
        filterData = lstData?.filter(
          (item) =>
            searchUpdatedStart <= dayjs(item.updated).format('YYYY-MM-DD') &&
            dayjs(item.updated).format('YYYY-MM-DD') <= dateString
        );
      }

      setCloudPMXFileLst(filterData);
      // setCloudPMXIQ3FileLst(filterData?.length > 0 ? filterData[0].parents[0].children : []);
    }
  };

  const clearAllFilter = (e) => {
    setSearchFileNm('');
    setSearchUpdatedStart('');
    setSearchUpdatedEnd('');

    setCloudPMXFileLst(cloudPMXFileLstBK);
    // setCloudPMXIQ3FileLst(cloudPMXFileLstBK?.length > 0 ? cloudPMXFileLstBK[0].parents[0].children : []);
  };

  useImperativeHandle(
    ref,
    () => ({
      getSelectedParent: () => {
        return selectedData;
      },
    }),
    [selectedData]
  );

  const cloudFileParentColumns = [
    {
      id: '1',
      title: 'ファイル名（部品点数）',
      dataIndex: 'name',
      width: 150 + 'px',
      // render: (_, record) => (
      //   <Tree
      //     showLine
      //     showIcons
      //     defaultExpandAll={false}
      //     defaultExpandedKeys={['0-0']}
      //     switcherIcon={<CaretUpOutlined />}
      //     treeData={record.parents}
      //   />
      // ),
    },
    {
      id: '2',
      title: '更新日時',
      dataIndex: 'modified',
      width: 100 + 'px',
      // render: (record) => {
      //   const date = new Date(record);
      //   const formattedDate = `${date.getFullYear()}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date
      //     .getDate()
      //     .toString()
      //     .padStart(2, '0')}`;
      //   const formattedTime = `${date.getHours().toString().padStart(2, '0')}:${date
      //     .getMinutes()
      //     .toString()
      //     .padStart(2, '0')}`;
      //   const updDate = formattedDate + ' ' + formattedTime;
      //   return updDate;
      // },
    },
  ];
  const cloudFileParentDetailColumns = [
    {
      id: '1',
      title: 'サムネイル',
      dataIndex: 'img',
      width: 200 + 'px',
      // render: (value, array, index) => index + 1,
    },
    {
      id: '2',
      title: '種別',
      dataIndex: 'type',
      width: 80 + 'px',
    },
    {
      id: '3',
      title: '名称',
      dataIndex: 'title',
      width: 200 + 'px',
    },
    {
      id: '4',
      title: '材質',
      dataIndex: 'material',
      width: 100 + 'px',
    },
    {
      id: '5',
      title: '板厚',
      dataIndex: 'thickness',
      width: 80 + 'px',
    },
    {
      id: '6',
      title: '員数',
      dataIndex: 'partCnt',
      width: 80 + 'px',
    },
  ];

  const closePMXModal = () => {
    setIsPMXCloudFileDialogOpen(false);
  };
  const childModalTitle = (
    <div
      style={{
        width: 1490,
        // backgroundColor: '#005fab',
        // // marginLeft: 0,
        // height: 36,
        // marginTop: -20,
        // borderTopLeftRadius: 7,
        // borderTopRightRadius: 7,
        // fontSize: 14,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>PMX取り込み</p>
    </div>
  );
  const addData = () => {};
  // 板金見積データ作成
  const getNewIQ3EstimateInfo = (lists, parameters) => {
    // let parameters = props.selectedEstimateData?.estimateProducts?.calcParameters?.parameters;

    const patternLst = parameters?.processPattern; // 工程パターンマスタデータ
    let kouteiPatternList = patternLst?.filter((item) => item.class === ServiceClass.SheetMetal);

    const materialType = parameters?.materialTypeIq3; // 材質マスタデータ
    let materialTypeList = materialType?.filter((item) => item.info.isUsed);

    const material = parameters?.materialIq3; // 材料マスタデータ
    let materialList = material?.filter(
      (item) =>
        item.info.isUsed && item.materialTypeIq3Id === (materialTypeList?.length > 0 ? materialTypeList[0]?.id : 0)
    );

    const materialSurface = parameters?.materialSurfaceIq3; // 材料表面マスタデータ
    let materialSurfaceList = materialSurface?.filter(
      (item) =>
        item.info.isUsed && item.materialTypeIq3Id === (materialTypeList?.length > 0 ? materialTypeList[0]?.id : 0)
    );

    // 工程マスタ情報取得
    let processMaster = [];
    const processMstData = parameters?.process; // 工程マスタデータ
    processMaster = processMstData?.filter((process) => process?.class === ServiceClass.SheetMetal);

    // 工程入力
    let processSelectDetails = kouteiPatternList[0]?.details;
    let kouteiInputArr = [];
    if (processSelectDetails) {
      kouteiInputArr = processSelectDetails?.map((item, index) => {
        const processMstInfo = processMaster?.filter((i) => i.id === item.processId)[0] || [];

        const workType = processMstInfo.workType || '';

        let data = {
          id: index + 1,
          processId: item.processId,
          details: getDetails(workType, parameters),
        };

        return data;
      });
    }

    //　工程積算
    let kouteiCalArr = [];
    if (processSelectDetails) {
      kouteiCalArr = processSelectDetails?.map((item, index) => {
        const processMstInfo = processMaster?.filter((i) => i.id === item.processId)[0] || [];

        const workType = processMstInfo.workType || '';

        let addition = {
          no: index + 1,
          processId: item.processId,
          dataType: DataType.Data,
          dataItems: [],
          editItems: [],
          totalDandori: {
            dataItemsAmt: 0,
            dataItemsTime: 0,
            editItemsAmt: 0,
            editItemsTime: 0,
          },
          totalSagyo: {
            dataItemsAmt: 0,
            dataItemsTime: 0,
            editItemsAmt: 0,
            editItemsTime: 0,
          },
          totalDataPrice: 0,
          totalDataTime: 0,
          totalEditPrice: 0,
          totalEditTime: 0,
        };

        return addition;
      });
    }

    // 材料詳細パターン情報を準備する
    let materialDetailPatterns = [];
    materialDetailPatterns = new Array(2).fill(null)?.map((_, index) => {
      let data = {
        patternType: index === 0 ? PatternType.Pattern1 : PatternType.Pattern2,
        totalPrice: 0,
        yield: 0,
        materialQuantity: 0,
        materialSizeIq3Id: parameters?.materialSizeIq3?.[0]?.id,
        sizeName: parameters?.materialSizeIq3?.[0]?.name,
        sizeX: 0,
        sizeY: 0,
      };
      return data;
    });

    // 材料詳細サイズリスト情報を準備する
    let materialDetailSizes = [];
    let materialSizeIq3Infos = parameters?.materialSizeIq3;
    if (materialSizeIq3Infos) {
      materialDetailSizes = materialSizeIq3Infos
        ?.filter((item) => item.info.isUsed)
        ?.map((item, index) => {
          let data = {
            no: index + 1,
            materialSizeIq3Id: item.id,
            sizeName: item.name,
            materialQuantity: 0,
            price: 0,
            yield: 0,
            pattern1Rate: 0,
            pattern1Quantity: 0,
            pattern2Rate: 0,
            pattern2Quantity: 0,
            maxYield: 0,
            maxQuantity: 0,
          };
          return data;
        });
    }

    const updatedList = lists?.map((i, index) => {
      if (i?.id === 0) {
        i.no = 1;
        i.creationType = EstimateCreationType.Auto;
        i.drawingNo = 'Part_' + formatDateString(todayTime());
        i.name = 'Part_' + padWithLeadingZeros(index + 1, 5);
        i.surfaceProtection = SurfaceProtectionType.OneSideVinyl;
        i.materialIq3Id = materialList?.length > 0 ? materialList[0]?.id : 0;
        i.materialName = materialList?.length > 0 ? materialList[0]?.name : '';
        i.materialTypeIq3Id = materialTypeList?.length > 0 ? materialTypeList[0]?.id : 0;
        i.materialTypeName = materialTypeList?.length > 0 ? materialTypeList[0]?.name : '';
        i.materialSurfaceIq3Id = materialSurfaceList?.length > 0 ? materialSurfaceList[0]?.id : 0;
        i.materialSurfaceName = materialSurfaceList?.length > 0 ? materialSurfaceList[0]?.name : '';
        i.thickness = materialList?.length > 0 ? materialList[0]?.id : 0;
        i.processSelect = {
          id: kouteiPatternList?.length > 0 ? kouteiPatternList[0]?.id : 0,
          name: kouteiPatternList?.length > 0 ? kouteiPatternList[0]?.name : '',
          details: kouteiPatternList?.length > 0 ? kouteiPatternList[0]?.details : [],
          modified: kouteiPatternList?.length > 0 ? kouteiPatternList[0]?.modified : dayjs().format(),
        };
        i.processInput = kouteiInputArr;
        i.additions = kouteiCalArr;
        i.materialDetailPatterns = materialDetailPatterns;
        i.materialDetailSizes = materialDetailSizes;
      }
      return i;
    });
    return updatedList;
  };
  // 新規見積データ作成
  const getNewEstimateInfo = (newParameters, iq3Count) => {
    let orderCoeffs = [];
    let deliDateCoeffs = [];
    let purCoeffs = [];
    let mngCoeffs = [];
    let costLst = [];
    let labelLst = [];
    let memoLst = [];
    let dateLst = [];
    let clientInfo = newParameters.client;
    let companyInfo = newParameters.company;
    let processInfo = newParameters.process;
    let deviceMasterInfo = newParameters.device;
    let processPattern = newParameters.processPattern;
    let estimateStatus = newParameters.estimateStatus;
    let purchaseAllocation = newParameters.purchaseAllocation;
    let purchaseCategoryInfo = newParameters.purchaseCategory;
    let purchasesData = newParameters.purchase;
    let staffList = newParameters.staff;
    let userSettingData = newParameters.userSettings;
    let companySettingData = newParameters.companySettings;
    //板金パラメータ情報
    let materialIq3 = newParameters.materialIq3;
    let materialSizeIq3 = newParameters.materialSizeIq3;
    let materialSurfaceIq3 = newParameters.materialSurfaceIq3;
    let materialType = newParameters.materialType;
    let materialTypeIq3 = newParameters.materialTypeIq3;

    let client = clientInfo?.length > 0 ? clientInfo[0] : undefined;
    if (companyInfo?.length > 0) {
      orderCoeffs = companyInfo[0].coeffOrders;
      deliDateCoeffs = companyInfo[0].coeffDeliveryDates;
      purCoeffs = companyInfo[0].coeffPurchases;
      mngCoeffs = companyInfo[0].coeffManagements;

      costLst = companyInfo[0]?.additionalItemCosts
        ?.filter((item) => item.isUsed && !item.isDeleted)
        ?.map((item) => {
          return initAdditionalItemCost(item.id, item.code, item.name);
        });

      labelLst = companyInfo[0]?.additionalItemLabels
        ?.filter((item) => item.isUsed && !item.isDeleted)
        ?.map((item) => {
          return initAdditionalItemLabel(item.id, item.code, item.name);
        });

      memoLst = companyInfo[0]?.additionalItemMemos
        ?.filter((item) => item.isUsed && !item.isDeleted)
        ?.map((item) => {
          return initAdditionalItemMemo(item.id, item.code, item.name);
        });

      dateLst = companyInfo[0]?.additionalItemDates
        ?.filter((item) => item.isUsed && !item.isDeleted)
        ?.map((item) => {
          return initAdditionalItemDate(item.id, item.code, item.name);
        });
    }
    // let kouteiPatternList = processPattern?.filter(
    //   (item) => item.class === (partNm === 'parent' ? ServiceClass.Parent : ServiceClass.SheetMetal)
    // );
    let kouteiPatternList = processPattern?.filter((item) => item.class === ServiceClass.Parent);

    let parameters = {
      company: companyInfo,
      client: clientInfo,
      process: processInfo,
      device: deviceMasterInfo,
      processPattern: processPattern,
      estimateStatus: estimateStatus,
      purchaseAllocation: purchaseAllocation,
      purchaseCategory: purchaseCategoryInfo,
      purchase: purchasesData,
      staff: staffList,
      materialIq3: materialIq3,
      materialSizeIq3: materialSizeIq3,
      materialSurfaceIq3: materialSurfaceIq3,
      materialType: materialType,
      materialTypeIq3: materialTypeIq3,
    };

    let settings = {
      userSettings: userSettingData,
      companySettings: companySettingData,
    };

    // 板金
    let iq3Data = [...iq3EstimateInitInfo];

    let newIQ3 = getNewIQ3EstimateInfo(iq3Data, parameters);

    const aggregatedObject = newIQ3?.reduce((result, currentItem) => {
      return { ...result, ...currentItem };
    }, {});
    const iq3ItemList = [];

    for (let i = 1; i <= iq3Count; i++) {
      //  CardDetailsの行により、板金データ作成
      iq3ItemList.push({ ...aggregatedObject, no: i });
    }
    return {
      ...estimateInitInfo,
      estimateProducts: {
        ...estimateInitInfo.estimateProducts,
        modifierId:
          userSettingData?.length > 0 ? userSettingData[0].userId : staffList?.length > 0 ? staffList[0].id : '',
        // modifier: staffList?.length > 0 ? staffList[0].label : '',
        modifier:
          userSettingData?.length > 0
            ? userSettingData[0].users.nickName
            : staffList?.length > 0
            ? staffList[0].name
            : '',
        drawingNo: formatDateString(todayTime()),
        name: formatDateString(todayTime()),
        clientId: client ? client?.id : 0,
        clientName: client ? client?.name : '',
        coeffOrdersId: orderCoeffs?.length > 0 ? orderCoeffs?.[0].id : undefined,
        coeffOrdersName: orderCoeffs?.length > 0 ? orderCoeffs?.[0].name : '',
        coeffDeliveryDatesId: deliDateCoeffs?.length > 0 ? deliDateCoeffs?.[0].id : undefined,
        coeffDeliveryDatesName: deliDateCoeffs?.length > 0 ? deliDateCoeffs?.[0].name : '',
        coeffPurchasesId: purCoeffs?.length > 0 ? purCoeffs?.[0].id : undefined,
        coeffPurchasesName: purCoeffs?.length > 0 ? purCoeffs?.[0].name : '',
        coeffManagementsId: mngCoeffs?.length > 0 ? mngCoeffs?.[0].id : undefined,
        coeffManagementsName: mngCoeffs?.length > 0 ? mngCoeffs?.[0].name : '',
        createrId:
          userSettingData?.length > 0 ? userSettingData[0].userId : staffList?.length > 0 ? staffList[0].id : '',
        // creater: staffList?.length > 0 ? staffList[0].label : '',
        creater:
          userSettingData?.length > 0
            ? userSettingData[0].users.nickName
            : staffList?.length > 0
            ? staffList[0].name
            : '',
        estimateNo: '初期値',
        estimateStatusId: estimateStatus?.length > 0 ? estimateStatus[0].id : '',
        estimateStatus: estimateStatus?.length > 0 ? estimateStatus[0].name : '',
        otherProduct: {
          adjustment: 0,
          adjustmentMethod: '+',
          coeffClient: client
            ? toDecimal(client?.coeffClients?.find((item) => item.clientType === ClientType.Customer)?.coeff)
            : 0.0,
          coeffOrders: orderCoeffs?.length > 0 ? toDecimal(orderCoeffs?.[0].coeff) : 0.0,
          coeffDeliveryDates: deliDateCoeffs?.length > 0 ? toDecimal(deliDateCoeffs?.[0].coeff) : 0.0,
          coeffPurchases: purCoeffs?.length > 0 ? toDecimal(purCoeffs?.[0].coeff) : 0.0,
          coeffManagements: mngCoeffs?.length > 0 ? toDecimal(mngCoeffs?.[0].coeff) : 0.0,
        },
        processSelect: {
          id: kouteiPatternList?.length > 0 ? kouteiPatternList[0]?.id : 0,
          name: kouteiPatternList?.length > 0 ? kouteiPatternList[0]?.name : '',
          details: kouteiPatternList?.length > 0 ? kouteiPatternList[0]?.details : [],
          modified: kouteiPatternList?.length > 0 ? kouteiPatternList[0]?.modified : dayjs().format(),
        },
        purchases: [],
        byQuantities: JSON.parse(JSON.stringify(byQuantities)),
        calcParameters: {
          settings: settings,
          parameters: parameters,
        },
      },
      epCustomCosts: costLst,
      epCustomDates: dateLst,
      epCustomLabels: labelLst,
      epCustomMemos: memoLst,
      esIq3Info: iq3ItemList,
    };
  };
  const getPMXData = async () => {
    if (selectedData?.details?.length > 0) {
      setIsPMXCloudFileDialogOpen(false);

      // 見積データ作成
      let newParameters = await GetParametersByCompId();
      let newEstimateInfo = getNewEstimateInfo(newParameters, selectedData?.details?.length);

      props.pmxProcessPattern(true, newEstimateInfo, selectedData);
    } else {
      alert('板金データありません！');
    }
  };
  const addPMXCloudFileContent = (
    <>
      <Row>
        <Col span={5}>
          <Input
            id="fileNm"
            style={{ marginLeft: 5 }}
            placeholder="ファイル名"
            className={'input-editable'}
            value={searchFileNm}
            allowClear
            onChange={(e) => setSearchFileNm(e.target.value)}
            onPressEnter={(e) => getFileNmByFilter(e.target.value)}
          />
        </Col>
        <Col span={9} style={{ textAlign: 'center', marginLeft: 20 }}>
          <div style={{ display: 'flex' }} id="pmxCloudFileFilter">
            <DatePicker
              style={{ padding: 5, marginLeft: 5, width: '75.7%' }}
              className={'input-editable'}
              onChange={(name, dateString) => getDateByFilter(0, dateString)}
              value={searchUpdatedStart != '' ? dayjs(searchUpdatedStart, 'YYYY-MM-DD') : ''}
            />
            <label style={{ paddingTop: 3 }}>~</label>
            <DatePicker
              style={{ padding: 5, marginLeft: 5, width: '75.7%' }}
              className={'input-editable'}
              onChange={(name, dateString) => getDateByFilter(1, dateString)}
              value={searchUpdatedEnd != '' ? dayjs(searchUpdatedEnd, 'YYYY-MM-DD') : ''}
            />
          </div>
        </Col>
        <Col span={9} style={{ textAlign: 'right' }}>
          <Button style={{ marginLeft: 0 }} className="filterClearButton" onClick={(e) => clearAllFilter()}>
            <div style={{ display: 'flex' }}>
              <Image preview={false} width={18} height={18} src={filter_clear_icon} style={{ marginBottom: '5px' }} />
              <label style={{ marginLeft: '5px', marginTop: 1, fontSize: '12px' }}>検索条件をクリア</label>
            </div>
          </Button>
        </Col>
      </Row>
      <Row style={{ marginTop: 10 }}>
        {/* <Col span={10}> */}
        <Table
          id="parentCloudFileTbl"
          showSorterTooltip={false}
          rowClassName={(record) => (record.id == selectedRow ? 'active-row' : 'data-row')}
          columns={cloudFileParentColumns}
          rowKey={(record) => record.id}
          dataSource={cloudPMXFileLst}
          className="parent-cloud-file-tb"
          // rowSelection={rowSelection}
          onRow={(record, index) => {
            return {
              onClick: (event) => {
                onRowSelect(record);
              },
            };
          }}
          pagination={{
            position: ['bottomLeft'],
            total: (record) => record?.length,
            showTotal: (total, range) => `全${total}件中${range[0]}件〜${range[1]}件を表示`,
            defaultPageSize: 25, // 本番値確認必要
            defaultCurrent: 1,
          }}
          scroll={{ y: 'calc(100vh-220px)', x: '950' }}
        />
        {/* </Col> */}
        {/* <Col span={14}>
          <Table
            id="parentCloudFileTbl"
            showSorterTooltip={false}
            columns={cloudFileParentDetailColumns}
            // rowKey={(record) => record.key}
            dataSource={cloudPMXIQ3FileLst}
            className="parent-cloud-file-tb"
            style={{ marginLeft: 10 }}
            // rowSelection={rowSelection}
            pagination={false}
            scroll={{ y: 'calc(100vh-220px)', x: '950' }}
          />
        </Col> */}
      </Row>
      <Row justify="end" style={{ marginTop: -30 }}>
        <Space>
          <Button className="mainButton" onClick={getPMXData}>
            OK
          </Button>
          <Button className="cancelButton" onClick={closePMXModal}>
            キャンセル
          </Button>
        </Space>
      </Row>
    </>
  );
  return (
    <>
      {isPMXCloudFileDialogOpen
        ? commonModal(
            isPMXCloudFileDialogOpen,
            childModalTitle,
            null,
            null,
            1490,
            addData,
            closePMXModal,
            addPMXCloudFileContent,
            null,
            // { left: 180, top: 50 },
            false
          )
        : ''}
    </>
  );
});

export default PMX_CloudFileDialog_Tab_Parent;
