/**
 * クラス名：係数パラメータ
 * 説明：係数情報一覧画面
 * 作成者：ケイ
 * 作成日：2023/05/01
 * バージョン：1.0
 */
import { Table, Form, Button, Row, Input, Col, Modal, Select, Checkbox, Space, Image, Radio, DatePicker } from 'antd';
import { Table as RTable } from 'react-bootstrap';
import { CSVLink, CSVDownload } from 'react-csv';
import React, { useRef, useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import { Outlet, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { StatusCodes } from 'http-status-codes';

import customParseFormat from 'dayjs/plugin/customParseFormat';
import { CloseOutlined, CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import SplitPane, { Pane } from 'split-pane-react';
import 'split-pane-react/esm/themes/default.css';
import { getCompanyInfo } from '../../../common/CommonAPI';
import { ErrorMessage } from '../../../common/Message';

import {
  confirmHakiContent,
  confirmUpdateContent,
  confirmDiscardContent,
  confirmCreateContent,
  confirmModalTitle,
  confirmDeleteContent,
  confirmDiscardAndDeleteContent,
  commonModal,
  commonFooter,
  authorizeError,
  error,
  commonDeleteFooter,
} from '../../../common/CommonModal';
import CoefficientDetails from './CoefficientDetails';
import {
  dataStateList,
  today,
  TextArea,
  getAccessToken,
  updateAccessToken,
  formValidatorMode,
  handleKeyPress,
  toDecimal,
} from '../../../common/Common';
import checked from '../../../../assets/images/checked.png';

dayjs.extend(customParseFormat);
const RadioGroup = Radio.Group;
const dateFormat = 'YYYY-MM-DD';

const columns = [
  {
    id: '1',
    title: 'No',
    dataIndex: 'no',
    width: 50,
    render: (id, record, index) => {
      return index + 1;
    },
    sorter: (a, b) => a.no - b.no,
    sortOrder: 'ascend',
    className: 'cm-a-right',
  },

  {
    id: '2',
    title: 'コード',
    dataIndex: 'code',
    width: 120,
  },
  {
    id: '3',
    title: '名称',
    dataIndex: 'name',
    width: 150,
  },
  {
    id: '4',
    title: '係数',
    dataIndex: 'coeff',
    width: 100,
    className: 'cm-a-right',
    render: (_, record) => <label style={{ fontSize: '13px' }}>{toDecimal(record.coeff, 2)}</label>,
  },

  {
    id: '5',
    title: 'ソート番号',
    dataIndex: 'sortNum',
    width: 95,
    className: 'cm-a-right',
  },
  {
    id: '6',
    title: '作成日',
    dataIndex: 'created',
    render: (id, record, index) => {
      return record['created']?.split('T')[0];
    },
    width: 100,
  },
  {
    id: '7',
    title: '更新日',
    dataIndex: 'modified',
    render: (id, record, index) => {
      return record['modified']?.split('T')[0];
    },
    width: 100,
  },
  {
    id: '8',
    title: '備考',
    dataIndex: 'remarks',
    width: 200,
  },
  {
    id: '9',
    title: '表示',
    dataIndex: 'isUsed',
    width: 95,
    render: (index, item) => {
      return item['isUsed'] ? (
        <Image
          preview={false}
          width={13}
          src={checked}
          style={{ marginLeft: '3px' }}
          /* key={item['key']} */
          id={item['id']}
        />
      ) : (
        <></>
      );
    },
    className: 'cm-a-center',
  },
  {
    id: '10',
    title: '',
    dataIndex: 'tableNo',
    hidden: true,
  },
]?.filter((item) => !item.hidden);

const deliTimeColumns = [
  {
    id: '1',
    title: 'No',
    dataIndex: 'no',
    width: 50,
    render: (id, record, index) => {
      return index + 1;
    },
    sorter: (a, b) => a.no - b.no,
    sortOrder: 'ascend',
    className: 'cm-a-right',
  },

  {
    id: '2',
    title: 'コード',
    dataIndex: 'code',
    width: 120,
  },
  {
    id: '3',
    title: '名称',
    dataIndex: 'name',
    width: 150,
  },
  {
    id: '4',
    title: '係数',
    dataIndex: 'coeff',
    width: 100,
    className: 'cm-a-right',
    render: (_, record) => <label style={{ fontSize: '13px' }}>{toDecimal(record.coeff, 2)}</label>,
  },
  /* {
    id: '5',
    title: '目安日数',
    dataIndex: 'daysTh',
    width: 100,
    className: 'cm-a-right',
  }, */

  {
    id: '6',
    title: 'ソート番号',
    dataIndex: 'sortNum',
    width: 95,
    className: 'cm-a-right',
  },
  {
    id: '7',
    title: '作成日',
    dataIndex: 'created',
    render: (id, record, index) => {
      return record['created']?.split('T')[0];
    },
    width: 100,
  },
  {
    id: '8',
    title: '更新日',
    dataIndex: 'modified',
    render: (id, record, index) => {
      return record['modified']?.split('T')[0];
    },
    width: 100,
  },
  {
    id: '9',
    title: '備考',
    dataIndex: 'remarks',
    width: 200,
  },
  {
    id: '10',
    title: '表示',
    dataIndex: 'isUsed',
    width: 95,
    render: (index, item) => {
      return item['isUsed'] ? (
        <Image preview={false} width={13} src={checked} style={{ marginLeft: '3px' }} id={item['id']} />
      ) : (
        <></>
      );
    },
    className: 'cm-a-center',
  },
]?.filter((item) => !item.hidden);

const tableColumns = columns?.map((item) => ({
  ...item,
}));

const deliTimeTableColumns = deliTimeColumns?.map((item) => ({
  ...item,
}));

const CoefficientParamList = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    setSplitPaneSize: setSplitPaneSize,
    createNewProcess: createNewProcess,
    showXMLInputModal: showXMLInputModal,
    bulkEditProcess: bulkEditProcess,
    deleteProcess: deleteProcess,
    showCSVInputModal: showCSVInputModal,
    getEditMode: editMode,
    updateEditMode: setEditMode,
    discardChange: discardChange,
    setDiscardChangeMode: setDiscardChangeMode,
    setEditModeCancelConfirm: setEditModeCancelConfirm,
  }));

  const inputItemRef = {};

  let checkedRow = [];
  const detailRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  // 破壊ポップアップを表示させる処理タイプ
  const discardChange = [
    { no: 1, state: 'rowSelect' },
    { no: 2, state: 'search' },
    { no: 3, state: 'bulkEdit' },
    { no: 4, state: 'createNew' },
  ];
  const coefficientType = [
    { value: 1, label: '受注係数' },
    { value: 2, label: '納期係数' },
    { value: 3, label: '購入品係数' },
    { value: 4, label: '管理係数' },
  ];

  const [paramSelected, setParamSelected] = useState('coefficient');
  const [paramType, setParamType] = useState('common');
  const [orderCoeffData, setOrderCoeffData] = useState([]);
  const [orderCoeffDataTmp, setOrderCoeffDataTmp] = useState([]);
  const [orderFilteredData, setOrderFilteredData] = useState([]);
  const [deliTimeCoeffData, setDeliTimeCoeffData] = useState([]);
  const [deliTimeCoeffDataTmp, setDeliTimeCoeffDataTmp] = useState([]);
  const [deliTimeFilteredData, setDeliTimeFilteredData] = useState([]);
  const [purchaseCoeffData, setPurchaseCoeffData] = useState([]);
  const [purchaseCoeffDataTmp, setPurchaseCoeffDataTmp] = useState([]);
  const [purchaseFilteredData, setPurchaseFilteredData] = useState([]);
  const [maintainCoeffData, setMaintainCoeffData] = useState([]);
  const [maintainCoeffDataTmp, setMaintainCoeffDataTmp] = useState([]);
  const [maintainFilteredData, setMaintainFilteredData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [tempCoefficientData, setTempCoefficientData] = useState([]);

  const [editMode, setEditMode] = useState(false);
  // 発生した処理タイプ
  const [discardChangeMode, setDiscardChangeMode] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [changeRecordKey, setChangeRecordKey] = useState(0);
  const [changeTableNo, setChangeTableNo] = useState();

  const [display, setDisplay] = useState();
  const [code, setCode] = useState('');
  const [name, setName] = useState('');
  const [coefficient, setCoefficient] = useState('');
  const [estimateDays, setEstimateDays] = useState('');
  const [remarks, setRemarks] = useState('');
  const [sortNo, setSortNo] = useState('');
  const [created, setCreated] = useState('');
  const [updated, setUpdated] = useState(today);
  const [tableNo, setTableNo] = useState(1);

  const [dataState, setDataState] = useState(0);
  const [keyword, setKeyWord] = useState('');

  const [outsourcingChecked, setOutsourcingChecked] = useState(false);
  const [invalidFlgChecked, setInvalidFlgChecked] = useState(false);
  const [hasCheckData, setHasCheckData] = useState(true);
  const [chkData, setChkData] = useState(0);
  const [selectedRowsData, setSelectedRowsData] = useState([]);

  const [isBulkEdit, setIsBulkEdit] = useState(false);
  const [isCreateNew, setIsCreateNew] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [visibleCSVInput, setVisibleCSVInput] = useState(false);
  const [visibleCSVOutput, setVisibleCSVOutput] = useState(false);
  const [visibleXMLInput, setVisibleXMLInput] = useState(false);
  const [childTitle, setChildTitle] = useState('');

  const [createConfirm, setCreateConfirm] = useState(false);
  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [updateConfirm, setUpdateConfirm] = useState(false);
  const [editModeCancelConfirm, setEditModeCancelConfirm] = useState(false);

  // 一括編集
  const [chkrowscount, setChkRowsCount] = useState(0);
  const [bulkEditOrdSelectedRow, setBulkEditOrdSelectedRow] = useState([]);
  const [bulkEditDltSelectedRow, setBulkEditDltSelectedRow] = useState([]);
  const [bulkEditPurSelectedRow, setBulkEditPurSelectedRow] = useState([]);
  const [bulkEditMainSelectedRow, setBulkEditMainSelectedRow] = useState([]);
  const [bulkEditOrdSelectedKeys, setBulkEditOrdSelectedKeys] = useState([]);
  const [bulkEditDltSelectedKeys, setBulkEditDltSelectedKeys] = useState([]);
  const [bulkEditPurSelectedKeys, setBulkEditPurSelectedKeys] = useState([]);
  const [bulkEditMainSelectedKeys, setBulkEditMainSelectedKeys] = useState([]);

  const [isOrderCoeff, setIsOrderCoeff] = useState(true);
  const [isDeliTimeCoeff, setIsDeliTimeCoeff] = useState(true);
  const [isPurchaseCoeff, setIsPurchaseCoeff] = useState(true);
  const [isMaintainCoeff, setIsMaintainCoeff] = useState(true);

  const [isBulkEditOrderCoeff, setIsBulkEditOrderCoeff] = useState(true);
  const [isBulkEditDeliTimeCoeff, setIsBulkEditDeliTimeCoeff] = useState(true);
  const [isBulkEditPurchaseCoeff, setIsBulkEditPurchaseCoeff] = useState(true);
  const [isBulkEditMaintainCoeff, setIsBulkEditMaintainCoeff] = useState(true);

  // 新規作成
  const [coeffType, setCoeffType] = useState(1);

  // Content Resize
  const [sizes, setSizes] = useState(['60%']);

  // 取引先情報をDBから取得する
  async function getCoefficientData() {
    let tempData = [];
    let selectedData = [];
    let orderCoeffDatas = [];
    let deliTimeCoeffDatas = [];
    let maintainCoeffDatas = [];
    let coeffPurchases = [];
    try {
      tempData = await getCompanyInfo();
      orderCoeffDatas = tempData?.length > 0 ? tempData[0].coeffOrders?.filter((item) => !item.isDeleted) : [];

      selectedData = orderCoeffDatas;
      deliTimeCoeffDatas =
        tempData?.length > 0 ? tempData[0]?.coeffDeliveryDates?.filter((item) => !item.isDeleted) : [];
      maintainCoeffDatas = tempData?.length > 0 ? tempData[0]?.coeffManagements?.filter((item) => !item.isDeleted) : [];
      coeffPurchases = tempData?.length > 0 ? tempData[0]?.coeffPurchases?.filter((item) => !item.isDeleted) : [];

      setDeliTimeCoeffData(deliTimeCoeffDatas);
      setDeliTimeFilteredData(deliTimeCoeffDatas);

      setPurchaseCoeffData(coeffPurchases);
      setPurchaseFilteredData(coeffPurchases);

      setMaintainCoeffData(maintainCoeffDatas);
      setMaintainFilteredData(maintainCoeffDatas);

      setOrderCoeffData(orderCoeffDatas);
      setOrderFilteredData(orderCoeffDatas);

      let rowSelectTbl = [];
      let tbl;
      let lastKey;

      switch (true) {
        case orderCoeffDatas?.length != 0 && orderCoeffDatas?.filter((item) => item.isUsed)?.length != 0:
          rowSelectTbl = orderCoeffDatas?.filter((item) => item.isUsed);
          tbl = 1;
          lastKey = rowSelectTbl[0]?.id;
          break;
        case deliTimeCoeffDatas?.length != 0 && deliTimeCoeffDatas?.filter((item) => item.isUsed)?.length != 0:
          rowSelectTbl = deliTimeCoeffDatas?.filter((item) => item.isUsed);
          tbl = 2;
          lastKey = rowSelectTbl[0]?.id;
          break;
        case coeffPurchases?.length != 0 && coeffPurchases?.filter((item) => item.isUsed)?.length != 0:
          rowSelectTbl = coeffPurchases?.filter((item) => item.isUsed);
          tbl = 3;
          lastKey = rowSelectTbl[0]?.id;
          break;
        case maintainCoeffDatas?.length != 0 && maintainCoeffDatas?.filter((item) => item.isUsed)?.length != 0:
          rowSelectTbl = maintainCoeffDatas?.filter((item) => item.isUsed);
          tbl = 4;
          lastKey = rowSelectTbl[0]?.id;
          break;
        default:
          rowSelectTbl = orderCoeffDatas;
          tbl = 1;
          lastKey = rowSelectTbl[0]?.id;
      }

      let record = getSelectedData(rowSelectTbl, lastKey);
      onRowSelectWithoutEditMode(record, tbl);

      setSelectedRowKeys(lastKey);
      setSelectedData(record);
      setSelectedRowsData(rowSelectTbl);
      setTableNo(tbl);
      setDataState(1);

      navigate(':common.coefficient');
      setParamSelected('coefficient');
      setParamType('common');
      setIsOrderCoeff(true);
      props.setParam(paramType, paramSelected);
    } catch (e) {
      error(e.message);
      return;
    }
  }

  useEffect(() => {
    getCoefficientData();
  }, [paramType, paramSelected]);

  /* useEffect(() => {
    let tempData = [];
    let selectedData = [];

    tempData =
      localStorage.getItem('paramCommonOrderCoeff') == undefined
        ? []
        : JSON.parse(localStorage.getItem('paramCommonOrderCoeff'));
    selectedData = tempData;
    setOrderCoeffData(tempData);
    setOrderFilteredData(tempData);
    // setSelectedRowKeys(tempData?.length);
    // setSelectedData(selectedData[selectedData?.length - 1]);
    setSelectedRowKeys(1);
    setSelectedData(selectedData != undefined && selectedData?.length > 0 ? selectedData[1] : []);
    setSelectedRowsData(tempData);
    setTableNo(1);
    setDataState(1);

    tempData = [];
    tempData =
      localStorage.getItem('paramCommonDeliTimeCoeff') == undefined
        ? []
        : JSON.parse(localStorage.getItem('paramCommonDeliTimeCoeff'));
    setDeliTimeCoeffData(tempData);
    setDeliTimeFilteredData(tempData);

    tempData = [];
    tempData =
      localStorage.getItem('paramCommonPurchaseCoeff') == undefined
        ? []
        : JSON.parse(localStorage.getItem('paramCommonPurchaseCoeff'));
    setPurchaseCoeffData(tempData);
    setPurchaseFilteredData(tempData);

    tempData = [];
    tempData =
      localStorage.getItem('paramCommonMaintainCoeff') == undefined
        ? []
        : JSON.parse(localStorage.getItem('paramCommonMaintainCoeff'));
    setMaintainCoeffData(tempData);
    setMaintainFilteredData(tempData);

    navigate(':common.coefficient');
    setParamSelected('coefficient');
    setParamType('common');
    setIsOrderCoeff(true);
    props.setParam(paramType, paramSelected);
  }, []); */

  // フラグ又はキーワードに変更された場合、
  useEffect(() => {
    // 絞り込み、
    getDataByFilter();
  }, [keyword, dataState, orderCoeffDataTmp, deliTimeCoeffDataTmp, purchaseCoeffDataTmp, maintainCoeffDataTmp]);

  // 編集中破棄確認ポップアップに「OK」又は「キャンセル」を押下
  useEffect(() => {
    if (!editMode && !editModeCancelConfirm) {
      let tempData = [];
      switch (discardChangeMode?.no) {
        case discardChange[0]?.no: // 編集中に行を選択した場合、
          tempData = getDataByType(changeTableNo);
          setTableNo(changeTableNo);
          setSelectedRowKeys(changeRecordKey);
          setSelectedData(getSelectedData(tempData, changeRecordKey));
          break;
        case discardChange[1]?.no: // 検索
          getDataByFilter();
          break;
        case discardChange[2]?.no: // 一括編集
        case discardChange[3]?.no: // 新規作成
          tempData = getDataByType(tableNo);
          setSelectedData(getSelectedData(tempData, selectedRowKeys));
          discardChangeMode?.no === discardChange[2]?.no ? bulkEditProcess() : createNewProcess();
          break;
      }
    }
  }, [editModeCancelConfirm]);

  // 一括編集の行チェックボックスを押下
  useEffect(() => {
    let chkCount =
      bulkEditOrdSelectedRow?.length +
      bulkEditDltSelectedRow?.length +
      bulkEditPurSelectedRow?.length +
      bulkEditMainSelectedRow?.length;
    setChkRowsCount(chkCount);
  }, [bulkEditOrdSelectedRow, bulkEditDltSelectedRow, bulkEditPurSelectedRow, bulkEditMainSelectedRow]);

  const setSplitPaneSize = (splitSize) => {
    setSizes(splitSize);
  };

  // 入力項目情報
  const inputFormItem = (
    <RTable id="param_detail_tbl" className="propertiesTbl">
      <thead>
        {/* 種類名 */}
        {isCreateNew ? (
          <tr className="detail-tbl-row">
            <td className="param-detail-tbl-val-title">
              <label className="param_detail-title-label">係数種類名</label>
            </td>
            <td style={{ verticalAlign: 'middle' }}>
              <RadioGroup
                name="coeffType"
                defaultValue={coeffType}
                className="radioCustomerGpEdit"
                onChange={(e) => setCoeffType(e.target.value)}
                value={coeffType}
              >
                {coefficientType?.map((item, index) => (
                  <Radio key={index} value={item.value}>
                    {item.label}
                  </Radio>
                ))}
              </RadioGroup>
            </td>
          </tr>
        ) : (
          <></>
        )}

        {/* コード */}
        <tr className="detail-tbl-row">
          <td className="param-detail-tbl-val-title">
            <label className="param_detail-title-label">コード</label>
          </td>
          <td style={{ verticalAlign: 'middle' }}>
            <Input
              className={'input-editable'}
              name="code"
              id="code"
              value={code}
              type="text"
              ref={(ref) => {
                inputItemRef['code'] = ref;
              }}
              maxLength={50}
              onChange={(e) => setCode(e.target.value)}
            />
          </td>
        </tr>
        {/* 名称 */}
        <tr className="detail-tbl-row">
          <td className="param-detail-tbl-val-title">
            <label className="param_detail-title-label">名称</label>
          </td>
          <td style={{ verticalAlign: 'middle' }}>
            <Input
              className={'input-editable'}
              name="name"
              id="name"
              value={name}
              type="text"
              ref={(ref) => {
                inputItemRef['name'] = ref;
              }}
              maxLength={50}
              onChange={(e) => setName(e.target.value)}
            />
          </td>
        </tr>
        {/* 係数 */}
        <tr className="detail-tbl-row">
          <td className="param-detail-tbl-val-title">
            <label className="param_detail-title-label">係数</label>
          </td>
          <td style={{ verticalAlign: 'middle' }}>
            <Input
              className={'input-editable'}
              name="coeff"
              id="coeff"
              value={coefficient}
              type="text"
              ref={(ref) => {
                inputItemRef['coeff'] = ref;
              }}
              maxLength={Number.MAX_SAFE_INTEGER}
              onChange={(e) => setCoefficient(e.target.value)}
              onKeyPress={(e) => handleKeyPress(e, false)}
            />
          </td>
        </tr>

        {/* ソート番号 */}
        <tr className="detail-tbl-row">
          <td className="param-detail-tbl-val-title">
            <label className="param_detail-title-label">ソート番号</label>
          </td>
          <td style={{ verticalAlign: 'middle' }}>
            <Input
              className={'input-editable'}
              name="sortNo"
              id="sortNo"
              value={sortNo}
              type="text"
              ref={(ref) => {
                inputItemRef['sortNum'] = ref;
              }}
              maxLength={Number.MAX_SAFE_INTEGER}
              onChange={(e) => setSortNo(e.target.value)}
              onKeyPress={(e) => handleKeyPress(e, true)}
            />
          </td>
        </tr>
        {/* 作成日 更新日 */}
        {isBulkEdit ? (
          ''
        ) : (
          <>
            <tr className="detail-tbl-row">
              <td className="param-detail-tbl-val-title">
                <label className="param_detail-title-label">作成日</label>
              </td>
              <td style={{ verticalAlign: 'middle' }}>
                <label className="param_detail-title-label">{created}</label>
              </td>
            </tr>

            <tr className="detail-tbl-row">
              <td className="param-detail-tbl-val-title">
                <label className="param_detail-title-label">更新日</label>
              </td>
              <td style={{ verticalAlign: 'middle' }}>
                <label className="param_detail-title-label">{updated}</label>
              </td>
            </tr>
          </>
        )}
        {/* 備考 */}
        <tr className="detail-tbl-row">
          <td className="param-detail-tbl-val-title">
            <label className="param_detail-title-label">備考</label>
          </td>
          <td style={{ verticalAlign: 'middle' }}>
            <TextArea
              className={'input-editable'}
              name="remarks"
              id="remarks"
              value={remarks}
              type="text"
              maxLength={2048}
              style={{ margin: 5, padding: 5, width: '98.3%' }}
              onChange={(e) => setRemarks(e.target.value)}
            />
          </td>
        </tr>
        {/* 表示 */}
        <tr className="detail-tbl-row">
          <td className="param-detail-tbl-val-title">
            <label className="param_detail-title-label">表示</label>
          </td>
          <td style={{ verticalAlign: 'middle' }}>
            <RadioGroup
              name="display"
              defaultValue={display}
              className="radioCustomerGpEdit"
              onChange={(e) => setDisplay(e.target.value)}
              value={display}
            >
              <Radio value={true}>オン</Radio>
              <Radio value={false}>オフ</Radio>
            </RadioGroup>
          </td>
        </tr>
      </thead>
    </RTable>
  );

  // 選択中行のデータを取得する
  const getSelectedData = (data, key) => {
    let ret = [];
    let record;
    record = data?.find((item) => item.id === key);
    return record ? record : ret;
  };

  // 一覧画面の行を選択した場合、
  const onRowSelect = (record, tblNo) => {
    let selectedData = [];
    selectedData.push({
      id: record.id,
      isUsed: record.isUsed,
      code: record.code,
      name: record.name,
      coeff: record.coeff,
      estimateDays: record.estimateDays,
      remarks: record.remarks,
      sortNum: record.sortNum,
      created: record.created,
      modified: record.modified,
    });
    setChangeRecordKey(record.id);
    setChangeTableNo(tblNo);
    if (editMode) {
      setDiscardChangeMode(discardChange[0]);
      setEditModeCancelConfirm(true);
    } else {
      setTableNo(tblNo);
      setSelectedRowKeys(record.id);
      setSelectedData(selectedData[0]);
      navigate(':common.coefficient');
    }
  };

  // 一覧画面の行を選択した場合、
  const onRowSelectWithoutEditMode = (record, tblNo) => {
    let selectedData = [];
    selectedData.push({
      id: record.id,
      isUsed: record.isUsed,
      code: record.code,
      name: record.name,
      isUsed: record.isUsed,
      estimateDays: record.estimateDays,
      remarks: record.remarks,
      sortNum: record.sortNum,
      created: record.created,
      modified: record.modified,

      // tableNo: record.tableNo,
    });
    setChangeRecordKey(record.id);
    setChangeTableNo(tblNo);

    setTableNo(tblNo);
    setSelectedRowKeys(record.id);
    setSelectedData(record /* selectedData[0] */);
    navigate(':common.coefficient');
  };

  const handleOkCSVInput = (e) => {
    setVisibleCSVInput(false);
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = '.csv';
    input.onchange = (_) => {
      let files = Array.from(input.files);
    };
    input.click();
  };

  const handleOkXMLInput = (e) => {
    setVisibleXMLInput(false);
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = '.xml';
    input.onchange = (_) => {
      let files = Array.from(input.files);
    };
    input.click();
  };

  // 削除ボタンを押下
  const deleteProcess = () => {
    setIsDelete(true);
    setChildTitle('削除');
  };

  // 削除確認ポップアップのOKボタンを押下
  const deleteOk = async (e) => {
    if (isDelete) {
      setIsDelete(false);
      setEditMode(false);
      let selectedKey = selectedRowKeys;
      let deletedData = getRelatedCoeff(await deleteCoeffDB(selectedKey, tableNo), tableNo);
      let filterdData =
        deletedData !== undefined && deletedData?.length > 0 ? deletedData?.filter((item) => !item.isDeleted) : [];
      setFilteredDataByType(tableNo, filterdData);

      let currentData = filterdData;
      setDataByType(tableNo, currentData);

      // 一番上のテーブルの最初の行を選択
      let rowSelectTbl = [];
      let tbl;
      outerLoop: for (let i = 1; i <= 4; i++) {
        if (i === tableNo && filterdData?.length <= 0) {
          continue outerLoop;
        }
        if (getDataByType(i)?.length > 0) {
          rowSelectTbl = getDataByType(i);
          tbl = i;
          break outerLoop;
        }
      }
      let lastKey = rowSelectTbl[0].id;
      let record = getSelectedData(rowSelectTbl, lastKey);
      onRowSelectWithoutEditMode(record, tbl);
    }
  };

  // 係数情報をDBに削除するt
  async function deleteCoeffDB(id, tblNo) {
    let ret = [];
    try {
      const Url = process.env.REACT_APP_BACKEND_API_URL + 'Company/' + id + '/tbl/' + tblNo;

      const otherParam = {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + getAccessToken(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          return await response.json();
        })
        .then((data) => {
          if (data && data?.updateToken) {
            // セクションにトークン情報を更新する
            updateAccessToken(data?.updateToken);
          }
          if (data && data.statusCode === StatusCodes.OK) {
            ret = data.data[0];
          } else if (
            data &&
            (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)
          ) {
            authorizeError(ErrorMessage()[data?.messageCode]);
            return;
          } else if (data && data.statusCode !== StatusCodes.OK) {
            error(ErrorMessage()[data?.messageCode]);
            return;
          }
        })
        .catch((e) => {
          authorizeError(ErrorMessage().E010);
          return;
        });
    } catch (e) {
      error(e.message);
      return;
    }
    return ret;
  }

  // 削除確認ポップアップのCandelボタンを押下
  const deleteCancel = (e) => {
    setIsDelete(false);
  };

  const showCSVInputModal = () => {
    setVisibleCSVInput(true);
    setChildTitle('CSV入力');
  };

  const showXMLInputModal = () => {
    setVisibleXMLInput(true);
    setChildTitle('XML入力');
  };

  const resetInputInfo = () => {
    setCoeffType(1);
    setDisplay();
    setCode('');
    setName('');
    setCoefficient('');
    setEstimateDays('');
    setRemarks('');
    setSortNo();
    setCreated('');
    setUpdated(today);
  };

  // 表示を押下の場合、
  const searchByDataState = (value) => {
    dataState === value ? getDataByFilter() : setDataState(value);
  };

  // キーワードで検索した場合、
  const searchByKeyword = (e) => {
    keyword === e.target.value ? getDataByFilter() : setKeyWord(e.target.value);
  };

  // 絞り込み、
  const getDataByFilter = () => {
    let searchBarText = keyword;
    let flag;
    let currentData = [];
    let currentFilterData = [];
    let getAllFilteredData = [];
    let isDataExist = false;
    for (let i = 1; i <= 4; i++) {
      currentData = getDataByType(i);
      /* if (currentData?.length <= 0) {
        return;
      } */
      currentFilterData = currentData;
      // キーワードで絞り込み
      if (searchBarText != '') {
        currentFilterData = currentData?.filter(
          (item) =>
            item.code?.toLowerCase().indexOf(searchBarText) >= 0 ||
            item.name?.toLowerCase().indexOf(searchBarText) >= 0 ||
            item.coefficient?.toLowerCase().indexOf(searchBarText) >= 0 ||
            item.estimateDays?.toLowerCase().indexOf(searchBarText) >= 0 ||
            item.remarks?.toLowerCase().indexOf(searchBarText) >= 0 ||
            item.created?.toLowerCase().indexOf(searchBarText) >= 0 ||
            item.updated?.toLowerCase().indexOf(searchBarText) >= 0
        );
      }

      //  表示で絞り込み
      if (dataState === 1 || dataState === 2) {
        dataState === 1 ? (flag = true) : (flag = false);
        currentFilterData = currentFilterData?.filter((item) => item.isUsed === flag);
      }

      // 「費用・ラベル・メモ・日付」を絞り込んだ、纏め情報
      getAllFilteredData.push({ id: i, data: currentFilterData });
    }

    let index = getAllFilteredData?.findIndex((item) => item.key === selectedRowKeys && item.tableNo === tableNo);
    // 検索した結果に、選択中行のKeyが無い場合、
    /*  if (index === -1) {
      // 編集中である場合、
      if (editMode) {
        setDiscardChangeMode(discardChange[1]);
        setEditModeCancelConfirm(true);
        return;
      }
      // 検索結果がある場合、
      if (getAllFilteredData?.length > 0) {
        index = getAllFilteredData?.length - 1;
        setTableNo(getAllFilteredData[index]?.tableNo);
        setSelectedRowKeys(getAllFilteredData[index]?.key);
        scrollTo('table' + getAllFilteredData[index]?.tableNo);
      }
    } 

    // 検索結果の有無チェック、
    getAllFilteredData?.length > 0 ? setSelectedData(getAllFilteredData[index]) : setSelectedData(getAllFilteredData);*/

    // 一覧に表示するデータ
    if (getAllFilteredData !== undefined && getAllFilteredData?.length > 0) {
      for (let i = 0; i < 4; i++) {
        //let tmpData = getAllFilteredData?.filter((item) => item.tableNo === i);
        setFilteredDataByType(getAllFilteredData[i].id, getAllFilteredData[i].data);
      }
    }
  };

  // Scrollの移動
  const scrollTo = (index) => {
    // Scrollの移動
    document.getElementById(index)?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  };

  const reportModalTitle = (
    <div
      style={{
        width: 520,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>確認</p>
    </div>
  );

  const editModalTitle = (
    <div
      style={{
        width: 1300,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{childTitle}</p>
    </div>
  );

  const addNewModalTitle = (
    <div
      style={{
        width: 700,
        backgroundColor: '#005fab',
        marginLeft: -10,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{childTitle}</p>
    </div>
  );

  // 確認ポップアップのOKボタン押下
  const confirmOk = async (e) => {
    let tempCoefficientData = prepareCreateNewInfo();
    let bulkEditSelectedRow = [
      { key: 1, data: bulkEditOrdSelectedRow, tableNo: 1 },
      { key: 2, data: bulkEditDltSelectedRow, tableNo: 2 },
      { key: 3, data: bulkEditPurSelectedRow, tableNo: 3 },
      { key: 4, data: bulkEditMainSelectedRow, tableNo: 4 },
    ];
    setCreateConfirm(false);
    if (isCreateNew) {
      if (!detailRef.current.formValidation(inputItemRef, formValidatorMode.Create)) {
        return;
      }

      let lastIndex = tempCoefficientData?.length - 1;
      let tempData = tempCoefficientData[lastIndex];
      let createdData = getRelatedCoeff(await insertCoeffDB(tempData), tempData.tableNo);
      // let currentFilterData = getFilteredDataByType(coeffType);
      setDataByType(tempData.tableNo, createdData);
      setFilteredDataByType(
        tempData.tableNo,
        createdData?.filter((item) => !item.isDeleted)
      );
      if (createdData !== undefined && createdData?.length > 0) {
        onRowSelectWithoutEditMode(createdData[createdData?.length - 1], tempData.tableNo);
      }
      setIsCreateNew(false);
      scrollTo('table' + tempData.tableNo);
    } else if (isBulkEdit) {
      setUpdateConfirm(false);
      // 画面の入力チェック
      if (!detailRef.current.formValidation(inputItemRef, formValidatorMode.BulkEdit)) {
        return;
      }
      // 一括編集画面から遷移された場合、
      setIsBulkEdit(false);
      // 選択された全ての対象行を更新、
      for (let i = 0; i < bulkEditSelectedRow?.length; i++) {
        let bulkEditSelected = bulkEditSelectedRow[i].data;
        if (bulkEditSelected?.length > 0) {
          for (let j = 0; j < bulkEditSelected?.length; j++) {
            let item = bulkEditSelected[j];
            if (display === true || display === false) item.isUsed = display;
            if (code) item.code = code;
            if (name) item.name = name;
            if (coefficient) item.coefficient = coefficient;
            if (remarks) item.remarks = remarks;
            if (sortNo) item.sortNum = sortNo;
            item.updated = today;
            await submitData(item, bulkEditSelectedRow[i].tableNo);
          }
        }
      }
    }
    resetInputInfo();
  };

  // 案件状態情報をDBに新規登録する
  async function insertCoeffDB(createData) {
    let ret = [];
    try {
      const Url = process.env.REACT_APP_BACKEND_API_URL + 'Company';
      const otherParam = {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + getAccessToken(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
        body: JSON.stringify({
          tableNo: createData.tableNo,
          companyCoeff: createData.coeffInfo,
          coeffDeliveryDate: createData.coeffInfo,
        }),
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          return await response.json();
        })
        .then((data) => {
          if (data && data?.updateToken) {
            // セクションにトークン情報を更新する
            updateAccessToken(data?.updateToken);
          }
          if (data && data.statusCode === StatusCodes.OK) {
            ret = data.data[0];
          } else if (
            data &&
            (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)
          ) {
            authorizeError(ErrorMessage()[data?.messageCode]);
            return;
          } else if (data && data.statusCode !== StatusCodes.OK) {
            error(ErrorMessage()[data?.messageCode]);
            return;
          }
        })
        .catch((e) => {
          authorizeError(ErrorMessage().E010);
          return;
        });
    } catch (e) {
      error(e.message);
      return;
    }
    return ret;
  }

  // 確認ポップアップのCancelボタン押下
  const confirmCancel = () => {
    setUpdateConfirm(false);
    setCreateConfirm(false);
  };

  // 編集中破壊確認ポップアップのOKボタン押下
  const discardConfirmOk = () => {
    setEditModeCancelConfirm(false);
    detailRef.current.discardConfirmOk();
  };

  // 編集中破壊確認ポップアップのCancelボタン押下
  const discardConfirmCancel = () => {
    setEditModeCancelConfirm(false);
    detailRef.current.discardConfirmCancel();
  };

  // 破壊確認ポップアップのOKボタン押下
  const cancelConfirmOK = () => {
    if (isCreateNew) {
      setIsCreateNew(false);
    } else if (isBulkEdit) {
      setIsBulkEdit(false);
    }
    setCancelConfirm(false);
  };

  // 破壊確認ポップアップのCancelボタン押下
  const cancelConfirmCancel = () => {
    setCancelConfirm(false);
  };

  // 更新確認のOKを押下で、一覧画面のデータを反映する
  const submitData = async (updatedData, tblNo) => {
    var id = updatedData.id;
    var index;
    let updatedInfo = getRelatedCoeff(await updateCompanyData(updatedData, tblNo), tblNo);
    index = updatedInfo?.length > 1 ? updatedInfo?.findIndex((data) => data.id == id) : id;
    setDataByType(tblNo, updatedInfo);
    setFilteredDataByType(tblNo, updatedInfo);
    let data = updatedInfo[index];
    if (id === selectedRowKeys && tableNo === tblNo) detailRef.current?.displayDetailInfo(data);
  };

  // 取引先情報をDBに更新する
  async function updateCompanyData(updateData, tableNo) {
    let ret = [];
    try {
      const Url = process.env.REACT_APP_BACKEND_API_URL + 'Company';
      const otherParam = {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + getAccessToken(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
        body: JSON.stringify({
          tableNo: tableNo,
          companyCoeff: updateData,
          coeffDeliveryDate: {},
        }),
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          return await response.json();
        })
        .then((data) => {
          if (data && data?.updateToken) {
            // セクションにトークン情報を更新する
            updateAccessToken(data?.updateToken);
          }
          if (data && data.statusCode === StatusCodes.OK) {
            ret = data.data[0];
          } else if (
            data &&
            (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)
          ) {
            authorizeError(ErrorMessage()[data?.messageCode]);
            return;
          } else if (data && data.statusCode !== StatusCodes.OK) {
            error(ErrorMessage()[data?.messageCode]);
            return;
          }
        })
        .catch((e) => {
          authorizeError(ErrorMessage().E010);
          return;
        });
    } catch (e) {
      error(e.message);
      return;
    }
    return ret;
  }

  // 受注・納期・購入品・管理によりデータを返す
  const getDataByType = (tableNo) => {
    let tempData = [];
    switch (tableNo) {
      case 1:
        tempData = orderCoeffData !== undefined && orderCoeffData?.length > 0 ? [...orderCoeffData] : [];
        break;
      case 2:
        tempData = deliTimeCoeffData !== undefined && deliTimeCoeffData?.length > 0 ? [...deliTimeCoeffData] : [];
        break;
      case 3:
        tempData = purchaseCoeffData !== undefined && purchaseCoeffData?.length > 0 ? [...purchaseCoeffData] : [];
        break;
      case 4:
        tempData = maintainCoeffData !== undefined && maintainCoeffData?.length > 0 ? [...maintainCoeffData] : [];
        break;
    }
    return tempData;
  };

  // 絞り込んだ「受注・納期・購入品・管理」によりデータを返す
  const getFilteredDataByType = (tableNo) => {
    let tempData = [];
    switch (tableNo) {
      case 1:
        tempData = [...orderFilteredData];
        break;
      case 2:
        tempData = [...deliTimeFilteredData];
        break;
      case 3:
        tempData = [...purchaseFilteredData];
        break;
      case 4:
        tempData = [...maintainFilteredData];
        break;
    }
    return tempData;
  };

  // 絞り込んだ「受注・納期・購入品・管理」によりデータを返す
  const getRelatedCoeff = (companyData, tableNo) => {
    let tmpData = [];
    switch (tableNo) {
      case 1:
        tmpData = companyData.coeffOrders;
        break;
      case 2:
        tmpData = companyData.coeffDeliveryDates;
        break;
      case 3:
        tmpData = companyData.coeffPurchases;
        break;
      case 4:
        tmpData = companyData.coeffManagements;
        break;
    }
    return tmpData;
  };

  // 受注・納期・購入品・管理によりデータを保存する
  const setDataByType = (tableNo, value) => {
    switch (tableNo) {
      case 1:
        setOrderCoeffData(value);
        setOrderCoeffDataTmp(value);
        localStorage.setItem('paramCommonOrderCoeff', JSON.stringify(value));
        break;
      case 2:
        setDeliTimeCoeffData(value);
        setDeliTimeCoeffDataTmp(value);
        localStorage.setItem('paramCommonDeliTimeCoeff', JSON.stringify(value));
        break;
      case 3:
        setPurchaseCoeffData(value);
        setPurchaseCoeffDataTmp(value);
        localStorage.setItem('paramCommonPurchaseCoeff', JSON.stringify(value));
        break;
      case 4:
        setMaintainCoeffData(value);
        setMaintainCoeffDataTmp(value);
        localStorage.setItem('paramCommonMaintainCoeff', JSON.stringify(value));
        break;
    }
  };

  // 絞り込んだ「受注・納期・購入品・管理」によりデータを保存する
  const setFilteredDataByType = (tableNo, value) => {
    switch (tableNo) {
      case 1:
        setOrderFilteredData(value);
        break;
      case 2:
        setDeliTimeFilteredData(value);
        break;
      case 3:
        setPurchaseFilteredData(value);
        break;
      case 4:
        setMaintainFilteredData(value);
        break;
    }
  };

  const clickOrderCoeff = () => {
    setIsOrderCoeff(!isOrderCoeff);
  };

  const clickDeliTimeCoeff = () => {
    setIsDeliTimeCoeff(!isDeliTimeCoeff);
  };

  const clickPurchaseCoeff = () => {
    setIsPurchaseCoeff(!isPurchaseCoeff);
  };

  const clickMaintainCoeff = () => {
    setIsMaintainCoeff(!isMaintainCoeff);
  };

  const clickBulkEditOrderCoeff = () => {
    setIsBulkEditOrderCoeff(!isBulkEditOrderCoeff);
  };

  const clickBulkEditDeliTimeCoeff = () => {
    setIsBulkEditDeliTimeCoeff(!isBulkEditDeliTimeCoeff);
  };

  const clickBulkEditPurchaseCoeff = () => {
    setIsBulkEditPurchaseCoeff(!isBulkEditPurchaseCoeff);
  };

  const clickBulkEditMaintainCoeff = () => {
    setIsBulkEditMaintainCoeff(!isBulkEditMaintainCoeff);
  };

  // 編集モードを変更
  const updateEditMode = (mode) => {
    setEditMode(mode);
    props.updateMainParameterEditMode(mode);
  };

  // 新規作成-開始
  const createNewProcess = () => {
    resetInputInfo();
    setIsCreateNew(true);
    setCreated(today);
    setUpdated(today);
    setDisplay(true);
    setChildTitle('係数（新規作成）');
  };

  const createNewOK = () => {
    setCreateConfirm(true);
  };

  const createNewCancel = (e) => {
    setCancelConfirm(true);
  };

  // 入力した新規情報を取得する
  const prepareCreateNewInfo = () => {
    let tempData = getDataByType(coeffType);
    let lastIndex = tempData?.length - 1;
    let coeffInfo = {
      coeff: coefficient,
      // estimateDays: estimateDays,
      remarks: remarks,
      sortNo: sortNo,
      isUsed: display,
      code: code,
      name: name,
    };
    tempData.push({
      coeffInfo: coeffInfo,
      tableNo: coeffType,
    });
    return tempData;
    // setTempCoefficientData(tempData);
  };

  const createNewTitle = (
    <div
      style={{
        width: 800,
        backgroundColor: '#005fab',
        marginLeft: -10,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{childTitle}</p>
    </div>
  );

  const createNewContent = (
    <>
      <Row style={{ paddingBottom: 0, paddingTop: 0 }}>
        {/** プロパティレイアウト */}
        <Col span={24}>
          <div
            style={{
              height: 475,
              // marginTop: 28,
              fontSize: '13.5px',
              overflowY: 'auto',
              whiteSpace: 'nowrap',
            }}
          >
            {inputFormItem}
          </div>
        </Col>
      </Row>
      <Row
        justify="end"
        style={{
          marginBottom: 0,
          marginTop: 0,
        }}
      >
        <Button className="mainButton" onClick={createNewOK} style={{ marginRight: 5 }}>
          登録
        </Button>
        <Button className="cancelButton" onClick={createNewCancel}>
          破棄
        </Button>
      </Row>
    </>
  );
  // 新規作成-終了

  // 一括編集-開始
  const onRowOrdCheckboxSelect = (checked, record) => {
    checkedRow = [...checked];
    setBulkEditOrdSelectedRow([...record]);
    setBulkEditOrdSelectedKeys(checkedRow);
    navigate(':common.coefficient');
  };

  const onRowDltCheckboxSelect = (checked, record) => {
    checkedRow = [...checked];
    setBulkEditDltSelectedRow([...record]);
    setBulkEditDltSelectedKeys(checkedRow);
    navigate(':common.coefficient');
  };

  const onRowPurCheckboxSelect = (checked, record) => {
    checkedRow = [...checked];
    setBulkEditPurSelectedRow([...record]);
    setBulkEditPurSelectedKeys(checkedRow);
    navigate(':common.coefficient');
  };

  const onRowMainCheckboxSelect = (checked, record) => {
    checkedRow = [...checked];
    setBulkEditMainSelectedRow([...record]);
    setBulkEditMainSelectedKeys(checkedRow);
    navigate(':common.coefficient');
  };

  const bulkEditOrdRowSelect = {
    onChange: onRowOrdCheckboxSelect,
  };

  const bulkEditDltRowSelect = {
    onChange: onRowDltCheckboxSelect,
  };

  const bulkEditPurRowSelect = {
    onChange: onRowPurCheckboxSelect,
  };

  const bulkEditMainRowSelect = {
    onChange: onRowMainCheckboxSelect,
  };

  const bulkEditProcess = () => {
    resetInputInfo();
    setBulkEditOrdSelectedRow([]);
    setBulkEditDltSelectedRow([]);
    setBulkEditPurSelectedRow([]);
    setBulkEditMainSelectedRow([]);
    setBulkEditOrdSelectedKeys([]);
    setBulkEditDltSelectedKeys([]);
    setBulkEditPurSelectedKeys([]);
    setBulkEditMainSelectedKeys([]);
    setChkRowsCount(0);
    setIsBulkEdit(true);
    setChildTitle('係数（選択したデータをまとめて編集）');
  };

  const bulkEditOK = (e) => {
    setUpdateConfirm(true);
  };

  const bulkEditCancel = (e) => {
    setCancelConfirm(true);
  };

  const bulkEditTitle = (
    <div
      style={{
        width: 1790,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{childTitle}</p>
    </div>
  );

  const bulkEditContent = (
    <>
      <Row style={{ paddingBottom: 0, paddingTop: 0 }}>
        {/** 一覧 */}
        <Col span={13}>
          <p style={{ fontSize: '13.5px', marginBottom: 6, marginTop: 0, fontWeight: '700' }}>
            {chkrowscount}件を選択中
          </p>
          <div className="overflow-auto" style={{ height: 750 }}>
            {/* 受注係数 */}
            <Row
              style={{ color: 'black', marginTop: 0 }}
              className="oyabuhin oyabuhin-row"
              onClick={clickBulkEditOrderCoeff}
            >
              <Col span={20} className="deli-col">
                <div style={{ display: 'flex' }}>
                  <label>{coefficientType[0].label}</label>
                  {isBulkEditOrderCoeff ? (
                    <CaretUpOutlined style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }} />
                  ) : (
                    <CaretDownOutlined style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }} />
                  )}
                </div>
              </Col>
            </Row>
            <div style={{ display: isBulkEditOrderCoeff ? 'block' : 'none' }}>
              {/* 一覧アリア */}
              <Row style={{ marginTop: 5 }}>
                <Table
                  showSorterTooltip={false}
                  className="drop-down-tbl"
                  columns={columns}
                  rowKey={(record) => record.id}
                  dataSource={orderFilteredData}
                  scroll={{ y: 250, x: '10vw' }}
                  rowClassName={(record) => (record.key == bulkEditOrdSelectedKeys ? 'active-row' : 'data-row')}
                  rowSelection={bulkEditOrdRowSelect}
                  pagination={false}
                />
              </Row>
            </div>
            {/* 納期係数 */}
            <Row
              style={{ color: 'black', marginTop: 5 }}
              className="oyabuhin oyabuhin-row"
              onClick={clickBulkEditDeliTimeCoeff}
            >
              <Col span={20} className="deli-col">
                <div style={{ display: 'flex' }}>
                  <label>{coefficientType[1].label}</label>
                  {isBulkEditDeliTimeCoeff ? (
                    <CaretUpOutlined style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }} />
                  ) : (
                    <CaretDownOutlined style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }} />
                  )}
                </div>
              </Col>
            </Row>
            <div style={{ display: isBulkEditDeliTimeCoeff ? 'block' : 'none' }}>
              {/* 一覧アリア */}
              <Row style={{ marginTop: 5 }}>
                <Table
                  showSorterTooltip={false}
                  className="drop-down-tbl"
                  columns={deliTimeTableColumns}
                  rowKey={(record) => record.id}
                  dataSource={deliTimeFilteredData}
                  scroll={{ y: 250, x: '10vw' }}
                  rowClassName={(record) => (record.key == bulkEditDltSelectedKeys ? 'active-row' : 'data-row')}
                  rowSelection={bulkEditDltRowSelect}
                  pagination={false}
                />
              </Row>
            </div>
            {/* 購入品係数 */}
            <Row
              style={{ color: 'black', marginTop: 5 }}
              className="oyabuhin oyabuhin-row"
              onClick={clickBulkEditPurchaseCoeff}
            >
              <Col span={20} className="deli-col">
                <div style={{ display: 'flex' }}>
                  <label>{coefficientType[2].label}</label>
                  {isBulkEditPurchaseCoeff ? (
                    <CaretUpOutlined style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }} />
                  ) : (
                    <CaretDownOutlined style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }} />
                  )}
                </div>
              </Col>
            </Row>
            <div style={{ display: isBulkEditPurchaseCoeff ? 'block' : 'none' }}>
              {/* 一覧アリア */}
              <Row style={{ marginTop: 5 }}>
                <Table
                  showSorterTooltip={false}
                  className="drop-down-tbl"
                  columns={columns}
                  rowKey={(record) => record.id}
                  dataSource={purchaseFilteredData}
                  scroll={{ y: 250, x: '10vw' }}
                  rowClassName={(record) => (record.key == bulkEditPurSelectedKeys ? 'active-row' : 'data-row')}
                  rowSelection={bulkEditPurRowSelect}
                  pagination={false}
                />
              </Row>
            </div>
            {/* 管理係数 */}
            <Row
              style={{ color: 'black', marginTop: 5 }}
              className="oyabuhin oyabuhin-row"
              onClick={clickBulkEditMaintainCoeff}
            >
              <Col span={20} className="deli-col">
                <div style={{ display: 'flex' }}>
                  <label>{coefficientType[3].label}</label>
                  {isBulkEditMaintainCoeff ? (
                    <CaretUpOutlined style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }} />
                  ) : (
                    <CaretDownOutlined style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }} />
                  )}
                </div>
              </Col>
            </Row>
            <div style={{ display: isBulkEditMaintainCoeff ? 'block' : 'none' }}>
              {/* 一覧アリア */}
              <Row style={{ marginTop: 5 }}>
                <Table
                  showSorterTooltip={false}
                  className="drop-down-tbl"
                  columns={columns}
                  rowKey={(record) => record.id}
                  dataSource={maintainFilteredData}
                  scroll={{ y: 250, x: '10vw' }}
                  rowClassName={(record) => (record.key == bulkEditMainSelectedKeys ? 'active-row' : 'data-row')}
                  rowSelection={bulkEditMainRowSelect}
                  pagination={false}
                />
              </Row>
            </div>
          </div>
        </Col>
        {/** プロパティレイアウト */}
        <Col span={11}>
          <div
            style={{
              height: 749,
              marginTop: 28,
              fontSize: '13.5px',
              overflowY: 'auto',
              whiteSpace: 'nowrap',
            }}
          >
            {inputFormItem}
          </div>
        </Col>
      </Row>
      <Row
        justify="end"
        style={{
          marginBottom: 0,
          marginTop: -40,
        }}
      >
        <Button className="mainButton" onClick={bulkEditOK} style={{ marginRight: 5 }}>
          更新
        </Button>
        <Button className="cancelButton" onClick={bulkEditCancel}>
          破棄
        </Button>
      </Row>
    </>
  );
  // 一括編集-終了

  return (
    <>
      {/* 一覧とプロパティ表示 */}
      <div
        style={{
          height: '90.8vh',
          marginLeft: 10,
        }}
      >
        <SplitPane split="vertical" sizes={sizes} onChange={setSizes} resizerSize={1}>
          <div style={{ marginTop: 7 }}>
            {/* 検索アリア */}
            <Space size="middle">
              <Input
                className="input-editable search-select-input"
                placeholder="キーワードを入力"
                allowClear
                onPressEnter={searchByKeyword}
                onBlur={searchByKeyword}
                onChange={(e) => {
                  if (e.target.value === '') {
                    searchByKeyword(e);
                  }
                }}
              />
              <Select
                className="search-select"
                onChange={searchByDataState}
                id="dataStateDrop"
                name="dataStateDrop"
                value={dataState}
              >
                {dataStateList?.map((item, index) => (
                  <Select.Option key={index} id={'dataState' + item.value} value={item.value}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Space>

            <div className="overflow-auto kouteical " style={{ height: 830 }}>
              {/* 受注係数 */}
              <Row
                style={{ color: 'black', marginTop: 10 }}
                className="oyabuhin oyabuhin-row"
                onClick={clickOrderCoeff}
              >
                <Col span={20} className="deli-col">
                  <div style={{ display: 'flex' }}>
                    <label>{coefficientType[0].label}</label>
                    {isOrderCoeff ? (
                      <CaretUpOutlined style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }} />
                    ) : (
                      <CaretDownOutlined style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }} />
                    )}
                  </div>
                </Col>
              </Row>
              <div style={{ display: isOrderCoeff ? 'block' : 'none' }}>
                {/* 一覧アリア */}
                <Row style={{ marginTop: 5 }}>
                  <Table
                    showSorterTooltip={false}
                    id="table1"
                    columns={hasCheckData ? tableColumns : chkData}
                    dataSource={orderFilteredData}
                    scroll={{ y: 180, x: '10vw' }}
                    rowClassName={(record) =>
                      record.id == selectedRowKeys && 1 === tableNo ? 'active-row' : 'data-row'
                    }
                    pagination={false}
                    className="drop-down-tbl"
                    onRow={(record, index) => {
                      return {
                        onClick: (event) => {
                          onRowSelect(record, 1);
                          //setChangeTableNo(1);
                        },
                      };
                    }}
                  />
                </Row>
              </div>

              {/* 納期係数 */}
              <Row
                style={{ color: 'black', marginTop: 5 }}
                className="oyabuhin oyabuhin-row"
                onClick={clickDeliTimeCoeff}
              >
                <Col span={20} className="deli-col">
                  <div style={{ display: 'flex' }}>
                    <label>{coefficientType[1].label}</label>
                    {isDeliTimeCoeff ? (
                      <CaretUpOutlined style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }} />
                    ) : (
                      <CaretDownOutlined style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }} />
                    )}
                  </div>
                </Col>
              </Row>
              <div style={{ display: isDeliTimeCoeff ? 'block' : 'none' }}>
                {/* 一覧アリア */}
                <Row style={{ marginTop: 5 }}>
                  <Table
                    showSorterTooltip={false}
                    id="table2"
                    columns={hasCheckData ? deliTimeTableColumns /* columns */ : chkData}
                    dataSource={deliTimeFilteredData}
                    scroll={{ y: 180, x: '10vw' }}
                    rowClassName={(record) =>
                      record.id == selectedRowKeys && 2 === tableNo ? 'active-row' : 'data-row'
                    }
                    pagination={false}
                    className="drop-down-tbl"
                    onRow={(record, index) => {
                      return {
                        onClick: (event) => {
                          onRowSelect(record, 2);
                          // setChangeTableNo(2);
                        },
                      };
                    }}
                  />
                </Row>
              </div>

              {/* 購入品係数 */}
              <Row
                style={{ color: 'black', marginTop: 5 }}
                className="oyabuhin oyabuhin-row"
                onClick={clickPurchaseCoeff}
              >
                <Col span={20} className="deli-col">
                  <div style={{ display: 'flex' }}>
                    <label>{coefficientType[2].label}</label>
                    {isPurchaseCoeff ? (
                      <CaretUpOutlined style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }} />
                    ) : (
                      <CaretDownOutlined style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }} />
                    )}
                  </div>
                </Col>
              </Row>
              <div style={{ display: isPurchaseCoeff ? 'block' : 'none' }}>
                {/* 一覧アリア */}
                <Row style={{ marginTop: 5 }}>
                  <Table
                    showSorterTooltip={false}
                    id="table3"
                    columns={hasCheckData ? tableColumns : chkData}
                    dataSource={purchaseFilteredData}
                    scroll={{ y: 180, x: '10vw' }}
                    rowClassName={(record) =>
                      record.id == selectedRowKeys && 3 === tableNo ? 'active-row' : 'data-row'
                    }
                    pagination={false}
                    className="drop-down-tbl"
                    onRow={(record, index) => {
                      return {
                        onClick: (event) => {
                          onRowSelect(record, 3);
                          // setChangeTableNo(3);
                        },
                      };
                    }}
                  />
                </Row>
              </div>

              {/* 管理係数 */}
              <Row
                style={{ color: 'black', marginTop: 5 }}
                className="oyabuhin oyabuhin-row"
                onClick={clickMaintainCoeff}
              >
                <Col span={20} className="deli-col">
                  <div style={{ display: 'flex' }}>
                    <label>{coefficientType[3].label}</label>
                    {isMaintainCoeff ? (
                      <CaretUpOutlined style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }} />
                    ) : (
                      <CaretDownOutlined style={{ fontSize: 18, marginTop: 2, marginLeft: 5 }} />
                    )}
                  </div>
                </Col>
              </Row>
              <div style={{ display: isMaintainCoeff ? 'block' : 'none' }}>
                {/* 一覧アリア */}
                <Row style={{ marginTop: 5 }}>
                  <Table
                    showSorterTooltip={false}
                    id="table4"
                    columns={hasCheckData ? tableColumns : chkData}
                    dataSource={maintainFilteredData}
                    scroll={{ y: 180, x: '10vw' }}
                    rowClassName={(record) =>
                      record.id == selectedRowKeys && 4 === tableNo ? 'active-row' : 'data-row'
                    }
                    pagination={false}
                    className="drop-down-tbl"
                    onRow={(record, index) => {
                      return {
                        onClick: (event) => {
                          onRowSelect(record, 4);
                          //setChangeTableNo(4);
                        },
                      };
                    }}
                  />
                </Row>
              </div>
            </div>
          </div>
          {/* プロパティ表示 */}
          <div>
            <Routes>
              <Route
                exact
                path=":common.coefficient"
                element={
                  <CoefficientDetails
                    ref={detailRef}
                    editMode={editMode}
                    selectedData={selectedData}
                    submitData={submitData}
                    updateEditMode={updateEditMode}
                    tableNo={tableNo}
                  />
                }
              />
            </Routes>
          </div>
        </SplitPane>
      </div>

      {/* 新規作成 */}
      {isCreateNew
        ? commonModal(
            isCreateNew,
            createNewTitle,
            null,
            null,
            800,
            createNewOK,
            createNewCancel,
            createNewContent,
            'addNewCustomerModal',
            null,
            true
          )
        : ''}

      {/* 一括編集 */}
      {isBulkEdit
        ? commonModal(
            isBulkEdit,
            bulkEditTitle,
            null,
            null,
            1790,
            bulkEditOK,
            bulkEditCancel,
            bulkEditContent,
            'updateModalStyle',
            null,
            true
          )
        : ''}

      {/* 削除 */}
      {isDelete
        ? commonModal(
            isDelete,
            confirmModalTitle,
            commonDeleteFooter(deleteOk, deleteCancel),
            null,
            400,
            deleteOk,
            deleteCancel,
            editMode ? confirmDiscardAndDeleteContent : confirmDeleteContent,
            null,
            null,
            false
          )
        : ''}

      {/* CSV取込 */}
      {/* <Modal
        title={reportModalTitle}
        open={visibleCSVInput}
        onOk={handleOkCSVInput}
        onCancel={handleCancel}
        footer={[
          <Button key="file" type="primary" onClick={handleOkCSVInput} className="mainButton">
            OK
          </Button>,
          <Button key="back" onClick={handleCancel} className="cancelButton">
            キャンセル
          </Button>,
        ]}
        closeIcon={<CloseOutlined style={{ color: 'white' }} />}
      >
        <p style={{ fontSize: '13.5px' }}>
          PCに保存した情報を読み込みます。<br></br>よろしいでしょうか？
        </p>
      </Modal> */}

      {/* XML呼出 */}
      {/* <Modal
        title={reportModalTitle}
        open={visibleXMLInput}
        onOk={handleOkXMLInput}
        onCancel={handleCancel}
        footer={[
          <Button key="file" type="primary" onClick={handleOkXMLInput} className="mainButton">
            OK
          </Button>,
          <Button key="back" onClick={handleCancel} className="cancelButton">
            キャンセル
          </Button>,
        ]}
        closeIcon={<CloseOutlined style={{ color: 'white' }} />}
      >
        <p style={{ fontSize: '13.5px' }}>
          装置情報（時間チャージ、段取設定、加工設定）を初期パラメータ作成情報から
          <br />
          取り込みます。
          <br />
          装置情報は、呼出情報で更新されます。
        </p>
      </Modal> */}

      {/* 登録確認 */}
      {createConfirm
        ? commonModal(
            createConfirm,
            confirmModalTitle,
            commonFooter(confirmOk, confirmCancel),
            null,
            400,
            confirmOk,
            confirmCancel,
            confirmCreateContent,
            null,
            null,
            false
          )
        : ''}

      {/* 更新確認 */}
      {updateConfirm
        ? commonModal(
            updateConfirm,
            confirmModalTitle,
            commonFooter(confirmOk, confirmCancel),
            null,
            400,
            confirmOk,
            confirmCancel,
            confirmUpdateContent,
            null,
            null,
            false
          )
        : ''}

      {/** 破棄の確認メッセージ */}
      {cancelConfirm
        ? commonModal(
            cancelConfirm,
            confirmModalTitle,
            commonFooter(cancelConfirmOK, cancelConfirmCancel),
            null,
            400,
            cancelConfirmOK,
            cancelConfirmCancel,
            confirmDiscardContent,
            null,
            null,
            false
          )
        : ''}

      {/** 編集中破棄確認ポップアップ */}
      {editModeCancelConfirm
        ? commonModal(
            editModeCancelConfirm,
            confirmModalTitle,
            commonFooter(discardConfirmOk, discardConfirmCancel),
            null,
            400,
            discardConfirmOk,
            discardConfirmCancel,
            confirmHakiContent,
            null,
            null,
            false
          )
        : ''}
    </>
  );
});

export default CoefficientParamList;
