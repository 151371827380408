/**
 * クラス名：システム全体の共通Modal
 * 説明：システム全体に共通使っているModalである。
 * 作成者：ナンス
 * 作成日：2023/03/01
 * バージョン：1.0
 */
import { Modal, Button, Image, Row, Col } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import warning_icon from '../../assets/images/warning_icon.png';
import confirm_icon from '../../assets/images/confirm_icon.png';
import mijisshi_icon from '../../assets/images/mijisshi_icon.png';
import '../../assets/styles/common.css';
import { loginPath } from '../common/CommonAPI';

/*  開発中ポップアップ */
export const commonTaiochuModal = (isOpen, closeCommonTaiochuModal) => {
  return (
    <>
      <Modal
        maskClosable={false}
        title={null}
        width={450}
        bodyStyle={{
          height: 350,
          backgroundImage: `url(${mijisshi_icon})`,
          backgroundPosition: 'center',
          backgroundSize: '85% 85%',
          backgroundRepeat: 'no-repeat',
        }}
        open={isOpen}
        onOk={null}
        onCancel={() => {
          false;
          closeCommonTaiochuModal();
        }}
        footer={null}
        closeIcon={<CloseOutlined style={{ color: 'black', fontSize: '13.5px' }} />}
      >
        <p
          style={{
            fontSize: '25px',
            fontWeight: '600',
            margin: 0,
            textAlign: 'center',
            verticalAlign: 'middle',
            paddingTop: '50px',
            color: '#21252991',
          }}
        >
          このページは現在開発中です。
        </p>
        <br />
        <p
          style={{
            fontSize: '16px',
            fontWeight: '600',
            margin: 0,
            textAlign: 'center',
            verticalAlign: 'middle',
            color: '#21252991',
          }}
        >
          リリースまでもうしばらくお待ちください。
        </p>
      </Modal>
    </>
  );
};

/* 確認メッセージのタイトル */
export const confirmModalTitle = (
  <div
    style={{
      width: 400,
      backgroundColor: '#005fab',
      marginLeft: -24,
      height: 36,
      marginTop: -20,
      borderTopLeftRadius: 7,
      borderTopRightRadius: 7,
      fontSize: 14,
    }}
  >
    <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>確認</p>
  </div>
);
/* 画面切替警告メッセージのタイトル */
export const confirmScreenChangeTitle = (
  <div
    style={{
      width: 400,
      backgroundColor: '#005fab',
      marginLeft: -24,
      height: 36,
      marginTop: -20,
      borderTopLeftRadius: 7,
      borderTopRightRadius: 7,
      fontSize: 14,
      display: 'flex',
    }}
  >
    <Image preview={false} width={20} src={confirm_icon} style={{ marginTop: 9, marginLeft: 7 }}></Image>
    <p style={{ paddingTop: 8, paddingLeft: 10, color: 'white', margin: 0 }}>確認</p>
  </div>
);
export const confirmScreenChangeWarning = (
  <div
    style={{
      width: 500,
      backgroundColor: '#e31212',
      marginLeft: -24,
      height: 36,
      marginTop: -20,
      borderTopLeftRadius: 7,
      borderTopRightRadius: 7,
      fontSize: 14,
      display: 'flex',
    }}
  >
    <p style={{ paddingTop: 8, paddingLeft: 10, color: 'white', margin: 0 }}>！注意</p>
  </div>
);
/* 確認メッセージ内容 */
export const confirmUpdateContent = <p style={{ fontSize: '13.5px' }}>データを更新します。よろしいでしょうか。</p>;
export const confirmDiscardContent = <p style={{ fontSize: '13.5px' }}>データを破棄します。よろしいでしょうか。</p>;
export const confirmDeleteContent = <p style={{ fontSize: '13.5px' }}>データを削除します。よろしいでしょうか。</p>;
export const confirmCreateContent = <p style={{ fontSize: '13.5px' }}>データを登録します。よろしいでしょうか。</p>;
export const confirmSaveContent = <p style={{ fontSize: '13.5px' }}>データを保存します。よろしいでしょうか。</p>;
export const confirmOverwriteSaveContent = (
  <p style={{ fontSize: '13.5px' }}>データを上書き保存します。よろしいでしょうか。</p>
);
export const confirmDiscardAndDeleteContent = (
  <p style={{ fontSize: '13.5px' }}>
    編集中のデータを破壊して削除します。<br></br>よろしいでしょうか。
  </p>
);

/* 編集中の破棄確認メッセージ内容 */
export const confirmHakiContent = (
  <p style={{ fontSize: '13.5px' }}>編集中のデータが破棄されます。よろしいでしょうか。</p>
);
/* 画面切替警告メッセージ内容 */
export const confirmScreenChangeContent = (
  <p style={{ fontSize: '13.5px' }}>画面切り替えで当画面のデータが破棄されます。よろしいでしょうか。</p>
);

/* 共通ModalFooter */
export const commonFooter = (onOk, onCancel) => [
  <Button key="submit3" type="primary" onClick={onOk} className="mainButton">
    OK
  </Button>,
  <Button key="back" onClick={onCancel} className="cancelButton">
    キャンセル
  </Button>,
];

/* 共通削除ModalFooter */
export const commonDeleteFooter = (onOk, onCancel) => [
  <div className="deleteFooter">
    <Button danger key="submit4" type="primary" onClick={onOk} className="mainButton deleteOKBtn">
      削除
    </Button>
    ,
    <Button key="back" onClick={onCancel} className="cancelButton">
      キャンセル
    </Button>
  </div>,
];

/* 共通ModalFooter */
export const commonActionFooter = (onOk, onCancel, okName, cancelName) => [
  <Button key="submit5" type="primary" onClick={onOk} className="mainButton">
    {okName}
  </Button>,
  <Button key="back" onClick={onCancel} className="cancelButton">
    {cancelName}
  </Button>,
];
// 共通コンポーネントModal
export const commonModal = (
  isOpen,
  title,
  footer,
  bodyStyle,
  width,
  onOk,
  onCancel,
  content,
  className,
  style,
  centered
) => {
  return (
    <Modal
      maskClosable={false}
      open={isOpen}
      title={title}
      footer={footer}
      bodyStyle={bodyStyle}
      width={width}
      onOk={onOk}
      onCancel={onCancel}
      closeIcon={<CloseOutlined style={{ color: 'white' }} />}
      className={className}
      style={style}
      centered={centered}
    >
      {content}
    </Modal>
  );
};
/* 共通Modalタイトル */
export const modalTitle = (title, size, marginLeft) => {
  return (
    <div
      style={{
        width: size,
        backgroundColor: '#005fab',
        marginLeft: marginLeft,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{title}</p>
    </div>
  );
};
// エラーポップアップ
export const error = (content) => {
  return Modal.error({
    width: 465,
    className: 'modal-error',
    title: 'エラー',
    content: <p style={{ fontSize: '13.5px', whiteSpace: 'pre-line', lineHeight: 2 }}>{content}</p>,
  });
};

// トークンの認証エラーポップアップ
export const authorizeError = (content) => {
  return Modal.error({
    width: 465,
    className: 'modal-error',
    title: 'エラー',
    content: <p style={{ fontSize: '13.5px', whiteSpace: 'pre-line', lineHeight: 2 }}>{content}</p>,
    onOk() {
      // localStorage.clear(); // セクション情報をクリック
      localStorage?.removeItem('iQxWeb_LoginUser');
      localStorage?.removeItem('iQxWeb_AccessToken');
      // window.location.replace(loginPath); // ログインへ遷移
    },
  });
};

// 現在表示中の見積データが保存されていません。保存しますか
export const confirmDisplayDataSaveContent = (discardConfirmOk, discardConfirmCancel) => {
  return (
    <>
      {' '}
      <Row style={{ marginTop: 0 }}>
        <p style={{ fontSize: '13.5px', fontWeight: '500', margin: 0 }}>
          現在表示中の見積データが保存されていません。
          <br />
          保存しますか。
        </p>
      </Row>
      <Row style={{ marginTop: '10px' }}>
        <Col span={19} style={{ textAlign: 'left' }} offset={0}>
          <Button onClick={discardConfirmOk} className="mainButton" id="update">
            保存
          </Button>

          <Button onClick={discardConfirmCancel} className="cancelButton" style={{ marginLeft: 10 }} id="discard">
            破棄
          </Button>
        </Col>
        <Col span={5} style={{ textAlign: 'right' }} offset={0}>
          <Button onClick={discardConfirmCancel} className="cancelButton2" style={{ marginRight: 10 }} id="cancel">
            キャンセル
          </Button>
        </Col>
      </Row>
    </>
  );
};

// 完了ポップアップ
export const complete = (content) => {
  return Modal.success({
    width: 465,
    className: 'modal-complete',
    title: '完了',
    content: <p style={{ fontSize: '13.5px', whiteSpace: 'pre-line', lineHeight: 2 }}>{content}</p>,
  });
};
// 出力完了ポップアップ
export const exportComplete = (content) => {
  return Modal.success({
    width: 300,
    className: 'modal-complete',
    content: <p style={{ fontSize: '13.5px', whiteSpace: 'pre-line', lineHeight: 2 }}>{content}</p>,
  });
};

// 新旧パラで計算の確認メッセージ
export const confirmDisplayOldNewParamContent = (
  calculateWithNewParam,
  calculateWithOldParam,
  discardConfirmCancel
) => {
  return (
    <>
      <Row style={{ marginTop: 0 }}>
        <p style={{ fontSize: '13.5px', fontWeight: '500', margin: 0 }}>最新工程見積もりパラメータで、計算します。</p>
      </Row>
      <Row style={{ marginTop: '10px' }}>
        <Col span={19} style={{ textAlign: 'left' }} offset={0}>
          <Button onClick={calculateWithNewParam} className="mainButton" id="update">
            新パラ計算
          </Button>
          <Button onClick={calculateWithOldParam} className="cancelButton" style={{ marginLeft: 10 }} id="discard">
            旧パラ計算
          </Button>
        </Col>
        <Col span={5} style={{ textAlign: 'right' }} offset={0}>
          <Button onClick={discardConfirmCancel} className="cancelButton2" style={{ marginRight: 10 }} id="cancel">
            キャンセル
          </Button>
        </Col>
      </Row>
    </>
  );
};
