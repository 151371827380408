import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Table } from 'react-bootstrap';
import { Row, Select, Input, Radio } from 'antd';
import { PlusCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { MaterialSurfaceBillingUnit, ProcessDetailGroup, WorkTypeGroup } from '../common/enums';
import { ProcessDetailGroups, handleKeyPress } from '../common/Common';

const methods = [
  { id: 1, value: 1, label: '無し' },
  { id: 2, value: 2, label: '脱脂' },
  { id: 3, value: 3, label: 'メッキ' },
  { id: 4, value: 4, label: '片面塗装' },
  { id: 5, value: 5, label: '両面塗装' },
];
const calculationMethods = [
  { id: 1, value: '面積', label: '面積' },
  { id: 2, value: '重量', label: '重量' },
];
const mekki = [
  { id: 1, value: 1, label: 'ユニクロメッキ' },
  { id: 2, value: 2, label: 'クロメート' },
  { id: 3, value: 3, label: 'クロームメッキ' },
  { id: 4, value: 4, label: '亜鉛メッキ' },
  { id: 5, value: 5, label: 'メッキ01' },
  { id: 6, value: 6, label: 'メッキ02' },
  { id: 7, value: 7, label: 'メッキ03' },
  { id: 8, value: 8, label: 'メッキ04' },
  { id: 9, value: 9, label: 'メッキ05' },
];
const ryoumen = [
  { id: 1, value: 1, label: 'メラミン塗装' },
  { id: 2, value: 2, label: 'アクリル塗装' },
  { id: 3, value: 3, label: 'ウレタン塗装' },
  { id: 4, value: 4, label: '粉体塗装' },
  { id: 5, value: 5, label: 'カチオン塗装' },
  { id: 6, value: 6, label: 'プライマー' },
  { id: 7, value: 7, label: '塗装01' },
  { id: 8, value: 8, label: '塗装02' },
  { id: 9, value: 9, label: '塗装03' },
  { id: 10, value: 10, label: '塗装04' },
];

//全ての子部品の面積合計
const childAquareMeter = 150.25;
// 重量の場合、親部品の重量を設定
const weight = 8.681;
const IQ3KouteiInput_Hyoumenshori = forwardRef((props, ref) => {
  // const [editMode, setEditMode] = useState(false);
  const [hyoumenshoriValues, setHyoumenshoriValues] = useState([]);
  const [surfaceWorkType, setSurfaceWorkType] = useState([]);
  const [surfaceWorkTypeList, setSurfaceWorkTypeList] = useState([]);

  const [surfaceProcessTypeVal0Lst, setSurfaceProcessTypeVal0Lst] = useState([{ id: 0, name: 'ー' }]);
  const [surfaceProcessTypeVal1Lst, setSurfaceProcessTypeVal1Lst] = useState([]);
  const [surfaceProcessTypeVal2Lst, setSurfaceProcessTypeVal2Lst] = useState([]);
  const [surfaceProcessTypeVal3Lst, setSurfaceProcessTypeVal3Lst] = useState([]);

  const [processDetailsType, setProcessDetailsType] = useState([]);
  // 編集モード
  // const editMode = props.editMode;
  useEffect(() => {
    if (props.type === '親部品') {
      const surfaceDetailsData = props.kouteiInputInfo.surfaceProcessInputInfo;
      setHyoumenshoriValues(surfaceDetailsData);
    } else {
      let data = props.surfaceInfo;
      if (data != undefined) {
        setHyoumenshoriValues(data?.details);
      }
    }

    setSurfaceWorkType(ProcessDetailGroups);

    const processDetailTypes = props.surfaceData[0]?.details?.processDetailTypes || [];
    setProcessDetailsType(processDetailTypes);

    let processTypeVal1Lst = processDetailTypes?.filter((i) => i.group === WorkTypeGroup.PaWorkGeneral);
    setSurfaceProcessTypeVal1Lst(processTypeVal1Lst);

    const processTypeVal2Lst = processDetailTypes?.filter((i) => i.group === WorkTypeGroup.PaPlating);
    setSurfaceProcessTypeVal2Lst(processTypeVal2Lst);

    const processTypeVal3Lst = processDetailTypes?.filter((i) => i.group === WorkTypeGroup.PaCoating);
    setSurfaceProcessTypeVal3Lst(processTypeVal3Lst);
  }, [props.hyoumenShoriVal, props.surfaceData, props.kouteiInputInfo, props.type]);

  useImperativeHandle(ref, () => ({
    setEditModeChange: setEditModeChange,
  }));
  const addHyoumenshori = (id) => {
    const copyData = hyoumenshoriValues.slice(); // Create a copy of the original array

    const insertIndex = hyoumenshoriValues?.findIndex((item) => item.no === id); // Find the clicked index of the item
    const largestId = hyoumenshoriValues?.reduce((maxId, item) => {
      return item.no > maxId ? item.no : maxId;
    }, 0);

    if (insertIndex !== -1) {
      const data = {
        no: largestId + 1,
        calcType: 1,
        areaUnitPrice: 0,
        weightUnitPrice: 0,
        processDetailWorkTypeGroup: 0,
        processDetailTypeNo: 0,
        weightCount: 0,
        areaCount: 0,
        areaAutoExtract: hyoumenshoriValues[0].areaAutoExtract,
        weightAutoExtract: hyoumenshoriValues[0].weightAutoExtract,
        totalArea: 0,
        totalWeight: 0,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setHyoumenshoriValues(copyData);

      props.updatedSurfaceProcessInput(copyData);
    }
  };
  const deleteHyoumenshori = (id) => {
    if (hyoumenshoriValues?.length > 1) {
      const surfaceList = hyoumenshoriValues?.filter((j) => {
        return ![id]?.includes(j.no);
      });
      setHyoumenshoriValues(surfaceList);
      props.updatedSurfaceProcessInput(surfaceList);
    }
  };
  const changeDetails = (event, id, types, calMethod) => {
    const temp = JSON.parse(JSON.stringify(hyoumenshoriValues));
    let updatedData;
    if (types === 'processDetailWorkTypeGroup') {
      let detailsId = 0;
      if (event === ProcessDetailGroup.None) {
        detailsId = surfaceProcessTypeVal0Lst[0].id;
        setSurfaceWorkTypeList(surfaceProcessTypeVal0Lst);
      } else if (event === ProcessDetailGroup.General) {
        detailsId = surfaceProcessTypeVal1Lst[0]?.id;
        setSurfaceWorkTypeList(surfaceProcessTypeVal1Lst);
      } else if (event === ProcessDetailGroup.Plating) {
        detailsId = surfaceProcessTypeVal2Lst[0]?.id;
        setSurfaceWorkTypeList(surfaceProcessTypeVal2Lst);
      } else {
        detailsId = surfaceProcessTypeVal3Lst[0]?.id;
        setSurfaceWorkTypeList(surfaceProcessTypeVal3Lst);
      }
      updatedData = temp?.map((row) => {
        if (row.no === id) {
          const updatedRow = { ...row, [types]: event };
          updatedRow.processDetailTypeNo = detailsId;
          return updatedRow;
        }
        return row;
      });
    } else if (types === 'processDetailTypeNo') {
      updatedData = temp?.map((row) => {
        if (row.no === id) {
          return { ...row, [types]: event };
        }
        return row;
      });
    } else if (types === 'calcType') {
      if (calMethod === 'area') {
        updatedData = temp?.map((row) => {
          if (row.no === id) {
            return { ...row, [types]: MaterialSurfaceBillingUnit.SquareMeter };
          }
          return row;
        });
      } else if (calMethod === 'weight') {
        updatedData = temp?.map((row) => {
          if (row.no === id) {
            return { ...row, [types]: MaterialSurfaceBillingUnit.Weight };
          }
          return row;
        });
      }
    } else {
      updatedData = temp?.map((row) => {
        if (row.no === id) {
          const updatedRow = { ...row, [types]: event.target.value };
          if (types === 'areaUnitPrice' || types === 'areaCount') {
            // Calculate and update totalCount
            updatedRow.totalArea = updatedRow.areaUnitPrice * updatedRow.areaCount;
          } else if (types === 'weightUnitPrice' || types === 'weightCount') {
            // Calculate and update totalCount
            updatedRow.totalWeight = updatedRow.weightUnitPrice * updatedRow.weightCount;
          }

          return updatedRow;
        }
        return row;
      });
    }
    setHyoumenshoriValues(updatedData);
    if (props.type === '親部品') {
      props.updatedSurfaceProcessInput(updatedData);
    } else {
      const newSurfaceInfo = { ...props.surfaceInfo };
      newSurfaceInfo.details = updatedData;
      props.updateSurface(newSurfaceInfo);
    }
  };
  return (
    <>
      <Row className="mt-1 registerModal" align="middle">
        <Table className="kouteiinput" style={{ width: '81%' }}>
          <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
            <tr style={{ textAlign: 'center' }}>
              <th style={{ width: '3.5%' }} className="tbl-header">
                No
              </th>
              <th style={{ width: '13%' }} className="tbl-header">
                計算方法
              </th>
              <th style={{ width: '26%' }} colSpan={2} className="tbl-header">
                詳細
              </th>
              <th style={{ width: '13%' }} className="tbl-header">
                面積/重量
              </th>
              {/* <th style={{ width: '13%', display: 'none' }} className="tbl-header">
                自動抽出
              </th> */}
              <th style={{ width: '13%' }} className="tbl-header">
                回数
              </th>
              <th style={{ width: '13%' }} className="tbl-header">
                合計
              </th>
            </tr>
          </thead>
          <tbody>
            {hyoumenshoriValues?.map((i, index) => (
              <>
                <tr key={index} style={{ verticalAlign: 'middle' }}>
                  <td rowSpan={2} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                    {index + 1}
                  </td>
                  <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                    <Radio
                      checked={i.calcType === MaterialSurfaceBillingUnit.SquareMeter}
                      onChange={(e) => changeDetails(e, i.no, 'calcType', 'area')}
                      // style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                    >
                      面積
                    </Radio>
                  </td>
                  <td rowSpan={2} style={{ verticalAlign: 'middle', width: '13%' }}>
                    <label>
                      {surfaceWorkType?.filter((item) => item.value === i.processDetailWorkTypeGroup)[0]?.label}
                    </label>
                    {/* <Select
                      name="otherYousetsuType"
                      className="yousetsu-select"
                      style={{
                        pointerEvents: editMode ? 'auto' : 'none',
                        fontSize: '13px',
                        pointerEvents: editMode ? 'auto' : 'none',
                      }}
                      value={i.processDetailWorkTypeGroup}
                      onChange={(e) => changeDetails(e, i.no, 'processDetailWorkTypeGroup')}
                    >
                      {surfaceWorkType?.map((option) => (
                        <Select.Option value={option.value}>{option.label}</Select.Option>
                      ))}
                    </Select> */}
                  </td>

                  <td rowSpan={2} style={{ width: '13%' }}>
                    <label>
                      {processDetailsType?.filter((item) => item.id === i.processDetailTypeNo)[0]?.name}
                      {/* {i.processDetailWorkTypeGroup === ProcessDetailGroup.None ? (
                        <>
                          aa
                          {processDetailsType?.filter((item) => item.id === i.processDetailWorkTypeGroup)[0]?.name}
                        </>
                      ) : i.processDetailWorkTypeGroup === ProcessDetailGroup.General ? (
                        <>
                          {
                            surfaceProcessTypeVal1Lst?.filter((item) => item.id === i.processDetailWorkTypeGroup)[0]
                              ?.name
                          }
                        </>
                      ) : i.processDetailWorkTypeGroup === ProcessDetailGroup.Plating ? (
                        <>
                          {
                            surfaceProcessTypeVal2Lst?.filter((item) => item.id === i.processDetailWorkTypeGroup)[0]
                              ?.name
                          }
                        </>
                      ) : (
                        <>
                          {
                            surfaceProcessTypeVal3Lst?.filter((item) => item.id === i.processDetailWorkTypeGroup)[0]
                              ?.label
                          }
                        </>
                      )} */}
                    </label>
                    {/* <Select
                      name="otherYousetsuType"
                      className="yousetsu-select"
                      style={{
                        pointerEvents: editMode ? 'auto' : 'none',
                        fontSize: '13px',
                        pointerEvents: editMode ? 'auto' : 'none',
                      }}
                      value={i.processDetailTypeNo}
                      onChange={(e) => changeDetails(e, i.no, 'processDetailTypeNo')}
                    >
                      {i.processDetailWorkTypeGroup === ProcessDetailGroup.None ? (
                        <>
                          {surfaceProcessTypeVal0Lst?.map((option) => (
                            <Select.Option value={option.id}>{option.name}</Select.Option>
                          ))}
                        </>
                      ) : i.processDetailWorkTypeGroup === ProcessDetailGroup.General ? (
                        <>
                          {surfaceProcessTypeVal1Lst?.map((option) => (
                            <Select.Option value={option.id}>{option.name}</Select.Option>
                          ))}
                        </>
                      ) : i.processDetailWorkTypeGroup === ProcessDetailGroup.Plating ? (
                        <>
                          {surfaceProcessTypeVal2Lst?.map((option) => (
                            <Select.Option value={option.id}>{option.name}</Select.Option>
                          ))}
                        </>
                      ) : (
                        <>
                          {surfaceProcessTypeVal3Lst?.map((option) => (
                            <Select.Option value={option.id}>{option.name}</Select.Option>
                          ))}
                        </>
                      )}
                    </Select> */}
                  </td>
                  <td>
                    <Input
                      // className={editMode ? 'input-editable ' : 'input-non-editable '}
                      className="input-editable"
                      value={i.areaUnitPrice}
                      onChange={(e) => changeDetails(e, i.no, 'areaUnitPrice')}
                      style={{ height: 32, textAlign: 'right' }}
                      onKeyPress={(e) => {
                        handleKeyPress(e, false);
                      }}
                    ></Input>
                  </td>
                  {/* <td style={{ display: 'none' }}>
                    <Input
                      className={editMode ? 'input-editable ' : 'input-non-editable '}
                      value={i.areaAutoExtract}
                      onChange={(e) => changeDetails(e, i.no, 'areaAutoExtract')}
                      style={{ height: 32, textAlign: 'right', pointerEvents: 'none' }}
                    ></Input>
                  </td> */}
                  <td>
                    <Input
                      // className={editMode ? 'input-editable ' : 'input-non-editable '}
                      className="input-editable"
                      value={i.areaCount}
                      onChange={(e) => changeDetails(e, i.no, 'areaCount')}
                      style={{ height: 32, textAlign: 'right' }}
                      onKeyPress={(e) => {
                        handleKeyPress(e, false);
                      }}
                    ></Input>
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    <label style={{ marginRight: '5px' }}>{i.totalArea}</label>
                  </td>
                  {/* {editMode ? (
                    <>
                      <td
                        rowSpan={2}
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '6%' }}
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            className="add-remove-icon"
                            style={{ marginLeft: 10, marginRight: 10 }}
                            onClick={() => addHyoumenshori(i.no)}
                          />
                          <CloseCircleFilled className="add-remove-icon" onClick={() => deleteHyoumenshori(i.no)} />
                        </Row>
                      </td>
                    </>
                  ) : (
                    <>
                      <td
                        rowSpan={2}
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '6%' }}
                      ></td>
                    </>
                  )} */}
                </tr>
                <tr style={{ verticalAlign: 'middle' }}>
                  <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                    {/* <Radio checked={false}>重量</Radio> */}
                    <Radio
                      checked={i.calcType === MaterialSurfaceBillingUnit.Weight}
                      // onChange={(e) => changeCalculateMethod(i.no, e.target.checked, 'area')}
                      // style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                      onChange={(e) => changeDetails(e, i.no, 'calcType', 'weight')}
                    >
                      重量
                    </Radio>
                  </td>

                  <td>
                    <Input
                      // className={editMode ? 'input-editable ' : 'input-non-editable '}
                      className="input-editable"
                      value={i.weightUnitPrice}
                      onChange={(e) => changeDetails(e, i.no, 'weightUnitPrice')}
                      style={{ height: 32, textAlign: 'right' }}
                      onKeyPress={(e) => {
                        handleKeyPress(e, false);
                      }}
                    ></Input>
                  </td>
                  {/* <td style={{ display: 'none' }}>
                    <Input
                      className={editMode ? 'input-editable ' : 'input-non-editable '}
                      value={i.weightAutoExtract}
                      onChange={(e) => changeDetails(e, i.no, 'weightAutoExtract')}
                      style={{ height: 32, textAlign: 'right', pointerEvents: 'none' }}
                    ></Input>
                  </td> */}
                  <td>
                    <Input
                      // className={editMode ? 'input-editable ' : 'input-non-editable '}
                      className="input-editable"
                      value={i.weightCount}
                      onChange={(e) => changeDetails(e, i.no, 'weightCount')}
                      style={{ height: 32, textAlign: 'right' }}
                      onKeyPress={(e) => {
                        handleKeyPress(e, false);
                      }}
                    ></Input>
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    <label style={{ marginRight: '5px' }}>{i.totalWeight}</label>
                    {/* <Input
                      className={editMode ? 'input-editable ' : 'input-non-editable '}
                      value={i.totalWeight}
                      style={{ height: 32, textAlign: 'right', pointerEvents: 'none' }}
                    ></Input> */}
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        </Table>
      </Row>
    </>
  );
});

export default IQ3KouteiInput_Hyoumenshori;
