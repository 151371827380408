import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { Row, Col, Input, Select, Popover, Checkbox, Space, InputNumber, notification } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import CurrencyInput from 'react-currency-input-field';
import { BsUsbC, BsX } from 'react-icons/bs';

import '../../assets/styles/common.css';
import {
  handelIncreaseTime,
  handelDecreaseTime,
  checkInput,
  secondsToHms,
  setTimetoSec,
  checkInputWithColor,
  handleCollpse,
  JPYs,
  checkShowHideIcon,
  handleShowHide,
  handleKeyPress,
  kouteiCalculateSelectedArr,
} from '../common/Common.js';
import { DataType, WorkType } from '../common/enums';
import { amtEmptyStr, timeHmsStr } from '../common/Constant';

const IQ3KouteiCalculate_MenualBari = forwardRef((props, ref) => {
  const [isYousetsudetail, setIYousetsudetail] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [totalAmt, setTotalAmt] = useState('');
  const [totalTime, setTotalTime] = useState('');
  const [totalSagyoAmt, setTotalSagyoAmt] = useState('');
  const [totalSagyoTime, setTotalSagyoTime] = useState('');
  const [totalDandoriAmt, setTotalDandoriAmt] = useState('');
  const [totalDandoriTime, setTotalDandoriTime] = useState('');
  const [totalSum, setTotalSum] = useState('');

  const [totalTimeSum, setTotalTimeSum] = useState('');
  const [sagyoAmtSum, setSagyoAmtSum] = useState('');
  const [sagyoTimeSum, setSagyoTimeSum] = useState('');
  const [dandoriAmtSum, setDandoriAmtSum] = useState('');
  const [dandoriTimeSum, setDandoriTimeSum] = useState('');

  const [handelingAmt, setHandelingAmt] = useState();
  const [handelingTime, setHandelingTime] = useState();
  const [manualBariAmt, setManualBariAmt] = useState();
  const [kensaTime, setKensaTime] = useState();

  const [editedHandelingAmt, setEditedHandelingAmt] = useState();
  const [editedHandelingTime, setEditedHandelingTime] = useState();
  const [editedKensaAmt, setEditedKensaAmt] = useState();
  const [editedKensaTime, setEditedKensaTime] = useState();
  const [selectedDataFlg, setSelectedDataFlg] = useState(0);

  const [processArr, setProcessArr] = useState([]);
  const [editedData, setEditedData] = useState([]);
  const [oldEditedData, setOldEditedData] = useState([]);
  const [patternInfo, setPatternInfo] = useState([]);
  const [updateEditedData, setUpdateEditedData] = useState([]);
  const [deviceMst, setDeviceMst] = useState([]);
  const [processMst, setProcessMst] = useState([]);

  const calculateTotals = (editDataArr) => {
    let sagyoTimeSumSec = 0;
    let sagyoAmtSum = 0;
    editDataArr?.forEach((element) => {
      sagyoAmtSum += element.sagyoAmt;
      sagyoTimeSumSec += element.sagyoTime;
    });

    let totalSum = sagyoAmtSum + editDataArr[0].dandoriAmt;
    let totalTimeSum = sagyoTimeSumSec + editDataArr[0].dandoriTime;
    let sagyoTimeSum = sagyoTimeSumSec;

    let totals = [];
    let i = 0;
    totals.push({
      id: i++,
      sagyoAmtSum: sagyoAmtSum,
      sagyoTimeSum: sagyoTimeSum,
      sagyoTimeSumSec: sagyoTimeSumSec,
      totalSum: totalSum,
      totalTimeSum: totalTimeSum,
    });
    return totals;
  };

  useEffect(() => {
    let dataEdit = [];
    let totals = [];
    let processMst = [];
    let deviceMst = [];
    let editData = [];

    let parameters = props?.selectedEstimateData?.estimateProducts?.calcParameters?.parameters;
    processMst = parameters?.process
      ? parameters.process?.filter((item) => item.workType === WorkType.SmDeburringManual)[0]
      : [];
    let patternInfo = props?.selectedIQ3DataDetail?.processSelect
      ? props?.selectedIQ3DataDetail?.processSelect?.details?.filter((item) => item.processId == processMst?.id)
      : [];
    setPatternInfo([]);
    if (patternInfo == undefined) return;
    if (patternInfo?.length > 0 && patternInfo[0]?.isUsedForCalc == false) return;
    deviceMst =
      parameters?.device && patternInfo
        ? parameters?.device?.filter(
            (item) => item.processId == patternInfo[0]?.processId && item.id == patternInfo[0]?.deviceId
          )[0]
        : []; //テスト用ID;
    setProcessMst(processMst ? processMst : []);
    setDeviceMst(deviceMst ? deviceMst : []);
    setPatternInfo(patternInfo);
    let deburringManualAdditions = props?.selectedIQ3DataDetail?.additions?.filter(
      (item) => item.processId == processMst?.id
    )[0];
    // 手動バリ工程入力情報取得
    let manualDeburProcessInput = props?.selectedIQ3DataDetail?.processInput?.filter(
      (item) => item.processId == processMst?.id
    )[0];

    let deburringAutoAddition = {};
    let detailItems = [];
    let editDetailItems = [];
    let processName = '手動バリ金額/時間';

    // 手動バリ詳細情報
    detailItems.push({
      id: 1,
      processName: processName,
      dandoriAmt: 0,
      dandoriTime: 0,
      sagyoAmt: 0,
      sagyoTime: 0,
      sagyoAmtOut: 0,
      sagyoTimeOut: 0,
      sagyoAmtIn: 0,
      sagyoTimeIn: 0,
      speedOut: 0,
      lenghtOut: manualDeburProcessInput?.details?.[0]?.length,
      processCountOut: manualDeburProcessInput?.details?.[0]?.count,
      speedIn: 0,
      lenghtIn: manualDeburProcessInput?.details?.[1]?.length,
      processCountIn: manualDeburProcessInput?.details?.[1]?.count,
      formula: '',
    });

    deburringAutoAddition = {
      no: processMst?.id,
      processId: processMst.id,
      dataType: deburringManualAdditions?.dataType ? deburringManualAdditions?.dataType : DataType.Data,
      dataItems: detailItems,
      editItems: deburringManualAdditions?.editItems ? deburringManualAdditions?.editItems : editDetailItems,
      totalDandori: {
        dataItemsAmt: 0,
        dataItemsTime: 0,
        editItemsAmt: 0,
        editItemsTime: 0,
      },
      totalSagyo: {
        dataItemsAmt: 0,
        dataItemsTime: 0,
        editItemsAmt: 0,
        editItemsTime: 0,
      },
      totalDataPrice: 0,
      totalDataTime: 0,
      totalEditPrice: 0,
      totalEditTime: 0,
    };
    deburringManualAdditions = deburringAutoAddition;

    if (deburringManualAdditions?.dataItems) {
      let orgData = JSON.parse(JSON.stringify(deburringManualAdditions?.dataItems));
      if (deviceMst != undefined && orgData?.length > 0) {
        // 重量
        let weight = props?.selectedIQ3DataDetail?.weight;
        // 面積
        let area = props?.selectedIQ3DataDetail?.totalSurfaceArea;
        // 板厚
        let thickness = props?.selectedIQ3DataDetail?.thickness;
        // サイズ係数
        let sizeCoef = getSizeCoeff(deviceMst, weight, area);

        /** 段取金額/時間 */
        // 段取時間設定
        //　基本段取時間＊サイズ係数
        let dandoriTimeSec = calDandoriTime(deviceMst, weight, area);
        let dandoriTimeHms = dandoriTimeSec;
        orgData[0].dandoriTime = dandoriTimeHms;
        deburringManualAdditions.dataItems[0].dandoriTime = dandoriTimeHms ? dandoriTimeHms : 0;
        setDandoriTimeSum(dandoriTimeHms);

        // 段取金額設定
        //段階時間＊時間チャージ
        let dandoriAmt = calDandoriAmt(dandoriTimeSec, deviceMst);
        orgData[0].dandoriAmt = dandoriAmt;
        deburringManualAdditions.dataItems[0].dandoriAmt = dandoriAmt ? dandoriAmt : 0;
        setDandoriAmtSum(dandoriAmt);

        /** 作業金額/時間 */
        // 作業時間{(外周切断長	/	加工速度	＊	加工速度係数)	+	(加工補正時間	*	加工補正係数)｝	*	サイズ係数	*	回数
        // 外周切断長
        let outCutLen = manualDeburProcessInput?.details?.[0]?.length;
        let outCutProcessCount = manualDeburProcessInput?.details?.[0]?.count;
        // 加工速度
        let cSagyoSpeed = getSpeedLenTime(deviceMst, outCutLen, thickness);
        orgData[0].speedOut = cSagyoSpeed;
        // 加工速度係数
        let workSpeedCoeffThickThMaster = deviceMst?.details?.workSpeedCoeffThickThMaster?.filter(
          (item) => item.value == thickness || item.value > thickness
        );
        let cSagyoSpeedOutCoef = deviceMst?.details?.workSpeedCoeffExternalWorkItems?.filter(
          (item) => item.no == workSpeedCoeffThickThMaster?.[0]?.no
        )?.[0]?.value;

        // 加工補正時間
        let modifyTimeQtyThMaster = deviceMst?.details?.modifyTimeQtyThMaster?.filter(
          (item) => item.value == area || item.value > area
        )?.[0];
        let hoseTime = deviceMst?.details?.modifyTimeItems?.filter((item) => item.no == modifyTimeQtyThMaster?.no)?.[0]
          .value;
        // 加工補正係数
        let hoseCoeff = getHoseCoeff(deviceMst, weight, area);

        // 外周作業時間{(外周切断長	/	加工速度	＊	加工速度係数)	+	(加工補正時間	*	加工補正係数)｝	*	サイズ係数	*	回数
        let cSagyoTimeOutPerSec =
          (outCutLen / cSagyoSpeed) * cSagyoSpeedOutCoef + hoseTime * hoseCoeff * sizeCoef * outCutProcessCount;
        let cSagyoTimeOutSec = cSagyoTimeOutPerSec;
        orgData[0].sagyoTimeOut = cSagyoTimeOutSec ? cSagyoTimeOutSec : 0;
        deburringManualAdditions.dataItems[0].sagyoTimeOut = cSagyoTimeOutSec ? cSagyoTimeOutSec : 0;

        // 作業金額
        //　作業時間＊時間チャージ
        let cSagyoAmtOut = calSagyoAmt(cSagyoTimeOutSec, deviceMst);
        orgData[0].sagyoAmtOut = cSagyoAmtOut ? cSagyoAmtOut : 0;
        deburringManualAdditions.dataItems[0].sagyoAmtOut = cSagyoAmtOut ? cSagyoAmtOut : 0;

        // 作業時間{(外周切断長	/	加工速度	＊	加工速度係数)	+	(加工補正時間	*	加工補正係数)｝	*	サイズ係数	*	回数
        // 内周切断長
        let inCutLen = manualDeburProcessInput?.details?.[1]?.length;
        let inCutProcessCount = manualDeburProcessInput?.details?.[1]?.count;
        // 加工速度
        let cSagyoSpeedIn = getSpeedLenTime(deviceMst, inCutLen, thickness);
        orgData[0].speedIn = cSagyoSpeedIn;
        // 加工速度係数
        let workSpeedCoeffThickThMasterIn = deviceMst?.details?.workSpeedCoeffThickThMaster?.filter(
          (item) => item.value == thickness || item.value > thickness
        );
        let cSagyoSpeedInCoef = deviceMst?.details?.workSpeedCoeffInternalWorkItems?.filter(
          (item) => item.no == workSpeedCoeffThickThMasterIn?.[0]?.no
        )?.[0]?.value;

        // 外周作業時間{(外周切断長	/	加工速度	＊	加工速度係数)	+	(加工補正時間	*	加工補正係数)｝	*	サイズ係数	*	回数
        let cSagyoTimeInPerSec =
          (inCutLen / cSagyoSpeedIn) * cSagyoSpeedInCoef + hoseTime * hoseCoeff * sizeCoef * inCutProcessCount;
        let cSagyoTimeInSec = cSagyoTimeInPerSec;
        orgData[0].sagyoTimeIn = cSagyoTimeInSec ? cSagyoTimeInSec : 0;
        deburringManualAdditions.dataItems[0].sagyoTimeIn = cSagyoTimeInSec ? cSagyoTimeInSec : 0;

        // 作業金額
        //　作業時間＊時間チャージ
        let cSagyoAmtIn = calSagyoAmt(cSagyoTimeInSec, deviceMst);
        orgData[0].sagyoAmtIn = cSagyoAmtIn ? cSagyoAmtIn : 0;
        deburringManualAdditions.dataItems[0].sagyoAmtIn = cSagyoAmtIn ? cSagyoAmtIn : 0;

        // 作業時間（外周切断時間 + 内周切断時間）
        let cSagyoTimeSec = orgData[0].sagyoTimeIn + orgData[0].sagyoTimeOut;
        let cSagyoTime = cSagyoTimeSec;
        orgData[0].sagyoTime = cSagyoTime;
        setSagyoTimeSum(cSagyoTime);

        // 作業金額
        //　作業時間＊時間チャージ
        let cSagyoAmt = cSagyoAmtIn + cSagyoAmtOut;
        orgData[0].sagyoAmt = cSagyoAmt;
        setSagyoAmtSum(cSagyoAmt);

        let totalSum = dandoriAmt + cSagyoAmt;
        setTotalSum(totalSum);
        deburringManualAdditions.totalDataPrice = totalSum ? totalSum : 0;
        deburringManualAdditions.totalDandori.dataItemsAmt = dandoriAmt ? dandoriAmt : 0;
        deburringManualAdditions.totalSagyo.dataItemsAmt = cSagyoAmt ? cSagyoAmt : 0;

        let totalTimeSec = dandoriTimeSec + cSagyoTimeSec;
        setTotalTimeSum(totalTimeSec);
        deburringManualAdditions.totalDataTime = totalTimeSec ? totalTimeSec : 0;
        deburringManualAdditions.totalDandori.dataItemsTime = dandoriTimeSec ? dandoriTimeSec : 0;
        deburringManualAdditions.totalSagyo.dataItemsTime = cSagyoTimeSec ? cSagyoTimeSec : 0;

        setProcessArr(orgData);
        setOldEditedData(orgData);

        if (deburringManualAdditions?.dataType == DataType.Edit) {
          editData = JSON.parse(JSON.stringify(deburringManualAdditions?.editItems));
        } else {
          editData = JSON.parse(JSON.stringify(orgData));
        }
        if (editData != undefined && editData?.length > 0) {
          deburringManualAdditions.editItems = editData;
          setEditedData(editData);
          totals = calculateTotals(editData);

          setTotalAmt(totals[0]?.totalSum);
          deburringManualAdditions.totalEditPrice = totals[0]?.totalSum ? totals[0]?.totalSum : 0;
          deburringManualAdditions.totalDandori.editItemsAmt = editData[0]?.dandoriAmt ? editData[0]?.dandoriAmt : 0;
          deburringManualAdditions.totalSagyo.editItemsAmt = totals[0]?.sagyoAmtSum ? totals[0]?.sagyoAmtSum : 0;

          setTotalTime(totals[0]?.totalTimeSum);
          setTotalTimeSum(totals[0]?.totalTimeSum);
          deburringManualAdditions.totalEditTime = totals[0]?.totalTimeSum ? totals[0]?.totalTimeSum : 0;
          deburringManualAdditions.totalDandori.editItemsTime = editData[0].dandoriTime ? editData[0].dandoriTime : 0;
          deburringManualAdditions.totalSagyo.editItemsTime = totals[0]?.sagyoTimeSum ? totals[0]?.sagyoTimeSum : 0;

          setTotalSagyoAmt(totals[0]?.sagyoAmtSum);
          setTotalSagyoTime(totals[0]?.sagyoTimeSum);

          setTotalDandoriAmt(totals[0]?.dandoriAmtSum);
          setTotalDandoriTime(totals[0]?.dandoriTimeSum);
        }
        setManualBariAmt(
          deburringManualAdditions?.dataType == DataType.Data
            ? deburringManualAdditions.totalDataPrice
            : deburringManualAdditions.totalEditPrice
        );
        setUpdateEditedData(deburringManualAdditions);
        props?.updateIQ3KouteiCal(deburringManualAdditions, WorkType.SmDeburringAuto);
      }
    }
  }, [props?.activeTabKey]);

  useEffect(() => {
    setEditMode(props?.editMode != undefined ? props?.editMode : false);
  }, [props?.editMode]);

  const getSizeCoeff = (deviceMst, weight, area) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    // 段取時間設定

    // 重量
    let prodSizeWeightThMaster = deviceMst?.details?.prodSizeWeightThMaster?.filter(
      (item) => item.value == weight || item.value > weight
    );
    // 面積
    let prodSizeAreaThMaster = deviceMst?.details?.prodSizeAreaThMaster?.filter(
      (item) => item.value == area || item.value > area
    );
    let childPartsCoeffs =
      prodSizeWeightThMaster?.length > 0 && prodSizeAreaThMaster?.length > 0
        ? deviceMst?.details?.prodSizeItems?.filter((item) => item.weightId == prodSizeWeightThMaster[0]?.no)
        : deviceMst?.details?.prodSizeItems[0];

    // サイズ係数
    let partsCoeff =
      childPartsCoeffs?.length > 0
        ? childPartsCoeffs?.[0]?.areaCoeffList?.filter((item) => item.no == prodSizeAreaThMaster?.[0]?.no)?.[0]?.value
        : childPartsCoeffs?.[0]?.areaCoeffList?.[0]?.value;

    return partsCoeff;
  };

  const getSpeedLenTime = (deviceMst, length, thickness) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    // 段取時間設定

    // 加工長
    let workSpeedLenThMaster = deviceMst?.details?.workSpeedLenThMaster?.filter(
      (item) => item.value == length || item.value > length
    );
    // 板厚
    let workSpeedThickThMaster = deviceMst?.details?.workSpeedThickThMaster?.filter(
      (item) => item.value == thickness || item.value > thickness
    );
    let childPartsCoeffs =
      workSpeedLenThMaster?.length > 0 && workSpeedThickThMaster?.length > 0
        ? deviceMst?.details?.workSpeedItems?.filter((item) => item?.lengthId == workSpeedLenThMaster[0]?.no)
        : deviceMst?.details?.workSpeedItems[0];

    // サイズ係数
    let partsCoeff =
      childPartsCoeffs?.length > 0
        ? childPartsCoeffs?.[0]?.itaatsuCoeffList?.filter((item) => item.no == workSpeedThickThMaster?.[0]?.no)?.[0]
            ?.value
        : childPartsCoeffs?.[0]?.itaatsuCoeffList?.[0]?.value;

    return partsCoeff;
  };

  const getHoseCoeff = (deviceMst, weight, area) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    // 段取時間設定

    // 重量
    let mtWeightThMaster = deviceMst?.details?.mtWeightThMaster?.filter(
      (item) => item.value == weight || item.value > weight
    );
    // 面積
    let mtAreaThMaster = deviceMst?.details?.mtAreaThMaster?.filter((item) => item.value == area || item.value > area);
    let childPartsCoeffs =
      mtWeightThMaster?.length > 0 && mtAreaThMaster?.length > 0
        ? deviceMst?.details?.prodSizeItems?.filter((item) => item.weightId == mtWeightThMaster[0]?.no)
        : deviceMst?.details?.prodSizeItems[0];

    // 補正係数
    let partsCoeff =
      childPartsCoeffs?.length > 0
        ? childPartsCoeffs?.[0]?.areaCoeffList?.filter((item) => item.no == mtAreaThMaster?.[0]?.no)?.[0]?.value
        : childPartsCoeffs?.[0]?.areaCoeffList?.[0]?.value;

    return partsCoeff;
  };

  const calDandoriTime = (deviceMst, weight, area) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    // 段取時間設定

    // 重量
    let childPartsCoeffWeightThMaster = deviceMst?.details?.childPartsCoeffWeightThMaster?.filter(
      (item) => item.value == weight || item.value > weight
    );
    // 面積
    let childPartsCoeffAreaThMaster = deviceMst?.details?.childPartsCoeffAreaThMaster?.filter(
      (item) => item.value == area || item.value > area
    );
    let childPartsCoeffs =
      childPartsCoeffWeightThMaster?.length > 0 && childPartsCoeffAreaThMaster?.length > 0
        ? deviceMst?.details?.childPartsCoeff?.filter((item) => item.weightId == childPartsCoeffWeightThMaster[0]?.no)
        : deviceMst?.details?.childPartsCoeff[0];

    // サイズ係数
    let partsCoeff =
      childPartsCoeffs?.length > 0
        ? childPartsCoeffs?.[0]?.areaCoeffList?.filter((item) => item.no == childPartsCoeffAreaThMaster?.[0]?.no)?.[0]
            .value
        : childPartsCoeffs?.[0]?.areaCoeffList?.[0]?.value;

    //　基本段取時間＊サイズ係数
    let dandoriTimeSec = deviceMst?.details?.prepBasicTime * partsCoeff;
    return dandoriTimeSec;
  };

  const calDandoriAmt = (dandoriTimeSec, deviceMst) => {
    //段階時間
    let mSetTimeMin = dandoriTimeSec / 60;
    //装置の段階時間チャージ
    let mSetHRate = deviceMst.details?.charges.prep;
    //段階時間＊時間チャージ
    let dandoriAmt = Math.floor((mSetTimeMin * mSetHRate) / 60.0);
    return dandoriAmt;
  };

  const calSagyoAmt = (cSagyoTimeKonpoSec, deviceMst) => {
    // 作業金額
    // 作業時間
    let mWorkHTime = cSagyoTimeKonpoSec;
    let mWorkHTimeMin = mWorkHTime / 60;
    //装置の作業時間チャージ
    let mWorkHRate = deviceMst.details?.charges.work;
    //　加工時間＊時間チャージ
    let cSagyoAmtKonpo = Math.floor((mWorkHTimeMin * mWorkHRate) / 60.0);
    return cSagyoAmtKonpo;
  };

  const yousetsu = () => {
    if (isYousetsudetail == true) {
      setIYousetsudetail(false);
    } else {
      setIYousetsudetail(true);
    }
  };

  const subpartDetail = (i) => {
    let showHide = document.getElementById('menualBProcess' + i).style.display;
    if (showHide == 'block') {
      document.getElementById('menualBProcess' + i).style.display = 'none';
      document.getElementById('arrowDownMenualB' + i).style.display = 'block';
      document.getElementById('arrowUpMenualB' + i).style.display = 'none';
    } else {
      document.getElementById('menualBProcess' + i).style.display = 'block';
      document.getElementById('arrowDownMenualB' + i).style.display = 'none';
      document.getElementById('arrowUpMenualB' + i).style.display = 'block';
    }
  };

  const setEditModeChange = (mode) => {
    setEditMode(mode);
  };
  useImperativeHandle(ref, () => ({
    setEditModeChange: setEditModeChange,
  }));

  const settingNewDandoriAmtInArr = (value, index, objId, timeType, timeAct) => {
    let newState = JSON.parse(JSON.stringify(editedData));
    if (objId !== 'dandoriAmt' && objId !== 'dandoriTime' && objId !== 'handelingAmt' && objId !== 'handelingTime') {
      setOldEditedData(JSON.parse(JSON.stringify(editedData)));
    }
    let oldValue = editedData[index][objId];
    if (value === undefined) {
      value = 0;
    }
    newState[index][objId] = Number(value);
    updateEditedData.editItems[index][objId] = newState[index][objId];
    //let deviceMst = [];

    if (objId === 'dandoriTime') {
      if (document.getElementById(index + 'menualBDandoriAmtCu').style.display !== undefined) {
        let showHideCu = document.getElementById(index + 'menualBDandoriAmtCu').style.display;
        if (showHideCu === 'block') {
          checkInputWithColor(false, '', index + 'menualBDandoriAmt', newState[index].dandoriAmt, 'red');
        } else {
          if (timeAct === 'input') {
            let timeType = setTimetoSec(value) - setTimetoSec(oldValue);
            newState[index].dandoriAmt = changeAmtwithTime(newState[index].dandoriAmt, timeType, timeAct, value, objId);
            updateEditedData.editItems[index].dandoriAmt = newState[index].dandoriAmt;
          } else {
            newState[index].dandoriAmt = changeAmtwithTime(newState[index].dandoriAmt, timeType, timeAct, value, objId);
            updateEditedData.editItems[index].dandoriAmt = newState[index].dandoriAmt;
          }
          // checkInputWithColor(false, '', index + 'menualBDandoriAmt', newState[index].dandoriAmt, 'green');
        }
      }
    } else if (objId?.includes('sagyoTime')) {
      const amtObjId = objId.replace('Time', 'Amt');
      /* (objId === 'sagyoTime')  */
      //checkInputWithColor(false,'',index + 'menualBWorkAmt', newState[index].sagyoAmt, 'green');
      let iconId = index + amtObjId + 'Cu';
      if (document.getElementById(iconId).style.display !== undefined) {
        let showHideCu = document.getElementById(iconId).style.display;
        if (showHideCu === 'block') {
          // checkInputWithColor(false,'',index + 'menualBWorkAmt', newState[index].sagyoAmt, 'red');
        } else {
          if (timeAct === 'input') {
            let timeHms = value;
            newState[index][amtObjId] = changeAmtwithTime(newState[index][amtObjId], timeType, timeAct, timeHms, objId);
          } else {
            newState[index][amtObjId] = changeAmtwithTime(newState[index][amtObjId], timeType, timeAct, value, objId);
          }
          newState[index].sagyoAmt = Number(newState[index]['sagyoAmtIn']) + Number(newState[index]['sagyoAmtOut']);
          newState[index].sagyoTime = newState[index]['sagyoTimeIn'] + newState[index]['sagyoTimeOut'];

          updateEditedData.editItems[index].sagyoAmt = newState[index].sagyoAmt;
          updateEditedData.editItems[index].sagyoTime = newState[index].sagyoTime;
          // checkInputWithColor(false,'',index + 'menualBWorkAmt', newState[index].sagyoAmt, 'green');
        }
      }
    } else if (objId === 'materialHandlingTime') {
      if (document.getElementById(index + 'materialHandlingAmtCu').style.display !== undefined) {
        let showHideCu = document.getElementById(index + 'materialHandlingAmtCu').style.display;
        if (showHideCu === 'block') {
          //checkInputWithColor(false,'',index + 'materialHandlingAmt', newState[0].dandoriAmt, 'red');
        } else {
          if (timeAct === 'input') {
            let timeType = setTimetoSec(value) - setTimetoSec(oldValue);
            newState[index].materialHandlingAmt = changeAmtwithTime(
              newState[0].materialHandlingAmt,
              timeType,
              timeAct,
              value,
              objId
            );
          } else {
            newState[index].materialHandlingAmt = changeAmtwithTime(
              newState[index].materialHandlingAmt,
              timeType,
              timeAct,
              value,
              objId
            );
          }
          //checkInputWithColor(false,'',index + 'menualBDandoriAmt', newState[index].materialHandlingAmt, 'green');
        }
      }
    } else if (objId === 'lenghtOut' || objId === 'speedOut' || objId === 'lenghtIn' || objId === 'speedIn') {
      if (value && value != 0) {
        /** 作業金額/時間 */
        // 作業時間（外周切断時間 + 内周切断時間 + マテハン時間）
        // 外周切断長
        let outCutLen = objId === 'lenghtOut' ? value : newState[index].lenghtOut;
        // 加工速度
        let cSagyoSpeedOut = objId === 'speedOut' ? value : newState[index].speedOut;
        // 加工速度係数
        let cSagyoSpeedOutCoef = 2.0;
        // サイズ係数
        let sizeCoef = 2.5;
        // 外周切断時間（（外周切断長/加工速度＊加工速度係数）＊サイズ係数）
        let cSagyoTimeOutPerSec = (outCutLen / cSagyoSpeedOut) * cSagyoSpeedOutCoef * sizeCoef;
        let cSagyoTimeOutSec = cSagyoTimeOutPerSec;
        newState[index].sagyoTimeOut = cSagyoTimeOutSec;
        updateEditedData.editItems[index].sagyoTimeOut = newState[index].sagyoTimeOut;

        // 作業金額
        //　作業時間＊時間チャージ
        if (document.getElementById(index + 'sagyoAmtOutCu').style.display !== undefined) {
          let showHideCu = document.getElementById(index + 'sagyoAmtOutCu').style.display;
          if (showHideCu === 'none') {
            let cSagyoAmtOut = calSagyoAmt(newState[index].sagyoTimeOut, deviceMst);
            newState[index].sagyoAmtOut = cSagyoAmtOut;
            updateEditedData.editItems[index].sagyoAmtOut = newState[index].sagyoAmtOut;
          }
        }
        let cSagyoSpeedIn = objId === 'speedIn' ? value : newState[index].speedIn;

        // 内周切断長
        let inCutLen = objId === 'lenghtIn' ? value : newState[index].lenghtIn;
        // 加工速度係数
        let cSagyoSpeedInCoef = 2.0;
        // 内周切断時間（（内周切断長/加工速度＊加工速度係数）＊サイズ係数）
        let cSagyoTimeInPerSec = (inCutLen / cSagyoSpeedIn) * cSagyoSpeedInCoef * sizeCoef;
        let cSagyoTimeInSec = cSagyoTimeInPerSec;
        newState[index].sagyoTimeIn = cSagyoTimeInSec;
        updateEditedData.editItems[index].sagyoTimeIn = newState[index].sagyoTimeIn;

        // 作業時間（外周切断時間 + 内周切断時間）
        let cSagyoTimeSec = newState[index].sagyoTimeIn + newState[index].sagyoTimeOut;
        let cSagyoTime = cSagyoTimeSec;
        newState[index].sagyoTime = cSagyoTime;
        updateEditedData.editItems[index].sagyoTime = newState[index].sagyoTime;

        // 作業金額
        //　作業時間＊時間チャージ

        if (document.getElementById(index + 'sagyoAmtInCu').style.display !== undefined) {
          let showHideCu = document.getElementById(index + 'sagyoAmtInCu').style.display;
          if (showHideCu === 'none') {
            let cSagyoAmt = calSagyoAmt(newState[index].sagyoTime, deviceMst);
            newState[index].sagyoAmt = cSagyoAmt;
            updateEditedData.editItems[index].sagyoAmt = newState[index].sagyoAmt;
          }
        }
      }
    }
    // else if (objId === 'naibuProcessValidationTime') {
    //   if (document.getElementById('naibuProcessValidationAmtCu').style.display !== undefined) {
    //     let showHideCu = document.getElementById('naibuProcessValidationAmtCu').style.display;
    //     if (showHideCu === 'block') {
    //       //checkInputWithColor(false,'',index + 'materialHandlingAmt', newState[0].dandoriAmt, 'red');
    //     } else {
    //       if (timeAct === 'input') {
    //         let timeType = setTimetoSec(value) - setTimetoSec(oldValue);
    //         newState[index].naibuProcessValidationAmt = changeAmtwithTime(
    //           newState[0].naibuProcessValidationAmt,
    //           timeType,
    //           timeAct,
    //           value
    //         );
    //       } else {
    //         newState[index].naibuProcessValidationAmt = changeAmtwithTime(
    //           newState[0].naibuProcessValidationAmt,
    //           timeType,
    //           timeAct,
    //           value
    //         );
    //       }
    //       //checkInputWithColor(false,'',index + 'menualBDandoriAmt', newState[index].materialHandlingAmt, 'green');
    //     }
    //   }
    // }

    let newSagyoSum = 0;
    let newDandoriSum = 0;
    let newSum = 0;
    let newSubSum = 0;
    let newSagyoTimeSec = 0;
    let newDandoriTimeSec = 0;

    newSubSum = Number(newState[index].sagyoAmtOut) + Number(newState[index].sagyoAmtIn);
    newState[index].sumAmt = newSubSum;

    let newSubTimeSum = newState[index].sagyoTimeOut + newState[index].sagyoTimeIn;
    newState[index].sumTime = newSubTimeSum;
    newState?.forEach((element) => {
      newSagyoSum += Number(element.sumAmt);
      newDandoriSum += Number(element.dandoriAmt);
      newSagyoTimeSec += element.sumTime;
      newDandoriTimeSec += element.dandoriTime;
    });
    /*  newSagyoSum += Number(newState[index].materialHandlingAmt);
    newSagyoTimeSec += newState[index].materialHandlingTime); */
    newSum = Number((Number(newSagyoSum) + Number(newDandoriSum)).toFixed(0));

    let newSagyoTimeSum = newSagyoTimeSec;
    let newDandoriTimeSum = newDandoriTimeSec;
    let newTotalTimeSum = newSagyoTimeSec + newDandoriTimeSec;

    /* checkInputWithColor(false,'',index + 'subTotalMenual', newSubSum, 'green');
    checkInputWithColor(false,'',index + 'subTotalTimeMenual', newSubTimeSum, 'green'); */
    /*  checkInputWithColor(false,'','totalSagyoAmtMenual', newSagyoSum, 'green');
    checkInputWithColor(false,'','totalDandoriAmtMenual', newDandoriSum, 'green'); */
    checkInputWithColor(false, '', 'totalAmtMenual', newSum, 'green');
    /*  checkInputWithColor(false,'','totalSagyoTimeMenual', newSagyoTimeSum, 'green');
    checkInputWithColor(false,'','totalDandoriTimeMenual', newDandoriTimeSum, 'green'); */
    checkInputWithColor(false, '', 'totalTimeMenual', newTotalTimeSum, 'green');
    setTotalSagyoAmt(newSagyoSum);
    setTotalDandoriAmt(newDandoriSum);
    setTotalAmt(newSum);
    setTotalSagyoTime(newSagyoTimeSum);
    setTotalDandoriTime(newDandoriTimeSum);
    setTotalTime(newTotalTimeSum);
    setEditedData(newState);

    updateEditedData.totalEditPrice = newSum;
    updateEditedData.totalEditTime = newTotalTimeSum;
    updateEditedData.totalDandori.editItemsAmt = newDandoriSum;
    updateEditedData.totalDandori.editItemsTime = newDandoriTimeSum;
    updateEditedData.totalSagyo.editItemsAmt = newSagyoSum;
    updateEditedData.totalSagyo.editItemsTime = newSagyoTimeSum;
    if (updateEditedData.dataType == DataType.Data) {
      setManualBariAmt(updateEditedData.totalDataPrice);
    } else {
      setManualBariAmt(updateEditedData.totalEditPrice);
    }
    props?.updateIQ3KouteiCal(updateEditedData, WorkType.SmDeburringManual);
  };

  const checkStr = (val) => {
    let intAmt;
    if (val === '') {
      intAmt = 0;
    } else if (typeof val === 'string') {
      intAmt = Number(val.charAt(0) == '¥' ? val.replace('¥', '') : val);
    } else intAmt = val;
    return intAmt;
  };

  const changeAmtwithTime = (amt, typeTime, action, timeVal, processType) => {
    let editedAmt;
    let totalSec = Number(timeVal);
    let intAmt = checkStr(amt);

    //let deviceMst = [];

    if (processType === 'dandoriTime') {
      // 段取金額
      //　段取時間＊時間チャージ
      editedAmt = calDandoriAmt(totalSec, deviceMst);
    } else if (processType?.includes('sagyoTime')) {
      // 作業金額
      //　加工時間＊時間チャージ
      editedAmt = calSagyoAmt(totalSec, deviceMst);
    } else if (processType === 'materialHandlingTime') {
      // マテハン金額
      //　マテリアルハンドリング時間＊時間チャージ
      editedAmt = calSagyoAmt(totalSec, deviceMst);
    }

    return editedAmt;
  };

  const handleChangeCheckData = (e, i) => {
    /* setSelectedDataFlg(e);
    props?.sendSelectedDataFlg(e); */
    const data = { ...updateEditedData };
    data.dataType = e;
    setUpdateEditedData(data);
    setManualBariAmt(e == DataType.Data ? data.totalDataPrice : data.totalEditPrice);
    props?.updateIQ3KouteiCal(data, WorkType.SmDeburringManual);
  };

  return (
    <div className="kouteiCalculateDiv">
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row " onClick={yousetsu}>
        <Col span={4} className="oyabuhin-col">
          <div style={{ display: 'flex' }}>
            {isYousetsudetail ? (
              <CaretUpOutlined style={{ fontSize: 17, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 17, marginTop: 2 }} />
            )}
            <label style={{ marginLeft: 5 }}>{processMst?.name}</label>
          </div>
        </Col>
        <Col span={6} className="oyabuhin-col">
          <div>{manualBariAmt ? JPYs.format(Math.floor(manualBariAmt)) : amtEmptyStr}</div>
        </Col>
        <Col span={13} style={{ border: 'none' }} className="oyabuhin-col">
          <div>
            <label style={{ marginLeft: 5 }}>{deviceMst?.name}</label>
          </div>
        </Col>
      </Row>
      <div style={{ display: isYousetsudetail ? 'block' : 'none' }}>
        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col">
            工程詳細
          </Col>
          <Col span={6} className="oyabuhin-detail-col">
            <Checkbox
              key={kouteiCalculateSelectedArr[0].key}
              value={kouteiCalculateSelectedArr[0].value}
              checked={kouteiCalculateSelectedArr[0].key == updateEditedData.dataType}
              disabled={!props?.editMode}
              style={{ marginRight: 6 }}
              onChange={(e) => props?.editMode && handleChangeCheckData(kouteiCalculateSelectedArr[0].key)}
            ></Checkbox>
            <label>{kouteiCalculateSelectedArr[0].value}</label>
          </Col>
          <Col span={8} className="oyabuhin-detail-col">
            <Checkbox
              key={kouteiCalculateSelectedArr[1].key}
              value={kouteiCalculateSelectedArr[1].value}
              disabled={!props?.editMode}
              onChange={(e) => props?.editMode && handleChangeCheckData(kouteiCalculateSelectedArr[1].key)}
              checked={kouteiCalculateSelectedArr[1].key == updateEditedData.dataType}
              style={{ marginRight: 6 }}
            ></Checkbox>
            <label>{kouteiCalculateSelectedArr[1].value}</label>
          </Col>
          <Col span={1} className="oyabuhin-detail-col">
            単位
          </Col>
          <Col span={5} className="formula-column">
            計算式
          </Col>
        </Row>

        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col">
            {processMst?.name}
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalAmtMenual">{totalSum ? JPYs.format(Math.floor(totalSum)) : amtEmptyStr}</label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalTimeMenual">{totalTimeSum ? secondsToHms(totalTimeSum) : timeHmsStr}</label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalAmtMenualIn" className="resetColor">
              {totalAmt ? JPYs.format(Math.floor(totalAmt)) : amtEmptyStr}
            </label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalTimeMenualIn" className="resetColor">
              {totalTime ? secondsToHms(totalTime) : timeHmsStr}
            </label>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={5} className="formula-column"></Col>
        </Row>
        {(() => {
          const arr = [];
          for (let j = 0; j < processArr?.length; j++) {
            arr.push(
              <div>
                <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                  <Col span={4} className="oyabuhin-detail-col">
                    <span>段取金額/時間</span>
                  </Col>
                  <Col span={3} className="oyabuhin-detail-col">
                    <label id={j + 'menualBDandoriAmt'}>
                      {processArr[0]?.dandoriAmt ? JPYs.format(Math.floor(processArr[0]?.dandoriAmt)) : amtEmptyStr}
                    </label>
                  </Col>
                  <Col span={3} className="oyabuhin-detail-col">
                    <label id={j + 'menualBDandoriTime'}>
                      {processArr[0]?.dandoriTime ? secondsToHms(processArr[0]?.dandoriTime) : timeHmsStr}
                    </label>
                  </Col>
                  <Col span={4} className="input-col">
                    <CurrencyInput
                      id={j + 'menualBDandoriAmtIn'}
                      value={editedData[j]?.dandoriAmt ? Math.floor(editedData[j]?.dandoriAmt) : 0}
                      defaultValue={0}
                      onValueChange={(e) => {
                        if (editedData[j]?.dandoriAmt != e) {
                          settingNewDandoriAmtInArr(e, j, 'dandoriAmt', '', '');
                          checkInputWithColor(false, '', j + 'menualBDandoriAmt', e, 'amt');
                        }
                      }}
                      style={{
                        border: 'none',
                        color:
                          Number(processArr[j]?.dandoriAmt) === Number(editedData[j]?.dandoriAmt)
                            ? 'black'
                            : checkShowHideIcon(j + 'menualBDandoriAmtCu') === 'none'
                            ? '#4cc12f'
                            : 'red',
                      }}
                      className={
                        editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'
                      }
                      prefix="¥"
                      decimalsLimit={100}
                    />
                    <div id={j + 'menualBDandoriAmtCu'} style={{ display: 'none' }}>
                      <div className="currencyIcon">
                        <BsUsbC style={{ fontSize: '25' }} />
                        <BsX style={{ margin: '-8px 0 0 16px' }} />
                        <span class="tooltiptext">
                          金額に変更がありましたので、以降時間と連携して手動計算されない。
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col span={4} className="input-col">
                    <Row style={{ width: '100%' }}>
                      <Col className="timer-input-col">
                        <Input
                          value={editedData[j]?.dandoriTime ? secondsToHms(editedData[j]?.dandoriTime) : timeHmsStr}
                          id={j + 'menualBDandoriTimeIn'}
                          style={{
                            width: '100%',
                            color:
                              processArr[j]?.dandoriTime === editedData[j]?.dandoriTime
                                ? handleShowHide(
                                    Number(editedData[j]?.dandoriAmt),
                                    editedData[j]?.dandoriAmt,
                                    j + 'menualBDandoriAmtCu'
                                  )
                                : 'red',
                          }}
                          className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                          onChange={(e) => {
                            //disableTimer('test');
                            settingNewDandoriAmtInArr(e.target.value, j, 'dandoriTime', 'input', 'input');
                          }}
                        ></Input>
                      </Col>
                      <Col className="timer-button-col">
                        {editMode ? (
                          <>
                            <CaretUpOutlined
                              style={{ fontSize: 17 + 'px' }}
                              onClick={() => {
                                let editedTime = handelIncreaseTime(
                                  editedData[j]?.dandoriTime,
                                  '10min',
                                  j + 'menualBDandoriTime'
                                );
                                settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', '10min', 'inc');
                              }}
                            />
                            <CaretDownOutlined
                              style={{ fontSize: 17 + 'px', color: '#000' }}
                              onClick={() => {
                                let editedTime = handelDecreaseTime(
                                  editedData[j]?.dandoriTime,
                                  '10min',
                                  j + 'menualBDandoriTime'
                                );
                                settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', '10min', 'dec');
                              }}
                            />
                            <CaretUpOutlined
                              style={{ fontSize: 17 + 'px', color: '#000' }}
                              onClick={() => {
                                let editedTime = handelIncreaseTime(
                                  editedData[j]?.dandoriTime,
                                  'min',
                                  j + 'menualBDandoriTime'
                                );
                                settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'min', 'inc');
                              }}
                            />
                            <CaretDownOutlined
                              style={{ fontSize: 17 + 'px', color: '#000' }}
                              onClick={() => {
                                let editedTime = handelDecreaseTime(
                                  editedData[j]?.dandoriTime,
                                  'min',
                                  j + 'menualBDandoriTime'
                                );
                                settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'min', 'dec');
                              }}
                            />
                            <CaretUpOutlined
                              style={{ fontSize: 17 + 'px', color: '#000' }}
                              onClick={() => {
                                let editedTime = handelIncreaseTime(
                                  editedData[j]?.dandoriTime,
                                  'sec',
                                  j + 'menualBDandoriTime'
                                );
                                settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'sec', 'inc');
                              }}
                            />
                            <CaretDownOutlined
                              style={{ fontSize: 17 + 'px', color: '#000' }}
                              onClick={() => {
                                let editedTime = handelDecreaseTime(
                                  editedData[j]?.dandoriTime,
                                  'sec',
                                  j + 'menualBDandoriTime'
                                );
                                settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'sec', 'dec');
                              }}
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col span={1} className="oyabuhin-detail-col"></Col>
                  <Col span={5} className="formula-column"></Col>
                </Row>
                <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                  <Col span={4} className="oyabuhin-detail-col" onClick={(e) => subpartDetail(j)}>
                    <CaretUpOutlined
                      id={'arrowUpMenualB' + j}
                      style={{ fontSize: 17, marginTop: 2, display: 'none' }}
                    />
                    <CaretDownOutlined id={'arrowDownMenualB' + j} style={{ fontSize: 17, marginTop: 2 }} />
                    <span style={{ marginLeft: '5px' }}></span>
                    <span>加工金額/時間</span>
                  </Col>
                  <Col span={3} className="oyabuhin-detail-col" onClick={(e) => subpartDetail(j)}>
                    <label id="totalSagyoAmtMenual">
                      {sagyoAmtSum ? JPYs.format(Math.floor(sagyoAmtSum)) : amtEmptyStr}
                    </label>
                  </Col>
                  <Col span={3} className="oyabuhin-detail-col" onClick={(e) => subpartDetail(j)}>
                    <label id="totalSagyoAmtMenual">{sagyoTimeSum ? secondsToHms(sagyoTimeSum) : timeHmsStr}</label>
                  </Col>
                  <Col span={4} className="oyabuhin-detail-col">
                    <label id="totalSagyoAmtIn" style={{ color: sagyoAmtSum == totalSagyoAmt ? 'black' : '#4cc12f' }}>
                      {totalSagyoAmt ? JPYs.format(Math.floor(totalSagyoAmt)) : amtEmptyStr}
                    </label>
                  </Col>
                  <Col span={4} className="oyabuhin-detail-col">
                    <label
                      id="totalSagyoTimeIn"
                      style={{ color: sagyoTimeSum == totalSagyoTime ? 'black' : '#4cc12f' }}
                    >
                      {totalSagyoTime ? secondsToHms(totalSagyoTime) : timeHmsStr}
                    </label>
                  </Col>
                  <Col span={1} className="oyabuhin-detail-col" onClick={(e) => subpartDetail(j)}></Col>
                  <Col span={5} className="formula-column" onClick={(e) => subpartDetail(j)}></Col>
                </Row>
                <div id={'menualBProcess' + j} style={{ display: 'none' }}>
                  <Row
                    style={{ color: 'black', display: processArr[j]?.sagyoAmtOut == 0 ? 'none' : '' }}
                    className="oyabuhin-detail oyabuhin-row"
                  >
                    <Col span={4} className="oyabuhin-detail-col" onClick={(e) => handleCollpse('KakouOut')}>
                      <div style={{ display: 'flex', marginLeft: 25 }}>
                        <CaretUpOutlined
                          id={'arrowUpKakouOut'}
                          style={{ fontSize: 17, marginTop: 2, display: 'none' }}
                        />

                        <CaretDownOutlined id={'arrowDownKakouOut'} style={{ fontSize: 17, marginTop: 2 }} />
                        <span style={{ marginLeft: 5 }}>外周加工金額/時間</span>
                      </div>
                    </Col>
                    <Col span={3} className="oyabuhin-detail-col" onClick={(e) => handleCollpse('KakouOut')}>
                      <label id={j + 'sagyoAmtOut'}>
                        {processArr[j]?.sagyoAmtOut ? JPYs.format(Math.floor(processArr[j]?.sagyoAmtOut)) : amtEmptyStr}
                      </label>
                    </Col>
                    <Col span={3} className="oyabuhin-detail-col" onClick={(e) => handleCollpse('KakouOut')}>
                      <label id={j + 'menualBWorkTimeOut'}>
                        {processArr[j]?.sagyoTimeOut ? secondsToHms(processArr[j]?.sagyoTimeOut) : timeHmsStr}
                      </label>
                    </Col>
                    <Col span={4} className="input-col">
                      <CurrencyInput
                        id={j + 'sagyoAmtOutIn'}
                        value={editedData[j]?.sagyoAmtOut ? Math.floor(editedData[j]?.sagyoAmtOut) : 0}
                        defaultValue={0}
                        onValueChange={(e) => {
                          if (editedData[j]?.sagyoAmtOut != e) {
                            settingNewDandoriAmtInArr(e, j, 'sagyoAmtOut', '', '');
                            checkInputWithColor(false, '', j + 'sagyoAmtOut', e, 'amt');
                          }
                        }}
                        style={{
                          border: 'none',
                          color:
                            processArr[j]?.sagyoAmtOut === editedData[j]?.sagyoAmtOut
                              ? 'black'
                              : checkShowHideIcon(j + 'sagyoAmtOutCu') === 'none'
                              ? '#4cc12f'
                              : 'red',
                        }}
                        className={
                          editMode
                            ? 'input-editable currency resetColor resetColor'
                            : 'input-non-editable currency resetColor resetColor'
                        }
                        prefix="¥"
                        decimalsLimit={100}
                      />
                      <div id={j + 'sagyoAmtOutCu'} style={{ display: 'none' }}>
                        <div className="currencyIcon">
                          <BsUsbC style={{ fontSize: '25' }} />
                          <BsX style={{ margin: '-8px 0 0 16px' }} />
                          <span class="tooltiptext">
                            金額に変更がありましたので、以降時間と連携して手動計算されない。
                          </span>
                        </div>
                      </div>
                    </Col>
                    <Col span={4} className="input-col">
                      <Row style={{ width: '100%' }}>
                        <Col className="timer-input-col">
                          <Input
                            value={editedData[j]?.sagyoTimeOut ? secondsToHms(editedData[j]?.sagyoTimeOut) : timeHmsStr}
                            id={j + 'menualBWorkTimeOutIn'}
                            style={{
                              width: '100%',
                              color:
                                processArr[j]?.sagyoTimeOut === editedData[j]?.sagyoTimeOut
                                  ? 'black'
                                  : oldEditedData[j]?.speedOut === editedData[j]?.speedOut &&
                                    oldEditedData[j]?.lenghtOut === editedData[j]?.lenghtOut
                                  ? 'red'
                                  : '#4cc12f',
                            }}
                            className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                            onChange={(e) => {
                              //disableTimer('test');
                              settingNewDandoriAmtInArr(e.target.value, j, 'sagyoTimeOut', 'input', 'input');
                            }}
                          ></Input>
                        </Col>
                        <Col className="timer-button-col">
                          {editMode ? (
                            <>
                              <CaretUpOutlined
                                style={{ fontSize: 17 + 'px' }}
                                onClick={() => {
                                  let editedTime = handelIncreaseTime(
                                    editedData[j]?.sagyoTimeOut,
                                    '10min',
                                    j + 'sagyoTimeOut'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, j, 'sagyoTimeOut', '10min', 'inc');
                                }}
                              />
                              <CaretDownOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelDecreaseTime(
                                    editedData[j]?.sagyoTimeOut,
                                    '10min',
                                    j + 'sagyoTimeOut'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, j, 'sagyoTimeOut', '10min', 'dec');
                                }}
                              />
                              <CaretUpOutlined
                                style={{ fontSize: 17 + 'px' }}
                                onClick={() => {
                                  let editedTime = handelIncreaseTime(
                                    editedData[j]?.sagyoTimeOut,
                                    'min',
                                    j + 'sagyoTimeOut'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, j, 'sagyoTimeOut', 'min', 'inc');
                                }}
                              />
                              <CaretDownOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelDecreaseTime(
                                    editedData[j]?.sagyoTimeOut,
                                    'min',
                                    j + 'sagyoTimeOut'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, j, 'sagyoTimeOut', 'min', 'dec');
                                }}
                              />
                              <CaretUpOutlined
                                style={{ fontSize: 17 + 'px' }}
                                onClick={() => {
                                  let editedTime = handelIncreaseTime(
                                    editedData[j]?.sagyoTimeOut,
                                    'sec',
                                    j + 'sagyoTimeOut'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, j, 'sagyoTimeOut', 'sec', 'inc');
                                }}
                              />
                              <CaretDownOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelDecreaseTime(
                                    editedData[j]?.sagyoTimeOut,
                                    'sec',
                                    j + 'sagyoTimeOut'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, j, 'sagyoTimeOut', 'sec', 'dec');
                                }}
                              />
                            </>
                          ) : (
                            <></>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col span={1} className="oyabuhin-detail-col" onClick={(e) => handleCollpse('KakouOut')}></Col>
                    <Col span={5} className="formula-column" onClick={(e) => handleCollpse('KakouOut')}></Col>
                  </Row>
                  <div id={'divKakouOut'} style={{ display: 'none' }}>
                    <Row
                      style={{ color: 'black' /* display: processArr[j]?.sagyoAmtOut == 0 ? 'none' : '' */ }}
                      className="oyabuhin-detail oyabuhin-row"
                    >
                      <Col span={4} className="oyabuhin-detail-col">
                        <span className="ddl_2_noImg" style={{ marginLeft: '45px' }}>
                          外周加工速度/切断長
                        </span>
                      </Col>
                      <Col span={3} className="oyabuhin-detail-col">
                        <label id="speedOut">{processArr[j]?.speedOut}</label>
                      </Col>
                      <Col span={3} className="oyabuhin-detail-col">
                        <label id="lenghtOut">{processArr[j]?.lenghtOut}</label>
                      </Col>

                      <Col span={4} className="input-col">
                        <Input
                          value={editedData[j]?.speedOut}
                          id="speedOutIn"
                          min={0}
                          className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                          controls={false}
                          style={{
                            width: '45%',
                            border: 'none',
                            color: Number(editedData[j]?.speedOut) === processArr[j]?.speedOut ? 'black' : 'red',
                          }}
                          onChange={(e) => settingNewDandoriAmtInArr(e.target.value, 0, 'speedOut', '', '')}
                        />
                      </Col>

                      <Col span={4} className="input-col">
                        <Input
                          value={editedData[j]?.lenghtOut}
                          id="lenghtOutIn"
                          min={0}
                          className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                          controls={false}
                          style={{
                            width: '45%',
                            border: 'none',
                            color: Number(editedData[j]?.lenghtOut) === processArr[j]?.lenghtOut ? 'black' : 'red',
                          }}
                          onChange={(e) => settingNewDandoriAmtInArr(e.target.value, 0, 'lenghtOut', '', '')}
                        />
                      </Col>
                      <Col span={1} className="oyabuhin-detail-col"></Col>
                      <Col span={5} className="formula-column"></Col>
                    </Row>
                  </div>
                  <Row
                    style={{ color: 'black' /* display: processArr[j]?.sagyoAmtIn == 0 ? 'none' : '' */ }}
                    className="oyabuhin-detail oyabuhin-row"
                  >
                    <Col span={4} className="oyabuhin-detail-col" onClick={(e) => handleCollpse('KakouIn')}>
                      <div style={{ display: 'flex', marginLeft: 25 }}>
                        <CaretUpOutlined
                          id={'arrowUpKakouIn'}
                          style={{ fontSize: 17, marginTop: 2, display: 'none' }}
                        />

                        <CaretDownOutlined id={'arrowDownKakouIn'} style={{ fontSize: 17, marginTop: 2 }} />
                        <span style={{ marginLeft: 5 }}>内周加工金額/時間</span>
                      </div>
                    </Col>
                    <Col span={3} className="oyabuhin-detail-col" onClick={(e) => handleCollpse('KakouIn')}>
                      <label id={j + 'sagyoAmtIn'}>
                        {processArr[j]?.sagyoAmtIn ? JPYs.format(Math.floor(processArr[j]?.sagyoAmtIn)) : amtEmptyStr}
                      </label>
                    </Col>
                    <Col span={3} className="oyabuhin-detail-col" onClick={(e) => handleCollpse('KakouIn')}>
                      <label id={j + 'sagyoTimeIn'}>
                        {processArr[j]?.sagyoTimeIn ? secondsToHms(processArr[j]?.sagyoTimeIn) : timeHmsStr}
                      </label>
                    </Col>
                    <Col span={4} className="input-col">
                      <CurrencyInput
                        id={j + 'sagyoAmtInIn'}
                        value={editedData[j]?.sagyoAmtIn ? Math.floor(editedData[j]?.sagyoAmtIn) : amtEmptyStr}
                        defaultValue={0}
                        onValueChange={(e) => {
                          if (editedData[j]?.sagyoAmtIn != e) {
                            settingNewDandoriAmtInArr(e, j, 'sagyoAmtIn', '', '');
                            checkInputWithColor(false, '', j + 'sagyoAmtIn', e, 'amt');
                          }
                        }}
                        style={{
                          border: 'none',
                          color:
                            processArr[j]?.sagyoAmtIn === editedData[j]?.sagyoAmtIn
                              ? 'black'
                              : checkShowHideIcon(j + 'sagyoAmtInCu') === 'none'
                              ? '#4cc12f'
                              : 'red',
                        }}
                        className={
                          editMode
                            ? 'input-editable currency resetColor resetColor'
                            : 'input-non-editable currency resetColor resetColor'
                        }
                        prefix="¥"
                        decimalsLimit={100}
                      />
                      <div id={j + 'sagyoAmtInCu'} style={{ display: 'none' }}>
                        <div className="currencyIcon">
                          <BsUsbC style={{ fontSize: '25' }} />
                          <BsX style={{ margin: '-8px 0 0 16px' }} />
                          <span class="tooltiptext">
                            金額に変更がありましたので、以降時間と連携して手動計算されない。
                          </span>
                        </div>
                      </div>
                    </Col>
                    <Col span={4} className="input-col">
                      <Row style={{ width: '100%' }}>
                        <Col className="timer-input-col">
                          <Input
                            value={editedData[j]?.sagyoTimeIn ? secondsToHms(processArr[j]?.sagyoTimeIn) : timeHmsStr}
                            id={j + 'sagyoTimeInIn'}
                            style={{
                              width: '100%',
                              color:
                                processArr[j]?.sagyoTimeIn === editedData[j]?.sagyoTimeIn
                                  ? 'black'
                                  : oldEditedData[j]?.speedIn === editedData[j]?.speedIn &&
                                    oldEditedData[j]?.lenghtIn === editedData[j]?.lenghtIn
                                  ? 'red'
                                  : '#4cc12f',
                            }}
                            className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                            onChange={(e) => {
                              //disableTimer('test');
                              settingNewDandoriAmtInArr(e.target.value, j, 'sagyoTimeIn', 'input', 'input');
                            }}
                          ></Input>
                        </Col>
                        <Col className="timer-button-col">
                          {editMode ? (
                            <>
                              <CaretUpOutlined
                                style={{ fontSize: 17 + 'px' }}
                                onClick={() => {
                                  let editedTime = handelIncreaseTime(
                                    editedData[j]?.sagyoTimeIn,
                                    '10min',
                                    j + 'sagyoTimeInIn'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, j, 'sagyoTimeIn', '10min', 'inc');
                                }}
                              />
                              <CaretDownOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelDecreaseTime(
                                    editedData[j]?.sagyoTimeIn,
                                    '10min',
                                    j + 'sagyoTimeInIn'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, j, 'sagyoTimeIn', '10min', 'dec');
                                }}
                              />
                              <CaretUpOutlined
                                style={{ fontSize: 17 + 'px' }}
                                onClick={() => {
                                  let editedTime = handelIncreaseTime(
                                    editedData[j]?.sagyoTimeIn,
                                    'min',
                                    j + 'sagyoTimeInIn'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, j, 'sagyoTimeIn', 'min', 'inc');
                                }}
                              />
                              <CaretDownOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelDecreaseTime(
                                    editedData[j]?.sagyoTimeIn,
                                    'min',
                                    j + 'sagyoTimeInIn'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, j, 'sagyoTimeIn', 'min', 'dec');
                                }}
                              />
                              <CaretUpOutlined
                                style={{ fontSize: 17 + 'px' }}
                                onClick={() => {
                                  let editedTime = handelIncreaseTime(
                                    editedData[j]?.sagyoTimeIn,
                                    'sec',
                                    j + 'sagyoTimeInIn'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, j, 'sagyoTimeIn', 'sec', 'inc');
                                }}
                              />
                              <CaretDownOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelDecreaseTime(
                                    editedData[j]?.sagyoTimeIn,
                                    'sec',
                                    j + 'sagyoTimeInIn'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, j, 'sagyoTimeIn', 'sec', 'dec');
                                }}
                              />
                            </>
                          ) : (
                            <></>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col span={1} className="oyabuhin-detail-col" onClick={(e) => handleCollpse('KakouIn')}></Col>
                    <Col span={5} className="formula-column" onClick={(e) => handleCollpse('KakouIn')}></Col>
                  </Row>
                  <div id={'divKakouIn'} style={{ display: 'none' }}>
                    <Row
                      style={{ color: 'black', display: processArr[j]?.sagyoAmtIn == 0 ? 'none' : '' }}
                      className="oyabuhin-detail oyabuhin-row"
                    >
                      <Col span={4} className="oyabuhin-detail-col">
                        <span className="ddl_2_noImg" style={{ marginLeft: '45px' }}>
                          内周切断速度/切断長
                        </span>
                      </Col>
                      <Col span={3} className="oyabuhin-detail-col">
                        <label id="speedIn">{processArr[j]?.speedIn}</label>
                      </Col>
                      <Col span={3} className="oyabuhin-detail-col">
                        <label id="lenghtIn">{processArr[j]?.lenghtIn}</label>
                      </Col>

                      <Col span={4} className="input-col">
                        <Input
                          value={editedData[j]?.speedIn}
                          id="speedInIn"
                          min={0}
                          className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                          controls={false}
                          style={{
                            width: '45%',
                            border: 'none',
                            color: Number(editedData[j]?.speedIn) === processArr[j]?.speedIn ? 'black' : 'red',
                          }}
                          onKeyPress={(e) => {
                            handleKeyPress(e);
                          }}
                          onChange={(e) => settingNewDandoriAmtInArr(e.target.value, j, 'speedIn', '', '')}
                        />
                      </Col>

                      <Col span={4} className="input-col">
                        <Input
                          value={editedData[j]?.lenghtIn}
                          id="lenghtInIn"
                          min={0}
                          className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                          controls={false}
                          style={{
                            width: '45%',
                            border: 'none',
                            color: Number(editedData[j]?.lenghtIn) === processArr[j]?.lenghtIn ? 'black' : 'red',
                          }}
                          onKeyPress={(e) => {
                            handleKeyPress(e);
                          }}
                          onChange={(e) => settingNewDandoriAmtInArr(e.target.value, j, 'lenghtIn', '', '')}
                        />
                      </Col>
                      <Col span={1} className="oyabuhin-detail-col"></Col>
                      <Col span={5} className="formula-column"></Col>
                    </Row>
                  </div>
                </div>
              </div>
            );
          }
          return arr;
        })()}
      </div>
    </div>
  );
});

export default IQ3KouteiCalculate_MenualBari;
