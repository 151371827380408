const iq3KouteiCalProgram_PMX1 = [
  {
    id: 0,
    programName: 'プログラム',
    dandoriAmt: 600,
    dandoriTime: '00:10:00',
    sagyoAmt: 1420,
    sagyoTime: '00:23:40',
    eleCount: 30,
    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:00:00',
  },
  {
    id: 1,
    programName: 'プログラム',
    dandoriAmt: 160,
    dandoriTime: '00:03:00',
    sagyoAmt: 80,
    sagyoTime: '00:01:00',
    eleCount: 3,
    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:00:00',
  },
  {
    id: 2,
    programName: 'プログラム',
    dandoriAmt: 180,
    dandoriTime: '00:03:00',
    sagyoAmt: 120,
    sagyoTime: '00:02:00',
    eleCount: 6,
    naibuProcessValidationAmt: 180,
    naibuProcessValidationTime: '00:03:00',
  },
  {
    id: 3,
    programName: 'プログラム',
    dandoriAmt: 0,
    dandoriTime: '00:00:00',
    sagyoAmt: 0,
    sagyoTime: '00:00:00',
    eleCount: 0,
    naibuProcessValidationAmt: 0,
    naibuProcessValidationTime: '00:00:00',
  },
];

const iq3KouteiCalHyomen_PMX1 = [
  {
    id: 0,
    handelingAmt: 80,
    handelingTime: '00:01:20',
    kensaAmt: 80,
    kensaTime: '00:01:20',
    dandoriKakouAmt: [
      {
        id: 0,
        processName: '表面処理(No1)',
        processType: 'ユニクロメッキ',
        dandoriAmt: 350,
        dandoriTime: '00:05:00',
        sagyoAmt: 10,
        sagyoTime: '00:00:00',
        formula: '段階時間チャージ x 段階時間 x 係数',
        subDiameter: 0, //加工長
        subCount: 0, //箇所
        subTimeSancho: 0, //回数
        subTimekari: 0,
        subFinishSancho: '運用', //溶接仕上
        subFinishKari: '運用',
        calMethod: '面積',
        calInput: 150.25,
        shori: 'メッキ',
      },
      {
        id: 1,
        processName: '表面処理(No2)',
        processType: 'アクリル塗装',
        dandoriAmt: 350,
        dandoriTime: '00:05:00',
        sagyoAmt: 10,
        sagyoTime: '00:00:00',
        formula: '段階時間チャージ x 段階時間 x 係数',
        subDiameter: 0,
        subCount: 0,
        subTimeSancho: 0, //回数
        subTimekari: 0,
        subFinishSancho: '運用', //溶接仕上
        subFinishKari: '運用',
        calMethod: '重量',
        calInput: 30.25,
        shori: '塗装(両面)',
      },
    ],
  },
];

export const additionalProcessArr = [
  {
    id: 0,
    processName: '追加工程①',
    totalAmt: 260,
    totalTime: '00:04:20',
    processDetail: [
      {
        id: 0,
        processDetailName: '仮組_標準',
        dandoriAmt: 80,
        dandoriTime: '00:01:20',
        kensaAmt: 80,
        kensaTime: '00:01:20',
        handelingAmt: 80,
        handelingTime: '00:01:20',
        processAmt: 80,
        processTime: '00:01:20',
        kasho: 1,
        itaku: true,
        supplier: '株式会社塗装',
        supplierCode: '101',
      },
      {
        id: 1,
        processDetailName: 'ネスティング_標準',
        dandoriAmt: 80,
        dandoriTime: '00:01:20',
        kensaAmt: 80,
        kensaTime: '00:01:20',
        handelingAmt: 80,
        handelingTime: '00:01:20',
        processAmt: 80,
        processTime: '00:01:20',
        kasho: 1,
        itaku: true,
        supplier: '株式会社塗装',
        supplierCode: '101',
      },
      {
        id: 2,
        processDetailName: '水張検査_標準',
        dandoriAmt: 80,
        dandoriTime: '00:01:20',
        kensaAmt: 80,
        kensaTime: '00:01:20',
        handelingAmt: 80,
        handelingTime: '00:01:20',
        processAmt: 80,
        processTime: '00:01:20',
        kasho: 1,
        itaku: true,
        supplier: '株式会社塗装',
        supplierCode: '101',
      },
    ],
  },
  /*  {
    id: 1,
    processName: '追加工程②',
    totalAmt: 260,
    totalTime: '00:04:20',
    processDetail: [
      {
        id: 0,
        processDetailName: '仮組_標準',
        dandoriAmt: 80,
        dandoriTime: '00:01:20',
        kensaAmt: 80,
        kensaTime: '00:01:20',
        handelingAmt: 80,
        handelingTime: '00:01:20',
        processAmt: 80,
        processTime: '00:01:20',
        kasho: 1,
        itaku: true,
        supplier: '株式会社塗装',
        supplierCode: '101',
      },
      {
        id: 1,
        processDetailName: 'ネスティング_標準',
        dandoriAmt: 80,
        dandoriTime: '00:01:20',
        kensaAmt: 80,
        kensaTime: '00:01:20',
        handelingAmt: 80,
        handelingTime: '00:01:20',
        processAmt: 80,
        processTime: '00:01:20',
        kasho: 1,
        itaku: true,
        supplier: '株式会社塗装',
        supplierCode: '101',
      },
      {
        id: 2,
        processDetailName: '水張検査_標準',
        dandoriAmt: 80,
        dandoriTime: '00:01:20',
        kensaAmt: 80,
        kensaTime: '00:01:20',
        handelingAmt: 80,
        handelingTime: '00:01:20',
        processAmt: 80,
        processTime: '00:01:20',
        kasho: 1,
        itaku: true,
        supplier: '株式会社塗装',
        supplierCode: '101',
      },
    ],
  }, */
];

const yousetsu1Data1 = [
  {
    id: 0,
    handelingAmt: 80,
    handelingTime: '00:01:20',
    kensaAmt: 0,
    kensaTime: '00:00:00',
    dandoriKakouAmt: [
      {
        id: 0,
        processName: '溶接(No1)',
        processType: 'アルゴン',
        dandoriAmt: 0,
        dandoriTime: '00:00:00',
        sagyoAmt: 0,
        sagyoTime: '00:00:00',
        formula: '段階時間チャージ x 段階時間 x 係数',
        subDiameter: 100, //加工長
        subCount: 1, //箇所
        subTimeSancho: 2, //回数
        subTimekari: 4,
        subFinishSancho: '運用', //溶接仕上
        subFinishKari: '運用',
      },
      {
        id: 1,
        processName: '溶接(No2)',
        processType: 'YAGロボット',
        dandoriAmt: 0,
        dandoriTime: '00:00:00',
        sagyoAmt: 0,
        sagyoTime: '00:00:00',
        formula: '段階時間チャージ x 段階時間 x 係数',
        subDiameter: 100, //加工長
        subCount: 1, //箇所
        subTimeSancho: 2, //回数
        subTimekari: 4,
        subFinishSancho: '運用', //溶接仕上
        subFinishKari: '運用',
      },
      {
        id: 2,
        processName: '溶接(No1)',
        processType: '点付',
        dandoriAmt: 210,
        dandoriTime: '00:03:00',
        sagyoAmt: 1458,
        sagyoTime: '00:20:50',
        formula: '段階時間チャージ x 段階時間 x 係数',
        subDiameter: 0, //径
        subCount: 10, //加工数
        subTime: 2, //回数
        subFinish: '除外',
      },
      {
        id: 3,
        processName: '溶接(No2)',
        processType: 'ナット',
        dandoriAmt: 210,
        dandoriTime: '00:03:00',
        sagyoAmt: 1458,
        sagyoTime: '00:20:50',
        formula: '段階時間チャージ x 段階時間 x 係数',
        subDiameter: 5,
        subCount: 4,
        subTime: 2,
        subFinish: '除外',
      },
    ],
  },
];

const yousetsuFinishData = [
  {
    id: 0,
    processName: '溶接仕上(No1)',
    handelingAmt: 80,
    handelingTime: '00:01:20',
    kensaAmt: 0,
    kensaTime: '00:00:00',
    processType: 'YAGロボット',
    subFinishSancho: '運用', //溶接仕上
    subFinishKari: '除外',
    subDiameter: 100, //加工長
    subCount: 0, //箇所
    processDetail: [
      {
        detailProcessName: 'バフ',
        detailProcessCode: '001',
        dandoriTime: '00:05:00',
        dandoriAmt: 350,
        sagyoTime: '00:01:15',
        sagyoAmt: 87,
      },
      {
        detailProcessName: '酸洗',
        detailProcessCode: '002',
        dandoriTime: '00:05:00',
        dandoriAmt: 350,
        sagyoTime: '00:01:15',
        sagyoAmt: 87,
      },
      {
        detailProcessName: 'サンダー',
        detailProcessCode: '003',
        dandoriTime: '00:05:00',
        dandoriAmt: 350,
        sagyoTime: '00:01:15',
        sagyoAmt: 87,
      },
    ],
  },
];

const autoBProcessData = [
  {
    id: 0,
    processName: '自動バリ取(No1)',
    dandoriAmt: 175,
    dandoriTime: '00:03:00',
    sagyoAmt: 18,
    sagyoTime: '00:00:18',
    processArea: '120.00c㎡',
    processCount: 1,
    materialHandlingAmt: 132,
    materialHandlingTime: '00:01:00',
    naibuProcessValidationAmt: 180,
    naibuProcessValidationTime: '00:03:00',
  },
];

const menualBProcessData = [
  {
    id: 0,
    processName: '手動バリ取取(No1)',
    dandoriAmt: 50,
    dandoriTime: '00:01:00',
    sagyoAmt: 14,
    sagyoTime: '00:00:16',
    processLength: 270.0,
    processCount: 1,
    sagyoAmtOut: 17,
    sagyoTimeOut: '00:00:14',
    lenghtOut: 1600,
    speedOut: 1,
    sagyoAmtIn: 5,
    sagyoTimeIn: '00:00:04',
    lenghtIn: 1600,
    speedIn: 1,
    materialHandlingAmt: 132,
    materialHandlingTime: '00:01:00',
    naibuProcessValidationAmt: 180,
    naibuProcessValidationTime: '00:03:00',
  },
];

const blankDataArr1_Combi = [
  /* {
    id: 0,
    processName: 'ブランク',
    processType: 'レーザー',
    dandori: [
      { dandoriType: '基本', dandoriAmt: 243, dandoriTime: '00:02:00' },
      { dandoriType: '(金型)', dandoriAmt: 0, dandoriTime: '00:00:00' },
    ],
    sagyo: [
      { sagyoType: '外周切断', sagyoAmt: 4, sagyoTime: '00:00:02', speed: '1500mm/分', length: '1600mm' },
      { sagyoType: '内周切断', sagyoAmt: 35, sagyoTime: '00:00:17', speed: '1500mm/分', length: '1600mm' },
      { sagyoType: '空送', sagyoAmt: 2, sagyoTime: '00:00:01', speed: '4000mm/分', length: '629.0816mm' },
      { sagyoType: 'ピアス加工', sagyoAmt: 7, sagyoTime: '00:00:10', piercingcount: 9, piercingTime: '00:00:05' },
      { sagyoType: 'ケガキ加工', sagyoAmt: 0, sagyoTime: '00:00:00', speed: '500mm/分', length: '0mm' },
    ],

    drillingAmt: 0,
    drillingTime: '00:00:00',
    drillingCount: 0,
    drillingCountTime: '00:00:00',
    jointAmt: 0,
    jointTime: '00:00:00',
    jointCount: 0,
    jointCountTime: '00:00:00',

    kensaAmt: 60,
    kensaTime: '00:01:00',
  }, */
  {
    id: 0,
    processName: 'ブランク',
    processType: '複合機',
    dandori: [
      { dandoriType: '基本', dandoriAmt: 243, dandoriTime: '00:02:00' },
      { dandoriType: '金型', dandoriAmt: 0, dandoriTime: '00:00:00' },
    ],
    sagyo: [
      {
        MFPType: 'レーザー',
        MFP: [
          {
            sagyoType: '外周切断',
            sagyoAmt: 4,
            sagyoTime: '00:00:02',
            speed: 1500,
            length: 1600,
            secondrySagyoType: 'レーザー',
          },
          {
            sagyoType: '内周切断',
            sagyoAmt: 35,
            sagyoTime: '00:00:17',
            speed: 1500,
            length: 1600,
            secondrySagyoType: 'レーザー',
          },

          {
            sagyoType: 'ピアス加工',
            sagyoAmt: 7,
            sagyoTime: '00:00:10',
            piercingcount: 9,
            piercingTime: '00:00:05',
            secondrySagyoType: 'レーザー',
          },
          {
            sagyoType: 'ケガキ加工',
            sagyoAmt: 0,
            sagyoTime: '00:00:00',
            speed: 500,
            length: 0,
            secondrySagyoType: 'レーザー',
          },
        ],
      },
      {
        MFPType: 'パンチ',
        MFP: [
          {
            sagyoType: '通常パンチ',
            sagyoAmt: 7,
            sagyoTime: '00:00:10',
            piercingcount: 9,
            piercingTime: '00:00:05',
            secondrySagyoType: 'パンチ',
          },
        ],
      },
      {
        MFPType: '空送',
        MFP: [
          {
            sagyoType: '空送',
            sagyoAmt: 19,
            sagyoTime: '00:00:12',
            speed: 5000,
            length: 651.612,
            secondrySagyoType: '空送',
          },
        ],
      },
    ],

    drillingAmt: 0,
    drillingTime: '00:00:00',
    drillingCount: 0,
    drillingCountTime: '00:00:00',
    jointAmt: 0,
    jointTime: '00:00:00',
    jointCount: 0,
    jointCountTime: '00:00:00',

    kensaAmt: 60,
    kensaTime: '00:01:00',
  },
];

const blankDataArr2_Punch = [
  {
    id: 0,
    processName: 'ブランク',
    processType: 'パンチ',
    dandori: [
      { dandoriType: '基本', dandoriAmt: 243, dandoriTime: '00:02:00' },
      { dandoriType: '金型', dandoriAmt: 0, dandoriTime: '00:00:00' },
    ],
    sagyo: [
      {
        MFPType: 'パンチ',
        MFP: [
          {
            sagyoType: 'パンチ',
            sagyoAmt: 7,
            sagyoTime: '00:00:10',
            piercingcount: 64,
            piercingTime: '00:00:05',
            secondrySagyoType: 'パンチ',
          },
        ],
      },
      {
        MFPType: '空送',
        MFP: [
          {
            sagyoType: '空送',
            sagyoAmt: 19,
            sagyoTime: '00:00:12',
            speed: 12000,
            length: 400,
            secondrySagyoType: '空送',
          },
        ],
      },
      {
        MFPType: 'ケガキ加工',
        MFP: [
          {
            sagyoType: 'ケガキ加工',
            sagyoAmt: 0,
            sagyoTime: '00:00:00',
            speed: 5200,
            length: 0,
            secondrySagyoType: 'ケガキ加工',
          },
        ],
      },
    ],

    drillingAmt: 0,
    drillingTime: '00:00:00',
    drillingCount: 0,
    drillingCountTime: '00:00:00',
    jointAmt: 0,
    jointTime: '00:00:00',
    jointCount: 0,
    jointCountTime: '00:00:00',

    kensaAmt: 60,
    kensaTime: '00:01:00',
  },
];

const blankDataArr1_Laser = [
  {
    id: 0,
    processName: 'ブランク',
    processType: 'レーザー',
    dandori: [{ dandoriType: '基本', dandoriAmt: 243, dandoriTime: '00:02:00' }],
    sagyo: [
      {
        MFPType: '外周切断',
        MFP: [
          {
            sagyoType: '外周切断',
            sagyoAmt: 4,
            sagyoTime: '00:00:02',
            speed: 1500,
            length: 1600,
            secondrySagyoType: 'レーザー',
          },
        ],
      },
      {
        MFPType: '内周切断',
        MFP: [
          {
            sagyoType: '内周切断',
            sagyoAmt: 35,
            sagyoTime: '00:00:17',
            speed: 1500,
            length: 1600,
            secondrySagyoType: 'レーザー',
          },
        ],
      },
      {
        MFPType: '空送',
        MFP: [
          {
            sagyoType: '空送',
            sagyoAmt: 19,
            sagyoTime: '00:00:12',
            speed: 5000,
            length: 651.612,
            secondrySagyoType: '空送',
          },
        ],
      },
      {
        MFPType: 'ピアス加工',
        MFP: [
          {
            sagyoType: 'ピアス加工',
            sagyoAmt: 7,
            sagyoTime: '00:00:10',
            piercingcount: 9,
            piercingTime: '00:00:05',
            secondrySagyoType: 'レーザー',
          },
        ],
      },
      {
        MFPType: 'ケガキ加工',
        MFP: [
          {
            sagyoType: 'ケガキ加工',
            sagyoAmt: 0,
            sagyoTime: '00:00:00',
            speed: 500,
            length: 0,
            secondrySagyoType: 'レーザー',
          },
        ],
      },
    ],

    drillingAmt: 0,
    drillingTime: '00:00:00',
    drillingCount: 0,
    drillingCountTime: '00:00:00',
    jointAmt: 0,
    jointTime: '00:00:00',
    jointCount: 0,
    jointCountTime: '00:00:00',

    kensaAmt: 60,
    kensaTime: '00:01:00',
  },
];

const nijiDataArr1 = [
  {
    id: 0,
    handelingAmt: 80,
    handelingTime: '00:01:20',
    kensaAmt: 80,
    kensaTime: '00:01:20',
    dandoriKakouAmt: [
      {
        id: 0,
        processName: 'タップ(M4)',
        processType: 'タップ',
        dandoriAmt: 80,
        dandoriTime: '00:01:20',
        sagyoAmt: 80,
        sagyoTime: '00:01:20',
        formula: '段階時間チャージ x 段階時間 x 係数',
        amount: 1, //subDiameter
        timeForOne: '00:01:20', //subCount
        subTimeSancho: 0, //回数
        subTimekari: 0,
        subFinishSancho: '運用', //溶接仕上
        subFinishKari: '運用',
      },
      {
        id: 1,
        processName: 'タップ(M5)',
        processType: 'タップ',
        dandoriAmt: 80,
        dandoriTime: '00:01:20',
        sagyoAmt: 80,
        sagyoTime: '00:01:20',
        formula: '段階時間チャージ x 段階時間 x 係数',
        amount: 1, //subDiameter
        timeForOne: '00:01:20', //subCount
        subTimeSancho: 0, //回数
        subTimekari: 0,
        subFinishSancho: '運用', //溶接仕上
        subFinishKari: '運用',
      },
      {
        id: 2,
        processName: 'サラ(M4)',
        processType: 'サラ',
        dandoriAmt: 80,
        dandoriTime: '00:01:20',
        sagyoAmt: 80,
        sagyoTime: '00:01:20',
        formula: '段階時間チャージ x 段階時間 x 係数',
        amount: 1, //subDiameter
        timeForOne: '00:01:20', //subCount
        subTime: 1, //回数
        subFinish: '除外',
      },
      {
        id: 3,
        processName: 'ナット圧入(M4)',
        processType: 'ナット',
        dandoriAmt: 80,
        dandoriTime: '00:01:20',
        sagyoAmt: 80,
        sagyoTime: '00:01:20',
        formula: '段階時間チャージ x 段階時間 x 係数',
        amount: 1, //subDiameter
        timeForOne: '00:01:20', //subCount
        subTime: 1,
        subFinish: '除外',
      },
    ],
  },
];

const punchingDataArr1 = [
  {
    id: 0,
    handelingAmt: 80,
    handelingTime: '00:01:20',
    kensaAmt: 80,
    kensaTime: '00:01:20',
    programAmt: 80,
    programTime: '00:01:20',
    mageDandoriAmt: 80,
    mageDandoriTime: '00:01:20',
    dandoriKakouAmt: [
      {
        id: 0,
        processName: '曲げ_V',
        processType: '曲げ_V',
        dandoriAmt: 80,
        dandoriTime: '00:01:20',
        sagyoAmt: 80,
        sagyoTime: '00:01:20',
        formula: '段階時間チャージ x 段階時間 x 係数',
        amount: 1, //subDiameter
        timeForOne: '00:01:20', //subCount
        subTimeSancho: 0, //回数
        subTimekari: 0,
        subFinishSancho: '運用', //溶接仕上
        subFinishKari: '運用',
      },
      {
        id: 1,
        processName: '曲げ_HM',
        processType: '曲げ_HM',
        dandoriAmt: 80,
        dandoriTime: '00:01:20',
        sagyoAmt: 80,
        sagyoTime: '00:01:20',
        formula: '段階時間チャージ x 段階時間 x 係数',
        amount: 1, //subDiameter
        timeForOne: '00:01:20', //subCount
        subTimeSancho: 0, //回数
        subTimekari: 0,
        subFinishSancho: '運用', //溶接仕上
        subFinishKari: '運用',
      },
      {
        id: 2,
        processName: '曲げ_FR',
        processType: '曲げ_FR',
        dandoriAmt: 80,
        dandoriTime: '00:01:20',
        sagyoAmt: 80,
        sagyoTime: '00:01:20',
        formula: '段階時間チャージ x 段階時間 x 係数',
        amount: 1, //subDiameter
        timeForOne: '00:01:20', //subCount
        subTime: 1, //回数
        subFinish: '除外',
      },
    ],
  },
];

const shirringDataArr1 = [
  {
    id: 0,
    handelingAmt: 80,
    handelingTime: '00:01:20',
    kensaAmt: 80,
    kensaTime: '00:01:20',
    dandoriKakouAmt: [
      {
        id: 0,
        processName: '表面処理(No1)',
        processType: 'ユニクロメッキ',
        dandoriAmt: 350,
        dandoriTime: '00:05:00',
        sagyoAmt: 0,
        sagyoTime: '00:00:00',
        formula: '段階時間チャージ x 段階時間 x 係数',
        cutCount: 1,
        timePerCut: '00:00:00',
      },
    ],
  },
];

const kensaDataArr1 = [
  {
    id: 0,
    handelingAmt: 80,
    handelingTime: '00:01:20',
    kensaAmt: 0,
    kensaTime: '00:00:00',
    dandoriKakouAmt: [
      {
        id: 0,
        processName: '表面処理(No1)',
        processType: 'ユニクロメッキ',
        dandoriAmt: 350,
        dandoriTime: '00:05:00',
        sagyoAmt: 0,
        sagyoTime: '00:00:00',
        formula: '段階時間チャージ x 段階時間 x 係数',
        kensaCount: 1,
        timePerKensa: '00:00:00',
      },
    ],
  },
];

const oyaAdditionalProcessArr = [
  {
    id: 0,
    processName: '追加工程①',
    totalAmt: 260,
    totalTime: '00:04:20',
    processDetail: [
      {
        id: 0,
        processDetailName: '仮組_標準',
        dandoriAmt: 80,
        dandoriTime: '00:01:20',
        kensaAmt: 160,
        kensaTime: '00:02:40',
        handelingAmt: 80,
        handelingTime: '00:01:20',
        processAmt: 80,
        processTime: '00:01:20',
        kasho: 1,
        itaku: true,
        supplier: '株式会社塗装',
        supplierCode: '101',
      },
    ],
  },
];

export const iq3KouteiCalInfoPMX1_part1 = [
  {
    key: 0,
    kouteiName: 'プログラム',
    kouteiContent: iq3KouteiCalProgram_PMX1[0],
  },
  {
    key: 1,
    kouteiName: 'ブランク',
    kouteiContent: blankDataArr1_Combi,
  },
  {
    key: 2,
    kouteiName: 'ベンディング',
    kouteiContent: punchingDataArr1,
  },
  {
    key: 3,
    kouteiName: '2次加工',
    kouteiContent: nijiDataArr1,
  },

  {
    key: 4,
    kouteiName: '表面処理',
    kouteiContent: iq3KouteiCalHyomen_PMX1,
  },
  {
    key: 5,
    kouteiName: '追加工程',
    kouteiContent: additionalProcessArr,
  },
  {
    key: 6,
    kouteiName: '溶接',
    kouteiContent: yousetsu1Data1,
  },
  {
    key: 7,
    kouteiName: '溶接仕上',
    kouteiContent: yousetsuFinishData,
  },
  {
    key: 8,
    kouteiName: '自動バリ',
    kouteiContent: autoBProcessData,
  },
  {
    key: 9,
    kouteiName: '手動バリ',
    kouteiContent: menualBProcessData,
  },
  {
    key: 10,
    kouteiName: 'シャーリング',
    kouteiContent: shirringDataArr1,
  },
  {
    key: 11,
    kouteiName: '検査',
    kouteiContent: kensaDataArr1,
  },
];

export const iq3KouteiCalInfoPMX1_part2 = [
  {
    key: 0,
    kouteiName: 'プログラム',
    kouteiContent: iq3KouteiCalProgram_PMX1[1],
  },
  {
    key: 1,
    kouteiName: 'ブランク',
    kouteiContent: blankDataArr2_Punch,
  },
  {
    key: 2,
    kouteiName: 'ベンディング',
    kouteiContent: punchingDataArr1,
  },
  {
    key: 3,
    kouteiName: '2次加工',
    kouteiContent: nijiDataArr1,
  },

  {
    key: 4,
    kouteiName: '表面処理',
    kouteiContent: iq3KouteiCalHyomen_PMX1,
  },
  {
    key: 5,
    kouteiName: '追加工程',
    kouteiContent: additionalProcessArr,
  },
  {
    key: 6,
    kouteiName: '溶接',
    kouteiContent: yousetsu1Data1,
  },
  {
    key: 7,
    kouteiName: '溶接仕上',
    kouteiContent: yousetsuFinishData,
  },
  {
    key: 8,
    kouteiName: '自動バリ',
    kouteiContent: autoBProcessData,
  },
  {
    key: 9,
    kouteiName: '手動バリ',
    kouteiContent: menualBProcessData,
  },
  {
    key: 10,
    kouteiName: 'シャーリング',
    kouteiContent: shirringDataArr1,
  },
  {
    key: 11,
    kouteiName: '検査',
    kouteiContent: kensaDataArr1,
  },
];

export const iq3KouteiCalInfoPMX1_part3 = [
  {
    key: 0,
    kouteiName: 'プログラム',
    kouteiContent: iq3KouteiCalProgram_PMX1[2],
  },
  {
    key: 1,
    kouteiName: 'ブランク',
    kouteiContent: blankDataArr1_Laser,
  },
  {
    key: 2,
    kouteiName: 'ベンディング',
    kouteiContent: punchingDataArr1,
  },
  {
    key: 3,
    kouteiName: '2次加工',
    kouteiContent: nijiDataArr1,
  },

  {
    key: 4,
    kouteiName: '表面処理',
    kouteiContent: iq3KouteiCalHyomen_PMX1,
  },
  {
    key: 5,
    kouteiName: '追加工程',
    kouteiContent: additionalProcessArr,
  },
  {
    key: 6,
    kouteiName: '溶接',
    kouteiContent: yousetsu1Data1,
  },
  {
    key: 7,
    kouteiName: '溶接仕上',
    kouteiContent: yousetsuFinishData,
  },
  {
    key: 8,
    kouteiName: '自動バリ',
    kouteiContent: autoBProcessData,
  },
  {
    key: 9,
    kouteiName: '手動バリ',
    kouteiContent: menualBProcessData,
  },
  {
    key: 10,
    kouteiName: 'シャーリング',
    kouteiContent: shirringDataArr1,
  },
  {
    key: 11,
    kouteiName: '検査',
    kouteiContent: kensaDataArr1,
  },
];

export const iq3KouteiCalInfoPMX1_part4 = [
  {
    key: 0,
    kouteiName: 'プログラム',
    kouteiContent: iq3KouteiCalProgram_PMX1,
  },
  {
    key: 1,
    kouteiName: 'ブランク',
    kouteiContent: blankDataArr1_Combi,
  },
  {
    key: 2,
    kouteiName: 'ベンディング',
    kouteiContent: punchingDataArr1,
  },
  {
    key: 3,
    kouteiName: '2次加工',
    kouteiContent: nijiDataArr1,
  },

  {
    key: 4,
    kouteiName: '表面処理',
    kouteiContent: iq3KouteiCalHyomen_PMX1,
  },
  {
    key: 5,
    kouteiName: '追加工程',
    kouteiContent: additionalProcessArr,
  },
  {
    key: 6,
    kouteiName: '溶接',
    kouteiContent: yousetsu1Data1,
  },
  {
    key: 7,
    kouteiName: '溶接仕上',
    kouteiContent: yousetsuFinishData,
  },
  {
    key: 8,
    kouteiName: '自動バリ',
    kouteiContent: autoBProcessData,
  },
  {
    key: 9,
    kouteiName: '手動バリ',
    kouteiContent: menualBProcessData,
  },
  {
    key: 10,
    kouteiName: 'シャーリング',
    kouteiContent: shirringDataArr1,
  },
  {
    key: 11,
    kouteiName: '検査',
    kouteiContent: kensaDataArr1,
  },
];

const oyaYousetsu1Data1 = [
  {
    id: 0,
    handelingAmt: 0,
    handelingTime: '00:00:00',
    kensaAmt: 0,
    kensaTime: '00:00:00',
    dandoriKakouAmt: [
      {
        id: 0,
        processName: '溶接(No1)',
        processType: 'アルゴン',
        dandoriAmt: 70,
        dandoriTime: '00:01:00',
        sagyoAmt: 233.33,
        sagyoTime: '00:03:20',
        formula: '段階時間チャージ x 段階時間 x 係数',
        subDiameter: 100, //加工長
        subCount: 1, //箇所
        subTimeSancho: 2, //回数
        subTimekari: 4,
        subFinishSancho: '運用', //溶接仕上
        subFinishKari: '運用',
      },
      {
        id: 1,
        processName: '溶接(No2)',
        processType: 'YAGロボット',
        dandoriAmt: 0,
        dandoriTime: '00:00:00',
        sagyoAmt: 93.333,
        sagyoTime: '00:01:20',
        formula: '段階時間チャージ x 段階時間 x 係数',
        subDiameter: 100,
        subCount: 1, //箇所
        subTimeSancho: 2, //回数
        subTimekari: 4,
        subFinishSancho: '運用', //溶接仕上
        subFinishKari: '運用',
      },
      {
        id: 2,
        processName: 'サブ溶接(No1)',
        processType: '点付',
        dandoriAmt: 60,
        dandoriTime: '00:01:00',
        sagyoAmt: 180,
        sagyoTime: '00:03:00',
        formula: '段階時間チャージ x 段階時間 x 係数',
        subDiameter: 0, //径
        subCount: 10, //加工数
        subTime: 2, //回数
        subFinish: '運用',
      },
      {
        id: 3,
        processName: 'サブ溶接(No2)',
        processType: 'ナット溶接',
        dandoriAmt: 60,
        dandoriTime: '00:01:00',
        sagyoAmt: 180,
        sagyoTime: '00:03:00',
        formula: '段階時間チャージ x 段階時間 x 係数',
        subDiameter: 5,
        subCount: 10,
        subTime: 2,
        subFinish: '運用',
      },
    ],
  },
];

const oyaYousetsuFinishData = [
  {
    id: 0,
    processName: '溶接仕上(No1)',
    handelingAmt: 0,
    handelingTime: '00:00:00',
    kensaAmt: 0,
    kensaTime: '00:00:00',
    processType: 'アルゴン',
    subFinishSancho: '運用', //溶接仕上
    subFinishKari: '除外',
    subDiameter: 100, //加工長
    subCount: 0, //箇所
    processDetail: [
      {
        detailProcessName: 'バフ',
        detailProcessCode: '001',
        dandoriTime: '00:05:00',
        dandoriAmt: 350,
        sagyoTime: '00:01:15',
        sagyoAmt: 87.5,
      },
      {
        detailProcessName: '酸洗',
        detailProcessCode: '002',
        dandoriTime: '00:05:00',
        dandoriAmt: 350,
        sagyoTime: '00:01:33',
        sagyoAmt: 109.375,
      },
      {
        detailProcessName: 'サンダー',
        detailProcessCode: '003',
        dandoriTime: '00:01:00',
        dandoriAmt: 70,
        sagyoTime: '00:02:05',
        sagyoAmt: 145.83334,
      },
    ],
  },
];

const oyaHyomenArr = [
  {
    id: 0,
    handelingAmt: 0,
    handelingTime: 0,
    kensaAmt: 0,
    kensaTime: 0,
    dandoriKakouAmt: [
      {
        id: 0,
        processName: '表面処理(No1)',
        processType: '2',
        dandoriAmt: 350,
        dandoriTime: '00:05:00',
        sagyoAmt: 10,
        sagyoTime: '00:00:00',
        formula: '段階時間チャージ x 段階時間 x 係数',
        subDiameter: 0, //加工長
        subCount: 0, //箇所
        subTimeSancho: 0, //回数
        subTimekari: 0,
        subFinishSancho: '運用', //溶接仕上
        subFinishKari: '運用',
        calMethod: '面積',
        calInput: 150.25,
        shori: 'メッキ',
      },
      {
        id: 1,
        processName: '表面処理(No2)',
        processType: '10',
        dandoriAmt: 350,
        dandoriTime: '00:05:00',
        sagyoAmt: 10,
        sagyoTime: '00:00:00',
        formula: '段階時間チャージ x 段階時間 x 係数',
        subDiameter: 0,
        subCount: 0,
        subTimeSancho: 0, //回数
        subTimekari: 0,
        subFinishSancho: '運用', //溶接仕上
        subFinishKari: '運用',
        calMethod: '重量',
        calInput: 30.25,
        shori: '塗装(両面)',
      },
    ],
  },
];

const oyaKumitateArr = [
  {
    id: 0,
    processName: '組立(No1)',
    calculateMethod: '子部品点数',
    totalCount: 45,
    dandoriAmt: 70,
    dandoriTime: '00:01:00',
    childPart: [
      {
        childName: '基本',
        cDandoriAmt: 0,
        cDandoriTime: '00:00:00',
        cSagyoAmtKumitate: 70,
        cSagyoTimeKumitate: '00:01:00',
        deviceCount: 4, //部品数
        timePerDevice: '00:00:15',
        kihon: true,
      },
      {
        childName: '六角ウエルドナット',
        cDandoriAmt: 0,
        cDandoriTime: '00:00:00',
        cSagyoAmtKumitate: 1500,
        cSagyoTimeKumitate: '00:20:00', //取付時間
        deviceCount: 10, //購入品必要数
        timePerDevice: '00:02:00',
        kihon: false,
      },
      {
        childName: 'オネジスタッド',
        cDandoriAmt: 0,
        cDandoriTime: '00:00:00',
        cSagyoAmtKumitate: 640,
        cSagyoTimeKumitate: '00:10:00', //取付時間
        deviceCount: 10, //購入品必要数
        timePerDevice: '00:01:00',
        kihon: false,
      },
    ],
    childTypeCount: 70,
    weight: 15.648,
    quality: '高品質',
  },
];

const oyaKensaArr = [
  {
    id: 0,
    processName: '組立(No1)',
    calculateMethod: '子部品点数',
    totalCount: 45,
    dandoriAmt: 70,
    dandoriTime: '00:01:00',
    childPart: [
      {
        childName: '基本',
        cDandoriAmt: 0,
        cDandoriTime: '00:00:00',
        cSagyoAmtKensa: 70,
        cSagyoTimeKensa: '00:01:00',
        deviceCount: 4,
        timePerDevice: '00:00:15',
      },
    ],
    childTypeCount: 70,
    weight: 8.681,
    quality: '高品質',
  },
];

const oyaKonpoArr = [
  {
    id: 0,
    processName: '梱包(No1)',
    calculateMethod: '子部品点数',
    totalCount: 45,
    dandoriAmt: 70,
    dandoriTime: '00:01:00',
    childPart: [
      {
        childName: '基本',
        cDandoriAmt: 0,
        cDandoriTime: '00:00:00',
        cSagyoAmtKonpo: 70,
        cSagyoTimeKonpo: '00:01:00',
        deviceCount: 4,
        timePerDevice: '00:00:15',
      },
    ],
    childTypeCount: 70,
    weight: 15.648,
    quality: '高品質',
  },
];

export const oyaKouteiCalInfo = [
  {
    key: 0,
    kouteiName: '溶接',
    kouteiContent: oyaYousetsu1Data1,
  },
  {
    key: 1,
    kouteiName: '溶接仕上',
    kouteiContent: oyaYousetsuFinishData,
  },

  {
    key: 2,
    kouteiName: '表面処理',
    kouteiContent: oyaHyomenArr,
  },
  {
    key: 3,
    kouteiName: '組立',
    kouteiContent: oyaKumitateArr,
  },
  {
    key: 4,
    kouteiName: '検査',
    kouteiContent: oyaKensaArr,
  },
  {
    key: 5,
    kouteiName: '梱包',
    kouteiContent: oyaKonpoArr,
  },
  {
    key: 6,
    kouteiName: '追加工程',
    kouteiContent: oyaAdditionalProcessArr,
  },
];
