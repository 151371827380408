import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { Row, Col, Input, Select, Popover, Checkbox, Space, InputNumber, notification } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import CurrencyInput from 'react-currency-input-field';
import { BsUsbC, BsX } from 'react-icons/bs';

import '../../assets/styles/common.css';
import {
  handelIncreaseTime,
  handelDecreaseTime,
  checkInput,
  secondsToHms,
  setTimetoSec,
  checkInputWithColor,
  isNumber,
  checkShowHideIcon,
  handleCollpse,
  handleKeyPress,
  JPYs,
  kouteiCalculateSelectedArr,
} from '../common/Common.js';
import { DataType, ServiceClass, WorkType } from '../common/enums';
import { amtEmptyStr, timeHmsStr } from '../common/Constant';

const zeroArr = [
  {
    id: 0,
    processName: 'ブランク',
    dandori: [],
    sagyo: [],
  },
];

const settingEditData = (arr) => {
  let editDataArr = [];
  let dandoriArr = [];
  let sagyoArr = [];
  if (arr?.length > 0) {
    dandoriArr = arr[0].dandori;
    sagyoArr = arr[0].sagyo;
  }

  for (let i = 0; i < arr?.length; i++) {
    editDataArr.push({
      id: i,
      dandori: dandoriArr,
      sagyo: sagyoArr,
      kensaAmt: arr[0].kensaAmt,
      kensaTime: arr[0].kensaTime,
      drillingAmt: arr[0].drillingAmt,
      drillingTime: arr[0].drillingTime,
      drillingCount: arr[0].twoStepHoleCnt,
      drillingCountTime: arr[0].twoStepHoleTm,
      jointAmt: arr[0].sagyoAmtJoint,
      jointTime: arr[0].sagyoTmJoint,
      jointCnt: arr[0].jointCnt,
      jointTm: arr[0].jointTm,
    });
  }
  return editDataArr;
};
const totalSubSagyoSum = (sagyoArr, sagyoEdit) => {
  let subsagyoSumOrg = [];
  let subsagyoSumEdit = [];
  let sagyoAmtSum = 0;
  let sagyoTimeSumSec = 0;
  let sagyoAmtSumEdit = 0;
  let sagyoTimeSumSecEdit = 0;
  for (let i = 1; i <= sagyoArr?.length; i++) {
    let subSagyoArr = sagyoArr[i - 1].MFP;
    let subsagyoEdit = sagyoEdit[i - 1].MFP;
    let amtTotalOrg = 0;
    let timeTotalOrgSec = 0;
    let amtTotalEdit = 0;
    let timeTotalEditSec = 0;
    for (let j = 0; j < subSagyoArr?.length; j++) {
      amtTotalOrg += parseFloat(subSagyoArr[j].sagyoAmt);
      timeTotalOrgSec += setTimetoSec(subSagyoArr[j].sagyoTime);
      amtTotalEdit += parseFloat(subsagyoEdit[j].sagyoAmt);
      timeTotalEditSec += setTimetoSec(subsagyoEdit[j].sagyoTime);
    }
    subsagyoSumOrg.push({
      type: sagyoArr[i - 1].MFPType,
      amt: amtTotalOrg,
      timeSec: timeTotalOrgSec,
      time: secondsToHms(timeTotalOrgSec),
    });

    subsagyoSumEdit.push({
      type: sagyoEdit[i - 1].MFPType,
      amt: amtTotalEdit,
      timeSec: timeTotalEditSec,
      time: secondsToHms(timeTotalEditSec),
    });
    sagyoAmtSum += amtTotalOrg;
    sagyoAmtSumEdit += amtTotalEdit;
    sagyoTimeSumSec += timeTotalOrgSec;
    sagyoTimeSumSecEdit += timeTotalEditSec;
  }

  /*  for (let i = 0; i < subsagyoSumEdit?.length && i < subsagyoSumOrg?.length; i++) { */
  //let element = subsagyoSumEdit[0];
  if (subsagyoSumEdit !== undefined && subsagyoSumOrg !== undefined) {
    subsagyoSumEdit.splice(subsagyoSumEdit?.length, 0, {
      totalAmt: sagyoAmtSumEdit,
      totalTime: secondsToHms(sagyoTimeSumSecEdit),
      totalTimeSec: sagyoTimeSumSecEdit,
    });

    subsagyoSumOrg.splice(subsagyoSumEdit?.length, 0, {
      totalAmt: sagyoAmtSum,
      totalTime: secondsToHms(sagyoTimeSumSec),
      totalTimeSec: sagyoTimeSumSec,
    });
  }

  return [subsagyoSumOrg, subsagyoSumEdit];
};

const IQ3KouteiCalculate_BlankPunch = forwardRef((props, ref) => {
  const [isBlankingdetail, setIBlankingdetail] = useState(false);
  const [isSagyoDetail, setIsSagyoDetail] = useState(false);
  const [isDandoriDetail, setIsDandoriDetail] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [totalAmt, setTotalAmt] = useState('');
  const [totalTime, setTotalTime] = useState('');
  const [dandoriAmtS, setDandoriAmtS] = useState('');
  const [dandoriTimeS, setDandoriTimeS] = useState('');
  const [sagyoAmtS, setSagyoAmtS] = useState('');
  const [sagyoTimeS, setSagyoTimeS] = useState('');
  const [totalSum, setTotalSum] = useState('');
  const [totalTimeSum, setTotalTimeSum] = useState('');
  const [processArr, setProcessArr] = useState([]);
  const [editedData, setEditedData] = useState([]);
  const [oldEditedData, setOldEditedData] = useState([]);
  const [mfpFlg, setMfpFlg] = useState(false);
  const [updateEditedData, setUpdateEditedData] = useState([]);
  const [punchAmt, setLaserAmt] = useState(0);
  const [deviceMst, setDeviceMst] = useState([]);
  const [processMst, setProcessMst] = useState([]);
  const [patternInfo, setPatternInfo] = useState([]);

  const calculateTotals = (editDataArr) => {
    let sagyoAmtSum = 0;
    let dandoriAmtSum = 0;

    let sagyoTimeSec = 0;
    let dandoriTimeSec = 0;

    editDataArr?.forEach((element) => {
      sagyoAmtSum += element.sagyoAmt;
      dandoriAmtSum += element.dandoriAmt;
      sagyoTimeSec += element.sagyoTime;
      dandoriTimeSec += element.dandoriTime;
    });
    let totalSum =
      sagyoAmtSum +
      dandoriAmtSum +
      editDataArr[0].sagyoDetails.sagyoAmtTwoStepHole +
      editDataArr[0].sagyoDetails.sagyoAmtJoint;
    let sagyoTimeSum = sagyoTimeSec;
    let dandoriTimeSum = dandoriTimeSec;
    let totalTimeSum =
      sagyoTimeSec +
      dandoriTimeSec +
      editDataArr[0].sagyoDetails.sagyoTmTwoStepHole +
      editDataArr[0].sagyoDetails.sagyoTmJoint;

    let totals = [];
    let i = 0;
    totals.push({
      id: i++,
      sagyoAmtSum: sagyoAmtSum,
      dandoriAmtSum: dandoriAmtSum,
      sagyoTimeSec: sagyoTimeSec,
      dandoriTimeSec: dandoriTimeSec,
      totalSum: totalSum,
      sagyoTimeSum: sagyoTimeSum,
      dandoriTimeSum: dandoriTimeSum,
      totalTimeSum: totalTimeSum,
    });
    return totals;
  };

  useEffect(() => {
    let editData = [];
    let toBEdit = [];
    let orgData = [];
    let totals = [];
    let processMst = [];
    let deviceMst = []; //テスト用ID;

    let parameters = props?.selectedEstimateData?.estimateProducts?.calcParameters?.parameters;
    if (parameters?.process?.length > 0) {
      processMst = parameters?.process
        ? parameters?.process?.filter(
            (item) => item?.class === ServiceClass.SheetMetal && item.workType === WorkType.SmBlankPunch
          )[0]
        : [];
      setProcessMst(processMst ? processMst : []);
      if (parameters?.device?.length > 0) {
        deviceMst = parameters?.device?.filter((item) => processMst?.id == item?.processId);
      }
    }

    let patternInfo = props?.selectedIQ3DataDetail?.processSelect
      ? props?.selectedIQ3DataDetail?.processSelect?.details?.filter((item) => item.processId == processMst?.id)
      : [];
    setPatternInfo([]);
    if (patternInfo == undefined) return;
    if (patternInfo?.length > 0 && patternInfo[0]?.isUsedForCalc == false) return;

    deviceMst =
      deviceMst && patternInfo
        ? deviceMst?.filter(
            (item) => item.processId == patternInfo[0]?.processId && item.id == patternInfo[0]?.deviceId
          )[0]
        : []; //テスト用ID;
    setDeviceMst(deviceMst ? deviceMst : []);
    if (deviceMst == undefined) return;
    setPatternInfo(patternInfo);
    let punchAdditions = props?.selectedIQ3DataDetail?.additions?.filter((item) => item.processId == processMst?.id)[0];
    // プログラムの工程入力情報取得
    let punchProcessInput = props?.selectedIQ3DataDetail?.processInput?.filter(
      (item) => item.processId == processMst?.id
    );

    // 新規の場合
    if (punchAdditions?.dataItems?.length == 0 || punchAdditions == undefined) {
      let detailItems = [];
      let editDetailItems = [];
      let dandoriDetail = {
        dandoriAmtBasic: 0,
        dandoriTmBasic: 0,
        dandoriAmtMold: 0,
        dandoriTmMold: 0,
      };
      let sagyoDetail = {
        sagyoAmtPunch: 0,
        sagyoTmPunch: 0,
        punchTm: 0,
        punchCnt: 0,
        sagyoAmtAirDeli: 0,
        sagyoTmAirDeli: 0,
        speedAirDeli: 0,
        speedAirDeliLen: 0,
        sagyoAmtScribing: 0,
        sagyoTmScribing: 0,
        speedScribing: 0,
        speedScribingLen: 0,
        sagyoAmtTwoStepHole: 0,
        sagyoTmTwoStepHole: 0,
        twoStepHoleCnt: 0,
        twoStepHoleTm: 0,
        sagyoAmtJoint: 0,
        sagyoTmJoint: 0,
        jointCnt: 0,
        jointTm: 0,
      };
      detailItems.push({
        id: 1,
        processName: processMst?.name,
        dandoriAmt: 0,
        dandoriTime: 0,
        dandoriDetails: dandoriDetail,
        sagyoAmt: 0,
        sagyoTime: 0,
        sagyoDetails: sagyoDetail,
        formula: '',
      });

      editDetailItems = [];
      let punchAddition = {
        no: processMst?.id,
        processId: processMst?.id,
        dataType: DataType.Data,
        dataItems: detailItems,
        editItems: editDetailItems,
        totalDandori: {
          dataItemsAmt: 0,
          dataItemsTime: 0,
          editItemsAmt: 0,
          editItemsTime: 0,
        },
        totalSagyo: {
          dataItemsAmt: 0,
          dataItemsTime: 0,
          editItemsAmt: 0,
          editItemsTime: 0,
        },
        totalDataPrice: 0,
        totalDataTime: 0,
        totalEditPrice: 0,
        totalEditTime: 0,
      };
      punchAdditions = punchAddition;
    }
    if (punchAdditions?.dataItems) {
      //データ設定
      orgData = JSON.parse(JSON.stringify(punchAdditions?.dataItems));
      if (deviceMst != undefined) {
        let totalSagyo = 0;
        let totalSagyoTm = 0;
        // 数量
        let partCnt = props?.selectedEstimateData?.estimateProducts?.quantity;
        // 板厚
        let thickness = props?.selectedIQ3DataDetail?.thickness;
        // 重量
        let weight = props?.selectedIQ3DataDetail?.weight;
        // 面積
        let area = props?.selectedIQ3DataDetail?.totalSurfaceArea;
        //材質
        let materialTypeIq3Id = props?.selectedIQ3DataDetail?.materialTypeIq3Id;

        //材質区分
        let materialTypeId = parameters?.materialTypeIq3?.filter((item) => item.id == materialTypeIq3Id)?.[0]
          ?.materialTypeId;

        // サイズ係数
        let sizeCoef = getSizeCoeff(deviceMst, weight, area);

        // 基本段取金額/時間
        let basicSetupTimeSec = deviceMst?.details?.prepBasicTime;
        orgData[0].dandoriDetails.dandoriTmBasic = basicSetupTimeSec;
        orgData[0].dandoriDetails.dandoriAmtBasic = calDandoriAmt(basicSetupTimeSec, deviceMst);

        // 金型段取金額/時間
        let prepMoldQtyCoeffThMaster = deviceMst?.details?.prepMoldQtyCoeffThMaster?.filter(
          (item) => item.no == partCnt || item.no > partCnt
        )?.[0];
        let prepMoldQtyCoeffItems = deviceMst?.details?.prepMoldQtyCoeffItems?.filter(
          (item) => item.no == prepMoldQtyCoeffThMaster?.no
        )?.[0];
        let moldSetupTimeSec = prepMoldQtyCoeffItems?.value;
        orgData[0].dandoriDetails.dandoriTmMold = moldSetupTimeSec;
        orgData[0].dandoriDetails.dandoriAmtMold = calDandoriAmt(moldSetupTimeSec, deviceMst);

        /** 段取金額/時間 (基本段取時間	+	金型段取時間)*/
        orgData[0].dandoriTime = basicSetupTimeSec + moldSetupTimeSec;
        orgData[0].dandoriAmt = orgData[0].dandoriDetails.dandoriAmtBasic + orgData[0].dandoriDetails.dandoriAmtMold;

        // 2工程穴加工金額/時間
        let twoStepHoleTm = deviceMst?.details?.twoStepHoleworkTime;
        let twoStepHoleCnt = 1; //確認必要？
        orgData[0].sagyoDetails.twoStepHoleTm = twoStepHoleTm;
        orgData[0].sagyoDetails.twoStepHoleCnt = twoStepHoleCnt;
        // 2工程穴加工時間	*	個数
        let twoStepHoleTmsec = twoStepHoleTm * twoStepHoleCnt;
        orgData[0].sagyoDetails.sagyoTmTwoStepHole = twoStepHoleTmsec;
        orgData[0].sagyoDetails.sagyoAmtTwoStepHole = calSagyoAmtTwoStepHole(twoStepHoleTmsec, deviceMst);

        // ジョイントバラシ加工金額/時間
        let jointTimePerSec = getJointTm(deviceMst, thickness, materialTypeId);
        let jointQtySizeThMaster = deviceMst?.details?.jointQtySizeThMaster?.filter(
          (item) => item.value == area || item.value > area
        )?.[0];
        let jointCnt = deviceMst?.details?.jointQtyItems?.filter((item) => item.no == jointQtySizeThMaster?.no)?.[0]
          ?.value;
        orgData[0].sagyoDetails.jointTm = jointTimePerSec;
        orgData[0].sagyoDetails.jointCnt = jointCnt;
        // ジョイントバラシ加工時間	*	個数
        let jointTimeSec = jointTimePerSec * jointCnt;
        orgData[0].sagyoDetails.sagyoTmJoint = jointTimeSec;
        orgData[0].sagyoDetails.sagyoAmtJoint = calSagyoAmtJoint(jointTimeSec, deviceMst);

        // パンチ加工時間（1パンチ加工時間	*	パンチ数	*	サイズ係数）
        // パンチ数
        let punchCnt = 1; //確認必要
        orgData[0].sagyoDetails.punchCnt = punchCnt;
        let punchTm = getSagyoPunchTm(deviceMst, thickness, materialTypeId);
        orgData[0].sagyoDetails.punchTm = punchTm;
        let sagyoTmPunchPerSec = punchTm * punchCnt * sizeCoef;
        orgData[0].sagyoDetails.sagyoTmPunch = sagyoTmPunchPerSec;
        orgData[0].sagyoDetails.sagyoAmtPunch = calSagyoAmt(sagyoTmPunchPerSec, deviceMst);

        // 空送時間（空送距離	/	送り速度）
        let speedAirDeli = deviceMst?.details?.feedSpeed;
        orgData[0].sagyoDetails.speedAirDeli = speedAirDeli;
        let speedAirDeliLen = punchProcessInput?.[0]?.details?.[0]?.dimensionData?.airFeedDistance;
        orgData[0].sagyoDetails.speedAirDeliLen = speedAirDeliLen;
        let sagyoTmAirDeli = Number.isFinite(speedAirDeliLen / speedAirDeli) ? speedAirDeliLen / speedAirDeli : 0;
        orgData[0].sagyoDetails.sagyoTmAirDeli = sagyoTmAirDeli;
        orgData[0].sagyoDetails.sagyoAmtAirDeli = calSagyoAmt(sagyoTmAirDeli, deviceMst);

        // ケガキ加工時間（ケガキ線長	/	加工速度）
        let speedScribing = deviceMst?.details?.workSpeed;
        orgData[0].sagyoDetails.speedScribing = speedScribing;
        let speedScribingLen = 1;
        orgData[0].sagyoDetails.speedScribingLen = speedScribingLen;
        let sagyoTmScribing = Number.isFinite(speedScribingLen / speedScribing) ? speedScribingLen / speedScribing : 0;
        orgData[0].sagyoDetails.sagyoTmScribing = sagyoTmScribing;
        orgData[0].sagyoDetails.sagyoAmtScribing = calSagyoAmt(sagyoTmScribing, deviceMst);

        //作業合計金額・時間
        orgData[0].sagyoAmt =
          orgData[0].sagyoDetails.sagyoAmtPunch +
          orgData[0].sagyoDetails.sagyoAmtAirDeli +
          orgData[0].sagyoDetails.sagyoAmtScribing;
        orgData[0].sagyoTime =
          orgData[0].sagyoDetails.sagyoTmPunch +
          orgData[0].sagyoDetails.sagyoTmAirDeli +
          orgData[0].sagyoDetails.sagyoTmScribing;

        totalSagyo =
          orgData[0].sagyoAmt + orgData[0].sagyoDetails.sagyoAmtTwoStepHole + orgData[0].sagyoDetails.sagyoAmtJoint;
        totalSagyoTm =
          orgData[0].sagyoTime + orgData[0].sagyoDetails.sagyoTmTwoStepHole + orgData[0].sagyoDetails.sagyoTmJoint;

        /** 　合計時間　*/
        let totalTimeSum = orgData[0].dandoriTime + totalSagyoTm;
        setTotalTimeSum(totalTimeSum);
        punchAdditions.totalDataTime = totalTimeSum;
        punchAdditions.totalDandori.dataItemsTime = totalTimeSum;

        /** 　合計金額　*/
        let totalSum = orgData[0].dandoriAmt + totalSagyo;
        setTotalSum(totalSum);
        punchAdditions.totalDataPrice = totalSum;
        punchAdditions.totalDandori.dataItemsAmt = totalSum;

        setProcessArr(orgData);

        if (punchAdditions.dataType == DataType.Edit) {
          toBEdit = JSON.parse(JSON.stringify(punchAdditions?.editItems));
        } else {
          toBEdit = JSON.parse(JSON.stringify(orgData));
        }
      }
    }
    if (toBEdit?.length > 0) {
      editData = toBEdit;
      punchAdditions.editItems = editData;
      if (editData?.length > 0) {
        setEditedData(editData);
        totals = calculateTotals(editData);

        setTotalAmt(totals[0].totalSum);
        punchAdditions.totalEditPrice = totals[0].totalSum;
        punchAdditions.totalDandori.editItemsAmt = totals[0].totalSum;

        setTotalTime(totals[0].totalTimeSum);
        punchAdditions.totalEditTime = totals[0].totalTimeSum;
        punchAdditions.totalDandori.editItemsTime = totals[0].totalTimeSum;
      }
      setUpdateEditedData(punchAdditions);
    }

    if (punchAdditions?.dataType == DataType.Data) {
      setLaserAmt(punchAdditions.totalDataPrice);
    } else {
      setLaserAmt(punchAdditions.totalEditPrice);
    }
    props?.updateIQ3KouteiCal(punchAdditions, WorkType.SmBlankPunch);
  }, [props?.activeTabKey]);

  useEffect(() => {
    setEditMode(props?.editMode != undefined ? props?.editMode : false);
  }, [props?.editMode]);

  const getSizeCoeff = (deviceMst, weight, area) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    // 段取時間設定

    // 重量
    let prodSizeWeightThMaster = deviceMst?.details?.prodSizeWeightThMaster?.filter(
      (item) => item.value == weight || item.value > weight
    );
    // 面積
    let prodSizeAreaThMaster = deviceMst?.details?.prodSizeAreaThMaster?.filter(
      (item) => item.value == area || item.value > area
    );
    let childPartsCoeffs =
      prodSizeWeightThMaster?.length > 0 && prodSizeAreaThMaster?.length > 0
        ? deviceMst?.details?.prodSizeItems?.filter((item) => item.weightId == prodSizeWeightThMaster[0]?.no)
        : deviceMst?.details?.prodSizeItems[0];

    // サイズ係数
    let partsCoeff =
      childPartsCoeffs?.length > 0
        ? childPartsCoeffs?.[0]?.areaCoeffList?.filter((item) => item.no == prodSizeAreaThMaster?.[0]?.no)?.[0]?.value
        : childPartsCoeffs?.[0]?.areaCoeffList?.[0]?.value;

    return partsCoeff;
  };

  const getSpeedCoef = (deviceMst, thickness, processType) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    let childPartsCoeff = 0;
    let workSpeedCoeffThickThMaster = deviceMst?.details?.workSpeedCoeffThickThMaster?.filter(
      (item) => item.value == thickness || item.value > thickness
    )?.[0];

    if (processType == 0) {
      childPartsCoeff = deviceMst?.details?.workSpeedCoeffInternalWorkItems?.filter(
        (item) => item.no == workSpeedCoeffThickThMaster?.no
      )?.[0]?.value;
    } else {
      childPartsCoeff = deviceMst?.details?.workSpeedCoeffExternalWorkItems?.filter(
        (item) => item.no == workSpeedCoeffThickThMaster?.no
      )?.[0]?.value;
    }

    return childPartsCoeff;
  };

  const getSagyoPunchTm = (deviceMst, thickness, materialTypeIq3Id) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    // 加工速度
    let punchWorkTimeThickThMaster = deviceMst?.details?.punchWorkTimeThickThMaster?.filter(
      (item) => item.value == thickness || item.value > thickness
    )?.[0];

    let punchWorkTimeItems = Object.entries(deviceMst.details?.punchWorkTimeItems)?.filter(
      ([key, data]) => parseInt(key) === punchWorkTimeThickThMaster?.no
    )?.[0];

    // 加工速度
    let childPartsCoeff = 0;
    punchWorkTimeItems?.map((item, index) => {
      if (index > 0) {
        childPartsCoeff = item?.filter((item) => parseInt(item.no) == materialTypeIq3Id)?.[0]?.value;
      }
    });
    return childPartsCoeff;
  };

  const getJointTm = (deviceMst, thickness, materialTypeIq3Id) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    let jointSeparateThickThMaster = deviceMst?.details?.jointSeparateThickThMaster?.filter(
      (item) => item.value == thickness || item.value > thickness
    )?.[0];

    let jointSeparateItems = Object.entries(deviceMst.details?.jointSeparateItems)?.filter(
      ([key, data]) => parseInt(key) === jointSeparateThickThMaster?.no
    )?.[0];

    // ジョイント時間
    let childPartsCoeff = 0;
    jointSeparateItems?.map((item, index) => {
      if (index > 0) {
        childPartsCoeff = item?.filter((item) => parseInt(item.no) == materialTypeIq3Id)?.[0]?.value;
      }
    });
    return childPartsCoeff;
  };

  const calDandoriAmt = (dandoriTimeSec, deviceMst) => {
    //段階時間
    let mSetTimeMin = dandoriTimeSec / 60;
    //装置の段階時間チャージ
    let mSetHRate = deviceMst.details?.charges.prep;
    //段階時間＊時間チャージ
    let dandoriAmt = (mSetTimeMin * mSetHRate) / 60.0;
    return dandoriAmt;
  };

  const calSagyoAmt = (cSagyoTimeKonpoSec, deviceMst) => {
    // 作業金額
    // 作業時間
    let mWorkHTime = cSagyoTimeKonpoSec;
    let mWorkHTimeMin = mWorkHTime / 60;
    //装置の作業時間チャージ
    let mWorkHRate = deviceMst.details?.charges.work;
    //　加工時間＊時間チャージ
    let cSagyoAmtKonpo = (mWorkHTimeMin * mWorkHRate) / 60.0;
    return cSagyoAmtKonpo;
  };

  const calSagyoAmtJoint = (dandoriTimeSec, deviceMst) => {
    //段階時間
    let mSetTimeMin = dandoriTimeSec / 60;
    //装置の段階時間チャージ
    let mSetHRate = deviceMst.details?.charges.jointSeparate;
    //段階時間＊時間チャージ
    let dandoriAmt = (mSetTimeMin * mSetHRate) / 60.0;
    return dandoriAmt;
  };

  const calSagyoAmtTwoStepHole = (dandoriTimeSec, deviceMst) => {
    //段階時間
    let mSetTimeMin = dandoriTimeSec / 60;
    //装置の段階時間チャージ
    let mSetHRate = deviceMst.details?.charges.twoStepHolework;
    //段階時間＊時間チャージ
    let dandoriAmt = (mSetTimeMin * mSetHRate) / 60.0;
    return dandoriAmt;
  };

  const Blanking = () => {
    if (isBlankingdetail == true) {
      setIBlankingdetail(false);
    } else {
      setIBlankingdetail(true);
    }
  };
  const dandoriDetail = () => {
    if (isdandoriDetail == true) {
      setIsDandoriDetail(false);
    } else {
      setIsDandoriDetail(true);
    }
  };

  const sagyoDetail = () => {
    if (isSagyoDetail == true) {
      setIsSagyoDetail(false);
    } else {
      setIsSagyoDetail(true);
    }
  };

  const dandoriDetails = () => {
    if (isDandoriDetail == true) {
      setIsDandoriDetail(false);
    } else {
      setIsDandoriDetail(true);
    }
  };

  const setEditModeChange = (mode) => {
    setEditMode(mode);
  };
  useImperativeHandle(ref, () => ({
    setEditModeChange: setEditModeChange,
  }));

  const settingNewDandoriAmtInArr = (value, indexi, index, objId, timeType, timeAct, arrType) => {
    let newState = JSON.parse(JSON.stringify(editedData));
    if (objId !== 'dandoriAmt' && objId !== 'dandoriTime' && objId !== 'handelingAmt' && objId !== 'handelingTime') {
      setOldEditedData(JSON.parse(JSON.stringify(editedData)));
    }
    let ij = indexi !== '' ? String(indexi) + String(index) : index;
    let dandori = [];
    let sagyo = [];
    let totals = [];
    let deviceMst = [];

    let oldValue;

    if (value === undefined) {
      value = 0;
    }

    if (arrType !== 'dandori' && arrType !== 'sagyo') {
      newState[0][objId] = value;
    }

    if (arrType === 'dandori' /* 'dandoriAmt' || objId == 'dandoriTime' */) {
      oldValue = JSON.parse(JSON.stringify(newState[0].dandori));
      if (value === undefined) {
        value = 0;
      }
      let dandori = newState[0].dandori;
      dandori[index][objId] = value;

      if (objId === 'dandoriTime') {
        if (document.getElementById(index + 'dandoriAmtCu').style.display !== undefined) {
          let showHideCu = document.getElementById(index + 'dandoriAmtCu').style.display;
          if (showHideCu === 'block') {
            checkInputWithColor(false, '', index + 'dandoriAmt', dandori[index].dandoriAmt, 'red');
          } else {
            if (timeAct === 'input') {
              let timeType = setTimetoSec(value) - setTimetoSec(oldValue[index].dandoriTime);
              dandori[index].dandoriAmt = changeAmtwithTime(dandori[index].dandoriAmt, timeType, timeAct, value, objId);
              checkInput(index + objId, value);
            } else {
              dandori[index].dandoriAmt = changeAmtwithTime(dandori[index].dandoriAmt, timeType, timeAct, value, objId);
            }
            checkInputWithColor(false, '', index + 'dandoriAmt', dandori[index].dandoriAmt, 'green');
          }
        }
        newState[0].dandori = dandori;
      }
    } else if (arrType === 'sagyo' /* objId == 'sagyoAmt' || objId == 'sagyoTime' */) {
      oldValue = JSON.parse(JSON.stringify(newState[0].sagyo));
      let sagyo = newState[0].sagyo;

      if (mfpFlg && indexi !== '') {
        sagyo = sagyo[indexi].MFP;
        oldValue = oldValue[indexi].MFP;
      }
      sagyo[index][objId] = value;
      if (objId === 'sagyoTime') {
        if (document.getElementById('sagyoAmtCu').style.display !== undefined) {
          let showHideCu = document.getElementById('sagyoAmtCu').style.display;

          if (showHideCu === 'block') {
            //checkInputWithColor(false,'',index + 'sagyoAmt', sagyo[index].sagyoAmt, 'red');
          } else {
            if (timeAct === 'input') {
              let timeType = setTimetoSec(value) - setTimetoSec(oldValue[index].sagyoTime);
              sagyo[index].sagyoAmt = changeAmtwithTime(sagyo[index].sagyoAmt, timeType, timeAct, value, objId);
              checkInput(index + objId, value);
            } else {
              sagyo[index].sagyoAmt = changeAmtwithTime(sagyo[index].sagyoAmt, timeType, timeAct, value, objId);
            }
            //checkInputWithColor(false,'',index + 'sagyoAmt', sagyo[index].sagyoAmt, 'green');
          }
        }
        mfpFlg ? (newState[0].sagyo[indexi].MFP = sagyo) : (newState[0].sagyo = sagyo);
      } else if (objId === 'piercingcount' || objId === 'piercingTime') {
        // サイズ係数
        let sizeCoef = 1.0;
        let piercingTime = objId === 'piercingTime' ? setTimetoSec(value) : setTimetoSec(sagyo[index].piercingTime);
        let piercingcount = objId === 'piercingcount' ? value : sagyo[index].piercingcount;
        // 通常パンチ時間（1パンチ加工時間	*	パンチ数	*	サイズ係数）
        let sagyoTimePerSec = piercingTime * piercingcount * sizeCoef;
        sagyo[index].sagyoTime = secondsToHms(sagyoTimePerSec);
        if (document.getElementById('sagyoAmtCu').style.display !== undefined) {
          let showHideCu = document.getElementById('sagyoAmtCu').style.display;
          if (showHideCu === 'none') {
            sagyo[index].sagyoAmt = calSagyoAmt(setTimetoSec(sagyo[index].sagyoTime), deviceMst);
          }
        }
      } else if (objId === 'length' || objId === 'speed') {
        let length = objId === 'length' ? value : sagyo[index]?.length;
        let speed = objId === 'speed' ? value : sagyo[index].speed;
        // ケガキ加工時間（ケガキ線長	/	加工速度）
        let sagyoTimePerSec = value === '' ? 0 : length / speed;
        sagyo[index].sagyoTime = secondsToHms(sagyoTimePerSec);
        /*  sagyo[index].sagyoAmt = calSagyoAmt(setTimetoSec(sagyo[index].sagyoTime), deviceMst); */
        if (document.getElementById('sagyoAmtCu').style.display !== undefined) {
          let showHideCu = document.getElementById('sagyoAmtCu').style.display;
          if (showHideCu === 'none') {
            sagyo[index].sagyoAmt = calSagyoAmt(setTimetoSec(sagyo[index].sagyoTime), deviceMst);
          }
        }
      }
    } else if (arrType === 'drillingTime') {
      if (document.getElementById('drillingAmtCu').style.display !== undefined) {
        let showHideCu = document.getElementById('drillingAmtCu').style.display;
        if (showHideCu === 'block') {
          checkInputWithColor(false, '', 'drillingAmt', newState[0].drillingAmt, 'red');
        } else {
          if (timeAct === 'input') {
            let timeType = setTimetoSec(value) - setTimetoSec(oldValue[index].drillingTime);
            newState[0].sagyoAmtTwoStepHole = changeAmtwithTime(
              newState[0].drillingAmt,
              timeType,
              timeAct,
              value,
              objId
            );
            checkInput(index + objId, value);
          } else {
            newState[0].sagyoAmtTwoStepHole = changeAmtwithTime(
              newState[0].drillingAmt,
              timeType,
              timeAct,
              value,
              objId
            );
          }
          checkInputWithColor(false, '', 'drillingAmt', newState[0].drillingAmt, 'green');
        }
      }
    } else if (arrType === 'drillingCount' || objId === 'drillingCountTime') {
      // 2工程穴加工金額/時間
      newState[0].twoStepHoleTm = arrType === 'drillingCountTime' ? value : newState[0].twoStepHoleTm;
      newState[0].twoStepHoleCnt = arrType === 'drillingCount' ? value : newState[0].twoStepHoleCnt;

      // 2工程穴加工時間	*	個数
      let drillingTimeSec = setTimetoSec(newState[0].twoStepHoleTm) * newState[0].twoStepHoleCnt;
      newState[0].sagyoTmTwoStepHole = secondsToHms(drillingTimeSec);
      if (document.getElementById('drillingAmtCu').style.display !== undefined) {
        let showHideCu = document.getElementById('drillingAmtCu').style.display;
        if (showHideCu === 'none') {
          newState[0].sagyoAmtTwoStepHole = calDandoriAmt(drillingTimeSec, deviceMst);
        }
      }
    } else if (arrType === 'jointTime') {
      if (document.getElementById('jointAmtCu').style.display !== undefined) {
        let showHideCu = document.getElementById('jointAmtCu').style.display;
        if (showHideCu === 'block') {
          checkInputWithColor(false, '', 'jointAmt', newState[0].sagyoAmtJoint, 'red');
        } else {
          if (timeAct === 'input') {
            let timeType = setTimetoSec(value) - setTimetoSec(oldValue[index].sagyoTmJoint);
            newState[0].sagyoAmtJoint = changeAmtwithTime(newState[0].sagyoAmtJoint, timeType, timeAct, value, objId);
            checkInput(index + objId, value);
          } else {
            newState[0].sagyoAmtJoint = changeAmtwithTime(newState[0].sagyoAmtJoint, timeType, timeAct, value, objId);
          }
          checkInputWithColor(false, '', 'jointAmt', newState[0].sagyoAmtJoint, 'green');
        }
      }
    } else if (arrType === 'jointCnt' || arrType === 'jointTm') {
      // ジョイント加工金額/時間
      newState[0].jointTm = arrType === 'jointTm' ? value : newState[0].jointTm;
      newState[0].jointCnt = arrType === 'jointCnt' ? value : newState[0].jointCnt;

      // ジョイント加工時間	*	個数
      let jointTimeSec = setTimetoSec(newState[0].jointTm) * newState[0].jointCnt;
      newState[0].sagyoTmJoint = secondsToHms(jointTimeSec);
      if (document.getElementById('jointAmtCu').style.display !== undefined) {
        let showHideCu = document.getElementById('jointAmtCu').style.display;
        if (showHideCu === 'none') {
          newState[0].sagyoAmtJoint = calDandoriAmt(jointTimeSec, deviceMst);
        }
      }
    }
    totals = calculateTotals(newState, processArr);
    checkInputWithColor(false, '', 'totalAmtBlank', totals[0].totalSum, 'green');
    checkInputWithColor(false, '', 'totalTimeBlank', totals[0].totalTimeSum, 'green');
    checkInputWithColor(false, '', 'totalDandoriAmtBlank', totals[0].dandoriAmt, 'green');
    checkInputWithColor(false, '', 'totalDandoriTimeBlank', totals[0].dandoriTime, 'green');
    checkInputWithColor(false, '', 'totalSagyoAmtBlank', totals[0].sagyoAmt, 'green');
    checkInputWithColor(false, '', 'totalSagyoTimeBlank', totals[0].sagyoTime, 'green');
    setTotalAmt(totals[0].totalSum);
    setTotalTime(totals[0].totalTimeSum);
    setDandoriAmtS(totals[0].dandoriAmt);
    setDandoriTimeS(totals[0].dandoriTime);
    setSagyoAmtS(totals[0].sagyoAmt);
    setSagyoTimeS(totals[0].sagyoTime);
    setEditedData(newState);
  };

  const checkStr = (val) => {
    let intAmt;
    if (val === '') {
      intAmt = 0;
    } else if (typeof val === 'string') {
      intAmt = parseFloat(val.charAt(0) == '¥' ? val.replace('¥', '') : val);
    } else intAmt = val;
    return intAmt;
  };

  const changeAmtwithTime = (amt, typeTime, action, timeVal, processType) => {
    let editedAmt;
    let totalSec = Number(setTimetoSec(timeVal));
    let intAmt = checkStr(amt);
    let deviceMst = [];

    if (processType === 'dandoriTime' || processType === 'drillingTime' || processType === 'jointTime') {
      // 段取金額
      //　段取時間＊時間チャージ
      editedAmt = calDandoriAmt(totalSec, deviceMst);
    } else if (processType === 'sagyoTime') {
      // 作業金額
      //　加工時間＊時間チャージ
      editedAmt = calSagyoAmt(totalSec, deviceMst);
    }
    return editedAmt;
  };

  const subpartDetail = (i) => {
    let showHide = document.getElementById('punchProcessIQ3' + i).style.display;
    if (showHide == 'block') {
      document.getElementById('punchProcessIQ3' + i).style.display = 'none';
      document.getElementById('arrowDownLaserIQ3' + i).style.display = 'block';
      document.getElementById('arrowUpLaserIQ3' + i).style.display = 'none';
    } else {
      document.getElementById('punchProcessIQ3' + i).style.display = 'block';
      document.getElementById('arrowDownLaserIQ3' + i).style.display = 'none';
      document.getElementById('arrowUpLaserIQ3' + i).style.display = 'block';
    }
  };

  const handleChangeCheckData = (e, i) => {
    /* setSelectedDataFlg(e);
    props?.sendSelectedDataFlg(e); */
    const data = { ...updateEditedData };
    data.dataType = e;
    setUpdateEditedData(data);
    setTotalAmt(e == DataType.Data ? data.totalDataPrice : data.totalEditPrice);
    props?.updateIQ3KouteiCal(data, WorkType.SmBlankPunch);
  };

  return (
    <div className="kouteiCalculateDiv">
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row " onClick={Blanking}>
        <Col span={4} className="oyabuhin-col">
          <div style={{ display: 'flex' }}>
            {isBlankingdetail ? (
              <CaretUpOutlined style={{ fontSize: 17, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 17, marginTop: 2 }} />
            )}
            <label style={{ marginLeft: 5 }}>{processMst?.name}</label>
          </div>
        </Col>
        <Col span={6} className="oyabuhin-col">
          <div>{punchAmt ? JPYs.format(Math.floor(punchAmt)) : amtEmptyStr}</div>
        </Col>
        <Col span={13} style={{ border: 'none' }} className="oyabuhin-col">
          <div>
            <label style={{ marginLeft: 5 }}>{deviceMst?.name}</label>
          </div>
        </Col>
      </Row>
      <div style={{ display: isBlankingdetail ? 'block' : 'none' }}>
        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col">
            工程詳細
          </Col>
          <Col span={6} className="oyabuhin-detail-col">
            <Checkbox
              key={kouteiCalculateSelectedArr[0].key}
              value={kouteiCalculateSelectedArr[0].value}
              checked={kouteiCalculateSelectedArr[0].key == updateEditedData.dataType}
              disabled={!props?.editMode}
              style={{ marginRight: 6 }}
              onChange={(e) => props?.editMode && handleChangeCheckData(kouteiCalculateSelectedArr[0].key)}
            ></Checkbox>
            <label>{kouteiCalculateSelectedArr[0].value}</label>
          </Col>
          <Col span={8} className="oyabuhin-detail-col">
            <Checkbox
              key={kouteiCalculateSelectedArr[1].key}
              value={kouteiCalculateSelectedArr[1].value}
              disabled={!props?.editMode}
              onChange={(e) => props?.editMode && handleChangeCheckData(kouteiCalculateSelectedArr[1].key)}
              checked={kouteiCalculateSelectedArr[1].key == updateEditedData.dataType}
              style={{ marginRight: 6 }}
            ></Checkbox>
            <label>{kouteiCalculateSelectedArr[1].value}</label>
          </Col>

          <Col span={1} className="oyabuhin-detail-col">
            単位
          </Col>
          <Col span={5} className="formula-column">
            計算式
          </Col>
        </Row>
        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col">
            {processMst?.name}
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalAmt">{totalSum ? JPYs.format(Math.floor(totalSum)) : amtEmptyStr}</label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalTime">{totalTimeSum ? secondsToHms(totalTimeSum) : timeHmsStr}</label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalAmtIn" className="resetColor">
              {totalAmt ? JPYs.format(Math.floor(totalAmt)) : amtEmptyStr}
            </label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalTimeIn" className="resetColor">
              {totalTime ? secondsToHms(totalTime) : timeHmsStr}
            </label>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={5} className="formula-column"></Col>
        </Row>
        {/** 段取金額/時間 */}
        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row" onClick={dandoriDetails}>
          <Col span={4} className="oyabuhin-detail-col">
            <div style={{ display: 'flex' }}>
              {isSagyoDetail ? (
                <CaretUpOutlined style={{ fontSize: 17, marginTop: 2 }} />
              ) : (
                <CaretDownOutlined style={{ fontSize: 17, marginTop: 2 }} />
              )}
              <label> 段取金額/時間</label>
            </div>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalDandoriAmtBlank">
              {processArr[0]?.dandoriAmt ? JPYs.format(Math.floor(processArr[0]?.dandoriAmt)) : amtEmptyStr}
            </label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalDandoriTimeBlank">
              {processArr[0]?.dandoriTime ? secondsToHms(processArr[0]?.dandoriTime) : timeHmsStr}
            </label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalDandoriAmtBlank">
              {editedData[0]?.dandoriAmt ? JPYs.format(Math.floor(editedData[0]?.dandoriAmt)) : amtEmptyStr}
            </label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalDandoriTimeBlank">
              {editedData[0]?.dandoriTime ? secondsToHms(editedData[0]?.dandoriTime) : timeHmsStr}
            </label>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={5} className="formula-column"></Col>
        </Row>
        <div style={{ display: isDandoriDetail ? 'block' : 'none' }}>
          <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
            <Col span={4} className="oyabuhin-detail-col">
              <div style={{ display: 'flex', marginLeft: '20px' }}>
                <label>基本段取金額/時間</label>
              </div>
            </Col>
            <Col span={3} className="oyabuhin-detail-col">
              <label id="totalDandoriAmtBlank">
                {processArr[0]?.dandoriDetails?.dandoriAmtBasic
                  ? JPYs.format(Math.floor(processArr[0]?.dandoriDetails?.dandoriAmtBasic))
                  : amtEmptyStr}
              </label>
            </Col>
            <Col span={3} className="oyabuhin-detail-col">
              <label id="totalDandoriTimeBlank">
                {processArr[0]?.dandoriDetails?.dandoriTmBasic
                  ? secondsToHms(processArr[0]?.dandoriDetails?.dandoriTmBasic)
                  : timeHmsStr}
              </label>
            </Col>
            <Col span={4} className="input-col">
              <CurrencyInput
                id={'dandoriAmtIn'}
                value={
                  editedData[0]?.dandoriDetails?.dandoriAmtBasic
                    ? Math.floor(editedData[0]?.dandoriDetails?.dandoriAmtBasic)
                    : 0
                }
                defaultValue={0}
                onValueChange={(e) => {
                  if (editedData[0]?.dandoriDetails?.dandoriAmtBasic != e) {
                    settingNewDandoriAmtInArr(e, '', j, 'dandoriAmt', '', '', 'dandori');
                    checkInputWithColor(false, '', 'dandoriAmt', e, 'amt');
                  }
                }}
                /* onKeyPress={(e) => {
                            if (isNumber(e.key)) {
                              checkInputWithColor(false,'', 'dandoriAmt', e, 'amt');
                            }
                          }} */
                style={{
                  border: 'none',
                  color:
                    processArr[0]?.dandoriDetails?.dandoriAmtBasic === editedData[0]?.dandoriDetails?.dandoriAmtBasic
                      ? 'black'
                      : checkShowHideIcon('dandoriAmtCu') === 'none'
                      ? '#4cc12f'
                      : 'red',
                }}
                className={editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'}
                prefix="¥"
                decimalsLimit={100}
              />
              <div id={'dandoriAmtCu'} style={{ display: 'none' }} className="currencyReset">
                <div className="currencyIcon">
                  <BsUsbC style={{ fontSize: '25' }} />
                  <BsX style={{ margin: '-8px 0 0 16px' }} />
                  <span class="tooltiptext">金額に変更がありましたので、以降時間と連携して自動計算されない。</span>
                </div>
              </div>
            </Col>
            <Col span={4} className="input-col">
              <Row style={{ width: '100%' }}>
                <Col className="timer-input-col">
                  <Input
                    value={
                      editedData[0]?.dandoriDetails?.dandoriTmBasic
                        ? secondsToHms(editedData[0]?.dandoriDetails?.dandoriTmBasic)
                        : timeHmsStr
                    }
                    id={'dandoriTimeIn'}
                    style={{
                      width: '100%',
                      color:
                        editedData[0]?.dandoriDetails?.dandoriTmBasic === processArr[0]?.dandoriDetails?.dandoriTmBasic
                          ? 'black'
                          : 'red',
                    }}
                    className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                    onChange={(e) => {
                      //disableTimer('test');
                      settingNewDandoriAmtInArr(e.target.value, '', j, 'dandoriTime', 'input', 'input', 'dandori');
                      // checkInput( 'dandoriTime', e.target.value);
                    }}
                  ></Input>
                </Col>
                <Col className="timer-button-col">
                  {editMode ? (
                    <>
                      <CaretUpOutlined
                        style={{ fontSize: 17 + 'px' }}
                        onClick={() => {
                          let editedTime = handelIncreaseTime(
                            editedData[0]?.dandoriDetails?.dandoriTmBasic,
                            '10min',
                            'dandoriTime'
                          );
                          settingNewDandoriAmtInArr(editedTime, '', j, 'dandoriTime', '10min', 'inc', 'dandori');
                          /*  settingNewDandoriAmtInArr(e.target.value, j, 'dandoriTime', 'input', 'input'); */
                        }}
                      />
                      <CaretDownOutlined
                        style={{ fontSize: 17 + 'px', color: '#000' }}
                        onClick={() => {
                          let editedTime = handelDecreaseTime(
                            editedData[0]?.dandoriDetails?.dandoriTmBasic,
                            '10min',
                            'dandoriTime'
                          );
                          settingNewDandoriAmtInArr(editedTime, '', j, 'dandoriTime', '10min', 'dec', 'dandori');
                        }}
                      />
                      <CaretUpOutlined
                        style={{ fontSize: 17 + 'px', color: '#000' }}
                        onClick={() => {
                          let editedTime = handelIncreaseTime(
                            editedData[0]?.dandoriDetails?.dandoriTmBasic,
                            'min',
                            'dandoriTime'
                          );
                          settingNewDandoriAmtInArr(editedTime, '', j, 'dandoriTime', 'min', 'inc', 'dandori');
                        }}
                      />
                      <CaretDownOutlined
                        style={{ fontSize: 17 + 'px', color: '#000' }}
                        onClick={() => {
                          let editedTime = handelDecreaseTime(
                            editedData[0]?.dandoriDetails?.dandoriTmBasic,
                            'min',
                            'dandoriTime'
                          );
                          settingNewDandoriAmtInArr(editedTime, '', j, 'dandoriTime', 'min', 'dec', 'dandori');
                        }}
                      />
                      <CaretUpOutlined
                        style={{ fontSize: 17 + 'px', color: '#000' }}
                        onClick={() => {
                          let editedTime = handelIncreaseTime(
                            editedData[0]?.dandoriDetails?.dandoriTmBasic,
                            'sec',
                            'dandoriTime'
                          );
                          settingNewDandoriAmtInArr(editedTime, '', j, 'dandoriTime', 'sec', 'inc', 'dandori');
                        }}
                      />
                      <CaretDownOutlined
                        style={{ fontSize: 17 + 'px', color: '#000' }}
                        onClick={() => {
                          let editedTime = handelDecreaseTime(
                            editedData[0]?.dandoriDetails?.dandoriTmBasic,
                            'sec',
                            'dandoriTime'
                          );
                          settingNewDandoriAmtInArr(editedTime, '', j, 'dandoriTime', 'sec', 'dec', 'dandori');
                        }}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
            </Col>
            <Col span={1} className="oyabuhin-detail-col"></Col>
            <Col span={5} className="formula-column"></Col>
          </Row>
          <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
            <Col span={4} className="oyabuhin-detail-col">
              <div style={{ display: 'flex', marginLeft: '20px' }}>
                <label>金型段取金額/時間</label>
              </div>
            </Col>
            <Col span={3} className="oyabuhin-detail-col">
              <label id="totalDandoriAmtBlank">
                {processArr[0]?.dandoriDetails?.dandoriAmtMold
                  ? JPYs.format(Math.floor(processArr[0]?.dandoriDetails?.dandoriAmtMold))
                  : amtEmptyStr}
              </label>
            </Col>
            <Col span={3} className="oyabuhin-detail-col">
              <label id="totalDandoriTimeBlank">
                {processArr[0]?.dandoriDetails?.dandoriTmMold
                  ? secondsToHms(processArr[0]?.dandoriDetails?.dandoriTmMold)
                  : timeHmsStr}
              </label>
            </Col>
            <Col span={4} className="input-col">
              <CurrencyInput
                id={'dandoriAmtIn'}
                value={
                  editedData[0]?.dandoriDetails?.dandoriAmtMold
                    ? Math.floor(editedData[0]?.dandoriDetails?.dandoriAmtMold)
                    : 0
                }
                defaultValue={0}
                onValueChange={(e) => {
                  if (editedData[0]?.dandoriDetails?.dandoriAmtMold != e) {
                    settingNewDandoriAmtInArr(e, '', j, 'dandoriAmt', '', '', 'dandori');
                    checkInputWithColor(false, '', 'dandoriAmt', e, 'amt');
                  }
                }}
                /* onKeyPress={(e) => {
                            if (isNumber(e.key)) {
                              checkInputWithColor(false,'', 'dandoriAmt', e, 'amt');
                            }
                          }} */
                style={{
                  border: 'none',
                  color:
                    processArr[0]?.dandoriDetails?.dandoriAmtMold === editedData[0]?.dandoriDetails?.dandoriAmtMold
                      ? 'black'
                      : checkShowHideIcon('dandoriAmtCu') === 'none'
                      ? '#4cc12f'
                      : 'red',
                }}
                className={editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'}
                prefix="¥"
                decimalsLimit={100}
              />
              <div id={'dandoriAmtCu'} style={{ display: 'none' }} className="currencyReset">
                <div className="currencyIcon">
                  <BsUsbC style={{ fontSize: '25' }} />
                  <BsX style={{ margin: '-8px 0 0 16px' }} />
                  <span class="tooltiptext">金額に変更がありましたので、以降時間と連携して自動計算されない。</span>
                </div>
              </div>
            </Col>
            <Col span={4} className="input-col">
              <Row style={{ width: '100%' }}>
                <Col className="timer-input-col">
                  <Input
                    value={
                      editedData[0]?.dandoriDetails?.dandoriTmMold
                        ? secondsToHms(editedData[0]?.dandoriDetails?.dandoriTmMold)
                        : timeHmsStr
                    }
                    id={'dandoriTimeIn'}
                    style={{
                      width: '100%',
                      color:
                        editedData[0]?.dandoriDetails?.dandoriTmMold === processArr[0]?.dandoriDetails?.dandoriTmMold
                          ? 'black'
                          : 'red',
                    }}
                    className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                    onChange={(e) => {
                      //disableTimer('test');
                      settingNewDandoriAmtInArr(e.target.value, '', j, 'dandoriTime', 'input', 'input', 'dandori');
                      // checkInput( 'dandoriTime', e.target.value);
                    }}
                  ></Input>
                </Col>
                <Col className="timer-button-col">
                  {editMode ? (
                    <>
                      <CaretUpOutlined
                        style={{ fontSize: 17 + 'px' }}
                        onClick={() => {
                          let editedTime = handelIncreaseTime(
                            editedData[0]?.dandoriDetails?.dandoriTmMold,
                            '10min',
                            'dandoriTime'
                          );
                          settingNewDandoriAmtInArr(editedTime, '', j, 'dandoriTime', '10min', 'inc', 'dandori');
                          /*  settingNewDandoriAmtInArr(e.target.value, j, 'dandoriTime', 'input', 'input'); */
                        }}
                      />
                      <CaretDownOutlined
                        style={{ fontSize: 17 + 'px', color: '#000' }}
                        onClick={() => {
                          let editedTime = handelDecreaseTime(
                            editedData[0]?.dandoriDetails?.dandoriTmMold,
                            '10min',
                            'dandoriTime'
                          );
                          settingNewDandoriAmtInArr(editedTime, '', j, 'dandoriTime', '10min', 'dec', 'dandori');
                        }}
                      />
                      <CaretUpOutlined
                        style={{ fontSize: 17 + 'px', color: '#000' }}
                        onClick={() => {
                          let editedTime = handelIncreaseTime(
                            editedData[0]?.dandoriDetails?.dandoriTmMold,
                            'min',
                            'dandoriTime'
                          );
                          settingNewDandoriAmtInArr(editedTime, '', j, 'dandoriTime', 'min', 'inc', 'dandori');
                        }}
                      />
                      <CaretDownOutlined
                        style={{ fontSize: 17 + 'px', color: '#000' }}
                        onClick={() => {
                          let editedTime = handelDecreaseTime(
                            editedData[0]?.dandoriDetails?.dandoriTmMold,
                            'min',
                            'dandoriTime'
                          );
                          settingNewDandoriAmtInArr(editedTime, '', j, 'dandoriTime', 'min', 'dec', 'dandori');
                        }}
                      />
                      <CaretUpOutlined
                        style={{ fontSize: 17 + 'px', color: '#000' }}
                        onClick={() => {
                          let editedTime = handelIncreaseTime(
                            editedData[0]?.dandoriDetails?.dandoriTmMold,
                            'sec',
                            'dandoriTime'
                          );
                          settingNewDandoriAmtInArr(editedTime, '', j, 'dandoriTime', 'sec', 'inc', 'dandori');
                        }}
                      />
                      <CaretDownOutlined
                        style={{ fontSize: 17 + 'px', color: '#000' }}
                        onClick={() => {
                          let editedTime = handelDecreaseTime(
                            editedData[0]?.dandoriDetails?.dandoriTmMold,
                            'sec',
                            'dandoriTime'
                          );
                          settingNewDandoriAmtInArr(editedTime, '', j, 'dandoriTime', 'sec', 'dec', 'dandori');
                        }}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
            </Col>
            <Col span={1} className="oyabuhin-detail-col"></Col>
            <Col span={5} className="formula-column"></Col>
          </Row>
        </div>
        {/** 加工金額/時間 */}
        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row" onClick={sagyoDetail}>
          <Col span={4} className="oyabuhin-detail-col">
            <div style={{ display: 'flex' }}>
              {isSagyoDetail ? (
                <CaretUpOutlined style={{ fontSize: 17, marginTop: 2 }} />
              ) : (
                <CaretDownOutlined style={{ fontSize: 17, marginTop: 2 }} />
              )}
              <label> 加工金額/時間</label>
            </div>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalSagyoAmtBlank">
              {processArr[0]?.sagyoAmt ? JPYs.format(Math.floor(processArr[0]?.sagyoAmt)) : amtEmptyStr}
            </label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalSagyoTimeBlank">
              {processArr[0]?.sagyoTime ? secondsToHms(processArr[0]?.sagyoTime) : timeHmsStr}
            </label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalSagyoAmtBlankIn" className="resetColor">
              {editedData[0]?.sagyoAmt ? JPYs.format(Math.floor(editedData[0]?.sagyoAmt)) : amtEmptyStr}
            </label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalSagyoTimeBlankIn" className="resetColor">
              {editedData[0]?.sagyoTime ? secondsToHms(editedData[0]?.sagyoTime) : timeHmsStr}
            </label>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={5} className="formula-column"></Col>
        </Row>
        {/** パンチ加工金額/時間 */}
        <div style={{ display: isSagyoDetail ? 'block' : 'none' }}>
          {/** パンチ */}
          <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
            <Col span={4} className="oyabuhin-detail-col">
              <div style={{ display: 'flex', marginLeft: '20px' }}>
                <CaretUpOutlined
                  id={'arrowUpLaserIQ32'}
                  style={{ fontSize: 17, marginTop: 2 }}
                  onClick={(e) => subpartDetail(2)}
                />
                <CaretDownOutlined
                  id={'arrowDownLaserIQ32'}
                  style={{ fontSize: 17, marginTop: 2, display: 'none' }}
                  onClick={(e) => subpartDetail(2)}
                />
                <span style={{ marginLeft: '5px' }}>パンチ加工金額/時間</span>
              </div>
            </Col>
            <Col span={3} className="oyabuhin-detail-col">
              {/* ¥ */}
              <label id={'punchSagyoAmt'}>
                {processArr[0]?.sagyoDetails?.sagyoAmtPunch
                  ? JPYs.format(Math.floor(processArr[0]?.sagyoDetails?.sagyoAmtPunch))
                  : amtEmptyStr}
              </label>
            </Col>
            <Col span={3} className="oyabuhin-detail-col">
              <label id={'punchSagyoTime'}>
                {processArr[0]?.sagyoDetails?.sagyoTmPunch
                  ? secondsToHms(processArr[0]?.sagyoDetails?.sagyoTmPunch)
                  : timeHmsStr}
              </label>
            </Col>
            <Col span={4} className="input-col">
              <CurrencyInput
                id={'punchSagyoAmtIn'}
                value={
                  editedData[0]?.sagyoDetails?.sagyoAmtPunch
                    ? Math.floor(editedData[0]?.sagyoDetails?.sagyoAmtPunch)
                    : 0
                }
                defaultValue={0}
                onValueChange={(e) => {
                  if (editedData[i]?.sagyoAmt != e) {
                    settingNewDandoriAmtInArr(e, i, 'sagyoAmt', '', '');
                    checkInputWithColor(false, '', 'LaserSagyoAmtCu', e, 'amt');
                  }
                }}
                style={{ border: 'none' }}
                className={editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'}
                prefix="¥"
                decimalsLimit={100}
              />

              <div id={'LaserSagyoAmtCu'} style={{ display: 'none' }} className="currencyReset">
                <div className="currencyIcon">
                  <BsUsbC style={{ fontSize: '25' }} />
                  <BsX style={{ margin: '-8px 0 0 16px' }} />
                  <span class="tooltiptext">金額に変更がありましたので、以降時間と連携して自動計算されない。</span>
                </div>
              </div>
            </Col>
            <Col span={4} className="input-col">
              <Row style={{ width: '100%' }}>
                <Col className="timer-input-col">
                  <Input
                    value={
                      editedData[0]?.sagyoDetails?.sagyoTmPunch
                        ? secondsToHms(editedData[0]?.sagyoDetails?.sagyoTmPunch)
                        : timeHmsStr
                    }
                    id={'punchSagyoTimeIn'}
                    style={{ width: '100%' }}
                    className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                    onChange={(e) => {
                      settingNewDandoriAmtInArr(e.target.value, i, 'sagyoTime', 'input', 'input');
                    }}
                  ></Input>
                </Col>
                <Col className="timer-button-col">
                  {editMode ? (
                    <>
                      <CaretUpOutlined
                        style={{ fontSize: 17 + 'px' }}
                        onClick={() => {
                          let editedTime = handelIncreaseTime(
                            editedData[0]?.sagyoDetails?.sagyoTmPunch,
                            '10min',
                            'punchSagyoTime'
                          );
                          settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', '10min', 'inc');
                        }}
                      />
                      <CaretDownOutlined
                        style={{ fontSize: 17 + 'px', color: '#000' }}
                        onClick={() => {
                          let editedTime = handelDecreaseTime(
                            editedData[0]?.sagyoDetails?.sagyoTmPunch,
                            '10min',
                            'punchSagyoTime'
                          );
                          settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', '10min', 'dec');
                        }}
                      />
                      <CaretUpOutlined
                        style={{ fontSize: 17 + 'px', color: '#000' }}
                        onClick={() => {
                          let editedTime = handelIncreaseTime(
                            editedData[0]?.sagyoDetails?.sagyoTmPunch,
                            'min',
                            'punchSagyoTime'
                          );
                          settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', 'min', 'inc');
                        }}
                      />
                      <CaretDownOutlined
                        style={{ fontSize: 17 + 'px', color: '#000' }}
                        onClick={() => {
                          let editedTime = handelDecreaseTime(
                            editedData[0]?.sagyoDetails?.sagyoTmPunch,
                            'min',
                            'punchSagyoTime'
                          );
                          settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', 'min', 'dec');
                        }}
                      />
                      <CaretUpOutlined
                        style={{ fontSize: 17 + 'px', color: '#000' }}
                        onClick={() => {
                          let editedTime = handelIncreaseTime(
                            editedData[0]?.sagyoDetails?.sagyoTmPunch,
                            'sec',
                            'punchSagyoTime'
                          );
                          settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', 'sec', 'inc');
                        }}
                      />
                      <CaretDownOutlined
                        style={{ fontSize: 17 + 'px', color: '#000' }}
                        onClick={() => {
                          let editedTime = handelDecreaseTime(
                            editedData[0]?.sagyoDetails?.sagyoTmPunch,
                            'sec',
                            'punchSagyoTime'
                          );
                          settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', 'sec', 'dec');
                        }}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
            </Col>
            <Col span={1} className="oyabuhin-detail-col"></Col>
            <Col span={5} className="formula-column"></Col>
          </Row>
          <div id={'punchProcessIQ32'} style={{ display: 'none' }}>
            {/* パンチ数/1パンチ加工時間 */}
            <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
              <Col span={4} className="oyabuhin-detail-col">
                <span className="ddl_2_noImg" style={{ marginLeft: '45px' }}>
                  パンチ数/1パンチ加工時間
                </span>
              </Col>

              <Col span={3} className="oyabuhin-detail-col">
                <label id={'punchCnt'}>
                  {processArr[0]?.sagyoDetails?.punchCnt
                    ? Math.floor(processArr[0]?.sagyoDetails?.punchCnt)
                    : amtEmptyStr}
                </label>
              </Col>

              <Col span={3} className="oyabuhin-detail-col">
                <label id="punchTm">
                  {processArr[0]?.sagyoDetails?.punchTm
                    ? secondsToHms(processArr[0]?.sagyoDetails?.punchTm)
                    : timeHmsStr}
                </label>
              </Col>

              <Col span={4} className="input-col">
                <Input
                  value={
                    editedData[0]?.sagyoDetails?.punchCnt
                      ? Math.floor(editedData[0]?.sagyoDetails?.punchCnt)
                      : amtEmptyStr
                  }
                  id={'punchCntIn'}
                  min={0}
                  className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                  controls={false}
                  style={{
                    width: '45%',
                    border: 'none',
                    color:
                      processArr[0]?.sagyoDetails?.punchCnt === editedData[0]?.sagyoDetails?.punchCnt ? 'black' : 'red',
                  }}
                  onKeyPress={(e) => {
                    handleKeyPress(e);
                  }}
                  onChange={(e) => settingNewDandoriAmtInArr(e.target.value, i, 'punchCnt', '', '')}
                />
              </Col>

              <Col span={4} className="input-col">
                <Input
                  value={
                    editedData[0]?.sagyoDetails?.punchTm
                      ? secondsToHms(editedData[0]?.sagyoDetails?.punchTm)
                      : timeHmsStr
                  }
                  id={'punchTmIn'}
                  min={0}
                  className={editMode ? 'input-editable' : 'input-non-editable '}
                  controls={false}
                  style={{
                    width: '45%',
                    border: 'none',
                    color:
                      processArr[0]?.sagyoDetails?.punchTm === editedData[0]?.sagyoDetails?.punchTm ? 'black' : 'red',
                  }}
                  onKeyPress={(e) => {
                    handleKeyPress(e);
                  }}
                  onChange={(e) => settingNewDandoriAmtInArr(e.target.value, i, 'punchTm', '', '')}
                />
              </Col>

              <Col span={1} className="oyabuhin-detail-col"></Col>
              <Col span={4} className="formula-column"></Col>
            </Row>
          </div>
          {/** 空送 */}
          <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
            <Col span={4} className="oyabuhin-detail-col">
              <div style={{ display: 'flex', marginLeft: '20px' }}>
                <CaretUpOutlined
                  id={'arrowUpLaserIQ33'}
                  style={{ fontSize: 17, marginTop: 2 }}
                  onClick={(e) => subpartDetail(3)}
                />
                <CaretDownOutlined
                  id={'arrowDownLaserIQ33'}
                  style={{ fontSize: 17, marginTop: 2, display: 'none' }}
                  onClick={(e) => subpartDetail(3)}
                />
                <span style={{ marginLeft: '5px' }}>空送金額/時間</span>
              </div>
            </Col>
            <Col span={3} className="oyabuhin-detail-col">
              {/* ¥ */}
              <label id={'punchSagyoAmt'}>
                {processArr[0]?.sagyoDetails?.sagyoAmtAirDeli
                  ? JPYs.format(Math.floor(processArr[0]?.sagyoDetails?.sagyoAmtAirDeli))
                  : amtEmptyStr}
              </label>
            </Col>
            <Col span={3} className="oyabuhin-detail-col">
              <label id={'punchSagyoTime'}>
                {processArr[0]?.sagyoDetails?.sagyoTmAirDeli
                  ? secondsToHms(processArr[0]?.sagyoDetails?.sagyoTmAirDeli)
                  : timeHmsStr}
              </label>
            </Col>
            <Col span={4} className="input-col">
              <CurrencyInput
                id={'punchSagyoAmtIn'}
                value={
                  editedData[0]?.sagyoDetails?.sagyoAmtAirDeli
                    ? Math.floor(editedData[0]?.sagyoDetails?.sagyoAmtAirDeli)
                    : 0
                }
                defaultValue={0}
                onValueChange={(e) => {
                  if (editedData[i]?.sagyoAmt != e) {
                    settingNewDandoriAmtInArr(e, i, 'sagyoAmt', '', '');
                    checkInputWithColor(false, '', 'LaserSagyoAmtCu', e, 'amt');
                  }
                }}
                style={{ border: 'none' }}
                className={editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'}
                prefix="¥"
                decimalsLimit={100}
              />

              <div id={'LaserSagyoAmtCu'} style={{ display: 'none' }} className="currencyReset">
                <div className="currencyIcon">
                  <BsUsbC style={{ fontSize: '25' }} />
                  <BsX style={{ margin: '-8px 0 0 16px' }} />
                  <span class="tooltiptext">金額に変更がありましたので、以降時間と連携して自動計算されない。</span>
                </div>
              </div>
            </Col>
            <Col span={4} className="input-col">
              <Row style={{ width: '100%' }}>
                <Col className="timer-input-col">
                  <Input
                    value={
                      editedData[0]?.sagyoDetails?.sagyoTmAirDeli
                        ? secondsToHms(editedData[0]?.sagyoDetails?.sagyoTmAirDeli)
                        : timeHmsStr
                    }
                    id={'punchSagyoTimeIn'}
                    style={{ width: '100%' }}
                    className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                    onChange={(e) => {
                      settingNewDandoriAmtInArr(e.target.value, i, 'sagyoTime', 'input', 'input');
                    }}
                  ></Input>
                </Col>
                <Col className="timer-button-col">
                  {editMode ? (
                    <>
                      <CaretUpOutlined
                        style={{ fontSize: 17 + 'px' }}
                        onClick={() => {
                          let editedTime = handelIncreaseTime(
                            editedData[0]?.sagyoDetails?.sagyoTmAirDeli,
                            '10min',
                            'punchSagyoTime'
                          );
                          settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', '10min', 'inc');
                        }}
                      />
                      <CaretDownOutlined
                        style={{ fontSize: 17 + 'px', color: '#000' }}
                        onClick={() => {
                          let editedTime = handelDecreaseTime(
                            editedData[0]?.sagyoDetails?.sagyoTmAirDeli,
                            '10min',
                            'punchSagyoTime'
                          );
                          settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', '10min', 'dec');
                        }}
                      />
                      <CaretUpOutlined
                        style={{ fontSize: 17 + 'px', color: '#000' }}
                        onClick={() => {
                          let editedTime = handelIncreaseTime(
                            editedData[0]?.sagyoDetails?.sagyoTmAirDeli,
                            'min',
                            'punchSagyoTime'
                          );
                          settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', 'min', 'inc');
                        }}
                      />
                      <CaretDownOutlined
                        style={{ fontSize: 17 + 'px', color: '#000' }}
                        onClick={() => {
                          let editedTime = handelDecreaseTime(
                            editedData[0]?.sagyoDetails?.sagyoTmAirDeli,
                            'min',
                            'punchSagyoTime'
                          );
                          settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', 'min', 'dec');
                        }}
                      />
                      <CaretUpOutlined
                        style={{ fontSize: 17 + 'px', color: '#000' }}
                        onClick={() => {
                          let editedTime = handelIncreaseTime(
                            editedData[0]?.sagyoDetails?.sagyoTmAirDeli,
                            'sec',
                            'punchSagyoTime'
                          );
                          settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', 'sec', 'inc');
                        }}
                      />
                      <CaretDownOutlined
                        style={{ fontSize: 17 + 'px', color: '#000' }}
                        onClick={() => {
                          let editedTime = handelDecreaseTime(
                            editedData[0]?.sagyoDetails?.sagyoTmAirDeli,
                            'sec',
                            'punchSagyoTime'
                          );
                          settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', 'sec', 'dec');
                        }}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
            </Col>
            <Col span={1} className="oyabuhin-detail-col"></Col>
            <Col span={5} className="formula-column"></Col>
          </Row>
          <div id={'punchProcessIQ33'} style={{ display: 'none' }}>
            {/* 空送速度/空送距離 */}
            <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
              <Col span={4} className="oyabuhin-detail-col">
                <span className="ddl_2_noImg" style={{ marginLeft: '45px' }}>
                  空送速度/空送距離
                </span>
              </Col>

              <Col span={3} className="oyabuhin-detail-col">
                <label id="speedAirDeli">{processArr[0]?.sagyoDetails?.speedAirDeli}</label>
              </Col>

              <Col span={3} className="oyabuhin-detail-col">
                <label id={'speedAirDeliLen'}>{processArr[0]?.sagyoDetails?.speedAirDeliLen}</label>
              </Col>

              <Col span={4} className="input-col">
                <Input
                  value={editedData[0]?.sagyoDetails?.speedAirDeli}
                  id={'speedAirDeliIn'}
                  min={0}
                  className={editMode ? 'input-editable' : 'input-non-editable '}
                  controls={false}
                  style={{
                    width: '45%',
                    border: 'none',
                    color:
                      processArr[0]?.sagyoDetails?.speedAirDeli === editedData[0]?.sagyoDetails?.speedAirDeli
                        ? 'black'
                        : 'red',
                  }}
                  onKeyPress={(e) => {
                    handleKeyPress(e);
                  }}
                  onChange={(e) => settingNewDandoriAmtInArr(e.target.value, i, 'speedAirDeli', '', '')}
                />
              </Col>

              <Col span={4} className="input-col">
                <Input
                  value={editedData[0]?.sagyoDetails?.speedAirDeliLen}
                  id={'speedAirDeliLenIn'}
                  min={0}
                  className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                  controls={false}
                  style={{
                    width: '45%',
                    border: 'none',
                    color:
                      processArr[0]?.sagyoDetails?.speedAirDeliLen === editedData[0]?.sagyoDetails?.speedAirDeliLen
                        ? 'black'
                        : 'red',
                  }}
                  onKeyPress={(e) => {
                    handleKeyPress(e);
                  }}
                  onChange={(e) => settingNewDandoriAmtInArr(e.target.value, i, 'speedAirDeliLen', '', '')}
                />
              </Col>
              <Col span={1} className="oyabuhin-detail-col"></Col>
              <Col span={4} className="formula-column"></Col>
            </Row>
          </div>
          {/** ケガキ加工金額/時間 */}
          <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
            <Col span={4} className="oyabuhin-detail-col">
              <div style={{ display: 'flex', marginLeft: '20px' }}>
                <CaretUpOutlined
                  id={'arrowUpLaserIQ32'}
                  style={{ fontSize: 17, marginTop: 2 }}
                  onClick={(e) => subpartDetail(5)}
                />
                <CaretDownOutlined
                  id={'arrowDownLaserIQ35'}
                  style={{ fontSize: 17, marginTop: 2, display: 'none' }}
                  onClick={(e) => subpartDetail(5)}
                />
                <span style={{ marginLeft: '5px' }}>ケガキ加工金額/時間</span>
              </div>
            </Col>
            <Col span={3} className="oyabuhin-detail-col">
              {/* ¥ */}
              <label id={'punchSagyoAmt'}>
                {processArr[0]?.sagyoDetails?.sagyoAmtScribing
                  ? JPYs.format(Math.floor(processArr[0]?.sagyoDetails?.sagyoAmtScribing))
                  : amtEmptyStr}
              </label>
            </Col>
            <Col span={3} className="oyabuhin-detail-col">
              <label id={'punchSagyoTime'}>
                {processArr[0]?.sagyoDetails?.sagyoTmScribing
                  ? secondsToHms(processArr[0]?.sagyoDetails?.sagyoTmScribing)
                  : timeHmsStr}
              </label>
            </Col>
            <Col span={4} className="input-col">
              <CurrencyInput
                id={'punchSagyoAmtIn'}
                value={
                  editedData[0]?.sagyoDetails?.sagyoAmtScribing
                    ? Math.floor(editedData[0]?.sagyoDetails?.sagyoAmtScribing)
                    : 0
                }
                defaultValue={0}
                onValueChange={(e) => {
                  if (editedData[i]?.sagyoAmt != e) {
                    settingNewDandoriAmtInArr(e, i, 'sagyoAmt', '', '');
                    checkInputWithColor(false, '', 'LaserSagyoAmtCu', e, 'amt');
                  }
                }}
                style={{ border: 'none' }}
                className={editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'}
                prefix="¥"
                decimalsLimit={100}
              />

              <div id={'LaserSagyoAmtCu'} style={{ display: 'none' }} className="currencyReset">
                <div className="currencyIcon">
                  <BsUsbC style={{ fontSize: '25' }} />
                  <BsX style={{ margin: '-8px 0 0 16px' }} />
                  <span class="tooltiptext">金額に変更がありましたので、以降時間と連携して自動計算されない。</span>
                </div>
              </div>
            </Col>
            <Col span={4} className="input-col">
              <Row style={{ width: '100%' }}>
                <Col className="timer-input-col">
                  <Input
                    value={
                      editedData[0]?.sagyoDetails?.sagyoTmScribing
                        ? secondsToHms(editedData[0]?.sagyoDetails?.sagyoTmScribing)
                        : timeHmsStr
                    }
                    id={'punchSagyoTimeIn'}
                    style={{ width: '100%' }}
                    className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                    onChange={(e) => {
                      settingNewDandoriAmtInArr(e.target.value, i, 'sagyoTime', 'input', 'input');
                    }}
                  ></Input>
                </Col>
                <Col className="timer-button-col">
                  {editMode ? (
                    <>
                      <CaretUpOutlined
                        style={{ fontSize: 17 + 'px' }}
                        onClick={() => {
                          let editedTime = handelIncreaseTime(
                            editedData[0]?.sagyoDetails?.sagyoTmScribing,
                            '10min',
                            'punchSagyoTime'
                          );
                          settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', '10min', 'inc');
                        }}
                      />
                      <CaretDownOutlined
                        style={{ fontSize: 17 + 'px', color: '#000' }}
                        onClick={() => {
                          let editedTime = handelDecreaseTime(
                            editedData[0]?.sagyoDetails?.sagyoTmScribing,
                            '10min',
                            'punchSagyoTime'
                          );
                          settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', '10min', 'dec');
                        }}
                      />
                      <CaretUpOutlined
                        style={{ fontSize: 17 + 'px', color: '#000' }}
                        onClick={() => {
                          let editedTime = handelIncreaseTime(
                            editedData[0]?.sagyoDetails?.sagyoTmScribing,
                            'min',
                            'punchSagyoTime'
                          );
                          settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', 'min', 'inc');
                        }}
                      />
                      <CaretDownOutlined
                        style={{ fontSize: 17 + 'px', color: '#000' }}
                        onClick={() => {
                          let editedTime = handelDecreaseTime(
                            editedData[0]?.sagyoDetails?.sagyoTmScribing,
                            'min',
                            'punchSagyoTime'
                          );
                          settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', 'min', 'dec');
                        }}
                      />
                      <CaretUpOutlined
                        style={{ fontSize: 17 + 'px', color: '#000' }}
                        onClick={() => {
                          let editedTime = handelIncreaseTime(
                            editedData[0]?.sagyoDetails?.sagyoTmScribing,
                            'sec',
                            'punchSagyoTime'
                          );
                          settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', 'sec', 'inc');
                        }}
                      />
                      <CaretDownOutlined
                        style={{ fontSize: 17 + 'px', color: '#000' }}
                        onClick={() => {
                          let editedTime = handelDecreaseTime(
                            editedData[0]?.sagyoDetails?.sagyoTmScribing,
                            'sec',
                            'punchSagyoTime'
                          );
                          settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', 'sec', 'dec');
                        }}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
            </Col>
            <Col span={1} className="oyabuhin-detail-col"></Col>
            <Col span={5} className="formula-column"></Col>
          </Row>
          <div id={'punchProcessIQ35'} style={{ display: 'none' }}>
            {/* ケガキ加工速度/切断長 */}
            <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
              <Col span={4} className="oyabuhin-detail-col">
                <span className="ddl_2_noImg" style={{ marginLeft: '45px' }}>
                  ケガキ加工速度/切断長
                </span>
              </Col>

              <Col span={3} className="oyabuhin-detail-col">
                <label id="speedScribing">{processArr[0]?.sagyoDetails?.speedScribing}</label>
              </Col>

              <Col span={3} className="oyabuhin-detail-col">
                <label id={'speedScribingLen'}>{processArr[0]?.sagyoDetails?.speedScribingLen}</label>
              </Col>

              <Col span={4} className="input-col">
                <Input
                  value={editedData[0]?.sagyoDetails?.speedScribing}
                  id={'speedScribingIn'}
                  min={0}
                  className={editMode ? 'input-editable' : 'input-non-editable '}
                  controls={false}
                  style={{
                    width: '45%',
                    border: 'none',
                    color:
                      processArr[0]?.sagyoDetails?.speedScribing === editedData[0]?.sagyoDetails?.speedScribing
                        ? 'black'
                        : 'red',
                  }}
                  onKeyPress={(e) => {
                    handleKeyPress(e);
                  }}
                  onChange={(e) => settingNewDandoriAmtInArr(e.target.value, i, 'speedScribing', '', '')}
                />
              </Col>

              <Col span={4} className="input-col">
                <Input
                  value={editedData[0]?.sagyoDetails?.speedScribingLen}
                  id={'speedScribingLenIn'}
                  min={0}
                  className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                  controls={false}
                  style={{
                    width: '45%',
                    border: 'none',
                    color:
                      processArr[0]?.sagyoDetails?.speedScribingLen === editedData[0]?.sagyoDetails?.speedScribingLen
                        ? 'black'
                        : 'red',
                  }}
                  onKeyPress={(e) => {
                    handleKeyPress(e);
                  }}
                  onChange={(e) => settingNewDandoriAmtInArr(e.target.value, i, 'speedScribingLen', '', '')}
                />
              </Col>
              <Col span={1} className="oyabuhin-detail-col"></Col>
              <Col span={4} className="formula-column"></Col>
            </Row>
          </div>
        </div>
        {/** 2工程穴加工金額/時間 */}
        <div>
          <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
            <Col span={4} className="oyabuhin-detail-col" onClick={(e) => handleCollpse('BlankDrilling')}>
              <div style={{ display: 'flex' }}>
                <CaretUpOutlined id={'arrowUpBlankDrilling'} style={{ fontSize: 17, marginTop: 2, display: 'none' }} />

                <CaretDownOutlined id={'arrowDownBlankDrilling'} style={{ fontSize: 17, marginTop: 2 }} />
                <span>2工程穴加工金額/時間</span>
              </div>
            </Col>
            <Col span={3} className="oyabuhin-detail-col" onClick={(e) => handleCollpse('BlankDrilling')}>
              <label id={'drillingAmt'}>
                {processArr[0]?.sagyoDetails?.sagyoAmtTwoStepHole
                  ? JPYs.format(Math.floor(processArr[0]?.sagyoDetails?.sagyoAmtTwoStepHole))
                  : amtEmptyStr}
              </label>
            </Col>
            <Col span={3} className="oyabuhin-detail-col" onClick={(e) => handleCollpse('BlankDrilling')}>
              <label id={'drillingTime'}>
                {processArr[0]?.sagyoDetails?.sagyoTmTwoStepHole
                  ? secondsToHms(processArr[0]?.sagyoDetails?.sagyoTmTwoStepHole)
                  : timeHmsStr}
              </label>
            </Col>
            <Col span={4} className="input-col">
              <CurrencyInput
                id="drillingAmtIn"
                value={
                  editedData[0]?.sagyoDetails?.sagyoAmtTwoStepHole
                    ? Math.floor(editedData[0]?.sagyoDetails?.sagyoAmtTwoStepHole)
                    : 0
                }
                defaultValue={0}
                onValueChange={(e) => {
                  if (editedData[0]?.sagyoDetails?.sagyoAmtTwoStepHole != e) {
                    settingNewDandoriAmtInArr(e, '', 0, 'drillingAmt', '', '', 'drilling');
                    checkInputWithColor(false, '', 'drillingAmt', e, 'amt');
                  }
                }}
                style={{
                  border: 'none',
                  color:
                    processArr[0]?.sagyoDetails?.sagyoAmtTwoStepHole ===
                    editedData[0]?.sagyoDetails?.sagyoAmtTwoStepHole
                      ? 'black'
                      : checkShowHideIcon('drillingAmtCu') === 'none'
                      ? '#4cc12f'
                      : 'red',
                }}
                className={editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'}
                prefix="¥"
                decimalsLimit={100}
              />
              <div id={'drillingAmtCu'} style={{ display: 'none' }}>
                <div className="currencyIcon">
                  <BsUsbC style={{ fontSize: '25' }} />
                  <BsX style={{ margin: '-8px 0 0 16px' }} />
                  <span class="tooltiptext">金額に変更がありましたので、以降時間と連携して自動計算されない。</span>
                </div>
              </div>
            </Col>
            <Col span={4} className="input-col">
              <Row style={{ width: '100%' }}>
                <Col className="timer-input-col">
                  <Input
                    value={
                      editedData[0]?.sagyoDetails?.sagyoTmTwoStepHole
                        ? secondsToHms(editedData[0]?.sagyoDetails?.sagyoTmTwoStepHole)
                        : timeHmsStr
                    }
                    id={'drillingTimeIn'}
                    style={{
                      width: '100%',
                      color:
                        processArr[0]?.sagyoDetails?.sagyoTmTwoStepHole ===
                        editedData[0]?.sagyoDetails?.sagyoTmTwoStepHole
                          ? 'black'
                          : oldEditedData[0]?.twoStepHoleCnt === editedData[0]?.sagyoDetails?.twoStepHoleCnt &&
                            oldEditedData[0]?.twoStepHoleTm === editedData[0]?.sagyoDetails?.twoStepHoleTm
                          ? 'red'
                          : '#4cc12f',
                    }}
                    className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                    onChange={(e) => {
                      //disableTimer('test');
                      settingNewDandoriAmtInArr(
                        e.target.value,
                        '',
                        0,
                        'drillingTime',
                        'input',
                        'input',
                        'drillingTime'
                      );
                    }}
                  ></Input>
                </Col>
                <Col className="timer-button-col">
                  {editMode ? (
                    <>
                      <CaretUpOutlined
                        style={{ fontSize: 17 + 'px' }}
                        onClick={() => {
                          let editedTime = handelIncreaseTime(
                            editedData[0]?.sagyoDetails?.sagyoTmTwoStepHole,
                            '10min',
                            'drillingTime'
                          );
                          settingNewDandoriAmtInArr(editedTime, '', 0, 'drillingTime', '10min', 'inc', 'drillingTime');
                        }}
                      />
                      <CaretDownOutlined
                        style={{ fontSize: 17 + 'px', color: '#000' }}
                        onClick={() => {
                          let editedTime = handelDecreaseTime(
                            editedData[0]?.sagyoDetails?.sagyoTmTwoStepHole,
                            '10min',
                            'drillingTime'
                          );
                          settingNewDandoriAmtInArr(editedTime, '', 0, 'drillingTime', '10min', 'dec', 'drillingTime');
                        }}
                      />
                      <CaretUpOutlined
                        style={{ fontSize: 17 + 'px', color: '#000' }}
                        onClick={() => {
                          let editedTime = handelIncreaseTime(
                            editedData[0]?.sagyoDetails?.sagyoTmTwoStepHole,
                            'min',
                            'drillingTime'
                          );
                          settingNewDandoriAmtInArr(editedTime, '', 0, 'drillingTime', 'min', 'inc', 'drillingTime');
                        }}
                      />
                      <CaretDownOutlined
                        style={{ fontSize: 17 + 'px', color: '#000' }}
                        onClick={() => {
                          let editedTime = handelDecreaseTime(
                            editedData[0]?.sagyoDetails?.sagyoTmTwoStepHole,
                            'min',
                            'drillingTime'
                          );
                          settingNewDandoriAmtInArr(editedTime, '', 0, 'drillingTime', 'min', 'dec', 'drillingTime');
                        }}
                      />
                      <CaretUpOutlined
                        style={{ fontSize: 17 + 'px', color: '#000' }}
                        onClick={() => {
                          let editedTime = handelIncreaseTime(
                            editedData[0]?.sagyoDetails?.sagyoTmTwoStepHole,
                            'sec',
                            'drillingTime'
                          );
                          settingNewDandoriAmtInArr(editedTime, '', 0, 'drillingTime', 'sec', 'inc', 'drillingTime');
                        }}
                      />
                      <CaretDownOutlined
                        style={{ fontSize: 17 + 'px', color: '#000' }}
                        onClick={() => {
                          let editedTime = handelDecreaseTime(
                            editedData[0]?.sagyoDetails?.sagyoTmTwoStepHole,
                            'sec',
                            'drillingTime'
                          );
                          settingNewDandoriAmtInArr(editedTime, '', 0, 'drillingTime', 'sec', 'dec', 'drillingTime');
                        }}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
            </Col>
            <Col span={1} className="oyabuhin-detail-col" onClick={(e) => handleCollpse('BlankDrilling')}></Col>
            <Col span={5} className="formula-column" onClick={(e) => handleCollpse('BlankDrilling')}></Col>
          </Row>

          <div id={'divBlankDrilling'} style={{ display: 'none' }}>
            <Row className="oyabuhin-detail oyabuhin-row">
              <Col span={4} className="oyabuhin-detail-col">
                <span className="ddl_2_noImg">数量/1つ当たりの加工時間</span>
              </Col>
              <Col span={3} className="oyabuhin-detail-col">
                <label id="drillingCount">{processArr[0]?.sagyoDetails?.twoStepHoleCnt}</label>
              </Col>
              <Col span={3} className="oyabuhin-detail-col">
                <label id="drillingCountTime">
                  {processArr[0]?.sagyoDetails?.twoStepHoleTm
                    ? secondsToHms(processArr[0]?.sagyoDetails?.twoStepHoleTm)
                    : timeHmsStr}
                </label>
              </Col>

              <Col span={4} className="input-col">
                <Input
                  value={editedData[0]?.sagyoDetails?.twoStepHoleCnt}
                  id="drillingCountIn"
                  min={0}
                  className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                  controls={false}
                  style={{
                    width: '45%',
                    border: 'none',
                    color:
                      editedData[0]?.sagyoDetails?.twoStepHoleCnt === processArr[0]?.sagyoDetails?.twoStepHoleCnt
                        ? 'black'
                        : 'red',
                  }}
                  onKeyPress={(e) => {
                    handleKeyPress(e);
                  }}
                  onChange={(e) =>
                    settingNewDandoriAmtInArr(e.target.value, '', 0, 'drillingCount', '', '', 'drillingCount')
                  }
                />
              </Col>

              <Col span={4} className="input-col">
                <Row style={{ width: '100%' }}>
                  <Col className="timer-input-col">
                    <Input
                      value={
                        editedData[0]?.sagyoDetails?.twoStepHoleTm
                          ? secondsToHms(editedData[0]?.sagyoDetails?.twoStepHoleTm)
                          : timeHmsStr
                      }
                      id="drillingCountTimeIn"
                      min={0}
                      className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                      controls={false}
                      style={{
                        width: '100%',
                        border: 'none',
                        color:
                          editedData[0]?.sagyoDetails?.twoStepHoleTm === processArr[0]?.twoStepHoleTm ? 'black' : 'red',
                      }}
                      onKeyPress={(e) => {
                        handleKeyPress(e);
                      }}
                      onChange={(e) =>
                        settingNewDandoriAmtInArr(
                          e.target.value,
                          0,
                          '',
                          'drillingCountTime',
                          'input',
                          'input',
                          'drillingCountTime'
                        )
                      }
                    />
                  </Col>
                  <Col className="timer-button-col">
                    {editMode ? (
                      <>
                        <CaretUpOutlined
                          style={{ fontSize: 17 + 'px' }}
                          onClick={() => {
                            let editedTime = handelIncreaseTime(
                              editedData[0]?.sagyoDetails?.twoStepHoleTm,
                              '10min',
                              'drillingCountTime'
                            );
                            settingNewDandoriAmtInArr(
                              editedTime,
                              0,
                              '',
                              'drillingCountTime',
                              '10min',
                              'inc',
                              'drillingCountTime'
                            );
                          }}
                        />
                        <CaretDownOutlined
                          style={{ fontSize: 17 + 'px', color: '#000' }}
                          onClick={() => {
                            let editedTime = handelDecreaseTime(
                              editedData[0]?.sagyoDetails?.twoStepHoleTm,
                              '10min',
                              'drillingCountTime'
                            );
                            settingNewDandoriAmtInArr(
                              editedTime,
                              0,
                              '',
                              'drillingCountTime',
                              '10min',
                              'dec',
                              'drillingCountTime'
                            );
                          }}
                        />
                        <CaretUpOutlined
                          style={{ fontSize: 17 + 'px', color: '#000' }}
                          onClick={() => {
                            let editedTime = handelIncreaseTime(
                              sagyoEdit[0]?.twoStepHoleTm,
                              'min',
                              'drillingCountTime'
                            );
                            settingNewDandoriAmtInArr(
                              editedTime,
                              0,
                              '',
                              'drillingCountTime',
                              'min',
                              'inc',
                              'drillingCountTime'
                            );
                          }}
                        />
                        <CaretDownOutlined
                          style={{ fontSize: 17 + 'px', color: '#000' }}
                          onClick={() => {
                            let editedTime = handelDecreaseTime(
                              editedData[0]?.sagyoDetails?.twoStepHoleTm,
                              'min',
                              'drillingCountTime'
                            );
                            settingNewDandoriAmtInArr(
                              editedTime,
                              0,
                              '',
                              'drillingCountTime',
                              'min',
                              'dec',
                              'drillingCountTime'
                            );
                          }}
                        />
                        <CaretUpOutlined
                          style={{ fontSize: 17 + 'px', color: '#000' }}
                          onClick={() => {
                            let editedTime = handelIncreaseTime(
                              sagyoEdit[0]?.twoStepHoleTm,
                              'sec',
                              'drillingCountTime'
                            );
                            settingNewDandoriAmtInArr(
                              editedTime,
                              0,
                              '',
                              'drillingCountTime',
                              'sec',
                              'inc',
                              'drillingCountTime'
                            );
                          }}
                        />
                        <CaretDownOutlined
                          style={{ fontSize: 17 + 'px', color: '#000' }}
                          onClick={() => {
                            let editedTime = handelDecreaseTime(
                              editedData[0]?.sagyoDetails?.twoStepHoleTm,
                              'sec',
                              'drillingCountTime'
                            );
                            settingNewDandoriAmtInArr(
                              editedTime,
                              0,
                              '',
                              'drillingCountTime',
                              'sec',
                              'dec',
                              'drillingCountTime'
                            );
                          }}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col span={1} className="oyabuhin-detail-col"></Col>
              <Col span={5} className="formula-column"></Col>
            </Row>
          </div>
        </div>
        {/* ジョイントバラシ加工金額/時間 */}
        <div>
          <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
            <Col span={4} className="oyabuhin-detail-col" onClick={(e) => handleCollpse('BlankJoint')}>
              <div style={{ display: 'flex' }}>
                <CaretUpOutlined id={'arrowUpBlankJoint'} style={{ fontSize: 17, marginTop: 2, display: 'none' }} />

                <CaretDownOutlined id={'arrowDownBlankJoint'} style={{ fontSize: 17, marginTop: 2 }} />
                <span style={{ fontSize: 11 }}>ジョイントバラシ加工金額/時間</span>
              </div>
            </Col>
            <Col span={3} className="oyabuhin-detail-col" onClick={(e) => handleCollpse('BlankJoint')}>
              <label id={'jointAmt'}>
                {processArr[0]?.sagyoDetails?.sagyoAmtJoint
                  ? JPYs.format(Math.floor(processArr[0]?.sagyoDetails?.sagyoAmtJoint))
                  : amtEmptyStr}
              </label>
            </Col>
            <Col span={3} className="oyabuhin-detail-col" onClick={(e) => handleCollpse('BlankJoint')}>
              <label id={'jointTime'}>
                {processArr[0]?.sagyoDetails?.sagyoTmJoint
                  ? secondsToHms(processArr[0]?.sagyoDetails?.sagyoTmJoint)
                  : timeHmsStr}
              </label>
            </Col>
            <Col span={4} className="input-col">
              <CurrencyInput
                id="jointAmtIn"
                value={
                  editedData[0]?.sagyoDetails?.sagyoAmtJoint
                    ? Math.floor(editedData[0]?.sagyoDetails?.sagyoAmtJoint)
                    : 0
                }
                defaultValue={0}
                onValueChange={(e) => {
                  if (editedData[0]?.sagyoDetails?.sagyoAmtJoint != e) {
                    settingNewDandoriAmtInArr(e, '', 0, 'jointAmt', '', '');
                    checkInputWithColor(false, '', 'jointAmt', e, 'amt');
                  }
                }}
                style={{
                  border: 'none',
                  color:
                    parseFloat(editedData[0]?.sagyoDetails?.sagyoAmtJoint) === processArr[0]?.sagyoAmtJoint
                      ? 'black'
                      : checkShowHideIcon('jointAmtCu') === 'none'
                      ? '#4cc12f'
                      : 'red',
                }}
                className={editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'}
                prefix="¥"
                decimalsLimit={100}
              />
              <div id={'jointAmtCu'} style={{ display: 'none' }}>
                <div className="currencyIcon">
                  <BsUsbC style={{ fontSize: '25' }} />
                  <BsX style={{ margin: '-8px 0 0 16px' }} />
                  <span class="tooltiptext">金額に変更がありましたので、以降時間と連携して自動計算されない。</span>
                </div>
              </div>
            </Col>
            <Col span={4} className="input-col">
              <Row style={{ width: '100%' }}>
                <Col className="timer-input-col">
                  <Input
                    value={
                      editedData[0]?.sagyoDetails?.sagyoTmJoint
                        ? secondsToHms(editedData[0]?.sagyoDetails?.sagyoTmJoint)
                        : timeHmsStr
                    }
                    id={'jointTimeIn'}
                    style={{
                      width: '100%',
                      color:
                        editedData[0]?.sagyoDetails?.sagyoTmJoint === processArr[0]?.sagyoDetails?.sagyoTmJoint
                          ? 'black'
                          : oldEditedData[0]?.sagyoDetails?.sagyoDetails?.jointCnt === editedData[0]?.jointCnt &&
                            oldEditedData[0]?.sagyoDetails?.jointTm === editedData[0]?.sagyoDetails?.jointTm
                          ? 'red'
                          : '#4cc12f',
                    }}
                    className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                    onChange={(e) => {
                      //disableTimer('test');
                      settingNewDandoriAmtInArr(e.target.value, '', 0, 'jointTime', 'input', 'input', 'jointTime');
                      // settingNewDandoriAmtInArr(e.target.value, j, 'kensaTime', 'input', 'input');
                    }}
                  ></Input>
                </Col>
                <Col className="timer-button-col">
                  {editMode ? (
                    <>
                      <CaretUpOutlined
                        style={{ fontSize: 17 + 'px' }}
                        onClick={() => {
                          let editedTime = handelIncreaseTime(
                            editedData[0]?.sagyoDetails?.sagyoTmJoint,
                            '10min',
                            'jointTime'
                          );
                          settingNewDandoriAmtInArr(editedTime, '', 0, 'jointTime', '10min', 'inc', 'jointTime');
                        }}
                      />
                      <CaretDownOutlined
                        style={{ fontSize: 17 + 'px', color: '#000' }}
                        onClick={() => {
                          let editedTime = handelDecreaseTime(
                            editedData[0]?.sagyoDetails?.sagyoTmJoint,
                            '10min',
                            'jointTime'
                          );
                          settingNewDandoriAmtInArr(editedTime, '', 0, 'jointTime', '10min', 'dec', 'jointTime');
                        }}
                      />
                      <CaretUpOutlined
                        style={{ fontSize: 17 + 'px', color: '#000' }}
                        onClick={() => {
                          let editedTime = handelIncreaseTime(
                            editedData[0]?.sagyoDetails?.sagyoTmJoint,
                            'min',
                            'jointTime'
                          );
                          settingNewDandoriAmtInArr(editedTime, '', 0, 'jointTime', 'min', 'inc', 'jointTime');
                        }}
                      />
                      <CaretDownOutlined
                        style={{ fontSize: 17 + 'px', color: '#000' }}
                        onClick={() => {
                          let editedTime = handelDecreaseTime(
                            editedData[0]?.sagyoDetails?.sagyoTmJoint,
                            'min',
                            'jointTime'
                          );
                          settingNewDandoriAmtInArr(editedTime, '', 0, 'jointTime', 'min', 'dec', 'jointTime');
                        }}
                      />
                      <CaretUpOutlined
                        style={{ fontSize: 17 + 'px', color: '#000' }}
                        onClick={() => {
                          let editedTime = handelIncreaseTime(
                            editedData[0]?.sagyoDetails?.sagyoTmJoint,
                            'sec',
                            'jointTime'
                          );
                          settingNewDandoriAmtInArr(editedTime, '', 0, 'jointTime', 'sec', 'inc', 'jointTime');
                        }}
                      />
                      <CaretDownOutlined
                        style={{ fontSize: 17 + 'px', color: '#000' }}
                        onClick={() => {
                          let editedTime = handelDecreaseTime(
                            editedData[0]?.sagyoDetails?.sagyoTmJoint,
                            'sec',
                            'jointTime'
                          );
                          settingNewDandoriAmtInArr(editedTime, '', 0, 'jointTime', 'sec', 'dec', 'jointTime');
                        }}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
            </Col>
            <Col span={1} className="oyabuhin-detail-col" onClick={(e) => handleCollpse('BlankJoint')}></Col>
            <Col span={5} className="formula-column" onClick={(e) => handleCollpse('BlankJoint')}></Col>
          </Row>

          <div id={'divBlankJoint'} style={{ display: 'none' }}>
            <Row className="oyabuhin-detail oyabuhin-row">
              <Col span={4} className="oyabuhin-detail-col">
                <span className="ddl_2_noImg">数量/1つ当たりの加工時間</span>
              </Col>
              <Col span={3} className="oyabuhin-detail-col">
                <label id="jointCnt">{processArr[0]?.sagyoDetails?.jointCnt}</label>
              </Col>
              <Col span={3} className="oyabuhin-detail-col">
                <label id="jointCntTime">
                  {processArr[0]?.sagyoDetails?.jointTm
                    ? secondsToHms(processArr[0]?.sagyoDetails?.jointTm)
                    : timeHmsStr}
                </label>
              </Col>

              <Col span={4} className="input-col">
                <Input
                  value={editedData[0]?.sagyoDetails?.jointCnt}
                  id="jointCntIn"
                  min={0}
                  className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                  controls={false}
                  style={{
                    width: '45%',
                    border: 'none',
                    color:
                      parseFloat(editedData[0]?.sagyoDetails?.jointCnt) === processArr[0]?.sagyoDetails?.jointCnt
                        ? 'black'
                        : 'red',
                  }}
                  onKeyPress={(e) => {
                    handleKeyPress(e);
                  }}
                  onChange={(e) => settingNewDandoriAmtInArr(e.target.value, '', 0, 'jointCnt', '', '', 'jointCnt')}
                />
              </Col>

              <Col span={4} className="input-col">
                <Row style={{ width: '100%' }}>
                  <Col className="timer-input-col">
                    <Input
                      value={
                        editedData[0]?.sagyoDetails?.jointTm
                          ? secondsToHms(editedData[0]?.sagyoDetails?.jointTm)
                          : timeHmsStr
                      }
                      id="jointTmIn"
                      min={0}
                      className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                      controls={false}
                      style={{
                        width: '100%',
                        border: 'none',
                        color:
                          editedData[0]?.sagyoDetails?.jointTm === processArr[0]?.sagyoDetails?.jointTm
                            ? 'black'
                            : 'red',
                      }}
                      onKeyPress={(e) => {
                        handleKeyPress(e);
                      }}
                      onChange={(e) =>
                        settingNewDandoriAmtInArr(e.target.value, 0, '', 'jointTm', 'input', 'input', 'jointTm')
                      }
                    />
                  </Col>
                  <Col className="timer-button-col">
                    {editMode ? (
                      <>
                        <CaretUpOutlined
                          style={{ fontSize: 17 + 'px' }}
                          onClick={() => {
                            let editedTime = handelIncreaseTime(
                              editedData[0]?.sagyoDetails?.jointTm,
                              '10min',
                              'jointTm'
                            );
                            settingNewDandoriAmtInArr(editedTime, 0, '', 'jointTm', '10min', 'inc', 'jointTm');
                          }}
                        />
                        <CaretDownOutlined
                          style={{ fontSize: 17 + 'px', color: '#000' }}
                          onClick={() => {
                            let editedTime = handelDecreaseTime(
                              editedData[0]?.sagyoDetails?.jointTm,
                              '10min',
                              'jointTm'
                            );
                            settingNewDandoriAmtInArr(editedTime, 0, '', 'jointTm', '10min', 'dec', 'jointTm');
                          }}
                        />
                        <CaretUpOutlined
                          style={{ fontSize: 17 + 'px', color: '#000' }}
                          onClick={() => {
                            let editedTime = handelIncreaseTime(editedData[0]?.sagyoDetails?.jointTm, 'min', 'jointTm');
                            settingNewDandoriAmtInArr(editedTime, 0, '', 'jointTm', 'min', 'inc', 'jointTm');
                          }}
                        />
                        <CaretDownOutlined
                          style={{ fontSize: 17 + 'px', color: '#000' }}
                          onClick={() => {
                            let editedTime = handelDecreaseTime(editedData[0]?.sagyoDetails?.jointTm, 'min', 'jointTm');
                            settingNewDandoriAmtInArr(editedTime, 0, '', 'jointTm', 'min', 'dec', 'jointTm');
                          }}
                        />
                        <CaretUpOutlined
                          style={{ fontSize: 17 + 'px', color: '#000' }}
                          onClick={() => {
                            let editedTime = handelIncreaseTime(editedData[0]?.sagyoDetails?.jointTm, 'sec', 'jointTm');
                            settingNewDandoriAmtInArr(editedTime, 0, '', 'jointTm', 'sec', 'inc', 'jointTm');
                          }}
                        />
                        <CaretDownOutlined
                          style={{ fontSize: 17 + 'px', color: '#000' }}
                          onClick={() => {
                            let editedTime = handelDecreaseTime(editedData[0]?.sagyoDetails?.jointTm, 'sec', 'jointTm');
                            settingNewDandoriAmtInArr(editedTime, 0, '', 'jointTm', 'sec', 'dec', 'jointTm');
                          }}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col span={1} className="oyabuhin-detail-col"></Col>
              <Col span={5} className="formula-column"></Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
});

export default IQ3KouteiCalculate_BlankPunch;
