/**
 * クラス名：装置設定の取付き時間（板厚判断）画面
 * 作成者：チュー
 * 作成日：2023/06/01
 * バージョン：1.0
 */

import React, { useState, useEffect, forwardRef, useRef } from 'react';
import { Row, Col, Input, Button, Space, Popover } from 'antd';
import { PlusCircleFilled, CloseCircleFilled, CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { Table } from 'react-bootstrap';
import { isEmpty } from '@rc-component/mini-decimal/es/numberUtil';
import { commonModal } from '../../../common/CommonModal';
import {
  souchiHandleIncreaseTime,
  souchiHandleDecreaseTime,
  secondsToHms,
  setTimetoSec,
  updateList,
  sortListByMasterData,
  getInputBorderStyle,
  handleKeyPress,
} from '../../../common/Common';
const ToritsukiJikan_Itaatsu = forwardRef((props, ref) => {
  const [editMode, setEditMode] = useState(false);
  const [isItaatsu, setIsItaatsu] = useState(false);

  const [detailsItaatsu, setDetailsItaatsu] = useState([]);
  const [detailsItaatsuTemp, setDetailsItaatsuTemp] = useState([]);
  const [list, setList] = useState([]);
  const [kouteiTypeList, setKouteiTypeList] = useState([]);

  const [filterKeys, setFilterKeys] = useState([]);

  const [errIndexVal, setErrIndexVal] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const inputRef = useRef([]);

  useEffect(() => {
    setErrIndexVal([]);
    setErrorMessage('');
    setEditMode(props.editMode);
    setDetailsItaatsu(props.detailsData?.instThickThMaster);
    setDetailsItaatsuTemp(props.detailsData?.instThickThMaster);
    setList(props.detailsData?.instThickThItems);
    setKouteiTypeList(props.processTypes);

    const usedIds = props?.processMstRecord?.details?.processDetailTypes
      ?.filter((item) => item.isUsed)
      ?.map((item) => item.id);
    setFilterKeys(usedIds);
  }, [props.detailsData, props.editMode, props.processTypes, props.processMstRecord]);
  const thickness = () => {
    setIsItaatsu(true);
  };

  const addThicknessModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>板厚</p>
    </div>
  );

  const addData = () => {
    setIsUpdateData(true);
  };

  const addItaatsu = (id, index) => {
    inputRef.current[index].focus();
    const copyData = detailsItaatsuTemp.slice(); // Create a copy of the original array

    const insertIndex = detailsItaatsuTemp?.findIndex((item) => item.no === id); // Find the index of the item with id 2

    if (insertIndex !== -1) {
      const data = {
        no: detailsItaatsuTemp?.length + 1,
        value: 0,
        isOutOfRange: false,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setDetailsItaatsuTemp(copyData);
    }
  };

  const deleteItaatsu = (id, e, index) => {
    // 更新するエラーインデックスを得る
    const updatedErrorIndexes = errIndexVal?.filter((item) => item !== index);

    const finalErrorIndexes = updatedErrorIndexes?.map((item) => (item > index ? item - 1 : item));
    setErrIndexVal(finalErrorIndexes);

    if (detailsItaatsuTemp?.filter((item) => item.isOutOfRange === false)?.length > 2) {
      const updatedData = detailsItaatsuTemp?.map((row) => {
        if (row.no === id) {
          return { ...row, isOutOfRange: true };
        }
        return row;
      });
      setDetailsItaatsuTemp(updatedData);
      const filterData = updatedData?.filter((i) => !i.isOutOfRange);
      if (index + 1 !== filterData?.length) {
        inputRef.current[index].focus();
      }
    }
  };

  const closeAddModal = () => {
    if (isItaatsu === true) {
      setIsItaatsu(false);
      setDetailsItaatsuTemp(detailsItaatsu);
    }
  };
  const cancelAddModal = () => {
    if (isItaatsu === true) {
      setErrIndexVal([]);
      setErrorMessage('');
      setIsItaatsu(false);
      setDetailsItaatsuTemp(detailsItaatsu);
    }
  };
  const handleItaatsuChange = (event, id, field) => {
    const updatedData = detailsItaatsuTemp?.map((row) => {
      if (row.no === id) {
        return { ...row, [field]: event.target.value };
      }
      return row;
    });
    setDetailsItaatsuTemp(updatedData);
  };

  const addItaatsuMaster = () => {
    const deletedId = [];

    detailsItaatsu?.forEach((item1, index) => {
      const found = detailsItaatsuTemp?.find((item2) => item2.no === item1.no && item2.isOutOfRange === true);
      if (found) {
        deletedId.push(item1.no);
      }
    });

    const newId = detailsItaatsuTemp
      ?.filter((item) => !item.isOutOfRange && !detailsItaatsu.some((data2Item) => data2Item.no === item.no))
      ?.map((item) => item.no);

    const updatedData = updateList(list, deletedId, newId);

    const sortedList = sortListByMasterData(updatedData, detailsItaatsuTemp);
    setIsItaatsu(false);
    setDetailsItaatsu(detailsItaatsuTemp);
    props.updatedItaatsu(detailsItaatsuTemp);
    setList(sortedList);
    props.updatedListData(sortedList);
  };
  const isValueOrderCorrect = (no) => {
    const temp = detailsItaatsuTemp?.filter((i) => i.isOutOfRange === false);

    const insertIndex = temp?.findIndex((item) => item.no === no);

    if (insertIndex > 0) {
      const prevValue = parseFloat(temp[insertIndex - 1].value);
      const currentValue = parseFloat(temp[insertIndex].value);
      const nextValue = parseFloat(temp[insertIndex + 1].value);
      if (currentValue > prevValue && currentValue < nextValue) {
        let temp = [insertIndex - 1, insertIndex, insertIndex + 1];

        setErrIndexVal(errIndexVal?.filter((item) => item !== insertIndex && item != insertIndex - 1));

        setErrorMessage('前のしきい値より大きい値を入力してください！');
      } else {
        setErrIndexVal([...errIndexVal, insertIndex]);

        if (currentValue <= prevValue) {
          setErrorMessage('前のしきい値より大きい値を入力してください！');
        } else if (currentValue >= nextValue) {
          setErrorMessage('次のしきい値より小さい値を入力してください！');
        }
      }
    } else {
      const currentValue = parseFloat(temp[insertIndex].value);
      const nextValue = parseFloat(temp[insertIndex + 1].value);

      if (currentValue < nextValue) {
        setErrIndexVal(errIndexVal?.filter((item) => item !== insertIndex));
      } else {
        setErrIndexVal([...errIndexVal, insertIndex]);
        setErrorMessage('次のしきい値より小さい値を入力してください！');
      }
    }
  };
  const addItaatsuContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>しきい値</label>（≦）
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {detailsItaatsuTemp
                  ?.filter((i) => i.isOutOfRange === false)
                  ?.map((item, index) => (
                    <tr key={index} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        {errIndexVal?.includes(index) ? (
                          <Popover
                            content={<div style={{ padding: 7 }}>{errorMessage}</div>}
                            placement="left"
                            visible={true}
                          ></Popover>
                        ) : (
                          ''
                        )}
                        <Input
                          value={item.value}
                          onChange={(e) => handleItaatsuChange(e, item.no, 'value')}
                          onBlur={() => isValueOrderCorrect(item.no)}
                          className={errIndexVal?.includes(index) ? 'red-border' : ''}
                          style={{
                            ...getInputBorderStyle(index, detailsItaatsuTemp),
                            pointerEvents: editMode ? 'auto' : 'none',
                          }}
                          onKeyPress={(e) => {
                            handleKeyPress(e, false);
                          }}
                          ref={(ref) => (inputRef.current[index] = ref)}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addItaatsu(item.no, index + 1)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteItaatsu(item.no, e, index)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button
          className={isEmpty(errIndexVal) ? 'mainButton' : 'disabledButton'}
          id="update"
          onClick={addItaatsuMaster}
        >
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} id="discard" onClick={cancelAddModal}>
          キャンセル
        </Button>
      </Row>
    </>
  );

  const listDataChange = (event, key, no) => {
    const newList = { ...list };
    const itemIndex = newList[key]?.findIndex((item) => item.no === no);

    if (itemIndex !== -1) {
      newList[key][itemIndex].value = event;
      setList(newList);
      props.updatedListData(newList);
    }
  };

  return (
    <>
      <div className="table-container" style={{ marginLeft: 10, marginRight: 10 }}>
        <Row>
          <Table
            style={{
              width:
                detailsItaatsu?.filter((i) => i.isOutOfRange === false)?.length > 4
                  ? '100%'
                  : detailsItaatsu?.filter((i) => i.isOutOfRange === false)?.length * 210 + 196 + 'px',
            }}
          >
            <tr style={{ color: 'white', textAlign: 'center', height: 40 }}>
              <td style={{ width: '196px' }} className="tbl-header fixedCol">
                <Row justify="center" className="tbl-header">
                  <Space>
                    取付種類 /
                    {editMode ? (
                      <>
                        <Button className="cancleButton" onClick={thickness} style={{ pointerEvents: 'auto' }}>
                          板厚
                        </Button>
                      </>
                    ) : (
                      <>
                        <label>板厚</label>
                      </>
                    )}
                  </Space>
                </Row>
              </td>

              {detailsItaatsu
                ?.filter((i) => i.isOutOfRange === false)
                ?.map((item, index) => (
                  <td key={index} style={{ backgroundColor: '#1063aa', width: '210px' }}>
                    ～{item.value}t
                  </td>
                ))}
            </tr>

            {Object.keys(list)
              ?.filter((listkey) => filterKeys?.includes(parseInt(listkey)))
              ?.map((key) => (
                <tr key={key} style={{ textAlign: 'center' }}>
                  <td style={{ color: 'white', width: '196px' }} className="tbl-header fixedCol">
                    {kouteiTypeList?.find((item) => item.id.toString() === key)?.name}
                  </td>
                  {list[key]?.map((item, index) => (
                    <td key={index} style={{ verticalAlign: 'middle', textAlign: 'center', width: 210 }}>
                      <Row align="middle">
                        {!editMode ? (
                          <>
                            <Col span={24}>
                              <label>{secondsToHms(item.value)}</label>
                            </Col>
                          </>
                        ) : (
                          <>
                            <Col span={10}>
                              <Input
                                value={secondsToHms(item.value)}
                                onChange={(e) => listDataChange(setTimetoSec(e.target.value), key, item.no)}
                              ></Input>
                            </Col>
                            <Col span={14}>
                              <CaretUpOutlined
                                style={{
                                  fontSize: 16 + 'px',
                                  marginLeft: 3,
                                  pointerEvents: 'auto',
                                }}
                                onClick={() => {
                                  let editedTime = souchiHandleIncreaseTime(secondsToHms(item.value), '10min');

                                  listDataChange(setTimetoSec(editedTime), key, item.no);
                                }}
                              />
                              <CaretDownOutlined
                                style={{
                                  fontSize: 17 + 'px',
                                  color: '#000',
                                  pointerEvents: 'auto',
                                }}
                                onClick={() => {
                                  let editedTime = souchiHandleDecreaseTime(secondsToHms(item.value), '10min');
                                  listDataChange(setTimetoSec(editedTime), key, item.no);
                                }}
                              />
                              <CaretUpOutlined
                                style={{
                                  fontSize: 17 + 'px',
                                  color: '#000',
                                  pointerEvents: 'auto',
                                }}
                                onClick={() => {
                                  let editedTime = souchiHandleIncreaseTime(secondsToHms(item.value), 'min');
                                  listDataChange(setTimetoSec(editedTime), key, item.no);
                                }}
                              />
                              <CaretDownOutlined
                                style={{
                                  fontSize: 17 + 'px',
                                  color: '#000',
                                  pointerEvents: 'auto',
                                }}
                                onClick={() => {
                                  let editedTime = souchiHandleDecreaseTime(secondsToHms(item.value), 'min');
                                  listDataChange(setTimetoSec(editedTime), key, item.no);
                                }}
                              />
                              <CaretUpOutlined
                                style={{
                                  fontSize: 17 + 'px',
                                  color: '#000',
                                  pointerEvents: 'auto',
                                }}
                                onClick={() => {
                                  let editedTime = souchiHandleIncreaseTime(secondsToHms(item.value), 'sec');
                                  listDataChange(setTimetoSec(editedTime), key, item.no);
                                }}
                              />
                              <CaretDownOutlined
                                style={{
                                  fontSize: 17 + 'px',
                                  color: '#000',
                                  pointerEvents: 'auto',
                                }}
                                onClick={() => {
                                  let editedTime = souchiHandleDecreaseTime(secondsToHms(item.value), 'sec');
                                  listDataChange(setTimetoSec(editedTime), key, item.no);
                                }}
                              />
                            </Col>
                          </>
                        )}
                      </Row>
                    </td>
                  ))}
                </tr>
              ))}
          </Table>
        </Row>
      </div>

      {/* 板厚 */}
      {isItaatsu
        ? commonModal(
            isItaatsu,
            addThicknessModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addItaatsuContent,
            null,
            null,
            true
          )
        : ''}
    </>
  );
});

export default ToritsukiJikan_Itaatsu;
