/**
 * クラス名：板金子部品工程選択
 * 説明：板金子部品工程選択ファイルである。
 * 作成者：ナンス
 * 作成日：2023/03/01
 * バージョン：1.0
 */
import React, { useEffect, useState, useImperativeHandle, forwardRef, useRef } from 'react';

import { patternPage } from '../common/Common';
import ProcessPatternForm from '../parameter/parent/processPattern/ProcessPatternForm';
import { getDeviceInfo, getProcessInfo, getProcessPatternInfo } from '../common/CommonAPI';
import { ProcessDetailGroup, ServiceClass, WorkType } from '../common/enums';

const page = patternPage[1];

const IQ3KouteiSentaku = forwardRef((props, ref) => {
  const [childPartNo, setChildPartNo] = useState(1);
  const [listData, setListData] = useState([]);
  const [processSelectedData, setProcessSelectedData] = useState([]);
  const [selectedChildPartInfo, setSelectedChildPartInfo] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [kouteiSentakuPatternNo, setKouteiSentakuPatternNo] = useState([]);
  const [kouteiSentakuPatternList, setKouteiSentakuPatternList] = useState([]);
  const [iq3List, setIQ3List] = useState([]);

  const [deviceMasterInfo, setDeviceMasterInfo] = useState([]);
  const [processMasterInfo, setProcessMasterInfo] = useState([]);
  const [clientInfo, setClientInfo] = useState([]);

  const patternformRef = useRef();
  const currentRoute = location.pathname;

  useEffect(() => {
    getProcesssPatternInfo();
    let iq3List = props.iQ3DataLst == undefined ? [] : props.iQ3DataLst;
    let iq3List_SelectBoxData = [];
    for (let i = 0; i < iq3List?.length; i++) {
      iq3List_SelectBoxData.push({ label: 'No.' + iq3List[i].no + '　' + iq3List[i].name, value: iq3List[i].no });
    }
    setIQ3List(iq3List_SelectBoxData);
  }, [props.selectedIQ3DataDetail, props.selectedDataDetail, props.type]);

  useEffect(() => {
    setEditMode(props.editMode != undefined ? props.editMode : false);
  }, [props.editMode]);

  const getProcesssPatternInfo = async () => {
    let kouteiPatternList = [];
    let kouteiSentakuPatternNo = 0;
    // const patternLst = await getProcessPatternInfo(); // 工程マスタデータ
    let parameters = props?.selectedDataDetail?.estimateProducts?.calcParameters?.parameters;
    // 工程マスタデータ
    const patternLst = parameters?.processPattern;
    kouteiPatternList = patternLst?.filter((item) => item.class === ServiceClass.SheetMetal);

    setListData(kouteiPatternList);
    setKouteiSentakuPatternList(kouteiPatternList);

    // if (props?.selectedIQ3DataDetail && props?.selectedIQ3DataDetail?.processSelect) {
    //   kouteiSentakuPatternNo = props.selectedIQ3DataDetail.processSelect.id;
    // }

    // let targertProcessPatternInfo = kouteiPatternList?.filter((i) => i.id === kouteiSentakuPatternNo);
    // if (targertProcessPatternInfo?.length > 0) {
    //   setProcessSelectedData(targertProcessPatternInfo?.[0]);
    // } else {
    //   setProcessSelectedData(kouteiPatternList?.[0]);
    // }
    if (props.type === 'pmx') {
      if (props?.patternNo != 0) {
        const selectedPatternInfo = kouteiPatternList?.filter((item) => item.id == props.patternNo);
        setProcessSelectedData(selectedPatternInfo[0]);
        setKouteiSentakuPatternNo(selectedPatternInfo[0]?.id);
      } else {
        setProcessSelectedData(kouteiPatternList[0]);
        setKouteiSentakuPatternNo(kouteiPatternList[0].id);
      }
    } else {
      let processSelect = props?.selectedIQ3DataDetail?.processSelect;
      setProcessSelectedData(processSelect);
      setKouteiSentakuPatternNo(processSelect?.id);
    }

    setDeviceMasterInfo(parameters?.device);
    setProcessMasterInfo(parameters?.process);
    setClientInfo(parameters?.client);
  };

  const updateKouteiSentakuInfo = (selectedProcessPattern, list) => {
    setKouteiSentakuPatternNo(selectedProcessPattern?.id);

    setProcessSelectedData(selectedProcessPattern);
    setKouteiSentakuPatternList(list);
    if (props.type === 'pmx') {
      props.updateSelectedPatternNo(selectedProcessPattern?.id);
      const updatedLists = props.selectedDataDetail?.esIq3Info?.map((item) => ({
        ...item,
        processSelect: selectedProcessPattern,
      }));
      props.selectedDataDetail.esIq3Info = updatedLists;

      // props.updatedSelectedData(updatedLists);
    }

    // props.updatePatternList(selectedProcessPattern, list, 'iQ3');
  };

  const updateSelectedData = (selectedPart) => {
    props.updateSelectedData(selectedPart);
  };

  useImperativeHandle(
    ref,
    () => ({
      getKouteiSentakuPatternNo: () => {
        return kouteiSentakuPatternNo;
      },
      getKouteiSentakuPatternList: () => {
        return kouteiSentakuPatternList;
      },
      getProcessSelectedData: () => {
        return processSelectedData;
      },
    }),
    [kouteiSentakuPatternNo, kouteiSentakuPatternList, processSelectedData]
  );

  return (
    <>
      <div style={{ paddingTop: 5 }}>
        <ProcessPatternForm
          ref={patternformRef}
          selectedData={processSelectedData}
          listData={listData}
          editMode={props.editMode}
          page={patternPage[1]}
          isParameter={false}
          updateKouteiSentakuInfo={updateKouteiSentakuInfo}
          kouteiSentakuEditMode={editMode}
          iq3PartList={iq3List}
          childPartNo={props.selectedIQ3DataDetail != undefined ? props.selectedIQ3DataDetail.no : 1}
          updateSelectedData={updateSelectedData}
          pmxMode={props.pmxMode}
          processMasterData={processMasterInfo}
          deviceMasterData={deviceMasterInfo}
          clientMasterData={clientInfo}
        />
      </div>
    </>
  );
});

export default IQ3KouteiSentaku;
