/**
 * クラス名：工程パターン詳細画面
 * 説明：親部品・板金の共通使う工程パターンの詳細画面である。
 * 作成者：ナンス
 * 作成日：2023/04/01
 * バージョン：1.0
 */
import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import { Button, Form, Modal } from 'antd';
import 'bootstrap/dist/css/bootstrap.min.css';
import { CloseOutlined } from '@ant-design/icons';

import ProcessPatternForm from './ProcessPatternForm';
import { patternPage } from '../../../common/Common';

const ProcessPatternDetails = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    discardConfirmOk: discardConfirmOk,
    discardConfirmCancel: discardConfirmCancel,
    editData: editData,
  }));

  const patternformRef = useRef();

  //　選択中情報
  const [selectedData, setSelectedData] = useState([]);

  //　選択中のキー
  const [key, setKey] = useState();

  // 編集モード
  const editMode = props?.editMode;

  // 更新確認
  const [updateConfirm, setUpdateConfirm] = useState(false);
  // 破棄確認
  const [cancelConfirm, setCancelConfirm] = useState(false);

  // 編集ボタンの押下
  const editData = () => {
    props?.updateEditMode(true);
    document.getElementById('update').style.display = 'block';
    document.getElementById('discard').style.display = 'block';
    document.getElementById('edit').style.display = 'none';
  };

  // 更新ボタンの押下
  const updateData = () => {
    //setUpdateConfirm(true);
    setUpdateConfirm(props.showEdtConfirmOK);
    if (!props.showEdtConfirmOK) {
      updConfirmOk();
    }
  };

  // 破棄ボタンの押下
  const discardData = () => {
    //setCancelConfirm(true);
    setCancelConfirm(props.showEdtConfirmCancle);
    if (!props.showEdtConfirmCancle) {
      discardConfirmOk();
    }
  };

  // 更新メッセージのOKボタンを押下
  const updConfirmOk = () => {
    setUpdateConfirm(false);
    props?.updateProcessPatternList(patternformRef.current.getUpdatePatternInfo());
    document.getElementById('update').style.display = 'none';
    document.getElementById('discard').style.display = 'none';
    document.getElementById('edit').style.display = 'block';
  };

  // 更新メッセージのCancelボタンを押下
  const updConfirmCancel = () => {
    setUpdateConfirm(false);
  };

  // 破棄メッセージのOKボタンを押下
  const discardConfirmOk = () => {
    props?.updateProcessPatternList(selectedData);
    setCancelConfirm(false);
    props?.updateEditMode(false);
    document.getElementById('update').style.display = 'none';
    document.getElementById('discard').style.display = 'none';
    document.getElementById('edit').style.display = 'block';
  };

  // 破棄メッセージのCancelボタンを押下
  const discardConfirmCancel = () => {
    setCancelConfirm(false);
  };

  const updateSelectedData = (selectedProcessPattern) => {
    props?.updateSelectedData(selectedProcessPattern);
  };

  // 画面の初期設定
  useEffect(() => {
    setSelectedData(props.selectedData);
    setKey(props.selectedData?.key);
    props?.updateEditMode(false);
  }, [props.selectedData]);

  return (
    <>
      <Form>
        <div className="d-flex" style={{ marginTop: 7 }}>
          <div>
            <Button
              style={{ marginLeft: 10, display: 'none', fontSize: '13.5px' }}
              className="mainButton"
              id="update"
              onClick={updateData}
            >
              更新
            </Button>
          </div>
          <div>
            <Button
              style={{ marginLeft: 10, display: 'none', fontSize: '13.5px' }}
              className="cancelButton"
              id="discard"
              onClick={discardData}
            >
              破棄
            </Button>
          </div>
          <div className="ms-auto">
            <Button
              style={{ marginRight: 20, fontSize: '13.5px', display: props.isAdd !== undefined ? 'none' : 'block' }}
              className={
                props.selectedData != undefined && props.selectedData?.length == 0 ? 'disabledButton' : 'mainButton'
              }
              id="edit"
              onClick={editData}
            >
              編集
            </Button>
          </div>
        </div>

        <div>
          <ProcessPatternForm
            ref={patternformRef}
            selectedData={selectedData}
            listData={props.listData}
            processMasterData={props.processMasterData}
            deviceMasterData={props.deviceMasterData}
            clientMasterData={props.clientMasterData}
            editMode={editMode}
            page={location.pathname?.includes('parent') ? patternPage[0] : patternPage[1]}
            isParameter={true}
            isAdd={props.isAdd}
            updateSelectedData={updateSelectedData}
          />
        </div>
      </Form>
      {/* 更新確認_Modal */}
      <Modal
        title={
          <div
            style={{
              width: 400,
              backgroundColor: '#005fab',
              marginLeft: -24,
              height: 36,
              marginTop: -20,
              borderTopLeftRadius: 7,
              borderTopRightRadius: 7,
              fontSize: 14,
            }}
          >
            <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>確認</p>
          </div>
        }
        closeIcon={<CloseOutlined style={{ color: 'white' }} />}
        width={400}
        open={updateConfirm}
        onOk={updConfirmOk}
        onCancel={updConfirmCancel}
        footer={[
          <Button key="submit21" onClick={updConfirmOk} className="mainButton">
            OK
          </Button>,
          <Button key="back" onClick={updConfirmCancel} className="cancelButton">
            キャンセル
          </Button>,
        ]}
      >
        <p>データを更新します。よろしいでしょうか。</p>
      </Modal>

      {/* 破棄確認_Modal */}
      <Modal
        title={
          <div
            style={{
              width: 400,
              backgroundColor: '#005fab',
              marginLeft: -24,
              height: 36,
              marginTop: -20,
              borderTopLeftRadius: 7,
              borderTopRightRadius: 7,
              fontSize: 14,
            }}
          >
            <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>確認</p>
          </div>
        }
        closeIcon={<CloseOutlined style={{ color: 'white' }} />}
        width={400}
        open={cancelConfirm}
        onOk={discardConfirmOk}
        onCancel={discardConfirmCancel}
        footer={[
          <Button key="submit22" onClick={discardConfirmOk} className="mainButton">
            OK
          </Button>,
          <Button key="back" onClick={discardConfirmCancel} className="cancelButton">
            キャンセル
          </Button>,
        ]}
      >
        <p>データを破棄します。よろしいでしょうか。</p>
      </Modal>
    </>
  );
});

export default ProcessPatternDetails;
