import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Table } from 'react-bootstrap';
import { Row, Select, Input, Checkbox } from 'antd';
import { PlusCircleFilled, CloseCircleFilled } from '@ant-design/icons';

import { getNameById } from '../common/Common';
import { WorkType, WorkTypeGroup } from '../common/enums';

const IQ3KouteiInput_2Jikakou = forwardRef((props, ref) => {
  const [listData, setListData] = useState([]);

  const [workTypes, setWorkTypes] = useState([]);
  // 編集モード
  // const editMode = props.editMode;

  useEffect(() => {
    const processes = props.processMaster;
    const secondaryDetails = processes?.filter((i) => i.workType === WorkType.SmSecondaryWork);
    if (secondaryDetails?.length > 0) {
      let types = secondaryDetails[0].details?.processDetailTypes?.filter(
        (i) => i.group === WorkTypeGroup.SmSecondaryWorkSize || i.group === WorkTypeGroup.SmSecondaryWorkMillimeter
      );
      setWorkTypes(types);
    }

    let data = props.secondaryWorkInfo;
    if (data != undefined) {
      setListData(data.details);
    }
  }, [props.secondaryWorkInfo]);

  useImperativeHandle(ref, () => ({
    setEditModeChange: setEditModeChange,
  }));
  const addRow = (id) => {
    const copyData = listData?.slice(); // Create a copy of the original array

    const insertIndex = listData?.findIndex((item) => item.id === id); // Find the clicked index of the item
    const largestId = listData?.reduce((maxId, item) => {
      return item.id > maxId ? item.id : maxId;
    }, 0);

    if (insertIndex !== -1) {
      const data = {
        id: largestId + 1,
        types: copyData[insertIndex].types,
        diameter: copyData[insertIndex].diameter === null ? 0 : copyData[insertIndex].diameter,
        xsize: copyData[insertIndex].xsize === null ? 0 : copyData[insertIndex].xsize,
        ysize: copyData[insertIndex].ysize === null ? 0 : copyData[insertIndex].ysize,
        perimeter: copyData[insertIndex].perimeter === null ? 0 : copyData[insertIndex].perimeter,
        count: copyData[insertIndex].count === null ? 1 : copyData[insertIndex].count,
        blankFlag: copyData[insertIndex].blankFlag,
        workTypeGroup: copyData[insertIndex].workTypeGroup,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setListData(copyData);
      const newSecondaryWorkInfo = { ...props.secondaryWorkInfo };
      newSecondaryWorkInfo.details = copyData;
      props.updateSecondaryWork(newSecondaryWorkInfo);
    }
  };
  const deleteRow = (id) => {
    if (listData?.length > 1) {
      const updatedData = listData?.filter((item) => item.id !== id);

      setListData(updatedData);
      const newSecondaryWorkInfo = { ...props.secondaryWorkInfo };
      newSecondaryWorkInfo.details = updatedData;
      props.updateSecondaryWork(newSecondaryWorkInfo);
    }
  };

  const changeDetails = (event, id, types) => {
    const temp = JSON.parse(JSON.stringify(listData));
    let updatedData = [];
    if (types === 'types') {
      updatedData = temp?.map((row) => {
        if (row.id === id) {
          const updatedRow = { ...row, [types]: event };
          const getGroup = workTypes?.filter((i) => i.id === event);
          updatedRow.workTypeGroup = getGroup ? getGroup[0].group : 0;
          return updatedRow;
          // return { ...row, [types]: event };
        }
        return row;
      });
      // setListData(updatedData);
    } else if (types === 'blankFlag') {
      updatedData = temp?.map((row) => {
        if (row.id === id) {
          return { ...row, [types]: event.target.checked };
        }
        return row;
      });
      // setListData(updatedData);
    } else {
      updatedData = temp?.map((row) => {
        if (row.id === id) {
          return { ...row, [types]: event.target.value };
        }
        return row;
      });
      // setListData(updatedData);
    }
    setListData(updatedData);
    const newSecondaryWorkInfo = { ...props.secondaryWorkInfo };
    newSecondaryWorkInfo.details = updatedData;
    props.updateSecondaryWork(newSecondaryWorkInfo);
  };
  return (
    <>
      <Row className="mt-2 registerModal">
        <Table className="kouteiinput " style={{ width: '60.9%' }}>
          <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
            <tr style={{ textAlign: 'center' }}>
              <th style={{ width: '3%' }} className="tbl-header">
                No.
              </th>
              <th style={{ width: '18%' }} className="tbl-header">
                種類.形状
              </th>
              <th style={{ width: '11%' }} className="tbl-header">
                Mサイズ・径(mm)
              </th>
              {/* <th style={{ width: '11%' }} className="tbl-header">
                Xサイズ(mm)
              </th>
              <th style={{ width: '11%' }} className="tbl-header">
                Yサイズ(mm)
              </th>
              <th style={{ width: '11%' }} className="tbl-header">
                周長(mm)
              </th> */}
              <th style={{ width: '11%' }} className="tbl-header">
                数
              </th>
              <th style={{ width: '11%' }} className="tbl-header">
                ブランク
              </th>
            </tr>
          </thead>
          <tbody>
            {listData?.map((i, index) => (
              <tr>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{index + 1}</td>
                <td style={{ verticalAlign: 'middle' }}>
                  {/* {editMode ? (
                    <> */}
                  <Select
                    defaultValue={1}
                    size="middle"
                    value={i.types}
                    onChange={(e) => changeDetails(e, i.id, 'types')}
                    style={{ width: '100%' }}
                  >
                    {workTypes?.map((option) => (
                      <Select.Option value={option.id}>{option.name}</Select.Option>
                    ))}
                  </Select>
                  {/* </>
                  ) : (
                    <>{getNameById(i.types, types)}</>
                  )} */}
                </td>

                {/* <td>
                  <Input
                    className={editMode ? 'input-editable ' : 'input-non-editable '}
                    value={i.xsize}
                    onChange={(e) => changeDetails(e, i.id, 'xsize')}
                    style={{ height: 32, textAlign: 'center' }}
                  ></Input>
                </td>
                <td>
                  <Input
                    className={editMode ? 'input-editable ' : 'input-non-editable '}
                    value={i.ysize}
                    onChange={(e) => changeDetails(e, i.id, 'ysize')}
                    style={{ height: 32, textAlign: 'center' }}
                  ></Input>
                </td>
                <td>
                  <Input
                    className={editMode ? 'input-editable ' : 'input-non-editable '}
                    value={i.perimeter}
                    onChange={(e) => changeDetails(e, i.id, 'perimeter')}
                    style={{ height: 32, textAlign: 'center' }}
                  ></Input>
                </td> */}
                <td>
                  <Input
                    // className={editMode ? 'input-editable ' : 'input-non-editable '}
                    className="input-editable"
                    value={i.diameter}
                    onChange={(e) => changeDetails(e, i.id, 'diameter')}
                    style={{ height: 32, textAlign: 'center' }}
                  ></Input>
                </td>
                <td>
                  <Input
                    // className={editMode ? 'input-editable ' : 'input-non-editable '}
                    className="input-editable"
                    value={i.count}
                    onChange={(e) => changeDetails(e, i.id, 'count')}
                    style={{ height: 32, textAlign: 'center' }}
                  ></Input>
                </td>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  <Checkbox
                    checked={i.blankFlag}
                    value={i.blankFlag}
                    onChange={(e) => changeDetails(e, i.id, 'blankFlag')}
                    // style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                  ></Checkbox>
                </td>
                {/* {editMode ? (
                  <> */}
                <td style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '6%' }}>
                  <Row justify="center">
                    <PlusCircleFilled
                      className="add-remove-icon"
                      onClick={() => addRow(i.id)}
                      style={{ marginLeft: 5, marginRight: 5 }}
                    />
                    <CloseCircleFilled
                      className="add-remove-icon"
                      onClick={() => deleteRow(i.id)}
                      // style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                    />
                  </Row>
                </td>
                {/* </>
                ) : (
                  <>
                    <td style={{ border: 'none', width: '6%' }}></td>
                  </>
                )} */}
              </tr>
            ))}
          </tbody>
        </Table>
      </Row>
    </>
  );
});

export default IQ3KouteiInput_2Jikakou;
