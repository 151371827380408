import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { BsUsbC, BsX } from 'react-icons/bs';
import { Row, Col, Input, Select, Collapse, Checkbox, Space, Popover } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';

import '../../assets/styles/common.css';
import {
  handelIncreaseTime,
  handelDecreaseTime,
  checkInput,
  setTimetoSec,
  secondsToHms,
  checkInputWithColor,
  isNumber,
  checkShowHideIcon,
  JPYs,
  kouteiCalculateSelectedArr,
  handleShowHide,
} from '../common/Common.js';
import { DataType, ServiceClass, WorkType } from '../common/enums';
import { amtEmptyStr, timeHmsStr } from '../common/Constant';

const handleCollpse = (id) => {
  if (document.getElementById('tbl' + id) !== null) {
    let showHide = document.getElementById('tbl' + id)?.style.display;
    if (showHide == 'block') {
      document.getElementById('tbl' + id).style.display = 'none';
      document.getElementById('arrowUp' + id).style.display = 'none';
      document.getElementById('arrowDown' + id).style.display = 'block';
    } else {
      document.getElementById('tbl' + id).style.display = 'block';
      document.getElementById('arrowUp' + id).style.display = 'block';
      document.getElementById('arrowDown' + id).style.display = 'none';
    }
  }
};

const contentTotal = (
  <div className="customPopover">
    <div className="totalDandori">
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row">
        <Col span={24} className="deli-col" onClick={(e) => handleCollpse('TotalDandori')}>
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>合計金額</label>
            <CaretUpOutlined id={'arrowUp' + 'TotalDandori'} style={{ fontSize: 17, marginTop: 2, display: 'none' }} />
            <CaretDownOutlined id={'arrowDown' + 'TotalDandori'} style={{ fontSize: 17, marginTop: 2 }} />
          </div>
        </Col>
      </Row>
      <div id={'tbl' + 'TotalDandori'} style={{ display: 'none' }}>
        <Row style={{ color: 'black', display: 'inherit' }} className="oyabuhin-detail oyabuhin-row ">
          <label style={{ marginRight: 5 }}>¥4,200 / 3600 * 7 = 8.1667</label>
        </Row>
      </div>
    </div>
    <div className="totalKakou">
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row">
        <Col span={24} className="deli-col" onClick={(e) => handleCollpse('totalKakou')}>
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>加工金額</label>
            <CaretUpOutlined id={'arrowUp' + 'totalKakou'} style={{ fontSize: 17, marginTop: 2, display: 'none' }} />
            <CaretDownOutlined id={'arrowDown' + 'totalKakou'} style={{ fontSize: 17, marginTop: 2 }} />
          </div>
        </Col>
      </Row>
      <div id={'tbl' + 'totalKakou'} style={{ display: 'none' }}>
        <Row style={{ color: 'black', display: 'inherit' }} className="oyabuhin-detail oyabuhin-row ">
          <label style={{ marginRight: 5 }}>¥4,200 / 3600 * 00:00:20 * 30 * 1.00 = 700</label>
        </Row>
      </div>
    </div>
    <div className="totalKeisan">
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row">
        <Col span={24} className="deli-col" onClick={(e) => handleCollpse('totalKeisan')}>
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>加工金額</label>
            <CaretUpOutlined id={'arrowUp' + 'totalKeisan'} style={{ fontSize: 17, marginTop: 2, display: 'none' }} />
            <CaretDownOutlined id={'arrowDown' + 'totalKeisan'} style={{ fontSize: 17, marginTop: 2 }} />
          </div>
        </Col>
      </Row>
      <div id={'tbl' + 'totalKeisan'} style={{ display: 'none' }}>
        <Row style={{ color: 'black', display: 'inherit' }} className="oyabuhin-detail oyabuhin-row ">
          <label style={{ marginRight: 5 }}>¥8.1667 + ¥700 = ¥708.1667 ≒ ¥709</label>
        </Row>
      </div>
    </div>
    <div className="footer">
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row"></Row>
    </div>
  </div>
);

const contentDandori = (
  <div className="customPopover">
    <div className="DandoriTime">
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row">
        <Col span={24} className="deli-col" onClick={(e) => handleCollpse('DandoriTime')}>
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>段取金額</label>
            <CaretUpOutlined id={'arrowUp' + 'DandoriTime'} style={{ fontSize: 17, marginTop: 2, display: 'none' }} />
            <CaretDownOutlined id={'arrowDown' + 'DandoriTime'} style={{ fontSize: 17, marginTop: 2 }} />
          </div>
        </Col>
      </Row>
      <div id={'tbl' + 'DandoriTime'} style={{ display: 'none' }}>
        <Row style={{ color: 'black', display: 'inherit' }} className="oyabuhin-detail oyabuhin-row ">
          <label style={{ marginRight: 5 }}>¥4,200 / 3600 * 7 = 8.1667</label>
        </Row>
      </div>
    </div>
    <div className="DandoriAmt">
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row">
        <Col span={24} className="deli-col" onClick={(e) => handleCollpse('DandoriAmt')}>
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>加工金額</label>
            <CaretUpOutlined id={'arrowUp' + 'DandoriAmt'} style={{ fontSize: 17, marginTop: 2, display: 'none' }} />
            <CaretDownOutlined id={'arrowDown' + 'DandoriAmt'} style={{ fontSize: 17, marginTop: 2 }} />
          </div>
        </Col>
      </Row>
      <div id={'tbl' + 'DandoriAmt'} style={{ display: 'none' }}>
        <Row style={{ color: 'black', display: 'inherit' }} className="oyabuhin-detail oyabuhin-row ">
          <label style={{ marginRight: 5 }}>¥4,200 / 3600 * 00:00:20 * 30 * 1.00 = 700</label>
        </Row>
      </div>
    </div>

    <div className="footer">
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row"></Row>
    </div>
  </div>
);

const contentKakou = (
  <div className="customPopover">
    <div className="DandoriTime">
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row">
        <Col span={24} className="deli-col" onClick={(e) => handleCollpse('DandoriTime')}>
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>段取金額</label>
            <CaretUpOutlined id={'arrowUp' + 'DandoriTime'} style={{ fontSize: 17, marginTop: 2, display: 'none' }} />
            <CaretDownOutlined id={'arrowDown' + 'DandoriTime'} style={{ fontSize: 17, marginTop: 2 }} />
          </div>
        </Col>
      </Row>
      <div id={'tbl' + 'DandoriTime'} style={{ display: 'none' }}>
        <Row style={{ color: 'black', display: 'inherit' }} className="oyabuhin-detail oyabuhin-row ">
          <label style={{ marginRight: 5 }}>¥4,200 / 3600 * 7 = 8.1667</label>
        </Row>
      </div>
    </div>
    <div className="DandoriAmt">
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row">
        <Col span={24} className="deli-col" onClick={(e) => handleCollpse('DandoriAmt')}>
          <div style={{ display: 'flex' }}>
            <label style={{ marginRight: 5 }}>加工金額</label>
            <CaretUpOutlined id={'arrowUp' + 'DandoriAmt'} style={{ fontSize: 17, marginTop: 2, display: 'none' }} />
            <CaretDownOutlined id={'arrowDown' + 'DandoriAmt'} style={{ fontSize: 17, marginTop: 2 }} />
          </div>
        </Col>
      </Row>
      <div id={'tbl' + 'DandoriAmt'} style={{ display: 'none' }}>
        <Row style={{ color: 'black', display: 'inherit' }} className="oyabuhin-detail oyabuhin-row ">
          <label style={{ marginRight: 5 }}>¥4,200 / 3600 * 00:00:20 * 30 * 1.00 = 700</label>
        </Row>
      </div>
    </div>

    <div className="footer">
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row"></Row>
    </div>
  </div>
);

const IQ3KouteiCalculate_Program = forwardRef((props, ref) => {
  const [program, setProgram] = useState(false);
  const [workAmountIn, setWorkAmountIn] = useState(0);
  const [dandoriAmountIn, setDandoriAmountIn] = useState(0);
  const [dantoriTime, setDantoriTime] = useState('');
  const [workTime, setWorkTime] = useState('');
  const [eleCount, setElecount] = useState(0);
  const [oldEleCount, setOldEleCount] = useState(0);
  const [totalAmt, setTotalAmt] = useState(0);
  const [totalTime, setTotalTime] = useState('');
  const [totalSum, setTotalSum] = useState('');
  const [totalTimeSum, setTotalTimeSum] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [orgProgramData, setOrgProgramData] = useState([]);
  const [edtProgramData, setEdtProgramData] = useState([]);
  const [selectedDataFlg, setSelectedDataFlg] = useState(0);
  const [naibuProcessValidationAmt, setNaibuProcessValidationAmt] = useState('');
  const [naibuProcessValidationTime, setNaibuProcessValidationTime] = useState('');
  const [patternInfo, setPatternInfo] = useState([]);
  const [deviceMst, setDeviceMst] = useState([]);
  const [processMst, setProcessMst] = useState([]);
  const [updateEditedData, setUpdateEditedData] = useState([]);
  const [programSum, setProgramSum] = useState('');

  const createEdtProgramData = (objID, valueE) => {
    let prog = {
      id: orgProgramData.id,
      programName: orgProgramData.programName,
      dandoriAmt: dandoriAmountIn,
      dandoriTime: dantoriTime,
      sagyoAmt: workAmountIn,
      sagyoTime: workTime,
      eleCount: eleCount,
    };

    if (objID !== undefined) {
      prog[objID] = valueE;
    }
    setEdtProgramData(prog);
    return prog;
  };

  useEffect(() => {
    let processMst = [];
    let deviceMst = []; //テスト用ID;
    let editData = [];
    let parameters = props?.selectedEstimateData?.estimateProducts?.calcParameters?.parameters;
    if (parameters?.process?.length > 0) {
      processMst = parameters?.process?.filter((item) => item?.class === ServiceClass.SheetMetal);
      processMst = processMst ? processMst?.filter((item) => item.workType === WorkType.SmProgram)[0] : [];
      setProcessMst(processMst ? processMst : []);
      if (parameters?.device?.length > 0) {
        deviceMst = parameters?.device?.filter((item) => processMst?.id == item?.processId);
      }
    }

    let patternInfo = props?.selectedIQ3DataDetail?.processSelect
      ? props?.selectedIQ3DataDetail?.processSelect?.details?.filter((item) => item.processId == processMst?.id)
      : [];
    setPatternInfo([]);
    if (patternInfo == undefined) return;
    if (patternInfo?.length > 0 && patternInfo[0]?.isUsedForCalc == false) return;

    deviceMst =
      deviceMst && patternInfo
        ? deviceMst?.filter(
            (item) => item.processId == patternInfo[0]?.processId && item.id == patternInfo[0]?.deviceId
          )[0]
        : []; //テスト用ID;
    setDeviceMst(deviceMst ? deviceMst : []);
    if (deviceMst == undefined) return;
    setPatternInfo(patternInfo);
    let programAdditions = props?.selectedIQ3DataDetail?.additions?.filter(
      (item) => item.processId == processMst?.id
    )[0];
    // プログラムの工程入力情報取得
    let programProcessInput = props?.selectedIQ3DataDetail?.processInput?.filter(
      (item) => item.processId == processMst?.id
    );
    // 新規の場合
    if (programAdditions?.dataItems?.length == 0 || programAdditions == undefined) {
      let detailItems = [];
      let editDetailItems = [];
      for (let j = 0; j < programProcessInput?.length; j++) {
        detailItems.push({
          id: 1,
          processName: processMst?.name,
          dandoriAmt: 0,
          dandoriTime: 0,
          sagyoAmt: 0,
          sagyoTime: 0,
          elementCnt: parseInt(programProcessInput?.[0]?.details?.[0]?.elements),
          formula: '',
        });
      }

      editDetailItems = [];
      let programAddition = {
        no: processMst?.id,
        processId: processMst?.id,
        dataType: DataType.Data,
        dataItems: detailItems,
        editItems: editDetailItems,
        totalDandori: {
          dataItemsAmt: 0,
          dataItemsTime: 0,
          editItemsAmt: 0,
          editItemsTime: 0,
        },
        totalSagyo: {
          dataItemsAmt: 0,
          dataItemsTime: 0,
          editItemsAmt: 0,
          editItemsTime: 0,
        },
        totalDataPrice: 0,
        totalDataTime: 0,
        totalEditPrice: 0,
        totalEditTime: 0,
      };
      programAdditions = programAddition;
    }

    //データ設定
    if (programAdditions?.dataItems) {
      let orgData = JSON.parse(JSON.stringify(programAdditions?.dataItems));
      setOrgProgramData(JSON.parse(JSON.stringify(programAdditions?.dataItems)));
      if (deviceMst != undefined) {
        //要素数
        let dataItems_elementCnt = parseInt(programProcessInput?.[0]?.details?.[0]?.elements);
        orgData[0].elementCnt = dataItems_elementCnt;
        /** 段取金額/時間 */
        // 初期段取時間
        let dandoriTimeSec = calDandoriTime(dataItems_elementCnt, deviceMst); //プログラム装置の初期段取時間を取得。
        let dandoriTimeHms = dandoriTimeSec;
        orgData[0].dandoriTime = dandoriTimeHms;
        programAdditions.dataItems[0].dandoriTime = dandoriTimeSec;
        // 段取金額設定
        //段階時間＊時間チャージ
        let dandoriAmt = calDandoriAmt(dandoriTimeSec, deviceMst);
        orgData[0].dandoriAmt = dandoriAmt;
        programAdditions.dataItems[0].dandoriAmt = dandoriAmt;
        /** 作業金額/時間 */
        // 作業時間
        //1要素当たりの作業時間＊要素数＊作業時間係数
        let cSagyoTimeSec = calSagyoTime(dataItems_elementCnt, deviceMst);
        let cSagyoTimeHms = cSagyoTimeSec;
        orgData[0].sagyoTime = cSagyoTimeHms;
        programAdditions.dataItems[0].sagyoTime = cSagyoTimeHms;
        // 作業金額
        //　作業時間＊時間チャージ
        let cSagyoAmt = calSagyoAmt(cSagyoTimeSec, deviceMst);
        orgData[0].sagyoAmt = cSagyoAmt;
        programAdditions.dataItems[0].sagyoAmt = cSagyoAmt;

        setTotalSum(orgData[0]?.dandoriAmt + orgData[0]?.sagyoAmt);
        programAdditions.totalDandori.dataItemsAmt = orgData[0]?.dandoriAmt;
        programAdditions.totalSagyo.dataItemsAmt = orgData[0]?.sagyoAmt;
        programAdditions.totalDataPrice = orgData[0]?.dandoriAmt + orgData[0]?.sagyoAmt;

        setTotalTimeSum(orgData[0]?.dandoriTime + orgData[0]?.sagyoTime);
        programAdditions.totalDandori.dataItemsTime = orgData[0]?.dandoriTime;
        programAdditions.totalSagyo.dataItemsTime = orgData[0]?.sagyoTime;
        programAdditions.totalDataTime = orgData[0]?.dandoriTime + orgData[0]?.sagyoTime;

        setOrgProgramData(orgData);
        if (programAdditions?.dataType == DataType.Edit) {
          editData = JSON.parse(JSON.stringify(programAdditions?.editItems));
        } else {
          editData = JSON.parse(JSON.stringify(orgData));
        }
      }
    } else {
      setWorkAmountIn(0);
      setDandoriAmountIn(0);
      setDantoriTime('00:00:00');
      setWorkTime('00:00:00');
      setElecount('00:00:00');
      setTotalAmt(0);
      setTotalTime('00:00:00');
      setOrgProgramData(0);
      setNaibuProcessValidationAmt(0);
      setNaibuProcessValidationTime('00:00:00');
    }
    if (editData != undefined && editData?.length > 0) {
      setWorkAmountIn(editData[0]?.sagyoAmt);
      setDandoriAmountIn(editData[0]?.dandoriAmt);
      setDantoriTime(editData[0]?.dandoriTime);
      setWorkTime(editData[0]?.sagyoTime);
      setElecount(editData[0]?.elementCnt);
      setTotalAmt(editData[0]?.dandoriAmt + editData[0]?.sagyoAmt);
      programAdditions.editItems = editData;
      programAdditions.totalEditPrice = editData[0]?.dandoriAmt + editData[0]?.sagyoAmt;
      programAdditions.totalDandori.editItemsAmt = editData[0]?.dandoriAmt;
      programAdditions.totalSagyo.editItemsAmt = editData[0]?.sagyoAmt;
      setTotalTime(editData[0]?.dandoriTime + editData[0]?.sagyoTime);
      programAdditions.totalEditTime = editData[0]?.dandoriTime + editData[0]?.sagyoTime;
      programAdditions.totalDandori.editItemsTime = editData[0]?.dandoriTime;
      programAdditions.totalSagyo.editItemsTime = editData[0]?.sagyoTime;

      // setOrgProgramData(editData);
      setEdtProgramData(JSON.parse(JSON.stringify(editData)));
    }
    setProgramSum(
      programAdditions?.dataType == DataType.Data ? programAdditions.totalDataPrice : programAdditions.totalEditPrice
    );
    setUpdateEditedData(programAdditions);
    props?.updateIQ3KouteiCal(programAdditions, WorkType.SmProgram);
  }, [props?.selectedEstimateData, props?.activeTabKey]);

  useEffect(() => {
    setEditMode(props?.editMode != undefined ? props?.editMode : false);
  }, [props?.editMode]);

  useEffect(() => {
    createEdtProgramData();
  }, [totalAmt, totalTime]);

  const calDandoriTime = (elementCnt, deviceMst) => {
    if (elementCnt == 0) return 0;
    // 段取時間設定
    let prepElementThMaster = deviceMst?.details?.prepElementThMaster?.filter(
      (item) => item.value == elementCnt || item.value > elementCnt
    );
    let prepElementThMasterCnt =
      prepElementThMaster?.length > 0 ? prepElementThMaster[0]?.no : deviceMst?.details?.prepElementThMaster[0]?.no;
    //　基本段取時間
    let dandoriTimeSec =
      prepElementThMasterCnt == 0
        ? 0
        : deviceMst?.details?.prepElementThItems?.filter((item) => item.no == prepElementThMasterCnt)[0]?.value;
    return dandoriTimeSec;
  };

  const calSagyoTime = (elementCnt, deviceMst) => {
    if (elementCnt == 0) return 0;
    // 作業時間
    //1要素当たりの作業時間
    let workBasicTime = deviceMst?.details?.workBasicTime;
    // 係数
    let otherElementCoeffThMaster = deviceMst?.details?.otherElementCoeffThMaster?.filter(
      (item) => item.value == elementCnt || item.value > elementCnt
    );
    let otherElementCoeffThMasterCnt =
      otherElementCoeffThMaster?.length > 0
        ? otherElementCoeffThMaster[0]?.no
        : deviceMst?.details?.otherElementCoeffThMaster[0]?.no;
    let workTimeElementCoeff =
      otherElementCoeffThMasterCnt == 0
        ? 0
        : deviceMst?.details?.workTimeElementCoeffThItems?.filter((item) => item.no == otherElementCoeffThMasterCnt)[0]
            ?.value;
    //1要素当たりの作業時間＊要素数＊作業時間係数
    let cSagyoTimeKensaSec = workBasicTime * elementCnt * workTimeElementCoeff;
    return cSagyoTimeKensaSec;
  };

  const calDandoriAmt = (dandoriTimeSec, deviceMst) => {
    //段階時間
    let mSetTimeMin = dandoriTimeSec / 60;
    //装置の段階時間チャージ
    let mSetHRate = deviceMst.details?.charges.prep;
    //段階時間＊時間チャージ
    let dandoriAmt = Math.floor((mSetTimeMin * mSetHRate) / 60.0);
    return dandoriAmt;
  };

  const calSagyoAmt = (cSagyoTimeSec, deviceMst) => {
    // 作業金額
    // 1部品当たりの作業時間
    let mWorkHTime = cSagyoTimeSec;
    let mWorkHTimeMin = mWorkHTime / 60;
    //装置の作業時間チャージ
    let mWorkHRate = deviceMst.details?.charges.work;
    //　加工時間＊時間チャージ
    let cSagyoAmtKensa = Math.floor((mWorkHTimeMin * mWorkHRate) / 60.0);
    return cSagyoAmtKensa;
  };

  const programDetail = () => {
    if (program == true) {
      setProgram(false);
    } else {
      setProgram(true);
    }
  };

  const setEditModeChange = (mode) => {
    setEditMode(mode);
  };
  useImperativeHandle(ref, () => ({
    setEditModeChange: setEditModeChange,
    getKouteiCalInfoProgram: () => {
      return edtProgramData;
    },
  }));

  const checkStr = (val) => {
    let intAmt;
    if (val === '') {
      intAmt = 0;
    } else if (typeof val === 'string') {
      intAmt = parseFloat(val.charAt(0) == '¥' ? val.replace('¥', '') : val);
    } else intAmt = val;
    return intAmt;
  };

  const handleTotalAmt = (v1, v2) => {
    v1 = checkStr(v1);
    v2 = checkStr(v2);
    let sum = v1 + v2;
    // checkInputWithColor(false,'','programTotalAmt', sum, 'green');
    setTotalAmt(sum);
    updateEditedData.totalEditPrice = sum;
  };

  const handleTotalTime = (v1, v2) => {
    v1 = Number(v1);
    v2 = Number(v2);
    let totalIncSec = v1 + v2;
    //let timeStr = secondsToHms(totalIncSec);
    // checkInputWithColor(false,'','programTotalTime', timeStr, 'green');
    updateEditedData.totalEditTime = totalIncSec;
    return totalIncSec;
  };

  const changeAmtwithTime = (time, typeTime, type, action) => {
    let editedAmt;
    let timeSec = Number(time);
    //let deviceMst = [];

    if (type == 'programWorkTime') {
      // 作業金額
      //　加工時間＊時間チャージ
      editedAmt = calSagyoAmt(timeSec, deviceMst);
      edtProgramData['sagyoAmt'] = editedAmt;
      updateEditedData.editItems[0].sagyoAmt = editedAmt;
      updateEditedData.totalSagyo.editItemsAmt = editedAmt;
      handleTotalAmt(editedAmt, dandoriAmountIn);
      if (document.getElementById('programWorkAmountCu').style.display !== undefined) {
        let showHideCu = document.getElementById('programWorkAmountCu').style.display;
        if (showHideCu === 'block') {
        } else {
          //checkInputWithColor(false,'','programWorkAmount', editedAmt, 'green');
        }
      }
    } else if (type == 'programDandoriTime') {
      // 段取金額
      //　段取時間＊時間チャージ
      editedAmt = calDandoriAmt(timeSec, deviceMst);
      setDandoriAmountIn(editedAmt);
      updateEditedData.editItems[0].dandoriAmt = editedAmt;
      updateEditedData.totalDandori.editItemsAmt = editedAmt;
      edtProgramData['dandoriAmt'] = editedAmt;
      handleTotalAmt(editedAmt, workAmountIn);
    }
    return editedAmt;
  };

  const changeAmtTimeByElementCnt = (eleCount) => {
    //let deviceMst = [];
    /** 作業金額/時間 */
    // 作業時間
    //1要素当たりの作業時間＊要素数＊作業時間係数
    let cSagyoTimeSec = 60 * eleCount * 0.9;
    //let cSagyoTimeHms = secondsToHms(cSagyoTimeSec);
    //checkInputWithColor(false, '', 'programWorkTime', cSagyoTimeHms, 'time');
    setWorkTime(cSagyoTimeSec);
    updateEditedData.editItems[0].sagyoTime = cSagyoTimeSec;
    updateEditedData.totalSagyo.editItemsTime = cSagyoTimeSec;
    // 作業金額
    //　作業時間＊時間チャージ
    let cSagyoAmt = calSagyoAmt(cSagyoTimeSec, deviceMst);
    setWorkAmountIn(cSagyoAmt);
    updateEditedData.editItems[0].sagyoAmt = cSagyoAmt;
    updateEditedData.totalSagyo.editItemsAmt = cSagyoAmt;
    handleTotalAmt(cSagyoAmt, dandoriAmountIn);
    setTotalTime(handleTotalTime(cSagyoTimeSec, dantoriTime));
  };

  const handleChangeCheckData = (e) => {
    const data = { ...updateEditedData };
    data.dataType = e;
    setUpdateEditedData(data);
    setProgramSum(e == DataType.Data ? data.totalDataPrice : data.totalEditPrice);
    props?.updateIQ3KouteiCal(data, WorkType.SmProgram);
  };

  return (
    <div className="kouteiCalculateDiv" id="kouteiCalculateDivID">
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row " onClick={programDetail}>
        <Col span={4} className="oyabuhin-col">
          <div style={{ display: 'flex' }}>
            {program ? (
              <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
            )}
            <label style={{ marginLeft: 5 }}>{processMst?.name}</label>
          </div>
        </Col>
        <Col span={6} className="oyabuhin-col">
          <div>{programSum ? JPYs.format(Math.floor(programSum)) : amtEmptyStr}</div>
        </Col>
        <Col span={13} style={{ border: 'none' }} className="oyabuhin-col">
          <div>
            <label style={{ marginLeft: 5 }}> {deviceMst?.name}</label>
          </div>
        </Col>
      </Row>
      <div style={{ display: program ? 'block' : 'none' }} id="testDiv">
        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col">
            工程詳細
          </Col>
          <Col span={6} className="oyabuhin-detail-col">
            <Checkbox
              key={kouteiCalculateSelectedArr[0].key}
              value={kouteiCalculateSelectedArr[0].value}
              checked={kouteiCalculateSelectedArr[0].key == updateEditedData.dataType}
              disabled={!props?.editMode}
              style={{ marginRight: 6 }}
              onChange={(e) => props?.editMode && handleChangeCheckData(kouteiCalculateSelectedArr[0].key)}
            ></Checkbox>
            <label>{kouteiCalculateSelectedArr[0].value}</label>
          </Col>
          <Col span={8} className="oyabuhin-detail-col">
            <Checkbox
              key={kouteiCalculateSelectedArr[1].key}
              value={kouteiCalculateSelectedArr[1].value}
              disabled={!props?.editMode}
              onChange={(e) => props?.editMode && handleChangeCheckData(kouteiCalculateSelectedArr[1].key)}
              checked={kouteiCalculateSelectedArr[1].key == updateEditedData.dataType}
              style={{ marginRight: 6 }}
            ></Checkbox>
            <label>{kouteiCalculateSelectedArr[1].value}</label>
          </Col>
          <Col span={1} className="oyabuhin-detail-col">
            単位
          </Col>
          <Col span={5} className="formula-column">
            計算式
          </Col>
        </Row>
        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col">
            {processMst?.name}
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="programTotalAmt">{totalSum ? JPYs.format(Math.floor(totalSum)) : amtEmptyStr}</label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            {' '}
            <label id="programTotalTime">{totalTimeSum ? secondsToHms(totalTimeSum) : timeHmsStr}</label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="programTotalAmtIn" style={{ color: totalAmt === totalSum ? 'black' : '#4cc12f' }}>
              {totalAmt ? JPYs.format(Math.floor(totalAmt)) : amtEmptyStr}
            </label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="programTotalTimeIn" style={{ color: totalTime === totalTimeSum ? 'black' : '#4cc12f' }}>
              {totalTime ? secondsToHms(totalTime) : timeHmsStr}
            </label>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={4} className="formula-column">
            {/* <div className="customPopover">
              <Popover title="合計金額" content={contentTotal} footer={<div>test</div>}>
                合計金額
              </Popover>
            </div> */}
          </Col>
        </Row>
        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col">
            段取金額/時間
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="programDandoriAmount">
              {orgProgramData[0]?.dandoriAmt ? JPYs.format(Math.floor(orgProgramData[0]?.dandoriAmt)) : amtEmptyStr}
            </label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="programDandoriTime">
              {orgProgramData[0]?.dandoriTime ? secondsToHms(orgProgramData[0]?.dandoriTime) : timeHmsStr}
            </label>
          </Col>
          <Col span={4} className="input-col">
            <CurrencyInput
              value={dandoriAmountIn ? Math.floor(dandoriAmountIn) : 0}
              id="programDandoriAmountIn"
              style={{
                border: 'none',
                color:
                  Math.floor(dandoriAmountIn) === Math.floor(orgProgramData[0]?.dandoriAmt)
                    ? 'black'
                    : checkShowHideIcon('programDandoriAmountCu') === 'none'
                    ? '#4cc12f'
                    : 'red',
              }}
              className={editMode ? 'input-editable currency' : 'input-non-editable currency'}
              prefix="¥"
              onValueChange={(e) => {
                if (dandoriAmountIn != e) {
                  if (e === undefined) {
                    e = 0;
                  }
                  setDandoriAmountIn(e);
                  updateEditedData.editItems[0].dandoriAmt = Number(e);
                  updateEditedData.totalDandori.editItemsAmt = Number(e);
                  checkInputWithColor(false, '', 'programDandoriAmount', e, 'amt');
                  handleTotalAmt(e, workAmountIn, naibuProcessValidationAmt);
                  //createEdtProgramData('dandoriAmt', e);
                  edtProgramData['dandoriAmt'] = e;
                  setProgramSum(
                    updateEditedData?.dataType == DataType.Data
                      ? updateEditedData.totalDataPrice
                      : updateEditedData.totalEditPrice
                  );
                  props?.updateIQ3KouteiCal(updateEditedData, WorkType.SmProgram);
                }
                ////setResetColor(false);
              }}
              decimalsLimit={100}
            />
            <div id={'programDandoriAmountCu'} style={{ display: 'none' }}>
              <div className="currencyIcon">
                <BsUsbC style={{ fontSize: '25' }} />
                <BsX style={{ margin: '-8px 0 0 16px' }} />
                <span class="tooltiptext">金額に変更がありましたので、以降時間と連携して自動計算されない。</span>
              </div>
            </div>
          </Col>
          <Col span={4} className="input-col">
            <Row style={{ width: '100%' }}>
              <Col className="timer-input-col">
                <Input
                  value={dantoriTime ? secondsToHms(dantoriTime) : timeHmsStr}
                  id="programDandoriTimeIn"
                  style={{
                    width: '100%',
                    color:
                      dantoriTime === orgProgramData[0]?.dandoriTime
                        ? handleShowHide(
                            parseFloat(dandoriAmountIn),
                            orgProgramData[0]?.dandoriAmt,
                            'programDandoriAmountCu'
                          )
                        : 'red',
                  }}
                  className={editMode ? 'input-editable ' : 'input-non-editable '}
                  onChange={(e) => {
                    if (checkShowHideIcon('programDandoriAmountCu') === 'none') {
                      setDandoriAmountIn(
                        changeAmtwithTime(
                          dandoriAmountIn,
                          'input',
                          'programDandoriTime',
                          'input',
                          setTimetoSec(e.target.value)
                        )
                      );
                    }
                    setTotalTime(handleTotalTime(setTimetoSec(e.target.value), workTime));
                    updateEditedData.editItems[0].dandoriTime = setTimetoSec(e.target.value);
                    updateEditedData.totalDandori.editItemsTime = setTimetoSec(e.target.value);
                    setDantoriTime(setTimetoSec(e.target.value));
                    edtProgramData['dandoriTime'] = setTimetoSec(e.target.value);
                    //createEdtProgramData('dandoriTime', e.target.value);
                    setProgramSum(
                      updateEditedData?.dataType == DataType.Data
                        ? updateEditedData.totalDataPrice
                        : updateEditedData.totalEditPrice
                    );
                    props?.updateIQ3KouteiCal(updateEditedData, WorkType.SmProgram);
                  }}
                ></Input>
              </Col>
              <Col className="timer-button-col">
                {/* <Space> */}
                {editMode ? (
                  <>
                    <CaretUpOutlined
                      style={{ fontSize: 17 + 'px' }}
                      onClick={() => {
                        let editedTime = handelIncreaseTime(dantoriTime, '10min', 'programDandoriTime');
                        setDantoriTime(editedTime);
                        if (checkShowHideIcon('programDandoriAmountCu') === 'none') {
                          setDandoriAmountIn(changeAmtwithTime(editedTime, '10min', 'programDandoriTime', 'inc'));
                        }
                        //setResetColor(false);
                        setTotalTime(handleTotalTime(editedTime, workTime));
                        updateEditedData.editItems[0].dandoriTime = editedTime;
                        updateEditedData.totalDandori.editItemsTime = editedTime;
                        edtProgramData['dandoriTime'] = editedTime;
                        setProgramSum(
                          updateEditedData?.dataType == DataType.Data
                            ? updateEditedData.totalDataPrice
                            : updateEditedData.totalEditPrice
                        );
                        props?.updateIQ3KouteiCal(updateEditedData, WorkType.SmProgram);
                      }}
                    />
                    <CaretDownOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let totalSec = Number(dantoriTime);
                        if (totalSec >= 600) {
                          let editedTime = handelDecreaseTime(dantoriTime, '10min', 'programDandoriTime');
                          setDantoriTime(editedTime);
                          if (checkShowHideIcon('programDandoriAmountCu') === 'none') {
                            setDandoriAmountIn(changeAmtwithTime(editedTime, '10min', 'programDandoriTime', 'dec'));
                          }
                          //setResetColor(false);
                          setTotalTime(handleTotalTime(editedTime, workTime));
                          updateEditedData.editItems[0].dandoriTime = editedTime;
                          updateEditedData.totalDandori.editItemsTime = editedTime;
                          edtProgramData['dandoriTime'] = editedTime;
                          setProgramSum(
                            updateEditedData?.dataType == DataType.Data
                              ? updateEditedData.totalDataPrice
                              : updateEditedData.totalEditPrice
                          );
                          props?.updateIQ3KouteiCal(updateEditedData, WorkType.SmProgram);
                        }
                      }}
                    />

                    <CaretUpOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let editedTime = handelIncreaseTime(dantoriTime, 'min', 'programDandoriTime');
                        setDantoriTime(editedTime);
                        if (checkShowHideIcon('programDandoriAmountCu') === 'none') {
                          setDandoriAmountIn(changeAmtwithTime(editedTime, 'min', 'programDandoriTime', 'inc'));
                        }
                        setTotalTime(handleTotalTime(editedTime, workTime));
                        updateEditedData.editItems[0].dandoriTime = editedTime;
                        updateEditedData.totalDandori.editItemsTime = editedTime;
                        edtProgramData['dandoriTime'] = editedTime;
                        setProgramSum(
                          updateEditedData?.dataType == DataType.Data
                            ? updateEditedData.totalDataPrice
                            : updateEditedData.totalEditPrice
                        );
                        props?.updateIQ3KouteiCal(updateEditedData, WorkType.SmProgram);
                      }}
                    />
                    <CaretDownOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let totalSec = Number(dantoriTime);
                        if (totalSec >= 60) {
                          let editedTime = handelDecreaseTime(dantoriTime, 'min', 'programDandoriTime');
                          setDantoriTime(editedTime);
                          if (checkShowHideIcon('programDandoriAmountCu') === 'none') {
                            setDandoriAmountIn(changeAmtwithTime(editedTime, 'min', 'programDandoriTime', 'dec'));
                          }
                          setTotalTime(handleTotalTime(editedTime, workTime));
                          updateEditedData.editItems[0].dandoriTime = editedTime;
                          updateEditedData.totalDandori.editItemsTime = editedTime;
                          edtProgramData['dandoriTime'] = editedTime;
                          setProgramSum(
                            updateEditedData?.dataType == DataType.Data
                              ? updateEditedData.totalDataPrice
                              : updateEditedData.totalEditPrice
                          );
                          props?.updateIQ3KouteiCal(updateEditedData, WorkType.SmProgram);
                        }
                      }}
                    />
                    <CaretUpOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let editedTime = handelIncreaseTime(dantoriTime, 'sec', 'programDandoriTime');
                        setDantoriTime(editedTime);
                        if (checkShowHideIcon('programDandoriAmountCu') === 'none') {
                          setDandoriAmountIn(changeAmtwithTime(editedTime, 'sec', 'programDandoriTime', 'inc'));
                        }
                        setTotalTime(handleTotalTime(editedTime, workTime));
                        updateEditedData.editItems[0].dandoriTime = editedTime;
                        updateEditedData.totalDandori.editItemsTime = editedTime;
                        edtProgramData['dandoriTime'] = editedTime;
                        setProgramSum(
                          updateEditedData?.dataType == DataType.Data
                            ? updateEditedData.totalDataPrice
                            : updateEditedData.totalEditPrice
                        );
                        props?.updateIQ3KouteiCal(updateEditedData, WorkType.SmProgram);
                      }}
                    />
                    <CaretDownOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let totalSec = Number(dantoriTime);
                        if (totalSec > 0) {
                          let editedTime = handelDecreaseTime(dantoriTime, 'sec', 'programDandoriTime');
                          setDantoriTime(editedTime);
                          if (checkShowHideIcon('programDandoriAmountCu') === 'none') {
                            setDandoriAmountIn(changeAmtwithTime(editedTime, 'sec', 'programDandoriTime', 'dec'));
                          }
                          setTotalTime(handleTotalTime(editedTime, workTime));
                          updateEditedData.editItems[0].dandoriTime = editedTime;
                          updateEditedData.totalDandori.editItemsTime = editedTime;
                          edtProgramData['dandoriTime'] = editedTime;
                          setProgramSum(
                            updateEditedData?.dataType == DataType.Data
                              ? updateEditedData.totalDataPrice
                              : updateEditedData.totalEditPrice
                          );
                          props?.updateIQ3KouteiCal(updateEditedData, WorkType.SmProgram);
                        }
                      }}
                    />
                  </>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={4} className="formula-column">
            {/* <div className="customPopover">
              <Popover title="段取金額" content={contentDandori} footer={<div>test</div>}>
                段取金額
              </Popover>
            </div> */}
          </Col>
        </Row>
        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col">
            作業金額/時間
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="programWorkAmount">
              {orgProgramData[0]?.sagyoAmt ? JPYs.format(Math.floor(orgProgramData[0]?.sagyoAmt)) : amtEmptyStr}
            </label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="programWorkTime">
              {orgProgramData[0]?.sagyoTime ? secondsToHms(orgProgramData[0]?.sagyoTime) : timeHmsStr}
            </label>
          </Col>
          <Col span={4} className="input-col">
            <CurrencyInput
              value={workAmountIn ? Math.floor(workAmountIn) : 0}
              id="programWorkAmountIn"
              style={{
                border: 'none',
                color:
                  workAmountIn === orgProgramData[0]?.sagyoAmt
                    ? 'black'
                    : checkShowHideIcon('programWorkAmountCu') === 'none'
                    ? '#4cc12f'
                    : 'red',
              }}
              className={editMode ? 'input-editable currency' : 'input-non-editable currency'}
              onValueChange={(e) => {
                if (workAmountIn != e) {
                  if (e === undefined) {
                    e = 0;
                  }
                  setWorkAmountIn(e);
                  updateEditedData.editItems[0].sagyoAmt = Number(e);
                  updateEditedData.totalSagyo.editItemsAmt = Number(e);
                  createEdtProgramData('sagyoAmt', e);
                  checkInputWithColor(false, '', 'programWorkAmount', e, 'amt');
                  handleTotalAmt(e, dandoriAmountIn, naibuProcessValidationAmt);
                  setProgramSum(
                    updateEditedData?.dataType == DataType.Data
                      ? updateEditedData.totalDataPrice
                      : updateEditedData.totalEditPrice
                  );
                  props?.updateIQ3KouteiCal(updateEditedData, WorkType.SmProgram);
                }
              }}
              prefix="¥"
              decimalsLimit={100}
            />
            <div id={'programWorkAmountCu'} style={{ display: 'none' }}>
              <div className="currencyIcon">
                <BsUsbC style={{ fontSize: '25' }} />
                <BsX style={{ margin: '-8px 0 0 16px' }} />
                <span class="tooltiptext">金額に変更がありましたので、以降時間と連携して自動計算されない。</span>
              </div>
            </div>
          </Col>
          <Col span={4} className="input-col">
            <Row style={{ width: '100%' }}>
              <Col className="timer-input-col">
                <Input
                  value={workTime ? secondsToHms(workTime) : timeHmsStr}
                  id="programWorkTimeIn"
                  style={{
                    width: '100%',
                    color:
                      workTime === orgProgramData[0]?.sagyoTime
                        ? 'black'
                        : eleCount !== oldEleCount
                        ? '#4cc12f'
                        : 'red',
                  }}
                  className={editMode ? 'input-editable ' : 'input-non-editable '}
                  onChange={(e) => {
                    if (checkShowHideIcon('programWorkAmountCu') === 'none') {
                      setDandoriAmountIn(
                        changeAmtwithTime(setTimetoSec(e.target.value), 'input', 'programWorkTime', 'input')
                      );
                    }
                    setOldEleCount(eleCount);
                    setTotalTime(handleTotalTime(setTimetoSec(e.target.value), dantoriTime));
                    setWorkTime(setTimetoSec(e.target.value));
                    updateEditedData.editItems[0].sagyoTime = setTimetoSec(e.target.value);
                    updateEditedData.totalSagyo.editItemsTime = setTimetoSec(e.target.value);

                    updateEditedData.editItems[0].sagyoTime = setTimetoSec(e.target.value);
                    createEdtProgramData('sagyoTime', setTimetoSec(e.target.value));
                    setProgramSum(
                      updateEditedData?.dataType == DataType.Data
                        ? updateEditedData.totalDataPrice
                        : updateEditedData.totalEditPrice
                    );
                    props?.updateIQ3KouteiCal(updateEditedData, WorkType.SmProgram);
                  }}
                ></Input>
              </Col>
              <Col className="timer-button-col">
                {/* <Space> */}
                {editMode ? (
                  <>
                    <CaretUpOutlined
                      style={{ fontSize: 17 + 'px' }}
                      onClick={(e) => {
                        let editedTime = handelIncreaseTime(workTime, '10min', 'programWorkTime');
                        setWorkTime(editedTime);

                        setOldEleCount(eleCount);
                        if (checkShowHideIcon('programWorkAmountCu') === 'none') {
                          setWorkAmountIn(changeAmtwithTime(editedTime, '10min', 'programWorkTime', 'inc'));
                          //createEdtProgramData('sagyoTime', e.target.value);
                        }
                        setTotalTime(handleTotalTime(editedTime, dantoriTime));
                        updateEditedData.editItems[0].sagyoTime = editedTime;
                        updateEditedData.totalSagyo.editItemsTime = editedTime;
                        createEdtProgramData('sagyoTime', editedTime);
                        setProgramSum(
                          updateEditedData?.dataType == DataType.Data
                            ? updateEditedData.totalDataPrice
                            : updateEditedData.totalEditPrice
                        );
                        props?.updateIQ3KouteiCal(updateEditedData, WorkType.SmProgram);
                      }}
                    />
                    <CaretDownOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={() => {
                        let totalSec = Number(workTime);
                        if (totalSec >= 600) {
                          let editedTime = handelDecreaseTime(workTime, '10min', 'programWorkTime');
                          setWorkTime(editedTime);

                          setOldEleCount(eleCount);
                          if (checkShowHideIcon('programWorkAmountCu') === 'none') {
                            setWorkAmountIn(changeAmtwithTime(editedTime, '10min', 'programWorkTime', 'dec'));
                          }
                          setTotalTime(handleTotalTime(editedTime, dantoriTime));
                          updateEditedData.editItems[0].sagyoTime = editedTime;
                          updateEditedData.totalSagyo.editItemsTime = editedTime;
                          createEdtProgramData('sagyoTime', editedTime);
                          setProgramSum(
                            updateEditedData?.dataType == DataType.Data
                              ? updateEditedData.totalDataPrice
                              : updateEditedData.totalEditPrice
                          );
                          props?.updateIQ3KouteiCal(updateEditedData, WorkType.SmProgram);
                        }
                      }}
                    />

                    <CaretUpOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={(e) => {
                        let editedTime = handelIncreaseTime(workTime, 'min', 'programWorkTime');
                        setWorkTime(editedTime);
                        setOldEleCount(eleCount);
                        if (checkShowHideIcon('programWorkAmountCu') === 'none') {
                          setWorkAmountIn(changeAmtwithTime(editedTime, 'min', 'programWorkTime', 'inc'));
                        }
                        setTotalTime(handleTotalTime(editedTime, dantoriTime));
                        updateEditedData.editItems[0].sagyoTime = editedTime;
                        updateEditedData.totalSagyo.editItemsTime = editedTime;
                        createEdtProgramData('sagyoTime', editedTime);
                        setProgramSum(
                          updateEditedData?.dataType == DataType.Data
                            ? updateEditedData.totalDataPrice
                            : updateEditedData.totalEditPrice
                        );
                        props?.updateIQ3KouteiCal(updateEditedData, WorkType.SmProgram);
                      }}
                    />
                    <CaretDownOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={(e) => {
                        let totalSec = Number(workTime);
                        if (totalSec >= 60) {
                          let editedTime = handelDecreaseTime(workTime, 'min', 'programWorkTime');
                          setWorkTime(editedTime);
                          setOldEleCount(eleCount);
                          if (checkShowHideIcon('programWorkAmountCu') === 'none') {
                            setWorkAmountIn(changeAmtwithTime(editedTime, 'min', 'programWorkTime', 'dec'));
                          }
                          setTotalTime(handleTotalTime(editedTime, dantoriTime));
                          updateEditedData.editItems[0].sagyoTime = editedTime;
                          updateEditedData.totalSagyo.editItemsTime = editedTime;
                          createEdtProgramData('sagyoTime', editedTime);
                          setProgramSum(
                            updateEditedData?.dataType == DataType.Data
                              ? updateEditedData.totalDataPrice
                              : updateEditedData.totalEditPrice
                          );
                          props?.updateIQ3KouteiCal(updateEditedData, WorkType.SmProgram);
                        }
                      }}
                    />
                    <CaretUpOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={(e) => {
                        let editedTime = handelIncreaseTime(workTime, 'sec', 'programWorkTime');
                        setWorkTime(editedTime);
                        setOldEleCount(eleCount);
                        if (checkShowHideIcon('programWorkAmountCu') === 'none') {
                          setWorkAmountIn(changeAmtwithTime(editedTime, 'sec', 'programWorkTime', 'inc'));
                        }
                        setTotalTime(handleTotalTime(editedTime, dantoriTime));
                        updateEditedData.editItems[0].sagyoTime = editedTime;
                        updateEditedData.totalSagyo.editItemsTime = editedTime;
                        createEdtProgramData('sagyoTime', editedTime);
                        setProgramSum(
                          updateEditedData?.dataType == DataType.Data
                            ? updateEditedData.totalDataPrice
                            : updateEditedData.totalEditPrice
                        );
                        props?.updateIQ3KouteiCal(updateEditedData, WorkType.SmProgram);
                      }}
                    />
                    <CaretDownOutlined
                      style={{ fontSize: 17 + 'px', color: '#000' }}
                      onClick={(e) => {
                        let totalSec = Number(workTime);
                        if (totalSec > 0) {
                          let editedTime = handelDecreaseTime(workTime, 'sec', 'programWorkTime');
                          setWorkTime(editedTime);
                          setOldEleCount(eleCount);
                          if (checkShowHideIcon('programWorkAmountCu') === 'none') {
                            setWorkAmountIn(changeAmtwithTime(editedTime, 'sec', 'programWorkTime', 'dec'));
                          }
                          setTotalTime(handleTotalTime(editedTime, dantoriTime));
                          updateEditedData.editItems[0].sagyoTime = editedTime;
                          updateEditedData.totalSagyo.editItemsTime = editedTime;
                          createEdtProgramData('sagyoTime', editedTime);
                          setProgramSum(
                            updateEditedData?.dataType == DataType.Data
                              ? updateEditedData.totalDataPrice
                              : updateEditedData.totalEditPrice
                          );
                          props?.updateIQ3KouteiCal(updateEditedData, WorkType.SmProgram);
                        }
                      }}
                    />
                  </>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={4} className="formula-column">
            {/* <div className="customPopover">
              <Popover title="加工金額" content={contentKakou} footer={<div>test</div>}>
                加工金額
              </Popover>
            </div> */}
          </Col>
        </Row>
        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col">
            要素数
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="programCount">{orgProgramData[0]?.elementCnt}</label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col"></Col>
          <Col span={4} className="input-col">
            <Input
              value={eleCount}
              id="programCountIn"
              style={{
                border: 'none',
                width: '45%',
                color: eleCount === orgProgramData[0]?.elementCnt ? 'black' : 'red',
              }}
              className={editMode ? 'input-editable' : 'input-non-editable '}
              onChange={(e) => {
                let eleCnt = Number(e.target.value);
                setOldEleCount(eleCount);
                setElecount(eleCnt);
                changeAmtTimeByElementCnt(eleCnt);
                createEdtProgramData('eleCount', eleCnt);
                updateEditedData.editItems[0].elementCnt = eleCnt;
                setProgramSum(
                  updateEditedData?.dataType == DataType.Data
                    ? updateEditedData.totalDataPrice
                    : updateEditedData.totalEditPrice
                );
                props?.updateIQ3KouteiCal(updateEditedData, WorkType.SmProgram);
              }}
            ></Input>
          </Col>
          <Col span={4} className="oyabuhin-detail-col"></Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={4} className="formula-column"></Col>
        </Row>
      </div>
    </div>
  );
});

export default IQ3KouteiCalculate_Program;
