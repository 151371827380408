import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';

import { Row, Input, Radio, Select, Button } from 'antd';
import { PlusCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { Tab, Table } from 'react-bootstrap';
import '../../assets/styles/common.css';
import ParentKouteiInput_ChildPartInfoSetting from './ParentKouteiInput_ChildPartInfoSetting';

const methods = ['面積', '重量'];

const methodsDetails = {
  面積: ['脱脂', 'メッキ'],
  重量: ['塗装(両面)', '塗装(片面)'],
};
const methodOptions = [
  { id: 1, value: 'area', label: '面積' },
  { id: 2, value: 'weight', label: '重量' },
];
const areaOptions = [
  { id: 1, value: 'dasshi', label: '脱脂' },
  { id: 2, value: 'mekki', label: 'メッキ' },
];
const weightOptions = [
  { id: 1, value: 'bothpaint', label: '塗装(両面)' },
  { id: 2, value: 'onesidepaint', label: '塗装(片面)' },
];

const platingoptions = [
  'ユニクロメッキ',
  'クロメート',
  'クロームメッキ',
  '亜鉛メッキ',
  'メッキ01',
  'メッキ02',
  'メッキ03',
];
const paintingoptions = ['メラミン塗装', 'アクリル塗装', 'ウレタン塗装', '粉体塗装'];
const paintingoptions2 = ['カチオン塗装', 'プライマー', '塗装01', '塗装02'];
const iQ3Data = [];
const iQ5Data = [];
const iQ7Data = [];
const childParts = [
  {
    key: 1,
    childKey: 1,
    parentName: `iQ3板金`,
    childType: 'iQ3',
    imgNm: 'Product-20230320 105141',
    childName: `Test-1`,
    itemNum: 2,
    quality: 'SPCC',
    thick: 1.6,
    area: 10,
    weight: 3,
    size: `200x100`,
    materialname: '丸バィフ',
    details: '25.0t-280.0',
  },
  {
    key: 2,
    childKey: 1,
    parentName: `iQ5形鋼`,
    childType: 'iQ5',
    imgNm: 'Product-20230320 105142',
    childName: `Test-1`,
    itemNum: 2,
    quality: 'SPCC',
    thick: 1.6,
    area: 10,
    weight: 3,
    size: `200x100`,
    materialname: '丸バィフ',
    details: '25.0t-280.0',
  },
  {
    key: 3,
    childKey: 1,
    parentName: `iQ7機械`,
    childType: 'iQ7',
    imgNm: 'Product-20230320 105143',
    childName: `Test-1`,
    itemNum: 2,
    quality: 'SPCC',
    thick: 1.6,
    area: 10,
    weight: 3,
    size: `200x100`,
    materialname: '丸バィフ',
    details: '25.0t-280.0',
  },
];
iQ3Data.push({
  key: 1,
  parentname: `iQ3板金`,
  childname: `Test-3`,
  surface: '100',
  weight: 10,
  quality: 'SPCC',
  thick: 1.6,
  status: '',
});
iQ5Data.push({
  key: 1,
  parentname: `iQ5形鋼`,
  childname: `Test-2`,
  surface: '100',
  weight: 10,
  quality: 'SPCC',
  thick: 1.6,
});
iQ7Data.push({
  key: 1,
  parentname: `iQ7機械`,
  childname: `Test-7`,
  surface: '100',
  weight: 10,
  quality: 'SPCC',
  thick: 1.6,
});
// let rowdata = [];
const rowdata = [
  {
    key: 1,
    areaChk: false,
    method: '面積',
    area: false,
    areaValue: 0,
    weightChk: false,
    weightValue: 0,
    dasshi: false,
    plat1: false,
    both: false,
    oneside: false,
    mekki: false,
    shorione: '',
    platRdoVal: '脱脂',
    paintRyomen: false,
    paintRyomenRdoVal: '',
    paintKatamen: false,
    paintKatamenRdoVal: '',
    totalcount: '',
    detailsBtn: 1,
    childParts: [],
    selectedChildPartCnt: 0,
    opttest: ['脱脂', 'メッキ'],
    rdooptions: [],
    total: 0.4778,
  },
  {
    key: 2,
    areaChk: false,
    method: '重量',
    area: false,
    areaValue: 0,
    weightChk: false,
    weightValue: 0,
    dasshi: false,
    plat1: false,
    both: false,
    oneside: false,
    mekki: false,
    shorione: '',
    platRdoVal: '塗装(両面)',
    paintRyomen: false,
    paintRyomenRdoVal: '',
    paintKatamen: false,
    paintKatamenRdoVal: '',
    totalcount: '',
    detailsBtn: 1,
    childParts: [],
    selectedChildPartCnt: 0,
    opttest: ['脱脂', 'メッキ'],
    rdooptions: paintingoptions,
    total: 0.9556,
  },
];

// const ParentKouteiInput_Hyoumenshori = forwardRef((props, ref) => {
//   // 表面処理追加
//   // const [rows, initRow] = useState([rowdata]);

//   // const [editMode, setEditMode] = useState(false);

//   const [curHyoumenshoriData, setCurHyoumenshoriData] = useState([]);
//   const [curDataIndex, setCurDataIndex] = useState(0);

//   // add new
//   const [addModal, setAddModal] = useState(false);

//   const [totalChildParts, setTotalChildParts] = useState(0);

//   const [hyoumenshoriDataLst, setHyoumenshoriDataLst] = useState([]);

//   // 編集モード
//   const editMode = props.editMode;

//   useEffect(() => {
//     setHyoumenshoriDataLst(props.test);
//     setTotalChildParts(60);
//   }, [props.rowdata, props.test]);

//   const methodhandleChange = (i, e, name) => {
//     const data = [...hyoumenshoriDataLst];
//     data[i]['method'] = e;
//     data[i][name] = e;
//     data[i]['opttest'] = methodsDetails[e];
//     data[i]['platRdoVal'] = methodsDetails[e][0];
//     if (data[i]['platRdoVal'] == '脱脂') {
//       data[i]['rdooptions'] = [];
//     } else if (data[i]['platRdoVal'] == 'メッキ') {
//       data[i]['rdooptions'] = platingoptions;
//     }
//     if (data[i]['platRdoVal'] == '塗装(両面)') {
//       data[i]['rdooptions'] = paintingoptions;
//     }
//     setHyoumenshoriDataLst(data);
//   };

//   const methodDetailsHandleChange = (i, e, name) => {
//     const data = [...hyoumenshoriDataLst];
//     data[i][name] = e;

//     if (data[i]['platRdoVal'] == '脱脂') {
//       data[i]['rdooptions'] = [];
//     } else if (data[i]['platRdoVal'] == 'メッキ') {
//       data[i]['rdooptions'] = platingoptions;
//     } else if (data[i]['platRdoVal'] == '塗装(両面)') {
//       data[i]['rdooptions'] = paintingoptions;
//     } else if (data[i]['platRdoVal'] == '塗装(片面)') {
//       data[i]['rdooptions'] = paintingoptions2;
//     }
//     setHyoumenshoriDataLst(data);
//   };
//   const addHyoumenshori = () => {
//     let i = hyoumenshoriDataLst.slice(-1)[0].key + 1;

//     const data = {
//       key: i,
//       areaChk: false,
//       method: '面積',
//       area: false,
//       areaValue: 0,
//       weightChk: false,
//       weightValue: 0,
//       dasshi: false,
//       plat1: false,
//       both: false,
//       oneside: false,
//       mekki: false,
//       shorione: '',
//       platRdoVal: '脱脂',
//       paintRyomen: false,
//       paintRyomenRdoVal: '',
//       paintKatamen: false,
//       paintKatamenRdoVal: '',
//       totalcount: 10,
//       detailsBtn: i,
//       childParts: [],
//       opttest: ['脱脂', 'メッキ'],
//       rdooptions: [],
//       selectedChildPartCnt: 0,
//       total: 0,
//     };
//     // initRow([...rows, data]);
//     let addArr = [...hyoumenshoriDataLst, data];
//     setHyoumenshoriDataLst(addArr);
//   };

//   const removeHyomenshori = (e, index) => {
//     if (hyoumenshoriDataLst?.length > 1) {
//       // const dataRow = [...hyoumenshoriDataLst];
//       // dataRow.splice(index, 1);
//       // setHyoumenshoriDataLst(dataRow);

//       setHyoumenshoriDataLst(
//         hyoumenshoriDataLst?.filter((j) => {
//           return ![e]?.includes(j.key);
//         })
//       );
//     }
//   };

//   const showModal = (index, item) => {
//     setCurHyoumenshoriData(item);
//     setCurDataIndex(index);

//     // setShorino(e.currentTarget.id);
//     setAddModal(true);
//   };

//   const updateChildPart = (selectedChildParts) => {
//     const iQ3Data = selectedChildParts?.filter((j) => {
//       return j.childType == 'iQ3';
//     });
//     const iQ5Data = selectedChildParts?.filter((j) => {
//       return j.childType == 'iQ5';
//     });
//     const iQ7Data = selectedChildParts?.filter((j) => {
//       return j.childType == 'iQ7';
//     });

//     let sum = selectedChildParts?.reduce(function (prev, current) {
//       return prev + +current.area;
//     }, 0);
//     let weightsum = selectedChildParts?.reduce(function (prev, current) {
//       return prev + +current.weight;
//     }, 0);
//     let total = 0;
//     if (curHyoumenshoriData.method == '面積') {
//       total = sum;
//     } else {
//       total = weightsum;
//     }
//     const updatedChildPart = {
//       key: curHyoumenshoriData.key,
//       childParts: selectedChildParts,

//       areaValue: sum,
//       weightValue: weightsum,
//       method: curHyoumenshoriData.method,
//       opttest: curHyoumenshoriData.opttest,
//       platRdoVal: curHyoumenshoriData.platRdoVal,
//       rdooptions: curHyoumenshoriData.rdooptions,
//       selectedChildPartCnt: parseInt(iQ3Data?.length) + parseInt(iQ5Data?.length) + parseInt(iQ7Data?.length),
//     };
//     const data = [...hyoumenshoriDataLst];
//     data[curDataIndex] = updatedChildPart;
//     setHyoumenshoriDataLst(data);
//     setAddModal(false);
//   };
//   const cancelChildPart = () => {
//     setAddModal(false);
//   };

//   const onChangePlating = (i, event) => {
//     const { id, name } = event.target;
//     const data = [...rows];
//     data[i][name] = event.target.value;
//     initRow(data);
//   };
//   const onChangePainting = (i, event) => {
//     const { id, name } = event.target;
//     const data = [...rows];
//     data[i][name] = event.target.value;

//     initRow(data);
//   };
//   const onChangePaintingoneside = (i, event) => {
//     const { id, name } = event.target;
//     const data = [...rows];
//     data[i][name] = event.target.value;
//     initRow(data);
//   };

//   const renderTb = hyoumenshoriDataLst?.map((rowsData, index) => {
//     const {
//       key,
//       areaChk,
//       method,
//       // weight,
//       areaValue,
//       area,
//       weightChk,
//       weightValue,
//       dasshi,
//       mekki,
//       plat1,
//       both,
//       oneside,
//       shorione,
//       platRdoVal,
//       paintRyomen,
//       paintRyomenRdoVal,
//       paintKatamen,
//       paintKatamenRdoVal,
//       totalcount,
//       detailsBtn,
//       selectedChildPartCnt,
//       opttest,
//       rdooptions,
//       childParts,
//       total,
//     } = rowsData;

//     return (
//       <>
//         <tr>
//           <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{key}</td>
//           <td style={{ verticalAlign: 'middle' }}>
//             {editMode ? (
//               <>
//                 <Select
//                   defaultValue={methods[0]}
//                   value={method}
//                   onChange={(event) => methodhandleChange(index, event, 'shorione')}
//                   style={{ pointerEvents: editMode ? 'auto' : 'none', width: '100%' }}
//                   options={methods?.map((method) => ({ label: method, value: method }))}
//                 />
//               </>
//             ) : (
//               <>{method}</>
//             )}
//           </td>
//           <td style={{ verticalAlign: 'middle', width: '17%' }}>
//             {editMode ? (
//               <>
//                 <Select
//                   style={{ width: '100%', pointerEvents: editMode ? 'auto' : 'none' }}
//                   value={platRdoVal}
//                   // onChange={onSecondCityChange}
//                   onChange={(event) => methodDetailsHandleChange(index, event, 'platRdoVal')}
//                   options={opttest?.map((i) => ({ label: i, value: i }))}
//                 ></Select>
//               </>
//             ) : (
//               <>{platRdoVal}</>
//             )}
//           </td>

//           <td style={{ verticalAlign: 'middle', width: '34%' }}>
//             <Radio.Group
//               value={rdooptions[0]}
//               options={rdooptions}
//               style={{ pointerEvents: editMode ? 'auto' : 'none' }}
//             ></Radio.Group>
//           </td>

//           <td style={{ verticalAlign: 'middle' }} className="min">
//             {method == '面積' ? (
//               <Input
//                 className={editMode ? 'input-editable ' : 'input-non-editable '}
//                 // value={areaValue}
//                 value={total}
//                 style={{
//                   textAlign: 'center',
//                   pointerEvents: 'none',
//                   border: 'none',
//                 }}
//               />
//             ) : (
//               <Input
//                 className={editMode ? 'input-editable ' : 'input-non-editable '}
//                 // value={weightValue}
//                 value={total}
//                 style={{
//                   textAlign: 'center',
//                   pointerEvents: 'none',
//                   border: 'none',
//                 }}
//               />
//             )}
//           </td>

//           <td style={{ verticalAlign: 'middle', textAlign: 'center' }} className="min">
//             <Row justify="center">
//               {selectedChildPartCnt}/{totalcount}
//             </Row>
//             {/* <Row justify="center"> {selectedChildPartCnt == totalcount ? 'ALL' : ''}</Row>
//             <Button
//               // onClick={(e) => showModal(index, rowsData)}
//               onClick={props.commonTaiochuPopupOpen}
//               // style={{ pointerEvents: editMode ? 'auto' : 'none' }}
//               id={detailsBtn}
//               className="mainButton"
//             >
//               {editMode ? '選択' : '詳細'}
//             </Button> */}
//           </td>

//           {editMode ? (
//             <>
//               <td
//                 style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '6%' }}
//                 className="min"
//               >
//                 <Row justify="center">
//                   <PlusCircleFilled
//                     className="add-remove-icon"
//                     style={{ marginLeft: 10, marginRight: 10 }}
//                     onClick={addHyoumenshori}
//                   />
//                   <CloseCircleFilled className="add-remove-icon" onClick={(event) => removeHyomenshori(key, event)} />
//                 </Row>
//               </td>
//             </>
//           ) : (
//             <>
//               <td style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '6%' }}></td>
//             </>
//           )}
//         </tr>
//       </>
//     );
//   });

//   useImperativeHandle(ref, () => ({
//     setEditModeChange: setEditModeChange,
//   }));
//   return (
//     <>
//       <Row className="mt-1 registerModal" align="middle">
//         <Table className="kouteiinput" style={{ width: '100%' }}>
//           <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
//             <tr style={{ textAlign: 'center' }}>
//               <th style={{ width: '4%' }} className="tbl-header">
//                 No
//               </th>
//               <th style={{ width: '18%' }} className="tbl-header">
//                 計算方法
//               </th>
//               <th style={{ width: '28%' }} colSpan={2} className="tbl-header">
//                 詳細
//               </th>
//               <th style={{ width: '11%' }} className="tbl-header">
//                 面積/重量
//               </th>
//               <th style={{ width: '11%' }} className="tbl-header">
//                 自動抽出
//               </th>
//               <th style={{ width: '11%' }} className="tbl-header">
//                 回数
//               </th>
//               <th style={{ width: '11%' }} className="tbl-header">
//                 合計
//               </th>
//             </tr>
//           </thead>
//           <tbody>{renderTb}</tbody>
//         </Table>
//       </Row>
//       {addModal ? (
//         <ParentKouteiInput_ChildPartInfoSetting
//           editMode={editMode}
//           onUpdate={updateChildPart}
//           onCancel={cancelChildPart}
//           curData={curHyoumenshoriData}
//           shoriNm={'表面処理' + curHyoumenshoriData.key}
//         />
//       ) : (
//         ''
//       )}
//     </>
//   );
// });
const ParentKouteiInput_Hyoumenshori = forwardRef((props, ref) => {});

export default ParentKouteiInput_Hyoumenshori;
