import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Table } from 'react-bootstrap';
import { Row, Input } from 'antd';

const IQ3KouteiInput_Shearing = forwardRef((props, ref) => {
  // 編集モード
  // const editMode = props.editMode;
  const [numOfCuts, setNumOfCuts] = useState('');

  useEffect(() => {
    // setNumOfCuts(props.numOfCuts);
    let data = props.shearingInfo;
    if (data != undefined && data.details != undefined) {
      setNumOfCuts(data.details?.[0].numOfCuts);
    }
  }, [props.shearingInfo]);

  useImperativeHandle(ref, () => ({
    setEditModeChange: setEditModeChange,
  }));

  return (
    <>
      <Row className="mt-2 registerModal">
        <Table className="kouteiinput " style={{ width: '22%' }}>
          <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
            <tr style={{ textAlign: 'center' }}>
              <th style={{ width: '2%' }} className="tbl-header">
                No.
              </th>
              <th style={{ width: '20%' }} className="tbl-header">
                カット回数
              </th>
            </tr>
          </thead>
          <tbody>
            {/* {numOfCuts === '' ? (
              <></>
            ) : (
              <> */}
            <tr>
              <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>1</td>
              <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                {/* {editMode ? (
                      <> */}
                <Input
                  // className={editMode ? 'input-editable ' : 'input-non-editable '}
                  className="input-editable"
                  value={numOfCuts}
                  onChange={(e) => {
                    setNumOfCuts(e.target.value);
                    props.shearingInfo.details[0].numOfCuts = e.target.value;
                    props.updateShearing(props.shearingInfo);
                  }}
                  style={{ height: 32, textAlign: 'center' }}
                ></Input>
                {/* </>
                    ) : (
                      <>{numOfCuts}</>
                    )} */}
              </td>
            </tr>
            {/* </>
            )} */}
          </tbody>
        </Table>
      </Row>
    </>
  );
});

export default IQ3KouteiInput_Shearing;
