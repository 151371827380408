import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { Row, Col, Input, Select, Popover, Checkbox, Space, InputNumber, notification } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import CurrencyInput from 'react-currency-input-field';
import { BsUsbC, BsX } from 'react-icons/bs';

import '../../assets/styles/common.css';
import {
  handelIncreaseTime,
  handelDecreaseTime,
  checkInput,
  secondsToHms,
  setTimetoSec,
  checkInputWithColor,
  isNumber,
  checkShowHideIcon,
  JPYs,
  handleShowHide,
  handleCollpse,
  handleKeyPress,
  kouteiCalculateSelectedArr,
} from '../common/Common.js';
import { DataType, MaterialSurfaceBillingUnit, ServiceClass, WorkType, WorkTypeGroup } from '../common/enums';
import { amtEmptyStr, timeHmsStr } from '../common/Constant';

const IQ3KouteiCalculate_Bending = forwardRef((props, ref) => {
  const [isBendingdetail, setBendingdetail] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [totalAmt, setTotalAmt] = useState('');
  const [totalTime, setTotalTime] = useState('');
  const [totalSagyoAmt, setTotalSagyoAmt] = useState('');
  const [totalSagyoTime, setTotalSagyoTime] = useState('');
  const [totalDandoriAmt, setTotalDandoriAmt] = useState('');
  const [totalDandoriTime, setTotalDandoriTime] = useState('');

  const [totalSum, setTotalSum] = useState('');
  const [totalTimeSum, setTotalTimeSum] = useState('');
  const [sagyoAmtSum, setSagyoAmtSum] = useState('');
  const [sagyoTimeSum, setSagyoTimeSum] = useState('');
  const [dandoriAmtSum, setDandoriAmtSum] = useState('');
  const [dandoriTimeSum, setDandoriTimeSum] = useState('');

  const [processArr, setProcessArr] = useState([]);
  const [editedData, setEditedData] = useState([]);
  const [oldEditedData, setOldEditedData] = useState([]);

  const [editedHandelingAmt, setEditedHandelingAmt] = useState();
  const [editedHandelingTime, setEditedHandelingTime] = useState();
  const [editedKensaAmt, setEditedKensaAmt] = useState();
  const [editedKensaTime, setEditedKensaTime] = useState();

  const [handelingAmt, setHandelingAmt] = useState();
  const [handelingTime, setHandelingTime] = useState();
  const [kensaAmt, setKensaAmt] = useState();
  const [kensaTime, setKensaTime] = useState();

  const [editedProgramAmt, setEditedProgramAmt] = useState();
  const [editedProgramTime, setEditedProgramTime] = useState();
  const [editedMageDandoriAmt, setEditedMageDandoriAmt] = useState();
  const [editedMageDandoriTime, setEditedMageDandoriTime] = useState();

  const [programAmt, setProgramAmt] = useState();
  const [programTime, setProgramTime] = useState();
  const [mageDandoriAmt, setMageDandoriAmt] = useState();
  const [mageDandoriTime, setMageDandoriTime] = useState();

  const [subBendingsArr, setSubBendingArr] = useState([]);
  const [bendingsArr, setBendingArr] = useState([]);
  const [selectedDataFlg, setSelectedDataFlg] = useState(0);
  const [updateEditedData, setUpdateEditedData] = useState([]);
  const [bendingAmt, setBendingAmt] = useState(0);
  const [deviceMst, setDeviceMst] = useState([]);
  const [processMst, setProcessMst] = useState([]);
  const [patternInfo, setPatternInfo] = useState([]);

  const JPYs = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'JPY',
  });

  const handleChangeCheckData = (e, i) => {
    const data = { ...updateEditedData };
    data.dataType = e;
    setUpdateEditedData(data);
    setBendingAmt(e == DataType.Data ? data.totalDataPrice : data.totalEditPrice);
    props?.updateIQ3KouteiCal(data, WorkType.SmBending);
  };

  const settingEditData = (arr) => {
    let editDataArr = [];

    for (let i = 0; i < arr[0].dandoriKakouAmt?.length; i++) {
      let subArr = arr[0].dandoriKakouAmt;
      editDataArr.push({
        id: i,
        dandoriAmt: subArr[i].dandoriAmt,
        dandoriTime: subArr[i].dandoriTime,
        sagyoAmt: subArr[i].sagyoAmt,
        sagyoTime: subArr[i].sagyoTime,
        processType: subArr[i].processType,

        partCnt: subArr[i].partCnt,
        workTimeSize: subArr[i].workTimeSize,
        bendinglen: subArr[i].lines,
        bendCount: subArr[i].bendCount,
        programAmt: arr[0].programAmt,
        programTime: arr[0].programTime,
        mageDandoriAmt: arr[0].mageDandoriAmt,
        mageDandoriTime: arr[0].mageDandoriTime,
      });
    }
    return editDataArr;
  };

  const calculateTotals = (editDataArr) => {
    let sagyoAmtSum = 0;
    let dandoriAmtSum = 0;

    let sagyoTimeSec = 0;
    let dandoriTimeSec = 0;

    editDataArr?.forEach((element) => {
      sagyoAmtSum += element.sagyoAmt;
      dandoriAmtSum += element.dandoriAmt;
      sagyoTimeSec += element.sagyoTime;
      dandoriTimeSec += element.dandoriTime;
    });

    let programAmt = editDataArr?.length == 0 ? 0 : editDataArr[0].programAmt;
    let mageDandoriAmt = editDataArr?.length == 0 ? 0 : editDataArr[0].mageDandoriAmt;

    let programTm = editDataArr?.length == 0 ? 0 : editDataArr[0].programTime;
    let mageDandoriTm = editDataArr?.length == 0 ? 0 : editDataArr[0].mageDandoriTime;

    dandoriAmtSum = dandoriAmtSum + programAmt + mageDandoriAmt;
    let totalSum = sagyoAmtSum + dandoriAmtSum + programAmt + mageDandoriAmt;
    let sagyoTimeSum = sagyoTimeSec;
    let dandoriTimeSum = dandoriTimeSec;
    let totalTimeSum = sagyoTimeSec + dandoriTimeSec + programTm + mageDandoriTm;

    let totals = [];
    let i = 0;
    totals.push({
      id: i++,
      sagyoAmtSum: sagyoAmtSum,
      dandoriAmtSum: dandoriAmtSum,
      sagyoTimeSec: sagyoTimeSec,
      dandoriTimeSec: dandoriTimeSec,
      totalSum: totalSum,
      sagyoTimeSum: sagyoTimeSum,
      dandoriTimeSum: dandoriTimeSum,
      totalTimeSum: totalTimeSum,
    });
    return totals;
  };

  const contentDandori = (
    <div>
      <p>
        段取金額=アルゴン段取金額＋ボルト段取金額
        <br /> {totalDandoriAmt} =
        {(() => {
          const subArr = [];
          for (let j = 0; j < editedData?.length; j++) {
            subArr.push(editedData[j]?.dandoriAmt);
          }
          return subArr.join('+');
        })()}
      </p>
      <p>
        段取時間=アルゴン段取時間＋ボルト段取時間
        <br /> {totalDandoriTime} =
        {(() => {
          const subArr = [];
          for (let j = 0; j < editedData?.length; j++) {
            subArr.push(editedData[j]?.dandoriTime);
          }
          return subArr.join('+');
        })()}
      </p>
    </div>
  );

  const contentSagyo = (
    <div>
      <p>
        作業金額=アルゴン作業金額＋ボルト作業金額
        <br /> {totalSagyoAmt} =
        {(() => {
          const subArr = [];
          for (let j = 0; j < editedData?.length; j++) {
            subArr.push(editedData[j]?.sagyoAmt);
          }
          return subArr.join('+');
        })()}
      </p>
      <p>
        作業時間=アルゴン作業時間＋ボルト作業時間
        <br /> {totalSagyoTime} =
        {(() => {
          const subArr = [];
          for (let j = 0; j < editedData?.length; j++) {
            subArr.push(editedData[j]?.sagyoTime);
          }
          return subArr.join('+');
        })()}
      </p>
    </div>
  );

  const contentSub = (
    <div>
      <p>
        作業金額=チャージ金額 x 作業時間
        <br /> ¥233.3334 = ¥4,200(/h) x 200(秒)
      </p>
      <p>
        作業時間 = 加工長 / 加工速度 x 溶接難易度係数 x 製品サイズ係数 x 生産個数係数 <br />
        200(秒) = 200(mm) / 1(mm/秒) x 1 x 1 x 1
      </p>
      <p>
        引用パラメータ
        <br />
        加工長：200mm
        <br /> 加工速度：60mm/分 = 1mm/秒 <br />
        溶接難易度係数：1 <br />
        製品サイズ係数：1 <br />
        生産個数係数：1
      </p>
    </div>
  );

  /* "input[data-type='currency']".on({
    keyup: function () {
      alert('test');
    },
  }); */

  const uniquifyArr = (arr) => {
    const uniqueProcessTypes = new Set();
    let uniqueData = arr?.filter((item) => {
      if (!uniqueProcessTypes.has(item.processType)) {
        uniqueProcessTypes.add(item.processType);
        return true;
      }
      return false;
    });
    return uniqueData;
  };

  const calculateAmtFromTime = (objId, amt, timeAct, timeType, value) => {
    let objType = objId.substring(0, objId.indexOf('Time'));
    let capitalObj = objType.charAt(0).toUpperCase() + objType.slice(1);
    let returnAmt = 0;
    if (document.getElementById('Bending' + capitalObj + 'AmtCu').style.display !== undefined) {
      if (timeAct === 'input') {
        let timeType = setTimetoSec(value) - setTimetoSec(oldValue);
        returnAmt = changeAmtwithTime(amt, timeType, timeAct, value, objId);
      } else {
        returnAmt = changeAmtwithTime(amt, timeType, timeAct, value, objId);
      }
    }
    return returnAmt;
  };

  useEffect(() => {
    let editData = [];
    let totals = [];
    let processMst = [];
    let deviceMst = []; //テスト用ID;
    let toBEdit = [];

    let parameters = props?.selectedEstimateData?.estimateProducts?.calcParameters?.parameters;
    // 数量
    let partCnt = props?.selectedEstimateData?.estimateProducts?.quantity;
    if (parameters?.process?.length > 0) {
      processMst = parameters?.process
        ? parameters?.process?.filter(
            (item) => item?.class === ServiceClass.SheetMetal && item.workType === WorkType.SmBending
          )[0]
        : [];
      setProcessMst(processMst ? processMst : []);
      if (parameters?.device?.length > 0) {
        deviceMst = parameters?.device?.filter((item) => processMst?.id == item?.processId);
      }
    }

    let patternInfo = props?.selectedIQ3DataDetail?.processSelect
      ? props?.selectedIQ3DataDetail?.processSelect?.details?.filter((item) => item.processId == processMst?.id)
      : [];
    setPatternInfo([]);
    if (patternInfo == undefined) return;
    if (patternInfo?.length > 0 && patternInfo[0]?.isUsedForCalc == false) return;

    deviceMst =
      deviceMst && patternInfo
        ? deviceMst?.filter(
            (item) => item.processId == patternInfo[0]?.processId && item.id == patternInfo[0]?.deviceId
          )[0]
        : []; //テスト用ID;
    setDeviceMst(deviceMst ? deviceMst : []);
    if (deviceMst == undefined) return;
    setPatternInfo(patternInfo);

    let bendingAdditions = props?.selectedIQ3DataDetail?.additions?.filter(
      (item) => item.processId == processMst?.id
    )[0];
    // ベンディング工程入力情報取得
    let bendingProcessInput = props?.selectedIQ3DataDetail?.processInput?.filter(
      (item) => item.processId == processMst?.id
    )[0];

    let detailItems = [];
    let editDetailItems = [];
    //ベンディング種類情報設定
    for (let j = 0; j < bendingProcessInput?.details?.[0]?.bendingList?.length; j++) {
      let bendingListProcessInput = bendingProcessInput?.details?.[0]?.bendingList[j];
      let processTypeNm = processMst?.details?.processDetailTypes?.filter(
        (item) => item.id == bendingListProcessInput.types
      )?.[0];
      detailItems.push({
        id: j + 1,
        processName: 'ベンディング処理(No' + (j + 1) + ')',
        processTypeId: processTypeNm?.id,
        processType: processTypeNm?.name,
        dandoriAmt: 0,
        dandoriTime: 0,
        sagyoAmt: 0,
        sagyoTime: 0,
        partCnt: partCnt,
        workTimeSize: bendingListProcessInput.workTimeSize, //1つ当たりの加工時間
        bendinglen: bendingListProcessInput.lines, //曲げ長
        bendCount: bendingListProcessInput.bendCount, //曲げ回数
        programAmt: 0,
        programTime: 0,
        mageDandoriAmt: 0,
        mageDandoriTime: 0,
        formula: '',
      });
    }
    editDetailItems = [];
    let bendingAddition = {
      no: processMst?.id,
      processId: processMst?.id,
      dataType: bendingAdditions?.dataType ? bendingAdditions.dataType : DataType.Data,
      dataItems: detailItems,
      editItems: bendingAdditions?.editItems ? bendingAdditions.editItems : editDetailItems,
      totalDandori: {
        dataItemsAmt: 0,
        dataItemsTime: 0,
        editItemsAmt: 0,
        editItemsTime: 0,
      },
      totalSagyo: {
        dataItemsAmt: 0,
        dataItemsTime: 0,
        editItemsAmt: 0,
        editItemsTime: 0,
      },
      totalDataPrice: 0,
      totalDataTime: 0,
      totalEditPrice: 0,
      totalEditTime: 0,
    };
    bendingAdditions = bendingAddition;

    if (bendingAdditions?.dataItems) {
      let orgData = JSON.parse(JSON.stringify(bendingAdditions?.dataItems));
      if (deviceMst != undefined) {
        let totalDandori = 0;
        let totalSagyo = 0;
        let totalSagyoTm = 0;
        let totalDandoriTm = 0;
        let dataItemsByProcessInput = [];

        // 板厚
        let thickness = props?.selectedIQ3DataDetail?.thickness;
        // 重量
        let weight = props?.selectedIQ3DataDetail?.weight;
        // 面積
        let area = props?.selectedIQ3DataDetail?.totalSurfaceArea;
        //材質
        let materialTypeIq3Id = props?.selectedIQ3DataDetail?.materialTypeIq3Id;

        //材質区分
        let materialTypeId = parameters?.materialTypeIq3?.filter((item) => item.id == materialTypeIq3Id)?.[0]
          ?.materialTypeId;

        // サイズ係数
        let sizeCoef = getSizeCoeff(deviceMst, weight, area);

        //　曲げ係数
        let bendingCoef = getBendingCoeff(deviceMst, weight, area);

        // 曲げ補正時間係数
        let bendingModifyCoef = getBendingModifyCoeff(deviceMst, weight, area);

        let totalBendLines = 0;

        for (let i = 0; i < bendingProcessInput?.details?.[0]?.bendingList?.length; i++) {
          //工程入力情報
          let bendingProcessInputInfo = bendingProcessInput?.details?.[0]?.bendingList[i];

          if (orgData?.length == 0) continue;
          let dataItemByProcessInput = orgData[i];

          let processTypeNm = processMst?.details?.processDetailTypes?.filter(
            (item) => item.id == bendingProcessInputInfo.types
          )?.[0];
          dataItemByProcessInput.processType = processTypeNm?.name;

          let deviceCharges = deviceMst?.details?.charges;

          /** 段取金額/時間 */
          // 曲げ_段取時間＊サイズ係数
          let dandoriTimeSec = calDandoriTime(deviceMst, thickness, bendingProcessInputInfo.types, sizeCoef);
          let dandoriTime = dandoriTimeSec;
          dataItemByProcessInput.dandoriTime = dandoriTime;
          // 段取時間の合計
          totalDandoriTm += dandoriTime;

          // 段取金額設定
          //段階時間
          let mSetTimeMin = dandoriTimeSec / 60;
          //装置の段階時間チャージ
          let mSetHRate = deviceCharges?.prep;
          let dandoriAmt = (mSetTimeMin * mSetHRate) / 60.0; //段階時間＊時間チャージ
          dataItemByProcessInput.dandoriAmt = dandoriAmt;
          // 段取金額の合計
          totalDandori += dandoriAmt;

          // 作業時間（{(1つ当たりの加工時間	*	曲げ時間係数)	+	(曲げ補正時間	*	曲げ補正係数)｝	*	曲げ回数	*	サイズ係数）
          // 1つ当たりの加工時間
          let sagyoTimePerSec = getSagyoTime(deviceMst, thickness, bendingProcessInputInfo.types);
          // 曲げ補正時間
          let modifyTimeQtyThMaster = deviceMst?.details?.modifyTimeQtyThMaster?.filter(
            (item) => item.value == partCnt || item.value > partCnt
          );
          let bendingModifyTime = modifyTimeQtyThMaster?.filter(
            (item) => item.no == modifyTimeQtyThMaster?.[0]?.no
          )?.[0]?.value;

          // 作業時間（{(1つ当たりの加工時間	*	曲げ時間係数)	+	(曲げ補正時間	*	曲げ補正係数)｝	*	曲げ回数	*	サイズ係数）
          let sagyoTimeSec =
            sagyoTimePerSec * bendingCoef +
            bendingModifyTime * bendingModifyCoef * bendingProcessInputInfo?.bendCount * sizeCoef;
          dataItemByProcessInput.sagyoTime = sagyoTimeSec;
          dataItemByProcessInput.workTimeSize = sagyoTimePerSec;
          totalSagyoTm += sagyoTimeSec;

          // 作業金額
          let mSagyoTimeMin = sagyoTimeSec / 60;
          //装置の作業時間チャージ
          let mSagyoHRate = deviceCharges?.work;
          let sagyoAmt = (mSagyoTimeMin * mSagyoHRate) / 60.0; //作業時間＊時間チャージ
          dataItemByProcessInput.sagyoAmt = sagyoAmt;

          // 作業金額の合計
          totalSagyo += sagyoAmt;
          totalBendLines += bendingProcessInputInfo?.lines;
          dataItemsByProcessInput.push(dataItemByProcessInput);
        }
        if (dataItemsByProcessInput?.length > 0) {
          // プログラム作成時間
          let programCreateTimeCountfThMaster = deviceMst?.details?.programCreateTimeCountfThMaster?.filter(
            (item) =>
              item.value == bendingProcessInput?.details?.[0]?.bendingList?.length ||
              item.value > bendingProcessInput?.details?.[0]?.bendingList?.length
          );
          let programTimeSec = deviceMst?.details?.programCreateTimeItems?.filter(
            (item) => item.no == programCreateTimeCountfThMaster?.[0]?.no
          )?.[0]?.value;
          dataItemsByProcessInput[0].programTime = programTimeSec;
          setProgramTime(programTimeSec);
          // プログラム作成金額
          let programAmt = calDandoriAmt(programTimeSec, deviceMst); //装置の加工金額
          dataItemsByProcessInput[0].programAmt = programAmt;
          setProgramAmt(programAmt);

          // 曲長段取時間
          let prepBendTimeLengthMaster = deviceMst?.details?.prepBendTimeLengthMaster?.filter(
            (item) => item.value == totalBendLines || item.value > totalBendLines
          );
          let bendingTimeSec = deviceMst?.details?.prepBendTimeItems?.filter(
            (item) => item.no == prepBendTimeLengthMaster?.[0]?.no
          )?.[0]?.value;
          dataItemsByProcessInput[0].mageDandoriTime = bendingTimeSec;
          setMageDandoriTime(bendingTimeSec);
          // 曲長段取金額
          let mageDandoriAmt = calDandoriAmt(bendingTimeSec, deviceMst); //装置の加工金額
          dataItemsByProcessInput[0].mageDandoriAmt = mageDandoriAmt;
          setMageDandoriAmt(mageDandoriAmt);
          totalDandori = totalDandori + programAmt + mageDandoriAmt;
          totalDandoriTm = totalDandoriTm + programTimeSec + bendingTimeSec;
          // 段取金額の合計
          setDandoriAmtSum(totalDandori);
          bendingAdditions.totalDandori.dataItemsAmt = totalDandori;
          // 段取時間の合計
          setDandoriTimeSum(totalDandoriTm);
          bendingAdditions.totalDandori.dataItemsTime = totalDandoriTm;
          // 作業金額の合計
          setSagyoAmtSum(totalSagyo);
          bendingAdditions.totalSagyo.dataItemsAmt = totalSagyo;
          setSagyoTimeSum(totalSagyoTm);
          bendingAdditions.totalSagyo.dataItemsTime = totalSagyoTm;

          /** 　合計時間　*/
          let totalTimeSum = totalDandoriTm;
          setTotalTimeSum(totalTimeSum);
          bendingAdditions.totalDataTime = totalTimeSum;

          /** 　合計金額　*/
          let totalSum = totalDandori + totalSagyo;
          setTotalSum(totalSum);
          bendingAdditions.totalDataPrice = totalSum;
        }

        orgData = dataItemsByProcessInput;
        setProcessArr(dataItemsByProcessInput);

        if (bendingAdditions.dataType == DataType.Edit) {
          toBEdit = JSON.parse(JSON.stringify(bendingAdditions?.editItems));
        } else {
          toBEdit = JSON.parse(JSON.stringify(orgData));
        }
      }
    }
    if (toBEdit?.length > 0) {
      editData = toBEdit;
      bendingAdditions.editItems = editData;
      if (editData?.length > 0) {
        setEditedData(editData);
        totals = calculateTotals(editData);

        setTotalAmt(totals[0]?.totalSum);
        bendingAdditions.totalEditPrice = totals[0]?.totalSum;
        bendingAdditions.totalDandori.editItemsAmt = totals[0]?.totalSum;

        setTotalTime(totals[0]?.totalTimeSum);
        bendingAdditions.totalEditTime = totals[0]?.totalTimeSum;
        bendingAdditions.totalDandori.editItemsTime = totals[0]?.totalTimeSum;

        setTotalSagyoAmt(totals[0]?.sagyoAmtSum);
        setTotalSagyoTime(totals[0]?.sagyoTimeSum);

        setTotalDandoriAmt(totals[0]?.dandoriAmtSum);
        setTotalDandoriTime(totals[0]?.dandoriTimeSum);
      }
      setUpdateEditedData(bendingAdditions);
    }
    if (bendingAdditions?.dataType == DataType.Data) {
      setBendingAmt(bendingAdditions.totalDataPrice);
    } else {
      setBendingAmt(bendingAdditions.totalEditPrice);
    }
    props?.updateIQ3KouteiCal(bendingAdditions, WorkType.SmBending);
  }, [props?.activeTabKey]);

  useEffect(() => {
    setEditMode(props?.editMode != undefined ? props?.editMode : false);
  }, [props?.editMode]);

  const getSizeCoeff = (deviceMst, weight, area) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    // 段取時間設定

    // 重量
    let prodSizeWeightThMaster = deviceMst?.details?.prodSizeWeightThMaster?.filter(
      (item) => item.value == weight || item.value > weight
    );
    // 面積
    let prodSizeAreaThMaster = deviceMst?.details?.prodSizeAreaThMaster?.filter(
      (item) => item.value == area || item.value > area
    );
    let childPartsCoeffs =
      prodSizeWeightThMaster?.length > 0 && prodSizeAreaThMaster?.length > 0
        ? deviceMst?.details?.prodSizeItems?.filter((item) => item.weightId == prodSizeWeightThMaster[0]?.no)
        : deviceMst?.details?.prodSizeItems[0];

    // サイズ係数
    let partsCoeff =
      childPartsCoeffs?.length > 0
        ? childPartsCoeffs?.[0]?.areaCoeffList?.filter((item) => item.no == prodSizeAreaThMaster?.[0].no)?.[0].value
        : childPartsCoeffs?.[0]?.areaCoeffList?.[0]?.value;

    return partsCoeff;
  };

  const getBendingCoeff = (deviceMst, weight, area) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    // 段取時間設定

    // 重量
    let bendTimeCoeffWeightThMaster = deviceMst?.details?.bendTimeCoeffWeightThMaster?.filter(
      (item) => item.value == weight || item.value > weight
    );
    // 面積
    let bendTimeCoeffLenThMaster = deviceMst?.details?.bendTimeCoeffLenThMaster?.filter(
      (item) => item.value == area || item.value > area
    );
    let bendTimeCoeffItems = Object.entries(deviceMst?.details?.bendTimeCoeffItems)?.filter(
      ([key, data]) => parseInt(key) === bendTimeCoeffWeightThMaster?.[0]?.no
    )?.[0];
    let bendTimeCoeffs = [];
    bendTimeCoeffItems?.map((item, index) => {
      if (index > 0) {
        bendTimeCoeffs = item;
      }
    });

    // サイズ係数
    let bendTimeCoeff = bendTimeCoeffs?.filter((item) => item.no == bendTimeCoeffLenThMaster?.[0]?.no)?.[0]?.value;

    return bendTimeCoeff;
  };

  const getBendingModifyCoeff = (deviceMst, weight, area) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    // 段取時間設定

    // 重量
    let modifyCoeffWeightThMaster = deviceMst?.details?.modifyCoeffWeightThMaster?.filter(
      (item) => item.value == weight || item.value > weight
    );
    // 面積
    let modifyCoeffLenThMaster = deviceMst?.details?.modifyCoeffLenThMaster?.filter(
      (item) => item.value == area || item.value > area
    );
    let childPartsCoeffs =
      modifyCoeffWeightThMaster?.length > 0 && modifyCoeffLenThMaster?.length > 0
        ? deviceMst?.details?.modifyCoeffItems?.filter((item) => item.weightId == modifyCoeffWeightThMaster[0]?.no)
        : deviceMst?.details?.modifyCoeffItems[0];

    // 補正係数
    let partsCoeff =
      childPartsCoeffs?.length > 0
        ? childPartsCoeffs?.[0]?.lengthCoeffList?.filter((item) => item.no == modifyCoeffLenThMaster?.[0]?.no)?.[0]
            ?.value
        : childPartsCoeffs?.[0]?.lengthCoeffList?.[0]?.value;

    return partsCoeff;
  };

  const calDandoriTime = (deviceMst, thickness, typeId, sizeCoef) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    // 段取時間設定
    let basicTimeThickThMaster = deviceMst?.details?.basicTimeThickThMaster?.filter(
      (item) => item.value == thickness || item.value > thickness
    );

    let basicTimeThickItems = Object.entries(deviceMst?.details?.basicTimeThickItems)?.filter(
      ([key, data]) => parseInt(key) === typeId
    )?.[0];
    let deviceDandori = [];
    basicTimeThickItems?.map((item, index) => {
      if (index > 0) {
        deviceDandori = item;
      }
    });
    //　基本段取時間
    let dandoriTimeSec =
      deviceDandori?.filter((item) => item.no == basicTimeThickThMaster?.[0]?.no)?.[0]?.value * sizeCoef;
    return dandoriTimeSec;
  };

  const calDandoriAmt = (dandoriTimeSec, deviceMst) => {
    //段階時間
    let mSetTimeMin = dandoriTimeSec / 60;
    //装置の段階時間チャージ
    let mSetHRate = deviceMst?.details?.charges.prep;
    //段階時間＊時間チャージ
    let dandoriAmt = (mSetTimeMin * mSetHRate) / 60.0;
    return dandoriAmt;
  };

  const getSagyoTime = (deviceMst, thickness, typeId) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    // 段取時間設定
    let workTimeThickThMaster = deviceMst?.details?.workTimeThickThMaster?.filter(
      (item) => item.value == thickness || item.value > thickness
    );

    let workTimeThickItems = Object.entries(deviceMst?.details?.workTimeThickItems)?.filter(
      ([key, data]) => parseInt(key) === typeId
    )?.[0];
    let deviceWorkTime = [];
    workTimeThickItems?.map((item, index) => {
      if (index > 0) {
        deviceWorkTime = item;
      }
    });
    //　基本段取時間
    let deviceWorkTimeSec = deviceWorkTime?.filter((item) => item.no == workTimeThickThMaster?.[0]?.no)?.[0]?.value;
    return deviceWorkTimeSec;
  };

  const calSagyoAmt = (cSagyoTimeKonpoSec, deviceMst) => {
    // 作業金額
    // 作業時間
    let mWorkHTime = cSagyoTimeKonpoSec;
    let mWorkHTimeMin = mWorkHTime / 60;
    //装置の作業時間チャージ
    let mWorkHRate = deviceMst?.details?.charges?.work;
    //　加工時間＊時間チャージ
    let cSagyoAmtKonpo = (mWorkHTimeMin * mWorkHRate) / 60.0;
    return cSagyoAmtKonpo;
  };

  const bending = () => {
    if (isBendingdetail == true) {
      setBendingdetail(false);
    } else {
      setBendingdetail(true);
    }
  };

  useEffect(() => {
    getBendingTypes();
  }, []);

  const getBendingTypes = () => {
    let subBendings = [];
    let bendings = [];
    const kouteiMasterData = JSON.parse(localStorage.getItem('kouteiMasterData'))?.forEach((e) => {
      if (e.processType === '溶接' && e.type === 'iQ3') {
        e.processDetail?.forEach((processDetail) => {
          if (
            processDetail.processDetailTitle === '取付種類（板厚判断）' ||
            processDetail.processDetailTitle === '取付種類（サイズ判断）'
          ) {
            processDetail.processDetailValue?.forEach((detailVal) => {
              subBendings.push(detailVal.detailType);
            });
          } else if (processDetail.processDetailTitle === '溶接種類') {
            processDetail.processDetailValue?.forEach((detailVal) => {
              bendings.push(detailVal.detailType);
            });
          }
        });
      }
    });
    setSubBendingArr(subBendings);
    setBendingArr(bendings);
  };

  const subpartDetail = (i) => {
    let showHide = document.getElementById('bendingProcessIQ3' + i).style.display;
    if (showHide == 'block') {
      document.getElementById('bendingProcessIQ3' + i).style.display = 'none';
      document.getElementById('arrowDownBendingIQ3' + i).style.display = 'block';
      document.getElementById('arrowUpBendingIQ3' + i).style.display = 'none';
    } else {
      document.getElementById('bendingProcessIQ3' + i).style.display = 'block';
      document.getElementById('arrowDownBendingIQ3' + i).style.display = 'none';
      document.getElementById('arrowUpBendingIQ3' + i).style.display = 'block';
    }
  };

  const setEditModeChange = (mode) => {
    setEditMode(mode);
  };
  useImperativeHandle(ref, () => ({
    setEditModeChange: setEditModeChange,
  }));

  const settingNewDandoriAmtInArr = (value, index, objId, timeType, timeAct) => {
    let newState = [...editedData];
    if (objId !== 'dandoriAmt' && objId !== 'dandoriTime' && objId !== 'handelingAmt' && objId !== 'handelingTime') {
      setOldEditedData(JSON.parse(JSON.stringify(editedData)));
    }
    let newStateUnique = [...editedData];
    let oldValue = editedData[index][objId];
    if (value === undefined) {
      value = 0;
    }
    if (objId !== 'dandoriTime' && objId !== 'dandoriAmt') {
      oldValue = editedData[index][objId];
      newState[index][objId] = value;
      updateEditedData.editItems[index][objId] = value;
    }

    if (objId === 'dandoriTime' || objId === 'dandoriAmt' || objId === 'programAmt' || objId === 'mageDandoriAmt') {
      oldValue = newStateUnique[index][objId];
      newStateUnique[index][objId] = value;
      if (objId === 'dandoriTime') {
        if (document.getElementById(index + 'BendingDandoriAmtCu').style.display !== undefined) {
          let showHideCu = document.getElementById(index + 'BendingDandoriAmtCu').style.display;
          if (showHideCu === 'block') {
            checkInputWithColor(false, '', index + 'bendingDandoriAmt', newStateUnique[index].dandoriAmt, 'red');
          } else {
            if (timeAct === 'input') {
              let timeType = value - oldValue;
              newStateUnique[index].dandoriAmt = changeAmtwithTime(
                newStateUnique[index].dandoriAmt,
                timeType,
                timeAct,
                value,
                objId
              );
              updateEditedData.editItems[index].dandoriAmt = newStateUnique[index].dandoriAmt;
            } else {
              newStateUnique[index].dandoriAmt = changeAmtwithTime(
                newStateUnique[index].dandoriAmt,
                timeType,
                timeAct,
                value,
                objId
              );
              updateEditedData.editItems[index].dandoriAmt = newStateUnique[index].dandoriAmt;
            }
            // checkInputWithColor(false, '', index + 'dandoriAmt', newStateUnique[index].dandoriAmt, 'green');
          }
        }
      }
      // newState = newState?.map((item) => {
      //   const matchingItem = newStateUnique?.find((el) => el.processType === item.processType);
      //   return matchingItem
      //     ? { ...item, dandoriAmt: matchingItem.dandoriAmt, dandoriTime: matchingItem.dandoriTime }
      //     : item;
      // });
    } else if (objId === 'sagyoTime') {
      checkInputWithColor(false, '', index + 'bendingSagyoAmt', newState[index].sagyoAmt, 'green');

      if (document.getElementById(index + 'bendingSagyoAmtCu').style.display !== undefined) {
        let showHideCu = document.getElementById(index + 'bendingSagyoAmtCu').style.display;
        if (showHideCu === 'block') {
          checkInputWithColor(false, '', index + 'bendingSagyoAmt', newState[index].sagyoAmt, 'red');
        } else {
          if (timeAct === 'input') {
            let timeType = value - oldValue;
            newState[index].sagyoAmt = changeAmtwithTime(newState[index].sagyoAmt, timeType, timeAct, value, objId);
            updateEditedData.editItems[index].sagyoAmt = newStateUnique[index].sagyoAmt;
          } else {
            newState[index].sagyoAmt = changeAmtwithTime(newState[index].sagyoAmt, timeType, timeAct, value, objId);
            updateEditedData.editItems[index].sagyoAmt = newStateUnique[index].sagyoAmt;
          }
          // checkInputWithColor(false, '', index + 'bendingSagyoAmt', newState[index].sagyoAmt, 'green');
        }
      }
    } else if (objId === 'handelingTime') {
      if (document.getElementById('BendingHandlingAmtCu').style.display !== undefined) {
        if (timeAct === 'input') {
          let timeType = value - oldValue;
          newState[0].handelingAmt = changeAmtwithTime(newState[0].handelingAmt, timeType, timeAct, value, objId);
        } else {
          newState[0].handelingAmt = changeAmtwithTime(newState[0].handelingAmt, timeType, timeAct, value, objId);
        }
        // checkInputWithColor(false, '', 'BendingHandlingAmt', newState[0].handelingAmt, 'green');
      }
    } else if (objId === 'programTime' || objId === 'mageDandoriTime') {
      let amt = objId === 'programTime' ? 'programAmt' : 'mageDandoriAmt';
      newState[0][amt] = calculateAmtFromTime(objId, newState[0][amt], timeAct, timeType, value);

      /* if (document.getElementById('BendingKensaAmtCu').style.display !== undefined) {
        if (timeAct === 'input') {
          let timeType = setTimetoSec(value) - setTimetoSec(oldValue);
          newState[0].kensaAmt = changeAmtwithTime(newState[0].kensaAmt, timeType, timeAct, value);
        } else {
          newState[0].kensaAmt = changeAmtwithTime(newState[0].kensaAmt, timeType, timeAct, value);
        }
      } */
    } else if (objId === 'partCnt' || objId === 'workTimeSize') {
      // 作業時間（(1つ当たりの加工時間	*	個数	*	曲げ時間係数)	*	サイズ係数）
      // 1つ当たりの加工時間
      let sagyoTimePerSec = objId === 'workTimeSize' ? value : newState[index].workTimeSize; //300;
      // 個数
      let partCnt = objId === 'partCnt' ? value : newState[index].partCnt;
      // 曲げ時間係数
      let bendingCoef = 1.0;
      // サイズ係数
      let sizeCoef = 1.0;
      // 曲げ_加工時間（(1つ当たりの加工時間	*	個数	*	曲げ時間係数)	*	サイズ係数）
      let sagyoTimeSec = sagyoTimePerSec * partCnt * bendingCoef * sizeCoef;
      // 作業時間（溶接時間＋仮付時間）
      newState[index].sagyoTime = sagyoTimeSec;
      updateEditedData.editItems[index].sagyoTime = sagyoTimeSec;

      // 作業金額
      let mSagyoTimeMin = newState[index].sagyoTime / 60;
      //装置の作業時間チャージ
      // let mSetHRate = deviceMst?.details?.charges[processType].prep;
      let mSagyoHRate = 10000;

      if (document.getElementById(index + 'bendingSagyoAmtCu').style.display !== undefined) {
        let showHideCu = document.getElementById(index + 'bendingSagyoAmtCu').style.display;
        if (showHideCu === 'none') {
          let sagyoAmt = (mSagyoTimeMin * mSagyoHRate) / 60.0; //作業時間＊時間チャージ
          newState[index].sagyoAmt = sagyoAmt;
          updateEditedData.editItems[index].sagyoAmt = sagyoAmt;
        }
      }
    }
    // else if (objId === 'kensaTime') {
    //   if (document.getElementById('BendingKensaAmtCu').style.display !== undefined) {
    //     if (timeAct === 'input') {
    //       let timeType = setTimetoSec(value) - setTimetoSec(oldValue);
    //       newState[0].kensaAmt = changeAmtwithTime(newState[0].kensaAmt, timeType, timeAct, value);
    //     } else {
    //       newState[0].kensaAmt = changeAmtwithTime(newState[0].kensaAmt, timeType, timeAct, value);
    //     }
    //     checkInputWithColor(false, '', 'BendingKensaAmt', newState[0].kensaAmt, 'green');
    //   }
    // }

    let newSagyoSum = 0;
    let newDandoriSum = 0;
    let newSum = 0;
    let newSubSum = 0;
    let newSagyoTimeSec = 0;
    let newDandoriTimeSec = 0;

    newState?.forEach((element) => {
      newSagyoSum += Number(element.sagyoAmt);
      newSagyoTimeSec += element.sagyoTime;
    });

    newStateUnique?.forEach((element) => {
      newDandoriSum += Number(element.dandoriAmt);
      newDandoriTimeSec += element.dandoriTime;
    });

    /* newSagyoSum += Number(newState[0].handelingAmt);
    newSagyoTimeSec += setTimetoSec(newState[0].handelingTime); */

    let programMageAmt = Number(newState[0].programAmt) + Number(newState[0].mageDandoriAmt);
    let programMageTime = newState[0].programTime + newState[0].mageDandoriTime;
    newDandoriSum += programMageAmt;
    newDandoriTimeSec += programMageTime;

    newSum = Number(newSagyoSum) + Number(newDandoriSum);

    let newSagyoTimeSum = newSagyoTimeSec;
    let newDandoriTimeSum = newDandoriTimeSec;
    let newTotalTimeSum = newSagyoTimeSec + newDandoriTimeSec;

    /* checkInputWithColor(false,'',index + 'subTotal', newSubSum, 'green');
    checkInputWithColor(false,'',index + 'subTotalTime', newSubTimeSum, 'green'); */
    checkInputWithColor(false, '', 'totalSagyoAmt', newSagyoSum, 'green');
    checkInputWithColor(false, '', 'totalDandoriAmt', newDandoriSum, 'green');
    checkInputWithColor(false, '', 'totalAmt', newSum, 'green');
    checkInputWithColor(false, '', 'totalSagyoTime', newSagyoTimeSum, 'green');
    checkInputWithColor(false, '', 'totalDandoriTime', newDandoriTimeSum, 'green');
    checkInputWithColor(false, '', 'totalTime', newTotalTimeSum, 'green');
    setTotalSagyoAmt(newSagyoSum);
    setTotalDandoriAmt(newDandoriSum);
    setTotalAmt(newSum);
    setTotalSagyoTime(newSagyoTimeSum);
    setTotalDandoriTime(newDandoriTimeSum);
    setTotalTime(newTotalTimeSum);
    /*  setEditedHandelingAmt(newState[0].handelingAmt);
    setEditedHandelingTime(newState[0].handelingTime); */
    setEditedKensaAmt(newState[0].kensaAmt);
    setEditedKensaTime(newState[0].kensaTime);

    setEditedProgramAmt(newState[0].programAmt);
    setEditedProgramTime(newState[0].programTime);
    setEditedMageDandoriAmt(newState[0].mageDandoriAmt);
    setEditedMageDandoriTime(newState[0].mageDandoriTime);
    setEditedData(newState);
    updateEditedData.totalEditPrice = newSum;
    updateEditedData.totalEditTime = newTotalTimeSum;
    updateEditedData.totalDandori.editItemsAmt = newDandoriSum;
    updateEditedData.totalDandori.editItemsTime = newDandoriTimeSum;
    updateEditedData.totalSagyo.editItemsAmt = newSagyoSum;
    updateEditedData.totalSagyo.editItemsTime = newSagyoTimeSum;
    if (updateEditedData.dataType == DataType.Data) {
      setBendingAmt(updateEditedData.totalDataPrice);
    } else {
      setBendingAmt(updateEditedData.totalEditPrice);
    }
    props?.updateIQ3KouteiCal(updateEditedData, WorkType.SmBending);
  };

  const checkStr = (val) => {
    let intAmt;
    if (val === '') {
      intAmt = 0;
    } else if (typeof val === 'string') {
      intAmt = Number(val.charAt(0) == '¥' ? val.replace('¥', '') : val);
    } else intAmt = val;
    return intAmt;
  };

  const changeAmtwithTime = (amt, typeTime, action, timeVal, processType) => {
    let editedAmt;
    let totalSec = Number(timeVal);
    let intAmt = checkStr(amt);
    /*  let deviceMst = []; */

    if (processType === 'dandoriTime') {
      // 段取金額
      //　段取時間＊時間チャージ
      editedAmt = calDandoriAmt(totalSec, deviceMst);
    } else if (processType === 'sagyoTime') {
      // 作業金額
      //　加工時間＊時間チャージ
      editedAmt = calSagyoAmt(totalSec, deviceMst);
    } else if (processType === 'handelingTime') {
      // マテハン金額
      //　マテリアルハンドリング時間＊時間チャージ
      editedAmt = calSagyoAmt(totalSec, deviceMst);
    } else if (processType === 'programTime') {
      // プログラム作成金額
      //　 プログラム作成時間＊時間チャージ
      editedAmt = calSagyoAmt(totalSec, deviceMst);
    } else if (processType === 'mageDandoriTime') {
      // 曲げ段取金額
      //　曲げ段取時間＊時間チャージ
      editedAmt = calSagyoAmt(totalSec, deviceMst);
    }
    return editedAmt;
  };

  const handleProvinceChange = (value) => {};

  const checkRegex = (value) => {
    var patt = /^\¥?[0-9]+\.?[0-9]*$/;
    return patt.test(value);
  };

  const disableTimer = (id) => {
    document.getElementById(id).style.color = 'grey';
    document.getElementById(id).style.pointerEvents = 'none';
  };

  return (
    <div className="kouteiCalculateDiv">
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row " onClick={bending}>
        <Col span={4} className="oyabuhin-col">
          <div style={{ display: 'flex' }}>
            {isBendingdetail ? (
              <CaretUpOutlined style={{ fontSize: 17, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 17, marginTop: 2 }} />
            )}
            <label style={{ marginLeft: 5 }}>{processMst?.name}</label>
          </div>
        </Col>
        <Col span={6} className="oyabuhin-col">
          <div>{bendingAmt ? JPYs.format(Math.floor(bendingAmt)) : amtEmptyStr}</div>
        </Col>
        <Col span={13} style={{ border: 'none' }} className="oyabuhin-col">
          <div>
            <label style={{ marginLeft: 5 }}>{deviceMst?.name}</label>
          </div>
        </Col>
      </Row>
      <div style={{ display: isBendingdetail ? 'block' : 'none' }}>
        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col">
            工程詳細
          </Col>
          <Col span={6} className="oyabuhin-detail-col">
            <Checkbox
              key={kouteiCalculateSelectedArr[0].key}
              value={kouteiCalculateSelectedArr[0].value}
              checked={kouteiCalculateSelectedArr[0].key == updateEditedData.dataType}
              disabled={!props?.editMode}
              style={{ marginRight: 6 }}
              onChange={(e) => props?.editMode && handleChangeCheckData(kouteiCalculateSelectedArr[0].key)}
            ></Checkbox>
            <label>{kouteiCalculateSelectedArr[0].value}</label>
          </Col>
          <Col span={8} className="oyabuhin-detail-col">
            <Checkbox
              key={kouteiCalculateSelectedArr[1].key}
              value={kouteiCalculateSelectedArr[1].value}
              disabled={!props?.editMode}
              onChange={(e) => props?.editMode && handleChangeCheckData(kouteiCalculateSelectedArr[1].key)}
              checked={kouteiCalculateSelectedArr[1].key == updateEditedData.dataType}
              style={{ marginRight: 6 }}
            ></Checkbox>
            <label>{kouteiCalculateSelectedArr[1].value}</label>
          </Col>

          <Col span={1} className="oyabuhin-detail-col">
            単位
          </Col>
          <Col span={5} className="formula-column">
            計算式
          </Col>
        </Row>
        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col">
            {processMst?.name}
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalAmt">{totalSum ? JPYs.format(Math.floor(totalSum)) : amtEmptyStr}</label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalTime">{totalTimeSum ? secondsToHms(totalTimeSum) : timeHmsStr}</label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalAmtIn" className="resetColor">
              {totalAmt ? JPYs.format(Math.floor(totalAmt)) : amtEmptyStr}
            </label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalTimeIn" className="resetColor">
              {totalTime ? secondsToHms(totalTime) : timeHmsStr}
            </label>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={5} className="formula-column"></Col>
        </Row>
        {/* 段取金額/時間 */}
        <Row
          style={{ color: 'black' }}
          className="oyabuhin-detail oyabuhin-row"
          onClick={(e) => handleCollpse('BendingDandori')}
        >
          <Col span={4} className="oyabuhin-detail-col">
            <div style={{ display: 'flex' }}>
              <CaretUpOutlined id={'arrowUpBendingDandori'} style={{ fontSize: 17, marginTop: 2, display: 'none' }} />

              <CaretDownOutlined id={'arrowDownBendingDandori'} style={{ fontSize: 17, marginTop: 2 }} />
              <label style={{ marginLeft: 5 }}> 段取金額/時間</label>
            </div>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalDandoriAmt">{dandoriAmtSum ? JPYs.format(Math.floor(dandoriAmtSum)) : amtEmptyStr}</label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalDandoriTime">{dandoriTimeSum ? secondsToHms(dandoriTimeSum) : timeHmsStr}</label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalDandoriAmtIn" className="resetColor">
              {totalDandoriAmt ? JPYs.format(Math.floor(totalDandoriAmt)) : amtEmptyStr}
            </label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalDandoriTimeIn" className="resetColor">
              {totalDandoriTime ? secondsToHms(totalDandoriTime) : timeHmsStr}
            </label>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={5} className="formula-column">
            <Popover content={contentDandori}>段取金額の総合計</Popover>
          </Col>
        </Row>
        <div id={'divBendingDandori'} style={{ display: 'none' }}>
          {(() => {
            const arr = [];
            for (let j = 0; j < processArr?.length; j++) {
              arr.push(
                <div>
                  {/* ○○_標準 */}
                  <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                    <Col span={4} className="oyabuhin-detail-col">
                      <div style={{ display: 'flex' }}>
                        <span style={{ marginLeft: '20px' }}>{processArr[j]?.processType}</span>
                      </div>
                    </Col>
                    <Col span={3} className="oyabuhin-detail-col">
                      <label id={j + 'BendingDandoriAmt'}>
                        {processArr[j]?.dandoriAmt && processArr[j]?.dandoriAmt > 0
                          ? JPYs.format(Math.floor(processArr[j]?.dandoriAmt))
                          : amtEmptyStr}
                      </label>
                    </Col>
                    <Col span={3} className="oyabuhin-detail-col">
                      {
                        <label id={j + 'BendingDndoriTime'}>
                          {processArr[j]?.dandoriTime ? secondsToHms(processArr[j]?.dandoriTime) : timeHmsStr}
                        </label>
                      }
                    </Col>

                    <Col span={4} className="input-col">
                      <CurrencyInput
                        id={j + 'BendingDandoriAmtIn'}
                        value={editedData[j]?.dandoriAmt ? Math.floor(editedData[j]?.dandoriAmt) : 0}
                        defaultValue={0}
                        style={{
                          border: 'none',
                          color:
                            processArr[j]?.dandoriAmt === editedData[j]?.dandoriAmt
                              ? 'black'
                              : checkShowHideIcon(j + 'BendingDandoriAmtCu') === 'none'
                              ? '#4cc12f'
                              : 'red',
                        }}
                        onValueChange={(e) => {
                          //checkRegex(e.target.value);
                          if (editedData[j]?.dandoriAmt != e) {
                            settingNewDandoriAmtInArr(e, j, 'dandoriAmt', '', '');
                            checkInputWithColor(false, '', j + 'BendingDandoriAmt', e, 'amt');
                          }
                        }}
                        className={
                          editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'
                        }
                        prefix="¥"
                        decimalsLimit={100}
                      />
                      <div id={j + 'BendingDandoriAmtCu'} style={{ display: 'none' }}>
                        <div className="currencyIcon">
                          <BsUsbC style={{ fontSize: '25' }} />
                          <BsX style={{ margin: '-8px 0 0 16px' }} />
                          <span class="tooltiptext">
                            金額に変更がありましたので、以降時間と連携して自動計算されない。
                          </span>
                        </div>
                      </div>
                    </Col>

                    <Col span={4} className="input-col">
                      <Row style={{ width: '100%' }}>
                        <Col className="timer-input-col">
                          <Input
                            value={editedData[j]?.dandoriTime ? secondsToHms(editedData[j]?.dandoriTime) : timeHmsStr}
                            id={j + 'BendingDandoriTimeIn'}
                            style={{
                              width: '100%',
                              color:
                                processArr[j]?.dandoriTime === editedData[j]?.dandoriTime
                                  ? handleShowHide(
                                      Number(editedData[j]?.dandoriAmt),
                                      editedData[j]?.dandoriAmt,
                                      j + 'BendingDandoriAmtCu'
                                    )
                                  : 'red',
                            }}
                            className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                            onChange={(e) => {
                              settingNewDandoriAmtInArr(e.target.value, j, 'dandoriTime', 'input', 'input');
                            }}
                          ></Input>
                        </Col>
                        <Col className="timer-button-col">
                          {editMode ? (
                            <>
                              <CaretUpOutlined
                                style={{ fontSize: 17 + 'px' }}
                                onClick={() => {
                                  let editedTime = handelIncreaseTime(
                                    editedData[j]?.dandoriTime,
                                    '10min',
                                    j + 'BendingDandoriTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', '10min', 'inc');
                                }}
                              />
                              <CaretDownOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelDecreaseTime(
                                    editedData[j]?.dandoriTime,
                                    '10min',
                                    j + 'BendingDandoriTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', '10min', 'dec');
                                }}
                              />
                              <CaretUpOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelIncreaseTime(
                                    editedData[j]?.dandoriTime,
                                    'min',
                                    j + 'BendingDandoriTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'min', 'inc');
                                }}
                              />
                              <CaretDownOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelDecreaseTime(
                                    editedData[j]?.dandoriTime,
                                    'min',
                                    j + 'BendingDandoriTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'min', 'dec');
                                }}
                              />
                              <CaretUpOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelIncreaseTime(
                                    editedData[j]?.dandoriTime,
                                    'sec',
                                    j + 'BendingDandoriTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'sec', 'inc');
                                }}
                              />
                              <CaretDownOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelDecreaseTime(
                                    editedData[j]?.dandoriTime,
                                    'sec',
                                    j + 'BendingDandoriTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'sec', 'dec');
                                }}
                              />
                            </>
                          ) : (
                            <></>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col span={1} className="oyabuhin-detail-col"></Col>
                    <Col span={5} className="formula-column"></Col>
                  </Row>
                </div>
              );
            }
            return arr;
          })()}
          <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
            <Col span={4} className="oyabuhin-detail-col">
              <span style={{ marginLeft: '20px' }}>プログラム作成時間</span>
            </Col>
            <Col span={3} className="oyabuhin-detail-col">
              {/* ¥ */}
              <label id="BendingProgramAmt">
                {processArr[0]?.programAmt ? JPYs.format(Math.floor(processArr[0]?.programAmt)) : amtEmptyStr}
              </label>
            </Col>
            <Col span={3} className="oyabuhin-detail-col">
              <label id="BendingProgramTime">
                {processArr[0]?.programTime ? secondsToHms(processArr[0]?.programTime) : timeHmsStr}
              </label>
            </Col>
            <Col span={4} className="input-col">
              <CurrencyInput
                id="BendingProgramAmtIn"
                value={editedData[0]?.programAmt ? Math.floor(editedData[0]?.programAmt) : 0}
                defaultValue={0}
                onValueChange={(e) => {
                  if (editedData[0]?.programAmt != e) {
                    /* setEditedHandelingAmt(e); */
                    settingNewDandoriAmtInArr(e, 0, 'programAmt', '', '');
                    checkInputWithColor(false, '', 'BendingProgramAmt', e, 'amt');
                  }
                }}
                style={{
                  border: 'none',
                  color:
                    programAmt === editedData[0]?.programAmt
                      ? 'black'
                      : checkShowHideIcon('BendingProgramAmtCu') === 'none'
                      ? '#4cc12f'
                      : 'red',
                }}
                className={editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'}
                prefix="¥"
                decimalsLimit={100}
              />

              <div id={'BendingProgramAmtCu'} style={{ display: 'none' }} className="currencyReset">
                <div className="currencyIcon">
                  <BsUsbC style={{ fontSize: '25' }} />
                  <BsX style={{ margin: '-8px 0 0 16px' }} />
                  <span class="tooltiptext">金額に変更がありましたので、以降時間と連携して自動計算されない。</span>
                </div>
              </div>
            </Col>
            <Col span={4} className="input-col">
              <Row style={{ width: '100%' }}>
                <Col className="timer-input-col">
                  <Input
                    value={editedData[0]?.programTime ? secondsToHms(editedData[0]?.programTime) : timeHmsStr}
                    id="BendingProgramTimeIn"
                    style={{
                      width: '100%',
                      color:
                        editedData[0]?.programTime === programTime
                          ? handleShowHide(Number(programAmt), Number(editedData[0]?.programAmt), 'BendingProgramAmtCu')
                          : 'red',
                    }}
                    className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                    onChange={(e) => {
                      settingNewDandoriAmtInArr(e.target.value, 0, 'programTime', 'input', 'input');
                    }}
                  ></Input>
                </Col>
                <Col className="timer-button-col">
                  {editMode ? (
                    <>
                      <CaretUpOutlined
                        style={{ fontSize: 17 + 'px' }}
                        onClick={() => {
                          let editedTime = handelIncreaseTime(
                            editedData[0]?.programTime,
                            '10min',
                            'BendingProgramTime'
                          );
                          settingNewDandoriAmtInArr(editedTime, 0, 'programTime', '10min', 'inc');
                        }}
                      />
                      <CaretDownOutlined
                        style={{ fontSize: 17 + 'px', color: '#000' }}
                        onClick={() => {
                          let editedTime = handelDecreaseTime(
                            editedData[0]?.programTime,
                            '10min',
                            'BendingProgramTime'
                          );
                          settingNewDandoriAmtInArr(editedTime, 0, 'programTime', '10min', 'dec');
                        }}
                      />
                      <CaretUpOutlined
                        style={{ fontSize: 17 + 'px', color: '#000' }}
                        onClick={() => {
                          let editedTime = handelIncreaseTime(editedData[0]?.programTime, 'min', 'BendingProgramTime');
                          settingNewDandoriAmtInArr(editedTime, 0, 'programTime', 'min', 'inc');
                        }}
                      />
                      <CaretDownOutlined
                        style={{ fontSize: 17 + 'px', color: '#000' }}
                        onClick={() => {
                          let editedTime = handelDecreaseTime(editedData[0]?.programTime, 'min', 'BendingProgramTime');
                          settingNewDandoriAmtInArr(editedTime, 0, 'programTime', 'min', 'dec');
                        }}
                      />
                      <CaretUpOutlined
                        style={{ fontSize: 17 + 'px', color: '#000' }}
                        onClick={() => {
                          let editedTime = handelIncreaseTime(editedData[0]?.programTime, 'sec', 'BendingProgramTime');
                          settingNewDandoriAmtInArr(editedTime, 0, 'programTime', 'sec', 'inc');
                        }}
                      />
                      <CaretDownOutlined
                        style={{ fontSize: 17 + 'px', color: '#000' }}
                        onClick={() => {
                          let editedTime = handelDecreaseTime(editedData[0]?.programTime, 'sec', 'BendingProgramTime');
                          settingNewDandoriAmtInArr(editedTime, 0, 'programTime', 'sec', 'dec');
                        }}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
            </Col>
            <Col span={1} className="oyabuhin-detail-col"></Col>
            <Col span={5} className="formula-column"></Col>
          </Row>

          <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
            <Col span={4} className="oyabuhin-detail-col">
              <span style={{ marginLeft: '20px' }}>曲長段取時間</span>
            </Col>
            <Col span={3} className="oyabuhin-detail-col">
              {/* ¥ */}
              <label id="BendingMageDandoriAmt">
                {processArr[0]?.mageDandoriAmt ? JPYs.format(Math.floor(processArr[0]?.mageDandoriAmt)) : amtEmptyStr}
              </label>
            </Col>
            <Col span={3} className="oyabuhin-detail-col">
              <label id="BendingMageDandoriTime">
                {processArr[0]?.mageDandoriTime ? secondsToHms(processArr[0]?.mageDandoriTime) : timeHmsStr}
              </label>
            </Col>
            <Col span={4} className="input-col">
              <CurrencyInput
                id="BendingMageDandoriAmtIn"
                value={editedData[0]?.mageDandoriAmt ? Math.floor(editedData[0]?.mageDandoriAmt) : 0}
                defaultValue={0}
                onValueChange={(e) => {
                  if (editedData[0]?.mageDandoriAmt != e) {
                    /* setEditedHandelingAmt(e); */
                    settingNewDandoriAmtInArr(e, 0, 'mageDandoriAmt', '', '');
                    checkInputWithColor(false, '', 'BendingMageDandoriAmt', e, 'amt');
                  }
                }}
                style={{
                  border: 'none',
                  color:
                    mageDandoriAmt === editedData[0]?.mageDandoriAmt
                      ? 'black'
                      : checkShowHideIcon('BendingMageDandoriAmtCu') === 'none'
                      ? '#4cc12f'
                      : 'red',
                }}
                className={editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'}
                prefix="¥"
                decimalsLimit={100}
              />

              <div id={'BendingMageDandoriAmtCu'} style={{ display: 'none' }} className="currencyReset">
                <div className="currencyIcon">
                  <BsUsbC style={{ fontSize: '25' }} />
                  <BsX style={{ margin: '-8px 0 0 16px' }} />
                  <span class="tooltiptext">金額に変更がありましたので、以降時間と連携して自動計算されない。</span>
                </div>
              </div>
            </Col>
            <Col span={4} className="input-col">
              <Row style={{ width: '100%' }}>
                <Col className="timer-input-col">
                  <Input
                    value={editedData[0]?.mageDandoriTime ? secondsToHms(editedData[0]?.mageDandoriTime) : timeHmsStr}
                    id="BendingMageDandoriTimeIn"
                    style={{
                      width: '100%',
                      color:
                        editedData[0]?.mageDandoriTime === mageDandoriTime
                          ? handleShowHide(
                              Number(mageDandoriAmt),
                              Number(editedData[0]?.mageDandoriAmt),
                              'BendingMageDandoriAmtCu'
                            )
                          : 'red',
                    }}
                    className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                    onChange={(e) => {
                      settingNewDandoriAmtInArr(e.target.value, 0, 'mageDandoriTime', 'input', 'input');
                    }}
                  ></Input>
                </Col>
                <Col className="timer-button-col">
                  {editMode ? (
                    <>
                      <CaretUpOutlined
                        style={{ fontSize: 17 + 'px' }}
                        onClick={() => {
                          let editedTime = handelIncreaseTime(
                            editedData[0]?.mageDandoriTime,
                            '10min',
                            'BendingMageDandoriTime'
                          );
                          settingNewDandoriAmtInArr(editedTime, 0, 'mageDandoriTime', '10min', 'inc');
                        }}
                      />
                      <CaretDownOutlined
                        style={{ fontSize: 17 + 'px', color: '#000' }}
                        onClick={() => {
                          let editedTime = handelDecreaseTime(
                            editedData[0]?.mageDandoriTime,
                            '10min',
                            'BendingMageDandoriTime'
                          );
                          settingNewDandoriAmtInArr(editedTime, 0, 'mageDandoriTime', '10min', 'dec');
                        }}
                      />
                      <CaretUpOutlined
                        style={{ fontSize: 17 + 'px', color: '#000' }}
                        onClick={() => {
                          let editedTime = handelIncreaseTime(
                            editedData[0]?.mageDandoriTime,
                            'min',
                            'BendingMageDandoriTime'
                          );
                          settingNewDandoriAmtInArr(editedTime, 0, 'mageDandoriTime', 'min', 'inc');
                        }}
                      />
                      <CaretDownOutlined
                        style={{ fontSize: 17 + 'px', color: '#000' }}
                        onClick={() => {
                          let editedTime = handelDecreaseTime(
                            editedData[0]?.mageDandoriTime,
                            'min',
                            'BendingMageDandoriTime'
                          );
                          settingNewDandoriAmtInArr(editedTime, 0, 'mageDandoriTime', 'min', 'dec');
                        }}
                      />
                      <CaretUpOutlined
                        style={{ fontSize: 17 + 'px', color: '#000' }}
                        onClick={() => {
                          let editedTime = handelIncreaseTime(
                            editedData[0]?.mageDandoriTime,
                            'sec',
                            'BendingMageDandoriTime'
                          );
                          settingNewDandoriAmtInArr(editedTime, 0, 'mageDandoriTime', 'sec', 'inc');
                        }}
                      />
                      <CaretDownOutlined
                        style={{ fontSize: 17 + 'px', color: '#000' }}
                        onClick={() => {
                          let editedTime = handelDecreaseTime(
                            editedData[0]?.mageDandoriTime,
                            'sec',
                            'BendingMageDandoriTime'
                          );
                          settingNewDandoriAmtInArr(editedTime, 0, 'mageDandoriTime', 'sec', 'dec');
                        }}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
            </Col>
            <Col span={1} className="oyabuhin-detail-col"></Col>
            <Col span={5} className="formula-column"></Col>
          </Row>
        </div>
        {/*  作業金額/時間 */}
        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col" onClick={(e) => handleCollpse('BendingSagyoTotal')}>
            <div style={{ display: 'flex' }}>
              <CaretUpOutlined
                id={'arrowUpBendingSagyoTotal'}
                style={{ fontSize: 17, marginTop: 2, display: 'none' }}
              />

              <CaretDownOutlined id={'arrowDownBendingSagyoTotal'} style={{ fontSize: 17, marginTop: 2 }} />

              <label style={{ marginLeft: 5 }}> 加工金額/時間</label>
            </div>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalSagyoAmt">{sagyoAmtSum ? JPYs.format(Math.floor(sagyoAmtSum)) : amtEmptyStr}</label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalSagyoTime">{sagyoTimeSum ? secondsToHms(sagyoTimeSum) : timeHmsStr}</label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalSagyoAmtIn" className="resetColor">
              {totalSagyoAmt ? JPYs.format(Math.floor(totalSagyoAmt)) : amtEmptyStr}
            </label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalSagyoTimeIn" className="resetColor">
              {totalSagyoTime ? secondsToHms(totalSagyoTime) : timeHmsStr}
            </label>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={5} className="formula-column">
            <Popover content={contentSagyo}>作業金額の総合計</Popover>
          </Col>
        </Row>
        <div id={'divBendingSagyoTotal'} style={{ display: 'none' }}>
          {(() => {
            const arr = [];

            for (let i = 0; i < processArr?.length; i++) {
              arr.push(
                <div>
                  <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                    <Col span={4} className="oyabuhin-detail-col" onClick={(e) => subpartDetail(i)}>
                      <div style={{ display: 'flex', marginLeft: '5px' }}>
                        <CaretUpOutlined id={'arrowUpBendingIQ3' + i} style={{ fontSize: 17, marginTop: 2 }} />
                        <CaretDownOutlined
                          id={'arrowDownBendingIQ3' + i}
                          style={{ fontSize: 17, marginTop: 2, display: 'none' }}
                        />
                        <span style={{ marginLeft: '5px' }}>{processArr[i]?.processType}</span>
                      </div>
                    </Col>
                    <Col span={3} className="oyabuhin-detail-col" onClick={(e) => subpartDetail(i)}>
                      {/* ¥ */}
                      <label id={i + 'bendingSagyoAmt'}>
                        {processArr[i]?.sagyoAmt ? JPYs.format(Math.floor(processArr[i]?.sagyoAmt)) : amtEmptyStr}
                      </label>
                    </Col>
                    <Col span={3} className="oyabuhin-detail-col" onClick={(e) => subpartDetail(i)}>
                      <label id={i + 'bendingSagyoTime'}>
                        {processArr[i]?.sagyoTime ? secondsToHms(processArr[i]?.sagyoTime) : timeHmsStr}
                      </label>
                    </Col>
                    <Col span={4} className="input-col">
                      <CurrencyInput
                        id={i + 'bendingSagyoAmtIn'}
                        value={editedData[i]?.sagyoAmt ? Math.floor(editedData[i]?.sagyoAmt) : 0}
                        defaultValue={0}
                        onValueChange={(e) => {
                          if (editedData[i]?.sagyoAmt != e) {
                            settingNewDandoriAmtInArr(e, i, 'sagyoAmt', '', '');
                            checkInputWithColor(false, '', i + 'bendingSagyoAmt', e, 'amt');
                          }
                        }}
                        style={{
                          border: 'none',
                          color:
                            processArr[i]?.sagyoAmt === editedData[i]?.sagyoAmt
                              ? 'black'
                              : checkShowHideIcon(i + 'bendingSagyoAmtCu') === 'none'
                              ? '#4cc12f'
                              : 'red',
                        }}
                        className={
                          editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'
                        }
                        prefix="¥"
                        decimalsLimit={100}
                      />

                      <div id={i + 'bendingSagyoAmtCu'} style={{ display: 'none' }} className="currencyReset">
                        <div className="currencyIcon">
                          <BsUsbC style={{ fontSize: '25' }} />
                          <BsX style={{ margin: '-8px 0 0 16px' }} />
                          <span class="tooltiptext">
                            金額に変更がありましたので、以降時間と連携して自動計算されない。
                          </span>
                        </div>
                      </div>
                    </Col>
                    <Col span={4} className="input-col">
                      <Row style={{ width: '100%' }}>
                        <Col className="timer-input-col">
                          <Input
                            value={editedData[i]?.sagyoTime ? secondsToHms(editedData[i]?.sagyoTime) : timeHmsStr}
                            id={i + 'bendingSagyoTimeIn'}
                            style={{
                              width: '100%',
                              color:
                                processArr[i]?.sagyoTime === editedData[i]?.sagyoTime
                                  ? 'black'
                                  : oldEditedData[i]?.partCnt === editedData[i]?.partCnt &&
                                    oldEditedData[i]?.workTimeSize === editedData[i]?.workTimeSize
                                  ? 'red'
                                  : '#4cc12f',
                            }}
                            className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                            onChange={(e) => {
                              settingNewDandoriAmtInArr(e.target.value, i, 'sagyoTime', 'input', 'input');
                            }}
                          ></Input>
                        </Col>
                        <Col className="timer-button-col">
                          {editMode ? (
                            <>
                              <CaretUpOutlined
                                style={{ fontSize: 17 + 'px' }}
                                onClick={() => {
                                  let editedTime = handelIncreaseTime(
                                    editedData[i]?.sagyoTime,
                                    '10min',
                                    i + 'bendingSagyoTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', '10min', 'inc');
                                }}
                              />
                              <CaretDownOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelDecreaseTime(
                                    editedData[i]?.sagyoTime,
                                    '10min',
                                    i + 'bendingSagyoTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', '10min', 'dec');
                                }}
                              />
                              <CaretUpOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelIncreaseTime(
                                    editedData[i]?.sagyoTime,
                                    'min',
                                    i + 'bendingSagyoTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', 'min', 'inc');
                                }}
                              />
                              <CaretDownOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelDecreaseTime(
                                    editedData[i]?.sagyoTime,
                                    'min',
                                    i + 'bendingSagyoTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', 'min', 'dec');
                                }}
                              />
                              <CaretUpOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelIncreaseTime(
                                    editedData[i]?.sagyoTime,
                                    'sec',
                                    i + 'bendingSagyoTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', 'sec', 'inc');
                                }}
                              />
                              <CaretDownOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelDecreaseTime(
                                    editedData[i]?.sagyoTime,
                                    'sec',
                                    i + 'bendingSagyoTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', 'sec', 'dec');
                                }}
                              />
                            </>
                          ) : (
                            <></>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col span={1} className="oyabuhin-detail-col" onClick={(e) => subpartDetail(i)}></Col>
                    <Col span={5} className="formula-column" onClick={(e) => subpartDetail(i)}></Col>
                  </Row>
                  <div id={'bendingProcessIQ3' + i} style={{ display: 'none' }}>
                    <div>
                      {/* 作業金額/時間 */}

                      <div>
                        {/* 加工種類/仮付箇所 */}
                        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                          <Col span={4} className="oyabuhin-detail-col">
                            <span className="ddl_2_noImg">数量/1つ当たりの加工時間</span>
                          </Col>
                          <Col span={3} className="oyabuhin-detail-col">
                            <label id="bendingAmount">{processArr[i]?.partCnt}</label>
                          </Col>
                          <Col span={3} className="oyabuhin-detail-col">
                            <label id={i + 'bendingTimeForOne'}>
                              {processArr[i]?.workTimeSize ? secondsToHms(processArr[i]?.workTimeSize) : timeHmsStr}
                            </label>
                          </Col>

                          <Col span={4} className="input-col">
                            <Input
                              value={editedData[i]?.partCnt}
                              id={i + 'bendingAmountIn'}
                              min={0}
                              className={editMode ? 'input-editable' : 'input-non-editable '}
                              controls={false}
                              style={{
                                width: '45%',
                                border: 'none',
                                color: Number(editedData[i]?.partCnt) === processArr[i]?.partCnt ? 'black' : 'red',
                              }}
                              onKeyPress={handleKeyPress}
                              onChange={(e) => settingNewDandoriAmtInArr(e.target.value, i, 'partCnt', '', '')}
                            />
                          </Col>

                          <Col span={4} className="input-col">
                            <Row style={{ width: '100%' }}>
                              <Col className="timer-input-col">
                                <Input
                                  value={
                                    editedData[i]?.workTimeSize ? secondsToHms(editedData[i]?.workTimeSize) : timeHmsStr
                                  }
                                  id={i + 'bendingTimeForOneIn'}
                                  min={0}
                                  className={editMode ? 'input-editable' : 'input-non-editable '}
                                  controls={false}
                                  style={{
                                    width: '100%',
                                    border: 'none',
                                    color:
                                      editedData[i]?.workTimeSize === processArr[i]?.workTimeSize ? 'black' : 'red',
                                  }}
                                  onKeyPress={handleKeyPress}
                                  onChange={(e) => settingNewDandoriAmtInArr(e.target.value, i, 'workTimeSize', '', '')}
                                />
                              </Col>
                              <Col className="timer-button-col">
                                {editMode ? (
                                  <>
                                    <CaretUpOutlined
                                      style={{ fontSize: 17 + 'px' }}
                                      onClick={() => {
                                        let editedTime = handelIncreaseTime(
                                          editedData[i]?.workTimeSize,
                                          '10min',
                                          'workTimeSize'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, i, 'workTimeSize', '10min', 'inc');
                                      }}
                                    />
                                    <CaretDownOutlined
                                      style={{ fontSize: 17 + 'px', color: '#000' }}
                                      onClick={() => {
                                        let editedTime = handelDecreaseTime(
                                          editedData[i]?.workTimeSize,
                                          '10min',
                                          'workTimeSize'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, i, 'workTimeSize', '10min', 'dec');
                                      }}
                                    />
                                    <CaretUpOutlined
                                      style={{ fontSize: 17 + 'px', color: '#000' }}
                                      onClick={() => {
                                        let editedTime = handelIncreaseTime(
                                          editedData[i]?.workTimeSize,
                                          'min',
                                          'workTimeSize'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, i, 'workTimeSize', 'min', 'inc');
                                      }}
                                    />
                                    <CaretDownOutlined
                                      style={{ fontSize: 17 + 'px', color: '#000' }}
                                      onClick={() => {
                                        let editedTime = handelDecreaseTime(
                                          editedData[i]?.workTimeSize,
                                          'min',
                                          'workTimeSize'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, i, 'workTimeSize', 'min', 'dec');
                                      }}
                                    />
                                    <CaretUpOutlined
                                      style={{ fontSize: 17 + 'px', color: '#000' }}
                                      onClick={() => {
                                        let editedTime = handelIncreaseTime(
                                          editedData[i]?.workTimeSize,
                                          'sec',
                                          'workTimeSize'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, i, 'workTimeSize', 'sec', 'inc');
                                      }}
                                    />
                                    <CaretDownOutlined
                                      style={{ fontSize: 17 + 'px', color: '#000' }}
                                      onClick={() => {
                                        let editedTime = handelDecreaseTime(
                                          editedData[i]?.workTimeSize,
                                          'sec',
                                          'workTimeSize'
                                        );
                                        settingNewDandoriAmtInArr(editedTime, i, 'workTimeSize', 'sec', 'dec');
                                      }}
                                    />
                                  </>
                                ) : (
                                  <></>
                                )}
                              </Col>
                            </Row>
                          </Col>
                          <Col span={1} className="oyabuhin-detail-col"></Col>
                          <Col span={4} className="formula-column"></Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
            return arr;
          })()}
        </div>
      </div>
    </div>
  );
});

export default IQ3KouteiCalculate_Bending;
