import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';

import { Row, Col, Input, Radio, Checkbox, Select, Table as AntTable, Modal, Button, Image, Space } from 'antd';
import { CloseOutlined, PlusCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { Table } from 'react-bootstrap';
import '../../assets/styles/common.css';
import { WorkType, WorkTypeGroup } from '../common/enums';

const RadioGroup = Radio.Group;

let yousetsu1Datas = [];
yousetsu1Datas.push({
  key: 1,
  yousetsuType: 'アルゴン',
  senchou: 0,
  senchouKaisu: 0,
  senchouYousetsushiage: 'on',

  karitsukeCnt: 0,
  karitsukeKaisu: 0,
  karitsukeYousetsushiage: 'off',
});
let yousetsu2Datas = [];
yousetsu2Datas.push({
  key: 1,
  otherYousetsuType: 'ナット溶接',
  otherSenchou: '-',
  count: '1',
  otherSenchouCnt: '',
  otherSenchouKaisu: '',
  otherSenchouYousetsushiage: 'off',
});
let initialYousetsu1 = [];
initialYousetsu1.push({
  key: 1,
  senchou: '',

  senchouKaisu: '',
  senchouYousetsushiage: 'on',

  karitsukeCnt: '',
  karitsukeKaisu: '',
  karitsukeYousetsushiage: 'on',
});
let initialYousetsu2 = [];
initialYousetsu2.push({
  key: 1,
  senchou: 100,

  senchouKaisu: 0,
  senchouYousetsushiage: 'on',

  karitsukeCnt: 0,
  karitsukeKaisu: 0,
  karitsukeYousetsushiage: 'on',
});

const IQ3KouteiInput_Yousetsu = forwardRef((props, ref) => {
  // const [editMode, setEditMode] = useState(false);
  // add new
  const [addModal, setAddModal] = useState(false);
  const [weldingList, setWeldingList] = useState([]);
  const [subWeldingList, setSubWeldingList] = useState([]);

  const [yousetsuTypes, setYousetsuTypes] = useState([]);
  const [subYousetsuTypes, setSubYousetsuTypes] = useState([]);

  // 編集モード
  // const editMode = props.editMode;

  useEffect(() => {
    const processes = props.processMaster;
    const weldingDetails = processes?.filter((i) => i.workType === WorkType.SmWelding);
    if (weldingDetails?.length > 0) {
      let yousetsu = weldingDetails[0].details?.processDetailTypes?.filter((i) => i.group === WorkTypeGroup.SmWelding);
      setYousetsuTypes(yousetsu);
      let subYousetsu = weldingDetails[0].details?.processDetailTypes?.filter(
        (i) => i.group === WorkTypeGroup.SmInstSize || i.group === WorkTypeGroup.SmInstThick
      );
      setSubYousetsuTypes(subYousetsu);
    }
    let data = props.weldingInfo;

    if (data != undefined) {
      setWeldingList(data.details[0].weldingList);

      setSubWeldingList(data.details[0].subWeldingList);
    }
  }, [props.weldingInfo, props.processMaster]);

  useImperativeHandle(ref, () => ({
    setEditModeChange: setEditModeChange,
  }));

  const addNew = () => {
    setAddModal(true);
  };
  const addOK = () => {
    setAddModal(false);
  };
  const addCancel = () => {
    setAddModal(false);
  };

  const addYousetsu1 = (id) => {
    // if (weldingList?.length > 0) {
    //   //
    //   const data = {
    //     key: weldingList.slice(-1)[0].key + 1,
    //     yousetsuType: 'アルゴン',
    //     senchou: 0.0,

    //     senchouKaisu: 0,
    //     senchouYousetsushiage: 'on',

    //     karitsukeCnt: 0,
    //     karitsukeKaisu: 0,
    //     karitsukeYousetsushiage: 'on',
    //     total1: 0,
    //     total2: 0,
    //   };
    //   let addArr = [...weldingList, data];
    //   setWeldingList(addArr);
    // }
    const copyData = weldingList?.slice(); // Create a copy of the original array

    const insertIndex = weldingList?.findIndex((item) => item.id === id); // Find the clicked index of the item
    const largestId = weldingList?.reduce((maxId, item) => {
      return item.id > maxId ? item.id : maxId;
    }, 0);

    if (insertIndex !== -1) {
      const data = {
        id: largestId + 1,
        yousetsuType: copyData[insertIndex].yousetsuType,
        senchou: copyData[insertIndex].senchou,

        senchouKaisu: copyData[insertIndex].senchouKaisu,
        senchouYousetsushiage: copyData[insertIndex].senchouYousetsushiage,

        karitsukeCnt: copyData[insertIndex].karitsukeCnt,
        karitsukeKaisu: copyData[insertIndex].karitsukeKaisu,
        karitsukeYousetsushiage: copyData[insertIndex].karitsukeYousetsushiage,
        total1: copyData[insertIndex].total1,
        total2: copyData[insertIndex].total2,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setWeldingList(copyData);
      const newWeldingInfo = { ...props.weldingInfo };
      newWeldingInfo.details[0].weldingList = copyData;
      props.updateWelding(newWeldingInfo);
    }
  };
  const deleteYousetsu1 = (id) => {
    if (weldingList?.length > 1) {
      // setWeldingList(
      //   weldingList?.filter((j) => {
      //     return ![e]?.includes(j.key);
      //   })
      // );
      const updatedData = weldingList?.filter((item) => item.id !== id);

      setWeldingList(updatedData);
      const newWeldingInfo = { ...props.weldingInfo };
      newWeldingInfo.details[0].weldingList = updatedData;
      props.updateWelding(newWeldingInfo);
      // const dataRow = [...weldingList];
      // dataRow.splice(index, 1);
      // setWeldingList(dataRow);
    }
  };
  const addYousetsu2 = (id) => {
    //
    // const data = {
    //   key: subWeldingList.slice(-1)[0].key + 1,
    //   otherYousetsuType: '点付',
    //   otherSenchou: 0,
    //   otherSenchouCnt: 0,
    //   otherSenchouKaisu: 0,
    //   otherSenchouYousetsushiage: 'on',
    // };
    // let addArr = [...subWeldingList, data];
    // setSubWeldingList(addArr);
    const copyData = subWeldingList?.slice(); // Create a copy of the original array

    const insertIndex = subWeldingList?.findIndex((item) => item.id === id); // Find the clicked index of the item
    const largestId = subWeldingList?.reduce((maxId, item) => {
      return item.id > maxId ? item.id : maxId;
    }, 0);

    if (insertIndex !== -1) {
      const data = {
        id: largestId + 1,
        subWeldingType: copyData[insertIndex].subWeldingType,
        daimeter: copyData[insertIndex].daimeter,
        number: copyData[insertIndex].number,
        count: copyData[insertIndex].count,
        yousetsushiage: copyData[insertIndex].yousetsushiage,
        total: copyData[insertIndex].total,
        subWeldingGroupType: copyData[insertIndex].subWeldingGroupType,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setSubWeldingList(copyData);
      const newWeldingInfo = { ...props.weldingInfo };
      newWeldingInfo.details[0].subWeldingList = copyData;
      props.updateWelding(newWeldingInfo);
    }
  };
  const deleteYousetsu2 = (id) => {
    if (subWeldingList?.length > 1) {
      // setSubWeldingList(
      //   subWeldingList?.filter((j) => {
      //     return ![e]?.includes(j.key);
      //   })
      // );
      const updatedData = subWeldingList?.filter((item) => item.id !== id);

      setSubWeldingList(updatedData);
      const newWeldingInfo = { ...props.weldingInfo };
      newWeldingInfo.details[0].subWeldingList = updatedData;
      props.updateWelding(newWeldingInfo);
      // const dataRow = [...subWeldingList];
      // dataRow.splice(index, 1);
      // setSubWeldingList(dataRow);
    }
  };
  const onYousetsu1ValUpdate = (id, event, types) => {
    // const { name, value } = event.target;
    // const data = [...weldingList];
    // data[i][name] = value;
    // setWeldingList(data);
    const temp = JSON.parse(JSON.stringify(weldingList));
    let updatedData = [];
    if (types === 'yousetsuType') {
      updatedData = temp?.map((row) => {
        if (row.id === id) {
          return { ...row, [types]: event };
        }
        return row;
      });
      // setBendingList(updatedData);
    } else {
      updatedData = temp?.map((row) => {
        // if (row.id === id) {
        //   return { ...row, [types]: event.target.value };
        // }
        // return row;
        if (row.id === id) {
          const updatedRow = { ...row, [types]: event.target.value };
          if (types === 'senchou' || types === 'senchouKaisu') {
            // Calculate and update totalCount
            updatedRow.total1 = updatedRow.senchou * updatedRow.senchouKaisu;
          } else if (types === 'karitsukeCnt' || types === 'karitsukeKaisu') {
            // Calculate and update totalCount
            updatedRow.total2 = updatedRow.karitsukeCnt * updatedRow.karitsukeKaisu;
          }

          return updatedRow;
        }
        return row;
      });
      // setBendingList(updatedData);
    }
    setWeldingList(updatedData);
    const newWeldingInfo = { ...props.weldingInfo };
    newWeldingInfo.details[0].weldingList = updatedData;
    props.updateWelding(newWeldingInfo);
  };
  const onYousetsuRdoValUpdate = (id, e, rdoname) => {};
  const onYousetsu2ValUpdate = (id, event, types) => {
    // const { name, value } = event.target;
    // const data = [...subWeldingList];
    // data[i][name] = value;

    // setSubWeldingList(data);
    // subWeldingType
    const temp = JSON.parse(JSON.stringify(subWeldingList));
    let updatedData = [];
    if (types === 'subWeldingType') {
      updatedData = temp?.map((row) => {
        if (row.id === id) {
          const updatedRow = { ...row, [types]: event };
          const getGroup = subYousetsuTypes?.filter((i) => i.id === event);
          updatedRow.subWeldingGroupType = getGroup ? getGroup[0].group : 0;
          return updatedRow;
        }
        return row;
      });
    } else {
      updatedData = temp?.map((row) => {
        if (row.id === id) {
          const updatedRow = { ...row, [types]: event.target.value };
          if (types === 'daimeter' || types === 'number' || types === 'count') {
            // Calculate and update totalCount
            updatedRow.total = updatedRow.daimeter * updatedRow.number * updatedRow.count;
          }

          return updatedRow;
        }
        return row;
      });
    }
    setSubWeldingList(updatedData);
    const newWeldingInfo = { ...props.weldingInfo };
    newWeldingInfo.details[0].subWeldingList = updatedData;
    props.updateWelding(newWeldingInfo);
  };
  const onYousetsu1ValSelectUpdate = (i, event, name) => {
    if (name == 'yousetsuType') {
      const data = [...weldingList];
      data[i][name] = event;
      setWeldingList(data);
    } else {
      const data = [...subWeldingList];
      data[i][name] = event;
      setSubWeldingList(data);
    }
  };

  const firstYousetsuComponent = (
    <>
      {weldingList?.map((item, index) => {
        const {
          id,
          yousetsuType,
          senchou,
          senchouKaisu,
          senchouYousetsushiage,
          karitsukeCnt,
          karitsukeKaisu,
          total1,
          total2,
          karitsukeYousetsushiage,
        } = item;
        return (
          <>
            <tr key={index}>
              <td rowSpan={2} style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                {index + 1}
              </td>
              <td rowSpan={2} style={{ verticalAlign: 'middle' }}>
                {/* {editMode ? (
                  <> */}
                <Select
                  name="yousetsuType"
                  className="yousetsu-select"
                  // style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                  // onChange={(e) => setArugonSel(e.target.value)}
                  // onChange={(event) => onYousetsu1ValSelectUpdate(index, event, 'yousetsuType')}
                  onChange={(event) => onYousetsu1ValUpdate(id, event, 'yousetsuType')}
                  // defaultValue={yousetsuType[0].value}
                  value={yousetsuType}
                >
                  {yousetsuTypes?.map((option, index) => (
                    <Select.Option key={index} value={option.id}>
                      {option.name}
                    </Select.Option>
                  ))}
                </Select>
                {/* </>
                ) : (
                  <>{yousetsuType}</>
                )} */}
              </td>

              <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>線長</td>
              <td>
                <Input
                  name="senchou"
                  // className={editMode ? 'input-editable ' : 'input-non-editable '}
                  className="input-editable"
                  style={{ height: 32, textAlign: 'center' }}
                  value={senchou}
                  onChange={(event) => onYousetsu1ValUpdate(id, event, 'senchou')}
                ></Input>
              </td>
              <td>
                <Input
                  name="senchouKaisu"
                  // className={editMode ? 'input-editable ' : 'input-non-editable '}
                  className="input-editable"
                  style={{ height: 32, textAlign: 'center' }}
                  value={senchouKaisu}
                  onChange={(event) => onYousetsu1ValUpdate(id, event, 'senchouKaisu')}
                ></Input>
              </td>
              <td style={{ textAlign: 'center', verticalAlign: 'middle', padding: 0 }}>
                <RadioGroup
                  name="senchouYousetsushiage"
                  defaultValue={0}
                  className="radioCustomerGpEdit input-editable"
                  // onChange={arugonRadioChange}
                  onChange={(event) => onYousetsu1ValUpdate(id, event, 'senchouYousetsushiage')}
                  // onChange={(event) => onYousetsuRdoValUpdate(index, event, 'senchouYousetsushiage')}
                  value={senchouYousetsushiage}
                >
                  <Radio value={0}>適用</Radio>
                  <Radio value={1}>除外</Radio>
                </RadioGroup>
              </td>
              <td style={{ verticalAlign: 'middle' }}>
                <Input
                  // className={editMode ? 'input-editable ' : 'input-non-editable '}
                  className="input-editable"
                  style={{ pointerEvents: 'none', textAlign: 'center' }}
                  value={total1}
                ></Input>
              </td>

              {/* {editMode ? (
                <> */}
              <td
                style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '6%' }}
                className="min"
                rowSpan={2}
              >
                <Row justify="center">
                  <PlusCircleFilled
                    className="add-remove-icon"
                    style={{ marginLeft: 10, marginRight: 10 }}
                    // onClick={addYousetsu1}
                    onClick={() => addYousetsu1(id)}
                  />
                  <CloseCircleFilled className="add-remove-icon" onClick={() => deleteYousetsu1(id)} />
                </Row>
              </td>
              {/* </>
              ) : (
                <>
                  <td
                    rowSpan={2}
                    style={{ border: '1px', backgroundColor: 'white', verticalAlign: 'middle', width: '6%' }}
                  ></td>
                </>
              )} */}
            </tr>
            <tr>
              <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>仮付</td>
              <td>
                <Input
                  name="karitsukeCnt"
                  // className={editMode ? 'input-editable ' : 'input-non-editable '}
                  className="input-editable"
                  style={{ height: 32, textAlign: 'center' }}
                  value={karitsukeCnt}
                  // onChange={(event) => onYousetsu1ValUpdate(index, event)}
                  onChange={(event) => onYousetsu1ValUpdate(id, event, 'karitsukeCnt')}
                ></Input>
              </td>
              <td>
                <Input
                  name="karitsukeKaisu"
                  // className={editMode ? 'input-editable ' : 'input-non-editable '}
                  className="input-editable"
                  style={{ height: 32, textAlign: 'center' }}
                  value={karitsukeKaisu}
                  onChange={(event) => onYousetsu1ValUpdate(id, event, 'karitsukeKaisu')}
                ></Input>
              </td>
              <td style={{ textAlign: 'center', verticalAlign: 'middle', padding: 0 }}>
                <RadioGroup
                  name="karitsukeYousetsushiage"
                  defaultValue={0}
                  className="radioCustomerGpEdit input-editable"
                  // onChange={arugonRadioChange}
                  onChange={(event) => onYousetsu1ValUpdate(id, event, 'karitsukeYousetsushiage')}
                  value={karitsukeYousetsushiage}
                >
                  <Radio value={0}>適用</Radio>
                  <Radio value={1}>除外</Radio>
                </RadioGroup>
              </td>
              <td style={{ verticalAlign: 'middle' }}>
                <Input
                  // className={editMode ? 'input-editable ' : 'input-non-editable '}
                  className="input-editable"
                  style={{ pointerEvents: 'none', textAlign: 'center' }}
                  value={total2}
                ></Input>
              </td>
            </tr>
          </>
        );
      })}
    </>
  );
  const secondYousetsuComponent = (
    <>
      {subWeldingList?.map((item, index) => {
        const { id, subWeldingType, daimeter, number, count, yousetsushiage, total, subWeldingGroupType } = item;
        return (
          <>
            <tr key={index}>
              <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{index + 1}</td>
              <td style={{ verticalAlign: 'middle' }}>
                {/* {editMode ? (
                  <> */}
                <Select
                  name="otherYousetsuType"
                  className="yousetsu-select"
                  // onChange={(e) => setShudousutaddoSel(e.target.value)}
                  // onChange={(event) => onYousetsu1ValSelectUpdate(index, event, 'otherYousetsuType')}
                  onChange={(event) => onYousetsu2ValUpdate(id, event, 'subWeldingType')}
                  // style={{ fontSize: '13px' }}
                  // defaultValue={yousetsuType_tentsuke[0].value}
                  value={subWeldingType}
                >
                  {subYousetsuTypes?.map((option, index) => (
                    <Select.Option key={index} value={option.id}>
                      {option.name}
                    </Select.Option>
                  ))}
                </Select>
                {/* </>
                ) : (
                  <>{otherYousetsuType}</>
                )} */}
              </td>

              <td>
                <Input
                  name="otherSenchou"
                  // className={
                  //   editMode &&
                  //   !(
                  //     otherYousetsuType === '点付' ||
                  //     otherYousetsuType === 'テーブルスポット' ||
                  //     otherYousetsuType === '定置スポット'
                  //   )
                  //     ? 'input-editable '
                  //     : 'input-non-editable '
                  // }
                  className="input-editable"
                  style={{ height: 32, textAlign: 'center' }}
                  value={daimeter}
                  onChange={(event) => onYousetsu2ValUpdate(id, event, 'daimeter')}
                ></Input>
              </td>
              <td>
                <Input
                  name="count"
                  // className={editMode ? 'input-editable ' : 'input-non-editable '}
                  className="input-editable"
                  style={{ height: 32, textAlign: 'center' }}
                  value={number}
                  onChange={(event) => onYousetsu2ValUpdate(id, event, 'number')}
                ></Input>
              </td>
              <td>
                <Input
                  name="otherSenchouKaisu"
                  // className={editMode ? 'input-editable ' : 'input-non-editable '}
                  className="input-editable"
                  style={{ height: 32, textAlign: 'center' }}
                  value={count}
                  onChange={(event) => onYousetsu2ValUpdate(id, event, 'count')}
                ></Input>
              </td>
              {/* <td style={{ textAlign: 'center', verticalAlign: 'middle', padding: 0 }}>
                <RadioGroup
                  name="otherSenchouYousetsushiage"
                  defaultValue={0}
                  className="radioCustomerGpEdit input-editable"
                  onChange={(event) => onYousetsu2ValUpdate(id, event, 'yousetsushiage')}
                  value={yousetsushiage}
                >
                  <Radio value={0}>適用</Radio>
                  <Radio value={1}>除外</Radio>
                </RadioGroup>
              </td> */}
              <td>
                <Input
                  name="otherSenchouCnt"
                  // className={editMode ? 'input-editable ' : 'input-non-editable '}
                  className="input-editable"
                  style={{ height: 32, textAlign: 'center', pointerEvents: 'none' }}
                  value={total}
                ></Input>
              </td>
              {/* {editMode ? (
                <> */}
              <td
                style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '6%' }}
                className="min"
              >
                <Row justify="center">
                  <PlusCircleFilled
                    className="add-remove-icon"
                    style={{ marginLeft: 10, marginRight: 10 }}
                    // onClick={addYousetsu2}
                    onClick={() => addYousetsu2(id)}
                  />
                  <CloseCircleFilled className="add-remove-icon" onClick={(event) => deleteYousetsu2(id)} />
                </Row>
              </td>
              {/* </>
              ) : (
                <>
                  <td style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '6%' }}></td>
                </>
              )} */}
            </tr>
          </>
        );
      })}
    </>
  );

  return (
    <>
      {/* 溶接 */}

      <Row className="mt-1 registerModal" align="middle">
        <Table className="kouteiinput " style={{ width: '83%' }}>
          <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
            <tr style={{ textAlign: 'center' }}>
              <th style={{ width: '4%' }} className="tbl-header">
                No
              </th>
              <th style={{ width: '18%' }} className="tbl-header">
                溶接種類
              </th>
              <th style={{ width: '11%' }} className="tbl-header">
                種類/形状
              </th>
              <th style={{ width: '11%' }} className="tbl-header">
                長さ/箇所
              </th>
              <th style={{ width: '11%' }} className="tbl-header">
                回数
              </th>
              <th style={{ width: '11%' }} className="tbl-header">
                溶接仕上
              </th>
              <th style={{ width: '11%' }} className="tbl-header">
                合計
              </th>
            </tr>
          </thead>
          <tbody>{weldingList?.length === 0 ? <></> : <>{firstYousetsuComponent}</>} </tbody>
        </Table>
      </Row>

      <Row className="mt-1 registerModal" align="middle">
        <Table className="kouteiinput" style={{ width: '72%' }}>
          <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
            <tr style={{ textAlign: 'center' }}>
              <th style={{ width: '4%' }} className="tbl-header">
                No
              </th>
              <th style={{ width: '18%' }} className="tbl-header">
                サプ溶接種類
              </th>
              <th style={{ width: '11%' }} className="tbl-header">
                径(mm)
              </th>
              <th style={{ width: '11%' }} className="tbl-header">
                数
              </th>
              <th style={{ width: '11%' }} className="tbl-header">
                回数
              </th>
              {/* <th style={{ width: '11%' }} className="tbl-header">
                溶接仕上
              </th> */}
              <th style={{ width: '11%' }} className="tbl-header">
                合計
              </th>
            </tr>
          </thead>
          <tbody>{subWeldingList?.length === 0 ? <></> : <>{secondYousetsuComponent}</>} </tbody>
        </Table>
      </Row>
    </>
  );
});

export default IQ3KouteiInput_Yousetsu;
