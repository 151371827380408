/**
 * クラス名：板金の装置設定の段取設定画面
 * 作成者：チュー
 * 作成日：2023/06/21
 * バージョン：1.0
 */
import { Row, Col, Input, Button, Space, Checkbox, Popover } from 'antd';
import React, { useEffect, forwardRef, useState, useRef } from 'react';
import { Table } from 'react-bootstrap';
import { PlusCircleFilled, CloseCircleFilled, CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { isEmpty } from '@rc-component/mini-decimal/es/numberUtil';

import { commonModal } from '../../../common/CommonModal';
import {
  souchiHandleIncreaseTime,
  souchiHandleDecreaseTime,
  deleteListColData,
  updateListColData,
  sortListData,
  secondsToHms,
  setTimetoSec,
  handleKeyPress,
  getProcessDetailTypesInfo,
  getProcessDetailTypeName,
  updateList,
  sortListByMasterData,
  getInputBorderStyle,
  toDecimal,
} from '../../../common/Common';
import { WorkType } from '../../../common/enums';

const Device_SetupSetting = forwardRef((props, ref) => {
  const [editMode, setEditMode] = useState(false);
  const [category, setCategory] = useState('');

  const [isWeight, setIsWeight] = useState(false);
  const [isArea, setIsArea] = useState(false);

  // 基本段取時間(ベンディング)
  const [isMagesuu, setIsMageSuu] = useState(false);
  const [magesuuTemp, setMagesuuTemp] = useState([]);
  const [mageSuuData, setMageSuuData] = useState([]);
  const [magesuuList, setMagesuuList] = useState([]);

  // 要素数
  const [isYousosuu, setIsYousosuu] = useState(false);
  const [yousosuu, setYousosuu] = useState([]);
  const [yousosuuTemp, setYousosuuTemp] = useState([]);
  const [yousosuuLst, setYousosuuLst] = useState([]);

  const [sagyouYousuuList, setSagyouYousuuList] = useState([]);
  const [kensaJikanKeisuuList, setKensaJikanKesisuuList] = useState([]);

  //   曲げ個数
  const [mageKosuuData, setMageKosuuData] = useState([]);
  const [mageKosuuDataTemp, setMageKosuuDataTemp] = useState([]);
  const [mageKosuuList, setMageKosuuList] = useState([]);
  const [isMageKosuu, setIsMageKosuu] = useState(false);

  // 加工方法
  const [processMethod, setProcessMethod] = useState('');

  // 子部品サイズ係数(kg&cm)
  const [weightData, setWeightData] = useState([]);
  const [detailsWeightTemp, setDetailsWeightTemp] = useState([]);
  const [areaData, setAreaData] = useState([]);
  const [detailsAreaTemp, setDetailsAreaTemp] = useState([]);
  const [sizeKeisuuList, setSizeKeisuuList] = useState([]);

  // 曲長段取時間
  const [isLength, setIsLength] = useState(false);
  const [lengthData, setLengthData] = useState([]);
  const [detailsLengthTemp, setDetailsLengthTemp] = useState([]);
  const [magechouDantoriJikanList, setMagechouDantoriJikanList] = useState([]);

  // 基本段取時間
  const [setupTime, setSetupTime] = useState('00:00:00');

  // 段取設定
  const [dantoriKouteiDetails, setDantoriKouteiDetails] = useState([]);

  // 段取個数係数
  const [kosuuData, setKosuuData] = useState([]);
  const [kosuuDataTemp, setKosuuDataTemp] = useState([]);
  const [kosuuList, setKosuuList] = useState([]);
  const [isKosuu, setIsKosuu] = useState(false);

  // 製品重量係数
  const [iq3SeihinSizeLst, setIq3SeihinSizeLst] = useState([]);
  // マテリアルハンドリング時間
  const [materialHandlingTimeList, setMaterialHandlingTimeList] = useState([]);

  // 工程内検査の個数
  const [processInspectionKosuuListosuuList, setProcessInspectionKosuuListosuuList] = useState([]);

  // 本数（プログラム作成時間）
  const [honsuuData, setHonsuuData] = useState([]);
  const [honsuuDataTemp, setHonsuuDataTemp] = useState([]);
  const [honsuuList, setHonsuuList] = useState([]);
  const [isHonsuu, setIsHonsuu] = useState(false);

  // 段取按分しない
  const [dantoriFlag, setDantoriFlag] = useState(false);

  const [groupDisplayList, setGroupDisplayList] = useState([]);
  const [processMstRecord, setProcessMstRecord] = useState();

  // 入力チェック
  const [errIndexVal, setErrIndexVal] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const inputRef = useRef([]);
  useEffect(() => {
    setErrIndexVal([]);
    setErrorMessage('');
    setEditMode(props.editMode);
    setProcessMethod(props.processMethod);

    // 基本段取時間(ベンディング)
    setMageSuuData(props?.detailsData?.basicTimeThickThMaster);
    setMagesuuTemp(props?.detailsData?.basicTimeThickThMaster);
    setMagesuuList(props?.detailsData?.basicTimeThickItems);

    // setCategory(props.processType);

    if (props.detailsData != undefined) {
      if (props?.detailsData?.prepSecs) setDantoriKouteiDetails(props?.detailsData?.prepSecs);
      setCategory(props.processType);
    }
    if (props?.processMstRecord) {
      setProcessMstRecord(props.processMstRecord);
    }
    // 段取設定の値がある場合、
    if (props?.detailsData?.prepSecs && props?.processMstRecord) {
      // 工程種別の詳細情報を得る
      const groupDisplayList = getProcessDetailTypesInfo(props?.detailsData?.prepSecs, props?.processMstRecord);
      setGroupDisplayList(groupDisplayList);
    }
    // 要素数
    if (props?.detailsData?.prepElementThMaster) setYousosuu(props?.detailsData?.prepElementThMaster);
    if (props?.detailsData?.prepElementThMaster) setYousosuuTemp(props?.detailsData?.prepElementThMaster);
    if (props?.detailsData?.prepElementThItems) setYousosuuLst(props?.detailsData?.prepElementThItems);

    setSagyouYousuuList(props.sagyouKeisuuYousosuuList);
    setKensaJikanKesisuuList(props.kensaJikanKeisuuList);

    // 曲げ個数
    setMageKosuuData(props.mageKosuu);
    setMageKosuuDataTemp(props.mageKosuu);
    setMageKosuuList(props.mageKosuuList);

    // 曲長段取時間
    setLengthData(props?.detailsData?.prepBendTimeLengthMaster);
    setDetailsLengthTemp(props?.detailsData?.prepBendTimeLengthMaster);
    setMagechouDantoriJikanList(props?.detailsData?.prepBendTimeItems);

    // 段取個数係数
    if (props?.detailsData?.prepQtyCoeffThMaster) {
      setKosuuData(props?.detailsData?.prepQtyCoeffThMaster);
      setKosuuDataTemp(props?.detailsData?.prepQtyCoeffThMaster);
    }
    setKosuuList(props?.detailsData?.prepQtyCoeffItems);

    // 本数（プログラム作成時間）
    if (props.processType === WorkType.SmBending) {
      setHonsuuData(props?.detailsData?.programCreateTimeCountfThMaster);
      setHonsuuDataTemp(props?.detailsData?.programCreateTimeCountfThMaster);
      setHonsuuList(props?.detailsData?.programCreateTimeItems);
    } else {
      setHonsuuData(props?.detailsData?.prepMoldQtyCoeffThMaster);
      setHonsuuDataTemp(props?.detailsData?.prepMoldQtyCoeffThMaster);
      setHonsuuList(props?.detailsData?.prepMoldQtyCoeffItems);
    }
    // 基本段取時間
    if (props?.detailsData?.prepBasicTime !== undefined) setSetupTime(props?.detailsData?.prepBasicTime);

    // 子部品サイズ係数(kg&cm)
    if (props?.detailsData?.childPartsCoeffAreaThMaster) {
      setAreaData(props?.detailsData?.childPartsCoeffAreaThMaster);
      setDetailsAreaTemp(props?.detailsData?.childPartsCoeffAreaThMaster);
    }
    if (props?.detailsData?.childPartsCoeffWeightThMaster) {
      setWeightData(props?.detailsData?.childPartsCoeffWeightThMaster);
      setDetailsWeightTemp(props?.detailsData?.childPartsCoeffWeightThMaster);
    }
    if (props?.detailsData?.childPartsCoeff) {
      setSizeKeisuuList(props?.detailsData?.childPartsCoeff);
    }

    // 製品重量係数
    setIq3SeihinSizeLst(props.iq3SeihinSizeLst);
    // マテリアルハンドリング時間
    setMaterialHandlingTimeList(props.materialHandlingTimeList);

    setProcessInspectionKosuuListosuuList(props.processInspectionKosuuListosuuList);
    setDantoriFlag(props.dantoriFlag);
  }, [
    props.processName,
    props.editMode,
    props.itaatsuData,
    props.mageSuuLst,
    props.yousosuuMasterData,
    props.shokidankaiYousosuuList,
    props.sagyouKeisuuYousosuuList,
    props.dantoriSetteiSuuryouList,
    props.mageKosuu,
    props.mageKosuuList,
    props.magechouDantoriJikanList,
    props.detailsData,
    props.kosuu,
    props.kosuuList,
    props.dantoriTime,
    props.area,
    props.sizeKeisuuListData,
    props.honSuuList,
    props.honSuu,
    props.processMethod,
    props.processInspectionKosuuListosuuList,
    props.dantoriFlag,
    props.kensaJikanKeisuuList,
    props.materialHandlingTimeList,
    props.iq3SeihinSizeLst,
  ]);

  const quantity = () => {
    setErrIndexVal([]);
    setErrorMessage('');
    setIsKosuu(true);
  };
  const number = () => {
    setErrIndexVal([]);
    setErrorMessage('');
    setIsHonsuu(true);
  };

  // 基本段取時間を変更した場合、
  const changeTime = (e) => {
    setSetupTime(setTimetoSec(e));
    props.updatedDantoriTime(setTimetoSec(e));
  };

  const addMagesuuModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>板厚</p>
    </div>
  );
  const addMageKosuuModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>個数</p>
    </div>
  );
  const addYousosuuModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>要素数</p>
    </div>
  );
  const addData = () => {
    setIsUpdateData(true);
  };
  const updateDataOk = () => {
    if (isMagesuu == true) {
    }
  };
  const updateDataCancel = () => {
    if (isMagesuu == true) {
      setIsMageSuu(true);
    }
    setIsUpdateData(false);
  };

  const discardOk = () => {
    if (isMagesuu == true) {
      setIsMageSuu(false);
    }
    setIsDiscardData(false);
  };
  const discardCancel = () => {
    if (isMagesuu == true) {
      setIsMageSuu(true);
    } else if (isYousosuu === true) {
      setIsYousosuu(true);
    }
    setIsDiscardData(false);
  };

  // 要素数マスタの値を入力された場合、
  const handleYousosuuChange = (event, no, field) => {
    const temp = JSON.parse(JSON.stringify(yousosuuTemp));
    const updatedData = temp?.map((row) => {
      if (row.no === no) {
        return { ...row, [field]: event.target.value };
      }
      return row;
    });

    setYousosuuTemp(updatedData);
  };

  // 要素数マスタの行追加ボタン押下
  const addYousosuu = (no, index) => {
    inputRef.current[index].focus();

    const copyData = yousosuuTemp.slice(); // Create a copy of the original array

    const insertIndex = yousosuuTemp?.findIndex((item) => item.no === no); // Find the clicked index of the item

    if (insertIndex !== -1) {
      const data = {
        no: yousosuuTemp?.length + 1,
        value: 0,
        isOutOfRange: false,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setYousosuuTemp(copyData);
    }
  };

  // 要素数マスタの行削除ボタン押下
  const deleteYousosuu = (no, e, index) => {
    // 更新するエラーインデックスを得る
    const updatedErrorIndexes = errIndexVal?.filter((item) => item !== index);

    const finalErrorIndexes = updatedErrorIndexes?.map((item) => (item > index ? item - 1 : item));
    setErrIndexVal(finalErrorIndexes);

    const listTemp = JSON.parse(JSON.stringify(yousosuuTemp));
    if (listTemp?.filter((item) => !item.isOutOfRange)?.length > 2) {
      const updatedData = listTemp?.map((row) => {
        if (row.no === no) {
          return { ...row, isOutOfRange: true };
        }
        return row;
      });
      setYousosuuTemp(updatedData);
      const filterData = updatedData?.filter((i) => !i.isOutOfRange);
      if (index + 1 !== filterData?.length) {
        inputRef.current[index].focus();
      }
    }
  };

  // 要素数ボタン押下
  const openYousosuuModal = () => {
    setErrIndexVal([]);
    setErrorMessage('');
    setIsYousosuu(true);
  };

  // 要素数のリスト値を変更した場合、
  const changeYousuu = (event, no) => {
    // const copyData = [...yousosuuLst];
    // const updatedData = JSON.parse(JSON.stringify(copyData));
    // updatedData[rowIndex].lstData[colIndex].value = event;
    yousosuuLst
      ?.filter((item) => item.no === no)
      ?.map((list) => {
        list.value = setTimetoSec(event);
      });
    setYousosuuLst(yousosuuLst);
    props.updatedYousosuuList(yousosuuLst);
  };

  // 要素数マスタのOKボタン押下
  const okYousosuuMasterData = () => {
    const deletedId = [];

    yousosuu?.forEach((item1, index) => {
      const found = yousosuuTemp?.find((item2) => item2.no === item1.no && item2.isOutOfRange);
      if (found) {
        deletedId.push(item1.no);
      }
    });

    const newId = yousosuuTemp
      ?.filter((item) => !item.isOutOfRange && !yousosuu.some((data2Item) => data2Item.no === item.no))
      ?.map((item) => item.no);

    // const deleteYousosuuList = deleteListColData(kensaJikanKeisuuList, deletedId);
    const deleteShokiDankaiJikanList = deleteListColData(yousosuuLst, deletedId);
    // const deleteSagyouJikanKeisuuList = deleteListColData(sagyouYousuuList, deletedId);

    // const updatedData = updateListColData(deleteYousosuuList, newId);
    const updateShokiDankaiJikanList = updateListColData(deleteShokiDankaiJikanList, newId);
    // const updateSagyouJikanKeisuuList = updateListColData(deleteSagyouJikanKeisuuList, newId);

    // const sortedList = sortListData(updatedData, yousosuuTemp);
    const sortedShokiDankiaiJikanList = sortListData(updateShokiDankaiJikanList, yousosuuTemp);
    // const sortedSagyouJikanKeisuuList = sortListData(updateSagyouJikanKeisuuList, yousosuuTemp);

    setIsYousosuu(false);
    setYousosuuTemp(yousosuuTemp);
    props.updatedYousosuuMasterData(yousosuuTemp);

    setYousosuuLst(sortedShokiDankiaiJikanList);
    props.updatedYousosuuList(sortedShokiDankiaiJikanList);

    // setSagyouYousuuList(sortedSagyouJikanKeisuuList);
    // props.updatedSagyouYousosuuList(sortedSagyouJikanKeisuuList);

    // setKensaJikanKesisuuList(sortedList);
    // props.updateKensaJikanKeisuuList(sortedList);
  };

  // マスタモデルのキャンセルボタン押下
  const cancelMasterData = () => {
    if (isYousosuu === true) {
      setYousosuuTemp(yousosuu);
      setIsYousosuu(false);
    } else if (isMagesuu === true) {
      setMagesuuTemp(mageSuuData);
      setIsMageSuu(false);
    } else if (isMageKosuu === true) {
      setMageKosuuDataTemp(mageKosuuData);
      setIsMageKosuu(false);
    } else if (isLength === true) {
      setDetailsLengthTemp(lengthData);
      setIsLength(false);
    } else if (isWeight === true) {
      setDetailsWeightTemp(weightData);
      setIsWeight(false);
    } else if (isArea === true) {
      setDetailsAreaTemp(areaData);
      setIsArea(false);
    } else if (isKosuu === true) {
      setKosuuDataTemp(kosuuData);
      setIsKosuu(false);
    } else if (isHonsuu === true) {
      setHonsuuDataTemp(honsuuData);
      setIsHonsuu(false);
    }
  };

  // マスタモデルの「X」ボタン押下
  const closeAddModal = () => {
    if (isMagesuu === true) {
      setIsMageSuu(false);
    } else if (isYousosuu === true) {
      setIsYousosuu(false);
    } else if (isMageKosuu === true) {
      setIsMageKosuu(false);
    } else if (isWeight === true) {
      setIsWeight(false);
    } else if (isArea === true) {
      setIsArea(false);
    } else if (isLength === true) {
      setIsLength(false);
    } else if (isHonsuu === true) {
      setIsHonsuu(false);
    } else if (isKosuu === true) {
      setIsKosuu(false);
    }
  };

  const handleMageKosuuChange = (event, id, field) => {
    const temp = JSON.parse(JSON.stringify(mageKosuuDataTemp));
    const updatedData = temp
      ?.filter((i) => i.isOutOfRange === true)
      ?.map((row) => {
        if (row.id === id) {
          return { ...row, [field]: event.target.value };
        }
        return row;
      });
    const previousValue = updatedData[updatedData?.length - 2].value;
    if (previousValue) {
      updatedData[updatedData?.length - 1].value = (parseInt(previousValue) + 1).toString();
      setMageKosuuDataTemp(updatedData);
    } else {
      setMageKosuuDataTemp(updatedData);
    }
  };

  // 個数マスタの行追加を押下
  const addKosuu = (no, index) => {
    inputRef.current[index].focus();
    const copyData = kosuuDataTemp.slice(); // Create a copy of the original array

    const insertIndex = kosuuDataTemp?.findIndex((item) => item.no === no); // Find the clicked index of the item

    if (insertIndex !== -1) {
      const data = {
        no: kosuuDataTemp?.length + 1,
        value: 0,
        isOutOfRange: false,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setKosuuDataTemp(copyData);
    }
  };

  // 本数マスタの行追加を押下
  const addHonsuu = (no, index) => {
    inputRef.current[index].focus();
    const copyData = honsuuDataTemp.slice(); // Create a copy of the original array

    const insertIndex = honsuuDataTemp?.findIndex((item) => item.no === no); // Find the clicked index of the item

    if (insertIndex !== -1) {
      const data = {
        no: honsuuDataTemp?.length + 1,
        value: 0,
        isOutOfRange: false,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setHonsuuDataTemp(copyData);
    }
  };

  // 個数マスタの値を変更した場合、
  const handleKosuuChange = (event, no, field) => {
    const temp = JSON.parse(JSON.stringify(kosuuDataTemp));
    const updatedData = temp?.map((row) => {
      if (row.no === no) {
        return { ...row, [field]: event.target.value };
      }
      return row;
    });

    setKosuuDataTemp(updatedData);
  };

  // 本数マスタの値を変更した場合、
  const handleHonsuuChange = (event, no, field) => {
    const temp = JSON.parse(JSON.stringify(honsuuDataTemp));
    const updatedData = temp
      // ?.filter((i) => !i.isOutOfRange)
      ?.map((row) => {
        if (row.no === no) {
          return { ...row, [field]: event.target.value };
        }
        return row;
      });

    setHonsuuDataTemp(updatedData);
  };

  // 個数マスタの行削除を押下
  const deleteKosuu = (no, e, index) => {
    // 更新するエラーインデックスを得る
    const updatedErrorIndexes = errIndexVal?.filter((item) => item !== index);

    const finalErrorIndexes = updatedErrorIndexes?.map((item) => (item > index ? item - 1 : item));
    setErrIndexVal(finalErrorIndexes);

    const listTemp = JSON.parse(JSON.stringify(kosuuDataTemp));
    if (listTemp?.filter((item) => !item.isOutOfRange)?.length > 2) {
      const updatedData = listTemp?.map((row) => {
        if (row.no === no) {
          return { ...row, isOutOfRange: true };
        }
        return row;
      });

      setKosuuDataTemp(updatedData);
      const filterData = updatedData?.filter((i) => !i.isOutOfRange);
      if (index + 1 !== filterData?.length) {
        inputRef.current[index].focus();
      }
    }
  };

  // 本数マスタの行削除を押下
  const deleteHosuu = (no, e, index) => {
    // 更新するエラーインデックスを得る
    const updatedErrorIndexes = errIndexVal?.filter((item) => item !== index);

    const finalErrorIndexes = updatedErrorIndexes?.map((item) => (item > index ? item - 1 : item));
    setErrIndexVal(finalErrorIndexes);

    const listTemp = JSON.parse(JSON.stringify(honsuuDataTemp));
    if (listTemp?.filter((item) => !item.isOutOfRange)?.length > 2) {
      const updatedData = listTemp?.map((row) => {
        if (row.no === no) {
          return { ...row, isOutOfRange: true };
        }
        return row;
      });

      setHonsuuDataTemp(updatedData);
      const filterData = updatedData?.filter((i) => !i.isOutOfRange);
      if (index + 1 !== filterData?.length) {
        inputRef.current[index].focus();
      }
    }
  };

  // 個数マスタのOKボタン押下
  const okKosuuMasterData = () => {
    const deletedId = [];

    kosuuData?.forEach((item1, index) => {
      const found = kosuuDataTemp?.find((item2) => item2.no === item1.no && item2.isOutOfRange);
      if (found) {
        deletedId.push(item1.no);
      }
    });

    const newId = kosuuDataTemp
      ?.filter((item) => !item.isOutOfRange && !kosuuData.some((data2Item) => data2Item.no === item.no))
      ?.map((item) => item.no);

    const afterDeleted = deleteListColData(kosuuList, deletedId);
    const updatedData = updateListColData(afterDeleted, newId);
    const sortedList = sortListData(updatedData, kosuuDataTemp);

    // const afterDeleted = kosuuList?.map((item) => {
    //   return {
    //     ...item,
    //     lstData: item.lstData?.filter((it) => !deletedId?.includes(it.key)),
    //   };
    // });

    // const afterDeleted2 = processInspectionKosuuListosuuList?.map((item) => {
    //   return {
    //     ...item,
    //     lstData: item.lstData?.filter((it) => !deletedId?.includes(it.key)),
    //   };
    // });

    // const updatedData = afterDeleted?.map((item) => {
    //   newId?.forEach((key) => {
    //     // Add new objects to lstData
    //     item.lstData.push({
    //       key: key,
    //       value: '',
    //     });
    //   });

    //   return item;
    // });

    // const updatedData2 = afterDeleted2?.map((item) => {
    //   newId?.forEach((key) => {
    //     // Add new objects to lstData
    //     item.lstData.push({
    //       key: key,
    //       value: '',
    //     });
    //   });

    //   return item;
    // });

    // const sortedList = updatedData?.map((item) => {
    //   const sortedLstData = [...item.lstData];
    //   sortedLstData.sort((a, b) => {
    //     const aIndex = kosuuDataTemp?.findIndex((obj) => obj.id === a.key);
    //     const bIndex = kosuuDataTemp?.findIndex((obj) => obj.id === b.key);
    //     return aIndex - bIndex;
    //   });

    //   return {
    //     key: item.key,
    //     materialType: item.materialType,
    //     lstData: sortedLstData,
    //   };
    // });

    // const sortedList2 = updatedData2?.map((item) => {
    //   const sortedLstData = [...item.lstData];
    //   sortedLstData.sort((a, b) => {
    //     const aIndex = kosuuDataTemp?.findIndex((obj) => obj.id === a.key);
    //     const bIndex = kosuuDataTemp?.findIndex((obj) => obj.id === b.key);
    //     return aIndex - bIndex;
    //   });

    //   return {
    //     key: item.key,
    //     materialType: item.materialType,
    //     lstData: sortedLstData,
    //   };
    // });

    setIsKosuu(false);
    setKosuuDataTemp(kosuuDataTemp);
    props.updatedKosuu(kosuuDataTemp);

    setKosuuList(sortedList);
    props.updatedKosuuList(sortedList);
    // setProcessInspectionKosuuListosuuList(sortedList2);
    // props.updatedProcessInspectionKosuuList(sortedList2);
  };

  // 本数マスタのOKボタン押下
  const okHonsuuMasterData = () => {
    const deletedId = [];

    honsuuData?.forEach((item1, index) => {
      const found = honsuuDataTemp?.find((item2) => item2.no === item1.no && item2.isOutOfRange);
      if (found) {
        deletedId.push(item1.no);
      }
    });

    const newId = honsuuDataTemp
      ?.filter((item) => !item.isOutOfRange && !honsuuData.some((data2Item) => data2Item.no === item.no))
      ?.map((item) => item.no);

    const afterDeleted = deleteListColData(honsuuList, deletedId);
    const updatedData = updateListColData(afterDeleted, newId);
    const sortedList = sortListData(updatedData, honsuuDataTemp);

    // const afterDeleted = honsuuList?.map((item) => {
    //   return {
    //     ...item,
    //     lstData: item.lstData?.filter((it) => !deletedId?.includes(it.key)),
    //   };
    // });

    // const updatedData = afterDeleted?.map((item) => {
    //   newId?.forEach((key) => {
    //     // Add new objects to lstData
    //     item.lstData.push({
    //       key: key,
    //       value: '',
    //     });
    //   });

    //   return item;
    // });

    // const sortedList = updatedData?.map((item) => {
    //   const sortedLstData = [...item.lstData];
    //   sortedLstData.sort((a, b) => {
    //     const aIndex = honsuuDataTemp?.findIndex((obj) => obj.id === a.key);
    //     const bIndex = honsuuDataTemp?.findIndex((obj) => obj.id === b.key);
    //     return aIndex - bIndex;
    //   });

    //   return {
    //     key: item.key,
    //     materialType: item.materialType,
    //     lstData: sortedLstData,
    //   };
    // });

    setIsHonsuu(false);
    setHonsuuDataTemp(honsuuDataTemp);

    setHonsuuList(sortedList);
    props.updateHonsuu(honsuuDataTemp);

    props.updateHonsuuList(sortedList);
  };
  const isValueOrderCorrect = (no, tblName) => {
    let temp = [];
    if (tblName === 'weight') {
      temp = detailsWeightTemp?.filter((i) => i.isOutOfRange === false);
    } else if (tblName === 'length') {
      temp = detailsLengthTemp?.filter((i) => i.isOutOfRange === false);
    } else if (tblName === 'area') {
      temp = detailsAreaTemp?.filter((i) => i.isOutOfRange === false);
    } else if (tblName === 'kosuu') {
      temp = kosuuDataTemp?.filter((i) => i.isOutOfRange === false);
    } else if (tblName === 'yousosuu') {
      temp = yousosuuTemp?.filter((i) => i.isOutOfRange === false);
    } else if (tblName === 'honsuu') {
      temp = honsuuDataTemp?.filter((i) => i.isOutOfRange === false);
    } else if (tblName === 'magesuu') {
      temp = magesuuTemp?.filter((i) => i.isOutOfRange === false);
    }

    const insertIndex = temp?.findIndex((item) => item.no === no);

    if (insertIndex > 0) {
      const prevValue = parseFloat(temp[insertIndex - 1].value);
      const currentValue = parseFloat(temp[insertIndex].value);
      const nextValue = parseFloat(temp[insertIndex + 1].value);
      if (currentValue > prevValue && currentValue < nextValue) {
        let temp = [insertIndex - 1, insertIndex, insertIndex + 1];

        setErrIndexVal(errIndexVal?.filter((item) => item !== insertIndex && item != insertIndex - 1));

        setErrorMessage('前のしきい値より大きい値を入力してください！');
      } else {
        setErrIndexVal([...errIndexVal, insertIndex]);

        if (currentValue <= prevValue) {
          setErrorMessage('前のしきい値より大きい値を入力してください！');
        } else if (currentValue >= nextValue) {
          setErrorMessage('次のしきい値より小さい値を入力してください！');
        }
      }
    } else {
      const currentValue = parseFloat(temp[insertIndex].value);
      const nextValue = parseFloat(temp[insertIndex + 1].value);

      if (currentValue < nextValue) {
        setErrIndexVal(errIndexVal?.filter((item) => item !== insertIndex));
      } else {
        setErrIndexVal([...errIndexVal, insertIndex]);
        setErrorMessage('次のしきい値より小さい値を入力してください！');
      }
    }
  };
  // 個数マスタ
  const addKosuuContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>値</label>
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {kosuuDataTemp
                  ?.filter((i) => !i.isOutOfRange)
                  ?.map((item, index) => (
                    <tr key={index} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        {errIndexVal?.includes(index) ? (
                          <Popover
                            content={<div style={{ padding: 7 }}>{errorMessage}</div>}
                            placement="left"
                            visible={true}
                          ></Popover>
                        ) : (
                          ''
                        )}
                        <Input
                          value={item.value}
                          onChange={(e) => handleKosuuChange(e, item.no, 'value')}
                          onBlur={() => isValueOrderCorrect(item.no, 'kosuu')}
                          className={errIndexVal?.includes(index) ? 'red-border' : ''}
                          style={{
                            ...getInputBorderStyle(index, kosuuDataTemp),
                            pointerEvents: editMode ? 'auto' : 'none',
                          }}
                          onKeyPress={(e) => {
                            handleKeyPress(e, false);
                          }}
                          ref={(ref) => (inputRef.current[index] = ref)}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addKosuu(item.no, index + 1)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteKosuu(item.no, e, index)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button
          className={isEmpty(errIndexVal) ? 'mainButton' : 'disabledButton'}
          id="update"
          onClick={okKosuuMasterData}
        >
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} onClick={cancelMasterData}>
          キャンセル
        </Button>
      </Row>
    </>
  );

  // 本数マスタ
  const addHonsuuContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>値</label>
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {honsuuDataTemp
                  ?.filter((subitem) => !subitem.isOutOfRange)
                  ?.map((item, index) => (
                    <tr key={index} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        {errIndexVal?.includes(index) ? (
                          <Popover
                            content={<div style={{ padding: 7 }}>{errorMessage}</div>}
                            placement="left"
                            visible={true}
                          ></Popover>
                        ) : (
                          ''
                        )}
                        <Input
                          value={item.value}
                          onChange={(e) => handleHonsuuChange(e, item.no, 'value')}
                          onBlur={() => isValueOrderCorrect(item.no, 'honsuu')}
                          className={errIndexVal?.includes(index) ? 'red-border' : ''}
                          style={{
                            ...getInputBorderStyle(index, honsuuDataTemp),
                            pointerEvents: editMode ? 'auto' : 'none',
                          }}
                          ref={(ref) => (inputRef.current[index] = ref)}
                          onKeyPress={(e) => {
                            handleKeyPress(e, false);
                          }}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addHonsuu(item.no, index + 1)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteHosuu(item.no, e, index)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button
          className={isEmpty(errIndexVal) ? 'mainButton' : 'disabledButton'}
          id="update"
          onClick={okHonsuuMasterData}
        >
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} onClick={cancelMasterData}>
          キャンセル
        </Button>
      </Row>
    </>
  );

  // 面積マスタの値を変更した場合、
  const handleAreaChange = (event, no, field) => {
    const updatedData = detailsAreaTemp?.map((row) => {
      if (row.no === no) {
        return { ...row, [field]: event.target.value };
      }
      return row;
    });
    setDetailsAreaTemp(updatedData);
  };

  // 面積マスタの行追加を押下
  const addAreaRow = (no, index) => {
    inputRef.current[index].focus();
    const copyData = detailsAreaTemp.slice(); // Create a copy of the original array

    const insertIndex = detailsAreaTemp?.findIndex((item) => item.no === no); // Find the index of the item with id 2

    if (insertIndex !== -1) {
      const data = {
        no: detailsAreaTemp?.length + 1,
        value: 0,
        isOutOfRange: false,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setDetailsAreaTemp(copyData);
    }
  };

  // 面積マスタの行削除を押下
  const deleteArea = (no, e, index) => {
    // 更新するエラーインデックスを得る
    const updatedErrorIndexes = errIndexVal?.filter((item) => item !== index);

    const finalErrorIndexes = updatedErrorIndexes?.map((item) => (item > index ? item - 1 : item));
    setErrIndexVal(finalErrorIndexes);
    if (detailsAreaTemp?.filter((item) => !item.isOutOfRange)?.length > 2) {
      const updatedData = detailsAreaTemp?.map((row) => {
        if (row.no === no) {
          return { ...row, isOutOfRange: true };
        }
        return row;
      });
      setDetailsAreaTemp(updatedData);
      const filterData = updatedData?.filter((i) => !i.isOutOfRange);
      if (index + 1 !== filterData?.length) {
        inputRef.current[index].focus();
      }
    }
  };

  // 面積マスタのOKボタン押下、
  const okAreaMaster = () => {
    const deletedId = [];

    areaData?.forEach((item1, index) => {
      const found = detailsAreaTemp?.find((item2) => item2.no === item1.no && item2.isOutOfRange);
      if (found) {
        deletedId.push(item1.no);
      }
    });

    const newId = detailsAreaTemp
      ?.filter((item) => !item.isOutOfRange && !areaData.some((data2Item) => data2Item.no === item.no))
      ?.map((item) => item.no);

    const deleteData = sizeKeisuuList?.map((item) => {
      return {
        ...item,
        areaCoeffList: deleteListColData(item.areaCoeffList, deletedId),
      };
    });

    const updatedData = deleteData?.map((item) => {
      newId?.forEach((key) => {
        // Add new objects to lstData
        item.areaCoeffList.push({
          no: key,
          value: 0,
          isOutOfRange: false,
        });
      });
      return item;
    });

    const sortedList = updatedData?.map((item) => {
      const sortLstData = [...item.areaCoeffList];
      const sortedLstData = sortListData(sortLstData, detailsAreaTemp);
      return {
        ...item,
        areaCoeffList: sortedLstData,
      };
    });

    // const deleteData = sizeKeisuuList?.map((item) => {
    //   return {
    //     ...item,
    //     lstData: item.lstData?.filter((it) => !deletedId?.includes(it.key)),
    //   };
    // });

    // const updatedData = deleteData?.map((item) => {
    //   newId?.forEach((key) => {
    //     // Add new objects to lstData
    //     item.lstData.push({
    //       key: key,
    //       value: '',
    //     });
    //   });

    //   return item;
    // });

    // const sortedList = updatedData?.map((item) => {
    //   const sortedLstData = [...item.lstData];
    //   sortedLstData.sort((a, b) => {
    //     const aIndex = detailsAreaTemp?.findIndex((obj) => obj.id === a.key);
    //     const bIndex = detailsAreaTemp?.findIndex((obj) => obj.id === b.key);
    //     return aIndex - bIndex;
    //   });

    //   return {
    //     key: item.key,
    //     max: item.max,
    //     isOutOfRange: item.isOutOfRange,
    //     lstData: sortedLstData,
    //   };
    // });

    setIsArea(false);
    setDetailsAreaTemp(detailsAreaTemp);

    setSizeKeisuuList(sortedList);
    props.updatedsizeKeisuuListData(sortedList);
    props.updatedArea(detailsAreaTemp);

    // props.updatedsizeKeisuuListData(sortedList);

    // 面積マスター更新によりマテリアルハンドリング時間一覧更新
    updateMaterialHandlingListByAreaMaster(deletedId, newId, detailsAreaTemp);
    // 面積マスター更新により製品重量係数一覧更新
    updateIq3SeihinSizeLstByAreaMaster(deletedId, newId, detailsAreaTemp);
  };

  // 面積マスター更新によりマテリアルハンドリング時間一覧更新
  const updateMaterialHandlingListByAreaMaster = (deletedId, newId, detailsAreaTemp) => {
    const deleteData = materialHandlingTimeList?.map((item) => {
      return {
        ...item,
        lstData: item.lstData?.filter((it) => !deletedId?.includes(it.key)),
      };
    });

    const updatedData = deleteData?.map((item) => {
      newId?.forEach((key) => {
        // Add new objects to lstData
        item.lstData.push({
          key: key,
          value: '00:00:00',
        });
      });

      return item;
    });

    const sortedList = updatedData?.map((item) => {
      const sortedLstData = [...item.lstData];
      sortedLstData.sort((a, b) => {
        const aIndex = detailsAreaTemp?.findIndex((obj) => obj.id === a.key);
        const bIndex = detailsAreaTemp?.findIndex((obj) => obj.id === b.key);
        return aIndex - bIndex;
      });

      return {
        key: item.key,
        max: item.max,
        isOutOfRange: item.isOutOfRange,
        lstData: sortedLstData,
      };
    });

    setMaterialHandlingTimeList(sortedList);
    props.updateMaterialHandlingList(sortedList);
  };

  // 面積マスター更新により製品重量係数一覧更新
  const updateIq3SeihinSizeLstByAreaMaster = (deletedId, newId, detailsAreaTemp) => {
    const deleteData = iq3SeihinSizeLst?.map((item) => {
      return {
        ...item,
        lstData: item.lstData?.filter((it) => !deletedId?.includes(it.key)),
      };
    });

    const updatedData = deleteData?.map((item) => {
      newId?.forEach((key) => {
        // Add new objects to lstData
        item.lstData.push({
          key: key,
          value: '',
        });
      });

      return item;
    });

    const sortedList = updatedData?.map((item) => {
      const sortedLstData = [...item.lstData];
      sortedLstData.sort((a, b) => {
        const aIndex = detailsAreaTemp?.findIndex((obj) => obj.id === a.key);
        const bIndex = detailsAreaTemp?.findIndex((obj) => obj.id === b.key);
        return aIndex - bIndex;
      });

      return {
        key: item.key,
        max: item.max,
        isOutOfRange: item.isOutOfRange,
        lstData: sortedLstData,
      };
    });

    setIq3SeihinSizeLst(sortedList);
    props.updatediq3ProductSizesList(sortedList);
  };

  const cancelAreaMaster = () => {
    setDetailsAreaTemp(areaData);
    setIsArea(false);
  };

  // 子部品サイズ係数のcm²ボタンを押下
  const addAreaContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>しきい値</label>（≦）
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {detailsAreaTemp
                  ?.filter((subitem) => !subitem.isOutOfRange)
                  ?.map((item, index) => (
                    <tr key={index} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        {errIndexVal?.includes(index) ? (
                          <Popover
                            content={<div style={{ padding: 7 }}>{errorMessage}</div>}
                            placement="left"
                            visible={true}
                          ></Popover>
                        ) : (
                          ''
                        )}
                        <Input
                          value={item.value}
                          onChange={(e) => handleAreaChange(e, item.no, 'value')}
                          onBlur={() => isValueOrderCorrect(item.no, 'area')}
                          className={errIndexVal?.includes(index) ? 'red-border' : ''}
                          style={{
                            ...getInputBorderStyle(index, detailsAreaTemp),
                            pointerEvents: editMode ? 'auto' : 'none',
                          }}
                          onKeyPress={(e) => {
                            handleKeyPress(e, false);
                          }}
                          ref={(ref) => (inputRef.current[index] = ref)}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addAreaRow(item.no, index + 1)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteArea(item.no, e, index)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button className={isEmpty(errIndexVal) ? 'mainButton' : 'disabledButton'} id="update" onClick={okAreaMaster}>
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} onClick={cancelAreaMaster}>
          キャンセル
        </Button>
      </Row>
    </>
  );

  const addMageKosuu = (id) => {
    const copyData = mageKosuuDataTemp.slice(); // Create a copy of the original array

    const insertIndex = mageKosuuDataTemp?.findIndex((item) => item.id === id); // Find the clicked index of the item

    if (insertIndex !== -1) {
      const data = {
        id: mageKosuuDataTemp?.length + 1,
        val: '',
        isOutOfRange: true,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setMageKosuuDataTemp(copyData);
    }
  };

  // 個数リストの値を変更した場合、
  const changeKosuu = (event, no) => {
    // const copyData = [...kosuuList];
    // const updatedData = JSON.parse(JSON.stringify(copyData));
    // updatedData[rowIndex].lstData[colIndex].value = event.target.value;
    kosuuList
      ?.filter((item) => item.no === no)
      ?.map((list) => {
        list.value = event.target.value;
      });
    setKosuuList(kosuuList);
    props.updatedKosuuList(kosuuList);
  };

  // 本数リスト値を変更した場合、
  const changeHonsuu = (value, no) => {
    // const copyData = [...honsuuList];
    // const updatedData = JSON.parse(JSON.stringify(copyData));
    // updatedData[rowIndex].lstData[colIndex].value = event.target.value;
    honsuuList
      ?.filter((item) => item.no === no)
      ?.map((list) => {
        list.value = setTimetoSec(value);
      });
    setHonsuuList(honsuuList);
    props.updateHonsuuList(honsuuList);
  };

  const deleteMageKosuu = (id, e) => {
    const listTemp = JSON.parse(JSON.stringify(mageKosuuDataTemp));
    if (listTemp?.filter((item) => item.isOutOfRange === true)?.length > 2) {
      const updatedData = listTemp?.map((row) => {
        if (row.id === id) {
          return { ...row, isOutOfRange: false };
        }
        return row;
      });
      const tempdata = JSON.parse(JSON.stringify(updatedData));

      const suuRyou = tempdata?.filter((i) => i.isOutOfRange === true);
      const previousValue = suuRyou[suuRyou?.length - 2].value;
      if (previousValue) {
        updatedData[updatedData?.length - 1].value = (parseInt(previousValue) + 1).toString();
        setMageKosuuDataTemp(updatedData);
      } else {
        setMageKosuuDataTemp(updatedData);
      }
    }
  };

  const addMageKosuuMasterData = () => {
    const deletedId = [];

    mageKosuuData?.forEach((item1, index) => {
      const found = mageKosuuDataTemp?.find((item2) => item2.id === item1.id && item2.isOutOfRange === false);
      if (found) {
        deletedId.push(item1.id);
      }
    });

    const newId = mageKosuuDataTemp
      ?.filter((item) => item.isOutOfRange && !mageKosuuData.some((data2Item) => data2Item.id === item.id))
      ?.map((item) => item.id);

    const afterDeleted = mageKosuuList?.map((item) => {
      return {
        ...item,
        lstData: item.lstData?.filter((it) => !deletedId?.includes(it.key)),
      };
    });

    const updatedData = afterDeleted?.map((item) => {
      newId?.forEach((key) => {
        // Add new objects to lstData
        item.lstData.push({
          key: key,
          value: '',
        });
      });

      return item;
    });

    const sortedList = updatedData?.map((item) => {
      const sortedLstData = [...item.lstData];
      sortedLstData.sort((a, b) => {
        const aIndex = mageKosuuDataTemp?.findIndex((obj) => obj.id === a.key);
        const bIndex = mageKosuuDataTemp?.findIndex((obj) => obj.id === b.key);
        return aIndex - bIndex;
      });

      return {
        key: item.key,
        materialType: item.materialType,
        lstData: sortedLstData,
      };
    });

    setIsMageKosuu(false);
    setMageKosuuDataTemp(mageKosuuDataTemp);

    setMageKosuuList(sortedList);
    props.updatedMageKosuu(mageKosuuDataTemp);

    props.updatedMageKosuuList(sortedList);
  };

  const addKosuuModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>個数</p>
    </div>
  );
  const addHonsuuModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>本数</p>
    </div>
  );
  const addAreaModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>面積</p>
    </div>
  );
  const addWeightModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>重量</p>
    </div>
  );
  const addLengthModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>曲長</p>
    </div>
  );

  // 要素数マスタ一覧
  const addYousosuuContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>しきい値</label>（≦）
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {yousosuuTemp
                  ?.filter((subitem) => !subitem.isOutOfRange)
                  ?.map((item, index) => (
                    <tr key={index} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        {errIndexVal?.includes(index) ? (
                          <Popover
                            content={<div style={{ padding: 7 }}>{errorMessage}</div>}
                            placement="left"
                            visible={true}
                          ></Popover>
                        ) : (
                          ''
                        )}
                        <Input
                          value={item.value}
                          onChange={(e) => handleYousosuuChange(e, item.no, 'value')}
                          onBlur={() => isValueOrderCorrect(item.no, 'yousosuu')}
                          className={errIndexVal?.includes(index) ? 'red-border' : ''}
                          style={{
                            ...getInputBorderStyle(index, yousosuuTemp),
                            pointerEvents: editMode ? 'auto' : 'none',
                          }}
                          ref={(ref) => (inputRef.current[index] = ref)}
                          onKeyPress={(e) => {
                            handleKeyPress(e, false);
                          }}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addYousosuu(item.no, index + 1)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteYousosuu(item.no, e, index)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button
          className={isEmpty(errIndexVal) ? 'mainButton' : 'disabledButton'}
          id="update"
          onClick={okYousosuuMasterData}
        >
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} onClick={cancelMasterData}>
          キャンセル
        </Button>
      </Row>
    </>
  );

  const addMageKosuuContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>値</label>
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {mageKosuuDataTemp
                  ?.filter((subitem) => subitem.isOutOfRange === true)
                  ?.map((item, index) => (
                    <tr key={index} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        <Input
                          value={item.val}
                          onChange={(e) => handleMageKosuuChange(e, item.id, 'val')}
                          style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addMageKosuu(item.id)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteMageKosuu(item.id, e)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button
          className={isEmpty(errIndexVal) ? 'mainButton' : 'disabledButton'}
          id="update"
          onClick={addMageKosuuMasterData}
        >
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} onClick={cancelMasterData}>
          キャンセル
        </Button>
      </Row>
    </>
  );

  // 板厚マスタの行追加を押下
  const addMagesuu = (no, index) => {
    inputRef.current[index].focus();
    const copyData = magesuuTemp.slice(); // Create a copy of the original array

    const insertIndex = magesuuTemp?.findIndex((item) => item.no === no); // Find the index of the item with id 2

    if (insertIndex !== -1) {
      const data = {
        no: magesuuTemp?.length + 1,
        value: 0,
        isOutOfRange: false,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setMagesuuTemp(copyData);
    }
  };

  // 板厚マスタの行削除を押下
  const deleteMagesuu = (no, e, index) => {
    // 更新するエラーインデックスを得る
    const updatedErrorIndexes = errIndexVal?.filter((item) => item !== index);

    const finalErrorIndexes = updatedErrorIndexes?.map((item) => (item > index ? item - 1 : item));
    setErrIndexVal(finalErrorIndexes);

    if (magesuuTemp?.filter((item) => !item.isOutOfRange)?.length > 2) {
      const updatedData = magesuuTemp?.map((row) => {
        if (row.no === no) {
          return { ...row, isOutOfRange: true };
        }
        return row;
      });
      setMagesuuTemp(updatedData);
      const filterData = updatedData?.filter((i) => !i.isOutOfRange);
      if (index + 1 !== filterData?.length) {
        inputRef.current[index].focus();
      }
    }
  };

  // 板厚マスタ値を変更した場合、
  const handleMagesuuChange = (event, no, field) => {
    const temp = JSON.parse(JSON.stringify(magesuuTemp));
    const updatedData = temp
      // ?.filter((i) => !i.isOutOfRange)
      ?.map((row) => {
        if (row.no === no) {
          return { ...row, [field]: event.target.value };
        }
        return row;
      });
    setMagesuuTemp(updatedData);
  };

  // 板厚マスタのOKボタン押下
  const okMagesuuMaster = () => {
    setIsMageSuu(false);

    const deletedId = [];

    mageSuuData?.forEach((item1, index) => {
      const found = magesuuTemp?.find((item2) => item2.no === item1.no && item2.isOutOfRange);
      if (found) {
        deletedId.push(item1.no);
      }
    });

    const newId = magesuuTemp
      ?.filter((item) => !item.isOutOfRange && !mageSuuData.some((data2Item) => data2Item.no === item.no))
      ?.map((item) => item.no);

    const updatedData = updateList(magesuuList, deletedId, newId);
    const sortedList = sortListByMasterData(updatedData, magesuuTemp);

    // const deletedData = magesuuList?.map((item) => {
    //   return {
    //     ...item,
    //     lstData: item.lstData?.filter((it) => !deletedId?.includes(it.key)),
    //   };
    // });
    // const updatedData = deletedData?.map((item) => {
    //   newId?.forEach((key) => {
    //     // Add new objects to lstData
    //     item.lstData.push({
    //       key: key,
    //       value: '',
    //     });
    //   });

    //   return item;
    // });

    // const sortedList = updatedData?.map((item) => {
    //   const sortedLstData = [...item.lstData];
    //   sortedLstData.sort((a, b) => {
    //     const aIndex = magesuuTemp?.findIndex((obj) => obj.id === a.key);
    //     const bIndex = magesuuTemp?.findIndex((obj) => obj.id === b.key);
    //     return aIndex - bIndex;
    //   });

    //   return {
    //     key: item.key,
    //     materialType: item.materialType,
    //     lstData: sortedLstData,
    //   };
    // });

    setMageSuuData(magesuuTemp);

    props.updatedItaatsuData(magesuuTemp);
    setMagesuuList(sortedList);
    props.updatedMagesuuList(sortedList);
  };

  // 板厚マスタの
  const thicknessContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>しきい値</label>（≦）
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {magesuuTemp
                  ?.filter((subitem) => !subitem.isOutOfRange)
                  ?.map((item, index) => (
                    <tr key={index} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        {errIndexVal?.includes(index) ? (
                          <Popover
                            content={<div style={{ padding: 7 }}>{errorMessage}</div>}
                            placement="left"
                            visible={true}
                          ></Popover>
                        ) : (
                          ''
                        )}
                        <Input
                          value={item.value}
                          onChange={(e) => handleMagesuuChange(e, item.no, 'value')}
                          onBlur={() => isValueOrderCorrect(item.no, 'magesuu')}
                          className={errIndexVal?.includes(index) ? 'red-border' : ''}
                          style={{
                            ...getInputBorderStyle(index, magesuuTemp),
                            pointerEvents: editMode ? 'auto' : 'none',
                          }}
                          ref={(ref) => (inputRef.current[index] = ref)}
                          onKeyPress={(e) => {
                            handleKeyPress(e, false);
                          }}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addMagesuu(item.no, index + 1)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteMagesuu(item.no, e, index)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button
          className={isEmpty(errIndexVal) ? 'mainButton' : 'disabledButton'}
          id="update"
          onClick={okMagesuuMaster}
        >
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} id="discard" onClick={cancelMasterData}>
          キャンセル
        </Button>
      </Row>
    </>
  );

  // 板厚のボタン押下
  const mageSuuModal = () => {
    setErrIndexVal([]);
    setErrorMessage('');
    setIsMageSuu(true);
  };

  const bendNumber = () => {
    setIsMageKosuu(true);
  };

  // 曲げ種類リストの値を入力された場合、
  const magesuuListDataChange = (event, rowKey, no) => {
    const copyData = { ...magesuuList };
    const updatedData = JSON.parse(JSON.stringify(copyData));
    // updatedData[rowIndex].lstData[colIndex].value = event;
    Object.entries(updatedData)?.map(([key, listItem]) => {
      if (parseInt(key) === parseInt(rowKey)) {
        listItem?.map((item) => {
          if (item.no === no) {
            item.value = setTimetoSec(event);
          }
        });
      }
    });
    setMagesuuList(updatedData);
    props.updatedMagesuuList(updatedData);
  };

  const changeMageKosuu = (event, rowIndex, colIndex) => {
    const copyData = [...mageKosuuList];
    const updatedData = JSON.parse(JSON.stringify(copyData));
    updatedData[rowIndex].lstData[colIndex].value = event.target.value;
    setMageKosuuList(updatedData);
    props.updatedMageKosuuList(updatedData);
  };

  // 曲長段取時間のリスト値を変更した場合、
  const changeMageSuuJikan = (event, no) => {
    // const copyData = [...magechouDantoriJikanList];
    // const updatedData = JSON.parse(JSON.stringify(copyData));
    // updatedData[rowIndex].lstData[colIndex].value = event.target.value;
    magechouDantoriJikanList
      ?.filter((item) => item.no === no)
      ?.map((list) => {
        list.value = event.target.value;
      });
    setMagechouDantoriJikanList(magechouDantoriJikanList);
    props.updatedmagechouDantoriJikanListData(magechouDantoriJikanList);
  };

  // サイズ係数のリスト値を変更した場合、
  const changeSizeListData = (value, wId, no) => {
    const copyData = [...sizeKeisuuList];
    const updatedData = JSON.parse(JSON.stringify(copyData));
    updatedData?.map((list) => {
      if (list?.weightId === wId) {
        list?.areaCoeffList?.map((data) => {
          if (data.no === no) {
            data.value = value;
          }
        });
      }
    });
    setSizeKeisuuList(updatedData);
    props.updatedsizeKeisuuListData(updatedData);
  };

  // 重量マスタのOKボタン押下
  const okWeightMaster = () => {
    const deletedId = [];

    weightData?.forEach((item1, index) => {
      const found = detailsWeightTemp?.find((item2) => item2.no === item1.no && item2.isOutOfRange);
      if (found) {
        deletedId.push(item1.no);
      }
    });

    const newId = detailsWeightTemp
      ?.filter((item) => !item.isOutOfRange && !weightData.some((data2Item) => data2Item.no === item.no))
      ?.map((item) => item.no);

    // const keisuuCustomItem =
    //   sizeKeisuuList?.length > 0
    //     ? sizeKeisuuList[0]
    //     : { weightId: 0, areaCoeffList: getListItemByCustomData(areaData)?.[0] };

    const keisuuCustomItem = { weightId: 0, areaCoeffList: getListItemByCustomData(areaData) };

    const deleteSizeKeisuuList = deleteListColData(sizeKeisuuList, deletedId, false, 'weightId');
    const updatedData = updateListColData(deleteSizeKeisuuList, newId, false, { ...keisuuCustomItem }, 'weightId');
    const result = sortListData(updatedData, detailsWeightTemp, false, 'weightId');

    // const deleteSizeKeisuuList = sizeKeisuuList?.filter((it) => !deletedId?.includes(it.key));
    // const deletematerialJikanList = materialHandlingTimeList?.filter((it) => !deletedId?.includes(it.key));
    // const deleteList = iq3SeihinSizeLst?.filter((it) => !deletedId?.includes(it.key));

    // const result = deleteSizeKeisuuList?.map((item) => {
    //   const list = detailsWeightTemp?.find((listItem) => listItem.id === item.key);

    //   return {
    //     ...item,
    //     max: list ? list.max : item.max,
    //     lstData: item.lstData?.map((lstItem) => {
    //       const newListData = {
    //         key: lstItem.key,
    //         value: lstItem.value,
    //       };

    //       return newListData;
    //     }),
    //   };
    // });

    // const result2 = deletematerialJikanList?.map((item) => {
    //   const list = detailsWeightTemp?.find((listItem) => listItem.id === item.key);

    //   return {
    //     ...item,
    //     max: list ? list.max : item.max,
    //     lstData: item.lstData?.map((lstItem) => {
    //       const newListData = {
    //         key: lstItem.key,
    //         value: lstItem.value,
    //       };

    //       return newListData;
    //     }),
    //   };
    // });

    // const result3 = deleteList?.map((item) => {
    //   const list = detailsWeightTemp?.find((listItem) => listItem.id === item.key);

    //   return {
    //     ...item,
    //     max: list ? list.max : item.max,
    //     lstData: item.lstData?.map((lstItem) => {
    //       const newListData = {
    //         key: lstItem.key,
    //         value: lstItem.value,
    //       };

    //       return newListData;
    //     }),
    //   };
    // });

    // newId?.forEach((id) => {
    //   const list = detailsWeightTemp?.find((item) => item.id === id);

    //   if (list) {
    //     const newObj = {
    //       key: id,
    //       max: list.max,
    //       isOutOfRange: true,
    //       lstData: result[0].lstData?.map((lstItem) => {
    //         return {
    //           key: lstItem.key,
    //           value: '',
    //         };
    //       }),
    //     };
    //     const newObj2 = {
    //       key: id,
    //       max: list.max,
    //       isOutOfRange: true,
    //       lstData: result2[0].lstData?.map((lstItem) => {
    //         return {
    //           key: lstItem.key,
    //           value: '00:00:00',
    //         };
    //       }),
    //     };
    //     const newObj3 = {
    //       key: id,
    //       max: list.max,
    //       isOutOfRange: true,
    //       lstData: result3[0].lstData?.map((lstItem) => {
    //         return {
    //           key: lstItem.key,
    //           value: '',
    //         };
    //       }),
    //     };

    //     result.push(newObj);
    //     result2.push(newObj2);
    //     result3.push(newObj3);
    //   }
    // });
    // result.sort((a, b) => {
    //   const aIndex = detailsWeightTemp?.findIndex((obj) => obj.id === a.key);
    //   const bIndex = detailsWeightTemp?.findIndex((obj) => obj.id === b.key);
    //   return aIndex - bIndex;
    // });
    // result2.sort((a, b) => {
    //   const aIndex = detailsWeightTemp?.findIndex((obj) => obj.id === a.key);
    //   const bIndex = detailsWeightTemp?.findIndex((obj) => obj.id === b.key);
    //   return aIndex - bIndex;
    // });
    // result3.sort((a, b) => {
    //   const aIndex = detailsWeightTemp?.findIndex((obj) => obj.id === a.key);
    //   const bIndex = detailsWeightTemp?.findIndex((obj) => obj.id === b.key);
    //   return aIndex - bIndex;
    // });

    setIsWeight(false);
    setWeightData(detailsWeightTemp);

    props.updatedWeight(detailsWeightTemp);

    setSizeKeisuuList(result);
    props.updatedsizeKeisuuListData(result);

    // setMaterialHandlingTimeList(result2);
    // props.updateMaterialHandlingList(result2);

    // setIq3SeihinSizeLst(result3);
    // props.updatediq3ProductSizesList(result3);
  };

  // 曲長マスタの値を変更した場合、
  const handleLengthChange = (event, no, field) => {
    const updatedData = detailsLengthTemp?.map((row) => {
      if (row.no === no) {
        return { ...row, [field]: event.target.value };
      }
      return row;
    });
    setDetailsLengthTemp(updatedData);
  };

  // 重量マスタ値を変更した場合、
  const handleWeightChange = (event, no, field) => {
    const updatedData = detailsWeightTemp?.map((row) => {
      if (row.no === no) {
        return { ...row, [field]: event.target.value };
      }
      return row;
    });
    setDetailsWeightTemp(updatedData);
  };

  // 曲長マスタの行追加を押下
  const addLengthRow = (no, index) => {
    inputRef.current[index].focus();
    const copyData = detailsLengthTemp.slice(); // Create a copy of the original array

    const insertIndex = detailsLengthTemp?.findIndex((item) => item.no === no); // Find the index of the item with id 2

    if (insertIndex !== -1) {
      const data = {
        no: detailsLengthTemp?.length + 1,
        value: 0,
        isOutOfRange: false,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setDetailsLengthTemp(copyData);
    }
  };

  // 曲長マスタの行削除を押下
  const deleteLength = (no, e, index) => {
    // 更新するエラーインデックスを得る
    const updatedErrorIndexes = errIndexVal?.filter((item) => item !== index);

    const finalErrorIndexes = updatedErrorIndexes?.map((item) => (item > index ? item - 1 : item));
    setErrIndexVal(finalErrorIndexes);
    if (detailsLengthTemp?.filter((item) => !item.isOutOfRange)?.length > 2) {
      const updatedData = detailsLengthTemp?.map((row) => {
        if (row.no === no) {
          return { ...row, isOutOfRange: true };
        }
        return row;
      });
      setDetailsLengthTemp(updatedData);
      const filterData = updatedData?.filter((i) => !i.isOutOfRange);
      if (index + 1 !== filterData?.length) {
        inputRef.current[index].focus();
      }
    }
  };

  // 曲長マスタのOKボタン押下
  const addLengthMaster = () => {
    const deletedId = [];

    lengthData?.forEach((item1, index) => {
      const found = detailsLengthTemp?.find((item2) => item2.no === item1.no && item2.isOutOfRange);
      if (found) {
        deletedId.push(item1.no);
      }
    });

    const newId = detailsLengthTemp
      ?.filter((item) => !item.isOutOfRange && !lengthData.some((data2Item) => data2Item.no === item.no))
      ?.map((item) => item.id);

    const afterDeleted = deleteListColData(magechouDantoriJikanList, deletedId);
    const updatedData = updateListColData(afterDeleted, newId);
    const sortedList = sortListData(updatedData, detailsLengthTemp);

    // const dantoriJikanMagechou = magechouDantoriJikanList?.map((item) => {
    //   return {
    //     ...item,
    //     lstData: item.lstData?.filter((it) => !deletedId?.includes(it.key)),
    //   };
    // });

    // const updatedData2 = dantoriJikanMagechou?.map((item) => {
    //   newId?.forEach((key) => {
    //     // Add new objects to lstData
    //     item.lstData.push({
    //       key: key,
    //       value: '',
    //     });
    //   });

    //   return item;
    // });

    // const sortedList2 = updatedData2?.map((item) => {
    //   const sortedLstData = [...item.lstData];
    //   sortedLstData.sort((a, b) => {
    //     const aIndex = detailsLengthTemp?.findIndex((obj) => obj.id === a.key);
    //     const bIndex = detailsLengthTemp?.findIndex((obj) => obj.id === b.key);
    //     return aIndex - bIndex;
    //   });

    //   return {
    //     key: item.key,
    //     materialType: item.materialType,
    //     lstData: sortedLstData,
    //   };
    // });
    setIsLength(false);
    setDetailsLengthTemp(detailsLengthTemp);
    props.updatedLength(detailsLengthTemp);

    setMagechouDantoriJikanList(sortedList);
    props.updatedmagechouDantoriJikanListData(sortedList);
  };

  // 重量マスタの行削除ボタン押下
  const deleteWeight = (no, e, index) => {
    // 更新するエラーインデックスを得る
    const updatedErrorIndexes = errIndexVal?.filter((item) => item !== index);

    const finalErrorIndexes = updatedErrorIndexes?.map((item) => (item > index ? item - 1 : item));
    setErrIndexVal(finalErrorIndexes);

    if (detailsWeightTemp?.filter((item) => !item.isOutOfRange)?.length > 2) {
      const updatedData = detailsWeightTemp?.map((row) => {
        if (row.no === no) {
          return { ...row, isOutOfRange: true };
        }
        return row;
      });
      setDetailsWeightTemp(updatedData);
      const filterData = updatedData?.filter((i) => !i.isOutOfRange);
      if (index + 1 !== filterData?.length) {
        inputRef.current[index].focus();
      }
    }
  };

  // 重量マスタの行追加ボタン押下
  const addWeightRow = (no, index) => {
    inputRef.current[index].focus();
    const copyData = detailsWeightTemp.slice(); // Create a copy of the original array

    const insertIndex = detailsWeightTemp?.findIndex((item) => item.no === no); // Find the index of the item with id 2

    if (insertIndex !== -1) {
      const data = {
        no: detailsWeightTemp?.length + 1,
        value: 0,
        isOutOfRange: false,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setDetailsWeightTemp(copyData);
    }
  };

  // 曲長マスタ
  const addLengthContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>しきい値</label>（≦）
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {detailsLengthTemp
                  ?.filter((subitem) => !subitem.isOutOfRange)
                  ?.map((item, index) => (
                    <tr key={index} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        {errIndexVal?.includes(index) ? (
                          <Popover
                            content={<div style={{ padding: 7 }}>{errorMessage}</div>}
                            placement="left"
                            visible={true}
                          ></Popover>
                        ) : (
                          ''
                        )}
                        <Input
                          value={item.value}
                          onChange={(e) => handleLengthChange(e, item.no, 'value')}
                          onBlur={() => isValueOrderCorrect(item.no, 'length')}
                          className={errIndexVal?.includes(index) ? 'red-border' : ''}
                          style={{
                            ...getInputBorderStyle(index, detailsLengthTemp),
                            pointerEvents: editMode ? 'auto' : 'none',
                          }}
                          onKeyPress={(e) => {
                            handleKeyPress(e, false);
                          }}
                          ref={(ref) => (inputRef.current[index] = ref)}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addLengthRow(item.no, index + 1)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteLength(item.no, e, index)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button
          className={isEmpty(errIndexVal) ? 'mainButton' : 'disabledButton'}
          id="update"
          onClick={addLengthMaster}
        >
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} onClick={cancelMasterData}>
          キャンセル
        </Button>
      </Row>
    </>
  );

  // 子部品サイズ係数のkgボタンを押下
  const addWeightContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>しきい値</label>（≦）
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {detailsWeightTemp
                  ?.filter((i) => !i.isOutOfRange)
                  ?.map((item, index) => (
                    <tr key={index} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        {errIndexVal?.includes(index) ? (
                          <Popover
                            content={<div style={{ padding: 7 }}>{errorMessage}</div>}
                            placement="left"
                            visible={true}
                          ></Popover>
                        ) : (
                          ''
                        )}
                        <Input
                          value={item.value}
                          onChange={(e) => handleWeightChange(e, item.no, 'value')}
                          onBlur={() => isValueOrderCorrect(item.no, 'weight')}
                          className={errIndexVal?.includes(index) ? 'red-border' : ''}
                          style={{
                            ...getInputBorderStyle(index, detailsWeightTemp),
                            pointerEvents: editMode ? 'auto' : 'none',
                          }}
                          onKeyPress={(e) => {
                            handleKeyPress(e, false);
                          }}
                          ref={(ref) => (inputRef.current[index] = ref)}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addWeightRow(item.no, index + 1)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteWeight(item.no, e, index)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button className={isEmpty(errIndexVal) ? 'mainButton' : 'disabledButton'} id="update" onClick={okWeightMaster}>
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} onClick={cancelMasterData}>
          キャンセル
        </Button>
      </Row>
    </>
  );

  const changeflag = (e) => {
    const isChecked = e.target.checked;
    setDantoriFlag(isChecked);
    props.updateDantoriFlag(isChecked);
  };

  const addLength = () => {
    setErrIndexVal([]);
    setErrorMessage('');
    setIsLength(true);
  };
  const addWeight = () => {
    setErrIndexVal([]);
    setErrorMessage('');
    setIsWeight(true);
  };

  // 段取設定の値を入力された場合、
  const changeDantoriTime = (itemId, value) => {
    // const updatedData = dantoriKouteiDetails?.map((item) => {
    //   if (item.key === itemId) {
    //     const updatedSubItems = item.processDetailValue?.map((subItem) => {
    //       if (subItem.key === subItemId) {
    //         return { ...subItem, dantoriTime: newName };
    //       }
    //       return subItem;
    //     });

    //     return { ...item, processDetailValue: updatedSubItems };
    //   }
    //   return item;
    // });

    // setDantoriKouteiDetails(updatedData);
    // props.updatedDantoriSetting(updatedData);
    Object.entries(dantoriKouteiDetails)?.map(([key]) => {
      if (parseInt(key) === itemId) {
        dantoriKouteiDetails[key] = setTimetoSec(value);
      }
    });
    setDantoriKouteiDetails(dantoriKouteiDetails);
    props.updatedDantoriSetting(dantoriKouteiDetails);
  };

  const addArea = () => {
    setErrIndexVal([]);
    setErrorMessage('');
    setIsArea(true);
  };

  // プログラム作成時間／金型段取時間
  const honsuuContent = (
    <>
      <Row
        className="mt-2"
        style={{
          backgroundColor: '#1063aa',
          color: 'white',
          height: 30,
          width:
            honsuuData?.filter((i) => i.isOutOfRange === false)?.length > 4
              ? '100%'
              : honsuuData?.filter((i) => i.isOutOfRange === false)?.length * 210 + 196 + 'px',
        }}
        align="middle"
      >
        <label style={{ marginLeft: 3 }}>
          {category === WorkType.SmBending ? 'プログラム作成時間' : '金型段取時間'}
        </label>
      </Row>
      <div className="qty-table-container">
        <Table
          style={{
            width:
              honsuuData?.filter((i) => i.isOutOfRange === false)?.length > 4
                ? '100%'
                : honsuuData?.filter((i) => i.isOutOfRange === false)?.length * 210 + 196 + 'px',
          }}
        >
          <tr style={{ color: 'white', textAlign: 'center', height: 40 }}>
            <td style={{ width: '196px' }} className="fixedCol">
              <Row justify="center" className="tbl-header">
                <Space>
                  {editMode ? (
                    <>
                      <Button className="cancleButton" onClick={number} style={{ pointerEvents: 'auto' }}>
                        本数
                      </Button>
                    </>
                  ) : (
                    <>
                      <label>本数</label>
                    </>
                  )}
                </Space>
              </Row>
            </td>

            {honsuuData
              ?.filter((subitem) => !subitem.isOutOfRange)
              ?.map((item, index) => (
                <td key={index} style={{ backgroundColor: '#1063aa', width: '210px' }}>
                  {/* {honsuuData[honsuuData?.length - 1].no === item.no ? <>{item.value}本 ～</> : <>～{item.value}本</>} */}
                  ～{item.value}本
                </td>
              ))}
          </tr>

          <tr style={{ textAlign: 'center' }}>
            <td style={{ width: '196px' }} className="fixedOneRowCol">
              {/* ベンディング */}
              {category === WorkType.SmBending ? 'プログラム作成時間' : '金型段取時間'}
              {/* {row.materialType} */}
            </td>
            {honsuuList?.map((item, index) => (
              <td key={index}>
                {/* <Input
                  value={item.value}
                  onChange={(e) => changeHonsuu(e, item.no)}
                  style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                  className={editMode ? 'input-editable ' : 'input-non-editable'}
                /> */}
                <Row align="middle">
                  {!editMode ? (
                    <>
                      <Col span={24}>
                        <label>{secondsToHms(item.value)}</label>
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col span={10}>
                        <Input
                          value={secondsToHms(item.value)}
                          onChange={(e) => changeHonsuu(e.target.value, item.no)}
                        ></Input>
                      </Col>
                      <Col span={14}>
                        <CaretUpOutlined
                          style={{
                            fontSize: 16 + 'px',
                            marginLeft: 3,
                            pointerEvents: 'auto',
                          }}
                          onClick={() => {
                            let editedTime = souchiHandleIncreaseTime(secondsToHms(item.value), '10min');
                            changeHonsuu(editedTime, item.no);
                          }}
                        />
                        <CaretDownOutlined
                          style={{
                            fontSize: 17 + 'px',
                            color: '#000',
                            pointerEvents: 'auto',
                          }}
                          onClick={() => {
                            let editedTime = souchiHandleDecreaseTime(secondsToHms(item.value), '10min');
                            changeHonsuu(editedTime, item.no);
                          }}
                        />
                        <CaretUpOutlined
                          style={{
                            fontSize: 17 + 'px',
                            color: '#000',
                            pointerEvents: 'auto',
                          }}
                          onClick={() => {
                            let editedTime = souchiHandleIncreaseTime(secondsToHms(item.value), 'min');
                            changeHonsuu(editedTime, item.no);
                          }}
                        />
                        <CaretDownOutlined
                          style={{
                            fontSize: 17 + 'px',
                            color: '#000',
                            pointerEvents: 'auto',
                          }}
                          onClick={() => {
                            let editedTime = souchiHandleDecreaseTime(secondsToHms(item.value), 'min');
                            changeHonsuu(editedTime, item.no);
                          }}
                        />
                        <CaretUpOutlined
                          style={{
                            fontSize: 17 + 'px',
                            color: '#000',
                            pointerEvents: 'auto',
                          }}
                          onClick={() => {
                            let editedTime = souchiHandleIncreaseTime(secondsToHms(item.value), 'sec');
                            changeHonsuu(editedTime, item.no);
                          }}
                        />
                        <CaretDownOutlined
                          style={{
                            fontSize: 17 + 'px',
                            color: '#000',
                            pointerEvents: 'auto',
                          }}
                          onClick={() => {
                            let editedTime = souchiHandleDecreaseTime(secondsToHms(item.value), 'sec');
                            changeHonsuu(editedTime, item.no);
                          }}
                        />
                      </Col>
                    </>
                  )}
                </Row>
              </td>
            ))}
          </tr>
        </Table>
      </div>
    </>
  );

  // 子部品サイズ係数
  const sizeContent = (
    <>
      <Row
        style={{
          backgroundColor: '#1063aa',
          color: 'white',
          height: 30,
          width:
            areaData?.filter((i) => !i.isOutOfRange)?.length > 8
              ? '100%'
              : areaData?.filter((i) => !i.isOutOfRange)?.length * 98 + 196 + 'px',
        }}
        align="middle"
      >
        <label style={{ marginLeft: 3 }}>子部品サイズ係数</label>
      </Row>
      <div className="table-container">
        <Table
          style={{
            width:
              areaData?.filter((i) => !i.isOutOfRange)?.length > 8
                ? '100%'
                : areaData?.filter((i) => !i.isOutOfRange)?.length * 98 + 196 + 'px',
          }}
        >
          <tr style={{ color: 'white', textAlign: 'center', height: 40 }}>
            <td style={{ width: '196px' }} className="fixedCol">
              <Row justify="center" className="tbl-header">
                <Space>
                  {editMode ? (
                    <>
                      <Button className="cancleButton" onClick={addWeight} style={{ pointerEvents: 'auto' }}>
                        kg
                      </Button>
                      /
                      <Button className="cancleButton" onClick={addArea} style={{ pointerEvents: 'auto' }}>
                        cm<sup>2</sup>
                      </Button>
                    </>
                  ) : (
                    <>
                      <label>
                        kg / cm<sup>2</sup>
                      </label>
                    </>
                  )}
                </Space>
              </Row>
            </td>

            {areaData
              ?.filter((i) => !i.isOutOfRange)
              ?.map((item, index) => (
                <td key={index} style={{ backgroundColor: '#1063aa', width: '98px' }}>
                  ～{item.value}
                </td>
              ))}
          </tr>

          {/* {sizeKeisuuList?.map((row, rowIndex) => (
            <tr style={{ textAlign: 'center' }}>
              <td style={{ width: '196px' }} className="fixedCol tbl-header">
                ～{row.max}
              </td>

              {row.lstData?.map((col, colIndex) => (
                <td style={{ width: '98px' }}>
                  <Input
                    value={col.value}
                    onChange={(e) => listDataChange(e, rowIndex, colIndex)}
                    style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                    className={editMode ? 'input-editable ' : 'input-non-editable'}
                  />
                </td>
              ))}
            </tr>
          ))} */}
          {/* {Object.keys(sizeKeisuuList)?.map((key) => (
            <tr key={key} style={{ textAlign: 'center' }}>
              <td style={{ color: 'white', width: '196px' }} className="tbl-header fixedCol">
                {key}
              </td>
              {sizeKeisuuList[key]?.map((item) => (
                <td style={{ verticalAlign: 'middle', textAlign: 'center', width: 210 }}>
                  <Input
                    value={item.value}
                    onChange={(e) => listDataChange(e.target.value, key, item.no)}
                    onKeyPress={(e) => {
                      handleKeyPress(e, false);
                    }}
                    style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                    className={editMode ? 'input-editable ' : 'input-non-editable'}
                  />
                </td>
              ))}
            </tr>
          ))} */}

          {sizeKeisuuList?.map((item) => (
            <tr key={`${item?.weightId}`}>
              <td style={{ color: 'white', width: '196px' }} className="tbl-header fixedCol cm-a-center">
                {weightData?.filter((wItem) => wItem.no === item?.weightId)?.[0]?.value}
              </td>
              {item?.areaCoeffList?.map((record, index) => (
                <td key={index} style={{ verticalAlign: 'middle', textAlign: 'center', width: 210 }}>
                  <Input
                    value={record.value}
                    onChange={(e) => changeSizeListData(e.target.value, item?.weightId, record.no)}
                    onKeyPress={(e) => {
                      handleKeyPress(e, false);
                    }}
                    style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                    className={editMode ? 'input-editable ' : 'input-non-editable'}
                  />
                </td>
              ))}
            </tr>
          ))}
        </Table>
      </div>
    </>
  );

  const dantoriKosuuKeisuuContent = (
    <>
      <Row
        className="mt-3"
        style={{
          backgroundColor: '#1063aa',
          color: 'white',
          height: 30,
          width:
            kosuuData?.filter((i) => !i.isOutOfRange)?.length > 8
              ? '100%'
              : kosuuData?.filter((i) => !i.isOutOfRange)?.length * 98 + 196 + 'px',
        }}
        align="middle"
      >
        <label style={{ marginLeft: 3 }}>段取個数係数</label>
      </Row>
      <Row className="qty-table-container">
        <Table
          style={{
            width:
              kosuuData?.filter((i) => !i.isOutOfRange)?.length > 7
                ? '100%'
                : kosuuData?.filter((i) => !i.isOutOfRange)?.length * 98 + 196 + 'px',
          }}
        >
          <tr style={{ color: 'white', textAlign: 'center', height: 40 }}>
            <td style={{ width: '196px' }} className="fixedCol">
              <Row justify="center" className="tbl-header">
                {editMode ? (
                  <>
                    <Button className="cancleButton" onClick={quantity} style={{ pointerEvents: 'auto' }}>
                      個数
                    </Button>
                  </>
                ) : (
                  <>
                    <label>個数</label>
                  </>
                )}
              </Row>
            </td>

            {kosuuData
              ?.filter((i) => !i.isOutOfRange)
              ?.map((item, index) => (
                <td key={index} style={{ backgroundColor: '#1063aa', width: '98px' }}>
                  {/* {kosuuData[kosuuData?.length - 1].id === item.id ? <>{item.value}個 ～</> : <>～{item.value}個</>} */}
                  ～{item.value}個
                </td>
              ))}
          </tr>

          {/* {kosuuList?.map((row, rowIndex) => (
            <tr style={{ textAlign: 'center' }}>
              <td className="fixedOneRowCol">{row.materialType}</td>
              {row.lstData?.map((col, colIndex) => (
                <td style={{ width: '98px' }}>
                  <Input
                    value={col.value}
                    onChange={(e) => changeKosuu(e, rowIndex, colIndex)}
                    style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                    className={editMode ? 'input-editable ' : 'input-non-editable'}
                  />
                </td>
              ))}
            </tr>
          ))} */}
          <tr style={{ textAlign: 'center' }}>
            <td style={{ width: '196px' }} className="fixedOneRowCol">
              段取個数係数
            </td>
            {kosuuList
              ?.filter((i) => !i.isOutOfRange)
              ?.map((row, index) => (
                <td key={index} style={{ width: '98px' }}>
                  <Input
                    value={editMode ? row.value : toDecimal(row.value, 2)}
                    onChange={(e) => changeKosuu(e, row.no)}
                    onKeyPress={(e) => {
                      handleKeyPress(e, false);
                    }}
                    style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                    className={editMode ? 'input-editable ' : 'input-non-editable'}
                  />
                </td>
              ))}
          </tr>
        </Table>
      </Row>
    </>
  );

  const dantoriSettingContent1 = (
    <>
      {/* <Row style={{ backgroundColor: '#1063aa', color: 'white', height: 30, width: '40%' }} align="middle">
        <label style={{ marginLeft: 3 }}>基本段取時間</label>
      </Row> */}
      <Row className="chargeAmount">
        <Col
          style={{
            width: '20%',
          }}
        >
          <Table className="tsuikakoutei">
            <thead>
              <tr style={{ textAlign: 'center', height: 40, verticalAlign: 'middle' }}>
                <th className="tbl-header" style={{ width: '20%' }}>
                  基本段取時間
                </th>

                {/* <th className="tbl-header" style={{ width: '20%' }}>
                  段取按分しない
                </th> */}
              </tr>
            </thead>
            <tbody>
              <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                <td>
                  <Row align="middle">
                    {!editMode ? (
                      <>
                        <Col span={24}>
                          <label>{secondsToHms(setupTime)}</label>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col span={10}>
                          <Input
                            value={secondsToHms(setupTime)}
                            onChange={(e) => {
                              setSetupTime(e.target.value);
                            }}
                          ></Input>
                        </Col>
                        <Col span={14}>
                          <CaretUpOutlined
                            style={{
                              fontSize: 16 + 'px',
                              marginLeft: 3,
                              pointerEvents: editMode ? 'auto' : 'none',
                            }}
                            onClick={() => {
                              let editedTime = souchiHandleIncreaseTime(secondsToHms(setupTime), '10min');
                              changeTime(editedTime);
                            }}
                          />
                          <CaretDownOutlined
                            style={{
                              fontSize: 17 + 'px',
                              color: '#000',
                              pointerEvents: editMode ? 'auto' : 'none',
                            }}
                            onClick={() => {
                              let editedTime = souchiHandleDecreaseTime(secondsToHms(setupTime), '10min');
                              changeTime(editedTime);
                            }}
                          />
                          <CaretUpOutlined
                            style={{
                              fontSize: 17 + 'px',
                              color: '#000',
                              pointerEvents: editMode ? 'auto' : 'none',
                            }}
                            onClick={() => {
                              let editedTime = souchiHandleIncreaseTime(secondsToHms(setupTime), 'min');
                              changeTime(editedTime);
                            }}
                          />
                          <CaretDownOutlined
                            style={{
                              fontSize: 17 + 'px',
                              color: '#000',
                              pointerEvents: editMode ? 'auto' : 'none',
                            }}
                            onClick={() => {
                              let editedTime = souchiHandleDecreaseTime(secondsToHms(setupTime), 'min');
                              changeTime(editedTime);
                            }}
                          />
                          <CaretUpOutlined
                            style={{
                              fontSize: 17 + 'px',
                              color: '#000',
                              pointerEvents: editMode ? 'auto' : 'none',
                            }}
                            onClick={() => {
                              let editedTime = souchiHandleIncreaseTime(secondsToHms(setupTime), 'sec');
                              changeTime(editedTime);
                            }}
                          />
                          <CaretDownOutlined
                            style={{
                              fontSize: 17 + 'px',
                              color: '#000',
                              pointerEvents: editMode ? 'auto' : 'none',
                            }}
                            onClick={() => {
                              let editedTime = souchiHandleDecreaseTime(secondsToHms(setupTime), 'sec');
                              changeTime(editedTime);
                            }}
                          />
                        </Col>
                      </>
                    )}
                  </Row>
                </td>

                {/* <td>
                  <Checkbox
                    style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                    checked={dantoriFlag}
                    onChange={changeflag}
                  ></Checkbox>
                </td> */}
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
  const dantoriSettingContent2 = (
    <>
      <Row className="chargeAmount">
        <Col
          style={{
            width: '20%',
          }}
        >
          <Table className="tsuikakoutei">
            <thead>
              <tr style={{ textAlign: 'center', height: 40, verticalAlign: 'middle' }}>
                <th className="tbl-header" style={{ width: '20%' }}>
                  段取按分しない
                </th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                <td>
                  <Checkbox
                    style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                    checked={dantoriFlag}
                    onChange={changeflag}
                  ></Checkbox>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
  // 段取設定の詳細情報を得る
  const getPrepSettingDetailValues = (id) => {
    var detailValue = {};
    detailValue = Object.entries(dantoriKouteiDetails)?.filter(([key, data]) => parseInt(key) === id)?.[0];
    return detailValue;
  };

  // 指定データより、リスト情報を作成
  const getListItemByCustomData = (data) => {
    const list = data?.map((item) => ({ no: item.no, value: 0, isOutOfRange: false }));
    return list;
  };

  return (
    <>
      <div style={{ marginLeft: 10, marginRight: 10 }}>
        {/* プログラム */}
        {category === WorkType.SmProgram ? (
          <div className="table-container">
            <Table
              className="tsuikakoutei"
              style={{
                width:
                  yousosuu?.filter((i) => !i.isOutOfRange)?.length > 3
                    ? '100%'
                    : yousosuu?.filter((i) => !i.isOutOfRange)?.length * 210 + 196 + 'px',
              }}
            >
              <tr style={{ color: 'white', textAlign: 'center', verticalAlign: 'middle' }}>
                <td style={{ width: '196px' }} className="tbl-header fixedCol">
                  <Row justify="center" align="middle" className="tbl-header">
                    {editMode ? (
                      <>
                        <Button className="cancleButton" onClick={openYousosuuModal} style={{ pointerEvents: 'auto' }}>
                          要素数
                        </Button>
                      </>
                    ) : (
                      <>
                        <label>要素数</label>
                      </>
                    )}
                  </Row>
                </td>

                {yousosuu
                  ?.filter((subitem) => !subitem.isOutOfRange)
                  ?.map((item, index) => (
                    <td key={index} style={{ backgroundColor: '#1063aa', width: '210px' }}>
                      {/* {yousosuu[yousosuu?.length - 1].no === item.no ? <>{item.value}～</> : <>～{item.value}</>} */}
                      ～{item.value}
                    </td>
                  ))}
              </tr>

              <tr style={{ textAlign: 'center' }}>
                <td style={{ width: '196px' }} className="fixedOneRowCol">
                  {/* {row.materialType} */}
                  {'初期段取時間'}
                </td>
                {yousosuuLst
                  ?.filter((item) => !item.isOutOfRange)
                  ?.map((row, index) => (
                    // <td style={{ width: '98px' }}>
                    //   <Input
                    //     value={col.value}
                    //     onChange={(e) => changeYousuu(e, rowIndex, colIndex)}
                    //     style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                    //     className={editMode ? 'input-editable ' : 'input-non-editable'}
                    //   />
                    // </td>
                    <td key={index} style={{ verticalAlign: 'middle', textAlign: 'center', width: 210 }}>
                      <Row align="middle">
                        {!editMode ? (
                          <>
                            <Col span={24}>
                              <label>{secondsToHms(row?.value)}</label>
                            </Col>
                          </>
                        ) : (
                          <>
                            <Col span={10}>
                              <Input
                                value={secondsToHms(row?.value)}
                                onChange={(e) => changeYousuu(e.target.value, row.no)}
                                onKeyPress={(e) => {
                                  handleKeyPress(e, false);
                                }}
                              ></Input>
                            </Col>
                            <Col span={14}>
                              <CaretUpOutlined
                                style={{
                                  fontSize: 16 + 'px',
                                  marginLeft: 3,
                                  pointerEvents: 'auto',
                                }}
                                onClick={() => {
                                  let editedTime = souchiHandleIncreaseTime(secondsToHms(row.value), '10min');
                                  changeYousuu(editedTime, row.no);
                                }}
                              />
                              <CaretDownOutlined
                                style={{
                                  fontSize: 17 + 'px',
                                  color: '#000',
                                  pointerEvents: 'auto',
                                }}
                                onClick={() => {
                                  let editedTime = souchiHandleDecreaseTime(secondsToHms(row.value), '10min');
                                  changeYousuu(editedTime, row.no);
                                }}
                              />
                              <CaretUpOutlined
                                style={{
                                  fontSize: 17 + 'px',
                                  color: '#000',
                                  pointerEvents: 'auto',
                                }}
                                onClick={() => {
                                  let editedTime = souchiHandleIncreaseTime(secondsToHms(row.value), 'min');
                                  changeYousuu(editedTime, row.no);
                                }}
                              />
                              <CaretDownOutlined
                                style={{
                                  fontSize: 17 + 'px',
                                  color: '#000',
                                  pointerEvents: 'auto',
                                }}
                                onClick={() => {
                                  let editedTime = souchiHandleDecreaseTime(secondsToHms(row.value), 'min');
                                  changeYousuu(editedTime, row.no);
                                }}
                              />
                              <CaretUpOutlined
                                style={{
                                  fontSize: 17 + 'px',
                                  color: '#000',
                                  pointerEvents: 'auto',
                                }}
                                onClick={() => {
                                  let editedTime = souchiHandleIncreaseTime(secondsToHms(row.value), 'sec');
                                  changeYousuu(editedTime, row.no);
                                }}
                              />
                              <CaretDownOutlined
                                style={{
                                  fontSize: 17 + 'px',
                                  color: '#000',
                                  pointerEvents: 'auto',
                                }}
                                onClick={() => {
                                  let editedTime = souchiHandleDecreaseTime(secondsToHms(row.value), 'sec');
                                  changeYousuu(editedTime, row.no);
                                }}
                              />
                            </Col>
                          </>
                        )}
                      </Row>
                    </td>
                  ))}
              </tr>
            </Table>
          </div>
        ) : category === WorkType.SmBending ? (
          // ベンディング
          <>
            <Row
              style={{
                backgroundColor: '#1063aa',
                color: 'white',
                height: 30,
                width:
                  mageSuuData?.filter((i) => i.isOutOfRange === false)?.length > 4
                    ? '100%'
                    : mageSuuData?.filter((i) => i.isOutOfRange === false)?.length * 210 + 196 + 'px',
              }}
              align="middle"
            >
              <label style={{ marginLeft: 3 }}>基本段取時間</label>
            </Row>
            <Row className="table-container">
              <Table
                style={{
                  width:
                    mageSuuData?.filter((i) => i.isOutOfRange === false)?.length > 4
                      ? '100%'
                      : mageSuuData?.filter((i) => i.isOutOfRange === false)?.length * 210 + 196 + 'px',
                }}
              >
                <tr style={{ color: 'white', textAlign: 'center', height: 40 }}>
                  <td style={{ width: '196px' }} className="tbl-header fixedCol">
                    <Row justify="center" className="tbl-header">
                      <Space>
                        曲げ種類 /
                        {editMode ? (
                          <>
                            <Button className="cancleButton" onClick={mageSuuModal} style={{ pointerEvents: 'auto' }}>
                              板厚
                            </Button>
                          </>
                        ) : (
                          <>
                            <label>板厚</label>
                          </>
                        )}
                      </Space>
                    </Row>
                  </td>

                  {mageSuuData
                    ?.filter((subitem) => !subitem.isOutOfRange)
                    ?.map((item, index) => (
                      <td key={index} style={{ backgroundColor: '#1063aa', width: '210px' }}>
                        ～{item.value}t
                      </td>
                    ))}
                </tr>

                {Object.entries(magesuuList)
                  ?.filter(([itemKey]) => getProcessDetailTypeName(parseInt(itemKey), processMstRecord) !== undefined)
                  ?.map(([key, groupItem], index) => (
                    <tr key={index} style={{ textAlign: 'center' }}>
                      <td style={{ width: '196px' }} className="tbl-header fixedCol">
                        {getProcessDetailTypeName(parseInt(key), processMstRecord)}
                      </td>
                      {groupItem
                        ?.filter((subitem) => !subitem.isOutOfRange)
                        ?.map((col, index) => (
                          <td key={index} style={{ verticalAlign: 'middle', textAlign: 'center', width: 210 }}>
                            <Row align="middle">
                              {!editMode ? (
                                <>
                                  <Col span={24}>
                                    <label>{secondsToHms(col.value)}</label>
                                  </Col>
                                </>
                              ) : (
                                <>
                                  <Col span={10}>
                                    <Input
                                      value={secondsToHms(col.value)}
                                      onChange={(e) => magesuuListDataChange(e.target.value, parseInt(key), col.no)}
                                    ></Input>
                                  </Col>
                                  <Col span={14}>
                                    <CaretUpOutlined
                                      style={{
                                        fontSize: 16 + 'px',
                                        marginLeft: 3,
                                        pointerEvents: 'auto',
                                      }}
                                      onClick={() => {
                                        let editedTime = souchiHandleIncreaseTime(secondsToHms(col.value), '10min');
                                        magesuuListDataChange(editedTime, parseInt(key), col.no);
                                      }}
                                    />
                                    <CaretDownOutlined
                                      style={{
                                        fontSize: 17 + 'px',
                                        color: '#000',
                                        pointerEvents: 'auto',
                                      }}
                                      onClick={() => {
                                        let editedTime = souchiHandleDecreaseTime(secondsToHms(col.value), '10min');
                                        magesuuListDataChange(editedTime, parseInt(key), col.no);
                                      }}
                                    />
                                    <CaretUpOutlined
                                      style={{
                                        fontSize: 17 + 'px',
                                        color: '#000',
                                        pointerEvents: 'auto',
                                      }}
                                      onClick={() => {
                                        let editedTime = souchiHandleIncreaseTime(secondsToHms(col.value), 'min');
                                        magesuuListDataChange(editedTime, parseInt(key), col.no);
                                      }}
                                    />
                                    <CaretDownOutlined
                                      style={{
                                        fontSize: 17 + 'px',
                                        color: '#000',
                                        pointerEvents: 'auto',
                                      }}
                                      onClick={() => {
                                        let editedTime = souchiHandleDecreaseTime(secondsToHms(col.value), 'min');
                                        magesuuListDataChange(editedTime, parseInt(key), col.no);
                                      }}
                                    />
                                    <CaretUpOutlined
                                      style={{
                                        fontSize: 17 + 'px',
                                        color: '#000',
                                        pointerEvents: 'auto',
                                      }}
                                      onClick={() => {
                                        let editedTime = souchiHandleIncreaseTime(secondsToHms(col.value), 'sec');
                                        magesuuListDataChange(editedTime, parseInt(key), col.no);
                                      }}
                                    />
                                    <CaretDownOutlined
                                      style={{
                                        fontSize: 17 + 'px',
                                        color: '#000',
                                        pointerEvents: 'auto',
                                      }}
                                      onClick={() => {
                                        let editedTime = souchiHandleDecreaseTime(secondsToHms(col.value), 'sec');
                                        magesuuListDataChange(editedTime, parseInt(key), col.no);
                                      }}
                                    />
                                  </Col>
                                </>
                              )}
                            </Row>
                          </td>
                        ))}
                    </tr>
                  ))}
              </Table>
            </Row>
            {/* <Row className="chargeAmount">
              <Col style={{ width: '20%' }}>
                <Table>
                  <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                    <tr style={{ textAlign: 'center', height: 40, verticalAlign: 'middle' }}>
                      <th className="tbl-header">段取按分しない</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>
                        <Checkbox
                          style={{ marginLeft: 3 }}
                          type="checkbox"
                          disabled={!editMode}
                          // checked={subItem.dantoriFlag}
                          onChange={(e) => {
                            // const data = [...processDetails];
                            // const processDetailValue = data[item.key].processDetailValue;
                            // processDetailValue[subItem.key].dantoriFlag = e.target.checked;
                            // if (e.target.checked) {
                            //   processDetailValue[subItem.key].kakouFlag = false;
                            // }
                            // setProcessDetails(data);
                          }}
                        ></Checkbox>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row> */}
            {honsuuContent}
            <Row
              style={{
                backgroundColor: '#1063aa',
                color: 'white',
                height: 30,
                width:
                  lengthData?.filter((i) => !i.isOutOfRange)?.length > 8
                    ? '100%'
                    : lengthData?.filter((i) => !i.isOutOfRange)?.length * 98 + 196 + 'px',
              }}
              align="middle"
            >
              <label style={{ marginLeft: 3 }}>曲長段取時間</label>
            </Row>
            <Row className="table-container">
              <Table
                style={{
                  width:
                    lengthData?.filter((i) => !i.isOutOfRange)?.length > 7
                      ? '100%'
                      : lengthData?.filter((i) => !i.isOutOfRange)?.length * 98 + 196 + 'px',
                }}
              >
                <tr style={{ color: 'white', textAlign: 'center', height: 40 }}>
                  <td style={{ width: '196px' }} className="fixedCol tbl-header">
                    <Row justify="center" className="tbl-header">
                      {editMode ? (
                        <>
                          <Button className="cancleButton" onClick={addLength} style={{ pointerEvents: 'auto' }}>
                            mm(曲長)
                          </Button>
                        </>
                      ) : (
                        <>
                          <label>mm(曲長)</label>
                        </>
                      )}
                    </Row>
                  </td>

                  {lengthData
                    ?.filter((subitem) => !subitem.isOutOfRange)
                    ?.map((item, index) => (
                      <td key={index} style={{ backgroundColor: '#1063aa', width: '98px' }}>
                        ～{item.value}mm
                      </td>
                    ))}
                </tr>

                <tr style={{ textAlign: 'center' }}>
                  <td style={{ width: '196px' }} className="fixedOneRowCol">
                    {/* {row.materialType} */}
                    {'曲長段取時間'}
                  </td>
                  {magechouDantoriJikanList?.map((item, index) => (
                    <td key={index} style={{ width: '98px' }}>
                      <Input
                        key={index}
                        value={item.value}
                        onChange={(e) => changeMageSuuJikan(e, item.no)}
                        style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                        className={editMode ? 'input-editable ' : 'input-non-editable'}
                      />
                    </td>
                  ))}
                </tr>
              </Table>
            </Row>

            {sizeContent}
            {dantoriKosuuKeisuuContent}
          </>
        ) : category === WorkType.SmSecondaryWork ||
          category === WorkType.SmSurface ||
          category === WorkType.SmWelding ||
          category === WorkType.SmWeldFinish ? (
          // 溶接、溶接仕上、表面処理、2次加工
          <>
            {/* {dantoriKouteiDetails?.map((item) => (
              <Row key={item.key} className="chargeAmount">
                {item.processDetailValue?.map((subItem) => (
                  <Col style={{ width: '20%' }}>
                    <Table>
                      <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                        <tr style={{ textAlign: 'center' }}>
                          <th className="tbl-header">{subItem.detailType}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr style={{ textAlign: 'center', height: 35 }}>
                          <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                            <Row align="middle">
                              {!editMode ? (
                                <>
                                  <Col span={24}>
                                    <label>{subItem.dantoriTime}</label>
                                  </Col>
                                </>
                              ) : (
                                <>
                                  <Col span={10}>
                                    <Input
                                      value={subItem.dantoriTime}
                                      onChange={(event) => changeDantoriTime(item.key, subItem.key, event.target.value)}
                                    ></Input>
                                  </Col>
                                  <Col span={14}>
                                    <CaretUpOutlined
                                      style={{
                                        fontSize: 16 + 'px',
                                        marginLeft: 3,
                                        pointerEvents: 'auto',
                                      }}
                                      onClick={() => {
                                        let editedTime = souchiHandleIncreaseTime(subItem.dantoriTime, '10min');

                                        changeDantoriTime(item.key, subItem.key, editedTime);
                                      }}
                                    />
                                    <CaretDownOutlined
                                      style={{
                                        fontSize: 17 + 'px',
                                        color: '#000',
                                        pointerEvents: 'auto',
                                      }}
                                      onClick={() => {
                                        let editedTime = souchiHandleDecreaseTime(subItem.dantoriTime, '10min');
                                        changeDantoriTime(item.key, subItem.key, editedTime);
                                      }}
                                    />
                                    <CaretUpOutlined
                                      style={{
                                        fontSize: 17 + 'px',
                                        color: '#000',
                                        pointerEvents: 'auto',
                                      }}
                                      onClick={() => {
                                        let editedTime = souchiHandleIncreaseTime(subItem.dantoriTime, 'min');
                                        changeDantoriTime(item.key, subItem.key, editedTime);
                                      }}
                                    />
                                    <CaretDownOutlined
                                      style={{
                                        fontSize: 17 + 'px',
                                        color: '#000',
                                        pointerEvents: 'auto',
                                      }}
                                      onClick={() => {
                                        let editedTime = souchiHandleDecreaseTime(subItem.dantoriTime, 'min');
                                        changeDantoriTime(item.key, subItem.key, editedTime);
                                      }}
                                    />
                                    <CaretUpOutlined
                                      style={{
                                        fontSize: 17 + 'px',
                                        color: '#000',
                                        pointerEvents: 'auto',
                                      }}
                                      onClick={() => {
                                        let editedTime = souchiHandleIncreaseTime(subItem.dantoriTime, 'sec');
                                        changeDantoriTime(item.key, subItem.key, editedTime);
                                      }}
                                    />
                                    <CaretDownOutlined
                                      style={{
                                        fontSize: 17 + 'px',
                                        color: '#000',
                                        pointerEvents: 'auto',
                                      }}
                                      onClick={() => {
                                        let editedTime = souchiHandleDecreaseTime(subItem.dantoriTime, 'sec');
                                        changeDantoriTime(item.key, subItem.key, editedTime);
                                      }}
                                    />
                                  </Col>
                                </>
                              )}
                            </Row>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                ))}
              </Row>
            ))} */}
            {Object.entries(groupDisplayList)?.map(([key, groupItem]) => (
              <Row key={key} className="chargeAmount">
                {groupItem?.map((subItem, index) =>
                  subItem ? (
                    <Col key={index} style={{ width: '20%' }}>
                      <Table key={index}>
                        <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                          <tr style={{ textAlign: 'center' }}>
                            <th className="tbl-header">{subItem?.name}</th>
                          </tr>
                        </thead>
                        {getPrepSettingDetailValues(subItem?.id)?.map((item, index) => {
                          if (parseInt(item) === subItem?.id) return;
                          return (
                            <tbody key={index}>
                              <tr style={{ textAlign: 'center', height: 35 }}>
                                <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                                  <Row align="middle">
                                    {!editMode ? (
                                      <>
                                        <Col span={24}>
                                          <label>{secondsToHms(item)}</label>
                                        </Col>
                                      </>
                                    ) : (
                                      <>
                                        <Col span={10}>
                                          <Input
                                            value={secondsToHms(item)}
                                            onChange={(event) => changeDantoriTime(subItem?.id, event.target.value)}
                                          ></Input>
                                        </Col>
                                        <Col span={14}>
                                          <CaretUpOutlined
                                            style={{
                                              fontSize: 16 + 'px',
                                              marginLeft: 3,
                                              pointerEvents: 'auto',
                                            }}
                                            onClick={() => {
                                              let editedTime = souchiHandleIncreaseTime(secondsToHms(item), '10min');
                                              changeDantoriTime(subItem?.id, editedTime);
                                            }}
                                          />
                                          <CaretDownOutlined
                                            style={{
                                              fontSize: 17 + 'px',
                                              color: '#000',
                                              pointerEvents: 'auto',
                                            }}
                                            onClick={() => {
                                              let editedTime = souchiHandleDecreaseTime(secondsToHms(item), '10min');
                                              changeDantoriTime(subItem?.id, editedTime);
                                            }}
                                          />
                                          <CaretUpOutlined
                                            style={{
                                              fontSize: 17 + 'px',
                                              color: '#000',
                                              pointerEvents: 'auto',
                                            }}
                                            onClick={() => {
                                              let editedTime = souchiHandleIncreaseTime(secondsToHms(item), 'min');
                                              changeDantoriTime(subItem?.id, editedTime);
                                            }}
                                          />
                                          <CaretDownOutlined
                                            style={{
                                              fontSize: 17 + 'px',
                                              color: '#000',
                                              pointerEvents: 'auto',
                                            }}
                                            onClick={() => {
                                              let editedTime = souchiHandleDecreaseTime(secondsToHms(item), 'min');
                                              changeDantoriTime(subItem?.id, editedTime);
                                            }}
                                          />
                                          <CaretUpOutlined
                                            style={{
                                              fontSize: 17 + 'px',
                                              color: '#000',
                                              pointerEvents: 'auto',
                                            }}
                                            onClick={() => {
                                              let editedTime = souchiHandleIncreaseTime(secondsToHms(item), 'sec');
                                              changeDantoriTime(subItem?.id, editedTime);
                                            }}
                                          />
                                          <CaretDownOutlined
                                            style={{
                                              fontSize: 17 + 'px',
                                              color: '#000',
                                              pointerEvents: 'auto',
                                            }}
                                            onClick={() => {
                                              let editedTime = souchiHandleDecreaseTime(secondsToHms(item), 'sec');
                                              changeDantoriTime(subItem?.id, editedTime);
                                            }}
                                          />
                                        </Col>
                                      </>
                                    )}
                                  </Row>
                                </td>
                              </tr>
                            </tbody>
                          );
                        })}
                      </Table>
                    </Col>
                  ) : (
                    <></>
                  )
                )}
              </Row>
            ))}
          </>
        ) : (
          <></>
        )}
        {/* バリ取り（自動・手動） */}
        {category === WorkType.SmDeburringAuto ||
        category === WorkType.SmDeburringManual ||
        // シャーリング
        category === WorkType.SmShearing ||
        // 検査
        category === WorkType.SmInspection ||
        // カスタム
        category === WorkType.SmCustom ? (
          <>
            {dantoriSettingContent1}
            {sizeContent}
            {dantoriKosuuKeisuuContent}
          </>
        ) : category === '表面処理' ? (
          <>
            {dantoriSettingContent2}
            {dantoriKosuuKeisuuContent}
          </>
        ) : category === WorkType.SmWelding ||
          category === WorkType.SmWeldFinish ||
          category === WorkType.SmSecondaryWork ? (
          // 溶接、溶接仕上、2次加工
          <>
            {/* {dantoriSettingContent2} */}
            {sizeContent}
            {dantoriKosuuKeisuuContent}
          </>
        ) : category === WorkType.SmBlankLaser ||
          category === WorkType.SmBlankPunch ||
          category === WorkType.SmBlankCombi ? (
          // ブランク
          <>
            {dantoriSettingContent1}
            {dantoriKosuuKeisuuContent}
          </>
        ) : (
          <></>
        )}

        {/* パンチ、複合機*/}
        {category === WorkType.SmBlankPunch || category === WorkType.SmBlankCombi ? <>{honsuuContent}</> : <></>}
      </div>

      {/* 板厚 */}
      {isMagesuu
        ? commonModal(
            isMagesuu,
            addMagesuuModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            thicknessContent,
            null,
            null,
            true
          )
        : ''}
      {/* 要素数 */}
      {isYousosuu
        ? commonModal(
            isYousosuu,
            addYousosuuModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addYousosuuContent,
            null,
            null,
            true
          )
        : ''}
      {/* 曲げ個数 */}
      {isMageKosuu
        ? commonModal(
            isMageKosuu,
            addMageKosuuModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addMageKosuuContent,
            null,
            null,
            true
          )
        : ''}
      {/* 曲長 */}
      {isLength
        ? commonModal(
            isLength,
            addLengthModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addLengthContent,
            null,
            null,
            true
          )
        : ''}
      {/* 重量 */}
      {isWeight
        ? commonModal(
            isWeight,
            addWeightModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addWeightContent,
            null,
            null,
            true
          )
        : ''}
      {/* 個数 */}
      {isKosuu
        ? commonModal(
            isKosuu,
            addKosuuModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addKosuuContent,
            null,
            null,
            true
          )
        : ''}

      {/* 本数 */}
      {isHonsuu
        ? commonModal(
            isHonsuu,
            addHonsuuModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addHonsuuContent,
            null,
            null,
            true
          )
        : ''}
      {/* 面積 */}
      {isArea
        ? commonModal(
            isArea,
            addAreaModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addAreaContent,
            null,
            null,
            true
          )
        : ''}
    </>
  );
});

export default Device_SetupSetting;
