/**
 * クラス名：板金子部品工程入力
 * 説明：板金子部品工程入力ファイルである。
 * 作成者：ナンス
 * 作成日：2023/03/01
 * バージョン：1.0
 */
import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { Row, Col, Input, Select } from 'antd';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { Table } from 'react-bootstrap';
import '../../assets/styles/common.css';
import IQ3KouteiInput_Program from './IQ3KouteiInput_Program';
import IQ3KouteiInput_Blank from './IQ3KouteiInput_Blank';
import IQ3KouteiInput_Bending from './IQ3KouteiInput_Bending';
import IQ3KouteiInput_Baritori from './IQ3KouteiInput_Baritori';
import IQ3KouteiInput_Yousetsu from './IQ3KouteiInput_Yousetsu';
import IQ3KouteiInput_Hyoumenshori from './IQ3KouteiInput_Hyoumenshori';
import IQ3KouteiInput_2Jikakou from './IQ3KouteiInput_2Jikakou';
import IQ3KouteiInput_Shearing from './IQ3KouteiInput_Shearing';
import IQ3KouteiInput_Kensa from './IQ3KouteiInput_Kensa';
import { ServiceClass, WorkType } from '../common/enums';
import { isEmpty } from '@rc-component/mini-decimal/es/numberUtil';

const surfaceData = [];

for (let i = 1; i < 10; i++) {
  surfaceData.push({
    key: i,
    parentname: `iQ3板金`,
    childname: `Test-${i}`,
    surface: '100',
    weight: 10,
    quality: 'SPCC',
    thick: 1.6,
  });
}

const IQ3KouteiInput = forwardRef((props, ref) => {
  const [isHyoumenShori, setIsHyoumenShori] = useState(false);
  const [isShirring, setIsShirring] = useState(false);
  const [isKensa, setIsKensa] = useState(false);
  const [isAdditionalProcess, setIsAdditionalProcess] = useState(false);
  const [isProgram, setIsProgram] = useState(false);
  const [isWelding, setIsWelding] = useState(false);
  const [isBending, setIsBending] = useState(false);
  const [isAuto, setIsAuto] = useState(false);
  const [isPlank, setIsPlank] = useState(false);
  const [isSecondProcess, setIsSecondProcess] = useState(false);
  const [isManual, setIsManual] = useState(false);

  const [programValues, setProgramValues] = useState([]);
  const [rectangleTbl, setRectangleTbl] = useState([]);

  const [hyoumenshoriTbl, setHyoumenshori] = useState([]);

  const [iq3KouteiInputInfo, setIQ3KouteiInputInfo] = useState([]);
  // カット回数
  const [numOfCuts, setNumOfCuts] = useState('1');
  // 検査回数
  const [numOfKensa, setNumOfKensa] = useState('1');

  //   プログラム
  const programRef = useRef();
  // 溶接
  const yousetsu = useRef();

  // 組立
  const kumitate = useRef();

  // 表面処理
  const hyoumenshoriRef = useRef();

  const plankRef = useRef();

  const bendRef = useRef();
  const manualRef = useRef();
  const autoRef = useRef();

  const [displayProgram, setDisplayProgram] = useState(false);
  const [displayBlank, setDisplayBlank] = useState(false);
  const [displaySecondaryWork, setDisplaySecondaryWork] = useState(false);
  const [displayBending, setDisplayBending] = useState(false);
  const [displayDeburring, setDisplayDeburring] = useState(false);
  const [displayWelding, setDisplayWelding] = useState(false);
  const [displaySurface, setDisplaySurface] = useState(false);
  const [displayShearing, setDisplayShearing] = useState(false);
  const [displayInspection, setDisplayInspection] = useState(false);

  const [processInputInfo, setProcessInputInfo] = useState([]);

  const [blankInfo, setBlankInfo] = useState([]);
  const [secondaryWorkInfo, setSecondaryWorkInfo] = useState([]);
  const [bendingInfo, setBendingInfo] = useState([]);
  const [manualDeburringInfo, setManualDeburringInfo] = useState([]);
  const [autoDeburringInfo, setAutoDeburringInfo] = useState([]);
  const [weldingInfo, setWeldingInfo] = useState([]);
  const [surfaceInfo, setSurfaceInfo] = useState([]);
  const [shearingInfo, setShearingInfo] = useState([]);
  const [inspectionInfo, setInspectionInfo] = useState([]);

  const [surfaceData, setSurfaceData] = useState([]);
  const [materialId, setMaterialId] = useState(0);
  const [materialTypeId, setMaterialTypeId] = useState(0);
  const [thickness, setThickness] = useState(0);
  const [sizeX, setSizeX] = useState(0);
  const [sizeY, setSizeY] = useState(0);
  const [weight, setWeight] = useState(0);
  const [area, setArea] = useState(0);
  const [gravity, setGravity] = useState(0);

  useEffect(() => {
    if (props.selectedIQ3DataDetail != undefined) {
      const surfaceDetails = props.processMasterData?.filter((i) => i.workType === WorkType.SmSurface);
      setSurfaceData(surfaceDetails);

      let surfaceProcessId = 0;
      if (surfaceDetails?.length > 0) {
        surfaceProcessId = surfaceDetails[0].id;
      }
      let surface = [];
      surface = props.selectedIQ3DataDetail?.processSelect?.details?.filter((i) => i.processId === surfaceProcessId);

      let surfaceDataFromSelect = [];
      if (surface?.length > 0) {
        surfaceDataFromSelect = surface[0]?.multiprocesses?.filter(
          (i) => i.processDetailGroup != 0 && i.processDetailTypeId != 0
        );
      }
      setMaterialTypeId(props?.selectedIQ3DataDetail?.materialTypeName);
      setMaterialId(props?.selectedIQ3DataDetail?.materialName);
      setSizeX(props?.selectedIQ3DataDetail?.partSizeX);
      setSizeY(props?.selectedIQ3DataDetail?.partSizeY);
      let gravityVal = 0;
      let thicknessVal = 0;
      let xsize = props?.selectedIQ3DataDetail?.partSizeX;
      let ysize = props?.selectedIQ3DataDetail?.partSizeY;
      let thickId = props?.selectedIQ3DataDetail?.thickness;
      const thicknessInfo = props.materialList?.find((item) => item.id === thickId);

      if (thicknessInfo != undefined) {
        setThickness(thicknessInfo?.info?.thick);
        thicknessVal = thicknessInfo?.info?.thick;
        gravityVal = thicknessInfo?.info?.gravity;
        setGravity(gravityVal);
      }
      calculateWeightArea(thicknessVal, xsize, ysize, gravityVal);

      let processInput = props?.selectedIQ3DataDetail?.processInput;

      setProcessInputInfo(processInput);
      processInput?.map((item, index) => {
        const processMstInfo = props.processMasterData?.filter((i) => i.id === item.processId)[0] || [];

        const workType = processMstInfo.workType || '';

        if (processMstInfo.workType === WorkType.SmProgram) {
          setProgramValues(item);
        } else if (
          processMstInfo.workType === WorkType.SmBlankCombi ||
          processMstInfo.workType === WorkType.SmBlankLaser ||
          processMstInfo.workType === WorkType.SmBlankPunch
        ) {
          let matchItem = props.selectedIQ3DataDetail?.processSelect?.details?.find(
            (i) => i.processId === item.processId
          );

          if (matchItem) {
            let isUsed = matchItem.isUsedForCalc;
            if (isUsed === true) setBlankInfo(item);
          }
        } else if (processMstInfo.workType === WorkType.SmSecondaryWork) {
          setSecondaryWorkInfo(item);
        } else if (workType === WorkType.SmBending) {
          setBendingInfo(item);
        } else if (workType === WorkType.SmDeburringAuto) {
          setAutoDeburringInfo(item);
        } else if (workType === WorkType.SmDeburringManual) {
          setManualDeburringInfo(item);
        } else if (workType === WorkType.SmWelding) {
          setWeldingInfo(item);
        } else if (workType === WorkType.SmSurface) {
          // setSurfaceInfo(item);
          const result = surfaceDataFromSelect?.map((item1) => {
            const matchingData = item.details?.find((item2) => item2.no === item1.processNo);
            if (matchingData) {
              return {
                ...matchingData,
                processDetailWorkTypeGroup: item1.processDetailGroup,
                processDetailTypeNo: item1.processDetailTypeId,
              };
            } else {
              return {
                no: item1.processNo,
                calcType: 1,
                processDetailWorkTypeGroup: item1.processDetailGroup,
                processDetailTypeNo: item1.processDetailTypeId,
                areaUnitPrice: area,
                weightUnitPrice: weight,
                weightCount: 1,
                areaCount: 1,
                areaAutoExtract: 0,
                weightAutoExtract: 0,
                totalArea: 0 * 1,
                totalWeight: 0 * 1,
              };
            }
          });
          item.details = result;
          setSurfaceInfo(item);
        } else if (workType === WorkType.SmShearing) {
          setShearingInfo(item);
        } else if (workType === WorkType.SmInspection) {
          setInspectionInfo(item);
        }
      });
      // calculateProgramCount(blankInfo, sizeX, sizeY);
      // }
      checkDisplayPanel();
    }
  }, [props.selectedIQ3DataDetail, props.processMasterData]);

  // 重量計算
  const calculateWeightArea = (thicknessVal, xsize, ysize, gravityVal) => {
    let weightVal = ((thicknessVal * parseInt(xsize) * parseInt(ysize)) / 1000000) * gravityVal;

    setWeight(weightVal);
    let areaVal = (parseInt(xsize) * parseInt(ysize)) / 100;
    setArea(areaVal);
    return [weightVal, areaVal];
  };
  const checkDisplayPanel = () => {
    // プログラム
    let programData = props.processMasterData?.filter((i) => i.workType === WorkType.SmProgram);
    if (programData && programData?.length > 0) {
      let programId = programData[0].id;
      let program = props?.selectedIQ3DataDetail?.processSelect?.details?.filter((i) => i.processId === programId);

      if (program != undefined) {
        setDisplayProgram(program[0]?.isUsedForCalc);
      }
    }
    // ブランク
    let blankData = props.processMasterData?.filter(
      (i) =>
        i.workType === WorkType.SmBlankCombi ||
        i.workType === WorkType.SmBlankLaser ||
        i.workType === WorkType.SmBlankPunch
    );
    if (blankData && blankData?.length > 0) {
      const filteredList2 = props?.selectedIQ3DataDetail?.processSelect?.details?.filter((item2) =>
        blankData.some((item1) => item1.id === item2.processId)
      );

      const isAnyTrue = filteredList2?.some((item) => item.isUsedForCalc);

      setDisplayBlank(isAnyTrue);
    }
    // 2次加工
    let secondaryWorkData = props.processMasterData?.filter((i) => i.workType === WorkType.SmSecondaryWork);
    if (secondaryWorkData && secondaryWorkData?.length > 0) {
      let secondaryWorkId = secondaryWorkData[0].id;
      let secordaryWork = props?.selectedIQ3DataDetail?.processSelect?.details?.filter(
        (i) => i.processId === secondaryWorkId
      );
      if (secordaryWork != undefined) {
        setDisplaySecondaryWork(secordaryWork[0]?.isUsedForCalc);
      }
    }
    // ベンディング
    let bendingData = props.processMasterData?.filter((i) => i.workType === WorkType.SmBending);
    if (bendingData && bendingData?.length > 0) {
      let bendingId = bendingData[0].id;
      let bending = props?.selectedIQ3DataDetail?.processSelect?.details?.filter((i) => i.processId === bendingId);
      if (bending != undefined) {
        setDisplayBending(bending[0]?.isUsedForCalc);
      }
    }
    // バリ取り
    let deburringData = props.processMasterData?.filter(
      (i) => i.workType === WorkType.SmDeburringAuto || i.workType === WorkType.SmDeburringManual
    );
    if (deburringData && deburringData?.length > 0) {
      const filteredList2 = props?.selectedIQ3DataDetail?.processSelect?.details?.filter((item2) =>
        deburringData?.some((item1) => item1.id === item2.processId)
      );

      const isAnyTrue = filteredList2?.some((item) => item.isUsedForCalc);

      setDisplayDeburring(isAnyTrue);
    }
    // 溶接
    let weldingData = props.processMasterData?.filter((i) => i.workType === WorkType.SmWelding);
    if (weldingData && weldingData?.length > 0) {
      let weldingId = weldingData[0].id;
      let welding = props?.selectedIQ3DataDetail?.processSelect?.details?.filter((i) => i.processId === weldingId);
      if (welding != undefined) {
        setDisplayWelding(welding[0]?.isUsedForCalc);
      }
    }
    // 表面処理
    let surfaceData = props.processMasterData?.filter((i) => i.workType === WorkType.SmSurface);
    if (surfaceData && surfaceData?.length > 0) {
      let surfaceId = surfaceData[0].id;
      let surface = props?.selectedIQ3DataDetail?.processSelect?.details?.filter((i) => i.processId === surfaceId);
      if (surface != undefined) {
        setDisplaySurface(surface[0]?.isUsedForCalc);
      }
    }
    // シャーリング
    let shearingData = props.processMasterData?.filter((i) => i.workType === WorkType.SmShearing);
    if (shearingData && shearingData?.length > 0) {
      let shearingId = shearingData[0].id;
      let shearing = props?.selectedIQ3DataDetail?.processSelect?.details?.filter((i) => i.processId === shearingId);
      if (shearing != undefined) {
        setDisplayShearing(shearing[0]?.isUsedForCalc);
      }
    }
    // 検査
    let inspectionData = props.processMasterData?.filter((i) => i.workType === WorkType.SmInspection);
    if (inspectionData && inspectionData?.length > 0) {
      let inspectionId = inspectionData[0].id;
      let inspection = props?.selectedIQ3DataDetail?.processSelect?.details?.filter(
        (i) => i.processId === inspectionId
      );
      if (inspection != undefined) {
        setDisplayInspection(inspection[0]?.isUsedForCalc);
      }
    }
  };
  const welding = () => {
    if (isWelding == true) {
      setIsWelding(false);
    } else {
      setIsWelding(true);
    }
  };

  const plank = () => {
    if (isPlank == true) {
      setIsPlank(false);
    } else {
      setIsPlank(true);
    }
  };

  const hyoumenShori = () => {
    if (isHyoumenShori === true) {
      setIsHyoumenShori(false);
    } else {
      setIsHyoumenShori(true);
    }
  };
  const shirring = () => {
    if (isShirring === true) {
      setIsShirring(false);
    } else {
      setIsShirring(true);
    }
  };
  const kensa = () => {
    if (isKensa === true) {
      setIsKensa(false);
    } else {
      setIsKensa(true);
    }
  };

  const program = () => {
    if (isProgram == true) {
      setIsProgram(false);
    } else {
      setIsProgram(true);
    }
  };
  const secondProcess = () => {
    if (isSecondProcess === true) {
      setIsSecondProcess(false);
    } else {
      setIsSecondProcess(true);
    }
  };

  const bending = () => {
    if (isBending == true) {
      setIsBending(false);
    } else {
      setIsBending(true);
    }
  };
  const auto = () => {
    if (isAuto == true) {
      setIsAuto(false);
    } else {
      setIsAuto(true);
    }
  };
  const manual = () => {
    if (isManual == true) {
      setIsManual(false);
    } else {
      setIsManual(true);
    }
  };
  const calculateProgramCount = (result, sizeX, sizeY) => {
    let totalCount = 0;
    if (Object.keys(result).length !== 0) {
      let blankList = result?.details?.[0]?.quantityList;
      totalCount = blankList?.reduce((total, item) => {
        const countValue = parseInt(item?.count, 10);
        return total + (countValue === 0 ? 1 : countValue);
      }, 0);
    }

    if (sizeX != 0 && sizeY != 0) {
      programValues.details[0].elements = 4 + totalCount;
      programValues.details[0].elementsAuto = 4 + totalCount;
    } else {
      programValues.details[0].elements = 0 + totalCount;
      programValues.details[0].elementsAuto = 0 + totalCount;
    }
    setProgramValues(programValues);
  };
  const updateProcessData = (result) => {
    const updatedLists = [...processInputInfo];
    const indexToUpdate = updatedLists?.findIndex(
      (item) => item.id === result.id && item.processId === result.processId
    );

    if (indexToUpdate !== -1) {
      updatedLists[indexToUpdate].details = result.details;
    }
    setProcessInputInfo(updatedLists);

    const processMstInfo = props.processMasterData?.filter((i) => i.id === result.processId)[0] || [];

    const workType = processMstInfo.workType || '';

    if (
      workType === WorkType.SmBlankCombi ||
      workType === WorkType.SmBlankLaser ||
      workType === WorkType.SmBlankPunch
    ) {
      setBlankInfo(result);
      if (displayBlank) {
        calculateProgramCount(result, sizeX, sizeY);
      } else {
        calculateProgramCount({}, sizeX, sizeY);
      }
    }
  };
  const changeSize = (e, size) => {
    if (size === 'x') {
      setSizeX(e.target.value);
      let recSize = calculateWeightArea(thickness, e.target.value, sizeY, gravity);

      const updatedJsonObject = { ...surfaceInfo };
      updatedJsonObject?.details?.forEach((detail) => {
        detail.areaUnitPrice = recSize[1];
        detail.weightUnitPrice = recSize[0];
      });
      setSurfaceInfo(updatedJsonObject);

      const updateDeburringInfo = { ...autoDeburringInfo };
      updateDeburringInfo?.details?.forEach((detail) => {
        detail.area = recSize[1];
        detail.areaAutoExtract = recSize[1];
        detail.weight = recSize[0];
        detail.weightAutoExtract = recSize[0];
      });
      setAutoDeburringInfo(updateDeburringInfo);

      // サイズにより、ブランクの外径周長(mm)と自動抽出(外径周長)
      let outerDia = (parseFloat(e.target.value) + parseFloat(sizeY)) * 2;
      const updatedBlankInfo = { ...blankInfo };
      updatedBlankInfo?.details?.forEach((detail) => {
        detail.dimensionData.outerDia = outerDia;
        detail.dimensionData.outerAutoExtract = outerDia;
      });
      setBlankInfo(updatedBlankInfo);

      // プログラム
      if (displayBlank) {
        calculateProgramCount(updatedBlankInfo, e.target.value, sizeY);
      } else {
        calculateProgramCount({}, e.target.value, sizeY);
      }

      // if (e.target.value != 0 && sizeY != 0) {
      //   programValues.details[0].elements = 4 + programValues.details[0].elements;
      //   programValues.details[0].elementsAuto = 4 + programValues.details[0].elementsAuto;
      // }
      // setProgramValues(programValues);
    } else {
      setSizeY(e.target.value);

      let recSize = calculateWeightArea(thickness, sizeX, e.target.value, gravity);

      const updatedJsonObject = { ...surfaceInfo };
      updatedJsonObject?.details?.forEach((detail) => {
        detail.areaUnitPrice = recSize[1];
        detail.weightUnitPrice = recSize[0];
      });
      setSurfaceInfo(updatedJsonObject);
      // サイズにより、ブランクの外径周長(mm)と自動抽出(外径周長)
      let outerDia = (parseFloat(e.target.value) + parseFloat(sizeX)) * 2;
      const updatedBlankInfo = { ...blankInfo };
      updatedBlankInfo?.details?.forEach((detail) => {
        detail.dimensionData.outerDia = outerDia;
        detail.dimensionData.outerAutoExtract = outerDia;
      });
      setBlankInfo(updatedBlankInfo);

      const updateDeburringInfo = { ...autoDeburringInfo };
      updateDeburringInfo?.details?.forEach((detail) => {
        detail.area = recSize[1];
        detail.areaAutoExtract = recSize[1];
        detail.weight = recSize[0];
        detail.weightAutoExtract = recSize[0];
      });
      setAutoDeburringInfo(updateDeburringInfo);

      if (displayBlank) {
        calculateProgramCount(updatedBlankInfo, sizeX, e.target.value);
      } else {
        calculateProgramCount({}, sizeX, e.target.value);
      }
    }
  };
  useImperativeHandle(
    ref,
    () => ({
      getKouteiInputInfo: () => {
        return processInputInfo;
      },
      // getKouteiInputInfo: updateProcessData,
      getRectangleXSize: () => {
        return sizeX;
      },
      getRectangleYSize: () => {
        return sizeY;
      },
      getWeightValue: () => {
        return weight;
      },
      getAreaValue: () => {
        return area;
      },
    }),
    [processInputInfo, sizeX, sizeY]
  );
  const changeDetails = (event, name) => {
    const temp = JSON.parse(JSON.stringify(rectangleTbl));
    if (name === 'materialName' || name === 'quality' || name === 'thick') {
      const updatedData = temp?.map((row) => {
        return { ...row, [name]: event };
      });
      setRectangleTbl(updatedData);
    } else {
      const updatedData = temp?.map((row) => {
        return { ...row, [name]: event.target.value };
      });
      setRectangleTbl(updatedData);
    }
  };
  const updatedSurfaceProcessInput = () => {};

  return (
    <>
      <Row className="registerModal sticky-style" style={{ paddingTop: '5px' }}>
        <Table className="kouteiinput" style={{ marginLeft: '5px', width: '99%', marginRight: '22px' }}>
          <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
            <tr>
              <td colSpan={3} className="tbl-header">
                材料
              </td>
              <td colSpan={4} className="tbl-header">
                矩形サイズ
              </td>
            </tr>
          </thead>
          <tbody>
            <tr className="table-light" style={{ textAlign: 'center' }}>
              <td style={{ width: '22%' }}>材料名称</td>
              <td style={{ width: '22%' }}>材質</td>
              <td style={{ width: '11%' }}>板厚</td>
              <td style={{ width: '11%' }}>Xサイズ</td>
              <td style={{ width: '11%' }}>Yサイズ</td>
              <td style={{ width: '11%' }}>重量(kg)</td>
              <td style={{ width: '11%' }}>
                表面積(cm<sup>2</sup>)
              </td>
            </tr>
            {/* {rectangleTbl?.map((i, index) => (
              <> */}
            <tr>
              <td style={{ verticalAlign: 'middle', alignContent: 'center' }}>
                {materialId}
                {/* <Select
                      value={i.materialName}
                      onChange={(e) => changeDetails(e, 'materialName')}
                      style={{ pointerEvents: editMode ? 'auto' : 'none', width: '100%' }}
                    >
                    
                      {types?.map((option, index) => (
                        <Select.Option key={index} value={option.value}>
                          {option.label}
                        </Select.Option>
                      ))}
                    </Select> */}
                {/* {materialId} */}
              </td>

              <td style={{ verticalAlign: 'middle' }}>
                {materialTypeId}
                {/* <Select
                  value={i.quality}
                  onChange={(e) => changeDetails(e, 'quality')}
                  style={{ pointerEvents: editMode ? 'auto' : 'none', width: '100%' }}
                >
                  <Select.Option value={i.quality}>{i.quality}</Select.Option>
                </Select> */}
              </td>

              <td style={{ verticalAlign: 'middle', alignContent: 'center' }}>
                {thickness}
                {/* <Select value={i.thick} onChange={(e) => changeDetails(e, 'thick')} style={{ width: '100%' }}>
                  {itaatsuTypes?.map((option, index) => (
                    <Select.Option key={index} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select> */}
              </td>
              <td>
                <Input
                  className="input-editable"
                  value={sizeX}
                  // onChange={(e) => setSizeX(e.target.value)}
                  onChange={(e) => changeSize(e, 'x')}
                  // value={i.xsize}
                  // onChange={(e) => changeDetails(e, 'xsize')}
                  style={{ textAlign: 'center' }}
                ></Input>
              </td>
              <td>
                <Input
                  className="input-editable"
                  value={sizeY}
                  // onChange={(e) => setSizeY(e.target.value)}
                  onChange={(e) => changeSize(e, 'y')}
                  // value={i.ysize}
                  // onChange={(e) => changeDetails(e, 'ysize')}
                  style={{ textAlign: 'center' }}
                ></Input>
              </td>
              <td>
                <Input className="input-non-editable " style={{ textAlign: 'center' }} value={weight}></Input>
              </td>
              <td>
                <Input className="input-non-editable " style={{ textAlign: 'center' }} value={area}></Input>
              </td>
            </tr>
            {/* </>
            ))} */}
          </tbody>
        </Table>
      </Row>
      <div className="kouteical timecharge" style={{ zIndex: 2 }}>
        {/* プログラム */}
        {displayProgram ? (
          <>
            <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={program}>
              <Col span={20} className="deli-col">
                <div style={{ display: 'flex' }}>
                  {isProgram ? (
                    <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
                  ) : (
                    <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
                  )}
                  <label style={{ marginLeft: 5 }}>プログラム</label>
                </div>
              </Col>
            </Row>
            <div style={{ display: isProgram ? 'block' : 'none', margin: '5px' }}>
              <IQ3KouteiInput_Program
                // editMode={props.editMode}
                programVal={programValues}
                // selectedIQ3Data={props.selectedIQ3DataDetail}
                blankInfo={blankInfo}
                sizeX={sizeX}
                sizeY={sizeY}
                updateProgram={updateProcessData}
              />
            </div>
          </>
        ) : (
          <></>
        )}
        {/* ブランク */}
        {displayBlank ? (
          <>
            <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={plank}>
              <Col span={20} className="deli-col">
                <div style={{ display: 'flex' }}>
                  {isPlank ? (
                    <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
                  ) : (
                    <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
                  )}
                  <label style={{ marginLeft: 5 }}>ブランク</label>
                </div>
              </Col>
            </Row>
            <div style={{ display: isPlank ? 'block' : 'none', margin: '5px' }}>
              <IQ3KouteiInput_Blank blankInfo={blankInfo} updateBlank={updateProcessData} />
            </div>
          </>
        ) : (
          <></>
        )}

        {/* 2次加工 */}
        {displaySecondaryWork ? (
          <>
            <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={secondProcess}>
              <Col span={20} className="deli-col">
                <div style={{ display: 'flex' }}>
                  {isSecondProcess ? (
                    <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
                  ) : (
                    <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
                  )}
                  <label style={{ marginLeft: 5 }}>2次加工</label>
                </div>
              </Col>
            </Row>
            <div style={{ display: isSecondProcess ? 'block' : 'none', margin: '5px' }}>
              <IQ3KouteiInput_2Jikakou
                secondaryWorkInfo={secondaryWorkInfo}
                updateSecondaryWork={updateProcessData}
                processMaster={props.processMasterData}
              ></IQ3KouteiInput_2Jikakou>
            </div>
          </>
        ) : (
          <></>
        )}
        {/* ベンディング */}
        {displayBending ? (
          <>
            <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={bending}>
              <Col span={20} className="deli-col">
                <div style={{ display: 'flex' }}>
                  {isBending ? (
                    <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
                  ) : (
                    <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
                  )}
                  <label style={{ marginLeft: 5 }}>ベンディング</label>
                </div>
              </Col>
            </Row>
            <div style={{ display: isBending ? 'block' : 'none', margin: '5px' }}>
              <IQ3KouteiInput_Bending
                bendingInfo={bendingInfo}
                updateBending={updateProcessData}
                processMaster={props.processMasterData}
              />
            </div>
          </>
        ) : (
          <></>
        )}
        {/* バリ取り */}
        {displayDeburring ? (
          <>
            <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={manual}>
              <Col span={20} className="deli-col">
                <div style={{ display: 'flex' }}>
                  {isManual ? (
                    <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
                  ) : (
                    <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
                  )}
                  <label style={{ marginLeft: 5 }}>バリ取</label>
                </div>
              </Col>
            </Row>
            <div style={{ display: isManual ? 'block' : 'none', margin: '5px' }}>
              <IQ3KouteiInput_Baritori
                blankInfo={blankInfo}
                manualDeburringInfo={manualDeburringInfo}
                autoDeburringInfo={autoDeburringInfo}
                updateDeburring={updateProcessData}
              />
            </div>
          </>
        ) : (
          <></>
        )}

        {/* 自動バリ */}
        {/* <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={auto}>
          <Col span={20} className="deli-col">
            <div style={{ display: 'flex' }}>
              {isAuto ? (
                <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
              ) : (
                <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
              )}
              <label style={{ marginLeft: 5 }}>自動バリ</label>
            </div>
          </Col>
        </Row>
        <div style={{ display: isAuto ? 'block' : 'none', margin: '5px' }}>
          <IQ3KouteiInput_Auto editMode={props.editMode} autoData={autoTbl} />
        </div> */}

        {/* 溶接 */}
        {displayWelding ? (
          <>
            <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={welding}>
              <Col span={20} className="deli-col">
                <div style={{ display: 'flex' }}>
                  {isWelding ? (
                    <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
                  ) : (
                    <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
                  )}
                  <label style={{ marginLeft: 5 }}>溶接</label>
                </div>
              </Col>
            </Row>
            <div style={{ display: isWelding ? 'block' : 'none', margin: '5px' }}>
              <IQ3KouteiInput_Yousetsu
                weldingInfo={weldingInfo}
                updateWelding={updateProcessData}
                processMaster={props.processMasterData}
              />
            </div>
          </>
        ) : (
          <></>
        )}
        {/* 表面処理 */}
        {displaySurface ? (
          <>
            <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={hyoumenShori}>
              <Col span={20} className="deli-col">
                <div style={{ display: 'flex' }}>
                  {isHyoumenShori ? (
                    <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
                  ) : (
                    <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
                  )}
                  <label style={{ marginLeft: 5 }}>表面処理</label>
                </div>
              </Col>
            </Row>
            <div style={{ display: isHyoumenShori ? 'block' : 'none', margin: '5px' }}>
              <IQ3KouteiInput_Hyoumenshori
                type={'iq3'}
                // editMode={props.editMode}
                kouteiInputInfo={hyoumenshoriTbl}
                surfaceInfo={surfaceInfo}
                surfaceData={surfaceData}
                updateSurface={updateProcessData}
              />
            </div>
          </>
        ) : (
          <></>
        )}

        {/* シャーリング */}
        {displayShearing ? (
          <>
            <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={shirring}>
              <Col span={20} className="deli-col">
                <div style={{ display: 'flex' }}>
                  {isShirring ? (
                    <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
                  ) : (
                    <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
                  )}
                  <label style={{ marginLeft: 5 }}>シャーリング</label>
                </div>
              </Col>
            </Row>
            <div style={{ display: isShirring ? 'block' : 'none', margin: '5px' }}>
              <IQ3KouteiInput_Shearing
                numOfCuts={numOfCuts}
                shearingInfo={shearingInfo}
                updateShearing={updateProcessData}
              />
            </div>
          </>
        ) : (
          <></>
        )}

        {/* 検査 */}
        {displayInspection ? (
          <>
            <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row" onClick={kensa}>
              <Col span={20} className="deli-col">
                <div style={{ display: 'flex' }}>
                  {isKensa ? (
                    <CaretUpOutlined style={{ fontSize: 18, marginTop: 2 }} />
                  ) : (
                    <CaretDownOutlined style={{ fontSize: 18, marginTop: 2 }} />
                  )}
                  <label style={{ marginLeft: 5 }}>検査</label>
                </div>
              </Col>
            </Row>
            <div style={{ display: isKensa ? 'block' : 'none', margin: '5px' }}>
              <IQ3KouteiInput_Kensa
                numOfKensa={numOfKensa}
                inspectionInfo={inspectionInfo}
                updateInspection={updateProcessData}
              />
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
});

export default IQ3KouteiInput;
