import { Button, Row, Input, Col, Image, Space, Modal, Radio } from 'antd';
import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { CloseOutlined, CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import '../../assets/styles/common.css';
import child_add from '../../assets/images/child_add.png';
import minus_icon from '../../assets/images/minus_icon.png';
import { formatDateString, getTenjikaiSampleData, JPYs, secondsToHms, setTimetoSec, todayTime } from '../common/Common';
import { commonTaiochuModal, commonModal, commonDeleteFooter } from '../common/CommonModal';
import { ServiceClass } from '../common/enums';
import { CSVLink } from 'react-csv';

dayjs.extend(customParseFormat);
const dateFormat = 'YYYY/MM/DD';

const KouteibetsuInfo = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  let [propsParam, setPropsParam] = useState(props.paramNm);
  const [showDetailRow, setShowDetailRow] = useState(true);
  const [showDetailRowIQ3, setShowDetailRowIQ3] = useState(true);
  const [showDetailRowIQ5, setShowDetailRowIQ5] = useState(true);
  const [showDetailRowIQ7, setShowDetailRowIQ7] = useState(true);
  const [activePartMenu, setActivePartMenu] = useState('total');
  const [parentProcess, setParentProcess] = useState([]);
  const [iq3Process, setIq3Process] = useState([]);
  const [iq5Process, setIq5Process] = useState([]);
  const [setupTotalTime, setSetupTotalTime] = useState(0);
  const [processingTotalTime, setProcessingTotalTime] = useState(0);
  const [setupTotalAmount, setSetupTotalAmount] = useState(0);
  const [processingTotalAmount, setProcessingTotalAmount] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [tanka, setTanka] = useState(0);
  // iq3
  const [setupTotalTime_IQ3, setSetupTotalTime_IQ3] = useState(0);
  const [processingTotalTime_IQ3, setProcessingTotalTime_IQ3] = useState(0);
  const [setupTotalAmount_IQ3, setSetupTotalAmount_IQ3] = useState(0);
  const [processingTotalAmount_IQ3, setProcessingTotalAmount_IQ3] = useState(0);
  const [totalTime_IQ3, setTotalTime_IQ3] = useState(0);
  const [totalAmount_IQ3, setTotalAmount_IQ3] = useState(0);
  const [tanka_IQ3, setTanka_IQ3] = useState(0);
  const [iq3MaterialAmount, setIq3MaterialAmount] = useState(0);

  // iq5
  const [setupTotalTime_IQ5, setSetupTotalTime_IQ5] = useState(0);
  const [processingTotalTime_IQ5, setProcessingTotalTime_IQ5] = useState(0);
  const [setupTotalAmount_IQ5, setSetupTotalAmount_IQ5] = useState(0);
  const [processingTotalAmount_IQ5, setProcessingTotalAmount_IQ5] = useState(0);
  const [totalTime_IQ5, setTotalTime_IQ5] = useState(0);
  const [totalAmount_IQ5, setTotalAmount_IQ5] = useState(0);
  const [tanka_IQ5, setTanka_IQ5] = useState(0);
  const [iq5MaterialAmount, setIq5MaterialAmount] = useState(0);

  // all total
  const [setupAllTotalTime, setSetupAllTotalTime] = useState(0);
  const [setupAllTotalAmount, setSetupAllTotalAmount] = useState(0);
  const [processingAllTotalTime, setProcessingAllTotalTime] = useState(0);
  const [processingAllTotalAmount, setProcessingAllTotalAmount] = useState(0);
  const [allTotalTime, setAllTotalTime] = useState(0);
  const [allTotalAmount, setAllTotalAmount] = useState(0);
  const [tanka_all, setTanka_all] = useState(0);
  const [allMaterialAmount, setallMaterialAmount] = useState(0);

  // 開発中ポップアップ
  const [openCommonTaiochuPopup, setOpenCommonTaiochuPopup] = useState(false);

  const [suryou, setSuryou] = useState(1);
  const [processCalAmount, setProcessCalAmount] = useState([]);
  const [parentCalAmount, setParentCalAmount] = useState([]);
  const [iq3CalAmount, setIQ3Amount] = useState([]);
  const tenjikaiSampleData = getTenjikaiSampleData();

  // CSV出力
  const csvExportRef = useRef();
  const [fileName, setFileName] = useState(`工程別合計_${formatDateString(todayTime())}?.csv`);

  useEffect(() => {
    if (props.selectedEstimateData == undefined || props.selectedEstimateData == null) return;

    if (props.selectedEstimateData != undefined) {
      // 材料原価
      const price = props.selectedEstimateData?.esIq3Info?.reduce(
        (acc, item) => (acc + item.materialCostTotalPrice ? item.materialCostTotalPrice : 0),
        0
      );

      setallMaterialAmount(price);
      const parentCalData = props.selectedEstimateData?.estimateProducts?.additions;
      let processes = props.selectedEstimateData?.estimateProducts?.calcParameters?.parameters?.process;
      let parentProcesses = [];
      let iq3Processes = [];
      if (processes) {
        parentProcesses = processes?.filter((item) => item.class === ServiceClass.Parent);
        iq3Processes = processes?.filter((item) => item.class === ServiceClass.SheetMetal);
      }
      let parentResult = [];
      if (parentCalData?.length > 0) {
        parentCalData?.forEach((item, index) => {
          const process = parentProcesses?.find((i) => i.id === item.processId);

          parentResult.push({
            key: index + 1,
            processNm: process.name,
            setupAmount: item.dataType === 0 ? item.totalDandori.dataItemsAmt : item.totalDandori.editItemsAmt,
            setupTime: item.dataType === 0 ? item.totalDandori.dataItemsTime : item.totalDandori.editItemsTime,
            processingAmount: item.dataType === 0 ? item.totalSagyo.dataItemsAmt : item.totalSagyo.editItemsAmt,
            processingTime: item.dataType === 0 ? item.totalSagyo.dataItemsTime : item.totalSagyo.editItemsTime,
          });
        });
      }
      setParentCalAmount(parentResult);

      setSuryou(props.quantity);

      // Create a mapping of processId to summed values
      const processIdMap = {};

      props.selectedEstimateData?.esIq3Info?.forEach((item) => {
        item.additions?.forEach((addition) => {
          const { processId, dataType, totalDandori, totalSagyo } = addition;
          if (!processIdMap[processId]) {
            processIdMap[processId] = {
              processId,
              setupAmount: 0,
              setupTime: 0,
              processingAmount: 0,
              processingTime: 0,
            };
          }
          processIdMap[processId].setupAmount += dataType === 0 ? totalDandori.dataItemsAmt : totalDandori.editItemsAmt;
          processIdMap[processId].processingAmount +=
            dataType === 0 ? totalSagyo.dataItemsAmt : totalSagyo.editItemsAmt;
          processIdMap[processId].setupTime += dataType === 0 ? totalDandori.dataItemsTime : totalDandori.editItemsTime;
          processIdMap[processId].processingTime +=
            dataType === 0 ? totalSagyo.dataItemsTime : totalSagyo.editItemsTime;
        });
      });

      // convert the mapping into an array of objects
      const result = Object.values(processIdMap)?.map((entry, key) => {
        const process = iq3Processes?.find((i) => i.id === entry.processId);
        return {
          key: key + 1,
          processNm: process ? process.name : '',
          setupAmount: entry.setupAmount,
          setupTime: entry.setupTime,
          processingAmount: entry.processingAmount,
          processingTime: entry.processingTime,
        };
      });

      setIQ3Amount(result);

      calTotal(parentResult, result, suryou);
    }
  }, [props.selectedEstimateData, props.quantity]);

  const calTotal = (parentData, iq3Data, suryou) => {
    // parent
    setParentProcess(parentData);
    let setupAmount = 0;
    let processingAmount = 0;
    let setupTime = 0;
    let processingTime = 0;
    let totalTime = 0;
    let totalAmount = 0;
    if (parentData != null) {
      for (let i = 0; i < parentData?.length; i++) {
        // setupAmount += parseFloat(parentData[i].setupAmount);
        setupAmount += parentData[i].setupAmount;
        // processingAmount += parseFloat(parentData[i].processingAmount);
        processingAmount += parentData[i].processingAmount;
        // totalAmount += parseFloat(parentData[i].setupAmount) + parseFloat(parentData[i].processingAmount);
        totalAmount += parentData[i].setupAmount + parentData[i].processingAmount;
        // setupTime += setTimetoSec(parentData[i].setupTime);
        setupTime += parentData[i].setupTime;
        // processingTime += setTimetoSec(parentData[i].processingTime);
        processingTime += parentData[i].processingTime;
        // totalTime += setTimetoSec(parentData[i].setupTime) + setTimetoSec(parentData[i].processingTime);
        totalTime += parentData[i].setupTime + parentData[i].processingTime;
      }

      let tanka = totalAmount / suryou;
      setSetupTotalAmount(setupAmount);
      setProcessingTotalAmount(processingAmount);
      setTotalAmount(totalAmount);
      setTanka(tanka);
      setSetupTotalTime(secondsToHms(setupTime));
      setProcessingTotalTime(secondsToHms(processingTime));
      setTotalTime(secondsToHms(totalTime));
    }

    // iq3
    let setupAmount_IQ3 = 0;
    let processingAmount_IQ3 = 0;
    let setupTime_IQ3 = 0;
    let processingTime_IQ3 = 0;
    let totalTime_IQ3 = 0;
    let totalAmount_IQ3 = 0;
    if (iq3Data != null) {
      for (let i = 0; i < iq3Data?.length; i++) {
        setupAmount_IQ3 += parseFloat(iq3Data[i].setupAmount);
        processingAmount_IQ3 += parseFloat(iq3Data[i].processingAmount);
        totalAmount_IQ3 += parseFloat(iq3Data[i].setupAmount) + parseFloat(iq3Data[i].processingAmount);
        // setupTime_IQ3 += setTimetoSec(iq3Data[i].setupTime);
        setupTime_IQ3 += iq3Data[i].setupTime;
        // processingTime_IQ3 += setTimetoSec(iq3Data[i].processingTime);
        processingTime_IQ3 += iq3Data[i].processingTime;
        // totalTime_IQ3 += setTimetoSec(iq3Data[i].setupTime) + setTimetoSec(iq3Data[i].processingTime);
        totalTime_IQ3 += iq3Data[i].setupTime + iq3Data[i].processingTime;
      }
      let tanka_IQ3 = totalAmount_IQ3 / suryou;
      setSetupTotalAmount_IQ3(setupAmount_IQ3);
      setProcessingTotalAmount_IQ3(processingAmount_IQ3);
      setTotalAmount_IQ3(totalAmount_IQ3);
      setTanka_IQ3(tanka_IQ3);
      setSetupTotalTime_IQ3(secondsToHms(setupTime_IQ3));
      setProcessingTotalTime_IQ3(secondsToHms(processingTime_IQ3));
      setTotalTime_IQ3(secondsToHms(totalTime_IQ3));
      // setIq3MaterialAmount(targetParent.iq3MaterialAmount);
      setIq3Process(iq3Data);
    }

    // iq5
    // let setupAmount_IQ5 = 0;
    // let processingAmount_IQ5 = 0;
    // let setupTime_IQ5 = 0;
    // let processingTime_IQ5 = 0;
    // let totalTime_IQ5 = 0;
    // let totalAmount_IQ5 = 0;
    // if (targetParent.iq5Process != null) {
    //   for (let i = 0; i < targetParent.iq5Process?.length; i++) {
    //     setupAmount_IQ5 += parseFloat(targetParent.iq5Process[i].setupAmount);
    //     processingAmount_IQ5 += parseFloat(targetParent.iq5Process[i].processingAmount);
    //     totalAmount_IQ5 +=
    //       parseFloat(targetParent.iq5Process[i].setupAmount) + parseFloat(targetParent.iq5Process[i].processingAmount);
    //     setupTime_IQ5 += setTimetoSec(targetParent.iq5Process[i].setupTime);
    //     processingTime_IQ5 += setTimetoSec(targetParent.iq5Process[i].processingTime);
    //     totalTime_IQ5 +=
    //       setTimetoSec(targetParent.iq5Process[i].setupTime) + setTimetoSec(targetParent.iq5Process[i].processingTime);
    //   }
    //   setSetupTotalAmount_IQ5(setupAmount_IQ5);
    //   setProcessingTotalAmount_IQ5(processingAmount_IQ5);
    //   setTotalAmount_IQ5(totalAmount_IQ5);
    //   setTanka_IQ5(totalAmount_IQ5);
    //   setSetupTotalTime_IQ5(secondsToHms(setupTime_IQ5));
    //   setProcessingTotalTime_IQ5(secondsToHms(processingTime_IQ5));
    //   setTotalTime_IQ5(secondsToHms(totalTime_IQ5));
    //   setIq5MaterialAmount(targetParent.iq5MaterialAmount);
    //   setIq5Process(targetParent.iq5Process);
    // }
    // All total
    let totalAmount_all = setupAmount + setupAmount_IQ3 + processingAmount + processingAmount_IQ3;
    let tanka_all = totalAmount_all / suryou;
    setSetupAllTotalAmount(setupAmount + setupAmount_IQ3);
    setSetupAllTotalTime(secondsToHms(setupTime + setupTime_IQ3));
    setProcessingAllTotalAmount(processingAmount + processingAmount_IQ3);
    setProcessingAllTotalTime(secondsToHms(processingTime + processingTime_IQ3));
    setAllTotalTime(secondsToHms(setupTime + setupTime_IQ3 + processingTime + processingTime_IQ3));
    setAllTotalAmount(totalAmount_all);
    setTanka_all(tanka_all);
    // setallMaterialAmount(targetParent.iq3MaterialAmount);
  };

  const calUnit = (parentData, iq3Data, suryou) => {
    // parent
    let setupAmount = 0;
    let processingAmount = 0;
    let setupTime = 0;
    let processingTime = 0;
    let totalTime = 0;
    let totalAmount = 0;
    if (parentData != null) {
      for (let i = 0; i < parentData?.length; i++) {
        parentData[i].setupAmount = parseFloat(parentData[i].setupAmount) / suryou;
        setupAmount += parentData[i].setupAmount;

        parentData[i].processingAmount = parseFloat(parentData[i].processingAmount) / suryou;
        processingAmount += parentData[i].processingAmount;

        totalAmount += parentData[i].setupAmount + parentData[i].processingAmount;

        let setupPerSec = parentData[i].setupTime / suryou;
        // let setupPerSec = setTimetoSec(parentData[i].setupTime) / suryou;
        parentData[i].setupTime = secondsToHms(setupPerSec);
        setupTime += setupPerSec;

        let processingPerSec = parentData[i].processingTime / suryou;
        // let processingPerSec = setTimetoSec(parentData[i].processingTime) / suryou;
        parentData[i].processingTime = secondsToHms(processingPerSec);
        processingTime += processingPerSec;

        totalTime += setTimetoSec(parentData[i].setupTime) + setTimetoSec(parentData[i].processingTime);
      }

      setSetupTotalAmount(setupAmount);
      setProcessingTotalAmount(processingAmount);
      setTotalAmount(totalAmount);
      setSetupTotalTime(secondsToHms(setupTime));
      setProcessingTotalTime(secondsToHms(processingTime));
      setTotalTime(secondsToHms(totalTime));
      setParentProcess(parentData);
    }

    // iq3
    let setupAmount_IQ3 = 0;
    let processingAmount_IQ3 = 0;
    let setupTime_IQ3 = 0;
    let processingTime_IQ3 = 0;
    let totalTime_IQ3 = 0;
    let totalAmount_IQ3 = 0;
    if (iq3Data != null) {
      for (let i = 0; i < iq3Data?.length; i++) {
        iq3Data[i].setupAmount = parseFloat(iq3Data[i].setupAmount) / suryou;
        setupAmount_IQ3 += iq3Data[i].setupAmount;

        iq3Data[i].processingAmount = parseFloat(iq3Data[i].processingAmount) / suryou;
        processingAmount_IQ3 += iq3Data[i].processingAmount;

        totalAmount_IQ3 += iq3Data[i].setupAmount + iq3Data[i].processingAmount;

        // let setupIQ3PerSec = setTimetoSec(iq3Data[i].setupTime) / suryou;
        let setupIQ3PerSec = iq3Data[i].setupTime / suryou;
        iq3Data[i].setupTime = secondsToHms(setupIQ3PerSec);
        setupTime_IQ3 += setupIQ3PerSec;

        // let processingIQ3PerSec = setTimetoSec(iq3Data[i].processingTime) / suryou;
        let processingIQ3PerSec = iq3Data[i].processingTime / suryou;
        iq3Data[i].processingTime = secondsToHms(processingIQ3PerSec);
        processingTime_IQ3 += processingIQ3PerSec;

        totalTime_IQ3 += setTimetoSec(iq3Data[i].setupTime) + setTimetoSec(iq3Data[i].processingTime);
      }

      setSetupTotalAmount_IQ3(setupAmount_IQ3);
      setProcessingTotalAmount_IQ3(processingAmount_IQ3);
      setTotalAmount_IQ3(totalAmount_IQ3);
      setSetupTotalTime_IQ3(secondsToHms(setupTime_IQ3));
      setProcessingTotalTime_IQ3(secondsToHms(processingTime_IQ3));
      setTotalTime_IQ3(secondsToHms(totalTime_IQ3));
      setIq3Process(iq3Data);
    }

    // iq5
    // let setupAmount_IQ5 = 0;
    // let processingAmount_IQ5 = 0;
    // let setupTime_IQ5 = 0;
    // let processingTime_IQ5 = 0;
    // let totalTime_IQ5 = 0;
    // let totalAmount_IQ5 = 0;
    // if (targetParent.iq5Process != null) {
    //   for (let i = 0; i < targetParent.iq5Process?.length; i++) {
    //     setupAmount_IQ5 += parseFloat(targetParent.iq5Process[i].setupAmount);
    //     processingAmount_IQ5 += parseFloat(targetParent.iq5Process[i].processingAmount);
    //     totalAmount_IQ5 +=
    //       parseFloat(targetParent.iq5Process[i].setupAmount) + parseFloat(targetParent.iq5Process[i].processingAmount);
    //     setupTime_IQ5 += setTimetoSec(targetParent.iq5Process[i].setupTime);
    //     processingTime_IQ5 += setTimetoSec(targetParent.iq5Process[i].processingTime);
    //     totalTime_IQ5 +=
    //       setTimetoSec(targetParent.iq5Process[i].setupTime) + setTimetoSec(targetParent.iq5Process[i].processingTime);
    //   }
    //   setSetupTotalAmount_IQ5(setupAmount_IQ5);
    //   setProcessingTotalAmount_IQ5(processingAmount_IQ5);
    //   setTotalAmount_IQ5(totalAmount_IQ5);
    //   setTanka_IQ5(totalAmount_IQ5);
    //   setSetupTotalTime_IQ5(secondsToHms(setupTime_IQ5));
    //   setProcessingTotalTime_IQ5(secondsToHms(processingTime_IQ5));
    //   setTotalTime_IQ5(secondsToHms(totalTime_IQ5));
    //   setIq5MaterialAmount(targetParent.iq5MaterialAmount);
    //   setIq5Process(targetParent.iq5Process);
    // }
    // All total
    let totalAmount_all = setupAmount + setupAmount_IQ3 + processingAmount + processingAmount_IQ3;
    setSetupAllTotalAmount(setupAmount + setupAmount_IQ3);
    setSetupAllTotalTime(secondsToHms(setupTime + setupTime_IQ3));
    setProcessingAllTotalAmount(processingAmount + processingAmount_IQ3);
    setProcessingAllTotalTime(secondsToHms(processingTime + processingTime_IQ3));
    setAllTotalTime(secondsToHms(setupTime + setupTime_IQ3 + processingTime + processingTime_IQ3));
    setAllTotalAmount(totalAmount_all);
    //setShowProcessTotalAmount(true);
  };

  const partMenuChange = (e) => {
    setActivePartMenu(e.target.value);
    if (e.target.value === 'unit') {
      calUnit(parentCalAmount, iq3CalAmount, suryou);
    } else {
      calTotal(parentCalAmount, iq3CalAmount, suryou);
    }
  };

  // 開発中ポップアップ呼出
  const commonTaiochuPopupOpen = () => {
    setOpenCommonTaiochuPopup(true);
  };

  // 開発中ポップアップ
  const closeCommonTaiochuPopup = () => {
    setOpenCommonTaiochuPopup(false);
  };

  const showDetail = (e, type) => {
    if (type == 'parent') {
      setShowDetailRow(false);
    } else if (type == 'iq3') {
      setShowDetailRowIQ3(false);
    } else if (type == 'iq5') {
      setShowDetailRowIQ5(false);
    } else if (type == 'iq7') {
      setShowDetailRowIQ7(false);
    }
  };

  const showHide = (e, type) => {
    if (type === 'parent') {
      setShowDetailRow(true);
    } else if (type === 'iq3') {
      setShowDetailRowIQ3(true);
    } else if (type === 'iq5') {
      setShowDetailRowIQ5(true);
    } else if (type === 'iq7') {
      setShowDetailRowIQ7(true);
    }
  };
  const confirmCancel = () => {
    props.closeProcessTotalAmountDialog();
  };

  // CSV出力データ準備
  const kouteibetsuTbl1 = [
    ['No', '工程', '段取時間', '段取原価', '加工時間', '段取原価', '合計時間', '製造原価', '単価'],
    [
      '',
      '合計',
      setupAllTotalTime,
      JPYs.format(Math.floor(setupAllTotalAmount)),
      processingAllTotalTime,
      JPYs.format(Math.floor(processingAllTotalAmount)),
      allTotalTime,
      JPYs.format(Math.floor(allTotalAmount)),
      JPYs.format(Math.floor(tanka_all)),
    ],
  ];

  const table2Headers = ['No', '工程', '段取時間', '段取原価', '加工時間', '段取原価', '合計時間', '製造原価', '単価'];
  if (activePartMenu === 'unit') {
    kouteibetsuTbl1?.forEach((row) => row.pop()); // remove the last element
  }
  const parentTotal = [
    '合計',
    secondsToHms(setupTotalTime),
    JPYs.format(Math.floor(setupTotalAmount)),
    secondsToHms(processingTotalTime),
    JPYs.format(Math.floor(processingTotalAmount)),
    secondsToHms(totalTime),
    JPYs.format(Math.floor(totalAmount)),
    JPYs.format(Math.floor(tanka)),
  ];
  const parentTotalNoUnit = parentTotal.slice(0, parentTotal?.length - 1);
  const iq3Total = [
    '合計',
    setupTotalTime_IQ3,
    JPYs.format(Math.floor(setupTotalAmount_IQ3)),
    processingTotalTime_IQ3,
    JPYs.format(Math.floor(processingTotalAmount_IQ3)),
    totalTime_IQ3,
    JPYs.format(Math.floor(totalAmount_IQ3)),
    JPYs.format(Math.floor(tanka_IQ3)),
  ];
  const iq3TotalNoUnit = iq3Total.slice(0, iq3Total?.length - 1);
  const allData = [
    ...kouteibetsuTbl1,
    [
      '材料原価',
      JPYs.format(Math.floor(allMaterialAmount)),
      activePartMenu === 'total' ? JPYs.format(Math.floor(allMaterialAmount / suryou)) : '',
    ],
    ['親部品工程'],
    activePartMenu === 'unit' ? table2Headers?.filter((header) => header !== '単価') : table2Headers,

    ...parentProcess?.map((item) => {
      const rowData = [
        item.key, // No
        item.processNm, // 工程
        secondsToHms(item.setupTime), // 段取時間
        JPYs.format(Math.floor(item.setupAmount)), // 段取原価
        secondsToHms(item.processingTime), // 加工時間
        item.processingAmount, // 段取原価

        secondsToHms(item.setupTime + item.processingTime),
        JPYs.format(Math.floor(item.setupAmount + item.processingAmount)),
      ];

      if (activePartMenu === 'total') {
        rowData.push(JPYs.format(Math.floor((item.setupAmount + item.processingAmount) / suryou)));
      }

      return rowData;
    }),
    activePartMenu === 'unit' ? parentTotalNoUnit : parentTotal,
    ['板金子部品工程'],
    activePartMenu === 'unit' ? table2Headers?.filter((header) => header !== '単価') : table2Headers,
    ...iq3Process?.map((item) => {
      const temp = [
        item.key, // No
        item.processNm, // 工程
        secondsToHms(item.setupTime), // 段取時間
        JPYs.format(Math.floor(item.setupAmount)), // 段取原価
        secondsToHms(item.processingTime), // 加工時間
        JPYs.format(Math.floor(item.processingAmount)), // 段取原価

        secondsToHms(item.setupTime + item.processingTime),
        JPYs.format(Math.floor(item.setupAmount + item.processingAmount)),
      ];

      if (activePartMenu === 'total') {
        temp.push(JPYs.format(Math.floor((item.setupAmount + item.processingAmount) / suryou)));
      }
      return temp;
    }),
    activePartMenu === 'unit' ? iq3TotalNoUnit : iq3Total,
    [
      '材料原価',
      JPYs.format(Math.floor(iq3MaterialAmount)),
      activePartMenu === 'total' ? JPYs.format(Math.floor(iq3MaterialAmount / suryou)) : '',
    ],
  ];

  const exportCSVData = () => {
    setFileName(`工程別合計_${formatDateString(todayTime())}?.csv`);

    csvExportRef.current.link.click();
  };
  /* 工程別合計コンポーネント開始 */
  const tblTile = (
    <>
      <Row className="estimate-tab2-title-row" style={{ marginTop: 25 }}>
        <Col span={1} className="estimate-tab2-title-col">
          No.
        </Col>
        <Col span={4} className="estimate-tab2-title-col">
          工程
        </Col>
        <Col span={2} className="estimate-tab2-title-col">
          段取時間
        </Col>
        <Col span={3} className="estimate-tab2-title-col">
          段取原価
        </Col>
        <Col span={2} className="estimate-tab2-title-col">
          加工時間
        </Col>
        <Col span={3} className="estimate-tab2-title-col">
          段取原価
        </Col>
        <Col span={2} className="estimate-tab2-title-col" style={{ marginLeft: 5 }}>
          合計時間
        </Col>
        <Col span={3} className="estimate-tab2-title-col">
          製造原価
        </Col>
        <Col span={3} className="estimate-tab2-title-col" style={{ display: activePartMenu === 'unit' ? 'none' : '' }}>
          単価
        </Col>
      </Row>
    </>
  );
  const totalRow = (
    <>
      {tblTile}
      <Row className="estimate-tab2-row">
        <Col span={1} className="estimate-tab2-col-total"></Col>
        <Col span={4} className="estimate-tab2-col-total" style={{ textAlign: 'center', fontWeight: 'bold' }}>
          合計
        </Col>
        <Col span={2} className="estimate-tab2-col-total">
          {setupAllTotalTime}
        </Col>
        <Col span={3} className="estimate-tab2-col-total">
          {setupAllTotalAmount != undefined ? JPYs.format(Math.floor(setupAllTotalAmount)) : '¥0'}
        </Col>
        <Col span={2} className="estimate-tab2-col-total">
          {processingAllTotalTime}
        </Col>
        <Col span={3} className="estimate-tab2-col-total">
          {processingAllTotalAmount != undefined ? JPYs.format(Math.floor(processingAllTotalAmount)) : '¥0'}
        </Col>
        <Col span={2} className="estimate-tab2-col-total estimate-tab2-col-total-main" style={{ marginLeft: 5 }}>
          {allTotalTime}
        </Col>
        <Col span={3} className="estimate-tab2-col-total estimate-tab2-col-total-main">
          {allTotalAmount != undefined ? JPYs.format(Math.floor(allTotalAmount)) : '¥'}
        </Col>
        <Col
          span={3}
          className="estimate-tab2-col-total estimate-tab2-col-total-main"
          style={{ display: activePartMenu === 'unit' ? 'none' : '' }}
        >
          {tanka_all != undefined ? JPYs.format(Math.floor(tanka_all)) : '¥0'}
        </Col>
      </Row>
      <Row className="estimate-tab2-row" style={{ marginTop: '0px' }}>
        <Col span={15}></Col>
        <Col
          span={2}
          className="estimate-tab2-col-total estimate-tab2-col-total-main"
          style={{ textAlign: 'right', paddingRight: '25px', fontWeight: 'bold', marginLeft: 5 }}
        >
          材料原価
        </Col>
        <Col span={3} className="estimate-tab2-col-total estimate-tab2-col-total-main">
          {allMaterialAmount != undefined ? JPYs.format(Math.floor(allMaterialAmount)) : '¥0'}
        </Col>
        <Col
          span={3}
          className="estimate-tab2-col-total estimate-tab2-col-total-main"
          style={{ display: activePartMenu === 'unit' ? 'none' : '' }}
        >
          {allMaterialAmount != undefined ? JPYs.format(Math.floor(allMaterialAmount) / suryou) : '¥0'}
        </Col>
      </Row>
    </>
  );

  let parentItemComponent = [];
  parentProcess != null
    ? parentProcess?.forEach((item, index) => {
        parentItemComponent.push(
          <Row className="estimate-tab2-row">
            <Col span={1} className="estimate-tab2-col">
              {item.key}
            </Col>
            <Col span={4} className="estimate-tab2-col" style={{ textAlign: 'center' }}>
              {item.processNm}
            </Col>
            <Col span={2} className="estimate-tab2-col">
              {secondsToHms(item.setupTime)}
            </Col>
            <Col span={3} className="estimate-tab2-col">
              {item.setupAmount != undefined ? JPYs.format(Math.floor(item.setupAmount)) : '¥0'}
            </Col>
            <Col span={2} className="estimate-tab2-col">
              {secondsToHms(item.processingTime)}
            </Col>
            <Col span={3} className="estimate-tab2-col">
              {item.processingAmount != undefined ? JPYs.format(Math.floor(item.processingAmount)) : '¥0'}
            </Col>
            <Col span={2} className="estimate-tab2-col estimate-tab2-col-total" style={{ marginLeft: 5 }}>
              {/* {secondsToHms(setTimetoSec(item.setupTime) + setTimetoSec(item.processingTime))} */}
              {secondsToHms(item.setupTime + item.processingTime)}
            </Col>
            <Col span={3} className="estimate-tab2-col estimate-tab2-col-total">
              {item.setupAmount != undefined && item.processingAmount
                ? JPYs.format(Math.floor(item.setupAmount + item.processingAmount))
                : '¥0'}
            </Col>
            <Col
              span={3}
              className="estimate-tab2-col estimate-tab2-col-total"
              style={{ display: activePartMenu === 'unit' ? 'none' : '' }}
            >
              {item.setupAmount != undefined && item.processingAmount
                ? JPYs.format(Math.floor((item.setupAmount + item.processingAmount) / suryou))
                : '¥0'}
            </Col>
          </Row>
        );
      })
    : '';
  const parentTBL = (
    <>
      <div>
        {tblTile}
        {showDetailRow ? parentItemComponent : ''}
        <Row className="estimate-tab2-row" style={{ marginTop: 5 }}>
          <Col span={1} className="estimate-tab2-col-total"></Col>
          <Col span={4} className="estimate-tab2-col-total" style={{ textAlign: 'center', fontWeight: 'bold' }}>
            合計
          </Col>
          <Col span={2} className="estimate-tab2-col-total">
            {secondsToHms(setupTotalTime)}
          </Col>
          <Col span={3} className="estimate-tab2-col-total">
            {setupTotalAmount != undefined ? JPYs.format(Math.floor(setupTotalAmount)) : 0}
          </Col>
          <Col span={2} className="estimate-tab2-col-total">
            {secondsToHms(processingTotalTime)}
          </Col>
          <Col span={3} className="estimate-tab2-col-total">
            {processingTotalAmount != undefined ? JPYs.format(Math.floor(processingTotalAmount)) : 0}
          </Col>
          <Col span={2} className="estimate-tab2-col-total estimate-tab2-col-total-main" style={{ marginLeft: 5 }}>
            {secondsToHms(totalTime)}
          </Col>
          <Col span={3} className="estimate-tab2-col-total estimate-tab2-col-total-main">
            {totalAmount != undefined ? JPYs.format(Math.floor(totalAmount)) : 0}
          </Col>
          <Col
            span={3}
            className="estimate-tab2-col-total estimate-tab2-col-total-main"
            style={{ display: activePartMenu === 'unit' ? 'none' : '' }}
          >
            {tanka != undefined ? JPYs.format(Math.floor(tanka)) : 0}
          </Col>
        </Row>
      </div>
    </>
  );
  /* 工程別合計コンポーネント　iq3工程 */
  let iq3ItemComponent = [];
  iq3Process != null
    ? iq3Process?.forEach((item, index) => {
        iq3ItemComponent.push(
          <Row className="estimate-tab2-row">
            <Col span={1} className="estimate-tab2-col">
              {item.key}
            </Col>
            <Col span={4} className="estimate-tab2-col" style={{ textAlign: 'center' }}>
              {item.processNm}
            </Col>
            <Col span={2} className="estimate-tab2-col">
              {secondsToHms(item.setupTime)}
            </Col>
            <Col span={3} className="estimate-tab2-col">
              {item.setupAmount != undefined ? JPYs.format(Math.floor(item.setupAmount)) : '¥0'}
            </Col>
            <Col span={2} className="estimate-tab2-col">
              {secondsToHms(item.processingTime)}
            </Col>
            <Col span={3} className="estimate-tab2-col">
              {item.processingAmount != undefined ? JPYs.format(Math.floor(item.processingAmount)) : '¥0'}
            </Col>
            <Col span={2} className="estimate-tab2-col estimate-tab2-col-total" style={{ marginLeft: 5 }}>
              {/* {secondsToHms(setTimetoSec(item.setupTime) + setTimetoSec(item.processingTime))} */}
              {secondsToHms(item.setupTime + item.processingTime)}
            </Col>
            <Col span={3} className="estimate-tab2-col estimate-tab2-col-total">
              {item.setupAmount != undefined && item.processingAmount
                ? JPYs.format(Math.floor(item.setupAmount + item.processingAmount))
                : '¥0'}
            </Col>
            <Col
              span={3}
              className="estimate-tab2-col estimate-tab2-col-total"
              style={{ display: activePartMenu === 'unit' ? 'none' : '' }}
            >
              {item.setupAmount != undefined
                ? JPYs.format(Math.floor(item.setupAmount + item.processingAmount) / suryou)
                : '¥0'}
            </Col>
          </Row>
        );
      })
    : '';
  const iq3TBL = (
    <>
      <div>
        {tblTile}
        {showDetailRowIQ3 ? iq3ItemComponent : ''}
        <Row className="estimate-tab2-row" style={{ marginTop: 5 }}>
          <Col span={1} className="estimate-tab2-col-total"></Col>
          <Col span={4} className="estimate-tab2-col-total" style={{ textAlign: 'center', fontWeight: 'bold' }}>
            合計
          </Col>
          <Col span={2} className="estimate-tab2-col-total">
            {setupTotalTime_IQ3}
          </Col>
          <Col span={3} className="estimate-tab2-col-total">
            {setupTotalAmount_IQ3 != undefined ? JPYs.format(Math.floor(setupTotalAmount_IQ3)) : '¥0'}
          </Col>
          <Col span={2} className="estimate-tab2-col-total">
            {processingTotalTime_IQ3}
          </Col>
          <Col span={3} className="estimate-tab2-col-total">
            {processingTotalAmount_IQ3 != undefined ? JPYs.format(Math.floor(processingTotalAmount_IQ3)) : '¥0'}
          </Col>
          <Col span={2} className="estimate-tab2-col-total estimate-tab2-col-total-main" style={{ marginLeft: 5 }}>
            {totalTime_IQ3}
          </Col>
          <Col span={3} className="estimate-tab2-col-total estimate-tab2-col-total-main">
            {totalAmount_IQ3 != undefined ? JPYs.format(Math.floor(totalAmount_IQ3)) : '¥0'}
          </Col>
          <Col
            span={3}
            className="estimate-tab2-col-total estimate-tab2-col-total-main"
            style={{ display: activePartMenu === 'unit' ? 'none' : '' }}
          >
            {tanka_IQ3 != undefined ? JPYs.format(Math.floor(tanka_IQ3)) : '¥0'}
          </Col>
        </Row>
        <Row className="estimate-tab2-row" style={{ marginTop: '0px' }}>
          <Col span={15}></Col>
          <Col
            span={2}
            className="estimate-tab2-col-total estimate-tab2-col-total-main"
            style={{ textAlign: 'right', paddingRight: '25px', fontWeight: 'bold', marginLeft: 5 }}
          >
            材料原価
          </Col>
          <Col span={3} className="estimate-tab2-col-total estimate-tab2-col-total-main">
            {iq3MaterialAmount != undefined ? JPYs.format(Math.floor(iq3MaterialAmount)) : '¥0'}
          </Col>
          <Col
            span={3}
            className="estimate-tab2-col-total estimate-tab2-col-total-main"
            style={{ display: activePartMenu === 'unit' ? 'none' : '' }}
          >
            {iq3MaterialAmount != undefined ? JPYs.format(Math.floor(iq3MaterialAmount)) : '¥0'}
          </Col>
        </Row>
      </div>
    </>
  );
  /* 工程別合計コンポーネント　iq5工程 */
  // let iq5Items = [
  //   {
  //     id: 1,
  //     process: `切断`,
  //     setupTime: `00:00:01`,
  //     setupAmount: `¥1001`,
  //     processTime: `00:00:01`,
  //     processAmount: `¥1001`,
  //     totalTime: `00:00:01`,
  //     totalAmount: `¥1001`,
  //     unitAmount: `¥1001`,
  //   },
  //   {
  //     id: 2,
  //     process: `穴`,
  //     setupTime: `00:00:01`,
  //     setupAmount: `¥1001`,
  //     processTime: `00:00:01`,
  //     processAmount: `¥1001`,
  //     totalTime: `00:00:01`,
  //     totalAmount: `¥1001`,
  //     unitAmount: `¥1001`,
  //   },
  // ];
  // let iq5ItemComponent = [];
  // iq5Process != null
  //   ? iq5Process?.forEach((item, index) => {
  //       iq5ItemComponent.push(
  //         <Row className="estimate-tab2-row">
  //           <Col span={1} className="estimate-tab2-col">
  //             {item.key}
  //           </Col>
  //           <Col span={5} className="estimate-tab2-col" style={{ textAlign: 'center' }}>
  //             {item.processNm}
  //           </Col>
  //           <Col span={2} className="estimate-tab2-col">
  //             {item.setupTime}
  //           </Col>
  //           <Col span={3} className="estimate-tab2-col">
  //             {item.setupAmount != undefined
  //               ? '¥' +
  //                 item.setupAmount.toLocaleString(navigator.language, {
  //                   minimumFractionDigits: 0,
  //                 })
  //               : 0}
  //           </Col>
  //           <Col span={2} className="estimate-tab2-col">
  //             {item.processingTime}
  //           </Col>
  //           <Col span={3} className="estimate-tab2-col">
  //             {item.processingAmount != undefined
  //               ? '¥' +
  //                 item.processingAmount.toLocaleString(navigator.language, {
  //                   minimumFractionDigits: 0,
  //                 })
  //               : '¥0'}
  //           </Col>
  //           <Col span={2} className="estimate-tab2-col estimate-tab2-col-total">
  //             {secondsToHms(setTimetoSec(item.setupTime) + setTimetoSec(item.processingTime))}
  //           </Col>
  //           <Col span={3} className="estimate-tab2-col estimate-tab2-col-total">
  //             {item.setupAmount != undefined
  //               ? '¥' +
  //                 (item.setupAmount + item.processingAmount).toLocaleString(navigator.language, {
  //                   minimumFractionDigits: 0,
  //                 })
  //               : 0}
  //           </Col>
  //           <Col
  //             span={3}
  //             className="estimate-tab2-col estimate-tab2-col-total"
  //             style={{ display: activePartMenu === 'unit' ? 'none' : '' }}
  //           >
  //             {item.setupAmount != undefined
  //               ? '¥' +
  //                 (item.setupAmount + item.processingAmount).toLocaleString(navigator.language, {
  //                   minimumFractionDigits: 0,
  //                 })
  //               : 0}
  //           </Col>
  //         </Row>
  //       );
  //     })
  //   : '';
  // const iq5TBL = (
  //   <>
  //     <div>
  //       {tblTile}
  //       {showDetailRowIQ5 ? iq5ItemComponent : ''}
  //       <Row className="estimate-tab2-row">
  //         <Col span={1} className="estimate-tab2-col-total"></Col>
  //         <Col span={5} className="estimate-tab2-col-total" style={{ textAlign: 'center' }}>
  //           合計
  //         </Col>
  //         <Col span={2} className="estimate-tab2-col-total">
  //           {setupTotalTime_IQ5}
  //         </Col>
  //         <Col span={3} className="estimate-tab2-col-total">
  //           {setupTotalAmount_IQ5 != undefined
  //             ? '¥' +
  //               setupTotalAmount_IQ5.toLocaleString(navigator.language, {
  //                 minimumFractionDigits: 0,
  //               })
  //             : 0}
  //         </Col>
  //         <Col span={2} className="estimate-tab2-col-total">
  //           {processingTotalTime_IQ5}
  //         </Col>
  //         <Col span={3} className="estimate-tab2-col-total">
  //           {processingTotalAmount_IQ5 != undefined
  //             ? '¥' +
  //               processingTotalAmount_IQ5.toLocaleString(navigator.language, {
  //                 minimumFractionDigits: 0,
  //               })
  //             : 0}
  //         </Col>
  //         <Col span={2} className="estimate-tab2-col-total estimate-tab2-col-total-main">
  //           {totalTime_IQ5}
  //         </Col>
  //         <Col span={3} className="estimate-tab2-col-total estimate-tab2-col-total-main">
  //           {totalAmount_IQ5 != undefined
  //             ? '¥' +
  //               totalAmount_IQ5.toLocaleString(navigator.language, {
  //                 minimumFractionDigits: 0,
  //               })
  //             : 0}
  //         </Col>
  //         <Col
  //           span={3}
  //           className="estimate-tab2-col-total estimate-tab2-col-total-main"
  //           style={{ display: activePartMenu === 'unit' ? 'none' : '' }}
  //         >
  //           {tanka_IQ5 != undefined
  //             ? '¥' +
  //               tanka_IQ5.toLocaleString(navigator.language, {
  //                 minimumFractionDigits: 0,
  //               })
  //             : 0}
  //         </Col>
  //       </Row>
  //       <Row className="estimate-tab2-row" style={{ marginTop: '1px' }}>
  //         <Col span={16}></Col>
  //         <Col
  //           span={2}
  //           className="estimate-tab2-col-total estimate-tab2-col-total-main"
  //           style={{ textAlign: 'right', paddingRight: '25px', fontWeight: 'bold', marginLeft: 5 }}
  //         >
  //           材料費
  //         </Col>
  //         <Col span={3} className="estimate-tab2-col-total estimate-tab2-col-total-main">
  //           {iq5MaterialAmount != undefined
  //             ? '¥' +
  //               iq5MaterialAmount.toLocaleString(navigator.language, {
  //                 minimumFractionDigits: 0,
  //               })
  //             : 0}
  //         </Col>
  //         <Col
  //           span={3}
  //           className="estimate-tab2-col-total estimate-tab2-col-total-main"
  //           style={{ display: activePartMenu === 'unit' ? 'none' : '' }}
  //         >
  //           {iq5MaterialAmount != undefined
  //             ? '¥' +
  //               iq5MaterialAmount.toLocaleString(navigator.language, {
  //                 minimumFractionDigits: 0,
  //               })
  //             : 0}
  //         </Col>
  //       </Row>
  //     </div>
  //   </>
  // );
  /* 工程別合計コンポーネント　iq7工程 */
  // let iq7Items = [
  //   {
  //     id: 1,
  //     process: `切断`,
  //     setupTime: `00:00:01`,
  //     setupAmount: `¥1001`,
  //     processTime: `00:00:01`,
  //     processAmount: `¥1001`,
  //     totalTime: `00:00:01`,
  //     totalAmount: `¥1001`,
  //   },
  //   {
  //     id: 2,
  //     process: `穴`,
  //     setupTime: `00:00:01`,
  //     setupAmount: `¥1001`,
  //     processTime: `00:00:01`,
  //     processAmount: `¥1001`,
  //     totalTime: `00:00:01`,
  //     totalAmount: `¥1001`,
  //   },
  // ];
  // let iq7ItemComponent = [];
  // iq7Items?.forEach((item, index) => {
  //   iq7ItemComponent.push(
  //     <Row className="estimate-tab2-row">
  //       <Col className="estimate-tab2-col" style={{ width: 50 }}>
  //         {item.id}
  //       </Col>
  //       <Col className="estimate-tab2-col" style={{ textAlign: 'center' }}>
  //         {item.process}
  //       </Col>
  //       <Col className="estimate-tab2-col">{item.setupTime}</Col>
  //       <Col className="estimate-tab2-col">{item.setupAmount}</Col>
  //       <Col className="estimate-tab2-col">{item.setupTime}</Col>
  //       <Col className="estimate-tab2-col">{item.processTime}</Col>
  //       <Col className="estimate-tab2-col">{item.processAmount}</Col>
  //       <Col className="estimate-tab2-col">{item.totalTime}</Col>
  //       <Col className="estimate-tab2-col">{item.totalAmount}</Col>
  //     </Row>
  //   );
  // });
  // const iq7TBL = (
  //   <>
  //     <div>
  //       {tblTile}
  //       {showDetailRowIQ7 ? iq7ItemComponent : ''}
  //       <Row className="estimate-tab2-row">
  //         <Col className="estimate-tab2-col-total" style={{ width: 50 }}></Col>
  //         <Col className="estimate-tab2-col-total" style={{ textAlign: 'center' }}>
  //           合計
  //         </Col>
  //         <Col className="estimate-tab2-col-total">{'02:00:01'}</Col>
  //         <Col className="estimate-tab2-col-total">{'¥7200'}</Col>
  //         <Col className="estimate-tab2-col-total">{'02:00:01'}</Col>
  //         <Col className="estimate-tab2-col-total">{'¥7200'}</Col>
  //         <Col className="estimate-tab2-col-total">{'02:00:01'}</Col>
  //         <Col className="estimate-tab2-col-total">{'¥7200'}</Col>
  //         <Col className="estimate-tab2-col-total">{'¥7200'}</Col>
  //       </Row>
  //     </div>
  //   </>
  // );
  /* 工程別合計コンポーネント */

  const processTotalAmountComponent = (
    <>
      <div style={{ overflowY: 'auto', marginTop: 5, height: '800px' }}>
        <Row id="totalRow" style={{ marginBottom: '5px' }}>
          <div style={{ float: 'left', width: '60%' }}>
            <Radio.Group value={activePartMenu} onChange={partMenuChange}>
              <Radio.Button value="total" style={{ width: 125, textAlign: 'center' }}>
                <p style={{ fontSize: '13px', fontWeight: 500 }}>合計</p>
              </Radio.Button>
              <Radio.Button value="unit" style={{ width: 125, textAlign: 'center' }}>
                <p style={{ fontSize: '13px', fontWeight: 500 }}>単品</p>
              </Radio.Button>
            </Radio.Group>
          </div>

          <div style={{ float: 'right', width: '40%' }}>
            <Button key="submit24" type="primary" className="csvoutput" onClick={exportCSVData}>
              CSV出力
              <CSVLink ref={csvExportRef} data={allData} filename={fileName} style={{ display: 'none' }}></CSVLink>
            </Button>
          </div>
        </Row>
        {/* <Row id="iq5TitleRow" style={{ marginTop: 15 }}>
          <Input className="estimate-tab2-tbl-thead" value="合計" />
        </Row> */}
        {totalRow}

        <Row id="parentTitleRow" style={{ marginTop: '13px' }}>
          <label className="estimate-tab2-tbl-thead" value="親部品工程">
            親部品工程
            {showDetailRow ? (
              <a onClick={(e) => showDetail(e, 'parent')}>
                <CaretUpOutlined className="koutebetsuDDLArrow" />
                {/* <Image preview={false} width={25} height={25} src={minus_icon} style={{ marginLeft: '5px' }}  />*/}
              </a>
            ) : (
              <a onClick={(e) => showHide(e, 'parent')}>
                {/* <Image preview={false} width={25} height={25} src={child_add} style={{ marginLeft: '5px' }} /> */}
                <CaretDownOutlined className="koutebetsuDDLArrow" />
              </a>
            )}
          </label>
        </Row>
        {parentTBL}

        <Row id="iq3TitleRow" style={{ marginTop: 15 }}>
          <label className="estimate-tab2-tbl-thead" value="板金子部品工程">
            板金子部品工程
            {showDetailRowIQ3 ? (
              <a onClick={(e) => showDetail(e, 'iq3')}>
                <CaretUpOutlined className="koutebetsuDDLArrow" />
                {/* <Image preview={false} width={25} height={25} src={minus_icon} style={{ marginLeft: '5px' }} /> */}
              </a>
            ) : (
              <a onClick={(e) => showHide(e, 'iq3')}>
                <CaretDownOutlined className="koutebetsuDDLArrow" />
                {/* <Image preview={false} width={25} height={25} src={child_add} style={{ marginLeft: '5px' }} /> */}
              </a>
            )}
          </label>
        </Row>
        {iq3TBL}
        {/* <Row id="iq5TitleRow" style={{ marginTop: 15 }}>
          <Input className="estimate-tab2-tbl-thead" value="形鋼子部品工程" />
          {showDetailRowIQ5 ? (
            <a onClick={(e) => showDetail(e, 'iq5')}>
              <Image preview={false} width={25} height={25} src={minus_icon} style={{ marginLeft: '5px' }} />
            </a>
          ) : (
            <a onClick={(e) => showHide(e, 'iq5')}>
              <Image preview={false} width={25} height={25} src={child_add} style={{ marginLeft: '5px' }} />
            </a>
          )}
        </Row>
        {iq5TBL} */}
        {/* <Row id="iq7TitleRow" style={{ marginTop: 15 }}>
          <Input className="estimate-tab2-tbl-thead" value="機械子部品工程" />
          {showDetailRowIQ7 ? (
            <a onClick={(e) => showDetail(e, 'iq7')}>
              <Image preview={false} width={25} height={25} src={minus_icon} style={{ marginLeft: '5px' }} />
            </a>
          ) : (
            <a onClick={(e) => showHide(e, 'iq7')}>
              <Image preview={false} width={25} height={25} src={child_add} style={{ marginLeft: '5px' }} />
            </a>
          )}
        </Row>
        <Row id="iq7Row" style={{ marginTop: 5 }}>
          {iq7TBL}
        </Row> */}
      </div>
    </>
  );
  /* 工程別合計コンポーネント 終了*/

  return (
    <>
      <Modal
        maskClosable={false}
        title={
          <div
            style={{
              width: 1150,
              backgroundColor: '#005fab',
              marginLeft: -24,
              height: 36,
              marginTop: -20,
              borderTopLeftRadius: 7,
              borderTopRightRadius: 7,
              fontSize: 14,
            }}
          >
            <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>工程別合計</p>
          </div>
        }
        width={1150}
        bodyStyle={{ height: 800 }}
        style={{ top: 50 }}
        open={props.showProcessTotalAmount}
        onOk={null}
        onCancel={confirmCancel}
        footer={null}
        closeIcon={<CloseOutlined style={{ color: 'white', fontSize: '13.5px' }} />}
      >
        {processTotalAmountComponent}
      </Modal>
      {/** 開発中ポップアップ */}
      {openCommonTaiochuPopup ? commonTaiochuModal(openCommonTaiochuPopup, closeCommonTaiochuPopup) : ''}
    </>
  );
};

export default KouteibetsuInfo;
