/**
 * クラス名：材質区分情報一覧画面
 * 説明：iQ3板金に使用の材質区分情報一覧ファイルです。
 * 作成者：テッ
 * 作成日：2023/06/01
 * バージョン：1.0
 */

import { Table, Form, Button, Row, Input, Col, Modal, Select, Space, Image, Radio } from 'antd';
import { CSVLink, CSVDownload } from 'react-csv';
import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Outlet, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import SplitPane, { Pane } from 'split-pane-react';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { CloseOutlined } from '@ant-design/icons';
import { StatusCodes } from 'http-status-codes';

import MaterialClassDetail from './MaterialClassDetail';
import '../../../../assets/styles/common.css';
import 'split-pane-react/esm/themes/default.css';
import checked from '../../../../assets/images/checked.png';
import {
  confirmHakiContent,
  confirmModalTitle,
  commonModal,
  commonFooter,
  confirmDiscardContent,
  modalTitle,
  confirmCreateContent,
  confirmDeleteContent,
  commonActionFooter,
  confirmUpdateContent,
  authorizeError,
  error,
  commonDeleteFooter,
} from '../../../common/CommonModal';
import {
  MaterialCategories,
  actionArr,
  dataStateList,
  formatDate,
  getAccessToken,
  updateAccessToken,
} from '../../../common/Common.js';
import { getMaterialTypeInfo } from '../../../common/CommonAPI';
import { ErrorMessage } from '../../../common/Message';

dayjs.extend(customParseFormat);
const { TextArea } = Input;
const RadioGroup = Radio.Group;

const MaterialClassList = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    setSplitPaneSize: setSplitPaneSize,
    showAddNewModal: showAddNewModal,
    showXMLInputModal: showXMLInputModal,
    showGroupEditModal: showGroupEditModal,
    showDeleteModal: showDeleteModal,
    showCSVInputModal: showCSVInputModal,
    getEditMode: editMode,
    updateEditMode: setEditMode,
    discardChange: actionArr,
    setDiscardChangeMode: setDiscardOKAction,
    setEditModeCancelConfirm: setEditModeCancelConfirm,
    discardConfirmOk: discardConfirmOk,
  }));

  const detailRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();

  const [paramSelected, setParamSelected] = useState('材質区分');
  const [paramType, setParamType] = useState('common');
  const [materialClassData, setMaterialClassData] = useState([]);
  const [tempMaterialClass, setTempMaterialClass] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);

  const [editMode, setEditMode] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState([]);
  const [checkedRowId, setCheckedRowId] = useState([]);
  const [changeRecordId, setChangeRecordId] = useState();

  const [dataState, setDataState] = useState();
  const [showFlag, setShowFlag] = useState(1);
  const [filterKey, setFilterKey] = useState();
  const [nameKeyword, setNameKeyWord] = useState('');
  const [hasCheckData, setHasCheckData] = useState(true);
  const [isFilter, setIsFilter] = useState(true);

  const [listShowHide, setListShowHide] = useState(true);
  const [chkData, setChkData] = useState(0);

  const [visibleGroupEditModal, setVisibleGroupEditModal] = useState(false);
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [visibleCSVInput, setVisibleCSVInput] = useState(false);
  const [visibleCSVOutput, setVisibleCSVOutput] = useState(false);
  const [visibleXMLInput, setVisibleXMLInput] = useState(false);
  const [visibleAddNewModal, setVisibleAddNewModal] = useState(false);
  const [childTitle, setChildTitle] = useState('');
  const [cancelConfirm, setCancelConfirm] = useState(false);

  const [editModeCancelConfirm, setEditModeCancelConfirm] = useState(false);
  const [discardOKAction, setDiscardOKAction] = useState(actionArr[0]);
  const [updateCancelConfirm, setUpdateCancelConfirm] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [isGpUpd, setIsGpUpd] = useState(false);
  const [tmpFilteredData, setTmpFilteredData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemNo, setItemNo] = useState(1);
  const [itemNoTmp, setItemNoTmp] = useState(0);

  const [showEdtConfirmOK, setShowEdtConfirmOK] = useState();
  const [showEdtConfirmCancle, setShowEdtConfirmCancle] = useState();

  // Content Resize
  const [sizes, setSizes] = useState(['60%']);

  useEffect(() => {
    if (props.showConfirmMsgs !== undefined) {
      setShowEdtConfirmOK(props.showConfirmMsgs?.editOk);
      setShowEdtConfirmCancle(props.showConfirmMsgs?.editCancel);
    } else {
      setShowEdtConfirmOK(true);
      setShowEdtConfirmCancle(true);
    }
  }, [props.showConfirmMsgs]);

  useEffect(() => {
    getMaterialTypeData();
  }, []);

  // 編集中破棄確認ポップアップに「OK」又は「キャンセル」を押下
  useEffect(() => {
    if (!editMode && !editModeCancelConfirm) {
      if (discardOKAction.key === 0 && changeRecordId !== undefined) {
        setSelectedRowId(changeRecordId);
        setSelectedData(filteredData?.find((item) => item.id === changeRecordId));
        setItemNo(itemNoTmp);
      } else if (discardOKAction.key === 0 && changeRecordId === undefined) {
        return;
      } else {
        const action = actionArr?.find((item) => item.key === discardOKAction.key);
        if ([1, 4, 5]?.includes(discardOKAction.key)) {
          callMethod(action.methodName, filterKey);
        } else {
          callMethod(action.methodName);
        }
      }
    }
  }, [editModeCancelConfirm]);

  // 材質区分マスタ情報を取得する
  const getMaterialTypeData = async () => {
    const materialTypeData = await getMaterialTypeInfo();
    setMaterialClassData(materialTypeData);
    setFilteredData(materialTypeData);
    setTempMaterialClass(materialTypeData);
    if (materialTypeData?.length > 0) {
      setSelectedRowId(materialTypeData[0]?.id);
      setSelectedData(materialTypeData[0]);
    }
    props.setParam(paramType, paramSelected);
    setIsFilter(true);
  };

  const columns = [
    {
      key: 'No',
      title: 'No',
      dataIndex: 'No',
      width: 50,
      sorter: (a, b) => a.no - b.no,
      sortOrder: 'ascend',
      render: (id, record, index) => {
        return (currentPage - 1) * 25 + (index + 1);
      },
      className: 'cm-a-right',
    },

    {
      key: 'code',
      title: 'コード',
      dataIndex: 'code',
      width: 120,
    },
    {
      key: 'name',
      title: '材質区分名',
      dataIndex: 'name',
      width: 150,
    },

    {
      key: 'category',
      title: '参考価格用材質引当',
      dataIndex: 'info',
      width: 150,
      render: (info) => MaterialCategories?.filter((item) => item.value === info.category)?.[0]?.label,
    },
    {
      key: 'created',
      title: '作成日',
      dataIndex: 'created',
      width: 100,
      render: (created) => formatDate(created),
    },
    {
      key: 'modified',
      title: '更新日',
      dataIndex: 'modified',
      width: 100,
      render: (modified) => formatDate(modified),
    },
    {
      key: 'modifier',
      title: '更新者',
      dataIndex: 'modifier',
      width: 150,
    },
    {
      key: 'remarks',
      title: '備考',
      dataIndex: 'info',
      width: 200,
      render: (info) => info.remarks,
    },
    {
      key: 'isUsed',
      title: '表示',
      dataIndex: 'info',
      width: 95,
      render: (text, record) => {
        return record.info.isUsed && <Image preview={false} width={13} src={checked} style={{ marginLeft: '3px' }} />;
      },
      className: 'cm-a-center',
    },
  ];

  const tableColumns = columns?.map((item) => ({
    ...item,
  }));

  const addModalContent = (
    <div id="addNewMaterialClass" style={{ marginTop: 8 }}>
      <table className="table table-bordered" style={{ marginBottom: 0 }}>
        <thead>
          <tr>
            <td className="param-detail-tbl-val-title">No</td>
            <td style={{ verticalAlign: 'middle', height: 28, padding: 3 }}>
              <label>
                {/* {materialClassData?.length + 1} */}
                {tempMaterialClass.no}
              </label>
            </td>
          </tr>

          <tr>
            <td className="param-detail-tbl-val-title">コード</td>
            <td style={{ border: '1px solid #dee2e6', padding: '3px' }}>
              <Input
                type="text"
                data-name="name"
                style={{ padding: 3 }}
                value={tempMaterialClass.code}
                onChange={(e) => {
                  {
                    setTempMaterialClass({ ...tempMaterialClass, code: e.target.value });
                  }
                }}
              />
            </td>
          </tr>
          <tr>
            <td className="param-detail-tbl-val-title">材質区分名</td>
            <td style={{ border: '1px solid #dee2e6', padding: '3px' }}>
              <Input
                type="text"
                data-name="name"
                style={{ padding: 3 }}
                value={tempMaterialClass.name}
                onChange={(e) => {
                  {
                    setTempMaterialClass({ ...tempMaterialClass, name: e.target.value });
                  }
                }}
              />
            </td>
          </tr>

          <tr>
            <td className="param-detail-tbl-val-title">参考価格用材質引当</td>
            <td style={{ border: '1px solid #dee2e6', padding: '3px' }}>
              <Select
                style={{ width: '100%' }}
                onChange={(e) => {
                  {
                    setTempMaterialClass({ ...tempMaterialClass, category: e });
                  }
                }}
                id="materialRef"
                name="materialRef"
                defaultValue="defaultValue"
              >
                <Select.Option id="defaultValue" value="defaultValue">
                  参考価格用材質を選択でください。
                </Select.Option>
                {MaterialCategories?.map((item, index) => (
                  <Select.Option key={index} id={'materialRef' + item.value} value={item.value}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </td>
          </tr>
          <tr>
            <td className="param-detail-tbl-val-title">作成日</td>
            <td style={{ border: '1px solid #dee2e6', padding: '3px' }}>
              <label style={{ fontSize: '13px' }}>{formatDate(tempMaterialClass.created)}</label>
            </td>
          </tr>
          <tr>
            <td className="param-detail-tbl-val-title">更新日</td>
            <td style={{ border: '1px solid #dee2e6', padding: '3px' }}>
              <label style={{ fontSize: '13px' }}>{formatDate(tempMaterialClass.modified)}</label>
            </td>
          </tr>
          <tr>
            <td className="param-detail-tbl-val-title">備考</td>
            <td style={{ border: '1px solid #dee2e6', padding: '3px' }}>
              <TextArea
                type="text"
                data-name="name"
                style={{ padding: 3 }}
                value={tempMaterialClass?.info?.remarks}
                id="remark"
                onChange={(e) => {
                  {
                    setTempMaterialClass({ ...tempMaterialClass, remarks: e.target.value });
                  }
                }}
              />
            </td>
          </tr>
          <tr>
            <td className="param-detail-tbl-val-title">表示</td>
            <td style={{ border: '1px solid #dee2e6', padding: '3px', verticalAlign: 'middle' }}>
              <RadioGroup
                name="isUsed"
                defaultValue={true}
                className="radioCustomerGpEdit"
                onChange={(e) => {
                  {
                    setTempMaterialClass({ ...tempMaterialClass, isUsed: e.target.value });
                  }
                }}
                value={tempMaterialClass?.info?.isUsed}
              >
                <Radio value={true}>オン</Radio>
                <Radio value={false}>オフ</Radio>
              </RadioGroup>
            </td>
          </tr>
        </thead>
      </table>
    </div>
  );

  let checkedRow = [];
  const onRowCheckboxSelect = (checked, record) => {
    checkedRow = [...checked];
    setCheckedRowId(checkedRow);
    setChkData(checkedRow?.length);
  };

  const rowSelection = {
    onChange: onRowCheckboxSelect,
  };

  const groupEditOK = (e) => {
    setIsAdd(true);
    setUpdateCancelConfirm(true);
  };

  const groupEditCancel = (e) => {
    setIsAdd(false);
    setUpdateCancelConfirm(true);
  };

  const gpEditModalContent = (
    <Form>
      <Row>
        <Col span="14">
          <p style={{ fontSize: '14px' }}>検索結果 {selectedRowId?.length} 件</p>
          <Table
            showSorterTooltip={false}
            dataSource={filteredData}
            columns={columns}
            scroll={{ y: 480, x: '49vw' }}
            pagination={{
              position: ['bottomLeft'],
              total: (record) => record?.length,
              showTotal: (total, range) => `全${total}件中${range[0]}件〜${range[1]}件を表示`,
              defaultPageSize: 25,
              defaultCurrent: 1,
              pageSize: 25,
              current: currentPage,
              onChange: (page) => {
                setCurrentPage(page);
              },
            }}
            className="groupEditTable"
            rowSelection={rowSelection}
          />
        </Col>
        <Col span="10">
          <div id="editGpDataTb" style={{ marginTop: 40 }}>
            <Form>
              <div
                style={{
                  height: 502,
                  marginTop: 28,
                  fontSize: '13.5px',
                  overflowY: 'auto',
                  whiteSpace: 'nowrap',
                }}
              >
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <td className="param-detail-tbl-val-title" style={{ width: '145px' }}>
                        材質区分名
                      </td>
                      <td style={{ border: '1px solid #dee2e6', padding: '3px' }}>
                        <Input
                          type="text"
                          data-name="name"
                          style={{ padding: 3 }}
                          value={tempMaterialClass.classificationName}
                          onChange={(e) => {
                            {
                              setTempMaterialClass({ ...tempMaterialClass, classificationName: e.target.value });
                            }
                          }}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="param-detail-tbl-val-title" style={{ width: '145px' }}>
                        参考価格用材質引当
                      </td>
                      <td style={{ border: '1px solid #dee2e6', padding: '3px' }}>
                        <Select
                          style={{ width: '100%' }}
                          onChange={(e) => {
                            {
                              setTempMaterialClass({ ...tempMaterialClass, materialRefPrice: e });
                            }
                          }}
                          id="materialRef"
                          name="materialRef"
                          /* value={tempMaterialClass.materialRefPrice} */
                          defaultValue="defaultValue"
                        >
                          <Select.Option id="defaultValue" value="defaultValue">
                            参考価格用材質を選択でください。
                          </Select.Option>
                          {MaterialCategories?.map((item, index) => (
                            <Select.Option key={index} id={'materialRef' + item.value} value={item.value}>
                              {item.label}
                            </Select.Option>
                          ))}
                        </Select>
                      </td>
                    </tr>
                    <tr>
                      <td className="param-detail-tbl-val-title" style={{ width: '145px' }}>
                        備考
                      </td>
                      <td style={{ border: '1px solid #dee2e6', padding: '3px' }}>
                        <TextArea
                          type="text"
                          data-name="name"
                          style={{ padding: 3 }}
                          id="remark"
                          value={tempMaterialClass.remark}
                          onChange={(e) => {
                            {
                              setTempMaterialClass({ ...tempMaterialClass, remark: e.target.value });
                            }
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="param-detail-tbl-val-title" style={{ width: '145px' }}>
                        表示
                      </td>
                      <td style={{ border: '1px solid #dee2e6', padding: '3px', verticalAlign: 'middle' }}>
                        <RadioGroup
                          name="displayFlag"
                          defaultValue={true}
                          className="radioCustomerGpEdit"
                          onChange={(e) => {
                            {
                              setTempMaterialClass({ ...tempMaterialClass, displayFlag: e.target.value });
                            }
                          }}
                          value={tempMaterialClass.displayFlag}
                        >
                          <Radio value={true}>オン</Radio>
                          <Radio value={false}>オフ</Radio>
                        </RadioGroup>
                      </td>
                    </tr>
                  </thead>
                </table>
              </div>
              <div style={{ marginBottom: 0, marginTop: 0, textAlign: 'end' }}>
                <Button style={{ marginRight: 5 }} className="mainButton" onClick={groupEditOK}>
                  更新
                </Button>
                <Button className="cancelButton" onClick={groupEditCancel}>
                  破棄
                </Button>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </Form>
  );

  const onRowSelect = (record, index) => {
    setChangeRecordId(record.id);
    //setItemNo((currentPage - 1) * 25 + (index + 1));
    if (editMode) {
      setDiscardOKAction(actionArr[0]);
      setItemNoTmp((currentPage - 1) * 25 + (index + 1));
      setEditModeCancelConfirm(true);
      let moveInEdit = props.showConfirmMsgs.moveInEdit == undefined ? true : props.showConfirmMsgs.moveInEdit;
      setEditModeCancelConfirm(moveInEdit);
      if (!moveInEdit) {
        setSelectedRowId(record.id);
        setSelectedData(record);
        setItemNo((currentPage - 1) * 25 + (index + 1));
        discardConfirmOk();
        navigate(':common.materialClass');
      }
    } else {
      setSelectedRowId(record.id);
      setSelectedData(record);
      setItemNo((currentPage - 1) * 25 + (index + 1));
      navigate(':common.materialClass');
    }
  };

  const handleOk = (e) => {
    //setUpdateConfirm(true);
    setVisibleGroupEditModal(false);
    setVisibleDeleteModal(false);
    setVisibleCSVOutput(false);
    setVisibleAddNewModal(false);
  };

  const handleCancel = (e) => {
    //setCancelConfirm(true);
    setVisibleGroupEditModal(false);
    setVisibleDeleteModal(false);
    setVisibleCSVOutput(false);
    setVisibleAddNewModal(false);
    setVisibleCSVInput(false);
    setVisibleXMLInput(false);
  };

  const gpEditCheck = (newObj, oldObj, objKey) => {
    if (newObj[objKey] === '') {
      return oldObj[objKey];
    } else {
      setIsGpUpd(newObj[objKey] === oldObj[objKey]);
      return newObj[objKey];
    }
  };

  const updConfirmOk = (e) => {
    let stateData = [];
    stateData = JSON.parse(JSON.stringify(materialClassData));
    if (visibleAddNewModal) {
      stateData.push(tempMaterialClass);
      setMaterialClassData(stateData);
      setTmpFilteredData(stateData);
      resetAddModal(stateData?.length + 1);
      onRowSelect(tempMaterialClass, 0);
      setCurrentPage(Math.ceil(tempMaterialClass.no / 25));
      setVisibleAddNewModal(false);
    } else if (visibleGroupEditModal) {
      if (checkedRowId?.length > 0) {
        const updatedData = stateData?.map((obj) => {
          if (checkedRowId?.includes(obj.id)) {
            return {
              ...obj,
              displayFlag: gpEditCheck(tempMaterialClass, obj, 'displayFlag'),
              classificationName: gpEditCheck(tempMaterialClass, obj, 'classificationName'),
              materialRefPrice: gpEditCheck(tempMaterialClass, obj, 'materialRefPrice'),
              remark: gpEditCheck(tempMaterialClass, obj, 'remark'),
              modified: isGpUpd ? obj.modified : dayjs().format('YYYY-MM-DD'),
            };
          }
          return obj;
        });
        setMaterialClassData(updatedData);
        //setFilteredData(updatedData);
        setTmpFilteredData(updatedData);
      }
      setVisibleGroupEditModal(false);
    }
    setUpdateCancelConfirm(false);
    setIsFilter(true);
  };

  const callMethod = (methodName, param) => {
    const method = eval(methodName);
    if (typeof method === 'function') {
      method(param);
    }
  };

  // 編集中破壊確認ポップアップのOKボタン押下
  const discardConfirmOk = () => {
    setEditModeCancelConfirm(false);
    detailRef.current.discardConfirmOk();
  };

  // 編集中破壊確認ポップアップのCancelボタン押下
  const discardConfirmCancel = () => {
    setEditModeCancelConfirm(false);
    detailRef.current.discardConfirmCancel();
    /* setEditModeCancelConfirm(showEdtConfirmCancle);
    if (!showEdtConfirmCancle) {
      detailRef.current.discardConfirmCancel();
    } */
  };

  const updCancelOK = () => {
    setUpdateCancelConfirm(false);
    setVisibleAddNewModal(false);
    setVisibleGroupEditModal(false);
  };

  const updCancelCancel = () => {
    setUpdateCancelConfirm(false);
    if (visibleAddNewModal) {
      setVisibleAddNewModal(true);
    } else if (visibleGroupEditModal) {
      setVisibleGroupEditModal(true);
    }
  };

  const updConfirmCancel = () => {
    /* setUpdateConfirm(false); */
    setUpdateCancelConfirm(false);
  };

  const handleOkXMLInput = (e) => {
    setVisibleXMLInput(false);
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = '.xml';
    input.onchange = (_) => {
      let files = Array.from(input.files);
    };
    input.click();
  };

  const showGroupEditModal = () => {
    if (editMode) {
      setDiscardOKAction(actionArr[2]);
      /* updateEditMode(false); */
      setEditModeCancelConfirm(true);
    } else {
      handleGpEdit();
    }
    /* setVisibleGroupEditModal(true); */
  };

  const handleGpEdit = () => {
    setTempMaterialClass({
      displayFlag: true,
      classificationName: '',

      materialRefPrice: '',
      remark: '',
    });
    setVisibleGroupEditModal(true);
  };

  const showCSVInputModal = () => {
    setVisibleCSVInput(false);
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = '.csv';
    input.onchange = (_) => {
      let files = Array.from(input.files);
    };
    input.click();
  };

  const showXMLInputModal = () => {
    setVisibleXMLInput(true);
  };

  const showAddNewModal = () => {
    if (editMode) {
      setDiscardOKAction(actionArr[3]);
      /* updateEditMode(false); */
      setEditModeCancelConfirm(true);
    } else {
      handleAddFormSubmit();
    }
  };

  const showDeleteModal = () => {
    if (editMode) {
      setEditModeCancelConfirm(true);
    } else {
      setVisibleDeleteModal(true);
    }
  };

  const handleAddFormSubmit = () => {
    setTempMaterialClass({
      key: materialClassData[materialClassData?.length - 1].key + 1,
      no: materialClassData?.length + 1,
      displayFlag: true,
      classificationCode: (parseFloat(materialClassData[materialClassData?.length - 1].classificationCode) + 1)
        .toString()
        .padStart(3, '0'),
      classificationName: '',

      materialRefPrice: ' ',
      created: dayjs().format('YYYY-MM-DD'),
      modified: dayjs().format('YYYY-MM-DD'),
      remark: '',
    });
    setVisibleAddNewModal(true);
    setChildTitle('材質区分');
  };

  const deleteOK = () => {
    setVisibleDeleteModal(false);
    let data = [...materialClassData];
    data = data?.filter((item) => item.key != selectedData.key);

    for (let i = 0; i < data?.length; i++) {
      data[i].no = i + 1;
    }
    setMaterialClassData(data);
    setTmpFilteredData(data);
    setFilteredData(data);
    setSelectedRowId(data?.length > 0 ? data[data?.length - 1].id : -1);
    setSelectedData(data?.length > 0 ? data[data?.length - 1] : []);
    setIsFilter(true);
  };

  const deleteCancel = () => {
    setVisibleDeleteModal(false);
  };

  const addOK = () => {
    setIsAdd(true);
    setUpdateCancelConfirm(true);
  };

  const addCancel = () => {
    setIsAdd(false);
    setUpdateCancelConfirm(showEdtConfirmCancle);
    if (!showEdtConfirmCancle) {
      updCancelOK();
    }
  };

  const resetAddModal = (count) => {
    setTempMaterialClass({
      key: count,
      displayFlag: false,
      classificationCode: (count + 1).toString().padStart(3, '0'),
      classificationName: '',
      materialRefPrice: '',
      remark: '',
    });
  };

  // フラグ、キーワードに変更された場合、
  useEffect(() => {
    getDataByFilter();
  }, [nameKeyword, dataState, showFlag, tmpFilteredData, materialClassData]);

  // 型番のキーワードで絞り込み無効フラグの押下
  const searchByDataState = (value) => {
    dataState === value ? getDataByFilter() : setDataState(value);
  };

  // 無効フラグの押下
  const searchByShowFlg = (value) => {
    showFlag === value ? getDataByFilter() : setShowFlag(value);
  };

  const beforeSearch = (itemName, e) => {
    {
      let action;
      setIsFilter(true);
      switch (itemName) {
        //材質区分名
        case 'name':
          action = actionArr[1];
          break;
        //材質
        case 'materialRef':
          action = actionArr[4];
          break;
        //表示.非表示
        case 'showFlag':
          action = actionArr[5];
          break;
      }
      setFilterKey(e);
      setDiscardOKAction(action);
      callMethod(action.methodName, e);
    }
  };

  // キーワードで検索した場合、
  const searchByKeyword = (e) => {
    switch (e.target.name) {
      case 'name':
        nameKeyword === e.target.value ? getDataByFilter() : setNameKeyWord(e.target.value);
        break;
    }
  };

  // 絞り込み、
  const getDataByFilter = () => {
    if (materialClassData?.length <= 0) {
      return;
    }
    let nKeyword = nameKeyword;
    let currentData = [...materialClassData];
    let flag;
    let materialFilterData = currentData;

    // 材質区分名のキーワードで絞り込み
    if (nKeyword != '') {
      materialFilterData = materialFilterData?.filter((item) => item.name?.toString().indexOf(nKeyword) >= 0);
    }

    //  表示・非表示フラグで絞り込み
    if (showFlag === 1 || showFlag === 2) {
      flag = showFlag === 1 ? true : false;
      materialFilterData = materialFilterData?.filter((item) => item.info.isUsed === flag);
    }

    // 参考価格用材質で絞り込み
    if (dataState !== undefined && dataState !== '全て') {
      materialFilterData = materialFilterData?.filter((item) => item.info.category === dataState);
    }
    setFilteredData(materialFilterData);
  };

  document.body.style.overflowY = 'hidden';

  const reportModalTitle = (
    <div
      style={{
        width: 520,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{childTitle}</p>
    </div>
  );

  const setSplitPaneSize = (splitSize) => {
    setSizes(splitSize);
  };

  // 更新確認のOKを押下で、一覧画面のデータを反映する
  const submitData = async (updateData) => {
    var tmpData = [...materialClassData];
    var updatedInfo = [];

    updatedInfo = await updateMaterialTypeData(updateData);

    var index = tmpData?.findIndex((item) => item.id === updatedInfo.id);
    tmpData[index] = updatedInfo;
    setMaterialClassData(tmpData);
    setTmpFilteredData(tmpData);
    setIsFilter(false);
    setSelectedRowId(updatedInfo?.id);
    setSelectedData(updatedInfo);
  };

  // 材質区分マスタ情報をDBに更新する
  async function updateMaterialTypeData(updateData) {
    let ret = [];
    try {
      const Url = process.env.REACT_APP_BACKEND_API_URL + 'MaterialType';
      const otherParam = {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + getAccessToken(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
        body: JSON.stringify({
          id: updateData.id,
          code: updateData.code,
          name: updateData.name,
          info: updateData.info,
        }),
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          return await response.json();
        })
        .then((data) => {
          if (data && data?.updateToken) {
            // セクションにトークン情報を更新する
            updateAccessToken(data?.updateToken);
          }
          if (data && data.statusCode === StatusCodes.OK) {
            ret = data.data[0];
          } else if (
            data &&
            (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)
          ) {
            authorizeError(ErrorMessage()[data?.messageCode]);
            return;
          } else if (data && data.statusCode !== StatusCodes.OK) {
            error(ErrorMessage()[data?.messageCode]);
            return;
          }
        })
        .catch((e) => {
          authorizeError(ErrorMessage().E010);
          return;
        });
    } catch (e) {
      error(e.message);
      return;
    }
    return ret;
  }

  // 編集モードを変更
  const updateEditMode = (mode) => {
    setEditMode(mode);
    props.updateMainParameterEditMode(mode);
  };

  return (
    <>
      {/* 一覧とプロパティ表示 */}
      <div
        style={{
          height: '90.8vh',
          marginLeft: 10,
        }}
      >
        <SplitPane split="vertical" sizes={sizes} onChange={setSizes} resizerSize={1}>
          <div style={{ marginTop: 7 }}>
            {/*  クイック検索 */}
            {listShowHide ? (
              <>
                <Space size="middle">
                  <Input
                    className="input-editable search-select-input"
                    placeholder="材質区分名"
                    allowClear
                    onChange={(e) => {
                      if (e.target.value === '') {
                        beforeSearch('name', e);
                      }
                    }}
                    onPressEnter={(e) => {
                      beforeSearch('name', e);
                    }}
                    onBlur={(e) => {
                      beforeSearch('name', e);
                    }}
                    name="name"
                    id="name"
                  />

                  <Select
                    className="input-editable search-select-input"
                    onChange={(e) => beforeSearch('materialRef', e) /* searchByDataState */}
                    id="materialRef"
                    name="materialRef"
                    value={dataState}
                    defaultValue={'全て'}
                  >
                    <Select.Option id="allOption" value={'全て'}>
                      {'全ての参考価格用材質'}
                    </Select.Option>
                    {MaterialCategories?.map((item, index) => (
                      <Select.Option key={index} id={'materialRef' + item.value} value={item.value}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                  <Select
                    style={{ width: 90 }}
                    onChange={(e) => beforeSearch('showFlag', e)}
                    id="showFlag"
                    name="showFlag"
                    value={showFlag}
                  >
                    {dataStateList?.map((item, index) => (
                      <Select.Option key={index} id={'showFlg' + item.value} value={item.value}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Space>
                {/* 一覧アリア */}
                <Row style={{ marginTop: 10 }}>
                  <Table
                    showSorterTooltip={false}
                    columns={hasCheckData ? tableColumns : chkData}
                    dataSource={filteredData}
                    scroll={{ y: 590, x: '10vw' }}
                    rowClassName={(record, index) => {
                      return record.id === selectedRowId ? 'active-row' : 'data-row';
                    }}
                    rowKey={(record) => record.id}
                    pagination={{
                      position: ['bottomLeft'],
                      total: (record) => record?.length,
                      showTotal: (total, range) => `全${total}件中${range[0]}件〜${range[1]}件を表示`,
                      defaultPageSize: 25,
                      defaultCurrent: 1,
                      pageSize: 25,
                      current: currentPage,
                      onChange: (page) => {
                        setCurrentPage(page);
                      },
                    }}
                    className="param_tbListShow"
                    onRow={(record, index) => {
                      return {
                        onClick: (event) => {
                          onRowSelect(record, index);
                        },
                      };
                    }}
                  />
                </Row>
              </>
            ) : (
              <></>
            )}
          </div>
          <div>
            {/* <Outlet /> */}
            <Routes>
              <Route
                exact
                path=":common.materialClass"
                element={
                  <MaterialClassDetail
                    ref={detailRef}
                    editMode={editMode}
                    selectedData={materialClassData?.find((element) => element.id === selectedRowId)}
                    submitData={submitData}
                    updateEditMode={updateEditMode}
                    itemNo={itemNo}
                    showEdtConfirmOK={showEdtConfirmOK}
                    showEdtConfirmCancle={showEdtConfirmCancle}
                  />
                }
              />
            </Routes>
          </div>
        </SplitPane>
      </div>

      {/* 一括編集　Modal */}
      {visibleGroupEditModal &&
        commonModal(
          visibleGroupEditModal,
          modalTitle('材質区分（選択したデータをまとめて編集）', 1300, -24),
          /* commonActionFooter(groupEditOK, groupEditCancel, '更新', '破棄'), */
          null,
          null,
          1300,
          handleOk,
          handleCancel,
          gpEditModalContent,
          'gpEditModal',
          null,
          false
        )}

      {/* 削除_Modal */}
      {visibleDeleteModal &&
        commonModal(
          visibleDeleteModal,
          confirmModalTitle,
          commonDeleteFooter(deleteOK, deleteCancel),
          null,
          400,
          handleOk,
          handleCancel,
          confirmDeleteContent,
          null,
          null,
          false
        )}

      {/* XML呼出 */}
      <Modal
        //title="確認"
        title={reportModalTitle}
        open={visibleXMLInput}
        onOk={handleOkXMLInput}
        onCancel={handleCancel}
        footer={[
          <Button key="file" type="primary" onClick={handleOkXMLInput} className="btn-ok-cancel">
            OK
          </Button>,
          <Button key="back" onClick={handleCancel} className="btn-ok-cancel">
            Cancel
          </Button>,
        ]}
        closeIcon={<CloseOutlined style={{ color: 'white' }} />}
      >
        <p>
          装置情報（時間チャージ、段取設定、加工設定）を初期パラメータ作成情報から取り込みます。
          <br></br>装置情報は、呼出情報で更新されます。
        </p>
      </Modal>

      {/** 編集中破棄確認ポップアップ */}
      {editModeCancelConfirm
        ? commonModal(
            editModeCancelConfirm,
            confirmModalTitle,
            commonFooter(discardConfirmOk, discardConfirmCancel),
            null,
            400,
            discardConfirmOk,
            discardConfirmCancel,
            confirmHakiContent,
            null,
            null,
            false
          )
        : ''}

      {/* 新規追加Modal */}
      {visibleAddNewModal &&
        commonModal(
          visibleAddNewModal,
          modalTitle('材質区分（新規追加）', 600, -24),
          commonActionFooter(addOK, addCancel, '登録', '破棄'),
          null,
          600,
          handleOk,
          handleCancel,
          addModalContent,
          'addNewMaterialClassModal',
          null,
          false
        )}

      {/** 新規追加破棄確認ポップアップ */}
      {updateCancelConfirm &&
        commonModal(
          updateCancelConfirm,
          confirmModalTitle,
          commonFooter(isAdd ? updConfirmOk : updCancelOK, isAdd ? updConfirmCancel : updCancelCancel),
          null,
          400,
          updCancelOK,
          updCancelCancel,
          isAdd ? (visibleGroupEditModal ? confirmUpdateContent : confirmCreateContent) : confirmDiscardContent,
          null,
          null,
          false
        )}
    </>
  );
});

export default MaterialClassList;
