/**
 * クラス名：お客様・仕入先パラメータ
 * 説明：お客様・仕入先情報一覧画面
 * 作成者：ケイ
 * 作成日：2023/05/01
 * バージョン：1.0
 */
import { Table, Form, Button, Row, Input, Col, Modal, Select, Checkbox, Space, Image, Radio, DatePicker } from 'antd';
import { Table as RTable } from 'react-bootstrap';
import { CSVLink, CSVDownload } from 'react-csv';
import React, { useState, useEffect, useImperativeHandle, forwardRef, useRef } from 'react';
import { Outlet, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { CloseOutlined } from '@ant-design/icons';
import SplitPane, { Pane } from 'split-pane-react';
import 'split-pane-react/esm/themes/default.css';
import { StatusCodes } from 'http-status-codes';

import { ClientType } from '../../../common/enums';
import CustomerSupplierDetails from './CustomerSupplierDetails';
import '../../../../assets/styles/common.css';
import checked from '../../../../assets/images/checked.png';
import {
  confirmHakiContent,
  confirmUpdateContent,
  confirmDiscardContent,
  confirmCreateContent,
  confirmModalTitle,
  confirmDeleteContent,
  confirmDiscardAndDeleteContent,
  commonModal,
  commonFooter,
  error,
  authorizeError,
  commonDeleteFooter,
} from '../../../common/CommonModal';
import {
  dataStateList,
  today,
  flgStateList,
  TextArea,
  formatDate,
  updateAccessToken,
  formValidatorMode,
  toDecimal,
  ResizableTitle,
  isCustomer,
  isSupplier,
  isContractor,
  getAccessToken,
  handleKeyPress,
} from '../../../common/Common';
import { getClientInfo } from '../../../common/CommonAPI';
import { ErrorMessage } from '../../../common/Message';
import filter_clear_icon from '../../../../assets/images/filter_clear_icon.png';

dayjs.extend(customParseFormat);
const RadioGroup = Radio.Group;
const dateFormat = 'YYYY-MM-DD';

const CustomerSupplierParamList = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    setSplitPaneSize: setSplitPaneSize,
    createNewProcess: createNewProcess,
    showXMLInputModal: showXMLInputModal,
    bulkEditProcess: bulkEditProcess,
    deleteProcess: deleteProcess,
    showCSVInputModal: showCSVInputModal,
    getEditMode: editMode,
    updateEditMode: setEditMode,
    discardChange: discardChange,
    setDiscardChangeMode: setDiscardChangeMode,
    setEditModeCancelConfirm: setEditModeCancelConfirm,
    discardConfirmOk: discardConfirmOk,
    // setShowEdtConfirmOK: setShowEdtConfirmOK,
  }));

  const inputItemRef = {};

  let checkedRow = [];
  const detailRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  // デフォルトページの表示件数
  const defaultPageSize = 25;

  let loginUser = JSON.parse(decodeURI(localStorage?.getItem('iQxWeb_LoginUser')));
  let token = localStorage?.getItem('iQxWeb_AccessToken');
  let partType = ['customer', 'supplier'];

  // 破壊ポップアップを表示させる処理タイプ
  const discardChange = [
    { no: 1, state: 'rowSelect' },
    { no: 2, state: 'search' },
    { no: 3, state: 'bulkEdit' },
    { no: 4, state: 'createNew' },
  ];
  const discardChangePart = [{ no: 99, state: 'changePart' }];

  const [paramSelected, setParamSelected] = useState(partType[0]);
  const [paramType, setParamType] = useState('common');
  const [activePartMenu, setActivePartMenu] = useState(partType[0]);
  const [changePartName, setChangePartName] = useState(partType[0]);

  const [customersData, setCustomersData] = useState([]);
  const [supplierData, setSupplierData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [tempCustomersData, setTempCustomersData] = useState([]);

  const [editMode, setEditMode] = useState(false);
  // 発生した処理タイプ
  const [discardChangeMode, setDiscardChangeMode] = useState();
  const [cusSelectedRowId, setCusSelectedRowId] = useState([]);
  const [supSelectedRowId, setSupSelectedRowId] = useState([]);
  const [changeRecordId, setChangeRecordId] = useState(0);

  const [cusCurrentPage, setCusCurrentPage] = useState(1);
  const [supCurrentPage, setSupCurrentPage] = useState(1);

  const [code, setCode] = useState('');
  const [name, setName] = useState('');
  const [created, setCreated] = useState('');
  const [modified, setModified] = useState('');
  const [modifier, setModifier] = useState('');
  const [customerFlg, setCustomerFlg] = useState(false);
  const [supplierFlg, setSupplierFlg] = useState(false);
  const [contractorFlg, setContractorFlg] = useState(false);
  const [customerCoefficients, setCustomerCoefficients] = useState('');
  const [supplierCoefficients, setSupplierCoefficients] = useState('');
  const [contractorCoefficients, setContractorCoefficients] = useState('');

  const [isUsed, setIsUsed] = useState();
  const [branchNum, setBranchNum] = useState();
  const [nameKana, setNameKana] = useState('');
  const [tel, setTel] = useState('');
  const [fax, setFax] = useState('');
  const [contactParson, setContactParson] = useState('');
  const [mail, setMail] = useState('');
  const [zip, setZip] = useState('');
  const [prefecture, setPrefecture] = useState('');
  const [address, setAddress] = useState('');
  const [remarks, setRemarks] = useState('');
  const [sortNum, setSortNum] = useState();

  const [dataState, setDataState] = useState(0);
  const [customerFlgState, setCustomerFlgState] = useState(0);
  const [supplierFlgState, setSupplierFlgState] = useState(0);
  const [contractorFlgState, setContractorFlgState] = useState(0);
  const [customerCoeff, setCustomerCoeff] = useState();
  const [supplierCoeff, setSupplierCoeff] = useState();
  const [outsourcerCoeff, setOutsourcerCoeff] = useState();
  const [keyword, setKeyWord] = useState('');

  const [hasCheckData, setHasCheckData] = useState(true);
  const [chkData, setChkData] = useState(0);

  const [selectedRowsData, setSelectedRowsData] = useState([]);

  const [isBulkEdit, setIsBulkEdit] = useState(false);
  const [isCreateNew, setIsCreateNew] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [visibleCSVInput, setVisibleCSVInput] = useState(false);
  const [visibleCSVOutput, setVisibleCSVOutput] = useState(false);
  const [visibleXMLInput, setVisibleXMLInput] = useState(false);
  const [childTitle, setChildTitle] = useState('');

  const [createConfirm, setCreateConfirm] = useState(false);
  const [cancelConfirm, setCancelConfirm] = useState(false);
  const [updateConfirm, setUpdateConfirm] = useState(false);
  const [editModeCancelConfirm, setEditModeCancelConfirm] = useState(false);

  // 一括編集
  const [chkrowscount, setChkRowsCount] = useState(0);
  const [bulkEditSelectedRow, setBulkEditSelectedRow] = useState([]);
  const [bulkEditSelectedRowId, setBulkEditSelectedRowId] = useState([]);

  const [tmpFilteredData, setTmpFilteredData] = useState();

  // Content Resize
  const [sizes, setSizes] = useState(['60%']);

  //　テーブル列Resizable用変数
  // 一覧用変数
  const [nameWidth, setNameWidth] = useState(150);
  const [nameKanaWidth, setNameKanaWidth] = useState(150);
  const [telWidth, setTelWidth] = useState(125);
  const [faxWidth, setFaxWidth] = useState(125);
  const [contactParsonWidth, setContactParsonWidth] = useState(125);
  const [mailWidth, setMailWidth] = useState(150);
  const [zipWidth, setZipWidth] = useState(100);
  const [prefectureWidth, setPrefectureWidth] = useState(150);
  const [addressWidth, setAddressWidth] = useState(150);
  const [remarkWidth, setRemarkWidth] = useState(200);
  // 一括編集用変数
  const [nameWidthEdit, setNameWidthEdit] = useState(150);
  const [nameKanaWidthEdit, setNameKanaWidthEdit] = useState(150);
  const [telWidthEdit, setTelWidthEdit] = useState(125);
  const [faxWidthEdit, setFaxWidthEdit] = useState(125);
  const [contactParsonWidthEdit, setContactParsonWidthEdit] = useState(125);
  const [mailWidthEdit, setMailWidthEdit] = useState(150);
  const [zipWidthEdit, setZipWidthEdit] = useState(100);
  const [prefectureWidthEdit, setPrefectureWidthEdit] = useState(150);
  const [addressWidthEdit, setAddressWidthEdit] = useState(150);
  const [remarkWidthEdit, setRemarkWidthEdit] = useState(200);

  const [showEdtConfirmOK, setShowEdtConfirmOK] = useState();
  const [showEdtConfirmCancle, setShowEdtConfirmCancle] = useState();

  useEffect(() => {
    getClientData();
  }, [paramType, paramSelected]);

  // フラグ又はキーワードに変更された場合、
  useEffect(() => {
    // 絞り込み、
    getDataByFilter();
  }, [keyword, dataState, customerFlgState, supplierFlgState, contractorFlgState, tmpFilteredData]);

  useEffect(() => {
    if (props.showConfirmMsgs !== undefined) {
      setShowEdtConfirmOK(props.showConfirmMsgs?.editOk);
      setShowEdtConfirmCancle(props.showConfirmMsgs?.editCancel);
    } else {
      setShowEdtConfirmOK(true);
      setShowEdtConfirmCancle(true);
    }
  }, [props.showConfirmMsgs]);

  // 編集中破棄確認ポップアップに「OK」又は「キャンセル」を押下
  useEffect(() => {
    let currentData = [];
    let selectedData;
    let id = '';
    if (!editMode && !editModeCancelConfirm) {
      currentData = getCurrentData();
      switch (discardChangeMode?.no) {
        case discardChange[0]?.no: // 編集中に行を選択した場合、
          id = changeRecordId;
          setSelectedRowId(id);
          selectedData = getSelectedData(currentData, id);
          setSelectedData(selectedData);
          break;
        case discardChange[1]?.no: // 検索
          getDataByFilter();
          break;
        case discardChange[2]?.no: // 一括編集
        case discardChange[3]?.no: // 新規作成
          id = getSelectedRowId();
          selectedData = getSelectedData(currentData, id);
          setSelectedData(selectedData);
          discardChangeMode?.no === discardChange[2]?.no ? bulkEditProcess() : createNewProcess();
          break;
        case discardChangePart[0]?.no: //　編集中に「お客様・仕入先」を変更した場合、
          //setActivePartMenu(changePartName);
          getDataByFilter(changePartName);
          break;
      }
    }
  }, [editModeCancelConfirm]);

  // 取引先情報をDBから取得する
  async function getClientData() {
    let clientsData = [];
    let selectedData = [];
    let customersData = [];
    let supplierData = [];
    try {
      clientsData = await getClientInfo();

      customersData = clientsData; /* ?.filter((record) => record.clientType === ClientType.Customer); */
      supplierData = clientsData; /* ?.filter((record) => record.clientType === ClientType.Supplier); */

      if (customersData?.length > 0) selectedData = customersData[0];

      setCustomersData(customersData);
      setSupplierData(supplierData);
      setFilteredData(customersData);

      setCusSelectedRowId(selectedData?.id);
      setSupSelectedRowId(supplierData?.[0]?.id);

      setSelectedData(selectedData);
      setSelectedRowsData(selectedData);

      setDataState(1);

      navigate(':common.customer');
      setParamSelected(partType[0]);
      setParamType('common');
      props.setParam(paramType, paramSelected);
    } catch (e) {
      error(e.message);
      return;
    }
  }

  const columns = [
    {
      id: '1',
      title: 'No',
      dataIndex: 'no',
      width: 50,
      render: (id, record, index) => {
        return (cusCurrentPage - 1) * defaultPageSize + (index + 1);
      },
      sorter: (a, b) => a.id - b.id,
      sortOrder: 'ascend',
      className: 'cm-a-right',
    },

    {
      id: '2',
      title: 'コード',
      dataIndex: 'code',
      width: 120,
      onFilter: (value, record) => record[dataIndex].toString().toLowerCase()?.includes(value.toLowerCase()),
    },
    {
      id: '3',
      title: '枝番',
      dataIndex: 'info',
      width: 90,
      render: (info) => info.branchNum,
    },
    {
      id: '4',
      title: '名称',
      dataIndex: 'name',
      width: isBulkEdit ? nameWidthEdit : nameWidth,
      onHeaderCell: (column) => ({
        width: column.width,
        onResize: (e, { size }) => {
          if (isBulkEdit) {
            setNameWidthEdit(size.width);
          } else {
            setNameWidth(size.width);
          }
        },
      }),
    },
    {
      id: '5',
      title: 'カナ',
      dataIndex: 'info',
      render: (info) => info.nameKana,
      width: isBulkEdit ? nameKanaWidthEdit : nameKanaWidth,
      onHeaderCell: (column) => ({
        width: column.width,
        onResize: (e, { size }) => {
          if (isBulkEdit) {
            setNameKanaWidthEdit(size.width);
          } else {
            setNameKanaWidth(size.width);
          }
        },
      }),
    },
    /* {
      id: '7',
      title: '係数',
      dataIndex: 'coefficients',
      width: 70,
      className: 'cm-a-right',
      render: (coefficients, data) =>
        toDecimal(coefficients?.filter((record) => record.clientType === data.clientType)?.[0]?.coefficient),
    }, */
    {
      id: '6',
      title: 'お客様',
      dataIndex: 'clientType',
      width: 95,
      render: (value) => {
        return (
          <div style={{ display: 'flex' }}>
            <div style={{ flex: 1 }}>
              {isCustomer(value) === true ? (
                <Image preview={false} width={13} src={checked} style={{ marginRight: '5px' }} />
              ) : (
                <></>
              )}
            </div>
          </div>
        );
      },
      className: 'cm-a-center',
    },
    {
      id: '7',
      title: 'お客様係数',
      dataIndex: 'coeffClients',
      width: 95,
      render: (coeffClients, data) =>
        isCustomer(data.clientType) === true ? (
          parseFloat(coeffClients?.filter((record) => record.clientType === ClientType.Customer)?.[0]?.coeff).toFixed(2)
        ) : (
          <></>
        ),
    },
    {
      id: '8',
      title: '仕入先',
      dataIndex: 'clientType',
      width: 95,
      render: (value) => {
        return (
          <div style={{ display: 'flex' }}>
            <div style={{ flex: 1 }}>
              {isSupplier(value) === true ? (
                <Image preview={false} width={13} src={checked} style={{ marginRight: '5px' }} />
              ) : (
                <></>
              )}
            </div>
          </div>
        );
      },
      className: 'cm-a-center',
    },
    {
      id: '9',
      title: '仕入先係数',
      dataIndex: 'coeffClients',
      width: 95,
      render: (coeffClients, data) =>
        isSupplier(data.clientType) === true ? (
          parseFloat(coeffClients?.filter((record) => record.clientType === ClientType.Supplier)?.[0]?.coeff).toFixed(2)
        ) : (
          <></>
        ),
    },
    {
      id: '10',
      title: '委託先',
      dataIndex: 'clientType',
      width: 95,
      render: (value) => {
        return (
          <div style={{ display: 'flex' }}>
            <div style={{ flex: 1 }}>
              {isContractor(value) === true ? (
                <Image preview={false} width={13} src={checked} style={{ marginRight: '5px' }} />
              ) : (
                <></>
              )}
            </div>
          </div>
        );
      },
      className: 'cm-a-center',
    },
    {
      id: '11',
      title: '委託先係数',
      dataIndex: 'coeffClients',
      width: 95,
      render: (coeffClients, data) =>
        isContractor(data.clientType) === true ? (
          parseFloat(coeffClients?.filter((record) => record.clientType === ClientType.Contractor)?.[0]?.coeff).toFixed(
            2
          )
        ) : (
          <></>
        ),
    },
    {
      id: '12',
      title: 'Tel',
      dataIndex: 'info',
      render: (info) => info.tel,
      width: isBulkEdit ? telWidthEdit : telWidth,
      onHeaderCell: (column) => ({
        width: column.width,
        onResize: (e, { size }) => {
          if (isBulkEdit) {
            setTelWidthEdit(size.width);
          } else {
            setTelWidth(size.width);
          }
        },
      }),
    },
    {
      id: '13',
      title: 'Fax',
      dataIndex: 'info',
      render: (info) => info.fax,
      width: isBulkEdit ? faxWidthEdit : faxWidth,
      onHeaderCell: (column) => ({
        width: column.width,
        onResize: (e, { size }) => {
          if (isBulkEdit) {
            setFaxWidthEdit(size.width);
          } else {
            setFaxWidth(size.width);
          }
        },
      }),
    },
    {
      id: '14',
      title: '担当者',
      dataIndex: 'info',
      render: (info) => info.contactParson,
      width: isBulkEdit ? contactParsonWidthEdit : contactParsonWidth,
      onHeaderCell: (column) => ({
        width: column.width,
        onResize: (e, { size }) => {
          if (isBulkEdit) {
            setContactParsonWidthEdit(size.width);
          } else {
            setContactParsonWidth(size.width);
          }
        },
      }),
    },
    {
      id: '15',
      title: 'メール',
      dataIndex: 'info',
      render: (info) => info.mail,
      width: isBulkEdit ? mailWidthEdit : mailWidth,
      onHeaderCell: (column) => ({
        width: column.width,
        onResize: (e, { size }) => {
          if (isBulkEdit) {
            setMailWidthEdit(size.width);
          } else {
            setMailWidth(size.width);
          }
        },
      }),
    },
    {
      id: '16',
      title: '郵便番号',
      dataIndex: 'info',
      width: 100,
      render: (info) => info.zip,
      width: isBulkEdit ? zipWidthEdit : zipWidth,
      onHeaderCell: (column) => ({
        width: column.width,
        onResize: (e, { size }) => {
          if (isBulkEdit) {
            setZipWidthEdit(size.width);
          } else {
            setZipWidth(size.width);
          }
        },
      }),
    },
    {
      id: '17',
      title: '都道府県',
      dataIndex: 'info',

      render: (info) => info.prefecture,
      width: isBulkEdit ? prefectureWidthEdit : prefectureWidth,
      onHeaderCell: (column) => ({
        width: column.width,
        onResize: (e, { size }) => {
          if (isBulkEdit) {
            setPrefectureWidthEdit(size.width);
          } else {
            setPrefectureWidth(size.width);
          }
        },
      }),
    },
    {
      id: '18',
      title: '住所',
      dataIndex: 'info',
      width: isBulkEdit ? addressWidthEdit : addressWidth,
      render: (info) => info.address,
      onHeaderCell: (column) => ({
        width: column.width,
        onResize: (e, { size }) => {
          if (isBulkEdit) {
            setAddressWidthEdit(size.width);
          } else {
            setAddressWidth(size.width);
          }
        },
      }),
    },

    {
      id: '19',
      title: 'ソート番号',
      dataIndex: 'info',
      width: 95,
      className: 'cm-a-right',
      render: (info) => info.sortNum,
    },
    {
      id: '20',
      title: '作成日',
      dataIndex: 'created',
      width: 100,
      render: (created) => formatDate(created),
    },
    {
      id: '21',
      title: '更新日',
      dataIndex: 'modified',
      width: 100,
      render: (modified) => formatDate(modified),
    },
    {
      id: '22',
      title: '更新者',
      dataIndex: 'modifier',
      width: 150,
    },
    {
      id: '23',
      title: '備考',
      dataIndex: 'info',
      width: isBulkEdit ? remarkWidthEdit : remarkWidth,
      render: (info) => info.remarks,
      onHeaderCell: (column) => ({
        width: column.width,
        onResize: (e, { size }) => {
          if (isBulkEdit) {
            setRemarkWidthEdit(size.width);
          } else {
            setRemarkWidth(size.width);
          }
        },
      }),
    },
    {
      id: '24',
      title: '表示',
      dataIndex: 'info',
      width: 95,
      render: (index, item) => {
        return item.info.isUsed ? (
          <Image
            preview={false}
            width={13}
            src={checked}
            style={{ marginLeft: '3px' }}
            //   key={item['key']}
            id={item.id}
          />
        ) : (
          <></>
        );
      },
      className: 'cm-a-center',
    },
    {
      id: '25',
      title: '',
      dataIndex: 'id',
      hidden: true,
    },
  ]?.filter((item) => !item.hidden);

  // テーブル幅Resizable
  const components = {
    header: {
      cell: ResizableTitle,
    },
  };

  const tableColumns = columns?.map((item) => ({
    ...item,
  }));

  const setSplitPaneSize = (splitSize) => {
    setSizes(splitSize);
  };

  /* const setShowEdtConfirmOK = (edtConfirm)=> {
    setShowEdtConfirmOKLocal(edtConfirm);
  } */

  // 入力項目情報
  const inputFormItem = (
    <RTable id="param_detail_tbl" className="propertiesTbl">
      <thead>
        {/* コード */}
        <tr className="detail-tbl-row">
          <td className="param-detail-tbl-val-title">
            <label className="param_detail-title-label">コード</label>
          </td>
          <td style={{ verticalAlign: 'middle' }}>
            <Input
              className={'input-editable'}
              name="code"
              id="code"
              value={code}
              type="text"
              ref={(ref) => {
                inputItemRef['code'] = ref;
              }}
              maxLength={50}
              onChange={(e) => setCode(e.target.value)}
            />
          </td>
        </tr>
        {/* 枝番 */}
        <tr className="detail-tbl-row">
          <td className="param-detail-tbl-val-title">
            <label className="param_detail-title-label">枝番</label>
          </td>
          <td style={{ verticalAlign: 'middle' }}>
            <Input
              className={'input-editable'}
              name="branchNum"
              id="branchNum"
              value={branchNum}
              type="text"
              ref={(ref) => {
                inputItemRef['branchNum'] = ref;
              }}
              maxLength={Number.MAX_SAFE_INTEGER}
              onChange={(e) => setBranchNum(e.target.value)}
            />
          </td>
        </tr>
        {/* 名称 */}
        <tr className="detail-tbl-row">
          <td className="param-detail-tbl-val-title">
            <label className="param_detail-title-label">名称</label>
          </td>
          <td style={{ verticalAlign: 'middle' }}>
            <Input
              className={'input-editable'}
              name="name"
              id="name"
              value={name}
              type="text"
              ref={(ref) => {
                inputItemRef['name'] = ref;
              }}
              maxLength={150}
              onChange={(e) => setName(e.target.value)}
            />
          </td>
        </tr>
        {/* カナ */}
        <tr className="detail-tbl-row">
          <td className="param-detail-tbl-val-title">
            <label className="param_detail-title-label">カナ</label>
          </td>
          <td style={{ verticalAlign: 'middle' }}>
            <Input
              className={'input-editable'}
              name="nameKana"
              id="nameKana"
              value={nameKana}
              type="text"
              ref={(ref) => {
                inputItemRef['nameKana'] = ref;
              }}
              maxLength={150}
              onChange={(e) => setNameKana(e.target.value)}
            />
          </td>
        </tr>
        {/* お客様 */}
        <tr className="detail-tbl-row">
          <td className="param-detail-tbl-val-title">
            <label className="param_detail-title-label">お客様/係数</label>
          </td>
          <td style={{ verticalAlign: 'middle' }}>
            <table style={{ width: '100%' }}>
              <tr>
                <td
                  style={{
                    width: '40px',
                    height: '44px',
                    padding: '0 7px',
                    textAlign: 'center',
                  }}
                >
                  <Checkbox
                    checked={customerFlg}
                    ref={(ref) => {
                      inputItemRef['customerFlg'] = ref;
                    }}
                    onChange={(e) => setCustomerFlg(e.target.checked)}
                  ></Checkbox>
                </td>
                <td>
                  <Input
                    className={'input-editable'}
                    name="customerCoefficients"
                    id="customerCoefficients"
                    value={customerCoefficients}
                    type="text"
                    ref={(ref) => {
                      inputItemRef['customerCoefficients'] = ref;
                    }}
                    maxLength={Number.MAX_SAFE_INTEGER}
                    onKeyPress={(e) => {
                      handleKeyPress(e, false);
                    }}
                    onChange={(e) => setCustomerCoefficients(e.target.value)}
                  />
                </td>
              </tr>
            </table>
            {/* <div style={{ display: 'flex', padding: '0 5px' }}>
              <Checkbox checked={checked}></Checkbox>
              <Input
                className={'input-editable'}
                name="coefficients"
                id="coefficients"
                value={coefficients}
                type="text"
                ref={(ref) => {
                  inputItemRef['coefficients'] = ref;
                }}
                maxLength={Number.MAX_SAFE_INTEGER}
                onChange={(e) => setCoefficients(e.target.value)}
                onBlur={(e) => setCoefficients(toDecimal(e.target.value))}
              />
            </div> */}
            {/* {customerFlg ? (
              <Image
                preview={false}
                width={13}
                src={checked}
                // style={{ marginLeft: '5px' }}
                id="imgOutsourcingFg"
              />
            ) : (
              <></>
            )} */}
          </td>
        </tr>

        {/* 仕入先 */}
        <tr className="detail-tbl-row">
          <td className="param-detail-tbl-val-title">
            <label className="param_detail-title-label">仕入先/係数</label>
          </td>
          <td style={{ verticalAlign: 'middle' }}>
            <table style={{ width: '100%' }}>
              <tr>
                <td
                  style={{
                    width: '40px',
                    height: '44px',
                    padding: '0 7px',

                    textAlign: 'center',
                  }}
                >
                  <Checkbox
                    checked={supplierFlg}
                    ref={(ref) => {
                      inputItemRef['supplierFlg'] = ref;
                    }}
                    onChange={(e) => setSupplierFlg(e.target.checked)}
                  ></Checkbox>
                </td>
                <td>
                  <Input
                    className={'input-editable'}
                    name="supplierCoefficients"
                    id="supplierCoefficients"
                    value={supplierCoefficients}
                    type="text"
                    ref={(ref) => {
                      inputItemRef['supplierCoefficients'] = ref;
                    }}
                    maxLength={Number.MAX_SAFE_INTEGER}
                    onChange={(e) => setSupplierCoefficients(e.target.value)}
                    onKeyPress={(e) => {
                      handleKeyPress(e, false);
                    }}
                  />
                </td>
              </tr>
            </table>
            {/* {supplierFlg ? (
              <Image
                preview={false}
                width={13}
                src={checked}
                // style={{ marginLeft: '5px' }}
                id="imgOutsourcingFg"
              />
            ) : (
              <></>
            )} */}
          </td>
        </tr>

        {/* 委託先 */}
        <tr className="detail-tbl-row">
          <td className="param-detail-tbl-val-title">
            <label className="param_detail-title-label">委託先/係数</label>
          </td>
          <td style={{ verticalAlign: 'middle' }}>
            <table style={{ width: '100%' }}>
              <tr>
                <td
                  style={{
                    width: '40px',
                    height: '44px',
                    padding: '0 7px',

                    textAlign: 'center',
                  }}
                >
                  <Checkbox
                    checked={contractorFlg}
                    ref={(ref) => {
                      inputItemRef['contractorFlg'] = ref;
                    }}
                    onChange={(e) => setContractorFlg(e.target.checked)}
                  ></Checkbox>
                </td>
                <td>
                  <Input
                    className={'input-editable'}
                    name="contractorCoefficients"
                    id="contractorCoefficients"
                    value={contractorCoefficients}
                    type="text"
                    ref={(ref) => {
                      inputItemRef['contractorCoefficients'] = ref;
                    }}
                    maxLength={Number.MAX_SAFE_INTEGER}
                    onChange={(e) => setContractorCoefficients(e.target.value)}
                    onKeyPress={(e) => {
                      handleKeyPress(e, false);
                    }}
                  />
                </td>
              </tr>
            </table>
            {/* {contractorFlg ? (
              <Image
                preview={false}
                width={13}
                src={checked}
                // style={{ marginLeft: '5px' }}
                id="imgOutsourcingFg"
              />
            ) : (
              <></>
            )} */}
          </td>
        </tr>

        {/* Tel */}
        <tr className="detail-tbl-row">
          <td className="param-detail-tbl-val-title">
            <label className="param_detail-title-label">Tel</label>
          </td>
          <td style={{ verticalAlign: 'middle' }}>
            <Input
              className={'input-editable'}
              name="tel"
              id="tel"
              value={tel}
              type="text"
              ref={(ref) => {
                inputItemRef['tel'] = ref;
              }}
              maxLength={20}
              onChange={(e) => setTel(e.target.value)}
            />
          </td>
        </tr>
        {/* Fax */}
        <tr className="detail-tbl-row">
          <td className="param-detail-tbl-val-title">
            <label className="param_detail-title-label">Fax</label>
          </td>
          <td style={{ verticalAlign: 'middle' }}>
            <Input
              className={'input-editable'}
              name="fax"
              id="fax"
              value={fax}
              type="text"
              ref={(ref) => {
                inputItemRef['fax'] = ref;
              }}
              maxLength={20}
              onChange={(e) => setFax(e.target.value)}
            />
          </td>
        </tr>
        {/* 担当者 */}
        <tr className="detail-tbl-row">
          <td className="param-detail-tbl-val-title">
            <label className="param_detail-title-label">担当者</label>
          </td>
          <td style={{ verticalAlign: 'middle' }}>
            <Input
              className={'input-editable'}
              name="contactParson"
              id="contactParson"
              value={contactParson}
              type="text"
              ref={(ref) => {
                inputItemRef['contactParson'] = ref;
              }}
              maxLength={50}
              onChange={(e) => setContactParson(e.target.value)}
            />
          </td>
        </tr>
        {/* メール */}
        <tr className="detail-tbl-row">
          <td className="param-detail-tbl-val-title">
            <label className="param_detail-title-label">メール</label>
          </td>
          <td style={{ verticalAlign: 'middle' }}>
            <Input
              className={'input-editable'}
              name="mail"
              id="mail"
              value={mail}
              type="text"
              ref={(ref) => {
                inputItemRef['mail'] = ref;
              }}
              maxLength={100}
              onChange={(e) => setMail(e.target.value)}
            />
          </td>
        </tr>
        {/* 郵便番号 */}
        <tr className="detail-tbl-row">
          <td className="param-detail-tbl-val-title">
            <label className="param_detail-title-label">郵便番号</label>
          </td>
          <td style={{ verticalAlign: 'middle' }}>
            <Input
              className={'input-editable'}
              name="zip"
              id="zip"
              value={zip}
              type="text"
              ref={(ref) => {
                inputItemRef['zip'] = ref;
              }}
              maxLength={10}
              onChange={(e) => setZip(e.target.value)}
            />
          </td>
        </tr>
        {/* 都道府県 */}
        <tr className="detail-tbl-row">
          <td className="param-detail-tbl-val-title">
            <label className="param_detail-title-label">都道府県</label>
          </td>
          <td style={{ verticalAlign: 'middle' }}>
            <Input
              className={'input-editable'}
              name="prefecture"
              id="prefecture"
              value={prefecture}
              type="text"
              ref={(ref) => {
                inputItemRef['prefecture'] = ref;
              }}
              maxLength={10}
              onChange={(e) => setPrefecture(e.target.value)}
            />
          </td>
        </tr>
        {/* 住所 */}
        <tr className="detail-tbl-row">
          <td className="param-detail-tbl-val-title">
            <label className="param_detail-title-label">住所</label>
          </td>
          <td style={{ verticalAlign: 'middle' }}>
            <Input
              className={'input-editable'}
              name="address"
              id="address"
              value={address}
              type="text"
              ref={(ref) => {
                inputItemRef['address'] = ref;
              }}
              maxLength={250}
              onChange={(e) => setAddress(e.target.value)}
            />
          </td>
        </tr>
        {/* ソート番号 */}
        {/* <tr className="detail-tbl-row">
          <td className="param-detail-tbl-val-title">
            <label className="param_detail-title-label">ソート番号</label>
          </td>
          <td style={{ verticalAlign: 'middle' }}>
            <Input
              className={'input-editable'}
              name="sortNum"
              id="sortNum"
              value={sortNum}
              type="text"
              ref={(ref) => {
                inputItemRef['sortNum'] = ref;
              }}
              maxLength={Number.MAX_SAFE_INTEGER}
              onChange={(e) => setSortNum(e.target.value)}
            />
          </td>
        </tr> */}
        {/* 作成日 */}
        {/* 更新日 */}
        {/* 更新者 */}
        {isBulkEdit ? (
          ''
        ) : (
          <>
            <tr className="detail-tbl-row">
              <td className="param-detail-tbl-val-title">
                <label className="param_detail-title-label">作成日</label>
              </td>
              <td style={{ verticalAlign: 'middle' }}>
                <label className="param_detail-title-label">{created}</label>
              </td>
            </tr>
            <tr className="detail-tbl-row">
              <td className="param-detail-tbl-val-title">
                <label className="param_detail-title-label">更新日</label>
              </td>
              <td style={{ verticalAlign: 'middle' }}>
                <label className="param_detail-title-label">{modified}</label>
              </td>
            </tr>
            <tr className="detail-tbl-row">
              <td className="param-detail-tbl-val-title">
                <label className="param_detail-title-label">更新者</label>
              </td>
              <td style={{ verticalAlign: 'middle' }}>
                <label className="param_detail-title-label">{modifier}</label>
              </td>
            </tr>
          </>
        )}

        {/* 備考 */}
        <tr className="detail-tbl-row">
          <td className="param-detail-tbl-val-title">
            <label className="param_detail-title-label">備考</label>
          </td>
          <td style={{ verticalAlign: 'middle' }}>
            <TextArea
              className={'input-editable'}
              name="remarks"
              id="remarks"
              value={remarks}
              type="text"
              maxLength={2048}
              style={{ margin: 5, padding: 5, width: '98.3%' }}
              onChange={(e) => setRemarks(e.target.value)}
            />
          </td>
        </tr>

        {/* 表示 */}
        <tr className="detail-tbl-row">
          <td className="param-detail-tbl-val-title">
            <label className="param_detail-title-label">表示</label>
          </td>
          <td style={{ verticalAlign: 'middle' }}>
            <RadioGroup
              name="isUsed"
              defaultValue={1}
              className="radioCustomerGpEdit"
              onChange={(e) => setIsUsed(e.target.value)}
              value={isUsed}
            >
              <Radio value={true}>オン</Radio>
              <Radio value={false}>オフ</Radio>
            </RadioGroup>
          </td>
        </tr>
      </thead>
    </RTable>
  );

  const getPartName = (partName = activePartMenu) => {
    return partName === partType[0] ? 'お客様' : '仕入先';
  };

  const getSelectedRowId = (partName = activePartMenu) => {
    return partName === partType[0] ? cusSelectedRowId : supSelectedRowId;
  };

  const setSelectedRowId = (value, partName = activePartMenu) => {
    partName === partType[0] ? setCusSelectedRowId(value) : setSupSelectedRowId(value);
  };

  const getCurrentData = (partName = activePartMenu) => {
    return partName === partType[0] ? [...customersData] : [...supplierData];
  };

  const setCurrentData = (value, partName = activePartMenu) => {
    partName === partType[0] ? setCustomersData(value) : setSupplierData(value);
  };

  const getCurrentPage = (partName = activePartMenu) => {
    return partName === partType[0] ? cusCurrentPage : supCurrentPage;
  };

  // const setCurrentPage = (value, partName = activePartMenu) => {
  //   partName === partType[0] ? setCusCurrentPage(value) : setSupCurrentPage(value);
  // };

  const setCurrentPage = (value) => {
    setCusCurrentPage(value);
  };

  // 選択中行のデータを取得する
  const getSelectedData = (data, id) => {
    let ret = [];
    let record;
    record = data?.find((item) => item.id === id);
    return record ? record : ret;
  };

  // 最大ソート番号を得る
  const getMaxSortNum = () => {
    let sortedList = getCurrentData().sort((a, b) => b.info.sortNum - a.info.sortNum);
    return sortedList?.length > 0 ? sortedList[0].info.sortNum + 1 : 1;
  };

  // Idで降順する
  const descOrderById = (data) => {
    let sortedList = data.sort((a, b) => b.id - a.id);
    return sortedList;
  };

  // 一覧画面の行を選択した場合、
  const onRowSelect = (record) => {
    let selectedCustomersData = [];
    if (record !== undefined) {
      selectedCustomersData.push({
        id: record.id,
        code: record.code,
        name: record.name,
        clientType: record.clientType,
        coeffClients: record.coeffClients,
        info: record.info,
        created: record.created,
        modified: record.modified,
        modifier: record.modifier,
      });
      setChangeRecordId(record.id);
      if (editMode) {
        setDiscardChangeMode(discardChange[0]);
        let moveInEdit = props.showConfirmMsgs.moveInEdit == undefined ? true : props.showConfirmMsgs.moveInEdit;
        setEditModeCancelConfirm(moveInEdit);
        if (!moveInEdit) {
          setSelectedRowId(record.id);
          setSelectedData(selectedCustomersData[0]);
          navigate(':common.customer');
        }
      } else {
        setSelectedRowId(record.id);
        setSelectedData(selectedCustomersData[0]);
        navigate(':common.customer');
      }
    } else {
      setSelectedRowId(-1);
      setSelectedData([]);
      navigate(':common.customer');
    }
  };

  const onRowSelectWithoutEditMode = (record) => {
    let selectedCustomersData = [];
    if (record !== undefined) {
      selectedCustomersData.push({
        id: record.id,
        code: record.code,
        name: record.name,
        clientType: record.clientType,
        coeffClients: record.coeffClients,
        info: record.info,
        created: record.created,
        modified: record.modified,
        modifier: record.modifier,
      });
      setChangeRecordId(record.id);
      setSelectedRowId(record.id);
      setSelectedData(selectedCustomersData[0]);
      const customerLst = [...filteredData];
      if (customerLst?.length > 0) {
        setCusCurrentPage(Math.ceil(customerLst?.length / 25));
      } else {
        setCusCurrentPage(1);
      }
      navigate(':common.customer');
    } else {
      setSelectedRowId(-1);
      setSelectedData([]);
      navigate(':common.customer');
    }
  };

  const handleOkCSVInput = (e) => {
    setVisibleCSVInput(false);
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = '.csv';
    input.onchange = (_) => {
      let files = Array.from(input.files);
    };
    input.click();
  };

  const handleOkXMLInput = (e) => {
    setVisibleXMLInput(false);
    let input = document.createElement('input');
    input.type = 'file';
    input.accept = '.xml';
    input.onchange = (_) => {
      let files = Array.from(input.files);
    };
    input.click();
  };

  // 削除ボタンを押下
  const deleteProcess = () => {
    let moveInEdit = props.showConfirmMsgs.moveInEdit == undefined ? true : props.showConfirmMsgs.moveInEdit;
    setEditModeCancelConfirm(moveInEdit);
    if (!moveInEdit) {
      setIsDelete(true);
      // deleteOk();
    } else {
      setIsDelete(true);
    }
  };

  // 削除確認ポップアップのOKボタンを押下
  const deleteOk = async (e) => {
    if (isDelete) {
      setIsDelete(false);
      setEditMode(false);
      let selectedId = getSelectedRowId();
      let deletedData = await deleteClientData(selectedId);

      let filterdData = [...filteredData];
      filterdData = filterdData?.filter((item) => item.id !== deletedData.id);
      setFilteredData(filterdData);

      let currentData = getCurrentData();
      currentData = currentData?.filter((item) => item.id !== deletedData.id);
      onRowSelectWithoutEditMode(
        currentData !== undefined && currentData?.length > 0 ? currentData[currentData?.length - 1] : []
      );
      setCurrentPage(1);
      setCurrentData(currentData);
      setTmpFilteredData(currentData);
    }
  };

  // 取引先情報をDBに削除する
  async function deleteClientData(id) {
    let ret = [];
    try {
      const Url = process.env.REACT_APP_BACKEND_API_URL + 'Client/' + id;
      const otherParam = {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + getAccessToken(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          return await response.json();
        })
        .then((data) => {
          if (data && data?.updateToken) {
            // セクションにトークン情報を更新する
            updateAccessToken(data?.updateToken);
          }
          if (data && data.statusCode === StatusCodes.OK) {
            ret = data.data[0];
          } else if (
            data &&
            (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)
          ) {
            authorizeError(ErrorMessage()[data?.messageCode]);
            return;
          } else if (data && data.statusCode !== StatusCodes.OK) {
            error(ErrorMessage()[data?.messageCode]);
            return;
          }
        })
        .catch((e) => {
          authorizeError(ErrorMessage().E010);
          return;
        });
    } catch (e) {
      error(e.message);
      return;
    }
    return ret;
  }

  // 削除確認ポップアップのCandelボタンを押下
  const deleteCancel = (e) => {
    setIsDelete(false);
  };

  const showCSVInputModal = () => {
    setVisibleCSVInput(true);
    setChildTitle('CSV入力');
  };

  const showXMLInputModal = () => {
    setVisibleXMLInput(true);
    setChildTitle('XML入力');
  };

  const resetInputInfo = () => {
    setCode('');
    setName('');
    setCustomerFlg(false);
    setSupplierFlg(false);
    setContractorFlg(false);
    setCustomerCoefficients('');
    setSupplierCoefficients('');
    setContractorCoefficients('');
    setIsUsed();
    setBranchNum();
    setNameKana('');
    setTel('');
    setFax('');
    setContactParson('');
    setMail('');
    setZip('');
    setPrefecture('');
    setAddress('');
    setRemarks('');
    setSortNum();
    setCreated(today);
    setModified(today);
    setModifier('');
  };

  // 表示で検索する場合、
  const searchByDataState = (value) => {
    dataState === value ? getDataByFilter() : setDataState(value);
  };

  // お客様で検索する場合、
  const searchByCustomerFlg = (value) => {
    customerFlgState === value ? getDataByFilter() : setCustomerFlgState(value);
  };

  // 仕入先で検索する場合、
  const searchBySupplierFlg = (value) => {
    supplierFlgState === value ? getDataByFilter() : setSupplierFlgState(value);
  };

  // 委託先で検索する場合、
  const searchByContractorFlg = (value) => {
    contractorFlgState === value ? getDataByFilter() : setContractorFlgState(value);
  };

  // キーワードで検索する場合、
  const searchByKeyword = (e) => {
    keyword === e.target.value ? getDataByFilter() : setKeyWord(e.target.value);
  };

  // 一覧データをキーワードで絞り込む
  const getDataByFilter = (partName = activePartMenu) => {
    let currentData = getCurrentData(partName);
    if (currentData?.length <= 0) {
      return;
    }
    let searchBarText = keyword;
    let flag;
    let currentFilterData = currentData;
    // キーワードで絞り込み
    if (searchBarText != '') {
      currentFilterData = currentFilterData?.filter(
        (item) =>
          item.code?.toLowerCase().indexOf(searchBarText) >= 0 ||
          item.name?.toLowerCase().indexOf(searchBarText) >= 0 ||
          item.coeffClients?.map((i) => i.coeff.toString().indexOf(searchBarText)) >= 0 ||
          item.info.branchNum?.toString().indexOf(searchBarText) >= 0 ||
          item.info.nameKana?.toLowerCase().indexOf(searchBarText) >= 0 ||
          item.info.tel?.toString().indexOf(searchBarText) >= 0 ||
          item.info.fax?.toLowerCase().indexOf(searchBarText) >= 0 ||
          item.info.contactParson?.toLowerCase().indexOf(searchBarText) >= 0 ||
          item.info.mail?.toLowerCase().indexOf(searchBarText) >= 0 ||
          item.info.zip?.toLowerCase().indexOf(searchBarText) >= 0 ||
          item.info.prefecture?.toLowerCase().indexOf(searchBarText) >= 0 ||
          item.info.address?.toLowerCase().indexOf(searchBarText) >= 0 ||
          item.info.remarks?.toLowerCase().indexOf(searchBarText) >= 0 ||
          item.info.sortNum?.toString().indexOf(searchBarText) >= 0 ||
          item.created?.toLowerCase().indexOf(searchBarText) >= 0 ||
          item.modified?.toLowerCase().indexOf(searchBarText) >= 0 ||
          item.modifier?.toLowerCase().indexOf(searchBarText) >= 0
      );
    }

    // //  表示で絞り込み
    if (dataState === 1 || dataState === 2) {
      dataState === 1 ? (flag = true) : (flag = false);
      currentFilterData = currentFilterData?.filter((item) => item.info.isUsed === flag);
    }

    //  お客様で絞り込み
    if (customerFlgState === 1 || customerFlgState === 2) {
      currentFilterData =
        customerFlgState === 1
          ? currentFilterData?.filter((item) => isCustomer(item.clientType))
          : currentFilterData?.filter((item) => !isCustomer(item.clientType));
    }

    //  仕入先で絞り込み
    if (supplierFlgState === 1 || supplierFlgState === 2) {
      currentFilterData =
        supplierFlgState === 1
          ? currentFilterData?.filter((item) => isSupplier(item.clientType))
          : currentFilterData?.filter((item) => !isSupplier(item.clientType));
    }

    //  委託先で絞り込み
    if (contractorFlgState === 1 || contractorFlgState === 2) {
      currentFilterData =
        contractorFlgState === 1
          ? currentFilterData?.filter((item) => isContractor(item.clientType))
          : currentFilterData?.filter((item) => !isContractor(item.clientType));
    }

    /* let selectedRowId = getSelectedRowId(partName);
    let index = currentFilterData?.findIndex((item) => item.id === selectedRowId);
    // 検索した結果に、選択中行のIdが無い場合、
    if (index === -1) {
      // 編集中である場合、
      if (editMode) {
        setDiscardChangeMode(discardChange[1]);
        setEditModeCancelConfirm(true);
        return;
      }
      // 検索結果がある場合、
      if (currentFilterData?.length > 0) {
        index = 0;
        let idVal = currentFilterData[index]?.id;
        setSelectedRowId(idVal, partName);
      }
    }
    currentFilterData?.length > 0
      ? setSelectedData(currentFilterData[index]) // 検索結果がある場合、
      : setSelectedData(currentFilterData); // 検索結果が無い場合、
    // 一覧に表示するデータ */
    setFilteredData(currentFilterData);
  };

  const reportModalTitle = (
    <div
      style={{
        width: 520,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>確認</p>
    </div>
  );

  const editModalTitle = (
    <div
      style={{
        width: 1300,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{childTitle}</p>
    </div>
  );

  const addNewModalTitle = (
    <div
      style={{
        width: 700,
        backgroundColor: '#005fab',
        marginLeft: -10,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{childTitle}</p>
    </div>
  );

  // 確認ポップアップのOKボタン押下
  const confirmOk = async (e) => {
    if (isCreateNew) {
      setCreateConfirm(false);
      // 画面の入力チェック
      if (!detailRef.current.formValidation(inputItemRef, formValidatorMode.Create)) {
        return;
      }
      let lastIndex = tempCustomersData?.length - 1;
      let tempData = tempCustomersData[lastIndex];
      let createdData = await createClientData(tempData);
      setCurrentData((prevData) => descOrderById([...prevData, createdData]));
      setTmpFilteredData(createdData);
      setFilteredData((filteredData) => descOrderById([...filteredData, createdData]));
      onRowSelectWithoutEditMode(createdData);
      setIsCreateNew(false);
    } else if (isBulkEdit) {
      setUpdateConfirm(false);
      // 画面の入力チェック
      if (!detailRef.current.formValidation(inputItemRef, formValidatorMode.BulkEdit)) {
        return;
      }
      // 一括編集画面から遷移された場合、
      setIsBulkEdit(false);
      // 選択された全ての対象行を更新、
      for (let i = 0; i < bulkEditSelectedRow?.length; i++) {
        let item = bulkEditSelectedRow[i];
        let clientType = 0;
        if (name) item.name = name;
        if (code) item.code = code;
        if (customerFlg === true || isCustomer(item.clientType)) {
          clientType = ClientType.Customer;
        }
        if (customerFlg === true) {
          let index = item.coeffClients?.findIndex((record) => record.clientType === ClientType.Customer);
          if (index !== -1) {
            item.coeffClients[index].coeff = customerCoefficients;
          } else {
            item.coeffClients.push({
              clientType: ClientType.Customer,
              coeff: customerCoefficients ? parseFloat(customerCoefficients) : 0.0,
            });
          }
        }
        if (supplierFlg === true || isSupplier(item.clientType)) {
          clientType = clientType + ClientType.Supplier;
        }
        if (supplierFlg === true) {
          let index = item.coeffClients?.findIndex((record) => record.clientType === ClientType.Supplier);
          if (index !== -1) {
            item.coeffClients[index].coeff = supplierCoefficients;
          } else {
            item.coeffClients.push({
              clientType: ClientType.Supplier,
              coeff: supplierCoefficients ? parseFloat(supplierCoefficients) : 0.0,
            });
          }
        }
        if (contractorFlg === true || isContractor(item.clientType)) {
          clientType = clientType + ClientType.Contractor;
        }
        if (contractorFlg === true) {
          let index = item.coeffClients?.findIndex((record) => record.clientType === ClientType.Contractor);
          if (index !== -1) {
            item.coeffClients[index].coeff = contractorCoefficients;
          } else {
            item.coeffClients.push({
              clientType: ClientType.Contractor,
              coeff: contractorCoefficients ? parseFloat(contractorCoefficients) : 0.0,
            });
          }
        }
        if (clientType !== 0) item.clientType = clientType;
        if (isUsed === true || isUsed === false) item.info.isUsed = isUsed;
        if (branchNum) item.info.branchNum = branchNum;
        if (nameKana) item.info.nameKana = nameKana;
        if (tel) item.info.tel = tel;
        if (fax) item.info.fax = fax;
        if (contactParson) item.info.contactParson = contactParson;
        if (mail) item.info.mail = mail;
        if (zip) item.info.zip = zip;
        if (prefecture) item.info.prefecture = prefecture;
        if (address) item.info.address = address;
        if (remarks) item.info.remarks = remarks;
        if (sortNum) item.info.sortNum = sortNum;
        item.modified = today;
        await submitData(item);
      }
    }
    resetInputInfo();
  };

  // 取引先情報をDBに新規登録する
  async function createClientData(createData) {
    let ret = [];
    try {
      const Url = process.env.REACT_APP_BACKEND_API_URL + 'Client';
      const otherParam = {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + getAccessToken(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
        body: JSON.stringify({
          code: createData.code,
          name: createData.name,
          clientType: createData.clientType,
          coeffClients: createData.coeffClients,
          info: createData.info,
        }),
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          return await response.json();
        })
        .then((data) => {
          if (data && data?.updateToken) {
            // セクションにトークン情報を更新する
            updateAccessToken(data?.updateToken);
          }
          if (data && data.statusCode === StatusCodes.OK) {
            ret = data.data[0];
          } else if (
            data &&
            (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)
          ) {
            authorizeError(ErrorMessage()[data?.messageCode]);
            return;
          } else if (data && data.statusCode !== StatusCodes.OK) {
            error(ErrorMessage()[data?.messageCode]);
            return;
          }
        })
        .catch((e) => {
          authorizeError(ErrorMessage().E010);
          return;
        });
    } catch (e) {
      error(e.message);
      return;
    }
    return ret;
  }

  // 確認ポップアップのCancelボタン押下
  const confirmCancel = () => {
    setUpdateConfirm(false);
    setCreateConfirm(false);
  };

  // 編集中破壊確認ポップアップのOKボタン押下
  const discardConfirmOk = () => {
    setEditModeCancelConfirm(false);
    detailRef.current.discardConfirmOk();
  };

  // 編集中破壊確認ポップアップのCancelボタン押下
  const discardConfirmCancel = () => {
    /* setEditModeCancelConfirm(showEdtConfirmCancle);
    if (!showEdtConfirmCancle) {
      detailRef.current.discardConfirmCancel();
    } */
    setEditModeCancelConfirm(false);
    detailRef.current.discardConfirmCancel();
  };

  // 破壊確認ポップアップのOKボタン押下
  const cancelConfirmOK = () => {
    if (isCreateNew) {
      setIsCreateNew(false);
    } else if (isBulkEdit) {
      setIsBulkEdit(false);
    }
    setCancelConfirm(false);
  };

  // 破壊確認ポップアップのCancelボタン押下
  const cancelConfirmCancel = () => {
    setCancelConfirm(false);
  };

  // お客様・仕入先タブの切り替え
  const partMenuChange = (e) => {
    setChangePartName(e.target.value);
    if (editMode) {
      setDiscardChangeMode(discardChangePart[0]);
      setEditModeCancelConfirm(true);
      return;
    }
    if (keyword != '' || dataState != 0 || customerFlgState != 0 || supplierFlgState != 0 || contractorFlgState != 0) {
      getDataByFilter(e.target.value);
    } else {
      var currentData = getCurrentData(e.target.value);
      var rowId = getSelectedRowId(e.target.value);
      var selectedData = getSelectedData(currentData, rowId);
      setSelectedData(selectedData);
      setFilteredData(currentData);
    }
    //setActivePartMenu(e.target.value);
  };

  // 更新確認のOKを押下で、一覧画面のデータを反映する
  const submitData = async (updateData) => {
    var tmpData = [];
    var updatedInfo = [];
    var index;
    let selectedRowId = getSelectedRowId();

    updatedInfo = await updateClientData(updateData);
    tmpData = getCurrentData();
    index = tmpData?.findIndex((item) => item.id === updatedInfo.id);
    tmpData[index] = updatedInfo;
    setCurrentData(tmpData);
    setTmpFilteredData(tmpData);

    tmpData = [...filteredData];
    index = tmpData?.findIndex((item) => item.id === updatedInfo.id);
    tmpData[index] = updatedInfo;
    setFilteredData(tmpData);

    // 詳細情報を再表示
    if (updatedInfo.id === selectedRowId) detailRef.current?.displayDetailInfo(updatedInfo);
  };

  // 取引先情報をDBに更新する
  async function updateClientData(updateData) {
    let ret = [];
    try {
      const Url = process.env.REACT_APP_BACKEND_API_URL + 'Client';
      const otherParam = {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + getAccessToken(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
        body: JSON.stringify({
          id: updateData.id,
          code: updateData.code,
          name: updateData.name,
          clientType: updateData.clientType,
          coeffClients: updateData.coeffClients,
          info: updateData.info,
        }),
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          return await response.json();
        })
        .then((data) => {
          if (data && data?.updateToken) {
            // セクションにトークン情報を更新する
            updateAccessToken(data?.updateToken);
          }
          if (data && data.statusCode === StatusCodes.OK) {
            ret = data.data[0];
          } else if (
            data &&
            (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)
          ) {
            authorizeError(ErrorMessage()[data?.messageCode]);
            return;
          } else if (data && data.statusCode !== StatusCodes.OK) {
            error(ErrorMessage()[data?.messageCode]);
            return;
          }
        })
        .catch((e) => {
          authorizeError(ErrorMessage().E010);
          return;
        });
    } catch (e) {
      error(e.message);
      return;
    }
    return ret;
  }

  // 編集モードを変更
  const updateEditMode = (mode) => {
    setEditMode(mode);
    props.updateMainParameterEditMode(mode);
  };

  // 新規作成-開始
  const createNewProcess = () => {
    resetInputInfo();
    setIsCreateNew(true);
    setIsUsed(true);
    setCustomerFlg(true);
    setSupplierFlg(true);
    setContractorFlg(true);
    setCustomerCoefficients((1).toFixed(2));
    setSupplierCoefficients((1).toFixed(2));
    setContractorCoefficients((1).toFixed(2));
    setSortNum(getMaxSortNum());
    setChildTitle(getPartName() + '（新規作成）');
  };

  const createNewOK = () => {
    prepareCreateNewInfo();
  };

  const createNewCancel = (e) => {
    //setCancelConfirm(true);
    setCancelConfirm(showEdtConfirmCancle);
    if (!showEdtConfirmCancle && isCreateNew) {
      setIsCreateNew(false);
    }
  };

  // 入力した新規情報を取得する
  const prepareCreateNewInfo = (e) => {
    let tempCustomersData = [];
    let info = {
      isUsed: isUsed,
      branchNum: branchNum,
      nameKana: nameKana,
      tel: tel,
      fax: fax,
      contactParson: contactParson,
      mail: mail,
      zip: zip,
      prefecture: prefecture,
      address: address,
      remarks: remarks,
      sortNum: sortNum,
    };
    let clientType = 0;
    let coeffs = [];
    if (customerFlg === true) {
      clientType = ClientType.Customer;
      coeffs.push({
        clientType: ClientType.Customer,
        coeff: customerCoefficients ? parseFloat(customerCoefficients) : 0.0,
      });
    }
    if (supplierFlg === true) {
      clientType = clientType + ClientType.Supplier;
      coeffs.push({
        clientType: ClientType.Supplier,
        coeff: supplierCoefficients ? parseFloat(supplierCoefficients) : 0.0,
      });
    }
    if (contractorFlg === true) {
      clientType = clientType + ClientType.Contractor;
      coeffs.push({
        clientType: ClientType.Contractor,
        coeff: contractorCoefficients ? parseFloat(contractorCoefficients) : 0.0,
      });
    }
    tempCustomersData.push({
      code: code,
      name: name,
      clientType: clientType,
      coeffClients: coeffs,
      info: info,
      created: today,
      modified: today,
    });
    setTempCustomersData(tempCustomersData);
    setCreateConfirm(true);
  };

  const createNewTitle = (
    <div
      style={{
        width: 800,
        backgroundColor: '#005fab',
        marginLeft: -10,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{childTitle}</p>
    </div>
  );

  const createNewContent = (
    <>
      <Row style={{ paddingBottom: 0, paddingTop: 0, marginBottom: '10px' }}>
        {/** プロパティレイアウト */}
        <Col span={24}>
          <div
            style={{
              height: 750,
              // marginTop: 28,
              fontSize: '13.5px',
              overflowY: 'auto',
              whiteSpace: 'nowrap',
            }}
          >
            {inputFormItem}
          </div>
        </Col>
      </Row>
      <Row
        justify="end"
        style={{
          marginBottom: 0,
          marginTop: 0,
        }}
      >
        <Button className="mainButton" onClick={createNewOK} style={{ marginRight: 5 }}>
          登録
        </Button>
        <Button className="cancelButton" onClick={createNewCancel}>
          破棄
        </Button>
      </Row>
    </>
  );
  // 新規作成-終了

  // 一括編集-開始
  const onRowCheckboxSelect = (checked, record) => {
    checkedRow = [...checked];
    setBulkEditSelectedRow([...record]);
    setBulkEditSelectedRowId(checkedRow);
    setChkRowsCount(checkedRow?.length);
    navigate(':common.customer');
  };

  const bulkEditRowSelect = {
    onChange: onRowCheckboxSelect,
  };

  const bulkEditProcess = () => {
    resetInputInfo();
    setBulkEditSelectedRow([]);
    setBulkEditSelectedRowId([]);
    setChkRowsCount(0);
    setIsBulkEdit(true);
    setChildTitle(getPartName() + '（選択したデータをまとめて編集）');
  };

  const bulkEditOK = (e) => {
    setUpdateConfirm(true);
  };

  const bulkEditCancel = (e) => {
    setCancelConfirm(true);
  };

  const bulkEditTitle = (
    <div
      style={{
        width: 1790,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{childTitle}</p>
    </div>
  );

  const bulkEditContent = (
    <>
      <Row style={{ paddingBottom: 0, paddingTop: 0 }}>
        {/** 一覧 */}
        <Col span={13}>
          <p style={{ fontSize: '13.5px', marginBottom: 6, marginTop: 0, fontWeight: '700' }}>
            {chkrowscount}件を選択中
          </p>
          <Table
            showSorterTooltip={false}
            components={components}
            columns={tableColumns}
            rowKey={(record) => record.id}
            dataSource={filteredData}
            scroll={{ y: 250, x: '10vw' }}
            rowClassName={(record) => (record.id == bulkEditSelectedRowId ? 'active-row' : 'data-row')}
            className="parent-detail-EditTable"
            rowSelection={bulkEditRowSelect}
            pagination={{
              position: ['bottomLeft'],
              total: (record) => record?.length,
              showTotal: (total, range) => `全${total}件中${range[0]}件〜${range[1]}件を表示`,
              defaultPageSize: 25,
              defaultCurrent: 1,
              pageSize: 25,
              current: cusCurrentPage,
              onChange: (page) => {
                setCurrentPage(page);
              },
            }}
          />
        </Col>
        {/** プロパティレイアウト */}
        <Col span={11}>
          <div
            style={{
              height: 749,
              marginTop: 28,
              fontSize: '13.5px',
              overflowY: 'auto',
              whiteSpace: 'nowrap',
            }}
          >
            {inputFormItem}
          </div>
        </Col>
      </Row>
      <Row
        justify="end"
        style={{
          marginBottom: 0,
          marginTop: -40,
        }}
      >
        <Button className="mainButton" onClick={bulkEditOK} style={{ marginRight: 5 }}>
          更新
        </Button>
        <Button className="cancelButton" onClick={bulkEditCancel}>
          破棄
        </Button>
      </Row>
    </>
  );
  // 一括編集-終了

  // 検索条件をクリアボタン
  const filterClearBtn = (
    <>
      <div style={{ display: 'flex' }}>
        <Image preview={false} width={18} height={18} src={filter_clear_icon} style={{ marginBottom: '5px' }} />
        <label style={{ marginLeft: '5px', marginTop: 1, fontSize: '12px' }}>検索条件をクリア</label>
      </div>
    </>
  );

  // 検索条件を初期値にする
  const clearAllFilter = (e) => {
    setDataState(0);
    setCustomerFlgState(0);
    setSupplierFlgState(0);
    setContractorFlgState(0);
    setKeyWord('');
  };

  return (
    <>
      {/* 一覧とプロパティ表示 */}
      <div
        style={{
          height: '90.8vh',
          marginLeft: 10,
        }}
      >
        <SplitPane split="vertical" sizes={sizes} onChange={setSizes} resizerSize={1}>
          <div style={{ marginTop: 7 }}>
            {/* 検索アリア */}
            <Space size="middle">
              <div>
                <label>お客様：</label>
                <Select
                  style={{ width: 90 }}
                  onChange={searchByCustomerFlg}
                  id="customerflgStateDrop"
                  name="customerflgStateDrop"
                  value={customerFlgState}
                >
                  {flgStateList?.map((item, index) => (
                    <Select.Option key={index} id={'customerFlgState' + item.value} value={item.value}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div>
                <label>仕入先：</label>
                <Select
                  style={{ width: 90 }}
                  onChange={searchBySupplierFlg}
                  id="supplierflgStateDrop"
                  name="supplierflgStateDrop"
                  value={supplierFlgState}
                >
                  {flgStateList?.map((item, index) => (
                    <Select.Option key={index} id={'supplierFlgState' + item.value} value={item.value}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div>
                <label>委託先：</label>
                <Select
                  className="search-select"
                  onChange={searchByContractorFlg}
                  id="contractorFlgStateDrop"
                  name="contractorFlgStateDrop"
                  value={contractorFlgState}
                >
                  {flgStateList?.map((item, index) => (
                    <Select.Option key={index} id={'contractorFlgState' + item.value} value={item.value}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <Input
                className="input-editable search-select-input"
                id="keyword"
                name="keyword"
                placeholder="キーワードを入力"
                allowClear
                onPressEnter={searchByKeyword}
                onBlur={searchByKeyword}
                value={keyword}
                onChange={searchByKeyword}
              />
              <div>
                <label>表示：</label>
                <Select
                  style={{ width: 90 }}
                  onChange={searchByDataState}
                  id="dataStateDrop"
                  name="dataStateDrop"
                  value={dataState}
                >
                  {dataStateList?.map((item, index) => (
                    <Select.Option key={index} id={'dataState' + item.value} value={item.value}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <Button style={{ marginLeft: 15 }} className="filterClearButton" onClick={clearAllFilter}>
                {filterClearBtn}
              </Button>
            </Space>

            {/* 一覧アリア */}
            <Row style={{ marginTop: 10 }}>
              <Table
                showSorterTooltip={false}
                columns={hasCheckData ? tableColumns : chkData}
                components={components}
                dataSource={filteredData}
                scroll={{ y: 590, x: '10vw' }}
                rowClassName={(record) => (record.id == getSelectedRowId() ? 'active-row' : 'data-row')}
                pagination={{
                  position: ['bottomLeft'],
                  total: (record) => record?.length,
                  showTotal: (total, range) => `全${total}件中${range[0]}件〜${range[1]}件を表示`,
                  defaultPageSize: defaultPageSize,
                  defaultCurrent: 1,
                  pageSize: 25,
                  current: cusCurrentPage,
                  onChange: (page) => {
                    setCusCurrentPage(page);
                  },
                }}
                className="param_tbListShow"
                onRow={(record, index) => {
                  return {
                    onClick: (event) => {
                      onRowSelect(record);
                    },
                  };
                }}
              />
            </Row>
          </div>
          {/* プロパティ表示 */}
          <div>
            <Routes>
              <Route
                exact
                path=":common.customer"
                element={
                  <CustomerSupplierDetails
                    ref={detailRef}
                    editMode={editMode}
                    showEdtConfirmOK={showEdtConfirmOK}
                    showEdtConfirmCancle={showEdtConfirmCancle}
                    selectedData={selectedData}
                    submitData={submitData}
                    updateEditMode={updateEditMode}
                    activePartMenu={activePartMenu}
                  />
                }
              />
            </Routes>
          </div>
        </SplitPane>
      </div>

      {/* 新規作成 */}
      {isCreateNew
        ? commonModal(
            isCreateNew,
            createNewTitle,
            null,
            null,
            800,
            createNewOK,
            createNewCancel,
            createNewContent,
            'addNewCustomerModal',
            null,
            true
          )
        : ''}

      {/* 一括編集 */}
      {isBulkEdit
        ? commonModal(
            isBulkEdit,
            bulkEditTitle,
            null,
            null,
            1790,
            bulkEditOK,
            bulkEditCancel,
            bulkEditContent,
            'updateModalStyle',
            null,
            true
          )
        : ''}

      {/* 削除 */}
      {isDelete
        ? commonModal(
            isDelete,
            confirmModalTitle,
            commonDeleteFooter(deleteOk, deleteCancel),
            null,
            400,
            deleteOk,
            deleteCancel,
            editMode ? confirmDiscardAndDeleteContent : confirmDeleteContent,
            null,
            null,
            false
          )
        : ''}

      {/* CSV取込 */}
      {/* <Modal
        title={reportModalTitle}
        open={visibleCSVInput}
        onOk={handleOkCSVInput}
        onCancel={handleCancel}
        footer={[
          <Button key="file" type="primary" onClick={handleOkCSVInput} className="mainButton">
            OK
          </Button>,
          <Button key="back" onClick={handleCancel} className="cancelButton">
            キャンセル
          </Button>,
        ]}
        closeIcon={<CloseOutlined style={{ color: 'white' }} />}
      >
        <p style={{ fontSize: '13.5px' }}>
          PCに保存した情報を読み込みます。<br></br>よろしいでしょうか？
        </p>
      </Modal> */}

      {/* XML呼出 */}
      {/* <Modal
        title={reportModalTitle}
        open={visibleXMLInput}
        onOk={handleOkXMLInput}
        onCancel={handleCancel}
        footer={[
          <Button key="file" type="primary" onClick={handleOkXMLInput} className="mainButton">
            OK
          </Button>,
          <Button key="back" onClick={handleCancel} className="cancelButton">
            キャンセル
          </Button>,
        ]}
        closeIcon={<CloseOutlined style={{ color: 'white' }} />}
      >
        <p style={{ fontSize: '13.5px' }}>
          装置情報（時間チャージ、段取設定、加工設定）を初期パラメータ作成情報から
          <br />
          取り込みます。
          <br />
          装置情報は、呼出情報で更新されます。
        </p>
      </Modal> */}

      {/* 登録確認 */}
      {createConfirm
        ? commonModal(
            createConfirm,
            confirmModalTitle,
            commonFooter(confirmOk, confirmCancel),
            null,
            400,
            confirmOk,
            confirmCancel,
            confirmCreateContent,
            null,
            null,
            false
          )
        : ''}

      {/* 更新確認 */}
      {updateConfirm
        ? commonModal(
            updateConfirm,
            confirmModalTitle,
            commonFooter(confirmOk, confirmCancel),
            null,
            400,
            confirmOk,
            confirmCancel,
            confirmUpdateContent,
            null,
            null,
            false
          )
        : ''}

      {/** 破棄の確認メッセージ */}
      {cancelConfirm
        ? commonModal(
            cancelConfirm,
            confirmModalTitle,
            commonFooter(cancelConfirmOK, cancelConfirmCancel),
            null,
            400,
            cancelConfirmOK,
            cancelConfirmCancel,
            confirmDiscardContent,
            null,
            null,
            false
          )
        : ''}

      {/** 編集中破棄確認ポップアップ */}
      {editModeCancelConfirm
        ? commonModal(
            editModeCancelConfirm,
            confirmModalTitle,
            commonFooter(discardConfirmOk, discardConfirmCancel),
            null,
            400,
            discardConfirmOk,
            discardConfirmCancel,
            confirmHakiContent,
            null,
            null,
            false
          )
        : ''}
    </>
  );
});

export default CustomerSupplierParamList;
