import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Table } from 'react-bootstrap';
import { Row, Input, Button, Select } from 'antd';
import { PlusCircleFilled, CloseCircleFilled } from '@ant-design/icons';

const types = [
  { id: 1, value: '丸', label: '丸' },
  { id: 2, value: '角', label: '角' },
  { id: 3, value: '長角', label: '長角' },
  { id: 4, value: '長丸', label: '長丸' },
  { id: 5, value: 'その他', label: 'その他' },
];
import { commonModal } from '../common/CommonModal';
const IQ3KouteiInput_Manual = forwardRef((props, ref) => {
  const [manual, setManual] = useState([]);
  const [auto, setAuto] = useState([]);
  // 編集モード
  // const editMode = props.editMode;
  // 詳細
  const [isDetails, setIsDetails] = useState(false);
  const data = [{ id: 1, types: '', xsize: '', ysize: '', count: '' }];

  const [manualDeburring, setManualDeburring] = useState([]);
  const [autoDeburring, setAutoDeburring] = useState([]);
  const [detailsList, setDetailsList] = useState([]);

  useEffect(() => {
    let blankData = {};
    if (props.blankInfo != undefined) {
      blankData = props?.blankInfo?.details?.[0].dimensionData;
    } else {
      blankData = {
        outerDia: 0,
        outerAutoExtract: 0,
        innerDia: 0,
        innerAutoExtract: 0,
        airFeedDistance: 0,
        airFeedAutoExtract: 0,
      };
    }

    let data = props?.manualDeburringInfo;
    if (data != undefined) {
      // setDetailsList(data.details[0].quantityList);

      let lists = data?.details;
      const updatedList = lists?.map((item) => {
        if (item.target === '外形') {
          return {
            ...item,
            length: item?.length != 0 ? item?.length : blankData?.outerDia,
            autoExtract: blankData?.outerAutoExtract,
          };
        } else if (item.target === '内形') {
          return {
            ...item,
            length: item?.length != 0 ? item?.length : blankData?.innerDia,
            autoExtract: blankData?.innerAutoExtract,
          };
        } else {
          return item; // No change for other targets
        }
      });
      setManualDeburring(updatedList);
    }
    let autoDeburringdata = props?.autoDeburringInfo;
    if (autoDeburringdata != undefined) {
      setAutoDeburring(autoDeburringdata?.details);
    }
  }, [props.manualDeburringInfo, props.blankInfo, props.autoDeburringInfo]);

  useImperativeHandle(ref, () => ({
    setEditModeChange: setEditModeChange,
  }));

  const changeAutoDetails = (event, id, types) => {
    const temp = JSON.parse(JSON.stringify(autoDeburring));

    const updatedData = temp?.map((row) => {
      if (row.id === id) {
        return { ...row, [types]: event.target.value };
      }
      return row;
    });
    setAutoDeburring(updatedData);
    const newAutoDeburringInfo = { ...props.autoDeburringInfo };
    newAutoDeburringInfo.details = updatedData;
    props.updateDeburring(newAutoDeburringInfo);
  };
  const changeManualDetails = (event, id, types) => {
    const temp = JSON.parse(JSON.stringify(manualDeburring));

    const updatedData = temp?.map((row) => {
      if (row.id === id) {
        return { ...row, [types]: event.target.value };
      }
      return row;
    });
    setManualDeburring(updatedData);
    const newDeburringInfo = { ...props.manualDeburringInfo };
    newDeburringInfo.details = updatedData;
    props.updateDeburring(newDeburringInfo);
  };
  const clickDetails = () => {
    setIsDetails(true);
  };
  const addDetailsModalTitle = (
    <div
      style={{
        width: 620,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}></p>
    </div>
  );
  const addData = () => {};
  const closeAddModal = () => {
    setIsDetails(false);
  };
  const addRow = (id) => {
    const copyData = detailsList.slice(); // Create a copy of the original array

    const insertIndex = detailsList?.findIndex((item) => item.id === id); // Find the clicked index of the item
    const largestId = detailsList?.reduce((maxId, item) => {
      return item.id > maxId ? item.id : maxId;
    }, 0);

    if (insertIndex !== -1) {
      const data = {
        id: largestId + 1,
        types: '',
        xsize: '',
        ysize: '',
        perimeter: '',
        count: '',
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setDetailsList(copyData);
    }
  };
  const deleteRow = (id) => {
    if (detailsList?.length > 1) {
      const updatedData = detailsList?.filter((item) => item.id !== id);

      setDetailsList(updatedData);
    }
  };

  const changeDetails = (event, id, types) => {
    const temp = JSON.parse(JSON.stringify(detailsList));
    let updatedData = [];
    if (types === 'types') {
      updatedData = temp?.map((row) => {
        if (row.id === id) {
          return { ...row, [types]: event };
        }
        return row;
      });
      // setDetailsList(updatedData);
    } else {
      updatedData = temp?.map((row) => {
        if (row.id === id) {
          return { ...row, [types]: event.target.value };
        }
        return row;
      });
      // setDetailsList(updatedData);
    }
    setDetailsList(updatedData);
    const newDeburringInfo = { ...props.manualDeburringInfo };
    newDeburringInfo.details[0].quantityList = updatedData;
    props.updateDeburring(newDeburringInfo);
  };
  const addContent = (
    <>
      {/* <Row className="mt-2 registerModal">
        <Table className="kouteiinput " style={{ width: '100%' }}>
          <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
            <tr style={{ textAlign: 'center' }}>
              <th style={{ width: '3%' }} className="tbl-header">
                No.
              </th>
              <th style={{ width: '30%' }} className="tbl-header">
                形状
              </th>
              <th style={{ width: '11%' }} className="tbl-header">
                Xサイズ(mm)
              </th>
              <th style={{ width: '11%' }} className="tbl-header">
                Yサイズ(mm)
              </th>
              <th style={{ width: '11%' }} className="tbl-header">
                周長(mm)
              </th>
              <th style={{ width: '11%' }} className="tbl-header">
                数
              </th>
              <th style={{ width: '11%', border: 'none' }}></th>
            </tr>
          </thead>
          <tbody>
            {detailsList?.map((i, index) => (
              <tr>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{index + 1}</td>
                <td style={{ verticalAlign: 'middle' }}>
                  {editMode ? (
                    <>
                      <Select
                        defaultValue={1}
                        size="middle"
                        value={i.types}
                        onChange={(e) => changeDetails(e, i.id, 'types')}
                        style={{ pointerEvents: editMode ? 'auto' : 'none', width: '100%' }}
                      >
                        {types?.map((option) => (
                          <Select.Option value={option.value}>{option.label}</Select.Option>
                        ))}
                      </Select>
                    </>
                  ) : (
                    <>{i.types}</>
                  )}
                </td>

                <td>
                  <Input
                    className={editMode ? 'input-editable ' : 'input-non-editable '}
                    value={i.xsize}
                    onChange={(e) => changeDetails(e, i.id, 'xsize')}
                    style={{ height: 32, textAlign: 'center' }}
                  ></Input>
                </td>
                <td>
                  <Input
                    className={editMode ? 'input-editable ' : 'input-non-editable '}
                    value={i.ysize}
                    onChange={(e) => changeDetails(e, i.id, 'ysize')}
                    style={{ height: 32, textAlign: 'center' }}
                  ></Input>
                </td>
                <td>
                  <Input
                    className={editMode ? 'input-editable ' : 'input-non-editable '}
                    value={i.perimeter}
                    onChange={(e) => changeDetails(e, i.id, 'perimeter')}
                    style={{ height: 32, textAlign: 'center', pointerEvents: 'none' }}
                  ></Input>
                </td>
                <td>
                  <Input
                    className={editMode ? 'input-editable ' : 'input-non-editable '}
                    value={i.count}
                    onChange={(e) => changeDetails(e, i.id, 'count')}
                    style={{ height: 32, textAlign: 'center' }}
                  ></Input>
                </td>

                {editMode ? (
                  <>
                    <td style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle' }}>
                      <Row>
                        <PlusCircleFilled
                          className="add-remove-icon"
                          onClick={() => addRow(i.id)}
                          style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                        />
                        <CloseCircleFilled
                          className="add-remove-icon"
                          onClick={() => deleteRow(i.id)}
                          style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                        />
                      </Row>
                    </td>
                  </>
                ) : (
                  <>
                    <td style={{ border: 'none', width: '6%' }}></td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
      </Row> */}
      {/* <div className="overflow-auto kouteical timecharge registerModal" style={{ height: '77.1vh', marginTop: 22 }}></div> */}
      <div className="mt-2 registerModal overflow-auto" style={{ height: '50vh' }}>
        <Table className="kouteiinput " style={{ width: '100%', height: '300', maxHeight: '300' }}>
          <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
            <tr style={{ textAlign: 'center' }}>
              <th style={{ width: '3%' }} className="tbl-header">
                No.
              </th>
              <th style={{ width: '30%' }} className="tbl-header">
                種類.形状
              </th>
              <th style={{ width: '11%' }} className="tbl-header">
                Xサイズ(mm)
              </th>
              <th style={{ width: '11%' }} className="tbl-header">
                Yサイズ(mm)
              </th>
              <th style={{ width: '11%' }} className="tbl-header">
                周長(mm)
              </th>
              <th style={{ width: '11%' }} className="tbl-header">
                数
              </th>
            </tr>
          </thead>
          <tbody>
            {detailsList?.map((i, index) => (
              <tr key={index}>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{index + 1}</td>
                <td style={{ verticalAlign: 'middle' }}>
                  {/* {editMode ? (
                    <> */}
                  <Select
                    defaultValue={1}
                    size="middle"
                    value={i.types}
                    onChange={(e) => changeDetails(e, i.id, 'types')}
                    style={{ width: '100%' }}
                  >
                    {/* <Select.Option value={1}> {i[1]}</Select.Option> */}
                    {types?.map((option, index) => (
                      <Select.Option key={index} value={option.value}>
                        {option.label}
                      </Select.Option>
                    ))}
                  </Select>
                  {/* </>
                  ) : (
                    <>{i.types}</>
                  )} */}
                </td>

                <td>
                  <Input
                    // className={editMode ? 'input-editable ' : 'input-non-editable '}
                    className="input-editable"
                    value={i.xsize}
                    onChange={(e) => changeDetails(e, i.id, 'xsize')}
                    style={{ height: 32, textAlign: 'center' }}
                  ></Input>
                </td>
                <td>
                  <Input
                    // className={editMode ? 'input-editable ' : 'input-non-editable '}
                    className="input-editable"
                    value={i.ysize}
                    onChange={(e) => changeDetails(e, i.id, 'ysize')}
                    style={{ height: 32, textAlign: 'center' }}
                  ></Input>
                </td>
                <td>
                  <Input
                    // className={editMode ? 'input-editable ' : 'input-non-editable '}
                    className="input-editable"
                    value={i.perimeter}
                    onChange={(e) => changeDetails(e, i.id, 'perimeter')}
                    style={{ height: 32, textAlign: 'center' }}
                  ></Input>
                </td>
                <td>
                  <Input
                    // className={editMode ? 'input-editable ' : 'input-non-editable '}
                    className="input-editable"
                    value={i.count}
                    onChange={(e) => changeDetails(e, i.id, 'count')}
                    style={{ height: 32, textAlign: 'center' }}
                  ></Input>
                </td>
                {/* {editMode ? (
                  <> */}
                <td style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '6%' }}>
                  <Row justify="center">
                    {/* <PlusCircleFilled
                          className="add-remove-icon"
                          onClick={() => addRow(i.id)}
                          style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                        /> */}
                    <CloseCircleFilled
                      className="add-remove-icon"
                      onClick={() => deleteRow(i.id)}
                      // style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                    />
                  </Row>
                </td>
                {/* </>
                ) : (
                  <>
                    <td style={{ border: 'none', width: '6%' }}></td>
                  </>
                )} */}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
  return (
    <>
      <Row className="mt-2 registerModal">
        <Table className="kouteiinput" style={{ width: '54%' }}>
          <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
            <tr style={{ textAlign: 'center' }}>
              <th style={{ width: '3%' }} className="tbl-header">
                No
              </th>
              <th style={{ width: '19%' }} className="tbl-header">
                種類.形状
              </th>
              <th style={{ width: '11.5%' }} className="tbl-header">
                対象
              </th>
              <th style={{ width: '11%' }} className="tbl-header">
                周長(mm)
              </th>
              {/* <th style={{ width: '11%' }} className="tbl-header">
                自動抽出
              </th> */}
              <th style={{ width: '11%' }} className="tbl-header">
                回数
              </th>
            </tr>
          </thead>
          <tbody>
            {manualDeburring?.map((i, index) => (
              <tr key={index}>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{i.id}</td>

                <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{i.types}</td>
                <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{i.target}</td>

                <td>
                  <Input
                    // className={editMode ? 'input-editable ' : 'input-non-editable '}
                    className="input-editable"
                    value={i?.length}
                    onChange={(e) => changeManualDetails(e, i.id, 'length')}
                    style={{ height: 32, textAlign: 'center' }}
                  ></Input>
                </td>
                {/* <td>
                  <Input
                    // className={editMode ? 'input-non-editable ' : 'input-non-editable '}
                    className="input-non-editable"
                    value={i.autoExtract}
                    onChange={(e) => changeManualDetails(e, i.id, 'autoExtract')}
                    style={{ height: 32, textAlign: 'center' }}
                  ></Input>
                </td> */}
                <td>
                  <Input
                    // className={editMode ? 'input-editable ' : 'input-non-editable '}
                    className="input-editable"
                    value={i.count}
                    onChange={(e) => changeManualDetails(e, i.id, 'count')}
                    style={{ height: 32, textAlign: 'center' }}
                  ></Input>
                </td>
                {/* <td style={{ width: '8%', border: 'none', textAlign: 'center' }}>
                  {i.target === '内形' ? (
                    <>
                      <Button className="mainButton" onClick={clickDetails}>
                        詳細
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                </td> */}
              </tr>
            ))}
          </tbody>
        </Table>
      </Row>
      <Row className="mt-2 registerModal">
        <Table className="kouteiinput" style={{ width: '54%' }}>
          <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
            <tr style={{ textAlign: 'center' }}>
              <th style={{ width: '3%' }} className="tbl-header">
                No
              </th>
              <th style={{ width: '19%' }} className="tbl-header">
                種類.形状
              </th>
              <th style={{ width: '11.5%' }} className="tbl-header">
                面積(cm<sup>2</sup>)
              </th>
              {/* <th style={{ width: '11%' }} className="tbl-header">
                自動抽出(面積)
              </th> */}
              <th style={{ width: '11%' }} className="tbl-header">
                重量(kg)
              </th>
              {/* <th style={{ width: '11%' }} className="tbl-header">
                自動抽出(重量)
              </th> */}
              <th style={{ width: '11%' }} className="tbl-header">
                回数
              </th>
            </tr>
          </thead>
          <tbody>
            {autoDeburring?.map((i, index) => (
              <tr key={index}>
                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{i.id}</td>

                <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{i.types}</td>
                <td>
                  <Input
                    // className={editMode ? 'input-editable ' : 'input-non-editable '}
                    className="input-editable"
                    value={i.area}
                    onChange={(e) => changeAutoDetails(e, i.id, 'area')}
                    style={{ height: 32, textAlign: 'center' }}
                  ></Input>
                </td>
                {/* <td>
                  <Input
                    // className={editMode ? 'input-non-editable ' : 'input-non-editable '}
                    className="input-non-editable"
                    value={i.areaAutoExtract}
                    // onChange={(e) => changeAutoDetails(e, i.id, 'areaAutoExtract')}
                    style={{ height: 32, textAlign: 'center', pointerEvents: 'none' }}
                  ></Input>
                </td> */}
                <td>
                  <Input
                    // className={editMode ? 'input-editable ' : 'input-non-editable '}
                    className="input-editable"
                    value={i.weight}
                    onChange={(e) => changeAutoDetails(e, i.id, 'weight')}
                    style={{ height: 32, textAlign: 'center' }}
                  ></Input>
                </td>
                {/* <td>
                  <Input
                    // className={editMode ? 'input-non-editable ' : 'input-non-editable '}
                    className="input-non-editable"
                    value={i.weightAutoExtract}
                    // onChange={(e) => changeAutoDetails(e, i.id, 'weightAutoExtract')}
                    style={{ height: 32, textAlign: 'center', pointerEvents: 'none' }}
                  ></Input>
                </td> */}
                <td>
                  <Input
                    // className={editMode ? 'input-editable ' : 'input-non-editable '}
                    className="input-editable"
                    value={i.count}
                    onChange={(e) => changeAutoDetails(e, i.id, 'count')}
                    style={{ height: 32, textAlign: 'center' }}
                  ></Input>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Row>

      {/*　詳細 */}
      {isDetails
        ? commonModal(
            isDetails,
            addDetailsModalTitle,
            null,
            null,
            620,
            addData,
            closeAddModal,
            addContent,
            null,
            null,
            true
          )
        : ''}
    </>
  );
});

export default IQ3KouteiInput_Manual;
