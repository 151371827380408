/**
 * クラス名：板金の装置設定の加工速度係数画面
 * 作成者：チュー
 * 作成日：2023/06/26
 * バージョン：1.0
 */
import { Row, Col, Input, Button, Space, Popover } from 'antd';
import React, { useEffect, forwardRef, useState, useRef } from 'react';
import { Table } from 'react-bootstrap';
import { PlusCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { isEmpty } from '@rc-component/mini-decimal/es/numberUtil';
import { commonModal } from '../../../common/CommonModal';
import {
  deleteListColData,
  updateListColData,
  sortListData,
  getProcessTypeName,
  handleKeyPress,
  getInputBorderStyle,
} from '../../../common/Common';
import { ProcessType } from '../../../common/enums';

const Device_Blank_KakouSokudoKeisuu = forwardRef((props, ref) => {
  const [editMode, setEditMode] = useState(false);

  const [isItaatsu, setIsItaatsu] = useState(false);

  // 板厚
  const [itaatsuTemp, setItaatsuTemp] = useState([]);
  const [itaatsuData, setItaatsuData] = useState([]);

  const [workSpeedCoeffIntWorkItemsList, setWorkSpeedCoeffIntWorkItemsList] = useState([]);
  const [workSpeedCoeffExtWorkItemsList, setWorkSpeedCoeffExtWorkItemsList] = useState([]);
  const [errIndexVal, setErrIndexVal] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const inputRef = useRef([]);
  useEffect(() => {
    setErrIndexVal([]);
    setErrorMessage('');

    setEditMode(props.editMode);
    if (props?.detailsData?.workSpeedCoeffThickThMaster) {
      setItaatsuData(props?.detailsData?.workSpeedCoeffThickThMaster);
      setItaatsuTemp(props?.detailsData?.workSpeedCoeffThickThMaster);
    }
    if (props?.detailsData?.workSpeedCoeffInternalWorkItems) {
      setWorkSpeedCoeffIntWorkItemsList(props?.detailsData?.workSpeedCoeffInternalWorkItems);
    }
    if (props?.detailsData?.workSpeedCoeffExternalWorkItems) {
      setWorkSpeedCoeffExtWorkItemsList(props?.detailsData?.workSpeedCoeffExternalWorkItems);
    }
  }, [props.detailsData, props.editMode]);

  const addItaatsuModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>板厚</p>
    </div>
  );

  const addData = () => {
    setIsUpdateData(true);
  };

  const closeAddModal = () => {
    if (isItaatsu === true) {
      setIsItaatsu(false);
    }
  };
  const addItaatsu = (id, index) => {
    inputRef.current[index].focus();
    const copyData = itaatsuTemp.slice(); // Create a copy of the original array

    const insertIndex = itaatsuTemp?.findIndex((item) => item.no === id); // Find the index of the item with id 2

    if (insertIndex !== -1) {
      const data = {
        no: itaatsuTemp?.length + 1,
        value: 0,
        isOutOfRange: false,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setItaatsuTemp(copyData);
    }
  };
  const isValueOrderCorrect = (no) => {
    const temp = itaatsuTemp?.filter((i) => i.isOutOfRange === false);

    const insertIndex = temp?.findIndex((item) => item.no === no);

    if (insertIndex > 0) {
      const prevValue = parseFloat(temp[insertIndex - 1].value);
      const currentValue = parseFloat(temp[insertIndex].value);
      const nextValue = parseFloat(temp[insertIndex + 1].value);
      if (currentValue > prevValue && currentValue < nextValue) {
        let temp = [insertIndex - 1, insertIndex, insertIndex + 1];

        setErrIndexVal(errIndexVal?.filter((item) => item !== insertIndex && item != insertIndex - 1));

        setErrorMessage('前のしきい値より大きい値を入力してください！');
      } else {
        setErrIndexVal([...errIndexVal, insertIndex]);

        if (currentValue <= prevValue) {
          setErrorMessage('前のしきい値より大きい値を入力してください！');
        } else if (currentValue >= nextValue) {
          setErrorMessage('次のしきい値より小さい値を入力してください！');
        }
      }
    } else {
      const currentValue = parseFloat(temp[insertIndex].value);
      const nextValue = parseFloat(temp[insertIndex + 1].value);

      if (currentValue < nextValue) {
        setErrIndexVal(errIndexVal?.filter((item) => item !== insertIndex));
      } else {
        setErrIndexVal([...errIndexVal, insertIndex]);
        setErrorMessage('次のしきい値より小さい値を入力してください！');
      }
    }
  };
  const deleteItaatsu = (id, e, index) => {
    // 更新するエラーインデックスを得る
    const updatedErrorIndexes = errIndexVal?.filter((item) => item !== index);

    const finalErrorIndexes = updatedErrorIndexes?.map((item) => (item > index ? item - 1 : item));
    setErrIndexVal(finalErrorIndexes);

    if (itaatsuTemp?.filter((item) => item.isOutOfRange === false)?.length > 2) {
      const updatedData = itaatsuTemp?.map((row) => {
        if (row.no === id) {
          return { ...row, isOutOfRange: true };
        }
        return row;
      });
      setItaatsuTemp(updatedData);
      const filterData = updatedData?.filter((i) => !i.isOutOfRange);
      if (index + 1 !== filterData?.length) {
        inputRef.current[index].focus();
      }
    }
  };

  const handleItaatsuChange = (event, id, field) => {
    const updatedData = itaatsuTemp?.map((row) => {
      if (row.no === id) {
        return { ...row, [field]: event.target.value };
      }
      return row;
    });
    setItaatsuTemp(updatedData);
  };

  const cancelMasterData = () => {
    setErrIndexVal([]);
    setErrorMessage('');
    setItaatsuTemp(itaatsuData);
    setIsItaatsu(false);
  };

  const addItaatsuMaster = () => {
    setIsItaatsu(false);

    const deletedId = [];

    itaatsuData?.forEach((item1, index) => {
      const found = itaatsuTemp?.find((item2) => item2.no === item1.no && item2.isOutOfRange === true);
      if (found) {
        deletedId.push(item1.no);
      }
    });

    const newId = itaatsuTemp
      ?.filter((item) => !item.isOutOfRange && !itaatsuData.some((data2Item) => data2Item.no === item.no))
      ?.map((item) => item.no);

    const deleteIntWorkItemList = deleteListColData(workSpeedCoeffIntWorkItemsList, deletedId);
    const deleteExtWorkItemList = deleteListColData(workSpeedCoeffExtWorkItemsList, deletedId);
    const updateIntWorkItemsList = updateListColData(deleteIntWorkItemList, newId);
    const updateExtWorkItemsList = updateListColData(deleteExtWorkItemList, newId);
    const sortedIntWorkItemsList = sortListData(updateIntWorkItemsList, itaatsuTemp);
    const sortedExtWorkItemsList = sortListData(updateExtWorkItemsList, itaatsuTemp);
    setItaatsuData(itaatsuTemp);
    props.updatedItaatsuData(itaatsuTemp);
    setWorkSpeedCoeffIntWorkItemsList(sortedIntWorkItemsList);
    props.updateWorkSpeedCoeffInternalWorkItems(sortedIntWorkItemsList);
    setWorkSpeedCoeffExtWorkItemsList(sortedExtWorkItemsList);
    props.updateWorkSpeedCoeffExternalWorkItems(sortedExtWorkItemsList);
  };

  const addContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>しきい値</label>（≦）
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {itaatsuTemp
                  ?.filter((subitem) => subitem.isOutOfRange === false)
                  ?.map((item, index) => (
                    <tr key={index} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        {errIndexVal?.includes(index) ? (
                          <Popover
                            content={<div style={{ padding: 7 }}>{errorMessage}</div>}
                            placement="left"
                            visible={true}
                          ></Popover>
                        ) : (
                          ''
                        )}
                        <Input
                          value={item.value}
                          onChange={(e) => handleItaatsuChange(e, item.no, 'value')}
                          onBlur={() => isValueOrderCorrect(item.no)}
                          className={errIndexVal?.includes(index) ? 'red-border' : ''}
                          style={{
                            ...getInputBorderStyle(index, itaatsuTemp),
                            pointerEvents: editMode ? 'auto' : 'none',
                          }}
                          onKeyPress={(e) => {
                            handleKeyPress(e, false);
                          }}
                          ref={(ref) => (inputRef.current[index] = ref)}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addItaatsu(item.no, index + 1)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteItaatsu(item.no, e, index)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button
          className={isEmpty(errIndexVal) ? 'mainButton' : 'disabledButton'}
          id="update"
          onClick={addItaatsuMaster}
        >
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} id="discard" onClick={cancelMasterData}>
          キャンセル
        </Button>
      </Row>
    </>
  );

  const itaatsuModal = () => {
    setIsItaatsu(true);
  };
  const listDataChange = (event, no, tblName) => {
    if (tblName === 'internal') {
      workSpeedCoeffIntWorkItemsList
        ?.filter((item) => item.no === no)
        ?.map((list) => {
          list.value = event.target.value;
        });
      setWorkSpeedCoeffIntWorkItemsList(workSpeedCoeffIntWorkItemsList);
      props.updateWorkSpeedCoeffInternalWorkItems(workSpeedCoeffIntWorkItemsList);
    } else {
      workSpeedCoeffExtWorkItemsList
        ?.filter((item) => item.no === no)
        ?.map((list) => {
          list.value = event.target.value;
        });
      setWorkSpeedCoeffExtWorkItemsList(workSpeedCoeffExtWorkItemsList);
      props.updateWorkSpeedCoeffExternalWorkItems(workSpeedCoeffExtWorkItemsList);
    }
  };

  return (
    <>
      <div className="table-container" style={{ marginLeft: 10, marginRight: 10 }}>
        <Table
          style={{
            width:
              itaatsuData?.filter((i) => i.isOutOfRange === false)?.length > 8
                ? '100%'
                : itaatsuData?.filter((i) => i.isOutOfRange === false)?.length * 98 + 196 + 'px',
          }}
        >
          <tr style={{ color: 'white', textAlign: 'center', height: 40 }}>
            <td style={{ width: '196px' }} className="tbl-header fixedCol">
              <Row justify="center" className="tbl-header">
                <Space>
                  <label>加工種別</label>
                  <label>/</label>
                  {editMode ? (
                    <>
                      <Button className="cancleButton" onClick={itaatsuModal} style={{ pointerEvents: 'auto' }}>
                        板厚
                      </Button>
                    </>
                  ) : (
                    <>
                      <label>板厚</label>
                    </>
                  )}
                </Space>
              </Row>
            </td>

            {itaatsuData
              ?.filter((subitem) => subitem.isOutOfRange === false)
              ?.map((item, index) => (
                <td key={index} style={{ backgroundColor: '#1063aa', width: '98px' }}>
                  ～{item.value}t
                </td>
              ))}
          </tr>

          <tr style={{ textAlign: 'center' }}>
            <td style={{ width: '196px' }} className="fixedOneRowCol">
              {getProcessTypeName(ProcessType.InternalWork)}
            </td>
            {workSpeedCoeffIntWorkItemsList?.map((row, index) => (
              <td key={index} style={{ width: '98px' }}>
                <Input
                  value={row.value}
                  onChange={(e) => listDataChange(e, row.no, 'internal')}
                  onKeyPress={(e) => {
                    handleKeyPress(e, false);
                  }}
                  style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                  className={editMode ? 'input-editable ' : 'input-non-editable'}
                />
              </td>
            ))}
          </tr>
          <tr style={{ textAlign: 'center' }}>
            <td style={{ width: '196px' }} className="fixedOneRowCol">
              {getProcessTypeName(ProcessType.ExternalWork)}
            </td>
            {workSpeedCoeffExtWorkItemsList?.map((row, index) => (
              <td key={index} style={{ width: '98px' }}>
                <Input
                  value={row.value}
                  onChange={(e) => listDataChange(e, row.no, 'external')}
                  onKeyPress={(e) => {
                    handleKeyPress(e, false);
                  }}
                  style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                  className={editMode ? 'input-editable ' : 'input-non-editable'}
                />
              </td>
            ))}
          </tr>
        </Table>
      </div>

      {/* 板厚 */}
      {isItaatsu
        ? commonModal(
            isItaatsu,
            addItaatsuModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addContent,
            null,
            null,
            true
          )
        : ''}
    </>
  );
});

export default Device_Blank_KakouSokudoKeisuu;
