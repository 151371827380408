import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';

import { Row, Col, Input, Select, Popover, Checkbox, InputNumber } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import '../../assets/styles/common.css';
import {
  handelIncreaseTime,
  handelDecreaseTime,
  checkInput,
  secondsToHms,
  setTimetoSec,
  checkInputWithColor,
  isNumber,
  checkShowHideIcon,
  handleShowHide,
  handleCollpse,
  handleKeyPress,
  kouteiCalculateSelectedArr,
  ProcessDetailGroups,
  JPYs,
} from '../common/Common.js';
import CurrencyInput from 'react-currency-input-field';
import { BsUsbC, BsX } from 'react-icons/bs';
import { DataType, WorkType, MaterialSurfaceBillingUnit } from '../common/enums';
import { amtEmptyStr, timeHmsStr } from '../common/Constant';

const KouteiCalculate_Hyomen = forwardRef((props, ref) => {
  const [isHyomendetail, setIHyomendetail] = useState(false);
  const [totalAmt, setTotalAmt] = useState('');
  const [totalTime, setTotalTime] = useState('');
  const [totalSagyoAmt, setTotalSagyoAmt] = useState('');
  const [totalSagyoTime, setTotalSagyoTime] = useState('');
  const [totalDandoriAmt, setTotalDandoriAmt] = useState('');
  const [totalDandoriTime, setTotalDandoriTime] = useState('');

  const [totalSum, setTotalSum] = useState('');
  const [totalTimeSum, setTotalTimeSum] = useState('');
  const [sagyoAmtSum, setSagyoAmtSum] = useState('');
  const [sagyoTimeSum, setSagyoTimeSum] = useState('');
  const [dandoriAmtSum, setDandoriAmtSum] = useState(0);
  const [dandoriTimeSum, setDandoriTimeSum] = useState('');
  const [processArr, setProcessArr] = useState([]);
  const [editedData, setEditedData] = useState([]);
  const [updateEditedData, setUpdateEditedData] = useState([]);

  const [subHyomensArr, setSubHyomenArr] = useState([]);
  const [hyomensArr, setHyomenArr] = useState([]);
  const [selectedDataFlg, setSelectedDataFlg] = useState(0);

  const [deviceMst, setDeviceMst] = useState([]);
  const [processMst, setProcessMst] = useState([]);
  const [patternInfo, setPatternInfo] = useState([]);
  const [orgData, setOrgData] = useState([]);
  const [oldEditedData, setOldEditedData] = useState([]);
  const [hyomenAmt, setHyomenAmt] = useState(0);

  useEffect(() => {
    let editData = [];
    let totals = [];
    let toBEdit = [];
    let parameters = props?.selectedDataDetail?.estimateProducts?.calcParameters?.parameters;
    let processMst = parameters?.process
      ? parameters.process?.filter((item) => item.workType === WorkType.PaSurface)[0]
      : [];
    let surfaceAdditions = props?.selectedDataDetail?.estimateProducts?.additions?.filter(
      (item) => item.processId == processMst?.id
    )[0];
    let patternInfo = props?.selectedDataDetail?.estimateProducts?.processSelect
      ? props?.selectedDataDetail?.estimateProducts?.processSelect?.details?.filter(
          (item) => item.processId == processMst?.id
        )
      : [];
    setPatternInfo([]);
    if (patternInfo == undefined) return;
    if (patternInfo?.length > 0 && patternInfo[0]?.isUsedForCalc == false) return;

    let deviceMst =
      parameters?.device && patternInfo
        ? parameters?.device?.filter(
            (item) => item.processId == patternInfo[0]?.processId && item.id == patternInfo[0]?.deviceId
          )[0]
        : []; //テスト用ID;
    setProcessMst(processMst ? processMst : []);
    setDeviceMst(deviceMst ? deviceMst : []);
    setPatternInfo(patternInfo);
    // 表面処理の工程入力情報取得
    let surfaceProcessInput = props?.selectedDataDetail?.estimateProducts?.processInput?.surfaceProcessInputInfo;
    // 新規の場合
    if (surfaceAdditions == undefined) {
      let detailItems = [];
      let editDetailItems = [];
      for (let j = 0; j < surfaceProcessInput?.length; j++) {
        detailItems.push({
          id: j + 1,
          processName: '表面処理(No' + (j + 1) + ')',
          processDetailTypeNo: surfaceProcessInput[j].processDetailTypeNo,
          dandoriAmt: 0,
          dandoriTime: 0,
          sagyoAmt: 0,
          sagyoTime: 0,
          calMethod: surfaceProcessInput[j].calcType == MaterialSurfaceBillingUnit.SquareMeter ? '面積' : '重量',
          calInput:
            surfaceProcessInput[j].calcType == MaterialSurfaceBillingUnit.SquareMeter
              ? surfaceProcessInput[j].areaUnitPrice
              : surfaceProcessInput[j].weightUnitPrice,
          processDetailWorkTypeGroup: surfaceProcessInput[j].processDetailWorkTypeGroup,
          formula: '',
        });
      }

      editDetailItems = [];
      let additionsNo = 1;
      if (props?.selectedDataDetail?.estimateProducts?.additions?.length > 0) {
        let lastRecord = props?.selectedDataDetail?.estimateProducts?.additions?.sort((a, b) => b.no - a.no)?.[0];
        additionsNo = lastRecord?.no + 1;
      }

      let hyomenAddition = {
        no: additionsNo,
        processId: processMst.id,
        dataType: DataType.Data,
        dataItems: detailItems,
        editItems: editDetailItems,
        totalDandori: {
          dataItemsAmt: 0,
          dataItemsTime: 0,
          editItemsAmt: 0,
          editItemsTime: 0,
        },
        totalSagyo: {
          dataItemsAmt: 0,
          dataItemsTime: 0,
          editItemsAmt: 0,
          editItemsTime: 0,
        },
        totalDataPrice: 0,
        totalDataTime: 0,
        totalEditPrice: 0,
        totalEditTime: 0,
      };
      surfaceAdditions = hyomenAddition;
    }

    if (surfaceAdditions?.dataItems) {
      let orgData = JSON.parse(JSON.stringify(surfaceAdditions?.dataItems));
      setOrgData(JSON.parse(JSON.stringify(surfaceAdditions?.dataItems)));
      if (deviceMst != undefined) {
        let totalDandori = 0;
        let totalSagyo = 0;
        let totalDandoriTm = 0;
        for (let i = 0; i < orgData?.length; i++) {
          let hyoumenInfo = orgData[i];
          let detailProcessInput = surfaceProcessInput?.filter(
            (item) =>
              item.processDetailTypeNo == hyoumenInfo.processDetailTypeNo &&
              item.processDetailWorkTypeGroup == hyoumenInfo.processDetailWorkTypeGroup
          )[0];

          let calInput =
            detailProcessInput?.calcType == MaterialSurfaceBillingUnit.SquareMeter
              ? detailProcessInput?.areaUnitPrice
              : detailProcessInput?.weightUnitPrice;
          orgData[i].calInput = calInput;
          surfaceAdditions.dataItems[i].calInput = calInput;

          let calMethod = detailProcessInput?.calcType == MaterialSurfaceBillingUnit.SquareMeter ? '面積' : '重量';
          orgData[i].calMethod = calMethod;
          surfaceAdditions.dataItems[i].calMethod = calMethod;

          let processType = parseInt(hyoumenInfo.processDetailTypeNo);

          /** 段取金額/時間 */
          // 段取時間設定
          let mBendCoeffMsts = deviceMst?.details?.prodWeightCoeffThMaster?.filter(
            (item) => item.value == hyoumenInfo.calInput || item.value > hyoumenInfo.calInput
          );
          let mBendCoeffMstNo =
            mBendCoeffMsts?.length > 0 ? mBendCoeffMsts[0]?.no : deviceMst?.details?.prodWeightCoeffThMaster?.[0]?.no;
          let mBendCoeff =
            mBendCoeffMstNo == 0
              ? 0
              : deviceMst?.details?.prodWeightCoeffThItems?.filter((item) => item.no == mBendCoeffMstNo)[0]?.value;
          //　基本段取時間＊重量係数
          let dandoriTimeSec = deviceMst?.details?.prepSecs[processType] * mBendCoeff;
          surfaceAdditions.dataItems[i].dandoriTime = dandoriTimeSec;
          let dandoriTime = dandoriTimeSec;
          orgData[i].dandoriTime = dandoriTime;
          totalDandoriTm += dandoriTime;

          // 段取金額設定
          //段階時間
          let mSetTimeMin = dandoriTimeSec / 60;
          //装置の段階時間チャージ
          let mSetHRate = deviceMst?.details?.charges?.[processType]?.prep;
          let dandoriAmt = (mSetTimeMin * mSetHRate) / 60.0; //段階時間＊時間チャージ
          orgData[i].dandoriAmt = dandoriAmt;
          surfaceAdditions.dataItems[i].dandoriAmt = dandoriAmt;
          totalDandori += dandoriAmt;

          // 処理選択名称設定
          orgData[i].processDetailWorkTypeGroup = ProcessDetailGroups?.filter(
            (item) => item.value == orgData[i].processDetailWorkTypeGroup
          )[0].label;

          orgData[i].processDetailTypeNo = processMst.details?.processDetailTypes?.filter(
            (item) => item.id == processType
          )[0]?.name;

          /** 　加工金額/時間　*/
          // 加工金額設定
          //面積
          let mWorkPrice = calInput / 10000; //平方メートルに変換
          let shori = hyoumenInfo.processDetailWorkTypeGroup;

          //装置の面積・重量時間チャージ
          let mWorkHRate =
            calMethod == '面積'
              ? deviceMst?.details?.charges[processType]?.squareMeter
              : deviceMst?.details?.charges[processType]?.weight;
          let sagyoAmt = mWorkPrice * mWorkHRate * (shori == '塗装(両面)' ? 2 : 1); //段階時間＊時間チャージ
          orgData[i].sagyoAmt = sagyoAmt;
          surfaceAdditions.dataItems[i].sagyoAmt = sagyoAmt;
          totalSagyo += sagyoAmt;
        }
        // 段取金額の合計
        setDandoriAmtSum(totalDandori);
        // 段取時間の合計
        setDandoriTimeSum(totalDandoriTm);
        // 作業金額の合計
        setSagyoAmtSum(totalSagyo);

        /** 　合計時間　*/
        let totalTimeSum = totalDandoriTm;
        setTotalTimeSum(totalTimeSum);
        surfaceAdditions.totalDataTime = totalTimeSum;
        surfaceAdditions.totalDandori.dataItemsTime = totalTimeSum;

        /** 　合計金額　*/
        let totalSum = totalDandori + totalSagyo;
        setTotalSum(totalSum);
        surfaceAdditions.totalDataPrice = totalSum;
        surfaceAdditions.totalDandori.dataItemsAmt = totalSum;
      }
      setProcessArr(orgData);
      if (surfaceAdditions.dataType == DataType.Edit) {
        toBEdit = JSON.parse(JSON.stringify(surfaceAdditions?.editItems));
      } else {
        toBEdit = JSON.parse(JSON.stringify(orgData));
      }
    }
    if (toBEdit?.length > 0) {
      editData = toBEdit != undefined ? settingEditData(toBEdit, surfaceAdditions?.dataItems) : [];
      surfaceAdditions.editItems = editData;
      if (editData?.length > 0) {
        setEditedData(editData);
        setOldEditedData(editData);

        totals = calculateTotals(editData);
        setTotalAmt(totals[0]?.totalSum);
        surfaceAdditions.totalEditPrice = totals[0]?.totalSum;
        surfaceAdditions.totalDandori.editItemsAmt = totals[0]?.totalSum;
        setTotalTime(totals[0]?.totalTimeSum);
        surfaceAdditions.totalEditTime = totals[0]?.totalTimeSum;
        surfaceAdditions.totalDandori.editItemsTime = totals[0]?.totalTimeSum;

        setTotalSagyoAmt(totals[0]?.sagyoAmtSum);
        setTotalSagyoTime(totals[0]?.sagyoTimeSum);
        setTotalDandoriAmt(totals[0]?.dandoriAmtSum);
        setTotalDandoriTime(totals[0]?.dandoriTimeSum);
      }
      setUpdateEditedData(surfaceAdditions);
    }
    if (surfaceAdditions?.dataType == DataType.Data) {
      setHyomenAmt(surfaceAdditions.totalDataPrice);
    } else {
      setHyomenAmt(surfaceAdditions.totalEditPrice);
    }
    props?.updateKouteiCal(surfaceAdditions, WorkType.PaSurface);
  }, [props?.activeTabKey]);

  const handleChangeCheckData = (e, i) => {
    const data = { ...updateEditedData };
    data.dataType = e;
    setUpdateEditedData(data);
    setHyomenAmt(e == DataType.Data ? data.totalDataPrice : data.totalEditPrice);
    props?.updateKouteiCal(data, WorkType.PaSurface);
  };

  const settingEditData = (arr, propsOrgData) => {
    let editDataArr = [];

    for (let i = 0; i < arr?.length; i++) {
      let subArr = arr[i];
      editDataArr.push({
        id: i,
        dandoriAmt: subArr.dandoriAmt,
        dandoriTime: subArr.dandoriTime,
        sagyoAmt: subArr.sagyoAmt,
        sagyoTime: subArr.sagyoTime,
        processDetailTypeNo: propsOrgData[i]?.processDetailTypeNo,
        sumAmt: subArr.dandoriAmt + subArr.sagyoAmt,
        sumTime: subArr.dandoriTime + subArr.sagyoTime,
        calMethod: subArr.calMethod,
        calInput: subArr.calInput,
        processDetailWorkTypeGroup: propsOrgData[i]?.processDetailWorkTypeGroup,
      });
    }
    return editDataArr;
  };

  const calculateTotals = (editDataArr) => {
    let sagyoAmtSum = 0;
    let dandoriAmtSum = 0;

    let sagyoTimeSec = 0;
    let dandoriTimeSec = 0;

    editDataArr?.forEach((element) => {
      sagyoAmtSum += element.sagyoAmt;
      dandoriAmtSum += element.dandoriAmt;
      sagyoTimeSec += element.sagyoTime;
      dandoriTimeSec += element.dandoriTime;
    });

    // sagyoAmtSum += parseInt(editDataArr[0].handelingAmt);
    // sagyoTimeSec += setTimetoSec(editDataArr[0].handelingTime);

    // let totalSum = Math.floor(sagyoAmtSum + dandoriAmtSum + editDataArr[0].kensaAmt);
    let totalSum = sagyoAmtSum + dandoriAmtSum;
    let sagyoTimeSum = sagyoTimeSec;
    let dandoriTimeSum = dandoriTimeSec;
    // let totalTimeSum = secondsToHms(sagyoTimeSec + dandoriTimeSec + setTimetoSec(editedKensaTime));
    let totalTimeSum = sagyoTimeSec + dandoriTimeSec;

    let totals = [];
    let i = 0;
    totals.push({
      id: i++,
      sagyoAmtSum: sagyoAmtSum,
      dandoriAmtSum: dandoriAmtSum,
      sagyoTimeSec: sagyoTimeSec,
      dandoriTimeSec: dandoriTimeSec,
      totalSum: totalSum,
      sagyoTimeSum: sagyoTimeSum,
      dandoriTimeSum: dandoriTimeSum,
      totalTimeSum: totalTimeSum,
    });
    return totals;
  };

  const contentDandori = (
    <div>
      {/* <p>
        段取金額=アルゴン段取金額＋ボルト段取金額
        <br /> {totalDandoriAmt} =
        {(() => {
          const subArr = [];
          for (let j = 0; j < editedData?.length; j++) {
            subArr.push(editedData[j].dandoriAmt);
          }
          return subArr.join('+');
        })()}
      </p>
      <p>
        段取時間=アルゴン段取時間＋ボルト段取時間
        <br /> {totalDandoriTime} =
        {(() => {
          const subArr = [];
          for (let j = 0; j < editedData?.length; j++) {
            subArr.push(editedData[j].dandoriTime);
          }
          return subArr.join('+');
        })()}
      </p> */}
    </div>
  );

  const contentSagyo = (
    <div>
      {/* <p>
        作業金額=アルゴン作業金額＋ボルト作業金額
        <br /> {totalSagyoAmt} =
        {(() => {
          const subArr = [];
          for (let j = 0; j < editedData?.length; j++) {
            subArr.push(editedData[j].sagyoAmt);
          }
          return subArr.join('+');
        })()}
      </p>
      <p>
        作業時間=アルゴン作業時間＋ボルト作業時間
        <br /> {totalSagyoTime} =
        {(() => {
          const subArr = [];
          for (let j = 0; j < editedData?.length; j++) {
            subArr.push(editedData[j].sagyoTime);
          }
          return subArr.join('+');
        })()}
      </p> */}
    </div>
  );

  const contentSub = (
    <div>
      <p>
        作業金額=チャージ金額 x 作業時間
        <br /> ¥233.3334 = ¥4,200(/h) x 200(秒)
      </p>
      <p>
        作業時間 = 加工長 / 加工速度 x 溶接難易度係数 x 製品サイズ係数 x 生産個数係数 <br />
        200(秒) = 200(mm) / 1(mm/秒) x 1 x 1 x 1
      </p>
      <p>
        引用パラメータ
        <br />
        加工長：200mm
        <br /> 加工速度：60mm/分 = 1mm/秒 <br />
        溶接難易度係数：1 <br />
        製品サイズ係数：1 <br />
        生産個数係数：1
      </p>
    </div>
  );

  const hyomen = () => {
    if (isHyomendetail == true) {
      setIHyomendetail(false);
    } else {
      setIHyomendetail(true);
    }
  };

  const setHyomenDisplay = (display) => {
    setIHyomendetail(display);
  };

  const subpartDetail = (i) => {
    let showHide = document.getElementById('hyomenProcess' + i).style.display;
    if (showHide == 'block') {
      document.getElementById('hyomenProcess' + i).style.display = 'none';
      document.getElementById('arrowDownHyomen' + i).style.display = 'block';
      document.getElementById('arrowUpHyomen' + i).style.display = 'none';
    } else {
      document.getElementById('hyomenProcess' + i).style.display = 'block';
      document.getElementById('arrowDownHyomen' + i).style.display = 'none';
      document.getElementById('arrowUpHyomen' + i).style.display = 'block';
    }
  };

  useImperativeHandle(ref, () => ({
    getKouteiCalInfoSurface: () => {
      return updateEditedData;
    },
    setHyomenDisplay: setHyomenDisplay,
  }));

  const settingNewDandoriAmtInArr = (value, index, objId, timeType, timeAct) => {
    let newState = [...editedData];
    setOldEditedData(JSON.parse(JSON.stringify(newState)));
    let oldValue = editedData[index][objId];
    newState[index][objId] = value;

    if (objId === 'dandoriTime') {
      if (document.getElementById(index + 'HyomenDandoriAmtCu').style.display !== undefined) {
        let showHideCu = document.getElementById(index + 'HyomenDandoriAmtCu').style.display;
        if (showHideCu === 'block') {
          checkInputWithColor(false, '', index + 'HyomenDandoriAmt', newState[index].dandoriAmt, 'red');
        } else {
          if (timeAct === 'input') {
            let timeType = value - oldValue;
            newState[index].dandoriAmt = changeAmtwithTime(newState[index].dandoriAmt, timeType, timeAct, value, index);
          } else {
            newState[index].dandoriAmt = changeAmtwithTime(newState[index].dandoriAmt, timeType, timeAct, value, index);
          }
          checkInputWithColor(false, '', index + 'HyomenDandoriAmt', newState[index].dandoriAmt, 'green');
        }
      }
      updateEditedData.editItems[index].dandoriAmt = newState[index].dandoriAmt;
      updateEditedData.editItems[index].dandoriTime = value;
    }

    if (objId === 'calInput') {
      /** 　加工金額/時間　*/
      // 加工金額設定
      // 面積
      let mWorkPrice = value / 10000; //平方メートルに変換
      let calMethod = newState[index].calMethod;
      let shori = newState[index].processDetailWorkTypeGroup;
      //装置の面積・重量時間チャージ
      let mWorkHRate =
        calMethod == '面積'
          ? deviceMst?.details?.charges[orgData[index].processDetailTypeNo].squareMeter
          : deviceMst?.details?.charges[orgData[index].processDetailTypeNo].weight;
      let sagyoAmt = mWorkPrice * mWorkHRate * (shori == '塗装(両面)' ? 2 : 1); //段階時間＊時間チャージ
      newState[index]['sagyoAmt'] = sagyoAmt;
      updateEditedData.editItems[index].sagyoAmt = sagyoAmt;
    }

    let newSagyoSum = 0;
    let newDandoriSum = 0;
    let newSum = 0;
    let newSubSum = 0;
    let newSagyoTimeSec = 0;
    let newDandoriTimeSec = 0;

    newState?.forEach((element) => {
      newSagyoSum += element.sagyoAmt ? Number(element.sagyoAmt) : 0;
      newDandoriSum += element.dandoriAmt ? Number(element.dandoriAmt) : 0;
      newSagyoTimeSec += element.sagyoTime ? element.sagyoTime : 0;
      newDandoriTimeSec += element.dandoriTime ? element.dandoriTime : 0;
    });

    newSum = newSagyoSum + newDandoriSum;

    let newSagyoTimeSum = newSagyoTimeSec;
    let newDandoriTimeSum = newDandoriTimeSec;
    let newTotalTimeSum = newSagyoTimeSec + newDandoriTimeSec;

    setTotalSagyoAmt(newSagyoSum);
    setTotalDandoriAmt(newDandoriSum);
    setTotalAmt(newSum);
    setTotalSagyoTime(newSagyoTimeSum);
    setTotalDandoriTime(newDandoriTimeSum);
    setTotalTime(newTotalTimeSum);
    updateEditedData.totalEditPrice = newSum;
    updateEditedData.totalEditTime = newTotalTimeSum;
    updateEditedData.totalDandori.editItemsAmt = newSum;
    updateEditedData.totalDandori.editItemsTime = newTotalTimeSum;

    setEditedData(newState);
    setHyomenAmt(
      updateEditedData.dataType == DataType.Data ? updateEditedData.totalDataPrice : updateEditedData.totalEditPrice
    );
    props?.updateKouteiCal(updateEditedData, WorkType.PaSurface);
  };

  const checkStr = (val) => {
    let intAmt;
    if (val === '') {
      intAmt = 0;
    } else if (typeof val === 'string') {
      intAmt = Number(val.charAt(0) == '¥' ? val.replace('¥', '') : val);
    } else intAmt = val;
    return intAmt;
  };

  const changeAmtwithTime = (amt, typeTime, action, timeVal, index) => {
    let editedAmt;
    let totalMin = Number(timeVal) / 60;
    let intAmt = checkStr(amt);

    if (action === 'input') {
      editedAmt = intAmt + typeTime;
    } else {
      //装置の段階時間チャージ
      let mSetHRate = deviceMst?.details?.charges[orgData[index].processDetailTypeNo].prep;
      editedAmt = (totalMin * mSetHRate) / 60.0; //段階時間＊時間チャージ
    }
    return editedAmt;
  };

  return (
    <div className="kouteiCalculateDiv" style={{ display: patternInfo?.length > 0 ? 'block' : 'none' }}>
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row " onClick={hyomen}>
        <Col span={4} className="oyabuhin-col">
          <div style={{ display: 'flex' }}>
            {isHyomendetail ? (
              <CaretUpOutlined style={{ fontSize: 17, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 17, marginTop: 2 }} />
            )}
            <label style={{ marginLeft: 5 }}>{processMst?.name}</label>
          </div>
        </Col>
        <Col span={6} className="oyabuhin-col">
          <div>{hyomenAmt ? JPYs.format(Math.floor(hyomenAmt)) : amtEmptyStr}</div>
        </Col>
        <Col span={13} style={{ border: 'none' }} className="oyabuhin-col">
          <div>
            <label style={{ marginLeft: 5 }}>{deviceMst?.name}</label>
          </div>
        </Col>
      </Row>
      <div style={{ display: isHyomendetail ? 'block' : 'none' }}>
        <Row
          style={{ color: '#1063aa', backgroundColor: '#d9d9d96b !important' }}
          className="oyabuhin-detail oyabuhin-row"
        >
          <Col span={4} className="oyabuhin-detail-col">
            工程詳細
          </Col>
          <Col span={6} className="oyabuhin-detail-col">
            <Checkbox
              key={kouteiCalculateSelectedArr[0].key}
              value={kouteiCalculateSelectedArr[0].value}
              checked={kouteiCalculateSelectedArr[0].key == updateEditedData.dataType}
              disabled={!props?.editMode}
              style={{ marginRight: 6 }}
              onChange={(e) => props?.editMode && handleChangeCheckData(kouteiCalculateSelectedArr[0].key)}
            ></Checkbox>
            <label>{kouteiCalculateSelectedArr[0].value}</label>
          </Col>
          <Col span={8} className="oyabuhin-detail-col">
            <Checkbox
              key={kouteiCalculateSelectedArr[1].key}
              value={kouteiCalculateSelectedArr[1].value}
              disabled={!props?.editMode}
              onChange={(e) => props?.editMode && handleChangeCheckData(kouteiCalculateSelectedArr[1].key)}
              checked={kouteiCalculateSelectedArr[1].key == updateEditedData.dataType}
              style={{ marginRight: 6 }}
            ></Checkbox>
            <label>{kouteiCalculateSelectedArr[1].value}</label>
          </Col>

          <Col span={1} className="oyabuhin-detail-col">
            単位
          </Col>
          <Col span={5} className="formula-column">
            計算式
          </Col>
        </Row>
        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col">
            {processMst?.name}
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalAmt">{totalSum ? JPYs.format(Math.floor(totalSum)) : amtEmptyStr}</label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalTime">{totalTimeSum ? secondsToHms(totalTimeSum) : timeHmsStr}</label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label
              id="totalAmtIn"
              className="resetColor"
              style={{ color: Math.floor(totalAmt) === Math.floor(totalSum) ? 'black' : '#4cc12f' }}
            >
              {totalAmt ? JPYs.format(Math.floor(totalAmt)) : amtEmptyStr}
            </label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label
              id="totalTimeIn"
              className="resetColor"
              style={{ color: totalTime === totalTimeSum ? 'black' : '#4cc12f' }}
            >
              {totalTime ? secondsToHms(totalTime) : timeHmsStr}
            </label>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={5} className="formula-column"></Col>
        </Row>
        {/* 段取金額/時間 */}
        <Row
          style={{ color: 'black' }}
          className="oyabuhin-detail oyabuhin-row"
          onClick={(e) => handleCollpse('IQ3HyomenDandori')}
        >
          <Col span={4} className="oyabuhin-detail-col">
            <div style={{ display: 'flex' }}>
              <CaretUpOutlined id={'arrowUpHyomenDandori'} style={{ fontSize: 17, marginTop: 2, display: 'none' }} />

              <CaretDownOutlined id={'arrowDownHyomenDandori'} style={{ fontSize: 17, marginTop: 2 }} />
              <label> 段取金額/時間</label>
            </div>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalDandoriAmt">{dandoriAmtSum ? JPYs.format(Math.floor(dandoriAmtSum)) : amtEmptyStr}</label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="hyoumenDandoriTimeSum">{dandoriTimeSum ? secondsToHms(dandoriTimeSum) : timeHmsStr}</label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label
              id="totalDandoriAmtIn"
              className="resetColor"
              style={{ color: Math.floor(dandoriAmtSum) === Math.floor(totalDandoriAmt) ? 'black' : '#4cc12f' }}
            >
              {totalDandoriAmt ? JPYs.format(Math.floor(totalDandoriAmt)) : amtEmptyStr}
            </label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label
              id="totalDandoriTimeIn"
              className="resetColor"
              style={{ color: dandoriTimeSum === totalDandoriTime ? 'black' : '#4cc12f' }}
            >
              {totalDandoriTime ? secondsToHms(totalDandoriTime) : timeHmsStr}
            </label>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={5} className="formula-column">
            <Popover content={contentDandori}>段取金額の総合計</Popover>
          </Col>
        </Row>
        <div id={'divIQ3HyomenDandori'} style={{ display: 'none' }}>
          {(() => {
            const arr = [];
            for (let j = 0; j < processArr?.length; j++) {
              arr.push(
                <div>
                  {/* ○○_標準 */}
                  <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                    <Col span={4} className="oyabuhin-detail-col">
                      <div style={{ display: 'flex' }}>
                        <span style={{ marginLeft: '20px' }}>{processArr[j]?.processDetailTypeNo}</span>
                      </div>
                    </Col>
                    <Col span={3} className="oyabuhin-detail-col">
                      <label id={j + 'HyomenDandoriAmt'}>
                        {processArr[j]?.dandoriAmt && processArr[j]?.dandoriAmt > 0
                          ? JPYs.format(Math.floor(processArr[j]?.dandoriAmt))
                          : amtEmptyStr}
                      </label>
                    </Col>
                    <Col span={3} className="oyabuhin-detail-col">
                      {
                        <label id={j + 'HyomenDndoriTime'}>
                          {processArr[j]?.dandoriTime ? secondsToHms(processArr[j]?.dandoriTime) : timeHmsStr}
                        </label>
                      }
                    </Col>

                    <Col span={4} className="input-col">
                      <CurrencyInput
                        id={j + 'HyomenDandoriAmtIn'}
                        value={editedData[j]?.dandoriAmt ? Math.floor(editedData[j]?.dandoriAmt) : 0}
                        defaultValue={0}
                        style={{
                          border: 'none',
                          color:
                            Math.floor(processArr[j]?.dandoriAmt) === Math.floor(editedData[j]?.dandoriAmt)
                              ? 'black'
                              : checkShowHideIcon(j + 'HyomenDandoriAmtCu') === 'none'
                              ? '#4cc12f'
                              : 'red',
                        }}
                        onValueChange={(e) => {
                          //checkRegex(e.target.value);
                          if (editedData[j]?.dandoriAmt != e) {
                            settingNewDandoriAmtInArr(e, j, 'dandoriAmt', '', '');
                            checkInputWithColor(false, '', j + 'HyomenDandoriAmt', e, 'amt');
                          }
                        }}
                        className={
                          props?.editMode
                            ? 'input-editable currency resetColor'
                            : 'input-non-editable currency resetColor'
                        }
                        prefix="¥"
                        decimalsLimit={100}
                      />
                      <div id={j + 'HyomenDandoriAmtCu'} style={{ display: 'none' }}>
                        <div className="currencyIcon">
                          <BsUsbC style={{ fontSize: '25' }} />
                          <BsX style={{ margin: '-8px 0 0 16px' }} />
                          <span class="tooltiptext">
                            金額に変更がありましたので、以降時間と連携して自動計算されない。
                          </span>
                        </div>
                      </div>
                    </Col>

                    <Col span={4} className="input-col">
                      <Row style={{ width: '100%' }}>
                        <Col className="timer-input-col">
                          <Input
                            value={editedData[j]?.dandoriTime ? secondsToHms(editedData[j]?.dandoriTime) : timeHmsStr}
                            id={j + 'HyomenDandoriTimeIn'}
                            style={{
                              width: '100%',
                              color:
                                processArr[j]?.dandoriTime === editedData[j]?.dandoriTime
                                  ? handleShowHide(
                                      Number(editedData[j]?.dandoriAmt),
                                      editedData[j]?.dandoriAmt,
                                      j + 'HyomenDandoriAmtCu'
                                    )
                                  : 'red',
                            }}
                            className={props?.editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                            onChange={(e) => {
                              settingNewDandoriAmtInArr(e.target.value, j, 'HyomendandoriAmt', 'input', 'input');
                            }}
                          ></Input>
                        </Col>
                        <Col className="timer-button-col">
                          {props?.editMode ? (
                            <>
                              <CaretUpOutlined
                                style={{ fontSize: 17 + 'px' }}
                                onClick={() => {
                                  let editedTime = handelIncreaseTime(
                                    editedData[j]?.dandoriTime,
                                    '10min',
                                    j + 'HyomenDandoriTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', '10min', 'inc');
                                }}
                              />
                              <CaretDownOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelDecreaseTime(
                                    editedData[j]?.dandoriTime,
                                    '10min',
                                    j + 'HyomenDandoriTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', '10min', 'dec');
                                }}
                              />
                              <CaretUpOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelIncreaseTime(
                                    editedData[j]?.dandoriTime,
                                    'min',
                                    j + 'HyomenDandoriTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'min', 'inc');
                                }}
                              />
                              <CaretDownOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelDecreaseTime(
                                    editedData[j]?.dandoriTime,
                                    'min',
                                    j + 'HyomenDandoriTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'min', 'dec');
                                }}
                              />
                              <CaretUpOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelIncreaseTime(
                                    editedData[j]?.dandoriTime,
                                    'sec',
                                    j + 'HyomenDandoriTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'sec', 'inc');
                                }}
                              />
                              <CaretDownOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelDecreaseTime(
                                    editedData[j]?.dandoriTime,
                                    'sec',
                                    j + 'HyomenDandoriTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'sec', 'dec');
                                }}
                              />
                            </>
                          ) : (
                            <></>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col span={1} className="oyabuhin-detail-col"></Col>
                    <Col span={5} className="formula-column"></Col>
                  </Row>
                </div>
              );
            }
            return arr;
          })()}
        </div>
        {/*  作業金額/時間 */}
        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col" onClick={(e) => handleCollpse('IQ3HyomenSagyoTotal')}>
            <div style={{ display: 'flex' }}>
              <CaretUpOutlined id={'arrowUpHyomenSagyoTotal'} style={{ fontSize: 17, marginTop: 2, display: 'none' }} />

              <CaretDownOutlined id={'arrowDownHyomenSagyoTotal'} style={{ fontSize: 17, marginTop: 2 }} />

              <label> 加工金額</label>
            </div>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalSagyoAmt">{sagyoAmtSum ? JPYs.format(Math.floor(sagyoAmtSum)) : amtEmptyStr}</label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            {/* <label id="totalSagyoTime">{sagyoTimeSum}</label> */}
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label
              id="totalSagyoAmtIn"
              className="resetColor"
              style={{ color: Math.floor(totalSagyoAmt) === Math.floor(sagyoAmtSum) ? 'black' : '#4cc12f' }}
            >
              {totalSagyoAmt ? JPYs.format(Math.floor(totalSagyoAmt)) : amtEmptyStr}
            </label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalSagyoTimeIn" className="resetColor" style={{ display: 'none' }}>
              {totalSagyoTime}
            </label>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={5} className="formula-column">
            <Popover content={contentSagyo}>作業金額の総合計</Popover>
          </Col>
        </Row>
        <div id={'divIQ3HyomenSagyoTotal'} style={{ display: 'none' }}>
          {(() => {
            const arr = [];

            for (let i = 0; i < editedData?.length; i++) {
              arr.push(
                <div>
                  <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                    <Col span={4} className="oyabuhin-detail-col" onClick={(e) => subpartDetail(i)}>
                      <div style={{ display: 'flex', marginLeft: '20px' }}>
                        <CaretUpOutlined id={'arrowUpHyomen' + i} style={{ fontSize: 17, marginTop: 2 }} />
                        <CaretDownOutlined
                          id={'arrowDownHyomen' + i}
                          style={{ fontSize: 17, marginTop: 2, display: 'none' }}
                        />
                        <span>{processArr[i]?.processName}</span>
                      </div>
                    </Col>
                    <Col span={3} className="oyabuhin-detail-col" onClick={(e) => subpartDetail(i)}>
                      {/* ¥ */}
                      <label id={i + 'hyomenSagyoAmt'}>
                        {processArr[i]?.sagyoAmt ? JPYs.format(Math.floor(processArr[i]?.sagyoAmt)) : ''}
                      </label>
                    </Col>
                    <Col span={3} className="oyabuhin-detail-col" onClick={(e) => subpartDetail(i)}>
                      {/*  <label id={i + 'hyomenSagyoTime'}>{processArr[i]?.sagyoTime}</label> */}
                    </Col>
                    <Col span={4} className="input-col">
                      <CurrencyInput
                        id={i + 'hyomenSagyoAmtIn'}
                        value={editedData[i]?.sagyoAmt ? Math.floor(editedData[i]?.sagyoAmt) : 0}
                        defaultValue={0}
                        onValueChange={(e) => {
                          if (Math.floor(editedData[i]?.sagyoAmt) != e) {
                            settingNewDandoriAmtInArr(e, i, 'sagyoAmt', '', '');
                            checkInputWithColor(false, '', i + 'hyomenDandoriAmt', e, 'amt');
                          }
                        }}
                        style={{
                          border: 'none',
                          color:
                            Math.floor(processArr[i]?.sagyoAmt) === Math.floor(editedData[i]?.sagyoAmt)
                              ? 'black'
                              : oldEditedData[i]?.calInput === editedData[i]?.calInput
                              ? 'red'
                              : '#4cc12f',
                        }}
                        className={
                          props?.editMode
                            ? 'input-editable currency resetColor'
                            : 'input-non-editable currency resetColor'
                        }
                        prefix="¥"
                        decimalsLimit={100}
                      />
                    </Col>
                    <Col span={4} className="input-col" onClick={(e) => subpartDetail(i)}>
                      {/* <Row style={{ width: '100%' }}>
                    <Col className="timer-input-col">
                      <Input
                        value={editedData[i]?.sagyoTime}
                        id={i + 'hyomenSagyoTimeIn'}
                        style={{ width: '100%' }}
                        className={props?.editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                        onChange={(e) => {
                          settingNewDandoriAmtInArr(e.target.value, j, 'sagyoTime', 'input', 'input');
                        }}
                      ></Input>
                    </Col>
                    <Col className="timer-button-col">
                      {props?.editMode ? (
                        <>
                          <CaretUpOutlined
                            style={{ fontSize: 17 + 'px' }}
                            onClick={() => {
                              let editedTime = handelIncreaseTime(
                                editedData[i]?.sagyoTime,
                                '10min',
                                i + 'hyomenSagyoTime'
                              );
                              settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', '10min', 'inc');
                            }}
                          />
                          <CaretDownOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelDecreaseTime(
                                editedData[i]?.sagyoTime,
                                '10min',
                                i + 'hyomenSagyoTime'
                              );
                              settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', '10min', 'dec');
                            }}
                          />
                          <CaretUpOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelIncreaseTime(
                                editedData[i]?.sagyoTime,
                                'min',
                                i + 'hyomenSagyoTime'
                              );
                              settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', 'min', 'inc');
                            }}
                          />
                          <CaretDownOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelDecreaseTime(
                                editedData[i]?.sagyoTime,
                                'min',
                                i + 'hyomenSagyoTime'
                              );
                              settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', 'min', 'dec');
                            }}
                          />
                          <CaretUpOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelIncreaseTime(
                                editedData[i]?.sagyoTime,
                                'sec',
                                i + 'hyomenSagyoTime'
                              );
                              settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', 'sec', 'inc');
                            }}
                          />
                          <CaretDownOutlined
                            style={{ fontSize: 17 + 'px', color: '#000' }}
                            onClick={() => {
                              let editedTime = handelDecreaseTime(
                                editedData[i]?.sagyoTime,
                                'sec',
                                i + 'hyomenSagyoTime'
                              );
                              settingNewDandoriAmtInArr(editedTime, i, 'sagyoTime', 'sec', 'dec');
                            }}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row> */}
                    </Col>
                    <Col span={1} className="oyabuhin-detail-col" onClick={(e) => subpartDetail(i)}></Col>
                    <Col span={5} className="formula-column" onClick={(e) => subpartDetail(i)}></Col>
                  </Row>
                  <div id={'hyomenProcess' + i} style={{ display: 'none' }}>
                    <div>
                      {/* 作業金額/時間 */}

                      <div>
                        {/* 計算法 /入力 */}
                        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                          <Col span={4} className="oyabuhin-detail-col">
                            <span className="ddl_2_noImg" style={{ marginLeft: 38 }}>
                              計算法 /入力
                            </span>
                          </Col>
                          <Col span={3} className="oyabuhin-detail-col">
                            <label id={i + 'calMethod'}>{processArr[i]?.calMethod}</label>
                          </Col>
                          <Col span={3} className="oyabuhin-detail-col">
                            <label id={i + 'calInput'}>{processArr[i]?.calInput}</label>
                          </Col>

                          <Col span={4} className="oyabuhin-detail-col">
                            <label id={i + 'calMethodIn'}>{editedData[i]?.calMethod}</label>
                          </Col>

                          <Col span={4} className="input-col">
                            <Input
                              value={editedData[i]?.calInput}
                              id={i + 'calInputIn'}
                              min={0}
                              className={props?.editMode ? 'input-editable' : 'input-non-editable '}
                              controls={false}
                              style={{
                                width: '45%',
                                border: 'none',
                                color:
                                  Number(editedData[i]?.calInput) === Number(processArr[i]?.calInput) ? 'black' : 'red',
                              }}
                              onKeyPress={(e) => {
                                handleKeyPress(e, false);
                              }}
                              onChange={(e) => settingNewDandoriAmtInArr(e.target.value, i, 'calInput', '', '')}
                            />
                          </Col>
                          <Col span={1} className="oyabuhin-detail-col"></Col>
                          <Col span={4} className="formula-column"></Col>
                        </Row>

                        {/*  処理選択 */}
                        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                          <Col span={4} className="oyabuhin-detail-col">
                            <span className="ddl_2_noImg" style={{ marginLeft: 38 }}>
                              処理選択
                            </span>
                          </Col>

                          <Col span={3} className="oyabuhin-detail-col">
                            <label id={i + 'shori'}>{processArr[i]?.processDetailWorkTypeGroup}</label>
                          </Col>
                          <Col span={3} className="oyabuhin-detail-col">
                            <label id={i + 'processType'}>{processArr[i]?.processDetailTypeNo}</label>
                          </Col>

                          <Col span={4} className="oyabuhin-detail-col">
                            <label id={i + 'shoriIn'}>{processArr[i]?.processDetailWorkTypeGroup}</label>
                          </Col>

                          <Col span={4} className="oyabuhin-detail-col">
                            <label id={i + 'processTypeIn'}>{processArr[i]?.processDetailTypeNo}</label>
                          </Col>

                          <Col span={1} className="oyabuhin-detail-col"></Col>
                          <Col span={5} className="formula-column"></Col>
                        </Row>

                        {/* 加工種類/仮付箇所 */}
                        {/*   <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                      <Col span={4} className="oyabuhin-detail-col">
                        <span className="ddl_2_noImg">加工種類/仮付箇所</span>
                      </Col>
                      <Col span={3} className="oyabuhin-detail-col">
                        <label id="proType">{processArr[i]?.processDetailTypeNo}</label>
                      </Col>
                      <Col span={3} className="oyabuhin-detail-col">
                        <label id={i + 'subCount'}>{processArr[i]?.subCount}</label>
                      </Col>

                      {props?.editMode ? (
                        <Col span={4} className="input-col">
                          <Select
                            defaultValue={editedData[i]?.processDetailTypeNo}
                            style={{
                              width: '100%',
                              margin: '0 5px',
                              fontSize: 14,
                            }}
                            id="proTypeIn"
                            onChange={(e) => settingNewDandoriAmtInArr(e, i, 'processType', '', '')}
                            options={hyomensArr?.map((province) => ({
                              label: province,
                              value: province,
                            }))}
                          />
                        </Col>
                      ) : (
                        <Col span={4} className="oyabuhin-detail-col">
                          <label id="proTypeIn">{editedData[i]?.processDetailTypeNo}</label>
                        </Col>
                      )}

                      <Col span={4} className="input-col">
                        <Input
                          value={editedData[i]?.subCount}
                          id={i + 'subCountIn'}
                          min={0}
                          className={props?.editMode ? 'input-editable' : 'input-non-editable '}
                          controls={false}
                          style={{
                            width: '45%',
                            border: 'none',
                            color: Number(editedData[i]?.subCount) === processArr[i]?.subCount ? 'black' : 'red',
                          }}
                          onKeyPress={handleKeyPress}
                          onChange={(e) => settingNewDandoriAmtInArr(e.target.value, i, 'subCount', '', '')}
                        />
                      </Col>
                      <Col span={1} className="oyabuhin-detail-col"></Col>
                      <Col span={4} className="formula-column"></Col>
                    </Row> */}

                        {/*  加工回数/溶接仕上 */}
                        {/* <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                      <Col span={4} className="oyabuhin-detail-col">
                        <span className="ddl_2_noImg">加工回数/溶接仕上</span>
                      </Col>

                      <Col span={3} className="oyabuhin-detail-col">
                        <label id={i + 'subTimekari'}>{processArr[i]?.subTimekari}</label>
                      </Col>
                      <Col span={3} className="oyabuhin-detail-col">
                        <label id={i + 'subFinishKari'}>{processArr[i]?.subFinishKari}</label>
                      </Col>
                      <Col span={4} className="input-col">
                        <Input
                          value={editedData[i]?.subTimekari}
                          id={i + 'subTimekariIn'}
                          min={0}
                          className={props?.editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                          controls={false}
                          style={{
                            width: '45%',
                            border: 'none',
                            color:
                              Number(editedData[i]?.subTimekari) === processArr[i]?.subTimekari ? 'black' : 'red',
                          }}
                          onKeyPress={handleKeyPress}
                          onChange={(e) => settingNewDandoriAmtInArr(e.target.value, i, 'subTimekari', '', '')}
                        />
                      </Col>

                      {props?.editMode ? (
                        <Col span={4} className="input-col">
                          <Select
                            defaultValue={editedData[i]?.subFinishKari}
                            style={{
                              width: '100%',
                              margin: '0 5px',
                              fontSize: 14,
                            }}
                            id={i + 'subFinishKariIn'}
                            onChange={(e) => settingNewDandoriAmtInArr(e, i, 'subFinishKari', '', '')}
                            options={finishData?.map((finish) => ({ label: finish, value: finish }))}
                          />
                        </Col>
                      ) : (
                        <Col span={4} className="oyabuhin-detail-col">
                          <label id={i + 'subFinishKariIn'}>{editedData[i]?.subFinishKari}</label>
                        </Col>
                      )}
                      <Col span={1} className="oyabuhin-detail-col"></Col>
                      <Col span={5} className="formula-column"></Col>
                    </Row> */}
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
            return arr;
          })()}
          {/* <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
        <Col span={4} className="oyabuhin-detail-col">
          <span style={{ marginLeft: '5px', fontSize: 11 }}>マテリアルハンドリング金額/時間</span>
        </Col>
        <Col span={3} className="oyabuhin-detail-col">
       
          <label id="HyomenHandlingAmt">{JPYs.format(handelingAmt)}</label>
        </Col>
        <Col span={3} className="oyabuhin-detail-col">
          <label id="hyomenHandelingTime">{handelingTime}</label>
        </Col>
        <Col span={4} className="input-col">
          <CurrencyInput
            id="HyomenHandlingAmtIn"
            value={editedData[0]?.handelingAmt}
            defaultValue={0}
            onValueChange={(e) => {
              if (editedData[0]?.handelingAmt != e) {
               
                settingNewDandoriAmtInArr(e, 0, 'handelingAmt', '', '');
                checkInputWithColor(false,'','HyomenHandlingAmt', e, 'amt');
              }
            }}
            style={{ border: 'none' }}
            className={props?.editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'}
            prefix="¥"
            decimalsLimit={100}
          />

          <div id={'HyomenHandlingAmtCu'} style={{ display: 'none' }} className="currencyReset">
            <div className="currencyIcon">
              <BsUsbC style={{ fontSize: '25' }} />
              <BsX style={{ margin: '-8px 0 0 16px' }} />
              <span class="tooltiptext">金額に変更がありましたので、以降時間と連携して自動計算されない。</span>
            </div>
          </div>
        </Col>
        <Col span={4} className="input-col">
          <Row style={{ width: '100%' }}>
            <Col className="timer-input-col">
              <Input
                value={editedData[0]?.handelingTime}
                id="HyomenHandlingTimeIn"
                style={{
                  width: '100%',
                  color:
                    editedData[0]?.handelingTime === handelingTime
                      ? handleShowHide(
                          Number(handelingAmt),
                          Number(editedData[0]?.handelingAmt),
                          'HyomenHandlingAmtCu'
                        )
                      : 'red',
                }}
                className={props?.editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                onChange={(e) => {
                  settingNewDandoriAmtInArr(e.target.value, 0, 'handelingTime', 'input', 'input');
                }}
              ></Input>
            </Col>
            <Col className="timer-button-col">
              {props?.editMode ? (
                <>
                  <CaretUpOutlined
                    style={{ fontSize: 17 + 'px' }}
                    onClick={() => {
                      let editedTime = handelIncreaseTime(
                        editedData[0]?.handelingTime,
                        '10min',
                        'HyomenHandlingTime'
                      );
                      settingNewDandoriAmtInArr(editedTime, 0, 'handelingTime', '10min', 'inc');
                    }}
                  />
                  <CaretDownOutlined
                    style={{ fontSize: 17 + 'px', color: '#000' }}
                    onClick={() => {
                      let editedTime = handelDecreaseTime(
                        editedData[0]?.handelingTime,
                        '10min',
                        'HyomenHandlingTime'
                      );
                      settingNewDandoriAmtInArr(editedTime, 0, 'handelingTime', '10min', 'dec');
                    }}
                  />
                  <CaretUpOutlined
                    style={{ fontSize: 17 + 'px', color: '#000' }}
                    onClick={() => {
                      let editedTime = handelIncreaseTime(
                        editedData[0]?.handelingTime,
                        'min',
                        'HyomenHandlingTime'
                      );
                      settingNewDandoriAmtInArr(editedTime, 0, 'handelingTime', 'min', 'inc');
                    }}
                  />
                  <CaretDownOutlined
                    style={{ fontSize: 17 + 'px', color: '#000' }}
                    onClick={() => {
                      let editedTime = handelDecreaseTime(
                        editedData[0]?.handelingTime,
                        'min',
                        'HyomenHandlingTime'
                      );
                      settingNewDandoriAmtInArr(editedTime, 0, 'handelingTime', 'min', 'dec');
                    }}
                  />
                  <CaretUpOutlined
                    style={{ fontSize: 17 + 'px', color: '#000' }}
                    onClick={() => {
                      let editedTime = handelIncreaseTime(
                        editedData[0]?.handelingTime,
                        'sec',
                        'HyomenHandlingTime'
                      );
                      settingNewDandoriAmtInArr(editedTime, 0, 'handelingTime', 'sec', 'inc');
                    }}
                  />
                  <CaretDownOutlined
                    style={{ fontSize: 17 + 'px', color: '#000' }}
                    onClick={() => {
                      let editedTime = handelDecreaseTime(
                        editedData[0]?.handelingTime,
                        'sec',
                        'hyomenHandelingTime'
                      );
                      settingNewDandoriAmtInArr(editedTime, 0, 'handelingTime', 'sec', 'dec');
                    }}
                  />
                </>
              ) : (
                <></>
              )}
            </Col>
          </Row>
        </Col>
        <Col span={1} className="oyabuhin-detail-col"></Col>
        <Col span={5} className="formula-column"></Col>
      </Row> */}
        </div>
        {/* <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
      <Col span={4} className="oyabuhin-detail-col">
        <span style={{ marginLeft: '5px' }}>工程内検査金額/時間</span>
      </Col>
      <Col span={3} className="oyabuhin-detail-col">
        <label id="HyomenKensaAmt">{JPYs.format(kensaAmt)}</label>
      </Col>
      <Col span={3} className="oyabuhin-detail-col">
        <label id="HyomenKensaTime">{kensaTime}</label>
      </Col>
      <Col span={4} className="input-col">
        <CurrencyInput
          id="HyomenKensaAmtIn"
          value={editedData[0]?.kensaAmt}
          defaultValue={0}
          onValueChange={(e) => {
            if (editedData[0]?.kensaAmt != e) {
              settingNewDandoriAmtInArr(e, 0, 'kensaAmt', '', '');
              checkInputWithColor(false,'','HyomenKensaAmt', e, 'amt');
            }
          }}
          style={{
            border: 'none',
            color:
              Number(editedData[0]?.kensaAmt) === kensaAmt
                ? 'black'
                : checkShowHideIcon('HyomenKensaAmtCu') === 'none'
                ? '#4cc12f'
                : 'red',
          }}
          className={props?.editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'}
          prefix="¥"
          decimalsLimit={100}
        />
        <div id="HyomenKensaAmtCu" style={{ display: 'none' }} className="currencyReset">
          <div className="currencyIcon">
            <BsUsbC style={{ fontSize: '25' }} />
            <BsX style={{ margin: '-8px 0 0 16px' }} />
            <span class="tooltiptext">金額に変更がありましたので、以降時間と連携して自動計算されない。</span>
          </div>
        </div>
      </Col>
      <Col span={4} className="input-col">
        <Row style={{ width: '100%' }}>
          <Col className="timer-input-col">
            <Input
              value={editedData[0]?.kensaTime}
              id="HyomenKensaTimeIn"
              style={{
                width: '100%',
                color:
                  editedData[0]?.kensaTime === kensaTime
                    ? handleShowHide(Number(kensaAmt), Number(editedData[0]?.kensaAmt), 'HyomenKensaAmtCu')
                    : 'red',
              }}
              className={props?.editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
              onChange={(e) => {
                settingNewDandoriAmtInArr(e.target.value, 0, 'kensaTime', 'input', 'input');
              }}
            ></Input>
          </Col>
          <Col className="timer-button-col">
            {props?.editMode ? (
              <>
                <CaretUpOutlined
                  style={{ fontSize: 17 + 'px' }}
                  onClick={() => {
                    let editedTime = handelIncreaseTime(editedData[0]?.kensaTime, '10min', 'hyomenKensaTime');
                    settingNewDandoriAmtInArr(editedTime, 0, 'kensaTime', '10min', 'inc');
                  }}
                />
                <CaretDownOutlined
                  style={{ fontSize: 17 + 'px', color: '#000' }}
                  onClick={() => {
                    let editedTime = handelDecreaseTime(editedData[0]?.kensaTime, '10min', 'hyomenKensaTime');
                    settingNewDandoriAmtInArr(editedTime, 0, 'kensaTime', '10min', 'dec');
                  }}
                />
                <CaretUpOutlined
                  style={{ fontSize: 17 + 'px', color: '#000' }}
                  onClick={() => {
                    let editedTime = handelIncreaseTime(editedData[0]?.kensaTime, 'min', 'hyomenKensaTime');
                    settingNewDandoriAmtInArr(editedTime, 0, 'kensaTime', 'min', 'inc');
                  }}
                />
                <CaretDownOutlined
                  style={{ fontSize: 17 + 'px', color: '#000' }}
                  onClick={() => {
                    let editedTime = handelDecreaseTime(editedData[0]?.kensaTime, 'min', 'hyomenKensaTime');
                    settingNewDandoriAmtInArr(editedTime, 0, 'kensaTime', 'min', 'dec');
                  }}
                />
                <CaretUpOutlined
                  style={{ fontSize: 17 + 'px', color: '#000' }}
                  onClick={() => {
                    let editedTime = handelIncreaseTime(editedData[0]?.kensaTime, 'sec', 'hyomenKensaTime');
                    settingNewDandoriAmtInArr(editedTime, 0, 'kensaTime', 'sec', 'inc');
                  }}
                />
                <CaretDownOutlined
                  style={{ fontSize: 17 + 'px', color: '#000' }}
                  onClick={() => {
                    let editedTime = handelDecreaseTime(editedData[0]?.kensaTime, 'sec', 'hyomenKensaTime');
                    settingNewDandoriAmtInArr(editedTime, 0, 'kensaTime', 'sec', 'dec');
                  }}
                />
              </>
            ) : (
              <></>
            )}
          </Col>
        </Row>
      </Col>
      <Col span={1} className="oyabuhin-detail-col"></Col>
      <Col span={5} className="formula-column"></Col>
    </Row> */}
      </div>
    </div>
  );
});

export default KouteiCalculate_Hyomen;
