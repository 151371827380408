/**
 * クラス名：板金子部品見積詳細
 * 説明：板板金子部品見積詳細ファイルである。
 * 作成者：ナンス
 * 作成日：2023/03/01
 * バージョン：1.0
 */
import React, { useImperativeHandle, forwardRef, useEffect, useRef, useState } from 'react';
import { Input, Form, Row, Col, Select } from 'antd';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';

import {
  getMaterialNameById,
  getMaterialTypeById,
  getMaterialSurfaceById,
  getMaterialThickById,
  isDigit,
  handleKeyPress,
  JPYs,
} from '../common/Common.js';
import { getMaterialTypeIQ3Info, getCardDetailImg } from '../common/CommonAPI';
import { ErrorMessage } from '../common/Message.js';
import { error } from '../common/CommonModal.js';
import { amtEmptyStr } from '../common/Constant.js';
import { async } from 'q';

const IQ3EstimateDetail = forwardRef((props, ref) => {
  const location = useLocation();
  const [rowKey, setRowKey] = useState(0);
  const [partNo, setPartNo] = useState('');
  const [img, setImg] = useState('');
  const [drawingNo, setDrawingNo] = useState('');
  const [name, setName] = useState('');
  const [patternName, setPatternName] = useState('');
  const [partCount, setPartCount] = useState(1);
  const [materialTypeId, setMaterialTypeId] = useState(0);
  const [thickness, setThickness] = useState('');
  const [materialId, setMaterialId] = useState(0);
  const [materialSurfaceId, setMaterialSurfaceId] = useState([]);
  const [weight, setWeight] = useState('');
  const [surfaceArea, setSurfaceArea] = useState('');
  const [materialCost, setMaterialCost] = useState('');
  const [processCost, setProcessCost] = useState('');
  const [purchaseCost, setPurchaseCost] = useState('');
  const [purchaseTotalCost, setPurchaseTotalCost] = useState('');
  const [surfaceCost, setSurfaceCost] = useState('');
  const [surfaceTotalCost, setSurfaceTotalCost] = useState('');
  const [costPrice, setCostPrice] = useState('');
  const [estimatedAmount, setEstimatedAmount] = useState('');
  const [totalCostPrice, setTotalCostPrice] = useState('');
  const [totalEstimatedAmount, setTotalEstimatedAmount] = useState('');
  const [costProfit, setCostProfit] = useState('');
  const [estimateProfit, setEstimateProfit] = useState('');
  const [profitRate, setProfitRate] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [detailData, setDetailData] = useState([]);
  const [totalMaterialCost, setTotalMaterialCost] = useState('');
  const [totalProcessCost, setTotalProcessCost] = useState('');
  const [materialLst, setMaterialLst] = useState([]);
  const [materialTypeLst, setMaterialTypeLst] = useState([]);
  const [materialSizeLst, setMaterialSizeLst] = useState([]);
  const [materialSurfaceLst, setMaterialSurfaceLst] = useState([]);
  const inputItemRef = {};

  useEffect(() => {
    const childPartDetail = props.selectedIQ3DataDetail;
    setDetailData({ ...childPartDetail });
    if (childPartDetail) {
      setImg(childPartDetail.imageId);
      setPartNo(childPartDetail.no);
      setDrawingNo(childPartDetail.drawingNo);
      setName(childPartDetail.name);
      setPatternName(childPartDetail.processSelect?.name);
      setPartCount(childPartDetail.partCount);
      setMaterialTypeId(childPartDetail.materialTypeIq3Id);
      setThickness(childPartDetail.thickness);
      setMaterialId(childPartDetail.materialIq3Id);
      setWeight(childPartDetail.weight);
      setSurfaceArea(childPartDetail.totalSurfaceArea);
      setMaterialCost(childPartDetail.materialCostUnitPrice);
      setTotalMaterialCost(childPartDetail.materialCostTotalPrice);
      setProcessCost(childPartDetail.workCostUnitPrice);
      setTotalProcessCost(childPartDetail.workCostTotalPrice);
      setPurchaseCost(childPartDetail.purchaseUnitPrice);
      setPurchaseTotalCost(childPartDetail.purchaseTotalPrice);
      setSurfaceCost(childPartDetail.surfaceUnitPrice);
      setSurfaceTotalCost(childPartDetail.surfaceTotalPrice);
      setCostPrice(childPartDetail.costUnitPrice);
      setEstimatedAmount(childPartDetail.costTotalPrice);
      setTotalCostPrice(childPartDetail.costTotalPrice);
      setTotalEstimatedAmount(childPartDetail.totalEstimatedAmount);
      setCostProfit(childPartDetail.costProfit);
      setEstimateProfit(childPartDetail.estimateProfit);
      setProfitRate(childPartDetail.profitRate);
      setMaterialSurfaceId(childPartDetail.materialSurfaceIq3Id);
    }
  }, [props.selectedIQ3DataDetail]);

  useEffect(() => {
    // const childPartDetail = props.selectedIQ3DataDetail;
    let materialList = props.materialLst;
    //材料名称リスト
    setMaterialLst(materialList);

    //板厚リスト
    // if (childPartDetail == undefined || childPartDetail == null) {
    //   setMaterialSizeLst(materialNameList);
    // } else {
    //   let materialSizeLst = materialNameList?.filter((item) => item.key == childPartDetail.materialNm);
    //   setMaterialSizeLst(materialSizeLst);
    // }
    //表面保護
    const materialSurfaceData = props.materialSurfaceLst;
    setMaterialSurfaceLst(materialSurfaceData);
    // 材質名称リスト
    setMaterialTypeLst(props.materialTypeLst);
  }, [props.materialLst, props.materialTypeLst, props.materialSurfaceLst]);

  // 材質マスタ情報を取得する
  // const getMaterialTypeIQ3Data = async () => {
  //   let materialTypeLst = await getMaterialTypeIQ3Info();
  //   setMaterialTypeLst(materialTypeLst);
  // };

  // 値を変更した場合、
  const onUpdateDetail = (name, value) => {
    detailData[name] = value;
    if (name == 'partCount') {
      let costPrice = detailData.costPrice * detailData.partCount;
      let totalCostPrice = detailData.workCostUnitPrice * detailData.partCount;
      detailData['costPrice'] = costPrice == undefined ? 0 : costPrice;
      detailData['totalCostPrice'] = totalCostPrice == undefined ? 0 : totalCostPrice;
    }
  };

  // 材質を変更した時、材料、板厚、表面保護の情報をフィルターする
  const filteredInfoByMaterialType = (materialTypeId) => {
    // 材料情報
    let materialList = materialLst?.filter((item) => item.info.isUsed && item.materialTypeIq3Id === materialTypeId);
    let materialId = 0;
    let materialSurfaceId = 0;
    if (materialList?.length > 0) {
      materialId = materialList[0]?.id;
    }
    setThickness(materialId); // 板厚
    setMaterialId(materialId);
    detailData['thickness'] = materialId;
    detailData['materialIq3Id'] = materialId;
    detailData['materialName'] = getMaterialNameById(materialId, materialLst);
    // 材料表面情報
    let materialSurfaceList = materialSurfaceLst?.filter(
      (item) => item.info.isUsed && item.materialTypeIq3Id === materialTypeId
    );
    if (materialSurfaceList?.length > 0) {
      materialSurfaceId = materialSurfaceList[0]?.id;
    }
    setMaterialSurfaceId(materialSurfaceId);
    detailData['materialSurfaceIq3Id'] = materialSurfaceId;
    detailData['materialSurfaceName'] = getMaterialSurfaceById(materialSurfaceId, materialSurfaceLst);
  };

  useImperativeHandle(
    ref,
    () => ({
      getIQ3EstimateDetailInfo: () => {
        return detailData;
      },
      getIQ3EstimateInfo: () => {
        return parentEstimateInfo;
      },
      formValidation: formValidation,
    }),
    [detailData]
  );

  // 画面の入力チェック
  const formValidation = () => {
    let ret = true;
    let message = [];
    let input;
    // 員数
    input = inputItemRef['partCount']?.input;
    input?.classList?.remove('input-error');
    if (input?.value?.length <= 0) {
      // 必須
      message.push(<li>{ErrorMessage('iQ3板金・基本情報の員数').E007}</li>);
      input.classList.add('input-error');
      ret = false;
    }
    if (input?.value?.length > 0 && !isDigit(input?.value)) {
      message.push(<li>{ErrorMessage('iQ3板金・基本情報の員数').E009}</li>);
      input.classList.add('input-error');
      ret = false;
    }

    // 材質
    input = document.getElementsByName('materialTypeId')?.[0];
    input?.childNodes?.[0]?.classList?.remove('input-error');
    if (input?.innerText?.length <= 0) {
      // 必須
      message.push(<li>{ErrorMessage('iQ3板金・基本情報の材質').E017}</li>);
      input?.childNodes?.[0]?.classList.add('input-error');
      ret = false;
    }

    // 材料名称
    input = document.getElementsByName('materialId')?.[0];
    input?.childNodes?.[0]?.classList?.remove('input-error');
    if (input?.innerText?.length <= 0) {
      // 必須
      message.push(<li>{ErrorMessage('iQ3板金・基本情報の材料名称').E017}</li>);
      input?.childNodes?.[0]?.classList.add('input-error');
      ret = false;
    }

    // 板厚
    input = document.getElementsByName('thickness')?.[0];
    input?.childNodes?.[0]?.classList?.remove('input-error');
    if (input?.innerText?.length <= 0) {
      // 必須
      message.push(<li>{ErrorMessage('iQ3板金・基本情報の板厚').E017}</li>);
      input?.childNodes?.[0]?.classList.add('input-error');
      ret = false;
    }

    // 表面保護
    input = document.getElementsByName('materialSurface')?.[0];
    input?.childNodes?.[0]?.classList?.remove('input-error');
    if (input?.innerText?.length <= 0) {
      // 必須
      message.push(<li>{ErrorMessage('iQ3板金・基本情報の表面保護').E017}</li>);
      input?.childNodes?.[0]?.classList.add('input-error');
      ret = false;
    }

    if (message?.length > 0) error(<ul>{message}</ul>);
    return ret;
  };

  return (
    <>
      <div style={{ display: 'flex', float: 'left', width: '100%', paddingTop: 5 }}>
        {/* 部品プロパティアリア */}
        <div style={{ width: '50%' }}>
          {' '}
          <Table className="propertiesTbl">
            <thead>
              <tr>
                <td className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">No.</label>
                </td>
                <td className="detail-tbl-val">
                  <Input
                    style={{ padding: 5, marginLeft: 5, width: '98.2%' }}
                    className="input-non-editable "
                    name="partNo"
                    id="partNo"
                    value={partNo}
                    type="text"
                  />
                </td>
              </tr>
              <tr>
                <td className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">子部品図番</label>
                </td>
                <td className="detail-tbl-val">
                  <Input
                    style={{ padding: 5, marginLeft: 5, width: '98.2%' }}
                    // className={editMode ? 'input-editable' : 'input-non-editable '}
                    className="input-editable"
                    name="drawingNo"
                    value={drawingNo}
                    // onChange={(e) => setImgNo(e.target.value)}
                    onChange={(e) => {
                      setDrawingNo(e.target.value);
                      onUpdateDetail(e.target.name, e.target.value);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">子部品名称</label>
                </td>
                <td className="detail-tbl-val">
                  <Input
                    style={{ padding: 5, marginLeft: 5, width: '98.2%' }}
                    // className={editMode ? 'input-editable ' : 'input-non-editable '}
                    className="input-editable"
                    name="name"
                    value={name}
                    // onChange={(e) => setPartNm(e.target.value)}
                    onChange={(e) => {
                      setName(e.target.value);
                      onUpdateDetail(e.target.name, e.target.value);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">工程パターン</label>
                </td>

                <td className="detail-tbl-val">
                  <Input
                    style={{ padding: 5, marginLeft: 5, width: '98.2%' }}
                    className={'input-non-editable '}
                    name="patternName"
                    id="patternName"
                    type="text"
                    value={patternName}
                    // onChange={(e) => {
                    //   setProcessNm(e.target.value);
                    //
                    //   onUpdateDetail(e.target.name, e.target.value);
                    // }}
                  />
                </td>
              </tr>
              <tr>
                <td className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">員数</label>
                </td>
                <td className="detail-tbl-val">
                  <Input
                    style={{ padding: 5, marginLeft: 5, width: '35.5%' }}
                    // className={editMode ? 'input-editable ' : 'input-non-editable '}
                    className="input-editable"
                    name="partCount"
                    value={partCount}
                    onKeyPress={(e) => {
                      handleKeyPress(e, true);
                    }}
                    ref={(ref) => {
                      if (ref) inputItemRef['partCount'] = ref;
                    }}
                    onChange={(e) => {
                      setPartCount(e.target.value);
                      onUpdateDetail(e.target.name, e.target.value);
                    }}
                    // disabled
                  />
                </td>
              </tr>
              <tr>
                <td className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">材質</label>
                </td>
                <td className="detail-tbl-val">
                  {/* {editMode ? ( */}
                  <Select
                    className="iq3-detail-select"
                    name="materialTypeId"
                    value={materialTypeId ? materialTypeId : ''}
                    // onChange={(e) => {
                    //   setMaterial(e);
                    // }}
                    onChange={(e) => {
                      setMaterialTypeId(e);
                      detailData['materialTypeName'] = getMaterialTypeById(e, materialTypeLst);
                      onUpdateDetail('materialTypeIq3Id', e);
                      filteredInfoByMaterialType(e);
                    }}
                    style={{ marginLeft: 5, width: '98.2%' }}
                  >
                    {materialTypeLst
                      ?.filter((item) => materialLst?.findIndex((mItem) => mItem.materialTypeIq3Id === item.id) > -1)
                      ?.map((option, index) => (
                        <Select.Option key={index} value={option.id}>
                          {option.name}
                        </Select.Option>
                      ))}
                  </Select>
                  {/* ) : (
                    <label style={{ fontSize: '13px', paddingLeft: 10 }}>
                      {getMaterialTypeById(material, materialTypeLst)}
                    </label>
                  )} */}
                </td>
              </tr>
              <tr>
                <td className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">材料名称</label>
                </td>
                <td className="detail-tbl-val">
                  {/* {editMode ? ( */}
                  <Select
                    className="iq3-detail-select"
                    name="materialId"
                    value={materialId ? materialId : ''}
                    onChange={(e) => {
                      setMaterialId(e);
                      detailData['materialName'] = getMaterialNameById(e, materialLst);
                      onUpdateDetail('materialIq3Id', e);
                      setThickness(e);
                      onUpdateDetail('thickness', e);
                    }}
                    style={{ marginLeft: 5, width: '98.2%' }}
                  >
                    {materialLst
                      ?.filter((item) => item.info.isUsed && item.materialTypeIq3Id === materialTypeId)
                      ?.map((option, index) => (
                        <Select.Option key={index} value={option.id}>
                          {option.name}
                        </Select.Option>
                      ))}
                  </Select>
                  {/* ) : (
                    <label style={{ fontSize: '13px', paddingLeft: 10 }}>
                      {getMaterialNameById(materialNm, materialNmLst)}
                    </label>
                  )} */}
                </td>
              </tr>

              <tr>
                <td className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">板厚</label>
                </td>
                <td className="detail-tbl-val">
                  {/* {editMode ? ( */}
                  <Select
                    name="thickness"
                    className="iq3-detail-select"
                    value={thickness ? thickness : ''}
                    onChange={(e) => {
                      setThickness(e);
                      onUpdateDetail('thickness', e);
                      setMaterialId(e);
                      detailData['materialName'] = getMaterialNameById(e, materialLst);
                      onUpdateDetail('materialIq3Id', e);
                    }}
                    style={{ marginLeft: 5, width: '35.5%' }}
                  >
                    {materialLst
                      ?.filter((item) => item.info.isUsed && item.materialTypeIq3Id === materialTypeId)
                      ?.map((option, index) => (
                        <Select.Option key={index} value={option.id}>
                          {option.info.thick}
                        </Select.Option>
                      ))}
                  </Select>
                  {/* ) : (
                    <Input
                      style={{ padding: 5, marginLeft: 5, width: '98.2%' }}
                      className={'input-non-editable '}
                      type="text"
                      value={getMaterialThickById(thickness, materialNmLst)}
                    />
                  )} */}
                </td>
              </tr>
              <tr>
                <td className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">表面保護</label>
                </td>
                <td className="detail-tbl-val">
                  {/* {editMode ? ( */}
                  <Select
                    name="materialSurface"
                    className="iq3-detail-select"
                    value={materialSurfaceId ? materialSurfaceId : ''}
                    onChange={(e) => {
                      setMaterialSurfaceId(e);
                      detailData['materialSurfaceName'] = getMaterialSurfaceById(e, materialSurfaceLst);
                      onUpdateDetail('materialSurfaceIq3Id', e);
                    }}
                    style={{ marginLeft: 5, width: '35.5%' }}
                  >
                    {materialSurfaceLst
                      ?.filter((item) => item.info.isUsed && item.materialTypeIq3Id === materialTypeId)
                      ?.map((option, index) => (
                        <Select.Option key={index} value={option.id}>
                          {option.name}
                        </Select.Option>
                      ))}
                  </Select>
                  {/* ) : (
                    <Input
                      style={{ padding: 5, marginLeft: 5, width: '98.2%' }}
                      className={'input-non-editable '}
                      type="text"
                      value={getMaterialSurfaceById(materialSurface, materialSurfaceLst)}
                    />
                  )} */}
                </td>
              </tr>
              <tr>
                <td className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">重量(kg)</label>
                </td>
                <td className="detail-tbl-val">
                  <Input
                    style={{ padding: 5, marginLeft: 5, width: '98.2%' }}
                    className={'input-non-editable '}
                    type="text"
                    value={weight ? Math.floor(weight).toFixed(2) : 0}
                  />
                </td>
              </tr>
              <tr>
                <td className="detail-tbl-val-title">
                  <label className="detail-tbl-val-title-label">表面積(㎠)</label>
                </td>
                <td className="detail-tbl-val">
                  <Input
                    style={{ padding: 5, marginLeft: 5, width: '98.2%' }}
                    className={'input-non-editable '}
                    type="text"
                    value={surfaceArea ? Math.floor(surfaceArea).toFixed(2) : 0}
                  />
                </td>
              </tr>
            </thead>
          </Table>
        </div>
        {/* 原価 */}
        <div style={{ marginLeft: '10px', width: '30%' }}>
          {/** 原価*/}
          <Row style={{ height: '45px', paddingTop: '20px' }}>
            <Col span={24}>【原価】</Col>
          </Row>
          <Row
            style={{
              backgroundColor: '#1063aa',
              color: 'white',
              height: '45px',
              paddingTop: '3px',
              textAlign: 'center',
              paddingTop: '10px',
            }}
          >
            <Col span={8}>
              <p style={{ margin: 0, fontSize: '13px' }}></p>
            </Col>
            <Col span={8}>
              <p style={{ margin: 0, fontSize: '13px' }}>単品</p>
            </Col>
            <Col span={8}>
              <p style={{ margin: 0, fontSize: '13px' }}>合計</p>
            </Col>
          </Row>
          <Row
            style={{
              paddingLeft: '5px',
              height: '45px',
              paddingTop: '10px',
              border: 'solid',
              borderWidth: 1,
              borderColor: '#a5a5a5',
            }}
          >
            <Col span={6}>
              <p style={{ margin: 0, fontSize: '13px' }}>原価</p>
            </Col>
            <Col span={9}>
              <p style={{ margin: 0, fontSize: '13px', textAlign: 'right', paddingRight: '40px' }}>
                {costPrice ? JPYs.format(Math.floor(costPrice)) : amtEmptyStr}
              </p>
            </Col>
            <Col span={9}>
              <p style={{ margin: 0, fontSize: '13px', textAlign: 'right', paddingRight: '10px' }}>
                {totalCostPrice ? JPYs.format(Math.floor(totalCostPrice)) : amtEmptyStr}
              </p>
            </Col>
          </Row>

          {/** 原価詳細*/}
          <Row style={{ height: '45px', paddingTop: '20px' }}>
            <Col span={24}>【原価内訳】</Col>
          </Row>
          <Row
            style={{
              backgroundColor: '#1063aa',
              color: 'white',
              height: '45px',
              paddingTop: '3px',
              textAlign: 'center',
              paddingTop: '10px',
            }}
          >
            <Col span={8}>
              <p style={{ margin: 0, fontSize: '13px' }}></p>
            </Col>
            <Col span={8}>
              <p style={{ margin: 0, fontSize: '13px' }}>単品</p>
            </Col>
            <Col span={8}>
              <p style={{ margin: 0, fontSize: '13px' }}>合計</p>
            </Col>
          </Row>
          <Row
            style={{
              paddingLeft: '5px',
              height: '45px',
              /* paddingTop: '10px', */
              border: 'solid',
              borderWidth: 0.5,
              borderColor: '#a5a5a5',
              borderBottom: 0,
              borderBottomWidth: 0,
              borderBottomColor: 'none',
              verticalAlign: 'middle',
              display: 'flex',
              flexWrap: 'wrap',
              alignContent: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Col span={6}>
              <p style={{ margin: 0, fontSize: '13px' }}>材料原価</p>
            </Col>
            <Col span={9}>
              <p style={{ margin: 0, fontSize: '13px', textAlign: 'right', paddingRight: '40px' }}>
                {totalCostPrice ? JPYs.format(Math.floor(materialCost)) : amtEmptyStr}
              </p>
            </Col>
            <Col span={9}>
              <p style={{ margin: 0, fontSize: '13px', textAlign: 'right', paddingRight: '10px' }}>
                {totalMaterialCost ? JPYs.format(Math.floor(totalMaterialCost)) : amtEmptyStr}
              </p>
            </Col>
          </Row>
          <Row
            style={{
              paddingLeft: '5px',
              height: '45px',
              /*  paddingTop: '10px', */
              border: 'solid',
              borderWidth: 0.5,
              borderColor: '#a5a5a5',
              borderBottom: 0,
              borderBottomWidth: 0,
              borderBottomColor: 'none',
            }}
          >
            <Col span={6}>
              <p style={{ margin: 0, fontSize: '13px', textAlign: 'center', width: 'fit-content' }}>
                製造原価
                <br />
                (表面処理費)
              </p>
            </Col>
            <Col span={9}>
              <p
                style={{
                  margin: 0,
                  fontSize: '13px',
                  textAlign: 'right',
                  paddingRight: '40px',
                  textAlign: 'center',
                  width: 'fit-content',
                  float: 'right',
                }}
              >
                {processCost ? JPYs.format(Math.floor(processCost)) : amtEmptyStr}
                <br />
                {surfaceCost ? JPYs.format(Math.floor(surfaceCost)) : amtEmptyStr}
              </p>
            </Col>
            <Col span={9}>
              <p
                style={{
                  margin: 0,
                  fontSize: '13px',
                  textAlign: 'right',
                  paddingRight: '10px',
                  textAlign: 'center',
                  width: 'fit-content',
                  float: 'right',
                }}
              >
                {totalProcessCost ? JPYs.format(Math.floor(totalProcessCost)) : amtEmptyStr} <br />
                {surfaceTotalCost ? JPYs.format(Math.floor(surfaceTotalCost)) : amtEmptyStr}
              </p>
            </Col>
          </Row>
          <Row
            style={{
              paddingLeft: '5px',
              height: '45px',
              paddingTop: '10px',
              border: 'solid',
              borderWidth: 0.5,
              borderColor: '#a5a5a5',
            }}
          >
            <Col span={6}>
              <p style={{ margin: 0, fontSize: '13px' }}>購入品費</p>
            </Col>
            <Col span={9}>
              <p
                style={{
                  margin: 0,
                  fontSize: '13px',
                  textAlign: 'right',
                  paddingRight: '40px',
                  textAlign: 'center',
                  width: 'fit-content',
                  float: 'right',
                }}
              >
                {purchaseCost ? JPYs.format(Math.floor(purchaseCost)) : amtEmptyStr}
              </p>
            </Col>
            <Col span={9}>
              <p
                style={{
                  margin: 0,
                  fontSize: '13px',
                  textAlign: 'right',
                  paddingRight: '10px',
                  textAlign: 'center',
                  width: 'fit-content',
                  float: 'right',
                }}
              >
                {purchaseTotalCost ? JPYs.format(Math.floor(purchaseTotalCost)) : amtEmptyStr}
              </p>
            </Col>
          </Row>
        </div>
        {/* イメージアリア */}
        <div
          style={{
            width: '20%',
            marginLeft: '10px',
            marginTop: '23px',
          }}
        >
          <Row>
            <Col span={24}>【イメージ】</Col>
          </Row>
          <div className="detailImage">{<img src={img} alt="" width={270} />}</div>
        </div>
      </div>
    </>
  );
});

export default IQ3EstimateDetail;
