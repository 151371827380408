import React, { Component, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Table } from 'react-bootstrap';
import { Row, Input } from 'antd';

const IQ3KouteiInput_Program = forwardRef((props, ref) => {
  const [programVal, setProgramVal] = useState({});
  const [element, setElement] = useState(0);
  const [elementAuto, setElementAuto] = useState(0);

  // 編集モード
  // const editMode = props.editMode;

  useEffect(() => {
    let totalCount = 0;
    if (props.blankInfo != undefined) {
      let blankList = props?.blankInfo?.details?.[0]?.quantityList;
      totalCount = blankList?.reduce((total, item) => {
        const countValue = parseInt(item.count, 10);
        return total + (countValue === 0 ? 1 : countValue);
      }, 0);
    }
    let programValues = props.programVal;
    // if (props.sizeX != 0 && props.sizeY != 0) {
    // programValues.details[0].elements = 4 + totalCount;
    // programValues.details[0].elementsAuto = 4 + totalCount;
    //   programValues.details[0].elements = 4 + programValues.details[0].elements;
    //   programValues.details[0].elementsAuto = 4 + programValues.details[0].elementsAuto;
    //   setElement(programValues.details[0]?.elements);
    //   setElementAuto(programValues.details[0]?.elementsAuto);
    // }
    //  else {
    //   programValues.details[0].elements = 0 + totalCount;
    //   programValues.details[0].elementsAuto = 0 + totalCount;
    //   setElement(programValues.details[0]?.elements);
    //   setElementAuto(programValues.details[0]?.elementsAuto);
    // }
    setElement(programValues?.details?.[0]?.elements);
    setElementAuto(programValues?.details?.[0]?.elementsAuto);
  }, [props.programVal, props.sizeX, props.sizeY, props.blankInfo]);

  useImperativeHandle(ref, () => ({
    setEditModeChange: setEditModeChange,
  }));
  // const changeValue = (e, name) => {
  //   const updatedData = programVal?.map((row) => {
  //     return { ...row, [name]: e.target.value };
  //   });
  //   setProgramVal(updatedData);
  // };
  // const changeValue = (e) => {
  //   const { name, value } = e.target;
  //   const newProgram = { ...props.programVal };

  //   newProgram.details[0].elements = parseFloat(value) || 0;
  //   setElement(parseFloat(value));
  //   props.updateProgram(newProgram);
  // };
  return (
    <>
      <Row className="mt-1 registerModal">
        <Table className="kouteiinput" style={{ width: '22%' }}>
          <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
            <tr style={{ textAlign: 'center' }}>
              <th style={{ width: '22%' }} className="tbl-header">
                要素数
              </th>
              {/* <th style={{ width: '22%' }} className="tbl-header">
                自動抽出(要素数)
              </th> */}
            </tr>
          </thead>

          <tbody>
            {/* {programVal?.map((i) => (
              <> */}
            <tr>
              <td>
                <Input
                  // className={editMode ? 'input-editable ' : 'input-non-editable '}
                  className="input-editable"
                  value={element}
                  onChange={(e) => {
                    setElement(e.target.value);
                    props.programVal.details[0].elements = e.target.value;
                    props.updateProgram(props.programVal);
                  }}
                  // onChange={changeValue}
                  // onChange={(e) => changeValue(e, 'elements')}
                  style={{ textAlign: 'center', height: 28 }}
                ></Input>
              </td>
              {/* <td>
                <Input
                  // className={editMode ? 'input-editable ' : 'input-non-editable '}
                  className="input-non-editable"
                  value={elementAuto}
                  // onChange={(e) => {
                  //   setElementAuto(e.target.value);
                  //   props.programVal.details[0].elementsAuto = e.target.value;
                  //   props.updateProgram(props.programVal);
                  // }}
                  style={{ textAlign: 'center', height: 28 }}
                ></Input>
              </td> */}
            </tr>
            {/* </>
            ))} */}
          </tbody>
        </Table>
      </Row>
    </>
  );
});

export default IQ3KouteiInput_Program;
