import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import {
  Table,
  Form,
  Button,
  Row,
  Input,
  Col,
  Modal,
  Select,
  Checkbox,
  Space,
  Image,
  Tooltip,
  Menu,
  Upload,
  Radio,
  DatePicker,
} from 'antd';
import dayjs from 'dayjs';
import * as XLSX from 'xlsx';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { RightCircleFilled, LeftCircleFilled, PlusCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { StatusCodes } from 'http-status-codes';
import { useNavigate, Route, Routes } from 'react-router-dom';
import { arrayMoveImmutable } from 'array-move';
import { Table as RTable } from 'react-bootstrap';
// Content resize
import SplitPane from 'split-pane-react';
import 'split-pane-react/esm/themes/default.css';

import ParentListDetail from './ParentListDetail';
import { WithRouter } from '../../components/WithRouter';
import '../../assets/styles/common.css';
import new_icon from '../../assets/images/new_icon.png';
import child_delete_icon from '../../assets/images/child_delete_icon.png';
import call_detail from '../../assets/images/call_detail.png';
import export_csv_icon from '../../assets/images/export_csv_icon.png';
import copy from '../../assets/images/copy.png';
import jisen_register from '../../assets/images/jisen_register.png';
import detail_confirm from '../../assets/images/detail_confirm.png';
import display_setting from '../../assets/images/display_setting.png';
import detail_display from '../../assets/images/detail_display.png';
import edit_list from '../../assets/images/edit_list.png';
import search_list from '../../assets/images/search_list.png';
import export_report from '../../assets/images/export_report.png';
import filter_clear_icon from '../../assets/images/filter_clear_icon.png';
import analyze from '../../assets/images/analyze.png';
import edit_info from '../../assets/images/edit_info.png';
import search from '../../assets/images/search.png';
import Sorting from '../common/Sorting';
import KouteibetsuInfo from './KouteibetsuInfo';
import {
  ResizableTitle,
  getAccessToken,
  getTenjikaiSampleData,
  pageSizeOptions,
  toDecimal,
  updateAccessToken,
  JPYs,
  secondsToHms,
  formatDate,
  productionManageCSVFileHeaders,
  formatDateString,
  todayTime,
  kouteiMasterTypes,
} from '../common/Common';
import {
  commonTaiochuModal,
  confirmHakiContent,
  confirmUpdateContent,
  confirmDiscardContent,
  confirmScreenChangeContent,
  confirmModalTitle,
  confirmScreenChangeTitle,
  commonModal,
  commonFooter,
  confirmDisplayDataSaveContent,
  commonDeleteFooter,
  authorizeError,
  error,
  exportComplete,
} from '../common/CommonModal';
import {
  createEstimateProductInfo,
  getEstimateIq3Info,
  getEstimateProductInfo,
  getEstimateStatusData,
  updateEstimateProductInfo,
} from '../common/CommonAPI';
import SearchAndGetCustomerData from '../common/SearchAndGetCustomerData';
import { Customer } from '../common/Constant';
import {
  ClientType,
  CustomerFilterType,
  DataType,
  DisplayColumnsGroup,
  PrintTypes,
  ServiceClass,
  WorkType,
} from '../common/enums';
import { ErrorMessage } from '../common/Message';
import {
  getExistCompanySetting,
  getExcelDatabyUseType,
  getS3FileContent,
  getPurchaseCategoryInfo,
  getProcessInfo,
} from '../common/CommonAPI';
import { CSVLink } from 'react-csv';
import { async } from 'q';
import { tab } from '@testing-library/user-event/dist/tab';

dayjs.extend(customParseFormat);
const iconWidth = 30;
const now = new Date();
const date = `${now.getFullYear()}/${now.getMonth() + 1}/${now.getDate()}`;
const { Search } = Input;
// 一覧に表示中の行カウント数
let displayTotalCount = 0;

const ParentList = forwardRef((props, ref) => {
  const [hasData, setHasData] = useState(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [form] = Form.useForm();

  const [selectedData, setSelectedData] = React.useState(0);

  const [hasCheckData, setCheckData] = useState(true);

  const [childTitle, setChildTitle] = useState('子部品確認');

  const [isCopy, setIsCopy] = useState(false);

  // 表示設定
  const [isDisplay, setIsDisplay] = useState(false); // ポップアップ表示／未表示
  const [value, setValue] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [chkData, setChkData] = useState([]); // ポップアップでチェックしている表示する項目

  // csv export
  const [csvExportData, setCSVExportData] = useState(0);
  const [initialTblItems, setInitialTableItems] = useState([]); // 初期表示に表示する項目
  const [initialTblItemsBk, setInitialTableItemsBk] = useState([]); // 初期表示に表示する項目
  const [olddata, setOldData] = useState(0);
  const [drawingNoWidth, setDrawingNoWidth] = useState(140);

  // まとめて編集
  const [isUpdate, setIsUpdate] = useState(false);
  const [chkrowscount, setChkRowsCount] = useState(0);

  const [updateItem, setUpdateItem] = useState([]);

  // 子部品確認
  const [isChildOpen, setIsChildOpen] = useState(false);

  // 加工確認
  const [isManufactureOpen, setIsManufactureOpen] = useState(false);

  const [selectedEstimateData, setSelectedEstimateData] = useState([]);

  // 削除
  const [isDelete, setIsDelete] = useState(false);

  // 帳票出力
  const [isReportOutputOpen, setIsReportOutputOpen] = useState(false);

  // リスト出力
  const [isListOutputOpen, setIsListOutputOpen] = useState(false);

  // 事前登録
  const [preRegister, setPreRegister] = useState(false);

  // 絞込み設定
  const [isFilter, setIsFilter] = useState(false);
  const [checkedState, setCheckedState] = useState([]);
  const [inputVal, setInputVal] = useState([]);
  // 絞り込み設定のON・OFFチェックボックス値
  const [filterCheckedData, setFilterCheckedData] = useState([]);
  // 絞り込み設定の入力値
  const [filterInputData, setFilterInputData] = useState([]);

  //  全てクリア
  const [isAllClear, setIsAllClear] = useState(false);

  const [isUpdateData, setIsUpdateData] = useState(false);

  // 絞込み破棄
  const [isDiscardData, setIsDiscardData] = useState(false);

  // add row
  const [rows, initRow] = useState([]);

  // 絞込み・お客様のチェックボックス値
  const [customerRdo, setCustomerRdo] = useState(2);
  const [customerRdoBk, setCustomerRdoBk] = useState(2);

  // Content Resize
  const [sizes, setSizes] = useState(['50%']);
  const [btnShowHide, setBtnShowHide] = useState(true);

  const [sortingData, setSortingData] = useState([]);

  const [datesData, setDatesData] = useState([]);
  const [labelsData, setLabelsData] = useState([]);
  const [memoData, setMemoData] = useState([]);
  const [costData, setCostData] = useState([]);

  // 検索アリア
  const [customer, setCustomer] = useState('');
  const [imageNum, setImageNum] = useState('');
  const [name, setName] = useState('');
  const [keyword, setKeywords] = useState('');

  // 開発中ポップアップ
  const [openCommonTaiochuPopup, setOpenCommonTaiochuPopup] = useState(false);

  const [editMode, setEditMode] = useState(false);
  const [editModeCancelConfirm, setEditModeCancelConfirm] = useState(false);
  const [currentRecordKey, setCurrentRecordKey] = useState(0);
  const [currentRecord, setCurrentRecord] = useState([]);

  const [confirmScreenChangeModalOpen, setConfirmScreenChangeModalOpen] = useState(false);

  const [clickedAction, setClickedAction] = useState(0);

  // 子部品確認画面のselected key
  const [childSelectedKey, setChildSelectedKey] = useState(0);

  const [page, setPage] = useState(1);

  // マスタの情報
  const [orderOptions, setOrderOptions] = useState([]); // 案件状態
  const [companyInfo, setCompanyInfo] = useState([]); // 会社情報
  const [clientInfo, setClientInfo] = useState([]); // お客様情報
  const [userInfo, setUserInfo] = useState({}); // ログインユーザー情報
  const [staffInfo, setStaffInfo] = useState([]); // 担当者情報
  const [processInfo, setProcessInfo] = useState([]); // 工程マスタ情報

  const [confirmDisplayDataSaveModalOpen, setConfirmDisplayDataSaveModalOpen] = useState(false);

  //複写編集項目
  const [customerCode, setCustomerCode] = useState('');
  const [drawingNo, setDrawingNo] = useState('');
  const [parentEstimateName, setParentEstimateName] = useState('');
  const [estimateStatus, setEstimateStatus] = useState('');
  const [customerModal, setCustomerModal] = useState(false);

  // 並び替え項目
  const [sortingColumns, setSortingColumns] = useState([]);

  // リスト帳票
  const [excelListFormats, setExcelListFormats] = useState([]);
  const [selectedExeclListFormat, setSelectedExeclListFormat] = useState();
  const [excelIndividualFormats, setExcelIndividualFormats] = useState([]);
  const [selectedExeclIndividualFormat, setSelectedExeclIndividualFormat] = useState();
  const [selectedExcelListTypeFileId, setSelectedExcelListTypeFileId] = useState();

  const [shouldExportCSV, setShouldExportCSV] = useState(false);
  const [htmlContent, setHtmlContent] = useState([]);
  const [isListReport, setIsListReport] = useState(false);
  const [isIndividualReport, setIsIndividualReport] = useState(false);
  const [purchaseCategoryInfos, setPurchaseCategoryInfos] = useState([]);

  const navigate = useNavigate();

  const sortingRef = useRef();

  const csvFile = useRef();
  const [estimateProductsData, setEstimateProductsData] = useState([]);
  const [exportColumns, setExportColumn] = useState([]);
  const [modifiedChkColumns, setModifiedChkColumns] = useState([]);

  const csvFileName = `親部品見積_${formatDateString(todayTime())}?.csv`;
  const [fileName, setFileName] = useState(`親部品見積_${formatDateString(todayTime())}?.csv`);
  const productionManageCSVFile = useRef();
  const [estimateProductsProductionManageData, setEstimateProductsProductionManageData] = useState([]);
  const [productionManageCSVFileName, setProductionManageCSVFileName] = useState(
    `親部品見積(生産管理連携)_${formatDateString(todayTime())}?.csv`
  );

  const [showEdtConfirmOK, setShowEdtConfirmOK] = useState();
  const [showEdtConfirmCancle, setShowEdtConfirmCancle] = useState();

  const [quantity, setQuantity] = useState(1);
  const [selectedIq3Data, setSelectedIq3Data] = useState([]);

  const rowdata = {
    drawingno: '',
    name: '',
    customercode: '',
    customername: '',
    lotno: 1,
    order: '受注',
  };

  const tenjikaiSampleData = getTenjikaiSampleData();

  const loginCompanayInfo = props?.companyInfo;
  // 費用
  const paramCostLst = loginCompanayInfo?.[0]?.additionalItemCosts?.filter((item) => item.isUsed && !item.isDeleted);
  const costLstData = paramCostLst?.length > 0 ? paramCostLst : [];

  // ラベル
  const paramLabelLst = loginCompanayInfo?.[0]?.additionalItemLabels?.filter((item) => item.isUsed && !item.isDeleted);
  const labelLstData = paramLabelLst?.length > 0 ? paramLabelLst : [];

  // メモ
  const paramMemoLst = loginCompanayInfo?.[0]?.additionalItemMemos?.filter((item) => item.isUsed && !item.isDeleted);
  const memoLstData = paramMemoLst?.length > 0 ? paramMemoLst : [];

  // 日付
  const paramDateLst = loginCompanayInfo?.[0]?.additionalItemDates?.filter((item) => item.isUsed && !item.isDeleted);
  const dateLstData = paramDateLst?.length > 0 ? paramDateLst : [];

  // 費用
  const hiyouColumns = costLstData
    ?.map((paramCost, index) => {
      return {
        id: paramCost?.id,
        title: paramCost.name,
        dataIndex: 'epCustomCosts',
        key: 'totalPrice',
        width: 100 + 'px',
        className: 'cm-a-right',
        render: (record) => {
          const targetCostLst = record?.find((item) => item?.additionalItemId === paramCost?.id);
          return targetCostLst ? <> {JPYs.format(targetCostLst.unitPrice)}</> : <> {JPYs.format(0)}</>;
        },
      };
    })
    ?.flat();
  const temphiyouColumns = hiyouColumns;

  // ラベル
  const labelColumns = labelLstData
    ?.map((paramLabel, index) => {
      return {
        id: paramLabel?.id,
        title: paramLabel.name,
        dataIndex: 'epCustomLabels',
        key: 'label',
        width: 100 + 'px',
        render: (record) => {
          const targetLabelLst = record?.find((item) => item?.additionalItemId === paramLabel?.id);
          return targetLabelLst ? <> {targetLabelLst.label}</> : <></>;
        },
      };
    })
    ?.flat();
  const templabelColumns = labelColumns;

  // メモ
  const memoColumns = memoLstData
    ?.map((paramMemo, index) => {
      return {
        id: paramMemo?.id,
        title: paramMemo.name,
        dataIndex: 'epCustomMemos',
        key: 'memo',
        width: 100 + 'px',
        render: (record) => {
          const targetMemoLst = record?.find((item) => item?.additionalItemId === paramMemo?.id);
          return targetMemoLst ? <> {targetMemoLst.memo}</> : <></>;
        },
      };
    })
    ?.flat();
  const tempmemoColumns = memoColumns;

  // 日付
  const dateColumns = dateLstData
    ?.map((paramDate, index) => {
      return {
        id: paramDate?.id,
        title: paramDate.name,
        dataIndex: 'epCustomDates',
        key: 'date',
        width: 100 + 'px',
        render: (record) => {
          const targetDateLst = record?.find((item) => item?.additionalItemId === paramDate?.id);
          return targetDateLst ? <> {formatDate(targetDateLst.date)}</> : <></>;
        },
      };
    })
    ?.flat();
  const tempdateColumns = dateColumns;

  // テーブル幅Resizable
  const components = {
    header: {
      cell: ResizableTitle,
    },
  };

  const iq3Columns = [
    {
      id: '1',
      title: 'No.',
      dataIndex: 'no',
      width: 50 + 'px',
    },
    {
      id: '2',
      title: '図番',
      dataIndex: 'drawingNo',
      //width: 140 + 'px',
      width: drawingNoWidth,
      onHeaderCell: (column) => ({
        width: column.width,
        onResize: (e, { size }) => {
          if (isChildOpen) {
            setDrawingNoWidth(size.width);
          }
        },
      }),
    },
    {
      id: '3',
      title: '名称',
      dataIndex: 'partNm',
      width: 150 + 'px',
    },
    {
      id: '4',
      title: '工程パターン',
      dataIndex: 'processNm',
      width: 120 + 'px',
    },
    {
      id: '5',
      title: '員数',
      dataIndex: 'partCnt',
      width: 55 + 'px',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>{record.partCnt}</label>
        </div>
      ),
    },
    {
      id: '6',
      title: '材質',
      dataIndex: 'material',
      width: 150 + 'px',
    },
    {
      id: '7',
      title: '材料名',
      dataIndex: 'materialNm',
      width: 150 + 'px',
    },
    {
      id: '8',
      title: '板厚',
      dataIndex: 'thickness',
      width: 60 + 'px',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>{record.thickness}</label>
        </div>
      ),
    },
    {
      id: '9',
      title: 'サイズX',
      dataIndex: 'sizeX',
      width: 70 + 'px',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>{record.sizeX}</label>
        </div>
      ),
    },
    {
      id: '10',
      title: 'サイズY',
      dataIndex: 'sizeY',
      width: 70 + 'px',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>{record.sizeY}</label>
        </div>
      ),
    },
    {
      id: '11',
      title: '重量',
      dataIndex: 'weight',
      width: 100 + 'px',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>{record.weight}</label>
        </div>
      ),
    },
    {
      id: '12',
      title: '面積',
      dataIndex: 'surfaceArea',
      width: 100 + 'px',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>{record.surfaceArea}</label>
        </div>
      ),
    },
    {
      id: '13',
      title: '材料費',
      dataIndex: 'materialCost',
      width: 100 + 'px',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>{record.materialCost}</label>
        </div>
      ),
    },
    {
      id: '14',
      title: '加工費',
      dataIndex: 'processCost',
      width: 100 + 'px',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>{record.processCost}</label>
        </div>
      ),
    },
    {
      id: '15',
      title: '購入品',
      dataIndex: 'purchaseCost',
      width: 100 + 'px',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>{record.purchaseCost}</label>
        </div>
      ),
    },
    {
      id: '16',
      title: '外注加工',
      dataIndex: 'outsourceProcess',
      width: 100 + 'px',
    },
    {
      id: '17',
      title: '外注仕入',
      dataIndex: 'subcontractPurchase',
      width: 100 + 'px',
    },
  ];

  const iq5Columns = [
    {
      id: '1',
      title: 'No.',
      dataIndex: 'id',
      width: 50 + 'px',
    },
    {
      id: '2',
      title: '図番',
      dataIndex: 'drawingNo',
      width: 120 + 'px',
      height: 50 + 'px',
    },
    {
      id: '3',
      title: '名称',
      dataIndex: 'name',
      width: 100 + 'px',
    },
    {
      id: '4',
      title: '工程パターン',
      dataIndex: 'processPattern',
      width: 120 + 'px',
    },
    {
      id: '5',
      title: '員数',
      dataIndex: 'proCount',
      width: 100 + 'px',
    },
    {
      id: '6',
      title: '鋼材',
      dataIndex: 'steel',
      width: 100 + 'px',
    },
    {
      id: '7',
      title: '材料名',
      dataIndex: 'materialNm',
      width: 100 + 'px',
    },
    {
      id: '8',
      title: '材質',
      dataIndex: 'material',
      width: 100 + 'px',
    },
    {
      id: '9',
      title: 'サイズ長さ',
      dataIndex: 'sizeLen',
      width: 100 + 'px',
    },
    {
      id: '11',
      title: '重量',
      dataIndex: 'weight',
      width: 100 + 'px',
    },
    {
      id: '12',
      title: '面積',
      dataIndex: 'area',
      width: 100 + 'px',
    },
    {
      id: '13',
      title: '材料費',
      dataIndex: 'materialCost',
      width: 100 + 'px',
    },
    {
      id: '14',
      title: '加工費',
      dataIndex: 'processingCost',
      width: 100 + 'px',
    },
    {
      id: '15',
      title: '購入品',
      dataIndex: 'purchase',
      width: 100 + 'px',
    },
    {
      id: '16',
      title: '外注加工',
      dataIndex: 'outsourceProcess',
      width: 100 + 'px',
    },
    {
      id: '17',
      title: '外注仕入',
      dataIndex: 'subcontractPurchase',
      width: 100 + 'px',
    },
  ];

  const iq7Columns = [
    {
      id: '1',
      title: 'No.',
      dataIndex: 'id',
      width: 50 + 'px',
    },
    {
      id: '2',
      title: '図番',
      dataIndex: 'drawingNo',
      width: 120 + 'px',
      height: 50 + 'px',
    },
    {
      id: '3',
      title: '名称',
      dataIndex: 'name',
      width: 100 + 'px',
    },
    {
      id: '4',
      title: '工程パターン',
      dataIndex: 'processPattern',
      width: 120 + 'px',
    },
    {
      id: '5',
      title: '員数',
      dataIndex: 'proCount',
      width: 100 + 'px',
    },
    {
      id: '6',
      title: 'タイプ',
      dataIndex: 'type',
      width: 100 + 'px',
    },
    {
      id: '7',
      title: '材料名',
      dataIndex: 'materialNm',
      width: 100 + 'px',
    },
    {
      id: '8',
      title: '材質',
      dataIndex: 'material',
      width: 100 + 'px',
    },
    {
      id: '9',
      title: 'サイズX・直接',
      dataIndex: 'sizeXArea',
      width: 100 + 'px',
    },
    {
      id: '10',
      title: 'サイズY・長さ',
      dataIndex: 'sizeYLen',
      width: 100 + 'px',
    },
    {
      id: '11',
      title: 'サイズZ',
      dataIndex: 'sizeZ',
      width: 100 + 'px',
    },
    {
      id: '12',
      title: '重量',
      dataIndex: 'weight',
      width: 100 + 'px',
    },
    {
      id: '13',
      title: '面積',
      dataIndex: 'area',
      width: 100 + 'px',
    },
    {
      id: '14',
      title: '材料費',
      dataIndex: 'materialCost',
      width: 100 + 'px',
    },
    {
      id: '15',
      title: '加工費',
      dataIndex: 'processingCost',
      width: 100 + 'px',
    },
    {
      id: '16',
      title: '購入品',
      dataIndex: 'purchase',
      width: 100 + 'px',
    },
    {
      id: '17',
      title: '外注加工',
      dataIndex: 'outsourceProcess',
      width: 100 + 'px',
    },
    {
      id: '18',
      title: '外注仕入',
      dataIndex: 'subcontractPurchase',
      width: 100 + 'px',
    },
  ];

  const childType = [
    { id: 1, value: 'すべて', label: 'すべて' },
    { id: 2, value: '板金子部品', label: '板金子部品' },
    /*   { id: 3, value: '形鋼子部品', label: '形鋼子部品' }, */
  ];

  const reportOutputMenuitems = [
    {
      label: <Image preview={false} width={iconWidth} src={export_report} style={{ marginLeft: '5px' }}></Image>,
      key: 'reportOutput',
      children: [
        {
          label: '個別帳票',
          key: 'parentReport',
        },
        {
          label: 'リスト帳票',
          key: 'listReport',
        },
      ],
    },
  ];
  const listOutputMenuitems = [
    {
      label: (
        <Image
          preview={false}
          width={iconWidth}
          src={export_csv_icon}
          style={{ marginLeft: '10px', textDecoration: 'none' }}
        ></Image>
      ),
      key: 'listOutput',
      children: [
        {
          label: 'リストCSV出力',
          key: 'csvReport',
        },
        {
          label: 'リストCSV出力(生産管理連携)',
          key: 'csvAllReport',
        },
        /* {
          label: 'iQ見積アナライザ',
          key: 'iQReport',
        },
        {
          label: '明細出力',
          key: 'detailsReport',
        }, */
      ],
    },
  ];
  const infoConfirmOutputMenuitems = [
    {
      label: <Image preview={false} width={27} src={detail_display} style={{ marginLeft: '0px' }}></Image>,
      key: 'reportOutput',
      children: [
        {
          label: '子部品確認',
          key: 'childConfirm',
        },
        {
          label: '工程別合計',
          key: 'processConfirm',
        },
      ],
    },
  ];

  // プロパティの更新処理
  const updatedDetails = async (updateData) => {
    if (updateData != null) {
      //更新処理を呼び出す
      let updatedData = await updateEstimateProductData(updateData);

      const data = [...initialTblItems];

      const index = data?.findIndex((item) => item?.estimateProducts?.id === updatedData?.estimateProducts?.id);
      const updatedItems = [...data];
      updatedItems[index] = updatedData;
      setInitialTableItems(updatedItems);
      displayTotalCount = updatedItems?.length; // リストの件数

      const updatedItems2 = [...initialTblItemsBk];
      updatedItems?.forEach((update) => {
        const index = updatedItems2?.findIndex((item) => item?.estimateProducts?.id === update?.estimateProducts?.id);
        updatedItems2[index] = update;
      });
      setInitialTableItemsBk(updatedItems2);
      setSelectedData(updatedData);
    }
  };

  // 親部品見積情報をDBに更新する
  async function updateEstimateProductData(updateData) {
    let ret = [];
    ret = await updateEstimateProductInfo(updateData);
    return ret;
  }

  const updateEditMode = (mode) => {
    setEditMode(mode);
    if (props.updateEstimateEditMode !== undefined) props.updateEstimateEditMode(mode);
  };

  // 絞込設定・お客様項目のラジオボタン押下
  const customerFilterChange = (e) => {
    setCustomerRdo(e.target.value);
  };

  // 表示設定の表示件数を変更した場合、
  const changeDisplayCount = (e) => {
    setValue(e.target.value);
  };

  let checkedRow = [];
  let token = localStorage?.getItem('iQxWeb_AccessToken');

  useEffect(() => {
    if (props.parentListClick || props?.isFromiQ3) getEstimateProductData();
  }, [props.parentListClick, props.isFromiQ3]);

  // ログインしているユーザーの会社情報
  useEffect(() => {
    setCompanyInfo(props.companyInfo);
  }, [props.companyInfo]);

  // お客様情報
  useEffect(() => {
    setClientInfo(props.clientInfo);
  }, [props.clientInfo]);

  // ユーザー情報
  useEffect(() => {
    setUserInfo(props.userInfo);
  }, [props.userInfo]);

  // 担当者情報
  useEffect(() => {
    setStaffInfo(props.staffInfo);
  }, [props.staffInfo]);

  // 工程マスタ情報
  useEffect(() => {
    setProcessInfo(props.processInfo);
  }, [props.processInfo]);

  // 工程マスタ情報
  useEffect(() => {
    setPurchaseCategoryInfos(props.purchaseCategoryInfo);
  }, [props.purchaseCategoryInfo]);

  useEffect(() => {
    if (props.userSettingInfo !== undefined && props.userSettingInfo?.length > 0) {
      setShowEdtConfirmOK(props?.userSettingInfo?.[0]?.massages?.editOk);
      setShowEdtConfirmCancle(props?.userSettingInfo?.[0]?.massages?.editCancel);
    } else {
      setShowEdtConfirmOK(true);
      setShowEdtConfirmCancle(true);
    }
  }, [props.userSettingInfo]);

  // 親部品見積リストの初期値
  const getEstimateProductData = async () => {
    setEditMode(props.editMode != undefined ? props.editMode : false);
    const estimateData = await getEstimateProductInfo();
    const listData = estimateData;
    const listDataLatestRowKey = listData?.length == 0 ? 0 : listData?.[0]?.estimateProducts?.id;
    let selectedData = listData?.find((item) => item.estimateProducts?.id === listDataLatestRowKey);
    displayTotalCount = listData?.length; // リストの件数

    // let estimateProductId = selectedData?.estimateProducts?.id;
    // let iq3Infos = await getEstimateIq3Info(estimateProductId);
    // setInitialTableItems(listData);
    setInitialTableItemsBk(listData);
    setSelectedData(selectedData);
    setSelectedEstimateData(selectedData);
    setSelectedRowKeys([listDataLatestRowKey]);
    // setSelectedIq3Data(iq3Infos);

    initRow([...rows, rowdata]);
    if (props.isFromiQ3 === undefined || props.isFromiQ3 === false) navigate(':parentList');
    // navigate(':parentList');
    setCSVExportData([listData]);

    // 表示設定の表示項目情報
    let userDisplaySetting = props?.userInfo?.displaySettings;
    let initDisplayColumns = getDisplayColumns();
    let sortColumns = initDisplayColumns; // 初期状態の場合、
    let chkCols = prepareChkDataColumns(sortColumns);
    setModifiedChkColumns(chkCols);
    // 表示設定の並び替え項目
    if (userDisplaySetting && userDisplaySetting?.sortColumns?.length > 0) {
      // 保存した並び替え項目より初期項目を得る
      sortColumns = userDisplaySetting?.sortColumns;
      sortColumns = sortColumns
        ?.map((col) => {
          if (col.displayColumnsGroup === DisplayColumnsGroup.Default) {
            // デフォルト項目の場合、
            return col;
          } else {
            // 追加項目の場合、マスタの名称を扱う
            let initColInfo = [...initDisplayColumns]?.find(
              (initCol) =>
                initCol.displayColumnsGroup === col.displayColumnsGroup &&
                initCol.additionalItemId === col.additionalItemId
            );
            return { ...col, id: initColInfo?.id, title: initColInfo?.title };
          }
        })
        ?.filter((col) => col.id !== undefined); // マスタより、利用しない・削除済みのデータは対象外
      setSortingColumns(sortColumns);
    } else {
      // 初期状態の場合、
      setSortingColumns(initDisplayColumns);
      let exportCols = prepareExportCol(initDisplayColumns);

      setExportColumn(exportCols);
    }

    let displayColumns = initDisplayColumns; // 初期状態の場合、
    // 表示設定の表示項目
    if (userDisplaySetting && userDisplaySetting?.displayColumns?.length > 0) {
      // 保存した表示項目より初期項目を得る
      displayColumns = userDisplaySetting?.displayColumns;
      displayColumns = displayColumns
        ?.map((col) => {
          // マスタの名称を扱う
          let initColInfo = [...initDisplayColumns]?.find((initCol) =>
            col.displayColumnsGroup === DisplayColumnsGroup.Default // デフォルト項目の場合、
              ? initCol.id === col.id
              : initCol.displayColumnsGroup === col.displayColumnsGroup && // 追加項目の場合
                initCol.additionalItemId === col.additionalItemId
          );
          return { ...initColInfo };
        })
        ?.filter((col) => col.id !== undefined); // マスタより、利用しない・削除済みのデータは対象外
    }

    // 追加項目マスタより、新規追加したデータがあるかどうかのチェック、
    let newAdditionItemColumns = initDisplayColumns?.filter(
      (initCol) =>
        // 保存した表示項目リストにない場合、新規項目と判断する
        displayColumns?.findIndex(
          (dispCol) =>
            dispCol.displayColumnsGroup === initCol.displayColumnsGroup &&
            dispCol.additionalItemId === initCol.additionalItemId
        ) === -1
    );
    // 追加項目マスタより、新規データがある場合、
    newAdditionItemColumns?.map((newCol) => {
      // 並び替え項目に、新規データがあるかどうかのチェック、
      let isExistSortCol =
        sortColumns?.findIndex(
          (sortCol) =>
            newCol.displayColumnsGroup === sortCol.displayColumnsGroup &&
            newCol.additionalItemId === sortCol.additionalItemId
        ) > -1;
      if (!isExistSortCol) {
        // 無い場合、追加する
        sortColumns = [newCol, ...sortColumns];
        displayColumns = [newCol, ...displayColumns];
      }
    });

    // 部品リストの項目順番を準備
    let prepareChkData = displayColumns?.filter(
      (disCol) => sortColumns?.findIndex((sCol) => sCol.id === disCol.id) > -1
    );
    prepareChkData.sort((a, b) => {
      // 並び替え項目の順番とおり、備える
      const aIndex = sortColumns?.findIndex((sort) => sort.id === a.id);
      const bIndex = sortColumns?.findIndex((sort) => sort.id === b.id);
      return aIndex - bIndex;
    });
    setChkData(prepareChkData);

    setSortingColumns(sortColumns);
    let exportCols = prepareExportCol(prepareChkData);
    setExportColumn(exportCols);

    // 表示件数
    if (userDisplaySetting?.displayNum) {
      setPageSize(userDisplaySetting?.displayNum);
      setValue(pageSizeOptions?.find((option) => option.label === userDisplaySetting?.displayNum)?.value);
    }

    // 絞り込み
    setDatesData(selectedData?.epCustomDates);
    setLabelsData(selectedData?.epCustomLabels);
    setMemoData(selectedData?.epCustomMemos);
    setCostData(selectedData?.epCustomCosts);
    if (props.orderOptions !== undefined) setOrderOptions(props.orderOptions);

    // 絞り込み設定の情報
    let userFilterSetting = props?.userInfo?.filterSettings;
    let filterInitDisplayColumns = initDisplayColumns?.filter((item) => item.id !== 26 && item.id !== 1); // ID、画像の項目以外
    if (
      userFilterSetting &&
      (userFilterSetting?.checkedInfos?.length > 0 || userFilterSetting?.inputInfos?.length > 0)
    ) {
      let userCheckedInfos = userFilterSetting?.checkedInfos;
      let userInputInfos = userFilterSetting?.inputInfos;
      // 保存したデータがある場合、
      for (let i = 0; i < filterInitDisplayColumns?.length; i++) {
        let initCol = filterInitDisplayColumns[i];
        let isDefaultCol = initCol.displayColumnsGroup === DisplayColumnsGroup.Default ? true : false;

        let checkedItem = userCheckedInfos?.find((checked) =>
          isDefaultCol
            ? checked.id === initCol.id // デフォルト項目の場合、
            : checked.displayColumnsGroup === initCol.displayColumnsGroup && // 追加項目の場合、
              checked.additionalItemId === initCol.additionalItemId
        );

        let inputItem = userInputInfos?.find((checked) =>
          isDefaultCol
            ? checked.id === initCol.id // デフォルト項目の場合、
            : checked.displayColumnsGroup === initCol.displayColumnsGroup && // 追加項目の場合、
              checked.additionalItemId === initCol.additionalItemId
        );

        filterCheckedData.push(
          checkedItem
            ? { ...checkedItem, id: initCol.id }
            : {
                id: initCol?.id,
                displayColumnsGroup: initCol?.displayColumnsGroup,
                additionalItemId: initCol?.additionalItemId,
                checked: false,
              }
        );
        filterInputData.push(
          inputItem
            ? { ...inputItem, id: inputItem.id }
            : {
                id: initCol?.id,
                displayColumnsGroup: initCol?.displayColumnsGroup,
                additionalItemId: initCol?.additionalItemId,
                inputData: ['', '', ''],
              }
        );
      }
    } else {
      // 初期状態の場合、
      for (let i = 0; i < filterInitDisplayColumns?.length; i++) {
        filterCheckedData.push({
          id: filterInitDisplayColumns[i].id,
          displayColumnsGroup: filterInitDisplayColumns[i].displayColumnsGroup,
          additionalItemId: filterInitDisplayColumns[i].additionalItemId
            ? filterInitDisplayColumns[i].additionalItemId
            : 0,
          checked: false,
        });
        filterInputData.push({
          id: filterInitDisplayColumns[i].id,
          displayColumnsGroup: filterInitDisplayColumns[i].displayColumnsGroup,
          additionalItemId: filterInitDisplayColumns[i].additionalItemId
            ? filterInitDisplayColumns[i].additionalItemId
            : 0,
          inputData: ['', '', ''],
        });
      }
    }
    setFilterCheckedData(filterCheckedData);
    setCheckedState(filterCheckedData);
    setFilterInputData(filterInputData);
    setInputVal(filterInputData);

    let customerUsedType = 0;
    // お客様項目のチェックボックス値
    if (userFilterSetting && userFilterSetting?.customerUsedType) {
      customerUsedType = userFilterSetting?.customerUsedType;
      setCustomerRdo(customerUsedType);
    }

    // 全てクリア
    if (filterCheckedData) {
      setIsAllClear(isFilterAllClear(filterCheckedData));
    }

    filterBySettingInfo(listData, filterCheckedData, filterInputData, customerUsedType);
  };

  const fetchListData = async () => {
    try {
      const companySetting = props.companySettingInfo;
      if (companySetting !== undefined && companySetting?.length > 0) {
        // Check if companySetting is not null or undefined
        const formats = getExcelDatabyUseType(companySetting[0]?.excelFormats);
        const excelLstformats = formats[PrintTypes.list];

        if (excelLstformats !== undefined && excelLstformats?.length > 0) {
          setExcelListFormats(excelLstformats);
          const selectedExelLstFormat = excelLstformats?.find(
            (format) => format.id === companySetting[0]?.printOutputs?.[PrintTypes.list]?.formatsId
          );
          if (selectedExelLstFormat !== undefined) {
            setSelectedExeclListFormat(selectedExelLstFormat);
          } else {
            setSelectedExeclListFormat(excelLstformats[0]);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchIndividualData = async () => {
    try {
      const companySetting = props.companySettingInfo;
      if (companySetting !== undefined && companySetting?.length > 0) {
        // Check if companySetting is not null or undefined
        const formats = getExcelDatabyUseType(companySetting[0]?.excelFormats);
        const excelIndividualformats = formats[PrintTypes.individual];
        if (excelIndividualformats !== undefined && excelIndividualformats?.length > 0) {
          const selectedExelIndividualFormat = formats[0]?.find(
            (format) => format.id === companySetting[0]?.printOutputs?.[PrintTypes.individual]?.formatsId
          );
          setExcelIndividualFormats(formats[PrintTypes.individual]);

          if (selectedExelIndividualFormat !== undefined) {
            setSelectedExeclIndividualFormat(selectedExelIndividualFormat);
          } else {
            setSelectedExeclIndividualFormat(formats[0][0]);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const defaultColumns = [
    {
      id: 1,
      title: 'ID',
      dataIndex: 'estimateProducts',
      dbNameAttr: 'id',
      key: 'id',
      sorter: (a, b) => a?.estimateProducts?.id - b?.estimateProducts?.id,
      sortOrder: 'descend',
      className: 'cm-a-right',
      width: 55,
      render: (id, record, index) => {
        return (page - 1) * pageSize + (displayTotalCount - index);
      },
      /*  render: (id, record, index) => {
        const value = (page - 1) * pageSize + (displayTotalCount - index);
        return (
          <div>
            <label name={'id' + index}>{value}</label>
          </div>
        );
      }, */
    },

    {
      id: 2,
      title: 'お客様コード',
      dbNameAttr: 'code',
      dataIndex: 'estimateProducts',
      key: 'clientCode',
      width: 95 + 'px',
      render: (record) => props?.clientInfo?.find((client) => client.id === record?.clientId)?.code,
      /*  render: (record) => (
        <div>
          <label>{props?.clientInfo?.find((client) => client.id === record?.clientId)?.code}</label>
        </div>
      ), */
    },

    {
      id: 3,
      title: 'お客様',
      dbNameAttr: 'clientName',
      dataIndex: 'estimateProducts',
      key: 'clientName',
      width: 250 + 'px',
      render: (record) => record?.clientName,
    },
    {
      id: 4,
      title: '図番',
      dbNameAttr: 'drawingNo',
      dataIndex: 'estimateProducts',
      key: 'drawingNo',
      width: 200 + 'px',
      render: (record) => record?.drawingNo,
    },
    {
      id: 5,
      title: '名称',
      dbNameAttr: 'name',
      dataIndex: 'estimateProducts',
      key: 'name',
      width: 200 + 'px',
      render: (record) => record?.name,
    },
    {
      id: 6,
      title: '数量',
      dbNameAttr: 'quantity',
      dataIndex: 'estimateProducts',
      key: 'quantity',
      width: 55 + 'px',
      render: (record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>{record?.quantity}</label>
        </div>
      ),
    },
    {
      id: 7,
      title: '粗利益',
      dbNameAttr: 'grossTotalPrice',
      dataIndex: 'estimateProducts',
      key: 'grossTotalPrice',
      width: 100 + 'px',
      render: (record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record?.grossTotalPrice != undefined
              ? '¥' +
                record?.grossTotalPrice.toLocaleString(navigator.language, {
                  minimumFractionDigits: 0,
                })
              : 0}
          </label>
        </div>
      ),
    },
    {
      id: 8,
      title: '粗利率',
      dbNameAttr: 'grossRate',
      dataIndex: 'estimateProducts',
      key: 'grossRate',
      width: 100 + 'px',
      render: (record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record?.grossRate != undefined
              ? record?.grossRate.toLocaleString(navigator.language, {
                  minimumFractionDigits: 0,
                }) + '%'
              : '0%'}
          </label>
        </div>
      ),
    },
    {
      id: 9,
      title: '見積単価',
      dbNameAttr: 'estimateUnitPrice',
      dataIndex: 'estimateProducts',
      key: 'estimateUnitPrice',
      width: 100 + 'px',
      render: (record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record?.estimateUnitPrice != undefined
              ? '¥' +
                record?.estimateUnitPrice.toLocaleString(navigator.language, {
                  minimumFractionDigits: 0,
                })
              : 0}
          </label>
        </div>
      ),
    },
    {
      id: 10,
      title: '見積合計',
      dbNameAttr: 'estimateTotalPrice',
      dataIndex: 'estimateProducts',
      key: 'estimateTotalPrice',
      width: 100 + 'px',
      render: (record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record?.estimateTotalPrice != undefined
              ? '¥' +
                record?.estimateTotalPrice.toLocaleString(navigator.language, {
                  minimumFractionDigits: 0,
                })
              : 0}
          </label>
        </div>
      ),
    },
    {
      id: 11,
      title: '材料原価',
      dbNameAttr: 'materialCostUnitPrice',
      dataIndex: 'estimateProducts',
      key: 'materialCostUnitPrice',
      width: 100 + 'px',
      render: (record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record?.materialCostUnitPrice != undefined
              ? '¥' +
                record?.materialCostUnitPrice.toLocaleString(navigator.language, {
                  minimumFractionDigits: 0,
                })
              : 0}
          </label>
        </div>
      ),
    },
    {
      id: 12,
      title: '製造原価',
      dbNameAttr: 'manufacturingCostTotalPrice',
      dataIndex: 'estimateProducts',
      key: 'manufacturingCostTotalPrice',
      width: 100 + 'px',
      render: (record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record?.manufacturingCostTotalPrice != undefined
              ? '¥' +
                record?.manufacturingCostTotalPrice.toLocaleString(navigator.language, {
                  minimumFractionDigits: 0,
                })
              : 0}
          </label>
        </div>
      ),
    },
    {
      id: 13,
      title: '段取原価',
      dbNameAttr: 'setupCostTotalPrice',
      dataIndex: 'estimateProducts',
      key: 'setupCostTotalPrice',
      width: 100 + 'px',
      render: (record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record?.setupCostTotalPrice != undefined
              ? '¥' +
                record?.setupCostTotalPrice.toLocaleString(navigator.language, {
                  minimumFractionDigits: 0,
                })
              : 0}
          </label>
        </div>
      ),
    },
    {
      id: 14,
      title: '加工原価',
      dbNameAttr: 'workCostTotalPrice',
      dataIndex: 'estimateProducts',
      key: 'workCostTotalPrice',
      width: 100 + 'px',
      render: (record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record?.workCostTotalPrice != undefined
              ? '¥' +
                record?.workCostTotalPrice.toLocaleString(navigator.language, {
                  minimumFractionDigits: 0,
                })
              : 0}
          </label>
        </div>
      ),
    },
    {
      id: 15,
      title: '購入品費',
      dbNameAttr: 'purchaseTotalPrice',
      dataIndex: 'estimateProducts',
      key: 'purchaseTotalPrice',
      width: 100 + 'px',
      render: (record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record?.purchaseTotalPrice != undefined
              ? '¥' +
                record?.purchaseTotalPrice.toLocaleString(navigator.language, {
                  minimumFractionDigits: 0,
                })
              : 0}
          </label>
        </div>
      ),
    },
    {
      id: 16,
      title: '管理費',
      dbNameAttr: 'managementFeeTotalPrice',
      dataIndex: 'estimateProducts',
      key: 'managementFeeTotalPrice',
      width: 100 + 'px',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record?.managementFeeTotalPrice != undefined
              ? '¥' +
                record?.managementFeeTotalPrice.toLocaleString(navigator.language, {
                  minimumFractionDigits: 0,
                })
              : 0}
          </label>
        </div>
      ),
    },
    {
      id: 17,
      title: '見積番号',
      dbNameAttr: 'estimateNo',
      dataIndex: 'estimateProducts',
      key: 'estimateNo',
      width: 100 + 'px',
      render: (record) => record?.estimateNo,
    },
    {
      id: 18,
      title: '価格調整',
      dbNameAttr: 'otherProduct?.adjustment',
      dataIndex: 'estimateProducts',
      key: 'adjustment',
      width: 100 + 'px',
      render: (record) => record?.otherProduct?.adjustment,
    },
    {
      id: 19,
      title: '作成者',
      dbNameAttr: 'creater',
      dataIndex: 'estimateProducts',
      key: 'creater',
      width: 100 + 'px',
      render: (record) => record?.creater,
    },
    {
      id: 20,
      title: '最終更新者',
      dbNameAttr: 'modifier',
      dataIndex: 'estimateProducts',
      key: 'modifier',
      width: 100 + 'px',
      render: (record) => record?.modifier,
    },
    {
      id: 21,
      title: '作成日',
      dbNameAttr: 'created',
      dataIndex: 'estimateProducts',
      key: 'created',
      width: 100 + 'px',
      render: (record) => formatDate(record?.created),
    },
    {
      id: 22,
      title: '更新日',
      dbNameAttr: 'modified',
      dataIndex: 'estimateProducts',
      key: 'modified',
      width: 100 + 'px',
      render: (record) => formatDate(record?.modified),
    },
    {
      id: 23,
      title: '総表面積(c㎡)',
      dbNameAttr: 'totalSurfaceArea',
      dataIndex: 'estimateProducts',
      key: 'totalSurfaceArea',
      width: 100 + 'px',
      render: (record) => toDecimal(record?.totalSurfaceArea, 2),
    },

    {
      id: 24,
      title: '受注形態',
      dbNameAttr: 'coeffOrdersName',
      dataIndex: 'estimateProducts',
      key: 'coeffOrdersName',
      width: 150 + 'px',
      render: (record) => record?.coeffOrdersName,
    },

    {
      id: 25,
      title: '納期形態',
      dbNameAttr: 'coeffDeliveryDatesName',
      dataIndex: 'estimateProducts',
      key: 'coeffDeliveryDatesName',
      width: 150 + 'px',
      render: (record) => record?.coeffDeliveryDatesName,
    },
    {
      id: 26,
      title: '画像',
      dbNameAttr: 'img',
      dataIndex: 'estimateProducts',
      render: (record) => record?.coeffDeliveryDatesName,
      key: 'img',
      width: 100 + 'px',
    },
    {
      id: 27,
      title: '受注非受注',
      dbNameAttr: 'estimateStatus',
      dataIndex: 'estimateProducts',
      key: 'estimateStatus',
      render: (record) => record?.estimateStatus,
      width: 150 + 'px',
    },
    {
      id: 28,
      title: 'サイズX',
      dbNameAttr: 'sizeX',
      dataIndex: 'estimateProducts',
      key: 'sizeX',
      width: 100 + 'px',
      render: (record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>{toDecimal(record?.sizeX, 2)}</label>
        </div>
      ),
    },
    {
      id: 29,
      title: 'サイズY',
      dbNameAttr: 'sizeY',
      dataIndex: 'estimateProducts',
      key: 'sizeY',
      width: 120 + 'px',
      render: (record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>{toDecimal(record?.sizeY, 2)}</label>
        </div>
      ),
    },
    {
      id: 30,
      title: 'サイズZ',
      dbNameAttr: 'sizeZ',
      dataIndex: 'estimateProducts',
      key: 'sizeZ',
      width: 120 + 'px',
      render: (record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>{toDecimal(record?.sizeZ, 2)}</label>
        </div>
      ),
    },
    {
      id: 31,
      title: '子部品総重量',
      dbNameAttr: 'totalWeightParts',
      dataIndex: 'estimateProducts',
      key: 'totalWeightParts',
      width: 100 + 'px',
      render: (record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>{record?.totalWeightParts}</label>
        </div>
      ),
    },
    {
      id: 32,
      title: '板金重量',
      dbNameAttr: 'totalWeightSm',
      dataIndex: 'estimateProducts',
      key: 'totalWeightSm',
      width: 100 + 'px',
      render: (record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>{record?.totalWeightSm}</label>
        </div>
      ),
    },
    // {
    //   id: '6',
    //   title: '形鋼重量',
    //   dataIndex: 'iq5Weight',
    //   width: 100 + 'px',
    //   render: (_, record) => (
    //     <div style={{ textAlign: 'right' }}>
    //       <label style={{ fontSize: '13px' }}>{record.iq5Weight}</label>
    //     </div>
    //   ),
    // },
    // {
    //   id: '7',
    //   title: '機械重量',
    //   dataIndex: 'iq7Weight',
    //   width: 120 + 'px',
    // },
    {
      id: 33,
      title: '子部品種類',
      dbNameAttr: 'partTypesParts',
      dataIndex: 'estimateProducts',
      key: 'partTypesParts',
      width: 120 + 'px',
      render: (record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>{record?.partTypesParts}</label>
        </div>
      ),
    },
    {
      id: 34,
      title: '板金種類',
      dbNameAttr: 'partTypesSm',
      dataIndex: 'estimateProducts',
      key: 'partTypesSm',
      width: 120 + 'px',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>{record?.partTypesSm}</label>
        </div>
      ),
    },
    // {
    //   id: '10',
    //   title: '形鋼種類',
    //   dataIndex: 'iq5TypeCnt',
    //   width: 100 + 'px',
    // },
    // {
    //   id: '11',
    //   title: '機械種類',
    //   dataIndex: 'iq7TypeCnt',
    //   width: 100 + 'px',
    // },
    {
      id: 35,
      title: '子部品製作数',
      dbNameAttr: 'totalQuantityParts',
      dataIndex: 'estimateProducts',
      key: 'totalQuantityParts',
      width: 100 + 'px',
      render: (record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>{record?.totalQuantityParts}</label>
        </div>
      ),
    },
    {
      id: 36,
      title: '板金製作数',
      dbNameAttr: 'totalQuantitySm',
      dataIndex: 'estimateProducts',
      key: 'totalQuantitySm',
      width: 100 + 'px',
      render: (record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>{record?.totalQuantitySm}</label>
        </div>
      ),
    },
    // {
    //   id: '14',
    //   title: '形鋼製作数',
    //   dataIndex: 'iq5TotalProduceCnt',
    //   width: 100 + 'px',
    // },

    // {
    //   id: '15',
    //   title: '機械製作数',
    //   dataIndex: 'iq7TotalProduceCnt',
    //   width: 100 + 'px',
    // },
    {
      id: 37,
      title: '表面処理',
      dbNameAttr: 'estimateProducts',
      dataIndex: 'estimateProducts',
      key: 'additions',
      width: 100 + 'px',
      render: (record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record?.additions != undefined && record?.additions?.length > 0
              ? '¥' +
                getProcessTotalPrice(record?.additions, props?.processInfo, WorkType.PaSurface)?.toLocaleString(
                  navigator.language,
                  {
                    minimumFractionDigits: 0,
                  }
                )
              : 0}
          </label>
        </div>
      ),
    },
    {
      id: 38,
      title: '組立',
      dbNameAttr: 'estimateProducts',
      dataIndex: 'estimateProducts',
      key: 'additions',
      width: 100 + 'px',
      render: (record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record?.additions != undefined && record?.additions?.length > 0
              ? '¥' +
                getProcessTotalPrice(record?.additions, props?.processInfo, WorkType.PaAssenble)?.toLocaleString(
                  navigator.language,
                  {
                    minimumFractionDigits: 0,
                  }
                )
              : 0}
          </label>
        </div>
      ),
    },
    {
      id: 39,
      title: '検査',
      dbNameAttr: 'estimateProducts',
      dataIndex: 'estimateProducts',
      key: 'additions',
      width: 100 + 'px',
      render: (record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record?.additions != undefined && record?.additions?.length > 0
              ? '¥' +
                getProcessTotalPrice(record?.additions, props?.processInfo, WorkType.PaInspection)?.toLocaleString(
                  navigator.language,
                  {
                    minimumFractionDigits: 0,
                  }
                )
              : 0}
          </label>
        </div>
      ),
    },
    {
      id: 40,
      title: '梱包',
      dbNameAttr: 'estimateProducts',
      dataIndex: 'estimateProducts',
      key: 'additions',
      width: 100 + 'px',
      render: (record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record?.additions != undefined && record?.additions?.length > 0
              ? '¥' +
                getProcessTotalPrice(record?.additions, props?.processInfo, WorkType.PaPacking)?.toLocaleString(
                  navigator.language,
                  {
                    minimumFractionDigits: 0,
                  }
                )
              : 0}
          </label>
        </div>
      ),
    },
    // {
    //   id: '5',
    //   title: '板付',
    //   dataIndex: 'temporaryAttachment',
    //   width: 100 + 'px',
    //   render: (_, record) => (
    //     <div style={{ textAlign: 'right' }}>
    //       <label style={{ fontSize: '13px' }}>
    //         {record.temporaryAttachment != undefined
    //           ? '¥' +
    //             record.temporaryAttachment.toLocaleString(navigator.language, {
    //               minimumFractionDigits: 0,
    //             })
    //           : 0}
    //       </label>
    //     </div>
    //   ),
    // },
    // {
    //   id: '6',
    //   title: '外注',
    //   dataIndex: 'outsourcing',
    //   width: 100 + 'px',
    //   render: (_, record) => (
    //     <div style={{ textAlign: 'right' }}>
    //       <label style={{ fontSize: '13px' }}>
    //         {record.outsourcing != undefined
    //           ? '¥' +
    //             record.outsourcing.toLocaleString(navigator.language, {
    //               minimumFractionDigits: 0,
    //             })
    //           : 0}
    //       </label>
    //     </div>
    //   ),
    // },
    // {
    //   id: '7',
    //   title: '研暦',
    //   dataIndex: 'polishing',
    //   width: 100 + 'px',
    //   render: (_, record) => (
    //     <div style={{ textAlign: 'right' }}>
    //       <label style={{ fontSize: '13px' }}>
    //         {record.polishing != undefined
    //           ? '¥' +
    //             record.polishing.toLocaleString(navigator.language, {
    //               minimumFractionDigits: 0,
    //             })
    //           : 0}
    //       </label>
    //     </div>
    //   ),
    // },
  ];

  // 親部品リスト画面の表示項目
  // const columns = [
  //   ...columnsOne,
  //   ...columnstwo,
  //   ...columnsthree,
  //   ...hiyouColumns,
  //   ...labelColumns,
  //   ...memoColumns,
  //   ...dateColumns,
  // ];

  // 表面処理の合計金額を取得する
  const getProcessTotalPrice = (additionsInfo, mstInfo, workType) => {
    let processId = getProcessRecordId(ServiceClass.Parent, workType, mstInfo);
    let processInfo = additionsInfo?.find((addition) => addition.processId === processId);
    return processInfo?.dataType === DataType.Data ? processInfo?.totalDataPrice : processInfo?.totalEditPrice;
  };

  // 工程マスタのレコードIDを取得する
  const getProcessRecordId = (classId, processTypeId, mstInfo) => {
    // 選択された、種別と工程より工程マスタレコードIDを得る
    let mstRecordInfo = [...mstInfo]?.filter((item) => item?.class === classId && item?.workType === processTypeId);
    return mstRecordInfo?.length > 0 ? mstRecordInfo[0].id : 0;
  };

  const getKouteiListData = (lists) => {
    const filteredKouteiResult = lists?.flatMap((item) => {
      const { processId, deviceId, isUsedForCalc, multiprocesses } = item;

      // Filter out multiprocesses where both processDetailGroup and processDetailTypeId are equal to 0
      const filteredMultiprocesses = multiprocesses?.filter(
        (process) => process.processDetailGroup !== 0 || process.processDetailTypeId !== 0
      );

      // Create an array of rows based on the filtered multiprocesses
      const rows = filteredMultiprocesses?.map((process) => ({
        processId,
        deviceId,
        isUsedForCalc,
        ...process, // Spread the process details
      }));

      // Include the item itself as a row if there are no filtered multiprocesses
      if (filteredMultiprocesses?.length === 0) {
        rows.push({
          processId,
          deviceId,
          isUsedForCalc,
        });
      }

      return rows;
    });
    return filteredKouteiResult;
  };
  const updateKouteiCalData = (lists) => {
    const newArray = [];

    lists?.forEach((item) => {
      item.dataItems?.forEach((data1Item, index) => {
        const newItem = {
          no: item.no,
          processId: item.processId,
          dataType: item.dataType,
          totalDandori: item.totalDandori,
          totalDataPrice: item.totalDataPrice,
          totalDataTime: item.totalDataTime,
          totalEditPrice: item.totalEditPrice,
          totalEditTime: item.totalEditTime,
          totalSagyo: item.totalSagyo,
          dataItems: data1Item,

          editItems: item.editItems[index],
        };

        newArray.push(newItem);
      });
    });
    return newArray;
  };

  const getProcessName = (deviceId) => {
    let processName = props.processInfo?.filter((i) => i.id === deviceId)[0]?.name;
    return processName;
  };
  const getProcessCode = (deviceId) => {
    let processCode = props.processInfo?.filter((i) => i.id === deviceId)[0]?.code;
    return processCode;
  };

  const getProcessDetailGroupCode = (deviceId, group) => {
    let process = props.processInfo?.filter((i) => i.id === deviceId)[0];
    if (process != undefined) {
      let groupCode = process.details.processDetailTypes?.find((type) => type.id === group);
      if (groupCode) {
        return groupCode.code;
      } else {
        return '';
      }
    }
  };
  const getProcessDetailGroupName = (deviceId, group) => {
    let process = props.processInfo?.filter((i) => i.id === deviceId)[0];
    if (process != undefined) {
      let groupCode = process.details.processDetailTypes?.find((type) => type.id === group);
      if (groupCode) {
        return groupCode.name;
      } else {
        return '';
      }
    }
  };

  let lastId = 0;

  // デフォルト項目のIDを生成
  const defaultColumnsList = defaultColumns?.map((item, index) => {
    return { ...item, id: index + 1, displayColumnsGroup: DisplayColumnsGroup.Default };
  });

  if (defaultColumnsList?.length > 0) lastId = defaultColumnsList?.findLast((item) => item)?.id;

  // デフォルト項目の最後IDより連続して、費用項目IDを生成
  const hiyouColumnsList = hiyouColumns?.map((item, index) => {
    return {
      ...item,
      id: lastId + (index + 1),
      displayColumnsGroup: DisplayColumnsGroup.AdditionalItemCost,
      additionalItemId: item.id, // 費用マスタID
    };
  });

  if (hiyouColumnsList?.length > 0) lastId = hiyouColumnsList?.findLast((item) => item)?.id;

  // 費用項目の最後IDより連続して、ラベル項目IDを生成
  const labelColumnsList = labelColumns?.map((item, index) => {
    return {
      ...item,
      id: lastId + (index + 1),
      displayColumnsGroup: DisplayColumnsGroup.AdditionalItemLabel,
      additionalItemId: item.id, // ラベルマスタID
    };
  });

  if (labelColumnsList?.length > 0) lastId = labelColumnsList?.findLast((item) => item)?.id;

  // ラベル項目の最後IDより連続して、メモ項目IDを生成
  const memoColumnsList = memoColumns?.map((item, index) => {
    return {
      ...item,
      id: lastId + (index + 1),
      displayColumnsGroup: DisplayColumnsGroup.AdditionalItemMemo,
      additionalItemId: item.id, // メモマスタID
    };
  });

  if (memoColumnsList?.length > 0) lastId = memoColumnsList?.findLast((item) => item)?.id;

  // メモ項目の最後IDより連続して、日付項目IDを生成
  const dateColumnsList = dateColumns?.map((item, index) => {
    return {
      ...item,
      id: lastId + (index + 1),
      displayColumnsGroup: DisplayColumnsGroup.AdditionalItemDate,
      additionalItemId: item.id, // 日付マスタID
    };
  });

  const columns = [
    ...defaultColumnsList,
    ...hiyouColumnsList,
    ...labelColumnsList,
    ...memoColumnsList,
    ...dateColumnsList,
  ];

  // 表示設定項目
  const displaySettingItems = [
    ...defaultColumnsList,
    ...hiyouColumnsList,
    ...labelColumnsList,
    ...memoColumnsList,
    ...dateColumnsList,
  ];

  const [updTblColumns, setUpdTblColumns] = useState([
    ...defaultColumnsList,
    ...temphiyouColumns,
    ...templabelColumns,
    ...tempmemoColumns,
    ...tempdateColumns,
  ]);

  // 絞り込み項目
  const filterSettingItems = [
    ...defaultColumnsList?.filter((item) => item.id !== 26 && item.id !== 1), // ID、画像の項目以外
    ...hiyouColumnsList,
    ...labelColumnsList,
    ...memoColumnsList,
    ...dateColumnsList,
  ];

  let tempColumnsArray = [];

  // 親部品見積一覧に表示する項目の準備
  const getDisplayColumns = () => {
    // 親部品見積一覧に表示する項目
    let displayColumns = [];
    displayColumns = [
      ...defaultColumnsList,
      ...hiyouColumnsList,
      ...labelColumnsList,
      ...memoColumnsList,
      ...dateColumnsList,
    ];
    return displayColumns;
  };

  // DBに保存している表示設定情報とマスタの情報を比較して、チェックボックスのON・OFFを判断する
  let displayColumnsIdList = sortingColumns
    ?.map((s) => {
      // デフォルト項目の場合、IDはそのまま扱う
      if (s?.displayColumnsGroup === DisplayColumnsGroup.Default) {
        return s.id;
      } else if (s?.displayColumnsGroup === DisplayColumnsGroup.AdditionalItemCost) {
        // 追加項目・費用の場合、マスタより生成したIDを扱う
        return hiyouColumnsList?.find((hiyou) => hiyou.additionalItemId === s.additionalItemId)?.id;
      } else if (s?.displayColumnsGroup === DisplayColumnsGroup.AdditionalItemLabel) {
        // 追加項目・ラベルの場合、マスタより生成したIDを扱う
        return labelColumnsList?.find((label) => label.additionalItemId === s.additionalItemId)?.id;
      } else if (s?.displayColumnsGroup === DisplayColumnsGroup.AdditionalItemMemo) {
        // 追加項目・メモの場合、マスタより生成したIDを扱う
        return memoColumnsList?.find((memo) => memo.additionalItemId === s.additionalItemId)?.id;
      } else if (s?.displayColumnsGroup === DisplayColumnsGroup.AdditionalItemDate) {
        // 追加項目・日付の場合、マスタより生成したIDを扱う
        return dateColumnsList?.find((date) => date.additionalItemId === s.additionalItemId)?.id;
      }
    })
    ?.filter((i) => i !== undefined); // マスタに削除されている情報は対象外

  const updateSortingData = (data) => {
    setSortingData(data);
  };

  // 親部品リストの行を選択した場合、
  const onRowSelect = (record) => {
    setQuantity(record?.estimateProducts?.quantity);
    setClickedAction(1);
    setCurrentRecordKey(record?.estimateProducts?.id);
    setCurrentRecord(record);
    if (editMode) {
      setEditModeCancelConfirm(true);
    } else {
      setEditMode(false);
      if (props.updateEstimateEditMode !== undefined) props.updateEstimateEditMode(false);
      setSelectedRowKeys(record?.estimateProducts?.id);
      setCSVExportData(record);
      setSelectedData(record);
      if (
        (props.isFromiQ3 !== undefined && props.isFromiQ3) ||
        (props.isFromParent !== undefined && props.isFromParent)
      ) {
        props.getSelectedEstimateData(record, null, false);
      }
      if (props.isFromiQ3 === undefined || props.isFromiQ3 === false) navigate(':parentList');
      // navigate(':parentList');
    }
  };

  const onRowCheckboxSelect = (checked, record) => {
    checkedRow = [...checked];
    setSelectedRowKeys(checkedRow);
    setChkRowsCount(checkedRow?.length);
    // setCSVExportData(record);
    setSelectedData(record.slice(-1));
  };

  const rowSelection = {
    onChange: onRowCheckboxSelect,
  };
  const getCurrentEditMode = () => {
    return editMode;
  };
  useImperativeHandle(ref, () => ({
    getCurrentEditMode: getCurrentEditMode,
  }));

  const onFinish = (values) => {};

  const contentResizeBtn = () => {
    setSizes(['100%']);
    setBtnShowHide(false);
  };
  const contentResizeBackwardBtn = () => {
    setSizes(['60%']);
    setBtnShowHide(true);
  };

  // keywordで検索
  const changeKeywords = (e) => {
    setKeywords(e.target.vaue);
  };

  // キーワードで検索する
  const getDataByFilter = (value, list) => {
    let searchBarText = value;
    let parentLstData = list;
    const parentLstFilterData = parentLstData?.filter(
      (item) =>
        item.estimateProducts.id.toString().indexOf(searchBarText) >= 0 ||
        props?.clientInfo
          ?.find((client) => client.id === item.estimateProducts.clientId)
          ?.code.toLowerCase()
          ?.indexOf(searchBarText) >= 0 ||
        item.estimateProducts.grossTotalPrice.toString().indexOf(searchBarText) >= 0 ||
        item.estimateProducts.quantity.toString().indexOf(searchBarText) >= 0 ||
        item.estimateProducts.grossRate.toString().indexOf(searchBarText) >= 0 ||
        item.estimateProducts.estimateUnitPrice.toString().indexOf(searchBarText) >= 0 ||
        item.estimateProducts.materialCostUnitPrice.toString().indexOf(searchBarText) >= 0 ||
        item.estimateProducts.manufacturingCostTotalPrice.toString().indexOf(searchBarText) >= 0 ||
        item.estimateProducts.setupCostTotalPrice.toString().indexOf(searchBarText) >= 0 ||
        item.estimateProducts.workCostTotalPrice.toString().indexOf(searchBarText) >= 0 ||
        item.estimateProducts.name.indexOf(searchBarText) >= 0 ||
        item.estimateProducts.estimateStatus.toLowerCase().indexOf(searchBarText) >= 0 ||
        item.estimateProducts.drawingNo.indexOf(searchBarText) >= 0 ||
        item.estimateProducts.clientName.toLowerCase().indexOf(searchBarText) >= 0 ||
        item.estimateProducts.estimateNo.toLowerCase().indexOf(searchBarText) >= 0 ||
        item.estimateProducts.creater.toLowerCase().indexOf(searchBarText) >= 0
    );
    return parentLstFilterData;
  };

  // お客様で検索
  const changeCustomer = (e) => {
    setCustomer(e.target.vaue);
  };
  // 図番で検索
  const changeDrawingNo = (e) => {
    setImageNum(e.target.vaue);
  };
  // 名称で検索
  const changeName = (e) => {
    setName(e.target.vaue);
  };
  //クイック検索
  const getParentsByFilter = (e) => {
    let value = e.target.value;
    let parentLstData = initialTblItemsBk;
    let customerTxt =
      e.target.name == 'searchCustomer' ? value : document.getElementsByName('searchCustomer')?.[0].defaultValue;
    let imgTxt =
      e.target.name == 'searchDrawingNo' ? value : document.getElementsByName('searchDrawingNo')?.[0].defaultValue;
    let nameTxt = e.target.name == 'searchName' ? value : document.getElementsByName('searchName')?.[0].defaultValue;
    let keywordTxt =
      e.target.name == 'searchKeyword' ? value : document.getElementsByName('searchKeyword')?.[0].defaultValue;
    let parentLstFilterData = parentLstData;
    if (customerTxt != '')
      parentLstFilterData = parentLstData?.filter((item) => item.estimateProducts.clientName?.includes(customerTxt));
    if (imgTxt != '')
      parentLstFilterData = parentLstFilterData?.filter((item) => item.estimateProducts.drawingNo?.includes(imgTxt));
    if (nameTxt != '')
      parentLstFilterData = parentLstFilterData?.filter((item) => item.estimateProducts.name?.includes(nameTxt));
    if (keywordTxt != '') parentLstFilterData = getDataByFilter(keywordTxt, parentLstFilterData);
    setInitialTableItems(parentLstFilterData);

    displayTotalCount = parentLstFilterData?.length; // リストの件数
  };

  const clearAllFilter = (e) => {
    setCustomer('');
    setImageNum('');
    setName('');
    setKeywords('');

    setInitialTableItems(initialTblItemsBk);
    displayTotalCount = initialTblItemsBk?.length; // リストの件数
  };

  // 複写ボタンの押下
  const displayCopyConfirm = () => {
    setIsCopy(true);
    // setCustomerCode(props?.clientInfo?.find((client) => client.id === selectedData?.estimateProducts?.clientId)?.code);
    setDrawingNo(selectedData.estimateProducts.drawingNo);
    setParentEstimateName(selectedData.estimateProducts.name);
    setEstimateStatus(selectedData.estimateProducts.estimateStatus);
    setChildTitle('複写');
  };

  const getCustomerName = () => {
    setCustomerModal(true);
  };

  const selectedCustomerData = (selectedCustomerData) => {
    setCustomerCode(selectedCustomerData.name);
    setCustomerModal(false);
  };

  // 複写情報入力ボックスのOKボタン押下
  const copyOk = async () => {
    const data = [...initialTblItemsBk];
    const copyData = JSON.parse(JSON.stringify(selectedData));

    copyData.estimateProducts = {
      ...copyData.estimateProducts,
      id: 0,
      drawingNo: drawingNo,
      name: parentEstimateName,
      estimateStatus: estimateStatus,
    };
    copyData.epCustomCosts = copyData?.epCustomCosts?.map((cost) => {
      return { ...cost, id: 0 };
    });
    copyData.epCustomDates = copyData?.epCustomDates?.map((date) => {
      return { ...date, id: 0 };
    });
    copyData.epCustomLabels = copyData?.epCustomLabels?.map((label) => {
      return { ...label, id: 0 };
    });
    copyData.epCustomMemos = copyData?.epCustomMemos?.map((memo) => {
      return { ...memo, id: 0 };
    });

    let estimateProductId = selectedData?.estimateProducts?.id;
    let iq3Infos = await getEstimateIq3Info(estimateProductId);

    copyData.esIq3Info = iq3Infos?.map((item) => ({ ...item, id: 0 }));

    let copiedData = await createEstimateProductData(copyData);

    data.push(copiedData);

    setSelectedRowKeys(copiedData.estimateProducts.id);
    setInitialTableItems(data);
    setInitialTableItemsBk(data);
    // const tblContents = extractDataByExcelColumns(data, modifiedChkColumns);
    // setEstimateProductsData(tblContents);
    setIsCopy(false);
    displayTotalCount = data?.length; // リストの件数
  };

  // 親部品見積情報をDBに新規登録する
  async function createEstimateProductData(createData) {
    let ret = [];
    ret = await createEstimateProductInfo(createData);
    return ret;
  }

  // 複写情報入力ボックスのCancelボタン押下
  const copyCancel = () => {
    setIsCopy(false);
  };

  const setCurrentPage = (value) => {
    setPage(value);
  };

  // まとめて編集
  const updateAll = () => {
    setClickedAction(4);

    if (editMode) {
      setEditModeCancelConfirm(true);
    } else {
      setEditMode(false);
      if (props.updateEstimateEditMode !== undefined) props.updateEstimateEditMode(false);
      setUpdateItem(initialTblItems);
      setIsUpdate(true);
      setChildTitle('選択したデータをまとめて編集');
    }
  };
  // 子部品確認
  const childOpen = () => {
    if (initialTblItems?.length < 1) {
      alert('確認するデータを選択してください');
    } else {
      setUpdateItem(selectedData);
      setIsChildOpen(true);
      setChildTitle('子部品確認');
    }
  };
  // 加工確認.工程別合計
  const manufactureOpen = () => {
    if (initialTblItems?.length < 1) {
      alert('確認するデータを選択してください');
    } else {
      setSelectedEstimateData(selectedData);
      setUpdateItem(selectedData);
      setIsManufactureOpen(true);
      setChildTitle('工程別合計');
    }
  };

  // CSV出力（生産管理連携）データ準備
  const prepareProductionManageExportData = (listData) => {
    let result = [];

    listData?.forEach((item) => {
      const {
        clientId,
        estimateNo,
        created,
        clientName,
        drawingNo,
        quantity,
        name,
        companyId,
        coeffDeliveryDatesId,
        coeffDeliveryDatesName,
        creater,
        modifier,
        estimateTotalPrice,
        managementFeeTotalPrice,
        purchaseTotalPrice,
        materialCostUnitPrice,
        processSelect,
        costTotalPrice,
        grossTotalPrice,
        additions,
      } = item.estimateProducts;

      const clientCode = props?.clientInfo?.find((client) => client.id === clientId)?.code;
      const branchNum = props?.clientInfo?.find((client) => client.id === clientId)?.info.branchNum;
      const company = props?.companyInfo?.find((item) => item.id === companyId);
      const targetObject = company?.coeffDeliveryDates?.find((item) => item.id === coeffDeliveryDatesId);
      let coeffDeliveryCode = '';
      if (targetObject) {
        coeffDeliveryCode = targetObject.code;
      }

      // 工程選択
      const kouteiData = processSelect?.details?.filter((i) => i.isUsedForCalc);
      const filteredKouteiResult = getKouteiListData(kouteiData);
      const kouteiCalData = updateKouteiCalData(additions);

      const { epCustomCosts, epCustomDates, epCustomLabels, epCustomMemos } = item;

      result.push({
        clientId: clientCode,
        estimateNo,
        created: formatDate(created),
        clientName,
        drawingNo,
        quantity,
        name,
        deliveryCode: coeffDeliveryCode,
        deliveryName: coeffDeliveryDatesName,
        creater,
        modifier,
        estimateTotalPrice: `￥${estimateTotalPrice}`,
        managementFeeTotalPrice: `￥${managementFeeTotalPrice}`,
        purchaseTotalPrice: `￥${purchaseTotalPrice}`,
        customerBranchNo: branchNum,
        materialCostUnitPrice: `￥${materialCostUnitPrice}`,
        costTotalPrice: `￥${costTotalPrice}`,
        grossTotalPrice: `￥${grossTotalPrice}`,
      });
      if (filteredKouteiResult?.length > 0) {
        filteredKouteiResult?.forEach((koutei, index) => {
          result.push({
            clientId: clientCode,
            estimateNo,
            created: formatDate(created),
            clientName,
            drawingNo,
            quantity,
            name,
            deliveryCode: coeffDeliveryCode,
            deliveryName: coeffDeliveryDatesName,
            creater,
            modifier,
            estimateTotalPrice: '',
            grossTotalPrice: '',
            managementFeeTotalPrice: '',
            purchaseTotalPrice: '',
            customerBranchNo: branchNum,
            materialCostUnitPrice: `￥${materialCostUnitPrice}`,
            route: index + 1,
            processCode: getProcessCode(koutei.deviceId),
            processName: getProcessName(koutei.deviceId),
            workTypeGroupCode: getProcessDetailGroupCode(koutei.deviceId, koutei.processDetailGroup),
            workTypeGroupName: getProcessDetailGroupName(koutei.deviceId, koutei.processDetailTypeId),
            singlePrepTime:
              kouteiCalData?.length > 0
                ? kouteiCalData[index]?.dataType === 0
                  ? kouteiCalData[index]?.dataItems.sagyoTime
                  : kouteiCalData[index]?.editItems.sagyoTime
                : '',
            prepTime:
              kouteiCalData?.length > 0
                ? kouteiCalData[index]?.dataType === 0
                  ? kouteiCalData[index]?.dataItems.sagyoTime * quantity
                  : kouteiCalData[index]?.editItems.sagyoTime * quantity
                : '',
            singleWorkTime:
              kouteiCalData?.length > 0
                ? kouteiCalData[index]?.dataType === 0
                  ? kouteiCalData[index]?.dataItems.dandoriTime
                  : kouteiCalData[index]?.editItems.dandoriTime
                : '',
            workTime:
              kouteiCalData?.length > 0
                ? kouteiCalData[index]?.dataType === 0
                  ? kouteiCalData[index]?.dataItems.dandoriTime * quantity
                  : kouteiCalData[index]?.editItems.dandoriTime * quantity
                : '',
            totalWorkTime:
              kouteiCalData?.length > 0
                ? kouteiCalData[index]?.dataType === 0
                  ? kouteiCalData[index]?.dataItems.sagyoTime * quantity +
                    kouteiCalData[index]?.dataItems.dandoriTime * quantity
                  : kouteiCalData[index]?.editItems.sagyoTime * quantity +
                    kouteiCalData[index]?.editItems.dandoriTime * quantity
                : '',
            totalCost:
              kouteiCalData?.length > 0
                ? kouteiCalData[index]?.dataType === 0
                  ? `￥${kouteiCalData[index]?.dataItems.sagyoAmt + kouteiCalData[index]?.dataItems.dandoriAmt}`
                  : `￥${kouteiCalData[index]?.editItems.sagyoAmt + kouteiCalData[index]?.editItems.dandoriAmt}`
                : '',
          });
        });
      }
      if (
        epCustomCosts?.length === 0 &&
        epCustomDates?.length === 0 &&
        epCustomLabels?.length === 0 &&
        epCustomMemos?.length === 0
      ) {
        result.push({
          clientId: clientCode,
          estimateNo,
          created: formatDate(created),
          clientName,
          drawingNo,
          quantity,
          name,
          deliveryCode: coeffDeliveryCode,
          deliveryName: coeffDeliveryDatesName,
          creater,
          modifier,
          estimateTotalPrice: '',
          managementFeeTotalPrice: '',
          purchaseTotalPrice: '',
          customerBranchNo: branchNum,
          materialCostUnitPrice: `￥${materialCostUnitPrice}`,
          grossTotalPrice: '',
        });
      } else {
        if (epCustomCosts?.length > 0) {
          epCustomCosts?.forEach((costItem) => {
            result.push({
              clientId: clientCode,
              estimateNo,
              created: formatDate(created),
              clientName,
              drawingNo,
              quantity,
              name,
              label: costItem.label,
              deliveryCode: coeffDeliveryCode,
              deliveryName: coeffDeliveryDatesName,
              creater,
              modifier,
              estimateTotalPrice: '',
              grossTotalPrice: '',
              managementFeeTotalPrice: '',
              purchaseTotalPrice: '',
              customerBranchNo: branchNum,
              materialCostUnitPrice: `￥${materialCostUnitPrice}`,
              ep_custom: '費用',
              customCode: costItem.additionalItemCode,
              customName: costItem.additionalItemName,
              totalCostAmount: `￥${costItem.totalPrice}`,
            });
          });
        }
        if (epCustomDates?.length > 0) {
          epCustomDates?.forEach((dateItem) => {
            result.push({
              clientId: clientCode,
              estimateNo,
              created: formatDate(created),
              clientName,
              drawingNo,
              quantity,
              name,
              label: dateItem.date,
              deliveryCode: coeffDeliveryCode,
              deliveryName: coeffDeliveryDatesName,
              creater,
              modifier,
              estimateTotalPrice: '',
              grossTotalPrice: '',
              managementFeeTotalPrice: '',
              purchaseTotalPrice: '',
              customerBranchNo: branchNum,
              materialCostUnitPrice: `￥${materialCostUnitPrice}`,
              ep_custom: '日付',
              customCode: dateItem.additionalItemCode,
              customName: dateItem.additionalItemName,
              customDate: formatDate(dateItem.date),
            });
          });
        }
        if (epCustomLabels?.length > 0) {
          epCustomLabels?.forEach((labelItem) => {
            result.push({
              clientId: clientCode,
              estimateNo,
              created: formatDate(created),
              clientName,
              drawingNo,
              quantity,
              name,
              label: labelItem.label,
              deliveryCode: coeffDeliveryCode,
              deliveryName: coeffDeliveryDatesName,
              creater,
              modifier,
              estimateTotalPrice: '',
              grossTotalPrice: '',
              managementFeeTotalPrice: '',
              purchaseTotalPrice: '',
              customerBranchNo: branchNum,
              materialCostUnitPrice: `￥${materialCostUnitPrice}`,
              ep_custom: 'ラベル',
              customCode: labelItem.additionalItemCode,
              customName: labelItem.additionalItemName,
              customText: labelItem.label,
            });
          });
        }
        if (epCustomMemos?.length > 0) {
          epCustomMemos?.forEach((memoItem) => {
            result.push({
              clientId: clientCode,
              estimateNo,
              created: formatDate(created),
              clientName,
              drawingNo,
              quantity,
              name,
              memo: memoItem.memo,
              deliveryCode: coeffDeliveryCode,
              deliveryName: coeffDeliveryDatesName,
              creater,
              modifier,
              estimateTotalPrice: '',
              grossTotalPrice: '',
              managementFeeTotalPrice: '',
              purchaseTotalPrice: '',
              customerBranchNo: branchNum,
              materialCostUnitPrice: `￥${materialCostUnitPrice}`,
              ep_custom: 'メモ',
              customCode: memoItem.additionalItemCode,
              customName: memoItem.additionalItemName,
              customText: memoItem.memo,
            });
          });
        }
      }
    });

    return result;
  };

  const estimateExportData = prepareProductionManageExportData(initialTblItems);
  // リストCSV出力のヘッダ
  const prepareExportCol = (tblCols) => {
    const updatedCols = tblCols?.map((item) => ({
      label: item.title,
      key: item.title,
    }));
    return updatedCols;
  };
  // 出力するヘッダ準備
  const prepareChkDataColumns = (cols) => {
    const updatedCols = cols?.map((item) => item.title);
    return updatedCols;
  };
  // 帳票出力
  const reportOutputChangeEvent = async (e) => {
    if (e.key == 'listReport') {
      fetchListData(); // Call the async function
      setIsReportOutputOpen(true);
      setChildTitle('リスト帳票');
      setIsListReport(true);
    } else if (e.key == 'parentReport') {
      let estimateProductId = selectedData?.estimateProducts?.id;
      let iq3Infos = await getEstimateIq3Info(estimateProductId);
      setSelectedIq3Data(iq3Infos);
      fetchIndividualData(); // Call the async function
      setIsReportOutputOpen(true);
      setChildTitle('親部品帳票');
      setIsIndividualReport(true);
    } else if (e.key == 'csvReport') {
      setFileName(`親部品見積_${formatDateString(todayTime())}?.csv`);
      csvFile.current.link.click();

      // setIsListOutputOpen(true);
      // setIsReportOutputOpen(true);
      // setChildTitle('CSV出力');
    } else if (e.key === 'csvAllReport') {
      setProductionManageCSVFileName(`親部品見積(生産管理連携)_${formatDateString(todayTime())}?.csv`);
      productionManageCSVFile.current.link.click();
    }
  };
  // リスト出力
  const listOutputChangeEvent = (e) => {
    setOpenCommonTaiochuPopup(true);
    // if (e.key == 'listReport') {
    //   setIsListOutputOpen(true);
    //   setChildTitle('CSV出力(表示)');
    // } else if (e.key == 'allReport') {
    //   setIsListOutputOpen(true);
    //   setChildTitle('CSV出力(全て)');
    // } else if (e.key == 'iQReport') {
    //   setIsListOutputOpen(true);
    //   setChildTitle('CSV出力(全て)');
    // } else {
    //   setIsListOutputOpen(true);
    //   setChildTitle('明細出力');
    // }
  };
  // 子部品確認・加工確認(工程別合計)
  const infoConfirmOutputMenuChangeEvent = async (e) => {
    // setOpenCommonTaiochuPopup(true);
    if (initialTblItems?.length < 1) {
      alert('確認するデータを選択してください');
    } else {
      if (e.key == 'childConfirm') {
        setUpdateItem(selectedData);
        setIsChildOpen(true);
        setChildTitle('子部品確認');
      } else if (e.key == 'processConfirm') {
        const estimateProductId = selectedData?.estimateProducts.id;
        let iq3Infos = await getEstimateIq3Info(estimateProductId);

        selectedData.esIq3Info = iq3Infos;
        setSelectedEstimateData(selectedData);
        setUpdateItem(selectedData);
        setIsManufactureOpen(true);
        setChildTitle('工程別合計');
      }
    }
  };
  const listOutputOk = () => {
    setIsListOutputOpen(false);
  };
  const listOutputCancel = () => {
    setIsListOutputOpen(false);
  };
  // 新規作成
  const createParent = () => {
    setClickedAction(2);
    if (editMode) {
      setConfirmScreenChangeModalOpen(true);
    } else {
      setConfirmDisplayDataSaveModalOpen(true);
    }
  };
  const goToCreateParentScreen = () => {
    setConfirmDisplayDataSaveModalOpen(false);
    setEditMode(false);
    if (props.updateEstimateEditMode !== undefined) props.updateEstimateEditMode(false);
    // props.getSelectedEstimateData(getNewParentInfo(initialTblItems?.length), null, true);
    navigate(':estimate.parent');
  };
  const discardGoToCreateParentScreen = () => {
    setConfirmDisplayDataSaveModalOpen(false);
  };

  // 開発中ポップアップ
  const closeCommonTaiochuPopup = () => {
    setOpenCommonTaiochuPopup(false);
  };
  // 呼出
  const commonTaiochuPopupOpen = () => {
    setOpenCommonTaiochuPopup(true);
  };

  const reportOutputOpen = () => {
    if (initialTblItems?.length < 1) {
      alert('確認するデータを選択してください');
    } else {
      setUpdateItem(selectedData);
      setIsReportOutputOpen(true);
      setChildTitle('リスト帳票出力');
    }
  };

  //Serverからフォーマットファイルにあるcolumnを取得
  //param (HTML型フォーマットテーブル)
  function extractHeaderFromHtmlTable(tableHtml) {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = tableHtml;

    const table = tempDiv.querySelector('table');
    let tdContentArray;

    if (table) {
      const rows = table.querySelectorAll('tr');
      rows?.forEach((row) => {
        const cells = row.querySelectorAll('td');
        const cellContentArray = [];

        cells?.forEach((cell) => {
          const cellContent = cell.textContent.trim();
          cellContentArray.push(cellContent);
        });

        tdContentArray = cellContentArray;
      });
    } else {
      console.error('Table not found in HTML content.');
    }

    return tdContentArray;
  }
  //dataIndexによって情報取得
  const extractColumnData = (record, column) => {
    const dbNameAttr = column.dbNameAttr;
    const dataIndex = column.dataIndex;
    const nestedDataIndex = dataIndex.split('.');
    let value = record;

    nestedDataIndex?.forEach((index) => {
      if (value) {
        value = value[index];
      }
    });

    if (value === undefined) {
      return 0;
    }

    if (column.render) {
      return column.render(value, record);
      /* return column.render(value); */
    }

    return value;
  };

  //フォーマットによって画面に表示しいてるdata取得
  function extractDataByExcelColumns(data, excelColumnHeaders, columnList) {
    const arrayOfObjects = [];

    let initDisplayColumns = getDisplayColumns();
    if (columnList) {
      initDisplayColumns = columnList;
    }
    data?.forEach((record, index) => {
      const rowData = {};
      excelColumnHeaders?.forEach((header) => {
        // defaultColumns?.forEach((column) => {
        const column = initDisplayColumns?.find((col) => col.title === header);
        if (column) {
          const columnNameT = column.id + column.title;
          const columnName = column.title;
          const columnData = extractColumnData(record, column);
          if (typeof columnData === 'object') {
            if (columnData.props?.children?.props !== undefined) {
              rowData[columnName] = columnData.props?.children?.props?.children;
            } else if (Array.isArray(columnData.props?.children)) {
              rowData[columnName] = columnData.props?.children?.[1];
            } else if (columnData.props?.children !== undefined) {
              rowData[columnName] = columnData.props?.children;
            } else if (columnData.props?.checked !== undefined) {
              rowData[columnName] = 'true' /* columnData.props?.checked */;
            }
          } else if (columnName == 'ID') {
            rowData[columnName] = data?.length - index;
          } else {
            rowData[columnName] = columnData;
          }
        } else {
          rowData[header] = null; // Handle columns not found in defaultColumns
        }
      });
      arrayOfObjects.push(rowData);
    });
    return arrayOfObjects;
  }
  const exportData = extractDataByExcelColumns(initialTblItems, modifiedChkColumns);

  //HTML Table作成
  //param (テーブル column, テーブル内容)
  function createHtmlTable(header, data) {
    // Create the table element
    let table = document.createElement('table');

    if (header !== '') {
      // Create the table header row
      let headerRow = document.createElement('tr');
      for (let columnHeader of header) {
        let th = document.createElement('th');
        th.textContent = columnHeader;
        headerRow.appendChild(th);
      }
      table.appendChild(headerRow);

      // Create table rows for each data entry
      for (let rowData of data) {
        let row = document.createElement('tr');
        for (let columnHeader of header) {
          let td = document.createElement('td');
          td.textContent = rowData[columnHeader];
          row.appendChild(td);
        }
        table.appendChild(row);
      }
    } else {
      for (let rowData of data) {
        let row = document.createElement('tr');
        for (let key in rowData) {
          let td = document.createElement('td');
          if (Object.hasOwnProperty.call(rowData, key)) {
            td.textContent = rowData[key];
          }
          row.appendChild(td);
        }
        table.appendChild(row);
      }
    }

    // Serialize the table to an HTML string
    let htmlString = table.outerHTML;

    return htmlString;
  }

  function getNameById(id) {
    let retValue = '';
    if (id) {
      let info = purchaseCategoryInfos?.filter((info) => info.id === id);
      if (info?.length > 0) {
        retValue = info[0].name;
      }
    }
    return retValue;
  }

  const createAdditionalItemsTbl = () => {
    const customFieldData = {
      '■ｶｽﾀﾑﾌｨｰﾙﾄﾞ[費用]': [],
      '■ｶｽﾀﾑﾌｨｰﾙﾄﾞ[ラベル]': [],
      '■ｶｽﾀﾑﾌｨｰﾙﾄﾞ[日付]': [],
      '■ｶｽﾀﾑﾌｨｰﾙﾄﾞ[メモ]': [],
    };

    // Populate the arrays with data
    customFieldData['■ｶｽﾀﾑﾌｨｰﾙﾄﾞ[費用]'] = selectedData.epCustomCosts?.map((item, index) => ({
      id: index + 1,
      additionalItemCode: item?.additionalItemCode,
      additionalItemName: item?.additionalItemName,
      unitPrice: item?.unitPrice,
      quantity: selectedData?.estimateProducts?.quantity,
      totalPrice: item?.totalPrice,
    }));

    customFieldData['■ｶｽﾀﾑﾌｨｰﾙﾄﾞ[ラベル]'] = selectedData.epCustomLabels?.map((item, index) => ({
      id: index + 1,
      additionalItemCode: item?.additionalItemCode,
      additionalItemName: item?.additionalItemName,
      label: item?.label,
    }));

    customFieldData['■ｶｽﾀﾑﾌｨｰﾙﾄﾞ[日付]'] = selectedData.epCustomDates?.map((item, index) => ({
      id: index + 1,
      additionalItemCode: item?.additionalItemCode,
      additionalItemName: item?.additionalItemName,
      date: item?.date,
    }));

    customFieldData['■ｶｽﾀﾑﾌｨｰﾙﾄﾞ[メモ]'] = selectedData.epCustomMemos?.map((item, index) => ({
      id: index + 1,
      additionalItemCode: item?.additionalItemCode,
      additionalItemName: item?.additionalItemName,
      memo: item?.memo,
    }));
    return customFieldData;
  };

  const createProcessDataTbl = () => {
    const customFieldData = {};
    let calculateData = selectedData.estimateProducts.additions;
    let calInputData = selectedData.estimateProducts.processInput;
    let processMasterData = processInfo;
    let calProcessMstData = [];
    calculateData?.forEach((calData, index1) => {
      let calProcess = processMasterData?.filter((record) => record?.id === calData.processId)?.[0];
      calProcessMstData.push(calProcess);
      let count = 1;
      calData.dataItems?.forEach((e, index) => {
        let totalAmt;
        let totalDandoriAmt;
        let totalSagyoAmt;
        let totalDandoriTime;
        let totalSagyoTime;
        if (calData.dataType == 0) {
          totalAmt = e?.dandoriAmt + e?.sagyoAmt;
        } else if (e.dataType == 1) {
          totalAmt = e?.dandoriAmt + e?.sagyoAmt;
        }
        let processInput = {};
        // Iterate through the processInput properties
        for (const prop in calInputData) {
          if (Object.hasOwnProperty.call(calInputData, prop)) {
            const items = calInputData[prop];

            // Check if the property is an array
            if (Array.isArray(items)) {
              const filteredItems = items?.filter(
                (item) =>
                  item?.workType === calProcess?.workType &&
                  e.processDetailTypeNo == item.processDetailTypeNo &&
                  e.processDetailWorkTypeGroup == item.processDetailWorkTypeGroup &&
                  e.id == item.no
              );
              processInput = filteredItems;
            }
          }
        }
        if (!customFieldData[calProcess?.workType]) {
          customFieldData[calProcess?.workType] = [];
        }
        customFieldData[calProcess?.workType].push({
          no: index1 + 1,
          classification: calProcess.class == 0 ? '親部品' : calProcess.class == 1 ? '板金' : '',
          type: calProcess.isAdditional ? '追加' : '標準',
          processCode: calProcess.code,
          processName: calProcess.name,
          processType: (() => {
            const matchType = kouteiMasterTypes?.find((type) => type.workType === calProcess.workType);
            return matchType ? matchType.typeName : null;
          })(),
          no2: count,
          detailCode: calProcess?.details?.processDetailTypes?.filter(
            (record) => record?.id === e?.processDetailTypeNo
          )?.[0]?.code,
          processDetailType: calProcess?.details?.processDetailTypes?.filter(
            (record) => record?.id === e?.processDetailTypeNo
          )?.[0]?.name,
          parentCount: 1,
          childCount: '',
          kaitsuu: selectedData?.estimateProducts?.quantity,
          menseki: processInput?.[0]?.totalArea,
          jyuuryou: processInput?.[0]?.totalWeight,
          estTotalAmt: totalAmt,
          estDandoriAmt: e.dandoriAmt,
          estWorkAmt: e.sagyoAmt,
          gankaTotalAmt: totalAmt,
          gankaDandoriAmt: e.dandoriAmt,
          gankaSagyoAmt: e.sagyoAmt,
          gankaDandoriTime: secondsToHms(e.dandoriTime),
          gankaSagyoTime: secondsToHms(e.sagyoTime),
        });
        count++;
      });
    });
    return customFieldData;
  };

  const createProcessDataTbl2 = () => {
    const customFieldData = {};
    let calculateData = selectedData.estimateProducts.additions;
    let calInputData = selectedData.estimateProducts.processInput;
    let processMasterData = processInfo;
    let calProcessMstData = [];
    calculateData?.forEach((calData, index1) => {
      let calProcess = processMasterData?.filter((record) => record?.id === calData.processId)?.[0];
      calProcessMstData.push(calProcess);

      calData.dataItems?.forEach((e, index) => {
        let totalAmt;
        let totalDandoriAmt;
        let totalSagyoAmt;
        let totalDandoriTime;
        let totalSagyoTime;
        if (calData.dataType == 0) {
          totalAmt = e?.dandoriAmt + e?.sagyoAmt;
        } else if (e.dataType == 1) {
          totalAmt = e?.dandoriAmt + e?.sagyoAmt;
        }
        let processInput = {};
        // Iterate through the processInput properties
        for (const prop in calInputData) {
          if (Object.hasOwnProperty.call(calInputData, prop)) {
            const items = calInputData[prop];

            // Check if the property is an array
            if (Array.isArray(items)) {
              const filteredItems = items?.filter(
                (item) =>
                  item?.workType === calProcess?.workType &&
                  e.processDetailTypeNo == item.processDetailTypeNo &&
                  e.processDetailWorkTypeGroup == item.processDetailWorkTypeGroup &&
                  e.id == item.no
              );
              processInput = filteredItems;
            }
          }
        }
        const commonAttribute =
          calProcess.workType == WorkType.PaCustom
            ? 'Additional' + calProcess?.workType + calProcess?.name
            : calProcess?.workType;

        if (!customFieldData[commonAttribute]) {
          customFieldData[commonAttribute] = [];
        }
        customFieldData[commonAttribute].push({
          'No.': index1 + 1,
          分類: calProcess.class == 0 ? '親部品' : calProcess.class == 1 ? '板金' : '',
          種別: calProcess.isAdditional ? '追加' : '標準',
          工程ｺｰﾄﾞ: calProcess.code,
          工程名: calProcess.name,
          工程ﾀｲﾌﾟ: (() => {
            const matchType = kouteiMasterTypes?.find((type) => type.workType === calProcess.workType);
            return matchType ? matchType.typeName : null;
          })(),
          'No.': index + 1,
          種類ｺｰﾄﾞ: calProcess?.details?.processDetailTypes?.filter(
            (record) => record?.id === e?.processDetailTypeNo
          )?.[0]?.code,
          種類: calProcess?.details?.processDetailTypes?.filter((record) => record?.id === e?.processDetailTypeNo)?.[0]
            ?.name,
          親部品数量: 1,
          '個数(子部品点数)': '',
          回数: selectedData?.estimateProducts?.quantity,
          面積: processInput?.[0]?.totalArea,
          重量: processInput?.[0]?.totalWeight,
          見積合計金額: totalAmt,
          段取金額: e.dandoriAmt,
          加工金額: e.sagyoAmt,
          原価合計金額: totalAmt,
          段取金額: e.dandoriAmt,
          加工金額: e.sagyoAmt,
          段取時間: secondsToHms(e.dandoriTime),
          加工時間: secondsToHms(e.sagyoTime),
        });
      });
    });
    return customFieldData;
  };

  const getChildProcessCount = () => {
    let processMasterData = processInfo;
    const isUsedForCalcArrayPerItem = selectedIq3Data?.map((item) =>
      item?.processSelect?.details?.filter((detail) => detail.isUsedForCalc)?.map((detail) => detail.processId)
    );
    const foundCountArr = [];
    isUsedForCalcArrayPerItem?.forEach((subArray) => {
      const subArrayCounts = subArray?.reduce((count, num) => {
        count[num] = (count[num] || 0) + 1;
        return count;
      }, {});

      for (const key in subArrayCounts) {
        foundCountArr[key] = (foundCountArr[key] || 0) + subArrayCounts[key];
      }
    });

    let dataSheet4 = [];
    Object.keys(foundCountArr)?.forEach((key, index) => {
      let useProcessMasterData = processMasterData?.filter((record) => record?.id === parseInt(key))?.[0];
      let matchType = kouteiMasterTypes?.find((type) => type.workType === useProcessMasterData.workType);
      let relatedType = matchType ? matchType.typeName : null;
      dataSheet4.push({
        no: index + 1,
        id: parseInt(key),
        classification: useProcessMasterData.class == 0 ? '親部品' : useProcessMasterData.class == 1 ? 'iQ3' : '',
        type: useProcessMasterData.isAdditional ? '追加' : '標準',
        processCode: useProcessMasterData.code,
        processName: useProcessMasterData.name,
        count: foundCountArr[key],
      });
    });

    return dataSheet4;
  };

  const getSheet5Data = (usedProcessList) => {
    const sheet5Data = [];
    selectedIq3Data?.forEach((estData, index) => {
      let additionsData = estData.additions;
      let hijyu = selectedData.estimateProducts.calcParameters.parameters.materialTypeIq3
        ?.filter((e) => e.id === estData.materialIq3Id)
        ?.map((e) => e.info.gravity)[0];

      const resultData = [];
      const sheet5Object = {
        no: index + 1,
        drawingNo: estData.drawingNo,
        name: estData.name,
        partCount: estData.partCount,
        prodNo: '',
        partSizeX: estData.partSizeX,
        partSizeY: estData.partSizeY,
        '(空白)': '',
        材料名称: estData.materialName,
        材質: estData.materialTypeName,
        板厚: estData.thickness,
        材料比重: hijyu,
        表面保護属性: estData.surfaceProtection,
        重量: estData.weight,
        表面積: estData.totalSurfaceArea,
        合計金額: estData.materialCostUnitPrice,
        歩留: estData.yield,
        枚数: estData.materialQuantity,
        重量単価: estData.weightUnitPrice,
        表面加算: estData.surfaceAddPrice,
        重量加算: estData.weightAddPrice,
        材料単価: estData.materialCostUnitPrice,
        原価率: estData.materialCostTotalRate,
        スクラップ: estData.scrapPrice,
        合計金額1: estData?.materialDetailPatterns[0]?.totalPrice,
        歩留1: estData?.materialDetailPatterns?.[0]?.yield,
        枚数1: estData?.materialDetailPatterns[0]?.materialQuantity,
        材料サイズ選択: estData?.materialDetailPatterns[0]?.sizeName,
        サイズX: estData?.materialDetailPatterns[0]?.sizeX,
        サイズY: estData?.materialDetailPatterns[0]?.sizeY,
        合計金額2: estData?.materialDetailPatterns[1]?.totalPrice,
        歩留2: estData?.materialDetailPatterns[1]?.yield,
        枚数2: estData?.materialDetailPatterns[1]?.materialQuantity,
        材料サイズ選択1: estData?.materialDetailPatterns[1]?.sizeName,
        サイズX1: estData?.materialDetailPatterns[1]?.sizeX,
        サイズY1: estData?.materialDetailPatterns[1]?.sizeY,
        '(空白)1': '',
        単価: estData.costUnitPrice,
        合計: estData.costTotalPrice,
        '(空白)2': '',
        材料単価1: estData.materialCostUnitPrice,
        加工単価: estData.workCostUnitPrice,
        購入品単価: estData.purchaseUnitPrice,
        材料合計: estData.materialCostTotalPrice,
        加工合計: estData.workCostTotalPrice,
        購入品合計: estData.purchaseTotalPrice,
        '(空白)3': '',
        単価1: selectedData.estimateUnitPrice,
        合計1: selectedData.estimateTotalPrice,
        '(空白)4': '',
        材料単価2: '',
        加工単価1: '',
        購入品単価1: '',
        材料合計1: '',
        加工合計1: '',
        購入品合計1: '',
        '(空白)5': '',
      };

      usedProcessList?.forEach((item) => {
        const id = item.id.id;
        const matchedData = additionsData?.find((addition) => addition.processId === id);

        if (matchedData) {
          const { dataType, totalDandori, totalSagyo } = matchedData;
          if (id == 15) {
            resultData[id] = {
              menseki: estData.totalSurfaceArea,
              jyuuryou: estData.weight,
              [`setupAmount${id}`]: dataType === 0 ? totalDandori.dataItemsAmt : totalDandori.editItemsAmt,
              [`setupTime${id}`]: dataType === 0 ? totalDandori.dataItemsTime : totalDandori.editItemsTime,
              [`processingAmount${id}`]: dataType === 0 ? totalSagyo.dataItemsAmt : totalSagyo.editItemsAmt,
              [`processingTime${id}`]: dataType === 0 ? totalSagyo.dataItemsTime : totalSagyo.editItemsTime,
              [`space${id}`]: '',
            };
          } else {
            resultData[id] = {
              [`setupAmount${id}`]: dataType === 0 ? totalDandori.dataItemsAmt : totalDandori.editItemsAmt,
              [`setupTime${id}`]: dataType === 0 ? totalDandori.dataItemsTime : totalDandori.editItemsTime,
              [`processingAmount${id}`]: dataType === 0 ? totalSagyo.dataItemsAmt : totalSagyo.editItemsAmt,
              [`processingTime${id}`]: dataType === 0 ? totalSagyo.dataItemsTime : totalSagyo.editItemsTime,
              [`space${id}`]: '',
            };
          }
        } else {
          if (id != 15) {
            resultData[id] = {
              [`setupAmount${id}`]: '',
              [`setupTime${id}`]: '',
              [`processingAmount${id}`]: '',
              [`processingTime${id}`]: '',
              [`space${id}`]: '',
            };
          } else {
            resultData[id] = {
              menseki: '',
              jyuuryou: '',
              [`setupAmount${id}`]: '',
              [`setupTime${id}`]: '',
              [`setupAmount${id}`]: '',
              [`processingTime${id}`]: '',
              [`space${id}`]: '',
            };
          }
        }
      });
      const mergedSheet5Object = [sheet5Object, ...resultData];
      const transformedObject = mergedSheet5Object?.reduce((result, item) => {
        for (const key in item) {
          result[key] = item[key];
        }
        return result;
      }, {});
      sheet5Data.push(transformedObject);
    });

    return sheet5Data;
  };

  const getSheet6Data = (usedProcessList) => {
    const sheet6Data = [];
    let calParam = selectedData?.estimateProducts?.calcParameters?.parameters;

    selectedIq3Data?.forEach((estData, index) => {
      sheet6Data[index] = {};
      let processSelect = estData.processSelect.details; //isUsedForCalcArrPerItem[index];
      let processInput = estData.processInput;

      const sheet6Object = [
        {
          no: index + 1,
          drawingNo: estData.drawingNo,
          name: estData.name,
          partCount: estData.partCount,
          prodNo: '',
          '(空白)': '',
        },
      ];
      sheet6Data[index]['partInfo'] = sheet6Object;
      usedProcessList?.forEach((item) => {
        sheet6Data[index][item.processName] = [];
        const id = item.id;
        let param = item.param;
        const matchedData = processSelect?.find((process) => process.processId === id);
        const matchedProcessInput = processInput?.find((process) => process.processId === id);
        let obj = {};
        if (matchedData.isUsedForCalc) {
          let matchedDevice = calParam?.device?.find((devic) => devic?.id === matchedData?.deviceId);
          let processMst = calParam?.process?.find((process) => process?.id === matchedData?.processId);
          let processCode = processMst.code;
          let deviceCode = matchedDevice === undefined ? '' : matchedDevice?.code;
          let deviceName = matchedDevice === undefined ? '' : matchedDevice?.name;

          obj.processCode = processCode;
          obj.deviceCode = deviceCode;
          obj.deviceName = deviceName;

          if (id === 10) {
            let element = processInput?.details?.[0]?.elements;
            obj.element = element;
            obj['(空白)'] = '';
            sheet6Data[index][item.processName].push(obj);
          } else if (id === 11 || id == 12 || id == 13) {
            obj.detailCode = processMst.code;
            obj.detailName = processMst.name;
            if (matchedProcessInput?.details !== undefined && matchedProcessInput?.details?.length > 0) {
              obj.innerDia = matchedProcessInput?.details?.[0]?.dimensionData?.innerDia;
              obj.outerDia = matchedProcessInput?.details?.[0]?.dimensionData?.outerDia;
              obj.airFeedDistance = matchedProcessInput?.details?.[0]?.dimensionData?.airFeedDistance;
              obj['(空白)'] = '';
            } else {
              obj.innerDia = '';
              obj.outerDia = '';
              obj.airFeedDistance = '';
              obj['(空白)'] = '';
            }
            sheet6Data[index][item.processName].push(obj);
          } else if (id === 14) {
            let bendingArr = [];
            if (matchedProcessInput?.details !== undefined && matchedProcessInput?.details?.length > 0) {
              matchedProcessInput?.details?.[0]?.bendingList?.forEach((bending) => {
                let matchedDetailType = processMst?.details?.processDetailTypes?.find(
                  (detailType) => detailType.id === bending.types
                );
                let obj1 = {};
                obj1.processCode = processCode;
                obj1.deviceCode = deviceCode;
                obj1.deviceName = deviceName;
                obj1.detailCode = matchedDetailType?.code;
                obj1.detailName = matchedDetailType?.name;
                obj1.bendCount = bending?.bendCount;
                if (bending.type == 3) {
                  obj1.bendCountFR = bending?.bendCountFR;
                } else {
                  obj1.bendCountFR = '';
                }
                //bendingArr.push(obj);
                obj1['(空白)'] = '';
                sheet6Data[index][item.processName].push(obj1);
              });
            } else {
              obj.detailCode = '';
              obj.detailName = '';
              obj.bendCount = '';
              obj.bendCountFR = '';
              //bendingArr.push(obj);
              obj['(空白)'] = '';
              sheet6Data[index][item.processName].push(obj);
            }
            //sheet6Data[index][item.processName].push(bendingArr);
          } else if (id === 15) {
            if (matchedProcessInput?.details !== undefined && matchedProcessInput?.details?.length > 0) {
              obj.detailCode = processMst.code;
              obj.detailName = processMst.name;

              obj.area = matchedProcessInput?.details?.[0]?.area;
              obj.weight = matchedProcessInput?.details?.[0]?.weight;
              obj.outerLength = '';
              obj.innerLength = '';
              obj.count = matchedProcessInput?.details?.[0]?.count;
              obj['(空白)'] = '';

              sheet6Data[index][item.processName].push(obj);
            } else {
              obj.detailCode = '';
              obj.detailName = '';
              obj.area = '';
              obj.weight = '';
              obj.outerLength = '';
              obj.innerLength = '';
              obj.count = '';
              obj['(空白)'] = '';
              sheet6Data[index][item.processName].push(obj);
            }
          } else if (id === 16) {
            if (matchedProcessInput?.details !== undefined && matchedProcessInput?.details?.length > 0) {
              obj.detailCode = processMst.code;
              obj.detailName = processMst.name;

              obj.area = '';
              obj.weight = '';

              obj.outerLength = matchedProcessInput?.details?.[0]?.length;
              obj.outerCount = matchedProcessInput?.details?.[0]?.count;
              obj.innerLength = matchedProcessInput?.details?.[1]?.length;
              obj.innerCount = matchedProcessInput?.details?.[1]?.count;
              obj['(空白)'] = '';

              sheet6Data[index][item.processName].push(obj);
            } else {
              obj.detailCode = '';
              obj.detailName = '';
              obj.area = '';
              obj.weight = '';
              obj.outerLength = '';
              obj.innerLength = '';
              obj.outerCount = '';
              obj.innerCount = '';
              obj['(空白)'] = '';
              sheet6Data[index][item.processName].push(obj);
            }
          } else if (id === 17) {
            let hyomenArr = [];
            if (matchedProcessInput?.details !== undefined && matchedProcessInput?.details?.length > 0) {
              matchedProcessInput?.details?.forEach((hyomen) => {
                let matchedDetailType = processMst?.details?.processDetailTypes?.find(
                  (detailType) => detailType.id === hyomen.processDetailWorkTypeGroup
                );
                let obj1 = {};
                obj1.processCode = processCode;
                obj1.deviceCode = deviceCode;
                obj1.deviceName = deviceName;
                obj1.detailCode = matchedDetailType?.code;
                obj1.detailName = matchedDetailType?.name;
                obj1.areaWeight = hyomen?.calcType == 1 ? '面積' : '重量';
                obj1.count = hyomen?.calcType == 1 ? hyomen?.areaCount : hyomen?.weightCount;
                obj1['(空白)'] = '';
                sheet6Data[index][item.processName].push(obj1);
              });
            } else {
              obj.detailCode = '';
              obj.detailName = '';
              obj.areaWeight = '';
              obj.count = '';
              obj['(空白)'] = '';
              sheet6Data[index][item.processName].push(obj);
            }
            //sheet6Data[index][item.processName].push(hyomenArr);
          } else if (id === 18) {
            let nijiArr = [];
            if (matchedProcessInput?.details !== undefined && matchedProcessInput?.details?.length > 0) {
              matchedProcessInput?.details?.forEach((niji) => {
                let matchedDetailType = processMst?.details?.processDetailTypes?.find(
                  (detailType) => detailType.id === niji.types
                );
                let obj1 = {};
                obj1.processCode = processCode;
                obj1.deviceCode = deviceCode;
                obj1.deviceName = deviceName;
                obj1.detailCode = matchedDetailType?.code;
                obj1.detailName = matchedDetailType?.name;
                obj1.count = niji?.count;
                obj1.x = niji?.xsize;
                obj1.y = niji?.ysize;
                obj1.perimeter = niji?.perimeter;
                //nijiArr.push(obj);
                obj1['(空白)'] = '';
                sheet6Data[index][item.processName].push(obj1);
              });
            } else {
              obj.detailCode = '';
              obj.detailName = '';
              obj.count = '';
              obj.x = '';
              obj.y = '';
              obj.perimeter = '';
              //nijiArr.push(obj);
              obj['(空白)'] = '';
              sheet6Data[index][item.processName].push(obj);
            }
            // sheet6Data[index][item.processName].push(nijiArr);
          } else if (id === 19) {
            let yosetsuArr = [];
            if (matchedProcessInput?.details !== undefined && matchedProcessInput?.details?.length > 0) {
              matchedProcessInput?.details?.[0]?.weldingList?.forEach((weld) => {
                let matchedDetailType = processMst?.details?.processDetailTypes?.find(
                  (detailType) => detailType.id === weld.yousetsuType
                );
                let obj1 = {};
                obj1.processCode = processCode;
                obj1.deviceCode = deviceCode;
                obj1.deviceName = deviceName;
                obj1.detailCode = matchedDetailType?.code;
                obj1.detailName = matchedDetailType?.name;
                obj1.count = '';
                obj1.type = '';
                obj1.senchou = String(weld?.senchou) + '/' + String(weld?.senchouKaisu);
                //yosetsuArr.push(obj);
                obj1['(空白)'] = '';
                sheet6Data[index][item.processName].push(obj1);
              });
              matchedProcessInput?.details?.[0]?.subWeldingList?.forEach((subWeld) => {
                let matchedDetailType = processMst?.details?.processDetailTypes?.find(
                  (detailType) => detailType.id === subWeld.subWeldingType
                );
                let obj1 = {};
                obj1.processCode = processCode;
                obj1.deviceCode = deviceCode;
                obj1.deviceName = deviceName;
                obj1.detailCode = matchedDetailType?.code;
                obj1.detailName = matchedDetailType?.name;
                obj1.count = subWeld.count;
                obj1.type = matchedDetailType.name + '/' + subWeld.daimeter;
                obj1.senchou = '';
                // yosetsuArr.push(obj);
                obj['(空白)'] = '';
                sheet6Data[index][item.processName].push(obj1);
              });
            } else {
              obj.detailCode = '';
              obj.detailName = '';
              obj.count = '';
              obj.type = '';
              obj.senchou = '';
              //yosetsuArr.push(obj);
              obj['(空白)'] = '';
              sheet6Data[index][item.processName].push(obj);
            }
            // sheet6Data[index][item.processName].push(obj);
          } else if (id === 20) {
            let shiageArr = [];
            if (
              matchedProcessInput?.details?.[0]?.weldingList !== undefined &&
              matchedProcessInput?.details?.[0]?.weldingList?.length > 0
            ) {
              matchedProcessInput?.details?.[0]?.weldingList?.forEach((weld) => {
                if (weld.senchouYousetsushiage == 1) {
                  let matchedDetailType = processMst?.details?.processDetailTypes?.find(
                    (detailType) => detailType.id === weld.yousetsuType
                  );
                  let obj1 = {};
                  obj1.processCode = processCode;
                  obj1.deviceCode = deviceCode;
                  obj1.deviceName = deviceName;
                  obj1.detailCode = matchedDetailType?.code;
                  obj1.detailName = matchedDetailType?.name;
                  obj1.count = '';
                  obj1.type = '';
                  obj1.senchou = weld?.senchou + '/' + weld?.senchouKaisu;
                  obj1['(空白)'] = '';
                  sheet6Data[index][item.processName].push(obj1);
                } else {
                  obj.detailCode = '';
                  obj.detailName = '';
                  obj.count = '';
                  obj.type = '';
                  obj.senchou = '';
                  obj['(空白)'] = '';
                  sheet6Data[index][item.processName].push(obj);
                }
              });
            } else {
              obj.detailCode = '';
              obj.detailName = '';
              obj.count = '';
              obj.type = '';
              obj.senchou = '';
              obj['(空白)'] = '';
              sheet6Data[index][item.processName].push(obj);
            }
            //shiageArr.push(obj);
          } else if (id === 21 || id === 22) {
            if (matchedProcessInput?.details !== undefined && matchedProcessInput?.details?.length > 0) {
              let numOfCuts = matchedProcessInput?.details?.[0]?.numOfCuts;
              obj.numOfCuts = numOfCuts;
            } else {
              obj.numOfCuts = '';
            }
            obj['(空白)'] = '';
            sheet6Data[index][item.processName].push(obj);
          } else if (id === 23 || id === 24 || id === 25 || id === 26 || id === 27) {
            if (matchedData?.multiprocesses !== undefined && matchedData?.multiprocesses?.length > 0) {
              let matchedDetailType = processMst?.details?.processDetailTypes?.find(
                (detailType) => detailType.id === matchedData?.multiprocesses?.[0]?.processDetailTypeId
              );
              obj.detailCode = matchedDetailType?.code;
              obj.detailName = matchedDetailType?.name;
              obj.count = matchedData?.multiprocesses?.length;
            } else {
              obj.detailCode = '';
              obj.detailName = '';
              obj.count = '';
            }
            obj['(空白)'] = '';
            sheet6Data[index][item.processName].push(obj);
          }
        } else {
          obj.processCode = '';
          obj.deviceCode = '';
          obj.deviceName = '';
          obj.detailCode = '';
          obj.detailName = '';
          if (id === 10) {
            obj.element = '';
            obj['(空白)'] = '';
            sheet6Data[index][item.processName].push(obj);
          } else if (id === 11 || id == 12 || id == 13) {
            obj.innerDia = '';
            obj.outerDia = '';
            obj.airFeedDistance = '';
            obj['(空白)'] = '';
            sheet6Data[index][item.processName].push(obj);
          } else if (id === 14) {
            let bending = [];

            obj.bendCount = '';
            obj.bendCountFR = '';
            obj['(空白)'] = '';
            //bending.push(obj);

            sheet6Data[index][item.processName].push(obj);
          } else if (id === 15) {
            obj.area = '';
            obj.weight = '';
            obj.outerLength = '';
            obj.innerLength = '';
            obj.count = '';
            obj['(空白)'] = '';
            sheet6Data[index][item.processName].push(obj);
          } else if (id === 16) {
            obj.area = '';
            obj.weight = '';
            obj.outerLength = '';
            (obj.outerCount = ''), (obj.innerLength = '');
            obj.innerCount = '';
            obj['(空白)'] = '';
            sheet6Data[index][item.processName].push(obj);
          } else if (id === 17) {
            let hyomenArr = [];

            obj.areaWeight = '';
            obj.count = '';
            obj['(空白)'] = '';
            hyomenArr.push(obj);

            sheet6Data[index][item.processName].push(obj);
          } else if (id === 18) {
            let nijiArr = [];
            obj.count = '';
            obj.x = '';
            obj.y = '';
            obj.perimeter = '';
            obj['(空白)'] = '';
            // nijiArr.push(obj);

            sheet6Data[index][item.processName].push(obj);
          } else if (id === 19) {
            let yosetsuArr = [];
            obj.count = '';
            obj.type = '';
            obj.senchou = '';
            obj['(空白)'] = '';
            //  yosetsuArr.push(obj);
            sheet6Data[index][item.processName].push(obj);
          } else if (id === 20) {
            let shiageArr = [];
            obj.detailCode = '';
            obj.detailName = '';
            obj.count = '';
            obj.type = '';
            obj.senchou = '';
            obj['(空白)'] = '';
            // shiageArr.push(obj);
            sheet6Data[index][item.processName].push(obj);
          } else if (id === 21 || id === 22) {
            obj.numOfCuts = '';
            obj['(空白)'] = '';

            sheet6Data[index][item.processName].push(obj);
          } else if (id === 23 || id === 24 || id === 25 || id === 26 || id === 27) {
            obj.count = '';
            obj['(空白)'] = '';

            sheet6Data[index][item.processName].push(obj);
          }
        }
      });
    });
    return sheet6Data;
  };

  function incrementExcelColumn(column) {
    let numericValue = 0;
    for (let i = 0; i < column?.length; i++) {
      numericValue = numericValue * 26 + (column.charCodeAt(i) - 64);
    }
    numericValue++;
    let incrementedColumn = '';
    while (numericValue > 0) {
      const remainder = (numericValue - 1) % 26;
      incrementedColumn = String.fromCharCode(65 + remainder) + incrementedColumn;
      numericValue = Math.floor((numericValue - 1) / 26);
    }
    return incrementedColumn;
  }

  function calculateRowCounts(data) {
    const rowCountsArray = [];
    let addition = {};
    data?.forEach((item) => {
      const rowCounts = {};
      let largestRowCount = 0;
      for (const key in item) {
        addition[key] = addition[key] == undefined ? 0 : addition[key];
        if (Array.isArray(item[key]) && item[key][0].processCode !== '') {
          rowCounts[key] = item[key]?.length;
          addition[key] += item[key]?.length;
          largestRowCount = Math.max(largestRowCount, item[key]?.length);
        } else {
          rowCounts[key] = 0;
        }
      }
      rowCounts.largestRowCount = largestRowCount;
      rowCountsArray.push(rowCounts);
    });
    rowCountsArray['addition'] = addition;
    return rowCountsArray;
  }

  const XLSX = require('xlsx');
  let childUsedProcess = getChildProcessCount();
  const individualExcelDataSheets = [
    (html) => {
      return html;
    },
    (html) => {
      let estimateProducts = selectedData.estimateProducts;

      //費用合計
      let costPrice = 0;
      let costPriceTotal = 0;
      let epCustomCosts = selectedData.epCustomCosts;
      for (let i = 0; i < epCustomCosts?.length; i++) {
        if (epCustomCosts[i].isUseUnit) {
          costPrice += Number(epCustomCosts[i].unitPrice);
        } else {
          costPrice += Number(epCustomCosts[i].totalPrice);
        }
        costPriceTotal += costPrice * estimateProducts.quantity;
      }

      let updateKouteiCalInfo = estimateProducts?.additions;
      let processPattern = estimateProducts?.processSelect;
      let setupCostTotalTime = 0;
      let workCostTotalTime = 0;
      if (updateKouteiCalInfo) {
        //工程パターンに利用しない工程を削除
        let updateKouteiInfos = [];
        updateKouteiCalInfo?.forEach((koutei) => {
          let updateKouteiInfo = {};
          updateKouteiInfo = processPattern?.details
            ? processPattern?.details?.filter(
                (item) =>
                  item.processId === koutei.processId &&
                  item.isUsedForCalc == true &&
                  koutei.dataItems[0]?.processDetailTypeNo == item.multiprocesses[0]?.processDetailTypeId
              )
            : [];
          if (updateKouteiInfo?.length > 0) {
            updateKouteiInfos.push(koutei);
          }
        });

        // 計算した工程データの足し算
        updateKouteiInfos?.forEach((updatedKouteiContent) => {
          setupCostTotalTime +=
            updatedKouteiContent.dataType == DataType.Data
              ? updatedKouteiContent.totalDandori.dataItemsTime
              : updatedKouteiContent.totalDandori.editItemsTime;

          workCostTotalTime +=
            updatedKouteiContent.dataType == DataType.Data
              ? updatedKouteiContent.totalSagyo.dataItemsTime
              : updatedKouteiContent.totalSagyo.editItemsTime;
          // }
        });
      }
      const fixedFileExcelDataSheet1 = [
        { key: 'コメント', value: estimateProducts.comment },
        { key: '重量', value: estimateProducts.totalWeight },
        { key: '表面積', value: estimateProducts.totalSurfaceArea },
        { key: '体積', value: estimateProducts.sizeX + estimateProducts.sizeY + estimateProducts.sizeZ },
        { key: '形鋼重量', value: '' },
        { key: '板金子部品種類', value: '' },
        { key: '形鋼子部品種類', value: '' },
        { key: '板金子部品製作数', value: '' },
        { key: '形鋼子部品製作数', value: '' },
        { key: '製造原価総額', value: 1 * estimateProducts.quantity },
        { key: '製造原価', value: estimateProducts.manufacturingCostTotalPrice },
        { key: '材料費', value: estimateProducts.materialCostUnitPrice },
        { key: '作業合計費用', value: estimateProducts.setupCostTotalPrice + estimateProducts.workCostTotalPrice },
        { key: '段取金額', value1: estimateProducts.setupCostTotalPrice, value2: secondsToHms(setupCostTotalTime) },
        { key: '加工金額', value1: estimateProducts.workCostTotalPrice, value2: secondsToHms(workCostTotalTime) },
        { key: '購入品費', value: estimateProducts.purchaseTotalPrice },
        { key: 'その他の費用', value: costPriceTotal },
        { key: '見積総額', value: estimateProducts.estimateTotalPrice },
        { key: '見積製造費', value: estimateProducts.estimateUnitPrice },
        { key: '材料費', value: estimateProducts.materialCostUnitPrice * 0.0 },
        { key: '作業費合計', value: '' },
        { key: '段取金額', value: '' },
        { key: '加工金額', value: '' },
        { key: '購入品費', value: '' },
        { key: 'その他費用', value: '' },
        {
          key: '受注ﾀｲﾌﾟ',
          value1: estimateProducts?.coeffOrdersName,
          value2: estimateProducts?.otherProduct?.coeffOrders,
        },
        {
          key: '納期ﾀｲﾌﾟ',
          value1: estimateProducts.coeffDeliveryDatesName,
          value2: estimateProducts?.otherProduct?.coeffDeliveryDates,
        },
        {
          key: '購入品ﾀｲﾌﾟ',
          value1: estimateProducts?.coeffPurchasesName,
          value2: estimateProducts?.otherProduct?.coeffPurchases,
        },
        {
          key: '管理費ﾀｲﾌﾟ',
          value1: estimateProducts?.coeffManagementsName,
          value2: estimateProducts?.otherProduct?.coeffManagements,
        },
        {
          key: '■親部品工程index',
          value: '',
        },
        { key: '材料ﾛｽ係数', value1: '', value2: '' },
        { key: '費用', value: selectedData?.epCustomCosts?.length },
        {
          key: 'ラベル',
          value: selectedData?.epCustomLabels?.length,
        },
        { key: 'メモ', value: selectedData?.epCustomMemos?.length },
        {
          key: '日付',
          value: selectedData?.epCustomDates?.length,
        },

        {
          key: '■ｶｽﾀﾑﾌｨｰﾙﾄﾞ[費用]',
          value: '',
        },
        {
          key: '■ｶｽﾀﾑﾌｨｰﾙﾄﾞ[ラベル]',
          value: '',
        },
        {
          key: '■ｶｽﾀﾑﾌｨｰﾙﾄﾞ[メモ]',
          value: '',
        },
        {
          key: '■ｶｽﾀﾑﾌｨｰﾙﾄﾞ[日付]',
          value: '',
        },
      ];

      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = html;
      const table = tempDiv.querySelector('table');
      let initDisplayColumns = getDisplayColumns();

      if (table) {
        const rows = table.querySelectorAll('tr');
        let tblData = createAdditionalItemsTbl();
        let largestRowCount = 0;
        let newRows = [];
        for (const key in tblData) {
          if (tblData.hasOwnProperty(key)) {
            const array = tblData[key];
            if (Array.isArray(array) && array?.length > largestRowCount) {
              largestRowCount = array?.length;
            }
          }
        }

        rows?.forEach((row) => {
          let cells = row.querySelectorAll('td');

          cells?.forEach((cell, index) => {
            const cellContent = cell.textContent.trim();
            const column = initDisplayColumns?.find((col) => col.title === cellContent);

            if (column !== undefined) {
              let nextCell = cell.nextSibling;
              const columnData = extractColumnData(selectedData, column);
              let extractedVal;

              if (typeof columnData === 'object') {
                if (columnData.props?.children?.props !== undefined) {
                  extractedVal = columnData.props?.children?.props?.children;
                } else {
                  extractedVal = columnData.props?.children?.[1];
                }
              } else if (cellContent == 'ID') {
                extractedVal = selectedRowKeys;
              } else {
                extractedVal = columnData;
              }

              if (nextCell.textContent == '') {
                nextCell.textContent = extractedVal;
              }
            }
            let matchedItem = fixedFileExcelDataSheet1?.find((item) => item.key === cellContent);

            if (matchedItem) {
              let nextCell = cell.nextSibling;
              let dataCell;
              if (
                cellContent == '■ｶｽﾀﾑﾌｨｰﾙﾄﾞ[費用]' ||
                cellContent == '■ｶｽﾀﾑﾌｨｰﾙﾄﾞ[ラベル]' ||
                cellContent == '■ｶｽﾀﾑﾌｨｰﾙﾄﾞ[メモ]' ||
                cellContent == '■ｶｽﾀﾑﾌｨｰﾙﾄﾞ[日付]'
              ) {
                dataCell = cell.id;
                let modifiedHtml = createHtmlTable('', tblData[cellContent]);
                const parts = dataCell.match(/(.*?)(\d+)/);
                const originalText = parts[1];
                const numberPart = parts[2];
                const targetNumber = (parseInt(numberPart) + 2).toString();
                const targetCell = originalText + targetNumber;
                const tbody = table.querySelector('tbody');
                if (rows?.length > 0) {
                  const lastRow = rows[rows?.length - 1];
                  if (newRows?.length <= 0) {
                    for (let i = 0; i < largestRowCount; i++) {
                      const newRow = lastRow.cloneNode(true);
                      let lastRowTds = newRow.querySelectorAll('td');
                      lastRowTds?.forEach((td) => {
                        let idParts = td.id.match(/(.*?)(\d+)/);
                        let idOriginalText = idParts[1];
                        let idNumberPart = idParts[2];
                        let idTargetNumber = (parseInt(idNumberPart) + i + 1).toString();
                        let idTargetCell = idOriginalText + idTargetNumber;
                        td.id = idTargetCell;
                        td.innerHTML = '';
                        td.innerText = '';
                        td.setAttribute('data-v', '');
                      });
                      newRows.push(newRow);
                      tbody.appendChild(newRow);
                    }
                  }

                  const tempDiv = document.createElement('div');
                  tempDiv.innerHTML = modifiedHtml;

                  // Query for all <tr> elements within the HTML element
                  const tableRows = tempDiv.querySelectorAll('tr');

                  for (let j = 0; j < tableRows?.length; j++) {
                    if (newRows?.length > 0) {
                      let childrenWithIds = newRows[j].querySelectorAll('[id]');
                      const tableCols = tableRows[j].querySelectorAll('td');
                      for (let i = 0; i < tableCols?.length; i++) {
                        let childParts = childrenWithIds[0].id.match(/(.*?)(\d+)/);
                        let childNumberPart = childParts[2];

                        const lastTargetChar = originalText.slice(-1);
                        const newLastTargetChar = String.fromCharCode(lastTargetChar.charCodeAt(0) + i);
                        let targetOrgPart = originalText.slice(0, -1) + newLastTargetChar;

                        let attributeValue = targetOrgPart + childNumberPart;
                        childrenWithIds?.forEach((child) => {
                          if (child.id == attributeValue) {
                            child.setAttribute('data-v', tableCols[i].textContent);
                          }
                        });
                      }
                    }
                  }
                }
              } else if (cellContent == '■親部品工程index') {
                let dataCellMst = cell.id;
                let processMasterOya = (estimateProducts?.calcParameters?.parameters?.process || [])?.filter(
                  (e) => e.class === 0
                );
                let processMstTblData = [];
                processMasterOya?.forEach((e, index) => {
                  let obj = {
                    no: index + 1,
                    class: '親部品',
                    additional: e?.isAdditional ? '追加' : '標準',
                    code: e?.code,
                    name: e?.name,
                    typeCount: e?.details?.processDetailTypes?.length,
                  };
                  processMstTblData.push(obj);
                });
                let processMstTbl = createHtmlTable('', processMstTblData);
                const processMstDiv = document.createElement('div');
                processMstDiv.innerHTML = processMstTbl;
                const parts = dataCellMst.match(/(.*?)(\d+)/);
                const originalText = parts[1];
                const numberPart = parts[2];

                const tableRows = processMstDiv.querySelectorAll('tr');
                for (let j = 0; j < tableRows?.length; j++) {
                  let targetNumber = (parseInt(numberPart) + j + 2).toString();
                  let targetId = originalText + targetNumber;
                  let targetRowTDs = rows[j + 3].querySelectorAll('td');
                  let target = Array.from(targetRowTDs)?.filter((cell) => cell.getAttribute('id') === targetId);
                  let targetCell = target[0];
                  const tableCols = tableRows[j].querySelectorAll('td');
                  for (let i = 0; i < tableCols?.length; i++) {
                    targetCell.setAttribute('data-v', tableCols[i].textContent);
                    targetCell.setAttribute('data-t', 's');
                    targetCell = targetCell.nextSibling;
                  }
                }
              }

              if (matchedItem.value) {
                nextCell.textContent = matchedItem.value;
              } else if (matchedItem.value1 && matchedItem.value2) {
                let coeffVal = nextCell.nextSibling;
                nextCell.textContent = matchedItem.value1;
                if (coeffVal.textContent === '時間') {
                  let timeVal = coeffVal.nextSibling;
                  timeVal.textContent = matchedItem.value2;
                } else {
                  coeffVal.textContent = matchedItem.value2;
                }
              }
            }
          });
        });
      }
      return tempDiv.innerHTML;
    },
    (html) => {
      let processData = createProcessDataTbl();
      let largestRowCount = 0;
      let modifiedHtml = {};
      for (const key in processData) {
        if (processData.hasOwnProperty(key)) {
          if (parseInt(key) !== WorkType.PaCustom) {
            const dataArray = processData[key];
            modifiedHtml[key] = createHtmlTable('', dataArray);
            const array = processData[key];
            if (Array.isArray(array) && array?.length > largestRowCount) {
              largestRowCount = array?.length;
            }
          }
        }
      }
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = html;
      let table = tempDiv.querySelector('table');
      const orgRows = tempDiv.querySelectorAll('tr');
      const firstTwoRows = [orgRows[0], orgRows[1]];
      if (table) {
        table.parentNode?.removeChild(table);
      }

      const newTable = document.createElement('table');
      const tbody1 = document.createElement('tbody');

      firstTwoRows?.forEach((row) => tbody1.appendChild(row.cloneNode(true)));

      newTable.appendChild(tbody1);

      tempDiv.appendChild(newTable);

      const rows = tempDiv.querySelectorAll('tr');

      let newRows = [];
      if (rows?.length > 0) {
        const lastRow = rows[rows?.length - 1];
        if (newRows?.length <= 0) {
          for (let i = 0; i < largestRowCount; i++) {
            const newRow = lastRow.cloneNode(true);
            let lastRowTds = newRow.querySelectorAll('td');
            lastRowTds?.forEach((td) => {
              let idParts = td.id.match(/(.*?)(\d+)/);
              let idOriginalText = idParts[1];
              let idNumberPart = idParts[2];
              let idTargetNumber = (parseInt(idNumberPart) + i + 1).toString();
              let idTargetCell = idOriginalText + idTargetNumber;
              td.id = idTargetCell;
              td.innerHTML = '';
              td.innerText = '';
              td.setAttribute('data-v', '');
            });
            newRows.push(newRow);
            tbody1.appendChild(newRow);
          }
        }
        let gotoNextTbl = 0;
        for (const key in modifiedHtml) {
          if (modifiedHtml.hasOwnProperty(key)) {
            if (parseInt(key) !== WorkType.PaCustom) {
              let tempDivModi = document.createElement('div');
              tempDivModi.innerHTML = modifiedHtml[key];
              const tableRows = tempDivModi.querySelectorAll('tr');
              const tableColsHeader = tableRows[0].querySelectorAll('td');
              let headerRow = rows?.[1].querySelectorAll('[id]');

              for (let j = 0; j < tableRows?.length; j++) {
                let tableCols = tableRows[j].querySelectorAll('td');
                if (newRows?.length > 0) {
                  let childrenWithIds = newRows[j].querySelectorAll('[id]');
                  for (let i = 0; i < tableColsHeader?.length; i++) {
                    let childParts = childrenWithIds[0].id.match(/(.*?)(\d+)/);
                    let childNumberPart = parseInt(childParts[2]);
                    let partsSheet2 = headerRow[gotoNextTbl + i].id.match(/(.*?)(\d+)/);
                    let orgTxt = partsSheet2[1];
                    let attributeValue = orgTxt + childNumberPart;
                    childrenWithIds?.forEach((child) => {
                      if (child.id == attributeValue) {
                        child.setAttribute('data-v', tableCols[i].textContent);
                      }
                    });
                  }
                }
              }
              gotoNextTbl += tableColsHeader?.length + 2;
            }
          }
        }
      }
      return tempDiv.innerHTML;
    },
    //sheet3
    (html) => {
      let processData = createProcessDataTbl2();
      let largestRowCount = 0;
      let modifiedHtml = {};
      const columnHeaders = [
        'No.',
        '分類',
        '種別',
        '工程ｺｰﾄﾞ',
        '工程名',
        '工程ﾀｲﾌﾟ',
        'No.',
        '種類ｺｰﾄﾞ',
        '種類',
        '親部品数量',
        '個数(子部品点数)',
        '回数',
        '面積',
        '重量',
        '見積合計金額',
        '段取金額',
        '加工金額',
        '原価合計金額',
        '段取金額',
        '加工金額',
        '合計時間',
        '段取時間',
        '加工時間',
        '(空白)',
      ];

      let additionCount = 0;
      for (const key in processData) {
        if (processData.hasOwnProperty(key)) {
          if (key?.includes('Additional700')) {
            const dataArray = processData[key];
            modifiedHtml[key] = createHtmlTable(columnHeaders, dataArray);
            const array = processData[key];
            if (Array.isArray(array) && array?.length > largestRowCount) {
              largestRowCount = array?.length + 1;
            }
            additionCount++;
          }
        }
      }
      let mainTblColsCount = columnHeaders?.length * additionCount;
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = html;
      let table = tempDiv.querySelector('table');
      const orgRows = tempDiv.querySelectorAll('tr');
      const firstTwoRows = [orgRows[0]];
      if (table) {
        table.parentNode?.removeChild(table);
      }
      const newTable = document.createElement('table');
      const tbody1 = document.createElement('tbody');

      firstTwoRows?.forEach((row) => tbody1.appendChild(row.cloneNode(true)));

      newTable.appendChild(tbody1);

      tempDiv.appendChild(newTable);

      const rows = tempDiv.querySelectorAll('tr');

      let newRows = [];
      if (rows?.length > 0) {
        if (newRows?.length <= 0) {
          for (let i = 0; i < largestRowCount; i++) {
            const startCharCode = 'A'.charCodeAt(0);
            const newRow = document.createElement('tr');
            for (let j = 0; j < mainTblColsCount + 2; j++) {
              const newCell = document.createElement('td');
              const charCode = startCharCode + j;
              const char = String.fromCharCode(charCode);
              let count = i + 1;
              newCell.id = `sjs-${char}` + count;
              newCell.innerHTML = '';
              newCell.innerText = '';
              newCell.setAttribute('data-v', '');
              newRow.appendChild(newCell);
            }
            newRows.push(newRow);
            tbody1.appendChild(newRow);
          }
        }

        let gotoNextTbl = 0;
        for (const key in modifiedHtml) {
          if (modifiedHtml.hasOwnProperty(key)) {
            if (key?.includes('Additional700')) {
              let tempDivModi = document.createElement('div');
              tempDivModi.innerHTML = modifiedHtml[key];
              const tableRows = tempDivModi.querySelectorAll('tr');
              let tableCols1 = tableRows[0].querySelectorAll('th');
              let headerRow = newRows?.[0].querySelectorAll('[id]');

              for (let j = 0; j < tableRows?.length; j++) {
                let tableCols =
                  tableRows[j].querySelectorAll('th')?.length == 0
                    ? tableRows[j].querySelectorAll('td')
                    : tableRows[j].querySelectorAll('th');
                if (newRows?.length > 0) {
                  let childrenWithIds = newRows[j].querySelectorAll('[id]');
                  for (let i = 0; i < tableCols?.length; i++) {
                    let childParts = childrenWithIds[0].id.match(/(.*?)(\d+)/);
                    let childNumberPart = parseInt(childParts[2]);
                    let partsSheet2 = headerRow[gotoNextTbl + i].id.match(/(.*?)(\d+)/);
                    let orgTxt = partsSheet2[1];
                    let attributeValue = orgTxt + childNumberPart;
                    childrenWithIds?.forEach((child) => {
                      if (child.id == attributeValue) {
                        child.setAttribute('data-v', tableCols[i].textContent);
                      }
                    });
                  }
                }
              }
              gotoNextTbl += tableCols1?.length + 2;
            }
          }
        }
      }
      return tempDiv.innerHTML;
    },
    //sheet4
    (html) => {
      let sheet4BodyContent = childUsedProcess?.map(({ id, ...rest }) => rest);
      let dataSheet4Tbl = createHtmlTable('', sheet4BodyContent);

      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = html;

      let table = tempDiv.querySelector('table');
      const orgRows = tempDiv.querySelectorAll('tr');
      const firstTwoRows = [orgRows[0], orgRows[1]];

      if (table) {
        table.parentNode?.removeChild(table);
      }
      const modifiedHtml =
        '<table>' + firstTwoRows[0].outerHTML + firstTwoRows[1].outerHTML + dataSheet4Tbl + '<table>';
      return modifiedHtml;
    },
    //sheet5
    (html) => {
      let defaultProcessParam = ['段取時間', '加工時間', '段取金額', '加工金額', '(空白)'];
      let bariProcessParam = ['面積', '重量', '段取時間', '加工時間', '段取金額', '加工金額', '(空白)'];
      let processMasterData = processInfo;

      let iq3Proccesses = [];
      processMasterData
        ?.filter((item) => item.class === 1)
        ?.map((item) => iq3Proccesses.push({ id: item.id, workType: item.workType, name: item.name }));

      let processParams = [];

      iq3Proccesses?.forEach((id) => {
        let matchType = kouteiMasterTypes?.find((type) => type.workType === id.workType);
        let relatedType = matchType ? matchType.typeName : null;

        if (id.id === 15 || id.id === 16) {
          processParams.push({
            id: id,
            param: bariProcessParam,
            name: id.name,
          });
        } else {
          processParams.push({
            id: id,
            param: defaultProcessParam,
            name: id.name,
          });
        }
      });

      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = html;

      let table = tempDiv.querySelector('table');
      const orgRows = tempDiv.querySelectorAll('tr');
      const firstTwoRows = [orgRows[0], orgRows[1]];
      const headerTd = orgRows[1].querySelectorAll('td');
      let lastHeaderTd;
      if (headerTd !== undefined && headerTd?.length > 0) {
        lastHeaderTd = headerTd[headerTd?.length - 1];
      }
      let lastParts;
      let lastLetters;
      let lastNumber;
      if (lastHeaderTd.id !== undefined) {
        lastParts = lastHeaderTd.id.split('-');
        // Extract BE and the number separately
        const secondPart = lastParts[1];
        const match = secondPart.match(/(\D+)(\d+)/);
        lastLetters = match[1]; // This will be "BE"
        lastNumber = match[2]; // This will be "2"
      }
      const uniqueNames = new Set();
      const usedProcessList = processParams?.filter((process1) => {
        return childUsedProcess.some((process2) => {
          if (process2.processName === process1.name && !uniqueNames.has(process1.name)) {
            uniqueNames.add(process1.name);
            return true;
          }
          return false;
        });
      });

      if (orgRows?.length > 0) {
        usedProcessList?.forEach((usedProc) => {
          for (let i = 0; i < usedProc.param?.length; i++) {
            lastLetters = incrementExcelColumn(lastLetters);
            let param = usedProc.param;
            const newCellProcName = document.createElement('td');
            const newCell = document.createElement('td');

            newCellProcName.id = `sjs-${lastLetters}` + 1;
            if (i == 0) {
              newCellProcName.innerHTML = '[' + usedProc.name + ']';
              newCellProcName.innerText = '[' + usedProc.name + ']';
              newCellProcName.setAttribute('data-v', '[' + usedProc.name + ']');
            }

            newCell.id = `sjs-${lastLetters}` + lastNumber;
            newCell.innerHTML = param[i];
            newCell.innerText = param[i];
            newCell.setAttribute('data-v', param[i]);

            orgRows[0].appendChild(newCellProcName);
            orgRows[1].appendChild(newCell);
          }
        });
      }

      const sheet5Data = getSheet5Data(usedProcessList);
      let dataSheet5Tbl = createHtmlTable('', sheet5Data);

      const modifiedHtml = '<table>' + orgRows[0].outerHTML + orgRows[1].outerHTML + dataSheet5Tbl + '<table>';
      return modifiedHtml;
    },
    //sheet6
    (html) => {
      let tblHeaderList = [
        {
          id: 10,
          name: 'プログラム',
          param: ['工程ｺｰﾄﾞ', '装置ｺｰﾄﾞ', '装置名', '要素数', '(空白)'],
        },
        {
          id: 11,
          name: 'レーザー',
          param: ['工程ｺｰﾄﾞ', '装置ｺｰﾄﾞ', '装置名', '種類ｺｰﾄﾞ', '種類名', '外形周長', '内径周長', '空走距離', '(空白)'],
        },
        {
          id: 12,
          name: 'パンチ',
          param: ['工程ｺｰﾄﾞ', '装置ｺｰﾄﾞ', '装置名', '種類ｺｰﾄﾞ', '種類名', '外形周長', '内径周長', '空走距離', '(空白)'],
        },
        {
          id: 13,
          name: '複合機',
          param: ['工程ｺｰﾄﾞ', '装置ｺｰﾄﾞ', '装置名', '種類ｺｰﾄﾞ', '種類名', '外形周長', '内径周長', '空走距離', '(空白)'],
        },
        {
          id: 14,
          name: 'ベンディング',
          param: ['工程ｺｰﾄﾞ', '装置ｺｰﾄﾞ', '装置名', '種類ｺｰﾄﾞ', '種類名', '曲線数', 'FR曲回数', '(空白)'],
        },
        {
          id: 15,
          name: '自動バリ取',
          param: [
            '工程ｺｰﾄﾞ',
            '装置ｺｰﾄﾞ',
            '装置名',
            '種類ｺｰﾄﾞ',
            '種類名',
            '面積',
            '重量',
            '外形周長',
            '内径周長',
            '回数',
            '(空白)',
          ],
        },
        {
          id: 16,
          name: '手動バリ取',
          param: [
            '工程ｺｰﾄﾞ',
            '装置ｺｰﾄﾞ',
            '装置名',
            '種類ｺｰﾄﾞ',
            '種類名',
            '面積',
            '重量',
            '外形周長',
            '外形回数',
            '内径周長',
            '内径回数',
            '(空白)',
          ],
        },
        {
          id: 17,
          name: '表面処理',
          param: ['工程ｺｰﾄﾞ', '装置ｺｰﾄﾞ', '装置名', '種類ｺｰﾄﾞ', '種類名', '面積/重量', '回数', '(空白)'],
        },
        {
          id: 18,
          name: '2次加工',
          param: ['工程ｺｰﾄﾞ', '装置ｺｰﾄﾞ', '装置名', '種類ｺｰﾄﾞ', '種類名', 'X', 'Y', '周長', '個数', '(空白)'],
        },
        {
          id: 19,
          name: '溶接',
          param: ['工程ｺｰﾄﾞ', '装置ｺｰﾄﾞ', '装置名', '種類ｺｰﾄﾞ', '種類名', '種類/径', '線長/個数', '回数', '(空白)'],
        },
        {
          id: 20,
          name: '溶接仕上',
          param: ['工程ｺｰﾄﾞ', '装置ｺｰﾄﾞ', '装置名', '種類ｺｰﾄﾞ', '種類名', '種類/径', '線長/個数', '回数', '(空白)'],
        },
        {
          id: 21,
          name: 'シャーリング',
          param: ['工程ｺｰﾄﾞ', '装置ｺｰﾄﾞ', '装置名', '種類ｺｰﾄﾞ', '種類名', 'ｶｯﾄ数', '(空白)'],
        },
        {
          id: 22,
          name: '検査',
          param: ['工程ｺｰﾄﾞ', '装置ｺｰﾄﾞ', '装置名', '種類ｺｰﾄﾞ', '種類名', '検査数', '(空白)'],
        },
        {
          id: 23,
          name: '追加工程①',
          param: ['工程ｺｰﾄﾞ', '装置ｺｰﾄﾞ', '装置名', '種類ｺｰﾄﾞ', '種類名', '数', '(空白)'],
        },
        {
          id: 24,
          name: '追加工程②',
          param: ['工程ｺｰﾄﾞ', '装置ｺｰﾄﾞ', '装置名', '種類ｺｰﾄﾞ', '種類名', '数', '(空白)'],
        },
        {
          id: 25,
          name: '追加工程③',
          param: ['工程ｺｰﾄﾞ', '装置ｺｰﾄﾞ', '装置名', '種類ｺｰﾄﾞ', '種類名', '数', '(空白)'],
        },
        {
          id: 26,
          name: '追加工程④',
          param: ['工程ｺｰﾄﾞ', '装置ｺｰﾄﾞ', '装置名', '種類ｺｰﾄﾞ', '種類名', '数', '(空白)'],
        },
        {
          id: 27,
          name: '追加工程⑤',
          param: ['工程ｺｰﾄﾞ', '装置ｺｰﾄﾞ', '装置名', '種類ｺｰﾄﾞ', '種類名', '数', '(空白)'],
        },
      ];
      const sheet6Data = getSheet6Data(childUsedProcess);
      let modifiedHtml = [];
      sheet6Data?.forEach((data, index) => {
        modifiedHtml[index] = [];
        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            const dataArray = data[key];
            modifiedHtml[index][key] = createHtmlTable('', dataArray);
          }
        }
      });

      let rowCountArr = calculateRowCounts(sheet6Data);

      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = html;

      let table = tempDiv.querySelector('table');
      let tbody = table.querySelector('tbody');
      const orgRows = tempDiv.querySelectorAll('tr');
      const firstTwoRows = [orgRows[0], orgRows[1]];
      const headerTd = orgRows[1].querySelectorAll('td');
      let lastHeaderTd;
      if (headerTd !== undefined && headerTd?.length > 0) {
        lastHeaderTd = headerTd[headerTd?.length - 1];
      }
      let lastParts;
      let lastLetters;
      let lastNumber;
      if (lastHeaderTd.id !== undefined) {
        lastParts = lastHeaderTd.id.split('-');
        // Extract BE and the number separately
        const secondPart = lastParts[1];
        const match = secondPart.match(/(\D+)(\d+)/);
        lastLetters = match[1]; // This will be "BE"
        lastNumber = match[2]; // This will be "2"
      }
      const uniqueNames = new Set();
      const usedProcessList = tblHeaderList?.filter((process1) => {
        return childUsedProcess.some((process2) => {
          if (process2.processName === process1.name && !uniqueNames.has(process1.name)) {
            uniqueNames.add(process1.name);
            return true;
          }
          return false;
        });
      });

      if (orgRows?.length > 0) {
        usedProcessList?.forEach((usedProc) => {
          for (let i = 0; i < usedProc.param?.length; i++) {
            lastLetters = incrementExcelColumn(lastLetters);
            let param = usedProc.param;
            const newCellProcName = document.createElement('td');
            const newCell = document.createElement('td');

            newCellProcName.id = `sjs-${lastLetters}` + 1;
            if (i == 0) {
              newCellProcName.innerHTML = usedProc.name + '   ' + rowCountArr.addition[usedProc.name];
              newCellProcName.innerText = usedProc.name + '   ' + rowCountArr.addition[usedProc.name];
              newCellProcName.setAttribute('data-v', usedProc.name + '   ' + rowCountArr.addition[usedProc.name]);
            }

            newCell.id = `sjs-${lastLetters}` + lastNumber;
            newCell.innerHTML = param[i];
            newCell.innerText = param[i];
            newCell.setAttribute('data-v', param[i]);

            orgRows[0].appendChild(newCellProcName);
            orgRows[1].appendChild(newCell);
          }
        });
      }

      const colCounts = orgRows[1].querySelectorAll('td')?.length;

      let rowCounts = 2;
      rowCountArr?.forEach((cnt) => {
        rowCounts += cnt.largestRowCount + 1;
      });
      let mainTblColsCount = colCounts * rowCounts;

      if (rowCounts > 2) {
        const originalRow = orgRows[1];

        for (let i = 2; i <= rowCounts; i++) {
          const clonedRow = originalRow.cloneNode(true);
          let cloneTds = clonedRow.querySelectorAll('td');
          cloneTds?.forEach((td) => {
            let id = td.id;
            let updatedId = id.replace(/\d+/, i + 1);
            td.id = updatedId;
            td.innerHTML = '';
            td.innerText = '';
            td.setAttribute('data-v', '');
          });
          tbody.appendChild(clonedRow);
        }

        let newRows = tbody.querySelectorAll('tr');
        let gotoNextPart = 2;
        modifiedHtml?.forEach((modi, index) => {
          let gotoNextTbl = 0;
          for (const key in modi) {
            if (modi.hasOwnProperty(key)) {
              let tempDivModi = document.createElement('div');
              tempDivModi.innerHTML = modi[key];
              const tableRows = tempDivModi.querySelectorAll('tr');
              let tableCols1 = tableRows[0].querySelectorAll('td');
              let headerRow = newRows?.[0].querySelectorAll('[id]');

              for (let j = 0; j < tableRows?.length; j++) {
                let tableCols =
                  tableRows[j].querySelectorAll('th')?.length == 0
                    ? tableRows[j].querySelectorAll('td')
                    : tableRows[j].querySelectorAll('th');
                /*  if (newRows?.length > 0) { */
                let childrenWithIds = newRows[j + gotoNextPart].querySelectorAll('[id]');
                for (let i = 0; i < tableCols?.length; i++) {
                  let childParts = childrenWithIds[0].id.match(/(.*?)(\d+)/);
                  let childNumberPart = parseInt(childParts[2]);
                  let partsSheet2 = headerRow[gotoNextTbl + i].id.match(/(.*?)(\d+)/);
                  let orgTxt = partsSheet2[1];
                  let attributeValue = orgTxt + childNumberPart;
                  childrenWithIds?.forEach((child) => {
                    if (child.id == attributeValue) {
                      child.setAttribute('data-v', tableCols[i].textContent);
                      child.setAttribute('data-t', 's');
                      child.innerHTML = tableCols[i].textContent;
                      child.innerText = tableCols[i].textContent;
                    }
                  });
                }
              }
              gotoNextTbl += tableCols1?.length;
            }
          }
          gotoNextPart += rowCountArr[index].largestRowCount;
        });
      }
      return tempDiv.innerHTML;
    },
    //sheet7
    (html) => {
      let purchaseLst = selectedData.estimateProducts.purchases;

      let iq3PurchaseLst = selectedIq3Data?.map((item) => item?.purchases);
      const flattenedArray = [];

      for (const innerArray of iq3PurchaseLst) {
        flattenedArray.push(...innerArray);
      }

      flattenedArray?.forEach((e) => {
        purchaseLst.push(e);
      });

      const purchaseColumns = [
        {
          id: '1',
          title: '種別',
          dataIndex: 'purchaseCategoryId',
          width: 180 + 'px',
          render: (_, record) =>
            selectedData?.estimateProducts?.purchases?.length >= 1 ? (
              <label style={{ fontSize: '13px' }}>{getNameById(record.purchaseCategoryId)}</label>
            ) : null,
        },
        {
          id: '2',
          title: '品名',
          dataIndex: 'name',
          width: 200 + 'px',
        },
        {
          id: '3',
          title: '型番',
          dataIndex: 'model',
          width: 120 + 'px',
        },
        {
          id: '4',
          title: '単価',
          dataIndex: 'unitPrice',
          width: 100 + 'px',
          className: 'cm-a-right',

          render: (text, record) => (
            <label
              style={{
                color: record.unitPriceByQuantity > 0 ? '#A5A5A5' : '#4A4747',
                display: 'block',
              }}
            >
              {text}
            </label>
          ),
        },

        {
          id: '5',
          title: '数量別単価',
          dataIndex: 'unitPriceByQuantity',
          width: 100 + 'px',
          className: 'cm-a-right',
          // className: (record) => (record.unitPriceByQuantity > 0 ? 'cm-a-right' : 'test cm-a-right'),

          render: (text, record) => (
            <label
              style={{
                color: record.unitPriceByQuantity > 0 ? '#4A4747' : '#A5A5A5',
                display: 'block',
              }}
            >
              {text}
            </label>
          ),
        },
        {
          id: '6',
          title: '必要数',
          dataIndex: 'required',
          width: 80 + 'px',
          className: 'cm-a-right',
        },
        {
          id: '7',
          title: '金額',
          dataIndex: 'price',
          width: 100 + 'px',
          className: 'cm-a-right',
        },

        {
          id: '8',
          title: '合計必要数',
          dataIndex: 'totalRequired',
          width: 120 + 'px',
        },
        {
          id: '9',
          title: '合計金額',
          dataIndex: 'totalPrice',
          width: 100 + 'px',
          className: 'cm-a-right',
        },
        {
          id: '10',
          title: '単価保持',
          dataIndex: 'isKeepPrice',
          width: 120 + 'px',
          render: (_, record) =>
            purchaseLst?.length >= 1 ? (
              <Checkbox style={{ marginLeft: '5px' }} disabled={true} checked={record.isKeepPrice} />
            ) : null,
        },
        {
          id: '11',
          title: '取付時間',
          dataIndex: 'processTime',
          width: 120 + 'px',
          render: (record) => (
            <div style={{ textAlign: 'right' }}>
              <label style={{ fontSize: '13px' }}>{secondsToHms(record)}</label>
            </div>
          ),
        },
        {
          id: '12',
          title: '合計取付時間',
          dataIndex: 'totalProcessTime',
          width: 120 + 'px',
          render: (record) => (
            <div style={{ textAlign: 'right' }}>
              <label style={{ fontSize: '13px' }}>{secondsToHms(record)}</label>
            </div>
          ),
        },
        {
          id: '13',
          title: '仕入先コード',
          dataIndex: 'supplierCode',
          width: 150 + 'px',
        },
        {
          id: '14',
          title: '仕入先名',
          dataIndex: 'supplierName',
          width: 150 + 'px',
        },
        {
          id: '15',
          title: 'メーカー',
          dataIndex: 'makerName',
          width: 150 + 'px',
        },

        {
          id: '16',
          title: '備考',
          dataIndex: 'remarks',
          width: 200 + 'px',
        },
      ];
      const tbHeaders = extractHeaderFromHtmlTable(html);
      const tblContents = extractDataByExcelColumns(purchaseLst, tbHeaders, purchaseColumns);
      tblContents?.forEach((row, index) => {
        row['No.'] = index + 1;
      });
      const modifiedHtml = createHtmlTable(tbHeaders, tblContents);

      html = modifiedHtml;
      return html;
    },
  ];

  async function convertServerExcelToHtml() {
    try {
      let excelData;
      if (isListReport) {
        excelData = selectedExeclListFormat;
      } else if (isIndividualReport) {
        excelData = selectedExeclIndividualFormat;
      }

      const s3Stream = await getS3FileContent(excelData?.id);
      const workbook = XLSX.read(s3Stream, { type: 'array' });
      let excelName;
      const sheetPromises = workbook.SheetNames?.map(async (sheetName, index) => {
        const sheet = workbook.Sheets[sheetName];
        let html = XLSX.utils.sheet_to_html(sheet);
        // Add additional content for List Report
        if (isListReport) {
          excelName = selectedExeclListFormat.title ? selectedExeclListFormat.title + '.xlsx' : 'list.xlsx';
          //  const tbHeaders = extractHeaderFromHtmlTable(html);
          const tbHeaders = [
            'ID',
            'お客様コード',
            'お客様',
            '図番',
            '名称',
            '数量',
            '粗利益',
            '粗利率',
            '見積単価',
            '見積合計',
            '材料原価',
            '製造原価',
            '段取原価',
            '加工原価',
            '購入品費',
            '管理費',
            '見積番号',
            '価格調整',
            '作成者',
            '最終更新者',
            '作成日',
            '更新日',
            '総表面積(c㎡)',
            '受注形態',
            '納期形態',
            '画像',
            '受注非受注',
            'サイズX',
            'サイズY',
            'サイズZ',
            '子部品総重量',
            '板金重量',
            '子部品種類',
            '板金種類',
            '子部品製作数',
            '板金製作数',
            '表面処理',
            '組立',
            '検査',
            '梱包',
          ];
          tbHeaders.push(...selectedData.epCustomCosts?.map((item) => item?.additionalItemName));
          tbHeaders.push(...selectedData.epCustomLabels?.map((item) => item?.additionalItemName));
          tbHeaders.push(...selectedData.epCustomDates?.map((item) => item?.additionalItemName));
          tbHeaders.push(...selectedData.epCustomMemos?.map((item) => item?.additionalItemName));

          const tblContents = extractDataByExcelColumns(initialTblItems, tbHeaders);
          const modifiedHtml = createHtmlTable(tbHeaders, tblContents);
          html = modifiedHtml;
        }

        // Add additional content for Individual Report
        if (isIndividualReport) {
          excelName = selectedExeclIndividualFormat.title + '.xlsx';
          html = individualExcelDataSheets[index](html);
        }
        return { sheetName, html };
      });
      const htmlContents = await Promise.all(sheetPromises);
      if (htmlContents?.length > 0) {
        handleExportToExcel(htmlContents, excelName);
      }
    } catch (error) {
      console.error('Error converting Excel to HTML:', error);
    }
  }

  //Excel File出力

  const handleExportToExcel = (contents, title) => {
    const wb = XLSX.utils.book_new();

    contents?.forEach(({ sheetName, html }) => {
      const tempContainer = document.createElement('div');
      tempContainer.innerHTML = html;

      const table = tempContainer.querySelector('table');

      if (table) {
        const ws = XLSX.utils.table_to_sheet(tempContainer);
        XLSX.utils.book_append_sheet(wb, ws, sheetName);
      }
    });

    XLSX.writeFile(wb, title);
  };

  const reportOutputOk = async () => {
    convertServerExcelToHtml();
    setIsReportOutputOpen(false);
    !isListReport || setIsListReport(false);
    !isIndividualReport || setIsIndividualReport(false);
  };

  const reportOutputCancel = () => {
    setIsReportOutputOpen(false);
    !isListReport || setIsListReport(false);
    !isIndividualReport || setIsIndividualReport(false);
  };
  // 事前登録
  const preRegistertation = () => {
    setClickedAction(3);

    if (editMode) {
      setEditModeCancelConfirm(true);
    } else {
      setEditMode(false);
      if (props.updateEstimateEditMode !== undefined) props.updateEstimateEditMode(false);
      setPreRegister(true);
      setChildTitle('事前登録');
    }
  };

  const preRegisterModalClose = () => {
    setPreRegister(false);
  };

  const updateOk = () => {
    setIsUpdate(false);
    setIsChildOpen(false);
    setIsManufactureOpen(false);
    setIsReportOutputOpen(false);
    setIsListOutputOpen(false);
  };

  const updateCancel = () => {
    setIsUpdate(false);
    setIsChildOpen(false);
    setIsManufactureOpen(false);
    setIsReportOutputOpen(false);
    setIsListOutputOpen(false);
  };
  // 削除
  const deleteRow = () => {
    if (csvExportData?.length < 1) {
      alert('削除するデータを選択してください');
    } else {
      let deleteEst =
        props?.userSettingInfo?.[0]?.massages?.deleteEst == undefined
          ? true
          : props?.userSettingInfo?.[0]?.massages?.deleteEst;
      setIsDelete(deleteEst);
      if (!deleteEst) {
        deleteOk();
      }
      setChildTitle('削除');
    }
  };

  // 削除確認メッセージのOKボタン押下
  const deleteOk = async () => {
    setIsDelete(false);
    let deletedData = await deleteEstimateProductData(selectedData?.estimateProducts?.id);
    let data = [...initialTblItems];
    data = data?.filter((item) => item.estimateProducts.id != deletedData?.estimateProducts?.id);
    // for (let i = 0; i < data?.length; i++) {
    //   data[i].id = i + 1;
    // }
    setInitialTableItems(data);

    setSelectedRowKeys(data?.length > 0 ? data[0].estimateProducts.id : 0);
    setSelectedData(data?.length > 0 ? data[0] : []);
    displayTotalCount = data?.length; // リストの件数
  };

  // 親部品見積情報をDBに削除する
  async function deleteEstimateProductData(id) {
    let ret = [];
    try {
      const Url = process.env.REACT_APP_BACKEND_API_URL + 'EstimateProduct/' + id;
      const otherParam = {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + getAccessToken(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          return await response.json();
        })
        .then((data) => {
          if (data && data?.updateToken) {
            // セクションにトークン情報を更新する
            updateAccessToken(data?.updateToken);
          }
          if (data && data.statusCode === StatusCodes.OK) {
            ret = data.data[0];
          } else if (
            data &&
            (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)
          ) {
            authorizeError(ErrorMessage()[data?.messageCode]);
            return;
          } else if (data && data.statusCode !== StatusCodes.OK) {
            error(ErrorMessage()[data?.messageCode]);
            return;
          }
        })
        .catch((e) => {
          authorizeError(ErrorMessage().E010);
          return;
        });
    } catch (e) {
      error(e.message);
      return;
    }
    return ret;
  }

  const deleteCancel = () => {
    setIsDelete(false);
  };
  // end_削除

  // 絞込設定、事前登録、表示設定、まとめて編集の破壊確認メッセージのOKボタン押下
  const discardOk = () => {
    if (isFilter == true) {
      setIsFilter(false);
      setCheckedState(filterCheckedData);
      setInputVal(filterInputData);
      setIsAllClear(isFilterAllClear(filterCheckedData));
      setCustomerRdo(customerRdoBk);
    }
    if (preRegister == true) {
      setPreRegister(false);
    }
    if (isUpdate == true) {
      setIsUpdate(false);
    }
    if (isDisplay == true) {
      setSortingColumns(chkData);
      setIsDisplay(false);
      setSortingData(chkData);
      sortingRef.current.setItems(chkData);
      sortingRef.current.setCurIndex(0);
    }
    setIsDiscardData(false);
  };

  // 絞込設定、事前登録、表示設定、更新処理の破壊確認メッセージのCancelボタン押下
  const discardCancel = () => {
    if (isFilter == true) {
      setIsFilter(true);
    }
    if (preRegister == true) {
      setPreRegister(true);
    }
    if (isUpdate == true) {
      setIsUpdate(true);
    }
    if (isDisplay == true) {
      setIsDisplay(true);
    }
    setIsDiscardData(false);
  };

  // 各項目のチェックON・OFFで全てクリアチェックを判断する
  const isFilterAllClear = (data) => {
    return data?.findIndex((item) => item.checked) === -1;
  };

  // 絞込設定の情報より、親部品リスト画面の情報を絞込み
  const filterBySettingInfo = (listData, checkedState, inputVal, customerUsedType) => {
    // merge
    const mergedArray = checkedState?.map((item, index) => {
      return { ...inputVal[index], check: item.checked };
    });
    const filterData = mergedArray?.filter((i) => i.check == true);
    let parentLstData = listData;
    if (filterData?.length >= 0) {
      const parentLstFilterData = parentLstData?.filter((item) =>
        filterData.every((filterItem) => {
          // const { 0: minValue, 1: maxValue, 2: key } = filterItem;
          let { 0: minValue, 1: maxValue, 2: colIndex } = filterItem.inputData;
          // const itemValue = item[key];
          let itemValue = '';
          let filterType = 0;
          // デフォルト項目の場合、
          if (filterItem.displayColumnsGroup === DisplayColumnsGroup.Default) {
            switch (colIndex) {
              case 1: // id
                filterType = 1;
                itemValue = item?.estimateProducts?.id;
                break;
              case 2: // お客様コード
                filterType = 2;
                itemValue = props?.clientInfo?.find((client) => client.id === item?.estimateProducts?.clientId)?.code;
                break;
              case 3: // お客様
                if (customerUsedType === CustomerFilterType.Select) {
                  filterType = 4;
                } else {
                  filterType = 3;
                  minValue = maxValue;
                }
                itemValue = item?.estimateProducts?.clientName;
                break;
              case 4: // 図番
                filterType = 3;
                itemValue = item?.estimateProducts?.drawingNo;
                break;
              case 5: // 名称
                filterType = 3;
                itemValue = item?.estimateProducts?.name;
                break;
              case 6: // 数量
                filterType = 1;
                itemValue = item?.estimateProducts?.quantity;
                break;
              case 7: // 粗利益
                filterType = 1;
                itemValue = item?.estimateProducts?.grossTotalPrice;
                break;
              case 8: // 粗利率
                filterType = 1;
                itemValue = item?.estimateProducts?.grossRate;
                break;
              case 9: // 見積単価
                filterType = 1;
                itemValue = item?.estimateProducts?.estimateUnitPrice;
                break;
              case 10: // 見積合計
                filterType = 1;
                itemValue = item?.estimateProducts?.estimateTotalPrice;
                break;
              case 11: // 材料原価
                filterType = 1;
                itemValue = item?.estimateProducts?.materialCostUnitPrice;
                break;
              case 12: // 製造原価
                filterType = 1;
                itemValue = item?.estimateProducts?.manufacturingCostTotalPrice;
                break;
              case 13: // 段取原価
                filterType = 1;
                itemValue = item?.estimateProducts?.setupCostTotalPrice;
                break;
              case 14: // 加工原価
                filterType = 1;
                itemValue = item?.estimateProducts?.workCostTotalPrice;
                break;
              case 15: // 購入品費
                filterType = 1;
                itemValue = item?.estimateProducts?.purchaseTotalPrice;
                break;
              case 16: // 管理費
                filterType = 1;
                itemValue = item?.estimateProducts?.managementFeeTotalPrice;
                break;
              case 17: // 見積番号
                filterType = 1;
                itemValue = item?.estimateProducts?.estimateNo;
                break;
              case 18: // 価格調整
                filterType = 1;
                itemValue = item?.estimateProducts?.otherProduct?.adjustment;
                break;
              case 19: // 作成者
                filterType = 4;
                itemValue = item?.estimateProducts?.creater;
                break;
              case 20: // 最終更新者
                filterType = 4;
                itemValue = item?.estimateProducts?.modifier;
                break;
              case 21: // 作成日
                filterType = 5;
                itemValue = item?.estimateProducts?.created;
                break;
              case 22: // 更新日
                filterType = 5;
                itemValue = item?.estimateProducts?.modified;
                break;
              case 23: // 総表面積(c㎡)
                filterType = 1;
                itemValue = item?.estimateProducts?.totalSurfaceArea;
                break;
              case 24: // 受注形態
                filterType = 4;
                itemValue = item?.estimateProducts?.coeffOrdersName;
                break;
              case 25: // 納期形態
                filterType = 4;
                itemValue = item?.estimateProducts?.coeffDeliveryDatesName;
                break;
              case 27: // 受注非受注
                filterType = 4;
                itemValue = item?.estimateProducts?.estimateStatus;
                break;
              case 28: // サイズX
                filterType = 1;
                itemValue = item?.estimateProducts?.sizeX;
                break;
              case 29: // サイズY
                filterType = 1;
                itemValue = item?.estimateProducts?.sizeY;
                break;
              case 30: // サイズZ
                filterType = 1;
                itemValue = item?.estimateProducts?.sizeZ;
                break;
              case 31: // 子部品総重量
                filterType = 1;
                itemValue = item?.estimateProducts?.totalWeightParts;
                break;
              case 32: // 板金重量
                filterType = 1;
                itemValue = item?.estimateProducts?.totalWeightSm;
                break;
              case 33: // 子部品種類
                filterType = 1;
                itemValue = item?.estimateProducts?.partTypesParts;
                break;
              case 34: // 板金種類
                filterType = 1;
                itemValue = item?.estimateProducts?.partTypesSm;
                break;
              case 35: // 子部品製作数
                filterType = 1;
                itemValue = item?.estimateProducts?.totalQuantityParts;
                break;
              case 36: // 板金製作数
                filterType = 1;
                itemValue = item?.estimateProducts?.totalQuantitySm;
                break;
              case 37: // 表面処理
                filterType = 1;
                itemValue = getProcessTotalPrice(item?.estimateProducts?.additions, processInfo, WorkType.PaSurface);
                break;
              case 38: // 組立
                filterType = 1;
                itemValue = getProcessTotalPrice(item?.estimateProducts?.additions, processInfo, WorkType.PaAssenble);
                break;
              case 39: // 検査
                filterType = 1;
                itemValue = getProcessTotalPrice(item?.estimateProducts?.additions, processInfo, WorkType.PaInspection);
                break;
              case 40: // 梱包
                filterType = 1;
                itemValue = getProcessTotalPrice(item?.estimateProducts?.additions, processInfo, WorkType.PaPacking);
                break;
              default:
                break;
            }
          } else if (filterItem.displayColumnsGroup === DisplayColumnsGroup.AdditionalItemCost) {
            filterType = 1;
            itemValue = item.epCustomCosts?.find((r) => r.additionalItemId === filterItem.additionalItemId)?.unitPrice;
          } else if (filterItem.displayColumnsGroup === DisplayColumnsGroup.AdditionalItemDate) {
            filterType = 5;
            itemValue = item.epCustomDates?.find((r) => r.additionalItemId === filterItem.additionalItemId)?.date;
          } else if (filterItem.displayColumnsGroup === DisplayColumnsGroup.AdditionalItemLabel) {
            filterType = 3;
            itemValue = item.epCustomLabels?.find((r) => r.additionalItemId === filterItem.additionalItemId)?.label;
          } else if (filterItem.displayColumnsGroup === DisplayColumnsGroup.AdditionalItemMemo) {
            filterType = 3;
            itemValue = item.epCustomMemos?.find((r) => r.additionalItemId === filterItem.additionalItemId)?.memo;
          }
          // From~To入力項目の場合、
          if (filterType === 1) {
            const intValue = parseInt(itemValue);
            if (minValue === '' && maxValue === '') return true;
            if (minValue === '') return intValue <= parseInt(maxValue);
            if (maxValue === '') return intValue >= parseInt(minValue);
            return intValue >= parseInt(minValue) && intValue <= parseInt(maxValue);
          } else if (filterType === 2) {
            // お客様コードの場合、
            if (minValue === '' && maxValue === '') return true;
            if (minValue === '') return itemValue <= maxValue;
            if (maxValue === '') return itemValue >= minValue;
            return itemValue >= minValue && itemValue <= maxValue;
          } else if (filterType === 3) {
            // テキストボックス値の場合、
            return itemValue?.includes(minValue);
          } else if (filterType === 4) {
            // 選択ボックス値の場合、
            return itemValue == minValue;
          } else if (filterType === 5) {
            // 日付値の場合、
            const intValue = new Date(formatDate(itemValue))?.getTime();
            if (minValue === '' && maxValue === '') return true;
            if (minValue === '') return intValue <= new Date(formatDate(maxValue))?.getTime();
            if (maxValue === '') return intValue >= new Date(formatDate(minValue))?.getTime();
            return (
              intValue >= new Date(formatDate(minValue))?.getTime() &&
              intValue <= new Date(formatDate(maxValue))?.getTime()
            );
          }
          return true;
        })
      );
      setInitialTableItems(parentLstFilterData);
      let initDisplayColumns = getDisplayColumns();
      let sortColumns = initDisplayColumns; // 初期状態の場合、
      let chkCols = prepareChkDataColumns(sortColumns);
      displayTotalCount = parentLstFilterData?.length;
    }
  };

  // 表示設定、絞込設定、事前登録画面の更新確認メッセージのOKボタン押下
  const updateDataOk = async () => {
    // 絞込設定画面の場合、
    if (isFilter == true) {
      let updateData = { ...userInfo };
      updateData = {
        ...updateData,
        displaySettings: { ...updateData.displaySettings },
        filterSettings: {
          customerUsedType: customerRdo,
          checkedInfos: checkedState,
          inputInfos: inputVal,
        },
      };
      let updatedData = await updateUserInfo(updateData);
      let checkedInfos = updatedData?.filterSettings?.checkedInfos;
      let inputInfos = updatedData?.filterSettings?.inputInfos;
      setFilterCheckedData(checkedInfos);
      setCheckedState(checkedInfos);
      setFilterInputData(inputInfos);
      setInputVal(inputInfos);
      setIsAllClear(isFilterAllClear(checkedInfos));
      setCustomerRdo(updatedData?.filterSettings?.customerUsedType);
      filterBySettingInfo(initialTblItemsBk, checkedState, inputVal, updatedData?.filterSettings?.customerUsedType);
      setIsFilter(false);
    }

    // 事前登録画面の場合、
    if (preRegister == true) {
      setPreRegister(false);
    }
    // 表示設定画面の場合、
    if (isDisplay == true) {
      if (value === 2) {
        // setPageSize(10); //本番の場合、200に変更
      } else if (value === 3) {
        // setPageSize(15); //本番の場合、300に変更
      } else if (value === 4) {
        // setPageSize(16); //本番の場合、500に変更
      } else if (value === 5) {
        // setPageSize(17); //本番の場合、1000に変更
      } else {
        // setPageSize(25); //本番の場合、100に変更
      }

      let pageSize = pageSizeOptions?.find((item) => item.value === value)?.label;

      sortingData?.map((i) => {
        var ret = columns?.find((col) => col.id === i.id);
        if (ret) tempColumnsArray.push(ret);
      });

      let displayColumns;
      if (tempColumnsArray?.length > 0) {
        setChkData([...tempColumnsArray]);
        let exportCols = prepareExportCol([...tempColumnsArray]);
        setExportColumn(exportCols);
        displayColumns = [...tempColumnsArray];
      } else {
        setChkData(sortingData);
        let exportCols = prepareExportCol(sortingData);
        setExportColumn(exportCols);
        displayColumns = [...sortingData];
      }
      tempColumnsArray = [];
      setIsDisplay(false);
      setCheckData(false);
      sortingRef.current.setCurIndex(0);

      let updateData = { ...userInfo };
      updateData = {
        ...updateData,
        displaySettings: {
          displayNum: pageSize,
          // displayColumns: displayColumns,
          displayColumns: columns,
          sortColumns: sortingData,
        },
        filterSettings: { ...updateData?.filterSettings },
      };
      let updatedData = await updateUserInfo(updateData);

      setPageSize(updatedData?.displaySettings?.displayNum);

      // setSortingColumns(sortingData);
      setSortingColumns(updatedData?.displaySettings?.sortColumns);
    }
    if (isUpdate == true) {
      setIsUpdate(false);
    }
    setIsUpdateData(false);
  };

  const updateDataCancel = () => {
    if (isFilter == true) {
      setIsFilter(true);
    }
    if (preRegister == true) {
      setPreRegister(true);
    }
    if (isUpdate == true) {
      setIsUpdate(true);
    }
    if (isDisplay == true) {
      setIsDisplay(true);
    }
    setIsUpdateData(false);
  };

  // ユーザー情報をDBに更新する
  async function updateUserInfo(updateData) {
    let ret = [];
    try {
      const Url = process.env.REACT_APP_BACKEND_API_URL + 'User';
      const otherParam = {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + getAccessToken(),
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-ZF-APP-KEY': process.env.REACT_APP_API_KEY_AUTH,
        },
        body: JSON.stringify({
          displaySettings: updateData.displaySettings,
          filterSettings: updateData?.filterSettings,
        }),
      };

      await fetch(Url, otherParam)
        .then(async (response) => {
          return await response.json();
        })
        .then((data) => {
          if (data && data?.updateToken) {
            // セクションにトークン情報を更新する
            updateAccessToken(data?.updateToken);
          }
          if (data && data.statusCode === StatusCodes.OK) {
            ret = data.data;
          } else if (
            data &&
            (data.statusCode === StatusCodes.UNAUTHORIZED || data.statusCode === StatusCodes.CONFLICT)
          ) {
            authorizeError(ErrorMessage()[data?.messageCode]);
            return;
          } else if (data && data.statusCode !== StatusCodes.OK) {
            error(ErrorMessage()[data?.messageCode]);
            return;
          }
        })
        .catch((e) => {
          authorizeError(ErrorMessage().E010);
          return;
        });
    } catch (e) {
      error(e.message);
      return;
    }
    return ret;
  }

  // 表示設定_start
  const displaySetting = () => {
    setClickedAction(5);
    if (editMode) {
      setEditModeCancelConfirm(true);
    } else {
      setEditMode(false);
      if (props.updateEstimateEditMode !== undefined) props.updateEstimateEditMode(false);
      setIsDisplay(true);

      setChildTitle('表示設定');
      let temp = [];
      temp = sortingColumns;
    }
  };

  const displayOk = () => {
    sortingData?.map((i) => {
      var ret = columns?.find((col) => col.id === i.id);
      if (ret) tempColumnsArray.push(ret);
    });
    setCheckData(false);
    if (tempColumnsArray?.length > 0) {
      setChkData([...tempColumnsArray]);
    } else {
      setChkData(sortingData);
    }
    tempColumnsArray = [];
    setIsDisplay(false);
    setSortingColumns(sortingData);
    sortingRef.current.setCurIndex(0);
  };

  const displayCancel = () => {
    setSortingColumns(chkData);
    setIsDisplay(false);
    setSortingData(chkData);
    sortingRef.current.setItems(chkData);
    sortingRef.current.setCurIndex(0);
  };

  // 表示設定画面の表示項目チェックボックスにON・OFF押下
  const handleChkChange = (e) => {
    let allSelectedData = [];
    let id = e.target.id;
    let name = e.target.name;
    let tempData = [...sortingData];
    let dispData = [];

    // チェックボックスONである、全てのItem
    dispData = displaySettingItems?.filter((record) => {
      if (document.getElementsByName('chkitem' + record?.id)?.[0]?.checked === true) {
        return record;
      }
    });

    // チェックボックスONの場合、
    if (document.getElementsByName(name) && document.getElementsByName(name)?.[0]?.checked) {
      //　ONにした項目の情報を取得する
      let newItem = dispData?.find((item) => item.id === id);
      // 並び替えリストに追加
      tempData = [...tempData, newItem];
      let index = tempData?.findIndex((item) => item.id === id);
      // 最初の項目として移す
      allSelectedData = arrayMoveImmutable(tempData, index, 0);
    } else {
      // チェックボックスOFFの場合、
      let index = tempData?.findIndex((item) => item.id === id);
      // 並び替えリストから消去する
      tempData.splice(index, 1);
      allSelectedData = tempData;
    }
    setSortingColumns(allSelectedData);
  };

  // 表示設定_end
  // 絞込み設定
  const filterSetting = () => {
    setClickedAction(6);

    if (editMode) {
      setEditModeCancelConfirm(true);
    } else {
      setEditMode(false);
      if (props.updateEstimateEditMode !== undefined) props.updateEstimateEditMode(false);
      setCustomerRdoBk(customerRdo);
      setIsFilter(true);
    }
  };

  const updateData = () => {
    // setIsFilter(false);
    setIsUpdateData(true);
  };

  const discardData = () => {
    setIsDiscardData(true);
    // setIsFilter(false);
  };

  const cancelFilter = () => {
    setIsFilter(false);
  };

  const discardConfirmOk = () => {
    setEditModeCancelConfirm(false);
    setEditMode(false);
    if (props.updateEstimateEditMode !== undefined) props.updateEstimateEditMode(false);
    if (clickedAction == 1) {
      // selected row change
      setSelectedRowKeys(currentRecordKey);
      setCSVExportData(currentRecord);
      setSelectedData(currentRecord);
      navigate(':parentList');
    } else if (clickedAction == 3) {
      // 事前登録
      setPreRegister(true);
      setChildTitle('事前登録');
    } else if (clickedAction == 4) {
      // 一括編集
      setUpdateItem(initialTblItems);
      setIsUpdate(true);
      setChildTitle('選択したデータをまとめて編集');
    } else if (clickedAction == 5) {
      // 表示設定
      setIsDisplay(true);

      setChildTitle('表示設定');
      let temp = [];
      temp = sortingColumns;
    } else if (clickedAction == 6) {
      // 絞込み設定
      setIsFilter(true);
    }
  };

  const discardConfirmCancel = () => {
    setEditMode(true);
    if (props.updateEstimateEditMode !== undefined) props.updateEstimateEditMode(true);
    setEditModeCancelConfirm(false);
  };

  // 絞込設定の全てクリア
  const clearAllCheckbox = (e) => {
    setIsAllClear(e.target.checked);
    let data = [...checkedState];
    data = data?.map((item) => ({ ...item, checked: !e.target.checked }));
    setCheckedState(data);
  };

  // 絞込設定のチェックボックスの押下
  const handleCheckboxChange = (index, checked) => {
    const newState = checkedState ? JSON.parse(JSON.stringify([...checkedState])) : [];
    newState[index].checked = checked;
    setCheckedState(newState);
    setIsAllClear(false);
  };

  // 絞込設定のテキストボックスに入力した場合、
  const handleInputVal = (index, e, data, colIndex) => {
    const newData = inputVal ? JSON.parse(JSON.stringify([...inputVal])) : [];
    newData[index].inputData[data] = e.target.value;
    newData[index].inputData[2] = colIndex;
    setInputVal(newData);
  };

  // 絞込設定の選択ボックスを変更した場合、
  const handleSelectVal = (index, e, data, colIndex) => {
    const newData = inputVal ? JSON.parse(JSON.stringify([...inputVal])) : [];
    newData[index].inputData[data] = e;
    newData[index].inputData[2] = colIndex;
    setInputVal(newData);
  };

  // 絞込設定の日付項目に値を変更した場合、
  const handleDateVal = (index, dateString, data, colIndex) => {
    const newData = inputVal ? JSON.parse(JSON.stringify([...inputVal])) : [];
    newData[index].inputData[data] = dateString;
    newData[index].inputData[2] = colIndex;
    setInputVal(newData);
  };

  // 画面切り替え
  const discardOkConfirm = () => {
    setConfirmScreenChangeModalOpen(false);
    setEditMode(false);
    if (clickedAction == 6) {
      // 子部品確認
      props.getSelectedEstimateData(updateItem, childSelectedKey);
      navigate(':estimate.iq3');
    }
  };

  const discardCancelConfirm = () => {
    setConfirmScreenChangeModalOpen(false);
  };

  // お客様コードでお客様名称を取得
  function getCustomerNameByCode(code, Lists) {
    let retValue = '';
    if (code) {
      let info = Lists?.filter((info) => info.code === code);
      if (info?.length > 0) {
        retValue = info[0].name;
      }
    }
    return retValue;
  }

  const handleCancel = () => {
    setIsUpdate(false);
  };

  // CSV
  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });

    const a = document.createElement('a');
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a?.dispatchEvent(clickEvt);
    a?.remove();
  };

  const exportToCsv = (e) => {
    e.preventDefault();
    let headers = ['Id,Name,DrawingNo,EstimateNo,Person In Charge'];
    if (csvExportData?.length > 0) {
      let usersCsv = csvExportData?.reduce((acc, user) => {
        const { id, customer, drawingNo, estimateNo, manager } = user;
        acc?.push([id, customer, drawingNo, estimateNo, manager].join(','));
        return acc;
      }, []);

      downloadFile({
        data: [...headers, ...usersCsv].join('\n'),
        fileName: '親部品リスト.csv',
        fileType: 'text/csv',
      });
    } else {
      alert('出力するデータを選択してください');
    }
  };
  // CSV end

  const childTypeChange = (e) => {
    if (e == 1) {
      document.getElementById('iq3Row').style.display = 'block';
      document.getElementById('iq5Row').style.display = 'block';
      document.getElementById('iq7Row').style.display = 'block';
    } else if (e == 2) {
      document.getElementById('iq3Row').style.display = 'block';
      document.getElementById('iq5Row').style.display = 'none';
      document.getElementById('iq7Row').style.display = 'none';
    } else if (e == 3) {
      document.getElementById('iq3Row').style.display = 'none';
      document.getElementById('iq5Row').style.display = 'block';
      document.getElementById('iq7Row').style.display = 'none';
    } else if (e == 4) {
      document.getElementById('iq3Row').style.display = 'none';
      document.getElementById('iq5Row').style.display = 'none';
      document.getElementById('iq7Row').style.display = 'block';
    }
  };

  const addRowTable = () => {
    const data = {
      drawingno: '',
      name: '',
      customercode: '',
      customername: '',
      lotno: 1,
      order: '受注',
    };
    initRow([...rows, data]);
  };

  const tableRowRemove = (index) => {
    if (rows?.length > 1) {
      const dataRow = [...rows];
      dataRow?.splice(index, 1);
      initRow(dataRow);
    }
  };

  const onValUpdate = (i, event) => {
    const { name, value } = event.target;
    const data = [...rows];
    data[i][name] = value;
    initRow(data);
  };

  const menuIconList = (
    <>
      <div
        style={{
          borderBottom: 'solid',
          borderBottomWidth: '1px',
          borderBottomColor: '#212529',
          backgroundColor: '#fafafc',
          // height: '3.8vh',
        }}
      >
        <Row style={{ marginLeft: '10px', marginTop: -22 }}>
          <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', width: '60%' }}>
            <Row style={{ marginTop: '1px', marginLeft: '10px' }}>
              <Col span={24}>
                <Space size="middle">
                  {/* {props.isFromiQ3 == undefined || props.isFromiQ3 == false ? (
                    <>
                      <Tooltip title="新規作成" placement="bottom" overlayClassName="tooltip-text">
                        <a onClick={createParent}>
                          <Image
                            preview={false}
                            width={iconWidth}
                            src={new_icon}
                            style={{
                              marginLeft: '0px',
                            }}
                          ></Image>
                        </a>
                      </Tooltip>
                      <Tooltip title="呼出" placement="bottom" overlayClassName="tooltip-text">
                        <a onClick={commonTaiochuPopupOpen}>
                          <Image preview={false} width={27} src={detail_display} style={{ marginLeft: '0px' }}></Image>
                        </a>
                      </Tooltip>{' '}
                    </>
                  ) : (
                    ''
                  )} */}
                  {/* <Tooltip title="追加呼出" placement="bottom" overlayClassName="tooltip-text">
                    <a onClick={commonTaiochuPopupOpen}>
                      <Image preview={false} width={27} src={call_detail} style={{ marginLeft: '0px' }}></Image>
                    </a>
                  </Tooltip> */}
                  <Tooltip title="子部品確認・工程別合計" placement="top" overlayClassName="tooltip-text">
                    <Menu
                      onClick={infoConfirmOutputMenuChangeEvent}
                      mode="horizontal"
                      items={infoConfirmOutputMenuitems}
                      style={{
                        marginLeft: '-19px',
                        marginTop: '-24px',
                        height: 0,
                        color: 'white',
                      }}
                    />
                  </Tooltip>

                  {/* <Tooltip title="加工確認" placement="bottom" overlayClassName="tooltip-text">
                    <a onClick={manufactureOpen}>
                      <Image
                        preview={false}
                        width={iconWidth}
                        src={detail_confirm}
                        style={{ marginLeft: '0px' }}
                      ></Image>
                    </a>
                  </Tooltip> */}

                  {props.isFromiQ3 == undefined || props.isFromiQ3 == false ? (
                    <>
                      <Tooltip title="複写" placement="bottom" overlayClassName="tooltip-text">
                        <a onClick={displayCopyConfirm}>
                          <Image preview={false} width={iconWidth} src={copy} style={{ marginLeft: '3px' }}></Image>
                        </a>
                      </Tooltip>

                      <Tooltip title="削除" placement="bottom" overlayClassName="tooltip-text">
                        <a onClick={deleteRow}>
                          <Image
                            preview={false}
                            width={iconWidth}
                            src={child_delete_icon}
                            style={{ marginLeft: '0px' }}
                          ></Image>
                        </a>
                      </Tooltip>
                      <Tooltip title="表示設定" placement="bottom" overlayClassName="tooltip-text">
                        <a onClick={displaySetting}>
                          <Image preview={false} width={33} src={display_setting} style={{ marginLeft: '0px' }}></Image>
                        </a>
                      </Tooltip>
                    </>
                  ) : (
                    ''
                  )}

                  <Tooltip title="絞込設定" placement="bottom" overlayClassName="tooltip-text">
                    <a onClick={filterSetting}>
                      <Image
                        preview={false}
                        width={iconWidth}
                        src={search_list}
                        style={{ marginLeft: '0px', marginTop: '0px' }}
                      ></Image>
                    </a>
                  </Tooltip>

                  {props.isFromiQ3 == undefined || props.isFromiQ3 == false ? (
                    <>
                      <Tooltip title="帳票出力" placement="top" overlayClassName="tooltip-text">
                        <Menu
                          onClick={reportOutputChangeEvent}
                          mode="horizontal"
                          items={reportOutputMenuitems}
                          style={{
                            marginLeft: '-19px',
                            marginTop: '-24px',
                            height: 0,
                            color: 'white',
                          }}
                        />
                      </Tooltip>

                      <Tooltip title="CSV出力" placement="top" overlayClassName="tooltip-text">
                        <Menu
                          onClick={reportOutputChangeEvent}
                          mode="horizontal"
                          items={listOutputMenuitems}
                          style={{
                            marginLeft: '-15px',
                            marginTop: '-24px',
                            height: 0,
                            color: 'white',
                            textDecoration: 'none',
                          }}
                        ></Menu>
                        <CSVLink
                          ref={csvFile}
                          // data={estimateProductsData}
                          data={exportData}
                          filename={fileName}
                          headers={exportColumns}
                          style={{ display: 'none' }}
                        >
                          Export
                        </CSVLink>

                        <CSVLink
                          ref={productionManageCSVFile}
                          // data={estimateProductsProductionManageData}
                          data={estimateExportData}
                          filename={productionManageCSVFileName}
                          headers={productionManageCSVFileHeaders}
                          style={{ display: 'none' }}
                        >
                          Export
                        </CSVLink>
                      </Tooltip>

                      {/* <Tooltip title="事前登録" placement="bottom" overlayClassName="tooltip-text">
                        <a onClick={preRegistertation}>
                          <Image
                            preview={false}
                            width={iconWidth}
                            src={jisen_register}
                            style={{ marginLeft: '15px' }}
                          ></Image>
                        </a>
                      </Tooltip>
                      <Tooltip title="一括編集" placement="bottom" overlayClassName="tooltip-text">
                        <a onClick={updateAll}>
                          <Image
                            preview={false}
                            width={28}
                            src={edit_list}
                            style={{ marginLeft: '15px', marginTop: '6px' }}
                          ></Image>
                        </a>
                      </Tooltip>
                      <Tooltip title="承認登録" placement="bottom" overlayClassName="tooltip-text">
                        <a onClick={commonTaiochuPopupOpen}>
                          <Image
                            preview={false}
                            width={iconWidth}
                            src={jisen_register}
                            style={{ marginLeft: '15px' }}
                          ></Image>
                        </a>
                      </Tooltip>
                      <Tooltip title="アナライズ" placement="bottom" overlayClassName="tooltip-text">
                        <a onClick={commonTaiochuPopupOpen}>
                          <Image preview={false} width={iconWidth} src={analyze} style={{ marginLeft: '15px' }}></Image>
                        </a>
                      </Tooltip> */}
                    </>
                  ) : (
                    ''
                  )}
                </Space>
              </Col>
            </Row>
          </div>
          <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center', width: '38%' }}>
            <Row style={{ marginTop: '24px', marginLeft: '10px' }}>
              {editMode ? (
                <Col span={editMode ? 20 : 0}>
                  <Input
                    className="editInfoTle"
                    value={'編集中'}
                    type="text"
                    prefix={
                      <Image
                        preview={false}
                        width={13}
                        src={edit_info}
                        style={{
                          transform: 'rotate(270deg)',
                          marginRight: '5px',
                        }}
                      />
                    }
                  />
                </Col>
              ) : (
                ''
              )}
              <Col span={editMode ? 4 : 5} offset={editMode ? 0 : 10}>
                <Space size="middle">
                  {btnShowHide ? (
                    <RightCircleFilled onClick={contentResizeBtn} style={{ color: '#1063aa', fontSize: 28 }} />
                  ) : (
                    <LeftCircleFilled onClick={contentResizeBackwardBtn} style={{ color: '#1063aa', fontSize: 28 }} />
                  )}
                </Space>
              </Col>
            </Row>
          </div>
        </Row>
      </div>
    </>
  );

  const addRows = rows?.map((rowsData, index) => {
    const { drawingno, name, customercode, customername, lotno, order } = rowsData;
    return (
      <tr key={index}>
        <td style={{ padding: '3px' }}>
          <Input
            onChange={(event) => onValUpdate(index, event)}
            value={drawingno}
            name="drawingno"
            className="input-editable"
          ></Input>
        </td>
        <td style={{ padding: '3px' }}>
          <Input style={{ padding: 3, height: 30 }} type="text" value={name} name="name" className="input-editable" />
        </td>
        <td style={{ padding: '3px' }}>
          <Select
            placeholder="選択"
            style={{ fontSize: '13.5px' }}
            id="customerCode"
            className="yousetsu-select"
            name="customercode"
          >
            {clientInfo?.map((option, index) => (
              <Select.Option key={index} value={option.id}>
                {option.name}
              </Select.Option>
            ))}
          </Select>
        </td>
        <td style={{ verticalAlign: 'middle', padding: '3px' }}>
          <Input
            style={{ padding: 3, height: 30 }}
            type="text"
            onChange={(event) => onValUpdate(index, event)}
            value={customername}
            className="input-editable"
            name="customername"
          />
        </td>
        <td style={{ verticalAlign: 'middle', padding: '3px' }}>
          <Input
            style={{ padding: 3, height: 30, textAlign: 'center' }}
            type="text"
            value={lotno}
            name="lotno"
            className="input-editable"
            onChange={(event) => onValUpdate(index, event)}
          />
        </td>
        <td style={{ verticalAlign: 'middle', padding: '3px' }}>
          {/* <Select
            placeholder="選択"
            style={{ width: '100%' }}
            options={orderOptions?.map((option) => ({ label: option.value, value: option.label }))}
          /> */}
          <Select
            // className="iq3-detail-select"
            // value={isOrder}
            name="estimateStatus"
            id="estimateStatus"
            // onChange={(e) => {
            //   setIsOrder(e);
            //   detailsData['allowGaichu'] = e;
            // }}
            style={{ padding: 3, width: '100%' }}
          >
            {orderOptions?.map((option, index) => (
              <Select.Option key={index} value={option.id}>
                {option.name}
              </Select.Option>
            ))}
          </Select>
        </td>
        <td
          style={{ border: 'none', width: '7%', backgroundColor: 'white', verticalAlign: 'middle' }}
          className="borderless"
        >
          <Row justify="center">
            <PlusCircleFilled
              className="add-remove-icon"
              style={{ marginLeft: 5, marginRight: 5 }}
              onClick={addRowTable}
            />
            <CloseCircleFilled className="add-remove-icon" onClick={tableRowRemove} />
          </Row>
        </td>
      </tr>
    );
  });

  const filterClearBtn = (
    <>
      <div style={{ display: 'flex' }}>
        <Image preview={false} width={18} height={18} src={filter_clear_icon} style={{ marginBottom: '5px' }} />
        <label style={{ marginLeft: '5px', marginTop: 1, fontSize: '12px' }}>検索条件をクリア</label>
      </div>
    </>
  );

  const editModalTitle = (
    <div
      style={{
        width: isUpdate ? 1790 : 520,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{childTitle}</p>
    </div>
  );

  const childModalTitle = (
    <div
      style={{
        width: 1790,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{childTitle}</p>
    </div>
  );

  const commonModalTitle = (
    <div
      style={{
        width: 520,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{childTitle}</p>
    </div>
  );

  const reportModalTitle = (
    <div
      style={{
        width: 600,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{childTitle}</p>
    </div>
  );

  const displaySettingModalTitle = (
    <div
      style={{
        width: 1400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>表示設定</p>
    </div>
  );

  const jisenRegisterModalTitle = (
    <div
      style={{
        width: 1030,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>事前登録リスト表示</p>
    </div>
  );

  const filterModalTitle = (
    <div
      style={{
        width: 1225,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>絞込設定</p>
    </div>
  );

  const confirmChildInfoContent = (
    <>
      {' '}
      <Row style={{ height: '25px' }}>
        <Space size="large">
          <p style={{ fontSize: '13.5px', fontWeight: '500' }}>お客様：{updateItem.clientName}</p>
          <p style={{ fontSize: '13.5px', fontWeight: '500' }}>図番：{updateItem.drawingNo}</p>
          <p style={{ fontSize: '13.5px', fontWeight: '500' }}>名称：{updateItem.name}</p>
        </Space>
      </Row>
      <Row align="middle" className="mt-1">
        <Col span={1}>
          <p style={{ fontSize: '13.5px', fontWeight: '500', margin: 0 }}>種類</p>
        </Col>
        <Col span={2}>
          <Select
            style={{ width: '100%', height: '30px', fontSize: '10px', marginLeft: -12 }}
            onChange={childTypeChange}
            name="childType"
            defaultValue={childType[0].value}
          >
            {childType?.map((option, index) => (
              <Select.Option key={index} value={option.id}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Row id="iq3Row" style={{ marginTop: 5 }}>
        <p style={{ fontSize: '13.5px', marginBottom: 1, marginTop: 0, fontWeight: 'bold' }}>板金部品</p>
        <Table
          showSorterTooltip={false}
          columns={iq3Columns}
          rowKey={(record) => record.key}
          dataSource={tenjikaiSampleData.parentList[26].iq3DataLst}
          // dataSource={selectedData}
          scroll={{ y: '10vh', x: '10vw' }}
          className="child-detailTable"
          pagination={false}
          components={components}
          onRow={(record, index) => {
            return {
              onDoubleClick: () => {
                if (isFromiQ3) return;
                if (record != null) {
                  setChildSelectedKey(record?.estimateProducts?.id);
                  setClickedAction(6);
                  if (editMode) {
                    setConfirmScreenChangeModalOpen(true);
                  } else {
                    setEditMode(false);
                    props.getSelectedEstimateData(updateItem, record?.estimateProducts?.id, false);
                    navigate(':estimate.iq3');
                  }
                }
              },
            };
          }}
        />
      </Row>
    </>
  );

  const copyParentInfoContent = (
    <>
      <p style={{ fontSize: '13.5px', height: 10, borderBottom: 10 }}>
        選択データを複写します。必要な項目を編集してください。
      </p>
      <RTable className="propertiesTbl">
        <thead>
          {/* お客様 */}
          {/*  <tr>
            <td className="detail-tbl-val-title">
              <label className="detail-tbl-val-title-label">お客様</label>
            </td>
            <td colSpan={6} style={{ verticalAlign: 'middle' }}>
              <Input
                style={{ padding: 5, marginLeft: 5, width: '98%' }}
                className="input-editable"
                name="customerCode"
                id="customerCode"
                type="text"
                onChange={(e) => {
                  setCustomerCode(e.target.value);
                }}
                value={customerCode}
                suffix={
                  <a onClick={getCustomerName}>
                    <Image preview={false} width={20} height={20} src={search} style={{ marginBottom: '5px' }} />
                  </a>
                }
              />
            </td>
          </tr> */}
          <tr>
            <td className="detail-tbl-val-title">
              <label className="detail-tbl-val-title-label">図番</label>
            </td>
            <td colSpan={6} style={{ verticalAlign: 'middle' }}>
              <Input
                style={{ padding: 5, marginLeft: 5, width: '98.2%' }}
                className="input-editable "
                ref={ref}
                name="drawingNo"
                id="drawingNo"
                value={drawingNo}
                type="text"
                onChange={(e) => {
                  setDrawingNo(e.target.value);
                }}
              />
            </td>
          </tr>
          <tr>
            <td className="detail-tbl-val-title">
              <label className="detail-tbl-val-title-label">名称</label>
            </td>
            <td colSpan={6} style={{ verticalAlign: 'middle' }}>
              <Input
                style={{ padding: 5, marginLeft: 5, width: '98.2%' }}
                className="input-editable "
                name="parentEstimateName"
                id="parentEstimateName"
                value={parentEstimateName}
                type="text"
                onChange={(e) => {
                  setParentEstimateName(e.target.value);
                }}
              />
            </td>
          </tr>
          <tr>
            <td className="detail-tbl-val-title">
              <label className="detail-tbl-val-title-label">受注非受注</label>
            </td>
            <td colSpan={6} style={{ verticalAlign: 'middle' }}>
              <Select
                className="iq3-detail-select"
                value={estimateStatus}
                name="estimateStatus"
                id="estimateStatus"
                onChange={(e) => {
                  setEstimateStatus(e);
                  // detailsData.estimateProducts['estimateStatus'] = e;
                }}
                style={{ marginLeft: 5, width: '40.8%' }}
              >
                {orderOptions?.map((option, index) => (
                  <Select.Option key={index} value={option.name}>
                    {option.name}
                  </Select.Option>
                ))}
              </Select>
            </td>
          </tr>
        </thead>
      </RTable>
    </>
  );

  // 親部品リストの削除ボタン押下
  const deleteParentInfoContent = (
    <>
      <p style={{ fontSize: '13.5px' }}>
        {/* 選択したデータ”図番”、”名称”を削除します。<br></br>削除したデータは戻せません。 */}
        選択した見積データを削除します。<br></br>削除したデータは戻せません。
      </p>
    </>
  );

  // 事前登録
  const jisenRegisterContent = (
    <>
      <div style={{ marginTop: -25 }}>
        <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Row className="mt-4" style={{ marginRight: 90 }}>
            <label
              class="input-group-text"
              for="inputGroupFile"
              style={{ color: '#005fab', height: 28, fontSize: '13.5px' }}
            >
              ファイル取込
            </label>
            <Input
              // type="file"
              id="inputGroupFile"
              style={{ display: 'none' }}
              // accept=".xlsx,.csv"
              onClick={commonTaiochuPopupOpen}
            />
          </Row>
        </Col>
      </div>

      <div className="overflow-auto registerModal" style={{ height: 600, overflowY: 'scroll', marginTop: 3 }}>
        <RTable style={{ marginTop: 5, width: '99%' }} className="">
          <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
            <tr style={{ textAlign: 'center' }}>
              <th style={{ width: '17%' }}>図番</th>
              <th style={{ width: '17%' }}>名称</th>
              <th style={{ width: '17%' }}>お客様コード</th>
              <th style={{ width: '17%' }}>お客様名称</th>
              <th style={{ width: '7%' }}>数量</th>
              <th style={{ width: '17%' }}>受注非受注</th>
            </tr>
          </thead>
          <tbody>{addRows}</tbody>
        </RTable>
      </div>
      <div style={{ marginTop: 5 }}>
        <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: -5 }}>
          <Row className="mt-4" style={{ marginRight: 0 }}>
            <Button onClick={updateData} className="mainButton" id="update">
              更新
            </Button>

            <Button onClick={discardData} className="cancelButton" style={{ marginLeft: 10 }} id="discard">
              破棄
            </Button>
          </Row>
        </Col>
      </div>
    </>
  );

  // 一括編集
  const editAllContent = (
    <>
      <Row style={{ paddingBottom: 0, paddingTop: 0 }}>
        {/** 一覧 */}
        <Col span={13}>
          <p style={{ fontSize: '13.5px', marginBottom: 6, marginTop: 0, fontWeight: '700' }}>
            {chkrowscount}件を選択中
          </p>
          <Table
            showSorterTooltip={false}
            columns={updTblColumns}
            rowKey={(record) => record.id}
            dataSource={updateItem}
            scroll={{ y: 250, x: '10vw' }}
            rowClassName={(record) => (record.key == selectedRowKeys ? 'active-row' : 'data-row')}
            className="parent-detail-EditTable"
            rowSelection={rowSelection}
            pagination={{
              position: ['bottomLeft'],
              total: (record) => record?.length,
              showTotal: (total, range) => `全${total}件中${range[0]}件〜${range[1]}件を表示`,
              defaultPageSize: 25, // 本番値確認必要
              defaultCurrent: 1,
            }}
          />
        </Col>
        {/** プロパティレイアウト */}
        <Col span={11}>
          <div
            style={{
              height: 749,
              marginTop: 28,
              fontSize: '13.5px',
              overflowY: 'auto',
              whiteSpace: 'nowrap',
            }}
          >
            <RTable className="propertiesTbl">
              <thead>
                <tr>
                  <td className="detail-tbl-val-title">
                    <label className="detail-tbl-val-title-label">受注非受注</label>
                  </td>
                  <td colSpan={6} style={{ verticalAlign: 'middle' }}>
                    <Select
                      className="iq3-detail-select"
                      name="estimateStatus"
                      id="estimateStatus"
                      onChange={(e) => {
                        // setIsOrder(e);
                        // detailsData['isOrder'] = e;
                      }}
                      style={{ marginLeft: 5, width: '45%' }}
                    >
                      {orderOptions?.map((option, index) => (
                        <Select.Option key={index} value={option.id}>
                          {option.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </td>
                </tr>
                <tr>
                  <td className="detail-tbl-val-title">
                    <label className="detail-tbl-val-title-label">サイズX</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <Input
                      type="text"
                      style={{ padding: 5, marginLeft: 5, width: '90%' }}
                      className={'input-editable'}
                    ></Input>
                  </td>
                  <td className="detail-tbl-val-title">
                    <label className="detail-tbl-val-title-label">サイズY</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <Input
                      type="text"
                      style={{ padding: 5, marginLeft: 5, width: '90%' }}
                      className={'input-editable'}
                    ></Input>
                  </td>
                  <td className="detail-tbl-val-title">
                    <label className="detail-tbl-val-title-label">サイズZ</label>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <Input
                      type="text"
                      style={{ padding: 5, marginLeft: 5, width: '90%' }}
                      className={'input-editable'}
                    ></Input>
                  </td>
                </tr>

                {temphiyouColumns?.map((item, index) => {
                  return (
                    <>
                      <tr key={index}>
                        <td className="detail-tbl-val-title">
                          <label className="detail-tbl-val-title-label">{item.title}</label>
                        </td>
                        <td colSpan={6} style={{ verticalAlign: 'middle' }}>
                          <Input style={{ padding: 5, marginLeft: 5, width: '98.2%' }} className={'input-editable'} />
                        </td>
                      </tr>
                    </>
                  );
                })}

                {templabelColumns?.map((item, index) => {
                  return (
                    <>
                      <tr key={index}>
                        <td className="detail-tbl-val-title">
                          <label className="detail-tbl-val-title-label">{item.title}</label>
                        </td>
                        <td colSpan={6} style={{ verticalAlign: 'middle' }}>
                          <Input style={{ padding: 5, marginLeft: 5, width: '98.2%' }} className={'input-editable'} />
                        </td>
                      </tr>
                    </>
                  );
                })}
                {tempmemoColumns?.map((item, index) => {
                  return (
                    <>
                      <tr key={index}>
                        <td className="detail-tbl-val-title">
                          <label className="detail-tbl-val-title-label">{item.title}</label>
                        </td>
                        <td colSpan={6} style={{ verticalAlign: 'middle' }}>
                          <Input style={{ padding: 5, marginLeft: 5, width: '98.2%' }} className={'input-editable'} />
                        </td>
                      </tr>
                    </>
                  );
                })}
                {tempdateColumns?.map((item, index) => {
                  return (
                    <>
                      <tr key={index}>
                        <td className="detail-tbl-val-title">
                          <label className="detail-tbl-val-title-label">{item.title}</label>
                        </td>
                        <td colSpan={6} style={{ verticalAlign: 'middle' }}>
                          <Input style={{ padding: 5, marginLeft: 5, width: '98.2%' }} className={'input-editable'} />
                        </td>
                      </tr>
                    </>
                  );
                })}
              </thead>
            </RTable>
          </div>
        </Col>
      </Row>
      <Row
        justify="end"
        style={{
          marginBottom: 0,
          marginTop: -40,
        }}
      >
        <Button className="mainButton" onClick={updateData} style={{ marginRight: 5 }}>
          更新
        </Button>
        <Button className="cancelButton" onClick={discardData}>
          破棄
        </Button>
      </Row>
    </>
  );

  // 表示設定のコンテンツ
  const displaySettingContent = (
    <>
      <Row>
        <Col span={16}>
          <Row style={{ fontSize: '13.5px', marginTop: 5, fontWeight: '700' }}>表示件数 </Row>
          <Row style={{ marginTop: 5, marginLeft: 20, fontSize: '13.5px' }}>
            <Radio.Group onChange={changeDisplayCount} value={value}>
              {pageSizeOptions?.map((option) => (
                <Radio key={option.value} value={option.value}>
                  {option.label}
                </Radio>
              ))}
            </Radio.Group>
          </Row>
        </Col>
      </Row>

      <Row>
        <div className="left-display">
          <div style={{ fontSize: '13.5px', marginBottom: 5, fontWeight: '700' }}>表示項目</div>
          <Space align="start" direction="horizontal">
            {new Array(Math.floor(displaySettingItems?.length / 16) + 1).fill(null)?.map((_, cIndex) => (
              <Space align="start" direction="vertical">
                {new Array(16).fill(null)?.map((_, rIndex) => {
                  var index = cIndex * 16 + rIndex;
                  if (index >= displaySettingItems?.length || !displaySettingItems[index]) return <></>;
                  return (
                    <>
                      <Checkbox
                        style={{ marginLeft: 10, marginRight: 10 }}
                        key={index}
                        type="checkbox"
                        name={'chkitem' + displaySettingItems[index]?.id}
                        id={displaySettingItems[index]?.id}
                        defaultChecked={
                          displaySettingItems[index]?.id ==
                          displayColumnsIdList?.filter((i) => {
                            if (i == displaySettingItems[index]?.id) {
                              return displaySettingItems[index]?.id;
                            }
                          })
                        }
                        checked={
                          displaySettingItems[index]?.id ==
                          displayColumnsIdList?.filter((i) => {
                            if (i == displaySettingItems[index]?.id) {
                              return displaySettingItems[index]?.id;
                            }
                          })
                        }
                        onChange={handleChkChange}
                      >
                        {displaySettingItems[index]?.title}
                      </Checkbox>
                    </>
                  );
                })}
              </Space>
            ))}
          </Space>
        </div>
        <div className="right-display">
          <div style={{ fontSize: '13.5px', marginBottom: 5, fontWeight: '700' }}>
            ※選択されている表示項目及び並び替え項目
          </div>
          <Sorting items={sortingColumns} update={updateSortingData} sortingData={sortingData} ref={sortingRef} />
        </div>
      </Row>

      <Row className="mt-2" style={{ marginRight: 0, marginBottom: -10 }} justify="end">
        <Button onClick={updateData} className="mainButton" id="update">
          更新
        </Button>

        <Button onClick={discardData} className="cancelButton" style={{ marginLeft: 10 }} id="discard">
          破棄
        </Button>
      </Row>
    </>
  );

  // 絞込み設定
  const filterContent = (
    <>
      <div
        style={{
          fontSize: '13.5px',
          overflowX: 'auto',
          whiteSpace: 'nowrap',
        }}
        className="shiborikomiTbl"
      >
        <RTable>
          <tr>
            {/* <td>{firstFilterCol}</td> */}

            <td style={{ verticalAlign: 'top' }}>
              {new Array(Math.floor(filterSettingItems?.length / 21) + 1).fill(null)?.map((_, cIndex) => (
                <Space align="start" direction="vertical" className="filterDataRows">
                  {new Array(21).fill(null)?.map((_, rIndex) => {
                    var index = cIndex * 21 + rIndex;
                    if (index >= filterSettingItems?.length || !filterSettingItems[index]) return <></>;
                    return (
                      <>
                        <Row key={index} style={{ width: 390 }} align="middle" className="mt-1">
                          {/* ラベル */}
                          <Col span={8}>
                            <Checkbox
                              style={{ marginLeft: 10, marginRight: 10 }}
                              key={index}
                              type="checkbox"
                              name="chkitem"
                              checked={checkedState[index]?.checked}
                              onChange={(e) => handleCheckboxChange(index, e.target.checked)}
                            >
                              {filterSettingItems[index]?.title}
                            </Checkbox>
                          </Col>
                          {filterSettingItems[index]?.id === 3 ? ( // お客様項目の場合、
                            <>
                              <Col span={13}>
                                <Radio.Group
                                  onChange={customerFilterChange}
                                  value={customerRdo}
                                  style={{ marginLeft: -25 }}
                                >
                                  <Row>
                                    <Radio value={CustomerFilterType.Select} style={{ marginBottom: 3 }}></Radio>
                                    <Select
                                      defaultValue="選択してください。"
                                      style={{ width: 212, fontSize: 11, marginBottom: 4 }}
                                      name="customer"
                                      disabled={customerRdo === CustomerFilterType.Text}
                                      value={
                                        inputVal?.[index]?.inputData?.[0]
                                          ? inputVal?.[index]?.inputData?.[0]
                                          : undefined
                                      }
                                      onChange={(e) => {
                                        handleSelectVal(index, e, 0, filterSettingItems[index].id);
                                      }}
                                    >
                                      {clientInfo?.map((client, index) => (
                                        <Select.Option key={index} value={client.name}>
                                          {client.name}
                                        </Select.Option>
                                      ))}
                                    </Select>
                                  </Row>
                                  <Row>
                                    <Radio value={CustomerFilterType.Text}></Radio>
                                    <Input
                                      style={{
                                        width: 212,
                                        pointerEvents: customerRdo === CustomerFilterType.Select ? 'none' : 'auto',
                                      }}
                                      className={
                                        customerRdo === CustomerFilterType.Select
                                          ? 'filter-input filter-input-range input-editable disabled'
                                          : 'filter-input filter-input-range input-editable'
                                      }
                                      value={inputVal?.[index]?.inputData?.[1]}
                                      onChange={(e) => handleInputVal(index, e, 1, filterSettingItems[index].id)}
                                    ></Input>
                                  </Row>
                                </Radio.Group>
                              </Col>
                            </>
                          ) : labelColumnsList?.findIndex(
                              (col) =>
                                col.additionalItemId === filterSettingItems[index]?.additionalItemId &&
                                col.displayColumnsGroup === filterSettingItems[index]?.displayColumnsGroup
                            ) > -1 || // 追加項目・ラベルの場合、
                            memoColumnsList?.findIndex(
                              (col) =>
                                col.additionalItemId === filterSettingItems[index]?.additionalItemId &&
                                col.displayColumnsGroup === filterSettingItems[index]?.displayColumnsGroup
                            ) > -1 || // 追加項目・メモの場合、
                            filterSettingItems[index]?.id === 4 || // 図番項目の場合、
                            filterSettingItems[index]?.id === 5 ? ( // 名称項目の場合、
                            <>
                              <Col span={13}>
                                <Input
                                  className="filter-input filter-input-range input-editable"
                                  value={inputVal?.[index]?.inputData?.[0]}
                                  onChange={(e) => handleInputVal(index, e, 0, filterSettingItems[index].id)}
                                ></Input>
                              </Col>
                            </>
                          ) : filterSettingItems[index]?.id === 19 || // 作成者項目の場合、
                            filterSettingItems[index]?.id === 20 || // 最終更新者項目の場合、
                            filterSettingItems[index]?.id === 24 || // 受注形態項目の場合、
                            filterSettingItems[index]?.id === 25 || // 納期形態項目の場合、
                            filterSettingItems[index]?.id === 27 ? ( // 受注非受注項目の場合、
                            <>
                              <Col span={16}>
                                <Select
                                  defaultValue="選択してください。"
                                  style={{ width: 212, fontSize: 11 }}
                                  name="customer"
                                  value={
                                    inputVal?.[index]?.inputData?.[0] ? inputVal?.[index]?.inputData?.[0] : undefined
                                  }
                                  onChange={(e) => {
                                    handleSelectVal(index, e, 0, filterSettingItems[index].id);
                                  }}
                                >
                                  {filterSettingItems[index]?.id === 19 || // 作成者項目の場合、
                                  filterSettingItems[index]?.id === 20 ? ( // 最終更新者項目の場合、
                                    staffInfo?.map((staff, index) => (
                                      <Select.Option key={index} value={staff.name}>
                                        {staff.name}
                                      </Select.Option>
                                    ))
                                  ) : filterSettingItems[index]?.id === 24 ? ( // 受注形態項目の場合、
                                    companyInfo?.[0]?.coeffOrders?.map((coeff, index) => (
                                      <Select.Option key={index} value={coeff.name}>
                                        {coeff.name}
                                      </Select.Option>
                                    ))
                                  ) : filterSettingItems[index]?.id === 25 ? ( // 納期形態項目の場合、
                                    companyInfo?.[0]?.coeffDeliveryDates?.map((date, index) => (
                                      <Select.Option key={index} value={date.name}>
                                        {date.name}
                                      </Select.Option>
                                    ))
                                  ) : filterSettingItems[index]?.id === 27 ? ( // 受注非受注項目の場合、
                                    orderOptions?.map((order, index) => (
                                      <Select.Option key={index} value={order.name}>
                                        {order.name}
                                      </Select.Option>
                                    ))
                                  ) : (
                                    <></>
                                  )}
                                </Select>
                              </Col>
                            </>
                          ) : (
                            <>
                              {/* 入力項目From */}
                              <Col span={6}>
                                {filterSettingItems?.length - dateColumns?.length <= index || // 追加項目・日付の場合、
                                filterSettingItems[index]?.id === 21 || // 作成日項目の場合、
                                filterSettingItems[index]?.id === 22 ? ( // 更新日項目の場合、
                                  <>
                                    <DatePicker
                                      format={'YYYY-MM-DD'}
                                      style={{ marginLeft: 0 }}
                                      value={
                                        inputVal?.[index]?.inputData?.[0]
                                          ? dayjs(inputVal?.[index]?.inputData?.[0], 'YYYY-MM-DD')
                                          : ''
                                      }
                                      onChange={(_, dateString) =>
                                        handleDateVal(index, dateString, 0, filterSettingItems[index].id)
                                      }
                                    />
                                  </>
                                ) : (
                                  <>
                                    <Input
                                      className="filter-input-range input-editable"
                                      value={inputVal?.[index]?.inputData?.[0]}
                                      onChange={(e) => handleInputVal(index, e, 0, filterSettingItems[index].id)}
                                    ></Input>
                                  </>
                                )}
                              </Col>
                              <Col span={1} verticalAlign="center">
                                ～
                              </Col>
                              {/* 入力項目To */}
                              <Col span={6}>
                                {filterSettingItems?.length - dateColumns?.length <= index || // 追加項目・日付の場合、
                                filterSettingItems[index]?.id === 21 || // 作成日項目の場合、
                                filterSettingItems[index]?.id === 22 ? ( // 更新日項目の場合、
                                  <>
                                    <DatePicker
                                      format={'YYYY-MM-DD'}
                                      style={{ marginLeft: 0 }}
                                      value={
                                        inputVal?.[index]?.inputData?.[1]
                                          ? dayjs(inputVal?.[index]?.inputData?.[1], 'YYYY-MM-DD')
                                          : ''
                                      }
                                      onChange={(_, dateString) =>
                                        handleDateVal(index, dateString, 1, filterSettingItems[index].id)
                                      }
                                    />
                                  </>
                                ) : (
                                  <>
                                    <Input
                                      className="filter-input-range input-editable"
                                      value={inputVal?.[index]?.inputData?.[1]}
                                      onChange={(e) => handleInputVal(index, e, 1, filterSettingItems[index].id)}
                                    ></Input>
                                  </>
                                )}
                              </Col>
                            </>
                          )}
                        </Row>
                      </>
                    );
                  })}
                </Space>
              ))}
            </td>
            {/* <td>
              <div>
                {[1, 2, 3]?.map((num, index) => (
                  <Checkbox
                    key={num}
                    checked={checkedState[index]}
                    onChange={(e) => handleCheckboxChange(index, e.target.checked)}
                  >
                    Checkbox {num}
                  </Checkbox>
                ))}
              </div>
            </td> */}
          </tr>
        </RTable>
      </div>

      <Row className="mt-1" style={{ marginBottom: -5 }} align="middle">
        <Col span={12}>
          <Checkbox onChange={clearAllCheckbox} checked={isAllClear}>
            全てクリア
          </Checkbox>
        </Col>

        <Col span={5} style={{ textAlign: 'end' }} offset={7}>
          <Button onClick={updateData} className="mainButton" id="update">
            更新
          </Button>

          <Button onClick={discardData} className="cancelButton" style={{ marginLeft: 10 }} id="discard">
            破棄
          </Button>
        </Col>
      </Row>
    </>
  );

  const reportParentList = (
    <>
      <Row style={{ marginTop: 10 }}>
        <p style={{ fontSize: '13.5px', fontWeight: '500', margin: 0 }}>
          {isListOutputOpen
            ? 'CSV出力するフォーマットを選択してください'
            : '帳票出力するフォーマットを選択してください'}
        </p>
      </Row>
      <Row style={{ marginTop: 20 }}>
        <Col span={7}>
          <p style={{ fontSize: '13.5px', fontWeight: '500', margin: 0 }}>出力フォーマット：</p>
        </Col>{' '}
        {isListOutputOpen ? (
          <Col span={17}>
            <Select style={{ width: 300, fontSize: 11 }} name="customer"></Select>
          </Col>
        ) : isListReport ? (
          <Col span={17}>
            {excelListFormats !== undefined && excelListFormats?.length > 0 ? (
              <Select
                style={{ width: 300, fontSize: 11 }}
                value={selectedExeclListFormat?.id}
                onChange={(e) => {
                  const selectedData = excelListFormats?.find((item) => item?.id === e);
                  if (selectedData !== undefined) {
                    setSelectedExcelListTypeFileId(selectedData.fileId);
                    setSelectedExeclListFormat(selectedData);
                  }
                }}
              >
                {excelListFormats?.map((item) => {
                  return (
                    <Select.Option key={item?.id} value={item?.id}>
                      {item?.fileName}
                    </Select.Option>
                  );
                })}
              </Select>
            ) : (
              <Select style={{ width: 300, fontSize: 11 }} />
            )}
          </Col>
        ) : (
          <Col span={17}>
            {excelIndividualFormats !== undefined && excelIndividualFormats?.length > 0 ? (
              <Select
                style={{ width: 300, fontSize: 11 }}
                value={selectedExeclIndividualFormat?.id}
                onChange={(e) => {
                  const selectedData = excelIndividualFormats?.find((item) => item?.id === e);
                  if (selectedData !== undefined) {
                    setSelectedExeclIndividualFormat(selectedData);
                  }
                }}
              >
                {excelIndividualFormats?.map((item) => (
                  <Select.Option key={item?.id} value={item?.id}>
                    {item?.fileName}
                  </Select.Option>
                ))}
              </Select>
            ) : (
              <Select style={{ width: 300, fontSize: 11 }} />
            )}
          </Col>
        )}
      </Row>
      {/* <Row style={{ marginTop: 10 }}>
        <Col span={7}>
          <p style={{ fontSize: '13.5px', fontWeight: '500', margin: 0 }}>出力元ファイル：</p>
        </Col>
        <Col span={17}>
          <Input className="input-editable" style={{ width: 300 }} />
          <Upload>
            <Button style={{ fontSize: '13.5px', marginLeft: '5px' }}>参照</Button>
          </Upload>
        </Col>
      </Row> */}
      <Row style={{ marginTop: '17px' }}>
        <Col span={24} style={{ textAlign: 'right' }} offset={0}>
          <Button onClick={isListOutputOpen ? listOutputOk : reportOutputOk} className="mainButton" id="update">
            OK
          </Button>

          <Button
            onClick={isListOutputOpen ? listOutputCancel : reportOutputCancel}
            className="cancelButton"
            style={{ marginLeft: 10 }}
            id="discard"
          >
            キャンセル
          </Button>
        </Col>
      </Row>
    </>
  );

  return (
    <>
      {htmlContent?.map((html, index) => (
        <div key={index} dangerouslySetInnerHTML={{ __html: html.htmlTable }} />
      ))}
      <div style={{ overflow: 'hidden' }}>
        <Form className="components-table-demo-control-bar" form={form} onFinish={onFinish}>
          {/* メニューバー */}
          {menuIconList}
          {/* 一覧とプロパティ表示 */}
          <div
            style={{
              height: props.isFromiQ3 == undefined || props.isFromiQ3 == false ? '90.8vh' : '65.2vh',
              marginLeft: 10,
            }}
          >
            <SplitPane split="vertical" sizes={sizes} onChange={setSizes} resizerSize={1} style={{ color: 'red' }}>
              <div style={{ marginTop: 7 }}>
                {/* 検索アリア */}
                <div style={{ display: 'flex', width: '89%' }}>
                  <Input
                    id="searchCustomer"
                    name="searchCustomer"
                    value={customer}
                    style={{ marginLeft: 0 }}
                    placeholder="お客様"
                    allowClear
                    onChange={changeCustomer}
                    onPressEnter={getParentsByFilter}
                  />
                  <Input
                    id="searchDrawingNo"
                    name="searchDrawingNo"
                    style={{ marginLeft: 5 }}
                    placeholder="図番"
                    value={imageNum}
                    allowClear
                    onChange={changeDrawingNo}
                    onPressEnter={getParentsByFilter}
                  />
                  <Input
                    id="searchName"
                    name="searchName"
                    style={{ marginLeft: 5 }}
                    placeholder="名称"
                    value={name}
                    allowClear
                    onChange={changeName}
                    onPressEnter={getParentsByFilter}
                  />
                  <Input
                    id="searchKeyword"
                    name="searchKeyword"
                    style={{ marginLeft: 5 }}
                    value={keyword}
                    placeholder="キーワードを入力"
                    allowClear
                    onChange={changeKeywords}
                    onPressEnter={getParentsByFilter}
                  />
                  <Button style={{ marginLeft: 15 }} className="filterClearButton" onClick={clearAllFilter}>
                    {filterClearBtn}
                  </Button>
                </div>
                {/* 一覧アリア */}
                <Row style={{ marginTop: 10 }}>
                  <Table
                    showSorterTooltip={false}
                    className={
                      props.isFromiQ3 == undefined || props.isFromiQ3 == false ? 'tbParentList' : 'tbParentList_dialog'
                    }
                    columns={chkData}
                    rowKey={(record) => record?.estimateProducts?.id}
                    rowClassName={(record) =>
                      record?.estimateProducts?.id == selectedRowKeys ? 'active-row' : 'data-row'
                    }
                    // dataSource={hasData ? initialTblItems : olddata}
                    dataSource={initialTblItems}
                    scroll={{ y: 590, x: '10vw' }}
                    pagination={{
                      position: ['bottomLeft'],
                      total: (record) => record?.length,
                      showTotal: (total, range) => `全${total}件中${range[0]}件〜${range[1]}件を表示`,
                      pageSize: pageSize,
                      defaultCurrent: 1,
                      onChange: (page) => {
                        setCurrentPage(page);
                      },
                    }}
                    onRow={(record, index) => {
                      return {
                        onClick: (event) => {
                          onRowSelect(record);
                        },
                        onDoubleClick: () => {
                          // let key = initialTblItems?.length == 0 ? 0 : 25;
                          // if (record.key == 25 || record.key == 26) {
                          //   props.getSelectedEstimateData(record, null, false);
                          //   navigate(':estimate.parent');
                          // }
                          props.getSelectedEstimateData(record, null, false);
                          navigate(':estimate.parent');
                        },
                      };
                    }}
                  />
                </Row>
              </div>
              {/* プロパティ表示 */}
              <div>
                <Routes>
                  <Route
                    exact
                    path=":parentList"
                    element={
                      <ParentListDetail
                        editMode={editMode}
                        selectedData={selectedData}
                        onUpdateData={updatedDetails}
                        updateEditMode={updateEditMode}
                        orderOptions={orderOptions}
                        isFromiQ3={props.isFromiQ3}
                        companyInfo={companyInfo}
                        showEdtConfirmOK={showEdtConfirmOK}
                        showEdtConfirmCancle={showEdtConfirmCancle}
                      />
                    }
                  />
                </Routes>
              </div>
            </SplitPane>
          </div>
        </Form>
      </div>

      {/** 開発中ポップアップ */}
      {openCommonTaiochuPopup ? commonTaiochuModal(openCommonTaiochuPopup, closeCommonTaiochuPopup) : ''}

      {/** 編集中破棄確認ポップアップ */}
      {editModeCancelConfirm
        ? commonModal(
            editModeCancelConfirm,
            confirmModalTitle,
            commonFooter(discardConfirmOk, discardConfirmCancel),
            null,
            400,
            discardConfirmOk,
            discardConfirmCancel,
            confirmHakiContent,
            null,
            null,
            false
          )
        : ''}

      {/** 画面切替警告メッセージ */}
      {confirmScreenChangeModalOpen
        ? commonModal(
            confirmScreenChangeModalOpen,
            confirmScreenChangeTitle,
            commonFooter(discardOkConfirm, discardCancelConfirm),
            null,
            400,
            discardOkConfirm,
            discardCancelConfirm,
            confirmScreenChangeContent,
            null,
            null,
            false
          )
        : ''}
      {/** 更新の確認メッセージ */}
      {isUpdateData
        ? commonModal(
            isUpdateData,
            confirmModalTitle,
            commonFooter(updateDataOk, updateDataCancel),
            null,
            400,
            updateDataOk,
            updateDataCancel,
            confirmUpdateContent,
            null,
            null,
            false
          )
        : ''}
      {/** 破棄の確認メッセージ */}
      {isDiscardData
        ? commonModal(
            isDiscardData,
            confirmModalTitle,
            commonFooter(discardOk, discardCancel),
            null,
            400,
            discardOk,
            discardCancel,
            confirmDiscardContent,
            null,
            null,
            false
          )
        : ''}
      {/* 子部品確認 */}
      {isChildOpen
        ? commonModal(
            isChildOpen,
            childModalTitle,
            null,
            { height: 822 },
            1790,
            updateOk,
            updateCancel,
            confirmChildInfoContent,
            'updateModalStyle',
            null,
            true
          )
        : ''}
      {/* 加工確認 */}
      {isManufactureOpen ? (
        <KouteibetsuInfo
          selectedEstimateData={selectedEstimateData}
          showProcessTotalAmount={isManufactureOpen}
          closeProcessTotalAmountDialog={updateCancel}
          quantity={quantity}
        />
      ) : (
        ''
      )}
      {/* 複写_Modal */}
      {isCopy
        ? commonModal(
            isCopy,
            commonModalTitle,
            commonFooter(copyOk, copyCancel),
            null,
            520,
            copyOk,
            copyCancel,
            copyParentInfoContent,
            null,
            null,
            false
          )
        : ''}
      {/* 削除_Modal */}
      {isDelete
        ? commonModal(
            isDelete,
            editModalTitle,
            commonDeleteFooter(deleteOk, deleteCancel),
            null,
            520,
            deleteOk,
            deleteCancel,
            deleteParentInfoContent,
            null,
            null,
            false
          )
        : ''}
      {/* 事前登録 */}
      {preRegister
        ? commonModal(
            preRegister,
            jisenRegisterModalTitle,
            null,
            null,
            1030,
            updateData,
            preRegisterModalClose,
            jisenRegisterContent,
            'registerModalStyle',
            null,
            true
          )
        : ''}
      {/* 一括編集 */}
      {isUpdate
        ? commonModal(
            isUpdate,
            editModalTitle,
            null,
            null,
            1790,
            updateData,
            updateCancel,
            editAllContent,
            'updateModalStyle',
            null,
            true
          )
        : ''}
      {/* 表示設定 */}
      {isDisplay
        ? commonModal(
            isDisplay,
            displaySettingModalTitle,
            null,
            null,
            1400,
            updateData,
            displayCancel,
            displaySettingContent,
            null,
            null,
            true
          )
        : ''}
      {/* 絞込み設定 */}
      {isFilter
        ? commonModal(
            isFilter,
            filterModalTitle,
            null,
            null,
            1225,
            updateData,
            cancelFilter,
            filterContent,
            null,
            null,
            true
          )
        : ''}
      {/* リスト出力 */}
      {/* {isListOutputOpen
        ? commonModal(
            isListOutputOpen,
            reportModalTitle,
            null,
            { height: 160 },
            600,
            listOutputOk,
            listOutputCancel,
            reportParentList,
            'updateModalStyle',
            { top: 50 },
            false
          )
        : ''} */}
      {/* 帳票出力・CSV出力 */}
      {isReportOutputOpen
        ? commonModal(
            isReportOutputOpen,
            reportModalTitle,
            null,
            { height: 125 },
            600,
            reportOutputOk,
            reportOutputCancel,
            reportParentList,
            'updateModalStyle',
            { top: 50 },
            false
          )
        : ''}
      {/** 閲覧モードでもこのデータを保存していない場合は破棄さえてしまうため、メッセージ表示 */}
      {confirmDisplayDataSaveModalOpen
        ? commonModal(
            confirmDisplayDataSaveModalOpen,
            confirmScreenChangeTitle,
            null,
            null,
            400,
            goToCreateParentScreen,
            discardGoToCreateParentScreen,
            confirmDisplayDataSaveContent(goToCreateParentScreen, discardGoToCreateParentScreen),
            null,
            null,
            false
          )
        : ''}
      {/** お客様情報検索ダイアログ表示 */}
      {customerModal ? (
        <SearchAndGetCustomerData
          customerModal={customerModal}
          selectedCustomerData={selectedCustomerData}
          title={Customer}
          clientType={ClientType.Customer}
        />
      ) : (
        ''
      )}
    </>
  );
});
export default WithRouter(ParentList);
