/**
 * クラス名：板金のブランクのパンチ条件画面
 * 作成者：チュー
 * 作成日：2023/06/28
 * バージョン：1.0
 */
import React, { useEffect, forwardRef, useState } from 'react';
import { Table } from 'react-bootstrap';
import { Row, Col, Input, Select } from 'antd';
import { handleKeyPress } from '../../../common/Common';
const Device_Blank_PunchCondition = forwardRef((props, ref) => {
  const [editMode, setEditMode] = useState(false);
  const [punchConditionList, setPunchConditionList] = useState();
  useEffect(() => {
    setEditMode(props.editMode);

    setPunchConditionList(props?.detailsData?.punches);
  }, [props.editMode, props.detailsData]);

  const changeValue = (id, value) => {
    punchConditionList[id] = value;
    setPunchConditionList(punchConditionList);
    props.updateList(punchConditionList);
  };
  return (
    <>
      <Row className="chargeAmount" style={{ marginLeft: 10, marginRight: 10 }}>
        <Table style={{ width: '60%' }}>
          <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
            <tr style={{ textAlign: 'center', verticalAlign: 'middle', height: 30 }}>
              <th style={{ width: '20%' }} className="tbl-header">
                1パンチ丸穴径
              </th>
              <th style={{ width: '20%' }} className="tbl-header">
                1パンチ矩形周長
              </th>
              <th style={{ width: '20%' }} className="tbl-header">
                二ブリングピッチ
              </th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>
                <Input
                  style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                  className={editMode ? 'input-editable ' : 'input-non-editable'}
                  value={punchConditionList?.punchHoleDia}
                  onChange={(event) => changeValue('punchHoleDia', event.target.value)}
                  onKeyPress={(e) => {
                    handleKeyPress(e, false);
                  }}
                />
              </td>
              <td>
                <Input
                  style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                  className={editMode ? 'input-editable ' : 'input-non-editable'}
                  value={punchConditionList?.punchRectanglePer}
                  onChange={(event) => changeValue('punchRectanglePer', event.target.value)}
                  onKeyPress={(e) => {
                    handleKeyPress(e, false);
                  }}
                />
              </td>
              <td>
                <Input
                  style={{ padding: 3, width: '98.2%', height: 26, textAlign: 'center' }}
                  className={editMode ? 'input-editable ' : 'input-non-editable'}
                  value={punchConditionList?.twoPitch}
                  onChange={(event) => changeValue('twoPitch', event.target.value)}
                  onKeyPress={(e) => {
                    handleKeyPress(e, false);
                  }}
                />
              </td>
            </tr>
          </tbody>
        </Table>
      </Row>
    </>
  );
});

export default Device_Blank_PunchCondition;
