/**
 * クラス名：板金の加工時間画面
 * 作成者：チュー
 * 作成日：2023/06/21
 * バージョン：1.0
 */
import React, { useEffect, forwardRef, useState, useRef } from 'react';
import { Table } from 'react-bootstrap';
import { Row, Col, Input, Checkbox, Space, Button, Radio, Popover } from 'antd';
import { PlusCircleFilled, CloseCircleFilled, CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { isEmpty } from '@rc-component/mini-decimal/es/numberUtil';

import {
  JPYs,
  deleteListColData,
  getInputBorderStyle,
  getMaterialTypeById,
  getProcessDetailTypeName,
  handleKeyPress,
  secondsToHms,
  setTimetoSec,
  sortListByMasterData,
  sortListData,
  sortSizeListData,
  updateList,
  updateListColData,
} from '../../../common/Common';
import { commonModal } from '../../../common/CommonModal';
import { souchiHandleIncreaseTime, souchiHandleDecreaseTime } from '../../../common/Common';
import { WorkType } from '../../../common/enums';
const Device_KakouJikan = forwardRef((props, ref) => {
  const [editMode, setEditMode] = useState(false);
  const [category, setCategory] = useState('');
  const [isLength, setIsLength] = useState(false);
  const [isSizeItaatsu, setIsSizeItaatsu] = useState(false);
  const [isMmItaatsu, setIsMmItaatsu] = useState(false);
  const [isSize, setIsSize] = useState(false);
  const [isAnaSize, setIsAnaSize] = useState(false);

  const [areaData, setAreaData] = useState([]);
  const [detailsAreaTemp, setDetailsAreaTemp] = useState([]);
  const [detailsList, setDetailsList] = useState([]);

  const [mode, setMode] = useState('simple');

  // 加工補正時間係数
  // const [correctionTimeFactorList, setCorrectionTimeFactorList] = useState([]);

  // 簡易入力と詳細入力
  const [sizeData, setSizeData] = useState([]);
  const [sizeDataTemp, setSizeDataTemp] = useState([]);
  const [anaSizeData, setAnaSizeData] = useState([]);
  const [anaSizeDataTemp, setAnaSizeDataTemp] = useState([]);
  const [kaniListData, setKaniListData] = useState([]);
  const [kaniAnaListData, setKaniAnaListData] = useState([]);

  // サイズ板厚マスタ
  const [sizeItaatsu, setSizeItaatsu] = useState([]);
  const [sizeItaatsuTemp, setSizeItaatsuTemp] = useState([]);

  // mm板厚マスタ
  const [mmItaatsu, setMmItaatsu] = useState([]);
  const [mmItaatsuTemp, setMmItaatsuTemp] = useState([]);

  // 材質区分
  const [zaishitsuData, setZaishitsuData] = useState([]);

  const [btnEnable, setBtnEnable] = useState(true);

  // One by one
  const [flag, setFlag] = useState(false);

  const [cutTime, setCutTime] = useState(0);

  // 工程マスタレコード情報
  const [processMstRecord, setProcessMstRecord] = useState();
  // 入力チェック
  const [errIndexVal, setErrIndexVal] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const inputRef = useRef([]);
  useEffect(() => {
    setErrIndexVal([]);
    setErrorMessage('');
    setEditMode(props.editMode);
    // setCategory(props.processName);
    setCategory(props.processType);

    if (props?.processMstRecord) {
      setProcessMstRecord(props?.processMstRecord);
    }
    if (props?.materialTypes) {
      setZaishitsuData(props?.materialTypes);
    }

    // 自動バリの加工時間
    if (props?.detailsData?.areaThMaster) {
      setAreaData(props?.detailsData?.areaThMaster);
      setDetailsAreaTemp(props?.detailsData?.areaThMaster);
    }
    if (props?.detailsData?.workTimeThItems) {
      setDetailsList(props?.detailsData?.workTimeThItems);
    }
    // サイズマスタ
    if (props?.detailsData?.sizeThMaster) {
      setSizeData(props?.detailsData?.sizeThMaster);
      setSizeDataTemp(props?.detailsData?.sizeThMaster);
    }
    // サイズ簡易入力リスト
    setKaniListData(props?.detailsData?.workTimeSizeThItems);
    // mmマスタ
    if (props?.detailsData?.mmThMaster) {
      setAnaSizeData(props?.detailsData?.mmThMaster);
      setAnaSizeDataTemp(props?.detailsData?.mmThMaster);
    }
    // mm簡易入力リスト
    setKaniAnaListData(props?.detailsData?.workTimeSizeHoleThItems);

    // サイズ板厚マスタ
    if (props?.detailsData?.sizeThicknessThMaster) {
      setSizeItaatsu(props?.detailsData?.sizeThicknessThMaster);
      setSizeItaatsuTemp(props?.detailsData?.sizeThicknessThMaster);
    }

    // mm板厚マスタ
    if (props?.detailsData?.mmThicknessThMaster) {
      setMmItaatsu(props?.detailsData?.mmThicknessThMaster);
      setMmItaatsuTemp(props?.detailsData?.mmThicknessThMaster);
    }

    // 1カット時間
    setCutTime(props?.detailsData?.cutTime);
    // one by one
    setFlag(props?.detailsData?.isOneCut);
    // setCorrectionTimeFactorList(props.correctionTimeFactorListData);
  }, [
    props.detailsData,
    props.editMode,
    props.processType,
    // props.area,
    props.listData,
    props.iq3SizeMaster,
    props.iq3KaniListData,
    props.mmMaster,
    props.iq3KaniAnaListData,
    props.itaatsu1,
    props.zaishitsu,
    props.cutTime,
    props.shirringflag,
    props?.processMstRecord,
    // props.correctionTimeFactorListData,
  ]);

  const addLength = () => {
    setIsLength(true);
  };

  // サイズ板厚ボタン押下
  const sizeThickness = () => {
    setErrIndexVal([]);
    setErrorMessage('');
    setIsSizeItaatsu(true);
  };

  // mm板厚ボタン押下
  const mmThickness = () => {
    setErrIndexVal([]);
    setErrorMessage('');
    setIsMmItaatsu(true);
  };

  // サイズボタン押下
  const size = () => {
    setErrIndexVal([]);
    setErrorMessage('');
    setIsSize(true);
  };

  // mmボタン押下
  const anaSize = () => {
    setErrIndexVal([]);
    setErrorMessage('');
    setIsAnaSize(true);
  };

  const addLengthModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>
        cm<sup>2</sup>
      </p>
    </div>
  );

  const addSizeModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>サイズ</p>
    </div>
  );

  const addAnaSizeModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>mm</p>
    </div>
  );

  const addThicknessModalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>板厚</p>
    </div>
  );

  const closeAddModal = () => {
    if (isLength === true) {
      setIsLength(false);
    } else if (isSize === true) {
      setIsSize(false);
    } else if (isSizeItaatsu === true) {
      setIsSizeItaatsu(false);
    } else if (isMmItaatsu === true) {
      setIsMmItaatsu(false);
    } else if (isAnaSize === true) {
      setIsAnaSize(false);
    }
  };

  const handleAreaChange = (event, id, field) => {
    const updatedData = detailsAreaTemp?.map((row) => {
      if (row.no === id) {
        return { ...row, [field]: event.target.value };
      }
      return row;
    });
    setDetailsAreaTemp(updatedData);
  };

  const addAreaRow = (id) => {
    const copyData = detailsAreaTemp.slice(); // Create a copy of the original array

    const insertIndex = detailsAreaTemp?.findIndex((item) => item.no === id); // Find the index of the item with id 2

    if (insertIndex !== -1) {
      const data = {
        no: detailsAreaTemp?.length + 1,
        value: 0,
        isOutOfRange: false,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setDetailsAreaTemp(copyData);
    }
  };

  const deleteArea = (id, e) => {
    if (detailsAreaTemp?.filter((item) => item.isOutOfRange === false)?.length > 2) {
      const updatedData = detailsAreaTemp?.map((row) => {
        if (row.no === id) {
          return { ...row, isOutOfRange: true };
        }
        return row;
      });
      setDetailsAreaTemp(updatedData);
    }
  };

  const addAreaMaster = () => {
    const deletedId = [];

    areaData?.forEach((item1, index) => {
      const found = detailsAreaTemp?.find((item2) => item2.no === item1.no && item2.isOutOfRange === true);
      if (found) {
        deletedId.push(item1.no);
      }
    });

    const newId = detailsAreaTemp
      ?.filter((item) => !item.isOutOfRange && !areaData.some((data2Item) => data2Item.no === item.no))
      ?.map((item) => item.no);

    const deleteData = deleteListColData(detailsList, deletedId);
    // const deleteCorrectionTimeFactorList = deleteListColData(correctionTimeFactorList, deletedId);

    const updatedData = updateListColData(deleteData, newId);
    // const updatecorrectionTimeFactorListData = updateListColData(deleteCorrectionTimeFactorList, newId);

    const sortedList = sortListData(updatedData, detailsAreaTemp);
    // const sortedCorrectionTimeFactorList = sortSizeListData(updatecorrectionTimeFactorListData, detailsAreaTemp);

    setIsLength(false);
    setAreaData(detailsAreaTemp);
    props.updatedArea(detailsAreaTemp);
    setDetailsList(sortedList);
    props.updatedIQ3KakouJikanList(sortedList);

    // setCorrectionTimeFactorList(sortedCorrectionTimeFactorList);
    // props.updatedcorrectionTimeFactorListData(sortedCorrectionTimeFactorList);
  };

  const cancelMasterData = () => {
    if (isLength === true) {
      setDetailsAreaTemp(areaData);
      setIsLength(false);
    } else if (isSize === true) {
      setIsSize(false);
      setSizeDataTemp(sizeData);
    } else if (isSizeItaatsu === true) {
      setIsSizeItaatsu(false);
      setSizeItaatsuTemp(sizeItaatsu);
    } else if (isMmItaatsu === true) {
      setIsMmItaatsu(false);
      setMmItaatsuTemp(mmItaatsu);
    } else if (isAnaSize === true) {
      setAnaSizeDataTemp(anaSizeData);
      setIsAnaSize(false);
    }
  };

  const addData = () => {};
  const isValueOrderCorrect = (no, tblName) => {
    let temp = [];
    if (tblName === 'anasize') {
      temp = anaSizeDataTemp?.filter((i) => i.isOutOfRange === false);
    } else if (tblName === 'size') {
      temp = sizeDataTemp?.filter((i) => i.isOutOfRange === false);
    } else if (tblName === 'sizeItaatsu') {
      temp = sizeItaatsuTemp?.filter((i) => i.isOutOfRange === false);
    } else if (tblName === 'mmItaatsu') {
      temp = mmItaatsuTemp?.filter((i) => i.isOutOfRange === false);
    }

    const insertIndex = temp?.findIndex((item) => item.no === no);

    if (insertIndex > 0) {
      const prevValue = parseFloat(temp[insertIndex - 1].value);
      const currentValue = parseFloat(temp[insertIndex].value);
      const nextValue = parseFloat(temp[insertIndex + 1].value);
      if (currentValue > prevValue && currentValue < nextValue) {
        let temp = [insertIndex - 1, insertIndex, insertIndex + 1];

        setErrIndexVal(errIndexVal?.filter((item) => item !== insertIndex && item != insertIndex - 1));

        setErrorMessage('前のしきい値より大きい値を入力してください！');
      } else {
        setErrIndexVal([...errIndexVal, insertIndex]);

        if (currentValue <= prevValue) {
          setErrorMessage('前のしきい値より大きい値を入力してください！');
        } else if (currentValue >= nextValue) {
          setErrorMessage('次のしきい値より小さい値を入力してください！');
        }
      }
    } else {
      const currentValue = parseFloat(temp[insertIndex].value);
      const nextValue = parseFloat(temp[insertIndex + 1].value);

      if (currentValue < nextValue) {
        setErrIndexVal(errIndexVal?.filter((item) => item !== insertIndex));
      } else {
        setErrIndexVal([...errIndexVal, insertIndex]);
        setErrorMessage('次のしきい値より小さい値を入力してください！');
      }
    }
  };
  const addLengthContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>しきい値</label>（≦）
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {detailsAreaTemp
                  ?.filter((subitem) => subitem.isOutOfRange === false)
                  ?.map((item, index) => (
                    <tr key={index} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        <Input
                          value={item.value}
                          onChange={(e) => handleAreaChange(e, item.no, 'value')}
                          style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addAreaRow(item.no)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteArea(item.no, e)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button className={isEmpty(errIndexVal) ? 'mainButton' : 'disabledButton'} id="update" onClick={addAreaMaster}>
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} onClick={cancelMasterData}>
          キャンセル
        </Button>
      </Row>
    </>
  );

  // サイズマスタの値を変更した場合、
  const handleSizeChange = (event, no, field) => {
    const updatedData = sizeDataTemp?.map((row) => {
      if (row.no === no) {
        return { ...row, [field]: event.target.value };
      }
      return row;
    });
    setSizeDataTemp(updatedData);
  };

  // mmマスタの値を変更した場合、
  const handleAnaSizeChange = (event, no, field) => {
    const updatedData = anaSizeDataTemp?.map((row) => {
      if (row.no === no) {
        return { ...row, [field]: event.target.value };
      }
      return row;
    });
    setAnaSizeDataTemp(updatedData);
  };

  // サイズマスタの行追加を押下
  const addSize = (no, index) => {
    inputRef.current[index].focus();
    const copyData = sizeDataTemp?.slice(); // Create a copy of the original array

    const insertIndex = sizeDataTemp?.findIndex((item) => item.no === no); // Find the index of the item with id 2

    if (insertIndex !== -1) {
      const data = {
        no: sizeDataTemp?.length + 1,
        value: 0,
        isOutOfRange: false,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setSizeDataTemp(copyData);
    }
  };

  // mmマスタの行追加を押下
  const addAnaSize = (no, index) => {
    inputRef.current[index].focus();
    const copyData = anaSizeDataTemp?.slice(); // Create a copy of the original array

    const insertIndex = anaSizeDataTemp?.findIndex((item) => item.no === no); // Find the index of the item with id 2

    if (insertIndex !== -1) {
      const data = {
        no: anaSizeDataTemp?.length + 1,
        value: 0,
        isOutOfRange: false,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      setAnaSizeDataTemp(copyData);
    }
  };

  // サイズマスタの行削除を押下
  const deleteSize = (no, e, index) => {
    // 更新するエラーインデックスを得る
    const updatedErrorIndexes = errIndexVal?.filter((item) => item !== index);

    const finalErrorIndexes = updatedErrorIndexes?.map((item) => (item > index ? item - 1 : item));
    setErrIndexVal(finalErrorIndexes);
    if (sizeDataTemp?.filter((item) => !item.isOutOfRange)?.length > 2) {
      const updatedData = sizeDataTemp?.map((row) => {
        if (row.no === no) {
          return { ...row, isOutOfRange: true };
        }
        return row;
      });
      setSizeDataTemp(updatedData);
      const filterData = updatedData?.filter((i) => !i.isOutOfRange);
      if (index + 1 !== filterData?.length) {
        inputRef.current[index].focus();
      }
    }
  };

  // mmマスタの行削除を押下
  const deleteAnaSize = (no, e, index) => {
    // 更新するエラーインデックスを得る
    const updatedErrorIndexes = errIndexVal?.filter((item) => item !== index);

    const finalErrorIndexes = updatedErrorIndexes?.map((item) => (item > index ? item - 1 : item));
    setErrIndexVal(finalErrorIndexes);
    if (anaSizeDataTemp?.filter((item) => !item.isOutOfRange)?.length > 2) {
      const updatedData = anaSizeDataTemp?.map((row) => {
        if (row.no === no) {
          return { ...row, isOutOfRange: true };
        }
        return row;
      });
      setAnaSizeDataTemp(updatedData);
      const filterData = updatedData?.filter((i) => !i.isOutOfRange);
      if (index + 1 !== filterData?.length) {
        inputRef.current[index].focus();
      }
    }
  };

  // 板厚リストの詳細情報を削除する
  const deleteDetailListData = (data, deletedId) => {
    const updatedData = { ...data };

    Object.values(data)?.forEach((item) => {
      item?.forEach((subitem) => {
        Object.entries(subitem?.workTimeThickThItems)?.forEach(([listKey, listItem]) => {
          if (subitem?.workTimeThickThItems && listItem) {
            // listItem = deleteListColData(listItem, deletedId);
            subitem.workTimeThickThItems[listKey] = deleteListColData(listItem, deletedId);
          }
        });
      });
    });

    return data;
  };

  // 板厚リストの詳細情報を更新する
  const updateDetailListData = (deletedData, newId) => {
    // const updatedData = JSON.parse(JSON.stringify(deletedData)); // Create a deep copy of the data array

    // updatedData?.forEach((item) => {
    //   item.lstData?.forEach((i) => {
    //     i.list?.forEach((j) => {
    //       if (j.lstData) {
    //         newId?.forEach((key) => {
    //           // Add new objects to lstData
    //           j.lstData.push({
    //             key: key,
    //             value: '',
    //           });
    //         });
    //       }
    //     });
    //   });
    // });

    const updatedData = { ...deletedData };

    Object.values(updatedData)?.forEach((item) => {
      item?.forEach((subitem) => {
        Object.values(subitem?.workTimeThickThItems)?.forEach((listItem) => {
          if (listItem) {
            listItem = updateListColData(listItem, newId);
          }
        });
      });
    });

    return updatedData;
  };

  // 板厚リストの詳細情報を並び替え
  const sortDetailListData = (list, masterData) => {
    // const sortedList = JSON.parse(JSON.stringify(list));

    // sortedList?.forEach((item) => {
    //   item.lstData?.forEach((i) => {
    //     i.list?.forEach((j) => {
    //       if (j.lstData) {
    //         j.lstData.sort((a, b) => {
    //           const aIndex = masterData?.findIndex((obj) => obj.id === a.key);
    //           const bIndex = masterData?.findIndex((obj) => obj.id === b.key);
    //           return aIndex - bIndex;
    //         });
    //       }
    //     });
    //   });
    // });

    const sortedList = { ...list };

    Object.values(sortedList)?.forEach((item) => {
      item?.forEach((subitem) => {
        Object.values(subitem?.workTimeThickThItems)?.forEach((listItem) => {
          if (listItem) {
            listItem = sortListData(listItem, masterData);
          }
        });
      });
    });

    return sortedList;
  };

  // 詳細情報を作成する
  const createDetailListData = (zaishitsuMstData, itaatsuMstData) => {
    const filteredData2 = itaatsuMstData
      ?.filter((subitem) => !subitem.isOutOfRange)
      ?.map((item) => {
        return {
          no: item.no,
          value: 0,
          isOutOfRange: false,
        };
      });
    const result = {};
    zaishitsuMstData?.map((materialType) => {
      result[materialType.id] = filteredData2;
    });
    return { workTimeThickThItems: result };
  };

  // サイズマスタのOKボタン押下
  const okSizeMaster = () => {
    const deletedId = [];

    sizeData?.forEach((item1, index) => {
      const found = sizeDataTemp?.find((item2) => item2.no === item1.no && item2.isOutOfRange);
      if (found) {
        deletedId.push(item1.no);
      }
    });

    const newId = sizeDataTemp
      ?.filter((item) => !item.isOutOfRange && !sizeData?.some((data2Item) => data2Item.no === item.no))
      ?.map((item) => item.no);

    // const deletedData = deleteListData(kaniListData, deletedId);
    const detailsData = createDetailListData(zaishitsuData, sizeItaatsu);
    // const updatedData = updateListData(deletedData, newId, detailsData);

    // const sortedList = sortListData(updatedData, sizeDataTemp);

    const updatedData = updateList(kaniListData, deletedId, newId, detailsData);
    const sortedList = sortListByMasterData(updatedData, sizeDataTemp);

    setIsSize(false);
    setSizeData(sizeDataTemp);
    props.updatediq3SizeMaster(sizeDataTemp);

    setKaniListData(sortedList);
    props.updatediq3KaniListData(sortedList);
  };

  // mmマスタのOKボタン押下
  const okAnaSizeMaster = () => {
    const deletedId = [];

    anaSizeData?.forEach((item1, index) => {
      const found = anaSizeDataTemp?.find((item2) => item2.no === item1.no && item2.isOutOfRange);
      if (found) {
        deletedId.push(item1.no);
      }
    });

    const newId = anaSizeDataTemp
      ?.filter((item) => !item.isOutOfRange && !anaSizeData?.some((data2Item) => data2Item.no === item.no))
      ?.map((item) => item.no);

    // const deletedData = deleteListData(kaniAnaListData, deletedId);
    const detailsData = createDetailListData(zaishitsuData, mmItaatsu);
    // const updatedData = updateListData(deletedData, newId, detailsData);
    // const sortedList = sortListData(updatedData, anaSizeDataTemp);

    const updatedData = updateList(kaniAnaListData, deletedId, newId, detailsData);
    const sortedList = sortListByMasterData(updatedData, anaSizeDataTemp);

    setIsAnaSize(false);
    setAnaSizeData(anaSizeDataTemp);
    props.updateSizeMmMaster(anaSizeDataTemp);

    setKaniAnaListData(sortedList);
    props.updateiq3KaniAnaListData(sortedList);
  };

  // サイズマスタ
  const addSizeContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>しきい値</label>（≦）
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {sizeDataTemp
                  ?.filter((subitem) => !subitem.isOutOfRange)
                  ?.map((item, index) => (
                    <tr key={index} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        {errIndexVal?.includes(index) ? (
                          <Popover
                            content={<div style={{ padding: 7 }}>{errorMessage}</div>}
                            placement="left"
                            visible={true}
                          ></Popover>
                        ) : (
                          ''
                        )}
                        <Input
                          value={item.value}
                          onChange={(e) => handleSizeChange(e, item.no, 'value')}
                          onBlur={() => isValueOrderCorrect(item.no, 'size')}
                          className={errIndexVal?.includes(index) ? 'red-border' : ''}
                          style={{
                            ...getInputBorderStyle(index, sizeDataTemp),
                            pointerEvents: editMode ? 'auto' : 'none',
                          }}
                          ref={(ref) => (inputRef.current[index] = ref)}
                          onKeyPress={(e) => {
                            handleKeyPress(e, false);
                          }}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addSize(item.no, index + 1)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteSize(item.no, e, index)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button className={isEmpty(errIndexVal) ? 'mainButton' : 'disabledButton'} id="update" onClick={okSizeMaster}>
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} id="discard" onClick={cancelMasterData}>
          キャンセル
        </Button>
      </Row>
    </>
  );

  // mmマスタ
  const addAnaSizeContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>しきい値</label>（≦）
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {anaSizeDataTemp
                  ?.filter((subitem) => !subitem.isOutOfRange)
                  ?.map((item, index) => (
                    <tr key={index} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        {errIndexVal?.includes(index) ? (
                          <Popover
                            content={<div style={{ padding: 7 }}>{errorMessage}</div>}
                            placement="left"
                            visible={true}
                          ></Popover>
                        ) : (
                          ''
                        )}
                        <Input
                          value={item.value}
                          onChange={(e) => handleAnaSizeChange(e, item.no, 'value')}
                          onBlur={() => isValueOrderCorrect(item.no, 'anasize')}
                          className={errIndexVal?.includes(index) ? 'red-border' : ''}
                          style={{
                            ...getInputBorderStyle(index, anaSizeDataTemp),
                            pointerEvents: editMode ? 'auto' : 'none',
                          }}
                          ref={(ref) => (inputRef.current[index] = ref)}
                          onKeyPress={(e) => {
                            handleKeyPress(e, false);
                          }}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addAnaSize(item.no, index + 1)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteAnaSize(item.no, e, index)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button
          className={isEmpty(errIndexVal) ? 'mainButton' : 'disabledButton'}
          id="update"
          onClick={okAnaSizeMaster}
        >
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} id="discard" onClick={cancelMasterData}>
          キャンセル
        </Button>
      </Row>
    </>
  );

  // 板厚マスタの値を変更した場合、
  const handleItaatsuChange = (event, no, field) => {
    let temp = isSizeItaatsu ? sizeItaatsuTemp : mmItaatsuTemp;
    const updatedData = temp?.map((row) => {
      if (row.no === no) {
        return { ...row, [field]: event.target.value };
      }
      return row;
    });
    isSizeItaatsu ? setSizeItaatsuTemp(updatedData) : setMmItaatsuTemp(updatedData);
  };

  // 板厚マスタの行追加を押下
  const addItaatsu = (no, index) => {
    inputRef.current[index].focus();
    let temp = isSizeItaatsu ? sizeItaatsuTemp : mmItaatsuTemp;
    const copyData = temp?.slice(); // Create a copy of the original array

    const insertIndex = temp?.findIndex((item) => item.no === no); // Find the index of the item with id 2

    if (insertIndex !== -1) {
      const data = {
        no: temp?.length + 1,
        value: 0,
        isOutOfRange: false,
      };
      copyData.splice(insertIndex + 1, 0, data); // Insert the new object after the found index
      isSizeItaatsu ? setSizeItaatsuTemp(copyData) : setMmItaatsuTemp(copyData);
    }
  };

  // 板厚マスタの行削除を押下
  const deleteItaatsu = (no, e, index) => {
    // 更新するエラーインデックスを得る
    const updatedErrorIndexes = errIndexVal?.filter((item) => item !== index);

    const finalErrorIndexes = updatedErrorIndexes?.map((item) => (item > index ? item - 1 : item));
    setErrIndexVal(finalErrorIndexes);

    let temp = isSizeItaatsu ? sizeItaatsuTemp : mmItaatsuTemp;
    if (temp?.filter((item) => !item.isOutOfRange)?.length > 2) {
      const updatedData = temp?.map((row) => {
        if (row.no === no) {
          return { ...row, isOutOfRange: true };
        }
        return row;
      });
      isSizeItaatsu ? setSizeItaatsuTemp(updatedData) : setMmItaatsuTemp(updatedData);
      const filterData = updatedData?.filter((i) => !i.isOutOfRange);
      if (index + 1 !== filterData?.length) {
        inputRef.current[index].focus();
      }
    }
  };

  // 板厚マスタのOKボタン押下
  const okItaatsuMaster = () => {
    setBtnEnable(false);
    const deletedId = [];

    if (isSizeItaatsu) {
      sizeItaatsu?.forEach((item1, index) => {
        const found = sizeItaatsuTemp?.find((item2) => item2.no === item1.no && item2.isOutOfRange);
        if (found) {
          deletedId.push(item1.no);
        }
      });

      const newId = sizeItaatsuTemp
        ?.filter((item) => !item.isOutOfRange && !sizeItaatsu?.some((data2Item) => data2Item.no === item.no))
        ?.map((item) => item.no);

      const deletedKaniData = deleteDetailListData(kaniListData, deletedId);

      const updatedKaniData = updateDetailListData(deletedKaniData, newId);

      const sortedKaniList = sortDetailListData(updatedKaniData, sizeItaatsuTemp);

      setIsSizeItaatsu(false);
      setSizeItaatsu(sizeItaatsuTemp);
      props.updatedSizeItaatsu(sizeItaatsuTemp);

      setKaniListData(sortedKaniList);
      props.updatediq3KaniListData(sortedKaniList);
    } else {
      mmItaatsu?.forEach((item1, index) => {
        const found = mmItaatsuTemp?.find((item2) => item2.no === item1.no && item2.isOutOfRange);
        if (found) {
          deletedId.push(item1.no);
        }
      });

      const newId = mmItaatsuTemp
        ?.filter((item) => !item.isOutOfRange && !mmItaatsu?.some((data2Item) => data2Item.no === item.no))
        ?.map((item) => item.no);

      const deletedAnaData = deleteDetailListData(kaniAnaListData, deletedId);

      const updatedAnaData = updateDetailListData(deletedAnaData, newId);

      const sortedAnaList = sortDetailListData(updatedAnaData, mmItaatsuTemp);

      setIsMmItaatsu(false);
      setMmItaatsu(mmItaatsuTemp);
      props.updatedMmItaatsu(mmItaatsuTemp);
      setKaniAnaListData(sortedAnaList);
      props.updateiq3KaniAnaListData(sortedAnaList);
    }
  };

  // サイズ板厚マスタ
  const addSizeItaatsuContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>しきい値</label>（≦）
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {sizeItaatsuTemp
                  ?.filter((subitem) => !subitem.isOutOfRange)
                  ?.map((item, index) => (
                    <tr key={index} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        {errIndexVal?.includes(index) ? (
                          <Popover
                            content={<div style={{ padding: 7 }}>{errorMessage}</div>}
                            placement="left"
                            visible={true}
                          ></Popover>
                        ) : (
                          ''
                        )}
                        <Input
                          value={item.value}
                          onChange={(e) => handleItaatsuChange(e, item.no, 'value')}
                          onBlur={() => isValueOrderCorrect(item.no, 'sizeItaatsu')}
                          className={errIndexVal?.includes(index) ? 'red-border' : ''}
                          style={{
                            ...getInputBorderStyle(index, sizeItaatsuTemp),
                            pointerEvents: editMode ? 'auto' : 'none',
                          }}
                          onKeyPress={(e) => {
                            handleKeyPress(e, false);
                          }}
                          ref={(ref) => (inputRef.current[index] = ref)}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addItaatsu(item.no, index + 1)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteItaatsu(item.no, e, index)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button
          className={isEmpty(errIndexVal) ? 'mainButton' : 'disabledButton'}
          id="update"
          onClick={okItaatsuMaster}
        >
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} id="discard" onClick={cancelMasterData}>
          キャンセル
        </Button>
      </Row>
    </>
  );

  // mm板厚マスタ
  const addMmItaatsuContent = (
    <>
      <div style={{ height: 400, overflow: 'auto' }}>
        <Row className="chargeAmount registerModal">
          <Col style={{ width: '100%' }}>
            <Table className="master-table">
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    No
                  </th>

                  <th style={{ width: '60%' }} className="tbl-header">
                    <Space>
                      <label>しきい値</label>（≦）
                    </Space>
                  </th>
                </tr>
              </thead>
              <tbody>
                {mmItaatsuTemp
                  ?.filter((subitem) => !subitem.isOutOfRange)
                  ?.map((item, index) => (
                    <tr key={index} style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                      <td>{index + 1}</td>

                      <td>
                        {errIndexVal?.includes(index) ? (
                          <Popover
                            content={<div style={{ padding: 7 }}>{errorMessage}</div>}
                            placement="left"
                            visible={true}
                          ></Popover>
                        ) : (
                          ''
                        )}
                        <Input
                          value={item.value}
                          onChange={(e) => handleItaatsuChange(e, item.no, 'value')}
                          onBlur={() => isValueOrderCorrect(item.no, 'mmItaatsu')}
                          className={errIndexVal?.includes(index) ? 'red-border' : ''}
                          style={{
                            ...getInputBorderStyle(index, mmItaatsuTemp),
                            pointerEvents: editMode ? 'auto' : 'none',
                          }}
                          onKeyPress={(e) => {
                            handleKeyPress(e, false);
                          }}
                          ref={(ref) => (inputRef.current[index] = ref)}
                        ></Input>
                      </td>
                      <td
                        style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '20%' }}
                        className="min"
                      >
                        <Row justify="center">
                          <PlusCircleFilled
                            onClick={() => addItaatsu(item.no, index + 1)}
                            className="add-remove-icon"
                            style={{ marginLeft: 5, marginRight: 5, pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                          <CloseCircleFilled
                            className="add-remove-icon"
                            onClick={(e) => deleteItaatsu(item.no, e, index)}
                            style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                          />
                        </Row>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
      <Row justify="center" className="mt-2">
        <Button
          className={isEmpty(errIndexVal) ? 'mainButton' : 'disabledButton'}
          id="update"
          onClick={okItaatsuMaster}
        >
          OK
        </Button>
        <Button className="cancelButton" style={{ marginLeft: 10 }} id="discard" onClick={cancelMasterData}>
          キャンセル
        </Button>
      </Row>
    </>
  );

  const changeKakouJikan = (event, no) => {
    // const copyData = [...detailsList];
    // const updatedData = JSON.parse(JSON.stringify(copyData));
    // updatedData[rowIndex].lstData[colIndex].value = event;
    // setDetailsList(updatedData);
    // props.updatedIQ3KakouJikanList(updatedData);
    detailsList
      ?.filter((item) => item.no === no)
      ?.map((list) => {
        list.value = event;
      });
    setDetailsList(detailsList);
    props.updatedIQ3KakouJikanList(detailsList);
  };

  const handleModeChange = (e) => {
    setMode(e.target.value);
  };

  // サイズ／mmのリスト値を変更した場合、
  const listDataChange = (value, rowIndex, colIndex, tblName) => {
    let updatedData;
    if (tblName === 'size') {
      updatedData = { ...kaniListData };
    } else if (tblName === 'mm') {
      updatedData = { ...kaniAnaListData };
    }
    Object.entries(updatedData)
      ?.find(([rowKey]) => parseInt(rowKey) === parseInt(rowIndex))?.[1]
      ?.map((colValue) => {
        if (colValue && colValue.no === colIndex) {
          colValue.value = setTimetoSec(value);
        }
      });
    if (tblName === 'size') {
      setKaniListData(updatedData);
      props.updatediq3KaniListData(updatedData);
    } else if (tblName === 'mm') {
      setKaniAnaListData(updatedData);
      props.updateiq3KaniAnaListData(updatedData);
    }
  };

  const handleInputChange = (event, rowIndex, colIndex) => {
    const copyData = { ...kaniListData };
    const updatedData = JSON.parse(JSON.stringify(copyData));
    updatedData[rowIndex].lstData[colIndex].value = event.target.value;
    setKaniListData(updatedData);
    props.updatediq3KaniListData(updatedData);
  };

  // 板厚リスト値を変更した場合、
  const itaatsulistDataChange = (value, rowIndex, colIndex, subrowIndex, subcolIndex, tblName) => {
    setBtnEnable(false);
    let updatedData;
    if (tblName === 'size') {
      updatedData = { ...kaniListData };
    } else if (tblName === 'mm') {
      updatedData = { ...kaniAnaListData };
    }
    Object.entries(updatedData)
      ?.find(([rowKey]) => parseInt(rowKey) === parseInt(rowIndex))?.[1]
      ?.map((colValue) => {
        if (colValue.no === colIndex) {
          Object.entries(colValue?.workTimeThickThItems)
            ?.find(([subrowKey]) => parseInt(subrowKey) === parseInt(subrowIndex))?.[1]
            ?.map((subcolValue) => {
              if (subcolValue && subcolValue.no === subcolIndex) {
                subcolValue.value = setTimetoSec(value);
              }
            });
        }
      });
    if (tblName === 'size') {
      setKaniListData(updatedData);
      props.updatediq3KaniListData(updatedData);
    } else if (tblName === 'mm') {
      setKaniAnaListData(updatedData);
      props.updateiq3KaniAnaListData(updatedData);
    }
  };

  // One by oneを変更した場合、
  const changeflag = (e) => {
    const isChecked = e.target.checked;
    setFlag(isChecked);
    props.updateShirringFlag(e.target.checked);
  };

  // 1カット時間を変更した場合、
  const changeTime = (e) => {
    setCutTime(setTimetoSec(e));
    props.updateCutTime(setTimetoSec(e));
  };

  return (
    <>
      {category === WorkType.SmShearing ? (
        // シャーリング
        <>
          <Row className="chargeAmount" style={{ marginLeft: 10, marginRight: 10 }}>
            <Table style={{ width: '40%' }}>
              <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '20%' }} className="tbl-header">
                    1カット時間
                  </th>
                  <th style={{ width: '20%' }} className="tbl-header">
                    One by one
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ textAlign: 'center' }}>
                  <td>
                    <Row align="middle">
                      {!editMode ? (
                        <>
                          <Col span={24}>
                            <label>{secondsToHms(cutTime)}</label>
                          </Col>
                        </>
                      ) : (
                        <>
                          <Col span={10}>
                            <Input
                              value={secondsToHms(cutTime)}
                              onChange={(e) => {
                                setCutTime(e.target.value);
                              }}
                            ></Input>
                          </Col>
                          <Col span={14}>
                            <CaretUpOutlined
                              style={{
                                fontSize: 16 + 'px',
                                marginLeft: 3,
                                pointerEvents: editMode ? 'auto' : 'none',
                              }}
                              onClick={() => {
                                let editedTime = souchiHandleIncreaseTime(secondsToHms(cutTime), '10min');
                                changeTime(editedTime);
                              }}
                            />
                            <CaretDownOutlined
                              style={{
                                fontSize: 17 + 'px',
                                color: '#000',
                                pointerEvents: editMode ? 'auto' : 'none',
                              }}
                              onClick={() => {
                                let editedTime = souchiHandleDecreaseTime(secondsToHms(cutTime), '10min');
                                changeTime(editedTime);
                              }}
                            />
                            <CaretUpOutlined
                              style={{
                                fontSize: 17 + 'px',
                                color: '#000',
                                pointerEvents: editMode ? 'auto' : 'none',
                              }}
                              onClick={() => {
                                let editedTime = souchiHandleIncreaseTime(secondsToHms(cutTime), 'min');
                                changeTime(editedTime);
                              }}
                            />
                            <CaretDownOutlined
                              style={{
                                fontSize: 17 + 'px',
                                color: '#000',
                                pointerEvents: editMode ? 'auto' : 'none',
                              }}
                              onClick={() => {
                                let editedTime = souchiHandleDecreaseTime(secondsToHms(cutTime), 'min');
                                changeTime(editedTime);
                              }}
                            />
                            <CaretUpOutlined
                              style={{
                                fontSize: 17 + 'px',
                                color: '#000',
                                pointerEvents: editMode ? 'auto' : 'none',
                              }}
                              onClick={() => {
                                let editedTime = souchiHandleIncreaseTime(cutTime, 'sec');
                                changeTime(editedTime);
                              }}
                            />
                            <CaretDownOutlined
                              style={{
                                fontSize: 17 + 'px',
                                color: '#000',
                                pointerEvents: editMode ? 'auto' : 'none',
                              }}
                              onClick={() => {
                                let editedTime = souchiHandleDecreaseTime(cutTime, 'sec');
                                changeTime(editedTime);
                              }}
                            />
                          </Col>
                        </>
                      )}
                    </Row>
                  </td>
                  <td>
                    <Checkbox
                      style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                      checked={flag}
                      onChange={changeflag}
                    ></Checkbox>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Row>
        </>
      ) : category === WorkType.SmDeburringAuto ? (
        <>
          <Row className="table-container" style={{ marginLeft: 10, marginRight: 10 }}>
            <Table
              style={{
                width:
                  areaData?.filter((i) => i.isOutOfRange === false)?.length > 4
                    ? '100%'
                    : areaData?.filter((i) => i.isOutOfRange === false)?.length * 210 + 196 + 'px',
              }}
            >
              <tr style={{ color: 'white', textAlign: 'center', height: 40 }}>
                <td style={{ width: '196px' }} className="fixedCol">
                  <Row justify="center" className="tbl-header">
                    {editMode ? (
                      <>
                        <Space>
                          <label>加工時間</label>
                          <label>/</label>
                          <Button className="cancleButton" onClick={addLength} style={{ pointerEvents: 'auto' }}>
                            cm<sup>2</sup>
                          </Button>
                        </Space>
                      </>
                    ) : (
                      <>
                        <Space>
                          <label>加工時間</label>
                          <label>/</label>
                          <label>
                            cm<sup>2</sup>
                          </label>
                        </Space>
                      </>
                    )}
                  </Row>
                </td>

                {areaData
                  ?.filter((subitem) => subitem.isOutOfRange === false)
                  ?.map((item) => (
                    <td style={{ backgroundColor: '#1063aa', width: '210px' }}>～{item.value}</td>
                  ))}
              </tr>

              <tr style={{ textAlign: 'center' }}>
                <td style={{ width: '196px', color: 'white' }} className="tbl-header fixedCol">
                  加工時間
                </td>
                {detailsList?.map((item, index) => (
                  <td key={index} style={{ verticalAlign: 'middle', textAlign: 'center', width: 210 }}>
                    <Row align="middle">
                      {!editMode ? (
                        <>
                          <Col span={24}>
                            <label>{secondsToHms(item.value)}</label>
                          </Col>
                        </>
                      ) : (
                        <>
                          <Col span={10}>
                            <Input
                              value={secondsToHms(item.value)}
                              onChange={(e) => changeKakouJikan(setTimetoSec(e.target.value), item.no)}
                            ></Input>
                          </Col>
                          <Col span={14}>
                            <CaretUpOutlined
                              style={{
                                fontSize: 16 + 'px',
                                marginLeft: 3,
                                pointerEvents: 'auto',
                              }}
                              onClick={() => {
                                let editedTime = souchiHandleIncreaseTime(secondsToHms(item.value), '10min');

                                changeKakouJikan(setTimetoSec(editedTime), item.no);
                              }}
                            />
                            <CaretDownOutlined
                              style={{
                                fontSize: 17 + 'px',
                                color: '#000',
                                pointerEvents: 'auto',
                              }}
                              onClick={() => {
                                let editedTime = souchiHandleDecreaseTime(secondsToHms(item.value), '10min');
                                changeKakouJikan(setTimetoSec(editedTime), item.no);
                              }}
                            />
                            <CaretUpOutlined
                              style={{
                                fontSize: 17 + 'px',
                                color: '#000',
                                pointerEvents: 'auto',
                              }}
                              onClick={() => {
                                let editedTime = souchiHandleIncreaseTime(secondsToHms(item.value), 'min');
                                changeKakouJikan(setTimetoSec(editedTime), item.no);
                              }}
                            />
                            <CaretDownOutlined
                              style={{
                                fontSize: 17 + 'px',
                                color: '#000',
                                pointerEvents: 'auto',
                              }}
                              onClick={() => {
                                let editedTime = souchiHandleDecreaseTime(secondsToHms(item.value), 'min');
                                changeKakouJikan(setTimetoSec(editedTime), item.no);
                              }}
                            />
                            <CaretUpOutlined
                              style={{
                                fontSize: 17 + 'px',
                                color: '#000',
                                pointerEvents: 'auto',
                              }}
                              onClick={() => {
                                let editedTime = souchiHandleIncreaseTime(secondsToHms(item.value), 'sec');
                                changeKakouJikan(setTimetoSec(editedTime), item.no);
                              }}
                            />
                            <CaretDownOutlined
                              style={{
                                fontSize: 17 + 'px',
                                color: '#000',
                                pointerEvents: 'auto',
                              }}
                              onClick={() => {
                                let editedTime = souchiHandleDecreaseTime(secondsToHms(item.value), 'sec');
                                changeKakouJikan(setTimetoSec(editedTime), item.no);
                              }}
                            />
                          </Col>
                        </>
                      )}
                    </Row>
                  </td>
                ))}
              </tr>
            </Table>
          </Row>
        </>
      ) : category === WorkType.SmSecondaryWork ? (
        // 2次加工
        <>
          <div style={{ marginLeft: 10, marginRight: 10 }}>
            <Row>
              <Radio.Group onChange={handleModeChange} value={mode} style={{ marginBottom: 8 }}>
                <Radio.Button value="simple" style={{ pointerEvents: btnEnable && editMode ? 'auto' : 'none' }}>
                  簡易入力
                </Radio.Button>
                <Radio.Button value="details" style={{ pointerEvents: editMode ? 'auto' : 'none' }}>
                  詳細入力
                </Radio.Button>
              </Radio.Group>
            </Row>
            {/* 加工種類・サイズ */}
            <Row className="qty-table-container">
              <Table
                style={{
                  width:
                    sizeData?.filter((i) => !i.isOutOfRange)?.length > 8
                      ? '100%'
                      : sizeData?.filter((i) => !i.isOutOfRange)?.length * 98 + 196 + 'px',
                }}
              >
                {/* 加工種類・サイズのヘッダー */}
                <tr style={{ color: 'white', textAlign: 'center', height: 40 }}>
                  <td style={{ width: '196px' }} className="fixedCol">
                    <Row justify="center" className="tbl-header">
                      <Space>
                        加工種類 /
                        {editMode ? (
                          <>
                            <Button className="cancleButton" onClick={size} style={{ pointerEvents: 'auto' }}>
                              サイズ
                            </Button>
                          </>
                        ) : (
                          <>
                            <label>サイズ</label>
                          </>
                        )}
                      </Space>
                    </Row>
                  </td>
                  {mode === 'simple' ? (
                    <></>
                  ) : (
                    <>
                      <td style={{ width: '196px' }} className="zaishitsukubun_header_fixedCol"></td>
                    </>
                  )}
                  {/* 加工種類・サイズのヘッダー */}
                  {sizeData
                    ?.filter((subitem) => !subitem.isOutOfRange)
                    ?.map((item, index) => (
                      <td
                        key={index}
                        style={{
                          backgroundColor: '#1063aa',
                          width:
                            mode === 'simple'
                              ? '210px'
                              : sizeItaatsu?.filter((i) => !i.isOutOfRange)?.length * 210 + 'px',
                        }}
                      >
                        ～M{item.value}
                      </td>
                    ))}
                </tr>

                {/* 加工種類・サイズのリスト */}
                {Object.entries(kaniListData)
                  ?.filter(
                    ([sizeDataKey]) => getProcessDetailTypeName(parseInt(sizeDataKey), processMstRecord) !== undefined
                  )
                  ?.map(([key, item], rowIndex) => (
                    <tr key={rowIndex} style={{ textAlign: 'center' }}>
                      <td style={{ width: '98px', textAlign: 'center' }} className="tbl-header fixedCol2">
                        {/* 工程詳細名 */}
                        {getProcessDetailTypeName(parseInt(key), processMstRecord)}
                      </td>
                      {mode === 'simple' ? (
                        <></>
                      ) : (
                        <>
                          <td style={{ width: '98px', padding: '0px' }} className="zaishitsukubun_fixedCol">
                            <Row className="kakoujikan" style={{ padding: 0 }}>
                              <Table className="zaishitsu" style={{ marginBottom: 0 }}>
                                {rowIndex === 0 ? (
                                  <>
                                    <tr style={{ color: 'white', textAlign: 'center', height: 48 }}>
                                      <td className="removeTopborder tbl-header" style={{ borderTop: 'none' }}>
                                        <Row justify="center" className="tbl-header">
                                          <Space>
                                            材質区分 /
                                            {editMode ? (
                                              <>
                                                <Button
                                                  className="cancleButton"
                                                  onClick={sizeThickness}
                                                  style={{ pointerEvents: 'auto' }}
                                                >
                                                  板厚
                                                </Button>
                                              </>
                                            ) : (
                                              <>
                                                <label>板厚</label>
                                              </>
                                            )}
                                          </Space>
                                        </Row>
                                      </td>
                                    </tr>
                                  </>
                                ) : (
                                  <></>
                                )}

                                {/* 材質区分名 */}
                                {zaishitsuData
                                  ?.filter((zaishitsuItem) => zaishitsuItem.info.isUsed)
                                  ?.map((disInfo, zIndex, zArray, index) => (
                                    <tr key={index} style={{ textAlign: 'center', height: 36 }}>
                                      <td
                                        style={{
                                          borderBottom: zArray?.length - 1 === zIndex ? 'none' : '',
                                          borderTop: zIndex === 0 ? 'auto' : '',
                                        }}
                                      >
                                        {getMaterialTypeById(parseInt(disInfo.id), zaishitsuData)}
                                      </td>
                                    </tr>
                                  ))}
                              </Table>
                            </Row>
                          </td>
                        </>
                      )}
                      {/* リスト情報 */}
                      {item?.map((col, colIndex) => (
                        <td
                          key={colIndex}
                          style={{
                            width:
                              mode === 'simple'
                                ? '210px'
                                : sizeItaatsu?.filter((i) => !i.isOutOfRange)?.length * 210 + 'px',
                            padding: 0,
                          }}
                        >
                          {mode === 'simple' ? (
                            // 簡易入力リスト
                            <>
                              <Row align="middle" style={{ padding: 3 }}>
                                {!editMode ? (
                                  <>
                                    <Col span={24}>
                                      <label>{secondsToHms(col.value)}</label>
                                    </Col>
                                  </>
                                ) : (
                                  <>
                                    <Col span={10}>
                                      <Input
                                        value={secondsToHms(col.value)}
                                        onChange={(e) => listDataChange(e.target.value, key, col.no, 'size')}
                                      ></Input>
                                    </Col>
                                    <Col span={14}>
                                      <CaretUpOutlined
                                        style={{
                                          fontSize: 16 + 'px',
                                          marginLeft: 3,
                                          pointerEvents: 'auto',
                                        }}
                                        onClick={() => {
                                          let editedTime = souchiHandleIncreaseTime(secondsToHms(col.value), '10min');
                                          listDataChange(editedTime, key, col.no, 'size');
                                        }}
                                      />
                                      <CaretDownOutlined
                                        style={{
                                          fontSize: 17 + 'px',
                                          color: '#000',
                                          pointerEvents: 'auto',
                                        }}
                                        onClick={() => {
                                          let editedTime = souchiHandleDecreaseTime(secondsToHms(col.value), '10min');
                                          listDataChange(editedTime, key, col.no, 'size');
                                        }}
                                      />
                                      <CaretUpOutlined
                                        style={{
                                          fontSize: 17 + 'px',
                                          color: '#000',
                                          pointerEvents: 'auto',
                                        }}
                                        onClick={() => {
                                          let editedTime = souchiHandleIncreaseTime(secondsToHms(col.value), 'min');
                                          listDataChange(editedTime, key, col.no, 'size');
                                        }}
                                      />
                                      <CaretDownOutlined
                                        style={{
                                          fontSize: 17 + 'px',
                                          color: '#000',
                                          pointerEvents: 'auto',
                                        }}
                                        onClick={() => {
                                          let editedTime = souchiHandleDecreaseTime(secondsToHms(col.value), 'min');
                                          listDataChange(editedTime, key, col.no, 'size');
                                        }}
                                      />
                                      <CaretUpOutlined
                                        style={{
                                          fontSize: 17 + 'px',
                                          color: '#000',
                                          pointerEvents: 'auto',
                                        }}
                                        onClick={() => {
                                          let editedTime = souchiHandleIncreaseTime(secondsToHms(col.value), 'sec');
                                          listDataChange(editedTime, key, col.no, 'size');
                                        }}
                                      />
                                      <CaretDownOutlined
                                        style={{
                                          fontSize: 17 + 'px',
                                          color: '#000',
                                          pointerEvents: 'auto',
                                        }}
                                        onClick={() => {
                                          let editedTime = souchiHandleDecreaseTime(secondsToHms(col.value), 'sec');
                                          listDataChange(editedTime, key, col.no, 'size');
                                        }}
                                      />
                                    </Col>
                                  </>
                                )}
                              </Row>
                            </>
                          ) : (
                            // 詳細入力リスト
                            <>
                              <Row className="subTbl">
                                <Table
                                  style={{
                                    marginBottom: 0,
                                    width: sizeItaatsu?.filter((i) => !i.isOutOfRange)?.length * 210 + 'px',
                                  }}
                                >
                                  {/* 板厚情報のヘッダー */}
                                  {rowIndex === 0 ? (
                                    <>
                                      <tr style={{ color: 'white', textAlign: 'center', height: 48 }}>
                                        {sizeItaatsu
                                          ?.filter((subitem) => !subitem.isOutOfRange)
                                          ?.map((item, index) => (
                                            <td
                                              key={index}
                                              style={{ backgroundColor: '#1063aa', width: '210px' }}
                                              className="removeTopborder"
                                            >
                                              ～{item.value}t
                                            </td>
                                          ))}
                                      </tr>
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  {/* 材質区分・板厚リスト詳細情報 */}
                                  {Object.entries(col?.workTimeThickThItems)
                                    ?.filter(
                                      ([thickKey]) => getMaterialTypeById(parseInt(thickKey), zaishitsuData) !== ''
                                    )
                                    ?.map(([subRowKey, subRowItem], subRowIndex, subRowLists) => (
                                      <tr style={{ textAlign: 'center', height: 36 }}>
                                        {subRowItem?.map((subCol, subColIndex) => (
                                          <td
                                            key={subColIndex}
                                            style={{
                                              width: '210px',
                                              borderLeft: subColIndex === 0 ? 'none' : '',
                                              borderRight: subColIndex === subRowLists?.length - 1 ? 'none' : '',
                                              borderBottom: subRowIndex === subRowItem?.length - 1 ? 'none' : '',
                                              borderTop: subRowIndex === 0 ? 'none' : '',
                                            }}
                                          >
                                            <Row align="middle" style={{ padding: 1 }}>
                                              {!editMode ? (
                                                <>
                                                  <Col span={24}>
                                                    <label>{secondsToHms(subCol.value)}</label>
                                                  </Col>
                                                </>
                                              ) : (
                                                <>
                                                  <Col span={10}>
                                                    <Input
                                                      value={secondsToHms(subCol.value)}
                                                      onChange={(e) =>
                                                        itaatsulistDataChange(
                                                          e.target.value,
                                                          key,
                                                          col.no,
                                                          subRowKey,
                                                          subCol.no,
                                                          'size'
                                                        )
                                                      }
                                                    ></Input>
                                                  </Col>
                                                  <Col span={14}>
                                                    <CaretUpOutlined
                                                      style={{
                                                        fontSize: 16 + 'px',
                                                        marginLeft: 3,
                                                        pointerEvents: 'auto',
                                                      }}
                                                      onClick={() => {
                                                        let editedTime = souchiHandleIncreaseTime(
                                                          secondsToHms(subCol.value),
                                                          '10min'
                                                        );

                                                        itaatsulistDataChange(
                                                          editedTime,
                                                          key,
                                                          col.no,
                                                          subRowKey,
                                                          subCol.no,
                                                          'size'
                                                        );
                                                      }}
                                                    />
                                                    <CaretDownOutlined
                                                      style={{
                                                        fontSize: 17 + 'px',
                                                        color: '#000',
                                                        pointerEvents: 'auto',
                                                      }}
                                                      onClick={() => {
                                                        let editedTime = souchiHandleDecreaseTime(
                                                          secondsToHms(subCol.value),
                                                          '10min'
                                                        );
                                                        itaatsulistDataChange(
                                                          editedTime,
                                                          key,
                                                          col.no,
                                                          subRowKey,
                                                          subCol.no,
                                                          'size'
                                                        );
                                                      }}
                                                    />
                                                    <CaretUpOutlined
                                                      style={{
                                                        fontSize: 17 + 'px',
                                                        color: '#000',
                                                        pointerEvents: 'auto',
                                                      }}
                                                      onClick={() => {
                                                        let editedTime = souchiHandleIncreaseTime(
                                                          secondsToHms(subCol.value),
                                                          'min'
                                                        );
                                                        itaatsulistDataChange(
                                                          editedTime,
                                                          key,
                                                          col.no,
                                                          subRowKey,
                                                          subCol.no,
                                                          'size'
                                                        );
                                                      }}
                                                    />
                                                    <CaretDownOutlined
                                                      style={{
                                                        fontSize: 17 + 'px',
                                                        color: '#000',
                                                        pointerEvents: 'auto',
                                                      }}
                                                      onClick={() => {
                                                        let editedTime = souchiHandleDecreaseTime(
                                                          secondsToHms(subCol.value),
                                                          'min'
                                                        );
                                                        itaatsulistDataChange(
                                                          editedTime,
                                                          key,
                                                          col.no,
                                                          subRowKey,
                                                          subCol.no,
                                                          'size'
                                                        );
                                                      }}
                                                    />
                                                    <CaretUpOutlined
                                                      style={{
                                                        fontSize: 17 + 'px',
                                                        color: '#000',
                                                        pointerEvents: 'auto',
                                                      }}
                                                      onClick={() => {
                                                        let editedTime = souchiHandleIncreaseTime(
                                                          secondsToHms(subCol.value),
                                                          'sec'
                                                        );
                                                        itaatsulistDataChange(
                                                          editedTime,
                                                          key,
                                                          col.no,
                                                          subRowKey,
                                                          subCol.no,
                                                          'size'
                                                        );
                                                      }}
                                                    />
                                                    <CaretDownOutlined
                                                      style={{
                                                        fontSize: 17 + 'px',
                                                        color: '#000',
                                                        pointerEvents: 'auto',
                                                      }}
                                                      onClick={() => {
                                                        let editedTime = souchiHandleDecreaseTime(
                                                          secondsToHms(subCol.value),
                                                          'sec'
                                                        );
                                                        itaatsulistDataChange(
                                                          editedTime,
                                                          key,
                                                          col.no,
                                                          subRowKey,
                                                          subCol.no,
                                                          'size'
                                                        );
                                                      }}
                                                    />
                                                  </Col>
                                                </>
                                              )}
                                            </Row>
                                          </td>
                                        ))}
                                      </tr>
                                    ))}
                                </Table>
                              </Row>
                            </>
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
              </Table>
            </Row>
            {/* 加工種類・mm */}
            <Row className="qty-table-container">
              <Table
                style={{
                  width:
                    anaSizeData?.filter((i) => !i.isOutOfRange)?.length > 8
                      ? '100%'
                      : anaSizeData?.filter((i) => !i.isOutOfRange)?.length * 98 + 196 + 'px',
                }}
              >
                {/* 加工種類・mmのヘッダー */}
                <tr style={{ color: 'white', textAlign: 'center', height: 40 }}>
                  <td style={{ width: '196px' }} className="fixedCol">
                    <Row justify="center" className="tbl-header">
                      <Space>
                        加工種類 /
                        {editMode ? (
                          <>
                            <Button className="cancleButton" onClick={anaSize} style={{ pointerEvents: 'auto' }}>
                              mm
                            </Button>
                          </>
                        ) : (
                          <>
                            <label>mm</label>
                          </>
                        )}
                      </Space>
                    </Row>
                  </td>
                  {mode === 'simple' ? (
                    <></>
                  ) : (
                    <>
                      <td style={{ width: '196px' }} className="zaishitsukubun_header_fixedCol"></td>
                    </>
                  )}

                  {/* 加工種類・mmのヘッダー */}
                  {anaSizeData
                    ?.filter((subitem) => !subitem.isOutOfRange)
                    ?.map((item, index) => (
                      <td
                        key={index}
                        style={{
                          backgroundColor: '#1063aa',
                          width:
                            mode === 'simple'
                              ? '210px'
                              : mmItaatsu?.filter((i) => !i.isOutOfRange)?.length * 210 + 'px',
                        }}
                      >
                        ～{item.value}
                      </td>
                    ))}
                </tr>

                {/* 加工種類・mmのリスト */}
                {Object.entries(kaniAnaListData)
                  ?.filter(
                    ([mmDataKey]) => getProcessDetailTypeName(parseInt(mmDataKey), processMstRecord) !== undefined
                  )
                  ?.map(([key, item], rowIndex) => (
                    <tr key={rowIndex} style={{ textAlign: 'center' }}>
                      <td style={{ width: '98px', textAlign: 'center' }} className="tbl-header fixedCol2">
                        {/* 工程詳細名 */}
                        {getProcessDetailTypeName(parseInt(key), processMstRecord)}
                      </td>
                      {mode === 'simple' ? (
                        <></>
                      ) : (
                        <>
                          <td style={{ width: '98px', padding: '0px' }} className="zaishitsukubun_fixedCol">
                            <Row className="kakoujikan" style={{ padding: 0 }}>
                              <Table className="zaishitsu" style={{ marginBottom: 0 }}>
                                {rowIndex === 0 ? (
                                  <>
                                    <tr style={{ color: 'white', textAlign: 'center', height: 48 }}>
                                      <td className="removeTopborder tbl-header" style={{ borderTop: 'none' }}>
                                        <Row justify="center" className="tbl-header">
                                          <Space>
                                            材質区分 /
                                            {editMode ? (
                                              <>
                                                <Button
                                                  className="cancleButton"
                                                  onClick={mmThickness}
                                                  style={{ pointerEvents: 'auto' }}
                                                >
                                                  板厚
                                                </Button>
                                              </>
                                            ) : (
                                              <>
                                                <label>板厚</label>
                                              </>
                                            )}
                                          </Space>
                                        </Row>
                                      </td>
                                    </tr>
                                  </>
                                ) : (
                                  <></>
                                )}

                                {/* 材質区分名 */}
                                {zaishitsuData
                                  ?.filter((zaishitsuItem) => zaishitsuItem.info.isUsed)
                                  ?.map((disInfo, zIndex, zArray, index) => (
                                    <tr key={index} style={{ textAlign: 'center', height: 36 }}>
                                      <td
                                        style={{
                                          borderBottom: zArray?.length - 1 === zIndex ? 'none' : '',
                                          borderTop: zIndex === 0 ? 'auto' : '',
                                        }}
                                      >
                                        {getMaterialTypeById(parseInt(disInfo.id), zaishitsuData)}
                                      </td>
                                    </tr>
                                  ))}
                              </Table>
                            </Row>
                          </td>
                        </>
                      )}
                      {/* リスト情報 */}
                      {item?.map((col, colIndex) => (
                        <td
                          key={colIndex}
                          style={{
                            width:
                              mode === 'simple'
                                ? '210px'
                                : mmItaatsu?.filter((i) => !i.isOutOfRange)?.length * 210 + 'px',
                            padding: 0,
                          }}
                        >
                          {mode === 'simple' ? (
                            // 簡易入力リスト
                            <>
                              <Row align="middle" style={{ padding: 3 }}>
                                {!editMode ? (
                                  <>
                                    <Col span={24}>
                                      <label>{secondsToHms(col.value)}</label>
                                    </Col>
                                  </>
                                ) : (
                                  <>
                                    <Col span={10}>
                                      <Input
                                        value={secondsToHms(col.value)}
                                        onChange={(e) => listDataChange(e.target.value, key, col.no, 'mm')}
                                      ></Input>
                                    </Col>
                                    <Col span={14}>
                                      <CaretUpOutlined
                                        style={{
                                          fontSize: 16 + 'px',
                                          marginLeft: 3,
                                          pointerEvents: 'auto',
                                        }}
                                        onClick={() => {
                                          let editedTime = souchiHandleIncreaseTime(secondsToHms(col.value), '10min');
                                          listDataChange(editedTime, key, col.no, 'mm');
                                        }}
                                      />
                                      <CaretDownOutlined
                                        style={{
                                          fontSize: 17 + 'px',
                                          color: '#000',
                                          pointerEvents: 'auto',
                                        }}
                                        onClick={() => {
                                          let editedTime = souchiHandleDecreaseTime(secondsToHms(col.value), '10min');
                                          listDataChange(editedTime, key, col.no, 'mm');
                                        }}
                                      />
                                      <CaretUpOutlined
                                        style={{
                                          fontSize: 17 + 'px',
                                          color: '#000',
                                          pointerEvents: 'auto',
                                        }}
                                        onClick={() => {
                                          let editedTime = souchiHandleIncreaseTime(secondsToHms(col.value), 'min');
                                          listDataChange(editedTime, key, col.no, 'mm');
                                        }}
                                      />
                                      <CaretDownOutlined
                                        style={{
                                          fontSize: 17 + 'px',
                                          color: '#000',
                                          pointerEvents: 'auto',
                                        }}
                                        onClick={() => {
                                          let editedTime = souchiHandleDecreaseTime(secondsToHms(col.value), 'min');
                                          listDataChange(editedTime, key, col.no, 'mm');
                                        }}
                                      />
                                      <CaretUpOutlined
                                        style={{
                                          fontSize: 17 + 'px',
                                          color: '#000',
                                          pointerEvents: 'auto',
                                        }}
                                        onClick={() => {
                                          let editedTime = souchiHandleIncreaseTime(secondsToHms(col.value), 'sec');
                                          listDataChange(editedTime, key, col.no, 'mm');
                                        }}
                                      />
                                      <CaretDownOutlined
                                        style={{
                                          fontSize: 17 + 'px',
                                          color: '#000',
                                          pointerEvents: 'auto',
                                        }}
                                        onClick={() => {
                                          let editedTime = souchiHandleDecreaseTime(secondsToHms(col.value), 'sec');
                                          listDataChange(editedTime, key, col.no, 'mm');
                                        }}
                                      />
                                    </Col>
                                  </>
                                )}
                              </Row>
                            </>
                          ) : (
                            // 詳細入力リスト
                            <>
                              <Row className="subTbl">
                                <Table
                                  style={{
                                    marginBottom: 0,
                                    width: mmItaatsu?.filter((i) => !i.isOutOfRange)?.length * 210 + 'px',
                                  }}
                                >
                                  {/* 板厚情報のヘッダー */}
                                  {rowIndex === 0 ? (
                                    <>
                                      <tr style={{ color: 'white', textAlign: 'center', height: 48 }}>
                                        {mmItaatsu
                                          ?.filter((subitem) => !subitem.isOutOfRange)
                                          ?.map((item, index) => (
                                            <td
                                              key={index}
                                              style={{ backgroundColor: '#1063aa', width: '210px' }}
                                              className="removeTopborder"
                                            >
                                              ～{item.value}t
                                            </td>
                                          ))}
                                      </tr>
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  {/* 材質区分・板厚リスト詳細情報 */}
                                  {Object.entries(col?.workTimeThickThItems)
                                    ?.filter(
                                      ([thickKey]) => getMaterialTypeById(parseInt(thickKey), zaishitsuData) !== ''
                                    )
                                    ?.map(([subRowKey, subRowItem], subRowIndex, subRowLists) => (
                                      <tr style={{ textAlign: 'center', height: 36 }}>
                                        {subRowItem?.map((subCol, subColIndex) => (
                                          <td
                                            key={subColIndex}
                                            style={{
                                              width: '210px',
                                              borderLeft: subColIndex === 0 ? 'none' : '',
                                              borderRight: subColIndex === subRowLists?.length - 1 ? 'none' : '',
                                              borderBottom: subRowIndex === subRowItem?.length - 1 ? 'none' : '',
                                              borderTop: subRowIndex === 0 ? 'none' : '',
                                            }}
                                          >
                                            <Row align="middle" style={{ padding: 1 }}>
                                              {!editMode ? (
                                                <>
                                                  <Col span={24}>
                                                    <label>{secondsToHms(subCol.value)}</label>
                                                  </Col>
                                                </>
                                              ) : (
                                                <>
                                                  <Col span={10}>
                                                    <Input
                                                      value={secondsToHms(subCol.value)}
                                                      onChange={(e) =>
                                                        itaatsulistDataChange(
                                                          e.target.value,
                                                          key,
                                                          col.no,
                                                          subRowKey,
                                                          subCol.no,
                                                          'size'
                                                        )
                                                      }
                                                    ></Input>
                                                  </Col>
                                                  <Col span={14}>
                                                    <CaretUpOutlined
                                                      style={{
                                                        fontSize: 16 + 'px',
                                                        marginLeft: 3,
                                                        pointerEvents: 'auto',
                                                      }}
                                                      onClick={() => {
                                                        let editedTime = souchiHandleIncreaseTime(
                                                          secondsToHms(subCol.value),
                                                          '10min'
                                                        );

                                                        itaatsulistDataChange(
                                                          editedTime,
                                                          key,
                                                          col.no,
                                                          subRowKey,
                                                          subCol.no,
                                                          'mm'
                                                        );
                                                      }}
                                                    />
                                                    <CaretDownOutlined
                                                      style={{
                                                        fontSize: 17 + 'px',
                                                        color: '#000',
                                                        pointerEvents: 'auto',
                                                      }}
                                                      onClick={() => {
                                                        let editedTime = souchiHandleDecreaseTime(
                                                          secondsToHms(subCol.value),
                                                          '10min'
                                                        );
                                                        itaatsulistDataChange(
                                                          editedTime,
                                                          key,
                                                          col.no,
                                                          subRowKey,
                                                          subCol.no,
                                                          'mm'
                                                        );
                                                      }}
                                                    />
                                                    <CaretUpOutlined
                                                      style={{
                                                        fontSize: 17 + 'px',
                                                        color: '#000',
                                                        pointerEvents: 'auto',
                                                      }}
                                                      onClick={() => {
                                                        let editedTime = souchiHandleIncreaseTime(
                                                          secondsToHms(subCol.value),
                                                          'min'
                                                        );
                                                        itaatsulistDataChange(
                                                          editedTime,
                                                          key,
                                                          col.no,
                                                          subRowKey,
                                                          subCol.no,
                                                          'mm'
                                                        );
                                                      }}
                                                    />
                                                    <CaretDownOutlined
                                                      style={{
                                                        fontSize: 17 + 'px',
                                                        color: '#000',
                                                        pointerEvents: 'auto',
                                                      }}
                                                      onClick={() => {
                                                        let editedTime = souchiHandleDecreaseTime(
                                                          secondsToHms(subCol.value),
                                                          'min'
                                                        );
                                                        itaatsulistDataChange(
                                                          editedTime,
                                                          key,
                                                          col.no,
                                                          subRowKey,
                                                          subCol.no,
                                                          'mm'
                                                        );
                                                      }}
                                                    />
                                                    <CaretUpOutlined
                                                      style={{
                                                        fontSize: 17 + 'px',
                                                        color: '#000',
                                                        pointerEvents: 'auto',
                                                      }}
                                                      onClick={() => {
                                                        let editedTime = souchiHandleIncreaseTime(
                                                          secondsToHms(subCol.value),
                                                          'sec'
                                                        );
                                                        itaatsulistDataChange(
                                                          editedTime,
                                                          key,
                                                          col.no,
                                                          subRowKey,
                                                          subCol.no,
                                                          'mm'
                                                        );
                                                      }}
                                                    />
                                                    <CaretDownOutlined
                                                      style={{
                                                        fontSize: 17 + 'px',
                                                        color: '#000',
                                                        pointerEvents: 'auto',
                                                      }}
                                                      onClick={() => {
                                                        let editedTime = souchiHandleDecreaseTime(
                                                          secondsToHms(subCol.value),
                                                          'sec'
                                                        );
                                                        itaatsulistDataChange(
                                                          editedTime,
                                                          key,
                                                          col.no,
                                                          subRowKey,
                                                          subCol.no,
                                                          'mm'
                                                        );
                                                      }}
                                                    />
                                                  </Col>
                                                </>
                                              )}
                                            </Row>
                                          </td>
                                        ))}
                                      </tr>
                                    ))}
                                </Table>
                              </Row>
                            </>
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
              </Table>
            </Row>
          </div>
        </>
      ) : (
        <></>
      )}
      {/* mm */}
      {isLength
        ? commonModal(
            isLength,
            addLengthModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addLengthContent,
            null,
            null,
            true
          )
        : ''}
      {/* サイズ */}
      {isSize
        ? commonModal(
            isSize,
            addSizeModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addSizeContent,
            null,
            null,
            true
          )
        : ''}
      {/* 穴サイズ */}
      {isAnaSize
        ? commonModal(
            isAnaSize,
            addAnaSizeModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addAnaSizeContent,
            null,
            null,
            true
          )
        : ''}
      {/* サイズ板厚 */}
      {isSizeItaatsu
        ? commonModal(
            isSizeItaatsu,
            addThicknessModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addSizeItaatsuContent,
            null,
            null,
            true
          )
        : ''}
      {/* mm板厚 */}
      {isMmItaatsu
        ? commonModal(
            isMmItaatsu,
            addThicknessModalTitle,
            null,
            null,
            400,
            addData,
            closeAddModal,
            addMmItaatsuContent,
            null,
            null,
            true
          )
        : ''}
    </>
  );
});

export default Device_KakouJikan;
