import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { Row, Col, Input, Select, Popover, Checkbox, Space, InputNumber, notification } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import CurrencyInput from 'react-currency-input-field';
import { BsUsbC, BsX } from 'react-icons/bs';

import '../../assets/styles/common.css';
import {
  handelIncreaseTime,
  handelDecreaseTime,
  checkInput,
  secondsToHms,
  setTimetoSec,
  checkInputWithColor,
  handleCollpse,
  handleShowHide,
  checkShowHideIcon,
  handleKeyPress,
  kouteiCalculateSelectedArr,
  handleChangeCheckData,
} from '../common/Common.js';
import { DataType, MaterialSurfaceBillingUnit, ServiceClass, WorkType, WorkTypeGroup } from '../common/enums';
import { amtEmptyStr, timeHmsStr } from '../common/Constant';

const IQ3KouteiCalculate_YosetsuFinish = forwardRef((props, ref) => {
  const [isYosetsuFinishdetail, setIYosetsuFinishdetail] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [totalAmt, setTotalAmt] = useState('');
  const [totalTime, setTotalTime] = useState('');
  const [totalSagyoAmt, setTotalSagyoAmt] = useState('');
  const [totalSagyoTime, setTotalSagyoTime] = useState('');
  const [totalDandoriAmt, setTotalDandoriAmt] = useState('');
  const [totalDandoriTime, setTotalDandoriTime] = useState('');

  const [totalSum, setTotalSum] = useState('');
  const [totalTimeSum, setTotalTimeSum] = useState('');
  const [sagyoAmtSum, setSagyoAmtSum] = useState('');
  const [sagyoTimeSum, setSagyoTimeSum] = useState('');
  const [dandoriAmtSum, setDandoriAmtSum] = useState('');
  const [dandoriTimeSum, setDandoriTimeSum] = useState('');
  const [processArr, setProcessArr] = useState([]);
  const [editedData, setEditedData] = useState([]);
  const [oldEditedData, setOldEditedData] = useState([]);
  const [patterns, setPatterns] = useState([]);

  const [handelingAmt, setHandelingAmt] = useState();
  const [handelingTime, setHandelingTime] = useState();
  const [kensaAmt, setKensaAmt] = useState();
  const [kensaTime, setKensaTime] = useState();

  const [editedHandelingAmt, setEditedHandelingAmt] = useState();
  const [editedHandelingTime, setEditedHandelingTime] = useState();
  const [editedKensaAmt, setEditedKensaAmt] = useState();
  const [editedKensaTime, setEditedKensaTime] = useState();

  const [subYousetsusArr, setSubYousetsuArr] = useState([]);
  const [yousetsusArr, setYousetsuArr] = useState([]);
  const [processCount, setProcessCount] = useState();

  const [subSagyoTotal, setSubSagyoTotal] = useState([]);
  const [selectedDataFlg, setSelectedDataFlg] = useState(0);
  const [partThickness, setPartThickness] = useState(0);
  const [updateEditedData, setUpdateEditedData] = useState([]);
  const [weldingFinishAmt, setWeldingFinishAmt] = useState(0);
  const [deviceMst, setDeviceMst] = useState([]);
  const [processMst, setProcessMst] = useState([]);
  const [patternInfo, setPatternInfo] = useState([]);

  const JPYs = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'JPY',
  });

  const handleChangeCheckData = (e, i) => {
    const data = { ...updateEditedData };
    data.dataType = e;
    setUpdateEditedData(data);
    setTotalAmt(e == DataType.Data ? data.totalDataPrice : data.totalEditPrice);
    props?.updateIQ3KouteiCal(data, WorkType.SmWeldFinish);
  };

  const calculateTotals = (editDataArr) => {
    let sagyoAmtSum = 0;
    let dandoriAmtSum = 0;

    let sagyoTimeSec = 0;
    let dandoriTimeSec = 0;

    editDataArr?.forEach((element) => {
      sagyoAmtSum += element.sagyoAmt;
      dandoriAmtSum += element.dandoriAmt;
      sagyoTimeSec += element.sagyoTime;
      dandoriTimeSec += element.dandoriTime;
    });

    // sagyoAmtSum += parseInt(editDataArr[0].handelingAmt);
    // sagyoTimeSec += setTimetoSec(editDataArr[0].handelingTime);

    // let totalSum = Math.floor(sagyoAmtSum + dandoriAmtSum + editDataArr[0].kensaAmt);
    let totalSum = sagyoAmtSum + dandoriAmtSum;
    let sagyoTimeSum = sagyoTimeSec;
    let dandoriTimeSum = dandoriTimeSec;
    // let totalTimeSum = secondsToHms(sagyoTimeSec + dandoriTimeSec + setTimetoSec(editedKensaTime));
    let totalTimeSum = sagyoTimeSec + dandoriTimeSec;

    let totals = [];
    let i = 0;
    totals.push({
      id: i++,
      sagyoAmtSum: sagyoAmtSum,
      dandoriAmtSum: dandoriAmtSum,
      sagyoTimeSec: sagyoTimeSec,
      dandoriTimeSec: dandoriTimeSec,
      totalSum: totalSum,
      sagyoTimeSum: sagyoTimeSum,
      dandoriTimeSum: dandoriTimeSum,
      totalTimeSum: totalTimeSum,
    });
    return totals;
  };

  const contentDandori = (
    <div>
      <p>
        段取金額=アルゴン段取金額＋ボルト段取金額
        <br /> {totalDandoriAmt} =
        {(() => {
          const subArr = [];
          for (let j = 0; j < editedData?.length; j++) {
            subArr.push(editedData[j].dandoriAmt);
          }
          return subArr.join('+');
        })()}
      </p>
      <p>
        段取時間=アルゴン段取時間＋ボルト段取時間
        <br /> {totalDandoriTime} =
        {(() => {
          const subArr = [];
          for (let j = 0; j < editedData?.length; j++) {
            subArr.push(editedData[j].dandoriTime);
          }
          return subArr.join('+');
        })()}
      </p>
    </div>
  );

  const contentSagyo = (
    <div>
      <p>
        作業金額=アルゴン作業金額＋ボルト作業金額
        <br /> {totalSagyoAmt} =
        {(() => {
          const subArr = [];
          for (let j = 0; j < editedData?.length; j++) {
            subArr.push(editedData[j].sagyoAmt);
          }
          return subArr.join('+');
        })()}
      </p>
      <p>
        作業時間=アルゴン作業時間＋ボルト作業時間
        <br /> {totalSagyoTime} =
        {(() => {
          const subArr = [];
          for (let j = 0; j < editedData?.length; j++) {
            subArr.push(editedData[j].sagyoTime);
          }
          return subArr.join('+');
        })()}
      </p>
    </div>
  );

  const contentSub = (
    <div>
      <p>
        作業金額=チャージ金額 x 作業時間
        <br /> ¥233.3334 = ¥4,200(/h) x 200(秒)
      </p>
      <p>
        作業時間 = 加工長 / 加工速度 x 溶接難易度係数 x 製品サイズ係数 x 生産個数係数 <br />
        200(秒) = 200(mm) / 1(mm/秒) x 1 x 1 x 1
      </p>
      <p>
        引用パラメータ
        <br />
        加工長：200mm
        <br /> 加工速度：60mm/分 = 1mm/秒 <br />
        溶接難易度係数：1 <br />
        製品サイズ係数：1 <br />
        生産個数係数：1
      </p>
    </div>
  );

  /* "input[data-type='currency']".on({
    keyup: function () {
      alert('test');
    },
  }); */

  useEffect(() => {
    let editData = [];
    let totals = [];
    let toBEdit = [];
    let processMst = [];
    let weldProcessMst = [];
    let deviceMst = [];

    let parameters = props?.selectedEstimateData?.estimateProducts?.calcParameters?.parameters;
    if (parameters?.process?.length > 0) {
      processMst = parameters?.process
        ? parameters?.process?.filter(
            (item) => item?.class === ServiceClass.SheetMetal && item.workType === WorkType.SmWeldFinish
          )[0]
        : [];
      setProcessMst(processMst ? processMst : []);
      if (parameters?.device?.length > 0) {
        deviceMst = parameters?.device?.filter((item) => processMst?.id == item?.processId);
      }
    }

    let patternInfo = props?.selectedIQ3DataDetail?.processSelect
      ? props?.selectedIQ3DataDetail?.processSelect?.details?.filter((item) => item.processId == processMst?.id)
      : [];
    setPatternInfo([]);
    if (patternInfo == undefined) return;
    if (patternInfo?.length > 0 && patternInfo[0]?.isUsedForCalc == false) return;

    deviceMst =
      deviceMst && patternInfo
        ? deviceMst?.filter(
            (item) => item.processId == patternInfo[0]?.processId && item.id == patternInfo[0]?.deviceId
          )[0]
        : []; //テスト用ID;
    setDeviceMst(deviceMst ? deviceMst : []);
    if (deviceMst == undefined) return;
    setPatternInfo(patternInfo);

    let weldingFinishAdditions = props?.selectedIQ3DataDetail?.additions?.filter(
      (item) => item.processId == processMst?.id
    )[0];
    // 溶接工程入力情報取得
    weldProcessMst = parameters?.process
      ? parameters?.process?.filter(
          (item) => item?.class === ServiceClass.SheetMetal && item.workType === WorkType.SmWelding
        )[0]
      : [];
    let weldingProcessInput = props?.selectedIQ3DataDetail?.processInput?.filter(
      (item) => item.processId == weldProcessMst?.id
    )[0];

    let detailItems = [];
    let editDetailItems = [];
    //溶接情報設定
    for (let j = 0; j < weldingProcessInput?.details?.[0]?.weldingList?.length; j++) {
      let weldingList = weldingProcessInput?.details?.[0]?.weldingList[j];
      //溶接仕上が適用の場合のみ
      if (weldingList.senchouYousetsushiage == 0) {
        let weldProcess = weldProcessMst?.details?.processDetailTypes?.filter(
          (item) => item.id == weldingList.yousetsuType
        )?.[0];
        detailItems.push({
          id: j + 1,
          processName: weldProcess?.name,
          processTypeId: weldProcess?.id,
          dandoriAmt: 0,
          dandoriTime: 0,
          dandoriDetails: [],
          sagyoAmt: 0,
          sagyoTime: 0,
          sagyoDetails: [],
          senchou: Number(weldingList.senchou), //溶接加工長
          senchouKaisu: Number(weldingList.senchouKaisu), //溶接加工長回数
          formula: '',
        });
        //工程選択から処理情報取得
        let multiprocesses = patternInfo[0].multiprocesses;
        for (let i = 0; i < multiprocesses?.length; i++) {
          //溶接仕上工程マスター情報取得
          let processTypeNm = processMst?.details?.processDetailTypes?.filter(
            (item) => item.id == multiprocesses[i].processDetailTypeId
          )?.[0];
          if (processTypeNm == undefined || processTypeNm?.length == 0) continue;
          let dandoriDetail = {
            id: i + 1,
            processDetailTypeId: processTypeNm?.id,
            processDetailName: processTypeNm?.name,
            dandoriAmt: 0,
            dandoriTime: 0,
          };
          let sagyoDetail = {
            id: i + 1,
            processDetailTypeId: processTypeNm?.id,
            processDetailName: processTypeNm?.name,
            sagyoAmt: 0,
            sagyoTime: 0,
          };
          detailItems[j].dandoriDetails.push(dandoriDetail);
          detailItems[j].sagyoDetails.push(sagyoDetail);
        }
      }
    }

    editDetailItems = [];
    let weldingFinishAddition = {
      no: processMst?.id,
      processId: processMst?.id,
      dataType: DataType.Data,
      dataItems: detailItems,
      editItems: editDetailItems,
      totalDandori: {
        dataItemsAmt: 0,
        dataItemsTime: 0,
        editItemsAmt: 0,
        editItemsTime: 0,
      },
      totalSagyo: {
        dataItemsAmt: 0,
        dataItemsTime: 0,
        editItemsAmt: 0,
        editItemsTime: 0,
      },
      totalDataPrice: 0,
      totalDataTime: 0,
      totalEditPrice: 0,
      totalEditTime: 0,
    };
    weldingFinishAdditions = weldingFinishAddition;

    if (weldingFinishAdditions?.dataItems) {
      let orgData = JSON.parse(JSON.stringify(weldingFinishAdditions?.dataItems));
      if (deviceMst != undefined) {
        let totalDandori = 0;
        let totalSagyo = 0;
        let totalDandoriTm = 0;
        let dataItemsByProcessInput = [];
        // 数量
        let partCnt = props?.selectedEstimateData?.estimateProducts?.quantity;
        // 板厚
        let thickness = props?.selectedIQ3DataDetail?.thickness;
        setPartThickness(thickness);
        // 重量
        let weight = props?.selectedIQ3DataDetail?.weight;
        // 面積
        let area = props?.selectedIQ3DataDetail?.totalSurfaceArea;
        //材質
        let materialTypeIq3Id = props?.selectedIQ3DataDetail?.materialTypeIq3Id;

        //材質区分
        let materialTypeId = parameters?.materialTypeIq3?.filter((item) => item.id == materialTypeIq3Id)?.[0]
          ?.materialTypeId;

        // 選択された部品の材質により難易度係数を取得
        let diffCoef = getDiffCoef(deviceMst, materialTypeId);
        // サイズ係数
        let sizeCoef = getSizeCoeff(deviceMst, weight, area);

        //溶接情報設定
        for (let i = 0; i < weldingProcessInput?.details?.[0]?.weldingList?.length; i++) {
          //工程入力情報
          let weldingProcessInputInfo = weldingProcessInput?.details?.[0]?.weldingList[i];
          let dataItemByProcessInput = orgData[i];
          if (dataItemByProcessInput) {
            if (i == 0) {
              /** 段取金額/時間 */
              for (let d = 0; d < dataItemByProcessInput?.dandoriDetails?.length; d++) {
                let dandoriDetailData = dataItemByProcessInput?.dandoriDetails[d];
                // 基本段取時間＊サイズ係数
                let dandoriTimeSec = calDandoriTime(deviceMst, weight, area, dandoriDetailData?.processDetailTypeId);
                let dandoriTime = dandoriTimeSec;
                dataItemByProcessInput.dandoriDetails[d].dandoriTime = dandoriTime;
                // 段取時間の合計
                dataItemByProcessInput.dandoriTime += dandoriTime;

                // 段取金額設定
                //段階時間
                let mSetTimeMin = dandoriTimeSec / 60;
                //装置の段階時間チャージ
                let deviceCharges = Object.entries(deviceMst.details?.charges)?.filter(
                  ([key, data]) => parseInt(key) === dandoriDetailData?.processDetailTypeId
                )?.[0];
                let mSetHRate = 0;
                deviceCharges?.map((item, index) => {
                  if (index > 0) {
                    mSetHRate = item?.prep;
                  }
                });
                let dandoriAmt = (mSetTimeMin * mSetHRate) / 60.0; //段階時間＊時間チャージ
                dataItemByProcessInput.dandoriDetails[d].dandoriAmt = dandoriAmt;
                // 段取金額の合計
                dataItemByProcessInput.dandoriAmt += dandoriAmt;
              }
              totalDandori = dataItemByProcessInput.dandoriAmt;
              totalDandoriTm = dataItemByProcessInput.dandoriTime;
            } else {
              dataItemByProcessInput.dandoriAmt = 0;
              dataItemByProcessInput.dandoriTime = 0;
            }

            // 作業時間（バフ加工時間	+	酸洗加工時間	+	サンダー加工時間）
            for (let s = 0; s < dataItemByProcessInput?.sagyoDetails?.length; s++) {
              let sagyoDetailData = dataItemByProcessInput?.sagyoDetails[s];
              // 加工長
              let sagyoLen = Number(weldingProcessInputInfo?.senchou);
              // 溶接線長により該当種類の加工速度を取得
              let sagyoSpeed = getSagyoSpeed(deviceMst, sagyoLen, sagyoDetailData?.processDetailTypeId);
              // 溶接時間（加工長*回数/加工速度＊難易度係数＊サイズ係数）
              let totalSagyoLen = sagyoLen * weldingProcessInputInfo?.senchouKaisu;
              let sagyoTimeSec = Number.isFinite(totalSagyoLen / sagyoSpeed)
                ? (totalSagyoLen / sagyoSpeed) * diffCoef * sizeCoef
                : 0;
              dataItemByProcessInput.sagyoDetails[s].sagyoTime = sagyoTimeSec;
              dataItemByProcessInput.sagyoTime += sagyoTimeSec;

              // 作業金額
              let mSagyoTimeMin = sagyoTimeSec / 60;
              //装置の作業時間チャージ
              let mSagyoHRate = 0;
              //装置の段階時間チャージ
              let sagyoDeviceCharges = Object.entries(deviceMst.details?.charges)?.filter(
                ([key, data]) => parseInt(key) === sagyoDetailData?.processDetailTypeId
              )?.[0];
              sagyoDeviceCharges?.map((item, index) => {
                if (index > 0) {
                  mSagyoHRate = item?.work;
                }
              });
              let sagyoAmt = (mSagyoTimeMin * mSagyoHRate) / 60.0; //作業時間＊時間チャージ
              dataItemByProcessInput.sagyoDetails[s].sagyoAmt = sagyoAmt;
              dataItemByProcessInput.sagyoAmt += sagyoAmt;
            }
            // 作業金額の合計
            totalSagyo = dataItemByProcessInput.sagyoAmt;
            dataItemsByProcessInput.push(dataItemByProcessInput);
          }
        }

        // 段取金額の合計
        setDandoriAmtSum(totalDandori);
        // 段取時間の合計
        setDandoriTimeSum(totalDandoriTm);
        // 作業金額の合計
        setSagyoAmtSum(totalSagyo);

        /** 　合計時間　*/
        let totalTimeSum = totalDandoriTm;
        setTotalTimeSum(totalTimeSum);
        weldingFinishAdditions.totalDataTime = totalTimeSum;
        weldingFinishAdditions.totalDandori.dataItemsTime = totalTimeSum;

        /** 　合計金額　*/
        let totalSum = totalDandori + totalSagyo;
        setTotalSum(totalSum);
        weldingFinishAdditions.totalDataPrice = totalSum;
        weldingFinishAdditions.totalDandori.dataItemsAmt = totalSum;

        orgData = dataItemsByProcessInput;
        setProcessArr(dataItemsByProcessInput);

        if (weldingFinishAdditions.dataType == DataType.Edit) {
          toBEdit = JSON.parse(JSON.stringify(weldingFinishAdditions?.editItems));
        } else {
          toBEdit = JSON.parse(JSON.stringify(orgData));
        }
      }
    }
    if (toBEdit?.length > 0) {
      editData = toBEdit;
      weldingFinishAdditions.editItems = editData;
      if (editData?.length > 0) {
        setEditedData(editData);
        totals = calculateTotals(editData);

        setTotalAmt(totals[0].totalSum);
        weldingFinishAdditions.totalEditPrice = totals[0].totalSum;
        weldingFinishAdditions.totalDandori.editItemsAmt = totals[0].totalSum;

        setTotalTime(totals[0].totalTimeSum);
        weldingFinishAdditions.totalEditTime = totals[0].totalTimeSum;
        weldingFinishAdditions.totalDandori.editItemsTime = totals[0].totalTimeSum;

        setTotalSum(totals[0].totalSum);
        setTotalTimeSum(totals[0].totalTimeSum);

        setTotalSagyoAmt(totals[0].sagyoAmtSum);
        setSagyoAmtSum(totals[0].sagyoAmtSum);

        setTotalSagyoTime(totals[0].sagyoTimeSum);
        setSagyoTimeSum(totals[0].sagyoTimeSum);

        setTotalDandoriAmt(totals[0].dandoriAmtSum);
        setDandoriAmtSum(totals[0].dandoriAmtSum);

        setTotalDandoriTime(totals[0].dandoriTimeSum);
        setDandoriTimeSum(totals[0].dandoriTimeSum);
      }
      setUpdateEditedData(weldingFinishAdditions);
    }
    if (weldingFinishAdditions?.dataType == DataType.Data) {
      setWeldingFinishAmt(weldingFinishAdditions.totalDataPrice);
    } else {
      setWeldingFinishAmt(weldingFinishAdditions.totalEditPrice);
    }
    props?.updateIQ3KouteiCal(weldingFinishAdditions, WorkType.SmWeldFinish);
  }, [props?.activeTabKey]);

  useEffect(() => {
    setEditMode(props?.editMode != undefined ? props?.editMode : false);
  }, [props?.editMode]);

  const getDiffCoef = (deviceMst, materialTypeId) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;

    let weldDifficultyCoeffThItems = Object.entries(deviceMst.details?.diffFactorCoeff)?.filter(
      ([key, data]) => parseInt(key) === materialTypeId
    )?.[0];

    // 溶接難易度
    let childPartsCoeff = 0;
    weldDifficultyCoeffThItems?.map((item, index) => {
      if (index > 0) {
        childPartsCoeff = item;
      }
    });
    return childPartsCoeff;
  };

  const getSizeCoeff = (deviceMst, weight, area) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    // 段取時間設定

    // 重量
    let prodSizeWeightThMaster = deviceMst?.details?.prodSizeWeightThMaster?.filter(
      (item) => item.value == weight || item.value > weight
    );
    // 面積
    let prodSizeAreaThMaster = deviceMst?.details?.prodSizeAreaThMaster?.filter(
      (item) => item.value == area || item.value > area
    );
    let childPartsCoeffs =
      prodSizeWeightThMaster?.length > 0 && prodSizeAreaThMaster?.length > 0
        ? deviceMst?.details?.prodSizeItems?.filter((item) => item.weightId == prodSizeWeightThMaster[0]?.no)
        : deviceMst?.details?.prodSizeItems[0];

    // サイズ係数
    let partsCoeff =
      childPartsCoeffs?.length > 0
        ? childPartsCoeffs?.[0]?.areaCoeffList?.filter((item) => item.no == prodSizeAreaThMaster?.[0]?.no)?.[0]?.value
        : childPartsCoeffs?.[0]?.areaCoeffList?.[0]?.value;

    return partsCoeff;
  };

  const calDandoriTime = (deviceMst, weight, area, processDetailTypeId) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    // 段取時間設定
    // 重量
    let childPartsCoeffWeightThMaster = deviceMst?.details?.childPartsCoeffWeightThMaster?.filter(
      (item) => item.value == weight || item.value > weight
    );
    // 面積
    let childPartsCoeffAreaThMaster = deviceMst?.details?.childPartsCoeffAreaThMaster?.filter(
      (item) => item.value == area || item.value > area
    );
    let childPartsCoeffs =
      childPartsCoeffWeightThMaster?.length > 0 && childPartsCoeffAreaThMaster?.length > 0
        ? deviceMst?.details?.childPartsCoeff?.filter((item) => item.weightId == childPartsCoeffWeightThMaster[0]?.no)
        : deviceMst?.details?.childPartsCoeff[0];

    // サイズ係数
    let partsCoeff =
      childPartsCoeffs?.length > 0
        ? childPartsCoeffs?.[0]?.areaCoeffList?.filter((item) => item.no == childPartsCoeffAreaThMaster?.[0]?.no)?.[0]
            .value
        : childPartsCoeffs?.[0]?.areaCoeffList?.[0]?.value;

    //　基本段取時間
    let deviceDandori = Object.entries(deviceMst.details?.prepSecs)?.filter(
      ([key, data]) => parseInt(key) === processDetailTypeId
    )?.[0];
    let deviceDandoriTm = 0;
    deviceDandori?.map((item, index) => {
      if (index > 0) {
        deviceDandoriTm = item;
      }
    });

    //　基本段取時間＊サイズ係数
    let dandoriTimeSec = deviceDandoriTm * partsCoeff;
    return dandoriTimeSec;
  };

  const calDandoriAmt = (dandoriTimeSec, deviceMst) => {
    //段階時間
    let mSetTimeMin = dandoriTimeSec / 60;
    //装置の段階時間チャージ
    // let mSetHRate = deviceMst.details?.charges.prep;
    let mSetHRate = 10000;
    //段階時間＊時間チャージ
    let dandoriAmt = Math.floor((mSetTimeMin * mSetHRate) / 60.0);
    return dandoriAmt;
  };

  const getSagyoSpeed = (deviceMst, sencho, processDetailTypeId) => {
    if (deviceMst == undefined || deviceMst?.length == 0) return 0;
    // 加工速度
    // 溶接線長
    let workSpeedThMaster = deviceMst?.details?.workSpeedThMaster?.filter(
      (item) => item.value == sencho || item.value > sencho
    )?.[0];

    let workSpeedThItems = Object.entries(deviceMst.details?.workSpeedThItems)?.filter(
      ([key, data]) => parseInt(key) === workSpeedThMaster?.no
    )?.[0];

    // 加工速度
    let childPartsCoeff = 0;
    workSpeedThItems?.map((item, index) => {
      if (index > 0) {
        childPartsCoeff = item?.filter((item) => parseInt(item.no) == processDetailTypeId)?.[0]?.value;
      }
    });
    return childPartsCoeff;
  };

  const calSagyoAmt = (cSagyoTimeKonpoSec, deviceMst) => {
    // 作業金額
    // 作業時間
    let mWorkHTime = cSagyoTimeKonpoSec;
    let mWorkHTimeMin = mWorkHTime / 60;
    //装置の作業時間チャージ
    // let mWorkHRate = deviceMst.details?.charges.work;
    let mWorkHRate = 10000;
    //　加工時間＊時間チャージ
    let cSagyoAmtKonpo = Math.floor((mWorkHTimeMin * mWorkHRate) / 60.0);
    return cSagyoAmtKonpo;
  };

  const getYousetsuTypes = () => {
    let subYousetsus = [];
    let yousetsus = [];
    const kouteiMasterData = JSON.parse(localStorage.getItem('kouteiMasterData'))?.forEach((e) => {
      if (e.processType === '溶接' && e.type === 'iQ3') {
        e.processDetail?.forEach((processDetail) => {
          if (
            processDetail.processDetailTitle === '取付種類（板厚判断）' ||
            processDetail.processDetailTitle === '取付種類（サイズ判断）'
          ) {
            processDetail.processDetailValue?.forEach((detailVal) => {
              subYousetsus.push(detailVal.detailType);
            });
          } else if (processDetail.processDetailTitle === '溶接種類') {
            processDetail.processDetailValue?.forEach((detailVal) => {
              yousetsus.push(detailVal.detailType);
            });
          }
        });
      }
    });
    setSubYousetsuArr(subYousetsus);
    setYousetsuArr(yousetsus);
  };

  const patternNo = 1;
  const getProcessFromPattern = (patternNo) => {
    let patterns = JSON.parse(localStorage.getItem('iq3_processPattern'));
    let processCodes = [];
    patterns?.forEach((obj) => {
      if (obj.key === patternNo) {
        obj.patternProcess?.forEach((pattenProcess) => {
          if (pattenProcess.processName === '溶接仕上') {
            let processDetailLst = pattenProcess.processDetailLst;
            processDetailLst?.forEach((processDetail) => {
              processCodes.push(processDetail.processDetailCode);
            });
          }
        });
      }
    });
    let processNames = [];
    JSON.parse(localStorage.getItem('kouteiMasterData'))?.forEach((e) => {
      if (e.processType === '溶接仕上' && e.type === 'iQ3') {
        let processDetailValue = e.processDetail[0]?.processDetailValue;
        for (const code of processCodes) {
          processNames.push(processDetailValue?.find((obj) => obj.detailCode === code)?.detailType);
        }
      }
    });
    return processNames;
  };

  const yosetsuFinish = () => {
    if (isYosetsuFinishdetail == true) {
      setIYosetsuFinishdetail(false);
    } else {
      setIYosetsuFinishdetail(true);
    }
  };

  const subpartDetail = (i) => {
    let showHide = document.getElementById('yosetsuFinishSagyo' + i).style.display;
    if (showHide == 'block') {
      document.getElementById('yosetsuFinishSagyo' + i).style.display = 'none';
      document.getElementById('arrowDownYosetsuFinish' + i).style.display = 'block';
      document.getElementById('arrowUpYosetsuFinish' + i).style.display = 'none';
    } else {
      document.getElementById('yosetsuFinishSagyo' + i).style.display = 'block';
      document.getElementById('arrowDownYosetsuFinish' + i).style.display = 'none';
      document.getElementById('arrowUpYosetsuFinish' + i).style.display = 'block';
    }
  };

  const setEditModeChange = (mode) => {
    setEditMode(mode);
  };
  useImperativeHandle(ref, () => ({
    setEditModeChange: setEditModeChange,
  }));

  const settingNewDandoriAmtInArr = (value, index, objId, timeType, timeAct) => {
    let newState = [...editedData];
    if (objId !== 'dandoriAmt' && objId !== 'dandoriTime' && objId !== 'handelingAmt' && objId !== 'handelingTime') {
      setOldEditedData(JSON.parse(JSON.stringify(editedData)));
    }
    let oldValue = editedData[index][objId];
    if (value === undefined) {
      value = 0;
    }
    newState[index][objId] = value;

    if (objId === 'dandoriTime') {
      /* if (timeAct === 'input') {
        let timeType = setTimetoSec(value) - setTimetoSec(oldValue);
        newState[index].dandoriAmt = changeAmtwithTime(newState[index].dandoriAmt, timeType, timeAct, value);
      } else {
        newState[index].dandoriAmt = changeAmtwithTime(newState[index].dandoriAmt, timeType, timeAct, value);
      } */
      if (document.getElementById(index + 'YFinishDandoriAmtCu').style.display !== undefined) {
        let showHideCu = document.getElementById(index + 'YFinishDandoriAmtCu').style.display;
        if (showHideCu === 'block') {
          checkInputWithColor(false, '', index + 'YFinishDandoriAmt', newState[index].dandoriAmt, 'red');
        } else {
          // if (timeAct === 'input') {
          //   let timeType = setTimetoSec(value) - setTimetoSec(oldValue);
          //   newState[index].dandoriAmt = changeAmtwithTime(newState[index].dandoriAmt, timeType, timeAct, value, objId);
          // } else {
          newState[index].dandoriAmt = changeAmtwithTime(newState[index].dandoriAmt, timeType, timeAct, value, objId);
          // }
          checkInputWithColor(false, '', index + 'YFinishDandoriAmt', newState[index].dandoriAmt, 'green');
        }
      }
    } else if (objId === 'sagyoTime') {
      /* if (timeAct === 'input') {
        let timeType = setTimetoSec(value) - setTimetoSec(oldValue);
        newState[index].sagyoAmt = changeAmtwithTime(newState[index].sagyoAmt, timeType, timeAct, value);
      } else {
        newState[index].sagyoAmt = changeAmtwithTime(newState[index].sagyoAmt, timeType, timeAct, value);
      } */
      checkInputWithColor(false, '', index + 'YFinishSagyoAmt', newState[index].sagyoAmt, 'green');
      if (document.getElementById(index + 'YFinishSagyoAmtCu').style.display !== undefined) {
        let showHideCu = document.getElementById(index + 'YFinishSagyoAmtCu').style.display;
        if (showHideCu === 'block') {
          checkInputWithColor(false, '', index + 'YFinishSagyoAmt', newState[index].sagyoAmt, 'red');
        } else {
          if (timeAct === 'input') {
            let timeType = setTimetoSec(value);
            let timeHms = timeType;
            newState[index].sagyoAmt = changeAmtwithTime(newState[index].sagyoAmt, timeType, timeAct, timeHms, objId);
          } else {
            newState[index].sagyoAmt = changeAmtwithTime(newState[index].sagyoAmt, timeType, timeAct, value, objId);
          }
          checkInputWithColor(false, '', index + 'YFinishSagyoAmt', newState[index].sagyoAmt, 'green');
        }
      }
    } else if (objId === 'handelingTime') {
      if (document.getElementById('YousetsuHandlingAmtCu').style.display !== undefined) {
        let showHideCu = document.getElementById('YousetsuHandlingAmtCu').style.display;
        if (showHideCu === 'block') {
          //checkInputWithColor(false,'','KensaHandlingAmt', newState[0].handelingAmt, 'red');
        } else {
          if (timeAct === 'input') {
            let timeType = setTimetoSec(value) - setTimetoSec(oldValue);
            newState[0].handelingAmt = changeAmtwithTime(newState[0].handelingAmt, timeType, timeAct, value, objId);
          } else {
            newState[0].handelingAmt = changeAmtwithTime(newState[0].handelingAmt, timeType, timeAct, value, objId);
          }
          /* checkInputWithColor(false,'','KensaHandlingAmt', newState[0].handelingAmt, 'green');*/
        }
      }
    } else if (objId === 'senchou') {
      for (let j = 0; j < newState?.length; j++) {
        // 作業時間
        // 加工長
        let sagyoLen = value;
        // 溶接線長により該当種類の加工速度を取得
        let sagyoSpeed = 10;
        // 選択された部品の板厚により難易度係数を取得
        let diffCoef = 2.25;
        // サイズ係数
        let sizeCoef = 0.6;
        // 加工長/加工速度＊難易度係数＊サイズ係数
        let sagyoTimeSec = (sagyoLen / sagyoSpeed) * diffCoef * sizeCoef;
        newState[j].sagyoTime = sagyoTimeSec;

        // 作業金額
        let mSagyoTimeMin = setTimetoSec(newState[j].sagyoTime) / 60;
        //装置の作業時間チャージ
        // let mSetHRate = deviceMst.details?.charges[processType].prep;
        let mSagyoHRate = 10000;
        let sagyoAmt = Math.floor((mSagyoTimeMin * mSagyoHRate) / 60.0); //作業時間＊時間チャージ
        if (document.getElementById(index + 'YFinishSagyoAmtCu').style.display !== undefined) {
          let showHideCu = document.getElementById(index + 'YFinishSagyoAmtCu').style.display;
          if (showHideCu === 'none') {
            newState[j].sagyoAmt = sagyoAmt;
          }
        }
      }
    }

    let newSagyoSum = 0;
    let newDandoriSum = 0;
    let newSum = 0;
    let newSubSum = 0;
    let newSagyoTimeSec = 0;
    let newDandoriTimeSec = 0;

    newSubSum = Number(newState[index].sagyoAmt) + Number(newState[index].dandoriAmt);
    newState[index].sumAmt = newSubSum;

    let newSubTimeSum = setTimetoSec(newState[index].sagyoTime) + setTimetoSec(newState[index].dandoriTime);
    newState[index].sumTime = newSubTimeSum;
    /* newState?.forEach((element) => { */

    for (let i = 0; i < newState?.length; i++) {
      newSagyoSum += Number(newState[i].sagyoAmt);
      newDandoriSum += Number(newState[i].dandoriAmt);
      newSagyoTimeSec += setTimetoSec(newState[i].sagyoTime);
      newDandoriTimeSec += setTimetoSec(newState[i].dandoriTime);
    }

    newDandoriSum = newDandoriSum / processCount;
    newDandoriTimeSec = newDandoriTimeSec / processCount;

    newSagyoSum += Number(newState[0].handelingAmt);
    newSagyoTimeSec += setTimetoSec(newState[0].handelingTime);

    newSum = (Number(newSagyoSum) + Number(newDandoriSum)).toFixed(0);

    let newSagyoTimeSum = newSagyoTimeSec;
    let newDandoriTimeSum = newDandoriTimeSec;
    let newTotalTimeSum = newSagyoTimeSec + newDandoriTimeSec;

    newSagyoSum = Math.floor(newSagyoSum);
    newDandoriSum = Math.floor(newDandoriSum);
    newSum = Math.floor(newSum);

    /* checkInputWithColor(false,'',index + 'subTotalYFinish', newSubSum, 'green');
    checkInputWithColor(false,'',index + 'subTotalTimeYFinish', newSubTimeSum, 'green'); */
    checkInputWithColor(false, '', 'totalSagyoAmtYFinish', newSagyoSum, 'green');
    checkInputWithColor(false, '', 'totalDandoriAmtYFinish', newDandoriSum, 'green');
    checkInputWithColor(false, '', 'totalAmtYFinish', newSum, 'green');
    checkInputWithColor(false, '', 'totalSagyoTimeYFinish', newSagyoTimeSum, 'green');
    checkInputWithColor(false, '', 'totalDandoriTimeYFinish', newDandoriTimeSum, 'green');
    checkInputWithColor(false, '', 'totalTimeYFinish', newTotalTimeSum, 'green');
    setTotalSagyoAmt(newSagyoSum);
    setTotalDandoriAmt(newDandoriSum);
    setTotalAmt(newSum);
    setTotalSagyoTime(newSagyoTimeSum);
    setTotalDandoriTime(newDandoriTimeSum);
    setTotalTime(newTotalTimeSum);
    setEditedData(newState);
  };

  const checkStr = (val) => {
    let intAmt;
    if (val === '') {
      intAmt = 0;
    } else if (typeof val === 'string') {
      intAmt = Number(val.charAt(0) == '¥' ? val.replace('¥', '') : val);
    } else intAmt = val;
    return intAmt;
  };

  const changeAmtwithTime = (amt, typeTime, action, timeVal, processType) => {
    let editedAmt;
    let totalSec = Number(setTimetoSec(timeVal));
    let intAmt = checkStr(amt);
    let deviceMst = [];

    if (processType === 'dandoriTime') {
      // 段取金額
      //　段取時間＊時間チャージ
      editedAmt = calDandoriAmt(totalSec, deviceMst);
    } else if (processType === 'sagyoTime') {
      // 作業金額
      //　加工時間＊時間チャージ
      editedAmt = calSagyoAmt(totalSec, deviceMst);
    } else if (processType === 'handelingTime') {
      // マテハン金額
      //　マテリアルハンドリング時間＊時間チャージ
      editedAmt = calSagyoAmt(totalSec, deviceMst);
    }

    return editedAmt;
  };

  return (
    <div className="kouteiCalculateDiv">
      <Row style={{ color: 'black' }} className="oyabuhin oyabuhin-row " onClick={yosetsuFinish}>
        <Col span={4} className="oyabuhin-col">
          <div style={{ display: 'flex' }}>
            {isYosetsuFinishdetail ? (
              <CaretUpOutlined style={{ fontSize: 17, marginTop: 2 }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: 17, marginTop: 2 }} />
            )}
            <label style={{ marginLeft: 5 }}>{processMst?.name}</label>
          </div>
        </Col>
        <Col span={6} className="oyabuhin-col">
          <div>{weldingFinishAmt ? JPYs.format(Math.floor(weldingFinishAmt)) : amtEmptyStr}</div>
        </Col>
        <Col span={13} style={{ border: 'none' }} className="oyabuhin-col">
          <div>
            <label style={{ marginLeft: 5 }}>{deviceMst?.name}</label>
          </div>
        </Col>
      </Row>
      <div style={{ display: isYosetsuFinishdetail ? 'block' : 'none' }}>
        {/* 工程詳細 */}
        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col">
            工程詳細
          </Col>
          <Col span={6} className="oyabuhin-detail-col">
            <Checkbox
              key={kouteiCalculateSelectedArr[0].key}
              value={kouteiCalculateSelectedArr[0].value}
              checked={kouteiCalculateSelectedArr[0].key == updateEditedData.dataType}
              disabled={!props?.editMode}
              style={{ marginRight: 6 }}
              onChange={(e) => props?.editMode && handleChangeCheckData(kouteiCalculateSelectedArr[0].key)}
            ></Checkbox>
            <label>{kouteiCalculateSelectedArr[0].value}</label>
          </Col>
          <Col span={8} className="oyabuhin-detail-col">
            <Checkbox
              key={kouteiCalculateSelectedArr[1].key}
              value={kouteiCalculateSelectedArr[1].value}
              disabled={!props?.editMode}
              onChange={(e) => props?.editMode && handleChangeCheckData(kouteiCalculateSelectedArr[1].key)}
              checked={kouteiCalculateSelectedArr[1].key == updateEditedData.dataType}
              style={{ marginRight: 6 }}
            ></Checkbox>
            <label>{kouteiCalculateSelectedArr[1].value}</label>
          </Col>
          <Col span={1} className="oyabuhin-detail-col">
            単位
          </Col>
          <Col span={5} className="formula-column">
            計算式
          </Col>
        </Row>
        {/* 工程詳細 */}
        {/*  溶接仕上 */}
        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col">
            {processMst?.name}
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalAmtYFinish">{totalSum ? JPYs.format(Math.floor(totalSum)) : amtEmptyStr}</label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalTimeYFinish">{totalTimeSum ? secondsToHms(totalTimeSum) : timeHmsStr}</label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalAmtYFinishIn" className="resetColor">
              {totalAmt ? JPYs.format(Math.floor(totalAmt)) : amtEmptyStr}
            </label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalTimeYFinishIn" className="resetColor">
              {totalTime ? secondsToHms(totalTime) : timeHmsStr}
            </label>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={5} className="formula-column"></Col>
        </Row>
        {/* 溶接仕上 */}
        {/* 段取金額/時間 */}
        <Row
          style={{ color: 'black' }}
          className="oyabuhin-detail oyabuhin-row"
          onClick={(e) => handleCollpse('YFinishDandori')}
        >
          <Col span={4} className="oyabuhin-detail-col">
            <div style={{ display: 'flex' }}>
              <CaretUpOutlined id={'arrowUpYFinishDandori'} style={{ fontSize: 17, marginTop: 2, display: 'none' }} />

              <CaretDownOutlined id={'arrowDownYFinishDandori'} style={{ fontSize: 17, marginTop: 2 }} />
              <label style={{ marginLeft: 5 }}> 段取金額/時間</label>
            </div>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalDandoriAmtYFinish">
              {dandoriAmtSum ? JPYs.format(Math.floor(dandoriAmtSum)) : amtEmptyStr}
            </label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalDandoriTimeYFinish">{dandoriTimeSum ? secondsToHms(dandoriTimeSum) : timeHmsStr}</label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalDandoriAmtYFinishIn" className="resetColor">
              {totalDandoriAmt ? JPYs.format(Math.floor(totalDandoriAmt)) : amtEmptyStr}
            </label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalDandoriTimeYFinishIn" className="resetColor">
              {totalDandoriTime ? secondsToHms(totalDandoriTime) : timeHmsStr}
            </label>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={5} className="formula-column">
            <Popover content={contentDandori}>段取金額の総合計</Popover>
          </Col>
        </Row>
        <div id={'divYFinishDandori'} style={{ display: 'none' }}>
          {(() => {
            const arr = [];
            for (let j = 0; j < processArr[0]?.dandoriDetails?.length; j++) {
              let processDetailOr = processArr[0]?.dandoriDetails[j];
              let processDetailOrEdit = editedData[0]?.dandoriDetails[j];
              arr.push(
                <div>
                  {/* ○○_標準 */}
                  <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                    <Col span={4} className="oyabuhin-detail-col">
                      <div style={{ display: 'flex' }}>
                        <span style={{ marginLeft: '20px' }}>{processDetailOr?.processDetailName}</span>
                      </div>
                    </Col>
                    <Col span={3} className="oyabuhin-detail-col">
                      <label id={j + 'YFinishDandoriAmt'}>
                        {processDetailOr?.dandoriAmt
                          ? JPYs.format(Math.floor(processDetailOr?.dandoriAmt))
                          : amtEmptyStr}
                      </label>
                    </Col>
                    <Col span={3} className="oyabuhin-detail-col">
                      {
                        <label id={j + 'YFinishDandoriTime'}>
                          {processDetailOr?.dandoriTime ? secondsToHms(processDetailOr?.dandoriTime) : timeHmsStr}
                        </label>
                      }
                    </Col>

                    <Col span={4} className="input-col">
                      <CurrencyInput
                        id={j + 'YFinishDandoriAmtIn'}
                        value={processDetailOrEdit?.dandoriAmt ? Math.floor(processDetailOrEdit?.dandoriAmt) : 0}
                        defaultValue={0}
                        style={{
                          border: 'none',
                          color:
                            Number(processDetailOr?.dandoriAmt) === Number(processDetailOrEdit?.dandoriAmt)
                              ? 'black'
                              : checkShowHideIcon(j + 'YFinishDandoriAmtCu') === 'none'
                              ? '#4cc12f'
                              : 'red',
                        }}
                        onValueChange={(e) => {
                          //checkRegex(e.target.value);
                          if (editedData[0]?.dandoriAmt != e) {
                            settingNewDandoriAmtInArr(e, j, 'dandoriAmt', '', '');
                            checkInputWithColor(false, '', j + 'YFinishDandoriAmt', e, 'amt');
                          }
                        }}
                        className={
                          editMode ? 'input-editable currency resetColor' : 'input-non-editable currency resetColor'
                        }
                        prefix="¥"
                        decimalsLimit={100}
                      />
                      <div id={j + 'YFinishDandoriAmtCu'} style={{ display: 'none' }}>
                        <div className="currencyIcon">
                          <BsUsbC style={{ fontSize: '25' }} />
                          <BsX style={{ margin: '-8px 0 0 16px' }} />
                          <span class="tooltiptext">
                            金額に変更がありましたので、以降時間と連携して自動計算されない。
                          </span>
                        </div>
                      </div>
                    </Col>

                    <Col span={4} className="input-col">
                      <Row style={{ width: '100%' }}>
                        <Col className="timer-input-col">
                          <Input
                            value={
                              processDetailOrEdit?.dandoriTime
                                ? secondsToHms(processDetailOrEdit?.dandoriTime)
                                : timeHmsStr
                            }
                            id={j + 'YFinishDandoriTimeIn'}
                            style={{
                              width: '100%',
                              color:
                                processDetailOr?.dandoriTime === processDetailOrEdit?.dandoriTime
                                  ? handleShowHide(
                                      Number(processDetailOrEdit?.dandoriTime),
                                      processDetailOrEdit?.dandoriTime,
                                      j + 'YFinishDandoriAmtCu'
                                    )
                                  : 'red',
                            }}
                            className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                            onChange={(e) => {
                              settingNewDandoriAmtInArr(e.target.value, j, 'YFinishDandoriAmt', 'input', 'input');
                            }}
                          ></Input>
                        </Col>
                        <Col className="timer-button-col">
                          {editMode ? (
                            <>
                              <CaretUpOutlined
                                style={{ fontSize: 17 + 'px' }}
                                onClick={() => {
                                  let editedTime = handelIncreaseTime(
                                    processDetailOrEdit?.dandoriTime,
                                    '10min',
                                    j + 'YFinishDandoriTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', '10min', 'inc');
                                }}
                              />
                              <CaretDownOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelDecreaseTime(
                                    processDetailOrEdit?.dandoriTime,
                                    '10min',
                                    j + 'YFinishDandoriTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', '10min', 'dec');
                                }}
                              />
                              <CaretUpOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelIncreaseTime(
                                    processDetailOrEdit?.dandoriTime,
                                    'min',
                                    j + 'YFinishDandoriTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'min', 'inc');
                                }}
                              />
                              <CaretDownOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelDecreaseTime(
                                    processDetailOrEdit?.dandoriTime,
                                    'min',
                                    j + 'YFinishDandoriTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'min', 'dec');
                                }}
                              />
                              <CaretUpOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelIncreaseTime(
                                    processDetailOrEdit?.dandoriTime,
                                    'sec',
                                    j + 'YFinishDandoriTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'sec', 'inc');
                                }}
                              />
                              <CaretDownOutlined
                                style={{ fontSize: 17 + 'px', color: '#000' }}
                                onClick={() => {
                                  let editedTime = handelDecreaseTime(
                                    processDetailOrEdit?.dandoriTime,
                                    'sec',
                                    j + 'YFinishDandoriTime'
                                  );
                                  settingNewDandoriAmtInArr(editedTime, j, 'dandoriTime', 'sec', 'dec');
                                }}
                              />
                            </>
                          ) : (
                            <></>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col span={1} className="oyabuhin-detail-col"></Col>
                    <Col span={5} className="formula-column"></Col>
                  </Row>
                </div>
              );
            }
            return arr;
          })()}
        </div>
        {/* 段取金額/時間 */}

        {/* 作業金額/時間 */}
        {/* 作業合計 */}
        <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
          <Col span={4} className="oyabuhin-detail-col" onClick={(e) => handleCollpse('YFinishSagyoTotal')}>
            <div style={{ display: 'flex' }}>
              <CaretUpOutlined
                id={'arrowUpYFinishSagyoTotal'}
                style={{ fontSize: 17, marginTop: 2, display: 'none' }}
              />
              <CaretDownOutlined id={'arrowDownYFinishSagyoTotal'} style={{ fontSize: 17, marginTop: 2 }} />
              <label style={{ marginLeft: 5 }}> 作業金額/時間</label>
            </div>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalSagyoAmtYFinish">{sagyoAmtSum ? JPYs.format(Math.floor(sagyoAmtSum)) : amtEmptyStr}</label>
          </Col>
          <Col span={3} className="oyabuhin-detail-col">
            <label id="totalSagyoTimeYFinish">{sagyoTimeSum ? secondsToHms(sagyoTimeSum) : timeHmsStr}</label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalSagyoAmtYFinishIn" className="resetColor">
              {totalSagyoAmt ? JPYs.format(Math.floor(totalSagyoAmt)) : amtEmptyStr}
            </label>
          </Col>
          <Col span={4} className="oyabuhin-detail-col">
            <label id="totalSagyoTimeYFinishIn" className="resetColor">
              {totalSagyoTime ? secondsToHms(totalSagyoTime) : timeHmsStr}
            </label>
          </Col>
          <Col span={1} className="oyabuhin-detail-col"></Col>
          <Col span={5} className="formula-column">
            <Popover content={contentSagyo}>作業金額の総合計</Popover>
          </Col>
        </Row>
        <div id={'divYFinishSagyoTotal'} style={{ display: 'none' }}>
          {(() => {
            const arr = [];
            for (let i = 0; i < processArr?.length; i++) {
              arr.push(
                <div>
                  <Row
                    style={{ color: 'black' }}
                    className="oyabuhin-detail oyabuhin-row"
                    onClick={(e) => subpartDetail(i)}
                  >
                    <Col span={4} className="oyabuhin-detail-col">
                      <div style={{ display: 'flex', marginLeft: '20px' }}>
                        <CaretUpOutlined id={'arrowUpYosetsuFinish' + i} style={{ fontSize: 17, marginTop: 2 }} />
                        <CaretDownOutlined
                          id={'arrowDownYosetsuFinish' + i}
                          style={{ fontSize: 17, marginTop: 2, display: 'none' }}
                        />
                        <span style={{ marginLeft: '5px' }}>{processArr[i]?.processName}</span>
                      </div>
                    </Col>
                    <Col span={3} className="oyabuhin-detail-col">
                      <label id={i + 'yosetsuSagyoAmt'}>
                        {processArr[i]?.sagyoAmt ? JPYs.format(Math.floor(processArr[i]?.sagyoAmt)) : amtEmptyStr}
                      </label>
                    </Col>
                    <Col span={3} className="oyabuhin-detail-col">
                      <label id={i + 'yosetsuSagyoTime'}>
                        {processArr[i]?.sagyoTime ? secondsToHms(processArr[i]?.sagyoTime) : timeHmsStr}
                      </label>
                    </Col>
                    <Col span={4} className="oyabuhin-detail-col">
                      <label
                        id={i + 'yosetsuSagyoAmtIn'}
                        style={{
                          color: processArr[i]?.sagyoAmt === editedData[i]?.sagyoAmt ? 'black' : '#4cc12f',
                        }}
                      >
                        {editedData[i]?.sagyoAmt ? JPYs.format(Math.floor(editedData[i]?.sagyoAmt)) : amtEmptyStr}
                      </label>
                    </Col>
                    <Col span={4} className="oyabuhin-detail-col">
                      <label
                        id={i + 'yosetsuSagyoTimeIn'}
                        style={{
                          color: processArr[i]?.sagyoTime === editedData[i]?.sagyoTime ? 'black' : '#4cc12f',
                        }}
                      >
                        {editedData[i]?.sagyoTime ? secondsToHms(editedData[i]?.sagyoTime) : timeHmsStr}
                      </label>
                    </Col>
                    <Col span={1} className="oyabuhin-detail-col"></Col>
                    <Col span={5} className="formula-column"></Col>
                  </Row>
                  <div id={'yosetsuFinishSagyo' + i} style={{ display: 'none' }}>
                    {(() => {
                      const arr = [];
                      for (let j = 0; j < processArr[i]?.sagyoDetails?.length; j++) {
                        let sagyoProcessDetailOr = processArr[i]?.sagyoDetails[j];
                        let sagyoProcessDetailOrEdit = editedData[i]?.sagyoDetails[j];
                        arr.push(
                          <div>
                            {/* ○○_標準 */}
                            <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                              <Col span={4} className="oyabuhin-detail-col">
                                <div style={{ display: 'flex' }}>
                                  <span style={{ marginLeft: '45px' }}>{sagyoProcessDetailOr.processDetailName}</span>
                                </div>
                              </Col>
                              <Col span={3} className="oyabuhin-detail-col">
                                <label id={j + 'YFinishSagyoAmt'}>
                                  {sagyoProcessDetailOr?.sagyoAmt
                                    ? JPYs.format(Math.floor(sagyoProcessDetailOr?.sagyoAmt))
                                    : amtEmptyStr}
                                </label>
                              </Col>
                              <Col span={3} className="oyabuhin-detail-col">
                                {
                                  <label id={j + 'YFinishSagyoTime'}>
                                    {sagyoProcessDetailOr?.sagyoTime
                                      ? secondsToHms(sagyoProcessDetailOr?.sagyoTime)
                                      : timeHmsStr}
                                  </label>
                                }
                              </Col>

                              <Col span={4} className="input-col">
                                <CurrencyInput
                                  id={j + 'YFinishSagyoAmtIn'}
                                  value={
                                    sagyoProcessDetailOrEdit?.sagyoAmt
                                      ? Math.floor(sagyoProcessDetailOrEdit?.sagyoAmt)
                                      : 0
                                  }
                                  defaultValue={0}
                                  style={{
                                    border: 'none',
                                    color:
                                      sagyoProcessDetailOr?.sagyoAmt === sagyoProcessDetailOrEdit?.sagyoAmt
                                        ? 'black'
                                        : checkShowHideIcon(j + 'YFinishSagyoAmtCu') === 'none'
                                        ? '#4cc12f'
                                        : 'red',
                                  }}
                                  onValueChange={(e) => {
                                    //checkRegex(e.target.value);
                                    if (sagyoProcessDetailOrEdit?.sagyoAmt != e) {
                                      settingNewDandoriAmtInArr(e, j, 'sagyoAmt', '', '');
                                      checkInputWithColor(false, '', j + 'YFinishSagyoAmt', e, 'amt');
                                    }
                                  }}
                                  className={
                                    editMode
                                      ? 'input-editable currency resetColor'
                                      : 'input-non-editable currency resetColor'
                                  }
                                  prefix="¥"
                                  decimalsLimit={100}
                                />
                                <div id={j + 'YFinishSagyoAmtCu'} style={{ display: 'none' }}>
                                  <div className="currencyIcon">
                                    <BsUsbC style={{ fontSize: '25' }} />
                                    <BsX style={{ margin: '-8px 0 0 16px' }} />
                                    <span class="tooltiptext">
                                      金額に変更がありましたので、以降時間と連携して自動計算されない。
                                    </span>
                                  </div>
                                </div>
                              </Col>

                              <Col span={4} className="input-col">
                                <Row style={{ width: '100%' }}>
                                  <Col className="timer-input-col">
                                    <Input
                                      value={
                                        sagyoProcessDetailOrEdit.sagyoTime
                                          ? secondsToHms(sagyoProcessDetailOrEdit?.sagyoTime)
                                          : timeHmsStr
                                      }
                                      id={j + 'YFinishSagyoTimeIn'}
                                      style={{
                                        width: '100%',
                                        color:
                                          sagyoProcessDetailOr?.sagyoTime === sagyoProcessDetailOrEdit?.sagyoTime
                                            ? 'black'
                                            : // : sagyoProcessDetailOr?.senchou !== sagyoProcessDetailOrEdit?.senchou
                                            // ? '#4cc12f'
                                            sagyoProcessDetailOr?.sagyoTime !== sagyoProcessDetailOrEdit?.sagyoTime
                                            ? 'red'
                                            : 'undefined', // Keep the existing color
                                      }}
                                      className={
                                        editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'
                                      }
                                      onChange={(e) => {
                                        settingNewDandoriAmtInArr(e.target.value, j, 'sagyoTime', 'input', 'input');
                                      }}
                                    ></Input>
                                  </Col>
                                  <Col className="timer-button-col">
                                    {editMode ? (
                                      <>
                                        <CaretUpOutlined
                                          style={{ fontSize: 17 + 'px' }}
                                          onClick={() => {
                                            let editedTime = handelIncreaseTime(
                                              sagyoProcessDetailOrEdit?.sagyoTime,
                                              '10min',
                                              j + 'YFinishSagyoTime'
                                            );
                                            settingNewDandoriAmtInArr(editedTime, j, 'sagyoTime', '10min', 'inc');
                                          }}
                                        />
                                        <CaretDownOutlined
                                          style={{ fontSize: 17 + 'px', color: '#000' }}
                                          onClick={() => {
                                            let editedTime = handelDecreaseTime(
                                              sagyoProcessDetailOrEdit?.sagyoTime,
                                              '10min',
                                              j + 'YFinishSagyoTime'
                                            );
                                            settingNewDandoriAmtInArr(editedTime, j, 'sagyoTime', '10min', 'dec');
                                          }}
                                        />
                                        <CaretUpOutlined
                                          style={{ fontSize: 17 + 'px', color: '#000' }}
                                          onClick={() => {
                                            let editedTime = handelIncreaseTime(
                                              sagyoProcessDetailOrEdit?.sagyoTime,
                                              'min',
                                              j + 'YFinishSagyoTime'
                                            );
                                            settingNewDandoriAmtInArr(editedTime, j, 'sagyoTime', 'min', 'inc');
                                          }}
                                        />
                                        <CaretDownOutlined
                                          style={{ fontSize: 17 + 'px', color: '#000' }}
                                          onClick={() => {
                                            let editedTime = handelDecreaseTime(
                                              sagyoProcessDetailOrEdit?.sagyoTime,
                                              'min',
                                              j + 'YFinishSagyoTime'
                                            );
                                            settingNewDandoriAmtInArr(editedTime, j, 'sagyoTime', 'min', 'dec');
                                          }}
                                        />
                                        <CaretUpOutlined
                                          style={{ fontSize: 17 + 'px', color: '#000' }}
                                          onClick={() => {
                                            let editedTime = handelIncreaseTime(
                                              sagyoProcessDetailOrEdit?.sagyoTime,
                                              'sec',
                                              j + 'YFinishSagyoTime'
                                            );
                                            settingNewDandoriAmtInArr(editedTime, j, 'sagyoTime', 'sec', 'inc');
                                          }}
                                        />
                                        <CaretDownOutlined
                                          style={{ fontSize: 17 + 'px', color: '#000' }}
                                          onClick={() => {
                                            let editedTime = handelDecreaseTime(
                                              sagyoProcessDetailOrEdit?.sagyoTime,
                                              'sec',
                                              j + 'YFinishSagyoTime'
                                            );
                                            settingNewDandoriAmtInArr(editedTime, j, 'sagyoTime', 'sec', 'dec');
                                          }}
                                        />
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </Col>
                                </Row>
                              </Col>
                              <Col span={1} className="oyabuhin-detail-col"></Col>
                              <Col span={5} className="formula-column"></Col>
                            </Row>
                          </div>
                        );
                      }
                      return arr;
                    })()}
                    <Row style={{ color: 'black' }} className="oyabuhin-detail oyabuhin-row">
                      <Col span={4} className="oyabuhin-detail-col">
                        <span className="ddl_2_noImg" style={{ marginLeft: '45px' }}>
                          加工長({processArr[i]?.processName})
                        </span>
                      </Col>
                      <Col span={3} className="oyabuhin-detail-col">
                        <label id={i + 'senchou'}>{processArr[i]?.senchou}</label>
                      </Col>
                      <Col span={3} className="oyabuhin-detail-col"></Col>
                      <Col span={4} className="input-col">
                        <Input
                          value={editedData[i]?.senchou}
                          id={i + 'senchouIn'}
                          min={0}
                          className={editMode ? 'input-editable resetColor' : 'input-non-editable resetColor'}
                          controls={false}
                          style={{
                            width: '45%',
                            border: 'none',
                            color: Number(editedData[i]?.senchou) === Number(processArr[i]?.senchou) ? 'black' : 'red',
                          }}
                          onKeyPress={(e) => {
                            handleKeyPress(e, false);
                          }}
                          onChange={(e) => settingNewDandoriAmtInArr(e.target.value, i, 'senchou', '', '')}
                        />
                      </Col>

                      <Col span={4} className="oyabuhin-detail-col"></Col>

                      <Col span={1} className="oyabuhin-detail-col"></Col>
                      <Col span={5} className="formula-column"></Col>
                    </Row>
                  </div>
                </div>
              );
            }
            return arr;
          })()}
        </div>
      </div>
    </div>
  );
});

export default IQ3KouteiCalculate_YosetsuFinish;
