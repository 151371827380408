/**
 * クラス名：板金子部品見積
 * 説明：板金子部品のタブ纏めJSファイルである。
 * 作成者：ナンス
 * 作成日：2023/03/01
 * バージョン：1.0
 */
import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import { Table, Form, Button, Row, Input, Col, Space, Image, Tooltip, Tabs, Modal, Checkbox } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { CaretUpOutlined, CaretDownOutlined, CloseOutlined, SettingOutlined, SettingFilled } from '@ant-design/icons';
import { Route, Routes } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';

import '../../assets/styles/common.css';
import '../../assets/styles/style.css';
import down_Arrow from '../../assets/images/down_Arrow.png';
import up_Arrow from '../../assets/images/up_Arrow.png';
import child_add from '../../assets/images/child_add.png';
import child_delete from '../../assets/images/child_delete.png';
import setting_icon from '../../assets/images/setting_icon.png';
import IQ3EstimateDetail from './IQ3EstimateDetail';
import KouteiSuryouBetsuBtn from '../parent/KouteiSuryouBetsuBtn';
import IQ3Purchase from './IQ3Purchase';
import IQ3KouteiCalculate from '../iq3/IQ3KouteiCalculate';
import IQ3KouteiInput from '../iq3/IQ3KouteiInput';
import IQ3KouteiSentaku from '../iq3/IQ3KouteiSentaku';
import IQ3MaterialDetail from './IQ3MaterialDetail';
import {
  commonTaiochuModal,
  confirmUpdateContent,
  confirmDiscardContent,
  confirmScreenChangeContent,
  confirmScreenChangeTitle,
  confirmModalTitle,
  confirmDeleteContent,
  confirmDiscardAndDeleteContent,
  commonModal,
  commonFooter,
  commonDeleteFooter,
} from '../common/CommonModal';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { Prev } from 'react-bootstrap/esm/PageItem';
import parent_call_detail from '../../assets/images/parent_call_detail.png';
import ParentList from '../parent/ParentList';
import {
  getMaterialNameById,
  getMaterialTypeById,
  getMaterialSurfaceById,
  getMaterialThickById,
  iq3EstimateInitInfo,
  padWithLeadingZeros,
  formatDateString,
  todayTime,
  toDecimal,
  updateEstimateInfoByReCal,
  JPYs,
} from '../common/Common.js';
import ParentPurchase from '../parent/ParentPurchase';
import { GetParametersByCompId, getEstimateIq3Info } from '../common/CommonAPI.js';
import {
  ParamCalType,
  ClientType,
  WorkType,
  DataType,
  ServiceClass,
  SurfaceProtectionType,
  EstimateCreationType,
  PatternType,
  WorkTypeGroup,
} from '../common/enums.js';
import { amtEmptyStr } from '../common/Constant';

dayjs.extend(customParseFormat);

const IQ3Estimate = forwardRef((props, ref) => {
  const navigate = useNavigate();
  const location = useLocation();
  // const [selectedRowKeys, setSelectedRowKeys] = useState([0]);
  const [isListOpen1, setIsListOpen1] = useState(false);
  const [isListClose1, setIsListClose1] = useState(false);
  const [isListOpenAll, setIsListOpenAll] = useState(true);
  const [selectedData, setSelectedData] = React.useState();
  const [modalTitleTxt, setMdalTitleTxt] = useState('確認');

  // 確認ダイアログ
  const [confirmMsgOpen, setConfirmMsgOpen] = useState(false);
  const [editMode, setEditMode] = useState(true);
  const [discardMode, setDiscardMode] = useState(false);
  const [iQ3DataLst, setIQ3DataLst] = useState([]);
  // const [targetRowKey, setTargetRowKey] = useState([0]);
  const [selectedEstimateData, setSelectedEstimateData] = useState([]);
  const [editModeCancelConfirm, setEditModeCancelConfirm] = useState(false);
  const [currentRecordNo, setCurrentRecordNo] = useState(0);
  const [currentRecord, setCurrentRecord] = useState([]);

  // 開発中ポップアップ
  const [openCommonTaiochuPopup, setOpenCommonTaiochuPopup] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState('1');
  const [selectedTabKey, setSelectedTabKey] = useState('1');
  const [isSelectedTab, setIsSelectedTab] = useState(false);

  const [isDelete, setIsDelete] = useState(false);
  const [childTitle, setChildTitle] = useState('');

  const [parentListDisplay, setParentListDisplay] = useState(false);
  const [selectedParentAddEstimate, setSelectedParentAddEstimate] = useState([]);

  const [isAddPart, setIsAddPart] = useState(false);

  const [materialLst, setMaterialLst] = useState([]);
  const [materialTypeLst, setMaterialTypeLst] = useState([]);
  const [materialThickLst, setMaterialThickLst] = useState([]);
  const [materialSurfaceLst, setMaterialSurfaceLst] = useState([]);
  const [materialSurface, setMaterialSurface] = useState([]);

  const [isSetting, setIsSetting] = useState(false);

  const [deviceMasterInfo, setDeviceMasterInfo] = useState([]);
  const [processMasterInfo, setProcessMasterInfo] = useState([]);
  const [clientMasterInfo, setClientMasterInfo] = useState([]);
  const [discardModeKouteiCal, setDiscardModeKouteiCal] = useState(false);

  const [newParameters, setNewParameters] = useState();

  const [quantity, setQuantity] = useState(1);

  // 材料原価合計
  const [materialTotalPrice, setMaterialTotalPrice] = useState(0);
  // 材料単価
  const [materialUnitPrice, setMaterialUnitPrice] = useState(0);

  // 板金子部品プロパティ情報
  let iq3DetailRef = useRef();

  // 工程選択
  let iq3KouteiSentakuRef = useRef();

  // 工程計算
  let iq3KouteiCalRef = useRef();

  // 工程入力
  let iq3KouteiInputRef = useRef();

  // 購入品
  let purchaseRef = useRef();

  // 材料詳細
  let iq3MaterialDetailRef = useRef();

  const currentRoute = location.pathname;

  const estimateParameters = props.selectedEstimateData?.estimateProducts?.calcParameters?.parameters;

  useEffect(() => {}, [props.materialLst, props.materialTypeLst, props.materialSurfaceLst]);

  //親部品・子部品タブ切替により呼び出す
  useEffect(() => {}, [props.activePartMenu]);

  //板金情報変更により呼び出す
  useEffect(() => {
    // if (props.type) {
    //   initPMXIq3EstimateInfo(props.selectedEstimateData);
    // } else {
    initIq3EstimateInfo(props.selectedEstimateData);
    // }
  }, [props.selectedEstimateData, props.type, props.activePartMenu]);

  // 板金部品見積もりの初期化
  const initIq3EstimateInfo = async (selectedEstimateData) => {
    if (selectedEstimateData === undefined) {
      return;
    }
    let iq3Infos = [];
    if (!props.type) {
      // 新規モードの場合、
      if (selectedEstimateData?.estimateProducts?.id == 0) {
        let iq3Data = [...iq3EstimateInitInfo];
        let newIQ3 = getNewIQ3EstimateInfo(iq3Data);
        iq3Infos = newIQ3;
        setIQ3DataLst(newIQ3);
      } else {
        //　板金情報リストを取得
        let estimateId = selectedEstimateData?.estimateProducts?.id;
        if (estimateId) iq3Infos = await getEstimateIQ3Data(estimateId);
      }
      selectedEstimateData.esIq3Info = iq3Infos;
    } else {
      let newIQ3 = selectedEstimateData?.esIq3Info;
      iq3Infos = newIQ3;
      setIQ3DataLst(iq3Infos);
    }
    let selectedData = iq3Infos?.length > 0 ? iq3Infos?.[0] : {};
    // setQuantity(props?.getParentQuantity()); // 入力した数量情報を得る
    setSelectedEstimateData(selectedEstimateData);
    setCurrentRecordNo(selectedData?.no ? selectedData?.no : 0);
    setSelectedData(selectedData);

    let parameters = selectedEstimateData?.estimateProducts?.calcParameters?.parameters;
    // 材料リスト
    let materialList = parameters?.materialIq3;
    setMaterialLst(materialList);
    // 板厚リスト
    // let materialThickLst = materialList?.filter((item) => item.key == childPartDetail.materialNm);
    // setMaterialThickLst(materialThickLst);
    // 表面保護
    const materialSurfaceData = parameters?.materialSurfaceIq3;
    setMaterialSurfaceLst(materialSurfaceData);
    // 材質リスト
    setMaterialTypeLst(parameters?.materialTypeIq3);

    if (parameters?.process?.length > 0) {
      let processMstData = parameters?.process?.filter((process) => process?.class === ServiceClass.SheetMetal);
      setProcessMasterInfo(processMstData);

      if (parameters?.device?.length > 0) {
        let deviceLst = parameters?.device?.filter((item) =>
          processMstData?.filter((process) => process?.id === item?.processId)
        );
        setDeviceMasterInfo(deviceLst);
      }
    }
    if (parameters?.client?.length > 0) {
      setClientMasterInfo(parameters?.client);
    }

    setActiveTabKey('1');
  };

  // 工程入力データ作成
  const getDetails = (workType, isAnyTrue) => {
    let parameters = props.selectedEstimateData?.estimateProducts?.calcParameters?.parameters;

    let subWeldingType = 0;
    let subWeldingGroupType = 0;
    let secondaryWorkProcessDetailsId = 1;
    let secondaryWorkProcessDetailsGroup = 10310;
    const weldingDetails = parameters?.process?.filter((i) => i.workType === WorkType.SmWelding);
    const secondaryWorkDetails = parameters?.process?.filter((i) => i.workType === WorkType.SmSecondaryWork);
    if (secondaryWorkDetails?.length > 0) {
      secondaryWorkProcessDetailsId = secondaryWorkDetails[0].details?.processDetailTypes[0].id;
      secondaryWorkProcessDetailsGroup = secondaryWorkDetails[0].details?.processDetailTypes[0].group;
    }
    if (weldingDetails?.length > 0) {
      let subYousetsu = weldingDetails[0].details?.processDetailTypes?.filter(
        (i) => i.group === WorkTypeGroup.SmInstSize || i.group === WorkTypeGroup.SmInstThick
      );
      if (subYousetsu?.length > 0) {
        subWeldingType = subYousetsu[0].id;
        subWeldingGroupType = subYousetsu[0].group;
      }
    }
    if (workType === WorkType.SmProgram) {
      if (isAnyTrue) {
        return [{ elements: 1, elementsAuto: 1 }];
      } else {
        return [{ elements: 0, elementsAuto: 0 }];
      }
      // return [{ elements: 0, elementsAuto: 0 }];
    } else if (
      workType === WorkType.SmBlankCombi ||
      workType === WorkType.SmBlankLaser ||
      workType === WorkType.SmBlankPunch
    ) {
      return [
        {
          dimensionData: {
            outerDia: 0,
            outerAutoExtract: 0,
            innerDia: 0,
            innerAutoExtract: 0,
            airFeedDistance: 0,
            airFeedAutoExtract: 0,
          },
          quantityList: [{ id: 1, types: 1, diameter: 0, xsize: 0, ysize: 0, perimeter: 0, count: 1 }],
        },
      ];
    } else if (workType === WorkType.SmSecondaryWork) {
      return [
        {
          id: 1,
          types: secondaryWorkProcessDetailsId,
          diameter: 0,
          xsize: 0,
          ysize: 0,
          perimeter: 0,
          count: 1,
          blankFlag: false,
          workTypeGroup: secondaryWorkProcessDetailsGroup,
        },
      ];
    } else if (workType === WorkType.SmBending) {
      return [
        {
          bendingData: { maxBendLength: 0, maxBendAutoExtract: 0 },
          bendingList: [
            {
              id: 1,
              types: 1,
              bendLength: 0,
              lines: 1,
              bendCount: 1,
            },
          ],
        },
      ];
    } else if (workType === WorkType.SmDeburringAuto) {
      return [
        {
          id: 1,
          types: '自動',
          area: 0,
          areaAutoExtract: 0,
          weight: 0,
          weightAutoExtract: 0,
          count: 1,
        },
      ];
    } else if (workType === WorkType.SmDeburringManual) {
      return [
        { id: 1, types: '手動', target: '外形', length: 0, autoExtract: 0, count: 1 },
        { id: 2, types: '手動', target: '内形', length: 0, autoExtract: 0, count: 1 },
      ];
    } else if (workType === WorkType.SmWelding) {
      return [
        {
          weldingList: [
            {
              id: 1,
              yousetsuType: 1,
              senchou: 0,
              senchouKaisu: 0,
              senchouYousetsushiage: 0,
              total1: 0,

              karitsukeCnt: 0,
              karitsukeKaisu: 0,
              karitsukeYousetsushiage: 0,
              total2: 0,
            },
          ],
          subWeldingList: [
            {
              id: 1,
              subWeldingType: subWeldingType,
              daimeter: 1,
              number: 0,
              count: 0,
              yousetsushiage: 1,
              total: 0,
              subWeldingGroupType: subWeldingGroupType,
            },
          ],
        },
      ];
    } else if (workType === WorkType.SmSurface) {
      return [
        {
          no: 1,
          calcType: 1,
          workType: 300,
          areaCount: 0,
          totalArea: 0,
          totalWeight: 0,
          weightCount: 0,
          areaUnitPrice: 0,
          areaAutoExtract: 0,
          weightUnitPrice: 0,
          weightAutoExtract: 0,
          processDetailTypeNo: 0,
          processDetailWorkTypeGroup: 0,
        },
      ];
    } else if (workType === WorkType.SmShearing || workType === WorkType.SmInspection) {
      return [{ numOfCuts: 1 }];
    } else {
      return []; // Default empty details for other deviceId values
    }
  };

  // 新規モードの場合、板金部品情報を初期化する
  const getNewIQ3EstimateInfo = (lists) => {
    let parameters = props.selectedEstimateData?.estimateProducts?.calcParameters?.parameters;

    const patternLst = parameters?.processPattern; // 工程パターンマスタデータ
    let kouteiPatternList = patternLst?.filter((item) => item.class === ServiceClass.SheetMetal);

    const materialType = parameters?.materialTypeIq3; // 材質マスタデータ
    let materialTypeList = materialType?.filter((item) => item.info.isUsed);

    const material = parameters?.materialIq3; // 材料マスタデータ
    let materialList = material?.filter(
      (item) =>
        item.info.isUsed && item.materialTypeIq3Id === (materialTypeList?.length > 0 ? materialTypeList[0]?.id : 0)
    );

    const materialSurface = parameters?.materialSurfaceIq3; // 材料表面マスタデータ
    let materialSurfaceList = materialSurface?.filter(
      (item) =>
        item.info.isUsed && item.materialTypeIq3Id === (materialTypeList?.length > 0 ? materialTypeList[0]?.id : 0)
    );

    // 工程マスタ情報取得
    let processMaster = [];
    const processMstData = parameters?.process; // 工程マスタデータ
    processMaster = processMstData?.filter((process) => process?.class === ServiceClass.SheetMetal);
    let blankIsUsedData = [];
    processMaster?.filter((i) => {
      if (
        i.workType === WorkType.SmBlankCombi ||
        i.workType === WorkType.SmBlankLaser ||
        i.workType === WorkType.SmBlankPunch
      ) {
        blankIsUsedData.push(i.id);
        // return i.id;
      }
    });
    const filteredList2 = kouteiPatternList[0]?.details?.filter((item2) =>
      blankIsUsedData.some((item1) => item1 === item2.processId)
    );

    const isAnyTrue = filteredList2?.some((item) => item.isUsedForCalc);

    // 工程入力
    let processSelectDetails = kouteiPatternList[0]?.details;

    let kouteiInputArr = [];
    if (processSelectDetails) {
      kouteiInputArr = processSelectDetails?.map((item, index) => {
        const processMstInfo = processMaster?.filter((i) => i.id === item.processId)[0] || [];

        const workType = processMstInfo.workType || '';

        let data = {
          id: index + 1,
          processId: item.processId,
          details: getDetails(workType, isAnyTrue),
        };

        return data;
      });
    }

    //　工程積算
    let kouteiCalArr = [];
    if (processSelectDetails) {
      kouteiCalArr = processSelectDetails?.map((item, index) => {
        const processMstInfo = processMaster?.filter((i) => i.id === item.processId)[0] || [];

        const workType = processMstInfo.workType || '';

        let addition = {
          no: index + 1,
          processId: item.processId,
          dataType: DataType.Data,
          dataItems: [],
          editItems: [],
          totalDandori: {
            dataItemsAmt: 0,
            dataItemsTime: 0,
            editItemsAmt: 0,
            editItemsTime: 0,
          },
          totalSagyo: {
            dataItemsAmt: 0,
            dataItemsTime: 0,
            editItemsAmt: 0,
            editItemsTime: 0,
          },
          totalDataPrice: 0,
          totalDataTime: 0,
          totalEditPrice: 0,
          totalEditTime: 0,
        };

        return addition;
      });
    }

    // 材料詳細パターン情報を準備する
    let materialDetailPatterns = [];
    materialDetailPatterns = new Array(2).fill(null)?.map((_, index) => {
      let data = {
        patternType: index === 0 ? PatternType.Pattern1 : PatternType.Pattern2,
        totalPrice: 0,
        yield: 0,
        materialQuantity: 0,
        materialSizeIq3Id: parameters?.materialSizeIq3?.[0]?.id,
        sizeName: parameters?.materialSizeIq3?.[0]?.name,
        sizeX: 0,
        sizeY: 0,
      };
      return data;
    });

    // 材料詳細サイズリスト情報を準備する
    let materialDetailSizes = [];
    let materialSizeIq3Infos = parameters?.materialSizeIq3;
    if (materialSizeIq3Infos) {
      materialDetailSizes = materialSizeIq3Infos
        ?.filter((item) => item.info.isUsed && item.name !== 'Free')
        ?.map((item, index) => {
          let data = {
            no: index + 1,
            materialSizeIq3Id: item.id,
            sizeName: item.name,
            materialQuantity: 0,
            price: 0,
            yield: 0,
            pattern1Rate: 0,
            pattern1Quantity: 0,
            pattern2Rate: 0,
            pattern2Quantity: 0,
            maxYield: 0,
            maxQuantity: 0,
            setting: { ySetting: true, yRotate: false, topFit: false, rightFit: false },
          };
          return data;
        });
    }

    const updatedList = lists?.map((i, index) => {
      if (i?.id === 0) {
        i.no = 1;
        i.creationType = EstimateCreationType.Auto;
        i.drawingNo = 'Part_' + formatDateString(todayTime());
        i.name = 'Part_' + padWithLeadingZeros(index + 1, 5);
        i.surfaceProtection = SurfaceProtectionType.OneSideVinyl;
        i.materialIq3Id = materialList?.length > 0 ? materialList[0]?.id : 0;
        i.materialName = materialList?.length > 0 ? materialList[0]?.name : '';
        i.materialTypeIq3Id = materialTypeList?.length > 0 ? materialTypeList[0]?.id : 0;
        i.materialTypeName = materialTypeList?.length > 0 ? materialTypeList[0]?.name : '';
        i.materialSurfaceIq3Id = materialSurfaceList?.length > 0 ? materialSurfaceList[0]?.id : 0;
        i.materialSurfaceName = materialSurfaceList?.length > 0 ? materialSurfaceList[0]?.name : '';
        i.thickness = materialList?.length > 0 ? materialList[0]?.id : 0;
        i.processSelect = {
          id: kouteiPatternList?.length > 0 ? kouteiPatternList[0]?.id : 0,
          name: kouteiPatternList?.length > 0 ? kouteiPatternList[0]?.name : '',
          details: kouteiPatternList?.length > 0 ? kouteiPatternList[0]?.details : [],
          modified: kouteiPatternList?.length > 0 ? kouteiPatternList[0]?.modified : dayjs().format(),
        };
        i.processInput = kouteiInputArr;
        i.additions = kouteiCalArr;
        // i.materialDetailPatterns = materialDetailPatterns;
        i.materialDetailSizes = materialDetailSizes;
      }
      return i;
    });
    return updatedList;
  };

  useEffect(() => {
    if (isAddPart) {
      let no = iQ3DataLst?.length > 0 ? iQ3DataLst[iQ3DataLst?.length - 1].no : 0;
      scrollTo(no);
    }
  }, [iQ3DataLst]);

  // 板金部品見積もりを取得する
  const getEstimateIQ3Data = async (estimateProductId) => {
    let estimateIq3Infos = await getEstimateIq3Info(estimateProductId);
    if (estimateIq3Infos) {
      setIQ3DataLst(estimateIq3Infos);
    }
    return estimateIq3Infos;
  };

  // Scrollの移動
  const scrollTo = (rowKey) => {
    // Scrollの移動
    var activeRowElements = document.getElementById('iq3EstimateTbl').getElementsByClassName('ant-table-row');
    for (let i = 0; i < activeRowElements?.length; i++) {
      var targetElement = activeRowElements[i].dataset.rowKey == rowKey ? activeRowElements[i] : [];
      if (targetElement != '') {
        targetElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    }
  };

  // 編集中破棄確認ポップアップのOKボタン押下
  const discardConfirmOk = () => {
    setDiscardModeKouteiCal(false);
    setEditModeCancelConfirm(false);
    props.updateMainEstimateEditMode(false);
    onChangeTab(selectedTabKey);
    getAllEstimateIq3Info();
  };

  // 編集中破棄確認ポップアップのCancelボタン押下
  const discardConfirmCancel = () => {
    setEditModeCancelConfirm(false);
  };

  // 部品リストの行を選択した場合、
  const onRowSelect = (record) => {
    setCurrentRecordNo(record.no);
    setCurrentRecord(record);
    setSelectedData(record);
  };

  const saveData = () => {
    // setDiscardMode(false);
    // setConfirmMsgOpen(true);
  };

  const updateData = () => {
    setDiscardMode(false);
    setConfirmMsgOpen(true);
  };

  const discardData = () => {
    setDiscardModeKouteiCal(false);
    setDiscardMode(true);
    setConfirmMsgOpen(true);
  };

  // 選択中であり、部品の各タブ情報をリストに反映するようにする
  const getAllEstimateIq3Info = () => {
    setConfirmMsgOpen(false);
    props.updateMainEstimateEditMode(false);
    let selectedIq3EstimateData = { ...selectedData };
    let estimateData = { ...selectedEstimateData };
    // 板金基本情報
    if (iq3DetailRef?.current) {
      const iq3Detail = iq3DetailRef?.current?.getIQ3EstimateDetailInfo();
      if (iq3Detail != null) {
        selectedIq3EstimateData = iq3Detail;
      }
    }
    // 工程選択
    if (iq3KouteiSentakuRef?.current) {
      const kouteiSentakuPatternNo = iq3KouteiSentakuRef?.current?.getKouteiSentakuPatternNo();
      const selectedPatternInfo = iq3KouteiSentakuRef?.current?.getProcessSelectedData();
      if (selectedPatternInfo) {
        selectedIq3EstimateData = {
          ...selectedIq3EstimateData,
          processSelect: {
            id: kouteiSentakuPatternNo,
            name: selectedPatternInfo?.name,
            details: selectedPatternInfo?.details,
            modified: selectedPatternInfo?.modified,
          },
        };
      }
    }
    // 工程入力
    if (iq3KouteiInputRef?.current) {
      const kouteiInputInfo = iq3KouteiInputRef?.current?.getKouteiInputInfo();
      const sizeX = iq3KouteiInputRef?.current?.getRectangleXSize();
      const sizeY = iq3KouteiInputRef?.current?.getRectangleYSize();
      const area = iq3KouteiInputRef?.current?.getAreaValue();
      const weight = iq3KouteiInputRef?.current?.getWeightValue();
      // selectedIq3EstimateData.kouteiInputInfo = kouteiInputInfo;
      if (kouteiInputInfo) {
        selectedIq3EstimateData = {
          ...selectedIq3EstimateData,
          processInput: kouteiInputInfo,
          partSizeX: sizeX,
          partSizeY: sizeY,
          weight: weight,
          totalSurfaceArea: area,
        };
      }
    }
    // 工程積算
    if (iq3KouteiCalRef?.current) {
      const iq3KouteiCalInfo = iq3KouteiCalRef?.current?.getIQ3KouteiCalInfo();
      if (iq3KouteiCalInfo) selectedIq3EstimateData.additions = iq3KouteiCalInfo;
    }
    // 購入品
    if (purchaseRef?.current) {
      const iq3PurchaseInfo = purchaseRef?.current?.getIQ3PurchaseInfo();
      if (iq3PurchaseInfo) selectedIq3EstimateData.purchases = iq3PurchaseInfo;
    }
    // 材料詳細
    if (iq3MaterialDetailRef?.current) {
      const materialDetailInfo = iq3MaterialDetailRef?.current?.getIQ3MaterialDetailInfo();
      let materialPatterns = materialDetailInfo?.materialDetailPatterns;
      let materialSizes = materialDetailInfo?.materialDetailSizes;

      if (activeTabKey !== '1' && materialDetailInfo) {
        if (selectedIq3EstimateData.materialIq3Id !== materialDetailInfo?.materialIq3Id)
          selectedIq3EstimateData.materialIq3Id = materialDetailInfo?.materialIq3Id;
        if (selectedIq3EstimateData.materialName !== materialDetailInfo?.materialName)
          selectedIq3EstimateData.materialName = materialDetailInfo?.materialName;
        if (selectedIq3EstimateData.materialTypeIq3Id !== materialDetailInfo?.materialTypeIq3Id)
          selectedIq3EstimateData.materialTypeIq3Id = materialDetailInfo?.materialTypeIq3Id;
        if (selectedIq3EstimateData.materialTypeName !== materialDetailInfo?.materialTypeName)
          selectedIq3EstimateData.materialTypeName = materialDetailInfo?.materialTypeName;
        if (selectedIq3EstimateData.materialSurfaceIq3Id !== materialDetailInfo?.materialSurfaceIq3Id)
          selectedIq3EstimateData.materialSurfaceIq3Id = materialDetailInfo?.materialSurfaceIq3Id;
        if (selectedIq3EstimateData.materialSurfaceName !== materialDetailInfo?.materialSurfaceName)
          selectedIq3EstimateData.materialSurfaceName = materialDetailInfo?.materialSurfaceName;
        if (selectedIq3EstimateData.thickness !== materialDetailInfo?.thickness)
          selectedIq3EstimateData.thickness = materialDetailInfo?.thickness;
      }
      if (materialDetailInfo) {
        selectedIq3EstimateData.grabbingX = materialDetailInfo?.grabbingX;
        selectedIq3EstimateData.grabbingY = materialDetailInfo?.grabbingY;
        selectedIq3EstimateData.pierWidthX = materialDetailInfo?.pierWidthX;
        selectedIq3EstimateData.pierWidthY = materialDetailInfo?.pierWidthY;
        selectedIq3EstimateData.materialCostUnitPrice = materialUnitPrice ? materialUnitPrice : 0;
        selectedIq3EstimateData.materialCostTotalPrice = materialTotalPrice ? materialTotalPrice : 0;
        selectedIq3EstimateData.materialDetailPatterns = materialPatterns ? materialPatterns : [];
        selectedIq3EstimateData.materialDetailSizes = materialSizes ? materialSizes : [];
      }
    }

    // 一覧の情報を反映するため、データ取得
    const data = [...iQ3DataLst];
    const updatedData = data?.map((item) => {
      if (item.no === selectedIq3EstimateData.no) {
        item = selectedIq3EstimateData;
      }
      return item;
    });

    estimateData = { ...estimateData, esIq3Info: updatedData };
    if (estimateData) {
      //タブ切替で再計算
      let calEstimateInfo = updateEstimateInfoByReCal(estimateData);
      if (calEstimateInfo) {
        // 一覧の情報を反映
        setIQ3DataLst(calEstimateInfo?.esIq3Info);
        calEstimateInfo?.esIq3Info?.map((item) => {
          if (item.no === selectedIq3EstimateData?.no) {
            // 選択中の部品情報を反映
            setSelectedData(item);
          }
        });
        props.updateEstimateInfo(calEstimateInfo);
        props.selectedEstimateData.estimateProducts = calEstimateInfo?.estimateProducts;
        props.selectedEstimateData.esIq3Info = calEstimateInfo?.esIq3Info;
        setSelectedEstimateData(calEstimateInfo);
      }
      return calEstimateInfo;
    }
  };

  const confirmOk = (e) => {
    getAllEstimateIq3Info();
  };

  const confirmCancel = () => {
    setConfirmMsgOpen(false);
    // props.childEditMode(false);
  };

  // 開発中ポップアップ
  const closeCommonTaiochuPopup = () => {
    setOpenCommonTaiochuPopup(false);
  };

  // 呼出
  const commonTaiochuPopupOpen = () => {
    setOpenCommonTaiochuPopup(true);
  };

  const partListCollapseChange = (key, e) => {
    if (key === 0) {
      if (isListClose1) {
        setIsListOpenAll(false);
        setIsListClose1(false);
        document.getElementById('data-panel-id-iq3-list-panel-id').style.flex = '0 1 0px';
        document.getElementById('data-panel-id-iq3-detail-panel-id').style.flex = '100 1 0px';
      } else {
        setIsListOpenAll(true);
        setIsListClose1(true);
        document.getElementById('data-panel-id-iq3-list-panel-id').style.flex = '18 1 0px';
        document.getElementById('data-panel-id-iq3-detail-panel-id').style.flex = '81 1 0px';
      }
    } else {
      if (isListOpen1) {
        setIsListOpenAll(true);
        setIsListOpen1(false);
        document.getElementById('data-panel-id-iq3-list-panel-id').style.flex = '39.3215 1 0px';
        document.getElementById('data-panel-id-iq3-detail-panel-id').style.flex = '60.6785 1 0px';
      } else {
        setIsListOpenAll(false);
        setIsListOpen1(true);
        document.getElementById('data-panel-id-iq3-list-panel-id').style.flex = '18 1 0px';
        document.getElementById('data-panel-id-iq3-detail-panel-id').style.flex = '81 1 0px';
      }
    }
  };

  const partListCollapseChange1 = (key, e) => {
    if (key === 0) {
      setIsListOpen1(false);
      document.getElementById('data-panel-id-iq3-list-panel-id').style.flex = '0 1 0px';
      document.getElementById('data-panel-id-iq3-detail-panel-id').style.flex = '100 1 0px';
    } else {
      setIsListClose1(false);
      document.getElementById('data-panel-id-iq3-list-panel-id').style.flex = '39.3215 1 0px';
      document.getElementById('data-panel-id-iq3-detail-panel-id').style.flex = '60.6785 1 0px';
    }
  };

  // 工程選択画面の工程リストを選択した場合、
  const updateSelectedData = (selectedPartNo) => {
    getAllEstimateIq3Info(); // タブの切り替えで入力中の情報を保持するようにする
    let selectedData = iQ3DataLst?.filter((item) => item.no == selectedPartNo);
    setCurrentRecordNo(selectedPartNo);
    setSelectedData(selectedData?.length > 0 ? selectedData[0] : iQ3DataLst[0]);
    scrollTo(selectedPartNo);
  };

  // import PMX file
  const importPMXFile = (pmxData) => {
    setIQ3DataLst(pmxData);
    setCurrentRecordNo(0);
    setSelectedData(pmxData[0]);
    navigate(':estimate.iq3');
  };

  const updateEstiateBasicInfoBydetailUpdate = (estimateInfo) => {
    let weight = 0.0;
    let surfaceArea = 0;
    let costPrice = 0.0;
    let totalCostPrice = 0.0;
    let estimatedAmount = 0.0;
    let totalEstimatedAmount = 0.0;
    let costProfit = 0.0;
    let estimateProfit = 0.0;
    let materialCost = 0.0;
    let processCost = 0.0;
    let purchaseCost = 0.0;
    let estimateDataProps = selectedEstimateData;
    if (estimateDataProps != undefined && estimateInfo != null) {
      costPrice = parseFloat(estimateDataProps.costPrice * estimateInfo.partCnt);
      totalCostPrice = parseFloat(estimateDataProps.totalCostPrice * estimateInfo.partCnt);
      estimatedAmount = parseFloat(estimateDataProps.estimatedAmount * estimateInfo.partCnt);
      totalEstimatedAmount = parseFloat(estimateDataProps.totalEstimatedAmount * estimateInfo.partCnt);
      costProfit = parseFloat(estimateDataProps.costProfit * estimateInfo.partCnt);
      estimateProfit = parseFloat(estimateDataProps.estimateProfit * estimateInfo.partCnt);
      materialCost = parseFloat(estimateDataProps.materialCost * estimateInfo.partCnt);
      processCost = parseFloat(estimateDataProps.processCost * estimateInfo.partCnt);
      purchaseCost = parseFloat(estimateDataProps.purchaseCost);
      const estimateBasicInfo = {
        estimateImgNo: estimateDataProps.imgNo,
        estimateNm: estimateDataProps.name,
        customerNm: estimateDataProps.customerNm,
        weight: estimateDataProps.childPartTotalWeight, // 確認
        surfaceArea: estimateDataProps.surfaceArea, // 確認
        volume: estimateDataProps.volume, // 確認
        creator: estimateDataProps.creator,
        estimatedDate: estimateDataProps.createdDate,
        deliveryDate: estimateDataProps.dateLst != undefined ? estimateDataProps.dateLst[2].data : '',
        partCnt: estimateDataProps.lotNum,

        costPrice: costPrice.toLocaleString(navigator.language, {
          minimumFractionDigits: 0,
        }),
        totalCostPrice: totalCostPrice.toLocaleString(navigator.language, {
          minimumFractionDigits: 0,
        }),
        estimatedAmount: estimatedAmount.toLocaleString(navigator.language, {
          minimumFractionDigits: 0,
        }),
        totalEstimatedAmount: totalEstimatedAmount.toLocaleString(navigator.language, {
          minimumFractionDigits: 0,
        }),
        costProfit: costProfit.toLocaleString(navigator.language, {
          minimumFractionDigits: 0,
        }),
        estimateProfit:
          estimateProfit != undefined
            ? estimateProfit.toLocaleString(navigator.language, {
                minimumFractionDigits: 0,
              })
            : 0,
        profitRate: estimateDataProps.rate,

        materialCost: materialCost.toLocaleString(navigator.language, {
          minimumFractionDigits: 0,
        }),
        processCost: processCost.toLocaleString(navigator.language, {
          minimumFractionDigits: 0,
        }),
        purchaseCost: purchaseCost.toLocaleString(navigator.language, {
          minimumFractionDigits: 0,
        }),

        customerCoefficient: estimateDataProps.customerCoefficient,
        managementCoefficient: estimateDataProps.managementCoefficient,
        materialLossCoefficient: estimateDataProps.materialLossCoefficient,
        orderForm: estimateDataProps.orderCoefficient,
        setupCoefficient: estimateDataProps.setupCoefficient,
        surfaceAreaCoefficient: estimateDataProps.surfaceAreaCoefficient,
        purchaseCoefficient: estimateDataProps.purchaseCoefficient,
        deliveryCoefficient: estimateDataProps.deliveryCoefficient,
        comment: estimateDataProps.comment,
      };

      props.updateEstimateInfo(estimateBasicInfo);
    }
  };

  useImperativeHandle(ref, () => ({
    importPMXFile: importPMXFile,
    getIQ3EditMode: () => {
      return editMode;
    },
    getIQ3EstimateDetailInfo: getAllEstimateIq3Info,
    getParentEditMode: () => {
      return editMode;
    },
    getEstimateIq3Info: () => {
      return iQ3DataLst;
    },
    kihonFormValidation: iq3DetailRef?.current?.formValidation,
    // getIQ3EstimateData: getEstimateInfo,
  }));

  // 部品追加ボタンの押下
  const addPart = async () => {
    let iq3Data = [...iq3EstimateInitInfo];
    let estimateData = { ...selectedEstimateData };
    var copyPart = getNewIQ3EstimateInfo(iq3Data);
    if (copyPart?.length > 0) copyPart = copyPart[0];
    if (iQ3DataLst != undefined && iQ3DataLst?.length > 0) {
      let tempIQ3List = [...iQ3DataLst];
      var lastRecord = tempIQ3List.sort((a, b) => b.no - a.no)?.[0];
      copyPart = { ...copyPart, no: lastRecord?.no + 1, name: 'Part_' + padWithLeadingZeros(lastRecord?.no + 1, 5) };
      setIQ3DataLst((prev) => [...prev, { ...copyPart }]);
      estimateData.esIq3Info = [...estimateData.esIq3Info, { ...copyPart }];
    } else {
      copyPart = { ...copyPart, no: lastRecord?.no + 1 };
      setIQ3DataLst([copyPart]);
      estimateData.esIq3Info = [copyPart];
    }
    setIsAddPart(true);
    setCurrentRecordNo(copyPart?.no);
    setSelectedData(copyPart);
    setSelectedEstimateData(estimateData);
  };

  // 部品削除ボタンの押下
  const deletePart = () => {
    if (iQ3DataLst?.length <= 1) return;
    /* setIsDelete(true); */

    let deletePart =
      props?.userSettingInfo?.[0]?.massages?.deletePart == undefined
        ? true
        : props?.userSettingInfo?.[0]?.massages?.deletePart;
    setIsDelete(deletePart);
    if (!deletePart) {
      deleteOk();
    }
    setChildTitle('削除');
  };

  // 削除ポップアップのOKボタンの押下
  const deleteOk = () => {
    setIsDelete(false);
    let tempIQ3List = [...iQ3DataLst];
    tempIQ3List = tempIQ3List?.filter((item) => item.no !== currentRecordNo);
    tempIQ3List = tempIQ3List?.map((item, index) => {
      return { ...item, no: index + 1 };
    });
    setIQ3DataLst(tempIQ3List);
    setSelectedEstimateData((prev) => ({ ...prev, esIq3Info: tempIQ3List }));
    if (tempIQ3List?.length > 0) {
      setCurrentRecordNo(tempIQ3List[0]?.no);
      setSelectedData(tempIQ3List[0]);
    }
  };

  // 削除ポップアップのCancelボタンの押下
  const deleteCancel = () => {
    setIsDelete(false);
  };

  // 上方向ボタンの押下
  const upRowSelect = () => {
    let tempIQ3List = [...iQ3DataLst];
    let no = selectedData?.no - 1;
    let record = tempIQ3List?.filter((item) => item.no === no);
    if (record && record?.length > 0) {
      setCurrentRecordNo(record[0]?.no);
      setSelectedData(record[0]);
      scrollTo(record[0]?.no);
    }
  };

  // 下方向ボタンの押下
  const downRowSelect = () => {
    let tempIQ3List = [...iQ3DataLst];
    let no = selectedData?.no + 1;
    let record = tempIQ3List?.filter((item) => item.no === no);
    if (record && record?.length > 0) {
      setCurrentRecordNo(record[0]?.no);
      setSelectedData(record[0]);
      scrollTo(record[0]?.no);
    }
  };

  //　追加呼出ボタンの押下
  const displayAddParentInfo = async () => {
    let newParameters = await GetParametersByCompId();
    newParameters.estimateStatus = newParameters?.estimateStatus?.filter((item) => item.info.isUsed);
    newParameters.staff = newParameters?.staff?.filter((item) => item.info.isUsed);
    newParameters.client = newParameters?.client?.filter((item) => item.info.isUsed);
    setNewParameters(newParameters);
    setParentListDisplay(true);
  };

  // 追加呼出ダイアログのOKボタン押下
  const addPartFromParentInfoOk = async () => {
    setParentListDisplay(false);
    if (selectedParentAddEstimate !== undefined && selectedParentAddEstimate?.estimateProducts?.id) {
      let estimateProductId = selectedParentAddEstimate?.estimateProducts?.id;
      const addIQ3Lst = await getEstimateIQ3Data(estimateProductId);
      var lastRecord = iQ3DataLst?.length > 0 ? iQ3DataLst[iQ3DataLst?.length - 1] : [];
      let no = lastRecord != [] ? lastRecord?.no + 1 : 1;
      const addNewIQ3Lst = [];
      for (let i = 0; i < addIQ3Lst?.length; i++) {
        var iq3Data = { ...addIQ3Lst[i] };
        iq3Data.id = 0;
        iq3Data.no = no;
        addNewIQ3Lst.push(iq3Data);
        no++;
      }
      setIQ3DataLst([...iQ3DataLst, ...addNewIQ3Lst]);
      setSelectedEstimateData({
        ...selectedEstimateData,
        esIq3Info: [...selectedEstimateData?.esIq3Info, ...addNewIQ3Lst],
      });
    }
  };

  // 追加呼出ダイアログのCancelボタン押下
  const addPartFromParentInfoCancel = () => {
    setParentListDisplay(false);
  };

  // 追加呼出画面に行を選択した場合、
  const getSelectedEstimateData = (estimateData, iq3RowKey, estimateEditMode) => {
    setSelectedParentAddEstimate(estimateData);
  };

  // タブの切り替え
  const onChange = (key) => {
    setSelectedTabKey(key);
    setDiscardModeKouteiCal(false);
    onChangeTab(key);
    // setIsSelectedTab(true);
  };

  // タブの切り替え
  const onChangeTab = (key) => {
    setActiveTabKey(key);
    getAllEstimateIq3Info();
  };

  const settingOpen = () => {
    setIsSetting(true);
  };

  const settingOK = () => {
    setIsSetting(false);
  };
  const settingCancel = () => {
    setIsSetting(false);
  };

  const menuIconList = (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'left',
          alignItems: 'center',
          width: '60%',
          marginTop: 5,
          marginBottom: 5,
          marginLeft: 5,
          height: 20,
        }}
      >
        <Row style={{ marginTop: '0px', marginLeft: '0', width: '-webkit-fill-available' }}>
          <Col span={2}>
            <Tooltip title="部品追加" placement="bottom" overlayClassName="tooltip-text">
              <a onClick={addPart}>
                <Image preview={false} width={27} src={child_add} style={{ marginLeft: '5px' }}></Image>
              </a>
            </Tooltip>
            <Tooltip title="部品削除" placement="bottom" overlayClassName="tooltip-text">
              <a onClick={deletePart}>
                <Image
                  preview={false}
                  width={23}
                  src={child_delete}
                  style={{ marginLeft: '20px', marginBottom: '1px' }}
                ></Image>
              </a>
            </Tooltip>
          </Col>
          <Col span={2}>
            <Tooltip title="追加呼出" placement="bottom" overlayClassName="tooltip-text">
              <a onClick={displayAddParentInfo}>
                <Image preview={false} width={25} src={parent_call_detail} style={{ marginLeft: '20px' }}></Image>
              </a>
            </Tooltip>
          </Col>
          <Col span={20}>
            <Tooltip title="Up" placement="bottom" overlayClassName="tooltip-text">
              <a
                onClick={() => {
                  getAllEstimateIq3Info(); // 行Upで入力中の情報を保持するようにする
                  upRowSelect();
                }}
              >
                <Image
                  preview={false}
                  width={23}
                  src={up_Arrow}
                  style={{ marginLeft: '5px', marginBottom: '1px' }}
                ></Image>
              </a>
            </Tooltip>
            <Tooltip title="Down" placement="bottom" overlayClassName="tooltip-text">
              <a
                onClick={() => {
                  getAllEstimateIq3Info(); // 行Downで入力中の情報を保持するようにする
                  downRowSelect();
                }}
              >
                <Image
                  preview={false}
                  width={23}
                  src={down_Arrow}
                  style={{ marginLeft: '20px', marginBottom: '1px' }}
                ></Image>
              </a>
            </Tooltip>
          </Col>
        </Row>
      </div>
      <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center', width: '39%' }}>
        <a onClick={settingOpen}>
          <Image preview={false} width={23} src={setting_icon} style={{ marginBottom: '1px' }}></Image>
        </a>
      </div>
    </div>
  );

  const closeComponent = (
    <>
      <CaretUpOutlined
        style={{ marginLeft: 10, color: 'white', marginTop: 6 }}
        onClick={(e) => partListCollapseChange(0, e)}
      />
      <p
        style={{
          fontSize: '13px',
          fontWeight: 'bold',
          paddingTop: 3,
          margin: 0,
          paddingLeft: 0,
          marginLeft: -7,
          color: 'white',
        }}
      >
        <a onClick={(e) => partListCollapseChange(0, e)}>閉じる</a>
      </p>
    </>
  );

  const openComponent = (
    <>
      <CaretDownOutlined
        style={{ marginLeft: 10, color: 'white', marginTop: 6 }}
        onClick={(e) => partListCollapseChange(1, e)}
      />

      <p
        style={{
          fontSize: '13px',
          fontWeight: 'bold',
          paddingTop: 3,
          margin: 0,
          paddingLeft: 0,
          marginLeft: -7,
          color: 'white',
        }}
      >
        <a onClick={(e) => partListCollapseChange(1, e)}>開く</a>
      </p>
    </>
  );

  const closeComponent1 = (
    <>
      <CaretUpOutlined
        style={{ marginLeft: 10, color: 'white', marginTop: 6 }}
        onClick={(e) => partListCollapseChange1(0, e)}
      />
      <p
        style={{
          fontSize: '13px',
          fontWeight: 'bold',
          paddingTop: 3,
          margin: 0,
          paddingLeft: 0,
          marginLeft: -7,
          color: 'white',
        }}
      >
        <a onClick={(e) => partListCollapseChange1(0, e)}>閉じる</a>
      </p>
    </>
  );

  const openComponent1 = (
    <>
      <CaretDownOutlined
        style={{ marginLeft: 10, color: 'white', marginTop: 6 }}
        onClick={(e) => partListCollapseChange1(1, e)}
      />

      <p
        style={{
          fontSize: '13px',
          fontWeight: 'bold',
          paddingTop: 3,
          margin: 0,
          paddingLeft: 0,
          marginLeft: -7,
          color: 'white',
        }}
      >
        <a onClick={(e) => partListCollapseChange1(1, e)}>開く</a>
      </p>
    </>
  );

  const childPartListHeader = (
    <>
      <div
        style={{
          display: 'flex',
        }}
      >
        <Space size="small">
          <p
            style={{
              fontSize: '13px',
              fontWeight: 'bold',
              paddingTop: 3,
              margin: 0,
              paddingLeft: 5,
              color: 'white',
            }}
          >
            部品リスト
          </p>
          {isListOpenAll ? closeComponent : openComponent}
          {isListOpen1 ? closeComponent1 : ''}
          {isListClose1 ? openComponent1 : ''}

          <div style={{ display: 'flex', marginLeft: '70px', marginTop: '1.3px' }}>
            {' '}
            <p
              style={{
                fontSize: '13px',
                fontWeight: '600',
                margin: 0,
                paddingLeft: 3,
                paddingRight: 3,
                color: 'white',
                border: 'solid',
                borderWidth: 1,
                borderColor: 'white',
                paddingTop: 3,
              }}
            >
              選択中
            </p>
            <p
              style={{
                fontSize: '13px',
                fontWeight: '600',
                paddingLeft: 10,
                margin: 0,
                paddingRight: 10,
                color: 'white',
                borderRight: 'solid',
                borderRightWidth: 1,
                borderRightColor: 'white',
                paddingTop: 3,
              }}
            >
              {selectedData != undefined && selectedData.no != undefined ? 'No.' + selectedData.no : 'No.0'}
            </p>
            <p
              style={{
                fontSize: '13px',
                fontWeight: '600',
                margin: 0,
                paddingLeft: 10,
                paddingRight: 10,
                color: 'white',
                borderRight: 'solid',
                borderRightWidth: 1,
                borderRightColor: 'white',
                paddingTop: 3,
              }}
            >
              {selectedData != undefined ? selectedData.drawingNo : 0}
            </p>
            <p
              style={{
                fontSize: '13px',
                fontWeight: '600',
                margin: 0,
                paddingLeft: 10,
                paddingRight: 10,
                color: 'white',
                borderRight: 'solid',
                borderRightWidth: 1,
                borderRightColor: 'white',
                paddingTop: 3,
              }}
            >
              {selectedData != undefined ? selectedData.name : 0}
            </p>
            <p
              style={{
                fontSize: '13px',
                fontWeight: '600',
                margin: 0,
                paddingLeft: 10,
                paddingRight: 10,
                color: 'white',
                paddingTop: 3,
              }}
            >
              {selectedData != undefined ? selectedData.partCount : 0}
            </p>
          </div>
        </Space>
      </div>
    </>
  );

  const modalTitle = (
    <div
      style={{
        width: 400,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 8, paddingLeft: 18, color: 'white' }}>{modalTitleTxt}</p>
    </div>
  );

  const parentListModalTitle = (
    <div
      style={{
        width: 1670,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 33,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
      }}
    >
      <p style={{ paddingTop: 5, paddingLeft: 18, color: 'white' }}>追加呼出</p>
    </div>
  );

  const iq3Columns = [
    {
      id: '1',
      title: 'No.',
      dataIndex: 'no',
      className: 'cm-a-right',
      width: 50 + 'px',
      // render: (value, array, index) => index + 1,
    },
    {
      id: '2',
      title: '採用',
      dataIndex: 'creationType',
      width: 60 + 'px',
      align: 'center',
      render: (value) => (value === EstimateCreationType.Auto ? '自動' : '手動'),
    },
    {
      id: '3',
      title: '画像',
      dataIndex: 'imageId',
      width: 140 + 'px',
      align: 'center',
      render: (value) => <img src={value} alt="" width={140} />,
    },
    {
      id: '4',
      title: ['工程パターン', <br />, '子部品図番', <br />, '子部品名称'],
      dataIndex: 'processSelect',
      width: 220 + 'px',
      align: 'center',
      render: (_, record) => (
        <div style={{ textAlign: 'left' }}>
          <label style={{ fontSize: '13px' }}>{record?.processSelect?.name}</label>
          <br />
          <label style={{ fontSize: '13px', marginTop: 2 }}>{record.drawingNo}</label>
          {/* <Input style={{ width: '260px', height: '22px', fontSize: '13px', marginTop: 2 }} value={record.imgNo} /> */}
          <br />
          <label style={{ fontSize: '13px', marginTop: 2 }}>{record.name}</label>
          {/* <Input style={{ width: '260px', height: '22px', fontSize: '13px', marginTop: 3 }} value={record.partNm} /> */}
          <br />
        </div>
      ),
    },
    {
      id: '5',
      title: '員数',
      dataIndex: 'partCount',
      width: 60 + 'px',
      align: 'center',
    },
    {
      id: '6',
      title: ['材質', <br />, '材料', <br />, '板厚', <br />, '表面保護'],
      dataIndex: 'materialItems',
      width: 150 + 'px',
      align: 'center',
      render: (_, record) =>
        estimateParameters ? (
          <div style={{ textAlign: 'left' }}>
            <label style={{ fontSize: '13px', marginTop: 2 }}>
              {getMaterialTypeById(record.materialTypeIq3Id, estimateParameters?.materialTypeIq3)}
            </label>
            <br />
            <label style={{ fontSize: '13px' }}>
              {getMaterialNameById(record.materialIq3Id, estimateParameters?.materialIq3)}
            </label>
            <br />
            <label style={{ fontSize: '13px', marginTop: 2 }}>
              {getMaterialThickById(record.thickness, estimateParameters?.materialIq3)}
            </label>
            <br />
            <label style={{ fontSize: '13px', marginTop: 2 }}>
              {getMaterialSurfaceById(record.materialSurfaceIq3Id, estimateParameters?.materialSurfaceIq3)}
            </label>
          </div>
        ) : (
          <></>
        ),
    },
    {
      id: '7',
      title: ['重量', <br />, '表面積', <br />, '表面処理費'],
      dataIndex: 'amountItems',
      width: 100 + 'px',
      align: 'center',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record.weight ? Math.floor(record.weight).toFixed(2) : 0.0}
            kg
          </label>
          <br />
          <label style={{ fontSize: '13px', marginTop: 8 }}>
            {record.totalSurfaceArea != undefined ? Math.floor(record.totalSurfaceArea).toFixed(2) : 0}
            cm<sup>2</sup>
          </label>
          <br />
          <label style={{ fontSize: '13px', marginTop: 8 }}>
            {record.surfaceTotalPrice ? JPYs.format(Math.floor(record.surfaceTotalPrice)) : amtEmptyStr}
          </label>
        </div>
      ),
    },
    {
      id: '8',
      title: ['材料原価', <br />, '製造原価', <br />, '購入品費'],
      dataIndex: 'costItems',
      width: 110 + 'px',
      align: 'center',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record.materialCostUnitPrice ? JPYs.format(Math.floor(record.materialCostUnitPrice)) : amtEmptyStr}
          </label>
          <br />
          <label style={{ fontSize: '13px', marginTop: 8 }}>
            {record.workCostUnitPrice ? JPYs.format(Math.floor(record.workCostUnitPrice)) : amtEmptyStr}
          </label>
          <br />
          <label style={{ fontSize: '13px', marginTop: 8 }}>
            {record.purchaseUnitPrice ? JPYs.format(Math.floor(record.purchaseUnitPrice)) : amtEmptyStr}
          </label>
        </div>
      ),
    },
    {
      id: '9',
      title: '原価',
      dataIndex: 'costUnitPrice',
      width: 110 + 'px',
      align: 'center',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record.costUnitPrice ? JPYs.format(Math.floor(record.costUnitPrice)) : amtEmptyStr}
          </label>
        </div>
      ),
    },
    {
      id: '10',
      title: '原価合計',
      dataIndex: 'costTotalPrice',
      width: 110 + 'px',
      align: 'center',
      render: (_, record) => (
        <div style={{ textAlign: 'right' }}>
          <label style={{ fontSize: '13px' }}>
            {record.costTotalPrice ? JPYs.format(Math.floor(record.costTotalPrice)) : amtEmptyStr}
          </label>
        </div>
      ),
    },
  ];

  const tabItems = [
    {
      key: '1',
      label: `基本情報`,
    },
    {
      key: '2',
      label: `工程選択`,
    },
    {
      key: '3',
      label: `工程入力`,
    },
    {
      key: '4',
      label: `工程積算`,
    },
    {
      key: '5',
      label: `購入品`,
    },
    {
      key: '6',
      label: `材料詳細`,
    },
  ];

  const settingModalTitle = (
    <div
      style={{
        width: 450,
        backgroundColor: '#005fab',
        marginLeft: -24,
        height: 36,
        marginTop: -20,
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        fontSize: 14,
        display: 'flex',
      }}
    >
      <p style={{ marginLeft: 7, paddingTop: 8, color: 'white' }}>設定</p>
    </div>
  );

  // 設定コンテンツ
  const settingContent = (
    <>
      <Row style={{ marginTop: '0px' }}>
        <Col span={2}>
          <Checkbox checked></Checkbox>
        </Col>
        <Col span={22}>
          <p style={{ fontSize: '13.5px', fontWeight: '500', margin: 0 }}>空送線を見積計算に含める</p>
        </Col>
      </Row>
      <Row style={{ marginTop: '0px' }}>
        <Col span={2}>
          <Checkbox checked></Checkbox>
        </Col>
        <Col span={22}>
          <p style={{ fontSize: '13.5px', fontWeight: '500', margin: 0 }}>プランク加工の下穴を見積計算に含める</p>
        </Col>
      </Row>
      <Row style={{ marginTop: '0px' }}>
        <Col span={2}>
          <Checkbox checked></Checkbox>
        </Col>
        <Col span={22}>
          <p style={{ fontSize: '13.5px', fontWeight: '500', margin: 0 }}>外形線を1本化処理して計算する</p>
        </Col>
      </Row>
      <Row style={{ marginTop: '0px' }}>
        <Col span={2}>
          <Checkbox checked></Checkbox>
        </Col>
        <Col span={22}>
          <p style={{ fontSize: '13.5px', fontWeight: '500', margin: 0 }}>同一線上の曲げ線をまとめる</p>
        </Col>
      </Row>
      <Row style={{ marginTop: '15px' }}>
        <Col span={24} style={{ textAlign: 'right' }} offset={0}>
          <Button onClick={settingOK} className="mainButton" id="update">
            OK
          </Button>

          <Button onClick={settingCancel} className="cancelButton" style={{ marginLeft: 10 }} id="discard">
            キャンセル
          </Button>
        </Col>
      </Row>
    </>
  );

  // タブ切り替えで、材料詳細の情報を再計算するかどうかのチェック
  const isReCalMaterial = () => {
    if (iq3MaterialDetailRef?.current === undefined) return false;
    let materialInfo = iq3MaterialDetailRef?.current?.getIQ3MaterialDetailInfo();
    if (materialInfo === undefined) return false;
    let quantity = iq3MaterialDetailRef?.current?.getQuantity();
    if (
      Number(materialInfo?.partCount) !== Number(selectedData?.partCount) ||
      Number(materialInfo?.materialIq3Id) !== Number(selectedData?.materialIq3Id) ||
      Number(materialInfo?.materialTypeIq3Id) !== Number(selectedData?.materialTypeIq3Id) ||
      Number(materialInfo?.materialSurfaceIq3Id) !== Number(selectedData?.materialSurfaceIq3Id) ||
      Number(materialInfo?.thickness) !== Number(selectedData?.thickness) ||
      Number(materialInfo?.partSizeX) !== Number(selectedData?.partSizeX) ||
      Number(materialInfo?.partSizeY) !== Number(selectedData?.partSizeY) ||
      Number(props?.getParentQuantity()) !== Number(quantity)
    ) {
      return true;
    }
    return false;
  };

  // 材料詳細の材料原価、材料単価
  const getMaterialPrice = (totalPrice, unitPrice) => {
    if (totalPrice) setMaterialTotalPrice(totalPrice);
    if (unitPrice) setMaterialUnitPrice(unitPrice);
  };

  return (
    <>
      <div>
        <Form>
          {/* 一覧とプロパティ表示 */}{' '}
          <div
            style={{
              height: '85.9vh',
            }}
          >
            <Row id="iq3-rows" style={{ marginLeft: '5px', width: '99.5%' }} className="oyabuhin oyabuhin-row">
              <Col span={24}>{childPartListHeader}</Col>
            </Row>
            {menuIconList}
            <PanelGroup direction="vertical">
              <Panel id="iq3-list-panel-id" collapsible={true} defaultSize={39.3}>
                <div id="iq3-list-div" className="iq3-list-div">
                  <Table
                    id="iq3EstimateTbl"
                    showSorterTooltip={false}
                    rowClassName={(record) => (record.no == currentRecordNo ? 'active-row' : 'data-row')}
                    columns={iq3Columns}
                    rowKey={(record) => record.no}
                    dataSource={iQ3DataLst}
                    className="iq3-list-tb"
                    // rowSelection={rowSelection}
                    pagination={false}
                    scroll={{ y: 'calc(100vh-220px)', x: '950' }}
                    onRow={(record, index) => {
                      return {
                        onClick: (event) => {
                          getAllEstimateIq3Info(); // 行選択で入力中の情報を保持するようにする
                          onRowSelect(record);
                        },
                      };
                    }}
                  />
                </div>
              </Panel>
              <PanelResizeHandle className="resizeHandleOuter">
                <div className="resizeHandleInner">
                  <svg className="iq3-detail-split-icon" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M8,18H11V15H2V13H22V15H13V18H16L12,22L8,18M12,2L8,6H11V9H2V11H22V9H13V6H16L12,2Z"
                    />
                  </svg>
                </div>
              </PanelResizeHandle>
              <Panel id="iq3-detail-panel-id" className="iq3-detail-panel" collapsible={true} defaultSize={60.7}>
                <div className="iq3-detail-div">
                  <Tabs
                    id="iq3-detail-tab"
                    className="iq3-detail-tab"
                    type="card"
                    activeKey={activeTabKey}
                    defaultActiveKey="1"
                    items={tabItems}
                    onChange={onChange}
                  />
                  {/* 基本情報 */}
                  <div style={{ display: activeTabKey == '1' ? 'block' : 'none' }} className="iq3-tab-div">
                    <IQ3EstimateDetail
                      isListOpen={isListOpenAll}
                      selectedIQ3DataDetail={selectedData}
                      ref={iq3DetailRef}
                      materialLst={materialLst}
                      materialTypeLst={materialTypeLst}
                      materialSurfaceLst={materialSurfaceLst}
                    />
                  </div>
                  {/* 工程選択 */}
                  <div style={{ display: activeTabKey == '2' ? 'block' : 'none' }} className="iq3-tab-div">
                    <IQ3KouteiSentaku
                      type={'list'}
                      ref={iq3KouteiSentakuRef}
                      editMode={editMode}
                      selectedIQ3DataDetail={selectedData}
                      // selectedEstimateData={props.selectedEstimateData}
                      selectedDataDetail={props.selectedEstimateData}
                      iQ3DataLst={iQ3DataLst}
                      pmxMode={false}
                      updateSelectedData={updateSelectedData}
                      processMasterData={processMasterInfo}
                      deviceMasterData={deviceMasterInfo}
                      clientMasterData={clientMasterInfo}
                    />
                  </div>
                  {/* 工程入力 */}
                  <div style={{ display: activeTabKey == '3' ? 'block' : 'none' }} className="iq3-tab-div">
                    <IQ3KouteiInput
                      ref={iq3KouteiInputRef}
                      processMasterData={processMasterInfo}
                      // editMode={editMode}
                      selectedIQ3DataDetail={selectedData}
                      materialList={materialLst}
                    />
                  </div>
                  {/* 工程積算 */}
                  <div style={{ display: activeTabKey == '4' ? 'block' : 'none' }} className="iq3-tab-div">
                    <IQ3KouteiCalculate
                      ref={iq3KouteiCalRef}
                      editMode={editMode}
                      selectedIQ3DataDetail={selectedData}
                      deviceMasterInfo={deviceMasterInfo}
                      processMasterData={processMasterInfo}
                      selectedEstimateData={props.selectedEstimateData}
                      activeTabKey={activeTabKey}
                    />
                  </div>
                  {/* 購入品 */}
                  <div style={{ display: activeTabKey == '5' ? 'block' : 'none' }} className="iq3-tab-div">
                    <IQ3Purchase
                      ref={purchaseRef}
                      selectedIQ3DataDetail={selectedData}
                      selectedEstimateData={props.selectedEstimateData}
                      getParentQuantity={props?.getParentQuantity}
                      // editMode={editMode}
                      isChildPart={true}
                    />
                  </div>
                  {/* 材料詳細 */}
                  <div style={{ display: activeTabKey == '6' ? 'block' : 'none' }} className="iq3-tab-div">
                    <IQ3MaterialDetail
                      ref={iq3MaterialDetailRef}
                      editMode={editMode}
                      selectedIQ3DataDetail={selectedData}
                      selectedEstimateData={props.selectedEstimateData}
                      getParentQuantity={props?.getParentQuantity}
                      materialLst={materialLst}
                      materialTypeLst={materialTypeLst}
                      materialSurfaceLst={materialSurfaceLst}
                      processMasterData={processMasterInfo}
                      deviceMasterData={deviceMasterInfo}
                      isReCalMaterial={isReCalMaterial()}
                      getMaterialPrice={getMaterialPrice}
                    />
                  </div>
                  {/* 工程別合計 */}
                  <div
                    style={{
                      display: 'flex',
                      marginTop: '3px',
                      position: 'absolute',
                      top: '-36px',
                      right: 0,
                    }}
                  >
                    <KouteiSuryouBetsuBtn
                      selectedEstimateData={selectedEstimateData}
                      getParentQuantity={props?.getParentQuantity}
                    />
                  </div>
                </div>
              </Panel>
              <PanelResizeHandle className="resizeHandleOuter">
                <div className="resizeHandleInner">
                  <svg className="iq3-detail-split-icon" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M8,18H11V15H2V13H22V15H13V18H16L12,22L8,18M12,2L8,6H11V9H2V11H22V9H13V6H16L12,2Z"
                    />
                  </svg>
                </div>
              </PanelResizeHandle>
            </PanelGroup>
          </div>
        </Form>

        {/** 開発中ポップアップ */}
        {openCommonTaiochuPopup ? commonTaiochuModal(openCommonTaiochuPopup, closeCommonTaiochuPopup) : ''}
        {/** 編集中破棄確認ポップアップ */}
        {editModeCancelConfirm
          ? commonModal(
              editModeCancelConfirm,
              confirmScreenChangeTitle,
              commonFooter(discardConfirmOk, discardConfirmCancel),
              null,
              400,
              discardConfirmOk,
              discardConfirmCancel,
              confirmScreenChangeContent,
              null,
              null,
              false
            )
          : ''}
        {/** 更新 / 破棄の確認メッセージ */}
        {confirmMsgOpen
          ? commonModal(
              confirmMsgOpen,
              modalTitle,
              commonFooter(confirmOk, confirmCancel),
              null,
              400,
              confirmOk,
              confirmCancel,
              discardMode ? confirmDiscardContent : confirmUpdateContent,
              null,
              null,
              false
            )
          : ''}

        {/* 削除 */}
        {isDelete
          ? commonModal(
              isDelete,
              confirmModalTitle,
              commonDeleteFooter(deleteOk, deleteCancel),
              null,
              400,
              deleteOk,
              deleteCancel,
              /*  editMode ? confirmDiscardAndDeleteContent : confirmDeleteContent, */
              <p style={{ fontSize: '13.5px' }}>
                選択中の子部品を削除します。
                <br></br>削除したデータは戻せませんが、よろしいですか？
              </p>,
              null,
              null,
              false
            )
          : ''}

        {/* 親部品リストダイアログ */}
        {parentListDisplay ? (
          <Modal
            maskClosable={false}
            open={parentListDisplay}
            title={parentListModalTitle}
            footer={null}
            bodyStyle={{ height: '71.7vh', marginTop: -5, marginLeft: -23 }}
            width={1670}
            onOk={addPartFromParentInfoOk}
            onCancel={addPartFromParentInfoCancel}
            closeIcon={<CloseOutlined style={{ color: 'white' }} />}
            className={null}
            style={{ top: 190, left: 50 }}
            centered={false}
          >
            <div style={{ borderBottom: '1px solid #6c757d', width: '101.2%' }}>
              <ParentList
                getSelectedEstimateData={getSelectedEstimateData}
                editMode={false}
                parentListClick={false}
                isFromiQ3={true}
                orderOptions={newParameters?.estimateStatus}
                companyInfo={newParameters?.company}
                clientInfo={newParameters?.client}
                userInfo={newParameters?.currentUserInfo}
                staffInfo={newParameters?.staff}
                processInfo={newParameters?.process}
              />
            </div>
            <Row
              justify="end"
              style={{
                marginBottom: 0,
                marginTop: 7,
              }}
            >
              <Button className="mainButton" onClick={addPartFromParentInfoOk} style={{ marginRight: 5 }}>
                追加
              </Button>
              <Button className="cancelButton" onClick={addPartFromParentInfoCancel}>
                閉じる
              </Button>
            </Row>
          </Modal>
        ) : (
          ''
        )}
        {/* 設定操作 */}
        {isSetting
          ? commonModal(
              isSetting,
              settingModalTitle,
              null,
              { height: 123 },
              450,
              settingOK,
              settingCancel,
              settingContent,
              'updateModalStyle',
              null,
              false
            )
          : ''}
      </div>
    </>
  );
});

export default IQ3Estimate;
