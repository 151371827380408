import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';

import { Row, Col, Input, Radio, Checkbox, Select, Table as AntTable, Modal, Button, Image, Space } from 'antd';
import { CloseOutlined, PlusCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { Table } from 'react-bootstrap';

import '../../assets/styles/common.css';
import { getNameById } from '../common/Common';

const RadioGroup = Radio.Group;

// let yousetsu1Datas = [];
const yousetsu1Datas = [
  {
    key: 1,
    yousetsuType: 'アルゴン',
    senchou: 199.86,
    senchouKaisu: 2,
    senchouYousetsushiage: 'on',
    total1: 399.72,

    karitsukeCnt: 0,
    karitsukeKaisu: 0,
    karitsukeYousetsushiage: 'on',
    total2: 0,
  },
  {
    key: 2,
    yousetsuType: 'アルゴン',
    senchou: '133.00',
    senchouKaisu: 2,
    senchouYousetsushiage: 'on',
    total1: '266.00',

    karitsukeCnt: 0,
    karitsukeKaisu: 0,
    karitsukeYousetsushiage: 'on',
    total2: 0,
  },
  {
    key: 3,
    yousetsuType: 'アルゴン',
    senchou: '170.00',
    senchouKaisu: 1,
    senchouYousetsushiage: 'on',
    total1: '170.00',

    karitsukeCnt: 0,
    karitsukeKaisu: 0,
    karitsukeYousetsushiage: 'on',
    total2: 0,
  },
];
let yousetsu2Datas = [];
yousetsu2Datas.push({
  key: 1,
  otherYousetsuType: 7,
  otherSenchou: 'M10',
  otherSenchouCnt: 10,
  otherSenchouKaisu: 1,
  otherSenchouYousetsushiage: 'off',
  karitsukeCnt: 10,
});

const initialYousetsu1 = [
  {
    key: 1,
    senchou: 0,

    senchouKaisu: 0,
    senchouYousetsushiage: 'on',

    karitsukeCnt: 0,
    karitsukeKaisu: 0,
    karitsukeYousetsushiage: 'on',
  },
  {
    key: 2,
    senchou: 0,

    senchouKaisu: 0,
    senchouYousetsushiage: 'on',

    karitsukeCnt: 0,
    karitsukeKaisu: 0,
    karitsukeYousetsushiage: 'on',
  },
];
let initialYousetsu2 = [];
initialYousetsu2.push({
  key: 1,
  senchou: 10,

  senchouKaisu: 10,
  senchouYousetsushiage: 'on',

  karitsukeCnt: 35,
  karitsukeKaisu: 0,
  karitsukeYousetsushiage: 'on',
});

const ParentKouteiInput_Yousetsu = forwardRef((props, ref) => {
  // const [editMode, setEditMode] = useState(false);
  // add new
  const [addModal, setAddModal] = useState(false);
  const [yousetsu1DataLst, setYousetsu1DataLst] = useState([]);
  const [yousetsu2DataLst, setYousetsu2DataLst] = useState([]);
  const [yousetsuType_tentsuke, setYousetsuType_tentsuke] = useState([]);
  const [yousetsuTypes, setYousetsuTypes] = useState([]);
  // 編集モード
  const editMode = props.editMode;

  useEffect(() => {
    //マスターのサプ溶接種類設定
    let yousetsuTypes_tentsuke = [];
    let yousetsuTypes = [];
    const kouteiMasterData =
      localStorage.getItem('kouteiMasterData') != undefined ? JSON.parse(localStorage.getItem('kouteiMasterData')) : [];
    const yosetsuMasterData = kouteiMasterData?.filter((item) => item.type == 'iQ3' && item.processType == '溶接');
    let id = 1;
    let yousetsuTypeId = 1;
    for (let j = 0; j < yosetsuMasterData[0]?.processDetail?.length; j++) {
      if (
        yosetsuMasterData[0].processDetail[j].processDetailTitle == '取付種類（板厚判断）' ||
        yosetsuMasterData[0].processDetail[j].processDetailTitle == '取付種類（サイズ判断）'
      ) {
        const title = yosetsuMasterData[0].processDetail[j].processDetailTitle;
        const masterYousetsuTypes = yosetsuMasterData[0].processDetail[j].processDetailValue;
        for (let i = 0; i < masterYousetsuTypes?.length; i++) {
          yousetsuTypes_tentsuke.push({
            id: id,
            value: id,
            label: masterYousetsuTypes[i].detailType,
            type: title == '取付種類（板厚判断）' ? 'PaInstThick' : 'PaInstSize',
          });
          id = id + 1;
        }
      } else if (yosetsuMasterData[0].processDetail[j].processDetailTitle == '溶接種類') {
        const masterYousetsuTypes = yosetsuMasterData[0].processDetail[j].processDetailValue;
        for (let n = 0; n < masterYousetsuTypes?.length; n++) {
          yousetsuTypes.push({
            id: yousetsuTypeId,
            value: yousetsuTypeId,
            label: masterYousetsuTypes[n].detailType,
          });
          yousetsuTypeId = yousetsuTypeId + 1;
        }
      }
    }
    setYousetsuType_tentsuke(yousetsuTypes_tentsuke);
    setYousetsuTypes(yousetsuTypes);
    var radioItem = document.getElementsByName('arugonRadio');
    radioItem.disabled = true;
    if (props.yousetsu1Datas?.length == 0) {
      // 設定から初期データ表示
      setYousetsu1DataLst(initialYousetsu1);
    } else {
      setYousetsu1DataLst(props.yousetsu1Datas);
    }
    if (yousetsu2Datas?.length == 0) {
      // 設定から初期データ表示
      setYousetsu2DataLst(initialYousetsu2);
    } else {
      setYousetsu2DataLst(yousetsu2Datas);
    }
  }, [props.yousetsu1Datas]);

  useImperativeHandle(ref, () => ({
    setEditModeChange: setEditModeChange,
  }));

  const addNew = () => {
    setAddModal(true);
  };
  const addOK = () => {
    setAddModal(false);
  };
  const addCancel = () => {
    setAddModal(false);
  };

  const addYousetsu1 = () => {
    if (yousetsu1DataLst?.length > 0) {
      //
      const data = {
        key: yousetsu1DataLst.slice(-1)[0].key + 1,
        yousetsuType: 1,
        senchou: 0.0,

        senchouKaisu: 0,
        senchouYousetsushiage: 'on',
        total1: 0,

        karitsukeCnt: 0,
        karitsukeKaisu: 0,
        karitsukeYousetsushiage: 'on',
        total2: 0,
      };
      let addArr = [...yousetsu1DataLst, data];
      setYousetsu1DataLst(addArr);
    }
  };
  const deleteYousetsu1 = (e, index) => {
    if (yousetsu1DataLst?.length > 1) {
      setYousetsu1DataLst(
        yousetsu1DataLst?.filter((j) => {
          return ![e]?.includes(j.key);
        })
      );
    }
  };
  const addYousetsu2 = () => {
    //
    const data = {
      key: yousetsu2DataLst.slice(-1)[0].key + 1,
      otherYousetsuType: 1,
      otherSenchou: 0,
      otherSenchouCnt: 0,
      otherSenchouKaisu: 0,
      otherSenchouYousetsushiage: 'off',
      karitsukeCnt: 0,
    };
    let addArr = [...yousetsu2DataLst, data];
    setYousetsu2DataLst(addArr);
  };
  const deleteYousetsu2 = (e, index) => {
    if (yousetsu2DataLst?.length > 1) {
      setYousetsu2DataLst(
        yousetsu2DataLst?.filter((j) => {
          return ![e]?.includes(j.key);
        })
      );
    }
  };
  const onYousetsu1ValUpdate = (i, event) => {
    const { name, value } = event.target;
    const data = [...yousetsu1DataLst];
    data[i][name] = value;
    setYousetsu1DataLst(data);
  };
  const onYousetsu2ValUpdate = (i, event) => {
    const { name, value } = event.target;
    const data = [...yousetsu2DataLst];
    data[i][name] = value;

    setYousetsu2DataLst(data);
  };
  const onYousetsu1ValSelectUpdate = (i, event, name) => {
    if (name == 'yousetsuType') {
      const data = [...yousetsu1DataLst];
      data[i][name] = event;
      setYousetsu1DataLst(data);
    } else {
      const data = [...yousetsu2DataLst];
      data[i][name] = event;
      setYousetsu2DataLst(data);
    }
  };

  const firstYousetsuComponent = (
    <>
      {yousetsu1DataLst?.map((item, index) => {
        const {
          yousetsuType,
          senchou,
          senchouKaisu,
          senchouYousetsushiage,
          karitsukeCnt,
          karitsukeKaisu,
          total1,
          total2,
          karitsukeYousetsushiage,
        } = item;
        return (
          <>
            <tr>
              <td rowSpan={2} style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                {item.key}
              </td>
              <td rowSpan={2} style={{ verticalAlign: 'middle' }}>
                {editMode ? (
                  <>
                    <Select
                      name="yousetsuType"
                      className="yousetsu-select"
                      style={{ pointerEvents: editMode ? 'auto' : 'none' }}
                      // onChange={(e) => setArugonSel(e.target.value)}
                      onChange={(event) => onYousetsu1ValSelectUpdate(index, event, 'yousetsuType')}
                      // defaultValue={yousetsuType[0].value}
                      value={yousetsuType}
                    >
                      {yousetsuTypes?.map((option, index) => (
                        <Select.Option key={index} value={option.value}>
                          {option.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </>
                ) : (
                  <>{getNameById(yousetsuType, yousetsuTypes)}</>
                )}
              </td>

              <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>線長（mm）</td>
              <td>
                <Input
                  name="senchou"
                  className={editMode ? 'input-editable ' : 'input-non-editable '}
                  style={{ height: 32, textAlign: 'center' }}
                  value={senchou}
                  onChange={(event) => onYousetsu1ValUpdate(index, event)}
                ></Input>
              </td>
              <td>
                <Input
                  name="senchouKaisu"
                  className={editMode ? 'input-editable ' : 'input-non-editable '}
                  style={{ height: 32, textAlign: 'center' }}
                  value={senchouKaisu}
                  onChange={(event) => onYousetsu1ValUpdate(index, event)}
                ></Input>
              </td>
              <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                <RadioGroup
                  name="senchouYousetsushiage"
                  defaultValue={'on'}
                  className={editMode ? 'radioCustomerGpEdit input-editable' : 'radioCustomerGpEdit input-non-editable'}
                  // onChange={arugonRadioChange}
                  onChange={(event) => onYousetsu1ValUpdate(index, event)}
                  value={senchouYousetsushiage}
                >
                  <Radio value={'on'}>適用</Radio>
                  <Radio value={'off'}>除外</Radio>
                </RadioGroup>
              </td>
              <td style={{ verticalAlign: 'middle' }}>
                <Input
                  className={editMode ? 'input-editable ' : 'input-non-editable '}
                  style={{ textAlign: 'center', border: 'none' }}
                  value={total1}
                ></Input>
              </td>

              {editMode ? (
                <>
                  <td
                    style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '6%' }}
                    className="min"
                    rowSpan={2}
                  >
                    <Row justify="center">
                      <PlusCircleFilled
                        className="add-remove-icon"
                        style={{ marginLeft: 10, marginRight: 10 }}
                        onClick={addYousetsu1}
                      />
                      <CloseCircleFilled
                        className="add-remove-icon"
                        onClick={(event) => deleteYousetsu1(item.key, event)}
                      />
                    </Row>
                  </td>
                </>
              ) : (
                <>
                  <td
                    rowSpan={2}
                    style={{ border: '1px', backgroundColor: 'white', verticalAlign: 'middle', width: '6%' }}
                  ></td>
                </>
              )}
            </tr>
            <tr>
              <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>仮付（箇所）</td>
              <td>
                <Input
                  name="karitsukeCnt"
                  className={editMode ? 'input-editable ' : 'input-non-editable '}
                  style={{ height: 32, textAlign: 'center' }}
                  value={karitsukeCnt}
                  onChange={(event) => onYousetsu1ValUpdate(index, event)}
                ></Input>
              </td>
              <td>
                <Input
                  name="karitsukeKaisu"
                  className={editMode ? 'input-editable ' : 'input-non-editable '}
                  style={{ height: 32, textAlign: 'center' }}
                  value={karitsukeKaisu}
                  onChange={(event) => onYousetsu1ValUpdate(index, event)}
                ></Input>
              </td>
              <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                <RadioGroup
                  name="karitsukeYousetsushiage"
                  defaultValue={'on'}
                  className={editMode ? 'radioCustomerGpEdit input-editable' : 'radioCustomerGpEdit input-non-editable'}
                  // onChange={arugonRadioChange}
                  onChange={(event) => onYousetsu1ValUpdate(index, event)}
                  value={karitsukeYousetsushiage}
                >
                  <Radio value={'on'}>適用</Radio>
                  <Radio value={'off'}>除外</Radio>
                </RadioGroup>
              </td>
              <td style={{ verticalAlign: 'middle' }}>
                <Input
                  className={editMode ? 'input-editable ' : 'input-non-editable '}
                  style={{ textAlign: 'center', border: 'none' }}
                  value={total2}
                ></Input>
              </td>
            </tr>
          </>
        );
      })}
    </>
  );
  const secondYousetsuComponent = (
    <>
      {yousetsu2DataLst?.map((item, index) => {
        const {
          otherYousetsuType,
          karitsukeCnt,
          otherSenchou,
          otherSenchouCnt,
          otherSenchouKaisu,
          otherSenchouYousetsushiage,
        } = item;
        return (
          <>
            <tr justify="middle">
              <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{item.key}</td>
              <td style={{ verticalAlign: 'middle' }}>
                {editMode ? (
                  <>
                    <Select
                      name="otherYousetsuType"
                      className="yousetsu-select"
                      // onChange={(e) => setShudousutaddoSel(e.target.value)}
                      onChange={(event) => onYousetsu1ValSelectUpdate(index, event, 'otherYousetsuType')}
                      style={{ fontSize: '13px' }}
                      // defaultValue={yousetsuType_tentsuke[0].value}
                      value={otherYousetsuType}
                    >
                      {yousetsuType_tentsuke?.map((option, index) => (
                        <Select.Option key={index} value={option.value}>
                          {option.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </>
                ) : (
                  <>{getNameById(otherYousetsuType, yousetsuType_tentsuke)}</>
                )}
              </td>

              <td>
                {' '}
                <Input
                  id={'otherSenchou' + index}
                  name="otherSenchou"
                  className={editMode ? 'input-editable ' : 'input-non-editable '}
                  style={{
                    height: 32,
                    textAlign: 'center',
                    backgroundColor:
                      yousetsuType_tentsuke?.filter(
                        (item) => item.id == otherYousetsuType && item.type == 'PaInstThick'
                      )?.length > 0
                        ? '#d9d9d9'
                        : 'white',
                    pointerEvents:
                      yousetsuType_tentsuke?.filter(
                        (item) => item.id == otherYousetsuType && item.type == 'PaInstThick'
                      )?.length > 0
                        ? 'none'
                        : editMode
                        ? 'auto'
                        : 'none',
                  }}
                  value={otherSenchou}
                  onChange={(event) => onYousetsu2ValUpdate(index, event)}
                ></Input>
              </td>
              <td>
                <Input
                  name="otherSenchouCnt"
                  className={editMode ? 'input-editable ' : 'input-non-editable '}
                  style={{ height: 32, textAlign: 'center' }}
                  value={otherSenchouCnt}
                  onChange={(event) => onYousetsu2ValUpdate(index, event)}
                ></Input>
              </td>
              <td>
                <Input
                  name="otherSenchouKaisu"
                  className={editMode ? 'input-editable ' : 'input-non-editable '}
                  style={{ height: 32, textAlign: 'center' }}
                  value={otherSenchouKaisu}
                  onChange={(event) => onYousetsu2ValUpdate(index, event)}
                ></Input>
              </td>
              <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                <RadioGroup
                  name="otherSenchouYousetsushiage"
                  defaultValue={'off'}
                  className={editMode ? 'radioCustomerGpEdit input-editable' : 'radioCustomerGpEdit input-non-editable'}
                  onChange={(event) => onYousetsu2ValUpdate(index, event)}
                  value={otherSenchouYousetsushiage}
                >
                  <Radio value={'on'}>適用</Radio>
                  <Radio value={'off'}>除外</Radio>
                </RadioGroup>
              </td>
              <td style={{ verticalAlign: 'middle' }}>
                <Input
                  className={editMode ? 'input-editable ' : 'input-non-editable '}
                  style={{ textAlign: 'center', border: 'none' }}
                  value={karitsukeCnt}
                ></Input>
              </td>
              {editMode ? (
                <>
                  <td
                    style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '6%' }}
                    className="min"
                  >
                    <Row justify="center">
                      <PlusCircleFilled
                        className="add-remove-icon"
                        style={{ marginLeft: 10, marginRight: 10 }}
                        onClick={addYousetsu2}
                      />
                      <CloseCircleFilled
                        className="add-remove-icon"
                        onClick={(event) => deleteYousetsu2(item.key, event)}
                      />
                    </Row>
                  </td>
                </>
              ) : (
                <>
                  <td style={{ border: 'none', backgroundColor: 'white', verticalAlign: 'middle', width: '6%' }}></td>
                </>
              )}
            </tr>
          </>
        );
      })}
    </>
  );

  return (
    <>
      {/* 溶接 */}

      <Row className="mt-1 registerModal" align="middle">
        <Table className="kouteiinput " style={{ width: '96%' }}>
          <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
            <tr style={{ textAlign: 'center' }}>
              <th style={{ width: '3%' }} className="tbl-header">
                No
              </th>
              <th style={{ width: '13%' }} className="tbl-header">
                溶接種類
              </th>
              <th style={{ width: '8%' }} className="tbl-header">
                種類/形状
              </th>
              <th style={{ width: '8%' }} className="tbl-header">
                詳細
              </th>
              <th style={{ width: '8%' }} className="tbl-header">
                回数
              </th>
              <th style={{ width: '16%' }} className="tbl-header">
                溶接仕上
              </th>
              <th style={{ width: '8%' }} className="tbl-header">
                合計
              </th>
            </tr>
          </thead>
          <tbody> {firstYousetsuComponent}</tbody>
        </Table>
      </Row>

      <Row className="mt-1 registerModal" align="middle">
        <Table className="kouteiinput" style={{ width: '96%' }}>
          <thead style={{ backgroundColor: '#1063aa', color: 'white' }}>
            <tr style={{ textAlign: 'center' }}>
              <th style={{ width: '3%' }} className="tbl-header">
                No
              </th>
              <th style={{ width: '13%' }} className="tbl-header">
                サプ溶接種類
              </th>
              <th style={{ width: '8%' }} className="tbl-header">
                径(mm)
              </th>
              <th style={{ width: '8%' }} className="tbl-header">
                数
              </th>
              <th style={{ width: '8%' }} className="tbl-header">
                回数
              </th>
              <th style={{ width: '16%' }} className="tbl-header">
                溶接仕上
              </th>
              <th style={{ width: '8%' }} className="tbl-header">
                合計
              </th>
            </tr>
          </thead>
          <tbody> {secondYousetsuComponent}</tbody>
        </Table>
      </Row>
    </>
  );
});

export default ParentKouteiInput_Yousetsu;
